/**
 * API requests for groups.
 *
 * @module api/v2/groups
 * @category Backend API v2
 * @subcategory ACL
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import log from "log";
import { responseToUserGroupEntry } from "model/acl";

/**
 * Get a list of all groupss visible to the current groups.
 *
 * @function list
 * @return {module:api/types/groups~User[]}
 */
export const list = async () => {
  try {
    const groups = (await get(`${endpoints.ACL_GROUP}/all`)).body;
    return groups.map(responseToUserGroupEntry);
  } catch (e) {
    log.error(e, "unable to list groupss");
    return [];
  }
};

export const count = async () => {
  try {
    const groups = (await get(`${endpoints.ACL_GROUP}/all`)).body;
    return groups.length;
  } catch (e) {
    log.error(e, "unable to count groupss");
    return [];
  }
};
