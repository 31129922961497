import { fakeLabels } from "api/mocks/metadata";
import log from "log";
import collapsible from "ui/component/collapsible";
import labelSelectModal from "ui/component/modal/label-select";
import icon from "ui/component/icon";
import { button } from "ui/html";

export default (modalView) => collapsible(
  { title: "Label Select", open: false },
  [
    button(
      ["Single Select", icon.solid("tag")],
      async () => {
        const label = await modalView.async(labelSelectModal({
          entries: fakeLabels,
        }, modalView));
        log.debug("selected label", label);
      },
      ".subtle.forward",
    ),
    button(
      ["Single Select (No Add)", icon.solid("tag")],
      async () => {
        const label = await modalView.async(labelSelectModal({
          entries: fakeLabels,
          noAdd: true,
        }, modalView));
        log.debug("selected label", label);
      },
      ".subtle.forward",
    ),
    button(
      ["Multi Select", icon.solid("tags")],
      async () => {
        const labels = await modalView.async(labelSelectModal({
          entries: fakeLabels,
          multiSelect: true,
        }, modalView));
        log.debug("selected labels", labels);
      },
      ".subtle.forward",
    ),
    button(
      ["Multi Select (No Add)", icon.solid("tags")],
      async () => {
        const labels = await modalView.async(labelSelectModal({
          entries: fakeLabels,
          multiSelect: true,
          noAdd: true,
        }, modalView));
        log.debug("selected labels", labels);
      },
      ".subtle.forward",
    ),
    button(
      ["Synchronous", icon.solid("tag")],
      () => {
        modalView.show(labelSelectModal({
          entries: fakeLabels,
          onSelect: (result) => log.debug("selected labels", result),
        }, modalView));
      },
      ".subtle.forward",
    ),
  ],
);
