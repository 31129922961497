import { featureTypes, verificationMethods } from "model/config/constants";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import span from "ui/html/span";
import badge from "ui/component/badge";
import { featureEnabled } from "util/feature-flag";

const userVerificationBadge = (userVerification) => {
  switch (userVerification) {
    case verificationMethods.ADMIN:
      return badge("admin", ".enabled");
    case verificationMethods.OTP:
      return badge("otp", ".enabled");
    default:
      return badge("disabled");
  }
};

export default function settingsBlock(state) {
  const {
    firstNameRequired,
    lastNameRequired,
    emailAddressRequired,
    cellPhoneRequired,
  } = state.config.server;
  const userVerification = featureEnabled(featureTypes.OTP_VERIFICATION);

  return div(
    "#settings.block",
    [
      h3("User Account Settings"),
      span('User Account First Name', '.label'),
      span(badge(firstNameRequired ? 'required' : 'optional'), '.value'),

      span('User Account Last Name', '.label'),
      span(badge(lastNameRequired ? 'required' : 'optional'), '.value'),

      span('User Account Email Address', '.label'),
      span(badge(emailAddressRequired ? 'required' : 'optional'), '.value'),

      span('User Account Cell Phone', '.label'),
      span(badge(cellPhoneRequired ? 'required' : 'optional'), '.value'),

      span('OTP Verification', '.label'),
      span(userVerificationBadge(userVerification), '.value'),
    ],
  );
}
