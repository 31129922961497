/**
 * A cameo for grading scheme.
 *
 * @module ui/component/cameo-grading-scheme
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import iconButton from "ui/component/icon-button";

const modeTitle = (automatic, onChangeMode) => {
  let title = automatic ? "Automatic Grading" : "Manual Grading";
  if (onChangeMode) title += " (click to change)";
  return title;
};

const modeControl = (automatic, onChangeMode) => {
  if (automatic === undefined) {
    return "";
  }
  return iconButton(
    automatic ? "robot" : "user-clock",
    modeTitle(automatic, onChangeMode),
    typeof onChangeMode === "function"
      ? (e) => {
        e.stopPropagation();
        onChangeMode(!automatic);
      }
      : null,
    automatic ? ".inverse.secondary" : ".inverse.warn",
    "solid",
    typeof onChangeMode !== "function",
  );
};

/**
 * Should handle changing the grading scheme to `newMode` in view state when the
 * grading scheme mode button is clicked on the cameo.
 *
 * @callback OnChangeModeCallback
 * @param {boolean} newMode
 */

/**
 * Cameo for grading schemes.
 *
 * If `state.selected` is true the grading scheme icon will be replaced with a green
 * checkmark, indicating the scheme has been selected (used in selection modals).
 *
 * If `state.onChangeMode` is provided the grading mode icon can be clicked to toggle the
 * grading scheme mode.
 *
 * @function cameoGradingScheme
 * @param {Object} state
 * @param {GradingScheme} gradingScheme
 * @param {boolean} [selected=false]
 * @param {OnChangeModeCallback} onChangeMode
 * @return {Cameo}
 */
export default function cameoGradingScheme({
  gradingScheme,
  selected = false,
  onChangeMode = null,
}) {
  return cameo({
    label: gradingScheme.description,
    icon: selected ? icon.solid("check-circle") : icon.solid("award"),
    controls: [
      modeControl(gradingScheme.automatic, onChangeMode),
    ],
    sel: `.cameo-grading-scheme${selected ? ".selected" : ""}`,
  });
}
