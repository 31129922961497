/**
 * A form field for usernames.
 *
 * @module ui/component/form-managed/field-username
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { usernameConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Usernames may contain only letters, numbers, and underscores, and must be between 3-255 characters.",
  required: "Please enter a username.",
  tooShort: "Usernames must be at least 3 characters.",
  taken: "This username is already in use by another account.",
});

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "username",
  autocapitalize: "off",
  autocorrect: "off",
  constraints: usernameConstraints,
  helpTexts,
  label: "Username",
  name: "username",
  sel: "",
  taken: null,
});

/**
 * A form field for usernames.
 *
 * If the `taken` property is provided with a list of username strings,
 * validation will check if the current value is in the list.
 *
 * @function username
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="username"]
 * @param {string} [label="Username"]
 * @param {?string[]} taken list of already taken usernames
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function username(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.first-name`,
  });
}
