/**
 * A placeholder element, meant to be used in dashboards temporarily while necessary data
 * is loaded.
 *
 * @module ui/component/placeholder
 * @category UI
 * @subcategory Components
 */
import div from "ui/html/div";

export default function placeholder(text = "", sel = "") {
  return div(`${sel}.placeholder`, text);
}
