/**
 * A textarea field.
 *
 * @module ui/component/form-managed/field-textarea
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import textarea from "ui/html/textarea";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Please enter valid text.",
  required: "Please enter some text.",
  taken: "This value is already in use.",
});

const defaultState = frozen({
  name: "text",
  label: "Text",
  helpTexts,
  sel: "",
  validHooks: [],
  maxlength: undefined,
  onInput: undefined,
  onChange: undefined,
  taken: null,
});

const takenHook = (taken) => (node, validity) => {
  if (!(taken instanceof Array)) return;
  if (taken?.includes(node.elm.value)) {
    /* eslint-disable no-param-reassign */
    validity.taken = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

/**
 * A generic textarea.
 *
 * @function fieldTextarea
 * @param {object} inState
 * @param {string} [inState.name="text"]
 * @param {string} [inState.label="Text"]
 * @param {?object} [inState.helpTexts]
 * @param {Selector} [inState.sel=""]
 * @param {?int} maxlength
 * @param {function} onChange called on blur when field has changed
 * @param {function} onInput called on keyup
 * @param {ValidationHook[]} [inState.validHooks=[]]
 * @return {El}
 */
export default function fieldTextarea(inState) {
  const state = merge(defaultState, inState);

  let validHooks = [];
  if (state.validHooks) validHooks = validHooks.concat(state.validHooks);
  if (state.taken) validHooks.push(takenHook(state.taken));

  return genericField(
    state,
    managedField(
      textarea(
        state.values?.[state?.name] || state.value,
        state.required,
        state.name,
        {
          props: { disabled: state.disabled },
          attrs: {
            "aria-label": state.label,
            placeholder: `${state.label}${state.required ? "*" : ""}`,
            maxlength: state.maxlength,
            title: state.label,
          },
          on: {
            change: state.onChange || undefined,
            keyup: state.onInput || undefined,
          },
        },
      ),
      state.name,
      (node) => node.elm.value,
      validHooks,
    ),
    `${state.sel}.textarea`,
  );
}
