/**
 * The manage content help section.
 *
 * @module ui/page/admin/content/help
 * @category Pages
 * @subcategory Admin - Content Management
 */
import { section, p } from "ui/html";
import collapsible from "ui/component/collapsible";

export default function helpSection() {
  return section("#help", [
    collapsible({
      title: "What does Mass Access Control do?",
    }, [
      p("Mass Access Control gives you the ability to make a piece of content available to registered or public access users. If you want to limit certain pieces of content to only registered or signed-up users, you can do this through the interface. If you want to give full viewing privileges to all users who access your organization’s SmartEdge installation, you can do this as well. All you need to do is toggle the OPEN ACCESS toggle on to give public access view rights for mass access."),
    ]),
    collapsible({
      title: "How does Mass Access Control work?",
    }, [
      p("In order to enable Mass Access Control, turn the ACCESS toggle to on/green status. The Manage Content Interface should change from content metadata to content access controls. When this interface appears, you can go in and change access control rights for different pieces of content. You can either have this publicly viewable or limited to only registered users. For content to be accessible to both public and registered users, make sure both toggles are turn on with green status. For content to be accessible to only public/open-site users, make sure the OPEN ACCESS toggle is turned on to green status and the registered toggle is turned off to red status. For content to be accessible to only registered users, make sure the OPEN ACCESS toggle is turned off to red status and the registered toggle is turned on to green status."),
    ]),
    collapsible({
      title: "What does OPEN ACCESS mean?",
    }, [
      p("OPEN ACCESS means the piece of content is visible to all users who visit your public SmartEdge site. This means that if a user goes to your website and wants to view a piece of content without registering, he/she will be able to do so."),
    ]),
    collapsible({
      title: "What does REGISTERED mean?",
    }, [
      p("REGISTERED means that the piece of content is only visible to registered/signed-up users on your SmartEdge installation. User management will need to be enabled for this to happen. Users will need to register in order to gain access to the relevant content."),
    ]),
  ]);
}
