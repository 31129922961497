/**
 * @module ui/page/admin/course/evaluation-status-table
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { div, section } from "ui/html";
import table from "ui/component/dynamic-table";
import { evaluationStatusFriendly } from "model/course/constants";
import { unique } from "util/array";
import getUsername from "ui/component/messaging/username";

const openAssessmentProfile = (assessmentId) => {
  window.open(`/admin/assessment-profile?id=${assessmentId}`, "_blank");
};

/**
 * @param {Assessment[]} assessments
 */
const getColumnLabels = (assessments) => assessments
  .map((assessment) => assessment.title) || [];

/**
 * @param {Assessment[]} assessments
 */
const getColumns = (assessments) => assessments
  .map((assessment) => assessment.id) || [];

/**
 * @param {User} user
 * @param {AnalyticsEvaluation[]} evaluationStatuses
 * @param {Function} onEvaluationClick
 */
const tableRow = (user, evaluationStatuses = [], onEvaluationClick = () => {}) => {
  const preparedTable = {
    student: getUsername(user),
  };
  const userAssessments = evaluationStatuses.filter((evalStatus) => evalStatus.userId === user.id);
  userAssessments.forEach((evalStatus) => {
    const { assessmentId } = evalStatus;
    preparedTable[assessmentId] = div(
      `.eval-status.${evalStatus.status.toLowerCase()}`,
      {
        attrs: {
          title: evaluationStatusFriendly.get(evalStatus.status),
        },
        on: {
          click: () => {
            onEvaluationClick(evalStatus);
          },
        },
      },
    );
  });
  return preparedTable;
};

/**
 * Sets up the user assessment status table.
 *
 * @function evaluationStatusTable
 * @param {AnalyticsEvaluation[]} evaluationStatuses
 * @param {Assessment[]} assessments
 * @param {User[]} users
 * @param {Function} onEvaluationClick
 * @param {string} sortColumn
 * @param {sortOrder} sortOrder
 * @param {Function} onSortChange
 * @private
 */
export default function evaluationStatusTable({
  evaluationStatuses = [],
  assessments = [],
  users = [],
  onEvaluationClick = () => {},
  sortColumn,
  sortOrder,
  onSortChange,
}) {
  const uniqueAssessments = unique(evaluationStatuses.map((s) => s.assessmentId))
    .map((id) => assessments.find((a) => a.id === id));
  const uniqueUsers = unique(evaluationStatuses.map((s) => s.userId))
    .map((id) => users.find((u) => u.id === id));
  return uniqueAssessments.length ? section("#evaluation-status-table", table({
    columnLabels: [
      "Student",
      ...getColumnLabels(uniqueAssessments),
    ],
    columns: [
      "student",
      ...getColumns(uniqueAssessments),
    ],
    rows: uniqueUsers.map((user) => tableRow(user, evaluationStatuses, onEvaluationClick)),
    sortColumn,
    sortOrder,
    onSortChange,
    onColumnNameClick: (assessmentId, index) => {
      if (index === 0) return;
      openAssessmentProfile(assessmentId);
    },
  })) : div(".user-evaluation-status-no-entries", "None Available");
}
