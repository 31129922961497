/**
 * @module ui/page/admin/evaluation/manage/common
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { sortOrders } from "ui/component/dynamic-table";
import { aclPrincipalTypes, aclRights } from "model/acl/constants";

/**
 * Makes the full name column for a user.
 * @function fullName
 * @private
 */
export const fullName = (user) => {
  const { lastName, firstName, username } = user;
  const firstNameValidated = firstName === null ? "" : firstName;
  const lastNameValidated = lastName === null ? "" : lastName;
  if (!firstNameValidated && !lastNameValidated && username) return username;
  if (!firstNameValidated && !lastNameValidated && !username) return "-";
  return `${firstNameValidated} ${lastNameValidated}`;
};

/**
 * Handles changing sort order.
 *
 * @function onSortChange
 * @private
 */
export const onSortChange = (theView) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === theView.state.sortColumn) {
    if (theView.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  theView.update({
    sortOrder,
    sortColumn,
  });
};

/**
 * Count solutions points sum
 *
 * @param {Solution[]} solutions
 * @returns {number}
 */
export const countSolutionsPoints = (solutions) => solutions.reduce((accumulator, currentValue) => {
  if (currentValue?.score?.points) {
    return accumulator + +currentValue.score.points;
  }
  return accumulator;
}, 0);

/**
 * Get the instructor for the evaluation
 *
 * @param {Array.<ACLGrantEntry>} grants
 * @returns {?User|*}
 */
export const getEvaluationInstructor = (grants) => grants
  .find((grant) => grant.rights.has(aclRights.OWNER)
    && grant.principal?.type === aclPrincipalTypes.INDIVIDUAL)?.principal?.entity;
