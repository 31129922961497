/**
 * Site UI configuration stream.
 *
 * Provides `get` interface for fetching site configuration.
 *
 * @module data/site/ui-configuration
 * @category Data Streams
 * @subcategory Site
 */
import xs from "xstream";
import { getUIConfiguration } from "api/site";
import { CONFIGURATION_KEY } from "model/site/constants";
import mono from "data/pipeline/mono";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const apiFn = () => getUIConfiguration(true);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
} = mono({
  apiFn,
  cacheKey: CONFIGURATION_KEY,
  accumulator: itemMap,
});

const out$ = xs.merge(
  getSink$,
);

all$.imitate(out$);

export default {
  get,
  all$,
};
