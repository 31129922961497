/**
 * A generic view. Can be used on its own, or augmented by other view subtypes.
 *
 * @module ui/view/view
 * @category UI
 * @subcategory Views
 */
import log from "log";
import el, { patch } from "ui/common/el";
import { div } from "ui/html";
import { frozen, merge } from "util/object";

const DEBUG = false;

/**
 * Every view accepts a state as a parameter and every component factory a view receives
 * should accept a single state object as a parameter. The default is an empty object.
 */
const defaultState = Object.freeze({});

const defaultComponentFactory = () => div(".warn", "I am a default view.");

const defaultUpdateFn = (self) => self.render();

const defaultOnStateChange = (self) => self.state;

/**
 * @callback ViewUpdateCallback
 * @param {module:ui/view/view~View} view view object which just received a state update
 */

/**
 * @typedef {object} View
 * @property {object} node the top-level snabbdom node for the view
 * @property {HTMLElement} element the HTMLElement the view is attached to
 * @property {object} state the internally managed state object
 * @property {function} patch see {@link module:ui/view/view~patch}
 * @property {function} update see {@link module:ui/view/view~update}
 * @property {function} updateState see {@link module:ui/view/view~updateState}
 * @property {function} rebind see {@link module:ui/view/view~rebind}
 */

/**
 * A factory which accepts the view as its only parameter and produces
 * a vNode tree.
 * @callback ComponentFactory
 * @param {object} self
 * @return {module:lib/ui/common/el~El}
 */

/**
 * Basic infrastructure for a view. Attached to a DOM node,
 * it coordinates updating state and re-drawing the view when
 * the state changes.
 *
 * @function view
 * @param {object} selector a css selector
 * @param {object} [initState] an aritrary state object, which will be made immutable
 * @param {ComponentFactory} [factory] produces the view's virtual DOM
 * @param {ViewUpdateCallback} [updateFn] a function to call when the state changes
 * @param {boolean} [freezeState] freeze state or not (necessary for chart view because the
 *                                lib mutates state
 *
 * @return View
 */
function view(
  selector,
  initState = defaultState,
  factory = defaultComponentFactory,
  updateFn = defaultUpdateFn,
  freezeState = true,
) {
  let state = freezeState ? frozen(merge({}, initState)) : merge({}, initState);
  let node = el.fromDOM(document.querySelector(selector));
  let onStateChange = defaultOnStateChange;

  const self = {
    get element() { return node.elm; },
    get factory() { return factory; },
    get node() { return node; },
    get selector() { return selector; },
    get state() { return state; },
    set onStateChange(fn) { onStateChange = fn; },
    /**
     *
     * Patches the view with a new snabbdom node tree.
     *
     * @function patch
     * @param {object} newNode snabbdom node
     */
    patch: (newNode) => {
      if (DEBUG) log.debug("VIEW :: patch:", newNode);
      patch(node, newNode);
      node = newNode;
    },

    /**
     * Updates the internal state using a merge strategy without updating the view.
     *
     * Note that update acts like a patch, not a replacement, so a partial state is
     * patched over the pre-existing state, generating a new internal state that is
     * the union of the old and new states. If you need to clear a state property you
     * should assign it a `null` value.
     *
     * @function updateState
     * @param {object} state new state, which is merged with the old state
     * @return {object} state the updated state
     */
    updateState: (newState) => {
      if (DEBUG) log.debug("VIEW :: updateState: before update state", state);
      state = freezeState ? frozen(merge(state, newState)) : merge(state, newState);
      if (onStateChange) {
        state = freezeState
          ? frozen(merge(state, onStateChange(self)))
          : merge(state, onStateChange(self));
      }
      if (DEBUG) log.debug("VIEW :: updateState: after update state", state);
      return state;
    },
  };

  /**
   * Produces a new virtual DOM tree and patches its owned node, using the supplied factory
   * function and current state.
   *
   * @function render
   * @return {module:ui/common/el~El}
   */
  self.render = () => {
    const newNodes = factory(self);
    try {
      self.patch(newNodes);
    } catch (e) {
      log.error("view: failed to patch vDom", newNodes, e);
    }
  };

  /**
   *
   * Updates the internal state, then calls the updateFn provided to the view factory.
   *
   * @function update
   * @param {object} state new state, which is merged with the old state
   * @param {boolean} clear if true, the page will first be patched with an empty div
   * @return {View}
   */
  self.update = (newState, clear = false) => {
    if (DEBUG) log.debug("VIEW :: update:", newState);
    if (clear) {
      state = freezeState ? frozen(merge({}, initState)) : merge({}, initState);
      self.render();
    }
    self.updateState(newState);
    updateFn(self);
    return self;
  };

  /**
   * Finds an element within the view's VDOM by query selector.
   *
   * @function qs
   * @param {CSSSelector} string
   * @return {?Element}
   */
  self.qs = (sel) => self.element.querySelector(sel);

  /**
   * Rebinds the view to a new element.
   *
   * @function rebind
   * @param {string} selector
   */
  self.rebind = (sel) => {
    const loc = document.querySelector(sel);
    loc.parentNode.replaceChild(self.element, loc);
    self.update({});
  };

  const streamReady = new Set();
  let signaledOk = false;

  const streamListeners = new Map();

  /**
   * Binds data streams to view state. Accepts a set of
   * key-stream pairs where the key corresponds to a
   * state property and the stream is consumed to update
   * the property.
   *
   * Once all streams have produced at least one value
   * `onReady` is called.
   */
  self.bindStreams = (streams, onReady = () => {}, readyWhenIn = null) => {
    const readyWhen = readyWhenIn || new Set(streams.map(([key]) => key));
    const ok = () => (/* !signaledOk && */[...readyWhen].reduce(
      (acc, cur) => acc && streamReady.has(cur), true,
    ));
    streams.forEach(([key, source$]) => {
      if (DEBUG) log.debug("VIEW :: binding stream", key);

      if (streamListeners.has(key)) {
        if (DEBUG) log.debug("VIEW :: found previous stream, unbinding", key);
        source$.removeListener(streamListeners.get(key));
        streamListeners.delete(key);
      }

      const listener = {
        next: (value) => {
          self.update({ [key]: value });
          if (signaledOk) return;
          streamReady.add(key);

          if (DEBUG) {
            log.debug(
              `VIEW :: stream ${key} received, ready: ${ok()}`,
              value,
              [...readyWhen],
              [...streamReady],
            );
          }
          if (ok()) {
            if (DEBUG) log.debug(`VIEW :: all streams ready`);
            signaledOk = true;
            onReady(self);
          }
        },
      };

      streamListeners.set(key, listener);
      source$.addListener(listener);
    });
  };

  return self;
}

/**
 * @template T
 * @typedef {function} ViewInitializer
 * @param {string} selector
 * @param {T} initState initial state for the view
 */

/**
 * Creates a view factory. Used for bootstrapping views.
 *
 * @function create
 * @param {module:ui/view/view~ComponentFactory} [componentFactory=defaultComponentFactory]
 * @param {module:ui/view/view~UpdateFn} [updateFn=defaultUpdateFn]
 * @return ViewInitializer
 */
view.create = (
  componentFactory = defaultComponentFactory,
  updateFn = defaultUpdateFn,
) => (selector, initState = defaultState) => {
  const container = document.querySelector(selector);
  if (container) {
    const self = view(selector, initState, componentFactory, updateFn);
    self.update(initState);
    return self;
  }
  throw new Error("view did not receive a selector for a valid container element");
};

export default view;
