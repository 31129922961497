/**
 * @module ui/page/admin/evaluation/manage/ungraded-table
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import a from "ui/html/a";
import section from "ui/html/section";
import table from "ui/component/dynamic-table-pageable";
import { evaluationStatusFriendly } from "model/course/constants";
import icon from "ui/component/icon";
import badge from "ui/component/badge";
import { fullName, onSortChange } from "./common";

/**
 * Sets up a user's row data for the table.
 * @function tableRow
 * @private
 */
const tableRow = (evaluation) => ({
  title: a([
    icon.solid("edit"),
    evaluation.assessment?.title,
  ], `/admin/evaluation-profile?courseId=${evaluation.course.id}&evaluationId=${evaluation.id}`),
  fullName: fullName(evaluation.student),
  status: badge(
    evaluationStatusFriendly.get(evaluation.status),
  ),
});

/**
 * Sets up the results table.
 *
 * @function showUngradedEvaluations
 * @private
 */
export default function showUngradedEvaluations(theView, onPageChange) {
  const { state } = theView;
  const { evaluationsPage, evaluationsLoading } = state;
  return section("#manage-evaluations", table({
    currentPage: evaluationsPage.currentPage,
    totalPages: evaluationsPage.totalPages,
    loading: evaluationsLoading,
    onPageChange,
    columnLabels: [
      "Assessment Name",
      "User Name",
      "Status",
    ],
    sortColumn: theView.state.sortColumn,
    sortOrder: theView.state.sortOrder,
    rows: evaluationsPage.items?.map(tableRow),
    placeholder: evaluationsLoading ? "Loading..." : "No evaluations found.",
    onSortChange: (sortColumn) => {
      onSortChange(theView)(sortColumn);
      onPageChange(evaluationsPage.currentPage);
    },
  }));
}
