/**
 * A HEADER element.
 *
 * @module ui/html/header
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 *
 * @function header
 * @param {string} sel css selectors
 * @param {mixed} ...args addtional arguments as per {@link ui/common/el}
 * @return {module:ui/common/el~El}
 */
export default (sel = '', ...args) => el(`header${sel}`, ...args);
