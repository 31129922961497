import img from "ui/html/img";
import div from "ui/html/div";
import p from "ui/html/p";
import commonHelpModal from "ui/component/modal/help/common-help";
import { modalTypes } from "ui/component/modal/help/utils";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";

export default function pwaAndroidPrompt() {
  return commonHelpModal({
    modalType: modalTypes.PWA_ANDROID,
    skipCache: true,
    async: true,
    sel: ".help-modal-pwa-android",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        lastSlideLabel: "Install",
        onLastSlideControlClick: () => getModal().resolve(true),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".img-lg", "/media/modal-help-home-slide-1.png"),
            div(".text-container", [
              p("Install our app for better experience!"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
