/**
 * Utilities for dealing with bulk user CSV files.
 *
 * @module ui/page/admin/user/bulk/csv-utils
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { frozen } from "util/object";
import { makeUserRecord } from "./user-record";

const isEmptyUser = (user) => (
  (!user.username || user.username === "" || user.username === "Unknown User")
  && (!user.firstName || user.firstName === "")
  && (!user.lastName || user.lastName === "")
  && (!user.email || user.email === "")
  && (!user.cellPhone || user.cellPhone === "")
  && (!user.password || user.password === "")
);

/* eslint-disable-next-line import/prefer-default-export */
export const csvHeaders = {
  groups: new Set([
    "groups",
    "group",
    "groupname",
    "groupnames",
    "group names",
    "group name",
  ]),
  firstName: new Set([
    "first_name",
    "firstname",
    "first name",
    "first",
    "given_name",
    "givenname",
    "given name",
    "given",
  ]),
  lastName: new Set([
    "last_name",
    "lastname",
    "last name",
    "last",
    "family_name",
    "familyname",
    "family name",
    "family",
  ]),
  username: new Set([
    "username",
    "user_name",
    "user",
    "user name",
  ]),
  email: new Set([
    "email",
    "email address",
    "email_address",
    "emailaddress",
  ]),
  cellPhone: new Set([
    "cellphone",
    "cell",
    "cell_phone",
    "cell phone",
    "cellular_phone",
    "cellular phone",
    "cellular",
    "mobile",
    "mobile_phone",
    "mobile phone",
    "telephone",
    "telephone_number",
    "telephone number",
    "telephonenumber",
    "phone",
  ]),
  password: new Set([
    "password",
  ]),
  verified: new Set([
    "verified",
  ]),
};

export const defaultRowIdentities = frozen(new Map([
  ["firstName", 0],
  ["lastName", 1],
  ["username", 2],
  ["cellPhone", 3],
  ["email", 4],
  ["password", 5],
  ["verified", 6],
  ["groups", 7],
]));

export const autodetectColumns = (data) => {
  const headers = data[0];
  const identified = new Map();
  headers.forEach((text, i) => {
    if (csvHeaders.firstName.has(text.toLowerCase())) identified.set("firstName", i);
    else if (csvHeaders.lastName.has(text.toLowerCase())) identified.set("lastName", i);
    else if (csvHeaders.username.has(text.toLowerCase())) identified.set("username", i);
    else if (csvHeaders.email.has(text.toLowerCase())) identified.set("email", i);
    else if (csvHeaders.cellPhone.has(text.toLowerCase())) identified.set("cellPhone", i);
    else if (csvHeaders.password.has(text.toLowerCase())) identified.set("password", i);
    else if (csvHeaders.verified.has(text.toLowerCase())) identified.set("verified", i);
    else if (csvHeaders.groups.has(text.toLowerCase())) identified.set("groups", i);
  });

  return identified;
};

/**
 * Splits groups string to array of group names
 *
 * @param {string} groupsString
 * @returns {string[]} Group names
 */
const parseUserGroupsString = (groupsString) => groupsString?.split(/\s*,\s*/)?.filter(Boolean);

/**
 * Given a Map of column identities, returns a function suitable for use with array.map
 * to map CSV rows to a user DTO.
 */
export const mapUserDataRow = (identified, userGroupMode) => (row) => {
  const user = {};
  if (identified.get("username") !== undefined) {
    user.username = row[identified.get("username")]?.trim?.() || null;
  } else user.username = null;
  if (identified.get("firstName") !== undefined) {
    user.firstName = row[identified.get("firstName")]?.trim?.() || null;
  } else user.firstName = null;
  if (identified.get("lastName") !== undefined) {
    user.lastName = row[identified.get("lastName")]?.trim?.() || null;
  } else user.lastName = null;
  if (identified.get("cellPhone") !== undefined) {
    user.cellPhone = row[identified.get("cellPhone")]?.trim?.() || null;
  } else user.cellPhone = null;
  if (identified.get("email") !== undefined) {
    user.email = row[identified.get("email")]?.trim?.() || null;
  } else user.email = null;
  if (identified.get("password") !== undefined) {
    user.password = row[identified.get("password")]?.trim?.() || null;
  } else user.password = null;
  if (identified.get("groups") !== undefined && userGroupMode === true) {
    user.groupNames = parseUserGroupsString(row[identified.get("groups")]?.trim?.() || "");
  } else user.groupNames = [];
  if (identified.get("verified") !== undefined) {
    user.enabled = row[identified.get("verified")]?.trim?.().toLowerCase() !== "no";
  } else user.enabled = true;

  return makeUserRecord(user);
};

/**
 * Check if the header row matches the provided template (lets us skip some other checks).
 */
export const csvHeaderCheck = (headers, userGroupMode) => {
  if (!headers[0] || !csvHeaders.firstName.has(headers[0].toLowerCase())) return false;
  if (!headers[1] || !csvHeaders.lastName.has(headers[1].toLowerCase())) return false;
  if (!headers[2] || !csvHeaders.username.has(headers[2].toLowerCase())) return false;
  if (!headers[3] || !csvHeaders.cellPhone.has(headers[3].toLowerCase())) return false;
  if (!headers[4] || !csvHeaders.email.has(headers[4].toLowerCase())) return false;
  if (!headers[5] || !csvHeaders.password.has(headers[5].toLowerCase())) return false;
  if (!headers[6] || !csvHeaders.verified.has(headers[6].toLowerCase())) return false;
  if (userGroupMode) {
    if (!headers[7] || !csvHeaders.groups.has(headers[7].toLowerCase())) return false;
  }
  return true;
};

/**
 * Given a set of parsed CSV data from papaparse, walks the user through common issues
 * and produces a set of user objects if possible from the output.
 */
export const processCSV = async (output, modalDialog, view) => {
  const { userGroupMode } = view.state;
  let detected = new Map();
  // let the user bail if the parser reported errors
  if (output?.errors?.length) {
    if (!(await modalDialog.confirm(
      `The selected file had ${output.errors.length} invalid rows.
       Would you like to proceed, discarding the invalid entries?`,
    ))) return [];
  }

  // let's see if we can figure out the CSV's format
  if (csvHeaderCheck(output.data[0], userGroupMode)) {
    // the user used the template (and hopefully filled it out right!)
    detected = defaultRowIdentities;
  } else if (output.data[0].length !== 6 || !csvHeaderCheck(output.data[0])) {
    // if the user is using his own spreadsheet we'll try our best...
    if ((await modalDialog.confirm(
      `Column Mismatch`,
      `The selected CSV contained an unexpected number of columns,
       or the columns were out of order, or the header row was missing.
       Would you like SmartEdge to attempt to detect the contents?`,
    ))) detected = autodetectColumns(output.data);
  }

  // if we couldn't find any identifiable columns we won't be able to produce anything
  if (detected.size === 0) {
    await modalDialog.alert(
      `Column Detection Failed`,
      `Unable to identify any usable columns.
       Please try using the CSV template provided below.`,
      `Ok`,
      true,
    );
    return [];
  }

  // alert the user not to expect much...
  if (!output.data || output.data.length < 2) {
    await modalDialog.alert(
      `The CSV contained no usable rows.`,
      `Please ensure the first row of the CSV
       contains labels, or try using the CSV template provided below.`,
      `Ok`,
      true,
    );
    return [];
  }

  // cross your fingers...
  let users = output.data.slice(1).map(mapUserDataRow(detected, userGroupMode));

  if (users.findIndex(isEmptyUser) < users.length - 1) {
    await modalDialog.alert(
      `Empty Rows`,
      `Some rows were empty or had no usable data. These rows will be removed.`,
    );
  }
  users = users.filter((user) => !isEmptyUser(user));

  /* eslint-disable no-param-reassign */

  // if the CSV had email addresses but not usernames we'll try to make usernames from
  // the email addresses
  if (!users.reduce((acc, cur) => acc && cur.username, true)) {
    if (await modalDialog.confirm(
      `Missing Usernames`,
      `Not all users in the CSV have assigned usernames. Would you like to generate
      usernames based on their email addresses?`,
    )) {
      users.forEach((user) => {
        if (!user?.username?.length) {
          if (user.email) [user.username] = user.email.split("@");
        }
      });
    }
  }

  // if usernames are missing and there are no email addresses, or the admin declined
  // to generate them based on email, let's try making them based on first & last name
  if (!users.reduce((acc, cur) => acc && cur.username, true)) {
    if (await modalDialog.confirm(
      `Missing Usernames`,
      `Not all users in the CSV have assigned usernames. Would you like to try generating
      usernames based on their first and last names?`,
    )) {
      users.forEach((user) => {
        if (!user?.username?.length) {
          const names = [];
          if (user.firstName) names.push(user.firstName.split(" ")[0]);
          if (user.lastName) names.push(user.lastName.split(" ")[0]);
          user.username = names.join(".");
        }
      });
    }
  }

  // if they haven't already assigned temporary passwords we'll generate some very fake
  // very lame temporary passwords
  // FIXME someday we need a real one time password system, this is silly
  if (!users.reduce((acc, cur) => acc && cur.password, true)) {
    if (await modalDialog.confirm(
      `Missing Passwords`,
      `Not all users in the CSV have passwords. Would you like to generate temporary
       passwords for them?`,
    )) {
      users.forEach((user) => {
        if (!user?.password?.length) {
          if (user.username) user.password = `${user.username}Pass123`;
        }
      });
    }
  }

  const foundEmails = new Set();
  let dupeEmails = false;
  const foundUsernames = new Set();
  let dupeUsernames = false;
  const foundCellPhones = new Set();
  let dupeCellPhones = false;

  users.forEach((user) => {
    if (user.email && foundEmails.has(user.email)) {
      dupeEmails = true;
      user.email = null;
    } else foundEmails.add(user.email);
    if (user.username && foundUsernames.has(user.username)) {
      dupeUsernames = true;
      user.username = null;
    } else foundUsernames.add(user.username);
    if (user.cellPhone && foundCellPhones.has(user.cellPhone)) {
      dupeCellPhones = true;
      user.cellPhone = null;
    } else foundCellPhones.add(user.cellPhone);
  });

  if (dupeUsernames) {
    await modalDialog.alert(
      `Some users had duplicate usernames.`,
      `The duplicate usernames have been removed from the affected records.`,
      `Ok`,
      true,
    );
  }
  if (dupeCellPhones) {
    await modalDialog.alert(
      `Some users had duplicate cell phone numbers.`,
      `The duplicate numbers have been removed from the affected records.`,
      `Ok`,
      true,
    );
  }
  if (dupeEmails) {
    await modalDialog.alert(
      `Some users had duplicate email addresses.`,
      `The duplicate addresses have been removed from the affected records.`,
      `Ok`,
      true,
    );
  }

  // passed all checks, let's move on
  return users;
};
