/**
 * Augments a basic view with some form management utilities
 * and behavior.
 *
 * @module ui/view/form-managed
 * @category UI
 * @subcategory Views
 */
import log from "log";
import baseView from "ui/view/view";
import el from "ui/common/el";
import { merge } from "util/object";
import { debounce } from "util/event";
import { getFormData, getFormValues, getValidity } from "./util";

const DEBUG = false;

/**
 * A managed form view. Borrows from the generic view, and augments with additional
 * properties and methods related to managed forms.
 *
 * @typedef {object} ManagedFormView
 * @borrows {module:ui/view/view~View}
 * @property {object} values getter for the form's values as an
 *                    object of `{ name: value, ...}`
 * @property {FormData} formData getter for the form's FormData
 * @property {function} validate see {@link module:ui/view/form-managed~validate}
 * @property {function} bind see {@link module:ui/view/form-managed~bind}
 * @property {function} setFullValidation see {@link module:ui/view/form-managed~setFullValidation}
 */

function bootstrapForm(self) {
  /* eslint-disable object-shorthand */
  /* eslint-disable no-param-reassign */
  let disabled = false;
  let validateRequired = false;
  let dataStale = true;
  let currentData = new FormData();
  let valuesStale = true;
  let currentValues = {};

  const onChangeHandler = (ev) => {
    ev.stopPropagation();
    currentValues = getFormValues(self.node);
    valuesStale = false;
    dataStale = true;
    if (DEBUG) log.debug("MANAGEDFORMVIEW :: onchange");
    self.update({
      validation: self.validate(),
      dirty: true,
    });
  };

  const onKeyupHandler = debounce(onChangeHandler, 1000);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (DEBUG) log.debug("MANAGEDFORMVIEW :: onsubmit");
    self.updateState({
      validation: self.validate(),
    });
  };

  const bootstrapEventBindings = (node) => {
    const bindListeners = (n) => {
      if (DEBUG) log.debug("MANAGEDFORMVIEW :: rebinding listeners", n.elm);
      n.elm.removeEventListener("change", onChangeHandler);
      n.elm.removeEventListener("keyup", onKeyupHandler);
      n.elm.removeEventListener("em:form-managed-change", onChangeHandler);
      n.elm.removeEventListener("submit", onSubmitHandler);
      n.elm.addEventListener("change", onChangeHandler);
      n.elm.addEventListener("keyup", onKeyupHandler);
      n.elm.addEventListener("em:form-managed-change", onChangeHandler);
      n.elm.addEventListener("submit", onSubmitHandler);
    };

    node.data.hook = node.data.hook || {};
    node.data.hook.insert = bindListeners;
    node.data.hook.update = (_, cur) => bindListeners(cur);
    node.data.hook.postpatch = () => {
      currentValues = getFormValues(self.node);
      valuesStale = false;
      dataStale = true;
    };
    const bootstrapped = el(node.sel, node.data, node.children);
    return bootstrapped;
  };

  self.patch(bootstrapEventBindings(self.node));

  /**
   * Validate the form and return a validation object.
   *
   * @function validate
   * @param {boolean} [checkRequired=false] if false, empty required fields will be ignored
   * @return {object}
   */
  self.validate = function validate(checkRequired) {
    return getValidity(self.node, checkRequired === undefined ? validateRequired : checkRequired);
  };

  /**
   * Sets whether validation will check whether a field is required and empty.
   *
   * This provides a default value for the `checkRequired` parameter of formManagedView.validate.
   *
   * @function setFullValidation
   * @param {boolean} enabled
   */
  self.setFullValidation = (enabled) => {
    validateRequired = !!enabled;
    self.render();
  };

  const origPatch = self.patch;
  self.patch = (newNode) => {
    origPatch(bootstrapEventBindings(newNode));
  };

  /**
   * Binds the form view to a set of elements.
   *
   * This is a convenience method for setting up common parameters of form fields.
   *
   * Elements may be either an array of `[fieldFactory, config, ...params]` or any
   *
   * In the array form the first parameter is any component factory which accepts an
   * object as its first parameter. The config object will be patched with the form's
   * values, disabled, and validation state, and any additional parameters will be passed
   * through to the factory.
   *
   * If any other entry is passed in `elements` it will be uncritically passed through
   * to the results. Since these results will generally be consumed as vDOM nodes they
   * should conform to the `ChildEl` type.
   *
   * @Example
   * // self here is a form-managed view
   * const formFactory = (self) => {
   *   const user = await api.user.getMe();
   *   const allUsers = await api.user.list():
   *   const takenUsers = allUsers
   *     .map((u) => u.username)
   *     .filter((name) => name !== user.username);
   *   const takenEmails = allUsers
   *     .map((u) => u.email)
   *     .filter((email) => email !== user.email);
   *
   *   return form("#my-form", self.bind([
   *     // assume *Input are conformant form fields
   *     [usernameInput, { taken: takenUsers, required: true }],
   *     [firstNameInput],
   *     [lastNameInput],
   *     [emailInput, { taken: takenEmails, required: true }],
   *     [passwordConfirmedPair],
   *   ], user);
   * };
   *
   * @function bind
   * @param {field[]} fields
   * @param {object} initialValues used when self.values is empty
   * @return {ChildEl}
   */
  self.bind = (fields, initialValues) => {
    let values = currentValues;
    if (Object.keys(values)?.length === 0) {
      // mark values stale when form is initialized
      // otherwise if no changes to the form are made they'll never
      // be updated
      valuesStale = true;
      dataStale = true;
      values = initialValues;
    }
    const validity = self.validate();

    return fields.map((field) => {
      if (!(field instanceof Array)) return field;
      const [factory, inState, ...params] = field;
      // pass on anything that isn't a field array
      if (typeof factory !== "function") return factory;
      let state;
      // FIXME if inState wasn't an object something is probably wrong but for
      // compatibility we'll just pass it on unexamined as a parameter for now
      if (typeof inState === "object") state = merge(inState, { values, validity });
      else if (!inState) state = { values, validity };
      else state = inState;
      // override disabled state only if the whole form is disabled
      if (disabled) state.disabled = true;
      return factory(state, ...params);
    });
  };

  self.disable = () => {
    disabled = true;
    self.render();
  };

  self.enable = () => {
    disabled = false;
    self.render();
  };

  Object.defineProperties(self, {
    formData: {
      get: function () {
        if (dataStale) {
          currentData = getFormData(self.node);
          dataStale = false;
        }
        return currentData;
      },
      enumerable: true,
    },
    values: {
      get: function () {
        if (valuesStale) {
          currentValues = getFormValues(self.node);
          valuesStale = false;
        }
        return currentValues;
      },
      enumerable: true,
    },
    disabled: {
      get: () => disabled,
    },
    validate: {
      enumerable: true,
      configurable: false,
    },
  });
  return self;
  /* eslint-enable no-param-reassign */
  /* eslint-enable object-shorthand */
}

/**
 * Creates a form view initializer.
 *
 * Note that the created initializer expects a selector for an `HTMLFormElement`.
 *
 * @function managedFormView
 * @param {module:ui/view/view~ComponentFactory} [componentFactory=defaultComponentFactory]
 * @param {module:ui/view/view~UpdateFn} [updateFn=defaultUpdateFn]
 * @return {module:ui/view/view~ViewInitializer}
 */
export default (componentFactory, updateFn) => (selector, initState) => {
  const container = document.querySelector(selector);
  if (container && container instanceof HTMLFormElement) {
    const theView = bootstrapForm(baseView(selector, initState, componentFactory, updateFn));
    theView.update(initState);
    return theView;
  }
  throw new Error("managed form view did not receive a selector for a valid form element");
};
