import { div } from "ui/html";

import aclDialogs from "./acl";
import alertDialogs from "./alert";
import assessmentDialogs from "./assessment";
import confirmDialogs from "./confirm";
import courseDialogs from "./course";
import evaluationDialogs from "./evaluation";
import labelDialogs from "./label";
import miscDialogs from "./misc";
import previewDialogs from "./preview";
import searchDialogs from "./search";
import sectionEditors from "./page-sections";
import selectionDialogs from "./selection";
import helpDialogs from "./help";
import chatDialogs from "./chat";
import loginDialogs from "./login";

export default (modalView, notificationView) => () => div(
  "#modal-dialogs",
  [
    alertDialogs(modalView),
    confirmDialogs(modalView),
    selectionDialogs(modalView),
    previewDialogs(modalView),
    searchDialogs(modalView),
    labelDialogs(modalView),
    aclDialogs(modalView, notificationView),
    sectionEditors(modalView, notificationView),
    courseDialogs(modalView, notificationView),
    assessmentDialogs(modalView),
    evaluationDialogs(modalView),
    miscDialogs(modalView),
    chatDialogs(),
    helpDialogs(),
    loginDialogs(),
  ],
);
