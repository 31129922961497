/**
 * A modal for selecting multiple groups.
 */
import { groupSortCriteria, systemPrivilegeGroupsFriendly } from "model/acl/constants";
import listSelectModal from "ui/component/modal/list-select";
import alert from "ui/component/modal/alert";
import userGroupName from "ui/component/user-group-name";
import { groupSort } from "util/sort";

/**
 * @function groupSelectMulti
 * @param {object} state
 * @param {Array.<ACLUserGroup>} groups all availalbe groups
 * @param {Array.<ACLUserGroup>} selectedGroups pre-selected groups
 * @param {ModalView} modalDialog
 */
export default function groupSelectMulti({
  groups = [],
  selectedGroups = [],
}, modalDialog) {
  if (groups.length) {
    return listSelectModal({
      entries: groups
        .sort(groupSort(groupSortCriteria.NAME_ASC))
        .map((group) => ({
          ...group,
          label: userGroupName(group),
          // for searching
          friendlyName: systemPrivilegeGroupsFriendly.get(group.name),
        })),
      multiSelect: true,
      selectedEntries: selectedGroups,
    }, modalDialog);
  }
  return alert({ title: "No groups available.", onConfirm: () => modalDialog.resolve(null) });
}
