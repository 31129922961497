/**
 * A cameo for assessment.
 *
 * @module ui/component/cameo-assessment
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";

const open = (assessment) => window.open(
  `/admin/assessment-profile?id=${assessment?.id || assessment}`,
  "_blank",
);

/**
 * Cameo element for assessment
 *
 * @param {Assessment} assessment
 * @param {Function} onRemove
 * @returns {*|El}
 */
export default function cameoAssessment({
  assessment,
  onRemove = null,
  showLink = true,
  config = {},
}) {
  return cameo({
    label: typeof assessment === "object" ? assessment.title : assessment,
    icon: icon.solid("medal"),
    controls: [
      showLink ? button.accent({
        icon: "external-link",
        iconOnly: true,
        onClick: () => open(assessment),
      }) : "",
      onRemove ? button.warning({
        icon: "trash-alt",
        iconOnly: true,
        onClick: () => onRemove(assessment),
      }) : "",
    ],
    sel: ".cameo-assessment",
    config,
  });
}
