/**
 * A form field for search tokens.
 *
 * @module ui/component/form-managed/field-search-terms
 * @category UI
 * @subcategory Forms
 */
import inputField from "ui/component/form-managed/field-input";
import helpModal from "ui/component/modal/help/page-section-search-terms";
import { frozen, merge } from "util/object";
import { getModal } from "ui/view/modal-view";

/**
 * @private
 */
const defaultState = frozen({
  label: "Search Terms",
  name: "terms",
  onChange: null,
  onInput: null,
  required: false,
  sel: ".search-terms",
  value: [],
  listId: null,
  filterIncomingValue: (value) => value?.join?.(" ") || "",
  filterOutgoingValue: (value) => value?.split?.(" ") || [],
  onHelp: async () => getModal().async(helpModal()),
});

/**
 * A form field for search tokens.
 *
 * Splits the text entered in the field to an array of tokens in the form values
 * collection, and accepts an array of tokens as its value.
 *
 * @function fieldSearchTerms
 * @param {object} state
 * @param {string} [state.label="Search Terms"]
 * @param {FieldValidity} [state.validity=defaultValidity]
 * @param {string} [state.name="terms"]
 * @param {boolean} [state.required=false]
 * @param {Selector} [state.sel=""]
 * @param {string[]} [state.value=[]]
 * @param {string} [state.override=""] override field value with given state.value
 * @param {function} onChange called on blur when field has changed
 * @param {function} onInput called on keyup
 * @return {module:ui/common/el~El}
 */
export default function fieldSearchTerms(inState = {}) {
  const state = merge(defaultState, inState);
  state.value = state.override
    ? state.value || state.values?.[state.name] || defaultState.value
    : state.values?.[state.name] || state.value || defaultState.value;
  state.sel = `${inState.sel || ""}${defaultState.sel}`;

  if (!(state.value instanceof Array)) throw new Error("fieldSearchTerms: value must be an array");

  return inputField(state);
}
