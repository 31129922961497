/**
 * The main management page for content (metadata).
 *
 * Corresponds to `markup/admin/manage-content.html`
 *
 * @module ui/page/admin/content/manage
 * @category Pages
 * @subcategory Admin - Content Management
 */
import api from "api";
import {
  metadataSortCriteria,
  metadataTypes,
  metadataTypesFriendly,
} from "model/metadata/constants";
import getConfig from "config";
import { a, div, section, span } from "ui/html";
import table, { sortOrders } from "ui/component/dynamic-table";
import advancedContentSearch from "ui/component/modal/advanced-content-search";
import contentModal from "ui/component/modal/content";
import icon from "ui/component/icon";
import actionBar from "ui/component/dashboard-action-bar";
import widget from "ui/component/dashboard-widget";
import placeholder from "ui/component/placeholder";
import actionBarSearch from "ui/component/action-bar-search";
import metadataIcon from "ui/component/metadata-icon";
import view from "ui/view";
import { metadataAdvancedFilter, metadataTextFilter } from "util/filter";
import { merge } from "util/object";
import { metadataSort } from "util/sort";
import dashboardLayout from "ui/page/layout/dashboard";
import button from "ui/component/form-managed/button";
import { externalURLs } from "api/constants";
import toggle from "ui/component/form-managed/field-toggle";
import { getMetadataAccessMap, hasChangedMediaGrants } from "api/acl";
// import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import { notificationMessageTypes } from "model/notification/constants";
import { featureEnabled } from "util/feature-flag";
import { featureTypes } from "model/config/constants";
import {
  defaultManageState as defaultState,
  defaultSearchParams, saveStates,
  searchModes,
  viewModes,
} from "./state";
import helpSection from "./help";

let manageContentView, modalView, searchView;

/**
 * Clears search results.
 * @function clearSearchResult
 * @private
 */
const clearSearchResult = () => {
  manageContentView.update({
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  });
};

/**
 * Updates the search params with the contents of the basic search filter.
 *
 * @function doFilter
 * @private
 */
const doFilter = (filter) => {
  manageContentView.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  });
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function showAdvancedSearchModal
 * @private
 */
const showAdvancedSearchModal = async () => {
  clearSearchResult();
  const labels = new Set([]);
  manageContentView.state.content.forEach((entry) => {
    entry.categories.forEach((l) => labels.add(l));
  });

  const searchParams = await modalView.async(
    advancedContentSearch({
      async: true,
      labels: [...labels],
      params: manageContentView.state.searchParams,
    }, modalView),
  );
  if (searchParams) {
    manageContentView.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
  }
};

/**
 * Figures out which metadataSortCriteria to used based on sortColumn and sortOrder
 * state properties.
 * @function getSortCriteria
 * @private
 */
const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "id":
      return (sortOrder === sortOrders.ASC
        ? metadataSortCriteria.ID_ASC
        : metadataSortCriteria.ID_DESC);
    case "type":
      return (sortOrder === sortOrders.ASC
        ? metadataSortCriteria.TYPE_ASC
        : metadataSortCriteria.TYPE_DESC);
    case "title":
      return (sortOrder === sortOrders.ASC
        ? metadataSortCriteria.TITLE_ASC
        : metadataSortCriteria.TITLE_DESC);
    case "created":
    default:
      return (sortOrder === sortOrders.ASC
        ? metadataSortCriteria.DATE_ASC
        : metadataSortCriteria.DATE_DESC);
  }
};

/**
 * Determine status icon.
 */
const rowIcon = (metadata, status) => {
  switch (status) {
    case saveStates.FAILURE:
      return span(
        icon.solid("exclamation-triangle"),
        ".status.error",
        { props: { title: "upload failed" } },
      );
    case saveStates.NEED_SAVE:
      return span(
        icon.solid("upload"),
        ".status.warning",
        { props: { title: "not saved" } },
      );
    case saveStates.SAVING:
      return span(
        icon.solid("circle-notch"),
        ".status.saving",
        { props: { title: "saving..." } },
      );
    case saveStates.SUCCESS:
    default:
      return span(
        icon.solid("upload"),
        ".status.ok",
        { props: { title: "upload successful" } },
      );
  }
};

const getGrantUpdateStatus = (metadata, initialMetadataAccessMap, metadataAccessMap) => {
  const currentAccess = metadataAccessMap.get(metadata.id);
  const initialAccess = initialMetadataAccessMap.get(metadata.id);
  if (currentAccess.publicAccess !== initialAccess.publicAccess
    || currentAccess.registeredUsers !== initialAccess.registeredUsers) {
    const { saveGrantsInProgress } = manageContentView.state;
    return saveGrantsInProgress ? saveStates.SAVING : saveStates.NEED_SAVE;
  }
  return saveStates.SUCCESS;
};

const tableRowVisibilityMode = (metadata, metadataAccess) => ({
  type: metadataIcon(metadata.type),
  title: a([
    icon.solid("eye"),
    metadata.title,
  ], `/admin/content-profile?type=${metadata.type}&id=${metadata.id}`),
  publicAccess: toggle({
    displayLabel: false,
    toggled: metadataAccess.publicAccess,
    onToggle: (toggled) => {
      const { metadataAccessMap } = manageContentView.state;
      metadataAccessMap.set(metadata.id, {
        ...metadataAccessMap.get(metadata.id),
        publicAccess: toggled,
      });
      manageContentView.update({});
    },
  }),
  registered: toggle({
    displayLabel: false,
    toggled: metadataAccess.registeredUsers,
    onToggle: (toggled) => {
      const { metadataAccessMap } = manageContentView.state;
      metadataAccessMap.set(metadata.id, {
        ...metadataAccessMap.get(metadata.id),
        registeredUsers: toggled,
      });
      manageContentView.update({});
    },
  }),
  status: rowIcon(
    metadata,
    getGrantUpdateStatus(
      metadata,
      manageContentView.state.initialMetadataAccessMap,
      manageContentView.state.metadataAccessMap,
    ),
  ),
});

/**
 * Creates row objects for dynamicTable out of metadata.
 * @function tableRow
 * @private
 */
const tableRow = (metadata) => ({
  created: span(metadata.createdAt.toLocaleDateString()),
  id: a([
    icon.solid("edit"),
    metadata.id,
  ], `/admin/content-profile?type=${metadata.type}&id=${metadata.id}`),
  title: a.fn([
    icon.solid("eye"),
    metadata.title,
  ], () => modalView.async(contentModal({ metadata, sameTab: false }, modalView))),
  type: span([metadataIcon(metadata.type), metadataTypesFriendly.get(metadata.type)]),
});

/**
 * Handles changing sort order.
 * @function onSortChange
 * @private
 */
const onSortChange = (theView) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === theView.state.sortColumn) {
    if (theView.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else if (sortColumn === "created") {
    sortOrder = sortOrders.DESC;
  } else sortOrder = sortOrders.ASC;
  theView.update({
    sortOrder,
    sortColumn,
  });
};

/**
 * Sets up the results table.
 *
 * @function showManageContent
 * @private
 */
const showManageContent = (theView) => {
  const { viewMode, metadataAccessMap } = theView.state;
  if (viewMode === viewModes.HELP) {
    return helpSection();
  }
  if (viewMode === viewModes.DEFAULT) {
    return section("#manage-content", table({
      columnLabels: ["Created", "ContentId", "Title", "Type"],
      columns: ["created", "id", "title", "type"],
      sortColumn: theView.state.sortColumn,
      sortOrder: theView.state.sortOrder,
      rows: theView.state.filteredContent.map(tableRow),
      placeholder: "No content found.",
      onSortChange: onSortChange(theView),
    }));
  }
  return section("#manage-content.view-mode-visibility", table({
    columnLabels: featureEnabled(featureTypes.OPEN_SITE_ACCESS) ? ["Type", "Title", "Public Access", "Registered", "Status"] : ["Type", "Title", "Registered", "Status"],
    columns: featureEnabled(featureTypes.OPEN_SITE_ACCESS) ? ["type", "title", "publicAccess", "registered", "status"] : ["type", "title", "registered", "status"],
    sortColumn: theView.state.sortColumn,
    sortOrder: theView.state.sortOrder,
    rows: theView.state.filteredContent.map(
      (content) => tableRowVisibilityMode(content, metadataAccessMap.get(content.id)),
    ),
    placeholder: "No content found.",
    onSortChange: onSortChange(theView),
  }));
};

const saveGrants = async () => {
  const { initialMetadataAccessMap, metadataAccessMap, content } = manageContentView.state;
  manageContentView.update({
    saveGrantsInProgress: true,
  });
  await api.acl.updateMediaGrantsBatch(initialMetadataAccessMap, metadataAccessMap);
  const updatedMetadataAccessMap = await getMetadataAccessMap(content);
  manageContentView.update({
    saveGrantsInProgress: false,
    initialMetadataAccessMap: new Map(metadataAccessMap),
    metadataAccessMap: updatedMetadataAccessMap,
  });
  getNotification().post({
    title: "Content rows are updated",
    type: notificationMessageTypes.BASIC,
  });
};

/**
 * Sets up the search action bar.
 * @function showSearch
 * @private
 */
const showSearch = (theView) => {
  const { viewMode } = theView.state;
  return actionBarSearch({
    resultCount: theView.state.filteredContent.length,
    filter: theView.state.searchParams.filter,
    showClear: theView.state.searchMode !== searchModes.NONE,
    onSearch: (filterText) => doFilter(filterText),
    onAdvancedSearch: showAdvancedSearchModal,
    onClear: clearSearchResult,
    title: "All Content",
    additionalControls: [
      button.alternate({
        icon: viewMode === viewModes.HELP ? "table" : "question-circle",
        label: viewMode === viewModes.HELP ? "Manage" : "Help",
        onClick: () => {
          if (viewMode === viewModes.HELP) {
            manageContentView.update({ viewMode: viewModes.DEFAULT });
          } else {
            manageContentView.update({ viewMode: viewModes.HELP });
          }
        },
        ariaLabel: "Show Help",
      }),
      ...(manageContentView.state.viewMode === viewModes.DEFAULT
      || manageContentView.state.viewMode === viewModes.HELP
        ? [button.accent({
          icon: "folder-upload",
          label: "Files",
          onClick: () => window.open(externalURLs.fileManager(theView.state.config.server), "_blank"),
        })]
        : [
          button.warning({
            label: "Revert",
            disabled: !hasChangedMediaGrants(
              manageContentView.state.initialMetadataAccessMap,
              manageContentView.state.metadataAccessMap,
            ),
            onClick: () => {
              manageContentView.update({
                metadataAccessMap: new Map(manageContentView.state.initialMetadataAccessMap),
              });
            },
          }),
          button.primary({
            icon: "save",
            label: "Save",
            disabled: !hasChangedMediaGrants(
              manageContentView.state.initialMetadataAccessMap,
              manageContentView.state.metadataAccessMap,
            ),
            onClick: saveGrants,
          }),
        ]),
      /* disabled until API issues are fixed
      toggle.boxed.inverse({
        label: "Access",
        disabledAutoToggle: true,
        disabled: manageContentView.state.initialMetadataAccessMap.size <= 0,
        toggled: viewMode === viewModes.VISIBILITY,
        onToggle: async (toggled) => {
          const { initialMetadataAccessMap, metadataAccessMap } = manageContentView.state;
          if (hasChangedMediaGrants(initialMetadataAccessMap, metadataAccessMap)
            && viewMode === viewModes.VISIBILITY) {
            theView.update({
              viewMode: viewModes.VISIBILITY,
            });
            const discard = await getModal().confirm(
              "You have unsaved changes",
              "Are you sure you want to discard them?",
              "Yes",
              "No",
              false,
              false,
              true,
            );
            if (discard) {
              manageContentView.update({
                viewMode: viewModes.DEFAULT,
                metadataAccessMap: new Map(initialMetadataAccessMap),
              });
              return;
            }
            return;
          }
          manageContentView.update({
            viewMode: toggled ? viewModes.VISIBILITY : viewModes.DEFAULT,
          });
        },
      }),
      */
    ],
  });
};

const doUpdate = (self) => {
  const {
    searchMode,
    content,
    sortColumn,
    sortOrder,
    searchParams,
  } = self.state;
  let filteredContent;
  switch (searchMode) {
    case searchModes.BASIC:
      filteredContent = content.filter(metadataTextFilter(searchParams.filter));
      break;
    case searchModes.ADVANCED:
      filteredContent = content.filter(metadataAdvancedFilter(searchParams));
      break;
    case searchModes.NONE:
    default:
      filteredContent = [...content];
  }
  filteredContent.sort(metadataSort(getSortCriteria(sortColumn, sortOrder)));

  self.updateState({ filteredContent });
  self.render();
  if (searchView) searchView.update(self.state);
};

/**
 * The manage content page shows a searchable, sortable table of all content items
 * in SmartEdge.
 *
 * @function manageContent
 * @param {module:ui/html~Selector} selector root element for the manage content view
 */
export default async function manageContent(selector) {
  const title = "Manage Content";
  const { modal, loading, header } = dashboardLayout(
    selector,
    [
      actionBar(div("#search")),
      widget(placeholder("Loading...", "#manage-content")),
    ],
    title,
    true,
  );
  modalView = modal;
  loading.show();
  const [user, config, content, labels] = await Promise.all([
    api.user.getMe(),
    getConfig(),
    api.metadata.list(),
    api.metadata.listCategories(),
  ]);

  const state = merge(
    defaultState,
    {
      user,
      config,
      content,
      filteredContent: content,
      labels,
      types: metadataTypes,
      title,
    },
  );
  header.update(state);
  manageContentView = view.create(showManageContent, doUpdate)(
    '#manage-content',
    state,
  );

  /*
   * disabled until API issues are fixed
  const accessMap = xs.fromPromise(api.acl.getMetadataAccessMap(content))
    .map((array) => new Map(array));

  manageContentView.bindStreams([
    ["initialMetadataAccessMap", accessMap],
    ["metadataAccessMap", accessMap],
  ]);
  */

  searchView = view.create(showSearch)("#search", state);
  loading.hide();
}
