/**
 * A collection of utility functions for the content management pages.
 *
 * @module ui/page/admin/content/admin
 * @category Pages
 * @subcategory Content Management
 */
import api from "api";
import {
  documentMimeTypes,
  fileTypes,
  imageMimeTypes,
  videoMimeTypes,
} from "model/file-descriptor/constants";
import { metadataTypes } from "model/metadata/constants";
import { filePickerModes } from "ui/component/form-managed/file-picker";
import labelSelect from "ui/component/modal/label-select";
import { difference } from "util/array";
import { getFileStorageRemaining, normalizeFileName } from "util/file";

/**
 * Check if document mime type is supported
 *
 * @private
 * @param {string} mimeType
 * @returns {boolean}
 */
const isSupportedFileMimeType = (mimeType) => documentMimeTypes.includes(mimeType);

/**
 * Check if image mime type is supported
 *
 * @private
 * @param {string} mimeType
 * @returns {boolean}
 */
const isSupportedImageMimeType = (mimeType) => imageMimeTypes.includes(mimeType);

/**
 * Check if video mime type is supported
 *
 * @private
 * @param {string} mimeType
 * @returns {boolean}
 */
const isSupportedVideoMimeType = (mimeType) => videoMimeTypes.includes(mimeType);

/**
 * Updates a form view with an invalidity message.
 *
 * @function genericInvalidUpdate
 * @private
 */
const genericInvalidUpdate = (formView, step) => {
  formView.update({
    invalidSteps: [step],
    step,
  });
};

const checkFilePicker = (type, value, validity) => {
  if (!value) return false;
  if (validity.valid === false) return false;
  let mimeCb;
  switch (type) {
    case metadataTypes.VIDEO:
      mimeCb = isSupportedVideoMimeType;
      break;
    case metadataTypes.DOCUMENT:
      mimeCb = isSupportedFileMimeType;
      break;
    case metadataTypes.IMAGE:
      mimeCb = isSupportedImageMimeType;
      break;
    default:
      throw new Error("unsupported metadata type", type);
  }
  switch (value.mode) {
    case filePickerModes.UPLOAD:
      if (value.mode === filePickerModes.UPLOAD) {
        if (!value.upload) return false;
        if (!mimeCb(value.upload.type)) return false;
      }
      break;
    case filePickerModes.DESCRIPTOR:
      if (!value.descriptor) return false;
      break;
    case filePickerModes.URL:
      if (!value.url) return false;
      break;
    case filePickerModes.FILE:
      if (!value.file) return false;
      break;
    // keep mode will be invalid if there was no descriptor to keep
    case filePickerModes.KEEP:
    default:
      return true;
  }
  return true;
};

export const checkFileCapacity = (formView) => {
  const { values } = formView;
  const remaining = getFileStorageRemaining();
  const videoFileSize = values.videoFile?.upload?.size || 0;
  const posterFileSize = values.posterFile?.upload?.size || 0;
  const documentFileSize = values.documentFile?.upload?.size || 0;
  const imageFileSize = values.imageFile?.upload?.size || 0;
  const sum = videoFileSize + posterFileSize + documentFileSize + imageFileSize;
  return remaining > sum;
};

/**
 * Validates a file from a
 * [file picker]{@link module:ui/component/form-managed/file-picker}.
 *
 * @function checkContentFiles
 * @param {module:ui/view/form~formView} formView
 * @param {number} fileStep a [step]{@module:ui/page/admin/add-content~step}
 *                          to return to on failure
 * @param {number} posterStep a [step]{@module:ui/page/admin/add-content~step}
 *                            to continue to on success
 * @return {boolean} true if files are ok
 */
export const checkContentFiles = (formView, fileStep, posterStep) => {
  const { values } = formView;
  const validity = formView.validate();
  const invalidFile = () => genericInvalidUpdate(formView, fileStep);
  switch (values.type) {
    case metadataTypes.VIDEO:
      if (!checkFilePicker(values.type, values.videoFile, validity.fields.videoFile)) {
        invalidFile();
        return false;
      }
      break;
    case metadataTypes.DOCUMENT:
      if (!checkFilePicker(values.type, values.documentFile, validity.fields.documentFile)) {
        invalidFile();
        return false;
      }
      break;
    case metadataTypes.IMAGE:
      if (!checkFilePicker(values.type, values.imageFile, validity.fields.imageFile)) {
        invalidFile();
        return false;
      }
      break;
    default:
      break;
  }
  if (!checkFilePicker(metadataTypes.IMAGE, values.posterFile, validity.fields.posterFile)) {
    genericInvalidUpdate(formView, posterStep);
    return false;
  }
  return true;
};

/**
 * Given a file picker state, figures out what action needs to be taken to produce
 * a file descriptor and does so.
 *
 * @param {FilePickerState} pickerState
 * @param {fileTypes} fileType
 * @param {LoadingView} loadingView
 * @return {FileDescriptor}
 */
export const handleFilePicker = async (pickerState, fileType) => {
  // TODO derive filetype here instead of expecting it as a parameter
  //      (how ?)

  // allow for dumbfire of this function
  if (!pickerState) {
    return undefined;
  }

  let file, fileName, data;
  switch (pickerState.mode) {
    case filePickerModes.URL:
      // create a file descriptor from a URL
      // backend accepts the URL as the 'fileName' parameter
      return api.file.create({
        name: pickerState.url,
        type: fileType,
      });
    case filePickerModes.UPLOAD:
      // we're uploading a new file
      file = pickerState.upload;
      data = new FormData();
      fileName = normalizeFileName(file.name);
      data.append("file", file, fileName);
      // file upload returns a descriptor when it's done
      return api.file.uploadFile(fileName, { data });
    case filePickerModes.DESCRIPTOR:
      // we're selecting a pre-existing descriptor, so we can just bail here
      return pickerState.descriptor;
    case filePickerModes.FILE:
      // in this case we have a file in media dump and just need to create
      // a new descriptor for it
      return api.file.create({
        name: pickerState.file,
        type: fileType,
      });
    case filePickerModes.KEEP:
    default:
      // we don't need to do anything and can just return the pre-existing
      // descriptor
      return pickerState.current;
  }
};

/**
 * FilePicker has a ton of form fields. This automates a few repetitive lines
 * of config initialization.
 *
 * @function filePickerConfig
 * @param {string} name name of the file picker input
 * @param {string} state formView state
 * @param {boolean} [disabled=false]
 * @param {?function} onChange
 * @return {object} file picker config
 */
export const filePickerConfig = (name, formView, disabled = false, onChange = null) => {
  // don't want to take all the picker's values because other configs
  // should not be overridden
  const config = ({
    name,
    uploadFileNames: formView.state.files,
    fileDescriptors: formView.state.descriptors,
    required: true,
    disabled,
    onChange,
  });
  return config;
};

/**
 * Checks if any of the file pickers have changed state from the previously
 * existing file.
 *
 * @function isFileChanged
 * @param {object} pickerStates
 * @param {object} pickerStates.posterFile
 * @param {object} [pickerStates.videoFile]
 * @param {object} [pickerStates.documentFile]
 * @return boolean
 */
export const isFileChanged = (pickerStates) => (
  pickerStates.posterFile.descriptor
  || pickerStates.posterFile.file
  || pickerStates.posterFile.upload
  || pickerStates.videoFile?.descriptor
  || pickerStates.videoFile?.file
  || pickerStates.videoFile?.upload
  || pickerStates.documentFile?.descriptor
  || pickerStates.documentFile?.file
  || pickerStates.documentFile?.upload
);

/**
 * Checks if the user has selected a file for a given picker.
 * @function isFileSelected
 * @param {object} pickerState
 * @return boolean
 */
export const isFileSelected = (pickerState) => pickerState && (
  (pickerState?.current && pickerState.mode === filePickerModes.KEEP)
  || (pickerState?.file && pickerState.mode === filePickerModes.FILE)
  || (pickerState?.descriptor && pickerState.mode === filePickerModes.DESCRIPTOR)
  || (pickerState?.upload && pickerState.mode === filePickerModes.UPLOAD)
  || (pickerState?.url && pickerState.mode === filePickerModes.URL)
);

/**
  * Handles all file pickers for a content item, and manages the loading view
  * while files are being uploaded/downloaded, as needed.
  *
  * Returns a dictionary of file descriptors, containing descriptors resulting from
  * the user's choices in the file pickers:
  *
  * ```js
  * {
  *   videoFile: ?FileDescriptor,
  *   documentFile: ?FileDescriptor,
  *   posterFile: ?FileDescriptor,
  *   logoFile: ?FileDescriptor,
  *   iconsFile: ?FileDescriptor,
  * }
  * ```
  *
  * @function handleFilePickers
  * @modifies loadingView
  * @param {object} pickers
  * @param {?object} pickers.posterFile
  * @param {?object} pickers.videoFile
  * @param {?object} pickers.documentFile
  * @return {Promise<object>}
  */
export const handleFilePickers = async (pickers, loadingView) => {
  const data = {};
  const filesTodo = new Set();

  [
    { picker: pickers.posterFile, type: fileTypes.IMAGE, name: "posterFile" },
    { picker: pickers.documentFile, type: fileTypes.DOCUMENT, name: "documentFile" },
    { picker: pickers.videoFile, type: fileTypes.VIDEO, name: "videoFile" },
    { picker: pickers.logoFile, type: fileTypes.IMAGE, name: "logoFile" },
    { picker: pickers.iconsFile, type: fileTypes.IMAGE, name: "iconsFile" },
  ].forEach((file) => {
    // anything other than keep has some kind of work to do
    if (file?.picker?.mode !== filePickerModes.KEEP) {
      filesTodo.add(file);
    } else if (file?.picker) {
      data[file.name] = file.picker.current;
    }
  });

  let loadingInterval = null;

  const makeLoadingMessage = () => {
    if (filesTodo.size === 0) return "Handling files...";
    const messages = [];
    [...filesTodo.values()].forEach((file) => {
      switch (file?.picker?.mode) {
        case filePickerModes.URL:
          messages.push(`Downloading ${file.picker.url}`);
          break;
        case filePickerModes.UPLOAD:
          messages.push(`Uploading ${normalizeFileName(file.picker.upload.name)}`);
          break;
        case filePickerModes.FILE:
        case filePickerModes.DESCRIPTOR:
        default:
          // no message
          break;
      }
    });
    const messageText = messages.join("\n");
    if (messages.length > 0) {
      return `
        Handling ${messages.length} file${messages.length > 1 ? 's' : ''}:
        ${messageText}
      `;
    }
    return "";
  };

  loadingView.show(makeLoadingMessage());

  loadingInterval = setInterval(() => {
    if (filesTodo.size === 0) {
      loadingView.hide();
      clearInterval(loadingInterval);
      return;
    }
    loadingView.show(makeLoadingMessage());
  }, 1000);

  try {
    await Promise.all([...filesTodo.values()].map(async (file) => {
      const descriptor = await handleFilePicker(file.picker, file.type);
      data[file.name] = descriptor;
      filesTodo.delete(file);
    }));
  } catch (err) {
    clearInterval(loadingInterval);
    loadingView.hide();
    throw err;
  }

  return data;
};

export const onSelectLabel = (steps, formView, modalView) => async () => {
  const { labels, selectedLabels } = formView.state;
  const selected = await modalView.async(labelSelect(
    {
      entries: difference(labels, selectedLabels),
      search: "",
      multiSelect: true,
      selectedLabels: formView.state.selectedLabels,
    },
    modalView,
  ));
  if (selected) {
    formView.update({
      step: steps.LABEL,
      selectedLabels: selected,
      isFormChanged: true,
    });
  }
};

export const onRemoveLabel = (steps, formView) => (choice) => {
  const { selectedLabels } = formView.state;
  formView.update({
    step: steps.LABEL,
    selectedLabels: selectedLabels.filter((item) => item !== choice),
    isFormChanged: true,
  });
};

/**
 * Handles reverting to a previous step when user re-opens a collapsible.
 *
 * @function onOpenStep
 * @private
 */
export const onOpenStep = (self, step) => (open) => {
  if (open) self.updateState({ step });
};
