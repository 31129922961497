/**
 * Endpoints for retrieving configuration information.
 *
 * @module api/configuration
 * @category Backend API
 * @subcategory Configuration
 */

/**
 * @function frontendConfig
 * @return {object} key/value pairs related to frontend configuration and build info
 */
/* eslint-disable-next-line import/prefer-default-export */
export const frontendConfig = async () => fetch('/info.json').then((res) => res.json());

/**
 * @function backendConfig
 * @return {object} key/value pairs related to backend configuration info
 */
