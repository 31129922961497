import collapsible from "ui/component/collapsible";
import { button } from "ui/html";
import { getModal } from "ui/view/modal-view";
import loginInformationModal from "ui/component/modal/login-information";
import sessionExpiredModal from "ui/component/modal/session-expired";

export default () => collapsible({
  title: "Login dialogs",
  open: false,
}, [
  button(["Login or register dialog"], async () => {
    await getModal().async(
      loginInformationModal(),
    );
  }, ".warn.subtle.forward"),
  button(["Session expired dialog"], async () => {
    await getModal().async(
      sessionExpiredModal(),
    );
  }, ".warn.subtle.forward"),
]);
