export const fileList = [
  "file_1.jpg",
  "file_2.mp4",
  "file_3.pdf",
  "file_4.pdf",
  "file_5.jpg",
  "file_6.jpg",
  "file_7.mp4",
  "file_8.pdf",
  "file_9.pdf",
  "file_10.jpg",
  "file_11.jpg",
  "file_12.mp4",
  "file_13.pdf",
  "file_14.pdf",
  "file_15.jpg",
];

export const fileDescriptorList = [
];

export const mockFileDescriptor = {
  checksum: "CCECA42EA535B1433044BF24E99B0712",
  fileId: "61f971f3f8ce1f495131c77a",
  name: "how_to_build_a_multimachine1.pdf",
  fileSize: 2769209,
  fileType: "application/pdf",
  id: "02b64c94-97ee-40a0-9941-8d15f477a3cb",
};
