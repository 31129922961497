import log from "log";
import {
  defaultGradingSchemes,
  defaultGradingSchemeTypes,
} from "model/course/default-grading-scheme";
import collapsible from "ui/component/collapsible";
import gradingSchemeSelectDialog from "ui/component/modal/course/grading-scheme-select";
import moduleEditDialog from "ui/component/modal/course/module";
import lectureEditDialog from "ui/component/modal/course/lecture";
import icon from "ui/component/icon";
import { button } from "ui/html";
import { merge } from "util/object";
import { lecturesMock } from "api/mocks/lecture";
import { courseMock } from "api/mocks/course";
import { metadataList } from "api/mocks/metadata";
import coursePreviewModal from "ui/component/modal/course/course-preview";
import { userList } from "../mocks";

export default (modalView, notificationView) => collapsible({
  title: "Course Dialogs",
  open: false,
}, [
  button(["Grading Scheme Select", icon("award")], async () => {
    const selected = await modalView.async(gradingSchemeSelectDialog({
      gradingSchemes: defaultGradingSchemes,
    }, modalView));
    log.debug("scheme result", selected);
  }, ".subtle.forward"),
  button(["Grading Scheme (Preselected, Manual)", icon("award")], async () => {
    const selectedScheme = merge(
      defaultGradingSchemes.get(defaultGradingSchemeTypes.AMERICAN),
      { automatic: false },
    );
    const selected = await modalView.async(gradingSchemeSelectDialog({
      selectedScheme,
      gradingSchemes: defaultGradingSchemes,
    }, modalView));
    log.debug("scheme result", selected);
  }, ".subtle.forward"),
  button(["Grading Scheme (Preselected, Auto)", icon("award")], async () => {
    const selectedScheme = merge(
      defaultGradingSchemes.get(defaultGradingSchemeTypes.CALIFORNIA),
      { automatic: true },
    );
    const selected = await modalView.async(gradingSchemeSelectDialog({
      selectedScheme,
      gradingSchemes: defaultGradingSchemes,
    }, modalView));
    log.debug("scheme result", selected);
  }, ".subtle.forward"),
  button(["Module Edit", icon("books")], async () => {
    const result = await modalView.async(moduleEditDialog({
      module: courseMock.modules[0],
      metadataList,
      users: userList,
    }, modalView, notificationView));
    log.debug("module result", result);
  }, ".subtle.forward"),
  button(["Lecture Edit", icon("presentation")], async () => {
    const result = await modalView.async(lectureEditDialog({
      lecture: lecturesMock[0],
      metadataList,
      users: userList,
    }, modalView, notificationView));
    log.debug("module result", result);
  }, ".subtle.forward"),
  button(["Lecture Edit (With Schedule)", icon("presentation")], async () => {
    const result = await modalView.async(lectureEditDialog({
      lecture: lecturesMock[0],
      metadataList,
      users: userList,
      showSchedule: true,
      schedule: {
        startDate: new Date(),
        endDate: new Date(),
      },
    }, modalView, notificationView));
    log.debug("module result", result);
  }, ".subtle.forward"),
  button(["Course Preview", icon("presentation")], async () => {
    const result = await modalView.async(coursePreviewModal({
      metadata: courseMock,
    }));
    log.debug("module result", result);
  }, ".subtle.forward"),
]);
