/**
 * Statically defined menu entries.
 *
 * These work similarly to the [user-created menu]{@link module:model/site/menu},
 * but have their own types and additional functionality not accessible to the user
 * menu editor. Each of the menu set constants has the shape:
 *
 * ```js
 * {
 *   entries: Array.<MenuEntry>, // menu entries for this set
 *   ?accountRequired: boolean, // if true, only shown to logged-in users
 *   ?privileges: Array.<UserAuthority>, // if present, the user must have at least one
 *                                       // of the listed authorities
 * }
 * ```
 *
 * Two new menu entry types are defined here:
 * - [Static]{@link MenuEntryStatic} a pre-defined menu link
 * - [Function]{@link MenuEntryStatic} calls a callback function
 *
 * These are only usable in pre-defined menu items, and aren't available in the menu
 * editor interface.
 *
 * The user-generated menu entry types are supported here as well.
 *
 * @module ui/component/header/menu/static-menus
 * @category UI
 * @subcategory Components
 */
import { systemPrivilegeGroups } from "model/acl/constants";
import { menuEntryTypes, menuEntryExclusiveTypes, menuLinkTypes } from "model/site/constants";
import { featureTypes } from "model/config/constants";
import { frozen } from "util/object";

/**
 * @typedef MenuEntryStatic
 * @property {string} slug a uri for an internal static page
 * @property {string} icon an icon name to display alongside the menu entry
 * @property {string} title the link label to display
 * @property {string} [type=menuEntryTypes.STATIC]
 */

/**
 * @typedef MenuEntryFunction
 * @property {function} cb a callback function to execute when clicked
 * @property {string} icon an icon name to display alongside the menu entry
 * @property {string} title the link label to display
 * @property {string} [type=menuEntryTypes.FUNCTION]
 */

/**
 * A callback function for logging the user out.
 *
 * @param {Event} e
 * @function emitLogout
 * @private
 */
const emitLogout = (e) => {
  const event = new CustomEvent("em:logout", { bubbles: true });
  e.currentTarget.dispatchEvent(event);
};

/**
 * A callback function for prompting user the way to import users: groups or just users.
 *
 * @param {Event} e
 * @function emitBulkUserImportWaySelector
 * @private
 */
const emitBulkUserImportWaySelector = (e) => {
  const event = new CustomEvent("em:bulk-user-import-selector", { bubbles: true });
  e.currentTarget.dispatchEvent(event);
};

/**
 * A callback function for prompting user the way to manage users: groups or just users.
 *
 * @param {Event} e
 * @function emitManageUserOrGroupSelector
 * @private
 */
const emitManageUserOrGroupSelector = (e) => {
  const event = new CustomEvent("em:manage-user-or-group-selector", { bubbles: true });
  e.currentTarget.dispatchEvent(event);
};

/**
 * A callback function for prompting user the way to add users: groups or just users.
 *
 * @param {Event} e
 * @function emitAddUserOrGroupSelector
 * @private
 */
const emitAddUserOrGroupSelector = (e) => {
  const event = new CustomEvent("em:add-user-or-group-selector", { bubbles: true });
  e.currentTarget.dispatchEvent(event);
};

/**
 * Links to be shown to any visitor.
 *
 * @constant siteAdmin
 */
export const siteGeneral = frozen({
  accountRequired: false,
  entries: [
    {
      slug: "/",
      label: "Home",
      icon: "home",
      type: menuEntryTypes.STATIC,
    },
    /*
    {
      slug: "/see-all",
      label: "See All",
      icon: "folder",
      type: STATIC,
    },
    */
  ],
});

/**
 * Links to be shown to an admin on the main site.
 *
 * @constant siteAdmin
 */
export const siteAdmin = frozen({
  accountRequired: true,
  privileges: [
    systemPrivilegeGroups.USER_MANAGEMENT,
    systemPrivilegeGroups.MEDIA_MANAGEMENT,
    systemPrivilegeGroups.FILE_MANAGEMENT,
  ],
  entries: [
    {
      slug: "/admin",
      label: "Admin Site",
      icon: "tools",
      type: menuEntryTypes.STATIC,
      exclusive: menuEntryExclusiveTypes.WEB,
    },
  ],
});

/**
 * Links related to a user's own account.
 *
 * @constant accountActions
 */
export const accountActions = frozen({
  accountRequired: true,
  entries: [
    {
      slug: "/account",
      label: "Account Settings",
      icon: "user-cog",
      type: menuEntryTypes.STATIC,
    },
    {
      slug: "/feedback",
      label: "Send Feedback",
      icon: "comments",
      type: menuEntryTypes.STATIC,
    },
    {
      slug: "/faq",
      label: "FAQ",
      icon: "question-square",
      type: menuEntryTypes.STATIC,
    },
    {
      cb: emitLogout,
      label: "Logout",
      icon: "sign-out-alt",
      type: menuEntryTypes.FUNCTION,
    },
  ],
});

/**
 * Links related to a admin's own account.
 *
 * @constant accountActions
 */
export const adminAccountActions = frozen({
  accountRequired: true,
  privileges: [
    systemPrivilegeGroups.USER_MANAGEMENT,
    systemPrivilegeGroups.MEDIA_MANAGEMENT,
    systemPrivilegeGroups.FILE_MANAGEMENT,
  ],
  entries: [
    {
      slug: "/account",
      label: "Account Settings",
      icon: "user",
      type: menuEntryTypes.STATIC,
    },
    {
      slug: "/faq",
      label: "User FAQ",
      icon: "message-question",
      type: menuEntryTypes.STATIC,
    },
    {
      slug: "/admin/admin-faq",
      label: "Admin FAQ",
      icon: "message-question",
      type: menuEntryTypes.STATIC,
    },
    {
      cb: emitLogout,
      label: "Logout",
      icon: "arrow-right-from-bracket",
      type: menuEntryTypes.FUNCTION,
    },
  ],
});

/**
 * Links accessible to anybody with admin privileges in the admin area.
 *
 * @constant siteAdminGeneral
 */
export const siteAdminGeneral = frozen({
  accountRequired: true,
  privileges: [
    systemPrivilegeGroups.USER_MANAGEMENT,
    systemPrivilegeGroups.MEDIA_MANAGEMENT,
    systemPrivilegeGroups.FILE_MANAGEMENT,
  ],
  entries: [
    {
      slug: "/admin",
      label: "Stats",
      icon: "signal",
      type: menuEntryTypes.STATIC,
    },
    {
      slug: "/",
      label: "User View",
      icon: "house",
      type: menuEntryTypes.STATIC,
    },
  ],
});

export const siteAdminSocial = frozen({
  accountRequired: true,
  features: [featureTypes.MESSAGING],
  privileges: [systemPrivilegeGroups.USER_MANAGEMENT],
  entries: [
    {
      label: "Messages",
      icon: "messages",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          slug: "/admin/manage-messages",
          label: "Manage",
          icon: "message",
          type: menuEntryTypes.STATIC,
        },
        {
          slug: "/admin/send-announcement",
          label: "Announcements",
          icon: "bell",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});

/**
 * Links accessible to admins with user management privileges.
 *
 * @constant siteAdminUsers
 */
export const siteAdminUsers = frozen({
  accountRequired: true,
  privileges: [systemPrivilegeGroups.USER_MANAGEMENT],
  features: [featureTypes.USER_MANAGEMENT],
  entries: [
    {
      label: "Users",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      icon: "user-group",
      entries: [
        {
          cb: emitManageUserOrGroupSelector,
          label: "Manage",
          icon: "users-between-lines",
          type: menuEntryTypes.FUNCTION,
        },
        {
          cb: emitAddUserOrGroupSelector,
          filter: [featureTypes.REGISTRATION],
          label: "Add",
          icon: "users-line",
          type: menuEntryTypes.FUNCTION,
        },
        {
          cb: emitBulkUserImportWaySelector,
          filter: [featureTypes.REGISTRATION],
          label: "Mass Creation",
          icon: "layer-plus",
          type: menuEntryTypes.FUNCTION,
        },
      ],
    },
  ],
});

/**
 * Links for content management.
 *
 * @constant siteAdminContent
 */
export const siteAdminContent = frozen({
  accountRequired: true,
  privileges: [systemPrivilegeGroups.MEDIA_MANAGEMENT],
  entries: [
    {
      label: "Content",
      icon: "files",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          slug: "/admin/manage-content",
          label: "Manage",
          icon: "file",
          type: menuEntryTypes.STATIC,
        },
        {
          slug: "/admin/add-content",
          label: "Add",
          icon: "file-plus",
          type: menuEntryTypes.STATIC,
        },
        {
          slug: "/admin/mass-content-create",
          label: "Mass Creation",
          icon: "layer-plus",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});

/**
 * Links for site customization.
 *
 * @constant siteAdminMedia
 */
export const siteAdminSite = frozen({
  accountRequired: true,
  privileges: [systemPrivilegeGroups.MEDIA_MANAGEMENT],
  entries: [
    {
      label: "Site",
      icon: "sidebar",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          label: "Page",
          type: menuEntryTypes.ENTRY,
          icon: "memo-circle-info",
          entries: [
            {
              slug: "/admin/manage-pages",
              label: "Manage",
              icon: "memo",
              type: menuEntryTypes.STATIC,
            },
            {
              slug: "/admin/add-page",
              label: "Add",
              icon: "message-plus",
              type: menuEntryTypes.STATIC,
            },
          ],
        },
        {
          slug: "/admin/edit-menu",
          label: "Menu",
          icon: "bars",
          type: menuEntryTypes.STATIC,
        },
        {
          slug: "/admin/ui-customization",
          label: "Site Customization",
          icon: "pen-paintbrush",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});

/**
 * Links for courses.
 *
 * @constant siteAdminCourse
 */
export const siteAdminCourse = frozen({
  accountRequired: true,
  features: [featureTypes.LMS],
  privileges: [systemPrivilegeGroups.MEDIA_MANAGEMENT],
  entries: [
    {
      label: "Courses",
      icon: "chalkboard",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          label: "Assessments",
          icon: "file-circle-question",
          type: menuEntryTypes.ENTRY,
          linkType: menuLinkTypes.NONE,
          entries: [
            {
              slug: "/admin/manage-assessments",
              label: "Manage",
              icon: "check-double",
              type: menuEntryTypes.STATIC,
            },
            {
              slug: "/admin/add-assessment",
              label: "Add",
              icon: "receipt",
              type: menuEntryTypes.STATIC,
            },
          ],
        },
        {
          label: "Courses",
          icon: "book-copy",
          type: menuEntryTypes.ENTRY,
          linkType: menuLinkTypes.NONE,
          entries: [
            {
              slug: "/admin/manage-courses",
              label: "Manage",
              icon: "books",
              type: menuEntryTypes.STATIC,
            },
            {
              slug: "/admin/add-course",
              label: "Add",
              icon: "book-medical",
              type: menuEntryTypes.STATIC,
            },
          ],
        },
        {
          label: "Evaluations",
          slug: "/admin/manage-evaluations",
          icon: "file-certificate",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});

/**
 * Links for assessments.
 *
 * @constant siteAdminAssessments
 */
export const siteAdminAssessments = frozen({
  accountRequired: true,
  features: [featureTypes.LMS],
  privileges: [systemPrivilegeGroups.MEDIA_MANAGEMENT],
  entries: [
    {
      label: "Assessments",
      icon: "ballot-check",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          slug: "/admin/manage-assessments",
          label: "Manage Assessments",
          icon: "check-double",
          type: menuEntryTypes.STATIC,
        },
        {
          slug: "/admin/add-assessment",
          label: "Add Assessment",
          icon: "receipt",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});

/**
 * Links for evaluations.
 *
 * @constant siteAdminEvaluations
 */
export const siteAdminEvaluations = frozen({
  accountRequired: true,
  features: [featureTypes.LMS],
  privileges: [systemPrivilegeGroups.MEDIA_MANAGEMENT],
  entries: [
    {
      label: "Evaluations",
      icon: "medal",
      type: menuEntryTypes.ENTRY,
      linkType: menuLinkTypes.NONE,
      entries: [
        {
          slug: "/admin/manage-evaluations",
          label: "Manage Evaluations",
          icon: "list-ol",
          type: menuEntryTypes.STATIC,
        },
      ],
    },
  ],
});
