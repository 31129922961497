import log from "log";
import { fileList } from "api/mocks/file";
import { aclGroupList } from "api/mocks/acl";
import { videoMetadataList } from "api/mocks/metadata";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import courseSelectDialog from "ui/component/modal/course-select";
import fileSelectDialog from "ui/component/modal/file-select";
import groupSelectDialog from "ui/component/modal/acl/group-select";
import listSelectDialog from "ui/component/modal/list-select";
import metadataSelectDialog from "ui/component/modal/metadata-select";
import pageSelectDialog from "ui/component/modal/page-select";
import principalSelectDialog from "ui/component/modal/acl/principal-select";
import userSelectDialog from "ui/component/modal/user-select";
import { selectSectionType } from "ui/page/admin/dynamic/modals";
import { button } from "ui/html";
import {
  courseList,
  descriptorList,
  pageList,
  principalList,
  userList,
} from "../mocks";

export default (modalView) => collapsible({
  title: "List/Select Dialogs",
  open: false,
}, [
  button(["Basic List Selector", icon("file-import")], async () => {
    const selected = await modalView.async(listSelectDialog({
      entries: ["one", "two", "three", "four", "five", "six", "seven"]
        .map((label) => ({ label, id: label })),
    }));
    log.debug("user selected", selected);
  }, ".subtle.forward"),
  button(["Basic List Multi-Selector", icon("file-import")], async () => {
    const selected = await modalView.async(listSelectDialog({
      entries: ["two", "three", "four", "five", "six", "seven"]
        .map((label) => ({ label, id: label })),
      multiSelect: true,
    }));
    log.debug("user multi-selected", selected);
  }, ".subtle.forward"),
  button(["File Selector", icon("file-import")], async () => {
    const file = await modalView.async(fileSelectDialog({
      uploads: fileList,
      descriptors: descriptorList,
    }));
    log.debug("selected file", file);
  }, ".subtle.forward"),
  button(["File Selector (Uploads Only)", icon("file-import")], async () => {
    const file = await modalView.async(fileSelectDialog({
      uploads: fileList,
    }));
    log.debug("selected file", file);
  }, ".subtle.forward"),
  button(["File Selector (Descriptors Only)", icon("file-import")], async () => {
    const file = await modalView.async(fileSelectDialog({
      descriptors: descriptorList,
    }));
    log.debug("selected file", file);
  }, ".subtle.forward"),
  button(["Metadata Selector", icon("list")], async () => {
    modalView.show(metadataSelectDialog({
      entries: videoMetadataList,
      onSelect: (selected) => log.debug("selected metadata", selected.id),
    }));
  }, ".subtle.forward"),
  button(["Metadata Selector (async)", icon("list")], async () => {
    const selected = await modalView.async(metadataSelectDialog.async({
      entries: videoMetadataList,
    }));
    log.debug("selected metadata", selected?.id);
  }, ".subtle.forward"),
  button(["Page Selector", icon("file-alt")], async () => {
    const selection = await modalView.async(pageSelectDialog({
      pages: pageList,
    }));
    log.debug("selected page", selection);
  }, ".subtle.forward"),
  button(["Page Section Selector", icon("file")], async () => {
    const t = await selectSectionType(modalView);
    log.debug("User selected page type", t);
  }, ".subtle.forward"),
  button(["Course Selector", icon("file-alt")], async () => {
    const selection = await modalView.async(courseSelectDialog({
      courses: courseList,
    }));
    log.debug("selected page", selection);
  }, ".subtle.forward"),
  button(["ACL Group Selector", icon("users")], async () => {
    const selection = await modalView.async(groupSelectDialog({
      groups: aclGroupList,
    }));
    log.debug("User selected group", selection);
  }, ".subtle.forward"),
  button(["ACL User/Group Selector", icon("lock")], async () => {
    const result = await modalView.async(principalSelectDialog({
      principals: principalList,
    }));
    log.debug("User selected principal", result);
  }, ".subtle.forward"),
  button(["User Selector", icon("user")], async () => {
    const result = await modalView.async(userSelectDialog({
      users: userList,
    }));
    log.debug("selected user", result);
  }, ".subtle.forward"),
]);
