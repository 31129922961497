/**
 * Messages search component
 *
 * @module ui/component/chat/message-search
 * @category UI
 * @subcategory Chat
 */
import { merge } from "util/object";
import advancedMessageSearch from "ui/component/modal/chat/advanced-message-search";
import actionBarSearch from "ui/component/action-bar-search";

let modal;

const defaultState = {
  users: [],
  title: "",
  onAdvancedSearch: () => {},
  onSearch: () => {},
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function showAdvancedSearchModal
 * @private
 */
const showAdvancedSearchModal = async (state) => {
  const searchParams = await modal.async(
    advancedMessageSearch({ users: state.users }, modal),
  );
  state.onAdvancedSearch(searchParams);
};

export default function messageSearch(inState, modalView) {
  const state = merge(defaultState, inState);
  modal = modalView;

  return actionBarSearch({
    title: state.title,
    onSearch: (searchText) => state.onSearch(searchText),
    onAdvancedSearch: () => showAdvancedSearchModal(state),
  });
}
