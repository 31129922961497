import log from "log";
import { fileList } from "api/mocks/file";
import { metadataList } from "api/mocks/metadata";
import { pageTypes, pageTypesFriendly } from "model/dynamic-page/constants";
import { button } from "ui/html";
import sectionIcon from "ui/component/section-icon";
import sectionEditModal from "ui/component/modal/section-edit";
import collapsible from "ui/component/collapsible";
import { defaultEditState, defaultSection } from "ui/page/admin/dynamic/state";
import { merge } from "util/object";
import { descriptorList, courseList } from "../mocks";

export default (modalView, notificationView) => collapsible({
  title: "Page Section Editors",
  open: false,
}, [...pageTypesFriendly.entries()]
  .filter(([type]) => type !== pageTypes.INDEPENDENT)
  .map(([type, title]) => button(
    [title, sectionIcon({ type })],
    async () => {
      const result = await modalView.async(sectionEditModal({
        ...defaultEditState,
        descriptors: descriptorList,
        files: fileList,
        metadata: metadataList,
        courses: courseList,
        section: merge(defaultSection, {
          type,
        }),
      }, modalView, notificationView));
      log.debug("section result", result);
    },
    ".subtle.forward",
  )));
