/**
 * A modal dialog for asking user to register or sign in.
 *
 * @module ui/component/modal/login-information
 * @category UI
 * @subcategory Modal Dialogs
 */
import { getModal } from "ui/view/modal-view";
import dialog from "ui/component/modal/layout/dialog";
import { div, h3 } from "ui/html";
import button from "ui/component/form-managed/button";
import { doLoginRedirect } from "util/navigation";

export default function loginInformationModal() {
  return dialog({
    header: h3("Register with SmartEdge"),
    footer: div("", [
      button({
        label: "Register now",
        sel: ".main",
        onClick: () => doLoginRedirect(true),
      }),
      div(".sign-in-container", [
        "or",
        button({
          label: "Sign in",
          sel: ".subtle.secondary",
          onClick: () => doLoginRedirect(),
        }),
      ]),
    ]),
    sel: ".login-information-dialog",
  }, getModal());
}
