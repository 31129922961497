import form from "ui/component/form-managed";
import toggle from "ui/component/form-managed/field-toggle";
import div from "ui/html/div";
import buttons from "./buttons";
import checkboxes from "./checkbox";
import checklists from "./checklist";
import dateAndTime from "./date-and-time";
import filePicker from "./file-picker";
import inputFields from "./input-fields";
import labelFields from "./labels";
import radios from "./radio";
import textareas from "./textarea-fields";
import toggles from "./toggles";

export default (modalView) => (self) => form(
  "#form-managed",
  [
    div(".buttons", [
      toggle.boxed({
        label: "Validate",
        name: "validation",
        sel: self.values?.validation ? ".danger" : ".primary",
        toggled: self.values?.validation,
        /* eslint-disable-next-line no-param-reassign */
        onToggle: (state) => self.setFullValidation(state),
      }),
      toggle.boxed({
        label: "Disable",
        name: "disabled",
        sel: self.values?.disabled ? ".danger" : ".primary",
        toggled: self.values?.disabled,
        onToggle: (state) => (state ? self.disable() : self.enable()),
      }),
    ]),
    inputFields(self),
    checkboxes(self),
    checklists(self),
    radios(self),
    labelFields(self, modalView),
    textareas(self),
    dateAndTime(self),
    toggles(self),
    buttons(self),
    filePicker(self),
  ],
);
