/**
 * A `em` element.
 *
 * @module ui/html/em
 * @category UI
 * @subcategory Elements
 */
import el from "ui/common/el";

/**
 *
 * @function span
 * @param {module:ui/html~ChildEl} children inner content for the span (text, vnode, or vnode array)
 * @param {module:ui/html~Selector} [sel=""] css selectors
 * @param {module:ui/html~Config} [config={}] snabbdom configuration object
 */
export default (children = "", sel = "", config = {}) => el(`em${sel}`, config, children);
