/**
 * The registration view for the login self.
 *
 * @module ui/self/login/register
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import log from "log";
import api from "api";
import { verificationMethods } from "model/config/constants";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import email from "ui/component/form-managed/field-email-address";
import firstName from "ui/component/form-managed/field-given-name";
import lastName from "ui/component/form-managed/field-family-name";
import passwordConfirmed from "ui/component/form-managed/field-password-confirmed";
import phone from "ui/component/form-managed/field-phone-number";
import username from "ui/component/form-managed/field-username";
import h1 from "ui/html/h1";
import { unique } from "util/array";
import { errorCodes, getErrorCode } from "util/error";
import { merge } from "util/object";
import { doLogin } from "./login";
import messages from "./messages";
import { initLogin, initVerification } from "./common";

/**
 * Handles submission of the registration form.
 *
 * @function doRegistration
 * @param {View} self
 * @private
 */
export const doRegister = async (self, notification) => {
  self.setFullValidation(true);
  if (!self.validate().valid) {
    notification.post(messages.invalid);
    return;
  }

  const { userVerification } = self.state.config.server;

  // send registration request to backend API
  try {
    self.disable();
    const response = await api.user.register(merge(
      self.values,
      { password: self.values.newPassword },
    ));
    if (response.verified || userVerification === verificationMethods.DISABLED) {
      // if the user was auto-verified we can immediately log in
      await doLogin(self, notification);
    } else {
      switch (userVerification) {
        case verificationMethods.ADMIN:
          // admin must verify, just bounce back to login page
          notification.post(messages.registerSuccessAdminVerify);
          initLogin(self, { username: "", password: "" });
          break;
        case verificationMethods.OTP:
          notification.post(messages.registerSuccessBothVerify);
          initVerification(self, { username: response.username });
          break;
        default:
          throw new Error("unknown verification method required by server.");
      }
    }
  } catch (error) {
    log.error("error during registration", error);

    let errors = [];
    log.info("error object", error.body);
    if (error.body.errors) {
      errors = [...error.body.errors];
    }
    if (error.body.message) errors.push(error.body.message);
    log.info("checking error messages", errors);
    errors.forEach((text) => {
      const code = getErrorCode(text);
      switch (code) {
        case errorCodes.USER_TAKEN_EMAIL:
          self.updateState({
            takenEmails: unique([...self.state.takenEmails, self.values.email]),
          });
          break;
        case errorCodes.USER_TAKEN_PHONE:
          self.updateState({
            takenPhones: unique([...self.state.takenPhones, self.values.cellPhone]),
          });
          break;
        case errorCodes.USER_TAKEN_USERNAME:
          self.updateState({
            takenNames: unique([...self.state.takenNames, self.values.username]),
          });
          break;
        case errorCodes.USER_BAD_LOGIN:
          break;
        default:
          notification.post(merge(messages.apiError, { text }));
      }
    });
    self.enable();
  }
  self.enable();
};

/**
 * Show the registration form.
 *
 * @function showRegistration
 * @private
 * @param {View} self
*/
export default (self, notification) => {
  const { server } = self.state.config;
  const { takenNames, takenPhones, takenEmails } = self.state;

  return form(
    "#register",
    self.bind([
      h1("Registration"),
      [username, { required: true, taken: takenNames, autocomplete: "off" }],
      [firstName, { required: server.firstNameRequired }],
      [lastName, { required: server.lastNameRequired }],
      [email, { required: server.emailRequired, taken: takenEmails }],
      [phone, { required: server.cellPhoneRequired, taken: takenPhones }],
      [passwordConfirmed, { required: true, name: "password", autocomplete: "off" }],
      [button, {
        label: "Register",
        onClick: () => doRegister(self, notification),
        disabled: !self.validate().valid,
        sel: "#register-button",
      }],
      [button, {
        label: "Back to Login",
        onClick: () => initLogin(self, self.values),
        sel: ".subtle.ok",
      }],
    ], self.state.registration),
  );
};
