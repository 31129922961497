import log from "log";
import fromEvent from "xstream/extra/fromEvent";
import api from "api";
import cache from "cache";
import { metadataTypes } from "model/metadata/constants";
import * as cacheKeys from "cache/constants";
import contentModal from "ui/component/modal/content";
import h1 from "ui/html/h1";
import main from "ui/html/main";
import button from "ui/component/form-managed/button";
import carousel from "ui/component/card-carousel";
import videoStream from "data/metadata/video";
import documentStream from "data/metadata/document";
import playlistStream from "data/metadata/playlist";
import layout from "ui/page/layout";
import view from "ui/view";

const defaultState = {
  videos: new Map(),
  documents: new Map(),
  playlists: new Map(),
  positions: new Map(),
};

const clearCache = () => {
  const keys = { ...cacheKeys };
  Object.values(keys).forEach((key) => cache.clearItem(key));
  log.debug("CACHE CLEARED");
};

const display = ({ state }) => main("#streams", [
  h1("Test Data Streams"),
  carousel({
    position: state.positions.get("VIDEO") || 0,
    list: {
      title: "Videos",
      id: "VIDEO",
      type: "VIDEO",
      items: [...state.videos.values()],
    },
  }),
  carousel({
    position: state.positions.get("DOCS") || 0,
    list: {
      title: "Documents",
      id: "DOCS",
      type: "DOCS",
      items: [...state.documents.values()],
    },
  }),
  carousel({
    position: state.positions.get("LISTS") || 0,
    list: {
      title: "Playlists",
      id: "LISTS",
      type: "LISTS",
      items: [...state.playlists.values()],
    },
  }),
  button({
    label: "Clear Cache",
    onClick: () => clearCache(),
  }),
]);

const open = (content, modal) => ({ detail: { type, id } }) => {
  const { videos, documents, playlists } = content.state;
  switch (type) {
    case metadataTypes.VIDEO:
      modal.async(contentModal({ metadata: videos.get(id) }, modal));
      break;
    case metadataTypes.DOCUMENT:
      modal.async(contentModal({ metadata: documents.get(id) }, modal));
      break;
    case metadataTypes.LIST:
      modal.async(contentModal({ metadata: playlists.get(id) }, modal));
      break;
    default:
      break;
  }
};

const scroll = (v) => ({ detail: { list, position } }) => {
  const positions = new Map(v.state.positions);
  positions.set(list.id, position);
  v.update({ positions });
};

const getItemIds = async () => {
  let videoIds = cache.getObject("VID_IDS");
  let documentIds = cache.getObject("DOC_IDS");
  let playlistIds = cache.getObject("PLAY_IDS");
  [videoIds, documentIds, playlistIds] = await Promise.all([
    videoIds || (await api.metadata.listVideos()).map((i) => i.id),
    documentIds || (await api.metadata.listDocuments()).map((i) => i.id),
    playlistIds || (await api.metadata.listPlaylists()).map((i) => i.id),
  ]);

  cache.storeObject("VID_IDS", videoIds);
  cache.storeObject("DOC_IDS", documentIds);
  cache.storeObject("PLAY_IDS", playlistIds);
  return [videoIds, documentIds, playlistIds];
};

/**
 * Basic test of data streaming functionality.
 *
 * @module ui/page/admin/test-data
 */
export default async function testData(selector) {
  const { loading, modal } = layout(selector, [main("#streams")], "Data Stream Test");
  loading.show();
  const test = view.create(display)("#streams", defaultState);
  const [videoIds, documentIds, playlistIds] = await getItemIds();

  test.bindStreams([
    ["videos", videoStream.getMany(videoIds)],
    ["documents", documentStream.getMany(documentIds)],
    ["playlists", playlistStream.getMany(playlistIds)],
  ], () => loading.hide());
  loading.hide();

  fromEvent(document.body, "em:carousel-scroll").addListener({ next: scroll(test) });
  fromEvent(document.body, "em:modal").addListener({ next: open(test, modal) });
}
