/**
 * A table that supports sorting by columns and (someday) other advanced functionality.
 *
 * @module ui/component/dynamic-table
 * @category UI
 * @subcategory Components
 */

import {
  // div,
  span,
  table,
  thead,
  tbody,
  tr,
  td,
  th,
} from "ui/html";
import icon from "ui/component/icon";
import spinner from "ui/component/spinner";
import { frozen } from "util/object";

/**
 * @enum sortOrders
 * @property ASC
 * @property DESC
 */
export const sortOrders = frozen({
  ASC: "ASC",
  DESC: "DESC",
});

const makePlaceholderRow = (placeholder, colspan) => tr(
  td(placeholder || spinner(), ".empty-placeholder", { attrs: { colspan } }),
);

/**
 * Selects the appropriate icon for the currently sorted column.
 * @function sortIcon
 * @private
 */
const sortIcon = (current, sortColumn, sortOrder) => {
  if (current !== sortColumn) return "";
  switch (sortOrder) {
    case sortOrders.ASC:
      return icon.solid("sort-down");
    case sortOrders.DESC:
    default:
      return icon.solid("sort-up");
  }
};

const buildRow = (columns, rowSelectors, onRowClick) => (row, i) => (row.PLACEHOLDER
  ? makePlaceholderRow("", columns.length)
  : tr(columns.map((col) => td(row[col])), rowSelectors[i], {
    on: {
      click: () => onRowClick(row, i),
    },
  }));

/**
 * A callback triggered when a column header is clicked. The view is responsible for
 * deciding what to do with it and calling dynamicTable with pre-sorted rows.
 *
 * @callback OnSortChangeCallback
 * @param {string} column column that was clicked
 */

/**
 * Builds a dynamic table with sorting capability.
 *
 * Note the table does not self-sort. It only provides sort icons based on the supplied
 * `sortColumn` and `sortOrder` parameters and a callback indicating which column header
 * was clicked. Sorting of the row data should be handled by the containing view/page.
 *
 * `columns` is an array of strings corresponding to object keys on row entries, in the
 * order (left-to-right) that they should be displayed on the table. If row objects have
 * properties not given in `columns` they will be ignored. If `columns` is not provided,
 * it will be populated with all the properties of the first row object.
 *
 * `columnLabels` should be an array of human-readable names for the table column headers,
 * and should correspond to `columns`. If not provided, they will be populated with the
 * contents of `columns`.
 *
 * `rows` is an array of objects with properties that can be Els or any value that can
 * coerce to a string.
 *
 * `rowSelectors` is optional, but if used must have the same number of elements as
 * `rows`. Rows that do not require selectors can be undefined.
 *
 * @example
 * dynamicTable({
 *   columnLabels: [ "Id", "Name", "Value" ],
 *   columns: ["itemId", "itemName", "itemValue" ],
 *   rows: [
 *     { itemId: 1, itemName: "foo", itemValue: "fooval", misc: "ignored" },
 *     { itemId: 2, itemName: "bar", itemValue: "barval", tags: [] },
 *     { itemId: 3, itemName: "baz", itemValue: "bazval" data: {} },
 *     { itemId: 4, itemName: "qux", itemValue: "quxval" },
 *     { itemId: 5, itemName: "quux", itemValue: "quuxval" },
 *   ],
 *   rowSelectors: [
 *     undefined,
 *     ".special-class",
 *     undefined,
 *     ".different-special-class",
 *     undefined,
 *   ],
 * });
 * @function dynamicTable
 * @param {?Array.<String>} columnLabels
 * @param {?Array.<String>} columns ordered array of property keys for row objects
 * @param {?Array.<Object>} rows ordered array of objects to populate rows with
 * @param {?Array.<Selector>} rowSelectors css selectors to be applied to rows
 * @param {Function} onRowClick row click callback
 * @param {string|number|El} rows[row[prop]] row properies for display in columns
 * @param {?OnSortChangeCallback} onSortChange called when a column label is clicked
 * @param {?Function} onColumnNameClick called when a column label is clicked
 */
export default function dynamicTable({
  columnLabels = null,
  columns = null,
  rows = [],
  rowSelectors = [],
  onRowClick = () => {},
  onSortChange = () => {},
  onColumnNameClick = () => {},
  sortColumn = null,
  sortOrder = sortOrders.ASC,
  placeholder = "N/A",
  loading = false,
}) {
  /* eslint-disable no-param-reassign */
  if (rows?.length && !columnLabels) columnLabels = Object.keys(rows[0]);
  if (rows?.length && !columns) columns = Object.keys(rows[0]);
  else if (!columns) columns = columnLabels;

  /* eslint-enable no-param-reassign */
  const header = thead(tr(columnLabels.map((label, i) => th(
    span([label, sortIcon(columns[i], sortColumn, sortOrder)]),
    "",
    {
      on: {
        click: () => {
          onSortChange(columns[i]);
          onColumnNameClick(columns[i], i);
        },
      },
      attrs: {
        title: label,
      },
    },
  ))));

  return table([
    header,
    rows?.length
      ? tbody([
        ...rows.map(buildRow(columns, rowSelectors, onRowClick)),
        loading ? makePlaceholderRow(spinner(), columns.length) : "",
      ])
      : tbody(makePlaceholderRow(placeholder, columns.length)),
  ], `.dynamic${rows?.length ? "" : ".empty"}`);
}
