/**
 * A thread preview component for displaying on manage messaging UI
 *
 * @module ui/page/admin/messaging/thread-preview
 * @category Pages
 * @subcategory Admin - Messages
 */
import { merge } from "util/object";
import { reportTypes } from "@smartedge/em-message/constants";
import { div } from "ui/html";
import { usFullDateFormat } from "util/date";
import { getThreadName } from "ui/page/admin/messaging/common";
import icon from "ui/component/icon";
import buttonGroup from "ui/component/button-group";

const defaultState = {
  users: [],
  groups: [],
  target: null,
  reportType: reportTypes.THREAD,
  created: null,
  text: "",
  controls: [],
  onClick: null,
};

export default function threadPreview(inState = {}) {
  const state = merge(defaultState, inState);
  const threadName = getThreadName(state.target, state.users, state.groups);
  if (!threadName) return "";

  return div(
    ".admin-message-display",
    {
      on: {
        click: () => state?.onClick?.(),
      },
    },
    [
      div(".message-header", [
        div(
          ".message-header-left",
          [
            div(".chat-avatar", icon("list")),
            div(".username", threadName),
          ],
        ),
        div(".message-header-right", [
          div(".date", usFullDateFormat(state.created)),
        ]),
      ]),
      div(".message-body", [
        div(".text", state.text),
      ]),
      buttonGroup([
        ...state.controls,
      ]),
    ],
  );
}
