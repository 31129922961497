/**
 * A textarea field preset for content descriptions.
 *
 * @module ui/component/form-managed/field-textarea
 * @category UI
 * @subcategory Forms
 */
import fieldTextarea from "ui/component/form-managed/field-textarea";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Please enter a valid description.",
  required: "Please enter a description.",
});

const defaultState = frozen({
  name: "description",
  label: "Description",
  helpTexts,
});

/**
 * A textarea for entering a plaintext description of an item.
 *
 * See [field-textarea]{@link module:ui/component/form-managed/field-textarea} for full
 * list of parameters.
 *
 * @function fieldDescription
 * @param {object} inState
 * @param {string} [inState.name="description"]
 * @param {string} [inState.label="Description"]
 * @return {El}
 */
export default function fieldDescription(inState) {
  return fieldTextarea(merge(defaultState, inState));
}
