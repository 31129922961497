/**
 * A page for bulk user group entry.
 *
 * Corresponds to `markup/admin/add-user-group-bulk.html`
 *
 * @module ui/view/admin/user/bulk/user-group
 * @category Pages
 * @subcategory Admin - Users
 */
import bulkAddUser from "./index";

/**
 * The bulk user group import page.
 *
 * @function bulkAddUserGroup
 */
export default async function bulkAddUserGroup(selector) {
  return bulkAddUser(selector, {
    userGroupMode: true,
    title: "Mass Creation — Groups",
  });
}
