import collapsible from "ui/component/collapsible";
import dateTime from "ui/component/form-managed/field-date-time";
import p from "ui/html/p";

export default function dateAndTime(self) {
  const required = true;

  return collapsible({
    title: "Date and Time",
    open: self.state.dateTimeOpen,
    onChange: (dateTimeOpen) => self.updateState({ dateTimeOpen }),
  }, self.bind([
    p("Any Time"),
    [dateTime, { required }],
    p("Date Before Now"),
    [dateTime, { name: "date-before-today", max: new Date() }],
    p("Date After Now"),
    [dateTime, { name: "date-after-today", min: new Date() }],
    p("Date Between 1980 - 2020"),
    [dateTime, { name: "date-in-range", min: new Date("1980-01-01T05:01Z"), max: new Date("2020-01-01T10:01Z") }],
  ]));
}
