/**
 * A view specialized for modals.
 *
 * The modal view is set up automatically by the
 * [layout]{@link module:ui/page/page-utils~layout} helper function, so it shouldn't need to
 * be initialized by a page module.
 *
 * @module ui/view/modal-view
 * @category UI
 * @subcategory Views
 */
import confirm from "ui/component/modal/confirm";
import alert from "ui/component/modal/alert";
import { div } from "ui/html";
import { qs } from "util/dom";
import { onEscKey } from "util/event";
import baseView from "./view";

let lastEventAbortController;
let storedModalView = null;

const wrap = (selector, innerNodes = "", onOverlayClick = null) => {
  // handling in here is verbose, but prevents multiple firings which can
  // inadvertently descend too far through a queue of stacked modals
  function handleEsc(e) {
    onEscKey(() => {
      if (document.body.classList.contains("modal-open")) {
        e.stopPropagation();
        onOverlayClick();
      }
    })(e);
  }

  function handleOverlayClick(e) {
    if (e.target !== qs(selector)) return;
    e.stopPropagation();
    e.preventDefault();
    onOverlayClick();
  }

  lastEventAbortController?.abort();

  lastEventAbortController = new AbortController();
  const { signal } = lastEventAbortController;

  return div(
    selector,
    {
      hook: {
        postpatch: () => {
          qs(selector)?.addEventListener("click", handleOverlayClick, { signal });
          document.addEventListener("keydown", handleEsc, { signal });
        },
      },
    },
    innerNodes,
  );
};

/**
 * Gets keyboard-focusable elements within a specified element
 *
 * @param {HTMLElement|Document} [element=document] element
 * @returns {Array}
 */
const getKeyboardFocusableElements = (element = document) => {
  if (!element) {
    return [];
  }
  return [...element.querySelectorAll(
    "input, textarea, select, details,[tabindex]:not([tabindex=\"-1\"])",
  )]
    .filter((el) => !el.hasAttribute("disabled") && !el.getAttribute("aria-hidden"));
};

/**
 * A callback called when an async modal dialog is ready to return a value.
 *
 * @callback AsyncModalReturnCallback
 * @param {mixed} retVal value to return from an async modal
 */

/**
 * A callback to throw an error from an asynchronous modal.
 *
 * @callback AsyncModalErrorCallback
 * @param {Error} error an error to throw from an async modal
 */
/**
 * An entry in the modal dialog queue.
 *
 * @typedef ModalQueueEntry
 * @property {module:ui/common/el~El} nodes
 * @property {boolean} isAsync whether the entry was called in async mode
 * @property {boolean} closeable whether the entry can be closed externally
 * @property {AsyncModalReturnCallback} resolve the resolve callback for an async promise
 * @property {AsyncModalErrorCallback} reject the reject callback for an async promise
 */

/**
 * A modal view. Borrows from the generic view, and augments with additional properties
 * and methods related to modals.
 *
 * @typedef {object} ModalView
 * @borrows {module:ui/view/view~View}
 * @property {number} queueLength the number of entries in the modal queue stack
 * @property {function} show see {@link module:ui/view/modal-view~show}
 * @property {function} close see {@link module:ui/view/modal-view~close}
 * @property {function} hide see {@link module:ui/view/modal-view~hide}
 * @property {function} async see {@link module:ui/view/modal-view~async}
 * @property {function} alert see {@link module:ui/view/modal-view~alert}
 * @property {function} customAlert see {@link module:ui/view/modal-view~customAlert}
 * @property {function} confirm see {@link module:ui/view/modal-view~confirm}
 * @property {function} customConfirm see {@link module:ui/view/modal-view~customConfirm}
 * @property {function} back see {@link module:ui/view/modal-view~back}
 */

/**
 * A modal dialog view has a couple extensions, including automatically
 * setting up a wrapper around whatever content nodes it's given.
 *
 * @function bootstrapModal
 * @private
 */
function bootstrapModal(view, selector, innerNodes) {
  /* eslint-disable object-shorthand */
  /* eslint-disable no-param-reassign */
  // eslint gets confused about Object.defineProperty

  /**
   * current inner view (current dialog is saved here for show/hide)
   * @private
   * @property {inner}
   */
  let inner = innerNodes;

  /**
   * @private
   * @property {Array<ModalQueueEntry>} queue, for use with push/pop and back buttons
   */
  const queue = [];

  /**
   * Gets the last entry in the queue.
   *
   * @function end
   * @private
   */
  const end = () => (queue.length ? queue[queue.length - 1] : null);

  /**
   * Sets up the <body> element styles and various other things outside the modal
   * view that need to be altered while the modal is opened.
   *
   * this could get ugly if some other component is also trying
   * to control these styles on the body... but it's the best
   * way to stop scrolling in a way that doesn't break the sticky
   * header ...
   *
   * @function setModalOpenBodyState
   * @private
   */
  const setModalOpenBodyState = () => {
    const headerHeight = 0;
    document.body.style.top = `-${window.scrollY - headerHeight}px`;
    // stop jog from scrollbar
    document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
    document.body.classList.add("modal-open");
  };

  /**
   * Shows the given nodes, or if no new nodes are given, shows the last
   * dialog content.
   *
   * @function show
   * @param {module:ui/html~childEl} nodes to place inside the modal dialog
   * @param {boolean} [closeable=true] whether to permit the modal to close by keypress
   * @mutates document.body.classList, adding 'modal-open' class
   */
  view.show = (nodes, closeable = true) => {
    // make sure whatever opened the dialog is blurred
    // otherwise hitting spacebar might duplicate the dialog
    document.activeElement.blur();
    if (nodes) {
      queue.push({
        nodes,
        closeable,
        isAsync: false,
      });
    }
    const last = end();
    if (last) {
      setModalOpenBodyState();
      view.patch(last.nodes);
      const focusableElements = getKeyboardFocusableElements(view?.element);
      focusableElements?.[0]?.focus();
    } else view.hide();
  };

  /**
   * Creates an asynchronous modal dialog. These can be awaited for the result of
   * some user input.
   *
   * @function async
   * @param {module:ui/html~childEl} nodes to place inside the modal dialog
   * @param {boolean} [closeable=true] whether to permit the modal to close by keypress
   * @return Promise resolved when the modal closes
   */
  view.async = (nodes, closeable = true) => {
    if (nodes) {
      return new Promise((resolve, reject) => {
        try {
          queue.push({
            nodes,
            closeable,
            isAsync: true,
            resolve,
            reject,
          });
          view.show();
        } catch (e) {
          reject(e);
        }
      });
    }
    return Promise.reject(new Error("ModalView.async called without content"));
  };

  /**
   * Resolves the promise on an asynchronous modal (created with @link async).
   *
   * Closes the async modal and pops it out of the queue in the process.
   *
   * @function resolve
   * @param {mixed} [result] a value to resolve the async modal with
   */
  view.resolve = (result) => {
    const last = end();
    if (!last) throw new Error("ModalView.resolve called with no queued modals");
    if (last.isAsync) {
      last.resolve(result);
      queue.pop();
      view.show();
    } else {
      throw new Error("ModalView.resolve called when an async modal was not on top.");
    }
  };

  /**
   * Rejects the promise on an asynchronous modal (created with @link async).
   *
   * Closes the async modal and pops it out of the queue in the process.
   *
   * @param {mixed} [result] a result to respond with, determined by the modal dialog
   */
  view.reject = (result) => {
    const last = end();
    if (!last) throw new Error("ModalView.reject called with no queued modals");
    if (last.isAsync) {
      last.reject(result);
      queue.pop();
      view.show();
    } else {
      throw new Error("ModalView.reject called when an async modal was not on top.");
    }
  };

  /**
   * Raises a confirm dialog.
   *
   * Parameters are the same as the modal confirm component.
   *
   * @see {module:ui/component/modal/confirm~confirm}
   * @function customConfirm
   */
  view.customConfirm = ({
    title = null,
    body = null,
    confirmLabel = "Yes",
    cancelLabel = "No",
    onConfirm = () => view.close(),
    onCancel = () => view.close(),
    /* eslint-disable-next-line no-shadow */
    selector = "",
    showCloseFab = false,
    dangerous = false,
  }) => {
    view.show(confirm({
      title,
      body,
      confirmLabel,
      cancelLabel,
      onConfirm,
      onCancel,
      showCloseFab,
      selector,
      dangerous,
    }, view), false);
  };

  /**
   * Raises a alert dialog with full control over the callback function.
   *
   * Parameters are the same as the modal alert component.
   *
   * @function customAlert
   * @see {module:ui/component/modal/alert~alert}
   * @see {module:ui/view/modal-view/alert}
   */
  view.customAlert = ({
    title = null,
    body = null,
    confirmLabel = "Ok",
    onConfirm = () => view.close(),
    sel = "",
    dangerous = false,
  }) => {
    view.show(alert({
      title,
      body,
      confirmLabel,
      onConfirm,
      sel,
      dangerous,
    }, view), false);
  };

  /**
   * A drop-in replacement for `window.confirm()`, except as a promise and some
   * additional optional params.
   *
   * @function confirm
   * @param {string} [title] text to be shown in the title area
   * @param {string} [body] text to be shown in the body area
   * @param {string} [confirmLabel="Yes"] text for the confirm button
   * @param {string} [cancelLabel="No"] text for the confirm button
   * @param {string} [dangerous=false] whether the dialog should indicate a dangerous
   *                                   action
   * @param {boolean} showCloseFab whether to show close icon
   * @param {boolean} warn whether to show warn cancel button
   *
   * @return {boolean} true if confirmed, false if canceled
   */
  view.confirm = (
    title = null,
    body = null,
    confirmLabel = "Yes",
    cancelLabel = "No",
    dangerous = false,
    showCloseFab = false,
    warn = false,
  ) => view.async(
    confirm({
      title,
      body,
      confirmLabel,
      cancelLabel,
      isAsync: true,
      onConfirm: () => view.resolve(true),
      onCancel: () => view.resolve(false),
      dangerous,
      showCloseFab,
      warn,
    }, view),
    false,
  );

  /**
   * A drop-in replacement for `window.alert()`, except as a promise.
   *
   * @function alert
   * @param {string} [title] text to be shown in the title area
   * @param {string} [body] text to be shown in the body area
   * @param {string} [confirmLabel="Ok"] label for the confirm button
   * @param {string} [dangerous=false] whether the dialog should indicate a dangerous
   *                                   action
   * @param {string} [sel]
   *
   * @return {boolean} always true
   */
  view.alert = (
    title = null,
    body = null,
    confirmLabel = "Ok",
    dangerous = false,
    sel = "",
  ) => view.async(
    alert({
      title,
      body,
      confirmLabel,
      isAsync: true,
      onConfirm: () => view.resolve(true),
      dangerous,
      sel,
    }, view),
    false,
  );

  /**
   * Destroy the current dialog and replace it with the last item in the queue.
   * If the queue is empty, closes the modal view.
   *
   * @function back
   */
  view.back = () => {
    const last = end();
    if (last) {
      if (last.isAsync) {
        last.resolve(null);
      }
      // this already happened during resolve/reject for async
      queue.pop();
      const next = end();
      if (next) view.show();
      else view.hide();
    }
  };

  /**
   * Hides the modal dialog by emptying it. This depends on some stylesheet
   * handling to hide the modal when it's empty, one way or other.
   *
   * The content is saved to `inner`, and if view.show() is called without
   * an argument it will be retrieved and shown again.
   *
   * @function hide
   * @modifies document.body.classList, removing 'modal-open' class
   */
  view.hide = () => {
    view.patch();
    const headerHeight = 0;
    const scrollY = (parseInt(document.body.style.top, 10) || 0) * -1;
    document.body.classList.remove("modal-open");
    document.body.style.top = "";
    document.body.style.paddingRight = "";

    window.scroll({
      left: 0,
      top: scrollY + headerHeight,
      behavior: "auto",
    });
  };

  /**
   * Close the modal and clear its cache and queue.
   *
   * @function close
   */
  view.close = () => {
    while (queue.length) {
      const last = end();
      if (!last.closeable) {
        return;
      }
      if (last.isAsync) {
        last.resolve(null);
      }
      queue.pop();
    }
    view.hide();
  };

  /**
   * @private
   * @property {function} origPatch backup the original patch function
   *                                - we need to wrap it
   */
  const origPatch = view.patch;

  function onOverlayClick() { // must not be anonymous
    if (end()?.closeable) view.back();
  }

  /**
   * Wrapper around {@link module:ui/view/view~patch}.
   */
  view.patch = (newNodes) => {
    inner = newNodes || inner;
    origPatch(wrap(view.selector, newNodes, onOverlayClick));
  };

  Object.defineProperties(view, {
    show: {
      enumerable: false,
      configurable: false,
    },
    hide: {
      enumerable: false,
      configurable: false,
    },
    validate: {
      enumerable: true,
      configurable: false,
    },
    queueLength: {
      get: () => queue.length,
      configurable: false,
    },
  });

  if (inner) view.show();
  return view;
  /* eslint-enable no-param-reassign */
  /* eslint-enable object-shorthand */
}

/**
 * Bootstraps a modal dialog view.
 *
 * Set up by the page module layout helper, so it should not need to be called.
 *
 * @function init
 * @param {module:ui/view/view~ComponentFactory} [componentFactory=defaultComponentFactory]
 * @param {module:ui/view/view~UpdateFn} [updateFn=defaultUpdateFn]
* @return {module:ui/view/view~ViewInitializer}
 */
export default (componentFactory, updateFn) => (selector, initState) => {
  const self = bootstrapModal(baseView(selector, initState, componentFactory, updateFn));
  self.update(initState);
  storedModalView = self;
  return self;
};

export const getModal = () => {
  if (!storedModalView) {
    throw new Error("MODAL VIEW :: tried to retrieve stored modal before it was initialized!");
  }
  return storedModalView;
};
