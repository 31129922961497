/**
 * Paired date + time picker with unified validation.
 *
 * @module ui/component/form-managed/date-time
 * @category UI
 * @subcategory Forms
 */
import { startOfDay } from "date-fns/fp";
import {
  hourMinute,
  iso8601,
  parseYMDHM,
  yearMonthDay,
} from "util/date";
import div from "ui/html/div";
import input from "ui/html/input";
import { makeInputBindings } from "ui/component/form-managed/field-input";
import genericField from "ui/component/form-managed/field-generic";
import { copyValidity, managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Please select a valid date and time.",
  required: "Please select a date and time.",
  rangeUnderflow: "Please select a later date and time.",
  rangeOverflow: "Please select an earlier date and time.",
  dateMissing: "Please select a date.",
  timeMissing: "Please select a time.",
});

const defaultState = frozen({
  helpTexts,
  name: "dateTime",
  label: "Date & Time",
  sel: "",
  value: null,
  min: null,
  max: null,
  onChange: () => {},
});

const getValue = (node) => {
  const { name } = node.managedField;
  const date = node.elm.querySelector(`[name="${name}--date"]`).value;
  let time = node.elm.querySelector(`[name="${name}--time"]`).value;
  if (!time) {
    time = "00:00";
  }
  try {
    return iso8601(parseYMDHM(`${date} ${time}`));
  } catch (e) {
    return null;
  }
};

const onDateChange = (time, state) => ({
  /* eslint-disable no-param-reassign */
  on: {
    change: ({ target: date }) => {
      if (!date.value) return;
      const value = startOfDay(parseYMDHM(`${date.value} 00:00`));
      if (state.max) {
        if (value.getTime() === startOfDay(state.max).getTime()) {
          time.elm.max = hourMinute(state.max);
        } else {
          time.elm.max = "";
        }
      }
      if (state.min) {
        if (value.getTime() === startOfDay(state.max).getTime()) {
          time.elm.min = hourMinute(state.min);
        } else {
          time.elm.min = "";
        }
      }
      state.onChange(getValue(state.node));
    },
  },
  /* eslint-enable no-param-reassign */
});

const onTimeChange = (state) => ({
  on: {
    change: () => state.onChange(getValue(state.node)),
  },
});

const validDateTime = (state) => (node, validity) => {
  const { required, min, max } = state;
  /* eslint-disable no-param-reassign */
  const { name } = node.managedField;
  const date = node.elm.querySelector(`[name="${name}--date"]`);
  const time = node.elm.querySelector(`[name="${name}--time"]`);

  const dateValid = copyValidity(date.validity);
  const timeValid = copyValidity(time.validity);

  const dateTimeValid = merge(dateValid, timeValid);
  dateTimeValid.valid = dateValid.valid && timeValid.valid;

  // note below that copyValidity will not copy custom keys, so we set them directly
  // on the validity object instead of dateTimeValid
  if (required && dateValid.valueMissing === true && timeValid.valueMissing === false) {
    validity.timeMissing = true;
    dateTimeValid.valueMissing = false;
    dateTimeValid.valid = false;
  }

  if (required && dateValid.valueMissing === false && timeValid.valueMissing === true) {
    validity.dateMissing = true;
    dateTimeValid.valueMissing = false;
    dateTimeValid.valid = false;
  }

  if (!date.value && time.value) {
    validity.dateMissing = true;
    dateTimeValid.valid = false;
  }

  const value = getValue(node);

  // check that there isn't a range underflow when accounting for time
  if (value && min && iso8601(value) < iso8601(min)) {
    dateTimeValid.rangeUnderflow = true;
    dateTimeValid.valid = false;
  }

  // check that there isn't a range overflow when accounting for time
  if (value && max && iso8601(value) > iso8601(max)) {
    dateTimeValid.rangeOverflow = true;
    dateTimeValid.valid = false;
  }

  Object.keys(copyValidity(dateTimeValid)).forEach((k) => {
    if (dateTimeValid[k]) validity[k] = dateTimeValid[k];
  });

  validity.valid = dateTimeValid.valid || false;

  /* eslint-enable no-param-reassign */
};

const isSameDay = (start, end) => yearMonthDay(start) === yearMonthDay(end);

/**
 * A paired date + time field.
 *
 * Note that min & max when provided will not be validated until both the date and the time
 * component are populated.
 *
 * @function textInput
 * @param {object} state
 * @param {boolean} [state.disabled=false]
 * @param {string} [state.label="Date & Time"]
 * @param {?Date|string} state.max maximum date/time as a valid Date or date time string
 * @param {?Date|string} state.min minimum date/time as a valid Date or date time string
 * @param {string} [state.name="dateTime"]
 * @param {boolean} [state.required=false]
 * @param {Selector} [state.sel=""]
 * @param {?Date|string} [state.value=""] a valid Date or date time string
 * @return {module:ui/common/el~El}
 */
export default function dateTimePicker(inState = {}) {
  const state = merge(defaultState, inState);
  // munge into a date so we can handle iso8601 and other date strings as well as date objects
  state.min = state.min ? new Date(state.min) : null;
  state.max = state.max ? new Date(state.max) : null;

  const time = input.time(
    state.value ? hourMinute(state.value) : "",
    state.required,
    `${state.name}--time`,
    makeInputBindings({ ...state, autocomplete: "off" }),
    state.value && state.min && isSameDay(state.min, state.value)
      ? { attrs: { min: hourMinute(state.min) } }
      : {},
    onTimeChange(state),
  );

  const date = input.date(
    state.value ? yearMonthDay(state.value) : "",
    state.required,
    `${state.name}--date`,
    makeInputBindings({ ...state, autocomplete: "off" }),
    state.min ? { attrs: { min: yearMonthDay(state.min) } } : {},
    state.max ? { attrs: { max: yearMonthDay(state.max) } } : {},
    onDateChange(time, state),
  );

  const node = div(".inner", [date, time]);
  state.node = node;
  return genericField(
    state,
    [
      managedField(
        node,
        state.name,
        getValue,
        [validDateTime(state)],
      ),
    ],
    `${state.sel}.date-time`,
  );
}
