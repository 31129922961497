/**
 * The color palette, stored in the site-config table.
 *
 * @module model/site/color-palette
 * @category Model
 * @subcategory Site
 */
import { validInt, validMember, validObject } from "model/constraints";
import { COLOR_PALETTE_KEY, paletteModes, paletteModesSet } from "./constants";

/**
 * A color palette.
 *
 * @typedef ColorPalette
 * @property {object} entries
 * @property {int} version version number (for potential future migration)
 */

/**
 * Maps an API response to a ColorPalette
 * @function responseToColorPalette
 * @param {object} response
 * @return {ColorPalette}
 */
export const responseToColorPalette = (response) => ({
  version: parseInt(response.version, 10),
  colors: response.colors,
  mode: response.mode || paletteModes.LIGHT,
});

/**
 * Validates a color palette for storage in the site config database.
 */
export const makeColorPaletteDTO = (partial) => ({
  [COLOR_PALETTE_KEY]: JSON.stringify({
    version: validInt(partial.version, "ColorPalette.version"),
    colors: validObject(partial.colors, "ColorPalette.colors"),
    mode: validMember(partial.mode, "ColorPalette.mode", paletteModesSet),
  }),
});
