import { merge } from "util/object";
import { div, span } from "ui/html";
import chatUserListModal from "ui/component/modal/chat/chat-user-list";
import getAvatar from "ui/component/messaging/avatar";

const defaultState = {
  users: [],
  onUnblockUser: () => {},
  onClickUser: () => {},
};

export default function channelUsersPreview(inState, modalView) {
  const state = merge(defaultState, inState);

  return state.users?.length > 0
    ? div(
      ".chat-container-group-users",
      {
        on: {
          click: async () => {
            const user = await modalView.async(chatUserListModal({
              users: state.users,
              blockedUserIds: state.blockedUserIds,
              onUnblockUser: state.onUnblockUser,
              selectable: true,
            }, modalView));
            if (user) state.onClickUser(user.id);
          },
        },
      },
      [
        ...state.users.map((user) => (
          span(getAvatar(user), ".user-avatar")
        )).splice(0, 3),
        span(state.users.length, ".users-length"),
      ],
    )
    : "";
}
