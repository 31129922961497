/**
 * A modal dialog for selecting a course module.
 *
 * @module ui/component/modal/course/module-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { courseModuleTitleFilter } from "util/filter";
import { courseModuleTitleSort } from "util/sort";
import cameoModule from "ui/component/cameo-module";
import listModal from "ui/component/modal/layout/list";

const doSearch = (modules, notificationView) => (search, modalView) => {
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(moduleSelectModal(
    { modules, search },
    modalView,
    notificationView,
  ));
};

export default function moduleSelectModal({
  modules,
  search = "",
}, modalView, notificationView) {
  const searchResults = (search
    ? modules.filter(courseModuleTitleFilter(search))
    : [...modules])
    .sort(courseModuleTitleSort());
  return listModal({
    entries: searchResults.map((module) => cameoModule({
      module,
      config: {
        on: {
          click: () => {
            modalView.resolve(module);
          },
        },
      },
    }, modalView, notificationView)),
    onSearch: doSearch(modules, notificationView),
    search,
  }, modalView);
}
