/**
 * Assessment data stream.
 *
 * Provides `getBatch` interface for fetching assessments by
 * `[courseId, moduleId, assessmentIds]`.
 *
 * @module data/course/assessment
 * @category Data Streams
 * @subcategory Course
 */
import xs from "xstream";
import { byIds } from "api/v2/course/assessment";
import placeholder from "model/common/placeholder";
import { CACHE_COURSE_ASSESSMENT_KEY_V2 as CACHE_KEY } from "cache/constants";
import batch from "data/pipeline/batch";
import { streamLog } from "data/stream/compose";
import { makeAssessment } from "model/course";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const all$ = xs.create();

const byIdsFlat = (args) => byIds(...args);

const defaultFn = ([courseId, moduleId]) => (id) => makeAssessment(
  { id, courseId, moduleId },
);

const {
  post: getBatch,
  sink$: getBatchSink$,
  pending$,
} = batch({
  apiFn: byIdsFlat,
  defaultFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const get = (courseId, moduleId, assessmentId) => getBatch([
  courseId,
  moduleId,
  [assessmentId],
]).map((assessments) => assessments?.get(assessmentId) || placeholder(assessmentId))
  .compose(streamLog("ASSESSMENT_GET$"));

const out$ = xs.merge(getBatchSink$);

all$.imitate(out$);
all$.compose(streamLog("ASSESSMENT_ALL$"));

export default {
  /**
   * Get single assessment.
   *
   * @function get
   * @param {UUID} courseId
   * @param {UUID} moduleId
   * @param {UUID} assessmentId
   * @returns {Stream<Assessment>}
   */
  get,
  /**
   * Get batch of assessments.
   *
   * @function getBatch
   * @param {UUID} courseId
   * @param {UUID} moduleId
   * @param {UUID[]} assessmentIds
   * @returns {Stream<HashMap<UUID[], Assessment>>}
   */
  getBatch,
  all$,
  pending$,
};
