import h4 from "ui/html/h4";
import collapsible from "ui/component/collapsible";
import checklist from "ui/component/form-managed/field-checklist";

export default function toggles(self) {
  const required = true;

  return collapsible({
    title: "Checklists",
    open: self.state.checklistOpen,
    onChange: (checklistOpen) => self.updateState({ checklistOpen }),
  }, self.bind([
    h4("Checklist"),
    [checklist, {
      name: "checklist",
      onChange: (values) => console.log("checklist values", values),
      entries: [
        { label: "Primary", value: "1", sel: ".primary" },
        { label: "Alternate", value: "2", sel: ".alternate" },
        { label: "Secondary", value: "secondary", sel: ".secondary" },
        { label: "Warning", value: "warning", sel: ".warn" },
        { label: "Danger", value: "danger", sel: ".danger" },
      ],
      required,
    }],
    h4("Boxed Checklist"),
    [checklist.boxed, {
      name: "boxed-checklist",
      onChange: (values) => console.log("checklist values", values),
      entries: [
        { label: "Primary", value: "1", sel: ".primary" },
        { label: "Alternate", value: "2", sel: ".alternate" },
        { label: "Secondary", value: "secondary", sel: ".secondary" },
        { label: "Warning", value: "warning", sel: ".warn" },
        { label: "Danger", value: "danger", sel: ".danger" },
      ],
      required,
    }],
  ], { checklist: ["1", "2"], "boxed-checklist": ["1", "2"] }),
  );
}
