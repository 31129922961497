/**
 * Add FAQ entry form modal
 *
 * @module ui/component/modal/section-edit/faq-add-entry
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import form from "ui/component/form-managed";
import formManagedView from "ui/view/form-managed";
import input from "ui/component/form-managed/field-input";
import textarea from "ui/component/form-managed/field-textarea";
import { div, h3 } from "ui/html";
import button from "ui/component/form-managed/button";
import { merge } from "util/object";

let count = 0;
let formView;

const defaultState = {
  entry: null,
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  inner.patch(inner.factory(inner));
};

const buildForm = (state) => (self) => {
  const { entry } = state;
  return form(
    "#faq-add-entry",
    self.bind([
      [input, { label: "Entry Label", required: true, name: "label" }],
      [textarea, { label: "Entry Text", required: true, name: "text" }],
    ], entry),
  );
};

const createForm = (state, modalView) => {
  const formElement = document.createElement("form");
  formElement.id = "faq-add-entry";
  modalView.element.querySelector(".body")
    .replaceChildren(formElement);
  formView = formManagedView(buildForm(state), onFormUpdate)("#faq-add-entry", state);
};

const onSubmit = (modalView) => {
  const { values } = formView;
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  modalView.resolve(values);
};

export default function faqAddEntryModal(inState, modalView) {
  const state = merge(defaultState, inState);

  return dialog({
    header: h3(state.entry ? "Edit Entry" : "Add Entry"),
    config: {
      hook: {
        insert: () => createForm(state, modalView),
        postPatch: () => createForm(state, modalView),
      },
      dataset: { advancedSearchId: (++count) },
    },
    body: form("#faq-add-entry"),
    footer: div(".buttons", { attrs: { role: "group" } }, [
      button.primary({
        label: state.entry ? "Save" : "Add",
        onClick: () => onSubmit(modalView),
      }),
    ]),
  }, modalView);
}
