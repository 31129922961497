/**
 * Utility function for users processing.
 *
 * @module util/user
 * @category Utilities
 */
import { userTypes } from "model/user/constants";

/**
 * Checks whether the given user is anonymous
 *
 * @param {User} user
 * @returns {boolean}
 */
// eslint-disable-next-line import/prefer-default-export
export const isAnonymous = (user) => user?.userType === userTypes.ANONYMOUS;
