/**
 * A content card for displaying a metadata item, used in grids and carousels.
 *
 * @module ui/component/card/content
 * @category UI
 * @subcategory Components
 */
import button from "ui/component/form-managed/button";
import { a, div, h3, img, span } from "ui/html";
import { metadataTypes } from "model/metadata/constants";
import placeholder from "ui/component/placeholder";
import viewButton from "ui/component/button-content-view";
import resumeButton from "ui/component/button-video-resume";
import { cardFormatOptions } from "model/dynamic-page/constants";
import icon from "ui/component/icon";
import { desc, openModalButton, position, posterPlaceholder } from "./common";

const quickView = (metadata, playback, cardSize = cardFormatOptions.VERTICAL) => {
  if (metadata.type === metadataTypes.VIDEO) {
    return div(".quick-view", [
      viewButton({ metadata, sel: ".main" }),
      resumeButton({ metadata, playback }),
    ]);
  }
  if (metadata.type === metadataTypes.LIST && cardSize === cardFormatOptions.HORIZONTAL) {
    const btn = openModalButton(metadata);
    // hack in a larger display for this button
    btn.sel += ".main";
    return div(".quick-view", btn);
  }
  const btn = viewButton({ metadata, sel: ".main" });
  if (btn) return div(".quick-view", btn);
  return div();
};

const makeCb = (node, url) => () => {
  /* eslint-disable no-param-reassign */
  node.elm.style.backgroundImage = `url(${url})`;
  setTimeout(() => {
    node.elm.dataset.imageLoaded = "loaded";
  }, 250); // give it a little time to draw itself
  /* eslint-enable no-param-reassign */
};

const poster = (url, metadata, playback) => {
  const post = div(
    ".poster",
    {
      hook: {
        /* eslint-disable-next-line no-param-reassign */
        insert: (node) => { node.elm.dataset.imageLoaded = "not-loaded"; },
      },
    },
  );
  post.children = [
    quickView(metadata, playback),
    img(".preloader", url, "", {
      on: { load: makeCb(post, url) },
    }),
  ];
  return post;
};

const titleBlock = (title, temp) => (title || !temp
  ? h3(title, ".title", { props: { title } })
  : h3(placeholder(), ".title")
);

const searchLabelUrl = (label) => `/search?label=${label.name.trim()}`;

const labels = (metadata) => div(
  ".labels",
  (metadata?.categories?.length
    ? [
      ...metadata.categories.map((label) => a(
        [icon("tag"), span(label.name)],
        searchLabelUrl(label),
      )),
    ]
    : ""
  ),
);

/**
 *
 * @function contentCard
 * @param {module:api/types~MetadataItem} metadata
 * @param {number} playbackPosition
 * @param {cardFormatOptions} cardSize
 * @return {module:ui/common/el~El}
 */
export default function contentCard({
  metadata = {},
  playbackPosition = null,
  cardSize = cardFormatOptions.VERTICAL,
}) {
  if (metadata === null) return "";
  const {
    description,
    length,
    title,
    PLACEHOLDER,
    posterFile,
    posterUrl,
  } = metadata;
  if (PLACEHOLDER) return contentCard.placeholder();

  if (cardSize === cardFormatOptions.HORIZONTAL) {
    return div(`.content-card.big`, [
      div(".pane.poster-pane", [
        posterFile && posterUrl
          ? poster(posterUrl, metadata, playbackPosition)
          : posterPlaceholder(),
        position(playbackPosition?.position || 0, length),
      ]),
      div(".pane.info-pane", [
        div(".info-content", [
          titleBlock(title),
          desc(description, false, cardFormatOptions.HORIZONTAL),
          labels(metadata),
        ]),
        div('.info-footer', [
          span(openModalButton(metadata), ".mobile"),
          quickView(metadata, playbackPosition, cardSize),
        ]),
      ]),
    ]);
  }

  return div(`.content-card`, [
    posterFile ? poster(posterUrl, metadata, playbackPosition)
      : posterPlaceholder(),
    position(playbackPosition?.position || 0, length),
    titleBlock(title),
    desc(description),
    openModalButton(metadata),
  ]);
}

contentCard.placeholder = () => div(".content-card", [
  posterPlaceholder(),
  position(0, 100),
  h3(placeholder(), ".title"),
  div(".description", placeholder()),
  button({
    label: "view",
    disabled: true,
  }),
]);
