/**
 * The `main` structural element.
 *
 * @module ui/html/main
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * Creates a `<main></main>` element.
 *
 * @function main
 * @param {string} sel css selectors
 * @param {mixed} ...args addtional arguments as per {@link ui/common/el}
 * @return {module:ui/common/el~El}
 *
 */
export default (sel = "", ...args) => el(`main${sel}`, ...args);
