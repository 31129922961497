/**
 * Avatar for admin messages.
 *
 * @module ui/component/messaging/avatar
 * @category UI
 * @subcategory Messaging Components
 */
export default function getAvatar(user) {
  if (!user || user.PLACEHOLDER) return "?";
  let initials = "";
  if (user.firstName) {
    initials = user?.firstName?.[0]?.toUpperCase();
  }
  if (user.firstName && user.lastName) {
    initials = `${initials}${user?.lastName?.[0]?.toUpperCase()}`;
  }
  if (!user.firstName) {
    initials = user?.name?.[0]?.toUpperCase() || user?.username?.[0]?.toUpperCase();
  }
  return initials || "?";
}
