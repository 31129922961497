/**
 * Messages used by the bulk content form.
 *
 * @module ui/page/admin/content/bulk/messages
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import { frozen } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";

export default frozen({
  ok: {
    title: "Success",
    text: `Content Added to SmartEdge.`,
    type: notificationMessageTypes.SUCCESS,
    duration: 3,
  },
  invalid: {
    title: "Invalid Form",
    text: "Please correct the above errors.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
  metadataPermissionError: {
    title: "Error",
    text: "You do not have permission to create content records.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
  filePermissionError: {
    title: "Error",
    text: "You do not have permission to create content files.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
  csvParseSuccess: {
    title: "Success",
    text: "Completed CSV upload.",
    type: notificationMessageTypes.SUCCESS,
    duration: 3,
  },
  fatalError: {
    title: "Error",
    text: "Couldn't upload a content.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
});
