/**
 * Data streams for cache update messages.
 *
 * @module data/cache-update
 */
import { messageTypes, cacheUpdateTypes } from "@smartedge/em-message/constants";
import { getServerStream, sendCacheUpdate } from "api/message";
import { getMe } from "api/v2/user/me";
import log from "log";
import cache, { USER_PROFILE } from "cache";
import * as v2Keys from "cache/constants";
import * as metadataCache from "api/metadata/cache";
import * as courseCache from "api/course/cache";
import * as pageCache from "api/page/constants";
import {
  COLOR_PALETTE_KEY,
  CONFIGURATION_KEY,
  MAIN_MENU_KEY,
} from "model/site/constants";

let initialized = false;
let cache$;

// FIXME: when old caches are no longer used this can be simplified
const cacheKeys = new Map([
  [cacheUpdateTypes.DOCUMENT_METADATA, [
    v2Keys.CACHE_DOCUMENT_KEY_V2,
    v2Keys.CACHE_ABSTRACT_ITEM_KEY_V2,
    metadataCache.CACHE_DOCUMENT_KEY,
  ]],
  [cacheUpdateTypes.VIDEO_METADATA, [
    v2Keys.CACHE_VIDEO_KEY_V2,
    v2Keys.CACHE_ABSTRACT_ITEM_KEY_V2,
    metadataCache.CACHE_VIDEO_KEY,
    metadataCache.CACHE_PLAYBACK_KEY,
  ]],
  [cacheUpdateTypes.PLAYLIST_METADATA, [
    v2Keys.CACHE_PLAYLIST_KEY_V2,
    v2Keys.CACHE_ABSTRACT_ITEM_KEY_V2,
    metadataCache.CACHE_PLAYLIST_KEY,
  ]],
  ["META_SEARCH", [
    v2Keys.CACHE_SEARCH_DOCUMENT_KEY_V2,
    v2Keys.CACHE_SEARCH_PLAYLIST_KEY_V2,
    v2Keys.CACHE_SEARCH_VIDEO_KEY_V2,
    v2Keys.CACHE_SEARCH_ABSTRACT_ITEM_KEY_V2,
    metadataCache.CACHE_SEARCH_DOCUMENT_KEY,
    metadataCache.CACHE_SEARCH_PLAYLIST_KEY,
    metadataCache.CACHE_SEARCH_PRESET_KEY,
    metadataCache.CACHE_SEARCH_VIDEO_KEY,
  ]],
  [cacheUpdateTypes.MENU, [
    MAIN_MENU_KEY,
  ]],
  [cacheUpdateTypes.SITE, [
    COLOR_PALETTE_KEY,
    CONFIGURATION_KEY,
  ]],
  [cacheUpdateTypes.USER, [
    v2Keys.CACHE_USER_KEY_V2,
    v2Keys.CACHE_USER_ABBREVIATED_V2,
  ]],
  ["USER_SELF", [
    USER_PROFILE,
  ]],
  ["USER_SEARCH", [
    v2Keys.CACHE_SEARCH_USER_KEY_V2,
  ]],
  [cacheUpdateTypes.PAGE, [
    v2Keys.CACHE_PAGE_KEY_V2,
    pageCache.CACHE_PAGE_KEY,
  ]],
  ["PAGE_SLUG", [
    v2Keys.CACHE_PAGE_SLUG_KEY_V2,
    pageCache.CACHE_PAGE_SLUG_KEY,
  ]],
  [cacheUpdateTypes.COURSE, [
    v2Keys.CACHE_COURSE_KEY_V2,
    courseCache.CACHE_COURSE_KEY,
  ]],
  ["COURSE_COMPONENT", [
    v2Keys.CACHE_COURSE_MODULE_KEY_V2,
    courseCache.CACHE_COURSE_MODULE_KEY,
    v2Keys.CACHE_COURSE_LECTURE_KEY_V2,
    courseCache.CACHE_COURSE_LECTURE_KEY,
    v2Keys.CACHE_COURSE_ASSESSMENT_KEY_V2,
    courseCache.CACHE_COURSE_ASSESSMENT_KEY,
  ]],
]);

const clearKeyedCacheEntries = (updateType, itemKey) => {
  cacheKeys.get(updateType).forEach((cacheKey) => {
    cache.deleteExpiringMapEntry(cacheKey, itemKey);
  });
};

const courseComponentFilter = (id) => (v) => v.courseId === id;

const clearCache = ({ updateType, objectId }) => {
  let filter;
  const me = cache.getProfile();
  log.debug("CACHE:CLEAR :: ", updateType, objectId);
  switch (updateType) {
    case cacheUpdateTypes.MENU:
    case cacheUpdateTypes.SITE:
      cacheKeys.get(updateType).forEach((key) => {
        cache.deleteValue(key);
      });
      break;
    case cacheUpdateTypes.PAGE:
      clearKeyedCacheEntries(updateType, objectId);
      cacheKeys.get("PAGE_SLUG").forEach((cacheKey) => {
        cache.findExpiringMapEntryKeys(cacheKey, (v) => v === objectId)
          .forEach((itemKey) => cache.deleteExpiringMapEntry(cacheKey, itemKey));
      });
      break;
    case cacheUpdateTypes.COURSE:
      clearKeyedCacheEntries(updateType, objectId);
      filter = courseComponentFilter(objectId);
      cacheKeys.get("COURSE_COMPONENT").forEach((cacheKey) => {
        cache.deleteMatchingExpiringMapEntries(cacheKey, filter);
      });
      cacheKeys.get("COURSE_SEARCH").forEach((key) => {
        cache.deleteValue(key);
      });
      break;
    case cacheUpdateTypes.DOCUMENT_METADATA:
    case cacheUpdateTypes.VIDEO_METADATA:
    case cacheUpdateTypes.PLAYLIST_METADATA:
      clearKeyedCacheEntries(updateType, objectId);
      // all searches always get cleared because a changed or deleted
      // item could appear in any of them
      // TODO consider more targeted approach, but this may have weird consequences
      cacheKeys.get("META_SEARCH").forEach((key) => {
        cache.deleteValue(key);
      });
      break;
    case cacheUpdateTypes.USER:
      clearKeyedCacheEntries(updateType, objectId);
      cacheKeys.get("USER_SEARCH").forEach((key) => {
        cache.deleteValue(key);
      });
      // if user's own profile was changed they need to resync
      // their whole cache, because their access rights might have
      // changed
      if (me?.id === objectId) {
        [...cacheKeys.values()].flat().forEach((key) => {
          cache.deleteValue(key);
        });
        // now refetch the user's profile, since some things want it in cache early
        getMe().then((res) => cache.storeProfile(res));
      }
      break;
    default:
      break;
  }
};

/**
 * Initializes the cache update stream.
 *
 * @function init
 * @private
 */
const init = () => {
  if (!initialized) {
    initialized = true;
    // listen for cache updates
    cache$ = getServerStream(messageTypes.CACHE_UPDATE).map(({ message }) => message);
    cache$.addListener({
      next: (msg) => {
        clearCache(msg);
      },
      complete: () => log.debug("CACHE: update stream ended"),
      error: (e) => log.error(e),
    });
  }
};

init();

const clearMenu = async () => {
  const updateType = cacheUpdateTypes.MENU;
  clearCache({ updateType });
  await sendCacheUpdate(updateType);
};

const clearPage = async (objectId) => {
  const updateType = cacheUpdateTypes.PAGE;
  clearCache({ updateType, objectId });
  await sendCacheUpdate(updateType, objectId);
};

const clearUser = async (objectId) => {
  const updateType = cacheUpdateTypes.USER;
  clearCache({ updateType, objectId });
  await sendCacheUpdate(updateType, objectId);
};

const clearDocument = async (objectId) => {
  const updateType = cacheUpdateTypes.DOCUMENT_METADATA;
  clearCache({ updateType, objectId });
  await sendCacheUpdate(updateType, objectId);
};

const clearPlaylist = async (objectId) => {
  const updateType = cacheUpdateTypes.PLAYLIST_METADATA;
  clearCache({ updateType, objectId });
  await sendCacheUpdate(updateType, objectId);
};

const clearVideo = async (objectId) => {
  const updateType = cacheUpdateTypes.VIDEO_METADATA;
  clearCache({ updateType, objectId });
  await sendCacheUpdate(updateType, objectId);
};

export default {
  init,
  clearDocument,
  clearMenu,
  clearPage,
  clearPlaylist,
  clearUser,
  clearVideo,
};
