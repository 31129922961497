/**
 * We have to pull some shenanigans here to replace the elements managed
 * by the modalView vdom with the elements managed by the formView vdom.
 * Otherwise the formView's stored element will no longer point to the
 * live version of #section-edit, which will have been replaced with a new
 * version when the modalView updates.
 *
 * @todo eventually a built-in way to manage nested views like this.
 *
 * @module ui/component/modal/section-edit/common
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */

/**
 * @function bindForm
 * @private
 */
/* eslint-disable-next-line import/prefer-default-export */
export const bindForm = (storedFormView, modalView) => {
  const formEl = document.createElement("FORM");
  formEl.id = "section-edit";
  modalView.element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#section-edit");
  const titleInput = document.querySelector(`#section-edit input[name="title"]`);
  if (titleInput) titleInput.focus();
};
