/**
 * The main admin page for managing evaluations.
 *
 * Corresponds to `markup/admin/manage-evaluations.html`
 *
 * @module ui/page/admin/evaluation/manage
 * @category Pages
 * @subcategory Admin - Evaluations
 */
import api from "api";
import { EXPAND_ASSESSMENTS, EXPAND_COURSES, EXPAND_GRADING } from "api/course/constants";
import actionBar from "ui/component/dashboard-action-bar";
import div from "ui/html/div";
import widget from "ui/component/dashboard-widget";
import { merge } from "util/object";
import view from "ui/view";
import tabs from "ui/component/tabs";
import advancedEvaluationSearchModal from "ui/component/modal/advanced-evaluation-search";
import actionBarSearch from "ui/component/action-bar-search";
import { sortOrders } from "ui/component/dynamic-table";
import { evaluationSortCriteria, evaluationStatus } from "model/course/constants";
import dashboardLayout from "ui/page/layout/dashboard";
import {
  defaultManageState as defaultState,
  defaultEvaluationsPage,
  manageModeTabIndex,
  defaultSearchParams,
  manageModes,
  searchModes,
} from "./state";
import showUngradedEvaluations from "./ungraded-table";
import showGradedEvaluations from "./graded-table";

let modalView;
let manageEvaluationsView;
let searchView;

/**
 * Figures out which evaluationSortCriteria to be used based on sortColumn and sortOrder
 * state properties.
 *
 * @function getSortCriteria
 * @private
 */
const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "fullName":
      return (sortOrder === sortOrders.ASC
        ? evaluationSortCriteria.FULLNAME_ASC
        : evaluationSortCriteria.FULLNAME_DESC);
    case "date":
      return (sortOrder === sortOrders.ASC
        ? evaluationSortCriteria.DATE_ASC
        : evaluationSortCriteria.DATE_DESC);
    case "status":
      return (sortOrder === sortOrders.ASC
        ? evaluationSortCriteria.STATUS_ASC
        : evaluationSortCriteria.STATUS_DESC);
    case "title":
    default:
      return (sortOrder === sortOrders.ASC
        ? evaluationSortCriteria.TITLE_ASC
        : evaluationSortCriteria.TITLE_DESC);
  }
};

const getStatusSearchParam = (mode) => {
  switch (mode) {
    case manageModes.INCOMPLETE:
      return evaluationStatus.INCOMPLETE;
    case manageModes.UNGRADED:
      return evaluationStatus.COMPLETE;
    case manageModes.GRADED:
      return evaluationStatus.SCORED;
    default:
      return "";
  }
};

const buildSearchParams = (theView) => {
  const { state } = theView;
  const {
    searchParams, sortColumn, sortOrder, mode,
  } = state;
  const builtParams = {
    assessmentId: searchParams.assessment?.id,
    userId: searchParams.user?.id,
    search: searchParams.search,
    sortBy: getSortCriteria(sortColumn, sortOrder),
    status: getStatusSearchParam(mode),
  };
  Object.keys(builtParams)
    .forEach((key) => builtParams[key] === undefined && delete builtParams[key]);
  return builtParams;
};

const onPageChange = async (pageNumber) => {
  manageEvaluationsView.update({
    evaluationsLoading: true,
    searchParams: {
      pageNumber,
    },
    evaluationsPage: defaultEvaluationsPage,
  });

  const evaluationsPage = await api.course.getEvaluations({
    ...buildSearchParams(manageEvaluationsView),
    pageNo: pageNumber,
  }, merge.all([EXPAND_COURSES, EXPAND_GRADING]));

  manageEvaluationsView.update({
    evaluationsPage,
    evaluationsLoading: false,
  });
};

/**
 * Clears search results.
 *
 * @function onClear
 * @private
 */
const onClear = async () => {
  manageEvaluationsView.update({
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  });
  await onPageChange(manageEvaluationsView.state.searchParams.pageNumber);
};

/**
 * Updates the search params with the contents of the basic search filter.
 *
 * @function onSearch
 * @private
 */
const onSearch = async (search) => {
  manageEvaluationsView.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      search,
    },
  });
  await onPageChange(manageEvaluationsView.state.searchParams.pageNumber);
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function onAdvancedSearch
 * @private
 */
const onAdvancedSearch = async () => {
  const searchParams = await modalView.async(
    advancedEvaluationSearchModal({
      params: manageEvaluationsView.state.searchParams,
      users: manageEvaluationsView.state.users,
      courses: manageEvaluationsView.state.courses,
    }, modalView),
  );
  if (searchParams) {
    manageEvaluationsView.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
    await onPageChange(manageEvaluationsView.state.searchParams.pageNumber);
  }
};

const getTitle = (mode) => {
  switch (mode) {
    case manageModes.INCOMPLETE:
      return "Incomplete Evaluations";
    case manageModes.UNGRADED:
      return "Ungraded Evaluations";
    case manageModes.GRADED:
      return "Graded Evaluations";
    default:
      return "";
  }
};

/**
 * Sets up the search action bar.
 *
 * @function showSearch
 * @private
 */
const showSearch = (theView) => actionBarSearch({
  resultCount: theView.state.evaluationsPage?.items?.length || 0,
  filter: theView.state.searchParams.search,
  showClear: theView.state.searchMode !== searchModes.NONE,
  title: getTitle(theView.state.mode),
  onSearch,
  onAdvancedSearch,
  onClear,
});

const showManageEvaluations = (theView) => tabs(
  ["Incomplete", "Ungraded", "Graded"],
  [
    showUngradedEvaluations(theView, onPageChange),
    showUngradedEvaluations(theView, onPageChange),
    showGradedEvaluations(theView, onPageChange),
  ],
  manageModeTabIndex.get(theView.state.mode),
  "#manage-evaluations",
  {
    on: {
      "em:tab-change": async (ev) => {
        theView.update({
          mode: (
            [...manageModeTabIndex.entries()].find((entry) => entry[1] === ev.detail.id)
            || [manageModes.UNGRADED]
          )[0],
          evaluationsPage: defaultEvaluationsPage,
          searchParams: {
            pageNumber: 0,
          },
          evaluationsLoading: true,
        });
        await onPageChange(theView.state.searchParams.pageNumber);
      },
    },
  },
  theView.state.evaluationsLoading,
);

const doUpdate = (self) => {
  self.render();
  if (searchView) searchView.update(self.state);
};

/**
 * @function manageEvaluations
 * @param {module:ui/html~Selector} selector root element for the manage evaluations view
 */
export default async function manageEvaluations(selector) {
  const title = "Manage Evaluations";
  const { modal, loading, header } = dashboardLayout(
    selector,
    [
      actionBar(div("#action-bar-search")),
      widget(div("#manage-evaluations")),
    ],
    title,
    true,
  );
  modalView = modal;

  loading.show();

  const [user, users, courses] = await Promise.all([
    api.user.getMe(),
    api.user.list(),
    api.course.listCourses(EXPAND_ASSESSMENTS),
  ]);

  // doing this in a separate step means above items will be prefetched and cached
  const evaluationsPage = await api.course.getEvaluations({
    pageNo: defaultSearchParams.pageNumber,
    status: evaluationStatus.COMPLETE,
  }, merge.all([EXPAND_COURSES, EXPAND_GRADING]));

  const state = merge(
    defaultState,
    {
      user,
      users,
      evaluationsPage,
      evaluationsLoading: false,
      title,
      courses,
    },
  );

  manageEvaluationsView = view.create(showManageEvaluations, doUpdate)("#manage-evaluations", state);
  searchView = view.create(showSearch)("#action-bar-search", state);
  header.update(state);
  loading.hide();
}
