/**
 * A managed checkbox element.
 *
 * @module ui/component/form-managed/field-checkbox
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import input from "ui/html/input";
import labelEl from "ui/html/label";
import span from "ui/html/span";
import icon from "ui/component/icon";
import { managedField } from "ui/view/form-managed/util";
import { emit, onSpaceKey } from "util/event";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "Please check this box to proceed.",
  invalid: "Please check this box to proceed.",
});

const requiredHook = (required) => (node, validity) => {
  if (!required) return;
  const checkbox = node.elm.querySelector("input");
  if (!checkbox?.checked) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

const getValue = (node) => node.elm.querySelector("input")?.checked === true;

const toggleCheckedState = (display, disabled) => (ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  if (!disabled) {
    const checkbox = display.elm.querySelector("input");
    checkbox.checked = !checkbox.checked;
    emit(checkbox, "change");
  }
};

/**
 * Exported for use by checklist.
 * @function makeLabel
 * @private
 */
export const makeLabel = (state, onClick) => labelEl(
  `${state.label}`,
  state.name,
  null,
  {
    class: { required: state.required },
    attrs: { target: state.name },
    on: { click: onClick },
  },
);

export const makeCheckbox = (state, onChange, onKeydown) => span([
  input.checkbox(
    state.checked,
    state.required,
    state.name,
    {
      key: `${state.name}-${state.value}`,
      sel: state.sel,
      class: {
        disabled: state.disabled,
        inverse: false, // override `inverse` class from boxed entries
      },
      attrs: {
        tabindex: state.disabled ? "-1" : "0",
        value: state.value,
      },
      props: {
        checked: state.checked,
      },
      on: {
        click: (ev) => ev.stopPropagation(),
        change: onChange,
        keydown: onKeydown,
      },
    },
  ),
  icon("check"),
], ".inner-checkbox");

const makeWrapper = (state) => {
  const self = managedField(
    makeCheckbox(
      { ...state, sel: `${state.sel}${state.checkSel}` },
      state.disabled ? undefined : (ev) => {
        const { elm } = self;
        if (!ev.target.checked) {
          state?.onChange?.(false);
        } else {
          state?.onChange?.(true);
        }
        elm.dispatchEvent(
          new Event("em:form-managed-change", { bubbles: true }),
        );
      },
      onSpaceKey((ev) => {
        toggleCheckedState(self, state.disabled)(ev);
      }),
    ),
    state.name,
    getValue,
    [requiredHook(state.required)],
  );
  return self;
};

/**
 * @private
 */
const defaultState = frozen({
  checked: undefined,
  disabled: false,
  helpTexts,
  label: "Checkbox",
  name: "checkbox",
  required: false,
  override: false,
  sel: "",
  checkSel: "",
});
/**
 * A checkbox.
 *
 * @function checkbox
 * @param {object} state
 * @param {string} [state.autocomplete="off"] autocomplete type
 * @param {boolean} [state.checked=false]
 * @param {boolean} [state.disabled=false]
 * @param {string} [state.label="Checkbox"]
 * @param {FieldValidity} [state.validity=defaultValidity]
 * @param {string} [state.name="checkbox"]
 * @param {boolean} [state.required=false] if true form will be considered invalid until
 *        checked
 * @param {string} [state.override=""] override field value with given state.value
 * @param {Selector} [state.sel=""] selectors for form element
 * @param {Selector} [state.checkSel=""] selectors applied to inner checkbox
 *        (uses button styling classes)
 * @param {function} [state.onChange] called when checked or unchecked with new state
 * @return {module:ui/common/el~El}
 */
export default function checkboxField(inState = {}) {
  const state = merge.all([
    defaultState,
    inState,
    { displayLabel: false },
  ]);

  if (!state.override && state.values?.[state?.name]) {
    state.checked = state.values[state.name];
  }

  const makeSel = () => {
    let sel = "";
    if (state.sel) sel += state.sel;
    if (state.disabled) sel += ".disabled";
    if (state.required) sel += ".required";
    if (state.checked) sel += ".checked";
    return sel;
  };

  const makeInnerSel = () => {
    let sel = ".inner";
    if (state.sel) sel += state.sel;
    if (state.disabled) sel += ".disabled";
    sel += ".checkbox";
    return sel;
  };

  const display = makeWrapper(state);

  const label = makeLabel(state, () => toggleCheckedState(display, state.disabled));

  return genericField(
    { ...state, type: "checkbox" },
    span([label, display], makeInnerSel()),
    makeSel(),
    { on: { click: toggleCheckedState(display, state.disabled) } },
  );
}

const checkStyle = (sel) => (state) => {
  /* eslint-disable no-param-reassign */
  if (state.checkSel) state.checkSel += sel;
  else state.checkSel = sel;
  return checkboxField(state);
  /* eslint-enable no-param-reassign */
};

const boxedStyle = (sel) => (state) => {
  /* eslint-disable no-param-reassign */
  if (state.sel) state.sel += `.boxed.inverse${sel}`;
  else state.sel = `.boxed.inverse${sel}`;
  return checkStyle(sel)(state);
  /* eslint-enable no-param-reassign */
};

checkboxField.ok = checkStyle(".ok");
checkboxField.warning = checkStyle(".warn");
checkboxField.accent = checkStyle(".accent");
checkboxField.secondary = checkStyle(".secondary");
checkboxField.alternate = checkStyle(".alternate");
checkboxField.danger = checkStyle(".danger");
checkboxField.boxed = boxedStyle("");
checkboxField.boxed.ok = boxedStyle(".ok");
checkboxField.boxed.accent = boxedStyle(".accent");
checkboxField.boxed.warning = boxedStyle(".warn");
checkboxField.boxed.secondary = boxedStyle(".secondary");
checkboxField.boxed.alternate = boxedStyle(".alternate");
checkboxField.boxed.danger = boxedStyle(".danger");
