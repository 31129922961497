/**
 * The HTML anchor element.
 *
 * @example
 * // an ordinary anchor element
 * const link = ui.html.a("a search engine", "https://duckduckgo.com", "#search-engine.extra-special");
 *
 * // a link that calls a function when it's clicked
 * const fnLink = ui.html.a.fn("click me!", () => alert("You did it!"), "#alarming");
 *
 * @module ui/html/a
 * @category UI
 * @subcategory Elements
 */
import { merge } from "util/object";
import el, { textNodesToSpans } from "ui/common/el";
import { isPWA } from "util/pwa";

const interactKeycodes = [
  13, // enter key
  32, // spacebar
];

/**
 * @function a
 * @param {string|El|El[]} children text label or child elements
 * @param {string} href link URL
 * @param {string} sel class/id selectors in css format
 * @param {object[]} ...configs snabbdom config objects
 *
 * @return {module:ui/common/el~El}
 */
const a = (children, href, sel = "", ...configs) => el(
  `a${sel}`,
  configs.length
    ? merge.all([
      { attrs: { href } },
      ...configs,
      isPWA() ? { target: undefined } : {},
    ])
    : { attrs: { href } },
  children,
);

/**
 * A link that performs a function instead of navigation.
 *
 * Accepts a callback function instead of a url, which is called
 * when the \<A\> is clicked.
 *
 * @function fn
 * @param {string|El|El[]} children text label or child elements
 * @param {function} fn an event listener callback function
 *
 * @return {module:ui/common/el~El}
 */
a.fn = (children, fn, cls, ...configs) => {
  const elm = a(textNodesToSpans(children), undefined, cls, ...configs);
  elm.data.on = elm.on || {};
  elm.merge({
    attrs: { tabindex: 0 },
    on: {
      click: [fn, elm],
      keydown: (e) => {
        if (interactKeycodes.includes(e.which)) {
          fn(elm);
        }
      },
    },
  });
  return elm;
};

export default a;
