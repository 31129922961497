/**
 * State defaults and constants for group pages.
 *
 * @module ui/page/admin/group/state
 * @private
 * @category Pages
 * @subcategory Admin - Groups
 */
import { frozen } from "util/object";
import { sortOrders } from "ui/component/dynamic-table";

export const searchModes = frozen({
  BASIC: "BASIC",
  ADVANCED: "ADVANCED",
  NONE: null,
});

export const defaultSearchParams = frozen({
  id: "",
  name: "",
  count: "",
});

export const defaultManageState = frozen({
  searchParams: defaultSearchParams,
  config: {},
  groups: [],
  filteredGroups: [],
  searchMode: null,
  sortColumn: "name",
  sortOrder: sortOrders.ASC,
});
