/**
 * Base modal dialog for user chat reports.
 *
 * @module ui/component/modal/chat/user-report
 * @category UI
 * @subcategory Chat
 */
import { reportReasons } from "@smartedge/em-message/constants";
import { merge } from "util/object";
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, span } from "ui/html";
import button from "ui/component/form-managed/button";
import form from "ui/component/form-managed";
import textarea from "ui/component/form-managed/field-textarea";
import radio from "ui/component/form-managed/field-radio";
import { notificationMessageTypes } from "model/notification/constants";
import bindModalForm from "ui/component/modal/bound-form";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import icon from "ui/component/icon";

const defaultState = {
  title: "Chat Report",
};

const submit = (state, formView) => {
  const { reason, additionalInformation } = formView.values;
  if (!reason) {
    getNotification().post({
      title: "Error",
      text: "Please select a reason to continue.",
      type: notificationMessageTypes.FAIL,
    });
    return;
  }
  const cleanState = { ...state };
  delete cleanState.title;
  const report = {
    ...cleanState,
    reason,
    additionalInformation,
  };
  getModal().resolve(report);
};

const formContent = (formView) => form("", formView.bind([
  [radio.contentBox, {
    entries: [
      {
        label: div(".entry-content", [
          icon("thumbs-down"),
          span("Offensive", ".title"),
        ]),
        value: reportReasons.OFFENSIVE,
        sel: `#${reportReasons.OFFENSIVE}`,
      },
      {
        label: div(".entry-content", [
          icon("face-sad-cry"),
          span("Harassment", ".title"),
        ]),
        value: reportReasons.HARASSMENT,
        sel: `#${reportReasons.HARASSMENT}`,
      },
      {
        label: div(".entry-content", [
          icon("envelopes-bulk"),
          span("Spam", ".title"),
        ]),
        value: reportReasons.SPAM,
        sel: `#${reportReasons.SPAM}`,
      },
      {
        label: div(".entry-content", [
          icon("grip-dots"),
          span("Other", ".title"),
        ]),
        value: reportReasons.OTHER,
        sel: `#${reportReasons.OTHER}`,
      },
    ],
    name: "reason",
  }],
  [textarea, {
    label: "Additional Feedback",
    name: "additionalInformation",
  }],
], { reason: null }));

/**
 * A unified reporting modal.
 *
 * @function userChatReport
 * @param {object} state
 * @param {mixed} [state[...props]] properties to attach to the report
 * @return {module:ui/common/el~El}
 */
export default function userChatReport(inState) {
  const state = merge(defaultState, inState);
  const formNode = bindModalForm("user-chat-report", formContent);

  return dialog({
    sel: ".dialog.user-chat-report",
    header: [
      h3(state.title),
    ],
    body: formNode,
    footerSpacer: true,
    footer: [
      button({
        label: "Submit",
        onClick: () => submit(state, formNode.view),
      }),
    ],
  }, getModal());
}
