/**
 * A file-upload answer on the student assessment page.
 *
 * @module ui/page/assessment/answer-file-upload
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import div from "ui/html/div";
import filePicker, { filePickerModes } from "ui/component/form-managed/file-picker";

const getSolutionValue = (solution, files) => {
  const file = files?.get(solution?.[0]);
  if (!file) {
    return {
      mode: filePickerModes.UPLOAD,
    };
  }
  return {
    mode: filePickerModes.KEEP,
    current: file.id,
  };
};

export default function answerFileUpload(state, view) {
  const {
    question,
    solution,
    validity,
    onFileSelect,
    onFileRemove,
    files,
    required,
  } = state;
  if (solution?.length === 0 || !files.get(solution?.[0])) {
    return div(".upload-answer", view.bind([[filePicker.upload, {
      name: question.id,
      validity,
      onChange: (newState, node) => onFileSelect(question.id, newState, node),
      required,
    }]], { [question.id]: getSolutionValue(solution, files) }));
  }
  return div(".upload-answer", view.bind([[filePicker.keep, {
    name: question.id,
    current: files.get(solution[0]),
    validity,
    onChange: (newState, node) => onFileRemove(question.id, newState, node),
    required,
  }]], { [question.id]: getSolutionValue(solution, files) }));
}
