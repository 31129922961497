/**
 * A Section element.
 *
 * @module ui/html/section
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * @function section
 * @param {module:ui/html~Selector} sel css selectors
 * @param {module:ui/html~Config[]} ...args addtional arguments as per {@link ui/common/el}
 * @return {module:ui/common/el~El}
 */
export default (sel = '', ...args) => el(`section${sel}`, ...args);
