/**
 * The pdf viewer page.
 *
 * @module ui/page/pdf
 * @category Pages
 * @subcategory User Facing
 */
import api from "api";
import { pageVisit } from "api/v2/analytics";
import cache from "cache";
import log from "log";
import getConfig from "config";
import el from "ui/common/el";
import icon from "ui/component/icon";
import {
  div,
  main,
  p,
  button,
  img,
  h3,
  a,
  span,
} from "ui/html";
import view from "ui/view";
import { isPWA } from "util/pwa";
import { getQueryParams, handleLoadingPageFail, setTitle } from "util/navigation";
import userLayout from "ui/page/layout/user";

let headerView;

const iframe = (src, sel = "") => el(
  `iframe${sel}`,
  {
    props: { src },
  },
);

const noPDFViewer = ({ state }) => div("#doc-direct-link", [
  p("Your browser does not have a built-in PDF viewer. Please download and view the document on your device."),
  button([icon("file-pdf"), state.doc.documentFile.name], () => window.location.assign(state.doc.getFileUrl()), ".ok"),
]);

const contentMetadata = (document) => div(".content-metadata", [
  document.posterUrl ? img(".poster", document.posterUrl) : "",
  h3(document.title, ".title"),
  div(".description", document.description),
  div(
    ".labels",
    document.categories
      .map((category) => a([icon("tag"), span(category.name)], `/search?label=${category.name}`)),
  ),
]);

const showDocument = ({ state }) => {
  if (isPWA()) return main("#doc-viewer", noPDFViewer({ state }));
  return main("#doc-viewer", [
    contentMetadata(state.doc),
    div(".iframe-container", [
      iframe(state.doc.getFileUrl()),
      noPDFViewer({ state }),
    ]),
  ]);
};

export default async function pdf(selector) {
  const { header, loading } = userLayout(selector, [main("#doc-viewer")]);
  loading.show();
  pageVisit(window.location.pathname);
  headerView = header;
  const params = getQueryParams();
  try {
    const [
      user,
      config,
      uiConfig,
      doc,
    ] = await Promise.all([
      api.user.getMe(),
      getConfig(),
      api.site.getUIConfiguration(),
      api.metadata.getDocument(params.id, true),
    ]);
    headerView.update({ user });

    setTitle(doc.title);

    const state = {
      user,
      doc,
      config,
      uiConfig,
    };

    view.create(showDocument)("#doc-viewer", state);
    const frame = document.querySelector("iframe");
    if (frame) {
      frame.addEventListener("load", () => {
        loading.hide();
      });
    } else loading.hide(); // PWA doesn't have an iframe
  } catch (err) {
    switch (err.statusCode) {
      case 404:
        window.location.replace("/404");
        break;
      case 403:
        handleLoadingPageFail(err, cache.getProfile());
        break;
      default:
        log.error(err);
    }
  }
}
