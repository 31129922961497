/**
 * The student assessment page.
 *
 * @module ui/page/assessment
 * @category Pages
 * @subcategory Courses
 */
import xs from "xstream";
import api from "api";
import cache from "cache";
import log from "log";
import { pageVisit } from "api/v2/analytics";
import { updateEvaluation } from "api/v2/course/evaluation";
import { featureTypes } from "model/config/constants";
import { questionType } from "model/course/constants";
import assessmentStream from "data/course/assessment";
import attendanceStream from "data/course/attendance";
import evaluationStream from "data/course/evaluation";
import courseStream from "data/course";
import div from "ui/html/div";
import main from "ui/html/main";
import p from "ui/html/p";
import span from "ui/html/span";
import strong from "ui/html/strong";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import icon from "ui/component/icon";
import userLayout from "ui/page/layout/user";
import formManagedView from "ui/view/form-managed";
import { filePickerModes } from "ui/component/form-managed/file-picker";
import { shuffleAssessment } from "util/assessments";
import { merge } from "util/object";
import { normalizeFileName } from "util/file";
import { prettyTime, splitParagraphs } from "util/format";
import { getQueryParams, setTitle } from "util/navigation";
import view from "ui/view";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import { guard } from "util/feature-flag";
import showQuestion from "./question";
import { defaultState } from "./state";

const DEBUG = false;

let headerView;
let loadingView;
let pageView;
let statusView;

const dbg = (...args) => { if (DEBUG) log.debug(...args); };

let timeUpTimeout = null;
const updateTime = async (timeLeft) => {
  if (timeLeft <= 2) {
    if (timeUpTimeout === null) {
      timeUpTimeout = setTimeout(() => {
        getModal()
          .alert("Time Up", "You are out of time. Your answers were submitted for grading in their current state.")
          .then(() => window.location.replace(pageView.state.prevPage ? `/${pageView.state.prevPage}` : "/"));
      }, 2000);
      statusView.update({ timeLeft });
      /* eslint-disable no-use-before-define */
      doSave(pageView);
    } else {
      statusView.update({ timeLeft: Math.max(timeLeft, 0) });
    }
  } else {
    statusView.update({ timeLeft });
  }
};

const refreshEvalTime = async () => {
  const { evaluation, course } = pageView.state;
  let response;
  if (evaluation) {
    try {
      response = await api.course.getTimeLeftForEvaluation(
        course.id,
        evaluation.id,
      );
      // integer overflow from very large time, likely
      if (response.timeLeftInSeconds < 0) {
        response.timeLeftInSeconds = Infinity;
      }
      statusView.update({
        timeLeft: response.timeLeftInSeconds,
        over: response.over,
      });
    } catch (e) {
      log.error("EVAL :: bad response from time check");
      response = {
        timeLeftInSeconds: Infinity,
      };
    }
    let second = 0;
    const resync = -30;
    const interval = setInterval(() => {
      second--;
      updateTime((response.timeLeftInSeconds) + second)
        .catch((e) => log.error(e));
      if (second < resync) {
        clearInterval(interval);
        setTimeout(() => {
          refreshEvalTime();
        });
      }
    }, 1000);
  }
};

const valuesToSolutions = (self) => {
  const { assessment } = self.state;
  if (!assessment) return [];
  const { values } = self;
  const solutions = [];
  assessment?.questions?.forEach((question) => {
    let value = values[question.id];
    if (value?.length === 0) return;
    if (question.questionType === questionType.UPLOAD) {
      value = value?.current ? [value.current] : [];
    }
    if (typeof value === "string") value = [value];
    if (value?.length) {
      solutions.push({ questionId: question.id, value });
    }
  });
  dbg("EVAL :: got solutions", solutions);
  return solutions;
};

/**
 * Submits final answers.
 */
const doSubmit = async (self) => {
  const confirmed = await getModal().confirm(
    "Submit Answers",
    "Are you sure you want to submit your answers? You will not be allowed to make any edits after submission.",
    "Yes",
    "No",
    false,
    false,
    true,
  );
  if (confirmed) {
    loadingView.show("Submitting answers...");
    const { assessment, evaluation, user, course } = self.state;
    const { values } = self;
    if (DEBUG) log.info("EVAL :: form values", values);
    try {
      const solutions = await Promise.all(assessment.questions.map(async (question, i) => {
        const value = values[question.id];
        if (!value) {
          self.updateState({ position: i });
          throw new Error("unanswered question in position", i);
        }
        if (question.questionType === questionType.UPLOAD) {
          let file, data, fileName, descriptor;
          switch (value.mode) {
            case filePickerModes.UPLOAD:
              file = value.upload;
              data = new FormData();
              fileName = normalizeFileName(file.name);
              data.append("file", file, fileName);
              descriptor = await api.file.uploadFile(fileName, { data });
              return ({ questionId: question.id, value: [descriptor.id] });
            case filePickerModes.KEEP:
            default:
              return [value.current?.id];
          }
        }
        if (value instanceof Array) return ({ questionId: question.id, value });
        return ({ questionId: question.id, value: [value] });
      }));
      if (DEBUG) log.info("solutions", solutions);
      const updated = merge(
        evaluation,
        {
          studentId: user.id,
          courseId: course.id,
          assessmentId: assessment.id,
          solutions,
          submitted: true,
        },
      );
      evaluationStream.pushEvaluationProgress(updated);
      if (DEBUG) log.info("EVAL :: FINAL SAVE", updated);
      await updateEvaluation(updated);
      await getModal().alert(
        "Assessment Complete",
        [
          div("", "Your final answers have been submitted!"),
          div("", "You will now be redirected to the home page."),
        ],
      );
      window.location.replace(self.state.prevPage ? `/${self.state.prevPage}` : "/");
    } catch (e) {
      log.error(e);
      loadingView.hide();
      getNotification().post({
        title: "Failed To Submit Answers",
        text: "A server error occured. Please try again in a few minutes or contact a site administrator for assistance.",
        type: "fail",
      });
    }
  }
};

/**
 * Does an intermediate save (without finalizing the exam).
 */
const doSave = async (self) => {
  const { user, evaluation, assessment, course } = self.state;
  const { values } = self;
  if (DEBUG) log.debug("EVAL :: form values", values);
  const updated = merge(evaluation, {
    studentId: user.id,
    courseId: course.id,
    assessmentId: assessment.id,
    solutions: valuesToSolutions(self),
  });
  evaluationStream.pushEvaluationProgress(updated);
  // fire this off but don't wait for it - otherwise UI also has to wait
  updateEvaluation(updated);
  if (DEBUG) log.debug("EVAL :: updated", updated);
};

/**
 * A field gets validated only if the student clicks next or blurs the question immediately
 * after the last question to be validated. Whenever next or the right arrow is clicked,
 * it queues movement to the next tab.
 */
const doNext = (self, last) => async () => {
  const { position, assessment } = self.state;
  if (DEBUG) log.debug("EVAL :: next", self.values);
  if (last) return doSubmit(self);
  await doSave(self);
  const newState = {
    requested: Math.min(assessment.questions.length - 1, position + 1),
  };
  return self.update(newState);
};

const backButton = (self) => {
  const { position } = self.state;
  const disabled = position === 0;
  return button.inverse({
    label: "Back",
    onClick: () => {
      if (DEBUG) log.debug("EVAL :: going back", self.values);
      self.update({
        requested: Math.max(0, position - 1),
      });
    },
    disabled,
  });
};

const isAnswered = (questionId, values, solutions) => {
  const value = values?.[questionId];
  if (value === undefined || value === null) {
    const solution = solutions?.find?.((s) => s.questionId === questionId);
    if (solution?.value?.length) return true;
  }
  return !!(value?.length > 0 || value?.upload);
};

const isValid = (questionId, values, validity) => (
  !values?.[questionId]
  || (values?.[questionId] && validity.fields?.[questionId]?.valid)
);

const nextButton = (self) => {
  const { position, assessment, evaluation, validity } = self.state;
  const { values } = self;
  const last = position === assessment.questions.length - 1;
  let label = (last ? "Submit Answers" : "Next");
  if (!last && !validity.valid) {
    label = "Check";
  }
  let disabled = false;
  const questionId = assessment?.questions?.[position]?.id;
  if (questionId && !(
    isAnswered(questionId, values, evaluation?.solutions)
    && isValid(questionId, values, validity)
  )) {
    disabled = true;
  }
  return button({
    label,
    onClick: doNext(self, last),
    disabled,
  });
};

const confirmBegin = (state) => getModal().confirm(
  `Start ${state.assessment.title}`,
  [
    p("After starting the assessment your progress will be saved automatically. You may leave and return at any time on the same device to complete the assessment."),
    p("Please do not clear your browser cache until the assessment is complete to avoid losing your place."),
    p("Are you ready to start?"),
  ],
);

const alreadyCompleted = () => getModal().alert(
  "You have already completed this assessment.",
  null,
  "Ok",
  false,
  ".full-width",
);

const doBegin = (self) => async () => {
  const { user, assessment, attendance } = self.state;
  let { evaluation } = self.state;

  if ((await confirmBegin(self.state))) {
    loadingView.show("preparing assessment...");
    if (evaluation?.submitted) {
      await alreadyCompleted();
      window.history.back();
      return;
    }
    if (!evaluation) { // either cache lost or not started yet
      try {
        evaluation = await api.course.createEvaluation(
          assessment.courseId,
          attendance.id,
          assessment,
          user,
        );
      } catch (err) {
        /* eslint-disable-next-line no-use-before-define */
        await handleErrorCode(err);
        window.history.back();
      }
    }
    if (DEBUG) log.info("EVAL :: beginning evaluation", evaluation);
    evaluationStream.pushEvaluationProgress(evaluation);
    self.update({ evaluation });
    // now force a second update to do initial validity pass
    self.update({ evaluation });
    setTimeout(() => {
      refreshEvalTime();
    });
    loadingView.hide();
  }
};

const showMeta = (self) => {
  const { assessment } = self.state;
  return div(".meta", [
    div(".description", splitParagraphs(assessment.description).map((line) => p(line))),
    p(["This assessment is worth ", span(strong(assessment.maxPoints)), " points."]),
    button({ label: `Start ${assessment.title}`, onClick: doBegin(self) }),
  ]);
};

const scrollTo = (position) => (node) => {
  const target = node.elm.children[position]?.offsetLeft;
  if (target !== null) {
    node.elm.scrollTo({
      left: target,
      behavior: "smooth",
    });
  }
};

export const showAssessment = (self) => {
  const {
    assessment,
    evaluation,
    position,
    validity,
  } = self.state;
  if (!(assessment)) return form("#assessment");
  const { state } = self;

  let solutions = new Map();

  // these are the initial values supplied to the form
  // when reloading a solution. they do NOT get used in
  // subsequent updates.
  if (evaluation) {
    solutions = new Map(
      evaluation.solutions
        ?.map(({ questionId, value }) => [
          questionId,
          value,
        ]),
    );
  }

  dbg("EVAL :: in showAssessment", solutions);

  return form("#assessment", [
    ...(!evaluation)
      ? [showMeta(self)]
      : [
        div(
          ".questions-wrap",
          div(
            ".questions",
            {
              hook: {
                insert: () => scrollTo(position),
                postpatch: scrollTo(position),
              },
              on: {
                /// update position when an element is focused - keeps question
                /// carousel in sync with tab/arrow navigation
                focusin: (ev) => {
                  const parent = ev.path || ev.composedPath()
                    .find((el) => el.classList.contains("questions"));
                  const children = [...parent.children];
                  const target = children.indexOf(children
                    .find((el) => el.contains(ev.target)));
                  self.update({ position: target });
                },
              },
            },
            assessment.questions?.map((question, i) => showQuestion({
              question,
              solution: solutions?.get?.(question.id) || [],
              validity,
              files: state.files,
              required: i < position,
            }, pageView)),
          ),
        ),
        div(".controls", [
          div(".footer", [
            backButton(self),
            nextButton(self),
          ]),
        ]),
      ],
  ], {
  });
};

export const showStatus = ({ state }) => {
  const { assessment, position, timeLeft } = state;
  const timePercent = timeLeft === 0 ? 0 : timeLeft / (assessment.durationInMin * 60);
  let status = ".secondary";
  if (timePercent < 0.5) status = ".accent";
  if (timePercent < 0.1) status = ".warning";
  return div("#status", [
    span(`${position + 1} / ${assessment.questions.length}`),
    p([
      span(icon("clock"), status),
      timeLeft === Infinity
        ? span("Unlimited", status)
        : span(prettyTime(timeLeft), status),
    ]),
  ]);
};

export const doStatusUpdate = (self) => {
  const { timeLeft, over } = self.state;
  if (pageView) pageView.updateState({ timeLeft, over });
  self.render();
};

export const doUpdate = (self) => {
  if (DEBUG) log.debug("EVAL :: do update", self.state);
  const { requested, position } = self.state;
  const validState = self.validate(true);
  const { values } = self;
  let newPos = position;
  if (DEBUG) log.debug("EVAL :: form values", self.values);

  if (requested > position) {
    for (; newPos < requested; newPos++) {
      self.updateState({ validPosition: newPos });
      const validity = self.validate(true);
      if (!validity.valid) break;
    }
  } else {
    newPos = requested;
  }

  const newState = {
    validity: validState,
    position: newPos,
    requested: newPos,
    validPosition: newPos,
    values,
  };

  if (self.state.assessment && self.state.course && !self.state.evaluation) {
    // give it a bit more time then bail
    setTimeout(() => loadingView.hide(), 1000);
  }

  self.updateState(newState);
  self.render();
  if (statusView) statusView.update(newState);
};

const handleErrorCode = (e) => {
  const modal = getModal();
  log.error(e, e.message);
  switch (e.statusCode) {
    case 406:
      if (e.message?.match("already exists")) {
        return modal.alert("Closed", "You have already completed this assessment.");
      }
      return modal.alert("Closed", "This assessment is not open to students at this time.");
    case 403:
      return modal.alert("Not Enrolled", "You are not authorized to take this assessment.");
    case 404:
      return modal.alert("Not Found", "No matching assessment was found.");
    default:
      return modal.alert("Error", "An unspecified error occured while loading the assessment. Please try again later.", "Ok", true);
  }
};

export default async function studentAssessment(selector) {
  guard(featureTypes.LMS);
  const { header, loading } = userLayout(
    selector,
    [
      main("", [
        form("#assessment"),
        div("#status"),
      ]),
    ],
    "Assessment",
  );
  headerView = header;
  loadingView = loading;
  loading.show("Retrieving assessment...");

  const { courseId, moduleId, id, prevPage } = getQueryParams();

  pageVisit(window.location.pathname);

  const user = cache.getProfile();
  const state = merge(defaultState, {
    user,
    prevPage,
  });

  if (state === null) return;
  setTitle(state.title);

  const assessment$ = assessmentStream.get(
    courseId,
    moduleId,
    id,
  ).map((assessment) => {
    if (assessment.PLACEHOLDER) return assessment;
    setTitle(assessment.title);
    headerView.update({ title: assessment.title });
    try {
      const shuffled = shuffleAssessment(assessment, user);
      return shuffled;
    } catch (e) {
      log.error(e);
      return assessment;
    }
  }).filter((item) => !item.PLACEHOLDER);

  const evaluation$ = attendanceStream.getEvaluation(courseId, id, user.id)
    .map((e) => {
      dbg("EVAL :: received from BE", e);
      return e;
    })
    .filter((item) => !item.PLACEHOLDER);

  evaluation$.addListener({ next: (ev) => {
    if (ev?.id) {
      setTimeout(() => {
        refreshEvalTime().catch((e) => log.error(e));
        pageView?.update({
          position: ev?.solutions?.length || 0,
          requested: ev?.solutions?.length || 0,
          validPosition: ev?.solutions?.length || 0,
        });
      }, 0);
    }
  } });

  headerView.update({ user: state.user, title: state.title, ...state.uiConfig });

  statusView = view.create(showStatus, doStatusUpdate)("#status", state);
  statusView.bindStreams([
    ["assessment", assessment$],
  ]);
  pageView = formManagedView(showAssessment, doUpdate)("#assessment", state);
  pageView.setFullValidation(true);

  pageView.bindStreams(
    [
      ["assessment", assessment$],
      ["attendance", attendanceStream.get([courseId, user.id])],
      ["evaluation", evaluation$],
      ["course", courseStream.get(courseId)],
      ["uiConfiguration", xs.fromPromise(api.site.getUIConfiguration())],
    ],
    () => {
      loading.hide();
    },
    ["assessment", "attendance", "evaluation", "course", "uiConfiguration"],
  );

  // trigger an update so validation runs
  if (state.evaluation) pageView.update(state);
}
