/**
 * A modal for creating module.
 *
 * @module ui/component/modal/course/module
 * @category UI
 * @subcategory Modal Dialogs
 */
import { merge } from "util/object";
import { div, h3 } from "ui/html";
import startOfDay from "date-fns/startOfDay";
import addWeeks from "date-fns/addWeeks";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import group from "ui/component/form-managed/field-group";
import cameoAssessment from "ui/component/cameo-assessment";
import cameoLecture from "ui/component/cameo-lecture";
import contentSchedule from "ui/component/content-schedule";
import dialog from "ui/component/modal/layout/dialog";
import multiToggle from "ui/component/form-managed/field-multi-toggle";
import title from "ui/component/form-managed/field-title";
import description from "ui/component/form-managed/field-description";
import { notificationMessageTypes } from "model/notification/constants";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import bindModalForm, { deleteStoredForm } from "ui/component/modal/bound-form";
import lectureModal from "./lecture";

const toggleOptions = [
  {
    label: "General Information",
    name: "generalInformation",
    value: "generalInformation",
  },
  {
    label: "Lectures",
    name: "lectures",
    value: "lectures",
  },
  {
    label: "Assessments",
    name: "assessments",
    value: "assessments",
  },
];

const staticMessages = {
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: notificationMessageTypes.FAIL,
    duration: 5,
  },
};

const defaultState = {
  validation: {
    fields: {},
  },
  metadataList: [],
  module: {
    id: null,
    title: "",
    description: "",
    lectures: [],
    startDate: new Date(),
    endDate: null,
  },
  users: [],
  headerTitle: null,
  selectedOption: toggleOptions[0],
};

// eslint-disable-next-line no-use-before-define
const onSelectOption = (self, option) => self.update({
  ...self.state,
  selectedOption: option,
});

const visibleClass = (
  currentOptionValue,
  selectedOption,
) => (selectedOption.value === currentOptionValue ? ".visible" : "");

const buildEditForm = (self) => {
  const { values, state } = self;
  return form(
    `#course-module`,
    [
      multiToggle({
        label: null,
        value: state.selectedOption.value,
        options: toggleOptions,
        onSelect: (option) => onSelectOption(self, option),
      }),
      div(`.pane${visibleClass("generalInformation", state.selectedOption)}`, self.bind([
        [title, { required: true, value: values.title || state.module.title }],
        [
          description,
          { value: values.description || state.module.description },
        ],
        group([
          contentSchedule({
            startDate: state.module.startDate ? new Date(state.module.startDate) : null,
            endDate: state.module.endDate ? new Date(state.module.endDate) : null,
            onEnable: () => {
              self.update({
                module: {
                  startDate: startOfDay(new Date()),
                  endDate: startOfDay(addWeeks(new Date(), 1)),
                },
              });
            },
            onDisable: () => self.update({
              module: {
                startDate: null,
                endDate: null,
              },
            }),
          }),
        ]),
      ])),
      div(`.pane${visibleClass("lectures", state.selectedOption)}`, [
        div(".container", [
          ...state.module.lectures.map((lecture) => cameoLecture({
            course: state.course,
            module: state.module,
            lecture,
            metadataList: state.metadataList,
            users: state.users,
            onUpdate: (updatedLecture) => {
              self.update({
                module: {
                  ...state.module,
                  lectures: state.module.lectures.map((item, index) => {
                    if (state.module.lectures.indexOf(lecture) === index) {
                      return updatedLecture;
                    }
                    return item;
                  }),
                },
              });
            },
            onRemove: (removeLecture) => {
              self.update({
                module: {
                  ...state.module,
                  lectures: state.module.lectures.filter((item) => item !== removeLecture),
                },
              });
            },
          })),
        ]),
        button.secondary({
          icon: "plus-circle",
          label: "Add Lecture",
          onClick: async () => {
            const lecture = await getModal().async(lectureModal({
              course: state.course,
              module: state.module,
              metadataList: state.metadataList,
              users: state.users,
            }), false);
            if (lecture) {
              self.update({
                module: {
                  ...state.module,
                  lectures: [...state.module.lectures, lecture],
                },
              });
            }
          },
        }),
      ]),
      div(`.pane${visibleClass("assessments", state.selectedOption)}`, [
        state.module.assessments?.length
          ? div(".container", state.module.assessments.map(
            (assessment) => cameoAssessment({ assessment }),
          ))
          : div(".no-assessments-title", "No assessments yet"),
      ]),
    ],
  );
};

const makeHeader = (state) => {
  if (state.headerTitle) {
    return state.headerTitle;
  }
  const modalTitle = state.module?.title;
  return modalTitle ? `Edit ${modalTitle}` : "New Module";
};

const onSave = (formNode) => {
  const { view } = formNode;
  view.setFullValidation(true);
  const validation = view.validate(true);
  const { state, values } = view;
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return;
  }
  const preparedModule = {
    ...state.module,
    title: values?.title,
    description: values?.description,
    startDate: values.startDate || null,
    endDate: values.endDate || null,
  };
  getModal().resolve(preparedModule);
};

export default function moduleModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  deleteStoredForm("course-module");
  const formNode = bindModalForm("course-module", buildEditForm, state, false);
  return dialog({
    sel: `.course-module${state.module.assessments?.length ? ".with-assessments" : ""}`,
    header: h3(makeHeader(state)),
    body: formNode,
    footerSpacer: true,
    footer: div(".buttons", { attrs: { role: "group" } }, [
      button.primary({
        label: "Save",
        onClick: () => onSave(formNode),
      }),
    ]),
  }, getModal());
}
