/**
 * The admin page for managing messages.
 *
 * Corresponds to `markup/admin/manage-messages.html`
 *
 * @module ui/page/admin/messaging/manage
 * @category Pages
 * @subcategory Admin - Messages
 */
import xs from "xstream";
import { messageStatus, messageTypes as types, reportTypes } from "@smartedge/em-message/constants";
import { featureTypes } from "model/config/constants";
import dashboardLayout from "ui/page/layout/dashboard";
import placeholder from "ui/component/placeholder";
import widget from "ui/component/dashboard-widget";
import api from "api";
import { getMBEStatusDaily, getMBEStatusWeekly } from "api/v2/analytics/messaging";
import view from "ui/view";
import { div, h3, section, span } from "ui/html";
import actionBar from "ui/component/dashboard-action-bar";
import actionBarSearch from "ui/component/action-bar-search";
import message from "ui/component/messaging/message";
import spinner from "ui/component/spinner";
import table from "ui/component/dynamic-table";
import {
  findReportsForMessage,
  getReportedMessagesForRecentMessages,
  getThreadProfileLink,
  isScrolledToBottom,
  loadDescriptors,
  takeActionOnReportedMessage,
  takeActionOnThread,
} from "ui/page/admin/messaging/common";
import advancedMessageSearch from "ui/component/modal/chat/advanced-message-search";
import button from "ui/component/form-managed/button";
import { defaultSearchParams } from "ui/page/chat/common";
import groupSelectModal from "ui/component/modal/acl/group-select";
import dmThreadSelectModal from "ui/component/modal/messaging/dm-thread-select";
import threadPreview from "ui/component/messaging/thread-preview";
import { frozen } from "util/object";
import { guard } from "util/feature-flag";

const defaultStatsState = frozen({
  daily: null,
  weekly: null,
});

let loadingView, modalView, recentView, reportedView, statsView, actionBarView;

const isGroup = (self, aMessage) => self.state.groups.some((g) => g.id === aMessage.to);

const showStatistics = (self) => {
  const { daily, weekly } = self.state;
  if (!daily || !weekly) return placeholder(spinner());

  return div(".stats", [
    h3("Statistics"),
    table({
      columnLabels: ["Entry", "Daily", "Weekly"],
      columns: ["stat", "daily", "weekly"],
      rows: [
        {
          stat: "Messages",
          daily: daily.messageCount,
          weekly: weekly.messageCount,
        },
        {
          stat: "Reports",
          daily: daily.reportCount,
          weekly: weekly.reportCount,
        },
        {
          stat: "Active Threads",
          daily: daily.activeGroups,
          weekly: weekly.activeGroups,
        },
        {
          stat: "Active Users",
          daily: daily.activeUsers,
          weekly: weekly.activeUsers,
        },
      ],
    }),
  ]);
};

const resetSearch = async () => {
  recentView.updateState({
    recentMessagesSearchParams: defaultSearchParams(),
  });
  const messages = await api.message.getAllMessages(recentView.state.recentMessagesSearchParams);
  loadDescriptors(recentView, messages);
  recentView.update({
    recentMessages: messages,
    recentMessagesResultCount: null,
  });
  actionBarView.update({});
};

const showSearch = (self) => actionBarSearch({
  title: "All recent messages",
  showClear: !!recentView?.state?.recentMessagesResultCount,
  resultCount: recentView?.state?.recentMessagesResultCount,
  onClear: resetSearch,
  onSearch: async (searchString) => {
    recentView.updateState({
      recentMessagesSearchParams: {
        ...defaultSearchParams(),
        searchString,
      },
    });
    const messages = await api.message.getAllMessages(recentView.state.recentMessagesSearchParams);
    loadDescriptors(recentView, messages);
    recentView.update({
      recentMessages: messages,
      recentMessagesResultCount: messages.length,
    });
    actionBarView.update({});
  },
  onAdvancedSearch: async () => {
    const params = await modalView.async(advancedMessageSearch({
      users: self.state.users,
    }, modalView));
    recentView.updateState({
      recentMessagesSearchParams: {
        ...defaultSearchParams(),
        ...params,
      },
    });
    const messages = await api.message.getAllMessages(recentView.state.recentMessagesSearchParams);
    loadDescriptors(recentView, messages);
    recentView.update({
      recentMessages: messages,
      recentMessagesResultCount: messages.length,
    });
    actionBarView.update({});
  },
  additionalControls: [
    button.secondary({
      icon: "user",
      label: "DM Thread",
      onClick: async () => {
        const { userOneId, userTwoId } = await modalView
          .async(dmThreadSelectModal({ users: recentView.state.users }, modalView));
        window.location.assign(`/admin/thread-profile?userOneId=${userOneId}&userTwoId=${userTwoId}`);
      },
    }),
    button.alternate({
      icon: "users",
      label: "Group Thread",
      onClick: async () => {
        const group = await modalView
          .async(groupSelectModal({
            groups: recentView.state.groups,
            title: span("Please select a group from the list below to view its thread profile.", ".list-select-title"),
          }, modalView));
        window.location.assign(`/admin/thread-profile?groupId=${group.principal.id}`);
      },
    }),
  ],
});

const showRecentMessages = (self) => {
  let searchInProgress = false;

  const goToThreadProfile = (recentMessage) => {
    if (isGroup(self, recentMessage)) {
      window.location.assign(`/admin/thread-profile?groupId=${recentMessage.to}`);
    } else {
      window.location.assign(`/admin/thread-profile?userOneId=${recentMessage.from}&userTwoId=${recentMessage.to}`);
    }
  };

  return div(".recent-messages", [
    h3(recentView?.state?.recentMessagesResultCount ? "Search Results" : "Recent Messages"),
    div(
      ".recent-messages-scrollable-container",
      {
        on: {
          scroll: async (e) => {
            if (isScrolledToBottom(e.target) && !searchInProgress) {
              searchInProgress = true;
              const lastMessage = self.state.recentMessages[self.state.recentMessages.length - 1];
              const endDate = new Date(lastMessage.created);
              const searchParams = {
                ...self.state.recentMessagesSearchParams,
                endDate,
              };
              const recentMessages = (await api.message.getAllMessages(searchParams))
                .filter(
                  (m) => !self.state.recentMessages
                    .some((existingMessage) => existingMessage.id === m.id),
                );
              const recentMessagesReports = await getReportedMessagesForRecentMessages(
                recentMessages,
              );
              loadDescriptors(reportedView, recentMessages);
              self.update({
                recentMessagesSearchParams: searchParams,
                recentMessages: [
                  ...self.state.recentMessages,
                  ...recentMessages,
                ],
                ...(recentMessagesReports.length ? { recentMessagesReports } : {}),
              });
              searchInProgress = false;
            }
          },
        },
      },
      self.state.recentMessages
        .map((recentMessage) => message({
          message: recentMessage,
          users: self.state.users,
          groups: self.state.groups,
          descriptors: self.state.descriptors,
          reports: findReportsForMessage(recentMessage, self.state.recentMessagesReports),
          controls: [
            button.icon({
              icon: "list",
              label: "Go to the thread profile",
              onClick: (e) => {
                e.stopPropagation();
                goToThreadProfile(recentMessage);
              },
            }),
          ],
          onClick: () => {
            goToThreadProfile(recentMessage);
          },
        }, modalView)),
    ),
  ]);
};

const showReportedMessages = (self) => {
  let searchInProgress = false;
  return div(".reported-messages", [
    h3("Reported Messages"),
    div(
      ".reported-messages-scrollable-container",
      {
        on: {
          scroll: async (e) => {
            if (isScrolledToBottom(e.target) && !searchInProgress) {
              searchInProgress = true;
              const lastMessage = self.state
                .reportedMessages[self.state.reportedMessages.length - 1];
              const endDate = new Date(lastMessage.created);
              const searchParams = {
                ...self.state.reportedMessagesSearchParams,
                endDate,
              };
              const reportedMessages = (await api.message.getAllReports(searchParams))
                .filter(
                  (m) => !self.state.reportedMessages
                    .some((existingMessage) => existingMessage.id === m.id),
                );
              loadDescriptors(reportedView, reportedMessages);
              self.update({
                reportedMessagesSearchParams: searchParams,
                reportedMessages: [
                  ...self.state.reportedMessages,
                  ...reportedMessages,
                ],
              });
              searchInProgress = false;
            }
          },
        },
      },
      self.state.reportedMessages
        .filter((report) => report.status === messageStatus.UNREAD)
        .map((report) => {
          if (report.reportType === reportTypes.THREAD) {
            return threadPreview({
              users: self.state.users,
              groups: self.state.groups,
              reportType: report.reportType,
              target: report.target,
              created: report.created,
              text: report.text,
              onClick: () => {
                takeActionOnThread(report, self.state.users, self.state.groups, modalView);
              },
              controls: [
                button.icon({
                  icon: "flag-alt",
                  label: "Open report details",
                  sel: ".danger",
                  onClick: (e) => {
                    e.stopPropagation();
                    takeActionOnThread(report, self.state.users, self.state.groups, modalView);
                  },
                }),
                button.icon({
                  icon: "list",
                  label: "Go to the thread profile",
                  onClick: (e) => {
                    e.stopPropagation();
                    window.location.assign(getThreadProfileLink(report.target));
                  },
                }),
              ],
            }, modalView);
          }
          return message({
            message: report.reportedMessage,
            descriptors: self.state.descriptors,
            users: self.state.users,
            groups: self.state.groups,
            controls: [
              button.icon({
                icon: "flag-alt",
                label: "Open report details",
                sel: ".danger",
                onClick: (e) => {
                  e.stopPropagation();
                  takeActionOnReportedMessage(report, self.state.users, modalView);
                },
              }),
              button.icon({
                icon: "list",
                label: "Go to the thread profile",
                onClick: (e) => {
                  e.stopPropagation();
                  if (isGroup(self, report.reportedMessage)) {
                    window.location
                      .assign(`/admin/thread-profile?groupId=${report.reportedMessage.to}`);
                  } else {
                    window.location
                      .assign(`/admin/thread-profile?userOneId=${report.reportedMessage.from}&userTwoId=${report.reportedMessage.to}`);
                  }
                },
              }),
            ],
            onClick: () => takeActionOnReportedMessage(report, self.state.users, modalView),
          }, modalView);
        }),
    ),
  ]);
};

export default async function manageMessages(selector) {
  guard(featureTypes.MESSAGING);
  const title = "Manage Messages";
  const views = dashboardLayout(
    selector,
    [
      actionBar(section("#actions")),
      widget(placeholder("Stats", "#stats"), "", ".stats"),
      widget(placeholder("Recent Messages", "#recent"), "", ".recent"),
      widget(placeholder("Reported", "#reported"), "", ".reported"),
    ],
    title,
  );
  const { header, modal, loading } = views;
  loadingView = loading;
  modalView = modal;

  loadingView.show("Prefetching content...");

  const recentMessagesSearchParams = defaultSearchParams();
  const reportedMessagesSearchParams = defaultSearchParams();

  const [
    user,
    users,
    groups,
    recentMessages,
    reportedMessages,
  ] = await Promise.all([
    api.user.getMe(),
    api.user.list(),
    api.acl.listGroups(),
    api.message.getAllMessages(recentMessagesSearchParams),
    api.message.getAllReports(reportedMessagesSearchParams),
  ]);

  const recentMessagesReports = await getReportedMessagesForRecentMessages(recentMessages);

  const state = {
    user,
    users,
    groups,
    statistics: null,
    recentMessages,
    reportedMessages,
    recentMessagesSearchParams,
    reportedMessagesSearchParams,
    recentMessagesReports,
    descriptors: new Map(),
  };

  statsView = view.create(showStatistics)("#stats", defaultStatsState);
  actionBarView = view.create(showSearch)("#actions", state);
  recentView = view.create(showRecentMessages)("#recent", state);
  reportedView = view.create(showReportedMessages)("#reported", state);

  statsView.bindStreams([
    ["daily", xs.periodic(15000)
      .map(() => xs.fromPromise(getMBEStatusDaily()))
      .startWith(xs.fromPromise(getMBEStatusDaily()))
      .flatten()],
    ["weekly", xs.periodic(15000)
      .map(() => xs.fromPromise(getMBEStatusWeekly()))
      .startWith(xs.fromPromise(getMBEStatusWeekly()))
      .flatten()],
  ]);

  loadDescriptors(recentView, recentMessages);
  loadDescriptors(reportedView, reportedMessages);

  const message$ = api.message.getMessageStream();

  message$.map((m) => m.message).addListener({
    next: (mess) => {
      if (mess.type === types.REPORT) {
        recentView.update({
          recentMessagesReports: [
            ...recentView.state.recentMessagesReports,
            mess,
          ],
        });
      }
    },
  });

  header.update({ user, title });
  loadingView.hide();
  loading.hide();
}
