/**
 * Messages for the menu editor.
 *
 * @module ui/page/admin/menu
 * @private
 * @category Pages
 * @subcategory Admin - Site Customization
 */
/***/
import { frozen } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";

const { FAIL, SUCCESS } = notificationMessageTypes;

export default frozen({
  ok: { title: `Saved!`, type: SUCCESS, duration: 5 },
  unknown: { title: "Error", text: "An unknown error occurred.", type: FAIL, duration: 5 },
  selectPage: { title: "Error", text: "Please select a page.", type: FAIL, duration: 5 },
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: FAIL, duration: 5 },
  accessDenied: { title: "Error", text: "You do not have permission to edit the main menu.", type: FAIL, duration: 5 },
  backendErrors: { title: "Error", text: "A server error ocurred. Please report this to the operators.", type: FAIL },
  /* editCanceled: { text: "Menu entry edit canceled.", type: "warn" }, */
});
