/**
 * A form field for one-time passwords.
 *
 * @module ui/component/form-managed/field-otp
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "Please enter your verification code.",
  invalid: "This code does not appear to be valid. Please recheck and try again.",
});

/**
 * Constraints applicable to verification codes.
 *
 * @constant verificationCodeConstraints
 * @readonly
 */
const constraints = frozen({ props: { pattern: "[0-9]+" } });

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "off",
  constraints,
  helpTexts,
  label: "One-Time Password",
  name: "code",
  sel: "",
  taken: null,
});

/**
 * A form field for one time passwords (verification codes).
 *
 * If the `taken` property is provided with a list of username strings,
 * validation will check if the current value is in the list.
 *
 * @function otp
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="code"]
 * @param {string} [label="One-Time Password"]
 * @param {?string[]} taken list of already taken usernames
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function username(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.first-name`,
  });
}
