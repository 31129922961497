/**
 * Color picker field
 *
 * @module ui/component/form-managed/field-color-picker
 * @category UI
 * @subcategory Forms
 */
import { frozen, merge } from "util/object";
import { makeInputBindings } from "ui/component/form-managed/field-input";
import { managedField } from "ui/view/form-managed/util";
import { div, input, option, datalist, span } from "ui/html";
import { uuidv4 } from "util/generator";
import genericField from "./field-generic";

const helpTexts = frozen({
  invalid: "Please select a valid color.",
});

const defaultState = frozen({
  helpTexts,
  name: undefined,
  label: "Color",
  sel: "",
  value: null,
  onChange: () => {},
  type: "color",
  predefinedColors: [],
  listId: undefined,
});

const getValue = (node, state) => node.elm.querySelector(`[name="${state.name}"]`)?.value;

export default function colorPicker(inState) {
  let dataListId;
  if (inState.predefinedColors.length) {
    dataListId = uuidv4();
    // eslint-disable-next-line no-param-reassign
    inState.listId = inState.predefinedColors.length ? dataListId : undefined;
  }
  const state = merge(defaultState, inState);
  return genericField(
    state,
    managedField(
      div(".field-color", [
        input.color(
          state.values?.[state.name] || state.value || "",
          state.required,
          state.name,
          makeInputBindings(state),
        ),
        state.predefinedColors.length
          ? datalist(state.predefinedColors.map((color) => option(color)), `#${dataListId}`)
          : "",
        span(`${state.label} (${state.value})`, ".color-label"),
      ]),
      state.name,
      (node) => getValue(node, state),
    ),
    state.sel,
  );
}
