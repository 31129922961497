/**
 * Pages. See description in [the UI module]{@link module:ui}.
 *
 * ## User Account Pages
 * - [account]{@link module:ui/page/account}, where users update their own account info
 * - [login]{@link module:ui/page/login} - universal login / registration / etc. page
 *
 * ## User-Facing Pages
 * - [home]{@link module:ui/page/home} - the old user landing page (deprecated)
 * - [dynamic]{@link module:ui/page/dynamic} - display dynamic pages
 * - [search]{@link module:ui/page/search} - the user search result page
 * - [feedback]{@link module:ui/page/feedback} - send feedback page
 * - [faq]{@link module:ui/page/end-user-faq} - end user FAQ page
 * - [lecture]{@link module:ui/page/lecture} - end user Lecture page
 * - [pdf]{@link module:ui/page/pdf} - end user PDF viewer
 * - [studentAssessment]{@link module:ui/page/assessment} - end user Assessment page
 * - [score]{@link module:ui/page/score} - end user Assessment score page
 *
 * ## Static Pages (unprivileged access)
 * - [termsOfService]{@link module:ui/page/tos} - the terms & conditions page
 * - [privacy]{@link module:ui/page/privacy} - the privacy policy page
 *
 * ## Administrative Pages
 * - [index]{@link module:ui/page/admin/stats} - the admin landing page
 * - [uiCustomization]{@link module:ui/page/admin/ui-customization} - the UI customization
 * - [adminFaq]{@link module:ui/page/admin/admin-faq} - admin FAQ page
 *
 * ### Admin Content (Metadata) Management
 * - [manageContent]{@link module:ui/page/admin/content/manage} -
 *   a dashboard for managing site content
 * - [addContent]{@link module:ui/page/admin/content/add} - the content creation form
 * - [addContentBulk]{@link module:ui/page/admin/add-content-bulk} -
 *   for admins to upload content in bulk
 * - [contentProfile]{@link module:ui/page/admin/content/profile} -
 *   page for editing content metadata
 *
 * ### Admin Course Management
 * - [manageCourses]{@link module:ui/page/admin/course/manage} - a dashboard for managing courses
 * - [courseProfile]{@link module:ui/page/admin/course/profile} - for admins to create or edit
 *   a course
 * - [manageAssessments]{@link module:ui/page/admin/assessment/manage} - a dashboard for
 *   managing assessments
 * - [assessmentProfile]{@link module:ui/page/admin/assessment/profile} - for admins to create or
 *   edit an assessment
 *
 * ### Admin Group Management
 * - [manageGroups]{@link module:ui/page/admin/group/manage} - a dashboard for managing user groups
 * - [groupProfile]{@link module:ui/page/admin/group/profile} - for admins to create or edit a group
 *
 * ### Admin User Management
 * - [manageUsers]{@link module:ui/page/admin/user/manage} - a dashboard for managing site users
 * - [addUser]{@link module:ui/page/admin/user/add} - for admins to create new users
 * - [addUserBulk]{@link module:ui/page/admin/user/bulk} - for admins to bulk upload users
 * - [bulkAddUserGroup]{@link module:ui/page/admin/user/bulk/user-group} -
 *   for admins to bulk upload user groups
 * - [userProfile]{@link module:ui/page/admin/user/profile} - where admins edit user profiles
 * - [sendAnnouncement]{@link module:ui/page/admin/send-announcement} - where admins send
 *   notifications to users
 * - [manageMessages]{@link module:ui/page/admin/messaging/manage} - where admins manages
 *   users chat messages
 *
 * ### Admin Dynamic Page Management
 * - [addPage]{@link module:ui/page/admin/dynamic/add} - for admins to add pages
 * - [managePages]{@link module:ui/page/admin/dynamic/manage} - dashboard for managing dynamic pages
 * - [pageProfile]{@link module:ui/page/admin/dynamic/profile} - editor for dynamic pages
 * - [editMenu]{@link module:ui/page/admin/menu} - editor for site navigation menu
 *
 * ### Admin Evaluation Management
 * - [managePages]{@link module:ui/page/admin/evaluation/manage} - dashboard for managing
 *   evaluations
 * - [userProfile]{@link module:ui/page/admin/evaluation/profile} - where admins edit evaluation
 *   profiles
 *
 * ### Development Pages (not present in production builds)
 * - [test]{@link module:ui/page/test} - a test page for components
 *
 * @module ui/page
 * @category Pages
 */
import account from "./account";
import addContent from "./admin/content/add";
import massContentCreate from "./admin/content/bulk";
import addPage from "./admin/dynamic/add";
import addUser from "./admin/user/add";
import massUserCreate from "./admin/user/bulk";
import admin from "./admin/stats";
import adminFaq from "./admin/admin-faq";
import assessmentProfile from "./admin/assessment/profile";
import massUserGroupCreate from "./admin/user/bulk/user-group";
import contentProfile from "./admin/content/profile";
import chat from "./chat";
import courseProfile from "./admin/course/profile";
import dynamic from "./dynamic";
import editMenu from "./admin/menu";
import endUserFaq from "./end-user-faq";
import evaluationProfile from "./admin/evaluation/profile";
import feedback from "./feedback";
import groupProfile from "./admin/group/profile";
import lecture from "./lecture";
import login from "./login";
import manageAssessments from "./admin/assessment/manage";
import manageContent from "./admin/content/manage";
import manageCourses from "./admin/course/manage";
import manageEvaluations from "./admin/evaluation/manage";
import manageGroups from "./admin/group/manage";
import manageMessages from "./admin/messaging/manage";
import managePages from "./admin/dynamic/manage";
import manageUsers from "./admin/user/manage";
import pageProfile from "./admin/dynamic/profile";
import pdf from "./pdf";
import privacy from "./privacy";
import score from "./score";
import search from "./search";
import sendAnnouncement from "./admin/send-announcement";
import studentAssessment from "./assessment";
import termsOfService from "./tos";
import test from "./admin/test";
import testData from "./admin/test-data";
import threadProfile from "./admin/messaging/thread-profile";
import uiCustomization from "./admin/ui-customization";
import userProfile from "./admin/user/profile";
import accountDeletionFaq from "./faq/account-deletion";
import accountSettingsFaq from "./faq/account-settings";
import contentFaq from "./faq/content";
import forgotPasswordFaq from "./faq/forgot-password";
import loginFaq from "./faq/login";
import registrationFaq from "./faq/registration";
import searchFaq from "./faq/search";

// note: when adding pages, please update the module documentation with a link!
export default {
  account,
  accountDeletionFaq,
  accountSettingsFaq,
  addContent,
  addPage,
  addUser,
  admin,
  adminFaq,
  assessmentProfile,
  chat,
  contentFaq,
  contentProfile,
  courseProfile,
  dynamic,
  editMenu,
  endUserFaq,
  evaluationProfile,
  feedback,
  forgotPasswordFaq,
  groupProfile,
  lecture,
  login,
  loginFaq,
  manageAssessments,
  manageContent,
  manageCourses,
  manageEvaluations,
  manageGroups,
  manageMessages,
  managePages,
  manageUsers,
  massContentCreate,
  massUserCreate,
  massUserGroupCreate,
  pageProfile,
  pdf,
  privacy,
  registrationFaq,
  score,
  search,
  searchFaq,
  sendAnnouncement,
  studentAssessment,
  termsOfService,
  test,
  testData,
  threadProfile,
  uiCustomization,
  userProfile,
};
