/**
 * A tooltip common functions.
 *
 * @module ui/component/tooltip/common
 * @category UI
 * @subcategory Components
 */

/**
 * Tooltip positions enum
 *
 * @enum tooltipPositions
 * @readonly
 * @property {string} TOP
 * @property {string} RIGHT
 * @property {string} BOTTOM
 * @property {string} LEFT
 */
export const tooltipPositions = Object.freeze({
  TOP: "TOP",
  RIGHT: "RIGHT",
  BOTTOM: "BOTTOM",
  LEFT: "LEFT",
});

/**
 * Tooltip modes enum
 *
 * @enum tooltipModes
 * @readonly
 * @property {string} HOVER
 * @property {string} CLICK
 */
export const tooltipModes = Object.freeze({
  HOVER: "HOVER",
  CLICK: "CLICK",
});

export const removeAllPositionClasses = (element) => {
  element.classList.remove(tooltipPositions.TOP);
  element.classList.remove(tooltipPositions.RIGHT);
  element.classList.remove(tooltipPositions.LEFT);
  element.classList.remove(tooltipPositions.BOTTOM);
};

export const getTooltipTopPosition = (tooltipRect, containerRect, TOOLTIP_MARGIN = 0) => {
  const left = -(tooltipRect.width / 2) + containerRect.width / 2;
  const top = -tooltipRect.height - TOOLTIP_MARGIN;
  return {
    left,
    top,
  };
};

export const getTooltipBottomPosition = (tooltipRect, containerRect, TOOLTIP_MARGIN = 0) => {
  const left = -(tooltipRect.width / 2) + containerRect.width / 2;
  const top = containerRect.height + TOOLTIP_MARGIN;
  return {
    left,
    top,
  };
};

export const getTooltipLeftCoordinates = (tooltipRect, containerRect, TOOLTIP_MARGIN = 0) => {
  const left = -tooltipRect.width - TOOLTIP_MARGIN;
  const top = -(tooltipRect.height / 2) + containerRect.height / 2;
  return {
    left,
    top,
  };
};

export const getTooltipRightCoordinates = (tooltipRect, containerRect, TOOLTIP_MARGIN = 0) => {
  const left = containerRect.width + TOOLTIP_MARGIN;
  const top = -(tooltipRect.height / 2) + containerRect.height / 2;
  return {
    left,
    top,
  };
};

const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && (rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const repositionTooltip = (position, tooltipElement, margin) => {
  /* eslint-disable no-param-reassign */
  const containerElement = tooltipElement.parentElement;
  const tooltipRect = tooltipElement.getBoundingClientRect();
  const containerRect = containerElement.getBoundingClientRect();
  let coordinates;
  switch (position) {
    case tooltipPositions.TOP: {
      coordinates = getTooltipTopPosition(tooltipRect, containerRect, margin);
      break;
    }
    case tooltipPositions.BOTTOM: {
      coordinates = getTooltipBottomPosition(tooltipRect, containerRect, margin);
      break;
    }
    case tooltipPositions.LEFT: {
      coordinates = getTooltipLeftCoordinates(tooltipRect, containerRect, margin);
      break;
    }
    case tooltipPositions.RIGHT:
    default: {
      coordinates = getTooltipRightCoordinates(tooltipRect, containerRect, margin);
    }
  }
  const { left, top } = coordinates;
  removeAllPositionClasses(tooltipElement);
  tooltipElement.classList.add(position);
  tooltipElement.style.left = `${left}px`;
  tooltipElement.style.top = `${top}px`;

  if (!isInViewport(tooltipElement)) {
    coordinates = getTooltipRightCoordinates(tooltipRect, containerRect, margin);
    removeAllPositionClasses(tooltipElement);
    tooltipElement.classList.add(tooltipPositions.RIGHT);
    tooltipElement.style.left = `${coordinates.left}px`;
    tooltipElement.style.top = `${coordinates.top}px`;
  }
  if (!isInViewport(tooltipElement)) {
    coordinates = getTooltipLeftCoordinates(tooltipRect, containerRect, margin);
    removeAllPositionClasses(tooltipElement);
    tooltipElement.classList.add(tooltipPositions.LEFT);
    tooltipElement.style.left = `${coordinates.left}px`;
    tooltipElement.style.top = `${coordinates.top}px`;
  }
  if (!isInViewport(tooltipElement)) {
    coordinates = getTooltipTopPosition(tooltipRect, containerRect, margin);
    removeAllPositionClasses(tooltipElement);
    tooltipElement.classList.add(tooltipPositions.TOP);
    tooltipElement.style.left = `${coordinates.left}px`;
    tooltipElement.style.top = `${coordinates.top}px`;
  }
  if (!isInViewport(tooltipElement)) {
    coordinates = getTooltipBottomPosition(tooltipRect, containerRect, margin);
    removeAllPositionClasses(tooltipElement);
    tooltipElement.classList.add(tooltipPositions.BOTTOM);
    tooltipElement.style.left = `${coordinates.left}px`;
    tooltipElement.style.top = `${coordinates.top}px`;
  }
};

export const addTooltipShowClass = (tooltipElement, onTooltipShow) => () => {
  tooltipElement.classList.add("visible");
  onTooltipShow();
};

export const removeTooltipShowClass = (tooltipElement, onTooltipHide) => () => {
  tooltipElement.classList.remove("visible");
  onTooltipHide();
};

export const initializeTooltipEvents = ({
  tooltipElement,
  mode,
  margin,
  position,
  onTooltipShow,
  onTooltipHide,
}) => {
  const containerElement = tooltipElement.parentElement;
  switch (mode) {
    case tooltipModes.CLICK: {
      addTooltipShowClass(tooltipElement, onTooltipShow)();
      repositionTooltip(position, tooltipElement, margin);
      setTimeout(() => {
        document.body.addEventListener("click", removeTooltipShowClass(tooltipElement, onTooltipHide));
      });
      break;
    }
    case tooltipModes.HOVER:
    default: {
      containerElement.addEventListener("mouseover", () => {
        addTooltipShowClass(tooltipElement, onTooltipShow)();
        repositionTooltip(position, tooltipElement, margin);
      });
      containerElement.addEventListener("mouseleave", removeTooltipShowClass(tooltipElement, onTooltipHide));
    }
  }
  window.addEventListener("resize", () => repositionTooltip(position, tooltipElement, margin));
};
