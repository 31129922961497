/**
 * Course data stream.
 *
 * Provides `getMany` interface for fetching course by
 * `[courseId]`.
 *
 * @module data/course/assessment
 * @category Data Streams
 * @subcategory Course
 */
import xs from "xstream";
import { byId } from "api/v2/course/grading-scheme";
import { CACHE_COURSE_GRADING_KEY_V2 as CACHE_KEY } from "cache/constants";
import { makeGradingScheme } from "model/course/grading-scheme";
import many from "data/pipeline/many";
import hashmap from "util/hash-map";

const byIds = async (ids) => Promise.all(ids.map(byId));

const itemMap = hashmap();

const defaultFn = (id) => makeGradingScheme({ id });

const modelFn = (partial) => makeGradingScheme(partial);

const all$ = xs.create();

const {
  post: getMany,
  sink$: getManySink$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

all$.imitate(getManySink$);

export default {
  getMany,
  all$,
};
