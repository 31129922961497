/**
 * A component for uploading and/or selecting existing files.
 *
 * TODO more thorough refactor to turn this into a standard managed field;
 *      handle most interactions in a modal instead of the tabs setup
 *
 * @module ui/component/form-managed/file-picker
 * @category UI
 * @subcategory Forms
 */
import div from "ui/html/div";
import input from "ui/html/input";
import cutout from "ui/component/cutout";
import hint from "ui/component/form-managed/hint";
import tabs from "ui/component/tabs";
import { managedField } from "ui/view/form-managed/util";
import { merge } from "util/object";
import { emit } from "util/event";
import { filePickerModes as modes } from "./constants";
import keepFileTab from "./tab-keep";
import selectFileTab from "./tab-select-file";
import uploadFileTab from "./tab-upload";
import {
  defaultConfig,
  defaultValue,
  documentConfig,
  imageAndSvgConfig,
  imageConfig,
  keepConfig,
  newConfig,
  newVideoConfig,
  svgConfig,
  uploadConfig,
  videoConfig,
} from "./state";
import { makeGetInput } from "./util";

/**
 * see [filePickerModes]{@link module:ui/component/form-managed/file-picker~filePickerModes}
 * @typedef {string} FilePickerMode
 */
export const filePickerModes = modes;

// stores a cache of vnodes for internal replacement when a file selection
// happens
const nodeCache = {};

const getValue = (state) => (node) => {
  const getInput = makeGetInput(node.managedField.name);
  const mode = getInput("mode")?.value;
  if (!mode) {
    return ({
      mode: state?.mode || null,
      current: state.value?.current || null,
    });
  }
  const descriptorId = getInput("descriptorId")?.value;
  const uploadInput = getInput("upload") || null;
  const uploadFile = uploadInput.value
    ? uploadInput.files[0]
    : null;
  const fileName = getInput("file")?.value || null;
  const urlValue = getInput("url")?.value;
  let descriptor = null;
  if (descriptorId && mode === filePickerModes.DESCRIPTOR) {
    descriptor = state.fileDescriptors.find((desc) => desc.id === descriptorId);
  }
  const file = (fileName && (mode === filePickerModes.FILE)
    ? fileName
    : null
  );
  const upload = (uploadFile && (mode === filePickerModes.UPLOAD)
    ? uploadFile
    : null
  );
  const url = (urlValue && (mode === filePickerModes.URL)
    ? urlValue
    : null
  );
  const value = {
    mode,
    upload,
    url,
    descriptor,
    file,
    current: state.value?.current || null,
  };
  return value;
};

const requiredHook = (state) => (node, validity) => {
  /* eslint-disable no-param-reassign */
  if (state.disabled || !state.required) return;
  const value = getValue(state)(node);
  const missing = () => {
    validity.valueMissing = true;
    validity.valid = false;
  };
  if (!value?.mode) {
    missing();
  }
  switch (value.mode) {
    case modes.URL:
      if (!value.url) missing();
      break;
    case modes.FILE:
      if (!value.file) missing();
      break;
    case modes.UPLOAD:
      if (!value.upload) missing();
      break;
    case modes.DESCRIPTOR:
      if (!value.descriptor) missing();
      break;
    case modes.KEEP:
      if (!value.current) missing();
      break;
    default:
      missing();
  }
  /* eslint-enable no-param-reassign */
};

/**
 * Callback called when the file picker's mode changes. Contents vary by mode.
 *
 * - in DESCRIPTOR mode, includes a `descriptor` with the chosen fileDescriptor
 * - in URL mode, includes `url` string input by user
 * - in FILE mode, includes `fileName` string for media dump file
 * - in KEEP mode, includes `descriptor` of the previous value
 * - in UPLOAD mode, contains a selector for the file input element in `upload` property
 * @callback FilePickerOnChangeCallback
 * @param {object} selection
 * @param {FilePickerMode} mode
 * @param {?FileDescriptor} descriptor present when mode === filePickerModes.DESCRIPTOR
 * @param {?string} url present when mode === filePickerModes.URL
 * @param {?string} fileName present when mode === filePickerModes.FILE
 * @param {?Selector} upload present when mode === filePickerModes.UPLOAD
 */

/**
 * @function onChange
 * @private
 * @param {object} state
 * @param {object} newValue
 * @param {View} ModalView
 */
const onChange = (state, newValue) => {
  const getInput = makeGetInput(state.name);
  getInput("mode").value = newValue.mode;
  switch (newValue.mode) {
    case modes.KEEP:
      break;
    case modes.DESCRIPTOR:
      getInput("descriptorId").value = newValue.descriptor?.id || "";
      break;
    case modes.FILE:
      getInput("file").value = newValue.file;
      break;
    case modes.URL:
      getInput("url").value = newValue.url;
      break;
    case modes.UPLOAD:
      break;
    default:
      break;
  }
  emit(
    document.querySelector(`[data-file-picker-id="${state.name}"]`),
    "em:form-managed-change",
  );
};

const getModeIndex = (mode) => {
  switch (mode) {
    case filePickerModes.KEEP:
      return filePickerModes.KEEP;
    case filePickerModes.URL:
    case filePickerModes.UPLOAD:
      return filePickerModes.UPLOAD;
    case filePickerModes.FILE:
    case filePickerModes.DESCRIPTOR:
      return filePickerModes.FILE;
    default:
      return mode;
  }
};

const buildTabs = (state) => {
  const getInput = makeGetInput(state.name);
  const contents = [];
  const indices = [];
  const labels = [];
  const { allowedModes } = state;

  if (allowedModes.includes(filePickerModes.KEEP)) {
    indices.push(filePickerModes.KEEP);
    labels.push("Keep File");
    contents.push(keepFileTab(state, onChange));
  }
  if (allowedModes.includes(filePickerModes.UPLOAD)
    || allowedModes.includes(filePickerModes.URL)) {
    indices.push(filePickerModes.UPLOAD);
    labels.push("Upload File");
    contents.push(uploadFileTab(state, onChange));
  }
  if (allowedModes.includes(filePickerModes.FILE)
    || allowedModes.includes(filePickerModes.DESCRIPTOR)) {
    indices.push(filePickerModes.FILE);
    labels.push("Select File");
    contents.push(selectFileTab(state, onChange));
  }

  const currentMode = state.value?.mode || allowedModes?.[0] || filePickerModes.KEEP;

  let currentTab = indices.indexOf(getModeIndex(currentMode));

  // don't start off on KEEP tab if there's nothing to keep
  if (currentMode === filePickerModes.KEEP && !state.value.current && contents.length > 1) {
    currentTab = 1;
  }
  return contents.length > 1 ? tabs(
    labels,
    contents,
    currentTab,
    ".file-picker-tabs",
    undefined,
    state.disabled,
    (selectedTab) => {
      let mode = indices[selectedTab];
      const descriptor = state.fileDescriptors.find(
        (desc) => desc.id === getInput("descriptorId").value,
      ) || null;
      const url = getInput("url")?.value || null;
      const file = getInput("file")?.value || null;
      // if mode is FILE and there was a previous descriptor id, update the mode
      // because we're switching back to a prior DESCRIPTOR mode
      if (descriptor && mode === filePickerModes.FILE) {
        mode = filePickerModes.DESCRIPTOR;
      }
      if (url && mode === filePickerModes.UPLOAD) {
        mode = filePickerModes.URL;
      }
      onChange(state, {
        mode,
        descriptor,
        url,
        file,
      });
    },
  ) : cutout(contents[0]);
};

/**
 * A file picker form component.
 *
 * Note: the `config.onSelect` parameter MUST be set if the file picker is part of
 *       a vNode tree, e.g. on a page. Otherwise it will patch itself, and you will
 *       get an error like `failed to execute 'insertBefore' on 'node' because the
 *       picker's node in the tree is stale. Instead, provide an onSelect callback,
 *       and patch the page with the `newFile` provided as the first parameter.
 *
 * @function filePicker
 * @param {Object} state
 * @param {string} state.name prefix for input field names
 * @param {String[]} [state.uploadFileNames] list of available upload files
 * @param {FileDescriptor[]} [state.fileDescriptors] list of available descriptors
 * @param {object} [state.newFile=""] new file name or descriptor id
 * @param {FileSelectionType} [state.newFileType=fileSelectionTypes.UPLOAD]
 * @param {FilePickerMode[]} [state.allowedModes] list of accepted
 *        filePickerModes (default: keep, upload, select)
 * @param {MetadataType[]} [state.fileTypes] list of file types permitted
 *        (default: [DOCUMENT, VIDEO, IMAGE, URL])
 * @param {boolean} [state.disabled=false] whether the form elements should be enabled
 * @param {FilePickerState} [state.value=defaultValue] file picker value
 * @param {string} [state.value.url=""] new file url (used when the file will be
 *        downloaded)
 * @param {string} [state.value.file=""] upload file name
 * @param {?FilePickerOnChangeCallback} [state.onChange] called when the mode changes or
 *        a new selection is made. If not present, filePicker will self-patch
 * @return {module:ui/common/el~El}
 */
export default function filePicker(...args) {
  // throw out falsy states, which may occur in some of the convenience functions
  const inState = merge.all([
    defaultConfig,
    ...args.filter((c) => typeof c === "object" && c !== null),
  ]);
  const state = merge.all([
    inState,
    {
      value: inState.values?.[inState.name] || inState.value || defaultValue,
    },
  ]);

  const { name, disabled, helpTexts, value, validity } = state;

  const picker = managedField(
    div(
      `#file-picker-${state.name}.file-picker${disabled ? ".disabled" : ""}`,
      {
        dataset: { filePickerId: name, name },
      },
      [
        input("hidden", value.mode, false, `${name}_mode`, { props: { disabled } }),
        buildTabs(state),
        ...hint({ name, helpTexts, validity }),
      ],
    ),
    name,
    getValue(state),
    [requiredHook(state)],
  );

  nodeCache[state.name] = picker;
  return picker;
}

/**
 * Preconfigured for replacing or selecting a document file.
 *
 * @function filePicker.document
 * @extends filePicker
 */
filePicker.document = (...args) => filePicker(documentConfig, ...args);

/**
 * Preconfigured for selecting a new document file.
 *
 * @function filePicker.newDocument
 * @extends filePicker
 */
filePicker.newDocument = (...args) => filePicker(
  documentConfig, newConfig, ...args,
);

/**
 * Preconfigured for replacing or selecting an image file.
 *
 * @function filePicker.image
 * @extends filePicker
 */
filePicker.image = (...args) => filePicker(imageConfig, ...args);

/**
 * Preconfigured for replacing or selecting an image or svg file.
 *
 * @function filePicker.imageAndSvg
 * @extends filePicker
 */
filePicker.imageAndSvg = (...args) => filePicker(imageAndSvgConfig, ...args);

/**
 * Preconfigured for replacing or selecting a svg file.
 *
 * @function filePicker.svg
 * @extends filePicker
 */
filePicker.svg = (...args) => filePicker(svgConfig, ...args);

/**
 * Preconfigured for selecting a new image file.
 *
 * @function filePicker.newImage
 * @extends filePicker
 */
filePicker.newImage = (...args) => filePicker(
  imageConfig, newConfig, ...args,
);

/**
 * Preconfigured for replacing or selecting a video file.
 *
 * @function filePicker.video
 * @extends filePicker
 */
filePicker.video = (...args) => filePicker(videoConfig, ...args);

/**
 * Preconfigured for selecting a new video file.
 *
 * @function filePicker.newVideo
 * @extends filePicker
 */
filePicker.newVideo = (...args) => filePicker(newVideoConfig, ...args);

/**
 * A file upload picker without tabs or support for other file selection methods.
 *
 * @function filePicker.upload
 * @extends filePicker
 */
filePicker.upload = (...args) => filePicker(uploadConfig, ...args);

/**
 * A file keep picker without tabs or support for other file selection methods.
 *
 * @function filePicker.keep
 * @extends filePicker
 */
filePicker.keep = (...args) => filePicker(keepConfig, ...args);
