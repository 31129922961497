/**
 * Builds a playlist carousel for a dynamic page.
 *
 * @module ui/page/dynamic/section-playlist-carousel
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import cardCarousel from "ui/component/card-carousel";
import { upgradeMetadataItem } from "./common";

const makeSectionId = (id) => `page-section-playlist-carousel-${id}`;

/**
 * A carousel display for a playlist section.
 *
 * @function sectionPlaylistCarousel
 * @param {module:model/dynamic-page~PageSection} section of type `PLAYLIST_CAROUSEL`
 * @param {object} listEntry collection of playlist items
 * @return {module:ui/common/el~El} section element
 */
export default function sectionPlaylistCarousel(state, sectionId) {
  const pageSection = state.sections.get(sectionId);
  if (!pageSection) return "";

  const playlist = state.playlists.get(pageSection.itemId);
  if (!playlist) return cardCarousel.placeholder(".playlist-carousel", makeSectionId(sectionId));

  // prefer full metadata items if present

  // issue: playlists do not filter their metadata items based on the item's own ACL
  // status, and yet users cannot watch a video or download a PDF if they do not have
  // individual access rights to the item. Here we're detecting items that lack
  // those rights and filtering them out to avoid user frustration.

  let items = playlist.items.map(upgradeMetadataItem(state));
  if (items.some((i) => i.PLACEHOLDER) && items.some((i) => !i.PLACEHOLDER)) {
    items = items.filter((i) => !i.PLACEHOLDER);
  }

  const position = state.positions.get(makeSectionId(sectionId)) || 0;

  return cardCarousel({
    list: {
      id: makeSectionId(sectionId),
      type: pageSection.type,
      items,
      title: pageSection.title,
      text: pageSection.text,
      playback: state.playback,
      cardSize: pageSection.searchData?.cardSize,
    },
    position,
  });
}
