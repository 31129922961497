/**
 * The dashboard for the left of the search results page.
 *
 * @module ui/component/search-dashboard
 * @category UI
 * @subcategory Components
 */
import { div } from "ui/html";
import toggle from "ui/component/form-managed/field-toggle";
import input from "ui/component/form-managed/field-input";
import button from "ui/component/form-managed/button";
import { metadataTypes } from "model/metadata/constants";
import { emit } from "util/event";
import { qs } from "util/dom";

const emitFilterEvent = (types, type) => (state) => {
  const newTypes = new Set([...types.values()]);
  if (state) newTypes.add(type);
  else newTypes.delete(type);
  setTimeout(() => {
    emit(document.body, "em:filter-criteria", newTypes);
  }, 0);
};

const toggleBlock = ({ types }) => div("#toggle-wrapper", [
  toggle.boxed.inverse({
    toggled: types ? types.has(metadataTypes.VIDEO) : true,
    required: false,
    name: "video-filter",
    label: "Video",
    onToggle: emitFilterEvent(types, metadataTypes.VIDEO),
  }),
  toggle.boxed.inverse({
    toggled: types ? types.has(metadataTypes.DOCUMENT) : true,
    required: false,
    name: "document-filter",
    label: "Document",
    onToggle: emitFilterEvent(types, metadataTypes.DOCUMENT),
  }),
  toggle.boxed.inverse({
    toggled: types ? types.has(metadataTypes.LIST) : true,
    required: false,
    name: "list-filter",
    label: "List",
    onToggle: emitFilterEvent(types, metadataTypes.LIST),
  }),
]);

const onSearch = () => {
  const newTerms = qs(".search-input-container input")?.value;
  emit(document.body, "em:search-terms", { value: newTerms });
};

const clearInput = () => {
  const inputField = qs(".search-input-container input");
  if (inputField) {
    inputField.value = "";
  }
};

export default function searchDashboard(state = {}) {
  const { searchParams } = state;
  const inputValue = searchParams?.terms?.[0];
  if (!inputValue) {
    clearInput();
  }
  return div(".dashboard", [
    div(".search-input-container", [
      input({
        value: inputValue || "",
        label: "Search...",
        onInput: (e) => {
          if (e.keyCode === 13) {
            onSearch();
          }
        },
      }),
      button.icon({
        icon: "magnifying-glass",
        onClick: onSearch,
      }),
    ]),
    toggleBlock(searchParams),
  ]);
}
