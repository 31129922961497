import { merge } from "util/object";
import { div, span } from "ui/html";
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import { inboxTypes } from "model/message/constants";
import getUsername from "ui/component/messaging/username";
import { getChannelUsersExtended } from "./common";
import { getIconName } from "../group-icon";
import icon from "../icon";
import messageSearch from "./message-search";
import channelUsersPreview from "./channel-users-preview";

const defaultState = {
  channels: [],
  blockedUserIds: new Set(),
  onUnblockUser: () => {},
  onClickUser: () => {},
};

const getChannelTitle = (state) => {
  const { selectedChannel } = state;
  if (selectedChannel && selectedChannel.type === inboxTypes.GROUP) {
    const title = systemPrivilegeGroupsFriendly.get(selectedChannel.name) || selectedChannel.name;
    return div(".label", { attrs: { title } }, [
      icon.solid(getIconName(selectedChannel.name)),
      span(title),
    ]);
  }
  if (selectedChannel && selectedChannel.type === inboxTypes.DM) {
    const title = getUsername(selectedChannel);
    return div(".label", { attrs: { title } }, [
      icon.solid("user"),
      span(title),
    ]);
  }
  return "?";
};

const getChannelUsers = (state, modalView) => channelUsersPreview({
  users: getChannelUsersExtended(state),
  blockedUserIds: state.blockedUserIds,
  onUnblockUser: state.onUnblockUser,
  onClickUser: state.onClickUser,
}, modalView);

export default function currentChatHeader(inState, modalView) {
  const state = merge(defaultState, inState);
  return div(".chat-header.action-bar", [
    div(".channel-title", getChannelTitle(state)),
    messageSearch({
      ...state,
      users: getChannelUsersExtended(state),
    }, modalView),
    getChannelUsers(state, modalView),
  ]);
}
