/**
 * Message item component
 *
 * @module ui/component/chat/message
 * @category UI
 * @subcategory Chat
 */
import { merge } from "util/object";
import { a, div, span } from "ui/html";
import buttonGroup from "ui/component/button-group";
import button from "ui/component/form-managed/button";
import getAvatar from "ui/component/messaging/avatar";
import getUsername from "ui/component/messaging/username";
import { timeStamp } from "./common";
import messageAttachmentPreview from "./message-attachment-preview";

const defaultState = {
  users: [],
  message: null,
  descriptors: new Map(),
  onReportMessage: () => {
  },
  onClickUser: () => {},
  showReportButton: true,
};

const reportButton = (state) => (
  state.message.from !== state.me.id && state.showReportButton
    ? button.icon({
      icon: "flag-alt",
      label: "Report the message",
      sel: ".danger",
      onClick: () => state.onReportMessage(state),
    })
    : ""
);

const blockButton = (state, fromUser) => (
  state.onBlockUser && state.message.from !== state.me.id && !fromUser.isModerator
    ? button.icon({
      icon: "stop-circle",
      label: "Block the user",
      sel: ".warn",
      onClick: () => state.onBlockUser(state.message),
    })
    : ""
);

const makeOnClickUser = (fromUser, me, cb) => () => {
  if (fromUser.id !== me.id) cb(fromUser.id);
};

const makeAttachmentMap = (state, modalView) => (attachment) => (
  state.descriptors?.get(attachment)
    ? messageAttachmentPreview({
      attachment: state.descriptors.get(attachment),
    }, modalView)
    : ""
);

export default function chatMessage(inState, modalView) {
  const state = merge(defaultState, inState);
  const { message, me } = state;
  let fromUser = state.knownUsers.get(message.from);
  if (!fromUser) fromUser = message.fromUser;
  if (!fromUser) fromUser = { username: "(unknown user)", PLACEHOLDER: true };
  return div(
    `.chat-message${message.from === me.id ? ".me" : ".other"}${message.superReply ? ".super-reply" : ""}${fromUser.isModerator ? ".moderator" : ""}`,
    {
      dataset: {
        messageId: state.message.id,
      },
    },
    [
      message.superReply
        ? span("M", ".chat-avatar")
        : a.fn(
          span(getAvatar(fromUser), `.chat-avatar`),
          makeOnClickUser(fromUser, me, state.onClickUser),
        ),
      div(".content", [
        span([
          message.superReply
            ? span("Moderator", ".username")
            : a.fn(
              span(getUsername(fromUser), ".username"),
              makeOnClickUser(fromUser, me, state.onClickUser),
            ),
          timeStamp(message.created),
        ], ".container-name"),
        span(message.text, ".text"),
        div(
          ".attachments",
          message?.attachments?.map(makeAttachmentMap(state, modalView)),
        ),
      ]),
      buttonGroup([
        reportButton(state),
        blockButton(state, fromUser),
      ]),
    ],
  );
}
