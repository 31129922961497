/**
 * Modal component for selecting an internal or external link.
 *
 * Used by the rich text editor.
 *
 * @module ui/component/modal/select-link
 * @category UI
 * @subcategory Modal Dialogs
 */
import { span } from "ui/html";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import urlRelative from "ui/component/form-managed/field-url-relative";
import pageSelectModal from "ui/component/modal/page-select";
import multiToggle from "ui/component/multi-toggle";
import cameoPage from "ui/component/cameo-page";
import dialog from "ui/component/modal/layout/dialog";
import formManagedView from "ui/view/form-managed";
import { getModal } from "ui/view/modal-view";
import { frozen, merge } from "util/object";

let count = 0;
let storedFormView;

export const modes = frozen({
  URL: 1,
  PAGE: 2,
});

const defaultSelectLinkState = frozen({
  pages: [],
  mode: null,
  url: "",
  page: null,
  validity: {
    valid: true,
  },
  messages: [],
});

const getMode = ({ url, pages }) => {
  if (!url) {
    return ({
      mode: modes.PAGE,
      page: null,
      url: null,
    });
  }
  if (url?.charAt(0) === "/") {
    const slug = url.slice(1);
    const page = pages.find((p) => p.slug === slug);
    if (page) {
      return ({
        mode: modes.PAGE,
        page,
        url: null,
      });
    }
  }
  return ({
    mode: modes.URL,
    page: null,
    url,
  });
};

/**
 * Creates placeholder elements used by the inner form view.
 * @function createPlaceholderElements
 * @private
 */
const createPlaceholderElements = (modalView) => {
  const formEl = document.createElement("FORM");
  formEl.id = "link-select-dialog-form";
  modalView.element.querySelector(".body").replaceChildren(formEl);
};

/**
 * Handles updating the form view.
 * @function onFormUpdate
 * @private
 */
export const onFormUpdate = (formView) => {
  const url = formView.values.url || formView.state.url;
  formView.updateState({ url });
  formView.patch(formView.factory(formView));
  formView.updateState({ messages: [] });
};

/**
 * Handles re-binding inner views after a view update.
 *
 * @function bindInnerViews
 * @private
 */
export const bindInnerViews = (modalView, formView = null) => {
  createPlaceholderElements(modalView);
  formView?.rebind("#link-select-dialog-form");
  formView?.qs(`input[name="url"]`)?.focus();
};

/**
 * Handles selecting a page for a page link.
 * @function onSelectPage
 * @private
 */
const onSelectPage = (self, modalView) => async () => {
  const { pages } = self.state;
  const selected = (await modalView.async(pageSelectModal({
    pages,
  }, modalView)));
  if (selected) {
    self.update({ page: selected });
  }
};

const okToSave = (self) => {
  if (!self) return false;
  const validity = self.validate(true);
  switch (self.state.mode) {
    case modes.URL:
      if (self.values.url && validity.valid) {
        return true;
      }
      break;
    case modes.PAGE:
    default:
      if (self.state.page?.slug) {
        return true;
      }
  }
  return false;
};

const doSave = (modalView) => () => {
  const self = storedFormView;
  self.setFullValidation(true);
  if (!okToSave(self)) return false;
  storedFormView = null;
  switch (self.state.mode) {
    case modes.URL:
      return modalView.resolve(self.values.url);
    case modes.PAGE:
    default:
      return modalView.resolve(`/${self.state.page.slug}`);
  }
};

const buildTab = (self, modalView) => {
  const {
    mode,
    page,
  } = self.state;
  const fields = [];
  switch (mode) {
    case modes.URL:
      fields.push([urlRelative, {
        name: "url",
        required: true,
      }]);
      break;
    case modes.PAGE:
    default:
      if (page) {
        fields.push(cameoPage({
          page,
          controls: [
            button({
              icon: "pencil",
              label: "Change",
              iconOnly: true,
              onClick: onSelectPage(self, modalView),
            }),
          ],
        }));
      } else {
        fields.push(button.standIn({
          icon: "file-alt",
          label: "Select Page",
          onClick: onSelectPage(self, modalView),
        }));
      }
  }
  return fields;
};

const onSelectMode = (selection) => storedFormView.update({ mode: selection.value });

/**
 * Builds elements for the inner form view.
 * @function build
 * @private
 */
const build = (modalView) => (self) => {
  const { mode } = self.state;
  return form("#link-select-dialog-form", self.bind(
    [
      multiToggle({
        onSelect: onSelectMode,
        selectedTab: mode === modes.PAGE ? 0 : 1,
        options: [
          { label: "Page Link", name: "link-select-page", value: modes.PAGE },
          { label: "External Link", name: "link-select-url", value: modes.URL },
        ],
      }, {}),
      ...buildTab(self, modalView),
      button({
        icon: "link",
        label: "Create Link",
        onClick: doSave(modalView),
        disabled: !okToSave(self),
      }),
    ], {}),
  );
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initInnerViews
 * @private
 */
const initInnerViews = (state, modalView) => {
  if (storedFormView) {
    bindInnerViews(modalView, storedFormView);
    return;
  }

  createPlaceholderElements(modalView);

  storedFormView = formManagedView(build(modalView), onFormUpdate)(
    "#link-select-dialog-form",
    state,
  );
};

/**
 * A modal for creating a link, either by inputting an external URL or by selecting
 * an existing page in the system.
 *
 * @function linkSelectModal
 * @async
 * @param {Object} state
 * @param {?string} state.url the current URL if present
 * @param {?modes} [state.mode=modes.URL] which mode to start in
 * @param {DynamicPage[]} [state.pages] pages available for selection
 * @return {string} url to be applied (either page slug or external)
 */
export default function linkSelectModal(inState) {
  storedFormView = null;
  const modalView = getModal();
  const state = merge.all([
    defaultSelectLinkState,
    inState,
    inState.mode ? {} : getMode(inState),
  ]);

  return dialog({
    sel: ".link-select",
    config: {
      dataset: { entryEditId: (++count) },
      hook: {
        insert: () => initInnerViews(state, modalView),
        postpatch: () => initInnerViews(state, modalView),
      },
      key: "link-select-dialog-form",
    },
    body: span("placeholder"),
    footer: "",
    onClose: () => { storedFormView = null; },
  });
}
