/**
 * API requests for dynamic pages.
 *
 * @module api/v2/dynamic-page
 * @category Backend API v2
 * @subcategory Dynamic Pages
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import log from "log";
import { responseToPage } from "model/dynamic-page";
import { pageTypes } from "model/dynamic-page/constants";
import { PAGE_MENU_KEY } from "model/site/menu/constants";
import { defaultMenu, responseToMenu } from "model/site/menu";

/**
 * Get a list of all pages.
 *
 * @function list
 * @return {DynamicPage[]}
 */
export const list = async () => {
  const response = (await get(endpoints.PAGE)).body;
  if (response) {
    return response.map(responseToPage);
  }
  return [];
};

/**
 * Count pages.
 *
 * Counts only main pages, not subpages.
 *
 * @function count
 * @return int
 */
export const count = async () => {
  try {
    const response = (await get(endpoints.PAGE)).body;
    if (response) {
      return response.filter((p) => p.type === pageTypes.INDEPENDENT).length;
    }
    return 0;
  } catch (e) {
    log.error(e, "unable to count pages");
    return -1;
  }
};

const PAGE_MENU_URL = (pageId) => `${endpoints.FE_CFG}/${PAGE_MENU_KEY(pageId)}`;

/**
 * Get admin-managed page menu.
 *
 * @function getPageMenu
 * @param {string} pageId
 * @return {module:model/site/menu~Menu}
 */
export const getPageMenu = async (pageId) => {
  try {
    return {
      ...responseToMenu(JSON.parse((await get(PAGE_MENU_URL(pageId)))?.body)),
      id: pageId,
    };
  } catch (e) {
    log.error(e);
    log.debug("failed to load page menu, using default");
    // menu may not be populated yet; that's OK
    return { ...defaultMenu, id: pageId };
  }
};
