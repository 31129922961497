/**
 * Messages attachments preview component
 *
 * @module ui/component/chat/message-attachment-preview
 * @category UI
 * @subcategory Chat
 */
import { merge } from "util/object";
import {
  applicationMsword,
  applicationPdf,
  imageMimeTypes,
  msExcel,
  presentation,
  spreadsheet,
  textCsv,
  wordDocument,
} from "model/file-descriptor/constants";
import { div, img } from "ui/html";
import buttonIcon from "ui/component/icon-button";
import icon from "ui/component/icon";
import imageModal from "ui/component/modal/image-modal";

let modal = null;

const defaultState = {
  attachment: null,
  onRemoveAttachedFile: null,
};

const getFileTypeIcon = (type) => {
  switch (type) {
    case applicationPdf:
      return "file-pdf";
    case applicationMsword:
    case wordDocument:
      return "file-word";
    case presentation:
      return "presentation";
    case spreadsheet:
    case textCsv:
    case msExcel:
      return "file-spreadsheet";
    default:
      return "file";
  }
};

const getPreview = (state) => {
  const { attachment, onRemoveAttachedFile } = state;
  if (attachment?.fileMetadata?.fileAttachInProgress) {
    return div(
      ".attachment",
      {
        attrs: {
          title: attachment.name,
        },
        on: {
          click: (e) => e.stopPropagation(),
        },
      },
      [
        icon.light("spinner-third", ".attachment-icon.spin"),
        div(".attachment-name", attachment.name),
        onRemoveAttachedFile ? buttonIcon("trash", "Remove", onRemoveAttachedFile, ".danger") : "",
      ],
    );
  }
  if (attachment?.fileMetadata?.failed) {
    return div(
      ".attachment.failed",
      {
        attrs: {
          title: attachment.name,
        },
        on: {
          click: (e) => e.stopPropagation(),
        },
      },
      [
        icon.sharp("xmark", ".attachment-icon"),
        div(".attachment-name", attachment.name),
        onRemoveAttachedFile ? buttonIcon("trash", "Remove", onRemoveAttachedFile, ".danger") : "",
      ],
    );
  }
  if (imageMimeTypes.includes(attachment.type)) {
    return div(
      ".attachment",
      {
        on: {
          click: async (e) => {
            e.stopPropagation();
            modal.async(imageModal(attachment.fileUrl(), modal));
          },
        },
      },
      [
        img(".image", attachment.fileUrlStatic),
        onRemoveAttachedFile ? buttonIcon("trash", "Remove", onRemoveAttachedFile, ".danger") : "",
      ],
    );
  }
  return div(
    ".attachment",
    {
      on: {
        click: (e) => {
          e.stopPropagation();
          window.open(attachment.fileUrl(), "_blank");
        },
      },
      attrs: {
        title: attachment.name,
      },
    },
    [
      icon.light(getFileTypeIcon(attachment?.fileMetadata?.type || attachment?.type), ".attachment-icon"),
      div(".attachment-name", attachment.name),
      onRemoveAttachedFile ? buttonIcon("trash", "Remove", onRemoveAttachedFile, ".danger") : "",
    ],
  );
};

export default function messageAttachmentPreview(inState, modalView) {
  modal = modalView;
  const state = merge(defaultState, inState);
  return getPreview(state);
}
