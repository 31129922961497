import { uuidv4 } from "./generator";
import { iso8601 } from "./date";

/* eslint-disable-next-line import/prefer-default-export */
export const measureVisitTime = (() => {
  const sessionKey = uuidv4();
  let startDate, dispatched;

  const resetTime = (startCb) => {
    startDate = Date.now();
    dispatched = false;
    // startCb does something as soon as measurement is set up, so we need to do it now
    const startTime = iso8601(new Date(startDate));
    startCb({ startTime, sessionKey });
  };

  const dispatchMeasurement = (endCb) => {
    const startTime = iso8601(new Date(startDate));
    const endTime = iso8601(new Date());
    const duration = Date.now() - startDate;
    endCb({ sessionKey, startTime, endTime, duration });
    dispatched = true;
  };

  return (startCb, endCb) => {
    // assume the first time this was called is after the page has already transitioned
    // to 'visible' state, during page setup. We need to initialize timers.
    resetTime(startCb);
    document.addEventListener("visibilitychange", () => {
      // if we haven't dispatched since we last started a measurement, do it now
      if (dispatched === false && (document.visibilityState === "hidden" || !document.hasFocus())) {
        dispatchMeasurement(endCb);
      }
      // when the page has become visible, start a new measurement
      if (document.visibilityState === "visible") {
        // if we missed the last hidden event somehow, dispatch now before restarting
        if (dispatched === false) {
          dispatchMeasurement(endCb);
        }
        resetTime(startCb);
      }
    });
    // try to call before freeze (UNRELIABLE)
    document.addEventListener("freeze", () => {
      dispatchMeasurement(endCb);
    });
    // try to call before unload (UNRELIABLE)
    window.addEventListener("beforeunload", () => {
      dispatchMeasurement(endCb);
    });
  };
})(); // gather all this up in a closure
