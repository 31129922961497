/**
 * The admin page for creating or editing group.
 *
 * Corresponds to `markup/admin/group-profile.html`
 *
 * @module ui/page/admin/group-profile
 * @category Pages
 * @subcategory Admin - Groups
 */
import api from "api";
import log from "log";
import {
  systemPrivilegeGroups,
  systemPrivilegeGroupsSet,
  systemPrivilegeGroupsFriendly,
  systemPrivilegeNames,
} from "model/acl/constants";
import { featureTypes } from "model/config/constants";
import { notificationMessageTypes } from "model/notification/constants";
import widget from "ui/component/dashboard-widget";
import actionBar from "ui/component/dashboard-action-bar";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import input from "ui/component/form-managed/field-input";
import cameoGroupMember from "ui/component/cameo-group-member";
import listSelectModal from "ui/component/modal/list-select";
import {
  div,
  h2,
  section,
} from "ui/html";
import { getQueryParams } from "util/navigation";
import formManagedView from "ui/view/form-managed";
import view from "ui/view";
import {
  groupNameSet,
  findGroupMemberChanges,
  prepareGroupMembershipPatchRequests,
} from "util/acl";
import { tidyBackendError } from "util/error";
import { guard } from "util/feature-flag";
import { userNamesString } from "util/format";
import { clone, frozen } from "util/object";
import dashboardLayout from "ui/page/layout/dashboard";

let actionsView, header, modal, loading, groupProfileView, notification;

const { SUCCESS, FAIL, BASIC } = notificationMessageTypes;

const staticMessages = frozen({
  success: {
    title: "Saved!",
    type: SUCCESS,
  },
  invalid: {
    title: "Failed to Save",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 3,
  },
  backendErrors: {
    title: "API Error",
    text: "A server error ocurred. Please report this to the operators.",
    type: FAIL,
    duration: 3,
  },
  saveCanceled: {
    title: "Save Canceled",
    text: "Your changes were not saved. Please make desired adjustments and try again.",
    type: FAIL,
    duration: 3,
  },
  noChange: {
    title: "No Changes",
    text: "No changes were made so no save is necessary.",
    type: BASIC,
    duration: 3,
  },
});

const findTaken = (groups, group) => (
  [...groupNameSet(groups)].filter((name) => name !== group?.name)
);

/**
 * Sanity checks for adding and removing members from groups.
 *
 * If any check fails the form is updated with an appropriate message, some invalid
 * changes may be reverted, and the function returns false.
 *
 * @function validateMembershipChanges
 * @param {ACLPrincipalEntry} state.group
 * @param {ACLPrincipalEntry[]} state.selectedUsers
 * @param {User[]} state.allUsers
 * @return {boolean} true if all changes are valid
 */
const validateMembershipChanges = async (state) => {
  const { group, originalGroup, selectedUsers, allUsers } = state;
  let changes;
  // new groups should skip the change check
  if (group.id && selectedUsers.length) {
    changes = findGroupMemberChanges(group, selectedUsers);
  } else if (group?.members?.length && !selectedUsers.length) {
    changes = { inserted: [], removed: group.members };
  } else changes = { inserted: selectedUsers, removed: [] };
  if (group?.system) {
    const removedAdmin = changes.removed.find((user) => user.name === "admin");
    if (removedAdmin) {
      // admin may not be removed from privilege groups, no matter what
      // (this may be enforced on frontend only, but we don't want to permit this
      //  since it's probably a mistake)
      await modal.alert(
        "The admin user may not be removed from system privilege groups.",
      );
      groupProfileView.update({
        selectedUsers: [removedAdmin, ...selectedUsers],
      });
      notification.post(staticMessages.saveCanceled);
      return false;
    }

    if (originalGroup.name !== systemPrivilegeGroups.ADMIN) {
      const adminsWithPrivileges = changes.removed.filter(
        (u) => allUsers.get(u.id).groups
          .some((g) => systemPrivilegeGroupsSet.has(g.name)),
      );

      if (adminsWithPrivileges.length) {
        await modal.alert(
          `You have attempted to remove a user with administrative privileges from
          the ${systemPrivilegeGroupsFriendly.get(originalGroup.name)} group. This will not
          result in a change in the user's access rights, so this change has been
          reverted.`,
        );
        groupProfileView.update({
          selectedUsers: [...adminsWithPrivileges, ...selectedUsers],
        });
        notification.post(staticMessages.saveCanceled);
        return false;
      }
    }

    // batch changes to system privileges require a confirmation
    if (changes.inserted.length) {
      const plur = changes.inserted.length === 1 ? "user" : "users";
      if (!(await modal.confirm(
        `Are you sure you want to grant ${systemPrivilegeNames.get(originalGroup.name)}
        powers to ${changes.inserted.length} ${plur}?`,
      ))) {
        notification.post(staticMessages.saveCanceled);
        return false;
      }
    }
  }

  // they probably don't want to leave a group empty, since this is equivalent to
  // deleting it in most cases
  if (
    ((changes.removed.length - changes.inserted.length) >= (group.members?.length || 0))
    && !(await modal.confirm(
      `Are you sure you want to leave this group empty?`,
    ))) {
    notification.post(staticMessages.saveCanceled);
    return false;
  }
  return true;
};

const isGroupChanged = () => {
  const { state: { group, originalGroup, selectedUsers, editing } } = groupProfileView;
  if (!editing) {
    return true;
  }
  const { valid } = groupProfileView.validate();
  const nameChanged = groupProfileView.values.name !== originalGroup.name
    && !group?.system;
  const usersChanged = prepareGroupMembershipPatchRequests(group, selectedUsers)
    .length > 0;
  return !!((nameChanged || usersChanged) && valid);
};

const doSave = async () => {
  groupProfileView.setFullValidation(true);
  const validation = groupProfileView.validate();
  let { group, groups } = groupProfileView.state;
  const { selectedUsers, originalGroup } = groupProfileView.state;
  const { values } = groupProfileView;
  const messages = [];
  const nameChanged = groupProfileView.values.name !== originalGroup.name
    && !group?.system;

  if (!validation.valid || messages.length > 0) {
    messages.push(staticMessages.invalid);
    groupProfileView.update({});
    messages.forEach(notification.post);
    return;
  }
  const validChanges = await validateMembershipChanges(groupProfileView.state);

  if (!validChanges) return;

  const isNewGroup = !group.id;

  try {
    loading.show();
    // if a new group, save it
    if (isNewGroup) {
      group = {
        ...(await api.acl.createGroup(groupProfileView.values.name)),
        members: [], // this will be null initially
      };
      groups = await api.acl.listGroups();
      groupProfileView.updateState({
        group,
        groups,
        originalGroup: clone(group),
        taken: findTaken(groups, group),
      });
    }

    // now save membership changes if the props changes passed
    const calls = prepareGroupMembershipPatchRequests(group, selectedUsers);
    if (!isNewGroup && calls.length === 0 && !nameChanged) {
      notification.post(staticMessages.noChange);
      loading.hide();
      return;
    }

    await Promise.all(calls.map((call) => call()));
    if (nameChanged && !isNewGroup) {
      group = await api.acl.changeGroupName(group.id, values.name);
      groups = await api.acl.listGroups();
      groupProfileView.updateState({
        group,
        groups,
        originalGroup: clone(group),
        taken: findTaken(groups, group),
      });
    }

    if (isNewGroup) {
      await modal.alert(`${group.name} created. Opening profile...`);
      window.location.assign(`/admin/group-profile?id=${group.id}`);
    } else {
      groupProfileView.update({
        group: {
          ...group,
          members: selectedUsers,
        },
      });
      notification.post(staticMessages.success);
      loading.hide();
    }
  } catch (err) {
    log.error(err);
    if (err.statusCode) {
      notification.post(staticMessages.backendErrors);
      tidyBackendError(err.body).forEach((text) => notification.post({
        title: "API Error Message",
        text,
        type: FAIL,
      }));
      loading.hide();
    }
  }
};

const doDelete = async () => {
  const { group } = groupProfileView.state;
  if (group.system) {
    modal.alert("You cannot delete system groups.", null, null, true);
    return;
  }
  if (!(await modal.confirm(
    `Are you sure you want to delete ${group.name}?`,
    "This action is irreversible.",
    undefined,
    undefined,
    true,
  ))) {
    return;
  }
  await api.acl.deleteGroup(group.id);
  await modal.alert("Group deleted. Returning group management page.");
  window.location.assign("/admin/manage-groups");
};

const addUsers = async (page) => {
  const usersListModalContent = [...page.state.allUsers.values()].map((user) => ({
    label: userNamesString(user),
    id: user.id,
    user,
  }));
  const selectedItems = await modal.async(listSelectModal({
    entries: usersListModalContent,
    multiSelect: true,
    selectedEntries: page.state.selectedUsers.map((user) => ({
      label: userNamesString(user),
      id: user.id,
      user,
    })),
  }, modal));
  if (selectedItems) {
    page.update({ selectedUsers: selectedItems.map((item) => item.user) });
  }
};

const removeUser = (self, user) => {
  const { state: { selectedUsers } } = self;
  self.update({ selectedUsers: selectedUsers.filter((item) => item.id !== user.id) });
};

const buildUserRow = (self, user) => cameoGroupMember({
  user,
  onRemove: () => removeUser(self, user),
}, modal);

const buildNameValue = (group) => (group?.system
  ? systemPrivilegeGroupsFriendly.get(group.name)
  : group?.name || "");

const buildUserList = (self) => div(".users", [
  ...self.state.selectedUsers.map(
    (user) => buildUserRow(self, self.state.allUsers.get(user.id)),
  ),
  button.standIn({
    icon: "plus-circle",
    label: "Add User",
    onClick: () => addUsers(self),
    sel: "#add-section",
  }),
]);

const helpTexts = {
  taken: "This group name is already in use.",
};

const showGroupProfileForm = (self) => {
  const { state: { group, taken, editing } } = self;
  const disabled = editing ? group?.system : false;
  const required = !disabled;
  return form(
    "#group-form",
    self.bind([
      [input, {
        name: "name",
        label: "Group Name",
        disabled,
        required,
        value: buildNameValue(group),
        taken,
        helpTexts,
      }],
      h2("Users", ".title-users"),
      buildUserList(self),
    ]),
  );
};

const showActionButtons = () => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: doSave,
    disabled: !isGroupChanged(),
  }),
  (
    groupProfileView?.state?.group?.id
    && !groupProfileView?.state?.group?.system
  ) ? button.warning({ icon: "trash", label: "Delete", onClick: doDelete }) : "",
]);

const doUpdate = (self) => {
  self.updateState({ group: self.values });
  if (actionsView) actionsView.update(self.state);
  self.render();
};

/**
 * The group profile page.
 *
 * @function groupProfile
 * @param {Selector} selector
 */
export default async function groupProfile(selector) {
  guard(featureTypes.USER_MANAGEMENT);
  const { id } = getQueryParams();
  const browserTitle = id ? "Group Profile" : "Add Group";
  ({ header, loading, modal, notification } = dashboardLayout(
    selector,
    [
      actionBar(section("#actions")),
      widget([
        form("#group-form"),
      ], id ? "Profile" : "New Group Profile"),
    ],
    browserTitle,
    true,
  ));
  loading.show();

  const [user, groups, allUsers, group] = await Promise.all([
    api.user.getMe(),
    api.acl.listGroups(),
    api.user.list()
      .then((users) => new Map(users.map((u) => ([u.id, u])))),
    id ? api.acl.getGroup(id) : Promise.resolve({}),
  ]);

  const state = {
    user,
    validation: {
      valid: true,
      fields: {},
    },
    group,
    originalGroup: clone(group),
    editing: !!id,
    groups,
    selectedUsers: group?.members || [],
    allUsers,
    taken: findTaken(groups, group),
  };

  const title = group?.name
    ? systemPrivilegeGroupsFriendly.get(group?.name) || group?.name
    : "Add Group";

  header.update({ ...state, title });
  groupProfileView = formManagedView(showGroupProfileForm, doUpdate)("#group-form", state);

  actionsView = view.create(showActionButtons)("#actions", state);

  loading.hide();
}
