/**
 * A cameo for a group that a user is a member of.
 *
 * @module ui/component/cameo-principal
 * @category UI
 * @subcategory Components
 */
import {
  systemPrivilegeGroups,
  systemPrivilegeGroupsSet,
} from "model/acl/constants";
import button from "ui/component/form-managed/button";
import icon from "ui/component/icon";
import groupIcon from "ui/component/group-icon";
import { principalDisplayName } from "util/format";
import cameo from "ui/component/cameo";

/**
 * Should remove the given user from the given group.
 *
 * @callback RemoveCallback
 * @param {Group} group
 * @param {User} user
 */

/**
 * Should redirect to the management page for the group.
 *
 * @callback ManageCallback
 * @param {Group} group
 * @param {User} user
 */

/**
 * @function isAdmin
 * @private
 */
const isAdmin = (user) => user.groups
  .some((g) => g.name === systemPrivilegeGroups.ADMIN);

/**
 * @function isDisabled
 * @private
 */
const isDisabled = (user, group) => (
  isAdmin(user)
  && group.name !== systemPrivilegeGroups.ADMIN
  && systemPrivilegeGroupsSet.has(group.name)
);

/**
 * @function onRemoveUserFromGroup
 * @private
 * @param {User} user
 * @param {UserGroup} group
 * @param {RemoveCallback} onRemove
 */
const onRemoveUserFromGroup = async ({ user, group, onRemove }) => {
  onRemove?.(group, user);
};

/**
 * @function onManagementClick
 * @private
 * @param {User} user
 * @param {UserGroup} group
 * @param {ManageCallback} onManage
 */
const onManagementClick = ({ user, group, onManage }) => (e) => {
  e.stopPropagation();
  onManage?.(group, user);
};

/**
 * A cameo for a group that a user is a member of, with optional
 * controls for viewing the group profile page for the group and
 * removing the user from the group.
 *
 * @function cameoMemberGroup
 * @param {object} state
 * @param {User} state.user
 * @param {Group} state.group
 * @param {RemoveCallback} state.onRemove
 * @param {ManageCallback} state.onManage
 */
export default function cameoMemberGroup(state) {
  const {
    user,
    group,
    onManage,
    onRemove,
  } = state;

  const controls = [
    onManage
      ? button.accent({
        iconOnly: true,
        icon: "external-link",
        onClick: onManagementClick(state),
        label: "manage",
      })
      : "",
    onRemove
      ? button.warning({
        iconOnly: true,
        icon: "trash-alt",
        onClick: () => onRemoveUserFromGroup(state),
        disabled: isDisabled(user, group),
        label: "delete",
      })
      : "",
  ];

  return cameo({
    icon: group.system ? groupIcon(group.name) : icon.solid("users"),
    label: principalDisplayName(group),
    controls,
    sel: `.member-group`,
  });
}
