/**
 * ACL group stream.
 *
 * @module data/acl/group
 * @category Data Streams
 * @subcategory ACL
 */
import { count as countGroups } from "api/v2/acl/group";
import countPipe from "data/pipeline/count";
import { streamLog } from "data/stream/compose";
import hashmap from "util/hash-map";

const countMap = hashmap();

const {
  post: count,
  sink$: countSink$,
} = countPipe({
  apiFn: countGroups,
  defaultFn: () => 0,
  modelFn: (r) => r,
  accumulator: countMap,
});

export default {
  count,
  count$: countSink$.compose(streamLog("ACL_GROUP_COUNT$")),
};
