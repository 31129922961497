/**
 * The user-facing login FAQ page.
 *
 * @module ui/page/faq/login
 * @category Pages
 * @subcategory User Facing
 */
import {
  div, h1, p, h3, a,
} from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "login-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showLoginFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Login"]),
  collapsible({
    title: "Does SmartEdge allow for multiple accounts for the same phone number or email address?",
    autoCloseConfiguration,
  }, [
    p([
      `If you are new to SmartEdge, please register on the `,
      a("registration page.", "/register"),
      ` Once you have completed the registration process, feel free to login using your new SmartEdge credentials.`,
    ]),
    p(`If you are already registered, feel free to login at any time. In case you have an
     issue with logging in, please email your organization’s SmartEdge admin.`),
    p(`If there are external credentials given by your school admin, please use the username
    and password you created for the external credentials. In case you have an issue with logging in,
    please email your organization’s SmartEdge admin.`),
  ]),
  collapsible({
    title: "After registration, do I need to log in with the new credentials to access SmartEdge?",
    autoCloseConfiguration,
  }, p(`Yes! You can click the Remember Me box on the login page to remain logged into your
    account for 24 hours. After 24 hours have passed, you will need to re-enter your
    username and password to log back in.`)),
  collapsible({
    title: "What happens if I forgot I already have an account and need to go back to the Login page?",
    autoCloseConfiguration,
  }, p(`No problem! Head back to the login page and use the info associated with your account to log into SmartEdge.`)),
  collapsible({
    title: "How can I go back to the login page from the main SmartEdge site?",
    autoCloseConfiguration,
  }, p(`If you want to go back to the main login page, simply log out of your account.
    This function is accessible through the Side Bar Menu of the SmartEdge interface.`)),
  collapsible({
    title: "Are there a certain number of times I can attempt logging in with the incorrect password?",
    autoCloseConfiguration,
  }, p(`Short answer- yes. You can only log in a maximum of 5 times before you are locked out of your account.
    Worst case scenario- please change your password by going through the Forgot Password process before this happens.`)),
]);

export default async function loginFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Login"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showLoginFaq)("#end-user-faq", state);
  loading.hide();
}
