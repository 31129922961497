/**
 * A simple queue for processing API requests.
 *
 * @module api/queue
 * @category Backend API
 * @subcategory Core
 */
import { makeRequestQueueEntry } from "model/api";
import { APIResponseError } from "model/error";
import doAPICall from "./core";

/**
 * Simple array to handle sequential requests.
 *
 * These queue entries are produced in {@link enqueue}.
 *
 * @type {module:api/types.RequestQueueEntry[]}
 */
const requestQueue = [];

/**
 * Whether we're in the midst of processing a queue entry.
 * @private
 */
let processing = false;

/**
 * Process the request queue until empty.
 *
 * @modifies requestQueue by removing and sometimes reinserting items
 * @function processEntry
 */
const processEntry = async (next) => {
  /** @type {module:api/types.RequestQueueEntry} */
  try {
    if (next.sequential) {
      // we await here if it's sequential,
      // blocking any subsequent calls until
      // the request resolves
      const response = await doAPICall(next.request);
      if (response.ok) next.resolve(response);
      else next.reject(new APIResponseError(response));
    } else {
      doAPICall(next.request)
        .then((response) => {
          if (response.ok) next.resolve(response);
          else next.reject(new APIResponseError(response));
        });
    }
  } catch (e) {
    next.reject(e);
  }
};

/**
 * Process one item in the request queue.
 * @function process
 */
export const process = async () => {
  // we only want to be running one of these loops at a time
  if (processing) return;
  processing = true;
  const seq = requestQueue.find((entry) => entry.sequential);
  if (seq) {
    // the queue could grow between calls, and we do actually want to await
    // here to avoid making simultaneous calls when they're sequential
    /* eslint-disable-next-line no-await-in-loop */
    await processEntry(seq);
    requestQueue.splice(requestQueue.indexOf(seq), 1);
    // resume at the end of the js event loop so effects
    // of the request can process
    setTimeout(() => {
      process();
    }, 1);
    processing = false;
    return;
  }
  processing = true;
  while (requestQueue.length) {
    processEntry(requestQueue.pop());
  }
  processing = false;
};

/**
 * This enqueues a request, and then doesn't resolve until the request
 * has been processed by the request queue.
 *
 * @example
 * const req = {
 *   //...
 * };
 *
 * await enqueue(req);
 *
 * @function enqueue
 * @async
 * @param {module:api/types.APIRequest} request
 * @param {boolean} sequential whether this call is sequential
 * @return Promise resolving when the API call has completed
 */
export const enqueue = (request, sequential = false) => new Promise((resolve, reject) => {
  requestQueue.push(makeRequestQueueEntry(
    request,
    (result) => resolve(result),
    (e) => reject(e),
    sequential,
  ));
  if (!processing) process();
});
