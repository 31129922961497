/**
 * A cameo for solution.
 *
 * @module ui/component/cameo-solution
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/html/button";
import evaluationManualGrading from "ui/component/modal/evaluation/evaluation-manual-grading";
import { getModal } from "ui/view/modal-view";

const openManualGradingModal = async (
  onGraded,
  solution,
  files,
) => {
  const updatedSolution = await getModal().async(evaluationManualGrading({
    solution,
    files,
  }));
  if (updatedSolution) {
    onGraded?.(updatedSolution);
  }
};

/**
 * Cameo element for solution
 *
 * @param {Solution} solution
 * @param {Function} onGraded
 * @param {Array} controls
 * @param modalView
 * @param notificationView
 * @returns {*|El}
 */
export default function cameoSolution({
  solution,
  onGraded = null,
  controls = [],
  files = null,
}) {
  return cameo({
    label: solution.question.text,
    icon: icon.solid("check-square", `${solution?.score?.points !== undefined ? ".green" : ".red"}`),
    controls: [
      onGraded ? button(icon.solid("edit"), () => openManualGradingModal(
        onGraded,
        solution,
        files,
      )) : "",
      ...controls,
    ],
    sel: ".cameo-solution",
  });
}
