/**
 * A FIGURE element.
 *
 * @module ui/html/figure
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 *
 * @function figure
 * @param {string} sel css selectors
 * @param {mixed} ...args addtional arguments as per {@link ui/common/el}
 */
export default (sel = '', ...args) => el(`figure${sel}`, ...args);
