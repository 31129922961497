/**
 * A help modal dialog for add new page page.
 *
 * @module ui/component/modal/help/add-new-page
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminAddNewPageHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_ADD_NEW_PAGE,
    skipCache,
    async,
    sel: ".help-modal-add-new-page",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-page.svg"),
            p("Add Page", ".title"),
            div(".text-container", [
              p("To create a new page, add a title and URL extension."),
              p("You can press the green circle arrow icon for the system to automatically"),
              p("generate a unique URL extension or you can add your own text!"),
              p("Please make sure there are no spaces in the intended extension.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-page.svg"),
            p("Add Page", ".title"),
            div(".text-container", [
              p("Click Add Section to choose the page extensions you would like to add to the page.", ".spaced-bottom"),
              p("You can choose rich text, video playlists, courses, and much more!", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-page.svg"),
            p("Add Page", ".title"),
            div(".text-container", [
              p("Once you are done, click Add, and your page will be created!"),
              p("Don’t forget to add the newly created page to the Sidebar Menu for"),
              p("easy user access."),
              p([
                "If you have any more questions, please visit our ",
                a("Admin FAQ", "/admin/admin-faq"),
                " for more help",
              ], ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
