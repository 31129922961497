import collapsible from "ui/component/collapsible";
import textarea from "ui/component/form-managed/field-textarea";
import description from "ui/component/form-managed/field-description";

export default function textareaFields(self) {
  const required = true;

  return collapsible({
    title: "Textareas",
    open: self.state.textareasOpen,
    onChange: (textareasOpen) => self.updateState({ textareasOpen }),
  }, self.bind([
    [textarea],
    [textarea, { label: "Required Text", name: "required-text", required }],
    [description, { label: "Required Description", required }],
  ]));
}
