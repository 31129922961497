/**
 * A test page for demoing components and testing them out of their usual context.
 * Uses stubs and mocks in place of usual API calls.
 *
 * @module ui/page/test
 * @category Pages
 * @subcategory Development
 */
import api from "api";
import log from "log";
import { fakeLabels } from "api/mocks/metadata";
import div from "ui/html/div";
import managedForm from "ui/component/form-managed";
import widget from "ui/component/dashboard-widget";
import view from "ui/view";
import richTextEditView from "ui/view/rich-text-edit-view";
import managedFormView from "ui/view/form-managed";
import { merge } from "util/object";
import dashboardLayout from "ui/page/layout/dashboard";
import { chartTypes, defaultChartSettings } from "model/chart/constants";
import { monthDayYear } from "util/date";
import chart from "ui/component/chart";
import chartWithToggles from "ui/component/chart/chart-with-toggles";
import chartWithCalendar from "ui/component/chart/chart-with-calendar";
import testCameos from "./cameos";
import testFormManaged from "./form-components";
import testModals from "./modal";
import testComponents from "./components";
import testTypography from "./typography";
import testNotifications from "./notifications";
import {
  analyticsFakeResponse,
  analyticsFakeResponseWeekly,
  fakePages,
} from "./mocks";
import { aclAnonymousState, aclDefaultState, defaultState } from "./state";
import testACL from "./acl";

let modalView;

const buildToggleChart = () => {
  const CHART_WEEKLY = "CHART_WEEKLY";
  const CHART_MONTHLY = "CHART_MONTHLY";
  const datasetBarConfiguration = {
    backgroundColor: defaultChartSettings.backgroundColor,
    barPercentage: 0.5,
    barThickness: 6,
    maxBarThickness: 8,
    minBarLength: 2,
  };

  function updateChartWithData(self, data, index) {
    self.update({
      data: {
        labels: data.map((item) => monthDayYear(new Date(item.startDate))),
        datasets: [
          {
            label: "Views",
            data: data.map((item) => item.count),
            ...datasetBarConfiguration,
          },
        ],
      },
      selectedTab: index,
    });
  }

  const toggleChart = view.chart(chartWithToggles)("#bar-chart-with-toggles", {
    type: chartTypes.BAR,
    data: {
      labels: analyticsFakeResponse.map((item) => monthDayYear(new Date(item.startDate))),
      datasets: [
        {
          label: "Views",
          data: analyticsFakeResponse.map((item) => item.count),
          ...datasetBarConfiguration,
        },
      ],
    },
    toggleOptions: [
      {
        label: "Monthly",
        name: "chartDateType",
        id: CHART_MONTHLY,
        value: CHART_MONTHLY,
      },
      {
        label: "Weekly",
        name: "chartDateType",
        id: CHART_WEEKLY,
        value: CHART_WEEKLY,
      },
    ],
    onToggleSelect: (type, index) => {
      if (type.id === CHART_WEEKLY) {
        updateChartWithData(toggleChart, analyticsFakeResponseWeekly, index);
      } else if (type.id === CHART_MONTHLY) {
        updateChartWithData(toggleChart, analyticsFakeResponse, index);
      }
    },
  });

  return toggleChart;
};

const buildCalendarChart = () => {
  const datasetBarConfiguration = {
    backgroundColor: defaultChartSettings.backgroundColor,
    borderColor: defaultChartSettings.backgroundColor,
    barPercentage: 0.5,
    barThickness: 6,
    maxBarThickness: 8,
    minBarLength: 2,
  };
  const calendarChart = view.chart(chartWithCalendar(modalView))("#chart-with-calendar", {
    type: chartTypes.LINE,
    data: {
      labels: analyticsFakeResponse.map((item) => monthDayYear(new Date(item.startDate))),
      datasets: [
        {
          label: "Views",
          data: analyticsFakeResponse.map((item) => item.count),
          ...datasetBarConfiguration,
        },
      ],
    },
    onDateSelected: ({ startDate, endDate }) => {
      calendarChart.update({ startDate, endDate });
    },
    onPointClick: (point) => {
      log.info(point);
    },
    withScrubBar: true,
    onScrubBarValueChange: (value) => log.info(value),
  });
};

export default async function testPage(selector) {
  const title = "Test Page";
  const {
    modal, loading, header, notification,
  } = dashboardLayout(selector, [
    widget([managedForm("#form-managed")], "Form Elements", ".form-elements"),
    widget([div("#components"), div("#cameos"), div("#notification-samples")], "Components", ".ui-components"),
    widget(div("#modals"), "Modal Dialogs", ".modals"),
    // widget(div("#cameos"), "Cameos", ".cameos"),
    // widget(div("#notification-samples"), "Announcements", ".notifications"),
    widget(div("#acl"), "ACL", ".acl"),
    widget(div("#acl.anonymous"), "ACL OSA", ".acl"),
    widget(div("#typography.pane"), "Typography", ".typography"),
    widget(div("#rte"), "Rich Text Editor", ".rich-text-editor"),
    widget(div("#bar-chart"), "Bar Chart", ".bar-chart"),
    widget(div("#line-chart"), "Line Chart", ".line-chart"),
    widget(div("#bar-chart-with-toggles"), "Chart With Toggles", ".bar-chart-with-toggles"),
    widget(div("#chart-with-calendar"), "Chart With Calendar", ".chart-with-calendar"),
  ], title);
  loading.show();
  modalView = modal;

  const user = await api.user.getMe();

  const state = merge(defaultState, {
    user,
    title,
    labels: fakeLabels.slice(0, 4),
  });

  managedFormView(testFormManaged(modalView))("#form-managed", state);

  view.chart(chart)("#bar-chart", {
    type: chartTypes.BAR,
    data: {
      labels: analyticsFakeResponse.map((item) => monthDayYear(new Date(item.startDate))),
      datasets: [
        {
          label: "Views",
          data: analyticsFakeResponse.map((item) => item.count),
          backgroundColor: defaultChartSettings.backgroundColor,
          barPercentage: 0.5,
          barThickness: 6,
          maxBarThickness: 8,
          minBarLength: 2,
        },
      ],
    },
  });
  view.chart(chart)("#line-chart", {
    type: chartTypes.LINE,
    data: {
      labels: analyticsFakeResponse.map((item) => monthDayYear(new Date(item.startDate))),
      datasets: [
        {
          label: "Views",
          data: analyticsFakeResponse.map((item) => item.count),
          backgroundColor: defaultChartSettings.backgroundColor,
        },
      ],
    },
  });

  buildToggleChart();
  buildCalendarChart();
  view.create(testComponents)("#components");
  view.create(testCameos(modalView))("#cameos");
  view.create(testModals(modalView, notification))("#modals");
  view.create(testNotifications(notification))("#notification-samples");
  view.create(() => testACL(aclDefaultState))("#acl");
  view.create(() => testACL(aclAnonymousState))("#acl.anonymous");

  const typography = view.create(testTypography)("#typography");
  typography.patch(testTypography());

  richTextEditView("#rte", modalView, { pages: fakePages });
  header.update(state);
  loading.hide();
}
