/**
 * A modal for multiple choice question editing.
 *
 * @module ui/component/modal/assessment/question-multiple-choice
 * @category UI
 * @subcategory Modal Dialogs
 */
import { merge } from "util/object";
import { div, h3, h4, span } from "ui/html";
import form from "ui/component/form-managed";
import textarea from "ui/component/form-managed/field-textarea";
import button from "ui/component/form-managed/button";
import formManagedView from "ui/view/form-managed";
import { notificationMessageTypes } from "model/notification/constants";
import number from "ui/component/form-managed/field-number";
import cameoAnswer from "ui/component/cameo-answer";
import { icon } from "ui/component";
import { questionType } from "model/course/constants";
import answerEditModal from "ui/component/modal/course/answer-edit";
import questionGradingHelpModal from "ui/component/modal/help/question-grading";
import dialog from "ui/component/modal/layout/dialog";
import { elId } from "util/dom";
import toggle from "ui/component/form-managed/field-toggle";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";

let count = 0;
let storedFormView;

const staticMessages = {
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: notificationMessageTypes.FAIL },
  pointsExceededLimit: { title: "Error", text: "Weight limit exceed. Reduce question weight.", type: notificationMessageTypes.FAIL },
};

const defaultState = {
  validation: {
    fields: {},
  },
  question: {
    text: "",
    questionType: questionType.MULTIPLE_CHOICE_SINGLE_ANSWER,
    answers: [],
    weight: undefined,
    randomizeAnswers: false,
  },
  multiAnswer: false,
  messages: [],
};

const updateDisabledButtonState = (state) => {
  const saveButton = elId("multiple-choice-save-button");
  if (saveButton) {
    const singleAnswerWithMultipleCorrectAnswers = !state.multiAnswer
      && state?.question.answers
        .map((answer) => answer.correct)
        .filter((correct) => correct).length > 1;
    const noCorrectAnswers = state?.question.answers
      .map((answer) => answer.correct)
      .filter((correct) => correct).length === 0;
    const theOnlyCorrectAnswer = state?.question.answers
      .map((answer) => answer.correct)
      .filter((correct) => correct).length === 1
    && state?.question.answers
      .map((answer) => answer.correct)
      .filter((correct) => !correct).length === 0;
    saveButton.disabled = singleAnswerWithMultipleCorrectAnswers
      || noCorrectAnswers
      || theOnlyCorrectAnswer;
  }
};

const bindForm = () => {
  const formEl = document.createElement("FORM");
  formEl.id = "multiple-choice";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#multiple-choice");
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  updateDisabledButtonState(inner.state);
  inner.patch(inner.factory(inner));
};

const buildMultipleChoiceForm = (self) => {
  const { state } = self;
  return form(
    "#multiple-choice",
    [
      ...self.bind([
        [textarea, { name: "text", label: "Question", required: true }],
        [toggle.boxed.inverse, { label: "Randomize Answers", name: "randomizeAnswers", sel: ".randomize" }],
        [
          toggle.boxed.inverse,
          {
            label: "Multi Answer",
            name: "multiAnswer",
            sel: ".multi",
            toggled: state.multiAnswer,
            onToggle: (toggled) => {
              self.update({
                questionType: toggled
                  ? questionType.MULTIPLE_CHOICE_MULTI_ANSWER
                  : questionType.MULTIPLE_CHOICE_SINGLE_ANSWER,
                multiAnswer: toggled,
              });
            },
          },
        ],
        div(".container-help", [
          h4("Grading"),
          icon("circle-question", ".show-help", {
            on: {
              click: async () => getModal().async(questionGradingHelpModal()),
            },
          }),
        ]),
        div(".used-points", `Points used: ${state.usedPoints} of ${state.maxPoints}`),
        [number, { required: true, label: "Weight", name: "weight" }],
      ], state.question),
      ...state.question.answers.map((answer, index) => cameoAnswer({
        answer,
        existingAnswers: state.question.answers,
        onEdit: (editedAnswer) => {
          self.update({
            question: {
              answers: state.question.answers.map(
                (item, idx) => (index === idx ? editedAnswer : item),
              ),
            },
          });
        },
        onRemove: () => {
          self.update({
            question: {
              answers: state.question.answers.filter(
                (item, idx) => index !== idx,
              ),
            },
          });
        },
      })),
      button.secondary({
        icon: "plus-circle",
        label: "Add Answer",
        onClick: async () => {
          const answer = await getModal().async(
            answerEditModal({ existingAnswers: state.question.answers }),
          );
          if (answer) {
            self.update({
              question: {
                answers: [...state.question.answers, answer],
              },
            });
          }
        },
      }),
    ],
  );
};

const initForm = (state) => {
  if (storedFormView) {
    bindForm();
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "multiple-choice";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView = formManagedView(
    buildMultipleChoiceForm,
    onFormUpdate,
  )(
    "#multiple-choice",
    state,
  );
};

const onSave = () => {
  storedFormView.setFullValidation(true);
  const validation = storedFormView.validate(true);
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return null;
  }
  const { state, values } = storedFormView;
  if (state.usedPoints - (state.question.weight || 0) + (+values.weight) > state.maxPoints) {
    getNotification().post(staticMessages.pointsExceededLimit);
    return null;
  }
  storedFormView.updateState({
    question: {
      text: values.text,
      weight: values.weight,
      randomizeAnswers: !!values.randomizeAnswers,
      questionType: values.multiAnswer
        ? questionType.MULTIPLE_CHOICE_MULTI_ANSWER
        : questionType.MULTIPLE_CHOICE_SINGLE_ANSWER,
    },
  });
  return getModal().resolve(storedFormView.state.question);
};

/**
 * Multiple choice modal
 *
 * @param inState
 * @param {Partial<Question>} inState.question
 * @param {number} inState.maxPoints
 * @param {number} inState.usedPoints
 * @returns {*|El}
 */
const questionMultipleChoiceModal = (inState = defaultState) => {
  storedFormView = null;
  const state = merge(defaultState, {
    ...inState,
    multiAnswer: inState.question?.questionType === questionType.MULTIPLE_CHOICE_MULTI_ANSWER,
  });
  return dialog({
    sel: ".form.assessment-question.question-multiple-choice",
    config: {
      hook: {
        insert: () => initForm(state),
        postPatch: () => initForm(state),
      },
      dataset: { advancedSearchId: (++count) },
    },
    header: h3("Multiple Choice"),
    body: span("placeholder"),
    footerSpacer: true,
    footer: button({
      label: "Save",
      onClick: () => onSave(),
      sel: "#multiple-choice-save-button",
    }),
  }, getModal());
};

export default questionMultipleChoiceModal;
