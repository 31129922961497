/**
 * A fake input. Appears like a disabled text input, but is actually just a div with
 * two spans emulating label and input.
 *
 * @module ui/component/fake-input
 * @category UI
 * @subcategory Components
 */
import { div, span } from "ui/html";

/**
 * A fake input + label pair.
 *
 * @param {string} label the label text
 * @param {string} value value to be displayed in the fake input element
 */
export default function fakeInput(label, value) {
  return div(
    ".fake-input",
    { attrs: { role: "group" } },
    [
      span(label, ".label"),
      span(value, ".value.disabled"),
    ],
  );
}
