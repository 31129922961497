/**
 * A dialog for doing advanced searches on a list of user groups.
 *
 * @module ui/component/modal/advanced-group-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { frozen } from "util/object";
import {
  button,
  div,
  form, h3,
  input,
  label,
} from "ui/html";
import closeFab from "ui/component/modal/component/close-fab";
import multiToggle from "ui/component/multi-toggle";

let count = 0;

const getFormCheckboxState = (selector, dialog) => {
  const element = dialog.querySelector(`${selector}:checked`);
  if (element && element.value === "true") {
    return true;
  }
  if (element && element.value === "false") {
    return false;
  }
  return null;
};

const doSearch = (id, handler, modalView) => {
  const dialog = document.querySelector(`[data-advanced-search-id="${id}"]`);
  const searchParams = {
    id: dialog.querySelector("[name=id]").value,
    name: dialog.querySelector("[name=name]").value,
    count: dialog.querySelector("[name=count]").value,
    sortOrder: dialog.querySelector("[name=sort-order]").value,
    sortField: dialog.querySelector("[name=sort-field]").value,
    system: getFormCheckboxState("[name=system]", dialog),
  };
  modalView.resolve(searchParams);
};

const defaultOnSearch = () => {};
const defaultParams = frozen({
  id: "",
  name: "",
  count: "",
});

const setFocus = () => {
  document.querySelector(`#advanced-form input[name="name"]`).focus();
};

/**
 * A modal dialog for doing advanced searches for groups.
 *
 * @function advancedGroupSearch
 * @param {object} state
 * @param {?function} [state.onSearch=defaultOnSearch]
 * @param {object} [state.params=defaultParams] search params object
 * @param {module:ui/view/modal~ModalView} modalView
 * @return {module:ui/html/div~Div}
 */
const advancedGroupSearch = (
  {
    onSearch = defaultOnSearch,
    params = defaultParams,
  }, modalView = null,
) => div(
  ".dialog.form.with-footer.advanced-search.advanced-content-search",
  {
    dataset: { advancedSearchId: (++count) },
    hook: {
      insert: setFocus,
      update: setFocus,
    },
  },
  [
    closeFab(true, modalView),
    div(".content", [
      div(".header", h3("Advanced Search")),
      div(".body", form("#advanced-form", [
        input("hidden", params.sortOrder, false, "sort-order"),
        input("hidden", params.sortField, false, "sort-field"),
        div("", { attrs: { role: "group" } }, [
          label("Group ID"),
          input.text(params.id, false, "id", { props: { placeholder: "Group ID" } }),
        ]),
        div("", { attrs: { role: "group" } }, [
          label("Group Name"),
          input.text(params.name, false, "name", { props: { placeholder: "Group Name" } }),
        ]),
        div("", { attrs: { role: "group" } }, [
          label("Count"),
          input.text(params.count, false, "count", { props: { placeholder: "Count" } }),
        ]),
        multiToggle(
          {
            label: 'System',
            options: [
              {
                label: "N/A",
                value: "na",
                name: "system",
                id: "system-na",
              },
              {
                label: "Yes",
                value: "true",
                name: "system",
                id: "system-yes",
              },
              {
                label: "No",
                value: "false",
                name: "system",
                id: "system-no",
              },
            ],
          },
        ),
      ])),
    ]),
    div(".footer", [
      div(".buttons", { attrs: { role: "group" } }, [
        button("Search", () => doSearch(count, onSearch, modalView)),
      ]),
    ]),
  ],
);

export default advancedGroupSearch;
