import log from "log";
import { userList } from "api/mocks/user";
import collapsible from "ui/component/collapsible";
import emailAddress from "ui/component/form-managed/field-email-address";
import familyName from "ui/component/form-managed/field-family-name";
import givenName from "ui/component/form-managed/field-given-name";
import input from "ui/component/form-managed/field-input";
import password from "ui/component/form-managed/field-password";
import confirmedPassword from "ui/component/form-managed/field-password-confirmed";
import phone from "ui/component/form-managed/field-phone-number";
import searchTerms from "ui/component/form-managed/field-search-terms";
import slug from "ui/component/form-managed/field-slug";
import title from "ui/component/form-managed/field-title";
import url from "ui/component/form-managed/field-url";
import urlRelative from "ui/component/form-managed/field-url-relative";
import userName from "ui/component/form-managed/field-username";
import select from "ui/component/form-managed/field-select";
import p from "ui/html/p";
import { pageList } from "ui/page/admin/test/mocks";
import { getModal } from "ui/view/modal-view";

const updateUser = (self) => {
  const user = {};
  const { values } = self;
  [...Object.keys(self.state.user)].forEach((key) => {
    if (values?.[key] !== undefined) {
      user[key] = values?.[key];
    }
  });
  self.updateState({ user });
};

export default function inputFields(self) {
  updateUser(self);
  const { username, email, cellPhone } = self.state.user;

  log.debug("form values", self.values);
  log.debug("form validity", self.validate(true));

  const takenUsers = userList.map((u) => u.username).filter((n) => n !== username);
  const takenEmails = userList.map((u) => u.email).filter((e) => e !== email);
  const takenPhones = userList.map((u) => u.cellPhone).filter((ph) => ph !== cellPhone);
  const takenTitles = pageList.map((page) => page.title);
  const takenSlugs = pageList.map((page) => page.slug);

  const required = true;
  const disabled = true;

  return collapsible({
    title: "Input Fields",
    open: self.state.inputsOpen,
    onChange: (inputsOpen) => self.updateState({ inputsOpen }),
  }, [
    ...self.bind([
      [input],
      [input, { label: "Required Text", name: "field-required", required }],
      [input, { label: "Disabled Text", name: "field-disabled", disabled }],
      [input, {
        label: "Input With Help",
        name: "field-with-help",
        onHelp: () => getModal().alert("Help button clicked!"),
      }],
      [userName, { taken: takenUsers }],
      [phone, { taken: takenPhones }],
      [emailAddress, { taken: takenEmails }],
      [givenName],
      [familyName],
      [title, { taken: takenTitles }],
      [slug, { taken: takenSlugs, title: self.values?.title }],
      [url],
      [urlRelative, { name: "relative-url", label: "Relative URL" }],
      [password, { required }],
      [searchTerms],
      p("New Password"),
      [confirmedPassword, { required }],
      p("Selectors"),
      [select, { options: [
        { label: "Option 1", value: 1 },
        { label: "Option 2", value: ["foo"] },
        { label: "Option 3", value: { bar: "baz" } },
        { label: "Option 4", value: "a string" },
      ] }],
      [select, {
        required,
        placeholder: "Select Timezone",
        options: Intl.supportedValuesOf("timeZone").map((value) => ({ value })),
        name: "select-timezone",
      }],
    ], { ...self.state.user, terms: ["a", "b", "c"] }),
  ]);
}
