/**
 * A modal for reported message action.
 *
 * @module ui/component/modal/chat/reported-message-action
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, span } from "ui/html";
import button from "ui/component/form-managed/button";
import { merge } from "util/object";
import { usFullDateFormat } from "util/date";
import { reportReasonsFriendly } from "ui/component/chat/common";
import getAvatar from "ui/component/messaging/avatar";
import getUsername from "ui/component/messaging/username";

const defaultState = {
  report: null,
  onSuspendUser: null,
  users: [],
};

const getUser = (id, users) => users.find((u) => u.id === id);

const getReportedUserId = (report) => (report.from === report.reportedMessage.from
  ? report.reportedMessage.to
  : report.reportedMessage.from);

const buildBody = (state) => {
  const { users, report } = state;
  const reportedUserId = getReportedUserId(report);
  return div(
    ".reported-message-body",
    [
      div(".users", [
        div(".user-left", [
          div("", "Reported User:"),
          div(".user-avatar", getAvatar(getUser(reportedUserId, users))),
          span(getUsername(getUser(reportedUserId, users)), ".username"),
        ]),
        div(".user-right", [
          div("", "Reporting User:"),
          div(".user-avatar", getAvatar(getUser(report.from, users))),
          span(getUsername(getUser(report.from, users)), ".username"),
        ]),
      ]),
      div(".container-status-and-date", [
        div(".date", usFullDateFormat(report.created)),
        div(".state", [
          span("Status: ", ".label-status"),
          span(report.status, ".value-status"),
        ]),
      ]),

      div(".report-reason", [
        span("Reason: ", ".label-status"),
        span(reportReasonsFriendly.get(report.reason), ".value-status"),
      ]),
      report.text
        ? div(".additional-information", [
          span("Additional Feedback: ", ".label-status"),
          div(".value-status", report.text),
        ])
        : "",
    ],
  );
};

const goToUserProfile = (state) => {
  window.location.assign(`/admin/user-profile?id=${getReportedUserId(state.report)}`);
};

export default function reportedMessageActionModal(inState, modalView) {
  const state = merge(defaultState, inState);

  return dialog({
    sel: ".reported-message-dialog",
    header: h3("Reported Message Summary"),
    body: buildBody(state),
    footer: [// div(".buttons", { attrs: { role: "group" } }, [
      button.warning({
        icon: "comment-times",
        label: "Suspend User",
        onClick: state.onSuspendUser,
        disabled: !state.onSuspendUser,
      }),
      button.primary({
        icon: "user-cog",
        label: "User Profile",
        onClick: () => goToUserProfile(state),
      }),
    ], // ),
  }, modalView);
}
