/**
 * A radio-type answer on the student assessment page.
 *
 * For use with multiple-choice, single-answer type questions.
 *
 * @module ui/page/assessment/answer-radio
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import div from "ui/html/div";
import radio from "ui/component/form-managed/field-radio";

const getSolutionValue = (solution) => solution?.[0] || null;

export default function answerRadio(state, view) {
  const { question, solution, validity, required } = state;
  return div(".answer.answer-radio-options", view.bind([[radio.boxed, {
    entries: question.answers.map((answer, i) => ({
      label: answer.value,
      value: answer.value,
      sel: `#${question.id}-${i}`,
    })),
    required,
    name: `${question.id}`,
    sel: `#${question.id}`,
    validity,
  }]], { [question.id]: getSolutionValue(solution) }));
}
