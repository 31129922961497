/**
 * Special cache handling for the metadata API.
 *
 * We avoid caching whole playlist objects and search results by storing any metadata
 * result in a shared cache, and then caching an abbreviated version of the search results
 * or playlist items separately. This isn't something the cache module can handle on its
 * own, so it's handled here.
 *
 * @module api/metadata/cache
 * @category Backend API
 * @subcategory Metadata
 */
import { CACHE_PAGE_KEY } from "api/page/constants";
import { removePageDTO } from "api/page/cache";
import cache from "cache";
import log from "log";
import {
  responseToPlaylistMetadata,
  responseToVideoMetadata,
  responseToDocumentMetadata,
  responseToCourseMetadata,
} from "model/metadata";
import { metadataTypes } from "model/metadata/constants";
import { responseToPage } from "model/dynamic-page";
import { merge, frozen } from "util/object";

export const CACHE_PLAYBACK_TIME = 30; // seconds

export const CACHE_COURSE_KEY = "courseMetadata";
export const CACHE_DOCUMENT_KEY = "documentMetadata";
export const CACHE_PLAYBACK_KEY = "video-playback-times";
export const CACHE_PLAYLIST_KEY = "playlistMetadata";

export const CACHE_SEARCH_COURSE_KEY = "courseMetadataSearch";
export const CACHE_SEARCH_DOCUMENT_KEY = "documentMetadataSearch";
export const CACHE_SEARCH_PLAYLIST_KEY = "playlistMetadataSearch";
export const CACHE_SEARCH_PRESET_KEY = "presetSearch";
export const CACHE_SEARCH_VIDEO_KEY = "videoMetadataSearch";
export const CACHE_VIDEO_KEY = "videoMetadata";

/**
 * Used when expiring search caches, which is done when an item is saved.
 * Please add any new search cache keys here as well as above!
 * @private
 */
export const CACHE_SEARCH_KEYS = frozen([
  CACHE_SEARCH_DOCUMENT_KEY,
  CACHE_SEARCH_PLAYLIST_KEY,
  CACHE_SEARCH_PRESET_KEY,
  CACHE_SEARCH_VIDEO_KEY,
  CACHE_SEARCH_COURSE_KEY,
]);

/**
 * Playlists are cached with only their item's ids in a list, and the items themselves
 * are stored in separate metadata caches. This restores the item's metadata entries.
 *
 * Returns null if any of the entries were not found (this should not happen).
 *
 * @param {object} cachedPlaylist a partially restored playlist
 * @return {?Promise<PlaylistMetadata>} the fully restored playlist item
 */
export const restorePlaylistItemsFromCache = async (cachedPlaylist) => {
  // get the whole map here so it doesn't have to be thawed for each item
  const cachedVideoMetadata = cache.getExpiredMap(CACHE_VIDEO_KEY);
  const cachedDocumentMetadata = cache.getExpiredMap(CACHE_DOCUMENT_KEY);
  const cachedCourseMetadata = cache.getExpiredMap(CACHE_COURSE_KEY);
  const items = await Promise.all(cachedPlaylist.items.map(async (entry) => {
    const { id, type } = entry;
    let found;
    switch (type) {
      case metadataTypes.VIDEO:
        found = responseToVideoMetadata((await cachedVideoMetadata.get(id))?.value);
        break;
      case metadataTypes.DOCUMENT:
        found = responseToDocumentMetadata((await cachedDocumentMetadata.get(id))?.value);
        break;
      case metadataTypes.COURSE:
        found = responseToCourseMetadata((await cachedCourseMetadata.get(id))?.value);
        break;
      default:
        log.error("unrecognized metadata type while restoring metadata entry from cache:", type);
        found = null;
    }
    if (found) return found;
    // this shouldn't happen
    log.debug("failed to restore playlist item from metadata cache", id);
    return null;
  }));
  const restored = await responseToPlaylistMetadata({
    ...cachedPlaylist,
    items,
  });
  return restored;
};

/**
 * Restores playlists from cache
 *
 * @returns {?Array.<Promise<PlaylistMetadata>>}
 */
export const restorePlaylistsFromCache = () => {
  const cachedPlaylistsMetadata = cache.getExpiredMap(CACHE_PLAYLIST_KEY);
  const restoredPlaylists = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const metadata of cachedPlaylistsMetadata) {
    const restored = restorePlaylistItemsFromCache(metadata);
    if (restored === null) {
      log.error("failed to restore a playlist from cache", metadata.id);
      return [];
    }
    restoredPlaylists.push(restored);
  }
  return restoredPlaylists;
};

/**
 * Stores a playlist response and its content items.
 *
 * @param {object} playlist playlist response dto
 */
export const storePlaylistWithItems = (playlist) => {
  playlist.items.forEach((item) => {
    const toStore = merge({}, item);
    /* eslint-disable-next-line no-param-reassign */
    toStore.abstract = true;
    // we only store the item if there's not already something in
    // the cache, because playlists give us abbreviated versions of
    // the items and we'd rather have the fully populated version
    // in there and in use
    // doing it this way means that any other copy will take precedent
    // since the other endpoints always store their full results
    let entry;
    switch (toStore.type) {
      case metadataTypes.VIDEO:
        entry = cache.getExpiringMapEntry(CACHE_VIDEO_KEY, toStore.id);
        if (!entry || entry.abstract) {
          cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, toStore.id, toStore);
        }
        break;
      case metadataTypes.DOCUMENT:
        entry = cache.getExpiringMapEntry(CACHE_DOCUMENT_KEY, toStore.id);
        if (!entry || entry.abstract) {
          cache.storeExpiringMapEntry(CACHE_DOCUMENT_KEY, toStore.id, toStore);
        }
        break;
      case metadataTypes.COURSE:
        entry = cache.getExpiringMapEntry(CACHE_COURSE_KEY, toStore.id);
        if (!entry || entry.abstract) {
          cache.storeExpiringMapEntry(CACHE_COURSE_KEY, toStore.id, toStore);
        }
        break;
      default:
        log.error("playlist cache received unrecognized metadata object", toStore);
        break;
    }
  });
  const toStore = {
    ...playlist,
    items: playlist.items.map(({ id, type }) => ({ id, type })),
  };
  cache.storeExpiringMapEntry(CACHE_PLAYLIST_KEY, toStore.id, toStore);
  return toStore;
};

export const expireAllSearches = () => {
  CACHE_SEARCH_KEYS.forEach((key) => {
    cache.storeExpiringMap(key, new Map());
  });
};

/**
 * Takes care of cleaning up cache entries after deleting a metadata item.
 *
 * @param {UUID} uuid
 * @param {MetadataType} type
 */
export const removeMetadataCacheEntries = (uuid, type) => {
  switch (type) {
    case metadataTypes.VIDEO:
      cache.deleteExpiringMapEntry(CACHE_VIDEO_KEY, uuid);
      cache.deleteExpiringMapEntry(CACHE_PLAYBACK_KEY, uuid);
      break;
    case metadataTypes.DOCUMENT:
      cache.deleteExpiringMapEntry(CACHE_DOCUMENT_KEY, uuid);
      break;
    case metadataTypes.LIST:
      cache.deleteExpiringMapEntry(CACHE_PLAYLIST_KEY, uuid);
      break;
    case metadataTypes.COURSE:
      cache.deleteExpiringMapEntry(CACHE_COURSE_KEY, uuid);
      break;
    default:
      throw new Error(`unknown metadata type: ${type}`);
  }
  // now remove page caches if any page item was linked to this metadata
  // TODO auto-remove section from page as well
  // TODO there should probably be a way to retrieve a page section without
  //      knowing about implementation details of page cache (ref: responseToPage)
  // NOTE this should never actually do anything, because backend will prevent removing
  //      a metadata if it is linked to a page. this is just a backstop.
  const pageMap = cache.getExpiringMap(CACHE_PAGE_KEY);
  const pagesToRemove = new Set();

  const recurseRemoveParents = (page) => {
    if (page.parentId) pagesToRemove.add(page.parentId);
    const parent = pageMap.get(page.parentId)?.value;
    if (parent) recurseRemoveParents(parent);
  };

  [...pageMap.values()]
    .map(({ value }) => responseToPage(value))
    .forEach((page) => {
      if (page.itemId === uuid) {
        pagesToRemove.add(page.id);
        recurseRemoveParents(page);
      }
    });
  [...pagesToRemove.values()].forEach((id) => {
    removePageDTO(id);
  });

  // now wipe all search results
  // this is less than ideal, but the internal implementation of search caches
  // varies by type and we shouldn't have to know about that here... So it would
  // be better to someday give searches their own models and API module, and then
  // call provided functions to manipulate them. Doing it surgically here is going
  // to be a mess (proven already in previous attempts).
  expireAllSearches();
};
