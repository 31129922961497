/**
 * A modal dialog that shows that your session is expired.
 *
 * @module ui/component/modal/session-expired
 * @category UI
 * @subcategory Modal Dialogs
 */
import { getModal } from "ui/view/modal-view";
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, p } from "ui/html";
import button from "ui/component/form-managed/button";
import { doLoginRedirect } from "util/navigation";

export default function sessionExpiredModal(onContinueWithoutLogin) {
  return dialog({
    header: h3("Session Expired"),
    body: p("Your SmartEdge session has expired."),
    footer: div("", [
      button({
        label: "Login",
        sel: ".main",
        onClick: () => doLoginRedirect(),
      }),
      div(".no-login-container", [
        button({
          label: "Continue without login",
          sel: ".subtle.secondary",
          onClick: () => onContinueWithoutLogin?.(),
        }),
      ]),
    ]),
    sel: ".session-expired-modal",
  }, getModal());
}
