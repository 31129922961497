/**
 * Constants for the course API.
 *
 * @module api/course/cache
 * @private
 * @category Backend API
 * @subcategory Course
 */
/***/
import { frozen } from "util/object";
/**
 * @typedef ExpandOptions
 * @property {boolean} courses
 * @property {boolean} grading
 * @property {boolean} modules
 * @property {boolean} lectures
 * @property {boolean} assessments
 * @property {boolean} metadata
 * @property {boolean} instructors
 * @property {boolean} evaluations
 */

// include presets determine how deep child object recursion goes when expanding
export const EXPAND_ALL = frozen({
  courses: true,
  grading: true,
  modules: true,
  lectures: true,
  assessments: true,
  metadata: true,
  instructors: true,
  students: true,
});

export const EXPAND_NONE = frozen({
});

export const EXPAND_COURSES = frozen({
  courses: true,
});

export const EXPAND_MODULES = frozen({
  courses: true,
  modules: true,
});

export const EXPAND_GRADING = frozen({
  courses: true,
  grading: true,
});

export const EXPAND_ASSESSMENTS = frozen({
  courses: true,
  modules: true,
  assessments: true,
});

export const EXPAND_LECTURES = frozen({
  courses: true,
  modules: true,
  lectures: true,
});

export const EXPAND_METADATA = frozen({
  courses: true,
  modules: true,
  lectures: true,
  metadata: true,
});

export const EXPAND_INSTRUCTORS = frozen({
  courses: true,
  modules: true,
  lectures: true,
  instructors: true,
});

// for expanding all data used in course profile page
export const EXPAND_COURSE_PROFILE = frozen({
  courses: true,
  grading: true,
  modules: true,
  lectures: true,
  instructors: true,
  metadata: true,
  assessments: true,
});

export const EXPAND_EVALUATIONS = frozen({
  courses: true,
  modules: true,
  assessments: true,
  evaluations: true,
});
