/**
 * An unordered list.
 *
 * @module ui/component/html/ul
 * @category UI
 * @subcategory Elements
 */
import el from "ui/common/el";

/**
 * @function ul
 * @param {module:ui/html~ChildEl} children
 * @param {module:ui/html~Selector} [sel=""]
 * @param {module:ui/html~Config} [data={}]
 * @return {module:ui/common/el~El}
 */
export default (children, sel = "", data = {}) => el(`ul${sel}`, data, children);
