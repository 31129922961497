/**
 * Constants used by metadata models.
 *
 * @module model/metadata/constants
 * @category Model
 * @subcategory Metadata
 */
import { sortCriteria } from "model/constraints";

/**
 * Map of ISO 639-2 language codes to human-friendly names.
 *
 * This is not an exhaustive list - just the languages supported by the app.
 *
 * @todo maybe get this from the backend api so it stays in sync
 *
 * @constant langaugeCodeMap
 * @type {Map.<string, string>}
 * @readonly
 */
export const languageCodeMap = Object.freeze(
  new Map([
    ['abk', 'Abkhaz'],
    ['aar', 'Afar'],
    ['afr', 'Afrikaans'],
    ['aka', 'Akan'],
    ['alb', 'Albanian'],
    ['amh', 'Amharic'],
    ['ara', 'Arabic'],
    ['arg', 'Aragonese'],
    ['arm', 'Armenian'],
    ['asm', 'Assamese'],
    ['ava', 'Avaric'],
    ['ave', 'Avestan'],
    ['aym', 'Aymara'],
    ['aze', 'Azerbaijani'],
    ['bam', 'Bambara'],
    ['bak', 'Bashkir'],
    ['baq', 'Basque'],
    ['bel', 'Belarusian'],
    ['ben', 'Bengali; Bangla'],
    ['bih', 'Bihari'],
    ['bis', 'Bislama'],
    ['bos', 'Bosnian'],
    ['bre', 'Breton'],
    ['bul', 'Bulgarian'],
    ['bur', 'Burmese'],
    ['cat', 'Catalan; Valencian'],
    ['cha', 'Chamorro'],
    ['che', 'Chechen'],
    ['nya', 'Chichewa; Chewa; Nyanja'],
    ['chi', 'Chinese'],
    ['chv', 'Chuvash'],
    ['cor', 'Cornish'],
    ['cos', 'Corsican'],
    ['cre', 'Cree'],
    ['hrv', 'Croatian'],
    ['cze', 'Czech'],
    ['dan', 'Danish'],
    ['div', 'Divehi; Dhivehi; Maldivian;'],
    ['dut', 'Dutch'],
    ['dzo', 'Dzongkha'],
    ['eng', 'English'],
    ['epo', 'Esperanto'],
    ['est', 'Estonian'],
    ['ewe', 'Ewe'],
    ['fao', 'Faroese'],
    ['fij', 'Fijian'],
    ['fin', 'Finnish'],
    ['fre', 'French'],
    ['ful', 'Fula; Fulah; Pulaar; Pular'],
    ['glg', 'Galician'],
    ['geo', 'Georgian'],
    ['ger', 'German'],
    ['gre', 'Greek, Modern'],
    ['grn', 'Guaraní'],
    ['guj', 'Gujarati'],
    ['hat', 'Haitian; Haitian Creole'],
    ['hau', 'Hausa'],
    ['heb', 'Hebrew (modern)'],
    ['her', 'Herero'],
    ['hin', 'Hindi'],
    ['hmo', 'Hiri Motu'],
    ['hun', 'Hungarian'],
    ['ina', 'Interlingua'],
    ['ind', 'Indonesian'],
    ['ile', 'Interlingue'],
    ['gle', 'Irish'],
    ['ibo', 'Igbo'],
    ['ipk', 'Inupiaq'],
    ['ido', 'Ido'],
    ['ice', 'Icelandic'],
    ['ita', 'Italian'],
    ['iku', 'Inuktitut'],
    ['jpn', 'Japanese'],
    ['jav', 'Javanese'],
    ['kal', 'Kalaallisut, Greenlandic'],
    ['kan', 'Kannada'],
    ['kau', 'Kanuri'],
    ['kas', 'Kashmiri'],
    ['kaz', 'Kazakh'],
    ['khm', 'Khmer'],
    ['kik', 'Kikuyu, Gikuyu'],
    ['kin', 'Kinyarwanda'],
    ['kir', 'Kyrgyz'],
    ['kom', 'Komi'],
    ['kon', 'Kongo'],
    ['kor', 'Korean'],
    ['kur', 'Kurdish'],
    ['kua', 'Kwanyama, Kuanyama'],
    ['lat', 'Latin'],
    ['ltz', 'Luxembourgish, Letzeburgesch'],
    ['lug', 'Ganda'],
    ['lim', 'Limburgish, Limburgan, Limburger'],
    ['lin', 'Lingala'],
    ['lao', 'Lao'],
    ['lit', 'Lithuanian'],
    ['lub', 'Luba-Katanga'],
    ['lav', 'Latvian'],
    ['glv', 'Manx'],
    ['mac', 'Macedonian'],
    ['mlg', 'Malagasy'],
    ['may', 'Malay'],
    ['mal', 'Malayalam'],
    ['mlt', 'Maltese'],
    ['mao', 'Māori'],
    ['mar', 'Marathi (Marāṭhī)'],
    ['mah', 'Marshallese'],
    ['mon', 'Mongolian'],
    ['nau', 'Nauru'],
    ['nav', 'Navajo, Navaho'],
    ['nob', 'Norwegian Bokmål'],
    ['nde', 'North Ndebele'],
    ['nep', 'Nepali'],
    ['ndo', 'Ndonga'],
    ['nno', 'Norwegian Nynorsk'],
    ['nor', 'Norwegian'],
    ['iii', 'Nuosu'],
    ['nbl', 'South Ndebele'],
    ['oci', 'Occitan'],
    ['oji', 'Ojibwe, Ojibwa'],
    ['chu', 'Old Church Slavonic, Church Slavonic, Old Bulgarian'],
    ['orm', 'Oromo'],
    ['ori', 'Oriya'],
    ['oss', 'Ossetian, Ossetic'],
    ['pan', 'Panjabi, Punjabi'],
    ['pli', 'Pāli'],
    ['per', 'Persian (Farsi)'],
    ['pol', 'Polish'],
    ['pus', 'Pashto, Pushto'],
    ['por', 'Portuguese'],
    ['que', 'Quechua'],
    ['roh', 'Romansh'],
    ['run', 'Kirundi'],
    ['rum', 'Romanian'],
    ['rus', 'Russian'],
    ['san', 'Sanskrit (Saṃskṛtā)'],
    ['srd', 'Sardinian'],
    ['snd', 'Sindhi'],
    ['sme', 'Northern Sami'],
    ['smo', 'Samoan'],
    ['sag', 'Sango'],
    ['srp', 'Serbian'],
    ['gla', 'Scottish Gaelic; Gaelic'],
    ['sna', 'Shona'],
    ['sin', 'Sinhala, Sinhalese'],
    ['slo', 'Slovak'],
    ['slv', 'Slovene'],
    ['som', 'Somali'],
    ['sot', 'Southern Sotho'],
    ['azb', 'South Azerbaijani'],
    ['spa', 'Spanish; Castilian'],
    ['sun', 'Sundanese'],
    ['swa', 'Swahili'],
    ['ssw', 'Swati'],
    ['swe', 'Swedish'],
    ['tam', 'Tamil'],
    ['tel', 'Telugu'],
    ['tgk', 'Tajik'],
    ['tha', 'Thai'],
    ['tir', 'Tigrinya'],
    ['tib', 'Tibetan Standard, Tibetan, Central'],
    ['tuk', 'Turkmen'],
    ['tgl', 'Tagalog'],
    ['tsn', 'Tswana'],
    ['ton', 'Tonga (Tonga Islands)'],
    ['tur', 'Turkish'],
    ['tso', 'Tsonga'],
    ['tat', 'Tatar'],
    ['twi', 'Twi'],
    ['tah', 'Tahitian'],
    ['uig', 'Uyghur, Uighur'],
    ['ukr', 'Ukrainian'],
    ['urd', 'Urdu'],
    ['uzb', 'Uzbek'],
    ['ven', 'Venda'],
    ['vie', 'Vietnamese'],
    ['vol', 'Volapük'],
    ['wln', 'Walloon'],
    ['wel', 'Welsh'],
    ['wol', 'Wolof'],
    ['fry', 'Western Frisian'],
    ['xho', 'Xhosa'],
    ['yid', 'Yiddish'],
    ['yor', 'Yoruba'],
    ['zha', 'Zhuang, Chuang'],
    ['zul', 'Zulu'],
  ]),
);

/**
 * A list of the language codes from languageCodeMap, for use in validation.
 *
 * @constant languageCodes
 * @type {Set.<string>}
 * @readonly
 */
export const languageCodes = Object.freeze(new Set(languageCodeMap.keys()));

/**
 * Possible values for the `type` field on {@link module:api/types.ListItem}
 *
 * @enum {string} metadataTypes
 * @readonly
 * @property {string} COURSE a course
 * @property {string} DYNAMIC_LIST (deprecated) a dynamically generated list
 * @property {string} LIST contains a collection of non-LIST type metadata
 * @property {string} DOCUMENT a general document type
 * @property {string} VIDEO a video
 * @property {string} MP4 an MP4 video
 * @property {string} IMAGE an image
 */
export const metadataTypes = Object.freeze({
  COURSE: "COURSE_METADATA",
  MODULE: "MODULE_METADATA",
  DYNAMIC_LIST: "DYNAMIC_LIST_METADATA",
  LIST: "LIST_METADATA",
  DOCUMENT: "DOCUMENT_METADATA",
  VIDEO: "VIDEO_METADATA",
  MP4: "VIDEO_METADATA",
  IMAGE: "IMAGE_METADATA",
});

/**
 * Enumeration of valid metadata type values as a Set.
 *
 * @constant metadataTypeValues
 * @type {Set.<string>}
 * @readonly
 */
export const metadataTypeValues = Object.freeze(
  new Set(Object.values(metadataTypes)),
);

/**
 * Map of user-friendly names for metadata types.
 *
 * Entries per {@link metadataTypes}
 *
 * @constant metadataTypesFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
export const metadataTypesFriendly = new Map([
  [metadataTypes.VIDEO, 'MP4'],
  [metadataTypes.DOCUMENT, 'Document'],
  [metadataTypes.LIST, 'List'],
  [metadataTypes.DYNAMIC_LIST, 'Dynamic Playlist'],
]);

/**
 * Metadata types that should be treated as a list or collection.
 * These all have an `items` array property.
 *
 * See [metadataTypes]{@link metadataTypes}
 *
 * @constant metadataListTypes
 * @type {Set.<string>}
 * @readonly
 */
export const metadataListTypes = Object.freeze(new Set([
  metadataTypes.LIST,
  metadataTypes.DYNAMIC_LIST,
]));

/**
 * @typedef SortCriteriaLabels
 * @property {string} value as {@link sortCriteria}
 * @property {string} label human readable label
 */

/**
 * User friendly labels for sort criteria.
 * @constant sortCriteriaLabeled
 * @type {Set.<SortCriteriaLabels>}
 * @readonly
 */
export const sortCriteriaLabeled = Object.freeze(
  new Set([
    { value: 'BY_CREATION_DATE_ASC', label: "Oldest" },
    { value: 'BY_CREATION_DATE_DESC', label: "Newest" },
    { value: 'BY_DURATION_ASC', label: "Shortest" },
    { value: 'BY_DURATION_DESC', label: "Longest" },
    // the following are disabled by request (lmsfe21-81) but still supported
    // { value: 'BY_RESOLUTION_ASC', label: "Smallest" },
    // { value: 'BY_RESOLUTION_DESC', label: "Largest" },
    { value: 'BY_TITLE_ASC', label: "A-Z" },
    { value: 'BY_TITLE_DESC', label: "Z-A" },
  ]),
);

/**
 * Sort criteria applicable to all metadata types. Used in searching, filtering,  and
 * table column sorting.
 *
 * @enum {string} metadataSortCriteria
 * @readonly
 * @property {string} DATE_ASC
 * @property {string} DATE_DESC
 * @property {string} RESOLUTION_ASC
 * @property {string} RESOLUTION_DESC
 * @property {string} LENGTH_ASC
 * @property {string} LENGTH_DESC
 * @property {string} TITLE_ASC
 * @property {string} TITLE_DESC
 * @property {string} ID_ASC
 * @property {string} ID_DESC
 * @property {string} TYPE_ASC
 * @property {string} TYPE_DESC
 * @property {string} DESCRIPTION_ASC
 * @property {string} DESCRIPTION_DESC
 */
export const metadataSortCriteria = Object.freeze({
  DATE_ASC: 'BY_CREATION_DATE_ASC',
  DATE_DESC: 'BY_CREATION_DATE_DESC',
  RESOLUTION_ASC: 'BY_RESOLUTION_ASC',
  RESOLUTION_DESC: 'BY_RESOLUTION_DESC',
  LENGTH_ASC: 'BY_DURATION_ASC',
  LENGTH_DESC: 'BY_DURATION_DESC',
  TITLE_ASC: 'BY_TITLE_ASC',
  TITLE_DESC: 'BY_TITLE_DESC',
  // below entries not supported by backend, used for client-side sorting only
  ID_ASC: 'BY_ID_ASC',
  ID_DESC: 'BY_ID_DESC',
  TYPE_ASC: 'BY_TYPE_ASC',
  TYPE_DESC: 'BY_TYPE_DESC',
  DESCRIPTION_ASC: 'BY_DESCRIPTION_ASC',
  DESCRIPTION_DESC: 'BY_DESCRIPTION_DESC',
});

/**
 * Set of valid sort criteria for video metadata searches.
 *
 * @constant videoSortCriteria
 * @type {Set.<string>}
 * @readonly
 */
export const videoSortCriteria = Object.freeze(
  new Set([
    ...[...sortCriteria],
    'BY_RESOLUTION_ASC',
    'BY_RESOLUTION_DESC',
    'BY_DURATION_ASC',
    'BY_DURATION_DESC',
  ]),
);
