/**
 * A horizontal rule.
 *
 * @module ui/html/hr
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * Horizontal rule factory.
 *
 * @param {string} sel CSS Selector
 * @return {module:ui/common/el~El}
 */
const hr = (sel = "") => el(`hr${sel}`);

export default hr;
