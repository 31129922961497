import collapsible from "ui/component/collapsible";
import { button } from "ui/html";
import evaluationManualGrading from "ui/component/modal/evaluation/evaluation-manual-grading";
import log from "log";
import { mockSolutionTextBox, mockSolutionUpload, mockSolutionMultipleChoice } from "api/mocks/course";

export default (modalView) => collapsible(
  { title: "Evaluations", open: false },
  [
    button(
      ["Manual grading"],
      async () => {
        const result = await modalView.async(evaluationManualGrading({
          solution: mockSolutionMultipleChoice,
        }));
        log.debug("Result", result);
      },
    ),
    button(
      ["Manual grading (text)"],
      async () => {
        const result = await modalView.async(evaluationManualGrading({
          solution: mockSolutionTextBox,
        }));
        log.debug("Result", result);
      },
    ),
    button(
      ["Manual grading (upload)"],
      async () => {
        const result = await modalView.async(evaluationManualGrading({
          solution: mockSolutionUpload,
        }));
        log.debug("Result", result);
      },
    ),
  ],
);
