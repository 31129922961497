/**
 * Utility functions related to generating random strings.
 *
 * @module util/generator
 * @category Utilities
 */

// eslint-disable-next-line import/prefer-default-export
export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
// eslint-disable-next-line no-bitwise,no-mixed-operators,implicit-arrow-linebreak
  (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
