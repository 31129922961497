/**
 * A help modal dialog for stats page.
 *
 * @module ui/component/modal/help/admin-dashboard
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminStatsHelpModal(skipCache = false, async = false) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_STATS,
    skipCache,
    async,
    sel: ".help-modal-admin-welcome",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img("", "/media/modal-help-admin-welcome.svg"),
            div(".text-container", [
              p("Welcome to the Admin Portal!"),
              p("On this page, you can view the stats by clicking on the category arrows.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img("", "/media/modal-help-admin-welcome.svg"),
            div(".text-container", [
              p("Navigate the dashboard to access interfaces for Manage Users, Manage"),
              p("Content, Manage Pages, and much more!", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img("", "/media/modal-help-admin-welcome.svg"),
            div(".text-container", [
              p([
                "If you have any more questions, please visit our ",
                a("Admin FAQ", "/admin/admin-faq"),
                " for more help",
              ]),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
