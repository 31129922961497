/**
 * The user-facing account settings FAQ page.
 *
 * @module ui/page/faq/account-settings
 * @category Pages
 * @subcategory User Facing
 */
import { a, div, h1, h3, p } from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "account-settings-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showAccountSettingsFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Account Settings"]),
  collapsible({
    title: "How to change my Account Settings?",
    autoCloseConfiguration,
  }, p(`Select Account Settings on the Side Bar Menu. From there, you'll be able to modify the name,
    mobile number, email address, and password associated with your account. Make sure to press Save before leaving!`)),
  collapsible({
    title: "What happens if I change my password on the Account Settings page?",
    autoCloseConfiguration,
  }, p(`You just need to enter a new password (along with the password again in the Confirm Password field).
    No need to log out and re-log in. You will not need to resubmit an OTP for user account verification. Make sure to
    remember the new password for next time you need to log into SmartEdge!`)),
  collapsible({
    title: "Can I modify my profile if I'm an external user?",
    autoCloseConfiguration,
  }, p(`Unfortunately not through SmartEdge. You'll need to go to the account management page for the external
    credentials to modify your account. Once these changes on the external system are changed, feel free to log into
    SmartEdge with the new login info.`)),
]);

export default async function accountSettingsFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("AccountSettings"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showAccountSettingsFaq)("#end-user-faq", state);
  loading.hide();
}
