/* eslint-disable no-await-in-loop */
/**
 * A page for bulk user entry.
 *
 * Corresponds to `markup/admin/add-user-bulk.html`
 *
 * @module ui/view/admin/user/bulk
 * @category Pages
 * @subcategory Admin - Users
 */
import Papa from "papaparse";
import api from "api";
import getConfig from "config";
import log from "log";
import { systemPrivilegeGroups } from "model/acl/constants";
import { notificationMessageTypes } from "model/notification/constants";
import {
  input,
  label,
  section,
  span,
} from "ui/html";
import form from "ui/component/form-managed";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import actionBar from "ui/component/dashboard-action-bar";
import placeholder from "ui/component/placeholder";
import widget from "ui/component/dashboard-widget";
import groupSelectMulti from "ui/component/modal/acl/group-select-multi";
import view from "ui/view";
import adminAddUserBulkHelpModal from "ui/component/modal/help/add-user-bulk";
import toggle from "ui/component/form-managed/field-toggle";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import dashboardLayout from "ui/page/layout/dashboard";
import formManagedView from "ui/view/form-managed";
import { unique, filterMap } from "util/array";
import { qs } from "util/dom";
import { tidyBackendError } from "util/error";
import { merge } from "util/object";
import { featureTypes } from "model/config/constants";
import { guard } from "util/feature-flag";
import {
  defaultState,
  displayModes,
  saveStates,
  uploadStates,
} from "./state";
import messages from "./messages";
import { makeUserRecord } from "./user-record";
import { processCSV } from "./csv-utils";
import {
  PLACEHOLDER_GROUP_ID,
  collectAppliedGroups,
  getUploadState,
  getUsersFromFields,
  refreshDerivedState,
  refreshUsers,
  setAllVerified,
  statusCounter,
} from "./util";
import { rowFactory } from "./user-row";
import helpSection from "./help";

let modal, actions, page, notification, loading, header;

const handleBackendError = (e) => {
  const errors = [];
  switch (e.statusCode) {
    case 403:
      errors.push(messages.permissionError);
      break;
    case undefined:
      errors.push(e.message);
      break;
    default:
      errors.push(tidyBackendError(e.body).join("\r\n"));
  }
  return errors;
};

const processingMessages = () => {
  const { users, saveStatuses } = page.state;
  const count = statusCounter(users, saveStatuses);
  const done = users.length - count(saveStates.SAVING);
  const remaining = users.length - done;
  return [
    remaining > 0
      ? {
        title: "In progress",
        text: `Processing: ${done} out of ${users.length} users created.
          ${count(saveStates.SUCCESS)} successful.
          ${count(saveStates.FAILURE)} failed.`,
        type: notificationMessageTypes.SUCCESS,
        duration: 10,
      }
      : {
        title: "Done",
        text: `Upload complete. ${users.length} users processed.
          ${count(saveStates.SUCCESS)} successful.
          ${count(saveStates.FAILURE)} failed.`,
        type: notificationMessageTypes.SUCCESS,
        duration: 10,
      },
  ];
};

const createNonExistingGroups = async (users, groups) => {
  const groupsToCreate = collectAppliedGroups(users, groups)
    .filter((group) => group.id === PLACEHOLDER_GROUP_ID)
    .map((group) => group.name);
  return Promise.all(
    groupsToCreate.map((groupName) => api.acl.createGroup(groupName)),
  );
};

const processUserGroups = async (user) => {
  const { groups } = page.state;
  if (user.groupNames) {
    await Promise.all(user.groupNames.map(async (groupName) => {
      const existingGroup = groups.find((group) => group.name === groupName);
      if (existingGroup) {
        await api.acl.addUserToGroup({
          groupId: existingGroup.id,
          userId: user.id,
        });
      }
    }));
  }
};

let lastSuccess;

const saveUsers = async (users) => {
  const { entries, errors } = await api.user.batchCreate(users);
  users.forEach((user, index) => {
    const newUser = entries[index];
    if (newUser) {
      processUserGroups({ ...user, id: newUser.id });
      const saveStatuses = new Map([...page.state.saveStatuses.entries()]);
      saveStatuses.set(user.tempId, saveStates.SUCCESS);
      page.update({ saveStatuses });
      const procMessages = processingMessages();
      lastSuccess = notification.replace(lastSuccess, procMessages[0]);
    } else {
      const error = errors[index];
      if (error) {
        const saveStatuses = new Map([...page.state.saveStatuses.entries()]);
        const saveErrors = new Map([...page.state.saveErrors.entries()]);
        saveStatuses.set(user.tempId, saveStates.FAILURE);
        saveErrors.set(user.tempId, handleBackendError(error));
        page.update({ saveStatuses, saveErrors });
        const procMessages = processingMessages();
        lastSuccess = notification.replace(lastSuccess, procMessages[0]);
      }
    }
  });
};

/* eslint-disable-next-line no-unused-vars */
const doSave = async () => {
  page.setFullValidation(true);
  const validity = page.validate();
  if (!validity.valid) {
    page.update({
      validity,
      messages: [messages.invalid],
      uploadState: uploadStates.NOT_READY,
    });
    return;
  }
  const { userGroupMode } = page.state;

  // get fresh user records
  refreshUsers(page, getUsersFromFields(page));
  // set all users to saving state
  const saveStatuses = new Map();
  page.state.users.forEach((user) => {
    const oldStatus = page.state.saveStatuses.get(user.tempId);
    saveStatuses.set(user.tempId, oldStatus === saveStates.SUCCESS
      ? saveStates.SUCCESS
      : saveStates.SAVING);
  });

  page.update({ saveStatuses });

  if (userGroupMode) {
    await createNonExistingGroups(page.state.users, page.state.groups);
    const newGroupsList = await api.acl.listGroups();
    page.update({ groups: newGroupsList });
  }

  try {
    await saveUsers(
      page.state.users.filter(
        (user) => page.state.saveStatuses.get(user.tempId) !== saveStates.SUCCESS,
      ),
    );
    const procMessages = processingMessages();
    lastSuccess = notification.replace(lastSuccess, procMessages[0]);
    page.update({ messages: procMessages });
  } catch (e) {
    log.error(e, "Failed to create users");
    notification.post({
      title: "Upload Error",
      text: `Failed to process users.`,
      type: notificationMessageTypes.FAIL,
      duration: 0,
    });
  }

  const existing = await api.user.list();
  const procMessages = processingMessages();
  page.updateState({
    existingUsers: existing.reduce(filterMap((u) => u.username, (u) => u.username), []),
    existingCellPhones: existing.reduce(filterMap((u) => u.cellPhone, (u) => u.cellPhone), []),
    existingEmails: existing.reduce(filterMap((u) => u.email, (u) => u.email), []),
  });
  page.update({
    messages: procMessages,
    uploadState: getUploadState(page),
  });
  lastSuccess = notification.replace(lastSuccess, procMessages[0]);
  page.setFullValidation(false);
};

const makeColumnLabel = (text, required = false) => label(
  `${text}${required ? "*" : ""}`,
);

const parseCSV = (file) => new Promise((res, rej) => {
  Papa.parse(file, {
    complete: (out) => res(out),
    error: (err) => rej(err),
  });
});

const handleCSV = async (self) => {
  self.update({
    uploadState: uploadStates.IN_PROGRESS,
  });
  const file = qs(`input[type="file"][name="csv_upload"]`).files[0];
  if (file) {
    const parsed = await parseCSV(file);
    const users = await processCSV(parsed, modal, page);
    qs(`input[type="file"][name="csv_upload"]`).value = "";
    refreshUsers(self, [...self.state.users, ...users.map(makeUserRecord)]);
    const validation = self.validate();
    self.update({ validation });
  }
};

const bulkAddToGroup = async () => {
  const selected = await modal.async(
    groupSelectMulti({ groups: [...page.state.groups] }, modal),
  );
  if (selected) {
    const users = page.state.users.map((user) => {
      const newGroups = unique([
        ...user.groupNames,
        ...selected.map((group) => group.name),
      ]).filter((entry) => !!entry.trim());
      return {
        ...user,
        groupNames: newGroups,
        groups: newGroups.map(
          (gn) => page.state.groups.find((g) => g.name === gn),
        ),
      };
    });
    refreshUsers(page, users);
  }
};

const onVerifiedChange = (on) => {
  if (on === true) setAllVerified(page, true);
  else setAllVerified(page, false);
};

const removeSuccessRows = async () => {
  if (!await modal.confirm(`Are you sure you want to delete uploaded rows?`)) {
    return;
  }
  const { users, saveStatuses } = page.state;
  refreshUsers(
    page,
    users.filter((user) => saveStatuses.get(user.tempId) !== saveStates.SUCCESS),
  );
};

const removeFailedRows = async () => {
  if (!await modal.confirm(`Are you sure you want to delete failed rows?`)) {
    return;
  }
  const { users, saveStatuses } = page.state;
  refreshUsers(
    page,
    users.filter((user) => saveStatuses.get(user.tempId) !== saveStates.FAILURE),
  );
};

const doAddUser = (self) => async () => {
  refreshUsers(self, [
    ...self.state.users,
    makeUserRecord(),
  ]);
};

const showForm = (self) => {
  const { userGroupMode } = self.state;
  if (self.state.displayMode === displayModes.HELP) {
    return helpSection(userGroupMode);
  }
  const { state } = self;
  const serverConfig = state.config.server;

  const headers = [
    makeColumnLabel(`First Name`, serverConfig.firstNameRequired),
    makeColumnLabel(`Last Name`, serverConfig.lastNameRequired),
    makeColumnLabel(`User Name`, true),
    makeColumnLabel(`Cell Phone`, serverConfig.cellPhoneRequired),
    makeColumnLabel(`Email`, serverConfig.emailRequired),
    makeColumnLabel("Password", true),
    span(icon.solid("user-check"), ".verified-column.icon-label"),
    span(icon.solid("check-circle"), ".status-column.icon-label"),
    span(icon.solid("trash"), ".remove-column.icon-label"),
  ];

  if (userGroupMode) {
    headers.unshift(
      makeColumnLabel("Groups", false),
    );
  }

  const rows = self.bind(self.state.users.map(rowFactory(self, modal)).flat(), self.state.users);
  const formName = self.state.userGroupMode
    ? "#add-user-group-bulk-form.bulk"
    : "#add-user-bulk-form.bulk";
  return form(formName, [
    ...headers,
    ...rows,
    button({
      icon: "plus-circle",
      label: "Add User",
      onClick: doAddUser(self),
      sel: "#add-user.stand-in",
    }),
    input.file(
      false,
      `csv_upload`,
      'text/csv',
      {
        on: {
          change: async () => handleCSV(page),
        },
      },
    ),
  ]);
};

const saveButton = (uploadState) => {
  let saveButtonText;
  let disabled;

  switch (uploadState) {
    case uploadStates.RETRY:
      saveButtonText = "Retry";
      disabled = false;
      break;
    case uploadStates.IN_PROGRESS:
      saveButtonText = "Processing";
      disabled = true;
      break;
    case uploadStates.READY:
      saveButtonText = "Save";
      disabled = false;
      break;
    case uploadStates.DONE:
      saveButtonText = "Completed";
      disabled = true;
      break;
    case uploadStates.ERRORS:
      saveButtonText = "Errors";
      disabled = true;
      break;
    case uploadStates.NOT_READY:
    default:
      saveButtonText = "Save";
      disabled = true;
      break;
  }
  return button({
    icon: "save",
    label: saveButtonText,
    onClick: () => doSave(),
    disabled,
    sel: "#save", // for QA
  });
};

const showActions = (self) => {
  const { state } = self;
  const { userGroupMode } = state;
  const count = statusCounter(page.state.users, page.state.saveStatuses);
  const showRemoveFailed = (count(saveStates.FAILURE) > 0);
  const showRemoveSuccess = (count(saveStates.SUCCESS) > 0);

  return section("#actions", [
    span([
      button({
        icon: "file-csv",
        label: "Import",
        onClick: () => qs("input[name=csv_upload]").click(),
        sel: "#import",
        ariaLabel: "Import CSV File",
      }),
      button.secondary({
        icon: "file-download",
        label: "Template",
        onClick: () => window.open(`/media/user${userGroupMode ? "_group" : ""}_bulk_upload_template.csv`, "_blank"),
        ariaLabel: "Download Template",
      }),
      state.displayMode === displayModes.HELP
        ? button.alternate({
          icon: "table",
          label: "Upload",
          onClick: () => {
            // need to double-update to get validation back
            page.update({ displayMode: displayModes.BULK_UPLOAD });
            page.update({});
          },
          ariaLabel: "Show Upload",
        })
        : button.alternate({
          icon: "question-circle",
          label: "Help",
          onClick: () => page.update({ displayMode: displayModes.HELP }),
          ariaLabel: "Show Help",
        }),
    ]),
    span([
      showRemoveSuccess ? button.secondary({
        icon: "check-circle",
        label: "remove successful",
        onClick: () => removeSuccessRows(),
        ariaLabel: "Remove Successful Rows",
      }) : "",
      showRemoveFailed ? button.warning({
        icon: "times-circle",
        label: "remove failed",
        onClick: () => removeFailedRows(),
        ariaLabel: "Remove Failed Rows",
      }) : "",
      state.userGroupMode
        ? button.alternate({
          icon: "users",
          label: "Add Groups",
          onClick: () => bulkAddToGroup(),
          ariaLabel: "Add Groups to All Users",
        })
        : "",
      toggle.boxed.inverse({
        toggled: page.state.allVerified,
        disabled: page.state.users.length === 0,
        required: false,
        name: "all-verified",
        label: "Verify All",
        onToggle: onVerifiedChange,
      }),
      saveButton(self.state.uploadState),
    ]),
  ]);
};

/**
 * An updateFn callback for the form view.
 * @function doUpdate
 * @private
 */
const doUpdate = (self) => {
  refreshDerivedState(self);
  self.render();
  if (actions) actions.update(self.state);
};

/**
 * The bulk user import page.
 *
 * @function bulkAddUser
 */
export default async function bulkAddUser(selector, {
  userGroupMode,
  title,
} = {
  userGroupMode: false,
  title: "Mass Creation — Users",
}) {
  guard(featureTypes.USER_MANAGEMENT);
  ({ header, modal, loading, notification } = dashboardLayout(selector, [
    actionBar(placeholder("Loading...", "#actions")),
    widget([
      form("#add-user-bulk-form"),
    ], "", ".add-user-bulk"),
  ], title, true));
  loading.show();

  const [config, user, existing, groups] = await Promise.all([
    getConfig(),
    api.user.getMe(),
    api.user.list(),
    api.acl.listGroups(),
  ]);

  const existingGroups = (groups || [])
    .filter((g) => g.name !== systemPrivilegeGroups.PUBLIC);

  const state = merge(defaultState, {
    user,
    existingUsers: existing.reduce(filterMap((u) => u.username, (u) => u.username), []),
    existingCellPhones: existing.reduce(filterMap((u) => u.cellPhone, (u) => u.cellPhone), []),
    existingEmails: existing.reduce(filterMap((u) => u.email, (u) => u.email), []),
    config,
    userGroupMode,
    existingGroups,
    groups: existingGroups,
    title,
  });

  header.update(state);

  page = formManagedView(showForm, doUpdate)("#add-user-bulk-form", state);
  actions = view.create(showActions)("#actions", state);

  if (!isHelpModalSeen(modalTypes.ADMIN_ADD_USER_BULK)) {
    modal.async(adminAddUserBulkHelpModal());
    markModalAsSeen(modalTypes.ADMIN_ADD_USER_BULK);
  }
  loading.hide();
}
