/**
 * Constants related to courses
 *
 * @module model/course/constants
 * @category Model
 * @subcategory Course
 */

/**
 * Course entry types
 *
 * @enum {string} courseEntryTypes
 * @readonly
 * @property {string} LECTURE lecture
 */
export const courseModuleEntryTypes = Object.freeze({
  LECTURE: "LECTURE",
});

/**
 * Set of course entry type strings accepted by the backend API,
 * as [courseEntryTypes]{@link courseModuleEntryTypes}
 *
 * @constant courseModuleEntryTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const courseModuleEntryTypesSet = Object.freeze(
  new Set(Object.values(courseModuleEntryTypes)),
);

/**
 * Sort criteria applicable to all course types. Used in searching, filtering, and
 * table column sorting.
 *
 * @enum {string} courseSortCriteria
 * @readonly
 * @property {string} DATE_ASC
 * @property {string} DATE_DESC
 * @property {string} ENTRIES_ASC
 * @property {string} ENTRIES_DESC
 * @property {string} ID_ASC
 * @property {string} ID_DESC
 * @property {string} TITLE_ASC
 * @property {string} TITLE_DESC
 */
export const courseSortCriteria = Object.freeze({
  DATE_ASC: "BY_DATE_ASC",
  DATE_DESC: "BY_DATE_DESC",
  ENTRIES_ASC: "BY_ENTRIES_ASC",
  ENTRIES_DESC: "BY_ENTRIES_DESC",
  ID_ASC: "BY_ID_ASC",
  ID_DESC: "BY_ID_DESC",
  TITLE_ASC: "BY_TITLE_ASC",
  TITLE_DESC: "BY_TITLE_DESC",
});

/**
 * Sort criteria applicable to all course types. Used in searching, filtering, and
 * table column sorting.
 *
 * @enum {string} assessmentSortCriteria
 * @readonly
 * @property {string} TITLE_ASC
 * @property {string} TITLE_DESC
 * @property {string} START_DATE_ASC
 * @property {string} START_DATE_DESC
 * @property {string} END_DATE_ASC
 * @property {string} END_DATE_DESC
 * @property {string} GRADING_SCHEME_ASC
 * @property {string} GRADING_SCHEME_DESC
 */
export const assessmentSortCriteria = Object.freeze({
  TITLE_ASC: "BY_TITLE_ASC",
  TITLE_DESC: "BY_TITLE_DESC",
  START_DATE_ASC: "BY_START_DATE_ASC",
  START_DATE_DESC: "BY_START_DATE_DESC",
  END_DATE_ASC: "BY_END_DATE_ASC",
  END_DATE_DESC: "BY_END_DATE_DESC",
  GRADING_SCHEME_ASC: "BY_GRADING_SCHEME_ASC",
  GRADING_SCHEME_DESC: "BY_GRADING_SCHEME_DESC",
});

/**
 * Course entry types
 *
 * @enum {string} courseEntryType
 * @readonly
 * @property {string} SINGLE_LECTURE single lecture
 * @property {string} MODULE module
 */
export const courseEntryType = Object.freeze({
  SINGLE_LECTURE: "SINGLE_LECTURE",
  MODULE: "COURSE_MODULE",
});

/**
 * Question types
 *
 * @enum {string} questionType
 * @readonly
 * @property {string} MULTIPLE_CHOICE_SINGLE_ANSWER
 * @property {string} MULTIPLE_CHOICE_MULTI_ANSWER
 * @property {string} FILL_IN_LONG
 * @property {string} FILL_IN_SHORT
 * @property {string} UPLOAD
 * @property {string} SUBSTITUTED a question that was missing when looked up in
 *                    association with a solution, and was replaced with a guess
 * @property {string} MISSING a question that was missing when looked up in
 *                    association with a solution, and could not be replaced
 */
export const questionType = Object.freeze({
  MULTIPLE_CHOICE_SINGLE_ANSWER: "MULTIPLE_CHOICE_SINGLE_ANSWER",
  MULTIPLE_CHOICE_MULTI_ANSWER: "MULTIPLE_CHOICE_MULTI_ANSWER",
  FILL_IN_LONG: "FILL_IN_LONG",
  FILL_IN_SHORT: "FILL_IN_SHORT",
  UPLOAD: "UPLOAD",
  SUBSTITUTED: "SUBSTITUTED",
  MISSING: "MISSING",
});

export const questionTypeFriendly = Object.freeze(new Map([
  [questionType.MULTIPLE_CHOICE_SINGLE_ANSWER, "Multiple Choice (Radio)"],
  [questionType.MULTIPLE_CHOICE_MULTI_ANSWER, "Multiple Choice (Checklist)"],
  [questionType.FILL_IN_LONG, "Fill-in Text (Long)"],
  [questionType.FILL_IN_SHORT, "Fill-in Text (Short)"],
  [questionType.UPLOAD, "File Dropbox"],
  [questionType.SUBSTITUTED, "(this question has been substituted with another)"],
  [questionType.MISSING, "(this question was missing)"],
]));

/**
 * Scoring methods
 *
 * @enum {string} scoringMethod
 * @readonly
 * @property {string} MANUAL
 * @property {string} AUTOMATIC
 *
export const scoringMethod = Object.freeze({
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC",
});
*/

/**
 * Map of user-friendly names for scoring methods.
 *
 * Entries per {@link scoringMethod}
 *
 * @constant scoringMethodFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
/*
export const scoringMethodFriendly = new Map([
  [scoringMethod.MANUAL, 'Manual'],
  [scoringMethod.AUTOMATIC, 'Automatic'],
]);
*/

/**
 * Sort criteria applicable to evaluations. Used in searching, filtering,  and
 * table column sorting.
 *
 * @enum {string} evaluationSortCriteria
 * @readonly
 * @property {string} TITLE_ASC
 * @property {string} TITLE_DESC
 * @property {string} FULLNAME_ASC
 * @property {string} FULLNAME_DESC
 * @property {string} DATE_ASC
 * @property {string} DATE_DESC
 * @property {string} STATUS_ASC
 * @property {string} STATUS_DESC
 */
export const evaluationSortCriteria = Object.freeze({
  TITLE_ASC: "title_asc",
  TITLE_DESC: "title_dsc",
  FULLNAME_ASC: "fullname_asc",
  FULLNAME_DESC: "fullname_dsc",
  DATE_ASC: "date_asc",
  DATE_DESC: "date_dsc",
  STATUS_ASC: "status_asc",
  STATUS_DESC: "status_dsc",
});

/**
 * Evaluation statuses
 *
 * @enum {string} evaluationStatus
 * @readonly
 * @property {string} ASSIGNED
 * @property {string} ENROLLED
 * @property {string} INCOMPLETE
 * @property {string} PENDING
 * @property {string} GRADED
 * @property {string} COMPLETE
 * @property {string} IN_PROGRESS (server status, not used on UI)
 */
export const evaluationStatus = {
  ASSIGNED: "ASSIGNED",
  COMPLETE: "COMPLETE",
  ENROLLED: "ENROLLED",
  INCOMPLETE: "INCOMPLETE",
  IN_PROGRESS: "IN_PROGRESS",
  NEW: "NEW",
  SCORED: "SCORED",
};

/**
 * Map of user-friendly names for evaluation statuses.
 *
 * Entries per {@link evaluationStatus}
 *
 * @constant evaluationStatusFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
export const evaluationStatusFriendly = new Map([
  [evaluationStatus.ASSIGNED, "Assigned"],
  [evaluationStatus.ENROLLED, "Enrolled"],
  [evaluationStatus.IN_PROGRESS, "In Progress"],
  [evaluationStatus.INCOMPLETE, "Closed"],
  [evaluationStatus.COMPLETE, "Completed"],
  [evaluationStatus.NEW, "New"],
  [evaluationStatus.SCORED, "Grade Available"],
]);

/**
 * Sort criteria applicable to assessment users. Used in searching, filtering, and
 * table column sorting.
 *
 * @enum {string} assessmentUserSortCriteria
 * @readonly
 * @property {string} STUDENT_ASC
 * @property {string} STUDENT_DESC
 * @property {string} EVAL_STATUS_ASC
 * @property {string} EVAL_STATUS_DESC
 */
export const assessmentUserSortCriteria = Object.freeze({
  STUDENT_ASC: "STUDENT_ASC",
  STUDENT_DESC: "STUDENT_DESC",
  EVAL_STATUS_ASC: "EVAL_STATUS_ASC",
  EVAL_STATUS_DESC: "EVAL_STATUS_DESC",
});

/**
 * Grading types
 *
 * @enum {string} gradingType
 * @readonly
 * @property {string} AUTO_GRADED
 * @property {string} MANUALLY_GRADED
 */
export const gradingType = Object.freeze({
  AUTO_GRADED: "AUTO_GRADED",
  MANUALLY_GRADED: "MANUALLY_GRADED",
});

/**
 * Map of user-friendly names for grading types.
 *
 * Entries per {@link gradingType}
 *
 * @constant gradingTypeFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
export const gradingTypeFriendly = Object.freeze(new Map([
  [gradingType.AUTO_GRADED, "Auto-Graded"],
  [gradingType.MANUALLY_GRADED, "Manually Graded"],
]));
