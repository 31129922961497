/**
 * API requests for document metadata.
 *
 * @module api/metadata/v2/document
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { makeDocumentSearchParams, responseToDocumentMetadata } from "model/metadata";
import {
  // del,
  get,
  // post,
  // put,
} from "api/request";

/**
 * Get document metadata by UUID.
 *
 * @function byId
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~DocumentMetadata}
 */
export const byId = async (uuid) => responseToDocumentMetadata(
  (await get(`${endpoints.META_DOCUMENT}/${uuid}`)).body,
);

/**
 * Get multiple document metadatas by id.
 *
 * @function byIds
 * @param {UUID[]} uuids
 * @returns {Promise.<DocumentMetadata[]>}
 */
export const byIds = async (uuids) => (await get(
  `${endpoints.META_DOCUMENT}/batch/?ids=${uuids.join(",")}`,
)).body.map(responseToDocumentMetadata);

/**
 * Search document metadata.
 *
 * @function searchDocuments
 * @param {object} partial matching {@link module:api/types/metadata.DocumentSearchParams}
 *
 * @return {DocumentMetadata[]}
 */
export const searchDocuments = async (partial) => ((await get(
  endpoints.META_DOCUMENT,
  makeDocumentSearchParams(partial),
)).body).map(responseToDocumentMetadata);

/**
 * List all existing document metadata objects.
 * @function list
 * @return {DocumentMetadata[]}
 */
export const list = async () => searchDocuments({ maxItems: 1000000 });

/**
 * Get a count of total search results for the given parameters.
 *
 * @function countDocuments
 * @return int
 */
export const countDocuments = async (params) => (await get(
  `${endpoints.META_DOCUMENT}/count`,
  makeDocumentSearchParams(params),
)).body;
