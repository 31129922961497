/**
 * A help modal dialog for question text length.
 *
 * @module ui/component/modal/help/question-text-length
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, li, ol, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function questionTextLengthHelpModal(skipCache = true, async = true) {
  return commonHelpModal({
    modalType: modalTypes.QUESTION_TEXT_LENGTH,
    skipCache,
    async,
    sel: ".help-modal-question-text",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-question-text.svg"),
            p("Question Text Length", ".title"),
            div(".text-container", [
              ol([
                li("Short Answers are answers under 250 characters.", ".lh-md"),
                li("Long Answers are answers over 250 characters.", ".lh-md"),
                li("Numerical values/equation inputs should be associated with Short Answers.", ".lh-md"),
              ]),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
