/**
 * Functions assisting with navigation.
 *
 * @module util/navigation
 * @category Utilities
 */
import cache from "cache";
import { CACHE_MODAL_KEY, CACHE_PLAYBACK_KEY_V2 } from "cache/constants";
import { frozen } from "util/object";
import hashmap from "util/hash-map";
import contentModal from "ui/component/modal/content";
import { getModal } from "ui/view/modal-view";
import { FORGOT_PASSWORD_PATH, LOGIN_PATH, REGISTER_PATH } from "../constants";
import { isAnonymous } from "./user";

/**
 * Redirect user to login page, with a redirect query parameter to return them to
 * the original page after login.
 *
 * @function doLoginRedirect
 */
export const doLoginRedirect = (toRegistration = false) => {
  const params = new URLSearchParams();
  cache.clear();
  // forces URL encoding
  params.set(
    "redirect",
    `${window.location.pathname}${window.location.search}`,
  );
  window.location.replace(`${toRegistration ? REGISTER_PATH : LOGIN_PATH}?${params.toString()}`);
};

/**
 * Redirect user to forgot password page
 *
 * @function doLoginRedirect
 */
export const doForgotPasswordRedirect = () => {
  cache.clear();
  window.location.replace(FORGOT_PASSWORD_PATH);
};

export const handleLoadingPageFail = (error, user) => {
  if (isAnonymous(user) && error.statusCode === 403) {
    doLoginRedirect();
  } else if (user) {
    window.location.replace("/403");
  } else {
    doLoginRedirect();
  }
};

/**
 * Utility function to transform query params into a key:value object.
 *
 * @function getQueryParams
 * @return {object} contains URL query params in the form `{ key: value, ... }`
 */
export const getQueryParams = () => {
  const params = {};
  const query = new URLSearchParams(window.location.search);
  [...query.entries()].forEach(([k, v]) => {
    params[k] = v;
  });
  return frozen(params);
};

/**
 * Gets the current page slug
 */
export const getSlug = () => {
  let slug = document.location.pathname.replace(/^\//, '');
  if (slug === "") slug = "home";
  return slug;
};

/**
 * Sets the page title displayed in the browser tab. For now all
 * this does is standardize the page title to `SmartEdge | Title`.
 *
 * It may later be extended for whitelabeling (a planned feature).
 *
 * @param {string} title
 */
export const setTitle = (title) => {
  document.title = title
    ? `SmartEdge | ${title}`
    : `SmartEdge`;
};

/**
 * Swaps the URL without reloading the page if the slug does not match
 * the current slug.
 *
 * Note that a route should be set in the frontend
 * server for any slug you plan to use here, or else the page will not
 * load if the user navigates to it directly.
 *
 * Also note this does nothing whatsoever if your page doesn't handle
 * pop states and routes - it's not a substitute for window.location.assign!
 *
 * @param {string} slug a url fragment
 * @param {string} [title] a page title
 */
export const reroute = (slug, title) => {
  if (getSlug() !== slug) window.history.pushState({}, "", `/${slug}`);
  setTitle(title);
};

/**
 * Reopens a previously opened content modal, if the user went 'back' from a video
 * player and the page they landed on is in the whitelist (defined in `src/lib/app.js`).
 *
 * @param {Map.<UUID, Metadata>} dictionary
 * @param {ModalView} modal
 */
export const reopenModal = () => {
  if (performance.getEntriesByType("navigation")[0].type === "back_forward") {
    const content = cache.getObject(CACHE_MODAL_KEY, null);
    if (content) {
      const playbackCache = cache.getExpiringMapEntry(
        CACHE_PLAYBACK_KEY_V2,
        cache.getProfile()?.id,
      );
      const positions = hashmap(playbackCache.positions || []);
      cache.storeValue(CACHE_MODAL_KEY, null);
      getModal().async(contentModal({
        metadata: content,
        playback: positions.get(content.id),
      }));
    }
  }
};

export const clearSearchResult = (page, defaultSearchParams) => {
  document.querySelector("[name=filter]").value = "";
  page.update({
    searchMode: null,
    searchFilter: null,
    searchParams: { ...defaultSearchParams },
  });
};

/**
 * Wrapper to handle not authorized redirects.
 *
 * Here so we can bootstrap other functionality into a 403 redirect later.
 */
export const notAuthorized = () => window.location.replace("/403");
