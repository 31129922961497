/**
 * Content for components pane on test page.
 *
 * @module ui/page/admin/test/buttons
 * @private
 */
/***/
import collapsible from "ui/component/collapsible";
import div from "ui/html/div";
import p from "ui/html/p";
import span from "ui/html/span";
import tooltipWrapper from "ui/component/tooltip/tooltip-wrapper";
import button from "ui/component/form-managed/button";
import progressBar from "ui/component/progress-bar";
import indicator from "ui/component/indicator";
import { tooltipModes, tooltipPositions } from "ui/component/tooltip/common";

export default () => div("#components", [
  collapsible({
    title: "Collapse 1",
    open: false,
  }, [
    p("foo"),
    p("bar"),
    p("baz"),
    collapsible({
      title: "Nested Collapsible",
    }, p("Collapception.")),
  ]),
  collapsible({
    title: "Collapse 2",
    open: false,
  }, [p("foo"), p("bar"), p("baz")]),
  collapsible({
    title: "Progress Bars",
    open: false,
  }, [
    progressBar({ min: 0, max: 100, value: 10 }),
    progressBar({ min: 0, max: 100, value: 51 }),
    progressBar({ min: 0, max: 100, value: 77 }),
    progressBar({ min: 0, max: 100, value: 10, sel: ".ok" }),
    progressBar({ min: 0, max: 100, value: 51, sel: ".warning" }),
    progressBar({ min: 0, max: 100, value: 77, sel: ".danger" }),
  ]),
  collapsible({
    title: "Indicators",
    open: false,
  }, [
    div(".indicators", [
      indicator(),
      span(" "),
      indicator({ sel: ".primary" }),
      span(" "),
      indicator({ sel: ".ok" }),
      span(" "),
      indicator({ sel: ".warning" }),
      span(" "),
      indicator({ sel: ".danger" }),
      span(" "),
      indicator({ sel: ".alternate" }),
    ]),
  ]),
  collapsible({
    title: "Tooltip",
    open: false,
  }, [
    tooltipWrapper({
      content: "This is a simple tooltip",
      children: button.standIn({ label: "Hover me", sel: ".inline" }),
      position: tooltipPositions.RIGHT,
    }),
    p(),
    tooltipWrapper({
      content: "This is a simple tooltip",
      children: button.standIn({ label: "Click anywhere", sel: ".inline" }),
      position: tooltipPositions.RIGHT,
      mode: tooltipModes.CLICK,
    }),
  ]),
  // TODO other misc. components
]);
