/**
 * Helper functions for the login page.
 *
 * @module ui/page/login/common
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import { reroute } from "util/navigation";
import { MODES } from "./state";

export const focus = (page) => page.element.querySelector("input").focus();

/**
 * Sets focus on the last autofocus element in the form.
 *
 * @function setFocus
 * @private
 */
export const setFocus = (page) => {
  // using querySelectorAll().forEach here ensures no error
  // if there is no autofocus, without creating a branch
  page.element.querySelectorAll("[autofocus]").forEach((elm) => elm.focus());
};

export const initLogin = (self, login = {}) => {
  reroute("login", "Login");
  self.setFullValidation(false);
  self.update({ mode: MODES.login, login });
  focus(self);
};

export const initPasswordResetUsername = (self) => {
  reroute("forgot-password", "Forgot Password");
  self.setFullValidation(false);
  self.update({ mode: MODES.passwordResetUsername });
  focus(self);
};

export const initPasswordReset = (self) => {
  reroute("password-reset", "Password Reset");
  self.setFullValidation(false);
  self.update({ mode: MODES.passwordReset });
  focus(self);
};

export const initRegister = (self) => {
  reroute("register", "Registration");
  self.setFullValidation(false);
  self.update({ mode: MODES.register });
  focus(self);
};

export const initVerification = (self, username) => {
  reroute("verify", "Verify Account");
  self.setFullValidation(false);
  self.update({ mode: MODES.verify, verification: { username } });
  focus(self);
};
