import {
  validArray,
  validInt,
  validString,
  validSearchCriteria,
  validSortCriteria,
} from "model/constraints";
import { responseToFileDescriptor } from "model/file-descriptor";
import { responseToCategory } from "model/metadata";
import { getImageFileURL } from "model/metadata/url";
import * as url from "model/metadata/url";
import { maybeDate } from "util/date";

export const makeAbstractSearchParams = (partial) => {
  const out = { firstItem: 0 };
  if (partial.terms) out.terms = validArray(partial.terms, "SearchParams.terms", validString);
  if (partial.termsCriteria) out.termsCriteria = validSearchCriteria(partial.termsCriteria, "SearchParams.termsCriteria");
  if (partial.categoriesCriteria) out.categoriesCriteria = validSearchCriteria(partial.categoriesCriteria, "SearchParams.categoriesCriteria");
  if (partial.categories) out.categories = validArray(partial.categories, "SearchParams.categories", validString);
  else if (partial.labels) out.categories = validArray(partial.labels, "SearchParams.labels", validString);

  if (partial.sortCriteriaList) out.sortCriteriaList = validArray(partial.sortCriteriaList, "SearchParams.sortCriteriaList", validSortCriteria);
  if (partial.maxItems) out.maxItems = validInt(partial.maxItems, "SearchParams.maxItems");

  return out;
};

export const makeAbstractItem = (partial) => ({
  id: partial.id || null,
  documentFile: partial.documentFile || null,
  videoFile: partial.videoFile || null,
  posterFile: partial.posterFile || null,
  posterUrl: partial.posterFile.id
    ? url.getImageFileURL(partial.id)
    : null,
  getPosterUrl: partial.posterFile.id
    ? () => url.getImageFileURL(partial.id)
    : null,
  title: partial.title || "",
  description: partial.description || "",
  categories: partial.categories || [],
  type: partial.type || "UNKNOWN",
  createdAt: partial.createdAt || new Date(),
  startDate: partial.startDate || null,
  endDate: partial.endDate || null,
  ABSTRACT: true,
});

export const responseToAbstractItem = (data) => {
  const posterFile = responseToFileDescriptor(data.posterFile || {});
  const documentFile = data.documentFile ? responseToFileDescriptor(data.documentFile) : undefined;
  const videoFile = data.videoFile ? responseToFileDescriptor(data.videoFile) : undefined;

  return {
    id: data.id || null,
    posterFile,
    posterUrl: getImageFileURL(data.id),
    getPosterUrl: () => getImageFileURL(data.id),
    title: data.title || "",
    description: data.description || "",
    documentFile,
    videoFile,
    fileUrl: url.getDocumentFileURL(data.id),
    getFileUrl: () => url.getDocumentFileURL(data.id),
    categories: data.categories?.map(responseToCategory) || [],
    type: data.type,
    createdAt: maybeDate(data.createdAt) || null,
    startDate: maybeDate(data.startDate) || maybeDate(data.startDateTime) || null,
    endDate: maybeDate(data.endDate) || maybeDate(data.endDateTime) || null,
  };
};
