/**
 * Application-wide constants.
 *
 * @module constants
 * @category Constants
 */

/**
 * Pages that may automatically open a content modal dialog when returning from
 * video player.
 * @constant
 */
export const MODAL_REOPEN_WHITELIST = [
  '',
  '/',
  '/index.html',
  '/search.html',
  '/video.html',
  '/search',
  '/see-all',
  '/video',
];

/**
 * Path to the login page.
 * @todo a general list of paths
 * @constant
 */
export const LOGIN_PATH = "/login";

/**
 * Path to the register page.
 * @constant
 */
export const REGISTER_PATH = "/register";

/**
 * Path to the forgot password page.
 * @constant
 */
export const FORGOT_PASSWORD_PATH = "/forgot-password";

/**
 * Pages that don't require authentication.
 * @constant
 */
export const LOGIN_WHITELIST = new Set([
  LOGIN_PATH,
  REGISTER_PATH,
  FORGOT_PASSWORD_PATH,
  "/verify",
  "/tos",
  "/privacy",
  "/tos.html",
  "/privacy.html",
]);
