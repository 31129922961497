/**
 * Functions for UI customization.
 *
 * @module util/ui-customization
 * @category Utilities
 */
import api from "api";
import { paletteModes } from "model/site/constants";

let BRANDING_LOGO_URL;

let BRANDING_ICONS_URL;

export const getBrandingLogoUrl = () => BRANDING_LOGO_URL;

export const getBrandingIconsUrl = () => BRANDING_ICONS_URL;

/**
 * Apply the list of colors to the UI.
 *
 * @param {object} colors
 * @param {string} colors[key] color string for the given color key
 */
export const updateColorPalette = (colors) => {
  Object.keys(colors).forEach((color) => {
    document.documentElement.style.setProperty(`--${color}`, colors[color]);
  });
  if (colors["fg-primary"]) {
    if (!document.querySelector("meta[name=theme-color]")) {
      const meta = document.createElement("META");
      meta.name = "theme-color";
      document.querySelector("head").appendChild(meta);
    }
    document.querySelector("meta[name=theme-color]")
      .setAttribute("content", colors["fg-primary"]);
  }
};

/**
 * Refresh the color palette from API and apply to the UI
 *
 * @returns {Promise<void>}
 */
// eslint-disable-next-line import/prefer-default-export
export const applyColorPalette = async () => {
  const colorPalette = await api.site.getColorPalette();
  const { colors } = colorPalette;
  if (!colors) {
    return;
  }
  if (colorPalette.mode === paletteModes.DARK) document.body.classList.add("dark-mode");
  if (colorPalette.mode === paletteModes.LIGHT) document.body.classList.remove("dark-mode");
  updateColorPalette(colors);
};

export const applyBranding = async () => {
  const branding = await api.site.getBranding();
  BRANDING_LOGO_URL = branding.images?.logoFile?.url;
  BRANDING_ICONS_URL = branding.images?.iconsFile?.url;
  if (BRANDING_ICONS_URL) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = BRANDING_ICONS_URL;
  }
};
