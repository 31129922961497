import div from "ui/html/div";
import h3 from "ui/html/h3";
import span from "ui/html/span";
import spinner from "ui/component/spinner";

export default function contentList(state) {
  return div(
    "#content.block",
    [
      h3("Content"),
      span('Videos', '.label'),
      span(state.videoCount === null ? spinner() : state.videoCount, '.value'),
      span('Documents', '.label'),
      span(state.documentCount === null ? spinner() : state.documentCount, '.value'),
      span('Lists', '.label'),
      span(state.playlistCount === null ? spinner() : state.playlistCount, '.value'),
      span('Categories', '.label'),
      span(state.labelCount === null ? spinner() : state.labelCount, '.value'),
      span('Pages', '.label'),
      span(state.pageCount === null ? spinner() : state.pageCount, '.value'),
    ],
  );
}
