import { videoMetadataList, documentMetadataList } from "api/mocks/metadata";
import { userList } from "api/mocks/user";

export const lectureMock = {
  id: "FAKE_ID_1",
  title: "Lecture 1",
  videoMetadata: videoMetadataList[0],
  poster: null,
  slides: documentMetadataList[0],
  additionalMaterial: [
    ...documentMetadataList,
  ],
  instructor: userList[0],
};

export const createLectureMock = {
  ...lectureMock,
  id: "NEW_FAKE_LECTURE",
  title: "New lecture",
};

export const lecturesMock = [
  {
    ...lectureMock,
    id: "FAKE_ID_1",
    title: "Lecture 1",
    videoMetadata: videoMetadataList[0],
    slides: documentMetadataList[0],
    instructor: userList[0],
  },
  {
    ...lectureMock,
    id: "FAKE_ID_2",
    title: "Lecture 2",
    videoMetadata: videoMetadataList[1],
    slides: documentMetadataList[1],
    instructor: userList[1],
  },
  {
    ...lectureMock,
    id: "FAKE_ID_3",
    title: "Lecture 3",
    videoMetadata: videoMetadataList[2],
    slides: documentMetadataList[2],
    instructor: userList[2],
  },
  {
    ...lectureMock,
    id: "FAKE_ID_4",
    title: "Lecture 4",
    videoMetadata: videoMetadataList[3],
    slides: documentMetadataList[3],
    instructor: userList[3],
  },
];
