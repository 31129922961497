/**
 * A form field for selecting user groups.
 *
 * Relative URLs require a leading slash.
 *
 * @module ui/component/form-managed/field-user-group-select
 * @category UI
 * @subcategory Forms
 */
import input from "ui/html/input";
import cameo from "ui/component/cameo";
import genericField from "ui/component/form-managed/field-generic";
import button from "ui/component/form-managed/button";
import { makeInputBindings } from "ui/component/form-managed/field-input";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "off",
  autofocus: false,
  constraints: null,
  disabled: false,
  label: "Generic Text",
  name: "generic-text",
  onChange: null,
  onSelectGroup: () => {},
  onInput: null,
  required: false,
  sel: "",
  type: "text",
  value: [],
  listId: null,
  validHooks: [],
});

const getValue = (node) => node.elm.value?.split(", ");

/**
 * A field for selecting a number of groups to add to a user record.
 *
 * Used by mass user input form. Requires an onSelectGroup callback, which
 * should open a group select dialog and do something with the result.
 *
 * Mostly one-way, since this field doesn't manage groups or handle its own selector.
 *
 * @param {object} state
 * @param {string} [state.autocomplete="off"] autocomplete type
 * @param {string} [state.autofocus=false] autofocus attribute state
 * @param {?object} state.constraints
 * @param {boolean} [state.disabled=false]
 * @param {string} [state.label="Group Names"]
 * @param {FieldValidity} [state.validity=defaultValidity]
 * @param {string} [state.name="groupNames"]
 * @param {boolean} [state.required=false]
 * @param {Selector} [state.sel=""]
 * @param {?string[]} state.taken
 * @param {string} [state.type="text"]
 * @param {string} [state.value=[]] list of group names
 * @param {string} [state.override=""] override field value with given state.value
 * @param {function} onChange called on blur when field has changed
 * @param {function} onInput called on keyup
*
 */
export default function userGroupSelect(inState = {}) {
  const state = merge(defaultState, inState);

  const value = state.override
    ? state.value || state.values?.[state.name] || []
    : state.values?.[state.name] || state.value || [];

  const inner = managedField(
    input(
      "hidden",
      value?.join?.(", "),
      state.required,
      state.name,
      state.constraints || {},
      makeInputBindings(state),
    ),
    state.name,
    getValue,
    state.validHooks,
  );

  return genericField(
    state,
    [
      inner,
      cameo({
        label: value?.length || 0,
        controls: [
          button({
            icon: "edit",
            iconOnly: true,
            onClick: () => state.onSelectGroup(),
            disabled: state.disabled,
          }),
        ],
        sel: ".groups-cameo",
      }),
    ],
    `${state.sel}.user-group-select`,
  );
}
