/**
 * A modal dialog for showing notifications.
 *
 * @module ui/component/modal/notifications
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  button, div, h3, span,
} from "ui/html";
import { emit } from "util/event";
import dialog from "ui/component/modal/layout/dialog";

const dismissNotifications = (view) => {
  emit(document.body, "em:dismiss-notifications", view);
  view.close();
};

const makeNotificationItems = (notifications) => notifications.map((notification) => div(".notification-item", [
  span(new Date(notification.created).toLocaleString(), ".created-at"),
  span(notification.text, ".message"),
]));

const createList = (notifications) => {
  if (!notifications.length) {
    return div(".notification-dropdown", [
      h3("No Announcements", ".label-empty"),
    ]);
  }
  return div(".notification-dropdown", [
    ...makeNotificationItems(notifications),
  ]);
};

export default function notificationsModal(view, notifications = []) {
  return dialog({
    sel: `.notifications${!notifications.length ? ".empty" : ""}`,
    header: notifications.length ? "All Announcements" : null,
    body: createList(notifications),
    footer: button("Dismiss", () => dismissNotifications(view), ".warn"),
    footerSpacer: true,
    isAsync: false,
  }, view);
}
