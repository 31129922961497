/**
 * A help modal dialog for evaluation advanced search
 *
 * @module ui/component/modal/help/evaluation-advanced-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import { getModal } from "ui/view/modal-view";
import slider from "ui/component/slider";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function evaluationAdvancedSearchHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.EVALUATION_ADVANCED_SEARCH,
    skipCache,
    async,
    sel: ".help-modal-evaluation-advanced-search",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-evaluation-advanced-search.svg"),
            p("Evaluation Advanced Search", ".title"),
            div(".text-container", [
              p("Student initiated the evaluation but never submitted progress"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-evaluation-advanced-search.svg"),
            p("Evaluation Advanced Search", ".title"),
            div(".text-container", [
              p("Student submitted progress but did not complete all questions"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-evaluation-advanced-search.svg"),
            p("Evaluation Advanced Search", ".title"),
            div(".text-container", [
              p("Student completed all questions but did not submit finalization"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-evaluation-advanced-search.svg"),
            p("Evaluation Advanced Search", ".title"),
            div(".text-container", [
              p("Student ran out of time while answering questions"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-evaluation-advanced-search.svg"),
            p("Evaluation Advanced Search", ".title"),
            div(".text-container", [
              p("Student initiated evaluation and still has time remaining"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
