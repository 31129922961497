/**
 * Shared default state and content for user pages.
 * @module ui/page/admin/user/state
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import { sortOrders } from "ui/component/dynamic-table";
import { frozen } from "util/object";

export const defaultSearchParams = frozen({
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  cellPhone: null,
  sortField: null,
  sortOrder: null,
  filter: "",
});

export const manageModes = frozen({
  MANAGE: "MANAGE",
  APPROVE: "APPROVE",
});

export const manageModeTabIndex = frozen(new Map([
  [manageModes.MANAGE, 0],
  [manageModes.APPROVE, 1],
]));

export const searchModes = frozen({
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
  NONE: null,
});

export const defaultManageState = frozen({
  searchParams: { ...defaultSearchParams },
  config: {},
  users: [],
  messages: [],
  filteredUsers: [],
  unapprovedUsers: [],
  searchMode: searchModes.NONE,
  sortColumn: "username",
  sortOrder: sortOrders.ASC,
  mode: manageModes.MANAGE,
});
