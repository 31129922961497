import h4 from "ui/html/h4";
import collapsible from "ui/component/collapsible";
import radio from "ui/component/form-managed/field-radio";

export default function toggles(self) {
  const required = true;

  return collapsible({
    title: "Radio Selectors",
    open: self.state.radioOpen,
    onChange: (radioOpen) => self.updateState({ radioOpen }),
  }, self.bind([
    h4("Required"),
    [radio, {
      name: "radio",
      onChange: (values) => console.log("radio values", values),
      entries: [
        { label: "Primary", value: "1", sel: ".primary" },
        { label: "Alternate", value: "2", sel: ".alternate" },
        { label: "Secondary", value: "secondary", sel: ".secondary" },
        { label: "Warning", value: "warning", sel: ".warn" },
        { label: "Danger", value: "danger", sel: ".danger" },
      ],
      required,
    }],
    h4("Boxed Radio"),
    [radio.boxed, {
      name: "boxed-radio",
      onChange: (values) => console.log("radio values", values),
      entries: [
        { label: "Primary", value: "1", sel: ".primary" },
        { label: "Alternate", value: "2", sel: ".alternate" },
        { label: "Secondary", value: "secondary", sel: ".secondary" },
        { label: "Warning", value: "warning", sel: ".warn" },
        { label: "Danger", value: "danger", sel: ".danger" },
      ],
      required,
    }],
  ], { radio: "1", "boxed-radio": "2" }),
  );
}
