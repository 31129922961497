/**
 * v2 API endpoints for courses.
 *
 * @module api/v2/course
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import { responseToCourse } from "model/course";
import log from "log";

/**
 * Get course by id
 *
 * @function byId
 * @private
 * @param {UUID} id
 * @returns {Promise.<Course>}
 */
export const byId = async (id) => responseToCourse((await get(endpoints.COURSE(id))).body);

/**
 * Get course list by ids
 *
 * @function byIds
 * @private
 * @param {UUID[]} ids
 * @returns {Promise.<Course[]>}
 */
export const byIds = async (ids) => Promise.all(ids.map(byId));

export const search = async (/* searchParams */) => {
  try {
    const courses = (await get(endpoints.COURSES)).body;
    return courses.map(responseToCourse);
  } catch (e) {
    log.error(e, "API :: COURSE : error searching courses");
    return [];
  }
};
