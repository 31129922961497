/**
 * An alert dialog body for modals.
 *
 * @module ui/component/modal/alert
 * @category UI
 * @subcategory Modal Dialogs
 */
import { button, p } from "ui/html";
import dialog from "ui/component/modal/layout/dialog";

const andClose = (view, cb, isAsync) => async () => {
  if (view && !isAsync) {
    cb(view);
    view.back();
  } else {
    await cb();
  }
};

const danger = (dangerous) => (dangerous ? ".danger" : "");

/**
 * This should normally be launched from `ModalView.confirm()`, which hooks
 * up the view automatically. When `view` parameter is provided the dialog will
 * self-close after executing the appropriate callback function.
 *
 * @param {Object} config
 * @param {string|null} [config.title=null] the dialog title
 * @param {string|null} [config.body=null] the dialog body
 * @param {string} [config.confirmLabel="Ok"] the text for the confirm button
 * @param {function} [config.onConfirm] a callback function for when the dialog is confirmed
 * @param {module:ui/view/modal-view~ModalView} view
 * @param {string} sel additional css selectors for the dialog wrapper
 *
 * @return {module:ui/common/el~El}
 */
export default function alert({
  title = null,
  body = null,
  confirmLabel = "Ok",
  isAsync = false,
  onConfirm = () => {},
  dangerous = false,
  sel = "",
}, modalView) {
  return dialog({
    sel: `.dialog.confirm${danger(dangerous)}${sel}`,
    header: title,
    body: body ? p(body) : null,
    footer: button(
      confirmLabel,
      andClose(modalView, onConfirm, isAsync),
      danger(dangerous),
    ),
    isAsync,
    withCloseFab: false,
  }, modalView);

  /*
  div(
  [
    (title ? div(".header", title) : ""),
    (body ? div(".body", p(body)) : ""),
    div(".footer", [
    ]),
  ],
  */
}
