/**
 * A loading spinner/interstitial.
 *
 * The loading view is set up automatically by the
 * [layout]{@link module:ui/page/page-utils~layout} helper function, so it shouldn't need to
 * be initialized by a page module.
 *
 * @module ui/view/loading-view
 * @category UI
 * @subcategory Views
 */
import baseView from "./view";
import loading from "../component/loading";

/**
 * A loading view. Borrows from the generic view, and augments with convenience functions
 * for showing and hiding the loading overlay.
 *
 * @typedef {object} LodingView
 * @borrows {module:ui/view/view~View}
 * @property {function} show see {@link module:ui/view/loading-view~show}
 * @property {function} hide see {@link module:ui/view/loading-view~hide}
 */

function bootstrapView(self) {
  /* eslint-disable no-param-reassign */

  /**
   * Shows the loading overlay, optionally with a message.
   *
   * @function show
   * @param {string} message
   */
  self.show = (message = "") => {
    self.update({ enabled: true, message });
  };

  /**
   * Hides the loading overlay, clearing the previous message if it was set.
   *
   * @function hide
   */
  self.hide = () => {
    self.update({ enabled: false, message: "" });
  };
  /* eslint-enable no-param-reassign */

  return self;
}

/**
 * Loading has a static updateFn callback.
 *
 * Side-effect: toggles the class 'loading' on the body element
 */
const updateFn = (self) => {
  self.patch(loading(self));
  if (self.state.enabled) {
    document.body.classList.add("em-loading");
  } else {
    document.body.classList.remove("em-loading");
  }
};

/**
 * A loading spinner/interstitial. Adds `show()` and `hide()` convenience methods,
 * and wraps the `loading` component.
 *
 * This is a {module:ui/view/view~ViewInitializer}, not a factory for the same. It
 * manages its own internal component factory and update callback.
 *
 * @function loadingView
 * @param {module:ui/html~Selector} selector
 * @param {object} [initState={}]
 * @param {boolean} [initState.enabled=false] whether the overlay should be shown
 * @return {module:ui/view/loading~LoadingView}
 */
export default (selector, initState = { enabled: false }) => {
  const self = bootstrapView(baseView(selector, initState, loading, updateFn));
  self.update(initState);
  return self;
};
