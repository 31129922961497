/**
 * A wrapper for a help modal dialog.
 *
 * @module ui/component/modal/help/common-help
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import dialog from "ui/component/modal/layout/dialog";
import { getModal } from "ui/view/modal-view";
import { isHelpModalSeen, markModalAsSeen } from "./utils";

const confirm = (async, modalType) => {
  markModalAsSeen(modalType);
  if (!async) getModal().back();
};

export default function commonHelpModal({
  modalType = null,
  skipCache = false,
  title = "",
  async = true,
  sel = "",
}, children = []) {
  if (isHelpModalSeen(modalType) && !skipCache) {
    // fix race condition where page is left in modal-open state
    setTimeout(() => getModal().back(), 1);
    return [];
  }
  return dialog({
    sel: `.common-faq${sel}`,
    header: title,
    body: children,
    isAsync: async,
    onClose: () => confirm(async, modalType),
  }, getModal());
}
