/**
 * The student assessment score page.
 *
 * @module ui/page/score
 * @category Pages
 * @subcategory Courses
 */
import api from "api";
import { pageVisit } from "api/v2/analytics";
import { featureTypes } from "model/config/constants";
import log from "log";
import {
  gradingType,
  gradingTypeFriendly,
  questionType,
  questionTypeFriendly,
} from "model/course/constants";
import { div, h2, main, span } from "ui/html";
import collapsible from "ui/component/collapsible";
import view from "ui/view";
import { usFullDateFormat } from "util/date";
import userLayout from "ui/page/layout/user";
import { getQueryParams, setTitle } from "util/navigation";
import { frozen, merge } from "util/object";
import { calculateScore } from "util/course";
import { guard } from "util/feature-flag";
import cameoFile from "ui/component/cameo-file";
import cameoAnswer from "ui/component/cameo-answer";

let modalView;
let pageView;
let loadingView;

const fullName = (student) => `${student.firstName} ${student.lastName}`;

const handleErrorCode = (e) => {
  log.error(e, e.message);
  switch (e.statusCode) {
    case 406:
      return modalView.alert("Closed", "Your evaluation is not graded yet. Please try again later.");
    case 403:
      return modalView.alert("Not Enrolled", "You are not authorized to view this score.");
    case 404:
      return modalView.alert("Not Found", "No matching evaluation was found.");
    default:
      return modalView.alert("Error", "An unspecified error occured while loading your score. Please try again later.", "Ok", true);
  }
};

const defaultState = frozen({
  user: null,
  evaluation: null,
  uiConfig: null,
});

const getState = async () => {
  const params = getQueryParams();
  let user, evaluation, uiConfig;

  try {
    [user, evaluation, uiConfig] = await Promise.all([
      api.user.getMe(),
      api.course.getEvaluationById(params.courseId, params.evaluationId),
      api.site.getUIConfiguration(),
    ]);
  } catch (e) {
    await handleErrorCode(e);
    return null;
  }

  const files = new Map();
  try {
    const fileIds = evaluation.solutions
      .filter((solution) => solution.question.questionType === questionType.UPLOAD)
      .flatMap((solution) => solution.value)
      .filter((solution) => solution);
    const response = await Promise.all(fileIds.map(api.file.getById));
    response.forEach((file) => files.set(file.id, file));
  } catch (e) {
    await handleErrorCode(e);
  }

  const state = merge(defaultState, {
    user,
    evaluation,
    uiConfig,
    files,
  });

  return state;
};

const makeTitle = (text, score) => span([
  span(text, ".question-text"),
  span(score, ".question-score"),
], ".question-title");

const buildFeedback = (text) => {
  switch (text) {
    case gradingType.AUTO_GRADED:
      return [
        div(".key-value-container", [
          span("Grading", ".label"),
          span(gradingTypeFriendly.get(gradingType.AUTO_GRADED), ".value"),
        ]),
      ];
    case "Initiated":
    case undefined:
    case "":
    case null:
      return [];
    default:
      return [
        div(".key-value-container", [
          span("Grading", ".label"),
          span(gradingTypeFriendly.get(gradingType.MANUALLY_GRADED), ".value"),
        ]),
        div(".key-value-container.wrap", [
          span("Feedback", ".label"),
          span(text, ".value"),
        ]),
      ];
  }
};

const buildAnswers = (answers, question, files) => {
  switch (question.questionType) {
    case questionType.FILL_IN_LONG:
    case questionType.FILL_IN_SHORT:
      return [span(answers[0], ".value")];
    case questionType.UPLOAD:
      return answers.map((fileId) => cameoFile({
        file: files.get(fileId),
        downloadable: true,
      }));
    case questionType.MULTIPLE_CHOICE_SINGLE_ANSWER:
    case questionType.MULTIPLE_CHOICE_MULTI_ANSWER:
    default:
      return question.answers.map((answer) => cameoAnswer({
        answer,
        existingAnswers: question.answers,
        viewOnly: true,
        highlighted: answers.some(
          (userValue) => userValue.toLowerCase() === answer.value.toLowerCase(),
        ),
      }, modalView));
  }
};

const buildSolution = (solution, files) => collapsible(
  { title: makeTitle(solution.question.text, solution.score?.points), open: false },
  div(".answer-content", [
    div(".container-question", [
      h2("Question"),
      div(".key-value-container.wrap", [
        span("Question", ".label"),
        span(solution.question.text, ".value"),
      ]),
      div(".key-value-container", [
        span("Question Type", ".label"),
        span(questionTypeFriendly.get(solution.question.questionType), ".value"),
      ]),
      div(".key-value-container.wrap", [
        span("Answer", ".label"),
        ...buildAnswers(solution.value, solution.question, files),
      ]),
    ]),
    div(".divider-container", [
      div(".divider"),
    ]),
    div(".container-answer", [
      h2("Answer"),
      div(".key-value-container", [
        span("Score Breakdown", ".label"),
        span([
          span(solution.score?.points, ".earned"),
          "/",
          span(solution.question.weight, ".possible"),
        ], ".value.points"),
      ]),
      ...buildFeedback(solution.score.description),
    ]),
  ]),
);

const buildOverview = (state) => {
  const { evaluation } = state;
  const { assessment, course } = evaluation;
  return [
    div(".container-overview", [
      div(".profile", [
        h2("Assessment Information"),
        span(fullName(evaluation.student), ".username"),
        div(".key-value-container", [
          span("Start Date", ".label"),
          span(assessment.startDate ? usFullDateFormat(assessment.startDate) : "No start date", ".value"),
        ]),
        div(".key-value-container", [
          span("Stop Date", ".label"),
          span(assessment.endDate ? usFullDateFormat(assessment.endDate) : "No end date", ".value"),
        ]),
        div(".key-value-container", [
          span("Grading Scheme", ".label"),
          span(course.gradingScheme?.description, ".value"),
        ]),
        div(".key-value-container.wrap", [
          span("Description", ".label"),
          span(evaluation.assessment.description, ".value"),
        ]),
      ]),

      div(".divider-container", [
        div(".divider"),
      ]),

      div(".info", [
        h2("Score"),
        div(".key-value-container.grade", [
          span("Grade", ".label"),
          span(calculateScore(
            course.gradingScheme,
            evaluation.score.points,
            assessment.maxPoints,
          ), ".value"),
        ]),
        div(".key-value-container", [
          span("Score", ".label"),
          span([
            span(evaluation.score?.points, ".earned"),
            "/",
            span(assessment.maxPoints, ".possible"),
          ], ".value.points"),
        ]),
      ]),
    ]),
  ];
};

const showScore = (theView) => {
  const { state } = theView;
  const { evaluation, files } = state;
  if (!evaluation.score) {
    // sometimes the evaluation gets saved and depublished
    loadingView.show();
    modalView.alert(
      "Incomplete",
      "Your score is still pending. Please try again later.",
    ).then(() => window.history.back());
  }
  return div("#score", [
    collapsible({
      title: "Overview",
      open: true,
    }, buildOverview(state), ".big"),
    collapsible({
      title: "Evaluation Details",
      open: true,
    }, [
      div(
        ".details",
        div(".answers", evaluation.solutions.map((solution) => buildSolution(solution, files))),
      ),
    ], ".big"),
  ]);
};

export default async function studentAssessment(selector) {
  guard(featureTypes.LMS);
  let title = "Evaluation Score";
  const { header, modal, loading } = userLayout(
    selector,
    [
      main("", [
        div("#score"),
      ]),
    ],
    title,
  );
  modalView = modal;
  loadingView = loading;
  loading.show("Calculating your score...");
  pageVisit(window.location.pathname);

  const state = await getState();

  title = `${state.evaluation.assessment.title} Score`;

  if (state === null) return;

  setTitle(title);
  header.update({ user: state.user, title, horizontal: state.uiConfig.horizontalMenu });

  pageView = view.create(showScore)("#score", state);
  // trigger an update so validation runs
  if (state.evaluation) pageView.update(state);

  loading.hide();
}
