/**
 * A form field for non-zero integers.
 *
 * @module ui/component/form-managed/field-non-zero-integer
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { nonZeroIntegerConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "Please enter a whole number greater than zero.",
  invalid: "Please enter a whole number greater than zero.",
});

/**
 * @private
 */
const defaultState = frozen({
  constraints: nonZeroIntegerConstraints,
  helpTexts,
  label: "Non-Zero Number",
  name: "field-non-zero-number",
  sel: "",
  taken: null,
});

/**
 * A form field for numbers.
 *
 * @function number
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="cellPhone"]
 * @param {string} [label="Cell Phone"]
 * @param {Selector} [sel=""]
 * @param {?string[]} taken
 * @return {module:ui/common/el~El}
 */
export default function number(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.non-zero-integer`,
  });
}
