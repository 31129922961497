/**
 * A help modal dialog for add home page.
 *
 * @module ui/component/modal/help/home
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, li, ol, p } from "ui/html";
import slider from "ui/component/slider";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function homeHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.HOMEPAGE,
    skipCache,
    async,
    sel: ".help-modal-home",
  }, [
    slider({
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".img-lg", "/media/modal-help-home-slide-1.png"),
            div(".text-container", [
              p("Welcome to SmartEdge", ".spaced-bottom"),
              p("You can find all your course content ready to stream on-demand."),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".img-lg", "/media/modal-help-home-slide-2.png"),
            div(".text-container", [
              ol([
                li("Select your course", ".spaced-bottom-sm"),
                li("Choose the content you would like to view/stream", ".spaced-bottom-sm"),
                li(`Enjoy the SmartEdge Experience! Feel free to use the search bar at the top of the page
          to handle your Search needs.`),
              ], ".spaced-left-sm"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".img-lg", "/media/modal-help-home-slide-3.jpg"),
            div(".text-container", [
              p("For more help, please visit the FAQ."),
              p("On behalf of the SmartEdge team, happy learning!"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
