/**
 * A canvas element.
 *
 * @module ui/html/canvas
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * Canvas factory.
 *
 * @param {string} sel CSS Selector
 * @return {module:ui/common/el~El}
 */
const canvas = (sel = "") => el(`canvas${sel}`);

export default canvas;
