/**
 * Extension for tiptap enabling SmartEdge color palette selection.
 */
import {
  Mark,
  Extension,
  mergeAttributes,
} from "@tiptap/core";

export const colors = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  THIRD: "tertiary",
  WARNING: "warning",
  ACCENT: "accent",
  DEFAULT: null,
};

export const PaletteColor = Extension.create({
  name: "paletteColor",

  defaultOptions: {
    paletteColors: Object.values(colors),
    defaultPaletteColor: colors.DEFAULT,
  },

  addGlobalAttributes() {
    return [{
      types: ["paletteSpan"],
      attributes: {
        paletteColor: {
          default: colors.DEFAULT,

          renderHTML: (attributes) => {
            if (!attributes.paletteColor) return {};

            return {
              class: [attributes.paletteColor],
            };
          },
        },
      },
    }];
  },

  addCommands() {
    return {
      setPaletteColor: ({ paletteColor }) => ({ chain }) => {
        if (!this.options.paletteColors.includes(paletteColor)) {
          return false;
        }

        return chain().setMark("paletteSpan", { paletteColor }).run();
      },
    };
  },
});

export const PaletteSpan = Mark.create({
  name: "paletteSpan",

  defaultOptions: {
    paletteColors: [...Object.values(colors)],
    defaultPaletteColor: colors.DEFAULT,
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (element) => {
          const paletteColor = [...element.classList.values()]
            .find((c) => this.options.paletteColors.includes(c));
          if (paletteColor) return ({ paletteColor });
          return false;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = { class: ["palette"] };
    return ["span", mergeAttributes(HTMLAttributes, attrs), 0];
  },
});
