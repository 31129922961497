/**
 * Builds a dynamic grid display for dynamic pages.
 *
 * @module ui/page/dynamic/section-dynamic-grid
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import h2 from "ui/html/h2";
import p from "ui/html/p";
import section from "ui/html/section";
import cardGrid from "ui/component/card-grid";
import placeholder from "ui/component/placeholder";
import { upgradeMetadataItem } from "./common";

const makeSectionId = (id) => `page-section-dynamic-grid-${id}`;

/**
 * A grid display for a dynamic page section.
 *
 * @function sectionDynamicGrid
 * @param {module:model/dynamic-page~PageSection} section of type `DYNAMIC_LIST_GRID`
 * @param {object} results search results for a dynamic page section
 * @param {object} results.list list content from search results
 * @param {module:model/metadata~MetadataItem[]} results.list.items
 * @return {module:ui/common/el~El} section element
 */
export default function sectionDynamicGrid(state, sectionId) {
  const pageSection = state.sections.get(sectionId);
  if (!pageSection) return "";

  const results = state.searchResults.get([pageSection.searchData, 0]);
  if (!results?.items) {
    return section(".content-grid.playlist-grid", [
      h2(placeholder()),
      cardGrid.placeholder(".playlist-grid"),
    ]);
  }
  if (results?.items?.length) {
    const items = results.items.map(upgradeMetadataItem(state));

    return section(".content-grid.playlist-grid", { key: makeSectionId(sectionId) }, [
      pageSection.title ? h2(pageSection.title) : "",
      cardGrid({
        id: makeSectionId(sectionId),
        type: pageSection.type,
        items,
        text: pageSection.text,
        playback: state.playback,
      }),
    ]);
  }
  if (pageSection.text) {
    return section(".content-grid.playlist-grid", { key: makeSectionId(sectionId) }, [
      pageSection.title ? h2(pageSection.title) : "",
      p(pageSection.text),
    ]);
  }
  return "";
}
