/**
 * Utilities for dealing with bulk user records.
 *
 * @module ui/page/admin/user/bulk/util
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { aclPrincipalTypes } from "model/acl/constants";
import { getHelpText } from "ui/common/help-texts";
import { clone } from "util/object";
import { saveStates, uploadStates, userFields } from "./state";

export const PLACEHOLDER_GROUP_ID = "PLACEHOLDER_GROUP_ID";

/**
 * Standard format for individual input names by user tempId.
 */
export const fieldName = (field, tempId) => `${field}_${tempId}`;

/**
 * Get the validation records for all of a user's fields, and their overall validation state.
 *
 * @function getUserValidity
 * @param {uuid} tempId
 * @param {ValidationState} validation
 * @return {ValidationState}
 */
export const getUserValidity = (tempId, validation) => {
  const userValidity = [];

  userFields.forEach((field) => {
    const fName = fieldName(field, tempId);
    userValidity[field] = clone(validation.fields[fName] || { valid: true });
  });

  userValidity.valid = Object.values(userValidity)
    .reduce((acc, val) => acc && val.valid, true);

  return userValidity;
};

const needsMessageFilter = ([field, validState]) => {
  if (!userFields.includes(field)) return false;
  return !validState.valid;
};

/**
 * Collect the validation messages for a user.
 *
 * @function getUserValidity
 * @param {uuid} tempId
 * @param {ValidationState} validation
 * @return {ValidationState}
 */
export const getUserValidityMessages = (validity) => ([
  ...Object.entries(validity)
    .filter(needsMessageFilter)
    .map(([field, validState]) => getHelpText(field, validState))
    .flat(),
]);

/**
 * Collect all the groups that have been applied to existing users.
 *
 * @function collectAppliedGroups
 * @param {User[]} users
 * @param {Group[]} existingGroups
 * @return {Group[]}
 */
export const collectAppliedGroups = (users, existingGroups) => {
  const appliedGroups = new Set([
    ...existingGroups.map((group) => group.name),
    ...users.map((user) => (user.groupNames || []).filter((g) => !!g?.trim())).flat(),
  ]);
  const newGroups = [...appliedGroups.values()].map(
    (name) => existingGroups.find((g) => g.name === name)
    || ({
      id: PLACEHOLDER_GROUP_ID,
      name,
      members: [],
      system: false,
      type: aclPrincipalTypes.GROUP,
    }),
  );
  return newGroups;
};

/**
 * Check that all required fields for a user are populated.
 *
 * @function checkRequiredFields
 * @private
 * @param {User} user
 * @param {Object} server server config variables
 * @return boolean
 */
export const checkRequiredFields = (user, server, values) => {
  const required = [
    ["cellPhone", server?.cellPhoneRequired],
    ["email", server?.emailRequired],
    ["firstName", server?.firstNameRequired],
    ["lastName", server?.lastNameRequired],
    ["password", true],
    ["username", true],
  ];

  return required.reduce((acc, [field, require]) => acc
    && (!require || !!values[fieldName(field, user.tempId)]),
  true);
};

/**
 * Check that all users are valid and not currently saving.
 *
 * @function allUsersValid
 * @param {Object} state page.state
 * @private
 * @return boolean
 */
const allUsersValid = ({
  users,
  config: { server },
  saveStatuses,
  validation,
}, values) => users.length && users
  .reduce((acc, cur) => (
    (saveStatuses.get(cur.tempId) === saveStates.SAVING
      || (
        getUserValidity(cur.tempId, validation)?.valid
        && checkRequiredFields(cur, server, values)
      )
    ) && acc),
  true);

/**
 * Returns a function that counts statuses for the given users and status map.
 *
 * @function statusCounter
 * @param {User[]} users
 * @param {Map.<uuid, saveStates>} statuses
 * @return integer
 */
export const statusCounter = (users, statuses) => (status) => users.reduce(
  (acc, user) => acc + (statuses.get(user.tempId) === status ? 1 : 0),
  0,
);

/**
 * Returns over-all upload status for the page.
 *
 * @function getUploadState
 * @param {FormView} page
 * @return UploadState
 */
export const getUploadState = (page) => {
  const count = statusCounter(page.state.users, page.state.saveStatuses);
  if (count(saveStates.SUCCESS) === page.state.users.length) {
    return uploadStates.DONE;
  }
  if (count(saveStates.FAILURE) > 0 && count(saveStates.PENDING) === 0) {
    return uploadStates.RETRY;
  }
  if (count(saveStates.SAVING)) {
    return uploadStates.IN_PROGRESS;
  }
  if (!allUsersValid(page.state, page.values)) {
    return uploadStates.ERRORS;
  }
  return uploadStates.READY;
};

/**
 * Check whether all users have verified status.
 *
 * @function checkAllVerified
 * @private
 * @param {FormView} page
 * @return boolean
 */
const checkAllVerified = (page) => page.state.users.length
  && page.state.users.reduce((cur, acc) => cur && acc.enabled, true);

/**
 * Get updated versions of all user records from current form field state.
 *
 * @function getUsersFromFields
 * @param {FormView} page
 * @return {BulkUser[]}
 */
export const getUsersFromFields = ({ state, values }) => state.users.map((u) => ({
  ...u,
  firstName: values[fieldName("firstName", u.tempId)] || u.firstName,
  lastName: values[fieldName("lastName", u.tempId)] || u.lastName,
  username: values[fieldName("username", u.tempId)] || u.username,
  cellPhone: values[fieldName("cellPhone", u.tempId)] || u.cellPhone,
  email: values[fieldName("email", u.tempId)] || u.email,
  password: values[fieldName("password", u.tempId)] || u.password,
  groupNames: values[fieldName("groupNames", u.tempId)] || u.groupNames,
  enabled: (() => {
    const val = values[fieldName("enabled", u.tempId)];
    if (val !== undefined) return val;
    return u.enabled;
  })(),
}));

/**
 * Recalculate various derived states for the page.
 *
 * @function refreshDerivedState
 * @param {FormView} page
 */
export const refreshDerivedState = (page) => {
  page.updateState({
    uploadState: getUploadState(page),
    allVerified: checkAllVerified(page),
    groups: collectAppliedGroups(page.state.users, page.state.groups),
    messages: [],
  });
};

/**
 * Force update the user list.
 *
 * Used to force the form's values to change to the ones supplied here.
 *
 * When modifying the user list, need to update twice, once to trigger user
 * refresh and then again with new validation state.
 *
 * @function refreshUsers
 * @param {FormView} page
 */
export const refreshUsers = (page, users) => {
  page.update({ users, override: true });
  page.updateState({ users: getUsersFromFields(page) });
  refreshDerivedState(page);
  // double-update so form fields get updated and then validation gets run
  page.update({ validation: page.validate(), override: false });
};

/**
 * Set the verified status of all users.
 *
 * @function setAllVerified
 * @param {FormView} page
 */
export const setAllVerified = (page, enabled) => {
  refreshUsers(
    page,
    page.state.users.map((u) => ({ ...u, enabled })),
  );
};
