/**
 * Pre-configured states for the file-picker component.
 *
 * @module ui/component/form-managed/file-picker
 * @private
 * @category UI
 * @subcategory Forms
 */
/***/
import { fileTypes } from "model/file-descriptor/constants";
import { frozen } from "util/object";
import { filePickerModes } from "./constants";

/**
 * @typedef FilePickerValue
 * @prop {filePickerModes} mode
 * @prop {?FileDescriptor} descriptor present when mode === filePickerModes.KEEP
 *       || filePickerModes.DESCRIPTOR
 * @prop {?string} url present when mode === filePickerModes.UPLOAD and URL was supplied
 * @prop {?string} file present when mode === filePickerModes.FILE and file is selected
 */
export const defaultValue = frozen({
  mode: filePickerModes.KEEP,
  descriptor: null,
  file: null,
  url: null,
});

/**
 * Supplies defaults for all possible config values.
 */
export const defaultConfig = frozen({
  current: null,
  uploadFileNames: [],
  fileDescriptors: [],
  file: "",
  descriptor: null,
  url: "",
  upload: "",
  mode: filePickerModes.KEEP,
  allowedModes: [
    filePickerModes.KEEP,
    filePickerModes.UPLOAD,
    filePickerModes.FILE,
    filePickerModes.DESCRIPTOR,
  ],
  fileTypes: [
    fileTypes.VIDEO,
    fileTypes.DOCUMENT,
    fileTypes.IMAGE,
  ],
  disabled: false,
  cache: true,
  onChange: null,
  validity: { valid: true },
  value: defaultValue,
  helpTexts: {
    required: "Please make a selection to proceed.",
    invalid: "Please make a selection to proceed.",
  },
});

/**
 * Configuration defaults for document file pickers.
 */
export const documentConfig = frozen({ fileTypes: [fileTypes.DOCUMENT] });

/**
 * Configuration defaults for image file pickers.
 */
export const imageConfig = frozen({ fileTypes: [fileTypes.IMAGE] });

/**
 * Configuration defaults for image + svg file pickers.
 */
export const imageAndSvgConfig = frozen({ fileTypes: [fileTypes.IMAGE, fileTypes.SVG] });

/**
 * Configuration defaults for svg file pickers.
 */
export const svgConfig = frozen({ fileTypes: [fileTypes.SVG] });

/**
 * Configuration defaults for creating a new metadata entry.
 */
export const newConfig = frozen({
  mode: filePickerModes.upload,
  allowedModes: [
    filePickerModes.UPLOAD,
    filePickerModes.DESCRIPTOR,
    filePickerModes.FILE,
  ],
});

/**
 * Configuration defaults for video file pickers.
 */
export const videoConfig = frozen({
  fileTypes: [fileTypes.VIDEO],
  allowedModes: [
    filePickerModes.KEEP,
    filePickerModes.UPLOAD,
    filePickerModes.URL,
    filePickerModes.DESCRIPTOR,
    filePickerModes.FILE,
  ],
});

export const newVideoConfig = frozen({
  fileTypes: [fileTypes.VIDEO],
  allowedModes: [
    filePickerModes.UPLOAD,
    filePickerModes.URL,
    filePickerModes.DESCRIPTOR,
    filePickerModes.FILE,
  ],
});

export const uploadConfig = frozen({
  mode: filePickerModes.UPLOAD,
  allowedModes: [
    filePickerModes.UPLOAD,
  ],
});

export const keepConfig = frozen({
  mode: filePickerModes.KEEP,
  allowedModes: [
    filePickerModes.KEEP,
  ],
});
