/**
 * The user-facing content FAQ page.
 *
 * @module ui/page/faq/content
 * @category Pages
 * @subcategory User Facing
 */
import {
  a,
  div, h1, h3, img, li, ol, p, span,
} from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "content-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showContentFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Content"]),
  collapsible({
    title: "How do I stream a video?",
    autoCloseConfiguration,
  }, p(`To start a video, click the Watch button on the list image or content popup.`)),
  collapsible({
    title: "How can I resume watching a video?",
    autoCloseConfiguration,
  }, p(`If you paused your video or accidentally closed your browser window, you can pick up where you left
    off by selecting Resume on the list image or content popup.`)),
  collapsible({
    title: "What do these buttons on the video player do?",
    autoCloseConfiguration,
  }, [
    img(".full-width", "/media/video-player.jpg", "video player"),
    ol([
      li([
        span("Back - ", ".bold"),
        "This button will take you back to the last page you were on.",
      ]),
      li([
        span("Restart - ", ".bold"),
        "If you would like to restart your video from the beginning, click this!",
      ]),
      li([
        span("Rewind 15 sec. - ", ".bold"),
        "This button will rewind your video by 15 seconds.",
      ]),
      li([
        span("Play - ", ".bold"),
        `This button will start/stop the video. If the video is playing, press the Play button to pause.
         To restart the video from the point you left off, press the Pause button.`,
      ]),
      li([
        span("Fast Forward 15 sec. - ", ".bold"),
        "This button will fast forward your video by 15 seconds.",
      ]),
      li([
        span("Watch Bar - ", ".bold"),
        "The watch bar tracks the progress of your video.",
      ]),
      li([
        span("Time Watched - ", ".bold"),
        "This indicates how much of the video you have watched in HH:MM:SS.",
      ]),
      li([
        span("Total Length - ", ".bold"),
        "This indicates the total length of the video in HH:MM:SS.",
      ]),
      li([
        span("Volume Control - ", ".bold"),
        `This control allows you to change the volume of the video on the video player. If you want to mute the video,
         move the slider all the way to the left. If you’re somewhere noisy and want to hear better, move the slider
         to the right.`,
      ]),
      li([
        span("Full screen - ", ".bold"),
        `This allows you to view the video you’re watching in full-screen mode. To get out of full-screen mode, either
         click the button again on the video player or press the ESC button.`,
      ]),
    ]),
  ]),
  collapsible({
    title: "Can I download a video?",
    autoCloseConfiguration,
  }, p(`Not right now, but we're working on ways to help you watch videos without an internet connection.
    Stay tuned!`)),
  collapsible({
    title: "Can I re-watch a video?",
    autoCloseConfiguration,
  }, p(`Totally! Please feel free to watch the videos in the SmartEdge Content Library as many times
    as you'd like.`)),
  collapsible({
    title: "Does SmartEdge keep track of all the videos I have watched?",
    autoCloseConfiguration,
  }, p(`Yes! To revisit your favorite pieces of content, check out the Recently Watched tab on the Homepage.`)),
  collapsible({
    title: "Can I only view a PDF through the browser? Can I download them for later?",
    autoCloseConfiguration,
  }, p(`You can do both! You can view the PDF through your browser’s default PDF viewer.
    You also have the option to download PDFs to view them outside of your browser`)),
  collapsible({
    title: "What is a List?",
    autoCloseConfiguration,
  }, p(`Lists inside SmartEdge are ways for instructors to group videos and files that are best viewed
    together. They’re like chapters inside a book.`)),
  collapsible({
    title: "Can I watch all the content in a List? How do I do this?",
    autoCloseConfiguration,
  }, p(`Definitely! Click the Watch All button on the List popup. Every video inside of a list will
    automatically play in order.`)),
  collapsible({
    title: "Can I choose a specific piece of content to watch in a List?",
    autoCloseConfiguration,
  }, p(`Of course! You can watch content in any order you like. Go to dropdown on List popup, select
    the content you'd like to view, and press Watch/View.`)),
]);

export default async function contentFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Content"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showContentFaq)("#end-user-faq", state);
  loading.hide();
}
