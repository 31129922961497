/**
 * A modal for entering a remote URL for a file descriptor.
 *
 * @module ui/component/modal/file-url
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  button,
  input,
  p,
} from "ui/html";
import dialog from "ui/component/modal/layout/dialog";
import { getModal } from "ui/view/modal-view";
import icon from "ui/component/icon";

export default function fileUrlDialog({ url = "" }) {
  const modalView = getModal();
  return dialog({
    sel: ".file-url",
    body: [
      icon.light("link-horizontal"),
      p("Please enter a URL for a video file."),
      input.text(url, false, `file_url_modal_value`, { attrs: { placeholder: "Enter the URL here..." } }),
    ],
    footerSpacer: true,
    footer: [
      button("Save", () => {
        const newUrl = document.querySelector(`[name="file_url_modal_value"]`).value;
        modalView.resolve(newUrl);
      }, "#modal-file-url-save"),
    ],
  }, modalView);
}
