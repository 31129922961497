/**
 * A modal for file dropbox question editing.
 *
 * @module ui/component/modal/assessment/question-file-dropbox
 * @category UI
 * @subcategory Modal Dialogs
 */
import { merge } from "util/object";
import {
  button, div, h3, h4, span,
} from "ui/html";
import form from "ui/component/form-managed";
import textarea from "ui/component/form-managed/field-textarea";
import { questionType } from "model/course/constants";
import dialog from "ui/component/modal/layout/dialog";
import icon from "ui/component/icon";
import { notificationMessageTypes } from "model/notification/constants";
import formManagedView from "ui/view/form-managed";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import number from "ui/component/form-managed/field-number";
import questionGradingHelpModal from "../help/question-grading";

let storedFormView;

const TOTAL_FILES_LIMIT = 1;

const staticMessages = {
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: notificationMessageTypes.FAIL },
  pointsExceededLimit: { title: "Error", text: "Weight limit exceed. Reduce question weight.", type: notificationMessageTypes.FAIL },
};

const defaultState = {
  validation: {
    fields: {},
  },
  question: {
    text: "",
    questionType: questionType.UPLOAD,
    answers: [
      /**
       * This is used to determine whether we have limit for files:
       * correct: false - no limit. correct: true - limited, value: "3" - max number of files
       */
      {
        correct: false,
        value: null,
      },
    ],
    weight: undefined,
  },
  messages: [],
};

const bindForm = () => {
  const formEl = document.createElement("FORM");
  formEl.id = "file-dropbox";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#file-dropbox");
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  inner.patch(inner.factory(inner));
};

const buildMultipleChoiceForm = (self) => {
  const { state } = self;
  return form(
    "#file-dropbox",
    self.bind([
      h4("Instructions"),
      [textarea, { name: "text", label: "Question", required: true }],
      div(".file-types", [
        span("File Types Accepted: ", ".file-types-label"),
        span(".pdf, .ppt, .docx, .jpeg, .png, .mp4, .json, .xlsx, .rtf", ".file-types-value"),
      ]),
      div(".justified", [
        div(".container-help", [
          h4("Grading"),
          icon("circle-question", ".show-help", {
            on: {
              click: async () => getModal().async(questionGradingHelpModal()),
            },
          }),
        ]),
        div(".used-points", `Points used: ${state.usedPoints} of ${state.maxPoints}`),
      ]),
      [number, { required: true, label: "Weight", name: "weight" }],
    ], state.question),
  );
};

const initForm = (state) => {
  if (storedFormView) {
    bindForm();
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "file-dropbox";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView = formManagedView(
    buildMultipleChoiceForm,
    onFormUpdate,
  )(
    "#file-dropbox",
    state,
  );
};

const onSave = () => {
  storedFormView.setFullValidation(true);
  const validation = storedFormView.validate(true);
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return null;
  }
  const { state, values } = storedFormView;
  if (state.usedPoints - (state.question.weight || 0) + (+values.weight) > state.maxPoints) {
    getNotification().post(staticMessages.pointsExceededLimit);
    return null;
  }
  const preparedAnswers = storedFormView.state.question.answers?.[0]?.correct
    ? [{
      correct: true,
      value: TOTAL_FILES_LIMIT,
    }]
    : [];
  storedFormView.updateState({
    question: {
      text: storedFormView.values.text,
      weight: storedFormView.values.weight,
      answers: preparedAnswers,
    },
  });
  return getModal().resolve(storedFormView.state.question);
};

/**
 * File dropbox modal
 *
 * @param inState
 * @param {Partial<Question>} inState.question
 * @param {number} inState.maxPoints
 * @param {number} inState.usedPoints
 * @returns {*|El}
 */
const questionFileDropboxModal = (inState = defaultState) => {
  storedFormView = null;
  const state = merge(defaultState, {
    ...inState,
    question: {
      ...inState.question,
      answers: inState.question?.answers?.length
        ? inState.question.answers
        : defaultState.question.answers,
    },
  });
  return dialog({
    sel: ".form.assessment-question.file-dropbox",
    config: {
      hook: {
        insert: () => initForm(state),
        postPatch: () => initForm(state),
      },
    },
    header: h3("File Dropbox"),
    body: span("placeholder"),
    footerSpacer: true,
    footer: [
      div(".buttons", { attrs: { role: "group" } }, [
        button("Save", () => onSave()),
      ]),
    ],
  }, getModal());
};

export default questionFileDropboxModal;
