/**
 * A cameo for course items.
 *
 * @module ui/component/cameo-course
 * @category UI
 * @subcategory Components
 */
import {
  span,
} from "ui/html";
import cameo from "ui/component/cameo";
import button from "ui/component/form-managed/button";
import contentPreview from "ui/component/modal/content";
import icon from "ui/component/icon";
import { getModal } from "ui/view/modal-view";

const viewItem = async (course) => getModal().async(
  // FIXME should be a course preview but we don't have one yet
  contentPreview({ metadata: course, sameTab: false }),
);

/**
 * A summary display of a course item which may present a preview
 * button and optionally other controls.
 *
 * If the `modalDialog` parameter is supplied the preview button will be
 * generated and managed automatically by the cameo.
 *
 * @function cameoCourse
 * @param {object} state
 * @param {ListCourse} course
 * @param {?ChildEl} controls additional buttons
 * @param {?ModalView} modalDialog
 */
export default function cameoCourse({
  course,
  controls = null,
  config = {},
}) {
  return course ? cameo({
    label: course.title,
    icon: icon.solid("book"),
    controls: [
      button({
        icon: "eye",
        iconOnly: true,
        onClick: () => viewItem(course),
      }),
      ...controls?.length
        ? controls
        : [],
    ],
    sel: ".cameo-course",
    config,
    disabled: course?.disabled,
  }) : span(
    [icon("question-circle"), span("No Selection")],
    ".course.stand-in",
  );
}
