import { fakeLabels } from "api/mocks/metadata";
import collapsible from "ui/component/collapsible";
import labels from "ui/component/form-managed/field-label-set";

export default function labelFields(self, modalView) {
  return collapsible({
    title: "Label Fields",
    open: self.state.labelsOpen,
    onChange: (labelsOpen) => self.updateState({ labelsOpen }),
  }, self.bind(
    [
      [labels, { existing: fakeLabels }, modalView],
    ],
    { labels: self.state.labels },
  ));
}
