/**
 * Constants related to dynamic pages.
 *
 * @module model/dynamic-page/constants
 * @category Model
 * @subcategory Dynamic Page
 */
import { featureTypes } from "model/config/constants";

/**
 * statically assigned UUID for the home page. Used for filtering in search
 * results as well as enabling/disabling certain features in page editor.
 *
 * @constant HOMEPAGE_ID
 * @type UUID
 * @default
 * @readonly
 */
export const HOMEPAGE_ID = '00000000-0000-0000-0000-000000000000';

/**
 * Page data version
 *
 * @type {number}
 */
export const PAGE_DATA_VERSION = 2;

/**
 * Pre-defined page types. All page types except `INDEPENDENT` are displayed as sections
 * on their parent page. Note that these are used exclusively by frontend to determine
 * which layout to use for a section. Backend does not enforce or validate these values.
 *
 * Frontend MUST implement layouts for any of the options here.
 *
 * Please do not remove any options, even deprecated ones. Dynamic pages should provide
 * a fallback layout for any PageType that is no longer supported, so it needs to be
 * recognized both in the API DTOs and in dynamic page UI.
 *
 * If we need to deprecate a layout and remove it as an option for admins, make
 * a separate list of deprecatedPagePageTypes and filter them out when displaying
 * options in the UI.
 *
 * @enum {string} pageTypes
 * @readonly
 * @property {string} EMBEDDED_ITEM a section with embedded video, image or metadata
 *                                  with text that can be placed to the left
 *                                  or to the right from the embed
 * @property {string} FAQ a section with collapsibles
 * @property {string} TEXT a section with a title and arbitrary body text
 * @property {string} IMAGE_SLIDER a section for displaying images in a
 *                                 'slider' (full-width carousel)
 * @property {string} PLAYLIST_CAROUSEL a section displaying a playlist as a carousel
 * @property {string} PLAYLIST_GRID section displaying a playlist as a grid
 * @property {string} DYNAMIC_LIST_CAROUSEL a section for displaying
 *                                          pre-configured search results as a
 *                                          playlist carousel
 *
 * @property {string} DYNAMIC_LIST_GRID a section for displaying pre-configured
 *                                      search results as a playlist grid
 * @property {null}   INDEPENDENT an independent page (not a page section)
 * @property {string} UNKNOWN a broken/not found type
 */
export const pageTypes = Object.freeze({
  EMBEDDED_ITEM: "EMBEDDED_ITEM",
  EMBEDDED_VIDEO_OR_IMAGE: "EMBEDDED_VIDEO_OR_IMAGE",
  FAQ: "FAQ",
  TEXT: "TEXT",
  TEXT_HEADER: "TEXT_HEADER",
  RICH_TEXT: "RICH_TEXT",
  IMAGE_SLIDER: "IMAGE_SLIDER",
  PLAYLIST_CAROUSEL: "PLAYLIST_CAROUSEL",
  PLAYLIST_GRID: "PLAYLIST_GRID",
  DYNAMIC_LIST_CAROUSEL: "DYNAMIC_LIST_CAROUSEL",
  DYNAMIC_LIST_GRID: "DYNAMIC_LIST_GRID",
  COURSE_OVERVIEW: "COURSE_OVERVIEW",
  COURSE_MODULE_CAROUSEL: "COURSE_MODULE_CAROUSEL",
  COURSE_MODULE_GRID: "COURSE_MODULE_GRID",
  UNKNOWN: undefined,
  INDEPENDENT: null,
});

/**
 * A collection of page type strings.
 *
 * @constant pageTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const pageTypesSet = Object.freeze(
  new Set(Object.values(pageTypes)),
);

/**
 * A map of page section types to user-friendly strings.
 * @constant pageTypesFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
export const pageTypesFriendly = Object.freeze(new Map([
  [pageTypes.TEXT, "Text"],
  [pageTypes.TEXT_HEADER, "Text Header"],
  [pageTypes.EMBEDDED_ITEM, "Embedded Item"],
  [pageTypes.FAQ, "FAQ"],
  [pageTypes.RICH_TEXT, "Rich Text"],
  [pageTypes.IMAGE_SLIDER, "Slider"],
  [pageTypes.PLAYLIST_CAROUSEL, "Playlist Carousel"],
  [pageTypes.PLAYLIST_GRID, "Playlist Grid"],
  [pageTypes.DYNAMIC_LIST_CAROUSEL, "Dynamic Carousel"],
  [pageTypes.DYNAMIC_LIST_GRID, "Dynamic Grid"],
  [pageTypes.COURSE_OVERVIEW, "Course Overview"],
  [pageTypes.COURSE_MODULE_CAROUSEL, "Course Module Carousel"],
  [pageTypes.COURSE_MODULE_GRID, "Course Module Grid"],
  [pageTypes.INDEPENDENT, "Page"],
]));

/**
 * A set of page section types that require a metadata selection.
 * @constant pageTypesRequiringMetadata
 * @type {Set.<string>}
 * @readonly
 */
export const pageTypesRequiringMetadata = Object.freeze(new Set([
  pageTypes.PLAYLIST_GRID,
  pageTypes.PLAYLIST_CAROUSEL,
]));

/**
 * A set of page section types that require search data.
 * @constant pageTypesRequiringSearchData
 * @type {Set.<string>}
 * @readonly
 */
export const pageTypesRequiringSearchData = Object.freeze(new Set([
  pageTypes.DYNAMIC_LIST_GRID,
  pageTypes.DYNAMIC_LIST_CAROUSEL,
]));

/**
 * A set of page section types that require course selection.
 * @constant pageTypesRequiringSearchData
 * @type {Set.<string>}
 * @readonly
 */
export const pageTypesRequiringCourseData = Object.freeze(new Set([
  pageTypes.COURSE_OVERVIEW,
  pageTypes.COURSE_MODULE_CAROUSEL,
  pageTypes.COURSE_MODULE_GRID,
]));

/**
 * A set of page section types that require any kind of external data.
 * @constant pageTypesRequiringExtraData
 * @type {Set.<string>}
 * @readonly
 */
export const pageTypesRequiringExtraData = Object.freeze(new Set([
  pageTypes.PLAYLIST_GRID,
  pageTypes.PLAYLIST_CAROUSEL,
  pageTypes.DYNAMIC_LIST_GRID,
  pageTypes.DYNAMIC_LIST_CAROUSEL,
  pageTypes.COURSE_OVERVIEW,
  pageTypes.COURSE_MODULE_CAROUSEL,
  pageTypes.COURSE_MODULE_GRID,
]));

/**
 * A map of feature requirements for page types.
 *
 * Used to filter out page sections with missing feature prerequisites.
 * @constant pageTypeFeatureRequirements
 * @type {Map<string, Array.<string>>}
 * @readonly
 */
export const pageTypeFeatureRequirements = Object.freeze(new Map([
  [pageTypes.COURSE_OVERVIEW, [featureTypes.LMS]],
  [pageTypes.COURSE_MODULE_CAROUSEL, [featureTypes.LMS]],
  [pageTypes.COURSE_MODULE_GRID, [featureTypes.LMS]],
]));

/**
 * Card format options for dynamic playlist page sections.
 *
 * @enum {string} cardSizeOptions
 * @readonly
 * @property {string} VERTICAL
 * @property {string} HORIZONTAL
 */
export const cardFormatOptions = Object.freeze({
  VERTICAL: "VERTICAL",
  HORIZONTAL: "HORIZONTAL",
});

/**
 * Map of user-friendly labels for card size options.
 *
 * @constant cardSizeOptionsLabeled
 * @type {Map.<string, string>}
 * @readonly
 */
export const cardSizeOptionsLabeled = Object.freeze(new Map([
  [cardFormatOptions.VERTICAL, "Vertical"],
  [cardFormatOptions.HORIZONTAL, "Horizontal"],
]));

/**
 * Set of criteria useful for sorting pages.
 *
 * @constant pageSortCriteria
 * @type {Set.<string>}
 * @readonly
 */
export const pageSortCriteria = Object.freeze({
  ID_ASC: "BY_ID_ASC",
  ID_DESC: "BY_ID_DESC",
  TITLE_ASC: "BY_TITLE_ASC",
  TITLE_DESC: "BY_TITLE_DESC",
  SLUG_ASC: "BY_SLUG_ASC",
  SLUG_DESC: "BY_SLUG_DESC",
});

/**
 * Set of slide types.
 *
 * @constant slideTypes
 * @type {Set.<string>}
 * @readonly
 */
export const slideTypes = Object.freeze({
  IMAGE: "IMAGE",
  TEXT_BLOCK: "TEXT_BLOCK",
});
