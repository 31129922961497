/**
 * Admin page for editing dynamic pages.
 *
 * @module ui/page/admin/dynamic/profile
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
import api from "api";
import { EXPAND_COURSES } from "api/course/constants";
import log from "log";
import cache from "cache";
import { MAIN_MENU_KEY } from "model/site/constants";
import getConfig from "config";
import cacheStream from "data/cache";
import { aclPrincipalTypes, aclRights, aclSubjectTypes } from "model/acl/constants";
import { HOMEPAGE_ID } from "model/dynamic-page/constants";
import { isRoot } from "model/user";
import { div, span } from "ui/html";
import cameoPageSection from "ui/component/cameo-page-section";
import actionBar from "ui/component/dashboard-action-bar";
import placeholder from "ui/component/placeholder";
import sortable from "ui/component/sortable";
import widget from "ui/component/dashboard-widget";
import { getQueryParams } from "util/navigation";
import view from "ui/view";
import { tidyBackendError } from "util/error";
import { merge } from "util/object";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import userContentAccess from "ui/component/user-content-access";
import { cloneGrants, makeGrantQueue, validateOwnRights, validateRights } from "util/acl";
import dashboardLayout from "ui/page/layout/dashboard";
import title from "ui/component/form-managed/field-title";
import slug from "ui/component/form-managed/field-slug";
import button from "ui/component/form-managed/button";
import { notificationMessageTypes } from "model/notification/constants";
import menuEditor from "ui/component/menu-editor";
import { qs } from "util/dom";
import chartWithCalendar from "ui/component/chart/chart-with-calendar";
import { chartTypes } from "model/chart/constants";
import { getDateWeekAgo } from "util/date";
import { getPageViews } from "api/v2/analytics";
import { analyticsPageViewsToDataset } from "model/chart";
import { defaultEditState, displayModes } from "./state";
import staticMessages from "./messages";
import {
  addSection,
  handleSectionImages,
  onDeleteSection,
  onEditSection,
  onSortSection,
  savePage,
} from "./common";
import helpSection from "./help";

let actionsView;
let modalView;
let headerView;
let loadingView;
let pageProfileView;
let sectionsView;
let userAccessView;
let notificationView;
let aclGrantQueue;
let analyticsView;

const deleteMenuEntryIfExist = async (state) => {
  const { page, menu } = state;
  const menuItemExist = menu.entries.some((entry) => entry.pageId === page.id);
  if (!menuItemExist) {
    return true;
  }
  if (await modalView.confirm(
    "This action will delete according menu entries. Would you like to proceed?",
  )) {
    const updatedMenu = {
      ...menu,
      entries: menu.entries.filter((item) => item.pageId !== page.id),
    };
    loadingView.show();
    await api.site.saveMenu(updatedMenu);
    await cacheStream.clearMenu();
    return true;
  }
  return false;
};

const doDelete = async (e, editPageView) => {
  e.preventDefault();
  e.stopPropagation();
  const { id } = editPageView.state.page;
  if (id === HOMEPAGE_ID) {
    await modalView.alert("You can't delete the home page!");
    return;
  }
  if (await modalView.confirm(
    `Are you sure you want to delete this page? This action is irreversible.`,
  )) {
    try {
      const proceed = await deleteMenuEntryIfExist(editPageView.state);
      if (!proceed) {
        return;
      }
      loadingView.show(`Deleting ${editPageView.state.page.title}...`);
      await Promise.all(editPageView.state.page.sections?.map(async (section) => {
        api.page.deleteById(section.id);
      }));
      await api.page.deleteById(id);
      await cacheStream.clearPage(id);
      await modalView.alert(staticMessages.successDelete.text);
      window.location.replace("/admin/manage-pages", 2000);
    } catch (err) {
      log.error(err);
      switch (e.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDenied);
          break;
        case 404:
          notificationView.post(staticMessages.doesNotExist);
          break;
        case 500:
          notificationView.post(staticMessages.errorDeleting);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
    } finally {
      loadingView.hide();
    }
  }
};

const processMenuEntries = async (page, menu) => {
  const { title: pageTitle, id, slug: pageSlug } = page;
  const updatedMenuEntries = await Promise.all(menu.entries.map(async (entry) => {
    let updated = { ...entry };
    if (entry.pageId === id && entry.label !== pageTitle) {
      const update = await modalView.confirm(
        "Update Menu Link?",
        `This page appears on the main menu with the label "${entry.label}".
         Would you like to update the menu entry to "${pageTitle}"?`,
      );
      if (update) {
        updated = {
          ...updated,
          label: pageTitle,
        };
      }
    }
    if (entry.pageId === id && entry.slug !== pageSlug) {
      updated = {
        ...updated,
        slug: pageSlug,
      };
    }
    return updated;
  }));
  const newMenu = {
    ...menu,
    entries: updatedMenuEntries,
  };
  // FIXME hacky cache invalidation
  cache.deleteValue(MAIN_MENU_KEY);
  return api.site.saveMenu(newMenu);
};

const updateAllGrants = async (self, updatedPage) => {
  if (self.state.manageRightsEnabled) {
    await aclGrantQueue.process();
  }
  const pageGrants = await api.acl.listPageGrants({ id: updatedPage.id });
  const subPageGrants = await Promise.all(updatedPage.subPageIds.map(
    (id) => api.acl.listPageGrants({ id }).catch(() => []),
  ));
  await Promise.all(
    subPageGrants.map((sourceGrants, i) => cloneGrants(
      updatedPage.subPageIds[i],
      aclSubjectTypes.PAGE,
      sourceGrants,
      pageGrants,
    ).process()),
  );
  return true;
};

const doSave = async (self) => {
  self.setFullValidation(true);
  const validation = self.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  const { values, state } = self;
  // deep clone to stip out any protections and avoid accidentally
  // changing anything
  const page = merge({}, { ...self.state.page, ...values });
  if (
    page.sections.length === 0
    && !(await modalView.confirm("You haven't added any sections. Are you sure you want to save?"))
  ) return;
  // now let's handle images
  let abort = false;
  loadingView.show("Saving page multimedia...");
  try {
    page.sections = await Promise.all(page.sections.map(
      handleSectionImages(self, loadingView),
    ));
  } catch (e) {
    abort = true;
  }
  if (abort) {
    loadingView.hide();
    return;
  }
  // otherwise images went fine, let's save the page object
  try {
    loadingView.show("Saving page sections...");
    await Promise.all(self.state.removedSections.map(async (sectionId) => {
      await api.page.removeSubPage(page.id, sectionId);
      await api.page.deleteById(sectionId);
    }));
    loadingView.show("Saving page...");
    const updatedPage = await savePage(page);
    loadingView.show("Saving page access control...");
    await updateAllGrants(self, updatedPage);
    loadingView.show("Saving page menu...");
    await processMenuEntries(page, state.menu);
    try {
      await api.site.savePageMenu(page.id, state.sideMenu);
    } catch (e) {
      log.error(e, "While updating grants");
      notificationView.post(staticMessages.accessSaveError);
      loadingView.hide();
      return;
    }
    modalView.customConfirm({
      title: "Page was successfully updated",
      body: "",
      confirmLabel: "Keep Editing",
      cancelLabel: `Manage Pages`,
      onConfirm: () => window.location.reload(),
      onCancel: () => window.location.assign(
        `/admin/manage-pages`,
      ),
    });
    await cacheStream.clearPage(page.id);
    loadingView.hide();
    loadingView.show("Redirecting...");
  } catch (err) {
    log.error(err);
    if (err.statusCode) {
      switch (err.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDenied);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
    } else {
      notificationView.post(staticMessages.backendErrors);
    }
    loadingView.hide();
  }
};

const buttons = (self) => ([
  self.state.displayMode === displayModes.HELP
    ? button.alternate({
      icon: "table",
      label: "Page Profile",
      onClick: () => {
        // need to double-update to get validation back
        qs("main.dashboard").classList.remove("show-help");
        pageProfileView.update({ displayMode: displayModes.PAGE_PROFILE });
        pageProfileView.update({});
      },
      ariaLabel: "Show Page Profile",
    })
    : button.alternate({
      icon: "question-circle",
      label: "Help",
      onClick: () => {
        qs("main.dashboard").classList.add("show-help");
        pageProfileView.update({ displayMode: displayModes.HELP });
      },
      ariaLabel: "Show Help",
    }),
  button.primary({
    icon: "save",
    label: "Save",
    onClick: () => doSave(self),
    disabled: !self?.state?.editEnabled,
  }),
  self.state.page.id !== HOMEPAGE_ID && self?.state?.deleteEnabled
    ? button.warning({
      icon: "trash",
      label: "Delete",
      onClick: (e) => doDelete(e, self),
    })
    : "",
]);

const showEditPage = (self) => {
  const { page, pages } = self.state;
  const takenSlugs = pages.map((p) => p.slug);

  return form("#add-page-form", self.bind([
    [title, { required: true }],
    [slug, { required: true, title: self.values?.title || page.title, taken: takenSlugs }],
  ], page));
};

const showSections = ({ state }) => {
  const onEdit = onEditSection(pageProfileView, modalView, notificationView);
  const onDelete = onDeleteSection(pageProfileView, modalView);
  const onSort = onSortSection(pageProfileView, modalView);

  return div("#sections.container", [
    sortable({ childSelector: "span.cameo-page-section.cameo", onSort }, div(
      ".sections",
      {},
      [
        ...state.page.sections.map(
          (section, id) => cameoPageSection({
            section,
            id,
            onEdit,
            onDelete,
          }),
        ),
      ],
    )),
    span([
      button.standIn({
        icon: "plus-circle",
        label: "Add Section",
        onClick: addSection(pageProfileView, modalView, notificationView),
        disabled: !state.editEnabled,
      }),
    ], ".add-section"),
  ]);
};

const showUserAccess = (accessView) => {
  const { state } = accessView;
  const {
    user,
    grants,
    allUsers,
    groups,
    page: subject,
    anonymousUser,
  } = state;

  return div("#access-rights", userContentAccess({
    anonymousUser,
    grants,
    users: allUsers,
    groups,
    subject,
    subjectType: aclSubjectTypes.PAGE,
    onAdd: (grant) => {
      accessView.update({
        grants: [
          ...accessView.state.grants,
          grant,
        ],
      });
      aclGrantQueue.update(grant);
    },
    onRemove: async (grant) => {
      const newGrants = grants.filter((g) => g.principal.id !== grant.principal.id);
      if (!(await validateOwnRights(user, newGrants, grants, modalView))) return;
      if (!(await validateRights(grant.principal, newGrants, grants, modalView))) return;
      accessView.update({ grants: newGrants });
      aclGrantQueue.remove(grant);
    },
    onUpdate: async (grant) => {
      const newGrants = grants.map((g) => {
        if (g.principal.id === grant.principal.id) {
          return grant;
        }
        return g;
      });
      if (!(await validateOwnRights(user, newGrants, grants, modalView))) return;
      if (!(await validateRights(grant.principal, newGrants, grants, modalView))) return;
      accessView.update({ grants: newGrants });
      aclGrantQueue.update(grant);
    },
  }, modalView, notificationView));
};

const analyticsState = (pageViews, pageKey) => ({
  type: chartTypes.BAR,
  data: analyticsPageViewsToDataset(pageViews),
  onDateSelected: async ({ startDate, endDate }) => {
    if (!startDate && !endDate) {
      return;
    }
    const views = await getPageViews({
      startDate,
      endDate,
      window: "1d",
      pageKey,
    });
    analyticsView.update({
      data: analyticsPageViewsToDataset(views),
    });
  },
});

const showActions = () => div("#actions", buttons(pageProfileView));

const showSideMenuEditor = (self) => {
  const { sideMenu, pages } = self.state;
  return menuEditor({
    menu: sideMenu,
    pages,
    onMenuUpdate: (menu) => {
      self.update({
        sideMenu: menu,
      });
    },
  }, modalView, notificationView);
};

const doUpdate = (self) => {
  const { values } = self;
  if (self.state.validation.valid) {
    self.updateState({
      messages: self.state.messages.filter((m) => m !== staticMessages.invalid),
    });
  }
  self.updateState({ page: { ...values } });
  self.render();
  if (actionsView) actionsView.update({ displayMode: self.state.displayMode });
  if (sectionsView) sectionsView.update(self.state);
  if (headerView) headerView.update({ title: self.state.page.title });
  if (userAccessView) userAccessView.update({ page: self.state.page });
};

/**
 * Initializer for the dynamic page editor.
 *
 * @function editPage
 * @param {module:ui/html~Selector} selector the root element for the page
 */
export default async function editPage(selector) {
  const pageTitle = "Page Profile";
  ({
    header: headerView,
    loading: loadingView,
    modal: modalView,
    notification: notificationView,
  } = dashboardLayout(
    selector,
    [
      actionBar(placeholder("", "#actions")),
      widget([
        form("#page-profile-form"),
        placeholder("Sections", "#sections"),
      ], "Profile", ".page-profile"),
      widget(placeholder("Loading...", "#access-control"), "Access Control", ".access"),
      widget(placeholder("Side menu", "#side-menu"), "Side Menu", ".side-menu"),
      widget(placeholder("Coming Soon", "#analytics"), "Analytics", ".analytics"),
      widget(div("#help"), null, ".help"),
    ],
    pageTitle,
  ));
  loadingView.show();
  const params = getQueryParams();

  const user = cache.getProfile();
  const config = await getConfig();

  let courses, page, pages, metadata, files, descriptors, allUsers, groups, anonymousUser;
  let userRights;
  let grants;
  let menu, sideMenu;

  try {
    loadingView.show("Prefetching content...");
    [
      anonymousUser,
      courses,
      files,
      metadata,
      descriptors,
      allUsers,
      groups,
      userRights,
      grants,
      menu,
      sideMenu,
    ] = await Promise.all([
      api.user.getAnonymousUser(),
      api.course.listCourses(EXPAND_COURSES),
      api.media.list(),
      api.metadata.list(),
      api.file.list().catch(() => []),
      api.user.list().catch(() => []),
      api.acl.listGroups().catch(() => []),
      api.acl.listPrincipalAccessRightsForPageId(
        user.id,
        params.id,
        aclPrincipalTypes.INDIVIDUAL,
      ).catch(() => new Set()),
      api.acl.listPageGrants({ id: params.id }).catch(() => []),
      api.site.getMenu(true),
      api.site.getPageMenu(params.id),
    ]);

    loadingView.show("Fetching page...");
    // fetch pages last, since above requests will get a bunch of stuff in cache
    // that we don't need to refetch
    [
      page,
      pages,
    ] = await Promise.all([
      api.page.byId(params.id, true)
        .then((p) => api.page.expand(p, true, true)),
      api.page.list(),
    ]);

    if (grants) {
      // populate subject since we only gave the id and type earlier
      grants = grants.map((grant) => ({ ...grant, subject: page }));
    } else {
      grants = [];
    }

    aclGrantQueue = makeGrantQueue(params.id, aclSubjectTypes.PAGE);

    const pageKey = page.id === HOMEPAGE_ID ? "/" : `/${page.slug}`;
    const pageViews = await getPageViews({
      startDate: getDateWeekAgo(),
      endDate: new Date(),
      window: "1d",
      pageKey,
    });

    const state = merge(
      defaultEditState,
      {
        anonymousUser,
        config,
        user,
        courses,
        files,
        metadata,
        descriptors,
        page,
        pages: pages.filter((entry) => entry.id !== page.id),
        userRights,
        grants,
        allUsers,
        groups,
        menu,
        editEnabled: isRoot(user)
          || userRights.has(aclRights.WRITE) || userRights.has(aclRights.OWNER),
        deleteEnabled: isRoot(user)
          || userRights.has(aclRights.DELETE) || userRights.has(aclRights.OWNER),
        manageRightsEnabled: isRoot(user)
          || (grants?.length && allUsers?.length && groups?.length),
        sideMenu,
        pageViews,
      },
    );

    headerView.update({ ...state, title: state.page.title });
    userAccessView = view.create(showUserAccess)("#access-control", state);
    analyticsView = view.chart(chartWithCalendar(modalView))("#analytics", analyticsState(pageViews, pageKey));
    pageProfileView = formManagedView(showEditPage, doUpdate)("#page-profile-form", state);
    view.create(helpSection)("#help", state);
    sectionsView = view.create(showSections)("#sections", state);
    const sideMenuUpdateFn = (self) => {
      pageProfileView.update({
        sideMenu: self.state.sideMenu,
      });
      self.render();
    };
    view.create(showSideMenuEditor, sideMenuUpdateFn)("#side-menu", state);
    actionsView = view.create(showActions)("#actions", state);

    loadingView.hide();
  } catch (e) {
    if (!page) {
      log.error(e);
      await modalView.alert("The page you requested no longer exists, or you do not have access to it.");
    } else {
      log.error(e);
      await modalView.alert("Unable to retrieve neccessary data at this time. Please try again in a few moments.");
    }
    window.location.replace("/admin/manage-pages");
  }
}
