/**
 * Shared functions and utilities for dynamic pages.
 *
 * @module ui/page/admin/dynamic/add-page
 * @private
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
/***/
import api from "api";
import cache from "cache";
import { CACHE_PAGE_KEY_V2 } from "cache/constants";
import { pageTypes, slideTypes } from "model/dynamic-page/constants";
import log from "log";
import { merge } from "util/object";
import { selectSectionType, editSection } from "./modals";
import { defaultSection } from "./state";

/**
 * Handles adding a new section to the page, after the user has finished editing it.
 *
 * @function addSection
 * @private
 */
export const addSection = (editPageView, modalDialog, notificationView) => async () => {
  let section;
  let completedSection = null;
  while (completedSection === null) {
    /* eslint-disable-next-line no-await-in-loop */
    const selection = await selectSectionType(modalDialog);
    if (selection === null) return;
    section = merge(defaultSection, { type: selection.type });
    /* eslint-disable-next-line no-await-in-loop */
    completedSection = await editSection(
      { ...editPageView.state, section },
      modalDialog,
      notificationView,
    );
  }
  editPageView.update({
    page: {
      ...editPageView.state.page,
      sections: editPageView.state.page.sections.concat(completedSection),
    },
  });
};

/**
 * Places a page section in the delete queue after a user has clicked its delete button.
 *
 * @function onDeleteSection
 * @private
 */
export const onDeleteSection = (editPageView, modalDialog) => async (id) => {
  const section = editPageView.state.page.sections[id];
  if (
    !(await modalDialog.confirm(`Are you sure you want to delete ${section.title}?`))
  ) return;
  const sections = [...editPageView.state.page.sections];
  sections.splice(id, 1);
  editPageView.update({
    removedSections: [
      ...editPageView.state.removedSections,
      section.id,
    ],
    page: {
      ...editPageView.state.page,
      sections,
    },
  });
};

/**
 * Handles updating state after a page section has been updated.
 *
 * @function onEditSection
 * @private
 */
export const onEditSection = (editPageView, modalDialog, notificationView) => async (id) => {
  let section = editPageView.state.page.sections[id];
  section = await editSection({ ...editPageView.state, section }, modalDialog, notificationView);
  if (section === null) return;
  const sections = [...editPageView.state.page.sections];
  sections.splice(id, 1, section);
  editPageView.update({
    page: {
      sections,
    },
  });
};

/**
 * Handles reordering sections after the user completes a drag and drop operation.
 *
 * @function onSortSection
 * @private
 */
export const onSortSection = (editPageView) => async (newSortOrder) => {
  const sections = [];
  const oldSections = editPageView.state.page.sections;
  newSortOrder.forEach((i) => sections.push(oldSections[i]));
  editPageView.update({ page: { sections } });
};

export const isEmptyString = (string) => (
  string === null
  || string === undefined
  || string?.trim() === ""
);

/**
 * Helper function to generate a page slug based on the page title.
 *
 * @function generateSlug
 * @param {String} text page title
 * @param {DynamicPage[]} existingPages used to avoid slug collisions
 * @return {String}
 */
export const generateSlug = (text, existingPages) => {
  if (isEmptyString(text)) return "";
  let processed = text.toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/-+$/g, "");
  const slugs = existingPages.map((page) => page.slug);
  while (slugs.includes(processed)) {
    const tail = /-\d*$/.exec(processed)?.[0];
    if (tail?.length) {
      processed = `${processed.slice(0, -tail.length)}-${parseInt(tail, 10) + 1}`;
    } else processed = `${processed}-2`;
  }
  return processed;
};

const getLastIndex = (sections) => {
  let lastIndex = 0;
  sections.forEach((section) => {
    if (section.slug) {
      const matches = section.slug.match(/\d+$/);
      let sectionIndex = (matches?.length) ? matches[0] : 0;
      if (sectionIndex) {
        try {
          sectionIndex = parseInt(sectionIndex, 10);
        } catch (e) {
          sectionIndex = lastIndex;
        }
      }
      if (sectionIndex > lastIndex) lastIndex = sectionIndex;
    }
  });

  return lastIndex;
};

/**
 * Handles saving the page.
 *
 * @function savePage
 * @param {DynamicPage} inPage
 * @return {?DynamicPage}
 * @throws {mixed} various errors depending on failure state
 */
export const savePage = async (inPage) => {
  let page = merge({}, inPage);
  let index = getLastIndex(page.sections);
  // need the page ID to produce section slugs, so we have to create
  // it first and add the sections later
  if (!page.id) {
    try {
      page = merge(inPage, await api.page.create(page));
    } catch (e) {
      log.error("failed to create main page", page);
      throw new Error("failed to create page");
    }
  }
  let sections = [];
  try {
    sections = await Promise.all(page.sections?.map(async (section) => {
      const toSave = {
        ...section,
      };
      if (!toSave.slug) {
        toSave.slug = `${page.id}-section-${++index}`;
      }
      try {
        if (section.id) {
          // FIXME hacky cache invalidation
          await api.page.update(toSave.id, toSave);
          cache.deleteExpiringMapEntry(CACHE_PAGE_KEY_V2, toSave.id);
          return { type: "update", id: section.id };
        }
        const result = await api.page.create(toSave);
        return { type: "create", id: result.id };
      } catch (e) {
        log.error("failed to save subpage", toSave, e);
        return null;
      }
    }));
  } catch (e) {
    log.error("failed to save subpages", e);
    return null;
  }
  await Promise.all(sections
    .filter((section) => section.type === "create")
    .map(async (section) => {
      await api.page.addSubPage(page.id, section.id);
    }));

  const toSave = {
    ...page,
    subPageIds: sections.map((s) => s.id),
  };
  // FIXME hacky cache invalidation
  cache.deleteExpiringMapEntry(CACHE_PAGE_KEY_V2, toSave.id);
  return api.page.update(toSave.id, toSave);
};

const reconcileSlideData = (section, imageDescriptors) => {
  if (section.type !== pageTypes.IMAGE_SLIDER) return section.searchData;
  const used = [];
  // probably not the most efficient way to do this...
  const newSlides = section.searchData?.slides.map((slide) => {
    if (slide.type !== slideTypes.IMAGE) return slide;
    if (slide.id) {
      const index = imageDescriptors.findIndex(
        (d, i) => !used.includes(i) && d.id === slide.id,
      );
      if (index !== -1) {
        used.push(index);
        return slide;
      }
    }
    const descriptor = imageDescriptors.find(
      (d, i) => !used.includes(i) && d.name === slide.name,
    );
    if (descriptor) {
      used.push(imageDescriptors.indexOf(descriptor));
      return merge(slide, { id: descriptor.id });
    }
    if (used.length !== imageDescriptors.length) {
      log.warn("not all image descriptors were used", imageDescriptors, used);
    }
    log.error("no descriptor found for slide", slide);
    return merge(slide, { id: null });
  });
  newSlides.forEach((slide) => {
    if (slide.id === null) {
      throw new Error(`could not find matching descriptor for slide ${slide.name}`);
    }
  });
  return merge(section.searchData, { slides: newSlides });
};

/**
 * Handles images for a single section.
 *
 * While the page is being edited, new images may be added as a file name string if
 * there is no file descriptor for it. In that case, this handler creates a file
 * descriptor for the section.
 *
 * If there is already a file descriptor the section will have it already, so nothing
 * needs to be done.
 *
 * @function handleSectionImages
 * @private
 */
export const handleSectionImages = (formView, loadingView) => async (section) => {
  if (!section.images) return merge({}, section);
  loadingView.show(`Saving ${section.title} Images...`);
  try {
    /* eslint-disable-next-line no-param-reassign */
    const images = await Promise.all(
      section.images.map(
        async (image) => {
          // hopefully a file name...
          if (typeof image === "string") return api.file.createImage(image);
          // hopefully a file descriptor...
          if (typeof image === "object") return image;
          throw new Error("unrecognized section image data", image);
        },
      ),
    );
    loadingView.hide();
    return merge(section, {
      searchData: reconcileSlideData(section, images),
      images,
    });
  } catch (e) {
    log.error(e);
    formView.update({
      messages: [
        {
          text: `
            Something went wrong while saving ${section.title}'s images.
            Please report this to your site administrator.
          `,
          type: "error",
        },
      ],
    });
    loadingView.hide();
    throw (e);
  }
};
