/**
 * Utilities for retrieving data from cache.
 *
 * @module data/stream/cache
 */
import ohash from "object-hash";
import cache from "cache";
import { tagPlaceholder, tagCached } from "data/util/tags";

export const makeCompoundCacheKey = (key) => {
  let compoundKey;
  if (!key) throw Error("makeCompoundCacheKey: key may not be falsy");
  if (typeof key === 'string') compoundKey = key;
  else compoundKey = ohash(key);
  return compoundKey;
};

const defaultGetCacheKey = (item) => item.id;

const defaultFactory = (id) => ({ id });

/**
 * Looks up an item from the cache or returns a default if it can't find one.
 */
export const fromCacheMap = (
  cacheKey,
  modelFn = defaultFactory,
  makeCacheKey = makeCompoundCacheKey,
) => (itemKey) => {
  let cached = cache.getExpiredMapEntry(cacheKey, makeCacheKey(itemKey));
  if (!cached) cached = tagPlaceholder(modelFn(itemKey));
  else cached = tagCached(cached);
  return cached;
};

export const fromCacheMany = (
  cacheKey,
  defFn = defaultFactory,
  makeCacheKey = makeCompoundCacheKey,
) => (mapKeys) => mapKeys.map(
  (key) => fromCacheMap(cacheKey, defFn, makeCacheKey)(key),
);

export const inCache = (cacheKey) => () => cache.isValid(cacheKey);

export const notInCache = (cacheKey) => () => !cache.isValid(cacheKey);

export const inCacheMap = (cacheKey) => (mapKey) => cache
  .mapEntryIsValid(cacheKey, makeCompoundCacheKey(mapKey));

export const notInCacheMap = (cacheKey) => (mapKey) => !cache
  .mapEntryIsValid(cacheKey, makeCompoundCacheKey(mapKey));

export const excludeCachedEntries = (cacheKey, alwaysUpdate = false) => (itemKeys) => {
  if (alwaysUpdate) return itemKeys;
  return (
    itemKeys instanceof Array
      ? itemKeys.filter(
        (itemKey) => !cache.mapEntryIsValid(cacheKey, makeCompoundCacheKey(itemKey)),
      )
      : []
  );
};

/**
 * Put an item in the cache.
 * @param {string} cacheKey local storage key for the map of cached items
 * @param {function} getCacheItemKeyFromItem in the form of fn(item: obj) -> string
 * @return {function} fn(item)
 */
export const toCacheMap = (cacheKey, getCacheItemKeyFromItem = defaultGetCacheKey) => (item) => {
  if (item) {
    cache.storeExpiringMapEntry(
      cacheKey,
      makeCompoundCacheKey(getCacheItemKeyFromItem(item)),
      item,
    );
  }
};

/**
 * Put an item in the cache.
 * @param {string} cacheKey local storage key for the map of cached items
 * @param {function} getCacheItemKeyFromItem in the form of fn(item: obj) -> string
 * @return {function} fn(item[])
 */
export const toCacheMany = (
  cacheKey,
  getCacheKeyItemFromItem = defaultGetCacheKey,
) => (items) => items.map(
  (item) => toCacheMap(cacheKey, getCacheKeyItemFromItem)(item),
);

export const toCache = (cacheKey) => (item) => cache.storeExpiring(cacheKey, item);

export const fromCache = (cacheKey, defFn = () => {}) => () => cache.getExpired(
  cacheKey,
) || defFn();

export const taggedMap = (mapFn) => ([tag, marble]) => [tag, mapFn(marble)];
