/**
 * A context-sensitive view / open button for all content types.
 *
 * @module ui/component/button-content-view
 * @category Components
 * @subcategory Metadata
 */
import { metadataTypes } from "model/metadata/constants";
import spinner from "ui/component/spinner";
import button from "ui/component/form-managed/button";
import { getModal } from "ui/view/modal-view";
import { isNowWithin, MIN_DATE, MAX_DATE } from "util/date";
import { documentUrl, videoUrl, documentUrlDirect } from "util/metadata";
import { isPWA } from "util/pwa";
import { frozen, merge } from "util/object";

const defaultState = frozen({
  metadata: null,
  onClick: () => {},
  sameTab: true,
  sel: "",
});

const defaultOnClick = () => { /* do nothing */ };

const hasVideos = (metadata) => (
  !metadata.ABSTRACT
  && metadata.items?.some(
    (item) => item.type === metadataTypes.VIDEO && isNowWithin(item.startDate, item.endDate),
  )
);

const listButton = ({ metadata, onClick = defaultOnClick, sameTab = true, sel = "" }) => (hasVideos(metadata)
  ? button({
    label: "watch all",
    onClick: () => {
      onClick();
      const url = `/video?type=${metadata.type}&id=${metadata.id}`;
      if (sameTab || isPWA()) window.location.assign(url);
      else window.open(url, "_blank");
    },
    sel,
  }) : "");

const documentButton = ({ metadata, onClick = defaultOnClick, sameTab = true, sel = "" }) => {
  if (!metadata.documentFile) return spinner();
  if (isPWA()) {
    return button({
      label: "download",
      onClick: () => {
        const url = documentUrl(metadata);
        if (!url) {
          getModal().alert("This document is not available at this time.");
          return;
        }
        onClick();
        window.location.assign(documentUrlDirect(metadata));
      },
      sel,
    });
  }
  return button({
    label: "open",
    onClick: () => {
      const url = documentUrl(metadata);
      if (!url) {
        getModal().alert("This document is not available at this time.");
        return;
      }
      onClick();
      if (sameTab) window.location.assign(url);
      else window.open(url, "_blank");
    },
    sel,
  });
};

const videoButton = ({ metadata, onClick = defaultOnClick, sameTab = true, sel = "" }) => button({
  label: "watch",
  onClick: () => {
    onClick();
    const url = videoUrl(metadata);
    if (sameTab || isPWA()) window.location.assign(url);
    else window.open(url, "_blank");
  },
  sel,
});

const unavailableButton = () => button({
  label: "unavailable",
  disabled: true,
});

/**
 * A context-sensitive view / open button for all content types.
 *
 * Sets an appropriate label based on whether the item is available and what
 * metadata type it is.
 *
 * If no useful view button can be created, returns an empty string.
 *
 * @function contentViewButton
 * @param {object} state
 * @param {Metadata} state.metadata
 * @param {Selector} state.sel
 * @param {function} onClick called before opening URL
 * @param {boolean} sameTab if false, will open target=_blank except in PWA
 * @return {El|string}
 */
export default function contentViewButton(inState) {
  const state = merge(defaultState, inState);
  const { metadata } = state;
  if (!isNowWithin(metadata.startDate || MIN_DATE, metadata.endDate || MAX_DATE)) {
    return unavailableButton();
  }
  switch (metadata.type) {
    case metadataTypes.DOCUMENT:
      return documentButton(state);
    case metadataTypes.VIDEO:
      return videoButton(state);
    case metadataTypes.LIST:
    case metadataTypes.DYNAMIC_LIST:
      return listButton(state);
    default:
      return "";
  }
}
