/**
 * Help section for the bulk content form.
 *
 * @module ui/page/admin/content/bulk/help
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import {
  a,
  button,
  li,
  ol,
  p,
  ul,
  section,
} from "ui/html";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

export default function helpSection({ formView, server, showUploadFiles }) {
  return section("#help", [
    button(
      "Need to upload more files?",
      () => showUploadFiles(formView, server),
      ".ok",
    ),
    collapsible({
      title: "How do I use the CSV template?",
    }, [
      p([
        `Download the CSV template here `,
        a(
          [icon.solid("file-download"), "template CSV file"],
          "/media/content_bulk_upload_template.csv",
        ),
        `. Enter the user information into the spreadsheet.`,
      ]),
    ]), // spreadsheet format
    collapsible({
      title: "How do I save my spreadsheet in CSV format?",
    }, [
      collapsible({
        title: "Using Google Sheets",
      }, ol([
        li(`Click "File" in the main menu.`),
        li(`Click "Download".`),
        li(`Select "Comma separated values (.csv, current sheet)".`),
      ])),
      collapsible({
        title: "Using Open/Libre Office",
      }, ol([
        li(`Click "File" in the main menu.`),
        li(`Click "Save As".`),
        li(`Select "Text CSV (.csv)" in the file type dropdown menu.`),
        li([
          `An options dialog will appear if your spreadsheet is in a different format
           than CSV. Ensure the following options are set:`,
          ul([
            li(`"Character set" is set to "Unicode (UTF-8)".`),
            li(`"Field delimiter" is a comma (,).`),
            li(`"String delimiter" is a double-quote (").`),
            li(`"Save cell content as shown" is checked.`),
            li(`"Save cell formulas instead of calculated values" is un-checked.`),
            li(`"Quote all text cells" is checked.`),
            li(`"Fixed column width" is un-checked.`),
          ]),
        ]),
        li(`Click "ok" to save the file.`),
      ])),
      collapsible({
        title: "Using Microsoft Excel",
      }, ol([
        li(`Go to "File -> Save As" in the main menu.`),
        li(`Click "Browse".`),
        li(`In the "Save As" dialog box, under the "Save as Type" box, choose "CSV (comma delimited)".`),
        li(`Click "Save".`),
      ])),
    ]),
    collapsible({
      title: "What if I already have a spreadsheet in a different layout?",
    }, [
      p(`SmartEdge will do its best to find the column labels it needs, provided the first row of your 
      spreadsheet contains labels. It will ignore any columns it doesn't recognize, and attempt to convert 
      any cell values it finds to its required format. This will not always work, so when possible it's 
      best to follow the specs.`),
      p(`After you have prepared the CSV, go to Import CSV to upload the completed CSV. Our verification 
      system will scan for any issues and confirm the correct metadata entries. Double check and verify 
      the necessary changes in red.`),
      p(`Click SAVE. When the Status column for each line item displays green icon, 
      you have successfully uploaded all content metadata.`),
      p(`The user addition bar will indicate the progress status at the bottom of the screen. 
      Please confirm the newly uploaded and created content metadata on the Manage Content interface. 
      You’re all set!`),
    ]),
    collapsible({
      title: "What format should my spreadsheet be in?",
    }, [
      p(`For the most reliable results, please ensure your spreadsheet file adheres to the
         following format:`),
      ul([
        li("The first row of the sheet should contain column labels for each column."),
        li([
          "The columns should be labeled with the following text and in the same order, starting from the first column:",
          ul([
            li("Type"),
            li("Title"),
            li("Description"),
            li("Labels"),
            li("Poster File Name"),
            li("Content File Name"),
            li("(Any other columns after these will be ignored)"),
          ]),
        ]),
        li(`The type column must contain only the word "video" or "document". Other entries will not be recognized.`),
        li(`Titles must be no more than 255 characters long. Titles exceeding this length will be truncated.`),
        li(`Labels should be separated by commas, like so: "label 1, label 2, label 3".`),
        li(`Poster and content file names should contain only the file name itself - no folders or directories.`),
        li(`File names should contain only letters, numbers and underscores, and must match the name of the files you intend to upload.`),
        li(`If you have previously uploaded a file and it's still available in the file management tool, you do not need to re-upload it.`),
        li(`Each poster and content file for each row should be uniquely named, even if they are copies.`),
      ]),
    ]),
    p([
      "If you have any more questions, please visit our ",
      a("Admin FAQ", "/admin/admin-faq"),
      " for more help",
    ]),
  ]);
}
