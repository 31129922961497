/**
 * A widget for showing page cameos list.
 *
 * @module ui/component/page-list-widget
 * @category UI
 * @subcategory Components
 */
import { button, div, span } from "ui/html";
import cameoPage from "ui/component/cameo-page";
import icon from "ui/component/icon";

const sortByTitle = (pages) => [...pages].sort((a, b) => a.title.localeCompare(b.title));

/**
 * A widget that shows a list of page cameos.
 *
 * Accepts an optional `onCreatePage` callback. If present, a `Create Page` button will
 * be presented that calls the callback when clicked. The outer view is responsible for
 * implementing this callback function and directing the user to page creation with
 * appropriate parameters.
 *
 * @function pageListWidget
 * @param {DynamicPage[]} pages
 * @param {function} onCreatePage
 * @return {ui/html/div~Div}
 */
export default function pageListWidget({
  pages = [],
  onCreatePage = null,
}, modalView) {
  if (!(pages instanceof Array)) throw new Error("pages must be an array");
  return div(
    ".page-list-cameos",
    [
      ...(sortByTitle(pages).map((page) => cameoPage({ page }, modalView))),
      onCreatePage
        ? button(
          [icon("file-alt"), span("Create Page")],
          onCreatePage,
          ".stand-in",
          "button",
          "Create a page with this content.",
        )
        : "",
    ],
  );
}
