/**
 * The login view for the login page.
 * @module ui/page/login/login
 * @private
 * @category Pages
 * @subcategory User Accounts */
/** */
import api from "api";
import { featureTypes, verificationMethods } from "model/config/constants";
import form from "ui/component/form-managed";
import username from "ui/component/form-managed/field-username";
import password from "ui/component/form-managed/field-password";
import button from "ui/component/form-managed/button";
import h1 from "ui/html/h1";
import { merge } from "util/object";
import { tidyBackendError } from "util/error";
import { featureEnabled } from "util/feature-flag";
import messages from "./messages";
import {
  initPasswordResetUsername,
  initRegister,
  initVerification,
} from "./common";

/**
 * Handles submission of the login form.
 *
 * @function doLogin
 * @private
 */
export const doLogin = async (self, notification, rethrow = false) => {
  self.setFullValidation(true);

  if (!self.validate().valid) {
    notification.post(messages.invalid);
    return;
  }

  const { values } = self;
  const { userVerification } = self.state.config.server;

  try {
    self.disable();
    const response = await api.authentication.login(values);
    if (response?.accessToken) {
      window.location.assign(self.state.redirect);
    }
  } catch (error) {
    if (rethrow) {
      throw error;
    }
    let message;
    switch (error.statusCode) {
      case 404: // user does not exist
        notification.post(messages.signInInvalid);
        break;
      case 403: // user exists but is unverified
        switch (userVerification) {
          case verificationMethods.OTP:
            message = messages.verificationRequiredBoth;
            break;
          case verificationMethods.DISABLED:
            message = messages.verificationRequiredDisabled;
            break;
          case verificationMethods.ADMIN:
          default:
            message = messages.verificationRequiredAdmin;
            break;
        }
        notification.post(message);
        self.updateState({ login: self.values });
        initVerification(self, self.values.username);
        break;
      case 401: // usually an LDAP auth failure, may supercede 404/403
        notification.post(messages.signInInvalid);
        break;
      default:
        tidyBackendError(error).map((text) => notification.post(
          merge(messages.apiError, { text }),
        ));
    }
  }
  self.enable();
};

/**
 * Show the login form.
 *
 * @function showLogin
 * @private
 * @param {View} page
 */
export default (self, modal, notification) => form(
  "#login-form",
  self.bind([
    h1("Login"),
    [username, { required: true }],
    [password, { weak: self.state.config.server.ldapEnabled, required: true }],
    [button, {
      label: "Login",
      onClick: () => doLogin(self, notification),
      sel: "#submit-login",
      disabled: !self.validate().valid,
    }],
    [button, {
      label: "Forgot Password?",
      onClick: () => initPasswordResetUsername(self, modal, notification),
      sel: "#forgot-password.subtle.back.alternate",
    }],
    featureEnabled(featureTypes.REGISTRATION) ? [button, {
      label: "Don't have an account? Register here.",
      onClick: () => initRegister(self, modal, notification),
      sel: "#sign-up.button.subtle.secondary",
    }] : "",
  ], self.state.login),
  { on: { keypress: (e) => e.which === 13 && doLogin(self, notification) } },
);
