/**
 * A cameo for dynamic pages.
 *
 * @module ui/component/cameo-page
 * @category UI
 * @subcategory Components
 */
import span from "ui/html/span";
import button from "ui/component/form-managed/button";
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";

/**
 * A summary display of a page item.
 *
 * @function cameoPage
 * @param {object} state
 * @param {DynamicPage} page
 * @param {?ChildEl} controls additional buttons
 */
export default function cameoPage({
  page,
  controls = [],
  config = null,
}) {
  return page ? cameo({
    label: page.title,
    icon: icon("file-alt"),
    controls: [
      button.accent({
        iconOnly: "true",
        icon: "external-link",
        label: "Open in new tab",
        onClick: () => {
          window.open(`/admin/page-profile?id=${page.id}`, "_blank");
        },
      }),
      ...controls,
    ],
    sel: ".cameo-page",
    config,
  }) : span(
    [icon("question-circle"), span("No Selection")],
    ".page.stand-in",
  );
}
