/**
 * API calls for dynamic page endpoints.
 *
 * @module api/page
 * @category Backend API
 * @subcategory Page
 */
import log from "log";
import { endpoints } from "api/constants";
import { getPlaylist } from "api/metadata";
import { getCourseById } from "api/course";
import { EXPAND_COURSES } from "api/course/constants";
import {
  get,
  post,
  put,
  del,
} from "api/request";
import cache from "cache";
import {
  responseToPage,
  makePageDTO,
  makeSubPageDTO,
} from "model/dynamic-page";
import { pageTypes } from "model/dynamic-page/constants";
import { MAIN_MENU_KEY } from "model/site/constants";
import { merge } from "util/object";
import { CACHE_PAGE_KEY, CACHE_PAGE_SLUG_KEY } from "./constants";
import { storePageDTO, removePageDTO } from "./cache";
import { doSearch } from "./search";

/**
 * Find a single page by its id.
 *
 * @param {UUID} uuid
 * @return {DynamicPage}
 */
export const byId = async (uuid, skipCache = false) => {
  let page;
  if (!skipCache) {
    page = cache.getExpiringMapEntry(CACHE_PAGE_KEY, uuid);
  }
  if (!page) {
    page = (await get(`${endpoints.PAGE}/${uuid}`)).body;
  }
  if (page) {
    storePageDTO(page);
    return responseToPage(page);
  }
  return null;
};

/**
 * Find multiple pages by their IDs.
 *
 * @param {Array<UUID>} uuids
 * @param {boolean} [skipCache=false]
 * @return {Array<DynamicPage>}
 */
export const allById = async (uuids, skipCache = false) => (await Promise.all(
  uuids.map((uuid) => byId(uuid, skipCache).catch(() => null)),
)).filter((res) => res !== null);

const trimParentPage = (partial) => ({
  id: partial.id,
  slug: partial.slug,
  title: partial.title,
  parent: partial.parent || null,
});

const getItemByPageType = (page) => async () => {
  switch (page.type) {
    case pageTypes.PLAYLIST_CAROUSEL:
    case pageTypes.PLAYLIST_GRID:
    case pageTypes.DYNAMIC_CAROUSEL:
    case pageTypes.DYNAMIC_GRID:
      return getPlaylist(page.itemId);
    case pageTypes.COURSE_OVERVIEW:
    case pageTypes.COURSE_MODULE_CAROUSEL:
    case pageTypes.COURSE_MODULE_GRID:
      return getCourseById(page.itemId, false, EXPAND_COURSES);
    default:
      return Promise.resolve(null);
  }
};

/**
 * Expands the abbreviated properties of a page (those that are stored as UUIDs or
 * search parameters), adding the referenced objects as properties of the page model.
 *
 * If the recursive parameter is `true`, it will also expand the properties of any
 * sub-pages recursively. This can be bandwidth intensive on first load, and should be
 * used mainly for displaying a page. Subsequent loads should find relevant data in
 * cache.
 *
 * If the isParent parameter is `true` and the page is being expanded recursively, only
 * an abbreviated entry will be provided for this and any further ancestors.
 *
 * @param {DynamicPage} page
 * @param {boolean} [skipCache=false]
 * @param {boolean} [recursive=false] recursively expand parents and children
 * @param {boolean} [isParent=false] was requested as a recursive expansion of a parent
 * @return {ExpandedDynamicPage}
 */
export const expand = async (page, skipCache = false, recursive = false, isParent = false) => {
  // other page APIs may return a null result; this allows fire-and-forget usage
  if (!page) return null;

  const requests = [];

  if (page.parentId) {
    requests.push({
      type: "parent",
      // note: skipCache explicitly omitted here; we rarely care if minor changes were made
      // and this makes loading nested pages very slow on Manage Pages
      call: () => byId(page.parentId).catch(() => null),
    });
  }

  if (!isParent) {
    if (page.subPageIds?.length) {
      requests.push({
        type: "subpages",
        call: () => allById(page.subPageIds, skipCache).catch(() => null),
      });
    }

    if (page.itemId) {
      requests.push({
        type: "item",
        call: getItemByPageType(page),
      });
    }

    if (page.searchData?.mode) {
      requests.push({
        type: "search",
        call: () => doSearch(page.searchData),
      });
    }
  } // end is parent stuff

  const results = await Promise.all(requests.map(async (req) => {
    try {
      const content = await req.call();
      return ({
        type: req.type,
        content,
      });
    } catch (e) {
      log.error("DYNAMIC PAGE API :: request error for content item", e);
      return null;
    }
  }));

  // merge, then restructure to remove immutability
  const out = merge({}, page);
  const children = [];
  const sections = [];
  let searchResults = [];

  // have to await here in case we're recursively expanding
  await Promise.all(results.map(async (result) => {
    let subpages;
    switch (result?.type) {
      case "subpages":
        if (recursive) {
          subpages = await Promise.all(
            result.content.map((sp) => expand(sp, skipCache, recursive)),
            skipCache,
          );
        } else subpages = result.content;
        subpages.forEach((sp) => {
          if (sp.type === pageTypes.INDEPENDENT) {
            children.push(sp);
          } else {
            sections.push(sp);
          }
        });
        break;
      case "item":
        out.item = result.content;
        break;
      case "parent":
        if (result.content) {
          out.parent = trimParentPage(await expand(result.content, false, recursive, true));
        } else {
          log.error("invalid parent page", out.parentId, "for subpage", out.id);
          out.parent = null;
        }
        break;
      case "search":
        searchResults = result.content;
        break;
      default:
        break;
    }
  }));

  if (searchResults.length) out.searchResults = searchResults;
  if (children.length) out.children = children;
  if (sections.length) out.sections = sections;

  return out;
};

/**
 * Find a single page by its slug.
 *
 * @param {string} slug
 * @return {?DynamicPage}
 */
export const bySlug = async (slug, skipCache = false) => {
  let page;
  if (!skipCache) {
    page = cache.getExpiringMapEntry(
      CACHE_PAGE_KEY,
      cache.getExpiringMapEntry(CACHE_PAGE_SLUG_KEY, slug),
    );
  }
  if (!page) {
    page = (await get(`${endpoints.PAGE_SLUG}/${slug}`)).body;
  }
  if (page) {
    storePageDTO(page);
    return responseToPage(page);
  }
  return null;
};

/**
 * Get a list of all pages.
 *
 * @return {Array<DynamicPage>}
 */
export const list = async () => {
  const response = (await get(endpoints.PAGE)).body;
  if (response) {
    response.forEach((page) => storePageDTO(page));
    return response.map(responseToPage);
  }
  return [];
};

/**
 * Create a new page.
 *
 * @function create
 * @param {object} partial matching {@link module:api/types/page~PageDTO}
 * @return {DyanmicPage}
 */
export const create = async (partial) => {
  const response = (await post(
    endpoints.PAGE,
    null,
    makePageDTO(partial),
    true,
  )).body;
  storePageDTO(response);
  return expand(responseToPage(response));
};

/**
 * Update an existing page.
 *
 * @function update
 * @param {string} uuid the page's UUID
 * @param {object} partial matching {@link module:api/types/page~PageDTO}
 * @return {DyanmicPage}
 */
export const update = async (uuid, partial) => {
  const response = (await put(
    `${endpoints.PAGE}/${uuid}`,
    null,
    makePageDTO(partial),
    true,
  )).body;
  if (response) {
    storePageDTO(response);
    cache.deleteValue(MAIN_MENU_KEY);
    return responseToPage(response);
  }
  return null;
};

/**
 * Delete a page.
 *
 * @function deleteById
 * @param {string} uuid the page's UUID
 * @return boolean indicating success
 */
export const deleteById = async (uuid) => {
  const result = (await del(
    `${endpoints.PAGE}/${uuid}`,
  )).ok;
  if (result) {
    removePageDTO(uuid);
    // menu cache needs to be invalidated
    // (other users will have their menus bootstrapped after a failed page load)
    cache.deleteValue(MAIN_MENU_KEY);
  }
  return result;
};

/**
 * Add a sub-page to a page in the page hierarchy.
 *
 * @function addSubPage
 * @param {UUID} parentId the parent page ID
 * @param {UUID} childId the child page ID
 * @return {DynamicPage} the parent page, with child attached
 */
export const addSubPage = async (parentId, childId) => (await put(
  `${endpoints.PAGE_SUBPAGE}/${parentId}`,
  null,
  makeSubPageDTO({ id: childId }),
  true,
)).body;

/**
 * Remove a sub-page from a page in the page hierarchy.
 *
 * @function removeSubPage
 * @param {UUID} parentId the parent page ID
 * @param {UUID} childId the child page ID
 * @return {DynamicPage} the parent page, with child attached
 */
export const removeSubPage = async (parentId, childId) => (await del(
  `${endpoints.PAGE_SUBPAGE}/${parentId}`,
  null,
  makeSubPageDTO({ id: childId }),
  true,
)).body;

/**
 * List all the pages that a piece of content is attached to
 *
 * @param {string} itemId abstract item id
 * @returns {Promise<DynamicPage[]>}
 */
export const listPageReferences = async (itemId) => {
  try {
    const response = (await get(`${endpoints.PAGE_SEARCH}/${itemId}`)).body;
    if (response) {
      const pagesWithChildren = response.map(responseToPage);
      const rootPages = pagesWithChildren.filter((page) => !page.parentId);
      const uniqueParents = Array
        .from(new Set(pagesWithChildren.map((page) => page.parentId)))
        .filter((id) => id);
      return allById([...rootPages, ...uniqueParents]);
    }
  } catch (e) {
    // not a problem
  }
  return [];
};
