/**
 * Modes for dynamic playlist page sections.
 *
 * @enum {string} searchDataModes
 * @readonly
 * @property {string} PRESET preset types have static parameters
 * @property {string} ADVANCED types allow the user to select search parameters
 */
export const searchDataModes = Object.freeze({
  PRESET: "preset",
  ADVANCED: "advanced",
});

/**
 * Collection of value strings from [searchDataModes]{@link searchDataModes}
 *
 * @constant searchDataModesSet
 * @type {Set.<string>}
 * @readonly
 */
export const searchDataModesSet = Object.freeze(new Set(Object.values(searchDataModes)));

/**
 * Map of user-friendly labels for search data modes.
 *
 * @constant searchDataModesLabeled
 * @type {Map.<string, string>}
 * @readonly
 */
export const searchDataModesLabeled = Object.freeze(new Map([
  [searchDataModes.PRESET, "Preset"],
  [searchDataModes.ADVANCED, "Advanced"],
]));

/**
 * Presets for dynamic playlist page sections.
 *
 * @enum {string} searchDataPresets
 * @readonly
 * @property {string} RECENTLY_ADDED a list of recently added metadata items
 * @property {string} RECENTLY_WATCHED a list of videos the user recently watched
 * @property {string} MOST_WATCHED most watched videos by all users
 * @property {string} TRENDING currently trending videos
 */
export const searchDataPresets = Object.freeze({
  RECENTLY_ADDED: "RECENTLY_ADDED",
  RECENTLY_WATCHED: "RECENTLY_WATCHED",
  MOST_WATCHED: "MOST_WATCHED",
  TRENDING: "TRENDING",
});

/**
 * The set of all possible search data presets for dynamic list page sections.
 *
 * @constant searchDataPresetsSet
 * @type {Set.<string>}
 * @readonly
 * @deprecated
 */
export const searchDataPresetsSet = Object.freeze(new Set(Object.values(searchDataPresets)));

/**
 * A map of human-readable labels for search data presets.
 *
 * @constant searchDataPresetsLabeled
 * @type {Set.<string>}
 * @readonly
 * @deprecated
 */
export const searchDataPresetsLabeled = Object.freeze(new Map([
  [searchDataPresets.RECENTLY_ADDED, "Recently Added"],
  [searchDataPresets.RECENTLY_WATCHED, "Recently Watched"],
  [searchDataPresets.MOST_WATCHED, "Most Watched"],
  [searchDataPresets.TRENDING, "Trending"],
]));
