import log from "log";
import { questionType } from "model/course/constants";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import answerEditModal from "ui/component/modal/course/answer-edit";
import multipleChoiceModal from "ui/component/modal/assessment/question-multiple-choice";
import questionFileDropboxModal from "ui/component/modal/assessment/question-file-dropbox";
import questionTextEntryModal from "ui/component/modal/assessment/question-text-entry";
import questionTypeSelectModal from "ui/component/modal/assessment/question-type-select";
import { button } from "ui/html";

export default (modalView) => collapsible(
  { title: "Assessments", open: false },
  [
    button(
      ["Answer Edit Modal", icon.solid("tag")],
      async () => {
        const answer = await modalView.async(answerEditModal({}));
        log.debug("user created answer", answer);
      },
      ".subtle.forward",
    ),
    button(
      ["Question Type Modal", icon.solid("tag")],
      async () => {
        const question = await modalView.async(questionTypeSelectModal());
        log.debug("user created question", question);
      },
      ".subtle.forward",
    ),
    button(
      ["Multiple choice modal", icon.solid("tag")],
      async () => {
        const question = await modalView.async(multipleChoiceModal({}));
        log.debug("user created question", question);
      },
      ".subtle.forward",
    ),
    button(
      ["Checkbox", icon.solid("tag")],
      async () => {
        const question = await modalView.async(multipleChoiceModal({
          question: {
            questionType: questionType.MULTIPLE_CHOICE_MULTI_ANSWER,
          },
        }));
        log.debug("user created question", question);
      },
      ".subtle.forward",
    ),
    button(
      ["Text Entry", icon.solid("tag")],
      async () => {
        const question = await modalView.async(questionTextEntryModal({}));
        log.debug("user created question", question);
      },
      ".subtle.forward",
    ),
    button(
      ["File Dropbox", icon.solid("tag")],
      async () => {
        const question = await modalView.async(questionFileDropboxModal({}));
        log.debug("user created question", question);
      },
      ".subtle.forward",
    ),
  ],
);
