/**
 * A modal dialog for selecting a course.
 *
 * @module ui/component/modal/course-select
 * @category UI
 * @subcategory Modal Dialogs
 */

import cameoCourse from "ui/component/cameo-course";
import listModal from "ui/component/modal/layout/list";
import { courseTextFilter } from "util/filter";
import { getModal } from "ui/view/modal-view";

const doSearch = (courses) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(courseSelectModal({
    courses,
    search,
  }));
};

const sortByTitle = (a, b) => {
  const at = a.title.toLowerCase();
  const bt = b.title.toLowerCase();
  if (at < bt) return -1;
  if (at > bt) return 1;
  return 0;
};

const entryLine = (course) => cameoCourse({
  course,
  config: {
    on: {
      click: () => getModal().resolve(course),
    },
  },
});

export default function courseSelectModal({
  courses,
  search = "",
}) {
  return listModal({
    entries: courses
      .filter(courseTextFilter(search))
      .sort(sortByTitle)
      .map(entryLine),
    searchText: search,
    onSearch: doSearch(courses),
    sel: ".course-select",
  });
}
