/**
 * A modal for doing advanced assessment searches on the manage assessment page.
 *
 * @module ui/component/modal/advanced-assessment-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { frozen, merge } from "util/object";
import dateTime from "ui/component/form-managed/field-date-time";
import form from "ui/component/form-managed";
import input from "ui/component/form-managed/field-input";
import button from "ui/component/form-managed/button";
import closeFab from "ui/component/modal/component/close-fab";
import {
  div, h3, label, span,
} from "ui/html";

const getCurrentParams = (modalView, params) => {
  const dqs = modalView.element.querySelector.bind(modalView.element);
  return {
    title: dqs("[name=title]").value || null,
    startDate: params.startDate || null,
    endDate: params.endDate || null,
    gradingScheme: dqs("[name=gradingScheme]").value,
    sortOrder: dqs("[name=sort-order]").value,
    sortField: dqs("[name=sort-field]").value,
  };
};

const doSearch = (modalView, params) => {
  modalView.resolve(getCurrentParams(modalView, params));
};

const defaultParams = frozen({
  title: "",
  startDate: "",
  endDate: "",
  gradingScheme: "",
  sortField: "",
  sortOrder: "",
});

const defaultState = frozen({
  params: defaultParams,
});

/**
 * An async modal dialog for doing advanced searches for assessments.
 *
 * @param {object} inState
 * @param {module:ui/view/modal~modalView} modalView modal dialog view
 * @return {?object} updated search parameters for use with advancedAssessmentFilter
 */
const advancedAssessmentSearchModal = (inState, modalView = null) => {
  const state = merge(defaultState, inState);
  const { params } = state;
  return div(
    ".dialog.form.advanced-search.advanced-assessment-search",
    [
      closeFab(true, modalView),
      div(".content", [
        div(".body", form("#advanced-form", [
          div(".header", h3("Advanced Search")),
          input({
            type: "hidden",
            value: params.sortOrder,
            name: "sort-order",
          }),
          input({
            type: "hidden",
            value: params.sortField,
            name: "sort-field",
          }),
          div("", [
            label("Title"),
            input({
              value: params.title,
              name: "title",
              label: "Title",
            }),
          ]),
          div("", [
            span("Start Date"),
            dateTime({
              name: "startDate",
              value: params.startDate || new Date(),
              onChange: (date) => {
                params.startDate = date;
              },
            }),
          ]),
          div("", [
            span("End Date"),
            dateTime({
              name: "endDate",
              value: params.endDate || new Date(),
              onChange: (date) => {
                params.endDate = date;
              },
            }),
          ]),
          div("", [
            label("Grading Scheme"),
            input({
              value: params.gradingScheme,
              name: "gradingScheme",
              label: "Grading Scheme",
            }),
          ]),
          div(".buttons", [
            button.primary({
              label: "Search",
              onClick: () => doSearch(modalView, params),
            }),
          ]),
        ])),
      ]),
    ],
  );
};

export default advancedAssessmentSearchModal;
