/**
 * The user-facing registration FAQ page.
 *
 * @module ui/page/faq/registration
 * @category Pages
 * @subcategory User Facing
 */
import {
  div, h1, p, h3, a,
} from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "registration-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showRegistrationFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Registration"]),
  collapsible({
    title: "How do I register for SmartEdge?",
    autoCloseConfiguration,
  }, p(`Head to our registration page! From there, you'll be asked to enter your chosen username,
   mobile number, and email address. After that, you can create a password. We'll send a one-time passcode
   (OTP) via e-mail or text. Once the OTP is entered, your account will be confirmed and created.
   Feel free to use the SmartEdge system. Have fun!`)),
  collapsible({
    title: "What happens if I have external credentials?",
    autoCloseConfiguration,
  }, p(`If your organization’s admin has given you a separate set of user credentials, please use
   them to log into your account. They are required to access your school’s SmartEdge system and library.`)),
  collapsible({
    title: "What happens after I enter the OTP?",
    autoCloseConfiguration,
  }, p(`Your account will be verified and you will be taken back to the login page to re-enter your
   new username/password. If we cannot verify your identity, you'll need to contact your organization’s SmartEdge admin.`)),
  collapsible({
    title: "I didn't receive an OTP through mobile or email. What do I do?",
    autoCloseConfiguration,
  }, p(`No worries! Please email the SmartEdge admin at your organization. They'll be able to
   assist you with account creation.`)),
  collapsible({
    title: "Does SmartEdge allow for multiple accounts for the same phone number or email address?",
    autoCloseConfiguration,
  }, p(`No. For security purposes, we do not allow an email or phone number to
   be associated with more than one account.`)),
]);

export default async function registrationFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Registration"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showRegistrationFaq)("#end-user-faq", state);
  loading.hide();
}
