/**
 * Utility functions for manipulating object structure.
 *
 * @module util/object
 * @category Utilities
 */
import deepmerge from "deepmerge";

/**
 * Recursively applies Object.freeze.
 *
 * Be careful of side-effects on shared objects here, as this freezes the object
 * in-place rather than creating a frozen deep copy.
 *
 * Supports Set and Map but may fail on other objects whose internal properties
 * are not enumerable. Also ignores HTMLElements because snabbdom wants to mutate
 * them.
 *
 * Not 100% guaranteed to always produce a perfectly immutable object -
 * for example, if an object's property is an object and is frozen, but its
 * own properties are unfrozen objects, they will not be recursed.
 *
 * @modifies obj somewhat ironically
 *
 * @function frozen
 * @param {object} object
 * @return {object} the input, recursively frozen
 */
export const frozen = (obj) => {
  if (obj instanceof HTMLElement) return obj;
  if (typeof obj !== "object") return obj;
  if (obj === null) return null;
  if (obj instanceof Map || obj instanceof Set) {
    [...obj.entries()].forEach(([k, v]) => {
      frozen(k);
      frozen(v);
    });
  }
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && !Object.isFrozen(obj[key])) {
      frozen(obj[key]);
    }
  });
  return Object.freeze(obj);
};

/**
 * Merge strategy for arrays: always overwrite arrays rather than concatenating them
 * @function arrayMerge
 * @private
 */
const arrayMerge = (dest, source) => source;

/**
 * Used to check if an object is not null before attmpting a merge.
 * @function isNonNullObject
 * @private
 */
const isNonNullObject = (value) => (!!value && typeof value === "object");

/**
 * Checks if an object is of a special type that shouldn't be merged.
 *
 * @function isSpecial
 * @private
 */
const isSpecial = (value) => {
  if (typeof window.File === "function" && value instanceof window.File) return true;
  const stringValue = Object.prototype.toString.call(value);

  return stringValue === "[object RegExp]"
    || stringValue === "[object Date]";
};

/**
 * Checks if an object is a map or a set, in which case it will overwrite instead
 * of merging.
 *
 * @function isMapOrSet
 * @private
 */
const isMapOrSet = (value) => (
  (value instanceof Map)
  || (value instanceof Set)
  || (value.doNotMergeOrClone)
);

/**
 * Check if an object is mergable. If it isn't it will be overwritten instead of being
 * merged with the new value.
 *
 * @function isMergeableObject
 * @private
 */
const isMergeableObject = (value) => (
  isNonNullObject(value)
  && !isSpecial(value)
  && !isMapOrSet(value)
);

/**
 * Performs a deep merge of two objects, returning a new object
 * as the union of the two parameters.
 *
 * Nested objects are cloned, and the underlying library supports
 * many special / non-enumerable object types, but it guarantees
 * neither 100% compatibility nor that all pass-by-reference
 * parameters will be cloned.
 *
 * Arrays are not merged - array properties in b will overwrite
 * arrays in b rather than being concatenated.
 *
 * Maps, HashMaps, Sets, RegExps, and Dates also overwrite instead of merging.
 *
 * @function merge
 * @param {object} a
 * @param {object} b
 * @return {object} merged object
 */
export const merge = (a, b) => deepmerge(a, b, { arrayMerge, isMergeableObject });

/**
 * Performs a deep merge of an array of objects, returning a new
 * object as a union of the contents of the passed array, in a
 * ascending order of precedence (the last item in the array takes
 * precedence over the previous, etc).
 *
 * See also {@link module:util/object~merge}
 *
 * Accessible as `merge.all` - jsdoc doesn't support documenting this directly
 *
 * @function mergeAll
 * @param {object[]} arr
 * @return {object} the deep merger of all objects in the array
 */
merge.all = (arr) => deepmerge.all(arr, { arrayMerge, isMergeableObject });

/**
 * Creates a deep copy of an object.
 *
 * See notes in [merge](#merge).
 *
 * @function clone
 * @param {object} obj
 * @return {object}
 */
export const clone = (obj) => merge({}, obj);
