import { metadataTypes } from "model/metadata/constants";
import { frozen, merge } from "util/object";

export const fakeLabels = frozen([
  { name: "Blender", description: "" },
  { name: "CGI", description: "" },
  { name: "Engineering", description: "" },
  { name: "Demo", description: "" },
  { name: "Test", description: "" },
  { name: "Family", description: "" },
  { name: "Comedy", description: "" },
  { name: "Networks", description: "" },
  { name: "DIY", description: "" },
]);

export const videoMetadata = frozen({
  id: "FAKE_VIDEO_FAKE",
  posterFile: {
    id: "FAKE_VIDEO_POSTER_FAKE",
    name: "fake_poster.jpg",
    type: "image/jpeg",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  posterUrl: "/media/logo-mark.svg",
  title: "Fake Metadata Entry",
  description: "This is a fake metadata entry for test purposes.",
  categories: fakeLabels.slice(0, 5),
  videoFile: {
    id: "FAKE_VIDEO_FILE_FAKE",
    name: "fake_file.mp4",
    type: "video/mp4",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  fileUrl: null,
  language: "en",
  year: 2008,
  width: 1024,
  height: 768,
  length: 60,
  viewCount: 0,
  type: metadataTypes.VIDEO,
  getPosterUrl: () => "/media/logo-mark.svg",
  getVideoFileUrl: () => "",
});

export const documentMetadata = frozen({
  id: "FAKE_PDF_FAKE",
  posterFile: {
    id: "FAKE_PDF_FILE_FAKE",
    name: "fake_poster.jpg",
    type: "image/jpeg",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  posterUrl: "/media/logo-mark.svg",
  title: "Fake PDF Metadata Entry",
  description: "This is a fake metadata entry for test purposes.",
  categories: fakeLabels.slice(3, 8),
  documentFile: {
    id: "FAKE_PDF_FILE_FAKE",
    name: "fake_file.pdf",
    type: "application/pdf",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  fileUrl: null,
  language: "en",
  year: 2008,
  viewCount: 0,
  type: metadataTypes.DOCUMENT,
  getPosterUrl: () => "/media/logo-mark.svg",
  getDocumentFileUrl: () => "",
});

export const videoMetadataList = frozen([
  { ...videoMetadata, title: "Fake Video Entry 1", id: "FAKE_VIDEO_FAKE_1" },
  { ...videoMetadata, title: "Fake Video Entry 2", id: "FAKE_VIDEO_FAKE_2" },
  { ...videoMetadata, title: "Fake Video Entry 3", id: "FAKE_VIDEO_FAKE_3" },
  { ...videoMetadata, title: "Fake Video Entry 4", id: "FAKE_VIDEO_FAKE_4" },
  { ...videoMetadata, title: "Fake Video Entry 5", id: "FAKE_VIDEO_FAKE_5" },
  { ...videoMetadata, title: "Fake Video Entry 6", id: "FAKE_VIDEO_FAKE_6" },
  { ...videoMetadata, title: "Fake Video Entry 7", id: "FAKE_VIDEO_FAKE_7" },
  { ...videoMetadata, title: "Fake Video Entry 8", id: "FAKE_VIDEO_FAKE_8" },
  { ...videoMetadata, title: "Fake Video Entry 9", id: "FAKE_VIDEO_FAKE_9" },
  { ...videoMetadata, title: "Fake Video Entry 10", id: "FAKE_VIDEO_FAKE_10" },
  { ...videoMetadata, title: "Fake Video Entry 11", id: "FAKE_VIDEO_FAKE_11" },
  { ...videoMetadata, title: "Fake Video Entry 12", id: "FAKE_VIDEO_FAKE_12" },
]);

export const documentMetadataList = frozen([
  { ...documentMetadata, title: "Fake PDF Entry 1", id: "FAKE_PDF_FAKE_1" },
  { ...documentMetadata, title: "Fake PDF Entry 2", id: "FAKE_PDF_FAKE_2" },
  { ...documentMetadata, title: "Fake PDF Entry 3", id: "FAKE_PDF_FAKE_3" },
  { ...documentMetadata, title: "Fake PDF Entry 4", id: "FAKE_PDF_FAKE_4" },
  { ...documentMetadata, title: "Fake PDF Entry 5", id: "FAKE_PDF_FAKE_5" },
  { ...documentMetadata, title: "Fake PDF Entry 6", id: "FAKE_PDF_FAKE_6" },
  { ...documentMetadata, title: "Fake PDF Entry 7", id: "FAKE_PDF_FAKE_7" },
  { ...documentMetadata, title: "Fake PDF Entry 8", id: "FAKE_PDF_FAKE_8" },
  { ...documentMetadata, title: "Fake PDF Entry 9", id: "FAKE_PDF_FAKE_9" },
  { ...documentMetadata, title: "Fake PDF Entry 10", id: "FAKE_PDF_FAKE_10" },
  { ...documentMetadata, title: "Fake PDF Entry 11", id: "FAKE_PDF_FAKE_11" },
  { ...documentMetadata, title: "Fake PDF Entry 12", id: "FAKE_PDF_FAKE_12" },
]);

export const playlistMetadata = frozen({
  id: "FAKE_PLAYLIST_FAKE",
  posterFile: {
    id: "FAKE_PLAYLIST_FILE_FAKE",
    name: "fake_poster.jpg",
    type: "image/jpeg",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  posterUrl: "/media/logo-mark.svg",
  title: "Fake Playlist Metadata Entry",
  description: "This is a fake metadata entry for test purposes.",
  categories: fakeLabels.slice(2, 7),
  documentFile: {
    id: "FAKE_PLAYLIST_FILE_FAKE",
    name: "fake_file.pdf",
    type: "application/pdf",
    fileId: "fake_file_id",
    checksum: "asdfasdfasdfasdfasdfasdf",
    size: 0,
  },
  fileUrl: null,
  language: "en",
  year: 2008,
  viewCount: 0,
  type: metadataTypes.LIST,
  items: videoMetadataList,
  getPosterUrl: () => "/media/logo-mark.svg",
  getDocumentFileUrl: () => "",
});

export const playlistMetadataList = [
  merge(playlistMetadata, { title: "Fake Playlist 1" }),
  merge(playlistMetadata, { title: "Fake Playlist 2" }),
  merge(playlistMetadata, { title: "Fake Playlist 3" }),
];

export const metadataList = frozen([
  ...videoMetadataList,
  ...documentMetadataList,
  ...playlistMetadataList,
]);
