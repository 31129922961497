/**
 * A modal for reported thread action.
 *
 * @module ui/component/modal/chat/reported-thread-action
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, span } from "ui/html";
import button from "ui/component/form-managed/button";
import { merge } from "util/object";
import { usFullDateFormat } from "util/date";
import { getThreadProfileLink, reportReasonsFriendly } from "ui/component/chat/common";

const defaultState = {
  threadName: "",
  report: null,
};

const buildBody = (state) => {
  const { report, threadName } = state;
  return div(
    ".reported-message-body",
    [
      div(".users", [
        span(threadName, ".thread-name"),
      ]),
      div(".container-status-and-date", [
        div(".date", usFullDateFormat(report.created)),
        div(".state", [
          span("Status: ", ".label-status"),
          span(report.status, ".value-status"),
        ]),
      ]),

      div(".report-reason", [
        span("Reason: ", ".label-status"),
        span(reportReasonsFriendly.get(report.reason), ".value-status"),
      ]),
      report.text
        ? div(".additional-information", [
          span("Additional Feedback: ", ".label-status"),
          div(".value-status", report.text),
        ])
        : "",
    ],
  );
};

const goToThreadProfile = (state) => {
  const { report } = state;
  window.location.assign(getThreadProfileLink(report.target));
};

export default function reportedThreadAction(inState, modalView) {
  const state = merge(defaultState, inState);

  return dialog({
    sel: ".reported-message-dialog",
    header: h3("Reported Thread Summary"),
    body: buildBody(state),
    footer: [
      button.primary({
        icon: "list",
        label: "Thread Profile",
        onClick: () => goToThreadProfile(state),
      }),
    ],
  }, modalView);
}
