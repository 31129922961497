/**
 * A modal dialog for selecting a page.
 *
 * @module ui/component/modal/page-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { pageTypes } from "model/dynamic-page/constants";
import listModal from "ui/component/modal/layout/list";
import cameoPage from "ui/component/cameo-page";
import { pageTextFilter, pageTypeFilter } from "util/filter";
import { getModal } from "ui/view/modal-view";

const doSearch = (pages) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(pageSelectModal({
    pages,
    search,
  }));
};

const sortByTitle = (a, b) => {
  const at = a.title.toLowerCase();
  const bt = b.title.toLowerCase();
  if (at < bt) return -1;
  if (at > bt) return 1;
  return 0;
};

const entryLine = (page) => cameoPage({
  page,
  config: {
    on: {
      click: () => {
        getModal().resolve(page);
      },
    },
  },
});

export default function pageSelectModal({
  pages,
  search = "",
}) {
  return listModal({
    entries: pages
      // FIXME double filter... yuck
      .filter(pageTypeFilter(pageTypes.INDEPENDENT))
      .filter(pageTextFilter(search))
      .sort(sortByTitle)
      .map(entryLine),
    searchText: search,
    onSearch: doSearch(pages),
    sel: ".page-select",
  });
}
