/**
 * Constants specific to file descriptors.
 *
 * @module model/file-descriptor/constants
 * @category Model
 * @subcategory File Descriptors
 */

/**
 * General classes of files.
 *
 * @enum {string} fileTypes
 * @readonly
 * @property {string} VIDEO a general video file
 * @property {string} DOCUMENT a general document file
 * @property {string} IMAGE an image file
 * @property {string} UNKNOWN an unidentifiable file type
 * @property {string} CSV a comma-separated-value table in plaintext
 * @property {string} SVG scalable vector graphics
 */
export const fileTypes = Object.freeze({
  VIDEO: "VIDEO",
  DOCUMENT: "DOCUMENT",
  IMAGE: "IMAGE",
  UNKNOWN: "UNKNOWN",
  CSV: "CSV",
  SVG: "SVG",
});

/**
 * Set of the values of [fileTypes]{@link fileTypes}
 *
 * @constant fileTypesValues
 * @type {Set.<string>}
 * @readonly
 */
export const fileTypesValues = Object.freeze(new Set(Object.values(fileTypes)));

/**
 * A map of supported file extensions to [fileType]{@link fileType}.
 *
 * @constant fileExtensions
 * @type {Map.<string, fileTypes>}
 * @readonly
 */
export const fileExtensions = Object.freeze(new Map([
  ["mp4", fileTypes.VIDEO],
  ["mpeg", fileTypes.VIDEO],
  ["pdf", fileTypes.DOCUMENT],
  ["xlsx", fileTypes.DOCUMENT],
  ["xls", fileTypes.DOCUMENT],
  ["ppt", fileTypes.DOCUMENT],
  ["pptx", fileTypes.DOCUMENT],
  ["doc", fileTypes.DOCUMENT],
  ["docx", fileTypes.DOCUMENT],
  ["rtf", fileTypes.DOCUMENT],
  ["json", fileTypes.DOCUMENT],
  ["jpg", fileTypes.IMAGE],
  ["jpeg", fileTypes.IMAGE],
  ["png", fileTypes.IMAGE],
  ["svg", fileTypes.SVG],
]));

export const applicationRtf = "application/rtf";

export const presentation = "application/vnd.openxmlformats-officedocument.presentationml.presentation";

export const applicationJson = "application/json";

export const wordDocument = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

export const spreadsheet = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const msExcel = "application/vnd.ms-excel";

export const applicationPdf = "application/pdf";

export const applicationMsword = "application/msword";

export const textCsv = "text/csv";

/**
 * A map of supported MIME types to [fileType]{@link fileType}.
 *
 * @constant
 * @type {Map.<string, string>}
 * @readonly
 */
export const fileMimeTypes = Object.freeze(new Map([
  ["video/mp4", fileTypes.VIDEO],
  ["image/jpeg", fileTypes.IMAGE],
  [applicationPdf, fileTypes.DOCUMENT],
  [applicationJson, fileTypes.DOCUMENT],
  [presentation, fileTypes.DOCUMENT],
  [applicationMsword, fileTypes.DOCUMENT],
  [wordDocument, fileTypes.DOCUMENT],
  [spreadsheet, fileTypes.DOCUMENT],
  [msExcel, fileTypes.DOCUMENT],
  [applicationRtf, fileTypes.DOCUMENT],
  [textCsv, fileTypes.CSV],
  ["image/svg+xml", fileTypes.SVG],
]));

export const videoMimeTypes = Object.freeze([
  "video/mp4",
]);

export const imageMimeTypes = Object.freeze([
  "image/jpeg",
  "image/png",
]);

export const documentMimeTypes = Object.freeze([
  applicationPdf,
  applicationJson,
  presentation,
  applicationMsword,
  wordDocument,
  spreadsheet,
  msExcel,
  applicationRtf,
]);

export const csvMimeTypes = Object.freeze([
  textCsv,
]);

export const svgMimeTypes = Object.freeze([
  "image/svg+xml",
]);

export const supportedMimeTypes = Object.freeze([
  ...videoMimeTypes,
  ...imageMimeTypes,
  ...documentMimeTypes,
  ...csvMimeTypes,
  ...svgMimeTypes,
]);

/**
 * A map of [fileTypes]{@link fileType} to collections of supported mime types.
 *
 * @constant
 * @type {Map.<string, Array.<string>>}
 * @readonly
 */
export const fileTypeMimeTypes = Object.freeze(new Map([
  [fileTypes.VIDEO, videoMimeTypes],
  [fileTypes.IMAGE, imageMimeTypes],
  [fileTypes.DOCUMENT, documentMimeTypes],
  [fileTypes.CSV, csvMimeTypes],
  [fileTypes.SVG, svgMimeTypes],
]));

/**
 * When selecting a file, the UI may present the user with a choice of raw uploaded
 * files or pre-existing file descriptors. This constant indicates which type has
 * been chosen.
 *
 * @enum {string}
 * @readonly
 * @property {string} DESCRIPTOR a pre-existing file descriptor
 * @property {string} FILE a raw uploaded file
 */
export const fileSelectionTypes = Object.freeze({
  DESCRIPTOR: "DESCRIPTOR",
  FILE: "FILE",
});
