/**
 * A cameo for answer.
 *
 * @module ui/component/cameo-answer
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import answerEditModal from "ui/component/modal/course/answer-edit";
import { getModal } from "ui/view/modal-view";

const showAnswer = (answer, onEdit, existingAnswers) => async () => {
  const modal = getModal();
  const editedAnswer = await modal.async(answerEditModal({
    answer,
    existingAnswers,
    viewOnly: !onEdit,
  }, modal));
  if (editedAnswer && onEdit) {
    onEdit(editedAnswer);
  }
};

/**
 * Cameo element for answer
 *
 * @param {Answer} answer
 * @param {Answer[]} existingAnswers
 * @param {Function} onEdit
 * @param {Function} onRemove
 * @param {boolean} viewOnly
 * @param {boolean} highlighted cameo (e.g. to show user answer)
 * @returns {*|El}
 */
export default function cameoAnswer({
  answer,
  existingAnswers = [],
  onEdit = null,
  onRemove = null,
  viewOnly = false,
  highlighted = false,
}) {
  return cameo({
    label: answer.value,
    icon: icon.solid(answer.correct ? "check-circle" : "times-circle"),
    controls: [
      onEdit ? button({
        icon: "edit",
        iconOnly: true,
        onClick: showAnswer(answer, onEdit, existingAnswers),
      }) : "",
      viewOnly && !onEdit ? button({
        icon: "eye",
        iconOnly: true,
        onClick: showAnswer(answer, null, existingAnswers),
      }) : "",
      onRemove ? button.warning({
        icon: "trash-alt",
        iconOnly: true,
        onClick: () => onRemove(answer),
      }) : "",
    ],
    sel: `.cameo-answer${highlighted ? ".highlighted" : ""}`,
  });
}
