/**
 * A vertically sortable container.
 *
 * @module ui/component/sortable
 * @category UI
 * @subcategory Components
 */
import toPx from "to-px";
import { parseElement } from "util/dom";
import { img } from "ui/html";

// used to generate unique keys for child elements
let count = 0;

const isInside = (y, elm) => {
  const cy = elm.getBoundingClientRect().top;
  return (cy - 25 < y && (cy + 25 + elm.offsetHeight) > y);
};

const findCurrentTarget = (e) => {
  const y = e.clientY;
  if (isInside(y, e.target)) {
    return e.target;
  }
  // exclude the drop targets adjacent to the original target element
  const dropTargets = [...e.target.parentNode.querySelectorAll(".sortable-drop-target")];
  const lastDropTarget = dropTargets[dropTargets.length - 1];
  const isFirst = e.target.parentNode.querySelector(".draggable") === e.target;
  if (y < dropTargets[0].getBoundingClientRect().top) {
    return isFirst ? e.target : dropTargets[0];
  }
  let target;
  if (y > lastDropTarget.getBoundingClientRect().bottom) {
    target = lastDropTarget;
  } else target = dropTargets.find((t) => isInside(y, t));
  return (
    e.target.previousSibling === target
    || e.target.nextSibling === target
  ) ? e.target : target;
};

const clearReceivingStates = (parentNode) => {
  [...parentNode.querySelectorAll(".sortable-drop-target")]
    .forEach((t) => {
      t.classList.remove("receiving");
    });
};

const moveGhost = (ghost, offset) => {
  if (ghost) {
    /* eslint-disable-next-line no-param-reassign */
    ghost.style.top = `${offset}px`;
  }
};

const makeDropTarget = (i) => {
  const target = document.createElement("div");
  target.classList.add("sortable-drop-target");
  target.dataset.sortIndex = i - 0.5;
  return target;
};

/**
 * Drop targets have to be reset after each patch, due to
 * vaguaries about how and when snabbdom decides to rearrange
 * things vs. leave them be.
 */
const resetSortableStates = (vnode) => {
  const container = vnode.elm;
  if (!container) return; // container isn't initialized yet

  container.querySelectorAll(".sortable-drop-target, .ghost")
    .forEach((child) => child.remove());

  let lastIndex = 0;
  container.querySelectorAll(".draggable")
    .forEach((child, i) => {
      lastIndex = i;
      child.classList.remove("dragging");
      container.insertBefore(
        makeDropTarget(i),
        child,
      );
    });
  const last = container.querySelector(".draggable:last-of-type")?.nextSibling;

  if (last) container.insertBefore(makeDropTarget(lastIndex + 1), last);
  else container.append(makeDropTarget(lastIndex + 1));
};

const onDragStart = (container, index) => (e) => {
  /* eslint-disable no-param-reassign */
  e.dataTransfer.setData("text/plain", index);
  e.dataTransfer.effectAllowed = "move";
  e.dataTransfer.setDragImage(e.target.querySelector(".pixel"), 0, 0);
  e.target.classList.add("dragging");
  // for mysterious reasons if the ghost gets created
  // immediately it sometimes causes drag to instantly
  // end... this is a hack to avoid that, but a real
  // debugging and fix would be swell
  setTimeout(() => {
    container.ghost = parseElement(e.target.outerHTML);
    container.ghost.classList.add("ghost");
    container.ghost.classList.remove("dragging");
    moveGhost(container.ghost, e.target.offsetTop);
    e.target.parentNode.append(container.ghost);
  }, 0);
};

const getTargetOffset = (elm) => {
  const topMargin = toPx(window.getComputedStyle(elm).marginTop);
  // we don't want a negative top margin to be counted
  return elm.offsetTop - Math.max(0, topMargin);
};

const onDrag = (container) => (e) => {
  let newTop = container.currentDropTarget?.offsetTop || 0;
  /* eslint-disable no-param-reassign */
  const newTarget = findCurrentTarget(e);
  if (!newTarget) { // change to free floating
    clearReceivingStates(e.target.parentNode);
    container.currentDropTarget = null;
    newTop = e.clientY - e.target.parentNode.getBoundingClientRect().top
      - (e.target.clientHeight / 2);
  } else if (newTarget === e.target) { // move to original position
    clearReceivingStates(e.target.parentNode);
    container.currentDropTarget = null;
    newTop = getTargetOffset(newTarget);
  } else if (container.currentDropTarget !== newTarget) { // move to new target
    clearReceivingStates(e.target.parentNode);
    container.currentDropTarget = newTarget;
    newTarget.classList.add("receiving");
    newTop = getTargetOffset(newTarget);
  } // else target hasn't changed, in which case don't do anything
  moveGhost(container.ghost, newTop);
};

const onDragEnd = (container, onSort) => (e) => {
  const moved = e.target;
  /* eslint-disable no-param-reassign */
  container.currentDropTarget = findCurrentTarget(e);
  if (container.currentDropTarget !== null && container.currentDropTarget !== e.target) {
    const parent = container.currentDropTarget.parentNode;
    moved.remove();
    parent.insertBefore(
      moved,
      container.currentDropTarget,
    );
    moved.classList.remove("dragging");
    container.ghost.remove();
    clearReceivingStates(parent);
    const newSortOrder = [...parent.querySelectorAll(".draggable")].map(
      (s) => s.dataset.sortIndex,
    );
    setTimeout(() => onSort(newSortOrder), 0);
  } else {
    resetSortableStates(container);
  }
  /* eslint-enable no-param-reassign */
};

/**
 * Wraps the given element, making its immediate children drag-and-drop sortable
 * in vertical order. Relies on stylesheets for animation handling.
 *
 * This is meant to be used with vertically ordered list-like elements and components such
 * as page sections or playlist items, and may behave badly for other kinds of layouts.
 *
 * Note that the childSelector parameter must be the same as the `vnode.sel` property.
 * It cannot be a general selector. This is due to needing to iterate over child nodes
 * before they're rendered to DOM. This is less than ideal.
 *
 * The onSort callback, if present, is called with an array of integers representing the
 * original indexes of the child elements, but in the new sort order. For example, if
 * the child elements were:
 * ```js
 * [
 *   p("foo"), // 0
 *   p("bar"), // 1
 *   p("baz"), // 2
 * ]
 * ```
 *
 * And the user rearranged them like so:
 * ```js
 * [
 *   p("baz"), // 2
 *   p("foo"), // 0
 *   p("bar"), // 1
 * ],
 *
 * Then `onSort` would be called like so:
 * ```js
 * onSort([2, 0, 1]);
 * ```
 *
 *
 * @function sortable
 * @param {object} config
 * @param {Selector} [config.childSelector="div.sortable-child"]
 *                   selector for children eligible for dragging
 * @param {function} [config.onSort] called when the sort order changes
 * @param {module:ui/common/el~El} container container element
 */
export default ({
  childSelector = "div.sortable-child",
  onSort = () => {},
}, container) => {
  /* eslint-disable no-param-reassign */
  container.currentDropTarget = null;
  container.ghost = null;
  container.merge({
    class: { sortable: true },
    hook: {
      insert: resetSortableStates,
      postpatch: resetSortableStates,
    },
  });

  // let's do some shenanigans to the element...
  // garbage in, garbage out applies!
  container.children
    .filter((child) => (childSelector ? child.sel.includes(childSelector) : true))
    .forEach((child, i) => {
      child.merge({
        key: child.key || `sortable-${count++}`,
        attrs: { draggable: "true" },
        dataset: { sortIndex: `${i}` },
        class: { draggable: "true" },
        on: {
          dragstart: onDragStart(container, i),
          drag: onDrag(container),
          dragend: onDragEnd(container, onSort),
        },
      });
      child.children.push(img(".pixel", "/media/empty-pixel.png"));
    });
  /* eslint-enable no-param-reassign */
  return container;
};
