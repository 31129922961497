/**
 * Editor fields for slider image sections.
 *
 * @module ui/component/modal/section-edit/slider
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import { fileTypes } from "model/file-descriptor/constants";
import { typeFilterAll, descriptorTypeFilterAll } from "util/filter";
import {
  label,
} from "ui/html";
import icon from "ui/component/icon";
import cameo from "ui/component/cameo";
import message from "ui/component/message";
import fileSelectModal from "ui/component/modal/file-select";
import title from "ui/component/form-managed/field-title";
import button from "ui/component/form-managed/button";
import toggle from "ui/component/form-managed/field-toggle";
import { getModal } from "ui/view/modal-view";
import editTextBlockModal from "ui/component/modal/section-edit/edit-text-block";
import { uuidv4 } from "util/generator";
import { slideTypes } from "model/dynamic-page/constants";

const makeDeleteImage = (self) => (slide, index) => {
  const slides = [...self.state.section.searchData.slides];
  slides.splice(index, 1);
  self.update({
    section: {
      searchData: {
        slides,
      },
      images: self.state.section.images.filter((image) => image.id !== slide.id),
    },
  });
};

const makeDeleteTextBlock = (self) => (index) => {
  const slides = [...self.state.section.searchData.slides];
  slides.splice(index, 1);
  self.update({
    section: {
      searchData: {
        slides,
      },
    },
  });
};

const entriesList = (self) => {
  const { state } = self;
  const { searchData } = state.section;
  const slides = searchData.slides || [];
  const deleteImage = makeDeleteImage(self);
  const deleteTextBlock = makeDeleteTextBlock(self);
  return slides.map((slide, index) => {
    switch (slide.type) {
      case slideTypes.IMAGE:
        return cameo({
          sel: ".image-entry",
          icon: icon("image"),
          label: slide.name,
          controls: [
            button.icon({
              sel: ".danger",
              icon: "trash",
              onClick: () => deleteImage(slide, index),
            }),
          ],
        });
      case slideTypes.TEXT_BLOCK:
        return cameo({
          sel: ".text-block-entry",
          icon: icon("text"),
          label: slide.name,
          controls: [
            button.icon({
              sel: ".danger",
              icon: "trash",
              onClick: () => deleteTextBlock(index),
            }),
          ],
        });
      default:
        return "";
    }
  });
};

const addTextBlock = async (formView) => {
  const { state } = formView;
  const { searchData } = state.section;
  const name = await getModal().async(editTextBlockModal({}));
  const textBlockSlide = {
    id: uuidv4(),
    name,
    type: slideTypes.TEXT_BLOCK,
  };
  formView.update({
    section: {
      searchData: {
        slides: [
          ...(searchData.slides || []),
          textBlockSlide,
        ],
      },
    },
  });
};

const addImage = async (formView, modalView) => {
  const descriptors = (formView.state?.descriptors || [])
    .filter(descriptorTypeFilterAll([fileTypes.IMAGE]));

  const selected = await modalView.async(fileSelectModal({
    uploads: formView.state.files.filter(typeFilterAll(fileTypes.IMAGE)),
    descriptors,
  }, modalView));

  if (selected !== null) {
    // this should handle either a descriptor or a file name without issue
    const images = formView?.state?.section?.images?.length
      ? formView.state.section.images.concat([selected.file])
      : [selected.file];
    const sliderImage = {
      id: selected.file.id || "upload_file",
      name: selected.file.name || selected.file,
      type: slideTypes.IMAGE,
    };
    formView.update({
      section: {
        images,
        searchData: {
          slides: [
            ...(formView.state?.section?.searchData?.slides || []),
            sliderImage,
          ],
        },
      },
    });
  }
};

/**
 * @function sliderFields
 * @private
 *
 * @param self
 * @param {module:ui/view/modal~modalView} modalView
 * @return {module:ui/html~ChildEl[]}
 */
export default (self, modalView) => {
  const { section } = self.state;
  return self.bind([
    [title, { value: section.title }],
    [toggle.boxed.inverse, { label: "Short", name: "short", toggled: section?.searchData?.short }],
    label("Images"),
    ...entriesList(self),
    button.standIn({
      icon: "plus-circle",
      label: "Add Image",
      onClick: () => addImage(self, modalView),
    }),
    button.standIn({
      icon: "plus-circle",
      label: "Add Text Block",
      onClick: () => addTextBlock(self),
    }),
    message({ type: "info", text: "Please ensure images are at least 1600 pixels wide and 350 pixels tall." }),
  ]);
};
