/**
 * Utilities related to events and event listeners.
 *
 * @module util/event
 * @category Utilities
 */

/**
 * Emit a custom event. Skips a few lines of wiring.
 *
 * @function emit
 * @param {Node} elm source/target element
 * @param {string} type event type string
 * @param {Object} [details] event details
 */
export const emit = (elm, type, detail = null) => {
  const event = new CustomEvent(type, { bubbles: true, detail });
  elm.dispatchEvent(event);
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `esc`.
 *
 * @function onEscKey
 * @param {function} cb the function to be called when the `esc` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onEscKey = (cb) => (e) => {
  if (e.keyCode === 27) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `tab`.
 *
 * @function onTabKey
 * @param {function} cb the function to be called when the `tab` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onTabKey = (cb) => (e) => {
  if (e.keyCode === 9) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `down arrow`.
 *
 * @function onDownArrowKey
 * @param {function} cb the function to be called when the `down arrow` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onDownArrowKey = (cb) => (e) => {
  if (e.keyCode === 40) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `up arrow`.
 *
 * @function onUpArrowKey
 * @param {function} cb the function to be called when the `up arrow` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onUpArrowKey = (cb) => (e) => {
  if (e.keyCode === 38) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `enter`.
 *
 * @function onEnterKey
 * @param {function} cb the function to be called when the `enter` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onEnterKey = (cb) => (e) => {
  if (e.keyCode === 13) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * A higher order function for producing a keybinding event listener callback that only
 * fires if the key pressed was `enter`.
 *
 * @function onEnterKey
 * @param {function} cb the function to be called when the `enter` key is pressed
 * @return {function} an event listener for use with `keyup`, `keydown`, or `keypress` events
 */
export const onSpaceKey = (cb) => (e) => {
  if (e.keyCode === 32) {
    e.stopPropagation();
    cb(e);
  }
};

/**
 * Pprevents rapid re-firing of events.
 *
 * @param {function} cb event handler callback
 * @param {int} [debounceTime=250] in milliseconds
 * @return function wrapped event handler callback
 */
export const debounce = (cb, debounceTime = 250) => {
  let timeout;
  return (e) => {
    const onTimeout = () => cb(e);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(onTimeout, debounceTime);
  };
};
