/**
 * Constraints specific to dynamic pages.
 *
 * @module model/dynamic-page/constraints
 * @category Model
 * @subcategory Dynamic Page
 */
import { ConstraintError } from "model/error";
import { validString, validMember } from "model/constraints";
import { searchDataModesSet, searchDataPresetsSet } from "model/search/constants";
import { pageTypesSet } from "./constants";

/**
 * Ensures the value is a properly formatted page slug.
 *
 * @param {string} value
 * @param {string} fieldName
 * @throws ConstraintError
 * @return {string}
 */
export const validSlug = (value, fieldName) => {
  validString(value, fieldName);
  if (/^[a-z0-9-]+[^-]$/.exec(value)?.[0] !== value) {
    throw new ConstraintError(fieldName, "invalid page slug", value);
  }
  return value;
};

/**
 * Ensures a section's type is on the list of valid section types.
 *
 * @param {string} type
 * @param {string} fieldName
 * @throws ConstraintError
 * @return {string}
 */
export const validPageType = (value, fieldName) => validMember(
  value,
  fieldName,
  pageTypesSet,
);

/**
 * Ensures a section's search data mode is on the list of valid modes.
 *
 * @param {string} type
 * @param {string} fieldName
 * @throws ConstraintError
 * @return {string}
 */
export const validSearchDataMode = (value, fieldName) => validMember(
  value,
  fieldName,
  searchDataModesSet,
);

/**
 * Ensures a section's search data preset is on the list of valid presets.
 *
 * @param {string} type
 * @param {string} fieldName
 * @throws ConstraintError
 * @return {string}
 */
export const validSearchDataPreset = (value, fieldName) => validMember(
  value,
  fieldName,
  searchDataPresetsSet,
);
