/**
 * State objects for test page.
 *
 * @module ui/page/admin/test
 * @private
 */
/***/
import cache from "cache";
import { frozen } from "util/object";
import { videoMetadataList } from "api/mocks/metadata";
import { aclSubjectTypes } from "model/acl/constants";
import { aclGroupList, mockGrants } from "api/mocks/acl";
import { userList } from "api/mocks/user";
import { userTypes } from "model/user/constants";

/* eslint-disable-next-line import/prefer-default-export */
export const defaultState = frozen({
  messages: [],
  user: cache.getProfile(),
  labels: ["Foo", "Bar", "Baz", "Qux", "Quux"],
  labelsOpen: false,
  searchParams: { terms: [] },
  validation: {
    fields: {},
  },
});

export const aclDefaultState = {
  subject: videoMetadataList[0],
  subjectType: aclSubjectTypes.MEDIA,
  grants: mockGrants,
  users: userList,
  groups: aclGroupList,
};

export const aclAnonymousState = {
  ...aclDefaultState,
  anonymousUser: {
    userType: userTypes.ANONYMOUS,
    id: "10000000-0000-0000-0000-000000000000",
    username: "anon",
  },
};
