import img from "ui/html/img";
import div from "ui/html/div";
import p from "ui/html/p";
import span from "ui/html/span";
import commonHelpModal from "ui/component/modal/help/common-help";
import { modalTypes } from "ui/component/modal/help/utils";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";

export default function pwaIosPrompt() {
  return commonHelpModal({
    modalType: modalTypes.PWA_IOS,
    skipCache: true,
    async: false,
    sel: ".help-modal-pwa-ios",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        lastSlideLabel: "Install",
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".img-lg", "/media/modal-help-home-slide-1.png"),
            div(".text-container", [
              p("Install our app for better experience!"),
              p([
                span("Tap"),
                img(".share", "/media/share-apple.svg", "share"),
                span("and select \"Add to Home Screen.\""),
              ], ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
