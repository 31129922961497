/**
 * Mock data for use on the test page.
 *
 * @module ui/page/admin/test/mocks
 * @private
 */
/***/
import { aclGroupList } from "api/mocks/acl";
import { fakeUser as mockFakeUser, userList as mockUserList } from "api/mocks/user";
import { userToPrincipal } from "model/acl";
import { pageTypes } from "model/dynamic-page/constants";
import {
  defaultGradingSchemes,
  defaultGradingSchemeTypes,
} from "model/course/default-grading-scheme";
import { frozen, merge } from "util/object";
import { uuidv4 } from "util/generator";

/* FIXME deprecated - use fakes directly */
export const fakeUser = mockFakeUser;
export const userList = mockUserList;

export const principalList = [
  ...userList.map(userToPrincipal),
  ...aclGroupList,
];

const fakePage = {
  title: "Fake Page",
  slug: "fake-page",
  type: pageTypes.INDEPENDENT,
};

export const pageList = [
  fakePage,
  fakePage,
  fakePage,
  fakePage,
  fakePage,
].map((page, i) => ({
  ...page,
  title: `${page.title} ${i}`,
  slug: `${page.slug}-${i}`,
}));

const fakeDescriptor = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "Fake File 1.jpg",
  fileId: "12345asdf67890",
  type: "image/jpeg",
  checksum: "1234567890",
  fileSize: 0,
};

export const descriptorList = [
  { ...fakeDescriptor },
  { ...fakeDescriptor, name: "Fake File 2.jpg", id: uuidv4() },
  { ...fakeDescriptor, name: "Fake File 3.png", type: "image/png", id: uuidv4() },
  { ...fakeDescriptor, name: "Fake File 4.gif", type: "image/gif", id: uuidv4() },
  { ...fakeDescriptor, name: "Fake File 5.mp4", type: "video/mp4", id: uuidv4() },
  { ...fakeDescriptor, name: "Fake File 6.pdf", type: "application/pdf", id: uuidv4() },
];

export const fakePages = [
  { title: "Page 1", slug: "page-1", type: pageTypes.INDEPENDENT },
  { title: "Page 2", slug: "page-2", type: pageTypes.INDEPENDENT },
  { title: "Page 3", slug: "page-3", type: pageTypes.INDEPENDENT },
  { title: "Page 4", slug: "page-4", type: pageTypes.INDEPENDENT },
  { title: "Page 5", slug: "page-5", type: pageTypes.INDEPENDENT },
];

export const fakeCourse = frozen({
  id: "FAKE_FAKE_FAKE_COURSE",
  title: "Fake Course",
  description: "This is a fake course.",
  modules: [],
  gradingScheme: defaultGradingSchemes.get(defaultGradingSchemeTypes.AMERICAN),
});

export const courseList = [
  fakeCourse,
  fakeCourse,
  fakeCourse,
  fakeCourse,
  fakeCourse,
  fakeCourse,
].map((course, i) => merge(course, {
  title: `${course.title} ${i}`,
}));

export const analyticsFakeResponse = [
  {
    startDate: "2023-01-11T00:00:00.000Z",
    endDate: "2023-01-11T23:59:59.999Z",
    count: 366,
  },
  {
    startDate: "2023-01-12T00:00:00.000Z",
    endDate: "2023-01-12T23:59:59.999Z",
    count: 695,
  },
  {
    startDate: "2023-01-13T00:00:00.000Z",
    endDate: "2023-01-13T23:59:59.999Z",
    count: 919,
  },
  {
    startDate: "2023-01-14T00:00:00.000Z",
    endDate: "2023-01-14T23:59:59.999Z",
    count: 795,
  },
  {
    startDate: "2023-01-15T00:00:00.000Z",
    endDate: "2023-01-15T23:59:59.999Z",
    count: 674,
  },
  {
    startDate: "2023-01-16T00:00:00.000Z",
    endDate: "2023-01-16T23:59:59.999Z",
    count: 255,
  },
  {
    startDate: "2023-01-17T00:00:00.000Z",
    endDate: "2023-01-17T23:59:59.999Z",
    count: 945,
  },
  {
    startDate: "2023-01-18T00:00:00.000Z",
    endDate: "2023-01-18T23:59:59.999Z",
    count: 594,
  },
  {
    startDate: "2023-01-19T00:00:00.000Z",
    endDate: "2023-01-19T23:59:59.999Z",
    count: 822,
  },
  {
    startDate: "2023-01-20T00:00:00.000Z",
    endDate: "2023-01-20T23:59:59.999Z",
    count: 632,
  },
  {
    startDate: "2023-01-21T00:00:00.000Z",
    endDate: "2023-01-21T23:59:59.999Z",
    count: 445,
  },
  {
    startDate: "2023-01-22T00:00:00.000Z",
    endDate: "2023-01-22T23:59:59.999Z",
    count: 717,
  },
  {
    startDate: "2023-01-23T00:00:00.000Z",
    endDate: "2023-01-23T23:59:59.999Z",
    count: 935,
  },
  {
    startDate: "2023-01-24T00:00:00.000Z",
    endDate: "2023-01-24T23:59:59.999Z",
    count: 947,
  },
  {
    startDate: "2023-01-25T00:00:00.000Z",
    endDate: "2023-01-25T23:59:59.999Z",
    count: 299,
  },
  {
    startDate: "2023-01-26T00:00:00.000Z",
    endDate: "2023-01-26T23:59:59.999Z",
    count: 751,
  },
  {
    startDate: "2023-01-27T00:00:00.000Z",
    endDate: "2023-01-27T23:59:59.999Z",
    count: 335,
  },
  {
    startDate: "2023-01-28T00:00:00.000Z",
    endDate: "2023-01-28T23:59:59.999Z",
    count: 346,
  },
  {
    startDate: "2023-01-29T00:00:00.000Z",
    endDate: "2023-01-29T23:59:59.999Z",
    count: 981,
  },
  {
    startDate: "2023-01-30T00:00:00.000Z",
    endDate: "2023-01-30T23:59:59.999Z",
    count: 641,
  },
  {
    startDate: "2023-01-31T00:00:00.000Z",
    endDate: "2023-01-31T23:59:59.999Z",
    count: 515,
  },
  {
    startDate: "2023-02-01T00:00:00.000Z",
    endDate: "2023-02-01T23:59:59.999Z",
    count: 518,
  },
  {
    startDate: "2023-02-02T00:00:00.000Z",
    endDate: "2023-02-02T23:59:59.999Z",
    count: 720,
  },
  {
    startDate: "2023-02-03T00:00:00.000Z",
    endDate: "2023-02-03T23:59:59.999Z",
    count: 690,
  },
  {
    startDate: "2023-02-04T00:00:00.000Z",
    endDate: "2023-02-04T23:59:59.999Z",
    count: 941,
  },
  {
    startDate: "2023-02-05T00:00:00.000Z",
    endDate: "2023-02-05T23:59:59.999Z",
    count: 890,
  },
  {
    startDate: "2023-02-06T00:00:00.000Z",
    endDate: "2023-02-06T23:59:59.999Z",
    count: 527,
  },
  {
    startDate: "2023-02-07T00:00:00.000Z",
    endDate: "2023-02-07T23:59:59.999Z",
    count: 496,
  },
  {
    startDate: "2023-02-08T00:00:00.000Z",
    endDate: "2023-02-08T23:59:59.999Z",
    count: 440,
  },
  {
    startDate: "2023-02-09T00:00:00.000Z",
    endDate: "2023-02-09T23:59:59.999Z",
    count: 993,
  },
  {
    startDate: "2023-02-10T00:00:00.000Z",
    endDate: "2023-02-10T23:59:59.999Z",
    count: 370,
  },
];

export const analyticsFakeResponseWeekly = [
  {
    startDate: "2023-02-04T00:00:00.000Z",
    endDate: "2023-02-04T23:59:59.999Z",
    count: 941,
  },
  {
    startDate: "2023-02-05T00:00:00.000Z",
    endDate: "2023-02-05T23:59:59.999Z",
    count: 890,
  },
  {
    startDate: "2023-02-06T00:00:00.000Z",
    endDate: "2023-02-06T23:59:59.999Z",
    count: 527,
  },
  {
    startDate: "2023-02-07T00:00:00.000Z",
    endDate: "2023-02-07T23:59:59.999Z",
    count: 496,
  },
  {
    startDate: "2023-02-08T00:00:00.000Z",
    endDate: "2023-02-08T23:59:59.999Z",
    count: 440,
  },
  {
    startDate: "2023-02-09T00:00:00.000Z",
    endDate: "2023-02-09T23:59:59.999Z",
    count: 993,
  },
  {
    startDate: "2023-02-10T00:00:00.000Z",
    endDate: "2023-02-10T23:59:59.999Z",
    count: 370,
  },
];
