/**
 * State management utilities for dynamic pages.
 *
 * @module ui/page/dynamic/state
 * @category Pages
 * @subcategory User Facing
*/
import { pageTypes } from "model/dynamic-page/constants";
import { metadataTypes } from "model/metadata/constants";
import { metadataScheduleFilter } from "util/filter";
import { buildContentDictionary } from "util/metadata";
import { frozen } from "util/object";
import hashmap from "util/hash-map";
import { collectCourseAssessments } from "util/course";

// for dynamic page section search results
export const dynamicId = (i) => `dynamic-${i}`;

/**
 * Collect all metadata items in a page into a dictionary, recursively.
 *
 * @function collectMetadata
 * @private
 */
export const collectMetadata = (page, collection = new Map()) => {
  if (page.item) {
    const itemDict = buildContentDictionary(page.item);
    [...itemDict.entries()].forEach((key, value) => collection.set(key, value));
  }
  if (page.searchResults?.length) {
    page.searchResults.forEach((result) => collection.set(result.id, result));
  }
  if (page.sections?.length) {
    page.sections.forEach((section) => collectMetadata(section, collection));
  }
  return collection;
};

/**
 * Collect all search results for page sections and place them in a wrapped
 * list info object for display and state management.
 *
 * @function collectSearches
 * @private
 */
export const collectSearches = (page) => new Map(
  page.sections?.map((section, i) => {
    const id = dynamicId(i);
    return [
      id,
      {
        list: {
          id,
          title: section.title,
          type: section.type,
          items: section.searchResults?.filter(metadataScheduleFilter()),
        },
        position: 0,
      },
    ];
  }),
);

/**
 * Collect all metadata lists and create state objects for them, for use in carousels.
 *
 * @function collectLists
 * @private
 */
export const collectLists = (page) => new Map(page.sections
  ?.filter((s) => s.item?.type === metadataTypes.LIST)
  ?.map((s) => ([
    s.item.id,
    {
      list: {
        ...s.item,
        items: s.item.items.filter(metadataScheduleFilter()),
      },
      position: 0,
    },
  ])) || []);

/**
 * Append course & module ID to lecture for ease of use in sections.
 */
export const expandSectionModules = (section) => ({
  ...section,
  item: ({
    ...section.item,
    modules: section.item.modules?.map((module) => ({
      ...module,
      lectures: module.lectures?.map((lecture) => ({
        ...lecture,
        moduleId: module.id,
        courseId: section.item.id,
      })) || [],
    })) || [],
  }),
});

export const collectCourses = (page) => new Map(page.sections
  // FIXME this is a crappy way to detect courses. they should have some identifier.
  ?.filter((s) => s.item && s.item.type === undefined)
  ?.map((s) => expandSectionModules(s))
  ?.map((s) => ([
    s.item.id,
    {
      list: {
        ...s.item,
        modules: s.item.modules?.filter(metadataScheduleFilter()),
      },
      positions: new Map(s.item.modules?.map((module) => ([module.id, 0])) || []),
    },
  ])) || []);

export const collectAssessments = (courses) => new Map(
  courses.map(collectCourseAssessments).flat().map((a) => ([a.id, a])),
);

/**
 * Collects all evaluations from attendance, mapping them to assessment id.
 *
 * This is so we can find evaluation status for displaying in evaluation cards.
 *
 * @param {CourseAttendance[]} attendances
 * @return {Map.<UUID, Evaluation>}
 */
export const collectCompletedEvaluations = (attendances) => {
  const evaluations = new Map();

  attendances.forEach((attendance) => {
    if (!attendance?.evaluations) return;
    attendance.evaluations.forEach((evaluation) => {
      if (evaluation.assessment) evaluations.set(evaluation.assessment.id, evaluation);
    });
  });
  return evaluations;
};

/**
 * Initialize slider positions state object.
 *
 * @function initSliderPositions
 * @private
 */
export const initSliderPositions = (page) => {
  const sliderPositions = {};

  page.sections?.forEach((section, i) => {
    if (section.type === pageTypes.IMAGE_SLIDER) {
      sliderPositions[i] = 0;
    }
  });

  return sliderPositions;
};

export const readyState = frozen({
  BEGIN: 0,
  FIRST_DATA: 1,
  READY: 2,
});

export const defaultState = frozen({
  assessments: hashmap(),
  attendance: hashmap(),
  config: null,
  courseLectures: hashmap(),
  courseModules: hashmap(),
  courses: hashmap(),
  documents: hashmap(),
  evaluations: hashmap(),
  horizontal: false,
  lists: hashmap(),
  metadata: hashmap(),
  page: null,
  playback: hashmap(),
  playlists: hashmap(),
  positions: hashmap(),
  searchParams: { terms: [] },
  searchResults: hashmap(),
  sideMenu: hashmap(),
  sections: hashmap(),
  itemReady: new Set(),
  title: "Page",
  user: null,
  videos: hashmap(),
  ready: readyState.BEGIN,
});
