/**
 * @module ui/component/modal/menu-edit/common
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import { merge } from "util/object";

/**
 * Gets the title for a modal dialog.
 */
export const getLabel = (entry) => entry.label || "Menu Entry";

/**
 * Handles updating the form view.
 * @function onFormUpdate
 * @private
 */
export const onFormUpdate = (formView) => {
  const validation = formView.validate();
  const entry = merge({}, formView.state.entry);
  const { values } = formView;
  Object.keys(entry).forEach((key) => {
    if (values[key]) entry[key] = values[key];
  });
  formView.updateState({ validation, entry });
  formView.patch(formView.factory(formView));
};
