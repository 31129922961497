/**
 * The user-facing FAQ page.
 *
 * @module ui/page/end-user-faq
 * @category Pages
 * @subcategory User Facing
 */
import {
  div, h1, p, h3, img, li, ol, span, a,
} from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import { isMobileWidth } from "util/pwa";

const showMobileUserFaq = () => div(".questions", [
  a([h3("Registration"), span("+", ".plus")], "/faq/registration"),
  a([h3("Login"), span("+", ".plus")], "/faq/login"),
  a([h3("Forgot Password"), span("+", ".plus")], "/faq/forgot-password"),
  a([h3("Content"), span("+", ".plus")], "/faq/content"),
  a([h3("Account Settings"), span("+", ".plus")], "/faq/account-settings"),
  a([h3("Account Deletion"), span("+", ".plus")], "/faq/account-deletion"),
]);

const showUserFaq = () => div(".questions", [
  h3("Registration"),
  collapsible({
    title: "How do I register for SmartEdge?",
  }, p(`Head to our registration page! From there, you'll be asked to enter your chosen username,
   mobile number, and email address. After that, you can create a password. We'll send a one-time passcode
   (OTP) via e-mail or text. Once the OTP is entered, your account will be confirmed and created.
   Feel free to use the SmartEdge system. Have fun!`)),
  collapsible({
    title: "What happens if I have external credentials?",
  }, p(`If your organization’s admin has given you a separate set of user credentials, please use
   them to log into your account. They are required to access your school’s SmartEdge system and library.`)),
  collapsible({
    title: "What happens after I enter the OTP?",
  }, p(`Your account will be verified and you will be taken back to the login page to re-enter your
   new username/password. If we cannot verify your identity, you'll need to contact your organization’s SmartEdge admin.`)),
  collapsible({
    title: "I didn't receive an OTP through mobile or email. What do I do?",
  }, p(`No worries! Please email the SmartEdge admin at your organization. They'll be able to
   assist you with account creation.`)),
  collapsible({
    title: "Does SmartEdge allow for multiple accounts for the same phone number or email address?",
  }, p(`No. For security purposes, we do not allow an email or phone number to
   be associated with more than one account.`)),

  h3("Login"),
  collapsible({
    title: "Does SmartEdge allow for multiple accounts for the same phone number or email address?",
  }, [
    p([
      `If you are new to SmartEdge, please register on the `,
      a("registration page.", "/register"),
      ` Once you have completed the registration process, feel free to login using your new SmartEdge credentials.`,
    ]),
    p(`If you are already registered, feel free to login at any time. In case you have an
     issue with logging in, please email your organization’s SmartEdge admin.`),
    p(`If there are external credentials given by your school admin, please use the username
    and password you created for the external credentials. In case you have an issue with logging in,
    please email your organization’s SmartEdge admin.`),
  ]),
  collapsible({
    title: "After registration, do I need to log in with the new credentials to access SmartEdge?",
  }, p(`Yes! You can click the Remember Me box on the login page to remain logged into your
    account for 24 hours. After 24 hours have passed, you will need to re-enter your
    username and password to log back in.`)),
  collapsible({
    title: "What happens if I forgot I already have an account and need to go back to the Login page?",
  }, p(`No problem! Head back to the login page and use the info associated with your account to log into SmartEdge.`)),
  collapsible({
    title: "How can I go back to the login page from the main SmartEdge site?",
  }, p(`If you want to go back to the main login page, simply log out of your account.
    This function is accessible through the Side Bar Menu of the SmartEdge interface.`)),
  collapsible({
    title: "Are there a certain number of times I can attempt logging in with the incorrect password?",
  }, p(`Short answer- yes. You can only log in a maximum of 5 times before you are locked out of your account.
    Worst case scenario- please change your password by going through the Forgot Password process before this happens.`)),

  h3("Forgot Password"),
  collapsible({
    title: "I forgot my password. How can I reset it?",
  }, p(`No worries! It happens to the best of us. To reset your password, you can head to the Forgot Password
    page on the main login page. From there, you will be issued an OTP via e-mail or text. You will need to enter this on
    the Forgot Password page. Once your account has been verified, you are free to change your password. Login with the
    new credentials after this process has been completed.`)),
  collapsible({
    title: "I didn't receive my one-time password (OTP).",
  }, p(`Oh no! We’re sorry to hear that. Please get in touch with your organization’s SmartEdge admin.
    They should be able to assist you.`)),
  collapsible({
    title: "Can I change my password if I am an external user?",
  }, p(`No- you will not be able to change your password from your account. If you would like to update your
    password, you will need to do this on the external credentials account management page. Please get in touch with
    your organization’s SmartEdge admin for any assistance.`)),

  h3("Content"),
  collapsible({
    title: "How do I stream a video?",
  }, p(`To start a video, click the Watch button on the list image or content popup.`)),
  collapsible({
    title: "How can I resume watching a video?",
  }, p(`If you paused your video or accidentally closed your browser window, you can pick up where you left
    off by selecting Resume on the list image or content popup.`)),
  collapsible({
    title: "What do these buttons on the video player do?",
  }, [
    img(".full-width", "/media/video-player.jpg", "video player"),
    ol([
      li([
        span("Back - ", ".bold"),
        "This button will take you back to the last page you were on.",
      ]),
      li([
        span("Restart - ", ".bold"),
        "If you would like to restart your video from the beginning, click this!",
      ]),
      li([
        span("Rewind 15 sec. - ", ".bold"),
        "This button will rewind your video by 15 seconds.",
      ]),
      li([
        span("Play - ", ".bold"),
        `This button will start/stop the video. If the video is playing, press the Play button to pause.
         To restart the video from the point you left off, press the Pause button.`,
      ]),
      li([
        span("Fast Forward 15 sec. - ", ".bold"),
        "This button will fast forward your video by 15 seconds.",
      ]),
      li([
        span("Watch Bar - ", ".bold"),
        "The watch bar tracks the progress of your video.",
      ]),
      li([
        span("Time Watched - ", ".bold"),
        "This indicates how much of the video you have watched in HH:MM:SS.",
      ]),
      li([
        span("Total Length - ", ".bold"),
        "This indicates the total length of the video in HH:MM:SS.",
      ]),
      li([
        span("Volume Control - ", ".bold"),
        `This control allows you to change the volume of the video on the video player. If you want to mute the video,
         move the slider all the way to the left. If you’re somewhere noisy and want to hear better, move the slider
         to the right.`,
      ]),
      li([
        span("Full screen - ", ".bold"),
        `This allows you to view the video you’re watching in full-screen mode. To get out of full-screen mode, either
         click the button again on the video player or press the ESC button.`,
      ]),
    ]),
  ]),
  collapsible({
    title: "Can I download a video?",
  }, p(`Not right now, but we're working on ways to help you watch videos without an internet connection.
    Stay tuned!`)),
  collapsible({
    title: "Can I re-watch a video?",
  }, p(`Totally! Please feel free to watch the videos in the SmartEdge Content Library as many times
    as you'd like.`)),
  collapsible({
    title: "Does SmartEdge keep track of all the videos I have watched?",
  }, p(`Yes! To revisit your favorite pieces of content, check out the Recently Watched tab on the Homepage.`)),
  collapsible({
    title: "Can I only view a PDF through the browser? Can I download them for later?",
  }, p(`You can do both! You can view the PDF through your browser’s default PDF viewer.
    You also have the option to download PDFs to view them outside of your browser`)),
  collapsible({
    title: "What is a List?",
  }, p(`Lists inside SmartEdge are ways for instructors to group videos and files that are best viewed
    together. They’re like chapters inside a book.`)),
  collapsible({
    title: "Can I watch all the content in a List? How do I do this?",
  }, p(`Definitely! Click the Watch All button on the List popup. Every video inside of a list will
    automatically play in order.`)),
  collapsible({
    title: "Can I choose a specific piece of content to watch in a List?",
  }, p(`Of course! You can watch content in any order you like. Go to dropdown on List popup, select
    the content you'd like to view, and press Watch/View.`)),

  h3("Search"),
  collapsible({
    title: "How does the search feature work?",
  }, p(`The search bar is located at the top right corner of the SmartEdge interface. It allows you to quickly
    navigate your organization’s Content Library. You can search for anything- numbers, characters, phrases, course
    titles, instructor info, etc. Type your chosen terms or topics into the search bar and hit Enter. Your search
    results will appear on the next page. Search does not spell check for you, so be sure you are correctly typing in
    your terms or you will not see the intended results.`)),
  collapsible({
    title: "What if there are no search results?",
  }, p(`Please double check the search terms. If these are fine, then there are no search results available
    for that given search field.`)),
  collapsible({
    title: "What does See All do?",
  }, p(`To access See All, go to the Side Bar Menu and press See All. This page lets you view all
    Content/Lists on a single page. Pretty nifty, right?`)),
  collapsible({
    title: "Can I filter between videos, files, and lists on the Search and See All pages?",
  }, p(`Yes! Please use the toggles near the top of the page to filter Videos, Files, and Lists. You can also
    sort by video length, file size, alphabetical order, or date uploaded.`)),
  collapsible({
    title: "How does sort work?",
  }, p(`The sort function allows you to sort through the results on the Search or See All pages. You can sort
    by date (Oldest, Newest), content length (Shortest, Longest), file size (Smallest, Largest),
    or alphabetical order (A-Z, Z-A).`)),

  h3("Account Settings"),
  collapsible({
    title: "How to change my Account Settings?",
  }, p(`Select Account Settings on the Side Bar Menu. From there, you'll be able to modify the name,
    mobile number, email address, and password associated with your account. Make sure to press Save before leaving!`)),
  collapsible({
    title: "What happens if I change my password on the Account Settings page?",
  }, p(`You just need to enter a new password (along with the password again in the Confirm Password field).
    No need to log out and re-log in. You will not need to resubmit an OTP for user account verification. Make sure to
    remember the new password for next time you need to log into SmartEdge!`)),
  collapsible({
    title: "Can I modify my profile if I'm an external user?",
  }, p(`Unfortunately not through SmartEdge. You'll need to go to the account management page for the external
    credentials to modify your account. Once these changes on the external system are changed, feel free to log into
    SmartEdge with the new login info.`)),

  h3("Account Deletion"),
  collapsible({
    title: "How do I delete my account?",
  }, p(`If you are a registered user, go to Account Settings and select the Delete button. You will be asked
    to confirm that you would like to delete your account.`)),
  collapsible({
    title: "What happens if I accidentally delete my account?",
  }, p(`Unfortunately, account deletions are permanent and your organization’s SmartEdge admin can’t restore
    your old profile. However, you can always re-create your account if necessary.`)),
  collapsible({
    title: "What happens if I delete my account if I'm an external user?",
  }, p(`Your account will be deleted on SmartEdge but not on your organization’s external system. If you
    re-login with the external info again, your SmartEdge account will be recreated.`)),
]);

const showFaq = (isMobile) => () => {
  if (isMobile) {
    return showMobileUserFaq();
  }
  return showUserFaq();
};

export default async function endUserFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", {}, [
        h1("FAQ"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showFaq(isMobileWidth()))("#end-user-faq", state);
  loading.hide();
}
