/**
 * An avatar bubble.
 *
 * @module ui/component/avatar
 * @category UI
 * @subcategory Components
 */
import { featureTypes } from "model/config/constants";
import { messageStatus } from "@smartedge/em-message/constants";
import { canChat } from "model/user";
import { menuEntryTypes, menuEntryExclusiveTypes } from "model/site/menu/constants";
import { div, span } from "ui/html";
import { elId } from "util/dom";
import { emit } from "util/event";
import { featureEnabled } from "util/feature-flag";
import { buildMenuEntry } from "./header/menu";

const countNotifications = (notifications) => notifications.reduce(
  (acc, cur) => acc + (cur.status === messageStatus.UNREAD ? 1 : 0),
  0,
) || null;

const addNotificationsItemEntry = (menuItems, notifications) => {
  if (!menuItems) {
    return menuItems;
  }
  const counter = countNotifications(notifications);

  return {
    ...menuItems,
    entries: [
      {
        icon: "bell",
        label: "Announcements",
        type: menuEntryTypes.FUNCTION,
        cb: () => {
          // listened for by layout, opens notification modal
          emit(document.body, "em:open-notifications", notifications);
        },
        counter,
      },
      ...menuItems.entries,
    ],
  };
};

const clickOutSide = (event) => {
  if (!event.target.closest(".avatar-dropdown")) {
    const dropdownContainer = elId("avatar-container");
    dropdownContainer.classList.toggle("active");
    document.removeEventListener("click", clickOutSide);
  }
};

const dropdownToggle = (event) => {
  event.stopPropagation();
  const dropdownContainer = elId("avatar-container");
  const isToggled = dropdownContainer.classList.toggle("active");
  if (isToggled) {
    document.addEventListener("click", clickOutSide);
  } else {
    document.removeEventListener("click", clickOutSide);
  }
};

const createChatEntry = (user) => ((
  canChat(user)
  && featureEnabled(featureTypes.MESSAGING)
) ? buildMenuEntry({
    slug: "/chat",
    label: "Chat",
    icon: "messages",
    type: menuEntryTypes.STATIC,
    exclusive: menuEntryExclusiveTypes.WEB,
  })
  : ""
);

const makeMenuItems = (user, menuItems) => [
  createChatEntry(user),
  ...menuItems?.entries?.map?.(buildMenuEntry) || [],
];

const createDropdown = (user, menuItems) => div(".avatar-dropdown", [
  ...makeMenuItems(user, menuItems),
]);

const createNotificationsCounter = (notifications) => {
  const notificationsCount = countNotifications(notifications);
  const noNotificationsSelector = !notificationsCount ? ".hidden" : "";
  return div(
    `.notification-counter${noNotificationsSelector}`,
    notificationsCount,
  );
};

export default function avatar({ user, menuItems, notifications = [] }) {
  if (!user) return div("#avatar-container.avatar.placeholder");
  let nameText;
  if (user.lastName && user.firstName) nameText = `${user.firstName[0]}${user.lastName[0]}`;
  else nameText = user.username?.charAt(0) || "?";
  return div("#avatar-container", [
    span([
      nameText,
      createNotificationsCounter(notifications),
    ], ".dropdown-toggle", {
      on: {
        click: dropdownToggle,
      },
    }),
    createDropdown(user, addNotificationsItemEntry(menuItems, notifications)),
  ]);
}
