/**
 * A cameo for a User.
 *
 * @module ui/component/cameo-user
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import button from "ui/component/form-managed/button";
import icon from "ui/component/icon";
import userModal from "ui/component/modal/user";
import { userNamesString } from "util/format";
import { getModal } from "ui/view/modal-view";

export const showUser = (user) => () => getModal().async(
  userModal({ user }, getModal()),
);

export default function cameoUser({
  user,
  controls = null,
  config = {},
  sel = "",
}) {
  return cameo({
    icon: icon.solid("user"),
    label: userNamesString(user),
    config,
    controls: [
      button({ icon: "eye", iconOnly: true, onClick: showUser(user) }),
      ...(controls || []),
    ],
    sel: `${sel}.cameo-user`,
  });
}
