/**
 * A modal component for editing dynamic page sections.
 *
 * Relies on submodules for the various section types:
 *
 * - [Dynamic Lists]{@link module:ui/component/modal/section-edit/dynamic-list}
 * - [Playlists]{@link module:ui/component/modal/section-edit/playlists}
 * - [Slider Images]{@link module:ui/component/modal/section-edit/slider}
 * - [Plain Text]{@link module:ui/component/modal/section-edit/text}
 *
 * @module ui/component/modal/section-edit
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  pageTypes,
  pageTypesFriendly,
  pageTypesRequiringCourseData,
  pageTypesRequiringMetadata,
} from "model/dynamic-page/constants";
import { searchDataModes } from "model/search/constants";
import {
  metadataSortCriteria,
} from "model/metadata/constants";
import {
  button,
  h3,
  span,
} from "ui/html";
import form from "ui/component/form-managed";
import input from "ui/component/form-managed/field-input";
import dialog from "ui/component/modal/layout/dialog";
import formManagedView from "ui/view/form-managed";
import { bindForm } from "./common";
import courseFields from "./course";
import dynamicFields from "./dynamic-list";
import playlistFields from "./playlist";
import faqFields from "./faq";
import richTextFields, { destroyRTE } from "./rich-text";
import sliderFields from "./slider";
import textFields from "./text";
import textHeaderFields from "./text-header";
import staticMessages from "./messages";
import embeddedItem, { staticMessages as embeddedVideoImageMessages } from "./embedded-item";

let count = 0;
let storedFormView;
let notification;

/**
 * Load the editor for the selected section type.
 *
 * @function getTypeSpecificFields
 * @private
 */
const getTypeSpecificFields = (self, modalView) => {
  switch (self.state.section.type) {
    case pageTypes.TEXT:
      return textFields(self);
    case pageTypes.TEXT_HEADER:
      return textHeaderFields(self);
    case pageTypes.EMBEDDED_ITEM:
      return embeddedItem(self, modalView);
    case pageTypes.FAQ:
      return faqFields(self, modalView);
    case pageTypes.RICH_TEXT:
      return richTextFields(self, modalView);
    case pageTypes.PLAYLIST_CAROUSEL:
    case pageTypes.PLAYLIST_GRID:
      return playlistFields(self, modalView);
    case pageTypes.IMAGE_SLIDER:
      return sliderFields(self, modalView);
    case pageTypes.DYNAMIC_LIST_GRID:
    case pageTypes.DYNAMIC_LIST_CAROUSEL:
      return dynamicFields(self, modalView);
    case pageTypes.COURSE_MODULE_CAROUSEL:
    case pageTypes.COURSE_MODULE_GRID:
    case pageTypes.COURSE_OVERVIEW:
      return courseFields(self, modalView);
    default:
      return [span("Error: unsupported section type. Please report this to your site administrator.")];
  }
};

/**
 * @function buildEditForm
 * @private
 */
const buildEditForm = (modalView) => (self) => {
  const { section } = self.state;
  return form(
    "#section-edit",
    self.bind([
      [input, { type: "hidden", name: "type" }],
      ...getTypeSpecificFields(self, modalView),
    ], section),
  );
};

/**
 * @function onClose
 * @private
 */
const onClose = (modalView) => async () => {
  if (await modalView.confirm(
    "Are you sure you want to cancel your changes?",
  )) {
    destroyRTE();
    modalView.resolve(null);
    return true;
  }
  return false;
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  const section = inner.values;
  inner.updateState({ section });
  inner.patch(inner.factory(inner));
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initForm
 * @private
 */
const initForm = (state, modalView) => {
  if (storedFormView) {
    bindForm(storedFormView, modalView);
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "section-edit";
  modalView.qs(".body")
    .replaceChildren(formEl);
  storedFormView = formManagedView(
    buildEditForm(modalView),
    onFormUpdate,
  )(
    "#section-edit",
    state,
  );
};

/**
 * @function onFinish
 * @private
 */
const onFinish = async (state, modalView) => {
  storedFormView.setFullValidation(true);
  const formData = storedFormView.values;
  const validation = storedFormView.validate(true);
  if (!validation.valid) {
    notification.post(staticMessages.invalid);
    return;
  }
  if (formData.type === pageTypes.EMBEDDED_ITEM
    && !storedFormView.state.section.itemId
    && !storedFormView.state.section.images?.length) {
    storedFormView.update({
      messages: [embeddedVideoImageMessages.selectItem],
    });
    return;
  }
  if (pageTypesRequiringMetadata.has(formData.type) && !formData.itemId) {
    notification.post(staticMessages.selectPlaylist);
  } else if (pageTypesRequiringCourseData.has(formData.type) && !formData.itemId) {
    notification.post(staticMessages.selectCourse);
  } else {
    const searchData = (
      storedFormView.state.section.searchData || formData.mode || formData.collapsible
    )
      ? {
        ...(storedFormView.state.section.searchData || {}),
        terms: formData.terms || [],
        mode: formData.mode || searchDataModes.ADVANCED,
        preset: formData.preset,
        maxItems: formData.maxItems || 12,
        sortOrder: formData.sortOrder || metadataSortCriteria.DATE_DESC,
        searchDocuments: formData.searchDocuments || false,
        searchVideos: formData.searchVideos || false,
        searchPlaylists: formData.searchPlaylists || false,
        collapsible: formData.collapsible || undefined,
        leftSideText: formData.leftSideText,
        rightSideText: formData.rightSideText,
        asContentCard: formData.asContentCard,
        short: formData?.short,
      }
      : null;
    const responseData = {
      title: formData.title || null,
      text: formData.description || storedFormView.state.section.text || null,
      type: formData.type,
      itemId: formData.itemId || storedFormView.state.section?.itemId || null,
      images: storedFormView.state.section.images || [],
      searchData,
    };
    // new sections will not have an id. existing sections will.
    if (state.section.id) {
      responseData.id = state.section.id;
    }
    destroyRTE();
    storedFormView = null;
    modalView.resolve(responseData);
  }
};

/**
 * @function getDialogTitle
 * @private
 */
const getDialogTitle = (state) => (state?.section?.type
  ? pageTypesFriendly.get(state.section.type)
  : 'Section');

/**
 * A modal view for editing page sections. Note that the section type must already
 * be selected, though the rest of the fields can be uninitialized. Selecting the
 * section type is normally done with a list selection modal in the dynamic page
 * UIs.
 *
 * @function sectionEditModal
 * @param {object} state
 * @param {module:api/types/page~PageSection} state.section a page section to be edited
 * @param {?module:ui/view/modal~ModalView} modalView a modal view instance
 * @param {?NotificationView} notificationView a notification view instance
 * @param {Array.<string>} files a list of uploaded files in the media dump
 * @param {Array.<module:model/file-descriptor~FileDescriptor>} descriptors file descriptors
 */
export default function sectionEditModal(state, modalView = null, notificationView = null) {
  notification = notificationView;
  const editorDialog = dialog({
    sel: ".section-edit",
    config: {
      dataset: { sectionEditId: (++count), sectionType: state.section.type },
      hook: {
        insert: () => initForm(state, modalView),
        update: () => bindForm(modalView),
      },
      key: "section-edit-view",
    },
    header: h3(`Edit ${getDialogTitle(state)}`),
    body: span("placeholder"),
    footer: button("Finish Editing", () => onFinish(state, modalView)),
    onClose: onClose(modalView),
  });

  // wipe any stored form so it gets reinitialized (see initForm)
  storedFormView = null;

  return editorDialog;
}
