/**
 * Authentication-related utilities.
 *
 * @module util/authentication
 * @category Utilities
 */
import cache from "cache";
import config from "config";
import { userIsManager } from "model/user";
import { UnauthorizedError } from "model/error";
import { anonymousLogin } from "api/authentication";
import { doLoginRedirect } from "./navigation";
import { isAnonymous } from "./user";

/* eslint-disable-next-line import/prefer-default-export */
export const updateCookie = async () => {
  const token = await cache.getTokens().accessToken;
  const { domain } = (await config());
  const newCookie = `X-Access-Token=${token}; path=/; domain=.${domain}; SameSite=lax;`;
  document.cookie = newCookie;
};

/**
 * Check if the user is a manager, and redirect to 403 page if not.
 *
 * @throws UnauthorizedError to stop the page initialization process
 */
export const managersOnly = () => {
  const user = cache.getProfile();
  if (!user || isAnonymous(user)) {
    doLoginRedirect();
    return;
  }
  if (!user.groups || !userIsManager(user)) {
    window.location.replace("/403");
    throw new UnauthorizedError();
  }
};

export const isAuthenticated = () => !!cache?.getTokens()?.accessToken;

export const loginAsAnonymousIfNotAuthenticated = async (openSiteAccessEnabled) => {
  if (!openSiteAccessEnabled) return;
  if (!isAuthenticated()) {
    await anonymousLogin();
  }
};
