/**
 * Video metadata playback position.
 *
 * Provides `get` interface for fetching playback position collection by `userId`.
 *
 * @module data/metadata/playback-position
 * @category Data Streams
 * @subcategory Metadata
 */
import xs from "xstream";
import { getPositionsForUser } from "api/v2/analytics/video-playback";
import { CACHE_PLAYBACK_KEY_V2 as CACHE_KEY } from "cache/constants";
import { streamLog } from "data/stream/compose";
import single from "data/pipeline/single";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const defaultFn = (id) => ({ id, positions: hashmap() });

const modelFn = (partial) => ({
  id: partial.id,
  positions: hashmap(partial.positions || []),
});

const byId = async ([userId]) => {
  const results = await getPositionsForUser(userId);
  const positions = results.map((entry) => [entry.id, entry]);
  return [{
    id: userId,
    positions,
  }];
};

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
} = single({
  apiFn: byId,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const out$ = getSink$;

all$.imitate(out$);
all$.compose(streamLog("PLAYBACK_ALL$"));

export default {
  get,
  all$,
};
