/**
 * A chart component with toggle options
 *
 * @module ui/component/chart-with-toggles
 * @category UI
 * @subcategory Components
 */
import chart from "ui/component/chart";
import multiToggle from "ui/component/multi-toggle";
import { merge } from "util/object";

/**
 * @typedef ChartToggleOptions
 * @property {string} id
 * @property {string} label
 * @property {string} name
 * @property {string} value
 */

/**
 * @const defaultState
 * @property toggleOptions ChartToggleOptions[]
 * @property selectedTab number
 * @property onToggleSelect Function
 */
const defaultState = {
  toggleOptions: [],
  selectedTab: 0,
  onToggleSelect: () => {},
};

/**
 * @function chartWithToggles
 * @param {object} inState
 * @param {ChartData} ...inState.chartData
 * @param {ChartToggleOptions[]} inState.toggleOptions
 * @param {number} inState.selectedTab
 * @param {Function} inState.onToggleSelect
 * @param {...any} args the rest arguments
 */
export default function chartWithToggles({ state: inState = defaultState, ...args }) {
  const state = merge(defaultState, inState);
  return chart({
    ...args,
    state: {
      canvasId: state.canvasId,
      data: state.data,
      type: state.type,
      sel: ".sm-chart-with-toggle",
      children: [
        multiToggle({
          options: state.toggleOptions,
          selectedTab: state.selectedTab,
          onSelect: (type, index) => state.onToggleSelect(type, index),
        }),
      ],
    },
  });
}
