import progress from "ui/html/progress";

export default function progressBar({
  label = "",
  value = 0,
  min = 0,
  max = 100,
  sel = "",
  config = {},
}) {
  return progress(label, value, min, max, `${sel}.progress-bar`, config);
}
