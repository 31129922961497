/**
 * Search bar component for modal dialogs.
 *
 * @module ui/component/modal/component/header-search-bar
 * @category UI
 * @subcategory Modal Dialogs
 */
import input from "ui/component/form-managed/field-input";
import span from "ui/html/span";

/**
 * @callback SearchCallback
 * @param {string} searchText content of the search input field
 * @param {ModalView} modalView
 */

/**
 * A search bar for modal headers.
 *
 * @param {string} searchText
 * @param {SearchCallback} onSearch
 * @param {?string} state.search text to show in search input field
 * @param {?SearchCallback} state.onSearch callback when search is submitted
 */
export default function modalHeaderSearchBar(state, modalView) {
  return span(input({
    value: state.searchText,
    name: "modal_list_search",
    label: "Search...",
    onInput: () => {
      const newText = modalView.qs(`[name=modal_list_search]`).value;
      state.onSearch(newText, modalView);
    },
  }), ".search");
}
