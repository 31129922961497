/**
 * Requests for file descriptor endpoints.
 *
 * File descriptors represent a file that has been stored in the file storage backend
 * service - currently as binary blobs in a MongoDB isntance. The data backend defines
 * these files in FileDescriptor entities, which are served inline with metadata objects,
 * dynamic page sections, and potentially in other areas.
 *
 * File descriptors must be created whenever a file is to be attached to some other
 * entity (for example as a `VideoMetadata.videoFile`), before the entity is saved and
 * after the file itself has been uploaded.
 *
 * The file API provides facilities for creating file descriptors, and for retreiving
 * them by ID. The latter is mainly useful for displaying existing files, since other
 * entity endpoints contain attached file descriptors.
 *
 * ## Example:
 * ```js
 * const fileData = new FormData(document.querySelector("#my-form")).get("video-file");
 * const fileName = util.normalizeFileName(file.name);
 * const fileDescriptor = await api.file.uploadFile(fileName, file);
 * const updatedMetadata = await api.metadata.updateVideo(metadata.id, {
 *   ...metadata,
 *   videoFile: fileDescriptor,
 * });
 * ```
 *
 * ## File creation:
 *
 * The `create()` function is for generic/parametric file creation. The other create
 * functions automatically apply the correct fileType constant.
 *
 * Each create function accepts a file name as its parameter, which corresponds to
 * a file in the file storage backend service uploaded through the file upload endpoint
 * or the external file browser utility. If the file doesn't exist an error will be
 * thrown.
 *
 * - [create()]{@link module:api/file~create}: create a file descriptor
 * - [createDocument()]{@link module:api/file~createDocument}: create a Document file descriptor
 * - [createVideo()]{@link module:api/file~createVideo}: create a video file descriptor
 * - [createImage()]{@link module:api/file~createImage}: create an image file descriptor
 *
 * File retrieval:
 *
 * Descriptors are retrieved by a file descriptor's UUID using the
 * [get()]{@link module:api/file~get} function.
 *
 * ```js
 * const fileDescriptor = await api.file.get(metadata.videoFile.id);
 * ```
 *
 * Get all files with the [list()]{@link module:api/file~list} function.
 *
 * ```js
 * const fileDescriptors = await api.file.list();
 * ```
 *
 * @module api/file
 * @category Backend API
 * @subcategory File
 */
import {
  responseToFileDescriptor,
  makeFileDescriptorCreateDTO,
  makeFileUploadDTO,
} from "model/file-descriptor";
import { fileTypes } from "model/file-descriptor/constants";
import { endpoints } from "./constants";
import { post, postMultipart, get } from "./request";
import { list as metaList } from "./metadata"; // FIXME hack (see `list` function)
import { list as pageList, expand } from "./page";

/**
 * Create a new file descriptor entry.
 *
 * @example
 * const fileDescriptor = await api.fileDescriptor.create({
 *   name: fileName, // e.g. video_file.mp4
 *   type: fileTypes.VIDEO,
 * });
 *
 * @function create
 * @async
 * @param {object} partial matching {@link module:api/types/file~FileDescriptorCreateDTO}
 * @return {module:api/types/file~FileDescriptor} the created descriptor
 */
export const create = async (partial) => responseToFileDescriptor((await post(
  endpoints.FILE_DESCRIPTOR,
  null,
  makeFileDescriptorCreateDTO(partial),
  true,
)).body);

/**
 * Create a new file descriptor entry using batch.
 *
 * @example
 * const fileDescriptor = await api.fileDescriptor.create([{
 *   name: fileName, // e.g. video_file.mp4
 *   type: fileTypes.VIDEO,
 * }]);
 *
 * @function batchCreate
 * @async
 * @param {object[]} partial matching {@link module:api/types/file~FileDescriptorCreateDTO}
 * @returns {Promise<{entries: FileDescriptor[], errors: object[]}>} the created descriptor
 */
export const batchCreate = async (partial) => {
  const response = (await post(
    endpoints.FILE_DESCRIPTOR_BATCH,
    null,
    partial.map(makeFileDescriptorCreateDTO),
  )).body;
  return {
    entries: response.entries.map((entry) => (entry ? responseToFileDescriptor(entry) : null)),
    errors: response.errors,
  };
};

/**
 * Create a Document file, given just the file name.
 *
 * @function createDocument
 * @async
 * @param {string} name the file name
 * @return {module:api/types/file~FileDescriptor} the created descriptor
 */
export const createDocument = async (name) => responseToFileDescriptor((await post(
  endpoints.FILE_DESCRIPTOR,
  null,
  makeFileDescriptorCreateDTO({ name, type: fileTypes.DOCUMENT }),
  true,
)).body);

/**
 * Create a video file, given just the file name.
 *
 * @function createVideo
 * @async
 * @param {string} name file name
 * @return {module:api/types/file~FileDescriptor} the created descriptor
 */
export const createVideo = async (name) => responseToFileDescriptor((await post(
  endpoints.FILE_DESCRIPTOR,
  null,
  makeFileDescriptorCreateDTO({ name, type: fileTypes.VIDEO }),
  true,
)).body);

/**
 * Create a video file, given just the file name.
 *
 * @function createVideo
 * @async
 * @param {string} name the file name
 * @return {module:api/types/file~FileDescriptor} the created descriptor
 */
export const createImage = async (name) => responseToFileDescriptor((await post(
  endpoints.FILE_DESCRIPTOR,
  null,
  makeFileDescriptorCreateDTO({ name, type: fileTypes.IMAGE }),
  true,
)).body);

/**
 * get a file descriptor by id.
 *
 * @function getById
 * @async
 * @param {string} id UUID
 * @return {module:api/types/file~FileDescriptor} the created descriptor
 */
export const getById = async (uuid) => responseToFileDescriptor((await get(`${endpoints.FILE_DESCRIPTOR}/${uuid}`)).body);

/**
 * List all existing file descriptors.
 *
 * NOTE: right now this just gathers descriptors from metadata
 *
 * @funciton list
 * @async
 * @return {Array.<module:api/types/file~FileDescriptor>}
 */
export const list = async () => {
  // FIXME this is a hack until backend adds an endpoint for this
  const meta = await metaList()
    .catch(() => []);
  const pages = await pageList()
    .then((res) => Promise.all(res.map((page) => expand(page))))
    .catch(() => []);
  const byId = new Map(); // [id, descriptor]
  const checksums = new Set(); // [checksum, descriptor]
  const collect = (descriptor) => {
    if (!byId.has(descriptor.id) && !checksums.has(descriptor.checksum)) {
      byId.set(descriptor.id, descriptor);
      checksums.add(descriptor.checksum);
    }
  };
  meta.forEach((entry) => {
    if (entry.documentFile) collect(entry.documentFile);
    if (entry.posterFile) collect(entry.posterFile);
    if (entry.videoFile) collect(entry.videoFile);
  });
  pages.forEach((entry) => {
    entry.sections?.forEach((section) => {
      section.images.forEach((image) => {
        collect(image);
      });
    });
  });
  return [...byId.values()];
};

/**
 * Upload a file and create a file descriptor for it.
 *
 * @function uploadFile
 * @param {string} name for the file
 * @param {module:api/types/media~MediaFileUploadPartial} data
 *
 * @return {boolean} indicating success
 */
export const uploadFile = async (name, data) => responseToFileDescriptor(
  (await postMultipart(
    `${endpoints.FILE}/${name}`,
    null,
    makeFileUploadDTO(data),
  )).body,
);
