/**
 * A hero/banner image slider.
 *
 * Note it's up to the view or page to control slide timing and up to the
 * stylesheet to decide how to transition them.
 *
 * @module ui/component/hero-slider
 * @category UI
 * @subcategory Components
 */
import {
  a,
  div,
  h1,
  img,
  nav,
  section,
} from "ui/html";
import placeholder from "ui/component/placeholder";
import { emit } from "util/event";
import { slideTypes } from "model/dynamic-page/constants";
import { getContentFileUrl } from "model/file-descriptor";

let counter = 0;

const makeSliderId = (sliderIndex) => `#slides-${sliderIndex}`;
const makeSlideId = (sliderIndex, slideIndex) => `#slide-${sliderIndex}-${slideIndex}`;

const makeClickFn = (slideIndex, sliderId, onNavigate) => (e) => {
  onNavigate(slideIndex);
  emit(
    e.currentTarget,
    "em:slider-navigation",
    { sliderId, selected: slideIndex },
  );
};

const navigation = (slides, current, onNavigate) => nav(
  `#slides-${counter}-nav.slider-nav`,
  slides.map((_, i) => a.fn(
    "",
    makeClickFn(i, counter, onNavigate),
    `${i === current ? ".current" : ""}`,
  )),
);

/**
 * This callback is called when a user clicks one of the nav bubbles at the bottom of
 * the slider. The page is responsible for updating its state and patching the slider
 * with the selected ID as its `current` parameter.
 *
 * Alternatively, you can listen for the `em:slider-navigation` event.
 *
 * @callback SliderNavigationCallback
 * @param {int} slideIndex the index of the selected slide
 */

/**
 * @function heroSlider
 * @param {object} state
 * @param {module:api/types/page~SectionImages[]} slides
 * @param {number} current the slide currently visible
 * @param {SliderNavigateCallback} onNavigate called when the nav bubbles are clicked
 * @param {string} key
 * @param {string} sel
 * @return {module:ui/html/section}
 */
export default function heroSlider({
  slides = [],
  sliderIndex = ++counter,
  current = 0,
  onNavigate = () => {},
  key,
  sel = "",
}) {
  return section(
    `${makeSliderId(sliderIndex)}${sel}.hero-slider`,
    key ? { key } : {},
    [
      ...slides.map((item, i) => img(
        `${makeSlideId(sliderIndex, i)}${i === (current % slides.length) ? ".current" : ""}`,
        item.src,
        `slide ${i}`,
        {
          key: `image-${sliderIndex}-${i}`,
          on: {
            load: (ev) => {
              /* eslint-disable-next-line no-param-reassign */
              ev.target.dataset.imageLoaded = "loaded";
            },
          },
        },
      )),
      navigation(slides, current, onNavigate),
    ],
  );
}

heroSlider.placeholder = ({ sliderIndex, key }) => section(
  `${makeSliderId(sliderIndex)}.hero-slider`,
  key ? { key } : {},
  placeholder(),
);

heroSlider.generic = ({
  slides = [],
  sliderIndex = ++counter,
  current = 0,
  onNavigate = () => {},
  key,
  sel = "",
}) => section(
  `${makeSliderId(sliderIndex)}${sel}.hero-slider`,
  key ? { key } : {},
  [
    ...slides.map((slide, i) => {
      switch (slide.type) {
        case slideTypes.TEXT_BLOCK:
          return div(
            `${makeSlideId(sliderIndex, i)}${i === (current % slides.length) ? ".text-block.current" : ".text-block"}`,
            [
              h1(slide.name),
            ],
          );
        case slideTypes.IMAGE:
        default:
          return img(
            `${makeSlideId(sliderIndex, i)}${i === (current % slides.length) ? ".current" : ""}`,
            slide.url ? slide.url : getContentFileUrl(slide.id)(),
            `slide ${i}`,
            {
              key: `image-${sliderIndex}-${i}`,
              on: {
                load: (ev) => {
                  /* eslint-disable-next-line no-param-reassign */
                  ev.target.dataset.imageLoaded = "loaded";
                },
              },
            },
          );
      }
    }),
    navigation(slides, current, onNavigate),
  ],
);
