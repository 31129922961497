/**
 * An individualized icon for an ACL grant subject.
 *
 * @module ui/component/subject-icon
 * @category UI
 * @subcateogry Components - ACL
 */

import { aclSubjectTypes, aclSubjectTypesFriendly } from "model/acl/constants";
import icon from "ui/component/icon";

export const getSubjectIconName = (subjectType) => {
  switch (subjectType) {
    case aclSubjectTypes.PAGE:
      return "file-alt";
    case aclSubjectTypes.MEDIA:
      return "photo-video";
    case aclSubjectTypes.FILE:
      return "file";
    case aclSubjectTypes.COURSE:
      return "book";
    default:
      return "question-circle";
  }
};

/**
 * @function subjectIcon
 * @param {ACLSubjectType} subjectType
 */
export default function subjectIcon(subjectType) {
  return icon.solid(
    getSubjectIconName(subjectType),
    ".subject-icon",
    {
      props: {
        title: aclSubjectTypesFriendly.get(subjectType),
      },
    },
  );
}
