/**
 * A cameo for an ACL principal (user or group).
 *
 * @module ui/component/cameo-principal
 * @category UI
 * @subcategory Components
 */
import { aclPrincipalTypes } from "model/acl/constants";
import cameo from "ui/component/cameo";
import button from "ui/html/button";
import groupIcon from "ui/component/group-icon";
import icon from "ui/component/icon";
import userModal from "ui/component/modal/user";
import groupModal from "ui/component/modal/group";
import { principalDisplayName } from "util/format";
import { getModal } from "ui/view/modal-view";

export const showUser = (user) => () => getModal().async(userModal({ user }));

export const showGroup = (group) => () => getModal().async(groupModal({ group }));

/**
 * @function cameoPrincipal
 * @param {object} config
 * @param {ACLPrincipalEntry} config.principal
 * @param {?El[]} config.controls
 * @param {Selector} [config.sel=""]
 */
export default function cameoPrincipal({
  principal,
  controls = null,
  sel = "",
  config = {},
}) {
  const iconName = principal.type === aclPrincipalTypes.INDIVIDUAL ? "user" : "users";
  const control = [];

  switch (principal.type) {
    case aclPrincipalTypes.INDIVIDUAL:
      control.push(button(icon.solid("eye"), showUser(principal.entity)));
      break;
    case aclPrincipalTypes.GROUP:
      control.push(button(icon.solid("eye"), showGroup(principal)));
      break;
    default:
      break;
  }

  return cameo({
    icon: principal.system ? groupIcon(principal.name) : icon.solid(iconName),
    label: principalDisplayName(principal),
    controls: [
      ...(control || []),
      ...(controls || []),
    ],
    sel: `${sel}.cameo-principal`,
    config,
  });
}
