/**
 * The user-facing contnet search page.
 *
 * @module ui/page/search
 * @category Pages
 * @subcategory User Facing
 */
import api from "api";
import { pageVisit } from "api/v2/analytics";
import cache from "cache";
import fromEvent from "xstream/extra/fromEvent";
import { metadataTypes } from "model/metadata/constants";
import view from "ui/view";
import playbackStream from "data/metadata/playback-position";
import cardGrid from "ui/component/card-grid";
import searchControls, { searchModes } from "ui/component/search-controls";
import contentModal from "ui/component/modal/content";
import { div, section } from "ui/html";
import layout from "ui/page/layout";
import { frozen, merge } from "util/object";
import {
  reopenModal,
  getQueryParams,
  getSlug,
  reroute,
} from "util/navigation";
import { metadataAdvancedFilter, metadataScheduleFilter } from "util/filter";
import { metadataMultiSort } from "util/sort";
import hashmap from "util/hash-map";
import searchDashboard from "ui/component/search-dashboard";

let controlsView;

const defaultTypes = frozen(new Set([
  metadataTypes.VIDEO,
  metadataTypes.DOCUMENT,
  metadataTypes.LIST,
]));

const defaultSearchParams = frozen({
  terms: [],
  labels: [],
  sortCriteriaList: [],
  filter: null,
  types: defaultTypes,
});

const defaultState = frozen({
  user: {},
  searchParams: defaultSearchParams,
  searchMode: searchModes.ALL,
  items: [],
  allContent: [],
  playback: hashmap(),
});

const emptyTerms = (terms) => terms && terms?.length !== 0 && !(terms?.length === 1 && terms[0] === "");

const open = (self, modal) => ({ detail: { type, id } }) => {
  const { allContent, playback } = self.state;
  const metadata = allContent.find((i) => i.id === id);
  switch (type) {
    case metadataTypes.VIDEO:
      modal.async(contentModal({
        metadata,
        playback: playback?.get(id),
      }, modal));
      break;
    case metadataTypes.DOCUMENT:
      modal.async(contentModal({ metadata }, modal));
      break;
    case metadataTypes.LIST:
      modal.async(contentModal({ metadata }, modal));
      break;
    default:
      break;
  }
};

const noEmpty = (i) => i !== "" && i !== null && i !== undefined;

const noDynamic = (item) => item.type !== metadataTypes.DYNAMIC_LIST;

const getTitle = (searchTerms, searchMode) => (
  !emptyTerms(searchTerms) && searchMode === searchModes.RESULTS
    ? "Search Results"
    : "See All"
);

const getSearchMode = () => {
  switch (getSlug()) {
    case "see-all":
      return searchModes.ALL;
    case "search":
    default:
      return searchModes.RESULTS;
  }
};

const parseSearchParams = (queryParams) => merge(defaultSearchParams, {
  terms: (
    (queryParams.terms || "")
      .split(" ")
      .filter(noEmpty)
      .map((i) => i.trim())
  ),
  labels: (
    (queryParams.label || "")
      .split(" ")
      .filter(noEmpty)
      .map((i) => i.trim())
  ),
  sortCriteriaList: (
    (queryParams.sortCriteriaList || "")
      .split(",")
      .filter(noEmpty)
  ),
  types: (queryParams.types !== undefined
    ? new Set(queryParams.types
      .split(",")
      .filter(noEmpty))
    : defaultTypes
  ),
});

const applySearch = ({
  allContent,
  searchParams,
}) => {
  const result = [
    ...(searchParams.types.has(metadataTypes.VIDEO)
      ? allContent.filter(metadataAdvancedFilter({
        type: metadataTypes.VIDEO,
        ...searchParams,
      }))
      : []),
    ...(searchParams.types.has(metadataTypes.DOCUMENT)
      ? allContent.filter(metadataAdvancedFilter({
        type: metadataTypes.DOCUMENT,
        ...searchParams,
      }))
      : []),
    ...(searchParams.types.has(metadataTypes.LIST)
      ? allContent.filter(metadataAdvancedFilter({
        type: metadataTypes.LIST,
        ...searchParams,
      }))
      : []),
  ];

  if (searchParams?.sortCriteriaList?.length) {
    result.sort(metadataMultiSort(searchParams.sortCriteriaList));
  }
  return result;
};

const onTermsUpdate = (grid, controls) => (e) => {
  const newParams = new URLSearchParams(window.location.search);
  newParams.set("terms", e.detail.value);
  reroute(`search?${newParams.toString()}`, getTitle(e.detail.value, searchModes.RESULTS));
  const newState = {
    searchParams: parseSearchParams(getQueryParams()),
    searchMode: searchModes.RESULTS,
  };
  grid.update(newState);
  controls.update(newState);
};

const onClearResults = (grid, controls, header) => () => {
  reroute("see-all", getTitle(undefined, searchModes.ALL));
  const newState = {
    searchParams: defaultSearchParams,
    searchMode: searchModes.ALL,
  };
  grid.update(newState);
  controls.update(newState);
  header.update(newState);
};

const onPopState = (grid, controls, header) => () => {
  const newState = {
    searchMode: getSearchMode(),
    searchParams: parseSearchParams(getQueryParams()),
  };
  grid.update(newState);
  controls.update(newState);
  header.update(newState);
};

const onSortChange = (grid, controls) => (e) => {
  const newParams = new URLSearchParams(window.location.search);
  newParams.set("sortCriteriaList", e.detail.value);
  reroute(`${getSlug()}?${newParams.toString()}`, getTitle(e.detail.value, grid.state.mode));
  const newState = {
    searchParams: parseSearchParams(getQueryParams()),
  };
  grid.update(newState);
  controls.update(newState);
};

const onFilterToggle = (grid, controls) => (e) => {
  const newParams = new URLSearchParams(window.location.search);
  const types = e.detail;

  controls.update({ searchParams: { types } });

  if (types.has(metadataTypes.VIDEO)
    && types.has(metadataTypes.DOCUMENT)
    && types.has(metadataTypes.LIST)
  ) newParams.delete("types");
  else newParams.set("types", [...types.values()].join(","));

  reroute(`${getSlug()}?${newParams.toString()}`, getTitle(newParams.get("terms"), grid.state.mode));

  const newState = {
    searchParams: parseSearchParams(getQueryParams()),
  };
  grid.update(newState);
};

const showSearchDashboard = ({ state }) => div(".search-dashboard", [
  searchDashboard(state),
  cardGrid(state),
]);

const showSearchControls = ({ state }) => searchControls(state);

const onSearchUpdate = (grid) => {
  const items = applySearch(grid.state);
  grid.updateState({
    items,
  });
  controlsView?.updateState({
    items,
  });
  controlsView?.render();
  grid.render();
};

export default async (selector) => {
  const params = getQueryParams();
  const searchParams = parseSearchParams(params);
  const searchMode = getSearchMode();

  const {
    modal,
    header,
    loading,
  } = layout(selector, [
    section("#search-controls"),
    section("#search-dashboard"),
  ], getTitle(searchParams.terms, searchMode));

  loading.show();
  pageVisit(window.location.pathname);

  const [user, allContent, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.metadata.list()
      .then((list) => Object.freeze(
        list
          .filter(noDynamic)
          .filter(metadataScheduleFilter()),
      )),
    api.site.getUIConfiguration(),
  ]);

  const items = allContent
    .filter(metadataScheduleFilter())
    .filter(metadataAdvancedFilter(searchParams));

  const state = merge(defaultState, {
    user,
    searchParams,
    searchMode,
    items,
    allContent,
    horizontal: uiConfig.horizontalMenu,
  });

  header.update(state);
  const grid = view.create(showSearchDashboard, onSearchUpdate)("#search-dashboard", state);
  controlsView = view.create(showSearchControls)("#search-controls", state);

  // handle having navigated back from the video player
  reopenModal();

  grid.bindStreams([
    ["playback", playbackStream.get(cache.getProfile()?.id)
      .map((result) => result.positions),
    ],
  ]);

  // modal open requests from metadata sections
  fromEvent(document.body, "em:modal").addListener({ next: open(grid, modal) });

  // handle changing sort criteria
  document.body.addEventListener("em:sort-criteria", onSortChange(grid, controlsView));
  // handle changing search terms
  document.body.addEventListener("em:search-terms", onTermsUpdate(grid, controlsView));
  // handle clearing results/going to see-all
  document.body.addEventListener("em:search-see-all", onClearResults(grid, controlsView, header));
  // handle filter toggles
  document.body.addEventListener("em:filter-criteria", onFilterToggle(grid, controlsView));
  // handle forward/back
  window.addEventListener("popstate", onPopState(grid, controlsView, header));
  loading.hide();
};
