/**
 * Methods for building single rows on the bulk user entry table.
 *
 * @module ui/page/admin/user/bulk/user-row
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { div, span } from "ui/html";
import message from "ui/component/message";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import cellPhone from "ui/component/form-managed/field-phone-number";
import email from "ui/component/form-managed/field-email-address";
import firstName from "ui/component/form-managed/field-given-name";
import lastName from "ui/component/form-managed/field-family-name";
import password from "ui/component/form-managed/field-password";
import toggle from "ui/component/form-managed/field-toggle";
import username from "ui/component/form-managed/field-username";
import userGroup from "ui/component/form-managed/field-user-group-select";
import groupSelectMulti from "ui/component/modal/acl/group-select-multi";
import { saveStates } from "./state";
import {
  checkRequiredFields,
  fieldName,
  getUploadState,
  getUserValidity,
  getUserValidityMessages,
  refreshUsers,
} from "./util";

/**
 * Create helpText components from any attached message objects on the user record.
 */
const buildHelp = (status, messages) => {
  if (messages.length === 0 || status === saveStates.SUCCESS) {
    return "";
  }

  return div(
    ".helps",
    {},
    messages.map(message),
  );
};

/**
 * Determine status icon.
 */
const rowIcon = (user, status, validity, server, values) => {
  switch (status) {
    case saveStates.SUCCESS:
      return span(
        icon.solid("upload"),
        ".status.ok",
        { props: { title: "upload successful" } },
      );
    case saveStates.FAILURE:
      return span(
        icon.solid("exclamation-triangle"),
        ".status.error",
        { props: { title: "upload failed" } },
      );
    case saveStates.SAVING:
      return span(
        icon.solid("circle-notch"),
        ".status.saving",
        { props: { title: "saving..." } },
      );
    case saveStates.PENDING:
    default:
      if (validity.valid && checkRequiredFields(user, server, values)) {
        return span(
          icon.solid("check-circle"),
          ".status.ok",
          { props: { title: "all fields are valid" } },
        );
      }
      return span(
        icon.solid("times-circle"),
        ".status.error",
        { props: { title: "validation errors" } },
      );
  }
};

const userDisabled = (user, statuses) => {
  const status = statuses.get(user.tempId);
  return status === saveStates.SUCCESS || status === saveStates.SAVING;
};

const onSelectGroup = (user, page, modal) => async () => {
  let selectedGroups = [];
  if (user.groupNames?.length) {
    selectedGroups = page?.state?.groups
      ?.filter((group) => user.groupNames.some((gn) => gn === group.name));
  }
  const selected = await modal.async(
    groupSelectMulti({ groups: [...page.state.groups], selectedGroups }, modal),
  );
  if (selected?.length) {
    refreshUsers(page, page.state.users.map((theUser) => {
      if (theUser.tempId === user.tempId) {
        return {
          ...user,
          groups: selected,
          groupNames: selected.map((g) => g.name),
        };
      }
      return theUser;
    }));
  }
};

const removeIcon = (user, status, page, modal) => {
  let iconName, style, dangerous;

  if (status === saveStates.SUCCESS) {
    iconName = "check";
    style = ".secondary";
    dangerous = false;
  } else {
    iconName = "trash";
    style = ".danger";
    dangerous = true;
  }
  return button({
    icon: iconName,
    iconOnly: true,
    onClick: async () => {
      if (!await modal.confirm(
        "Are you sure you want to remove this user row?",
        "",
        "Yes",
        "No",
        dangerous,
      )) {
        return;
      }
      const users = page.state.users.filter((u) => u.tempId !== user.tempId);
      page.updateState({
        users,
      });
      page.update({
        uploadState: getUploadState(page),
        messages: [],
      });
    },
    sel: `.delete-row${style}`,
    label: "Remove Row",
  });
};

const updateUserVerified = (tempId, page) => (enabled) => {
  const users = page.state.users.map((u) => (u.tempId === tempId
    ? { ...u, enabled }
    : u
  ));
  refreshUsers(page, users);
};

export const userRow = (user, validation, override, values, page, modal) => {
  const disabled = userDisabled(user, page.state.saveStatuses);
  const status = page.state.saveStatuses.get(user.tempId);
  const validity = getUserValidity(user.tempId, validation);
  const messages = getUserValidityMessages(validity);
  const { server } = page.state.config;
  return ([
    page.state.userGroupMode
      ? [userGroup, {
        value: user.groupNames,
        onSelectGroup: onSelectGroup(user, page, modal),
        name: fieldName("groupNames", user.tempId),
        override,
      }] : "",
    [firstName, {
      value: user.firstName,
      name: fieldName("firstName", user.tempId),
      required: server.firstNameRequired,
      disabled,
      override,
    }],
    [lastName, {
      value: user.lastName,
      name: fieldName("lastName", user.tempId),
      required: server.lastNameRequired,
      disabled,
      override,
    }],
    [username, {
      taken: page.state.existingUsers,
      value: user.username,
      name: fieldName("username", user.tempId),
      required: true,
      disabled,
      override,
    }],
    [cellPhone, {
      taken: page.state.existingCellPhones,
      value: user.cellPhone,
      name: fieldName("cellPhone", user.tempId),
      required: server.cellPhoneRequired,
      disabled,
      override,
    }],
    [email, {
      taken: page.state.existingEmails,
      value: user.email,
      name: fieldName("email", user.tempId),
      required: server.emailRequired,
      disabled,
      override,
    }],
    [password, {
      value: user.password,
      name: fieldName("password", user.tempId),
      required: true,
      disabled,
      override,
      type: "text",
    }],
    [toggle, {
      toggled: !!user.enabled,
      label: "",
      name: fieldName("enabled", user.tempId),
      onToggle: updateUserVerified(user.tempId, page),
      override,
    }],
    rowIcon(user, status, validity, server, values),
    removeIcon(user, status, page, modal),
    buildHelp(status, messages),
  ]);
};

/**
 * Returns a function that creates a bulk upload form row for a user.
 *
 * @function rowFactory
 * @return function
 */
export const rowFactory = (page, modal) => {
  const { override } = page.state;
  const validation = page.validate();
  // this is a getter function, which collects the values during each call
  // so let's cache them
  const { values } = page;

  page.updateState({ override: false });

  return (user) => userRow(user, validation, override, values, page, modal);
};
