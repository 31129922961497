/**
 * Displays a list of groups a user belongs to, and optionally provides controls for
 * changing group membership.
 *
 * @module ui/component/user-group-membership
 * @category UI
 * @subcategory Components - ACL
 */
import { groupSortCriteria } from "model/acl/constants";
import {
  button,
  div,
} from "ui/html";
import icon from "ui/component/icon";
import cameoMemberGroup from "ui/component/cameo-member-group";
import { groupSort } from "util/sort";

/**
 * @function buildUserGroupsRows
 * @private
 */
const buildUserGroupsRows = (state, modalDialog) => {
  const {
    user,
    onAdd,
  } = state;
  return div(".groups", [
    ...[...user.groups]
      .sort(groupSort(groupSortCriteria.NAME_ASC))
      .map(
        (group) => cameoMemberGroup({
          group,
          ...state,
        }, modalDialog),
      ),
    onAdd ? button(
      [icon.solid("plus-circle"), "Add Group"],
      () => onAdd(user),
      ".stand-in",
      "button",
      "add group",
    ) : "",
  ]);
};

/**
 * Should present an interface for selecting a group and adding a user to it.
 *
 * @callback AddCallback
 * @param {User}
 */

/**
 * @function userGroupMembership
 * @param {object} state
 * @param {User} state.user
 * @param {module:ui/component/cameo-member-group~RemoveCallback} state.onRemove
 * @param {module:ui/component/cameo-member-group~ManageCallback} state.onManage
 * @param {module:ui/component/cameo-member-group~AddCallback} state.onAdd
 * @param {ModalView} modal
 */
export default function userGroupMembership(state, modalDialog) {
  return div(
    ".user-group-membership-container",
    buildUserGroupsRows(state, modalDialog),
  );
}
