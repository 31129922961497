/**
 * A modal dialog encapsulating start and end date selection.
 *
 * @module ui/component/modal/start-end-date-picker
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { h3, span } from "ui/html";
import dateTimePicker from "ui/component/form-managed/field-date-time";
import button from "ui/component/form-managed/button";
import buttonGroup from "ui/component/button-group";

export default function startEndDatePickerModal({ startDate, endDate }, modalView) {
  return dialog({
    sel: ".start-end-date-picker",
    header: h3("Please select date"),
    body: [
      span("Start"),
      dateTimePicker({
        max: endDate,
        name: "startDate",
        value: startDate,
        onChange: (date) => {
          modalView.patch(startEndDatePickerModal({
            startDate: new Date(date),
            endDate: endDate ? new Date(endDate) : undefined,
          }, modalView));
        },
      }),
      span("Stop"),
      dateTimePicker({
        min: startDate,
        name: "endDate",
        value: endDate,
        onChange: (date) => {
          modalView.patch(startEndDatePickerModal({
            startDate: startDate ? new Date(startDate) : undefined,
            endDate: new Date(date),
          }, modalView));
        },
      }),
      buttonGroup([
        button.primary({
          label: "Save",
          onClick: () => {
            modalView.resolve({ startDate, endDate });
          },
        }),
        (startDate || endDate)
          ? button.warning({
            label: "Clear",
            onClick: () => {
              modalView.resolve({
                startDate: undefined,
                endDate: undefined,
              });
            },
          })
          : "",
      ], ".centered"),
    ],
  }, modalView);
}
