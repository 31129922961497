/**
 * A form field for given names.
 *
 * @module ui/component/form-managed/field-given-name
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { nameConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "First/given names may contain only letters and spaces, and must be between 0-255 characters.",
  required: "Please enter a first or given name.",
});

/**
 * @private
 */
const defaultState = frozen({
  name: "firstName",
  label: "First Name",
  constraints: nameConstraints,
  autocomplete: "given-name",
  sel: "",
  helpTexts,
});

/**
 * A form field for given names.
 *
 * @function givenName
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="firstName"]
 * @param {string} [label="First Name"]
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function givenName(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.first-name`,
  });
}
