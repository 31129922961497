/**
 * A login modal dialog.
 *
 * @module ui/component/modal/login
 * @category UI
 * @subcategory Modal Dialogs
 */
import { featureTypes, verificationMethods } from "model/config/constants";
import dialog from "ui/component/modal/layout/dialog";
import bindModalForm from "ui/component/modal/bound-form";
import button from "ui/component/form-managed/button";
import form from "ui/component/form-managed";
import username from "ui/component/form-managed/field-username";
import password from "ui/component/form-managed/field-password";
import { doLogin } from "ui/page/login/login";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import { h3 } from "ui/html";
import cache from "cache";
import { featureEnabled } from "util/feature-flag";
import { merge } from "util/object";
import { doForgotPasswordRedirect, doLoginRedirect } from "util/navigation";
import messages from "ui/page/login/messages";
import { tidyBackendError } from "util/error";

const onLogin = async (self) => {
  const { userVerification } = self.state.config.server;
  try {
    await doLogin(self, getNotification(), true);
  } catch (error) {
    switch (error.statusCode) {
      case 401:
      case 404:
        getNotification().post(messages.signInInvalid);
        break;
      case 403: {
        if (userVerification === verificationMethods.DISABLED) {
          getNotification().post(messages.verificationRequiredDisabled);
        } else {
          cache.clear();
          window.location.replace("/verify");
        }
        break;
      }
      default:
        tidyBackendError(error).map((text) => getNotification().post(
          merge(messages.apiError, { text }),
        ));
    }
  } finally {
    self.enable();
  }
};

const formContent = (self) => form(
  "#login-form",
  self.bind([
    [username, { required: true }],
    [password, { weak: self?.state?.config?.server?.ldapEnabled, required: true }],
    [button, {
      label: "Login",
      onClick: () => onLogin(self),
      sel: "#submit-login",
      disabled: !self.validate().valid,
    }],
    [button, {
      label: "Forgot Password?",
      onClick: () => doForgotPasswordRedirect(),
      sel: "#forgot-password.subtle.back.alternate",
    }],
    featureEnabled(featureTypes.REGISTRATION) ? [button, {
      label: "Don't have an account? Register here.",
      onClick: () => doLoginRedirect(true),
      sel: "#sign-up.button.subtle.secondary",
    }] : "",
  ], self.state.login),
  { on: { keypress: (e) => e.which === 13 && onLogin(self) } },
);

export default function loginModal(inState = {}) {
  const params = new URL(window.location).searchParams;
  let redirect = "/";
  if (params.get("no-redirect") === null) {
    const param = params.get("redirect") || "/";
    // last-page is set during beforeunload by an event listener callback in app.js
    const lastPage = cache.getValue("last-page");
    if (lastPage === param) redirect = "/";
    else redirect = param;
  }

  const state = merge({ redirect }, inState);
  const formNode = bindModalForm("login-form", formContent, state);
  return dialog({
    sel: ".login-form",
    header: h3("Login"),
    body: formNode,
  }, getModal());
}
