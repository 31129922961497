/**
 * Models for feedback.
 *
 * @module model/feedback
 * @category Model
 * @subcategory Feedback
 */
import { required, validString } from "model/constraints";

/**
 * A feedback DTO.
 *
 * @typedef FeedbackDTO
 * @property {string} senderEmail
 * @property {string} senderName
 * @property {string} body
 */

/**
 * DTO for sending feedback.
 *
 * Sent to `/api/feedback` endpoint.
 *
 * @function makeFeedbackDTO
 * @param {object} partial
 * @param {string} partial.senderEmail
 * @param {string} partial.senderName
 * @param {string} partial.body
 * @return {FeedbackDTO}
 */
// eslint-disable-next-line import/prefer-default-export
export const makeFeedbackDTO = (partial) => ({
  senderEmail: partial.senderEmail,
  senderName: required(partial.senderName, "FeedbackDTO.senderName", validString),
  body: required(partial.body, "FeedbackDTO.body", validString),
});
