/**
 * Basic UI layout component.
 *
 * @module ui/page/layout
 * @category Layout
 * @subcategory Admin
 */
import { div, header } from "ui/html";
import el, { patch } from "ui/common/el";
import view from "ui/view";
import { setTitle } from "util/navigation";
import { bindNotificationModal } from "./common";

/**
 * Generates a basic page layout DOM and binds universal views.
 *
 * @function layout
 * @param {module:ui/html~Selector} selector selector for the root element of the page's main view
 * @param {module:ui/html~ChildEl} elements placeholder elements for the main view
 * @param {string} title page title, which will be set on the browser window/tab
 * @return {object} containing universal views: `{ header, loading, modal }`
 */
export default function layout(selector, elements = [], title = "") {
  const structure = div(`${selector}.page`, [
    header(),
    ...elements,
    div('#modal'),
    div("#loading"),
    div("#notifications"),
  ]);

  const container = el.fromDOM(document.querySelector(selector));

  patch(container, structure);

  const headerView = view.header('header', { user: {} });
  const loadingView = view.loading("#loading");
  const modalView = view.modal(() => "")("#modal", {});
  const notificationView = view.notification("#notifications", {});

  bindNotificationModal(headerView);

  setTitle(title);

  return {
    header: headerView,
    loading: loadingView,
    modal: modalView,
    notification: notificationView,
  };
}
