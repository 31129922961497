/**
 * v2 API endpoints for course assessments.
 *
 * @module api/v2/course/assessment
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import { responseToAssessment } from "model/course";
import { merge } from "util/object";

/**
 * Get modules by ids list
 *
 * @function getModulesByIds
 * @private
 * @param {UUID} courseId
 * @param {UUID} moduleIds
 * @returns {Promise.<CourseModule[]>}
 */
// eslint-disable-next-line import/prefer-default-export
export const byIds = async (courseId, moduleId, assessmentIds) => {
  if (moduleId === null) return [];
  if (courseId === null) return [];
  if (assessmentIds?.length === 0) return [];

  const response = (await get(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/assessment/?aid=${assessmentIds.join(",")}`,
  )).body;

  return response.map((item) => responseToAssessment(merge(item, { courseId, moduleId })));
};
