import xs from "xstream";
import cache from "cache";
import { CACHE_COURSE_EVALUATION_KEY_V2 as CACHE_KEY } from "cache/constants";
import { byCourseIdEvaluationId } from "api/v2/course/evaluation";
import single from "data/pipeline/single";
import many from "data/pipeline/many";
import { streamLog, sumStream } from "data/stream/compose";
import { makeAttendance as modelFn } from "model/course";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const defaultFn = ([courseId, id]) => modelFn({ courseId, id });

const getCacheKeyFromItem = (item) => (item ? [item?.courseId, item?.id] : []);

const all$ = xs.create();

const byIds = (pairs) => Promise.all(
  pairs.map(([courseId, evaluationId]) => byCourseIdEvaluationId(courseId, evaluationId)),
);

/**
 * Gets a single evaluation by courseId & evaluationId.
 *
 * @function get
 * @param {UUID[]} pair
 * @param {UUID} pair.0 courseId
 * @param {UUID} pair.1 evaluationId
 * @return {Stream.<Evaluation>}
 */
const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  getCacheKeyFromItem,
  accumulator: itemMap,
});

/**
 * Get multiple evaluations.
 *
 * @function getMany
 * @param {Array.<Array>} pairs array of [courseId, evaluationId] pairs
 * @param {UUID[]} pairs.index
 * @param {UUID} pairs.index.0 courseId
 * @param {UUID} pairs.index.1 evaluationId
 * @return {Stream<Evaluation>}
 */
const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  getCacheKeyFromItem,
  itemSink$: all$,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
);

const pending$ = sumStream(
  singlePending$,
  manyPending$,
);

all$.imitate(out$);
all$.compose(streamLog("EVALUATIONS_ALL$"));

/**
 * TODO: someday replace with a push stream that handles the whole
 *       process. For now this just pushes it to cache and pipes.
 */
const pushEvaluationProgress = (evaluation) => {
  itemMap.set(evaluation.id, evaluation);
  cache.storeExpiringMapEntry(CACHE_KEY, evaluation.id, evaluation);
  all$.shamefullySendNext(itemMap);
};

export default {
  get,
  getMany,
  all$,
  pending$,
  pushEvaluationProgress,
};
