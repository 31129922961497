/**
 * An ordered list.
 *
 * @module ui/html/ol
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * Creates an "<ol></ol>" element.
 *
 * @function ol
 * @param {module:ui/html~ChildEl} children
 * @param {module:ui/html~Selector} [selector=""]
 * @param {module:ui/html~Config} [data={}]
 * @return {module:ui/common/el~El}
 */
const ol = (children, sel = "", data = {}) => el(`ol${sel}`, data, children);

export default ol;
