/**
 * A modal dialog for selecting a file.
 *
 * @module ui/component/modal/file-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { fileTypes, fileMimeTypes, fileSelectionTypes } from "model/file-descriptor/constants";
import icon from "ui/component/icon";
import { getFileType } from "util/file";
import listModal from "ui/component/modal/layout/list";
import itemCameo from "ui/component/cameo-list-select-item";
import { frozen, merge } from "util/object";
import { getModal } from "ui/view/modal-view";

const getFileIcon = (entry) => {
  const fileType = (
    entry.type === fileSelectionTypes.DESCRIPTOR
      ? fileMimeTypes.get(entry.file.type) || fileTypes.UNKNOWN
      : getFileType(entry.file)
  );

  switch (fileType) {
    case fileTypes.VIDEO:
      return icon("file-video");
    case fileTypes.DOCUMENT:
      return icon("file-alt");
    case fileTypes.IMAGE:
      return icon("file-image");
    case fileTypes.UNKNOWN:
    default:
      return icon("file");
  }
};

const fileLine = (entry) => itemCameo({
  icon: getFileIcon(entry),
  label: entry.type === fileSelectionTypes.FILE
    ? entry.file
    : entry.file.name,
  onSelect: () => getModal().resolve(entry),
});

const doTabChange = (state) => (selectedTab) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(fileSelectModal({
    ...state,
    selectedTab,
  }));
};

const doSearch = (state) => (searchText) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(fileSelectModal({
    ...state,
    search: searchText,
  }));
};

const defaultState = frozen({
  uploads: [],
  descriptors: [],
  selectedTab: 0, // internal use
  search: "",
});

/**
 * A modal for selecting a file from a list of descriptors and/or the media dump.
 *
 * @param {Object} state
 * @param {string[]} uploads media dump file name list
 * @param {FileDescriptor[]} descriptors file descriptor object list
 * @param {string} search prepopulated search text
 */
export default function fileSelectModal(inState) {
  const state = merge(defaultState, inState);

  return listModal({
    entries: [
      state.descriptors
        ? state.descriptors
          .filter((file) => file.name.toLowerCase().includes(state.search.toLowerCase()))
          .map((file) => fileLine({
            type: fileSelectionTypes.DESCRIPTOR,
            file,
          }))
        : [],
      state.uploads
        ? state.uploads
          .filter((file) => file.toLowerCase().includes(state.search.toLowerCase()))
          .map((file) => fileLine({
            type: fileSelectionTypes.FILE,
            file,
          }))
        : [],
    ],
    tabLabels: (state.descriptors?.length && state.uploads?.length)
      ? ["Existing Media", "Uploaded Files"]
      : null,
    onTabChange: doTabChange(state),
    selectedTab: state.selectedTab,
    sel: ".file-select",
    searchText: state.search,
    onSearch: doSearch(state),
  });
}
