/**
 * A page for editing an existing user.
 *
 * Corresponds to `markup/admin/user-profile.html`
 *
 * @module ui/page/admin/user/profile
 * @category Pages
 * @subcategory Admin - Users
 */
import { messageTypes } from "@smartedge/em-message/constants";
import api from "api";
import log from "log";
import cache from "cache";
import getConfig from "config";
import { subWeeks } from "date-fns";
import cacheStream from "data/cache";
import courseStream from "data/course";
import { dropChoke } from "data/stream/buffer";
import attendanceStream from "data/course/attendance";
import userStream from "data/user";
import { tee } from "data/stream/compose";
import {
  systemPrivilegeNames,
  systemPrivilegeGroups,
  systemPrivilegeGroupsSet,
} from "model/acl/constants";
import { notificationMessageTypes } from "model/notification/constants";
import actionBar from "ui/component/dashboard-action-bar";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import cellPhone from "ui/component/form-managed/field-phone-number";
import email from "ui/component/form-managed/field-email-address";
import firstName from "ui/component/form-managed/field-given-name";
import lastName from "ui/component/form-managed/field-family-name";
import passwordConfirmed from "ui/component/form-managed/field-password-confirmed";
import toggle from "ui/component/form-managed/field-toggle";
import username from "ui/component/form-managed/field-username";
import groupSelectMulti from "ui/component/modal/acl/group-select-multi";
import messageListModal from "ui/component/modal/messaging/message-list";
import reportedMessageActionModal from "ui/component/modal/chat/reported-message-action";
import placeholder from "ui/component/placeholder";
import userGroupMembership from "ui/component/user-group-membership";
import widget from "ui/component/dashboard-widget";
import table from "ui/component/dynamic-table";
import icon from "ui/component/icon";
import spinner from "ui/component/spinner";
import a from "ui/html/a";
import div from "ui/html/div";
import span from "ui/html/span";
import dashboardLayout from "ui/page/layout/dashboard";
import {
  suspendUserDialog,
  restoreUserDialog,
} from "ui/page/admin/messaging/common";
import view from "ui/view";
import formManagedView from "ui/view/form-managed";
import { merge, frozen } from "util/object";
import { tidyBackendError } from "util/error";
import {
  findUserMembershipChanges,
  groupNameSet,
  mapPrivilegeGroups,
  prepareUserMembershipPatchRequests,
} from "util/acl";
import hashmap from "util/hash-map";
import { getPageViewsByUsers } from "api/v2/analytics";
import { getDateWeekAgo } from "util/date";
import { analyticsPageViewsByUserToDataset } from "model/chart";
import chartWithCalendar from "ui/component/chart/chart-with-calendar";
import { chartTypes } from "model/chart/constants";
import { guard } from "util/feature-flag";
import { featureTypes } from "model/config/constants";
import { isRoot } from "model/user";

let analytics, actions, accessControl, header, pageViewsView;
let loading, messaging, modal, notification, profile;

const { FAIL, SUCCESS } = notificationMessageTypes;

const defaultState = frozen({
  isSaveCanceled: false,
  me: {},
  user: {},
  users: hashmap(),
  searchParams: {
    terms: [],
  },
  validation: {
    fields: {},
  },
  config: {
  },
});

export const defaultSearchParams = frozen({
  id: null,
  title: null,
  entriesAmount: null,
});

const staticMessages = frozen({
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: FAIL },
  existUser: { title: "Failed", text: "The user no longer exists. Redirecting to user search...", type: FAIL },
  noPermission: { title: "Access Denied", text: "You do not have permission to edit this user.", type: FAIL },
  successDelete: { title: "User Deleted", text: "Redirecting to user search...", type: SUCCESS },
  notAllowed: { text: "You are not allowed to delete this user", type: "error" },
  doesNotExist: { title: "Couldn't Delete", text: "User does not exist (might already be deleted).", type: FAIL },
  errorDeleting: { title: "Couldn't Delete", text: "Internal error deleting user. Please try later.", type: FAIL },
  saveCanceled: {
    title: "Save Canceled",
    text: "Your changes were not saved. Please make desired adjustments and try again.",
    type: FAIL,
    duration: 3,
  },
  success: { title: "Saved!", type: SUCCESS },
  userSuspended: {
    title: "User Suspended",
    text: "User's chat privileges have been revoked.",
    type: SUCCESS,
  },
  userRestored: {
    title: "User Restored",
    text: "User's chat privileges have been restored.",
    type: SUCCESS,
  },
});

const getUserData = async () => {
  const urlParams = (new URL(window.location)).searchParams;
  const userId = urlParams.get("id");
  let user;
  try {
    user = await api.user.getById(userId, true);
  } catch (e) {
    log.error(e);
    let str = '';
    switch (e.statusCode) {
      case 404:
        await modal.alert(`User '${userId}' no longer exists. Redirecting to Find User page.`);
        setTimeout(() => window.location.replace('/admin/manage-users'), 2000);
        break;
      case undefined:
        await modal.alert(e.message);
        break;
      default:
        tidyBackendError(e.body).forEach((err) => {
          str += `\r\n ${err}`;
        });
        await modal.alert(str);
        return false;
    }
    return false;
  }
  return user;
};

const deleteUser = async (page) => {
  const urlParams = new URL(window.location).searchParams;
  const userId = urlParams.get("id");
  const defaultError = { error: { text: "", type: "error" } };
  const response = await modal.confirm(
    "Are you sure you want to delete this user?",
    "This action is irreversible.",
    "Yes",
    "No",
    true,
  );
  if (response) {
    try {
      await api.user.deleteById(userId);
      await cacheStream.clearUser(userId);
      await modal.alert(`${page.state.user.username} has been deleted.`);
      window.location.replace('/admin/manage-users');
    } catch (e) {
      switch (e.statusCode) {
        case 403:
          notification.post(staticMessages.notAllowed);
          return false;
        case 404:
          notification.post(staticMessages.doesNotExist);
          return false;
        case 500:
          notification.post(staticMessages.errorDeleting);
          return false;
        case 400:
        default:
          tidyBackendError(e.body).forEach((err) => {
            defaultError.error.text += `\r\n ${err}`;
          });
          notification.post(defaultError.error);
          return false;
      }
    }
  }

  return true;
};

/**
 * Sanity checks for adding and removing users from groups.
 *
 * If any check fails the form is updated with an appropriate message, some invalid
 * changes may be reverted, and the function returns false.
 *
 * @function validateMembershipChanges
 * @param {ACLPrincipalEntry} group
 * @param {User} user
 * @return {boolean} true if all changes are valid
 */
const validateMembershipChanges = async () => {
  const { user } = profile.state;
  const changes = findUserMembershipChanges(
    user.groups,
    accessControl.state.originalGroups,
  );
  const { ADMIN } = systemPrivilegeGroups;

  const newGroups = groupNameSet(changes.inserted);
  const existingGroups = groupNameSet(profile.state.originalGroups);
  const removedGroups = groupNameSet(changes.removed);

  const willGainAdmin = !existingGroups.has(ADMIN) && newGroups.has(ADMIN);
  const willLoseAdmin = removedGroups.has(ADMIN) || !existingGroups.has(ADMIN);
  const willHaveAdmin = (existingGroups.has(ADMIN) && !willLoseAdmin) || willGainAdmin;

  const autoCanceledRemovals = [], canceledRemovals = [], canceledInsertions = new Set();
  /* eslint-disable no-await-in-loop */
  // eslint-disable-next-line no-restricted-syntax
  for (const change of changes.removed) {
    if (systemPrivilegeGroupsSet.has(change.name)) {
      if (user.username === "admin") {
        // admin may not be removed from privilege groups, no matter what
        // (this may be enforced on frontend only, but we don't want to permit this
        //  since it's probably a mistake)
        autoCanceledRemovals.push(change);
      } else if (change.name !== ADMIN && willHaveAdmin) {
        autoCanceledRemovals.push(change);
      } else if (!await modal.confirm(
        `Are you sure you want to revoke ${systemPrivilegeNames.get(change.name)}
          privileges for this user?`,
      )) {
        canceledRemovals.push(change);
      }
    }
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const change of changes.inserted) {
    if (systemPrivilegeGroupsSet.has(change.name)) {
      if (
        (!willHaveAdmin || (willGainAdmin && change.name === ADMIN))
        && !await modal.confirm(
          `Are you sure you want to grant ${systemPrivilegeNames.get(change.name)}
            privileges to this user?`,
        )
      ) {
        canceledInsertions.add(change.id);
      }
    }
  }
  /* eslint-enable no-await-in-loop */

  // show the alerts & confirms only once even if there were multiple cancellations
  if (autoCanceledRemovals.length) {
    if (user.username === "admin") {
      await modal.alert(
        "The admin user may not be removed from system privilege groups.",
      );
    } else {
      await modal.alert(
        `You have attempted to remove a user with administrative privileges from
        a system privilege group. This will not result in a change in the user's access
        rights, so this change has been reverted.`,
      );
    }
  }

  if (autoCanceledRemovals.length
    || canceledRemovals.length
    || canceledInsertions.size) {
    profile.update({
      user: {
        groups: [
          ...user.groups.filter(
            (group) => !canceledInsertions.has(group.id),
          ),
          ...autoCanceledRemovals,
          ...canceledRemovals,
        ],
      },
    });
    notification.post(staticMessages.saveCanceled);
    return false;
  }
  return changes;
};

const onSave = async (self) => {
  self.setFullValidation(true);
  const validation = self.validate();
  if (!validation.valid) {
    notification.post(staticMessages.invalid);
    return false;
  }

  const changes = await validateMembershipChanges();

  if (!changes) {
    return false;
  }

  const qs = self.element.querySelector.bind(self.element);

  const { user: userData } = self.state;

  const data = {
    ...userData,
    ...self.values,
  };

  const password = qs("[name=newPassword]").value;
  const passwordConfirm = qs("[name=newPasswordConfirm]").value;
  if (password && passwordConfirm && password === passwordConfirm) {
    data.password = password;
  }

  const defaultError = { title: "API Error", text: "", type: FAIL };

  if (!self.state.isSaveCanceled) {
    try {
      loading.show("Saving user...");
      const response = await api.user.update(data);
      const allUsers = await api.user.list();
      const membershipPatches = prepareUserMembershipPatchRequests(userData, changes);
      await Promise.all(membershipPatches.map((call) => call()));
      /* eslint-disable no-param-reassign */
      qs("[name=newPassword]").value = "";
      qs("[name=newPasswordConfirm]").value = "";
      /* eslint-enable no-param-reassign */
      self.update({
        user: { response, groups: userData.groups },
        allUsers,
        takenUsernames: allUsers.map((u) => u.username).filter((n) => n !== response.username),
        takenEmails: allUsers.map((u) => u.email).filter((em) => em !== response.email),
        takenPhones: allUsers.map((u) => u.cellPhone).filter((ph) => ph !== response.cellPhone),
        originalGroups: userData.groups,
      });
      cacheStream.clearUser(response.id);
      notification.post(staticMessages.success);
    } catch (e) {
      switch (e.statusCode) {
        case 404:
          notification.post(staticMessages.existUser);
          setTimeout(() => window.location.replace('/admin/manage-users'), 2000);
          return false;
        case 403:
          notification.post(staticMessages.noPermission);
          return false;
        case 400:
        default:
          tidyBackendError(e.body).forEach((err) => {
            notification.post({
              ...defaultError,
              text: err,
            });
          });
          return false;
      }
    } finally {
      loading.hide();
    }
  }

  return true;
};

const onAddUserToGroup = async (user, groupsView) => {
  const { groups } = groupsView.state;
  const existingGroupNames = groupNameSet(user.groups);
  const filteredGroups = groups.filter((g) => !existingGroupNames.has(g.name));
  const newGroups = await modal.async(groupSelectMulti({
    groups: filteredGroups,
  }, modal));
  const {
    ADMIN,
    MEDIA_MANAGEMENT: MEDIA,
    FILE_MANAGEMENT: FILES,
    USER_MANAGEMENT: USERS,
  } = systemPrivilegeGroups;
  const privileges = mapPrivilegeGroups(groups);
  if (newGroups.some((group) => group.name === ADMIN)) {
    if (!existingGroupNames.has(MEDIA) && !newGroups.some((group) => group.name === MEDIA)) {
      newGroups.push(privileges.get(MEDIA));
    }
    if (!existingGroupNames.has(FILES) && !newGroups.some((group) => group.name === FILES)) {
      newGroups.push(privileges.get(FILES));
    }
    if (!existingGroupNames.has(USERS) && !newGroups.some((group) => group.name === USERS)) {
      newGroups.push(privileges.get(USERS));
    }
  }
  const oldGroups = user.groups;
  profile.update({ user: { groups: [...oldGroups, ...newGroups] } });
};

const onDeleteUserFromGroup = async (group, groupsView) => {
  const { user } = groupsView.state;
  const filteredGroups = user.groups.filter(
    (item) => item.id !== group.id,
  );
  profile.update({ user: { groups: filteredGroups } });
};

const navigateToGroupManagement = (group) => {
  window.open(`/admin/group-profile?id=${group.id}`, "_blank");
};

const showUserData = (self) => {
  const { server } = self.state.config;
  const { me, user, takenUsernames, takenEmails, takenPhones } = self.state;

  const isMyAccount = user.id === me.id;
  const required = true;

  return form("#user-profile-form", self.bind([
    [username, { required, taken: takenUsernames, disabled: true }],
    [firstName, { required: server.firstNameRequired }],
    [lastName, { required: server.lastNameRequired }],
    [cellPhone, { required: server.cellPhoneRequired, taken: takenPhones }],
    [email, { required: server.emailRequired, taken: takenEmails }],
    [passwordConfirmed, { disabled: user.external }],
    div('.toggle-wrapper', self.bind([
      [
        toggle.boxed.inverse,
        { name: "enabled", label: "Verified", disabled: isMyAccount },
      ],
      [
        toggle.boxed.inverse,
        { name: "external", label: "External", sel: ".faux" },
      ],
    ], user)),
    [
      toggle.boxed.inverse,
      { name: "resetVerificationCodeResendCount", label: "Reset OTP Attempts" },
    ],
  ], user));
};

const showGroups = (groupsView) => div("#group-membership", [
  userGroupMembership({
    user: groupsView.state.user,
    onRemove: (group) => onDeleteUserFromGroup(group, groupsView),
    onManage: navigateToGroupManagement,
    onAdd: (user) => onAddUserToGroup(user, groupsView),
  }, modal),
]);

const messageCount = (list) => (list.length > 250
  ? "> 250"
  : list.length || 0
);

const showMessageListModal = (messages, state) => () => modal.async(messageListModal({
  user: state.user,
  users: [...state.users.values()],
  groups: state.groups,
  messages: [...messages.values()],
}, modal));

const showReport = async (state) => modal.async(
  reportedMessageActionModal(state, modal),
);

const showReportListModal = (messages, state) => () => modal.async(messageListModal({
  user: state.user,
  users: [...state.users.values()],
  groups: state.groups,
  messages: [...messages.values()].map((m) => ({
    ...m.reportedMessage,
    reportId: m.id,
    created: m.created,
  })),
  onClick: (message) => showReport({
    ...state,
    report: messages.find((m) => m.id === message.reportId),
  }),
}, modal));

const suspendUser = async () => {
  const ok = await suspendUserDialog(profile.state.user, modal);
  if (ok) {
    const groups = profile.state.user.groups
      .filter((g) => g.name !== systemPrivilegeGroups.USER_MESSAGING);
    const originalGroups = profile.state.originalGroups
      .filter((g) => g.name !== systemPrivilegeGroups.USER_MESSAGING);

    profile.update({
      user: {
        groups,
      },
      originalGroups,
    });
    notification.post(staticMessages.userSuspended);
  }
};

const restoreUser = async () => {
  const ok = await restoreUserDialog(profile.state.user, modal);
  if (ok) {
    const group = profile.state.groups
      .find((g) => g.name === systemPrivilegeGroups.USER_MESSAGING);
    if (group) {
      profile.update({
        user: {
          groups: [...profile.state.user.groups, group],
        },
        originalGroups: [...profile.state.originalGroups, group],
      });
    }
    notification.post(staticMessages.userRestored);
  }
};

const showMessaging = (self) => {
  const messagingEnabled = self.state.user.groups.some(
    (g) => g.name === systemPrivilegeGroups.USER_MESSAGING,
  );

  const hasUsers = self.state.users.size;

  const display = div("#messaging", [
    self.state.loadingMessageData
      ? placeholder(spinner())
      : table({
        columnLabels: ["Weekly Activity", "#", " "],
        columns: ["label", "count", "actions"],
        rows: [
          {
            label: span([icon.solid("paper-plane", ".primary"), "Sent Messages"]),
            count: messageCount(self.state.recentSentMessages),
            actions: [
              button.icon({
                icon: hasUsers ? "eye" : "spinner-third",
                onClick: showMessageListModal(self.state.recentSentMessages, self.state),
                disabled: !self.state.recentSentMessages.length || !hasUsers,
              }),
            ],
          },
          {
            label: span([icon.solid("mailbox", ".secondary"), "Received Messages"]),
            count: messageCount(self.state.recentReceivedMessages),
            actions: [
              button.icon({
                icon: hasUsers ? "eye" : "spinner-third",
                onClick: showMessageListModal(self.state.recentReceivedMessages, self.state),
                disabled: !self.state.recentReceivedMessages.length || !hasUsers,
              }),
            ],
          },
          {
            label: span([icon.solid("comment-exclamation", ".warning"), "Reporting"]),
            count: messageCount(self.state.recentReportsByUser),
            actions: [
              button.icon({
                icon: hasUsers ? "eye" : "spinner-third",
                onClick: showReportListModal(
                  self.state.recentReportsByUser,
                  self.state,
                ),
                disabled: !self.state.recentReportsByUser.length || !hasUsers,
              }),
            ],
          },
          {
            label: span([icon.solid("siren-on", ".danger"), "Reported"]),
            count: messageCount(self.state.recentReportsForUser),
            actions: [
              button.icon({
                icon: hasUsers ? "eye" : "spinner-third",
                onClick: showReportListModal(
                  self.state.recentReportsForUser,
                  self.state,
                ),
                disabled: !self.state.recentReportsForUser.length || !hasUsers,
              }),
            ],
          },
          messagingEnabled || isRoot(self.state.user)
            ? {
              label: span([icon.solid("comment-check", ".secondary"), "Chat Enabled"]),
              count: "",
              actions: [
                button.warning({
                  iconOnly: true,
                  label: "Suspend",
                  icon: "comment-times",
                  onClick: () => suspendUser(),
                  disabled: isRoot(self.state.user) || self.state.user.id === self.state.me.id,
                }),
              ],
            }
            : {
              label: span([icon.solid("comment-times", ".danger"), "Chat Suspended"]),
              count: "",
              actions: [
                button.secondary({
                  iconOnly: true,
                  label: "Enable",
                  icon: "comment-check",
                  onClick: () => restoreUser(),
                  disabled: self.state.user.id === self.state.me.id,
                }),
              ],
            },
        ],
      }),
  ]);
  return display;
};

const showActions = () => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: () => onSave(profile),
    disabled: !profile.validate().valid,
  }),
  button.warning({
    icon: "trash",
    label: "Delete",
    onClick: () => deleteUser(profile),
    disabled: profile.state.isMyAccount,
  }),
]);

const itemsReady = (ids, itemMap) => ids
  ?.map((id) => itemMap.get(id))
  .reduce((acc, cur) => (acc && !!cur && !cur.PLACEHOLDER), true);

const assessmentsReady = (att, evaluations, assessments) => {
  if (!itemsReady(att.evaluationIds, evaluations)) return false;
  return att.evaluationIds
    .map((id) => assessments.get(evaluations.get(id).assessmentId))
    .reduce((acc, cur) => acc && cur && !cur.PLACEHOLDER, true);
};

const mapEvaluations = (evaluations) => new Map(
  [...evaluations.values()].map((e) => [e.id, e]),
);

const buildEvalCount = (att, course, modules) => {
  if (!itemsReady(course?.moduleIds, modules)) return spinner();
  const assessCount = course.moduleIds.map((id) => modules.get(id))
    .reduce((acc, cur) => acc + (cur?.assessmentIds?.length || 0), 0);
  return `${att.evaluationIds.length} / ${assessCount}`;
};

const buildScore = (att, evaluations, assessments) => {
  if (!assessmentsReady(att, evaluations, assessments)) return spinner();
  const evals = att.evaluationIds.map((id) => evaluations.get(id));
  const score = evals.reduce((acc, cur) => acc + cur.score.points, 0);
  const available = evals.map((ev) => assessments.get(ev.assessmentId))
    .reduce((acc, cur) => acc + cur.maxPoints, 0);
  return `${score} / ${available}`;
};

const courseTitleLine = (course) => (course
  ? span([
    course.title,
    a(icon("external-link"), `/admin/course-profile?id=${course.id}`, "", { props: { target: "_blank" } }),
  ])
  : spinner());

const showAnalytics = ({ state }) => {
  const { courses, attendance, assessments, evaluations, modules } = state;
  if (state.pending > 0) {
    return placeholder(spinner(), "#analytics");
  }
  if (attendance.size === 0) {
    return div("#analytics.empty-placeholder", [
      "User is not enrolled in any courses.",
    ]);
  }
  return div("#analytics", [
    table({
      columnLabels: ["Course", "Evaluations", "Points"],
      rows: [...attendance.values()]
        .filter((att) => att && !att.PLACEHOLDER)
        .map((att) => [
          courseTitleLine(courses.get(att?.courseId)),
          buildEvalCount(att, courses.get(att.courseId), modules),
          buildScore(att, evaluations, assessments),
        ]),
      placeholder: "User is not enrolled in any courses.",
    }),
  ]);
};

const pageViewsState = (pageViews, userId) => ({
  type: chartTypes.BAR,
  data: analyticsPageViewsByUserToDataset(pageViews),
  onDateSelected: async ({ startDate, endDate }) => {
    if (!startDate && !endDate) {
      return;
    }
    const views = await getPageViewsByUsers({
      startDate,
      endDate,
      window: "1d",
      visitedBy: userId,
    });
    pageViewsView.update({
      data: analyticsPageViewsByUserToDataset(views),
    });
  },
});

/**
 * An updateFn callback for the form view.
 *
 * This will get called any time the form changes (defined as when a form element
 * goes out of focus with new content), or when `page.update()` is called.
 *
 * Before this hook is called the page runs validation and exposes updated validation
 * state on `page.validation`.
 *
 * Here we take values from the form exposed in `page.values` and update the user model
 * in page.state, then we patch the form view with the updated model and validation
 * status.
 *
 * @function doUpdate
 * @private
 */
const doUpdate = (self) => {
  self.updateState({
    user: {
      ...self.state.user,
      ...self.values,
    },
  });
  self.render();
  if (accessControl) accessControl.update(self.state);
  if (messaging) messaging.update(self.state);
  if (actions) actions.render();
};

/**
 * The page initializer for user profile.
 *
 * @function userProfile
 * @param {module:ui/html~Selector} selector the root element for the user profile form
 * @param {object} initialState (deprecated)
 */
export default async function userProfile(selector, initialState = defaultState) {
  guard(featureTypes.USER_MANAGEMENT);
  ({ modal, header, loading, notification } = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget(form("#profile"), "Profile", ".profile"),
      widget(placeholder(spinner(), "#group-membership"), "Access Control", ".group-membership"),
      widget(placeholder(spinner(), "#messaging"), "Messaging", ".messaging"),
      widget(placeholder(spinner(), "#page-views"), "Page Views", ".page-views"),
      widget(placeholder(spinner(), "#analytics"), "Course Progress", ".analytics"),
    ],
    "User Profile",
  ));
  loading.show();

  const me = cache.getProfile();
  const [
    config,
    user,
    groups,
    canEdit,
  ] = await Promise.all([
    getConfig(),
    await getUserData(),
    api.acl.listGroups(),
    api.acl.isUserInUserManagementGroup(me.id),
  ]);
  const pageViews = await getPageViewsByUsers({
    startDate: getDateWeekAgo(),
    endDate: new Date(),
    window: "1d",
    visitedBy: user.id,
  });

  const state = merge(defaultState, merge(initialState, {
    me,
    user,
    config,
    groups,
    canEdit: canEdit || isRoot(me),
    pageViews,
    isMyAccount: user.id === me.id,
    title: user.username,
    originalGroups: [...user.groups.map((g) => merge({}, g))],
  }));

  const analyticsState = {
    courses: hashmap(),
    attendance: hashmap(),
    evaluations: hashmap(),
    assessments: hashmap(),
    pending: 0,
  };

  const messagingState = {
    me,
    user,
    users: hashmap(),
    groups,
    recentSentMessages: [],
    recentReceivedMessages: [],
    recentReportsByUser: [],
    recentReportsForUser: [],
    loadingMessageData: true,
  };

  header.update({ title: user.username });
  profile = formManagedView(showUserData, doUpdate)("#profile", state);
  if (!state.canEdit) profile.disable();

  actions = view.create(showActions)("#actions", state);
  accessControl = view.create(showGroups)("#group-membership", state);
  analytics = view.create(showAnalytics)("#analytics", analyticsState);
  messaging = view.create(showMessaging)("#messaging", messagingState);
  pageViewsView = view.chart(chartWithCalendar(modal))("#page-views", pageViewsState(pageViews, user.id));

  loading.hide();

  const messageParams = {
    startDateTime: new Date(),
    endDateTime: subWeeks(new Date(), 1),
    messageCount: 1001,
  };

  const filterChat = (messages) => messages.filter((m) => m.type === messageTypes.CHAT_TEXT);

  const filterReport = (messages) => messages.filter((m) => m.type === messageTypes.REPORT);

  Promise.all([
    api.message.getSentMessagesByUserId(user.id, messageParams).then(filterChat),
    api.message.getReceivedMessagesByUserId(user.id, messageParams).then(filterChat),
    api.message.getReportsByUserId(user.id, messageParams).then(filterReport),
    api.message.getReportsForUserId(user.id, messageParams).then(filterReport),
  ]).then(([
    recentSentMessages,
    recentReceivedMessages,
    recentReportsByUser,
    recentReportsForUser,
  ]) => messaging.update({
    recentSentMessages,
    recentReceivedMessages,
    recentReportsByUser,
    recentReportsForUser,
    loadingMessageData: false,
  }));

  courseStream.search([{}, 0]);
  courseStream.all$
    .compose(dropChoke(10))
    .compose(tee((courses) => {
      attendanceStream.getMany([...courses.keys()].map((cid) => ([cid, user.id])));
    }));

  userStream.search([{}, 0]);

  const taken$ = userStream.all$.map((users) => {
    const takenUsernames = [];
    const takenEmails = [];
    const takenPhones = [];
    [...users.values()].forEach((u) => {
      if (u.username && u.username !== user.username) takenUsernames.push(u.username);
      if (u.email && u.email !== user.email) takenEmails.push(u.email);
      if (u.cellPhone && u.cellPhone !== user.cellPhone) takenPhones.push(u.cellPhone);
    });
    return { takenUsernames, takenEmails, takenPhones };
  });

  profile.bindStreams([
    ["allUsers", userStream.all$],
    ["takenUsernames", taken$.map((taken) => taken.takenUsernames)],
    ["takenEmails", taken$.map((taken) => taken.takenEmails)],
    ["takenPhones", taken$.map((taken) => taken.takenPhones)],
  ]);

  messaging.bindStreams([
    ["users", userStream.all$],
  ]);

  analytics.bindStreams([
    ["courses", courseStream.all$],
    ["pending", attendanceStream.manyPending$],
    ["attendance", attendanceStream.all$],
    ["modules", courseStream.getModules()],
    ["assessments", courseStream.getAssessments()],
    ["evaluations", attendanceStream.getEvaluations().map(mapEvaluations)],
  ]);
}
