/**
 * Page templates for pre-generated pages.
 *
 * @module model/dynamic-page/templates
 * @category Model
 * @subcategory Dynamic Page
 */
import { generateSlug } from "ui/page/admin/dynamic/common";
import { frozen } from "util/object";
import { pageTypes } from "./constants";

/**
 * A template for generating a course carousel page from a course object.
 *
 * @param {Course} course
 * @param {Page[]} pages used for slug generation
 * @private
 * @return {DynamicPage}
 */
/* eslint-disable-next-line import/prefer-default-export */
const templateCoursePageCarousel = (course, pages) => ({
  title: course.title,
  slug: generateSlug(course.title, pages),
  sections: [
    {
      title: `${course.title} Overview`,
      itemId: course.id,
      type: pageTypes.COURSE_OVERVIEW,
    },
    {
      title: `${course.title} Modules`,
      itemId: course.id,
      type: pageTypes.COURSE_MODULE_CAROUSEL,
    },
  ],
});

/**
 * List of page template types.
 * @enum pageTemplateTypes
 * @property {string} COURSE_CAROUSEL a course page with module carousel
 */
export const pageTemplateTypes = frozen({
  COURSE_CAROUSEL: "COURSE_CAROUSEL",
});

/**
 * Collection of factories for course carousels as a map.
 * @typedef {Map.<pageTemplateType,pageTemplateFactory>} pageTemplates
 */
export const pageTemplates = frozen(new Map([
  [pageTemplateTypes.COURSE_CAROUSEL, templateCoursePageCarousel],
]));
