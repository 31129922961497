/**
 * @module ui/component/admin-search
 * @category UI
 * @subcategory Components
 */
import {
  button,
  div,
  input,
} from "ui/html";
import modal from "ui/component/modal";
import { clearSearchResult } from "util/navigation";

const doSearch = (page, searchParams) => {
  page.update({
    searchMode: "advanced",
    searchFilter: null,
    searchParams,
  });
};

const showAdvancedBlock = (type, page, modalDialog) => {
  clearSearchResult(page);
  if (type === 'content') {
    const labels = new Set([]);
    page.state.tableList.forEach((entry) => {
      entry.categories.forEach((l) => labels.add(l));
    });
    modalDialog.show(
      modal.advancedContentSearch({
        title: "Advanced Search",
        labels: [...labels],
        params: page.state.searchParams,
        onSearch: (searchParams) => {
          page.updateState({ searchParams });
          doSearch(page, searchParams);
        },
      }, modalDialog),
    );
  } else if (type === "group") {
    modalDialog.show(
      modal.advancedGroupSearch({
        title: "Advanced Search",
        params: page.state.searchParams,
        onSearch: (searchParams) => {
          page.update({ searchParams });
          doSearch(page, searchParams);
        },
      }, modalDialog),
    );
  } else {
    modalDialog.show(
      modal.advancedUserSearch({
        title: "Advanced Search",
        params: page.state.searchParams,
        onSearch: (searchParams) => {
          page.update({ searchParams });
          doSearch(page, searchParams);
        },
      }, modalDialog),
    );
  }
};

const doFilter = (page, defaultSearchParams) => {
  page.update({
    searchMode: "basic",
    searchFilter: document.querySelector("[name=filter]").value,
    searchParams: { ...defaultSearchParams },
  });
};

const adminSearch = ({
  page,
  defaultSearchParams,
  modalDialog,
  type,
}) => {
  const result = div('.searchBlock', [
    input.text(page.state.searchParams.filter || "", false, '', {
      props: { placeholder: 'some search keywords', name: 'filter' },
      on: {
        keypress: (e) => {
          if (e.keyCode === 13) {
            doFilter(page);
          }
        },
      },
    }),
    button('Search', () => doFilter(page, defaultSearchParams)),
    button('Advanced', () => showAdvancedBlock(type, page, modalDialog), ".secondary"),
  ]);

  return result;
};

export default adminSearch;
