/**
 * A light wrapper around snabbdom with a couple extra utility functions.
 *
 * @module ui/common/el
 * @category UI
 * @subcategory Elements
 */
import { init } from 'snabbdom/init';
import { classModule } from 'snabbdom/modules/class';
import { propsModule } from 'snabbdom/modules/props';
import { attributesModule } from 'snabbdom/modules/attributes';
import { styleModule } from 'snabbdom/modules/style';
import { eventListenersModule } from 'snabbdom/modules/eventlisteners';
import { datasetModule } from 'snabbdom/modules/dataset';
import { h } from 'snabbdom/h';
import { toVNode } from 'snabbdom/tovnode';
import deepmerge from "deepmerge";
import { parseElement } from "util/dom";
/**
 * A virtual DOM node, as a wrapper around a snabbdom vNode with
 * some extra functionality.
 *
 * @callback ElMerge
 * @param {object.<module:ui/html/ElArgs>} data configuration object
 *
 * @typedef El
 * @property {ElMerge} merge merges new configuration object with existing node config.
 */

/**
 * The snabbdom patch function, as returned by snabbdom's init() function.
 *
 * @function patch
 *
 */
export const patch = init([
  classModule,
  propsModule,
  attributesModule,
  styleModule,
  eventListenersModule,
  datasetModule,
]);

/**
 * Attaches additional methods and properties to a snabbdom vNode.
 * @function makeEl
 * @private
 * @return {El}
 */
const makeEl = (vNode) => {
  /* eslint-disable no-param-reassign */
  vNode.merge = (data) => {
    if (data) vNode.data = deepmerge(vNode.data, data);
    return vNode.data;
  };
  return vNode;
  /** eslint-enable no-param-reassign */
};

/**
 * A wrapper around snabbdom's `h()`. The snabbdom node is composed with a few helper
 * functions and properties specific to SmartEdge.
 *
 * The `sel` parameter is like a css selector but slightly more strict: it must be in
 * `{tag}{id}{class}{other}` order, and only `tag` is required.
 *
 * The `config` parameter is optional and can be omitted entirely, as can the `children`
 * parameter.
 *
 * @Example
 * // valid:
 * el("div#foo.bar.baz[data-quz=quuz]");
 * el("div#foo");
 * el("div.bar");
 * el("div");
 *
 * // invalid (selectors out of order):
 * el("div.foo#bar");
 * el(".foo");
 * el("div[data-foo=baz].foo");
 *
 * // valid:
 * el("div#foo", { attr: { bar: "baz" } }, "Some text.");
 * el("div#foo", [el("span", "Some text."), el("span", "Other text.")]);
 *
 * // invalid (children cannot precede config):
 * el("div#foo", "Some text", { attr: { bar: "baz" } });
 *
 * @function el
 * @param {string} sel CSS-style selector including tag
 * @param {object} [config] snabbdom configuration object
 * @param {string|mixed[]} [children] element children, either a plain string or an
 *                                    array of strings and elements
 *
 * @return {El}
 */
const el = (...args) => {
  const vNode = h(...args);

  return makeEl(vNode);
};

/**
 * Produce a snabbdom element from plain HTML text.
 *
 * @function fromHTML
 * @param {string} html valid HTML string
 *
 * @return {El}
 */
el.fromHTML = function fromHTML(html) {
  const vNode = toVNode(parseElement(html));
  return makeEl(vNode);
};

/**
 * Produce a snabbdom element from a DOM HTMLElement node.
 *
 * @function fromDOM
 * @param {HTMLElement} domElement
 * @return {El}
 */
el.fromDOM = function fromDOM(domElement) {
  const vNode = toVNode(domElement);
  return makeEl(vNode);
};

el.patch = patch;

export default el;

/**
 * Wraps child text nodes into spans. Used by certain elements that accept mixed
 * text nodes and other elements, so that their children can be styled appropriately.
 *
 * @param {ChildEl} children
 * @return {ChildEl}
 */
export const textNodesToSpans = (children) => {
  if (typeof children === "string") {
    return el(`span`, children);
  }
  if (children instanceof Array) return children.map(textNodesToSpans);
  return children;
};
