/**
 * Fields for configuring a dynamic carousel or grid.
 *
 * @module ui/component/modal/section-edit/dynamic-list
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import { sortCriteriaLabeled } from "model/metadata/constants";
import {
  cardFormatOptions,
  cardSizeOptionsLabeled,
  pageTypes,
} from "model/dynamic-page/constants";
import { searchDataModes, searchDataModesLabeled, searchDataPresetsLabeled } from "model/search/constants";
import { div } from "ui/html";
import fieldSearchTerms from "ui/component/form-managed/field-search-terms";
import select from "ui/component/form-managed/field-select";
import toggle from "ui/component/form-managed/field-toggle";
import multiToggle from "ui/component/form-managed/field-multi-toggle";
import title from "ui/component/form-managed/field-title";
import number from "ui/component/form-managed/field-number";
import input from "ui/component/form-managed/field-input";

const presets = [...searchDataPresetsLabeled.entries()].map(([value, name]) => ({
  name,
  value,
}));

const searchModeSelect = (formView) => multiToggle({
  label: "Mode",
  name: "mode",
  onSelect: (option) => {
    formView.update({ section: { searchData: { mode: option.value } } });
  },
  value: formView.state.section.searchData.mode,
  options: [
    {
      label: searchDataModesLabeled.get(searchDataModes.PRESET),
      value: searchDataModes.PRESET,
    },
    {
      label: searchDataModesLabeled.get(searchDataModes.ADVANCED),
      value: searchDataModes.ADVANCED,
    },
  ],
});

const cardSizeSelect = (formView) => multiToggle({
  label: "Card Format",
  onSelect: (option) => {
    formView.update({
      section: {
        searchData: { cardSize: option.value },
      },
    });
  },
  value: formView.state.section.searchData.cardSize || cardFormatOptions.VERTICAL,
  options: [
    {
      id: "card-size-small",
      label: cardSizeOptionsLabeled.get(cardFormatOptions.VERTICAL),
      name: "cardSize",
      value: cardFormatOptions.VERTICAL,
    },
    {
      id: "card-size-big",
      label: cardSizeOptionsLabeled.get(cardFormatOptions.HORIZONTAL),
      name: "cardSize",
      value: cardFormatOptions.HORIZONTAL,
    },
  ],
});

const searchPresetSelect = (formView) => select({
  name: "preset",
  options: presets.map(({ name, value }) => ({
    label: name,
    value,
  })),
  label: "Search Preset",
  required: true,
  value: formView.state.section.searchData.preset,
  onChange: (newValue) => formView.update({ section: { searchData: { preset: newValue } } }),
  placeholder: "-- Please Select --",
});

const searchSortSelect = (formView) => select({
  name: "sortOrder",
  options: [...sortCriteriaLabeled].map(({ label: name, value }) => ({
    label: name,
    value,
  })),
  label: "Sort By",
  required: true,
  value: formView.state.section.searchData.sortOrder,
  onChange: (newValue) => formView.update({ section: { searchData: { sortOrder: newValue } } }),
});

/**
 * @function dynamicListFields
 * @private
 *
 * @param self
 * @param {module:ui/view/modal~ModalView} modalView
 * @return {module:ui/html~ChildEl[]}
 */
export default function dynamicListSectionEditor(self) {
  const { section } = self.state;
  return (section.searchData.mode === searchDataModes.ADVANCED)
    ? [
      div(".pane", self.bind([
        [title, { value: section.title }],
        [input, { value: section.text, label: "No entries text", name: "text" }],
        searchModeSelect(self),
        section.type === pageTypes.DYNAMIC_LIST_CAROUSEL ? cardSizeSelect(self) : "",
      ])),
      div(".pane", self.bind([
        [fieldSearchTerms, { value: section.searchData.terms }],
        [number, { value: self.state.section.searchData.maxItems, required: true, label: "Limit", name: "maxItems" }],
        searchSortSelect(self),
        [
          toggle.boxed.inverse,
          {
            toggled: self.state.section.searchData.searchVideos,
            name: "searchVideos",
            label: "Search Videos",
            onToggle: (searchVideos) => self.update({ section: { searchData: { searchVideos } } }),
          },
        ],
        [
          toggle.boxed.inverse,
          {
            toggled: self.state.section.searchData.searchDocuments,
            name: "searchDocuments",
            label: "Search Documents",
            onToggle: (searchDocuments) => self
              .update({ section: { searchData: { searchDocuments } } }),
          },
        ],
        [
          toggle.boxed.inverse,
          {
            toggled: self.state.section.searchData.searchPlaylists,
            name: "searchPlaylists",
            label: "Search Playlists",
            onToggle: (searchPlaylists) => self
              .update({ section: { searchData: { searchPlaylists } } }),
          },
        ],
      ])),
    ]
    : [
      div(".pane", self.bind([
        [title, { value: section.title }],
        [input, { value: section.text, label: "No entries text", name: "text" }],
        searchModeSelect(self),
        section.type === pageTypes.DYNAMIC_LIST_CAROUSEL ? cardSizeSelect(self) : "",
      ])),
      div(".pane", self.bind([
        searchPresetSelect(self),
        [number, { value: self.state.section.searchData.maxItems, required: true, label: "Limit", name: "maxItems" }],
      ])),
    ];
}
