/**
 * An H2 element.
 *
 * @module ui/html/h2
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 *
 * @function h2
 * @param {mixed} [children=""] string, vnode, or vnode[]
 * @param {string} [sel=""] css selectors
 * @param {object} [config={}] snabbdom config object
 */
export default (children = "", sel = "", config = {}) => el(`h2${sel}`, config, children);
