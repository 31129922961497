/**
 * @module ui/component/form-managed/file-picker/util
 * @private
 */

/**
 * Helper for finding an input on a file picker.
 * @function makeGetInput
 * @private
 */
/* eslint-disable-next-line import/prefer-default-export */
export const makeGetInput = (name) => (which) => document.querySelector(
  `[data-file-picker-id="${name}"] input[name="${name}_${which}"]`,
);
