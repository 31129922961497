/**
 * Renders the plain-text section type for dynamic pages.
 *
 * @module ui/page/dynamic/section-text
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import collapsible from "ui/component/collapsible";
import {
  div,
  h2,
  p,
  section,
} from "ui/html";

const splitParagraphs = (text) => text?.split(/[\r\n]+/)?.filter((t) => t).map((paragraph) => p(paragraph)) || [];

/**
 * A plain text section.
 *
 * @function sectionText
 * @param {module:model/dynamic-page~PageSection} section of type `TEXT`
 * @return {module:ui/common/el~El} section element
 */
export default function sectionText(pageSection) {
  if (!pageSection.text && !pageSection.searchData?.leftSideText && !pageSection.searchData?.rightSideText) return ""; // don't display section if empty
  const textSectionOneParagraphs = splitParagraphs(pageSection.searchData?.leftSideText);
  const textSectionTwoParagraphs = splitParagraphs(pageSection.searchData?.rightSideText);
  const textSectionThreeParagraphs = splitParagraphs(pageSection.text);
  const displayedParagraphs = [
    textSectionOneParagraphs?.length ? div(".text-section", ...textSectionOneParagraphs) : "",
    textSectionTwoParagraphs?.length ? div(".text-section", ...textSectionTwoParagraphs) : "",
    textSectionThreeParagraphs?.length ? div(".text-section", ...textSectionThreeParagraphs) : "",
  ];
  if (pageSection.searchData?.collapsible && pageSection.title) {
    return section(
      ".text.collapsing",
      collapsible(
        { title: pageSection.title },
        div(".inner", div(".text-container", displayedParagraphs)),
      ),
    );
  }
  return section(".text", [
    pageSection.title ? h2(pageSection.title) : "",
    div(".text-container", displayedParagraphs),
  ]);
}
