/**
 * State default for dynamic page areas.
 *
 * @module ui/page/admin/dynamic/state
 * @private
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
import { sortOrders } from "ui/component/dynamic-table";
import { frozen } from "util/object";
import { cardFormatOptions } from "model/dynamic-page/constants";
import { CURRENT_MENU_VERSION } from "model/site/menu/constants";

/** used for sorting on manage/index page */
export const columnsFields = {
  id: "id",
  title: "title",
  slug: "slug",
};

/** an empty page */
export const defaultPage = {
  title: "",
  slug: "",
  sections: [],
};

/** an empty section */
export const defaultSection = {
  title: "",
  text: "",
  type: "TEXT",
  searchData: {
    maxItems: 12,
    cardSize: cardFormatOptions.VERTICAL,
  },
  images: [],
  itemId: null,
};

/** default validation state for add/edit */
export const defaultValidation = {
  valid: true,
  fields: {},
};

export const searchModes = frozen({
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
  NONE: null,
});

export const displayModes = frozen({
  PAGE_PROFILE: "PAGE_PROFILE",
  HELP: "HELP",
});

/** used on manage page for searching */
export const defaultSearchParams = frozen({
  id: "",
  title: "",
  slug: "",
  sortField: "",
  sortOrder: "",
  filter: null,
});

/** default state of the editor pages */
export const defaultEditState = frozen({
  config: {},
  user: {},
  page: { ...defaultPage },
  files: [],
  pages: [],
  courses: [],
  metadata: [],
  descriptors: [],
  removedSections: [],
  messages: [],
  validation: defaultValidation,
  displayMode: displayModes.PAGE_PROFILE,
  sideMenu: {
    version: CURRENT_MENU_VERSION,
    entries: [],
  },
});

/** default state of the manage/index page */
export const defaultManageState = frozen({
  searchParams: { ...defaultSearchParams },
  config: {},
  user: {},
  pages: [],
  filteredPages: [],
  sortOrder: sortOrders.ASC,
  sortColumn: "title",
  searchMode: searchModes.NONE,
});
