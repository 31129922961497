/**
 * Constants for site configuration models.
 *
 * @module model/site/constants
 * @category Model
 * @subcategory Site
 */
import { frozen } from "util/object";

export * from "./menu/constants";

export const CURRENT_COLOR_PALETTE_VERSION = 1;

export const COLOR_PALETTE_KEY = "COLOR_PALETTE";

export const CURRENT_CONFIGURATIONS_VERSION = 0;

export const CONFIGURATION_KEY = "UI_CONFIGURATIONS";

export const BRANDING_KEY = "BRANDING";

export const CURRENT_BRANDING_VERSION = 0;

export const paletteModes = frozen({
  DARK: "dark",
  LIGHT: "light",
});

export const paletteModesSet = frozen(new Set(Object.values(paletteModes)));
