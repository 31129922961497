/**
 * A button.
 *
 * @module ui/component/form-managed/button
 * @category UI
 * @subcategory Forms
 */

import iconComponent from "ui/component/icon";
import buttonEl from "ui/html/button";
import { frozen, merge } from "util/object";

const getIconFactory = (iconType) => {
  switch (iconType) {
    case "solid":
      return iconComponent.solid;
    case "regular":
      return iconComponent.solid;
    default:
      return iconComponent;
  }
};

const defaultState = frozen({
  disabled: false,
  faux: false,
  icon: null,
  iconType: "solid",
  iconOnly: false,
  label: "",
  onClick: undefined,
  sel: "",
  swap: false,
  type: "button",
});

/**
 * A form button.
 *
 * If `icon` param is provided and `iconOnly` param is true the label will not be shown
 * on the button, but is still used for tooltip and aria-label.
 *
 * @function button
 * @param {object} state
 * @param {boolean} [state.disabled=false]
 * @param {boolean} [state.faux=false] if true, button will not have click or hover/etc.
 *                  states
 * @param {string} [state.icon] a valid icon class (see icon component)
 * @param {boolean} [state.iconOnly] if true, label will not be shown on button
 * @param {string} [label=""] label text for the button (required if no icon is provided)
 * @param {ButtonClickCallback} [onClick=undefined] onclick callback function
 * @param {Selector} [sel=""]
 * @param {boolean} [swap=false] swap the order of label & icon
 * @param {string} [type="button"] HTML button type: `button` or `submit`
 */
export default function button(inState) {
  const state = merge(defaultState, inState);
  const icon = state.icon
    ? getIconFactory(state.iconType)(
      state.icon,
      "",
      { props: { title: state.label, ariaLabel: state.label } },
    )
    : "";
  const label = icon && state.iconOnly
    ? ""
    : state.label;
  const sel = `${state.iconOnly ? ".icon" : ""}${state.faux ? ".faux" : ""}${state.sel}`;
  return buttonEl(
    state.swap ? [label, icon] : [icon, label],
    !state.faux && state.onClick ? state.onClick : undefined,
    sel,
    state.type,
    state.label,
    state.disabled,
    state.faux ? { attrs: { tabindex: "-1" } } : {},
  );
}

const buttonStyle = (baseState) => (state) => button(merge.all([
  baseState,
  state,
  {
    sel: `${state.sel || ""}${baseState.sel || ""}`,
  },
]));

/**
 * Preset for icon-only buttons.
 */
button.icon = buttonStyle({ iconOnly: true });

/**
 * Preset for stand-in buttons.
 */
button.standIn = buttonStyle({ sel: ".stand-in" });

/**
 * Preset for stand-in buttons.
 */
button.inverse = buttonStyle({ sel: ".inverse" });

/**
 * Preset for primary buttons.
 */
button.primary = buttonStyle({ sel: ".primary" });

/**
 * Preset for secondary buttons.
 */
button.secondary = buttonStyle({ sel: ".secondary" });

/**
 * Preset for alternate buttons.
 */
button.alternate = buttonStyle({ sel: ".alternate" });

/**
 * Preset for warning/danger buttons.
 */
button.warning = buttonStyle({ sel: ".danger" });

/**
 * Preset for accent buttons.
 */
button.accent = buttonStyle({ sel: ".warn" });

/**
 * Preset for subtle buttons.
 */
button.subtle = buttonStyle({ sel: ".subtle" });
