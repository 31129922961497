/**
 * Requests related to media files and content.
 *
 * @module api/media
 * @category Backend API
 * @subcategory Media
 */
import cache from "cache";
import { del, get } from "./request";
import { endpoints } from "./constants";

const CACHE_FILE_KEY = "file_list";
const CACHE_FILE_TIME = 1; // minute

const minutesFromNow = (minutes) => Date.now() + (minutes * 60 * 1000);

/**
 * A list of all files in media storage.
 *
 * @function files
 * @async
 * @return {string[]}
 */
export const files = async () => {
  let cached = cache.getObject(CACHE_FILE_KEY);
  // init the cache if it doesn't exist
  if (cached === null) {
    cache.storeObject(CACHE_FILE_KEY, { expires: Date.now(), fileList: [] });
    cached = cache.getObject(CACHE_FILE_KEY);
  }
  if (cached && cached.expires > Date.now()) {
    return cached.fileList;
  }
  try {
    const result = (await get(endpoints.FILE)).body;
    cached.fileList = result;
    cached.expires = minutesFromNow(CACHE_FILE_TIME);
    cache.storeObject(CACHE_FILE_KEY, cached);
    return result;
  } catch (e) {
    // FIXME handle errors - for now just give an empty list
    return [];
  }
};

/**
 * alias, for standardization
 * @function list
 * @alias files
 */
export const list = files;

/**
 * Get a document filename by UUID.
 *
 * @function getDocumentById
 * @async
 * @param {string} uuid
 * @return {string[]} filename[s]
 */
export const getDocumentById = async (uuid) => (await get(`${endpoints.MEDIA_DOCUMENT}/${uuid}`)).body;

/**
 * Get a video filename by UUID.
 *
 * @function getVideoById
 * @param {string} uuid
 *
 * @return {string[]} filename[s]
 */
export const getVideoById = async (uuid) => (await get(`${endpoints.MEDIA_VIDEO}/${uuid}`)).body;
/**
 * Get a poster image filename by UUID.
 *
 * @function getPosterById
 * @param {string} uuid
 *
 * @return {string[]} filename[s]
 */
export const getPosterById = async (uuid) => (await get(`${endpoints.MEDIA_POSTER}/${uuid}`)).body;

/**
 * Deletes a file by its filename.
 *
 * @function deleteFileByName
 * @param {string} name of the file
 *
 * @return {boolean} indicating success
 */
export const deleteFileByName = async (name) => (await del(`${endpoints.FILE}/${name}`)).ok;
