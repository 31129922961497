/**
 * The main app module, loaded on every page load.
 *
 * Exposes a bunch of other stuff, mainly for debugging purposes,
 * and handles a few universal things dealing with auth state and
 * events.
 *
 * TODO most of this should be moved out of here now that we're done
 *      transitioning from the prototype.
 *
 * @module app
 * @Category UI
 */
import api from "api";
import cache from "cache";
import config from "config";
import log from "log";
import util from "util";
import ui from "ui";
import cacheStream from "data/cache";
import { featureTypes } from "model/config/constants";
import { REFRESH_MARGIN } from "model/authentication/constants";
import { emit } from "util/event";
import { featureEnabled } from "util/feature-flag";
import { playerActions, processPlayerAction } from "util/player";
import { loginAsAnonymousIfNotAuthenticated } from "util/authentication";
import { LOGIN_PATH, LOGIN_WHITELIST, MODAL_REOPEN_WHITELIST } from "./constants";

// expose the modules for use by console
window.smartedge = {
  api,
  cache,
  config,
  log,
  ui,
  util,
};

// register PWA
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/pwa-worker.js')
    .catch((e) => log.error(e, "error registering service worker"));
}

// listen for video player actions
window.addEventListener("message", (e) => {
  const { type } = e.data;
  const localOrigin = new URL(window.location)?.origin;
  const remoteOrigin = e.origin;
  if (localOrigin !== remoteOrigin) throw new Error("PlayerAction message received from cross-origin source.");
  if (type === playerActions.TYPE) {
    const { playerId, videoPlayerAction } = e.data;
    processPlayerAction(playerId, videoPlayerAction);
  }
});

window.addEventListener("beforeunload", () => {
  // used to prevent redirect bounce when going back from page after login redirect
  const lastPage = document.location.href.replace(document.location.origin, "");
  cache.storeValue("last-page", lastPage);
});

/**
 * Do app initialization.
 */
const onContentLoad = async () => {
  try {
    // before we can do anything we need to acquire server config
    // we need to know the URL for backend calls before other api
    // endpoints can run
    const conf = await config();
    log.debug(conf);
    // we can bury auth errors and let the system do its thing
    // most of the time we have no auth token it'll be because
    // the user isn't yet logged in
    if (!LOGIN_WHITELIST.has(window.location.pathname)) {
      await loginAsAnonymousIfNotAuthenticated(featureEnabled(featureTypes.OPEN_SITE_ACCESS));
      const tokenPair = await api.authentication.refresh(true);
      if (!tokenPair) {
        log.error("unable to refresh auth token", tokenPair);
        emit(window, "em:login");
        return;
      }
      // now set up an interval to continually reauthenticate
      const refreshTime = tokenPair.validityPeriod - REFRESH_MARGIN;
      setInterval(
        api.authentication.refresh,
        refreshTime * 1000,
      );
      // just get the menu into the cache, menu will use it synchronously later
      await api.site.getMenu();
      // subscribe to cache update broadcasts
      cacheStream.init();
    }
    if (MODAL_REOPEN_WHITELIST.indexOf(window.location.pathname) === -1) {
      cache.storeValue('storedModalID', null);
    }
  } catch (e) {
    log.error(e, "in content load handler");
  } finally {
    log.debug("SmartEdge initialized.");
    emit(window, "em:ready");
  }
};

document.addEventListener(
  "DOMContentLoaded",
  () => { onContentLoad().catch((e) => { log.error(e); }); },
);

/**
 * Top level event handler for the logout function,
 * which should be handled on every page.
 *
 * Generally fired by a user clicking on the logout link in the menu.
 */
window.addEventListener("em:logout", () => {
  const action = async () => {
    await api.authentication.logout();
    if (featureEnabled(featureTypes.OPEN_SITE_ACCESS)) {
      window.location.replace("/");
    } else {
      window.location.replace(LOGIN_PATH);
    }
  };
  action().catch(() => log.error("Failed to logout properly"));
});

// app install event
window.addEventListener("beforeinstallprompt", (e) => {
  log.info("PWA :: deferred prompt");
  e.preventDefault();
  window.smartEdgeDeferredAppPrompt = e;
});

/**
 * Fixes some kind of mobile layout bug ???
 */
const mobile100vh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--mobile100vh', `${vh}px`);
};
window.addEventListener('resize', mobile100vh);
mobile100vh();
