/**
 * Constants related to authentication models.
 *
 * @module model/authentication/constants
 * @category Model
 * @subcategory Authentication
 */

/* eslint-disable-next-line import/prefer-default-export */
export const TOKEN_KEY = "authenticationTokenPair";

export const ANONYMOUS_SESSION_ID_KEY = "anonymousSessionIdKey";

/**
 * Time in seconds of margin for refreshing access tokens (they will be requested
 * this many seconds earlier than they would expire);
 *
 */
export const REFRESH_MARGIN = 3;
