/**
 * Document metadata streams.
 *
 * Provides `get` interface for fetching document by `documentId`.
 *
 * Provides `getMany` interface for fetching many documents by `[documentId]`.
 *
 * @module data/metadata/document
 * @category Data Streams
 * @subcategory Metadata
 */
import xs from "xstream";
import { byIds, countDocuments } from "api/v2/metadata/document";
import { makeDocumentMetadata } from "model/metadata";
import { CACHE_DOCUMENT_KEY_V2 as CACHE_KEY } from "cache/constants";
import countPipe from "data/pipeline/count";
import many from "data/pipeline/many";
import single from "data/pipeline/single";
import hashmap from "util/hash-map";
import { streamLog, sumStream } from "data/stream/compose";

const itemMap = hashmap();
const countMap = hashmap();

const defaultFn = (id) => makeDocumentMetadata({ id });
const modelFn = (partial) => makeDocumentMetadata(partial);

const all$ = xs.createWithMemory();

const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const {
  post: count,
  sink$: countSink$,
  pending$: countPending$,
} = countPipe({
  apiFn: countDocuments,
  defaultFn: () => 0,
  modelFn: (r) => r,
  cacheKey: CACHE_KEY,
  accumulator: countMap,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
);

const pending$ = sumStream(
  singlePending$,
  manyPending$,
  countPending$,
);

all$.compose(streamLog("DOCUMENT_ALL$"));
all$.imitate(out$);

export default {
  count,
  get,
  getMany,
  all$,
  count$: countSink$.compose(streamLog("DOCUMENT_COUNT$")),
  pending$,
};
