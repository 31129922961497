import { defaultMenuEntry } from "model/site/menu";
import { frozen } from "util/object";

export const defaultValidationState = frozen({
  valid: true,
  fields: {},
});

export const defaultFormState = frozen({
  entry: { type: null },
});

export const defaultMenuEntryState = frozen({
  pages: [],
  breadcrumbs: [],
  validation: defaultValidationState,
  entry: defaultMenuEntry(),
  hooks: {},
});
