/**
 * Renderer for [course lectures]{@link ui/page/admin/course}.
 *
 * @module ui/page/lecture
 * @category Pages
 * @subcategory User Facing
 */
import fromEvent from "xstream/extra/fromEvent";
import { pageVisit } from "api/v2/analytics";
import courseStream from "data/course";
import lectureStream from "data/course/lecture";
import moduleStream from "data/course/module";
import meStream from "data/user/me";
import { metadataTypes } from "model/metadata/constants";
import { tee } from "data/stream/compose";
import { featureTypes } from "model/config/constants";
import {
  div,
  h3,
  main,
  section,
  span,
  a,
} from "ui/html";
import el from "ui/common/el";
import iconButton from "ui/component/icon-button";
import button from "ui/component/form-managed/button";
import cameo from "ui/component/cameo";
import cameoMetadata from "ui/component/cameo-metadata";
import cameoLecture from "ui/component/cameo-lecture";
import layout from "ui/page/layout";
import { getQueryParams, setTitle } from "util/navigation";
import view from "ui/view";
import { lectureUrl } from "util/course";
import { frozen, merge } from "util/object";
import hashmap from "util/hash-map";
import { guard } from "util/feature-flag";

let loading, header;

const defaultState = frozen({
  course: null,
  module: null,
  lecture: null,
  lectures: hashmap(),
  videos: hashmap(),
  documents: hashmap(),
  positions: hashmap(),
});

const registerPageVisit = () => {
  pageVisit(`${window.location.pathname}/${window.location.search}`);
};

let iframeKey = 0;
const iframe = (src, sel = "") => el(
  `iframe${sel}`,
  {
    props: { src },
    // key is important here to force replacement of the iframe. If we only update it
    // then the src property is updated, which counts as navigation and introduces a
    // new window.history state entry, which messes up pushState / onpopstate navigation.
    // replacing it doesn't register in navigation history.
    key: ++iframeKey,
  },
);

const showSlides = (self, lecture) => {
  if (!lecture.slidesId || !self.state.documents) return [];
  return [
    h3("Slides"),
    div(
      ".slides",
      cameoMetadata({ metadata: self.state.documents.get(lecture.slidesId) }),
    ),
  ];
};

const showAdditional = (self, lecture) => {
  if (!lecture.additionalMaterialIds?.length) return [];
  return [
    h3("Materials"),
    div(
      ".material",
      lecture.additionalMaterialIds.map((mid) => cameoMetadata({
        metadata: self.state.documents.get(mid),
      })),
    ),
  ];
};

const changeLecture = (lecture, self) => {
  const { course, module } = self.state;
  self.update({ lecture });
  header.update({ title: lecture.title });
  window.history.pushState({}, '', lectureUrl(course.id, module.id, lecture.id));
  registerPageVisit();
};

const videoPlayer = (video) => div(
  ".lecture-player",
  [
    iframe(
      `/video?type=${metadataTypes.VIDEO}&id=${video.id}&no-auto=true&enable-expand=true`,
      "#player",
    ),
  ],
);

const documentViewer = (doc) => div(
  ".lecture-document-viewer",
  [
    iframe(`${doc?.getFileUrl()}#view=FitH&toolbar=0`, "#viewer"),
    button({
      icon: "external-link",
      swap: true,
      label: "Open in PDF Viewer",
      sel: ".warn",
      onClick: () => window.open(`/pdf?id=${doc.id}`, "_blank"),
    }),
  ],
);

const fileDownload = (doc) => div(
  ".lecture-file-download",
  [
    cameo({
      label: doc.documentFile.name,
      controls: [
        button.icon({
          icon: "download",
          onClick: () => window.open(doc.getFileUrl()),
        }),
      ],
    }),
  ],
);

const getPlayer = (state) => {
  const { lecture } = state;
  if (lecture.videoId) {
    const video = state.videos?.get(lecture.videoId);
    if (video) return videoPlayer(video);
  }

  if (lecture.slidesId) {
    const doc = state.documents?.get(lecture.slidesId);
    if (doc?.documentFile?.name?.endsWith?.("pdf")) return documentViewer(doc);
    if (doc?.documentFile) return fileDownload(doc);
  }

  if (lecture.additionalMaterialIds.length) {
    const doc = state.documents?.get(lecture.additionalMaterialIds[0]);
    if (doc?.documentFile?.name?.endsWith?.("pdf")) return documentViewer(doc);
    if (doc?.documentFile) return fileDownload(doc);
  }

  return "";
};

const showLecture = (self) => {
  const {
    course,
    module,
    lecture,
    lectures,
    params,
  } = self.state;
  if (!lecture) return main("#lecture");

  return main("#lecture", [
    div(".breadcrumbs", [
      a(
        course?.title,
        `/${params.previousLocation}`,
        ".breadcrumb",
      ),
      span(" — "),
      span(lecture?.title),
    ]),
    div(
      "#lecture-content",
      [
        section(".lecture-main", [
          getPlayer(self.state),
        ]),
        section(".lecture-sidebar", [
          module?.lectureIds?.length > 1 ? h3("Lecture Playlist") : "",
          module?.lectureIds?.length > 1
            ? div(
              ".lecture-playlist",
              module.lectureIds.map((lid) => cameoLecture({
                course,
                module,
                lecture: lectures.get(lid),
                sel: lid === lecture.id ? ".current" : "",
                controls: [
                  lid === lecture.id
                    ? ""
                    : iconButton(
                      "play",
                      "Play",
                      () => changeLecture(lectures.get(lid), self),
                      ".accent",
                    ),
                ],
              })),
            ) : "",
          ...showAdditional(self, lecture),
          ...showSlides(self, lecture),
        ]),
      ],
    ),
  ]);
};

const scroll = (v) => ({ detail: { list, position } }) => {
  const positions = hashmap(v.state.positions);
  positions.set(list.id, position);
  v.update({ positions });
};

const onPopState = (self) => () => {
  const params = getQueryParams();
  registerPageVisit();
  self.bindStreams([
    ["course", courseStream.get(params.course)],
    ["module", moduleStream.get(params.course, params.module)],
    ["lecture", lectureStream.get(params.course, params.module, params.id)
      .compose(tee((l) => setTitle(l.title))),
    ],
  ], () => loading.hide(), ["lecture"]);
};

const setTitles = (title) => {
  header.update({ title });
  setTitle(title);
};

/**
 * Build a lecture page.
 *
 * @function lectureViewer
 */
export default async function lectureViewer(selector) {
  guard(featureTypes.LMS);
  ({
    header,
    loading,
  } = layout(selector, [main("#lecture")]));
  loading.show();
  registerPageVisit();

  const params = getQueryParams();

  const self = view.create(showLecture)("#lecture", merge(defaultState, { params }));

  self.bindStreams([
    ["user", meStream.get()],
    ["course", courseStream.get(params.course)],
    ["module", moduleStream.get(params.course, params.module)],
    ["lecture", lectureStream.get(params.course, params.module, params.id)
      .compose(tee((l) => setTitles(l.title))),
    ],
    ["videos", lectureStream.getMetadata().video$],
    ["documents", lectureStream.getMetadata().document$],
    ["lectures", moduleStream.getLectures()],
  ], () => loading.hide(), ["lecture"]);

  // lecture carousel scroll events
  fromEvent(document.body, "em:carousel-scroll").addListener({ next: scroll(self) });
  fromEvent(window, "popstate").addListener({ next: onPopState(self) });
}
