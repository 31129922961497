/**
 * Me (authenticated user) stream.
 *
 * Provides `get` interface for fetching the authenticated user's profile.
 *
 * @module data/user/me
 * @category Data Streams
 * @subcategory User
 */
import xs from "xstream";
import { getMe } from "api/v2/user/me";
import { USER_PROFILE } from "cache";
import { makeUser } from "model/user";
import mono from "data/pipeline/mono";
import { streamLog } from "data/stream/compose";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const defaultFn = (id) => makeUser({ id });
const modelFn = (partial) => makeUser(partial);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
} = mono({
  apiFn: getMe,
  defaultFn,
  modelFn,
  cacheKey: USER_PROFILE,
  accumulator: itemMap,
});

const out$ = xs.merge(
  getSink$,
);

all$.imitate(out$);
all$.compose(streamLog("USER_ALL$"));

export default {
  get,
  all$,
};
