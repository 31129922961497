/**
 * An assortment of default help text definitions and some helper functions
 * for retrieving them.
 *
 * These are used mainly in form validation, and individual help texts
 * correspond to the `name` property of a form field.
 *
 * You should define new help texts here when creating a new type of field,
 * and the validation system will consume and display them automatically.
 *
 * @module ui/common/help-texts
 * @category UI
 * @subcategory Common
 */
import { messageTypes } from "ui/component/message";
import { frozen, merge } from "../../util/object";

const { HINT, REQUIRED, INVALID } = messageTypes;

/**
 * @typedef {string} HelpDefinitionType (hint|error|required)
 *
 * - hint: shown whenever the field is in focus and not yet invalid
 * - invalid: a validation error (contextual)
 * - required: the field is required and has been left empty
 *
 * Note that other types might be applied by validation.
 */

/**
 * @typedef {object} HelpDefinition
 *
 * @property {string} text the text to display
 * @property {HelpDefinitionType} type applied as a class to the text
 */

/**
 * @typedef {object} HelpDefinitions a set of context-sensitive help texts to show in a form
 *
 * @property {HelpDefinition} [invalid] shown when no more specific text is available
 * @property {HelpDefinition} [required] shown when a required field has no value
 * @property {HelpDefinition} [hint] a hint shown while the field is in focus
 * @property {HelpDefinition} [tooShort] shown when the field is shorter than `minlength`
 * @property {HelpDefinition} [tooShort] shown when the field is longer than `maxlength`
 * @property {HelpDefinition} [targetMismatch] shown when the field does not match its
 *                                             target field (e.g. password confirm)
 * @property {HelpDefinition} [taken] shown when a unique field (such as username)
 *                                    is already reserved in the system
 */

const help = (text, type) => ({ text, type, sel: ".help" });
export const helpRequired = (text) => help(text, REQUIRED);
export const helpInvalid = (text) => help(text, INVALID);
export const helpHint = (text) => help(text, HINT);

const requiredText = frozen(helpRequired("This field is required."));

/**
 * Generic fallback help texts. These are delivered when no help text
 * corresponding to a field name are found.
 *
 * @constant genericHelp
 * @readonly
 */
export const genericHelp = frozen({
  required: helpRequired("This field is required."),
  invalid: helpInvalid("Please enter a valid value."),
  targetMismatch: helpInvalid("This field does not match its partner."),
});

/**
 * @constant {Map<string, HelpDefinitions>} helpTexts
 * @readonly
 */
const helpTexts = frozen(new Map(
  [
    ["cellPhone", {
      ...genericHelp,
      required: helpRequired("Please enter a phone number."),
      hint: helpHint("Please make sure to include a country code (+1, +91, etc)"),
      invalid: helpInvalid("Please enter a valid phone number in the format \"+13215551234\" with country code included (+1, +91, etc.)"),
      taken: helpInvalid("This phone number is already in use by another account."),
    }],
    ["email", {
      ...genericHelp,
      required: helpRequired("Please enter an email address."),
      invalid: helpInvalid("Please enter a valid email address."),
      taken: helpInvalid("This email address is already in use by another account."),
    }],
    ["newPassword", {
      ...genericHelp,
      required: helpRequired("Please enter your new password."),
      invalid: helpInvalid("Passwords must contain at least one lowercase letter, one uppercase letter and one number, and be between 8-255 characters."),
    }],
    ["newPasswordConfirm", {
      ...genericHelp,
      required: helpRequired("Please confirm your new password."),
      invalid: helpInvalid("Passwords must contain at least one lowercase letter, one uppercase letter and one number, and be between 8-255 characters."),
      targetMismatch: helpInvalid("This password does not match."),
    }],
    ["text", {
      ...genericHelp,
      invalid: helpInvalid("Text must be between 0-255 characters with no leading or trailing spaces."),
      requiredText,
    }],
    ["password", {
      ...genericHelp,
      required: helpRequired("Please enter a password."),
      invalid: helpRequired("This is not a valid password. Please check that you've entered it correctly."),
    }],
    ["username", {
      ...genericHelp,
      invalid: helpInvalid("Usernames may contain only letters, numbers, and underscores, and must be between 3-255 characters."),
      required: helpRequired("Please enter a username."),
      tooShort: helpInvalid("Usernames must be at least 3 characters."),
      taken: helpInvalid("This username is already in use by another account."),
    }],
    ["firstName", {
      ...genericHelp,
      invalid: helpInvalid("First/given names may contain only letters and spaces, and must be between 0-255 characters."),
      required: helpRequired("Please enter a first or given name."),
    }],
    ["lastName", {
      ...genericHelp,
      invalid: helpInvalid("Last/family names may contain only letters and spaces, and must be between 0-255 characters."),
      required: helpRequired("Please enter a last name or family name."),
    }],
    // bulk contnet upload
    ["posterFileName", {
      ...genericHelp,
      invalid: helpInvalid("Poster file was not found. Please check that the file has been uploaded, that it is a supported file type, and that the file name contains only letters, numbers and underscores."),
      required: helpRequired("Please attach a poster file."),
      badInput: helpInvalid("Poster file is an invalid file type."),
    }],
    ["contentFileName", {
      ...genericHelp,
      invalid: helpInvalid("Content file was not found. Please check that the file has been uploaded, that it is a supported file type, and that the file name contains only letters, numbers and underscores."),
      required: helpRequired("Please attach a content file."),
      badInput: helpInvalid("Content file is an invalid file type."),
    }],
    ["slug", {
      ...genericHelp,
      invalid: helpInvalid("Slugs must be 3-128 characters long and may contain only lower-case letters, numbers, and dashes and may not end or start with a dash."),
      required: helpRequired("Please supply a URL slug."),
    }],
    ["maxItems", {
      ...genericHelp,
      invalid: helpInvalid("This field must be a whole number."),
      required: helpRequired("Please enter the maximum number of results to display."),
    }],
    ["url", {
      ...genericHelp,
      invalid: helpInvalid(`Please enter a full, valid url, including the protocol (e.g. "https://").`),
      required: helpRequired("You must select a URL to continue."),
    }],
    ["checklist", {
      ...genericHelp,
      required: helpRequired("Please select at least one option."),
    }],
    ["file-picker", {
      ...genericHelp,
      required: helpRequired("Please select a file."),
    }],
  ],
));

const selectHelp = (helpSet = {}, validity = {}) => {
  const texts = merge(genericHelp, helpSet);
  const helps = [];
  if (!validity.valid) {
    if (validity.taken) {
      helps.push(texts.taken);
    } else if (validity.targetMismatch) {
      helps.push(texts.targetMismatch);
    } else if (validity.tooShort) {
      helps.push(helps.tooShort ? texts.tooShort : texts.invalid);
    } else if (validity.tooLong) {
      helps.push(helps.tooLong ? texts.tooLong : texts.invalid);
    } else if (validity.valueMissing) {
      helps.push(texts.required);
    }
    // if no more specific helps were available use the basic invalid help
    if (!helps.length) {
      helps.push(texts.invalid);
    }
  } else if (texts.hint) {
    helps.push(texts.hint);
  }

  return helps;
};

/**
 * This looks up context-sensitive help text based on field name and validity state.
 *
 * @param {string} field the type of field being validated {@link helpTexts}
 * @param {ValidityState} validity state of the field
 * @return {HelpDefinition}
 * @deprecated
 */
export const getHelpText = (field, validity) => {
  if (!validity) return [];
  const texts = helpTexts.get(field) || genericHelp;
  return selectHelp(texts, validity);
};
