/**
 * ACL test examples
 *
 * @module ui/page/admin/test/acl
 * @private
 */
/***/
import userContentAccess from "ui/component/user-content-access";
import { merge } from "util/object";
import { aclSubjectTypes } from "model/acl/constants";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";

const defaultState = {
  anonymousUser: null,
  grants: [],
  users: [],
  groups: [],
  subject: null,
  subjectType: aclSubjectTypes.MEDIA,
  onAdd: () => {},
  onRemove: () => {},
  onUpdate: () => {},
};

export default function testACL(inState = {}) {
  const state = merge(defaultState, inState);
  return userContentAccess({
    anonymousUser: state.anonymousUser,
    grants: state.grants,
    users: state.users,
    groups: state.groups,
    subject: state.subject,
    subjectType: state.subjectType,
    onAdd: state.onAdd,
    onRemove: state.onRemove,
    onUpdate: state.onUpdate,
  }, getModal(), getNotification());
}
