import { userIsManager } from "model/user";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import span from "ui/html/span";
import spinner from "ui/component/spinner";

const countVerified = (usermap) => {
  const users = [...usermap.values()];
  return (users.length
    ? users.map((u) => u.items).flat().filter((u) => u.enabled).length
    : spinner()
  );
};

const countManagers = (usermap) => {
  const users = [...usermap.values()];
  return (users.length
    ? users.map((u) => u.items).flat().filter((u) => userIsManager(u)).length
    : spinner()
  );
};

export default function usersList(state) {
  return div(
    "#users.block",
    [
      h3("Users"),
      span('Total user accounts', '.label'),
      span(state.userCount === null ? spinner() : state.userCount, '.value'),
      span('Verified accounts', '.label'),
      span(countVerified(state.users), '.value'),
      span('Manager accounts', '.label'),
      span(countManagers(state.users), '.value'),
      span('User Groups', '.label'),
      span(state.groupCount === null ? spinner() : state.groupCount, '.value'),
    ],
  );
}
