/**
 * User UI layout component.
 *
 * @module ui/page/layout/unauthenticated
 * @category Layout
 * @subcategory Admin
 */
import { div, header } from "ui/html";
import el, { patch } from "ui/common/el";
import view from "ui/view";
import { setTitle } from "util/navigation";
import { bindNotificationModal } from "./common";

/**
 * A layout for "user" style pages.
 *
 * @function userLayout
 * @param {Selector} selector selects the containing app element
 * @param {ChildEl} elements
 * @param {string} title page title
 */
export default function userLayout(selector, elements = [], title = "Admin") {
  const structure = div(`${selector}.page`, [
    header(),
    ...elements,
    div('#modal'),
    div("#loading"),
    div("#notifications"),
  ]);

  const container = el.fromDOM(document.querySelector(selector));

  patch(container, structure);

  const headerView = view.header('header', { user: {} });
  const loadingView = view.loading("#loading");
  const modalView = view.modal(() => "")("#modal", {});
  const notificationView = view.notification("#notifications", {});

  bindNotificationModal(headerView);

  setTitle(title);

  return {
    header: headerView,
    loading: loadingView,
    modal: modalView,
    notification: notificationView,
  };
}
