/**
 * Maps v.0 menus to v.1 menus.
 *
 * @module model/site/menu
 * @category Model
 * @private
 * @subcategory Site
 */
/***/
import { menuLinkTypesSet, menuLinkTypes } from "./constants";

/**
 * @function responseToEntry
 * @private
 * @return {MenuEntry}
 */
/* eslint-disable-next-line import/prefer-default-export */
export const v0ToV1 = (response) => ({
  slug: null,
  url: null,
  pageId: null,
  ...response,
  type: menuLinkTypes.ENTRY,
  linkType: response.type && menuLinkTypesSet.has(response.type)
    ? response.type
    : null,
  label: response.title,
  entries: response.links?.map(v0ToV1) || [],
  links: null,
});
