/**
 * A modal preview for content objects.
 *
 * @module ui/component/modal/content
 * @category UI
 * @subcategory Modal Dialogs
 */

import cache from 'cache';
import { CACHE_MODAL_KEY } from "cache/constants";
import {
  a,
  button,
  div,
  h3,
  img,
  p,
  progress,
  span,
} from "ui/html";
import icon from "ui/component/icon";
import { metadataTypes } from "model/metadata/constants";
import dialog from "ui/component/modal/layout/dialog";
import viewButton from "ui/component/button-content-view";
import resumeButton from "ui/component/button-video-resume";
import { getModal } from "ui/view/modal-view";
import { formatPercent, shortDescription, splitParagraphs } from "util/format";
import { merge, frozen } from "util/object";

const searchLabelUrl = (label) => `/search?label=${label.name.trim()}`;

const stashModal = (metadata) => cache.storeObject(CACHE_MODAL_KEY, metadata);

/**
 * @function selectListItemHandler
 * @private
 */
const selectListItemHandler = (state) => (id) => {
  const view = getModal();
  if (id) {
    // we have to push a placeholder on the queue and then patch it so view.queuelength
    // is correct when the button factory is called, else the back button won't be shown
    // until the next patch
    view.async(div(".placeholder"));
    /* eslint-disable-next-line no-use-before-define */
    view.patch(contentModal({
      ...state,
      metadata: state.metadata.items.find(((entry) => entry.id === id)),
    }));
  }
};

/**
 * @function buttons
 * @private
 */
const buttons = (metadata, playback, sameTab) => {
  const view = getModal();
  const onClick = () => stashModal(metadata);
  const back = (view?.queueLength > 1)
    ? button(
      icon.sharp("arrow-left"),
      () => view.back(),
      ".back",
    )
    : "";
  if (metadata.type === metadataTypes.VIDEO) {
    return div(".buttons", [
      back,
      resumeButton({ metadata, sel: ".secondary", playback, onClick }),
      viewButton({ metadata, sameTab, onClick }),
    ]);
  }
  return div(".buttons", [
    back,
    viewButton({ metadata, sameTab, onClick }),
  ]);
};

/**
 * @function labels
 * @private
 */
const labels = (metadata) => div(
  ".labels",
  (metadata?.categories?.length
    ? [
      ...metadata.categories.map((label) => a(
        [icon("tag"), span(label.name)],
        searchLabelUrl(label),
      )),
    ]
    : ""
  ),
);

/**
 * @function poster
 * @private
 */
const poster = (metadata) => {
  const { posterUrl } = metadata;

  if (!posterUrl) return "";

  return img(
    ".poster",
    posterUrl,
    `poster image for ${metadata.title}`,
  );
};

/**
 * @function progressBar
 * @private
 */
const progressBar = (metadata, playback) => {
  let position, percent;
  if (metadata.type === metadataTypes.VIDEO) {
    position = playback?.position || 0;
    percent = Math.floor((position / metadata.length) * 100);
    return progress(
      formatPercent(percent),
      percent,
      0,
      100,
    );
  }
  return "";
};

/**
 * @function description
 * @private
 */
const description = (state) => {
  const { metadata, expand } = state;
  const view = getModal();
  return div(
    `.description${expand ? ".expanded" : ""}`,
    [
      metadata.type === metadataTypes.LIST ? p(
        `${metadata.items.length} entries`,
        ".entry-count",
      ) : "",
      ...(
        (metadata.description && metadata.description.length >= 180)
          ? [
            div(".excerpt", [
              p(shortDescription(metadata.description || "", 180, false).trim()),
            ]),
            a.fn("More", () => {
              /* eslint-disable-next-line no-use-before-define */
              view.patch(contentModal({ ...state, expand: true }));
            }, ".more"),
            div(".full", [
              ...splitParagraphs(metadata.description).map((chunk) => p(chunk)),
            ]),
            a.fn("Less", () => {
              /* eslint-disable-next-line no-use-before-define */
              view.patch(contentModal({ ...state, expand: false }));
            }, ".less"),
          ]
          : splitParagraphs(metadata.description).map((chunk) => p(chunk))
      ),
    ],
  );
};

const buildMetadataListItem = (item, state) => div(".list-item", {
  on: {
    click: () => {
      selectListItemHandler(state)(item.id);
    },
  },
  attrs: {
    title: item.title,
  },
}, item.title);

const buildView = (state) => {
  const { metadata, sameTab, playback } = state;
  return [
    div(".pane.poster-pane", [
      poster(metadata),
      progressBar(metadata, playback),
    ]),
    div(".pane.info-pane", [
      div(".info-content", [
        h3(metadata.title, ".title", { props: { title: metadata.title } }),
        description(state),
        labels(metadata),
      ]),
      div('.info-footer', [
        buttons(metadata, playback, sameTab),
      ]),
    ]),
  ];
};

const buildListView = (state) => {
  const { metadata, sameTab } = state;
  return [
    div(".pane.poster-pane", [
      poster(metadata),
      progressBar(metadata),
    ]),
    div(".pane.info-pane", [
      div(".info-content", [
        h3(metadata.title, ".title", { props: { title: metadata.title } }),
        description(state),
        labels(metadata),
      ]),
      div('.info-footer', [
        buttons(metadata, sameTab),
      ]),
    ]),
    metadata?.items?.length
      ? div(".pane.list-pane", [
        h3("List Items", ".title"),
        div(".items", [
          ...metadata.items
            // remove placeholder items; these were items that had no full
            // metadata item response, usually due to lack of access rights
            .filter((item) => !item.PLACEHOLDER)
            .map(
              (item) => buildMetadataListItem(item, state),
            ),
        ]),
      ]) : "",
  ];
};

const isListView = (metadata) => metadata.type === metadataTypes.LIST
  || metadata.type === metadataTypes.DYNAMIC_LIST;

const defaultState = frozen({
  metadata: {},
  expand: false,
  sel: "",
  sameTab: true,
});

/**
 * A modal dialog for displaying metadata content. If the view parameter is set,
 * the dialog will use it to set up the close and back buttons.
 *
 * @example
 * const video = api.metadata.getVideo(metaId);
 * const modalView = api.view.modal("#my-modal-container");
 *
 * modalView.async(contentModal({ metadta: video, sel: ".cool" }, modalView));
 *
 * @function contentModal
 * @param {object} state
 * @param {module:model/metadata~ListMetadata} state.metadata media metadata object
 * @param {string} [state.sel=""] additional selectors for the modal container
 * @param {boolean} [state.expand=false] whether the description is expanded
 * @param {boolean} [sameTab=true] whether to open content in the same tab
 * @return {module:ui/common/el~El}
 */
export default function contentModal(inState) {
  const state = merge(defaultState, inState);
  const { metadata, sel } = state;
  return dialog({
    sel: `${sel}.preview-content.content${(metadata.type === metadataTypes.LIST || metadata.type === metadataTypes.DYNAMIC_LIST) ? "" : ".with-action"}`,
    config: {
      dataset: {
        metaId: metadata.id,
        metaType: metadata.type,
      },
    },
    body: isListView(metadata)
      ? buildListView(state)
      : buildView(state),
  }, getModal());
}
