/**
 * Course data stream.
 *
 * Provides `getMany` interface for fetching course by
 * `[courseId]`.
 *
 * @module data/course
 * @category Data Streams
 * @subcategory Course
 */
import xs from "xstream";
import { byIds, search as searchCourses } from "api/v2/course";
import {
  CACHE_COURSE_KEY_V2 as CACHE_KEY,
  CACHE_SEARCH_COURSE_KEY_V2 as CACHE_SEARCH_KEY,
} from "cache/constants";
import { makeCourse } from "model/course";
import { mapSpread, streamLog, sumStream } from "data/stream/compose";
import single from "data/pipeline/single";
import searchPipe from "data/pipeline/search";
import many from "data/pipeline/many";
import gradingStream from "data/course/grading-scheme";
import moduleStream from "data/course/module";
import attendanceStream from "data/course/attendance";
import lectureStream from "data/course/lecture";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const defaultFn = (id) => makeCourse({ id });
const modelFn = (partial) => makeCourse(partial);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: search,
  items$: searchItems$,
  pending$: searchPending$,
} = searchPipe({
  apiFn: searchCourses,
  defaultFn,
  modelFn,
  cacheItemsKey: CACHE_KEY,
  cacheSearchKey: CACHE_SEARCH_KEY,
  itemsAccumulator: itemMap,
});

const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
  searchItems$,
);

const pending$ = sumStream(
  singlePending$,
  manyPending$,
  searchPending$,
  moduleStream.pending$,
  lectureStream.pending$,
  attendanceStream.pending$,
);

all$.imitate(out$);
all$.compose(streamLog("COURSE_ALL$"));

/**
 * Pushes all module ids from any fetched courses into the module stream.
 *
 * Returns the module `all$`.
 *
 * Useful for ensuring availability of any modules attached to any courses fetched by
 * get() or getMany().
 *
 * @example
 * const courseA$ = courseStream.get(courseIdA);
 * const courseB$ = courseStream.get(courseIdB);
 * const module$ = courseStream.getModules();
 * // map of all modules in courseA and courseB
 *
 * @function getModules()
 * @returns {Stream} `Stream.<Map.<UUID, CourseModule>>`
 */
const getModules = () => {
  const seen = new Set();
  xs.merge(all$, xs.of(itemMap))
    .compose(mapSpread)
    .filter((course) => course?.id && course?.moduleIds?.length && !seen.has(course.id))
    .map((course) => {
      seen.add(course.id);
      return [course.id, course.moduleIds];
    })
    .map(moduleStream.getBatch)
    .compose(streamLog("COURSE_MODULE_OUT"));
  return moduleStream.all$;
};

/**
 * Returns the attendance `all$`.
 *
 * Useful for ensuring availability of any attendance records attached to any
 * requested attendance records.
 *
 * @example
 * const courseA$ = courseStream.get(courseIdA);
 * const courseB$ = courseStream.get(courseIdB);
 * const module$ = courseStream.getModules();
 * // -> map of all modules in courseA and courseB
 * const attendance$ = assessmentStream.getEvaluations();
 * // -> map of all assessments in courseA and courseB
 *
 * @function getAssessments()
 * @returns {Stream} `Stream.<Map.<UUID, Assessment>>`
 */
const getAttendance = (userId) => {
  const seen = new Set();
  xs.merge(all$, xs.of(itemMap))
    .compose(mapSpread)
    .filter((course) => course?.id && !seen.has(course?.id))
    .map((course) => {
      seen.add(course.id);
      return [course.id, userId];
    })
    .map(attendanceStream.get)
    .flatten()
    .compose(streamLog("COURSE_ATTENDANCE_OUT"));
  return attendanceStream.all$;
};

const getGradingSchemes = () => {
  all$
    .compose(mapSpread)
    .filter((course) => (course?.id && course?.gradingSchemeId))
    .map((course) => [course.gradingSchemeId])
    .map(gradingStream.getMany)
    .flatten()
    .compose(streamLog("COURSE_GRADING_OUT"));
  return gradingStream.all$;
};

export default {
  get,
  search,
  getMany,
  getModules,
  /**
   * @see moduleStream#getLectures
   *
   * Note that this stream will be silent unless getModules() is also called
   * @function getLectures
   */
  getLectures: moduleStream.getLectures,
  /**
   * @see moduleStream#getAssessments
   *
   * Note that this stream will be silent unless getModules() is also called
   * @function getEvaluations
   * @returns {Stream.<HashMap<UUID[], Assessment>>}
   */
  getAssessments: moduleStream.getAssessments,
  getAttendance,
  /**
   * @see lectureStream#getMetadata
   *
   * Note that this stream will be silent unless getModules() and getLectures()
   * are also called
   * @function getLectureMetadata
   */
  getLectureMetadata: lectureStream.getMetadata,
  /**
   * @see attendanceStream#getEvaluations
   * @function getEvaluations
   * @returns {Stream.<HashMap<UUID[], Evaluation>>}
   */
  getEvaluations: attendanceStream.getEvaluations,
  /**
   * @function getGradingSchemes
   * @returns {Stream.<HashMap<UUID, GradingScheme>>}
   */
  getGradingSchemes,
  /**
   * All courses retrieved by any course stream, as they are loaded.
   * @prop {Stream.<HashMap<UUID, Course>>} all$
   */
  all$,
  /**
   * Count of requests currently being processed.
   * @prop {Stream.<int>} pending$
   */
  pending$,
};
