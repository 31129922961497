import xs from "xstream";
import { CACHE_COURSE_ATTENDANCE_KEY_V2 as CACHE_KEY } from "cache/constants";
import placeholder from "model/common/placeholder";
import { byCourseIdUserId } from "api/v2/course/attendance";
import single from "data/pipeline/single";
import many from "data/pipeline/many";
import { makeAttendance } from "model/course";
import { mapSpread, streamLog, tee } from "data/stream/compose";
import evaluationStream from "data/course/evaluation";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const modelFn = (item) => makeAttendance(item || {});

const defaultFn = ([courseId, studentId]) => modelFn({ courseId, studentId });

const getCacheKeyFromItem = (item) => (item ? [item?.courseId, item?.studentId] : []);

const all$ = xs.create();

const byIds = (pairs) => Promise.all(
  pairs.map(([courseId, userId]) => byCourseIdUserId(courseId, userId)),
);

/**
 * Get attendance record for a given user and course.
 *
 * @function get
 * @param {UUID[]} pair id pair
 * @param {UUID} pair.0 courseId
 * @param {UUID} pair.1 userId
 * @return {Stream<Attendance>}
 */
const {
  post: get,
  sink$: getSink$,
  pending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  getCacheKeyFromItem,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

/**
 * Get multiple attendance records for a given user and course.
 *
 * @function getMany
 * @param {UUID[]} pair id pair
 * @param {UUID} pair.0 courseId
 * @param {UUID} pair.1 userId
 * @return {Stream<Attendance>}
 */
const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  getCacheKeyFromItem,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink: all$,
});

const out$ = xs.merge(getSink$, getManySink$);

all$.imitate(out$);
all$.compose(streamLog("ATTENDANCE_ALL$"));

/**
 * Pushes all evaluation ids from any fetched attendance records into the evaluations stream.
 *
 * Returns the evaluation `all$`.
 *
 * Useful for ensuring availability of any evaluations attached to any requested attendance records.
 *
 * @example
 * const courseA$ = courseStream.get(courseIdA);
 * const courseB$ = courseStream.get(courseIdB);
 * const module$ = courseStream.getModules();
 * // -> map of all modules in courseA and courseB
 * const evaluation$ = assessmentStream.getEvaluations();
 * // -> map of all assessments in courseA and courseB
 *
 * @function getAssessments()
 * @returns {Stream<HashMap<UUID, Assessment>>}
 */
const getEvaluations = () => {
  const seen = new Set();
  xs.merge(all$, xs.create().startWith(itemMap))
    .compose(streamLog("COURSE_EVALUATION_ALL"))
    .compose(mapSpread)
    .filter((item) => !!item)
    .compose(streamLog("COURSE_EVALUATION_ITEM_IN"))
    .filter((attn) => attn?.courseId && attn.evaluationIds?.length && !seen.has(attn.id))
    .compose(tee((attn) => seen.add(attn.id)))
    .map((attn) => attn.evaluationIds.map((ev) => [attn.courseId, ev]))
    .compose(streamLog("COURSE_EVALUATION_IN"))
    .map(evaluationStream.getMany)
    .compose(streamLog("COURSE_EVALUATION_BEFORE_FLAT"))
    .flatten()
    .compose(streamLog("COURSE_EVALUATION_OUT"));
  return evaluationStream.all$;
};

const getEvaluation = (courseId, assessmentId, userId) => {
  get([courseId, userId]);
  return getEvaluations()
    .compose(streamLog("COURSE_EVALUATION"))
    .map((evaluations) => [...evaluations.values()].find(
      (ev) => ev?.assessmentId === assessmentId,
    ) || placeholder());
};

export default {
  get,
  getMany,
  getEvaluations,
  getEvaluation,
  all$,
  pending$,
  manyPending$,
};
