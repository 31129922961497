/**
 * Composer for standard api `getById(id, skipCache) -> model` style calls.
 * @function byIds
 * @return Composer
 */
import log from "log";
import { composer } from "data/stream/compose";

export const byId = (apiFn) => composer(
  (listener) => async (id) => listener.next(await apiFn(id, true)),
);

/**
 * Composer for standard api `byIds(ids, skipCache) -> model[]` style calls.
 * @function byIds
 * @return Composer
 */
export const byIds = (apiFn) => composer(
  (listener) => async (ids) => {
    try {
      const item = await apiFn(ids, true);
      listener.next(item);
    } catch (e) {
      log.error("DATA STREAM : API byId ERR ::", e);
      listener.error(e);
    }
  },
);

/**
 * Bootstrapper to use a single-item API call on a batch of ids.
 *
 * Should be replaced once the API provides a batch interface.
 *
 * @function byIdsBootstrap
 * @return Composer
 */
export const byIdsBootstrap = (apiFn) => composer(
  (listener) => async (ids) => listener.next(
    Promise.all(ids.map((id) => apiFn(id, true))),
  ),
);

export const markInProgress = (acc, items) => {
  items.forEach((item) => acc.add(item));
};

export const excludeInProgress = (acc, items) => items.filter(
  (item) => !acc.has(item),
);

export const markComplete = (acc, items) => {
  items.forEach((item) => {
    if (acc.has(item.id)) acc.delete(item.id);
  });
};
