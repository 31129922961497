/**
 * A modal for creating lecture.
 *
 * @module ui/component/modal/course/lecture
 * @category UI
 * @subcategory Modal Dialogs
 */
import startOfDay from "date-fns/startOfDay";
import addWeeks from "date-fns/addWeeks";
import {
  div, h3,
} from "ui/html";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import dialog from "ui/component/modal/layout/dialog";
import contentSchedule from "ui/component/content-schedule";
import { metadataTypes } from "model/metadata/constants";
import iconButton from "ui/component/icon-button";
import { difference } from "util/array";
import cameoMetadata from "ui/component/cameo-metadata";
import metadataSelectModal from "ui/component/modal/metadata-select";
import cameoUser from "ui/component/cameo-user";
import userSelectModal from "ui/component/modal/user-select";
import { merge } from "util/object";
import title from "ui/component/form-managed/field-title";
import description from "ui/component/form-managed/field-description";
import { notificationMessageTypes } from "model/notification/constants";
import { SINGLE_LECTURE } from "ui/page/admin/course/state";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import bindModalForm, { deleteStoredForm } from "ui/component/modal/bound-form";
import multiToggle from "ui/component/form-managed/field-multi-toggle";

const staticMessages = {
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
};

const toggleOptions = [
  {
    label: "General Information",
    name: "generalInformation",
    value: "generalInformation",
  },
  {
    label: "Content",
    name: "content",
    value: "content",
  },
  {
    label: "Additional Materials",
    name: "additionalMaterials",
    value: "additionalMaterials",
  },
  {
    label: "Schedule",
    name: "schedule",
    value: "schedule",
  },
];

const defaultState = {
  course: null,
  validation: {
    fields: {},
  },
  metadataList: [],
  lecture: {
    id: null,
    title: "",
    description: "",
    videoMetadata: null,
    poster: null,
    slides: null,
    additionalMaterial: [],
  },
  schedule: {
    startDate: undefined,
    endDate: undefined,
  },
  showSchedule: false,
  selectedOption: toggleOptions[0],
};

const onSelectOption = (self, option) => self.update({
  ...self.state,
  selectedOption: option,
});

const visibleClass = (
  currentOptionValue,
  selectedOption,
) => (selectedOption.value === currentOptionValue ? ".visible" : "");

const selectVideoItem = async (self) => {
  const { state } = self;
  const selectedVideoItem = await getModal().async(metadataSelectModal.async({
    entries: state.metadataList?.filter((item) => item.type === metadataTypes.VIDEO),
  }), false);
  if (selectedVideoItem) {
    self.update({
      lecture: {
        ...state.lecture,
        videoMetadata: selectedVideoItem,
      },
      messages: [],
    });
  }
};

/* FIXME enable when image metadata exists
const selectPoster = async () => {
  const { state } = storedFormView;
  const selectedPoster = await getModal().async(metadataSelectModal.async({
    entries: state.metadataList?.filter((item) => item.type === metadataTypes.DOCUMENT),
  }), false);
  if (selectedPoster) {
    storedFormView.updateState({
      lecture: {
        ...state.lecture,
        poster: selectedPoster,
      },
      messages: [],
    });
    bindForm();
  }
};
*/

const selectSlides = async (self) => {
  const { state } = self;
  const selectedSlides = await getModal().async(metadataSelectModal.async({
    entries: state.metadataList?.filter((item) => item.type === metadataTypes.DOCUMENT),
  }), false);
  if (selectedSlides) {
    self.update({
      lecture: {
        ...state.lecture,
        slides: selectedSlides,
      },
      messages: [],
    });
  }
};

const selectInstructor = async (self) => {
  const { state } = self;
  const selectedUser = await getModal().async(userSelectModal({
    users: state?.users || [],
  }));
  if (selectedUser) {
    self.update({
      lecture: {
        ...state.lecture,
        instructor: selectedUser,
      },
      messages: [],
    });
  }
};

const removeMetadata = (self, metadataProperty) => {
  self.update({
    lecture: {
      ...self.state.lecture,
      [metadataProperty]: null,
    },
  });
};

const onMaterialRemove = (formView, metadata) => () => {
  formView.update({
    lecture: {
      ...formView.state.lecture,
      additionalMaterial: formView.state.lecture.additionalMaterial
        .filter((item) => metadata.id !== item.id),
    },
  });
};

const enableSchedule = (formView) => () => {
  formView.update({
    schedule: {
      startDate: startOfDay(new Date()),
      endDate: startOfDay(addWeeks(new Date(), 1)),
    },
  });
};

const disableSchedule = (formView) => () => {
  formView.update({
    schedule: {
      startDate: null,
      endDate: null,
    },
  });
};

const buildEditForm = (self) => {
  const { values, state } = self;
  return form(
    "#course-lecture",
    [
      multiToggle({
        label: null,
        value: state.selectedOption.value,
        options: state.showSchedule ? toggleOptions : toggleOptions.slice(0, 3),
        onSelect: (option) => onSelectOption(self, option),
      }),
      div(`.pane${visibleClass("generalInformation", state.selectedOption)}`, self.bind([
        [title, { required: true, value: values.title || state.lecture.title }],
        [
          description,
          { value: values.description || state.lecture.description },
        ],
      ])),
      div(`.pane${visibleClass("content", state.selectedOption)}`, [
        div(".metadata-content", [
          state.lecture.videoMetadata
            ? cameoMetadata({
              metadata: state.lecture.videoMetadata,
              controls: [
                button.icon({
                  icon: "trash-alt",
                  onClick: () => removeMetadata(self, "videoMetadata"),
                  sel: ".danger",
                }),
              ],
            })
            : button.secondary({
              icon: "video",
              label: "Select Video Item",
              onClick: () => selectVideoItem(self),
            }),
        ]),
        /* FIXME enable when image metadata is a thing
        div(".image-content", [
          state.lecture.poster
            ? cameoMetadata({
              metadata: state.lecture.poster,
              controls: [
                button(icon.regular("trash-alt"), () => removeMetadata("poster"), ".danger"),
              ],
              explicitMetadataType: metadataTypes.IMAGE,
            })
            : button([
              icon("image"),
              "Select Poster",
            ], () => selectPoster(), ".stand-in"),
        ]),
        */
        div(".slides-content", [
          state.lecture.slides
            ? cameoMetadata({
              metadata: state.lecture.slides,
              controls: [
                button.icon({
                  icon: "trash-alt",
                  onClick: () => removeMetadata(self, "slides"),
                  sel: ".danger",
                }),
              ],
            })
            : button.secondary({
              icon: "file-pdf",
              label: "Select Slides",
              onClick: () => selectSlides(self),
            }),
        ]),
        div(".instructor-content", [
          state.lecture.instructor
            ? cameoUser({
              user: state.lecture.instructor,
              controls: [
                button.icon({
                  icon: "trash-alt",
                  onClick: () => removeMetadata(self, "instructor"),
                  sel: ".danger",
                }),
              ],
            })
            : button.secondary({
              icon: "user",
              label: "Select Instructor",
              onClick: () => selectInstructor(self),
            }),
        ]),
      ]),
      div(`.pane${visibleClass("additionalMaterials", state.selectedOption)}`, [
        div(".container", state.lecture.additionalMaterial.map(
          (metadata) => cameoMetadata({
            metadata,
            controls: [
              iconButton(
                "trash",
                "Remove",
                onMaterialRemove(self, metadata),
                ".danger",
              ),
            ],
          }),
        )),
        button.secondary({
          icon: "plus-circle",
          label: "Add Item",
          onClick: async () => {
            const entry = await getModal().async(metadataSelectModal.async({
              entries: difference(
                state.metadataList.filter((item) => item.type === metadataTypes.DOCUMENT),
                state.lecture.additionalMaterial,
              ),
            }));
            if (entry) {
              self.update({
                lecture: {
                  ...state.lecture,
                  additionalMaterial: state.lecture.additionalMaterial.concat([entry]),
                },
              });
            }
          },
        }),
      ]),
      div(`.pane${visibleClass("schedule", state.selectedOption)}`, ...state.showSchedule
        ? [
          contentSchedule({
            ...state.schedule,
            onEnable: enableSchedule(self),
            onDisable: disableSchedule(self),
          }),
        ]
        : [],
      ),
    ],
  );
};

const makeHeader = (state) => {
  const modalTitle = state.lecture?.title;
  return modalTitle ? `Edit ${modalTitle}` : "New Lecture";
};

const onSave = (formNode) => {
  const self = formNode.view;
  self.setFullValidation(true);
  const validation = self.validate(true);
  const { state, values } = self;
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return;
  }
  self.update({
    lecture: {
      ...state.lecture,
      title: values?.title,
      description: values?.description,
    },
  });
  const preparedLecture = {
    id: state.lecture?.id,
    title: self.state.lecture.title,
    description: self.state.lecture.description,
    videoMetadata: state.lecture.videoMetadata,
    poster: state.lecture.poster,
    slides: state.lecture.slides,
    instructor: state.lecture.instructor,
    additionalMaterial: state.lecture.additionalMaterial,
    schedule: state.showSchedule && values.startDate && values.endDate
      ? {
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
      }
      : {
        startDate: null,
        endDate: null,
      },
  };
  getModal().resolve(preparedLecture);
};

const getBreadcrumbs = (state) => {
  try {
    const { course, module, lecture } = state;
    const breadcrumbs = [
      course.title || "New Course",
      module.title === SINGLE_LECTURE ? [] : module.title,
      lecture.title || "New Lecture",
    ].flat();
    return breadcrumbs.join(" > ");
  } catch {
    return "";
  }
};

/**
 * A modal for lecture creation
 *
 * @param {object} inState
 * @param {Lecture} inState.lecture
 * @param {Metadata[]} inState.metadataList
 * @param {?object} inState.schedule for single-module lectures
 * @param {?Date} inState.schedule.startDate
 * @param {?Date} inState.schedule.endDate
 * @param {boolean} [showSchedule=false]
 * @returns {*|El}
 */
export default function lectureModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  getBreadcrumbs(state);
  deleteStoredForm("course-lecture");
  const formNode = bindModalForm("course-lecture", buildEditForm, state, false);
  return dialog({
    sel: ".course-lecture",
    header: [
      h3(makeHeader(state)),
      // Commented out due to FEDEV23-499
      // span(getBreadcrumbs(state), ".breadcrumbs"),
    ],
    body: formNode,
    footerSpacer: true,
    footer: div(".buttons", { attrs: { role: "group" } }, [
      button.primary({
        label: "Save",
        onClick: () => onSave(formNode),
      }),
    ]),
  }, getModal());
}
