/**
 * The user-facing search FAQ page.
 *
 * @module ui/page/faq/search
 * @category Pages
 * @subcategory User Facing
 */
import { a, div, h1, h3, p } from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "search-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showSearchFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Search"]),
  collapsible({
    title: "How does the search feature work?",
    autoCloseConfiguration,
  }, p(`The search bar is located at the top right corner of the SmartEdge interface. It allows you to quickly
    navigate your organization’s Content Library. You can search for anything- numbers, characters, phrases, course
    titles, instructor info, etc. Type your chosen terms or topics into the search bar and hit Enter. Your search
    results will appear on the next page. Search does not spell check for you, so be sure you are correctly typing in
    your terms or you will not see the intended results.`)),
  collapsible({
    title: "What if there are no search results?",
    autoCloseConfiguration,
  }, p(`Please double check the search terms. If these are fine, then there are no search results available
    for that given search field.`)),
  collapsible({
    title: "What does See All do?",
    autoCloseConfiguration,
  }, p(`To access See All, go to the Side Bar Menu and press See All. This page lets you view all
    Content/Lists on a single page. Pretty nifty, right?`)),
  collapsible({
    title: "Can I filter between videos, files, and lists on the Search and See All pages?",
    autoCloseConfiguration,
  }, p(`Yes! Please use the toggles near the top of the page to filter Videos, Files, and Lists. You can also
    sort by video length, file size, alphabetical order, or date uploaded.`)),
  collapsible({
    title: "How does sort work?",
    autoCloseConfiguration,
  }, p(`The sort function allows you to sort through the results on the Search or See All pages. You can sort
    by date (Oldest, Newest), content length (Shortest, Longest), file size (Smallest, Largest),
    or alphabetical order (A-Z, Z-A).`)),
]);

export default async function searchFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Search"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showSearchFaq)("#end-user-faq", state);
  loading.hide();
}
