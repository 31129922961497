/**
 * A modal for doing advanced course searches on the manage course page.
 *
 * @module ui/component/modal/advanced-course-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { frozen, merge } from "util/object";
import group from "ui/component/form-managed/field-group";
import closeFab from "ui/component/modal/component/close-fab";
import {
  button, div, form, h3, input, label,
} from "ui/html";

const getCurrentParams = (modalView) => {
  const dqs = modalView.element.querySelector.bind(modalView.element);
  return {
    id: dqs("[name=id]").value || null,
    title: dqs("[name=title]").value || null,
    entriesAmount: dqs("[name=entriesAmount]").value || null,
    sortOrder: dqs("[name=sort-order]").value,
    sortField: dqs("[name=sort-field]").value,
  };
};

const doSearch = (modalView) => {
  modalView.resolve(getCurrentParams(modalView));
};

const defaultParams = frozen({
  id: "",
  title: "",
  entriesAmount: "",
  sortField: "",
  sortOrder: "",
});

const defaultState = frozen({
  params: defaultParams,
});

const setFocus = () => {
  document.querySelector(`#advanced-form input[name="id"]`).focus();
};

/**
 * An async modal dialog for doing advanced searches for courses.
 *
 * @param {object} inState
 * @param {module:ui/view/modal~modalView} modalView modal dialog view
 * @return {?object} updated search parameters for use with advancedCourseFilter
 */
const advancedCourseSearchModal = (inState, modalView = null) => {
  const state = merge(defaultState, inState);
  const { params } = state;
  return div(
    ".dialog.form.advanced-search.advanced-course-search",
    {
      hook: {
        insert: setFocus,
        update: setFocus,
      },
    },
    [
      closeFab(true, modalView),
      div(".content", [
        div(".header", h3("Advanced Search")),
        div(".body", form("#advanced-form", [
          input("hidden", params.sortOrder, false, "sort-order"),
          input("hidden", params.sortField, false, "sort-field"),
          group([
            label("Course ID"),
            input.text(params.id, false, "id", { props: { placeholder: "Course ID" } }),
          ]),
          group([
            label("Title"),
            input.text(params.title, false, "title", { props: { placeholder: "Title" } }),
          ]),
          group([
            label("Module Count"),
            input.text(params.entriesAmount, false, "entriesAmount", { props: { placeholder: "Module Count" } }),
          ]),
          group(button("Search", () => doSearch(modalView)), ".buttons"),
        ])),
      ]),
    ],
  );
};

export default advancedCourseSearchModal;
