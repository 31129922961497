/**
 * A field-group. Like a fieldset.
 *
 * @module ui/component/form-managed/field-group
 * @category UI
 * @subcategory Forms
 */
import div from "ui/html/div";
import { merge } from "util/object";

/**
 * A group of related elements similar to a fieldset, but without fieldset's quirky
 * default behaviors.
 *
 * @function fieldGroup
 * @param {El[]} [children=[]]
 * @param {El[]} [config={}] element configuration
 * @param {boolean} roleGroupDisabled disable group role
 * @return {module:ui/html/div~Div}
 */
export default function fieldGroup(
  children = [],
  sel = "",
  config = {},
  roleGroupDisabled = false,
) {
  const mergedConfig = merge({
    attrs: { role: roleGroupDisabled ? "" : "group" },
  }, config);

  return div(
    sel,
    mergedConfig,
    children,
  );
}
