/**
 * Builds a dynamic card carousel section for dynamic pages.
 *
 * @module ui/page/dynamic/section-dynamic-carousel
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import cardCarousel from "ui/component/card-carousel";
import { upgradeMetadataItem } from "./common";

const makeSectionId = (id) => `page-section-dynamic-carousel-${id}`;

/**
 * A carousel display for a dynamic page section.
 *
 * @function sectionDynamicCarousel
 * @param {module:model/dynamic-page~PageSection} pageSection of type `DYNAMIC_LIST_CAROUSEL`
 * @param {object} listEntry search results for a dynamic list section
 * @return {module:ui/common/el~El} section element
 */
export default function sectionDynamicCarousel(state, sectionId) {
  const section = state.sections.get(sectionId);
  if (!section) return "";
  const results = state.searchResults.get([section.searchData, 0]);

  if (!results) return cardCarousel.placeholder(".playlist-carousel", makeSectionId(sectionId));

  const items = results.items.map(upgradeMetadataItem(state));
  const position = state.positions.get(makeSectionId(sectionId)) || 0;

  return cardCarousel({
    list: {
      id: makeSectionId(sectionId),
      type: section.type,
      items,
      title: section.title,
      text: section.text,
      cardSize: section.searchData?.cardSize,
      playback: state.playback,
    },
    position,
  });
}
