/**
 * Generic managed form field component wrapper.
 *
 * Meant to be extended by other components, not used independently.
 *
 * @module ui/component/form-managed/field-generic
 * @category UI
 * @subcategory Forms
 */

import { defaultValidity } from "ui/common/validation-constraints";
import fieldGroup from "ui/component/form-managed/field-group";
import hint from "ui/component/form-managed/hint";
import icon from "ui/component/icon";
import label from "ui/html/label";
import input from "ui/html/input";
import button from "ui/html/button";
import { frozen, merge } from "util/object";

const makeDefaultChildElement = (name, required) => (
  name
    ? input.text("", name, required)
    : button([icon.sharp("xmark"), "missing form element"], () => {}, ".danger", true)
);

const defaultState = frozen({
  displayLabel: true,
  helpTexts: {},
  label: null,
  name: null,
  required: false,
  validity: defaultValidity,
  value: undefined,
  sel: "",
  type: "generic",
});

/**
 * A generic form field. Generally meant to be used by specific fields, not as an
 * independent component.
 *
 * Handles creating help texts and label, if parameters are provided.
 *
 * Hooks the `formdata` event if a callback is provided, to help populate form data with
 * custom properties.
 *
 * The state params `label` and `name` must be provided together, and will generate a
 * label element if both are present. If `required` is true the label will also be given
 * the `required` class for styling purposes.
 *
 * Help text message element will be generated if `validity` and `name` properties are also
 * provided in state. If helpGroup is provided the appropriate help text(s) will be
 * selected based on validity; if not, the genericHelp texts will be used.
 *
 * If `name` is provided and `children` is empty or undefined a placeholder text input
 * is generated.
 *
 * @function genericField
 * @param {object} state
 * @param {boolean} [state.displayLabel=true] if false, will not build label element
 * @param {object} [state.helpTexts] see {@link module:ui/component/form-managed/hint}
 * @param {?string} state.label
 * @param {?string} state.name
 * @param {boolean} [required=false] whether the contained form field is required
 * @param {?FormValidity} [state.validity=defaultValidity]
 * @param {?mixed} state.value
 * @param {ChildEl} [children=[]}
 * @param {Selector} [sel=""] selector for the wrapping span, *not* the inner elements
 * @return {El[]}
 */
export default function genericField(
  inState = {},
  children = [],
  sel = "",
  config = {},
) {
  const state = merge(defaultState, inState);
  let childElements;
  if (
    (children instanceof Array && children.length)
  ) {
    childElements = children;
  } else if (typeof children === "object") {
    childElements = [children];
  } else {
    childElements = [makeDefaultChildElement(inState)];
  }

  const { name, helpTexts, validity } = state;
  const roleGroupDisabled = state.type === "hidden";

  return fieldGroup(
    [
      (state.displayLabel && state.label && state.name) ? label(
        `${state.label}`,
        state.name,
        null,
        { class: { required: state.required } },
      ) : "",
      ...childElements,
      ...hint({ name, helpTexts, validity }),
    ],
    `${sel}.form-managed.field${state.required ? ".required" : ""}.${state.type}`,
    config,
    roleGroupDisabled,
  );
}
