/**
 * A modal for question type selection.
 *
 * @module ui/component/modal/assessment/question-type-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import listDialog from "ui/component/modal/layout/list";
import multipleChoiceModal from "ui/component/modal/assessment/question-multiple-choice";
import textEntryModal from "ui/component/modal/assessment/question-text-entry";
import fileDropboxModal from "ui/component/modal/assessment/question-file-dropbox";
import h3 from "ui/html/h3";
import { getModal } from "ui/view/modal-view";

const buildEntry = (label, iconName, click) => cameo({
  label,
  icon: icon(iconName),
  config: { on: { click } },
});

/**
 * A modal for question type selection
 *
 * @param {ModalView} modal
 * @param {Object?} additionalState
 * @param {NotificationView?} notificationView
 * @returns {*|El}
 */
export default function questionTypeSelectModal(additionalState = {}) {
  const modal = getModal();
  return listDialog({
    sel: ".question-type-select",
    header: h3("Question Type"),
    entries: [
      buildEntry("Multiple Choice", "list-check", async () => {
        const question = await modal.async(multipleChoiceModal({ ...additionalState }));
        modal.resolve(question);
      }),
      buildEntry("Text Entry", "text", async () => {
        const question = await modal.async(textEntryModal({ ...additionalState }));
        modal.resolve(question);
      }),
      buildEntry("File Dropbox", "file-download", async () => {
        const question = await modal.async(fileDropboxModal({ ...additionalState }));
        modal.resolve(question);
      }),
    ],
  }, modal);
}
