import placeholder from "ui/component/placeholder";
import h3 from "ui/html/h3";
import div from "ui/html/div";
import a from "ui/html/a";
import icon from "ui/component/icon";

/**
 * A placeholder content card.
 */
export default function placeholderCard(sel = "") {
  return div(`${sel}.content-card`, [
    div(".poster", placeholder(icon("image"))),
    h3(placeholder(), ".title"),
    div(".description", placeholder()),
    a.fn("   ", () => {}, ".button.disabled"),
  ]);
}
