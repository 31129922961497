/**
 * A list of reports.
 *
 * @module ui/component/modal/messaging/report-list
 * @component UI
 * @subcomponent Modal Dialogs
 */
import { merge } from "util/object";
import listModal from "ui/component/modal/layout/list";
import { h3 } from "ui/html";
import cameoReport from "ui/component/cameo-report";
import { getModal } from "ui/view/modal-view";

const defaultState = {
  reports: [],
  users: [],
};

const buildEntries = ({ reports, users }) => reports
  .map((report) => cameoReport({ report, users }));

export default function reportListModal(inState = {}) {
  const state = merge(defaultState, inState);

  return listModal({
    sel: ".report-list-dialog",
    header: h3("Reports"),
    entries: buildEntries(state),
  }, getModal());
}
