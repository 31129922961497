/**
 * Utilities related to Progressive Web App functionality.
 *
 * @module util/pwa
 * @category Utilities
 */

/**
 * Detects whether the user is using an iOS device.
 *
 * @function isIos
 * @returns boolean
 */
export const isIos = () => /iPhone|iPad|iPod/.exec(window.navigator.userAgent);

/**
 * Detects whether the user is using an iOS device.
 *
 * @function isIos
 * @returns boolean
 */
export const isAndroid = () => /android/.exec(window.navigator.userAgent);

/**
 * Detects whether the screen has mobile width.
 *
 * @function isMobileWidth
 * @returns boolean
 */
export const isMobileWidth = () => window.screen.width <= 768;

/**
 * Detects whether the user is using an iOS PWA.
 *
 * @function isIosApp
 * @returns boolean
 */
export const isIosApp = () => (isIos() && !(/Safari/.exec(window.navigator.userAgent)));

/**
 * Detects whether the user is using our PWA.
 *
 * @function isPWA
 * @returns boolean
 */
export const isPWA = () => {
  const media = window.matchMedia('(display-mode: standalone)').matches;
  const androidApp = document.referrer.includes('android-app://');
  const iosApp = isIosApp();

  return media || window.navigator.standalone || window.fullscreen || androidApp || iosApp;
};
