/**
 * @module ui/page/admin/users/manage/manage-users-table
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import { groupSortCriteria, systemManagementGroupsSet } from "model/acl/constants";
import {
  a,
  div,
  section,
} from 'ui/html';
import icon from "ui/component/icon";
import groupIcon from "ui/component/group-icon";
import table from "ui/component/dynamic-table";
import { groupSort } from "util/sort";
import { fullName, onSortChange } from "./common";

/**
 * Makes the collection of privilege icons for the authorities column.
 * @function privilegeIcons
 * @private
 */
const privilegeIcons = (user) => div('', user.groups
  .filter((group) => systemManagementGroupsSet.has(group.name))
  .sort(groupSort(groupSortCriteria.NAME_ASC))
  .map((group) => groupIcon(group.name)));

/**
 * Gets appropriate icon for the external column.
 * @function external
 * @private
 */
const external = (user) => (user.external
  ? icon.solid("check-circle", ".ok")
  : icon.solid("times-circle", ".danger"));

/**
 * Gets appropriate icon for the verified column.
 * @function external
 * @private
 */
const verified = (user) => (user.enabled
  ? icon.solid("check-circle", ".ok")
  : icon.solid("times-circle", ".danger"));

/**
 * Sets up a user's row data for the table.
 * @function tableRow
 * @private
 */
const tableRow = (user) => {
  const row = ({
    username: a(user.username, `/admin/user-profile?id=${user.id}`),
    fullName: fullName(user),
    cellPhone: user.cellPhone === null ? '—' : user.cellPhone,
    email: user.email === null ? '—' : user.email,
    verified: verified(user),
    external: external(user),
    authorities: privilegeIcons(user),
  });
  return row;
};

/**
 * Sets up the results table.
 *
 * @function showManageUsers
 * @private
 */
export default function showManageUsers(theView) {
  return section("#manage-users", table({
    columnLabels: [
      "User Name",
      "Full Name",
      "Mobile Number",
      "Email Address",
      "Verified",
      "External",
      "Permissions",
    ],
    sortColumn: theView.state.sortColumn,
    sortOrder: theView.state.sortOrder,
    rows: theView.state.filteredUsers.map(tableRow),
    onSortChange: onSortChange(theView),
    placeholder: "No users found.",
  }));
}
