/**
 * A help modal dialog for edit menu page.
 *
 * @module ui/component/modal/help/edit-menu
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, img, li, ol, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminEditMenuHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_EDIT_MENU,
    skipCache,
    async,
    sel: ".help-modal-edit-menu",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-edit-menu.svg"),
            p("Edit Menu", ".title"),
            div(".text-container", [
              p("To edit the sidebar menu, press Add Entry to add new links.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-edit-menu.svg"),
            p("Edit Menu", ".title"),
            div(".text-container", [
              p("Select from 3 link options", ".text-align-left"),
              ol([
                li(`External Link - for external sites outside of SmartEdge`),
                li(`Page Link - for pages created within SmartEdge`),
                li(`Sub-Menu - for adding a collection of external links`),
              ]),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-edit-menu.svg"),
            p("Edit Menu", ".title"),
            div(".text-container", [
              p("Once you are done, click Save, and your changes will be saved!", ".spaced-bottom"),
              p([
                "If you have any more questions, please visit our ",
                a("Admin FAQ", "/admin/admin-faq"),
                " for more help",
              ]),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
