/**
 * Shared UI elements and functionality.
 *
 * @module ui/common
 * @category UI
 * @subcategory Common
 */
import log from "log";
import page from "ui/page";
import { initSocket, pageDuration, pageDurationDeferred } from "api/v2/analytics";
import { managersOnly } from "util/authentication";
import { doLoginRedirect } from "util/navigation";
import { applyBranding, applyColorPalette } from "util/ui-customization";
import { measureVisitTime } from "util/analytics";

export { default as el } from "./el";

let onReadyListener;
const onReady = async (pageModule, selector, resolve, reject) => {
  try {
    await Promise.all([
      initSocket().catch((e) => log.warn(e, "could not pre-initialize analytics")),
      applyColorPalette().catch(() => log.warn("stored color palette not found")),
      applyBranding().catch(() => "stored branding settings not found"),
    /* eslint-disable-next-line no-console */
    ]).catch((e) => console.error(e));
    measureVisitTime(
      async (timing) => { // start callback
        await pageDurationDeferred({
          pageKey: window.location.pathname,
          hash: window.location.hash,
          queryString: window.location.search,
          ...timing,
        });
      },
      async (timing) => { // end callback
        await pageDuration({
          pageKey: window.location.pathname,
          hash: window.location.hash,
          queryString: window.location.search,
          ...timing,
        });
      },
    );
    await page[pageModule](selector);
    resolve(true);
  } catch (e) {
    reject(e);
  }
};

const onLogin = async () => {
  window.removeEventListener("em:ready", onReadyListener);
  doLoginRedirect();
};

/* eslint-disable-next-line import/prefer-default-export */
export const initPage = (pageModule, selector) => new Promise((resolve, reject) => {
  onReadyListener = () => {
    onReady(pageModule, selector, resolve, reject)
      .catch((e) => log.error(e, "in em:ready handler"));
  };
  if (window.location.pathname.startsWith("/admin")) {
    try {
      managersOnly();
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error(e);
    }
  }
  window.addEventListener(
    "em:login",
    () => { onLogin().catch((e) => log.error(e, "in em:login handler")); },
    { once: true },
  );
  window.addEventListener(
    "em:ready",
    onReadyListener,
    { once: true },
  );
});
