/**
 * Defaults and constants related to courses management states.
 *
 * @module ui/page/admin/course/state
 * @category Pages
 * @subcategory Admin - Course
 */
import { frozen } from "util/object";
import { sortOrders } from "ui/component/dynamic-table";
import { notificationMessageTypes } from "model/notification/constants";
import { courseEntryType } from "model/course/constants";
import hashmap from "util/hash-map";

export const { SINGLE_LECTURE } = courseEntryType;

export const staticMessages = frozen({
  success: {
    title: "Success",
    text: "Saved!",
    type: notificationMessageTypes.SUCCESS,
  },
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
  gradingScheme: {
    title: "Error",
    text: "Please select a grading scheme before saving.",
    type: notificationMessageTypes.FAIL,
  },
  backendErrors: {
    title: "API Error",
    text: "A server error ocurred. Please report this to the operators.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
  metadataPreloadError: {
    title: "API Error",
    text: "Unable to preload lecture metadata. Please contact a site administrator for assistance.",
    type: notificationMessageTypes.FAIL,
    duration: 5,
  },

});

export const defaultSearchParams = frozen({
  id: null,
  title: null,
  entriesAmount: null,
});

/**
 * @enum searchModes
 */
export const searchModes = frozen({
  NONE: null,
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
});

export const defaultManageState = frozen({
  searchParams: { ...defaultSearchParams },
  courses: hashmap(),
  filteredCourses: hashmap(),
  searchMode: searchModes.NONE,
  sortColumn: "createdAt",
  sortOrder: sortOrders.DESC,
});

export const defaultProfileState = frozen({
  user: null,
  users: [],
  courseId: null,
  course: [],
  evaluationStatuses: [],
  modulesReordered: false,
  validation: {
    fields: {},
  },
  grants: [],
  groups: [],
  userRights: new Set(),
  editEnabled: false,
  deleteEnabled: false,
  manageRightsEnabled: false,
});
