/**
 * A general purpose form component. Meant to be used with form-managed view.
 *
 * @module ui/component/form-managed
 * @category UI
 * @subcategory Forms
 */
import form from "ui/html/form";
import { merge } from "util/object";

/**
 * @function managedForm
 * @param {Selector} [sel=""]
 * @param {ChildEl} [children=[]]
 * @param {Config} [config={}] form element configuration
 */
export default function managedForm(
  sel = "",
  children = [],
  config = {},
) {
  const mergedConfig = merge({
    props: { method: "POST", action: "/" },
    attrs: { novalidate: true },
  }, config);

  return form(
    `${sel}.managed`,
    mergedConfig,
    children,
  );
}
