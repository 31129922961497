/**
 * A list item.
 *
 * @module ui/html/li
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

const li = (childNodes, sel = "", data = {}) => el(`li${sel}`, data, childNodes);

export default li;
