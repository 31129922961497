/**
 * Chat message window.
 */
import { messageStatus, threadStatuses } from "@smartedge/em-message/constants";
import div from "ui/html/div";
import { frozen, merge } from "util/object";
import { filterMap } from "util/array";
import { qs } from "util/dom";
import button from "ui/component/form-managed/button";
import chatMessage from "./message";
import { isScrolledToTop } from "./common";

const defaultState = frozen({
  me: null,
  users: [],
  channelMessages: [],
  blockedUserIds: new Set(),
  selectedChannel: null,
  onReportMessage: () => {},
  onBlockUser: () => {},
  onScrollReachedTop: () => {},
  reportedMessageIds: new Set(),
});

const CHAT_WINDOW_SELECTOR = ".chat-window";

const getStateMessage = (state) => {
  if (state.messagesLoading) return "Loading…";
  const channel = state.selectedChannel;
  if (channel && channel.status === threadStatuses.CLOSED) return "Channel is closed.";
  if (channel && channel.status === threadStatuses.REMOVED) return "Channel is no longer available.";
  return "No Messages Found.";
};

const showScrollToBottomIcon = (state) => {
  const currentMessages = (state.channelMessages.get(state.selectedChannel?.id) || [])
    .filter((m) => !state.blockedUserIds.has(m.from));
  const lastMessage = currentMessages.at(0);
  const chatWindowContainer = qs(CHAT_WINDOW_SELECTOR);
  return chatWindowContainer?.scrollTop !== 0 && lastMessage?.status === messageStatus.UNREAD;
};

const scrollBottomButton = (state) => button.icon({
  sel: ".scroll-bottom-button",
  icon: "chevron-down",
  onClick: () => {
    const chatWindowContainer = qs(CHAT_WINDOW_SELECTOR);
    chatWindowContainer.scrollTop = 0;
    state.onSelectChannel(state.selectedChannel);
  },
});

export default function chatWindow(inState, modalView) {
  const state = merge(defaultState, inState);
  const channelMessages = state.channelMessages.get(state.selectedChannel?.id) || [];
  return div(
    CHAT_WINDOW_SELECTOR,
    {
      on: {
        scroll: (e) => {
          if (isScrolledToTop(e.target)) {
            state.onScrollReachedTop();
          }
        },
      },
    },
    [
      ...channelMessages.length
        ? channelMessages.reduce(filterMap(
          (m) => !state.blockedUserIds.has(m.from),
          (message) => chatMessage({
            ...state,
            message,
            onReportMessage: state.onReportMessage,
            onBlockUser: state.onBlockUser,
            showReportButton: !state.reportedMessageIds.has(message.id),
          }, modalView),
        ), [])
        : [div(".chat-no-channel-selected", div(".label", getStateMessage(state)))],
      showScrollToBottomIcon(state) ? scrollBottomButton(state) : "",
    ],
  );
}
