/**
 * @typedef GradeBracket
 * @property {string} id
 * @property {number} pointsFrom
 * @property {number} pointsTo
 * @property {string} description
 */
import {
  validInt,
  validBoolean,
  validString,
  required,
} from "model/constraints";

/**
 * @typedef GradeBracket
 * @property {string} id
 * @property {number} pointsFrom
 * @property {number} pointsTo
 * @property {string} description
 */

/**
 * @typedef GradeBracketDTO
 * @property {string} id
 * @property {number} pointsFrom
 * @property {number} pointsTo
 * @property {string} description
 */

/**
 * @typedef GradingScheme
 * @property {string} id
 * @property {string} description
 * @property {boolean} automatic
 * @property {GradeBracket[]} gradingBrackets
 */

/**
 * @typedef GradingSchemeDTO
 * @property {string} id
 * @property {string} description
 * @property {boolean} manualOrAutomatic
 * @property {GradeBracketDTO[]} gradingBrackets
 */

/**
 * Converts data to grade brackets object
 *
 * @param data
 * @param {string} data.id
 * @param {number} data.pointsFrom
 * @param {number} data.pointsTo
 * @param {string} data.description
 * @returns GradeBracket
 */
export const responseToGradeBracket = (data) => ({
  id: data.id,
  pointsFrom: data.pointsFrom,
  pointsTo: data.pointsTo,
  description: data.description,
});

/**
 * Converts data to grading object.
 *
 * @param data
 * @param {string} data.id
 * @param {boolean} data.manualOrAutomatic
 * @param {string} data.description
 * @param {Object[]} data.gradeBrackets
 * @returns Grading
 */
export const responseToGradingScheme = (data) => ({
  id: data.id,
  automatic: data.manualOrAutomatic,
  description: data.description,
  gradeBrackets: data.gradeBrackets?.map(responseToGradeBracket) || [],
});

/**
 * Make a default grading scheme.
 *
 * @function makeGradingScheme
 * @returns Grading
 */
export const makeGradingScheme = (partial) => ({
  id: partial.id || null,
  automatic: partial.automatic !== undefined ? partial.automatic : null,
  description: partial.description || "",
  gradeBrackets: partial.gradeBrackets?.length ? partial.gradeBrackets : [],
});

/**
 * Validates and sanitizes grading scheme braket.
 * @function makeGradeBracketDTO
 * @param {GradeBracket | {}} partial
 * @returns {GradeBracketDTO}
 */
export const makeGradeBracketDTO = (partial) => ({
  id: partial.id,
  pointsFrom: validInt(partial.pointsFrom, "GradeBracket.pointsFrom"),
  pointsTo: validInt(partial.pointsTo, "GradeBracket.pointsTo"),
  description: validString(partial.description, "GradeBracket.description"),
});

/**
 * Validates and sanitizes grading scheme.
 * @param {GradingScheme | {}} partial
 * @returns {GradingSchemeDTO}
 */
export const makeGradingSchemeDTO = (partial) => ({
  id: partial.id,
  manualOrAutomatic: required(
    partial.automatic,
    "GradingSchemeDTO.automatic",
    validBoolean,
  ),
  description: required(partial.description, "GradingSchemeDTO.title", validString),
  gradeBrackets: partial.gradeBrackets?.map(makeGradeBracketDTO) || [],
});
