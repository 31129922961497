import { makeFeedbackDTO } from "model/feedback";
import { post } from "./request";
import { endpoints } from "./constants";

/**
 * Send a notification to users list
 *
 * @function send
 * @param {FeedbackDTO} partial
 */
// eslint-disable-next-line import/prefer-default-export
export const send = async (partial) => (await post(
  endpoints.FEEDBACK,
  null,
  makeFeedbackDTO(partial),
)).ok;
