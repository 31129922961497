/**
 * A text-input answer on the student assessment page.
 *
 * For use with fill-in-short type questions.
 *
 * @module ui/page/assessment/answer-checklist
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import div from "ui/html/div";
import inputField from "ui/component/form-managed/field-input";

const getSolutionValue = (solution) => solution?.value?.[0] || null;

export default function answerTextInput(state, view) {
  const { question, solution, validity, required } = state;
  const value = solution?.[0] || "";
  return div(".answer.answer-text-input", view.bind([[inputField, {
    label: "Your Answer",
    value,
    name: question.id,
    required,
    validity,
    onInput: () => view.update({}),
  }]], { [question.id]: getSolutionValue(solution) }));
}
