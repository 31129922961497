/**
 * The user account management page (for managing one's own account).
 *
 * @module ui/page/account
 * @category Pages
 * @subcategory User Accounts
 */
import api from "api";
import log from "log";
import getConfig from "config";
import { pageVisit } from "api/v2/analytics";
import { ConstraintError } from "model/error";
import { userIsManager } from "model/user";
import { notificationMessageTypes } from "model/notification/constants";
import form from "ui/component/form-managed";
import email from "ui/component/form-managed/field-email-address";
import firstName from "ui/component/form-managed/field-given-name";
import lastName from "ui/component/form-managed/field-family-name";
import passwordConfirmed from "ui/component/form-managed/field-password-confirmed";
import phone from "ui/component/form-managed/field-phone-number";
import username from "ui/component/form-managed/field-username";
import userGroupMembership from "ui/component/user-group-membership";
import button from "ui/html/button";
import div from "ui/html/div";
import h2 from "ui/html/h2";
import main from "ui/html/main";
import userLayout from "ui/page/layout/user";
import formManagedView from "ui/view/form-managed";
import view from "ui/view/view";
import { tidyBackendError } from "util/error";
import { frozen, merge } from "util/object";

// need a global place to put the header view after it's bound
let header, modal, notification;

const { SUCCESS, FAIL } = notificationMessageTypes;

/**
 * Collection of messages shown to the user in the notification area.
 *
 * @constant messages
 * @type object
 * @readonly
 * @private
 */
const staticMessages = frozen({
  ok: { title: "Saved!", type: SUCCESS },
  invalid: {
    title: "Failed to Save",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 3,
  },
  unauthorized: { title: "Unauthorized", text: "You are not authorized to change this user's information.", type: FAIL },
  signInInvalid: { text: "You are not logged in. Your session may have expired.", type: FAIL },
});

const handleError = (error) => {
  log.error(error);
  log.debug("error object", error);
  if (error instanceof ConstraintError) {
    notification.post({
      title: "API Error",
      text: "An API request constraint error occurred. Please report this to the operators with the below error message included.",
      type: FAIL,
      duration: 5,
    });
    notification.post({
      title: "API Error Message",
      text: error.message,
      type: "error",
    });
    return;
  }
  if (error.statusCode === 401) {
    notification.post(staticMessages.signInInvalid);
  } else {
    tidyBackendError(error).forEach((text) => notification.post({
      title: "API Error Message",
      text,
      type: "error",
    }));
  }
};

/**
 * Attempt to save the user after validating the form.
 *
 * @function doSave
 * @private
 * @param {FormView} page
 */
const doSave = async (self) => {
  self.setFullValidation(true);
  const validation = self.validate();
  if (!validation.valid) {
    notification.post(staticMessages.invalid);
    return;
  }

  try {
    self.disable();
    const data = {
      username: self.state.user.username,
      ...self.values,
    };
    if (self.values.newPassword && self.values.newPassword === self.values.newPasswordConfirm) {
      data.password = self.values.newPassword;
      delete data.newPassword;
      delete data.newPasswordConfirm;
    }
    const response = await api.user.updateMe(data);
    // clear out the new password fields after a successful save
    /* eslint-disable-next-line no-param-reassign */
    self.element.querySelector("[name=newPassword]").value = "";
    /* eslint-disable-next-line no-param-reassign */
    self.element.querySelector("[name=newPasswordConfirm]").value = "";
    notification.post(staticMessages.ok);
    // clear message after a few seconds
    setTimeout(() => { self.update({ messages: [] }); }, 3000);

    // refresh the header with the new user info
    header.update({ user: response });
    self.enable();
  } catch (error) {
    handleError(error);
    self.enable();
  }
};

/**
 * Stubs for all the state fields the account page will need. These get populated
 * later but they need to exist during instantiation.
 */
const defaultState = frozen({
  user: {},
  config: {},
  checkRequired: false,
});

const doDelete = async (page) => {
  const { user } = page.state;
  if (user.external && !(await modal.confirm("Deleting your account will irreversibly delete your SmartEdge-specific details. Your organization account will remain intact, and may be used to create a new SmartEdge account by logging in. Are you sure you want to proceed?"))) return;
  if (!(await modal.confirm("Are you sure you want to delete your account? This action is irreversible."))) return;

  try {
    await api.user.deleteMe();
    await modal.alert("Your account was successfully deleted.");
    window.location.assign("/login");
  } catch (error) {
    handleError(error);
  }
};

/**
 * @function showAccount
 * @private
 * @param {module:ui/view/form-managed~FormManaged} self
 */
const showAccount = (self) => {
  const { server } = self.state.config;
  return form("#user-account", self.bind(
    [
      // users are not allowed to change their own username
      [username, { disabled: true }],
      [firstName, { required: server.firstNameRequired }],
      [lastName, { required: server.lastNameRequired }],
      [email, { required: server.emailRequired }],
      [phone, { required: server.cellPhoneRequired }],
      // LDAP accounts can't change their passwords here, so don't show password change
      // for external users
      self.state.user.external ? "" : [passwordConfirmed, {}],
    ],
    self.state.user,
  ));
};

const showGroups = ({ state: { user } }) => div(
  "#groups",
  user.groups.length
    ? []
    : [
      h2("Group Membership"),
      userGroupMembership({ user }),
    ],
);

const showActions = (formView) => (self) => div("#actions", [
  button("Save", () => doSave(formView)),
  userIsManager(self.state.user) ? "" : button("Delete", () => doDelete(self), ".danger"),
]);

/**
 * Instantiate the account settings page. This is called from `src/markup/account.html`
 *
 * @function account
 * @param {string} selector css selector for the page's root container element
 * @param {Object} [initialState=defaultState]
 */
export default async function account(selector, initialState = defaultState) {
  const title = "Account Settings";
  ({ header, modal, notification } = userLayout(
    selector,
    [
      main("", [
        form("#user-account"),
        div("#actions"),
        div("#groups"),
        div("#messages"),
      ]),
    ],
    title,
  ));
  pageVisit(window.location.pathname);

  const user = await api.user.getMe();
  const config = await getConfig();
  const uiConfig = await api.site.getUIConfiguration();
  const messages = [];
  if (user.external) {
    messages.unshift({
      text: "As an external user you are unable to update your password here.",
      type: "ok",
    });
  }

  header.update({ user, title });

  const state = merge(defaultState, merge(initialState, {
    user,
    config,
    messages,
    horizontal: uiConfig.horizontalMenu,
  }));

  const accountPage = formManagedView(showAccount)("#user-account", state);
  accountPage.onStateChange = (self) => ({ user: self.values });

  view.create(showActions(accountPage))("#actions", state);
  view.create(showGroups)("#groups", state);

  return accountPage;
}
