/**
 * A modal dialog for selecting an assessment.
 *
 * @module ui/component/modal/assessment-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import listModal from "ui/component/modal/layout/list";
import cameoAssessment from "ui/component/cameo-assessment";
import { getModal } from "ui/view/modal-view";
import { assessmentTextFilter } from "util/filter";

const doSearch = (assessments) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(assessmentSelectModal({
    assessments,
    search,
  }));
};

const sortByTitle = (a, b) => {
  const at = a.title.toLowerCase();
  const bt = b.title.toLowerCase();
  if (at < bt) return -1;
  if (at > bt) return 1;
  return 0;
};

const entryLine = (assessment) => cameoAssessment({
  assessment,
  config: {
    on: {
      click: () => getModal().resolve(assessment),
    },
  },
});

export default function assessmentSelectModal({
  assessments,
  search = "",
}) {
  const searchResults = search
    ? assessments
      .filter(assessmentTextFilter(search))
      .sort(sortByTitle)
    : assessments;
  return listModal({
    entries: searchResults.map(entryLine),
    sel: ".assessment-select",
    onSearch: () => doSearch(assessments),
    searchText: search,
  });
}
