/**
 * Methods for building single rows on the bulk content entry table.
 *
 * @module ui/page/admin/content/bulk/content-row
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import {
  fileTypes,
} from "model/file-descriptor/constants";
import {
  metadataTypes,
  metadataTypesFriendly,
} from "model/metadata/constants";
import {
  button,
  div,
  input,
  span,
  textarea,
} from "ui/html";
import icon from "ui/component/icon";
import select from "ui/component/form-managed/field-select";
import message from "ui/component/message";
import fileSelectDialog from "ui/component/modal/file-select";
import labelCollection from "ui/component/label-collection";
import labelSelect from "ui/component/modal/label-select";
import contentScheduleModal from "ui/component/modal/content-schedule";
import {
  titleConstraints,
} from "ui/common/validation-constraints";
import { getFileType } from "util/file";
import { clone, merge } from "util/object";
import { exists, fieldName, saveStates } from "./content-record";

const data = (id) => ({ dataset: { rowId: id } });
const place = (placeholder) => ({ props: { placeholder } });
const typeFilterAll = (types) => (item) => types.includes(getFileType(item));
const isScheduled = (meta) => meta.startDate && meta.endDate;

/**
 * Create message components from any attached message objects on the content record.
 * These will be populated during refreshContentRecords.
 */
const buildHelp = (content) => {
  if (content.messages.length === 0) {
    return "";
  }

  return div(
    ".helps",
    {},
    content.messages.map(message),
  );
};

/**
 * For determining row status icon in pending rows.
 */
const missingRequired = (content) => !(content.title?.trim()
    && content.type?.trim()
    && content.contentFileName?.trim()
    && content.posterFileName?.trim());

/**
 * Determine status icon.
 */
const rowIcon = (content) => {
  switch (content.saveStatus) {
    case saveStates.SUCCESS:
      return span(
        icon.solid("upload"),
        ".status.ok",
        { props: { title: "Upload Successful" } },
      );
    case saveStates.FAILURE:
      return span(
        icon.solid("exclamation-triangle"),
        ".status.error",
        { props: { title: "Upload Failed" } },
      );
    case saveStates.SAVING:
      return span(
        icon.solid("circle-notch"),
        ".status.saving",
        { props: { title: "Saving..." } },
      );
    case saveStates.PENDING:
    default:
      if (content.validity.valid && !missingRequired(content)) {
        return span(
          icon.solid("check-circle"),
          ".status.ok",
          { props: { title: "All Fields Are Valid" } },
        );
      }
      return span(
        icon.solid("times-circle"),
        ".status.error",
        { props: { title: "Validation Errors" } },
      );
  }
};

const isDisabled = (content) => (
  (content.saveStatus === saveStates.SUCCESS)
  || (content.saveStatus === saveStates.SAVING)
  || (content.type === null)
);

const disabled = (content) => (isDisabled(content)
  ? { props: { disabled: true } }
  : { props: { disabled: false } }
);

const validHook = () => (node) => {
  node.elm.setCustomValidity("taken");
};

const setValid = (field, content) => {
  if (content?.validity?.[field]?.taken) {
    return {
      hook: {
        insert: validHook(),
        update: validHook(),
      },
    };
  }
  return {
    hook: { insert: null, update: null },
  };
};

const makeInput = (entry, required, rowNum, field, placeholder, ...configs) => input.text(
  entry[field],
  required,
  fieldName(field, rowNum),
  data(rowNum),
  disabled(entry),
  place(placeholder),
  setValid(field, entry),
  ...configs,
);

const typeSelect = (entry, rowNum, formView) => select({
  name: fieldName("type", rowNum),
  placeholder: "Select Type",
  onChange: () => formView.update(),
  options: [
    {
      label: metadataTypesFriendly.get(metadataTypes.VIDEO),
      value: metadataTypes.VIDEO,
    },
    {
      label: metadataTypesFriendly.get(metadataTypes.DOCUMENT),
      value: metadataTypes.DOCUMENT,
    },
  ],
  value: entry.type,
});

const description = (entry, rowNum, ...configs) => textarea(
  entry.description,
  false,
  fieldName("description", rowNum),
  disabled(entry),
  ...configs,
);

const pickLabels = async (entry, rowNum, formView, modalView) => {
  const choice = await modalView.async(labelSelect(
    {
      entries: formView.state.labels,
      selectedLabels: entry.labels,
      multiSelect: true,
      search: "",
    },
    modalView,
  ));

  if (choice) {
    formView.update({
      contents: formView.state.contents.map((c, i) => (
        i === rowNum
          ? { ...c, labels: choice }
          : c
      )),
    });
  }
};

const labelInput = (entry, rowNum, formView, modalView) => span([
  labelCollection(entry.labels, (choice) => formView.update({
    contents: formView.state.contents.map((c, i) => (
      i === rowNum
        ? { ...c, labels: c.labels.filter((item) => item.name !== choice.name) }
        : c
    )),
  })),
  button(
    icon("plus-circle"),
    () => pickLabels(entry, rowNum, formView, modalView),
    "",
    "button",
    "Add Label",
    isDisabled(entry),
  ),
], ".label-column");

const fileOkSpan = (fileName) => span(
  ["File", icon("check-circle")],
  ".file.ok.faux",
  { props: { title: fileName } },
);

const fileEmptySpan = (fileName) => span(
  ["File", icon("question-circle")],
  ".file.warn.faux",
  { props: { title: fileName } },
);

const fileMissingSpan = (fileName) => span(
  ["File", icon.solid("exclamation-circle")],
  ".file.danger.faux",
  { props: { title: fileName } },
);

const onContentSchedule = (entry, rowNum, modalView, formView) => async () => {
  const sched = await modalView.async(contentScheduleModal(entry, modalView));
  if (sched) {
    formView.update({
      contents: formView.state.contents.map((c, i) => (i === rowNum
        ? merge(c, sched)
        : c)),
    });
  }
};

const scheduleColumn = (entry, rowNum, disable, modalView, formView) => {
  if (disable) {
    return button(
      icon.solid("clock"),
      () => {},
      ".warn.schedule-column",
      "button",
      "Set Schedule",
      true,
    );
  }
  if (isScheduled(entry)) {
    return button(
      icon.solid("clock"),
      onContentSchedule(entry, rowNum, modalView, formView),
      ".ok.schedule-column",
      "button",
      "Change Schedule",
    );
  }
  return button(
    icon.solid("times-circle"),
    onContentSchedule(entry, rowNum, modalView, formView),
    ".warn.schedule-column",
    "button",
    "Set Schedule",
  );
};

const wrongFileType = (entry, field) => {
  if (field === "posterFileName") {
    return getFileType(entry[field]) !== fileTypes.IMAGE;
  }
  switch (entry.type) {
    case metadataTypes.VIDEO:
      if (getFileType(entry[field]) !== fileTypes.VIDEO) return true;
      break;
    case metadataTypes.DOCUMENT:
    default:
      if (getFileType(entry[field]) !== fileTypes.DOCUMENT) return true;
  }
  return false;
};

const fileIndicator = (entry, field, files) => {
  if (!entry.type?.trim()) {
    return fileEmptySpan("no file selected");
  }
  if (exists(entry[field]) && files.includes(entry[field]) && wrongFileType(entry, field)) {
    return fileMissingSpan(`${entry[field]}: incompatible file type`);
  }
  if (exists(entry[field]) && files.includes(entry[field])) {
    return fileOkSpan(entry[field]);
  }
  if (exists(entry[field]) && !files.includes(entry[field])) {
    return fileMissingSpan(`${entry[field]}: file does not exist`);
  }
  return fileEmptySpan("no file selected");
};

const selectFile = (field, rowNum, uploads, formView, modalView) => async () => {
  const chosen = await modalView.async(fileSelectDialog(
    {
      uploads,
      search: "",
    },
    modalView,
  ));
  if (chosen) {
    document.querySelector(`[name="${fieldName(field, rowNum)}"]`).value = chosen.file;
    formView.validate();
    formView.render();
    formView.update({});
  }
};

const filePicker = (entry, rowNum, field, files, fileType, modalView, formView) => span([
  input("hidden", entry[field], false, fieldName(field, rowNum)),
  fileIndicator(entry, field, files),
  button(
    icon.solid("edit"),
    selectFile(field, rowNum, files.filter(typeFilterAll(fileType)), formView, modalView),
    ".edit",
    undefined,
    "Select File",
    isDisabled(entry),
  ),
], ".file-column");

const fileTypeOf = (type) => {
  switch (type) {
    case metadataTypes.VIDEO:
      return [fileTypes.VIDEO];
    case metadataTypes.DOCUMENT:
      return [fileTypes.DOCUMENT];
    case null:
    default:
      return [];
  }
};

const removeIcon = (entry, rowNum, formView, modalView) => {
  let iconName, style, dangerous;

  if (entry.saveStatus === saveStates.SUCCESS) {
    iconName = "check";
    style = ".secondary";
    dangerous = false;
  } else {
    iconName = "trash";
    style = ".danger";
    dangerous = true;
  }

  return button(
    icon(iconName),
    async () => {
      if (!await modalView.confirm(
        "Are you sure you want to remove this content row?",
        "",
        "Yes",
        "No",
        dangerous,
      )) {
        return;
      }
      const contents = formView.state.contents.map(clone);
      if (contents[rowNum]) {
        contents[rowNum].markedForDeletion = true;
      }
      formView.update({
        contents,
        messages: [],
      });
    },
    `.delete-row${style}`,
    "button",
    "Remove Row",
  );
};

export default (formView, modalView) => (entry, rowNum) => ([
  typeSelect(entry, rowNum, formView),
  makeInput(entry, true, rowNum, "title", "Title", titleConstraints),
  description(entry, rowNum),
  labelInput(entry, rowNum, formView, modalView),
  filePicker(entry, rowNum, "posterFileName", formView.state.files, [fileTypes.IMAGE], modalView, formView),
  filePicker(entry, rowNum, "contentFileName", formView.state.files, fileTypeOf(entry.type), modalView, formView),
  scheduleColumn(entry, rowNum, isDisabled(entry), modalView, formView),
  rowIcon(entry),
  removeIcon(entry, rowNum, formView, modalView),
  buildHelp(entry),
]);
