/**
 * Utilities for dealing with bulk content CSV files.
 *
 * @module ui/page/admin/content/bulk/csv-utils
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import { metadataTypes } from "model/metadata/constants";
import { frozen } from "util/object";
import { makeContentRecord, exists } from "./content-record";

const isEmptyContent = (content) => (
  !exists(content.title)
  && !exists(content.description)
  && !exists(content.posterFileName)
  && !exists(content.contentFileName)
  && (!exists(content.labels)
      || (content.labels instanceof Array && content.labels.length === 0))
);

/* eslint-disable-next-line import/prefer-default-export */
export const csvHeaders = {
  type: new Set([
    "type",
    "content type",
    "content_type",
  ]),
  title: new Set([
    "title",
  ]),
  description: new Set([
    "description",
    "desc",
    "text",
  ]),
  labels: new Set([
    "categories",
    "labels",
  ]),
  posterFileName: new Set([
    "poster_file_name",
    "poster_file",
    "poster file name",
    "poster file",
    "poster name",
    "poster",
  ]),
  contentFileName: new Set([
    "content_file_name",
    "content_file",
    "content file name",
    "content file",
    "content name",
    "content",
    "video_file_name",
    "video_file",
    "video file name",
    "video file",
    "video name",
    "video",
    "pdf_file_name",
    "pdf_file",
    "pdf file name",
    "pdf file",
    "pdf name",
    "document",
    "document_file_name",
    "document_file",
    "document file name",
    "document file",
    "document name",
    "document",
  ]),
};

export const videoTypes = frozen(new Set([
  "video",
  "video_metadata",
  "mp4",
]));

export const documentTypes = frozen(new Set([
  "document",
  "document_metadata",
  "pdf",
]));

export const defaultRowIdentities = frozen(new Map([
  ["type", 0],
  ["title", 1],
  ["description", 2],
  ["labels", 3],
  ["posterFileName", 4],
  ["contentFileName", 5],
]));

export const autodetectColumns = (data) => {
  const headers = data[0];
  const identified = new Map();
  headers.forEach((text, i) => {
    if (csvHeaders.type.has(text.toLowerCase())) identified.set("type", i);
    else if (csvHeaders.title.has(text.toLowerCase())) identified.set("title", i);
    else if (csvHeaders.description.has(text.toLowerCase())) identified.set("description", i);
    else if (csvHeaders.labels.has(text.toLowerCase())) identified.set("labels", i);
    else if (csvHeaders.posterFileName.has(text.toLowerCase())) identified.set("posterFileName", i);
    else if (csvHeaders.contentFileName.has(text.toLowerCase())) identified.set("contentFileName", i);
  });

  return identified;
};

const parseType = (text) => {
  if (!text) return null;
  if (videoTypes.has(text.toLowerCase())) return metadataTypes.VIDEO;
  if (documentTypes.has(text.toLowerCase())) return metadataTypes.DOCUMENT;
  return null;
};

const parseLabels = (text) => (text && typeof text === "string"
  ? text.split(",").map((name) => ({ name: name.trim(), description: "" }))
  : null);

/**
 * Given a Map of column identities, returns a function suitable for use with array.map
 * to map CSV rows to a content DTO.
 */
export const mapContentDataRow = (identified) => (row) => {
  const content = {};
  if (identified.get("type") !== undefined) {
    content.type = parseType(row[identified.get("type")]);
  } else content.type = null;
  if (identified.get("title") !== undefined) {
    content.title = row[identified.get("title")] || null;
  } else content.title = null;
  if (identified.get("description") !== undefined) {
    content.description = row[identified.get("description")] || null;
  } else content.description = null;
  if (identified.get("labels") !== undefined) {
    content.labels = parseLabels(row[identified.get("labels")]) || [];
  } else content.labels = [];
  if (identified.get("posterFileName") !== undefined) {
    content.posterFileName = row[identified.get("posterFileName")] || null;
  } else content.posterFileName = null;
  if (identified.get("contentFileName") !== undefined) {
    content.contentFileName = row[identified.get("contentFileName")] || null;
  } else content.contentFileName = null;

  return makeContentRecord(content);
};

/**
 * Check if the header row matches the provided template (lets us skip some other checks).
 */
export const csvHeaderCheck = (headers) => {
  if (!headers[0] || !csvHeaders.type.has(headers[0].toLowerCase())) return false;
  if (!headers[1] || !csvHeaders.title.has(headers[1].toLowerCase())) return false;
  if (!headers[2] || !csvHeaders.description.has(headers[2].toLowerCase())) return false;
  if (!headers[3] || !csvHeaders.labels.has(headers[3].toLowerCase())) return false;
  if (!headers[4] || !csvHeaders.posterFileName.has(headers[4].toLowerCase())) return false;
  if (!headers[5] || !csvHeaders.contentFileName.has(headers[5].toLowerCase())) return false;
  return true;
};

/**
 * Given a set of parsed CSV data from papaparse, walks the content through common issues
 * and produces a set of content objects if possible from the output.
 */
export const processCSV = async (output, modalDialog, existing, files) => {
  let detected = new Map();
  // let the content bail if the parser reported errors
  if (output?.errors?.length) {
    if (!(await modalDialog.confirm(
      `The selected file had ${output.errors.length} invalid rows.
       Would you like to proceed, discarding the invalid entries?`,
    ))) return [];
  }

  // let's see if we can figure out the CSV's format
  if (csvHeaderCheck(output.data[0])) {
    // the content used the template (and hopefully filled it out right!)
    detected = defaultRowIdentities;
  } else if (output.data[0].length !== 6 || !csvHeaderCheck(output.data[0])) {
    // if the content is using his own spreadsheet we'll try our best...
    if ((await modalDialog.confirm(
      `The selected CSV contained an unexpected number of columns,
       or the columns were out of order, or the header row was missing.
       Would you like SmartEdge to attempt to detect the contents?`,
    ))) detected = autodetectColumns(output.data);
  }

  // if we couldn't find any identifiable columns we won't be able to produce anything
  if (detected.size === 0) {
    await modalDialog.alert(
      `Unable to identify any usable columns.
       Please try using the CSV template provided below.`,
    );
    return [];
  }

  // alert the content not to expect much...
  if (!output.data || output.data.length < 2) {
    await modalDialog.alert(
      `The CSV contained no usable rows. Please ensure the first row of the CSV
       contains labels, or try using the CSV template provided below.`,
    );
    return [];
  }

  // cross your fingers...
  let contents = output.data.slice(1).map(mapContentDataRow(detected));

  if (contents.findIndex(isEmptyContent) < contents.length - 1) {
    await modalDialog.alert(
      `Some rows were empty or had no usable data. These rows will be removed.`,
    );
  }
  contents = contents.filter((content) => !isEmptyContent(content));

  const missingPosters = new Set();
  const missingFiles = new Set();
  let badTypes = false;

  /* eslint-disable no-param-reassign */
  contents.forEach((content) => {
    if (content.posterFileName && !files.includes(content.posterFileName)) {
      missingPosters.add(content.posterFileName);
    }
    if (content.contentFileName && !files.includes(content.contentFileName)) {
      missingFiles.add(content.contentFileName);
    }
    if (content.type === null) {
      badTypes = true;
    }
  });

  if (missingPosters.size > 0) {
    await modalDialog.alert(
      `Some entries had missing poster files.
       Please check the Help & Instructions for instructions and a link to upload
       files.`,
    );
  }
  if (missingFiles.size > 0) {
    await modalDialog.alert(
      `Some entries had missing content files.
       Please check the Help & Instructions for instructions and a link to upload
       files.`,
    );
  }
  if (badTypes) {
    await modalDialog.alert(
      `SmartEdge was unable to determine the content type of one or more entries.
       Please select their correct types in the form before saving.`,
    );
  }

  // passed all checks, let's move on
  return contents;
};
