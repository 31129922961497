/**
 * A IMG element.
 *
 * @module ui/html/div
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 *
 * @function img
 * @param {string} sel css selectors
 * @param {string} src src url
 * @param {string} alt alt text
 * @param {Object} config additional config data for `el()`
 * @return {module:ui/common/el~El}
 */
export default (sel = '', src = "", alt = "", config = {}) => el(`img${sel}`, {
  attrs: { src, alt },
  ...config,
});
