/**
 * A modal dialog for previewing a user.
 *
 * @module ui/component/modal/user
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, h2, span } from "ui/html";
import cameoMemberGroup from "ui/component/cameo-member-group";
import dialog from "ui/component/modal/layout/dialog";
import icon from "ui/component/icon";
import { getModal } from "ui/view/modal-view";
import { isAnonymous } from "util/user";

const empty = (value) => (
  value === undefined
  || value === null
  || value === ""
  || value?.length === 0
);

const formatValue = (value) => {
  if (value === true) return span(icon("check-circle"), ".secondary");
  if (value === false) return span(icon("times-circle"), ".warning");
  return `${value}`;
};

const property = (user) => (label, prop) => (!empty(user[prop])
  ? [span(label, ".label"), span(formatValue(user[prop]), ".property")]
  : []
);

const groups = (user) => (!empty(user.groups)
  ? div(".groups", div(".groups-inner", [
    ...user.groups.map(
      ((group) => cameoMemberGroup({
        group,
      })),
    ),
  ]))
  : ""
);

/**
 * @function modalUser
 * @param {object} config
 * @param {User} config.user
 * @param {Selector} [config.sel=""]
 * @param {ModalView} modalView
 */
export default function modalUser({ user, sel = "" }) {
  const prop = property(user);
  return dialog({
    sel: `${sel}.user${isAnonymous(user) ? ".anonymous" : ""}`,
    body: isAnonymous(user)
      ? [
        h2("Anonymous User", ".profile"),
      ]
      : [
        h2("Profile", ".profile"),
        ...prop("Username", "username"),
        ...prop("First name", "firstName"),
        ...prop("Last Name", "lastName"),
        ...prop("Email", "email"),
        ...prop("Phone", "cellPhone"),
        ...prop("Verified", "enabled"),
        ...prop("External", "external"),
        a("SEE FULL PROFILE", `/admin/user-profile?id=${user.id}`, ".profile-link"),
        h2("Groups"),
        groups(user),
      ],
  }, getModal());
}
