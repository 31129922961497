export const tags = Object.freeze({
  placeholder: "PLACEHOLDER",
  cache: "CACHE",
});

const tag = (obj, toTag) => ({
  ...obj,
  [toTag]: true,
});

export const tagPlaceholder = (obj) => ({
  ...obj,
  [tags.placeholder]: true,
});

export const tagCached = (obj) => ({
  ...obj,
  [tags.cache]: true,
});

/**
 * Tags an item as a placeholder.
 *
 * @param {function} model
 * @return {function} fn (params) -> obj by wy of factory
 */
export const makePlaceholderModelFactory = (model) => (...params) => tag(
  model(...params),
  tags.placeholder,
);

/**
 * Tags an item as a placeholder.
 *
 * @param {function} model
 * @return {function} fn (params) -> obj by wy of factory
 */
export const makeCachedModelFactory = (model) => (...params) => tag(
  model(...params),
  tags.cache,
);

export const preserveTagsOnModel = (model) => (item) => {
  const out = model(item);
  Object.keys(tags).forEach((t) => {
    if (item[t]) out[t] = true;
  });
  return out;
};

export const isPlaceholder = (item) => !item || item?.[tags.placeholder];

export const isFromCache = (item) => !item || item?.[tags.cache];

export const hasAnyPlaceholders = (items) => items.find(isPlaceholder);
