/**
 * Data streams for system notification messages.
 *
 * @module data/notification
 */
import xs from "xstream";
import { messageTypes, messageStatus } from "@smartedge/em-message/constants";
import {
  getMessageStream,
  getNotificationsForUserId,
  markMessagesRead,
} from "api/message";
import { tee, collect, streamLog } from "data/stream/compose";
import hashmap from "util/hash-map";
import { sortDate } from "util/sort";

const notifications = hashmap();

const out$ = xs.create();

let initialized = false;

const emitUpdate = (collection) => {
  const items = [...collection.values()];
  items.sort((a, b) => sortDate(a.created, b.created));
  out$.shamefullySendNext(items);
};

const markRead = ({ message: { messageIds } }) => {
  messageIds.forEach((mid) => {
    if (notifications.has(mid)) {
      notifications.set(
        mid,
        { ...notifications.get(mid), status: messageStatus.READ },
      );
    }
  });
  emitUpdate(notifications);
};

/**
 * Initializes the notification stream.
 *
 * @function init
 * @private
 */
const init = async (user) => {
  if (!initialized) {
    const archived = await getNotificationsForUserId(user.id);
    archived.forEach((n) => notifications.set(n.id, n));
    initialized = true;
    emitUpdate(notifications);
    // listen for mark-read updates
    getMessageStream(messageTypes.UPDATE_READ)
      .compose(tee(markRead));
  }
};

/**
 * Gets the notification stream.
 *
 * @function get
 */
const get = (user) => {
  init(user);
  xs.merge(
    getMessageStream(messageTypes.ADMIN_NOTIFICATION),
    getMessageStream(messageTypes.NOTIFICATION),
  )
    .compose(streamLog("NOTIFY_STREAM_INCOMING"))
    .map((n) => [n.message.id, n.message])
    .compose(collect(notifications))
    .compose(tee(emitUpdate));
  return out$;
};

out$.compose(streamLog("NOTIFY_STREAM_OUTGOING"));

/**
 * Mark a list of notifications as read.
 *
 * Updates notifications in cache + dispatches to MBE.
 *
 * @function markAllRead
 */
const markAllRead = (messageIds) => {
  markRead({ message: { messageIds } }); // more responsive if we assume this will work
  markMessagesRead(messageIds); // don't await this, just fire & forget
};

/**
 * Remove a notification from the queue.
 */
const remove = (id) => {
  notifications.remove(id);
  out$.shamefullySendNext([...notifications.entries()]);
};

export default {
  get,
  remove,
  markAllRead,
  all$: xs.merge(out$),
};
