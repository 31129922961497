/**
 * Type definitions and sanitation functions for ACL.
 *
 * @module model/acl
 * @category Model
 * @subcategory ACL
 */
import { required, validString, validUUID } from "model/constraints";
import { validACLAccessRights } from "./constraints";
import {
  aclPrincipalTypes,
  systemPrivilegeGroupsSet,
} from "./constants";

/**
 * One of the principal types enumerated in
 * [aclPrincipalTypes](@link module:model/acl/constants.aclPrincipalTypes)
 *
 * @typedef {string} ACLPrincipalType
 */

/**
 * @typedef {ListMetadata|DynamicPage|Course} ACLSubjectEntry
 */

/**
 * One of the access rights types enumerated in
 * [aclRights](@link module:model/acl/constants.aclRights)
 *
 * @typedef {string} ACLRight
 */

/**
 * An acl user or group entry.
 *
 * @typedef ACLPrincipalEntry
 * @property {UUID} id
 * @property {string} name
 * @property {?User} entity
 * @property {ACLPrincipalType} type
 * @property {boolean} system whether this is a special system privilege group
 * @property {?ACLPrincipalEntry[]} members only present for groups
 */

/**
 * @typedef ACLGrantEntry
 * @property {ACLPrincipalEntry} principal
 * @property {ACLSubjectEntry} subject
 * @property {ACLSubjectType} type
 * @property {ACLRight[]} rights
 */

/**
 * Transforms a `User` into an `ACLPrincipalEntry`.
 *
 * @param {User} user
 * @return {ACLPrincipalEntry}
 */
export const userToPrincipal = (user) => ({
  id: user.id,
  name: user.username,
  type: aclPrincipalTypes.INDIVIDUAL,
  entity: user,
});

/**
 * Transforms access rights DTO to array of access rights
 *
 * @param response
 * @returns {Set<string>}
 */
export const responseToAccessRight = (response) => new Set([
  ...response,
]);

/**
 * @function responseToACLGrantEntry
 * @returns {ACLGrantEntry}
 */
export const responseToACLGrantEntry = (partial) => ({
  principal: partial.principal,
  subject: partial.subject,
  type: partial.type,
  rights: new Set(partial.rights),
});

/**
 * An entry for a user group.
 *
 * @typedef ACLUserGroupEntry
 * @property {UUID} id uuid
 * @property {string} name user group name
 * @property {boolean} system whether this is a special system privilege group
 */

/**
 * Make ACLUserGroupEntry from partial data.
 *
 * @function makeGroup
 * @return AclUserGroupEntry
 */
export const makeGroup = (partial) => ({
  id: partial.id || "",
  name: partial.name || "",
  system: systemPrivilegeGroupsSet.has(partial.name) || false,
});

/**
 * Transforms a user group DTO to a standardized UserGroupEntry object.
 *
 * @function responseToUserGroupEntry
 * @param {object} response
 * @param {string} response.userGroupId
 * @param {string} response.userGroupName
 *
 * @return {(ACLUserGroupEntry|null)}
 */
export const responseToUserGroupEntry = (response) => ({
  id: response.userGroupId,
  name: response.userGroupName,
  system: systemPrivilegeGroupsSet.has(response.userGroupName),
});

/**
 * Transforms an acl response DTO to a standardized ACLPrincipalEntry object.
 *
 * @function responseToAclUserEntry
 * @param {object} response
 * @param {string} response.id
 * @param {string} response.name
 * @param {ACLPrincipalType} response.type
 *
 * @return {(ACLPrincipalEntry|null)}
 */
export const responseToACLPrincipalEntry = (response) => ({
  id: response.id,
  name: response.name,
  type: response.type,
  members: response.members?.map?.(responseToACLPrincipalEntry) || null,
  system: systemPrivilegeGroupsSet.has(response.name),
});

/**
 * A user group DTO.
 *
 * @typedef UserGroupDTO
 * @property {string} userListId
 * @property {string} userId
 */

/**
 * DTO for adding or removing user to a user group.
 *
 * Sent to PUT / DELETE `/api/user/group` endpoints.
 *
 * @function makeUserGroupDTO
 * @param {object} partial
 * @param {string} partial.groupId
 * @param {string} partial.userId
 * @return {UserGroupDTO}
 */
export const makeUserGroupDTO = (partial) => ({
  userGroupId: required(partial.groupId, "UserGroupDTO.groupId", validUUID),
  userIds: [
    required(partial.userId, "UserGroupDTO.userId", validUUID),
  ],
});

/**
 * An access right DTO.
 *
 * @typedef GrantDTO
 * @property {array} accessTypes
 * @property {aclPrincipalTypes} userAclType
 * @property {string} userId
 */

/**
 * DTO for updating access rights.
 *
 * Sent to POST "/api/acl/file/${fileId}/user/${userId}",
 * "/api/acl/media/${mediaId}/user/${userId}" endpoints.
 *
 * @function makeAcccessRightsDTO
 * @param {Array<Object>} partial
 * @return {Array<GrantDTO>}
 */
export const makeAccessRightsDTO = (partial) => partial?.map((item) => ({
  userId: validString(item.principal.id, "GrantDTO.principal.id"),
  userAclType: validString(item.principal.type, "GrantDTO.principal.type"),
  accessTypes: validACLAccessRights([...item.rights], "GrantDTO.rights"),
}));

/**
 * DTO for updating access rights.
 *
 * @function makeAcccessRightsDTO
 * @param {Object} partial
 * @return {GrantDTO}
 */
export const makeGrantDTO = (partial) => ({
  userId: validString(partial.id, "GrantDTO.id"),
  userAclType: validString(partial.type, "GrantDTO.type"),
  accessTypes: validACLAccessRights([...partial.rights], "GrantDTO.rights"),
});

/**
 * @typedef CreateGroupDTO
 * @property {string} groupName
 */

/**
 * @function makeCreateGroupDTO
 * @param {string} name
 */
export const makeCreateGroupDTO = (partial) => ({
  groupName: validString(partial.name, "CreateGroupDTO.name"),
});
