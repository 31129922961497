/**
 * A tooltip component
 *
 * @module ui/component/tooltip/tooltip-wrapper
 * @category UI
 * @subcategory Components
 */
import { merge } from "util/object";
import { div, span } from "ui/html";
import {
  initializeTooltipEvents,
  repositionTooltip,
  tooltipModes,
  tooltipPositions,
} from "./common";

const TOOLTIP_MARGIN = 16;

const defaultState = {
  position: tooltipPositions.RIGHT,
  mode: tooltipModes.HOVER,
  content: "",
  children: "",
  margin: TOOLTIP_MARGIN,
  onTooltipShow: () => {},
  onTooltipHide: () => {},
};

export default function tooltipWrapper(inState = defaultState) {
  const state = merge(defaultState, inState);
  const { mode, position, onTooltipShow, onTooltipHide, margin } = state;
  return span(
    [
      state.children,
      div(
        `.tooltip.${position}`,
        {
          hook: {
            insert: (node) => {
              repositionTooltip(position, node.elm, margin);
              initializeTooltipEvents({
                tooltipElement: node.elm,
                mode,
                position,
                onTooltipShow,
                onTooltipHide,
                margin,
              });
            },
          },
        },
        state.content,
      ),
    ],
    ".tooltip-wrapper",
  );
}
