/**
 * A modal for doing advanced evaluation searches on the manage evaluation page.
 *
 * @module ui/component/modal/advanced-evaluation-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, h3 } from "ui/html";
import cameoUser from "ui/component/cameo-user";
import button from "ui/component/form-managed/button";
import cameoCourse from "ui/component/cameo-course";
import cameoAssessment from "ui/component/cameo-assessment";
import userSelectModal from "ui/component/modal/user-select";
import dialog from "ui/component/modal/layout/dialog";
import courseSelectModal from "ui/component/modal/course-select";
import assessmentSelectModal from "ui/component/modal/assessment-select";
import message from "ui/component/message";
import { getModal } from "ui/view/modal-view";
import { frozen, merge } from "util/object";

const staticMessages = frozen({
  specifyAssessment: {
    text: "Please select assessment",
    type: "warn",
  },
});

const defaultParams = frozen({
  user: null,
  course: null,
  assessment: null,
});

const defaultState = frozen({
  params: defaultParams,
  messages: [],
  users: [],
  courses: [],
  assessments: [],
});

/* eslint-disable no-use-before-define */
const selectUser = async (state) => {
  const modal = getModal();
  const selectedUser = await modal.async(userSelectModal({
    users: state.users,
  }));
  if (selectedUser) {
    modal.patch(
      advancedEvaluationSearchModal(merge(state, { params: { user: selectedUser } })),
    );
  } else {
    modal.patch(advancedEvaluationSearchModal(state));
  }
};

const selectCourse = async (state) => {
  const modal = getModal();
  const { courses } = state;
  const course = await modal.async(courseSelectModal({ courses }));
  if (course) {
    modal.patch(advancedEvaluationSearchModal(merge.all([
      state,
      { params: { course } },
      { assessments: course.modules.map((module) => module.assessments).flat() },
    ])));
  } else {
    modal.patch(advancedEvaluationSearchModal(state));
  }
};

const selectAssessment = async (state) => {
  const modal = getModal();
  const { assessments } = state;
  const assessment = await modal.async(assessmentSelectModal({ assessments }));
  if (assessment) {
    modal.patch(
      advancedEvaluationSearchModal(merge(state, { params: { assessment } })),
    );
  } else {
    modal.patch(advancedEvaluationSearchModal(state));
  }
};

const doSearch = (state) => {
  const modal = getModal();
  const messages = [];
  if (state.params.course && !state.params.assessment) {
    messages.push(staticMessages.specifyAssessment);
    modal.patch(advancedEvaluationSearchModal(merge(state, { messages })));
    return;
  }
  modal.resolve({
    user: state.params.user,
    course: state.params.course,
    assessment: state.params.assessment,
  });
};

const showUser = (state) => (state.params.user
  ? cameoUser({
    user: state.params.user,
    controls: [
      button.warning({
        icon: "trash-alt",
        iconOnly: true,
        onClick: () => getModal().patch(
          advancedEvaluationSearchModal(merge(state, { user: null })),
        ),
      }),
    ],
  })
  : button.standIn({
    icon: "user",
    label: "Select User",
    onClick: () => selectUser(state),
  }));

const showCourse = (state) => (state.params.course
  ? cameoCourse({
    course: state.params.course,
    controls: [
      button.warning({
        icon: "trash-alt",
        iconOnly: true,
        onClick: () => getModal().patch(
          advancedEvaluationSearchModal(merge(
            state,
            { params: { course: null, assessment: null } },
          )),
        ),
      }),
    ],
  })
  : button.standIn({
    icon: "book",
    label: "Select Course",
    onClick: () => selectCourse(state),
  }));

const showAssessment = (state) => (state.params.assessment
  ? cameoAssessment({
    assessment: state.params.assessment,
    onRemove: () => getModal().patch(
      advancedEvaluationSearchModal(merge(state, { params: { assessment: null } })),
    ),
  })
  : button.standIn({
    icon: "medal",
    label: "Select Assessment",
    onClick: () => selectAssessment(state),
    disabled: !state.params.course,
  }));

export default function advancedEvaluationSearchModal(inState) {
  const state = merge(defaultState, inState);
  const modal = getModal();
  return dialog({
    sel: ".form.advanced-search.advanced-evaluation-search",
    header: h3("Advanced Search"),
    body: [
      showUser(state),
      showCourse(state),
      showAssessment(state),
      div(".messages", state.messages.map(message)),
    ],
    footer: button({
      icon: "magnifying-glass",
      label: "Search",
      onClick: () => doSearch(state),
    }),
  }, modal);
}
/* eslint-enable no-use-before-define */
