import log from "log";
import collapsible from "ui/component/collapsible";
import fileUrlModal from "ui/component/modal/file-url";
import linkSelectDialog from "ui/component/modal/link-select";
import pwaIosPrompt from "ui/component/modal/pwa-ios-prompt";
import pwaAndroidPrompt from "ui/component/modal/pwa-android-prompt";
import icon from "ui/component/icon";
import { button } from "ui/html";
import { pageList } from "../mocks";

export default (modalView) => collapsible({
  title: "Misc. Dialogs",
  open: false,
}, [
  button(["File URL ", icon("link")], () => {
    modalView.async(
      fileUrlModal((url) => log.debug(`User input URL ${url}`), modalView),
    );
  }, ".subtle.forward"),
  button(["Link Selector (External)", icon("link")], async () => {
    const selectedLink = await modalView.async(linkSelectDialog({
      pages: [...pageList],
      url: "https://google.com",
    }, modalView));
    if (selectedLink) log.debug("user selected link", selectedLink);
    else log.debug("user didn't select a link");
  }, ".subtle.forward"),
  button(["Link Selector (Page)", icon("link")], async () => {
    const selectedLink = await modalView.async(linkSelectDialog({
      pages: [...pageList],
      url: `/${pageList[0].slug}`,
    }, modalView));
    if (selectedLink) log.debug("user selected link", selectedLink);
    else log.debug("user didn't select a link");
  }, ".subtle.forward"),
  button(["PWA IOS Prompt", icon("link")], async () => {
    await modalView.async(pwaIosPrompt(modalView));
  }, ".subtle.forward"),
  button(["PWA Android Prompt", icon("link")], async () => {
    await modalView.async(pwaAndroidPrompt(modalView));
  }, ".subtle.forward"),
]);
