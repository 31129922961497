/**
 * Standard stream sources.
 *
 * @module data/stream/source
 */
import xs from "xstream";
/* eslint-disable import/prefer-default-export */

/**
 * @typedef SourceStream
 * @extends Stream
 * @method post forces the stream to emit a marble
 * @param {mixed} marble
 */

/**
 * General purpose source stream.
 *
 * Meant to be used as the start of a pipeline, and provides an extra method
 * `source$.post` for pushing requests into the pipe.
 *
 * @function source
 * @returns SourceStream
 */
export const source = () => {
  const source$ = xs.create();
  source$.post = (marble) => source$.shamefullySendNext(marble);
  return source$;
};
