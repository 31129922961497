/**
 * A help modal dialog explaining the system privileges field for page sections.
 *
 * @module ui/component/modal/help/system-privilege
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminSystemPrivilegeHelpModal(
  skipCache = true,
  async = true,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_SYSTEM_PRIVELEGE,
    skipCache,
    async,
    sel: ".help-modal-system-privilege",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-system-privilege.svg"),
            p("System Privilege", ".title"),
            div(".text-container", [
              p("Users can be granted system administration privileges depending on role"),
              p("within the organization."),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-system-privilege.svg"),
            p("System Privilege", ".title"),
            div(".text-container", [
              p("For more information, please get in touch with your organization’s"),
              p("SmartEdge administrator."),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
