import collapsible from "ui/component/collapsible";
import toggle from "ui/component/form-managed/field-toggle";
import multiToggle from "ui/component/form-managed/field-multi-toggle";
import span from "ui/html/span";

export default function toggles(self) {
  // shorthands
  const required = true;
  const disabled = true;
  const boxed = true;

  return collapsible({
    title: "Toggles",
    open: self.state.togglesOpen,
    onChange: (togglesOpen) => self.updateState({ togglesOpen }),
  }, self.bind([
    [toggle, { label: "Optional", name: "optional-toggle" }],
    [toggle, { label: "Required", name: "required-toggle", required }],
    [toggle, { label: "Disabled", name: "disabled-toggle", disabled }],
    // note contents of wrapper elements must be bound separately
    // bind can't consume child trees (at least for now)
    span(self.bind([
      [toggle, { label: "Optional", name: "optional-box", boxed, sel: ".inverse" }],
      [toggle, { label: "Required", name: "required-box", boxed, required, sel: ".inverse" }],
      [toggle.boxed, { label: "Primary", name: "primary-box" }],
      [toggle.boxed.secondary, { label: "Secondary", name: "secondary-box" }],
      [toggle.boxed.alternate, { label: "Alternate", name: "alternate-box" }],
      [toggle.boxed.warning, { label: "Warning", name: "warning-box" }],
      [toggle.boxed.danger, { label: "Danger", name: "danger-box" }],
      [toggle.boxed.disabled, { label: "Disabled", name: "disabled-box" }],
    ]), ".buttons"),
    [multiToggle, {
      value: "2",
      options: [
        { label: "One", value: "1" },
        { label: "Two", value: "2" },
        { label: "Three", value: "3" },
      ],
    }],
  ]));
}
