import collapsible from "ui/component/collapsible";
import checkbox from "ui/component/form-managed/field-checkbox";

export default function toggles(self) {
  // shorthands
  const required = true;
  const { boxed } = checkbox;

  return collapsible({
    title: "Checkboxes",
    open: self.state.checkRadioOpen,
    onChange: (checkRadioOpen) => self.updateState({ checkRadioOpen }),
  }, self.bind([
    [checkbox],
    [checkbox, { label: "Required Checkbox", name: "checkbox-required", required }],
    [checkbox.ok, { label: "Ok/Good Checkbox", name: "checkbox-ok" }],
    [checkbox.warning, { label: "Warning Checkbox", name: "checkbox-warning" }],
    [checkbox.danger, { label: "Dangerous Checkbox", name: "checkbox-dangerous" }],
    [checkbox.alternate, { label: "Alternate Checkbox", name: "checkbox-alternate" }],
    [boxed, { label: "Required Boxedcheck", name: "boxcheck-required", required }],
    [boxed.ok, { label: "Ok/Good Boxedcheck", name: "boxcheck-ok" }],
    [boxed.warning, { label: "Warning Boxedcheck", name: "boxcheck-warning" }],
    [boxed.danger, { label: "Dangerous Boxedcheck", name: "boxcheck-dangerous" }],
    [boxed.alternate, { label: "Alternate Boxedcheck", name: "boxcheck-alternate" }],
  ], { "checkbox-ok": true }));
}
