/**
 * Get grading scheme by id.
 *
 * @function getGradingSchemeById
 * @param {string} id
 * @param {boolean} skipCache
 * @returns {Promise<Course>}
 */
import cache from "cache";
import {
  del,
  get,
  post,
  put,
} from "api/request";
import { makeGradingSchemeDTO, responseToGradingScheme } from "model/course/grading-scheme";
import { endpoints } from "api/constants";
import { CACHE_COURSE_GRADING_KEY } from "./cache";

/**
 * Get grading scheme by id
 *
 * @function getGradingSchemeById
 * @param {UUID} id
 * @param {boolean} skipCache
 * @returns {Promise<GradingScheme>}
 */
export const getGradingSchemeById = async (id, skipCache = false) => {
  if (!skipCache) {
    return responseToGradingScheme(await cache.getOrRefreshExpiringMapEntry(
      CACHE_COURSE_GRADING_KEY,
      id,
      async () => cache.storeExpiringMapEntry(
        CACHE_COURSE_GRADING_KEY,
        id,
        (await get(endpoints.GRADING_SCHEME(id))).body,
      ),
    ));
  }
  return responseToGradingScheme(cache.storeExpiringMapEntry(
    CACHE_COURSE_GRADING_KEY,
    id,
    (await get(endpoints.GRADING_SCHEME(id))).body,
  ));
};

/**
 * Create a grading scheme
 *
 * @function updateGradingScheme
 * @param {GradingScheme} partial
 * @returns {Promise<GradingScheme>}
 */
export const createGradingScheme = async (partial) => {
  const response = (await post(
    endpoints.GRADING_SCHEMES,
    null,
    makeGradingSchemeDTO(partial),
  )).body;
  cache.storeExpiringMapEntry(CACHE_COURSE_GRADING_KEY, response.id, response);
  return responseToGradingScheme(response);
};

/**
 * Update a grading scheme
 *
 * @function updateGradingScheme
 * @param {GradingScheme} partial
 * @returns {Promise<GradingScheme>}
 */
export const updateGradingScheme = async (partial) => {
  const response = (await put(
    endpoints.GRADING_SCHEME(partial.id),
    null,
    makeGradingSchemeDTO(partial),
  )).body;
  cache.storeExpiringMapEntry(CACHE_COURSE_GRADING_KEY, response.id, response);
  return responseToGradingScheme(response);
};

/**
 * Delete grading scheme by id
 *
 * @function deleteGradingSchemeById
 * @param {string} id
 * @return boolean indicating success
 */
export const deleteGradingSchemeById = async (id) => {
  const response = await del(endpoints.GRADING_SCHEME(id));
  if (response.ok) {
    cache.storeExpiringMapEntry(CACHE_COURSE_GRADING_KEY, id, null);
  }
  return response.ok;
};
