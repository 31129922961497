/**
 * A small button that shows only an icon with no label text.
 *
 * @module ui/component/icon-button
 * @category UI
 * @subcategory Components
 */
import icon from "ui/component/icon";
import button from "ui/html/button";

const getIconFactory = (iconType) => {
  switch (iconType) {
    case "solid":
      return icon.solid;
    case "regular":
      return icon.solid;
    default:
      return icon;
  }
};

export default function iconButton(iconId, title, onClick, sel = "", iconType = "solid", disabled = false) {
  return button(
    getIconFactory(iconType)(iconId, "", { props: { title } }),
    onClick,
    `.icon${sel}`,
    undefined,
    title,
    disabled,
  );
}
