/**
 * A DIV element.
 *
 * @module ui/html/div
 * @category UI
 * @subcategory Elements
 */
import el from "ui/common/el";

/**
 *
 * @function div
 * @param {string} sel css selectors
 * @param {mixed} ...args addtional arguments as per {@link ui/common/el}
 */
export default (sel = "", ...args) => el(`div${sel}`, ...args);
