/**
 * A cameo for displaying menu entries in the menu editor page and
 * sub-menu entry edit dialogs.
 *
 * @module ui/component/menu-entry-edit-bar
 * @private
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
/***/
import icon from "ui/component/icon";
import iconButton from "ui/component/icon-button";
import span from "ui/html/span";
import { menuLinkTypes, menuLinkTypesLabeled } from "model/site/constants";
import cameo from "ui/component/cameo";

const entryIcon = (name, type) => icon.solid(
  name,
  ".primary",
  { prop: { title: menuLinkTypesLabeled.get(type) } },
);

export const getIcon = (type) => {
  switch (type) {
    case menuLinkTypes.LINK_EXTERNAL:
      return entryIcon("link", type);
    case menuLinkTypes.LINK_PAGE:
      return entryIcon("file-alt", type);
    default:
      return entryIcon("bars", type);
  }
};

// FIXME refactor to use object style parameter
export default function cameoMenuEntry(
  entry,
  index,
  onEdit,
  onDelete,
) {
  return cameo({
    icon: getIcon(entry.type),
    label: span([
      entry.label,
      (entry.entries?.length
        ? span(`[${entry.entries.length}]`, ".entries")
        : ""
      ),
    ], ".title"),
    controls: [
      iconButton("edit", "Edit", () => onEdit(entry, index)),
      iconButton("trash-alt", "Delete", () => onDelete(entry, index), ".danger"),
    ],
    sel: ".menu-entry",
    config: {
      key: entry.title,
      attrs: { role: "group" },
    },
  });
}
