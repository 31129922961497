/**
 * Dashboard UI layout component.
 *
 * @module ui/page/layout/dashboard
 * @category Layout
 * @subcategory Admin
 */
import { div, header, main } from "ui/html";
import el, { patch } from "ui/common/el";
import view from "ui/view";
import { setTitle } from "util/navigation";
import footer from "ui/component/footer";
import { bindNotificationModal } from "./common";

/**
 * A layout for "dashboard" style pages.
 *
 * @function dashboardLayout
 * @param {Selector} selector selects the containing app element
 * @param {ChildEl} elements
 * @param {string} title page title
 * @param {boolean} lonely if true, the layout will be formatted for a full-page cell
 * @param {string} additionalSelector additional selector for main block
 */
export default function dashboardLayout(
  selector,
  elements = [],
  title = "Admin",
  lonely = false,
  additionalSelector = "",
) {
  const structure = div(`${selector}.page`, [
    header(),
    main(`.dashboard${lonely ? ".lonely" : ""}${additionalSelector}`, elements),
    footer.admin(),
    div('#modal'),
    div("#loading"),
    div("#notifications"),
  ]);

  const container = el.fromDOM(document.querySelector(selector));

  patch(container, structure);

  const headerView = view.header.dashboard('header', { user: {} });
  const loadingView = view.loading("#loading");
  const modalView = view.modal(() => "")("#modal", {});
  const notificationView = view.notification("#notifications", {});

  bindNotificationModal(headerView);

  // adding the dashboard class to body toggles on
  // dashboard-style header and page layouts
  document.body.classList.add("dashboard");

  setTitle(title);
  headerView.update({ title });

  return {
    header: headerView,
    loading: loadingView,
    modal: modalView,
    notification: notificationView,
  };
}
