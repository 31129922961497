/**
 * A checklist answer on the student assessment page.
 *
 * For use with multiple-question multiple-answer type questions.
 *
 * @module ui/page/assessment/answer-text-input
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import div from "ui/html/div";
import checklist from "ui/component/form-managed/field-checklist";

export default function answerChecklist(state, view) {
  const { question, solution, validity, required } = state;
  return div(".answer.answer-checklist-options",
    view.bind([[checklist.boxed, {
      entries: question.answers.map((answer, i) => ({
        label: answer.value,
        value: answer.value,
        sel: `#${question.id}-${i}`,
      })),
      required,
      name: `${question.id}`,
      sel: `#${question.id}`,
      validity,
    }]], { [question.id]: solution }),
  );
}
