/**
 * @module ui/component/header/menu
 * @private
 * @category UI
 * @subcategory Components
 */
/***/
import log from "log";
import { userInGroupName } from "model/user";
import { userTypes } from "model/user/constants";
import { menuEntryTypes, menuLinkTypes, menuEntryExclusiveTypes } from "model/site/constants";
import { BREAKPOINT_SMALL, elId } from "util/dom";
import {
  a,
  button,
  div,
  hr,
  nav,
  span,
} from "ui/html";
import icon from "ui/component/icon";
import { clone, merge } from "util/object";
import { isPWA } from "util/pwa";
import { mapFilter } from "util/array";
import { isAnonymous } from "util/user";
import * as staticMenus from "./static-menus";

const MENU_ID = "main-menu";

/**
 * Handles closing the menu when the user clicks outside the menu area.
 *
 * @function clickOutSide
 * @private
 * @param {MouseClickEvent} event
 */
const clickOutSide = (event) => {
  if (!event.target.closest(".sidebar")) {
    const main = elId("main-menu");
    main.classList.toggle("active");
    document.removeEventListener("click", clickOutSide);
  }
};

/**
 * Closes all sub-menus.
 *
 * It's more convenient to just close any that are open than to
 * seek the parent.
 *
 * @function closeAllSubmenus
 * @private
 */
const closeAllSubMenus = (ev) => {
  if (ev) {
    // this may be called in a way that `ev` is an element, not an event
    // during keyboard navigation so stopPropagation is not always defined
    ev.stopPropagation?.();
  }
  [...document.querySelectorAll("nav .sub-menu.active")].forEach((el) => {
    el.classList.remove("active");
    el.blur();
  });
};

/**
 * The menu toggle button.
 *
 * @function menuToggle
 * @private
 */
const menuToggle = (event) => {
  event.stopPropagation();
  const main = elId("main-menu");
  const isToggled = main.classList.toggle("active");
  if (isToggled) {
    document.addEventListener("click", clickOutSide);
  } else {
    closeAllSubMenus();
    document.removeEventListener("click", clickOutSide);
  }
};

/**
 * Toggles a sub-menu open or closed. This is only used with
 * mobile / touch-input.
 *
 * In other cases hover/active/focus state determines whether
 * the menu is open.
 *
 * @function subMenuToggle
 * @private
 */
const subMenuToggle = (ev) => {
  ev.stopPropagation();
  ev.preventDefault();
  const submenu = ev.composedPath()
    .find((target) => target.classList?.contains("sub-menu"));
  submenu?.classList.toggle("active");
};

/**
 * sets the height of the pad element on submenus. Set up in a hook as an
 * event handler, since snabbdom doesn't support capture & once options.
 * This must happen while the main menu is displayed so that offsetTop can be
 * calculated correctly, so it fires on hover events.
 */
const setPad = (ev) => {
  const pad = ev.currentTarget.querySelector(".pad");
  if (pad) pad.style.height = `${ev.currentTarget.offsetTop}px`;
};

const getSubmenuOffsetClass = (event) => {
  event.stopPropagation();
  const sidebar = event.target.parentElement.querySelector(".sidebar");
  const dropdownRect = sidebar?.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  if (dropdownRect?.right > bodyRect.width) {
    sidebar?.classList.add("to-left");
  } else {
    sidebar?.classList.remove("to-left");
  }
};

const buildSubMenuEntries = (entries, horizontal, menuLevel) => div(
  ".sidebar",
  div(".list", [
    // used to push the menu up a little depending on parent position
    div(".pad"),
    /* eslint-disable-next-line no-use-before-define */
    ...entries.map((en) => buildMenuEntry(en, horizontal, menuLevel)),
    // close button (displayed in mobile views)
    div(
      ".close.item",
      a.fn(
        [icon.solid("long-arrow-alt-left"), "Back"],
        closeAllSubMenus,
        ".sub-menu-close",
      ),
    ),
  ]),
);

const buildDynamicEntry = (entry, horizontal, menuLevel = 0) => {
  const sel = `.item${entry.entries?.length ? ".sub-menu" : ""}`;
  let iconName = "";
  let config = {
    // explit string cast for menuLevel because 0 is falsey and will be omitted
    dataset: { menuItemType: entry.type, menuLinkType: entry.linkType, menuLevel: `${menuLevel}` },
  };

  const childEls = [];
  if (entry.icon) iconName = entry.icon;
  else {
    if (entry.linkType === menuLinkTypes.LINK_EXTERNAL) iconName = "link";
    if (entry.linkType === menuLinkTypes.LINK_PAGE) iconName = "file-alt";
    if (entry.linkType === menuLinkTypes.NONE) iconName = "bars";
  }

  const makeHooks = () => ({
    postpatch: (node) => {
      if (!horizontal) {
        node.elm.addEventListener("touchstart", setPad, { capture: true, once: true, passive: true });
        node.elm.addEventListener("mouseover", setPad, { capture: true, once: true, passive: true });
      }
    },
    insert: (node) => {
      if (horizontal) {
        node.elm.addEventListener("touchstart", getSubmenuOffsetClass, { capture: true, once: true, passive: true });
        node.elm.addEventListener("mouseover", getSubmenuOffsetClass, { capture: true, once: true });
      } else {
        node.elm.addEventListener("touchstart", setPad, { capture: true, once: true, passive: true });
        node.elm.addEventListener("mouseover", setPad, { capture: true, once: true });
      }
    },
  });

  const openButton = () => (entry.entries?.length
    ? a.fn(
      icon("angle-right"),
      subMenuToggle,
      ".sub-menu-open-button",
      { hook: makeHooks() },
    )
    : "");

  switch (entry.linkType) {
    case menuLinkTypes.LINK_EXTERNAL:
      childEls.push(a(
        [icon.sharp(iconName), span(entry.label), openButton()],
        entry.url,
        entry.entries?.length ? ".sub-menu-handle" : "",
        { attrs: { target: "_blank" } },
      ));
      break;
    case menuLinkTypes.LINK_PAGE:
      childEls.push(a(
        [icon.sharp(iconName), span(entry.label), openButton()],
        entry.slug,
        entry.entries?.length ? ".sub-menu-handle" : "",
      ));
      break;
    case menuLinkTypes.NONE:
      childEls.push(span(
        [
          icon.sharp(iconName),
          span(entry.label),
          openButton(),
        ],
        ".sub-menu-handle.no-link",
        {
          attrs: {
            tabIndex: 0,
          },
        },
      ));
      break;
    default:
      log.error("unsupported menu link type", entry.linkType);
      return "";
  }

  if (entry.entries?.length) {
    config = merge(config, {
      hook: makeHooks(),
    });
    childEls.push(buildSubMenuEntries(entry.entries, horizontal, menuLevel + 1));
  }

  return div(sel, config, childEls);
};

/**
 * Generates a single menu entry. Requires different handling for various
 * menu entry types.
 *
 * @function buildMenuEntry
 * @private
 * @param {MenuEntry} entry
 * @param {boolean} horizontal
 * @return {module:ui/common/el~El} `div.item`
 */
export const buildMenuEntry = (entry, horizontal, menuLevel = 0) => {
  if (entry.exclusive === menuEntryExclusiveTypes.WEB && isPWA()) {
    return "";
  }
  if (entry.exclusive === menuEntryExclusiveTypes.PWA && !isPWA()) {
    return "";
  }
  switch (entry.type) {
    case menuEntryTypes.ENTRY:
      return buildDynamicEntry(entry, horizontal, menuLevel);
    case menuEntryTypes.STATIC:
      return div(".item", a(
        [icon.sharp(entry.icon), entry.label],
        entry.slug,
      ));
    case menuEntryTypes.FUNCTION:
      return div(".item", a.fn(
        [icon.sharp(entry.icon), entry.label, entry?.counter ? span(entry.counter, ".counter") : ""],
        entry.cb,
      ));
    default:
      log.error("unsupported menu type", entry.type, entry);
      return "";
  }
};

/**
 * Generates menu items for a single group.
 *
 * The static menu groups include some properties for determining who
 * gets to see the entries in the group. We check them here.
 *
 * @function buildMenuItems
 * @private
 * @param {module:model/user~User} user
 * @param {object} set a [menu item group]{@link module:ui/component/header/static-menus.MenuGroup}
 * @returns {Array.<module:ui/common/el~El>} array of `div.item` menu items
 */
const buildMenuItems = (user, horizontal) => (set) => {
  if (user.userType !== userTypes.ROOT) {
    if (!user && set.accountRequired && !isAnonymous(user)) return [];
    if (set?.privileges) {
      if (!set.privileges.find((priv) => userInGroupName(user, priv))) return [];
    }
  }
  const items = set?.entries
    // the counts used below are to determine the position of the sub-menu
    // in the list of all items
    .map((item) => buildMenuEntry(item, horizontal));
  return items;
};

/**
 * Builds a collection of menu entries from a collection of menu entry groups.
 *
 * Inserts an `HR` element between each non-empty group.
 *
 * @function combineMenuSets
 * @private
 * @param {module:model/user~User} user
 * @param {Array.<object>} sets collection of
 *        [menu item groups]{@link module:ui/component/header/static-menus.MenuGroup}
 * @param {boolean} horizontal
 * @returns {Array.<module:ui/common/el~El>} array of `div.item` menu items
 */
const combineMenuSets = (user, sets, horizontal) => {
  const groups = sets.map(buildMenuItems(user, horizontal));
  const out = [];
  groups.forEach((group, i) => {
    if (!group) return;
    if (group.length) {
      out.push(group);
      if (i < (groups.length - 1) && !horizontal) out.push(hr());
    }
  });
  return horizontal ? [
    ...out.flat(),
    div(
      ".menu-hamburger.item.sub-menu",
      [
        span([icon.sharp("ellipsis-v")], ".sub-menu-handle"),
        div(".sidebar", [
          div(".list"),
        ]),
      ],
    ),
  ] : out.flat();
};

const processHorizontalItems = (node) => {
  let availableMenuWidth = (document.getElementById(MENU_ID)?.offsetWidth || 0) - 100;
  const menuItems = node.children.filter((child) => child.sel.includes("div.item"));
  const hiddenItems = [];
  menuItems.forEach((item) => {
    const itemWidth = item.elm.offsetWidth;
    if (!itemWidth) {
      return;
    }
    if (itemWidth < availableMenuWidth) {
      availableMenuWidth -= itemWidth;
    } else {
      hiddenItems.push(item);
    }
  });
  const hamburger = document.querySelector(".menu-hamburger .list");
  hiddenItems.forEach((item) => {
    item.elm.remove();
    hamburger.appendChild(item.elm);
    item.elm.classList.add("item-hamburger");
    item.elm.addEventListener("click", () => {
      item.elm.classList.toggle("opened");
    });
  });
  if (!hiddenItems.length) {
    document.querySelector(".menu-hamburger").classList.add("hidden");
    document.querySelector(".menu-hamburger").parentElement.classList.add("space-evenly");
  } else {
    document.querySelector(".menu-hamburger").parentElement.classList.remove("space-evenly");
  }
};

/**
 * Builds the sidebar nav menu.
 *
 * @function menu
 * @param {module:model/user~User} user
 * @param {module:ui/common/html~ChildEl} entries menu entries (should be `div.item`)
 * @param {boolean} horizontal horizontal mode
 * @return {module:ui/common/el~El} `nav#main-menu`
 */
const menu = (entries = [], horizontal = false) => nav(
  `#main-menu${horizontal ? ".active" : ""}`,
  [
    button(
      [icon.sharp("bars", ".on"), icon.sharp("xmark", ".off")],
      menuToggle,
      ".sidebar-toggle",
      "button",
      "navigation menu",
    ),
    div(".sidebar", [
      div(".list", {
        hook: {
          insert: (node) => {
            if (document.body.clientWidth >= BREAKPOINT_SMALL && horizontal) {
              processHorizontalItems(node);
            }
          },
        },
      }, horizontal ? [...entries] : [
        hr(),
        ...entries,
      ]),
    ]),
  ],
);

const filterDynamicEntries = (tree, pages) => {
  if (!tree.entries.length) return tree;
  const filtered = tree.entries.reduce(mapFilter(
    (entry) => {
      const out = clone(entry);
      if (
        entry.linkType === menuLinkTypes.LINK_PAGE
        && (!pages.has(entry.pageId) || pages.get(entry.pageId)?.PLACEHOLDER)
      ) {
        return null;
      }
      if (entry.entries?.length) out.entries = filterDynamicEntries(entry, pages)?.entries;

      return out;
    },
    (e) => e !== null,
  ), []);
  return merge(tree, { entries: filtered });
};

/**
 * Creates the menu for the main site.
 *
 * @function user
 * @param {object} state
 * @param {module:model/user~User} state.user
 * @param {boolean} horizontal horizontal mode
 * @return {module:ui/common/el~El} `nav#main-menu`
 */
menu.user = (state) => {
  let validHorizontal = state.horizontal;
  if (document.body.clientWidth < BREAKPOINT_SMALL) {
    validHorizontal = false;
  }
  return menu(
    combineMenuSets(state.user, [
      staticMenus.siteGeneral,
      staticMenus.siteAdmin,
      filterDynamicEntries(state.menu, state.pages),
    ], validHorizontal),
    validHorizontal,
  );
};

/**
 * Creates the menu for the admin site.
 *
 * @function admin
 * @param {module:model/user~User} user
 * @return {module:ui/common/el~El} `nav#main-menu`
 */
menu.admin = (user) => menu(
  combineMenuSets(user, [
    staticMenus.siteAdminGeneral,
    staticMenus.siteAdminSocial,
    staticMenus.siteAdminUsers,
    staticMenus.siteAdminContent,
    staticMenus.siteAdminCourse,
    staticMenus.siteAdminAssessments,
    staticMenus.siteAdminEvaluations,
    staticMenus.siteAdminSite,
  ]),
);

export default menu;
