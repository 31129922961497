/**
 * Editor fields for text sections.
 *
 * @module ui/component/modal/section-edit/text
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import title from "ui/component/form-managed/field-title";
import description from "ui/component/form-managed/field-description";
import toggle from "ui/component/form-managed/field-toggle";
import textarea from "ui/component/form-managed/field-textarea";

/**
 * @function sliderFields
 * @private
 *
 * @param self
 * @return {module:ui/html~ChildEl[]}
 */
export default (self) => {
  const { section } = self.state;
  return self.bind([
    [title, { value: section.title }],
    [textarea, { name: "leftSideText", label: "Text Section 1", value: section.searchData.leftSideText }],
    [textarea, { name: "rightSideText", label: "Text Section 2", value: section.searchData.rightSideText }],
    [description, { label: "Text Section 3", value: section.text }],
    [toggle.boxed, {
      toggled: section.searchData?.collapsible || false,
      name: "collapsible",
      label: "Collapsible",
      sel: ".inverse",
    }],
  ]);
};
