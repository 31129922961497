/**
 * A cameo for list-select dialog items.
 */
import cameo from "ui/component/cameo";

export default function cameoListSelectItem({
  label = "list item",
  labelId = null,
  key = null,
  selected = false,
  faded = false,
  onSelect = null,
  icon = null,
  sel = "",
  controls = [],
}) {
  return cameo({
    sel: `${sel}.cameo-list-select-item${selected ? ".selected" : ""}${faded ? ".faded" : ""}`,
    label,
    icon,
    controls,
    config: {
      dataset: labelId !== null ? { labelId } : {},
      on: onSelect ? {
        click: () => onSelect(),
      } : {},
      key: key !== null ? key : undefined,
    },
  });
}
