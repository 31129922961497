/**
 * A modal for selection DM thread.
 *
 * @module ui/component/modal/messaging/dm-thread-select
 * @component UI
 * @subcomponent Modal Dialogs
 */
import { merge, frozen } from "util/object";
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, span } from "ui/html";
import cameoUser from "ui/component/cameo-user";
import button from "ui/component/form-managed/button";
import userSelectModal from "ui/component/modal/user-select";
import view from "ui/view";

let storedView;

const defaultState = frozen({
  users: [],
  userOne: null,
  userTwo: null,
});

const removeUser = (state, userProp, modalView) => {
  // eslint-disable-next-line no-use-before-define
  modalView.patch(dmThreadSelectModal({
    ...state,
    [userProp]: null,
  }, modalView));
};

const selectUser = async (state, userProp, modalView) => {
  const user = await modalView.async(userSelectModal({ users: state.users }, modalView));
  // eslint-disable-next-line no-use-before-define
  modalView.patch(dmThreadSelectModal({
    ...state,
    [userProp]: user,
  }, modalView));
};

const bindView = (modalView) => {
  const element = document.createElement("div");
  element.id = "dm-thread-select";
  modalView.element.querySelector(".body")
    .replaceChildren(element);
  storedView.rebind("#dm-thread-select");
};

const buildView = (modalView) => (self) => {
  const { state } = self;
  return div("#dm-thread-select", [
    state.userOne
      ? cameoUser({
        user: state.userOne,
        controls: [
          button.icon({
            icon: "trash-alt",
            onClick: () => removeUser(state, "userOne", modalView),
            sel: ".danger",
          }),
        ],
      },
      modalView)
      : button.standIn({
        icon: "user",
        label: "Select First User",
        onClick: () => selectUser(state, "userOne", modalView),
      }),

    state.userTwo
      ? cameoUser({
        user: state.userTwo,
        controls: [
          button.icon({
            icon: "trash-alt",
            onClick: () => removeUser(state, "userTwo", modalView),
            sel: ".danger",
          }),
        ],
      },
      modalView)
      : button.standIn({
        icon: "user",
        label: "Select Second User",
        onClick: () => selectUser(state, "userTwo", modalView),
      }),

    button.primary({
      label: "Open Thread",
      disabled: !state.userOne || !state.userTwo,
      onClick: () => {
        modalView.resolve({
          userOneId: state.userOne.id,
          userTwoId: state.userTwo.id,
        });
      },
    }),
  ]);
};

const buildBody = (state, modalView) => {
  if (storedView) {
    bindView(modalView);
    return;
  }
  const element = document.createElement("div");
  element.id = "dm-thread-select";
  modalView.element.querySelector(".body")
    .replaceChildren(element);
  storedView = view.create(buildView(modalView))("#dm-thread-select", state);
};

/**
 * A modal for selection DM thread.
 *
 * @function dmThreadSelectModal
 * @param {Object} inState
 * @param {Object} modalView
 */
export default function dmThreadSelectModal(inState, modalView = null) {
  storedView = null;
  const state = merge(defaultState, inState);

  return dialog({
    sel: ".dm-thread-select",
    header: [
      h3("Select two users"),
      span("Please select the two users to view their DM thread profile."),
    ],
    body: div(".body"),
    config: {
      hook: {
        insert: () => buildBody(state, modalView),
        postpatch: () => buildBody(state, modalView),
      },
    },
  }, modalView);
}
