/**
 * A carousel of content cards for metadata items.
 *
 * @module ui/component/card-carousel
 * @category UI
 * @subcategory Components
 */
import {
  div,
  h2,
  p,
  section,
  span,
} from "ui/html";
import placeholder from "ui/component/placeholder";
import card from "ui/component/card/content";
import placeholderCard from "ui/component/card/placeholder";
import icon from "ui/component/icon";
import { emit } from "util/event";
import hashmap from "util/hash-map";
import { frozen, merge } from "util/object";
import { cardFormatOptions } from "model/dynamic-page/constants";
import { metadataTypes } from "model/metadata/constants";
import courseItemCard from "ui/component/card/course";

const leftArrow = (state) => span(
  icon.sharp("arrow-left"),
  ".fab.prev",
  {
    on: {
      click: () => emit(
        document.body,
        "em:carousel-scroll",
        { ...state, position: Math.max(0, state.position - 1) },
      ),
    },
  },
);

const rightArrow = (state) => span(
  icon("arrow-right"),
  ".fab.next",
  {
    on: {
      click: () => emit(
        document.body,
        "em:carousel-scroll",
        { ...state, position: Math.min(state.list.items.length, state.position + 1) },
      ),
    },
  },
);

/**
 * Insert/update hook
 *
 * Elements can't be measured until after they're rendered to DOM, so this
 * insert/update hook runs afterward to calculate correct scroll offsets and
 * determine whether to show the left/right buttons.
 */
const onRender = (list, position, vnode) => {
  const cards = vnode.elm.querySelector(".cards");
  const sample = vnode.elm.querySelector(".cards .content-card:first-of-type");
  const cardWidth = sample.clientWidth;
  const margin = sample.offsetLeft;
  const containerWidth = cards.clientWidth; // - (cards.clientWidth % cardWidth);
  const outerWidth = (cardWidth + (margin * 2));
  const cardsPerPage = Math.floor(containerWidth / outerWidth);
  const lastCard = Math.max(0, (list.items.length - cardsPerPage) + 1);
  const targetCard = vnode.elm
    .querySelector(`.content-card:nth-of-type(${position + 1})`);

  if (position <= 0) vnode.elm.querySelector(`.fab.prev`).classList.add("disabled");
  else vnode.elm.querySelector(`.fab.prev`).classList.remove("disabled");
  if (position === (lastCard - 1) || cardsPerPage >= list.items.length) {
    vnode.elm.querySelector(`.fab.next`).classList.add("disabled");
  } else vnode.elm.querySelector(`.fab.next`).classList.remove("disabled");

  if (
    targetCard
    && position >= 0
    && position <= lastCard
  ) {
    let left = 0;
    if (position > 0) left = targetCard.offsetLeft - margin;
    cards.scrollTo({ left });
  }
};

const defaultState = frozen({
  list: {
    id: null,
    type: null,
    items: [],
    playback: hashmap(),
    cardSize: cardFormatOptions.VERTICAL,
  },
  position: 0,
});

/**
 * @function cardCarousel
 * @param {Object} state
 * @param {module:api/types~MetadataListItem} state.list
 * @param {Number} state.position the left-most card to scroll to
 * @param {function} [state.cardFactory=ContentCard] which factory to use for cards
 * @emits "em:carousel-scroll" when a scroll arrow is clicked (must be handled by view)
 * @return {module:ui/common/el~El}
 */
export default function cardCarousel(inState) {
  const state = merge(defaultState, inState);
  const { list, position } = state;
  const cardFactory = state.cardFactory || card;
  if (list?.items?.length) {
    const carousel = section(
      ".content-card-carousel",
      {
        key: list.id,
        hook: {
          insert: (vnode) => onRender(list, position, vnode),
          update: (_, node) => onRender(list, position, node),
        },
      },
      [
        list.title ? h2(list.title) : "",
        leftArrow(state),
        div(".cards", [
          ...list.items.map(
            (item) => {
              if (item.PLACEHOLDER) {
                return placeholderCard();
              }
              if (item.type === metadataTypes.COURSE) {
                return courseItemCard({ metadata: item, cardSize: list?.cardSize });
              }
              return cardFactory({
                metadata: item,
                playbackPosition: list?.playback?.get?.(item.id) || null,
                cardSize: list?.cardSize,
              });
            },
          ),
          span("", ".endcap"),
        ]),
        rightArrow(state),
      ],
    );
    return carousel;
  }
  if (list.text) {
    return section(".content-card-carousel.empty", [
      list.title ? h2(list.title) : "",
      p(list.text),
    ]);
  }

  return "";
}

cardCarousel.placeholder = (sel = "", key = undefined) => section(
  `${sel}.content-card-carousel`,
  key ? { key } : {},
  [
    h2(placeholder()),
    div(".cards", [
      placeholderCard(),
      placeholderCard(),
      placeholderCard(),
      placeholderCard(),
      placeholderCard(),
    ]),
  ],
);
