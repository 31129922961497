/**
 * @template T
 * @typedef Pageable
 * @property {number} currentPage
 * @property {number} totalPages
 * @property {number} itemsPerPage
 * @property {T[]} items
 */

/**
 * Converts page response to pageable model
 *
 * @param response
 * @param {number} response.currentPage
 * @param {number} response.totalPages
 * @param {number} response.itemsPerPage
 * @param {string} itemsFieldName
 * @returns Pageable
 */
// eslint-disable-next-line import/prefer-default-export
export const responseToPageable = (response, itemsFieldName = "") => ({
  currentPage: response.currentPage,
  totalPages: response.totalPages,
  itemsPerPage: response.itemsPerPage,
  items: response[itemsFieldName] || [],
});
