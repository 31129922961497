/**
 * A modal for doing advanced message searches
 *
 * @module ui/component/chat/advanced-message-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import { merge } from "util/object";
import dialog from "ui/component/modal/layout/dialog";
import { div, h3, p } from "ui/html";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import input from "ui/component/form-managed/field-input";
import dateTime from "ui/component/form-managed/field-date-time";
import button from "ui/component/form-managed/button";
import toggle from "ui/component/form-managed/field-toggle";
import cameoUser from "ui/component/cameo-user";
import chatUserListModal from "ui/component/modal/chat/chat-user-list";

let count = 0;
let storedFormView;

const defaultState = {
  selectedUser: null,
  users: [],
};

const bindForm = (modalView) => {
  const formEl = document.createElement("FORM");
  formEl.id = "message-search";
  modalView.element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#message-search");
};

const removeUser = () => {
  storedFormView.update({
    selectedUser: null,
  });
};

const selectUser = async (state, modalView) => {
  const selectedUser = await modalView.async(chatUserListModal({
    users: state.users,
    selectable: true,
  }, modalView));
  if (selectedUser) {
    storedFormView.update({
      selectedUser,
    });
  }
};

const buildForm = (modalView) => (self) => form(
  `#message-search`,
  [
    ...self.bind([
      [input, { label: "Message Text", name: "searchString" }],
      p("Start Date"),
      [dateTime, { name: "startDate" }],
      p("End Date"),
      [dateTime, { name: "endDate" }],
      self.state.selectedUser
        ? cameoUser({
          user: self.state.selectedUser,
          controls: [
            button.icon({
              icon: "trash-alt",
              onClick: () => removeUser(),
              sel: ".danger",
            }),
          ],
        },
        modalView)
        : button.standIn({
          icon: "user",
          label: "Select User",
          onClick: () => selectUser(self.state, modalView),
        }),
      [toggle.boxed.inverse, { label: "Attachments", name: "hasAttachments" }],
      self.values.hasAttachments
        ? [input, { label: "Attachment Name", name: "attachmentName" }]
        : "",
    ]),
  ],
);

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  inner.patch(inner.factory(inner));
};

const initForm = (state, modalView) => {
  if (storedFormView) {
    bindForm(modalView);
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "message-search";
  modalView.element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView = formManagedView(
    buildForm(modalView),
    onFormUpdate,
  )(
    "#message-search",
    state,
  );
};

const onSearch = (modalView) => {
  const { values, state } = storedFormView;
  storedFormView.setFullValidation(true);
  const validation = storedFormView.validate(true);
  if (!validation.valid) {
    return;
  }
  modalView.resolve({
    startDate: values.startDate,
    endDate: values.endDate,
    searchString: values.searchString,
    params: {
      userId: state.selectedUser?.id,
      hasAttachments: values.hasAttachments,
      attachmentName: values.attachmentName,
    },
  });
};

export default function advancedMessageSearch(inState = {}, modalView = null) {
  if (!modalView) throw new Error("modalView is required in advancedMessageSearch");
  storedFormView = null;
  const state = merge(defaultState, inState);
  return dialog({
    sel: ".advanced-message-search",
    config: {
      hook: {
        insert: () => initForm(state, modalView),
        postPatch: () => initForm(state, modalView),
      },
      dataset: { advancedSearchId: (++count) },
    },
    header: h3("Advanced message search"),
    body: form("#message-search"),
    footer: div(".buttons", { attrs: { role: "group" } }, [
      button.primary({
        label: "Search",
        onClick: () => onSearch(modalView),
      }),
    ]),
  }, modalView);
}
