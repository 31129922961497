/**
 * A message component for displaying on manage messaging UI
 *
 * @module ui/component/messaging/message
 * @category Pages
 * @subcategory Admin - Messages
 */
import { div, span } from "ui/html";
import { merge } from "util/object";
import buttonGroup from "ui/component/button-group";
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import icon from "ui/component/icon";
import { usFullDateFormat } from "util/date";
import getAvatar from "ui/component/messaging/avatar";
import getUsername from "ui/component/messaging/username";
import messageAttachmentPreview from "ui/component/chat/message-attachment-preview";
import { getModal } from "ui/view/modal-view";
import reportListModal from "ui/component/modal/messaging/report-list";
import { takeActionOnReportedMessage } from "ui/page/admin/messaging/common";

const defaultState = {
  message: null,
  users: [],
  groups: [],
  controls: [],
  onClick: null,
  reports: [],
  descriptors: new Map(),
};

const getUserFrom = (state) => state.users.find((u) => u.id === state?.message?.from);
const getUserTo = (state) => state.users.find((u) => u.id === state?.message?.to);
const getGroup = (state) => state.groups.find((g) => g.id === state?.message?.to);
const getGroupName = (group) => systemPrivilegeGroupsFriendly.get(group?.name)
  || group?.name
  || "";

const hasReports = ({ reports }) => !!reports?.length;

const openReport = (e, { reports, users }) => {
  e.stopPropagation();
  if (reports.length === 1) {
    takeActionOnReportedMessage(reports[0], users);
  } else {
    getModal().async(reportListModal({ reports, users }));
  }
};

export default function message(inState, modalView) {
  const state = merge(defaultState, inState);
  const { fromUser, toUser, toGroup, superReply } = state.message;
  let toName;
  if (getGroup(state)) {
    toName = getGroupName(toGroup || getGroup(state));
  } else {
    toName = getUsername(toUser || getUserTo(state), true);
  }
  return div(
    `.admin-message-display${state.onClick ? ".clickable" : ""}${superReply ? ".super-reply" : ""}${hasReports(state) ? ".has-reports" : ""}`,
    {
      on: {
        click: () => state?.onClick?.(),
      },
    },
    [
      div(".message-header", [
        div(
          ".message-header-left",
          superReply
            ? div(".super-reply-username", "Moderator")
            : [
              div(".chat-avatar", getAvatar(fromUser || getUserFrom(state))),
              div(".username", getUsername(fromUser || getUserFrom(state), true)),
              div(".users-splitter", "to"),
              div(
                ".chat-avatar",
                getAvatar(toUser || getUserTo(state) || getGroup(state)) || span(icon("users"), ".avatar-group"),
              ),
              div(".username", toName),
            ],
        ),
        div(".message-header-right", [
          div(".date", usFullDateFormat(state.message.created)),
        ]),
      ]),
      div(".message-body", [
        div(".text", state.message.text),
      ]),
      div(
        ".chat-attachments",
        state.message?.attachments?.map((attachment) => (state.descriptors?.get(attachment)
          ? messageAttachmentPreview({
            attachment: state.descriptors.get(attachment),
          }, modalView)
          : "")),
      ),
      buttonGroup([
        ...state.controls,
      ]),
      hasReports(state)
        ? div(
          ".reports-container",
          {
            on: {
              click: (e) => openReport(e, state),
            },
          },
          [
            state.reports.length > 1 ? span(state.reports.length) : "",
            icon("flag-alt"),
          ])
        : "",
    ],
  );
}
