/**
 * Renders the course-overview section type for dynamic pages.
 *
 * @module ui/page/dynamic/section-course-overview
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import {
  div,
  h2,
  p,
  section,
  span,
} from "ui/html";
import button from "ui/component/form-managed/button";
import placeholder from "ui/component/placeholder";
import { splitParagraphs } from "util/format";
import {
  courseStartDate,
  courseEndDate,
} from "util/course";
import {
  datesEquivalent,
  MAX_DATE,
  MIN_DATE,
  monthDayYear,
  usFullDateFormat,
  usHourMinute,
} from "util/date";
import { emit } from "util/event";
import { getAttendance } from "./common";

const buildDate = (label, date) => (
  div(".date.course-meta-block", [
    span(label, ".label"),
    date === null
    || datesEquivalent(date, MAX_DATE)
    || datesEquivalent(date, MIN_DATE)
      ? span("Not Indicated", ".value")
      : span(usFullDateFormat(date), ".value"),
  ])
);

const buildEnrollment = (course, state) => {
  const attendance = getAttendance(state, course.id);
  return div(".enrollment.course-meta-block", [
    attendance
      ? span([
        span(
          button.primary({
            icon: "check-circle",
            label: "Enrolled",
            disabled: true,
          }),
          ".value",
        ),
        div(".value.date", monthDayYear(attendance.creationDateTime)),
        div(".value.date", usHourMinute(attendance.creationDateTime)),
      ])
      : span(
        button.primary({
          icon: "school",
          label: "Enroll",
          onClick: () => {
            emit(document.body, "em:course-enroll", { course });
          },
        }),
        ".value",
      ),
  ]);
};

const collectInstructors = (course) => {
  const instructors = new Set();
  // eslint-disable-next-line no-restricted-syntax
  for (const module of course.modules) {
    // eslint-disable-next-line no-restricted-syntax
    for (const lecture of module.lectures) {
      if (lecture.instructor) {
        instructors.add(`${lecture.instructor.firstName} ${lecture.instructor.lastName}`);
      }
    }
  }
  return [...instructors].filter((i) => i.trim().length);
};

const buildInstructors = (instructors) => (instructors.length
  ? div(
    ".instructor.course-meta-block",
    [
      span(`Instructor${instructors.length > 1 ? "s" : ""}`, ".label"),
      span(span(instructors.join(", "), "", { attrs: { title: instructors.join(", ") } }), ".value"),
    ],
  )
  : "");

const buildModules = (course, courseModules) => {
  const modulesAmount = course.moduleIds.reduce((accumulator, moduleId) => {
    const hasLectures = !!courseModules?.get(moduleId)?.lectureIds?.length;
    return hasLectures ? accumulator + 1 : accumulator;
  }, 0);
  return div(
    ".modules.course-meta-block",
    [span("Modules", ".label"), span(modulesAmount, ".value")],
  );
};

const fakeModule = { lectureIds: [], assessmentIds: [] };

const countLectures = (course, state) => course.moduleIds
  .map((id) => state.courseModules.get(id) || fakeModule)
  .reduce((acc, mod) => acc + (mod.lectureIds?.length || 0), 0);

const countAssessments = (course, state) => course.moduleIds
  .map((id) => state.courseModules.get(id) || fakeModule)
  .reduce((acc, mod) => acc + mod.assessmentIds.length, 0);

const buildLectures = (course, state) => div(
  ".lectures.course-meta-block",
  [span("Lectures", ".label"), span(countLectures(course, state), ".value")],
);

const buildAssessments = (course, state) => {
  const count = countAssessments(course, state);
  if (count < 1) return "";
  return div(
    ".assessments.course-meta-block",
    [span("Assessments", ".label"), span(count, ".value")],
  );
};

const checkReady = (course, state) => (
  course.moduleIds?.reduce((acc, id) => acc && state.courseModules.has(id), true)
);

const buildMetaPlaceholder = () => div(".course-meta", [
  div(".instructor.course-meta-block", placeholder()),
  div(".date.course-meta-block", placeholder()),
  div(".date.course-meta-block", placeholder()),
  div(".modules.course-meta-block", placeholder()),
  div(".assessments.course-meta-block", placeholder()),
  div(".enrollment.course-meta-block", placeholder()),
]);

const buildPlaceholder = () => section(".course-overview.not-enrolled", [
  h2(placeholder(), ".course-title"),
  p(placeholder()),
  buildMetaPlaceholder(),
]);

export default function sectionCourseOverview(state, id) {
  const pageSection = state.sections.get(id);
  if (!pageSection || pageSection.PLACEHOLDER) return buildPlaceholder();
  const course = state.courses?.get(pageSection.itemId);
  if (!course || course.PLACEHOLDER) return buildPlaceholder();
  const attendance = getAttendance(state, course.id);
  const startDate = courseStartDate(course);
  const endDate = courseEndDate(course);
  const metaReady = checkReady(course, state);
  if (!metaReady) return buildPlaceholder();
  return section(
    `#course-overview-${id}.course-overview${attendance ? ".enrolled" : ".not-enrolled"}`,
    { key: `course-overview-${id}` },
    [
      course.title ? h2(course.title, ".course-title") : "",
      ...splitParagraphs(course?.description).map((para) => p(para, ".course-description")),
      div(".course-meta", [
        buildInstructors(collectInstructors(course)),
        buildDate("Start Date", startDate),
        buildDate("End Date", endDate),
        buildModules(course, state.courseModules),
        buildLectures(course, state),
        buildAssessments(course, state),
        buildEnrollment(course, state),
      ]),
    ],
  );
}
