/**
 * Utility functions related to DOM manipulation and traversal.
 *
 * @module util/dom
 * @category Utilities
 */
export const BREAKPOINT_SMALL = 768;

/**
 * A shortcut for getting element by id, as `document.getElementById`
 *
 * @function elId
 * @param {string} id an element id
 * @return {?HTMLElement}
 */
export const elId = document.getElementById.bind(document);

/**
 * A shortcut for `document.querySelector`
 *
 * @param {string} selector a css-like selector
 * @return {?HTMLElement}
 */
export const qs = document.querySelector.bind(document);

/**
 * A shortcut for `document.querySelectorAll`
 *
 * @param {string} selector a css-like selector
 * @return {?HTMLElement}
 */
export const qsa = document.querySelectorAll.bind(document);

/**
 * Hack to escape HTML entities. Probably has some ugly edge cases. Avoid.
 *
 * @function txt
 * @deprecated just a temporary hack
 * @param {string} string a string possibly containing html entities
 * @return {string} an escaped string
 */
export const txt = (string) => {
  const tmp = document.createElement("div");
  tmp.append(document.createTextNode(string));
  return tmp.innerHTML;
};

/**
 * Hack for quickly parsing HTML text into a DOM tree.
 *
 * @function parseHTML
 * @deprecated an old hack used while transitioning from the prototype system
 * @param {string} html
 * @return {HTMLElement[]}
 */
export const parseHTML = (html) => {
  const template = document.createElement("template");
  template.innerHTML = html.trim();
  return template.content.childNodes;
};

/**
 * A hack for parsing a string into an HTML element. Like {@link parseHTML},
 * but expects a single element as a root node.
 *
 * @function parseElement
 * @deprecated an old hack used while transitioning from the prototype system
 * @param {string} html
 * @return {HTMLElement}
 */
export const parseElement = (html) => {
  const parsed = parseHTML(html);
  if (parsed.length > 1) throw new Error("parseElement: more than one element given");
  return parsed[0];
};

/**
 * Checks if current window is opened in an iframe
 *
 * @returns {boolean}
 */
export const isInIframe = () => window.self !== window.top;
