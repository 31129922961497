/**
 * The branding, stored in the site-config table.
 *
 * @module model/site/branding
 * @category Model
 * @subcategory Site
 */
import { BRANDING_KEY } from "./constants";
import { validInt, validObject } from "../constraints";

/**
 * Branding object.
 *
 * @typedef Branding
 * @property {object} images
 * @property {int} version version number (for potential future migration)
 */

/**
 * Maps an API response to a Branding
 * @function responseToBranding
 * @param {object} response
 * @return {Branding}
 */
export const responseToBranding = (response) => ({
  version: parseInt(response.version, 10),
  images: response.images,
});

/**
 * Validates branding for storage in the site config database.
 */
export const makeBrandingDTO = (partial) => ({
  [BRANDING_KEY]: JSON.stringify({
    version: validInt(partial.version, "Branding.version"),
    images: validObject(partial.images, "Branding.images"),
  }),
});
