/**
 * Utility functions for the course API.
 *
 * @module api/course/cache
 * @private
 * @category Backend API
 * @subcategory Course
 */
import { questionType } from "model/course/constants";

/**
 * Search assessment by id in a course
 *
 * @function findAssessmentInCourse
 * @private
 * @param {Course} course
 * @param {string} assessmentId
 * @returns Assessment
 */
export const findAssessmentInCourse = (course, assessmentId) => course?.modules
  .map((module) => module.assessments)
  .flat()
  .find((assessment) => assessment.id === assessmentId);

/**
 * Search question by id in a course
 *
 * @function findQuestionInCourse
 * @private
 * @param {Course} course
 * @param {string} questionId
 * @returns {Question}
 */
export const findQuestionInCourse = (course, questionId) => {
  const allCourseQuestions = course?.modules
    .map((module) => module.assessments)
    .flat()
    .map((assessment) => assessment.questions)
    .flat();
  return allCourseQuestions?.find((question) => questionId === question.id);
};

/**
 * Find question related to a given evaluation solution in an assessment.
 *
 * Handles missing questions by trying to match an existing question by index, or provides
 * a default value failing that.
 *
 * @function findQuestionInAssessment
 * @private
 * @param {Course} course
 * @param {string} questionId
 * @returns {Question}
 */
export const findQuestionInAssessment = (
  assessment,
  solution,
  index,
) => {
  const { questionId } = solution;
  let found = assessment?.questions.find((q) => q.id === questionId);
  if (!found) {
    found = assessment?.questions[index];
    if (found) {
      found.type = questionType.SUBSTITUTED;
    } else {
      found = {
        id: questionId,
        title: null,
        weight: 0,
        randomizeAnswers: false,
        type: questionType.MISSING,
        text: "???",
        answers: solution.value.map((value) => ({ value, correct: false })),
      };
    }
  }
  return found;
};
