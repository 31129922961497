/**
 * A toggle element supporting multiple labeled states.
 *
 * @module ui/component/multi-toggle
 * @deprecated use {@link module:ui/component/form-managed/field-multi-toggle}
 * @category UI
 * @subcategory Components
 */
/***/
import {
  div,
  span,
  input,
  p,
} from 'ui/html';
import { merge } from "util/object";
import { emit } from "util/event";

const getTabsContainer = (elm) => {
  if (elm.classList.contains("tab-container")) return elm;
  if (elm.parentElement) return getTabsContainer(elm.parentElement);
  return null;
};

const selectTab = (name, value, index) => (e) => {
  [...document.querySelectorAll(`[name="${name}"]`)].forEach((el) => {
    /* eslint-disable-next-line no-param-reassign */
    el.checked = false;
  });
  document.querySelector(`[name="${name}"][value="${value}"]`).checked = true;
  const container = getTabsContainer(e.currentTarget);
  if (!container) return;
  container.dataset.selectedTab = index;
  emit(container, "em:tab-change", { index });
};

const notch = (onSelect) => (option, index) => {
  const { label, name, value } = option;
  return span(
    label,
    ".notch",
    {
      dataset: { tabId: `${index}` },
      on: {
        click: (e) => {
          selectTab(name, value, index)(e);
          if (onSelect) onSelect(option, index);
        },
      },
    },
  );
};

const buildRadio = (selectedTab) => ({ name, id, value }, index) => input.radio(
  selectedTab === index,
  false,
  name,
  value,
  { attrs: { id } },
);

/**
 * @function multiToggle
 * @param {object} state
 * @param {string} state.label label for the entire toggle element
 * @param {object[]} state.options options to be displayed on the toggle
 * @param {string} state.options[].label label for the option
 * @param {string} state.options[].name name property for the option
 * @param {string} state.options[].value value property for the option
 * @param {number} [selectedTab=0] index of the currently selected option
 * @param {function} onSelect callback function called when the user makes a selection
 * @return {module:ui/html/div} `div.tab-container`
 */
const multiToggle = ({
  label,
  options,
  onSelect = null,
  selectedTab = 0,
}) => div(
  '.tab-container.multi-toggle',
  merge(
    {
      dataset: {
        tabCount: `${options.length}`,
        selectedTab,
      },
    },
    {},
  ),
  [
    div('.notches-wrapper', [
      p(label),
      div(".notches", options.map(notch(onSelect))),
      div('.radio-notches-wrapper', options.map(buildRadio(selectedTab))),
    ]),
  ],
);

export default multiToggle;
