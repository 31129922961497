import icon from "ui/component/icon";
import {
  pageTypes,
  pageTypesFriendly,
} from "model/dynamic-page/constants";

const makeIcon = (name, type) => icon.solid(
  name,
  ".section-icon",
  {
    prop: { title: pageTypesFriendly.get(type) },
  },
);

export default function sectionIcon(section) {
  const { type } = section;
  switch (type) {
    case pageTypes.EMBEDDED_ITEM:
      return makeIcon("photo-video", type);
    case pageTypes.IMAGE_SLIDER:
      return makeIcon("images", type);
    case pageTypes.FAQ:
      return makeIcon("question-circle", type);
    case pageTypes.TEXT:
      return makeIcon("text", type);
    case pageTypes.TEXT_HEADER:
      return makeIcon("heading", type);
    case pageTypes.RICH_TEXT:
      return makeIcon("paragraph", type);
    case pageTypes.PLAYLIST_CAROUSEL:
      return makeIcon("film", type);
    case pageTypes.DYNAMIC_LIST_CAROUSEL:
      return makeIcon("magnifying-glass", type);
    case pageTypes.PLAYLIST_GRID:
      return makeIcon("border-all", type);
    case pageTypes.DYNAMIC_LIST_GRID:
      return makeIcon("border-none", type);
    case pageTypes.COURSE_OVERVIEW:
      return makeIcon("book", type);
    case pageTypes.COURSE_MODULE_CAROUSEL:
      return makeIcon("books", type);
    case pageTypes.COURSE_MODULE_GRID:
      return makeIcon("books", type);
    default:
      return icon.solid(
        "question-circle",
        ".section-icon",
        { props: { title: "Unknown Section Type" } },
      );
  }
}
