/**
 * Defaults and utilities for user records.
 *
 * @module ui/page/admin/user/bulk/user-record
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { makeUser } from "model/user";
import { uuidv4 } from "util/generator";

/**
 * Populates a partial user record with defaults for empty fields.
 */
/* eslint-disable-next-line import/prefer-default-export */
export const makeUserRecord = (partial = {}) => ({
  // usernames and groups are handled differently when parsed from CSV
  ...makeUser({ ...partial }),
  username: partial.username || "",
  password: partial.password || "",
  enabled: !!partial.enabled,
  tempId: partial.id || uuidv4(),
  groupNames: partial.groupNames || partial.groups?.map((g) => g.name) || [],
});
