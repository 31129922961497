import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import sectionIcon from "ui/component/section-icon";
import { button, img } from "ui/html";

export default function cameoPageSection({
  section,
  id,
  onEdit,
  onDelete,
}) {
  return cameo({
    icon: sectionIcon(section),
    label: section.title || "No title",
    controls: [
      onEdit ? button(icon.solid("edit"), () => onEdit(id)) : "",
      onDelete ? button(icon.solid("trash-alt"), () => onDelete(id), ".danger") : "",
      img(".ghost", "/media/empty-pixel.png"),
    ],
    config: {
      key: section.title,
      attrs: { role: "group" }, // draggable: "true" },
    },
    sel: ".cameo-page-section",
  });
}
