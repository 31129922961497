/**
 * A form field for phone numbers.
 *
 * @module ui/component/form-managed/field-phone-number
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { cellPhoneConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "Please enter a phone number.",
  hint: "Please make sure to include a country code (+1, +91, etc)",
  invalid: "Please enter a valid phone number in the format \"+13215551234\" with country code included (+1, +91, etc.)",
  taken: "This phone number is already in use by another account.",
});

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "tel",
  constraints: cellPhoneConstraints,
  helpTexts,
  label: "Cell Phone",
  name: "cellPhone",
  sel: "",
  taken: null,
});

/**
 * A form field for phone numbers.
 *
 * If the `taken` property is provided with a list of phone number strings,
 * validation will check if the current value is in the list.
 *
 * @function phoneNumber
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="cellPhone"]
 * @param {string} [label="Cell Phone"]
 * @param {Selector} [sel=""]
 * @param {?string[]} taken
 * @return {module:ui/common/el~El}
 */
export default function phoneNumber(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.phone-number`,
  });
}
