/**
 * A help modal dialog for add content bulk page.
 *
 * @module ui/component/modal/help/add-content-bulk
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminAddContentBulkHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_ADD_CONTENT_BULK,
    skipCache,
    async,
    sel: ".help-modal-add-content-bulk",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content-bulk.svg"),
            p("Content Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("Content Mass Creation allows organizations to add several pieces of content to SmartEdge at the same time.", ".spaced-bottom"),
              p("To get started, please upload all the content to the FTP portal"),
              p("before uploading the CSV.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content-bulk.svg"),
            p("Content Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("Download the CSV template and enter the content information"),
              p("into the spreadsheet.", ".spaced-bottom"),
              p("Save the file as CSV.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content-bulk.svg"),
            p("Content Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("After you have prepared the CSV, go to Import CSV to upload the completed CSV.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content-bulk.svg"),
            p("Content Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("Our verification system will scan for any issues and confirm the"),
              p("correct user entries.", ".spaced-bottom"),
              p("Double check and verify the necessary changes.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content-bulk.svg"),
            p("Content Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("Then click SAVE. You’re all set!", ".spaced-bottom"),
              p("Please confirm the newly created users on the Manage Content interface.", ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
