/**
 * An individualized icon for a user group.
 *
 * If the user group is a system privilege group, provides
 * the corresponding icon. Otherwise, a generic user group icon.
 *
 * @module ui/component/group-icon
 * @category UI
 * @subcategory Components - ACL
 */
import {
  systemPrivilegeGroups,
  systemPrivilegeGroupsFriendly,
} from "model/acl/constants";
import icon from "ui/component/icon";

export const getIconName = (groupName) => {
  switch (groupName) {
    case systemPrivilegeGroups.ADMIN:
      return "user-crown";
    case systemPrivilegeGroups.ADMIN_MESSAGING:
      return "users-crown";
    case systemPrivilegeGroups.USER_MANAGEMENT:
      return "users";
    case systemPrivilegeGroups.FILE_MANAGEMENT:
      return "file-upload";
    case systemPrivilegeGroups.MEDIA_MANAGEMENT:
      return "photo-video";
    case systemPrivilegeGroups.PUBLIC:
      return "user-unlock";
    case systemPrivilegeGroups.USER_MESSAGING:
      return "users-class";
    default:
      return "users";
  }
};

export default function groupIcon(groupName) {
  return icon.solid(
    getIconName(groupName),
    ".group-icon",
    {
      props: {
        title: `System Privilege: ${systemPrivilegeGroupsFriendly.get(groupName)}`,
      },
    },
  );
}
