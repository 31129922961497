/**
 *
 * A collection of miscellaneous shared functions.
 *
 * - [acl]{@link module:util/acl} helpers related to access control
 * - [array]{@link module:util/array} utilities for manipulating javascript
 *   arrays
 * - [dom]{@link module:util/dom} a set of shortcuts for HTML DOM interaction
 * - [error]{@link module:util/error} error production and handling
 * - [event]{@link module:util/event} utilities for creating, handling and
 *   dispatching DOM events
 * - [file]{@link module:util/file} files, file metadata, and file type handling
 * - [filter]{@link module:util/filter} higher-order functions used for filtering
 *   and searching collections of SmartEdge entities
 * - [format]{@link module:util/format} utilities for formatting various entities
 *   and values as strings
 * - [metadata]{@link module:util/metadata} helpers related to metadata entities
 * - [object]{@link module:util/object} utilities for manipulating javascript
 *   objects, esp. in regard to merging and mutability
 * - [sort]{@link module:util/sort} higher-order functions used for sorting
 *   collections of SmartEdge entities
 * - [uiCustomization]{@link module:util/ui-customization} UI customization utilities
 *
 *
 * TODO this used to be one giant file full of helper functions, and the
 * re-exports here are to maintain compatibility with imports elsewhere. Eventually
 * we should refactor imports to use specific utility submodules instead of just
 * importing things piecemeal from the pile.
 *
 * @module util
 * @category Utilities
 */
import * as dom from "./dom";
import * as error from "./error";
import * as event from "./event";
import * as file from "./file";
import * as filter from "./filter";
import * as format from "./format";
import * as metadata from "./metadata";
import * as object from "./object";
import * as sort from "./sort";
import * as uiCustomization from "./ui-customization";

const utils = {
  dom,
  error,
  event,
  file,
  filter,
  format,
  metadata,
  object,
  sort,
  uiCustomization,
};

export default utils;
