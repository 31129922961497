/**
 * Functions for sorting lists of users and metadata.
 *
 * @module util/sort
 * @category Utilities
 */
import MersenneTwister from "mersenne-twister";
import {
  groupSortCriteria,
  systemPrivilegeGroups,
  systemPrivilegeGroupsSet,
} from "model/acl/constants";
import {
  metadataTypes,
  metadataSortCriteria,
} from "model/metadata/constants";
import { isSingleLecture } from "model/course";
import {
  pageSortCriteria,
} from "model/dynamic-page/constants";
import {
  // userAuthorities,
  userSortCriteria,
} from "model/user/constants";
import {
  assessmentSortCriteria,
  assessmentUserSortCriteria,
  courseSortCriteria,
} from "model/course/constants";
import { normalizeToNull } from "./internal";

/**
 * Used to control the sort order when sorting by metadata type.
 *
 * @constant typeSortOrder
 * @type object
 * @readonly
 * @private
 */
const typeSortOrder = Object.freeze([
  metadataTypes.VIDEO,
  metadataTypes.DOCUMENT,
  metadataTypes.IMAGE,
  metadataTypes.LIST,
  metadataTypes.DYNAMIC_LIST,
]);

/**
 * Sort by natural text order, case insensitive, with empty/null at the end.
 *
 * @function sortText
 * @param {?string} a
 * @param {?string} b
 * @return {number} sort direction
 */
export const sortText = (a, b) => {
  const normA = normalizeToNull(a);
  const normB = normalizeToNull(b);
  if (normA === null) return 1;
  if (normB === null) return -1;
  if (normA === normB) return 0;
  return normA.toLowerCase() > normB.toLowerCase() ? 1 : -1;
};

/**
 * Sort by natural number order.
 *
 * @function sortNum
 * @param {?number} a
 * @param {?number} b
 * @return {number} sort direction
 */
export const sortNum = (a, b) => {
  const pA = parseFloat(a, 10);
  const pB = parseFloat(b, 10);
  if (Number.isNaN(pA)) return 1;
  if (Number.isNaN(pB)) return -1;
  if (pA === pB) return 0;
  return pA > pB ? 1 : -1;
};

/**
 * Sort by metadata type.
 * @param {module:api/types/metadata~ListMetadata} a
 * @param {module:api/types/metadata~ListMetadata} b
 * @return {number} sort direction
 */
const sortType = (a, b) => {
  const iA = typeSortOrder.indexOf(a);
  const iB = typeSortOrder.indexOf(b);
  if (iA === -1) return 1;
  if (iB === -1) return -1;
  if (iA === iB) return 0;
  return iA > iB ? 1 : -1;
};

/**
 * Determine the length of an object.
 *
 * If it is a playlist metadata, the return value will be its playlist length.
 * Otherwise it will be the `length` property of the object if present, or `0`.
 *
 * @function getLength
 * @private
 */
const getLength = (obj) => {
  if (obj.length !== null && obj.length !== undefined) return parseFloat(obj.length, 10);
  if (obj?.items?.length) return obj.items.length;
  return 0;
};

/**
 * Sort by value of a Date object.
 *
 * @function sortDate
 * @private
 */
export const sortDate = (a, b) => {
  if (!(a instanceof Date)) return 1;
  if (!(b instanceof Date)) return -1;
  if (a.getTime() === b.getTime()) return 0;
  return a.getTime() > b.getTime() ? 1 : -1;
};

const sortBoolean = (booleanType) => {
  if (!booleanType) return -1;
  return 1;
};

/**
 * Finds the diagonal of a video's resolution.
 *
 * @function getResolution
 * @param {module:api/types/metadata~ListMetadata} a
 * @return {number}
 */
const getResolution = (a) => {
  // TODO improve with better metadata
  if (a.width && a.height && a.length) {
    // take the diagonal resolution times the length
    // ideally we'd take the file size instead but that
    // is not yet available
    // at least this will work for videos.
    return Math.sqrt(a.width ** 2 * a.height ** 2) * a.length;
  }
  // we can sort playlists by counting their items
  if (a?.items?.length) return a.items.length;
  // if it has some other length attribute that will do for now
  if (a.length) return a.length;
  // most likely we fall through to here
  return -1;
};

/**
 * A higher-order function that produces a sort callback used to sort metadata entities,
 * given the chosen sort criteria.
 *
 * @function metadataSort
 * @param {module:api/constants~metadataSortCriteria} sortCriteria
 * @return {function} for use with `Array.sort`
 */
export const metadataSort = (sortCriteria) => {
  switch (sortCriteria) {
    case metadataSortCriteria.DATE_ASC:
      return (a, b) => sortDate(a.createdAt, b.createdAt);
    case metadataSortCriteria.DATE_DESC:
      return (a, b) => sortDate(b.createdAt, a.createdAt);

    case metadataSortCriteria.DESCRIPTION_ASC:
      return (a, b) => sortText(a.description, b.description);
    case metadataSortCriteria.DESCRIPTION_DESC:
      return (a, b) => sortText(b.description, a.description);

    case metadataSortCriteria.LENGTH_ASC:
      return (a, b) => sortNum(getLength(a), getLength(b));
    case metadataSortCriteria.LENGTH_DESC:
      return (a, b) => sortNum(getLength(b), getLength(a));

    case metadataSortCriteria.ID_ASC:
      return (a, b) => sortText(a.id, b.id);
    case metadataSortCriteria.ID_DESC:
      return (a, b) => sortText(b.id, a.id);

    case metadataSortCriteria.RESOLUTION_ASC:
      return (a, b) => sortNum(getResolution(a), getResolution(b));
    case metadataSortCriteria.RESOLUTION_DESC:
      return (a, b) => sortNum(getResolution(b), getResolution(a));

    case metadataSortCriteria.TYPE_ASC:
      return (a, b) => sortType(a.type, b.type);
    case metadataSortCriteria.TYPE_DESC:
      return (a, b) => sortType(b.type, a.type);

    case metadataSortCriteria.TITLE_ASC:
      return (a, b) => sortText(a.title, b.title);
    case metadataSortCriteria.TITLE_DESC:
    default:
      return (a, b) => sortText(b.title, a.title);
  }
};

/**
 * Sorts user authorities by number of assigned permissions.
 * @function sortPermissions
 * @private
 */
const sortAuthorities = (first, second) => {
  if (!first?.length) return 1;
  if (!second?.length) return -1;
  if (first.length > second.length) return -1;
  if (first.length < second.length) return 1;
  return 0;
};

const getFullName = (user) => (user.fullName
  ? user.fullName
  : [user.firstName, user.lastName].filter((n) => n !== null).join(" "));

/**
 * Note that sorting by fullName is slow because it has to build the full name
 * for both users in each pass. It supports optimization by providing a pre-populated
 * fullName field, and otherwise derives it from firstName + lastName.
 */
export const userSort = (sortCriteria) => {
  const privilegeFilter = (group) => systemPrivilegeGroupsSet.has(group.name);

  switch (sortCriteria) {
    case userSortCriteria.USERNAME_ASC:
      return (a, b) => sortText(a.username, b.username);
    case userSortCriteria.USERNAME_DESC:
      return (a, b) => sortText(b.username, a.username);

    case userSortCriteria.FULLNAME_ASC:
      return (a, b) => sortText(getFullName(a), getFullName(b));
    case userSortCriteria.FULLNAME_DESC:
      return (a, b) => sortText(getFullName(b), getFullName(a));

    case userSortCriteria.CELLPHONE_ASC:
      return (a, b) => sortNum(a.cellPhone, b.cellPhone);
    case userSortCriteria.CELLPHONE_DESC:
      return (a, b) => sortNum(b.cellPhone, a.cellPhone);

    case userSortCriteria.EMAIL_ASC:
      return (a, b) => sortText(a.email, b.email);
    case userSortCriteria.EMAIL_DESC:
      return (a, b) => sortText(b.email, a.email);

    case userSortCriteria.ENABLED_ASC:
      return (a) => sortBoolean(a.enabled);
    case userSortCriteria.ENABLED_DESC:
      return (_, b) => sortBoolean(b.enabled);

    case userSortCriteria.EXTERNAL_ASC:
      return (a) => sortBoolean(a.external);
    case userSortCriteria.EXTERNAL_DESC:
      return (_, b) => sortBoolean(b.external);

    case userSortCriteria.AUTHORITIES_ASC:
      return (a, b) => sortAuthorities(
        a.groups.filter(privilegeFilter),
        b.groups.filter(privilegeFilter),
      );
    case userSortCriteria.AUTHORITIES_DESC:
      return (a, b) => sortAuthorities(
        b.groups.filter(privilegeFilter),
        a.groups.filter(privilegeFilter),
      );
    default:
      return (a, b) => sortText(b.title, a.title);
  }
};

/**
 * When sorting groups by name, the special system groups should appear before
 * or after other groups.
 * @function groupNameSort
 * @private
 */
const groupNameSort = (a, b) => {
  // privilege groups always on top
  if (a.system && !b.system) return -1;
  if (!a.system && b.system) return 1;
  // public access goes to bottom of privilege groups
  if (a.name === systemPrivilegeGroups.PUBLIC) return 1;
  if (b.name === systemPrivilegeGroups.PUBLIC) return -1;
  // sort other privilege groups alphabetically
  return sortText(a.name, b.name);
};

/**
 * Sorts a list of ACL groups.
 * @function groupSort
 * @param {GroupSortCriteria} sortCriteria
 * @return {function} for use with `Array.sort`
 */
export const groupSort = (sortCriteria) => {
  switch (sortCriteria) {
    case groupSortCriteria.ID_ASC:
      return (a, b) => sortText(a.id, b.id);
    case groupSortCriteria.ID_DESC:
      return (a, b) => sortText(b.id, a.id);
    case groupSortCriteria.NAME_ASC:
      return groupNameSort;
    case groupSortCriteria.NAME_DESC:
      return (a, b) => groupNameSort(b, a);
    case groupSortCriteria.COUNT_ASC:
      return (a, b) => sortNum(a.members?.length || 0, b.members?.length || 0);
    case groupSortCriteria.COUNT_DESC:
      return (a, b) => sortNum(b.members?.length || 0, a.members?.length || 0);
    default:
      return groupNameSort;
  }
};

/**
 * A higher-order function that produces a sort callback used to sort page entities,
 * given the chosen sort criteria.
 *
 * @function pageSort
 * @param {module:api/constants~pageSortCriteria} sortCriteria
 * @return {function} for use with `Array.sort`
 */
export const pageSort = (sortCriteria) => {
  switch (sortCriteria) {
    case pageSortCriteria.ID_ASC:
      return (a, b) => sortText(a.id, b.id);
    case pageSortCriteria.ID_DESC:
      return (a, b) => sortText(b.id, a.id);
    case pageSortCriteria.SLUG_ASC:
      return (a, b) => sortText(a.slug, b.slug);
    case pageSortCriteria.SLUG_DESC:
      return (a, b) => sortText(b.slug, a.slug);
    case pageSortCriteria.TITLE_ASC:
      return (a, b) => sortText(a.title, b.title);
    case pageSortCriteria.TITLE_DESC:
      return (a, b) => sortText(b.title, a.title);
    default:
      return (a, b) => sortText(b.title, a.title);
  }
};

/**
 * A higher-order function that produces a sort callback used to sort course entities,
 * given the chosen sort criteria.
 *
 * @function coursesSort
 * @param {module:api/constants~courseSortCriteria} sortCriteria
 * @return {function} for use with `Array.sort`
 */
export const courseSort = (sortCriteria) => {
  switch (sortCriteria) {
    case courseSortCriteria.DATE_ASC:
      return (a, b) => sortDate(a.createdAt, b.createdAt);
    case courseSortCriteria.DATE_DESC:
      return (a, b) => sortDate(b.createdAt, a.createdAt);
    case courseSortCriteria.ID_ASC:
      return (a, b) => sortText(a.id, b.id);
    case courseSortCriteria.ID_DESC:
      return (a, b) => sortText(b.id, a.id);
    case courseSortCriteria.TITLE_ASC:
      return (a, b) => sortText(a.title, b.title);
    case courseSortCriteria.TITLE_DESC:
      return (a, b) => sortText(b.title, a.title);
    case courseSortCriteria.ENTRIES_ASC:
      return (a, b) => sortNum(a.modules?.length, b.modules?.length);
    case courseSortCriteria.ENTRIES_DESC:
      return (a, b) => sortNum(b.modules?.length, a.modules?.length);
    default:
      return (a, b) => sortText(b.title, a.title);
  }
};

/**
 * A higher-order function that produces a sort callback used to sort course entities,
 * given the chosen sort criteria.
 *
 * @function assessmentsSort
 * @param {assessmentSortCriteria} sortCriteria
 * @return {function} for use with `Array.sort`
 */
export const assessmentsSort = (sortCriteria) => {
  switch (sortCriteria) {
    case assessmentSortCriteria.TITLE_ASC:
      return (a, b) => sortText(a.title, b.title);
    case assessmentSortCriteria.TITLE_DESC:
      return (a, b) => sortText(b.title, a.title);
    case assessmentSortCriteria.START_DATE_ASC:
      return (a, b) => sortDate(a.startDate, b.startDate);
    case assessmentSortCriteria.START_DATE_DESC:
      return (a, b) => sortDate(b.startDate, a.startDate);
    case assessmentSortCriteria.END_DATE_ASC:
      return (a, b) => sortDate(a.endDate, b.endDate);
    case assessmentSortCriteria.END_DATE_DESC:
      return (a, b) => sortDate(b.endDate, a.endDate);
    case assessmentSortCriteria.GRADING_SCHEME_ASC:
      return (a, b) => sortText(
        a.gradingScheme?.description,
        b.gradingScheme.description,
      );
    case assessmentSortCriteria.GRADING_SCHEME_DESC:
      return (a, b) => sortText(
        b.gradingScheme?.description,
        a.gradingScheme?.description,
      );
    default:
      return (a, b) => sortText(b.title, a.title);
  }
};

/**
 * TODO: figure out some way to actually support multiple sort criteria.
 *       For now it's not used anywhere.
 *
 * @function metadataMultiSort
 * @private
 */
export const metadataMultiSort = (sortCriteria) => {
  if (sortCriteria.length) {
    return metadataSort(sortCriteria[0]);
  }
  // if no criteria, just return a sort function that does nothing
  return () => 0;
};

/**
 * A higher-order function that produces a sort callback used to sort assessment's user entities,
 * given the chosen sort criteria.
 *
 * @function assessmentUserSort
 * @param {assessmentUserSortCriteria} sortCriteria
 * @param {User[]} users
 * @return {function} for use with `Array.sort`
 */
export const assessmentUserSort = (sortCriteria, users = []) => {
  const findUser = (userId) => users.find((u) => u.id === userId);
  switch (sortCriteria) {
    case assessmentUserSortCriteria.STUDENT_ASC:
      return (a, b) => sortText(getFullName(findUser(a.userId)), getFullName(findUser(b.userId)));
    case assessmentUserSortCriteria.STUDENT_DESC:
      return (a, b) => sortText(getFullName(findUser(b.userId)), getFullName(findUser(a.userId)));
    case assessmentUserSortCriteria.EVAL_STATUS_ASC:
      return (a, b) => sortText(
        a.status,
        b.status,
      );
    case assessmentUserSortCriteria.EVAL_STATUS_DESC:
      return (a, b) => sortText(
        b.status,
        a.status,
      );
    default:
      return (a, b) => sortText(getFullName(findUser(a.userId)), getFullName(findUser(b.userId)));
  }
};

const getModuleTitle = (module) => (isSingleLecture(module)
  ? module.lectures?.[0].title
  : module.title);

export const courseModuleTitleSort = () => (a, b) => sortText(
  getModuleTitle(a),
  getModuleTitle(b),
);

/**
 * Shuffle an array using the fisher-yates algorithm.
 *
 * If `rng` is supplied, it will be used as the random number generator.
 *
 * Otherwise a new mersenne-twister prng instance will be created for the shuffle.
 *
 * @function shuffle
 * @param {Array.mixed} list any array or iterator
 * @param {?mixed} rng random number generator
 * @return {Array.mixed} the shuffled array
 */
export const shuffle = (list, rng = new MersenneTwister()) => {
  let temp = null;
  const shuffled = [...list];

  for (let i = shuffled.length - 1; i > 0; i -= 1) {
    const j = Math.floor(rng.random() * (i + 1));
    temp = shuffled[i];
    shuffled[i] = shuffled[j];
    shuffled[j] = temp;
  }

  return shuffled;
};
