/**
 * A page for sending notifications to users.
 *
 * Corresponds to `markup/admin/send-announcement.html`
 *
 * @module ui/page/admin/send-announcement
 * @category Pages
 * @subcategory Admin - Users
 */
import api from "api";
import getConfig from "config";
import log from "log";
import { userToPrincipal } from "model/acl";
import { aclPrincipalTypes } from "model/acl/constants";
import { notificationMessageTypes } from "model/notification/constants";
import actionBar from "ui/component/dashboard-action-bar";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import textarea from "ui/component/form-managed/field-textarea";
import cameoPrincipal from "ui/component/cameo-principal";
import widget from "ui/component/dashboard-widget";
import principalSelectModal from "ui/component/modal/acl/principal-select";
import div from "ui/html/div";
import h2 from "ui/html/h2";
import dashboardLayout from "ui/page/layout/dashboard";
import view from "ui/view";
import formManagedView from "ui/view/form-managed";
import { userNamesString } from "util/format";
import { frozen } from "util/object";

let actionsView, header, loading, modal, notification, sendNotificationView;

const { FAIL, SUCCESS } = notificationMessageTypes;

const staticMessages = frozen({
  noUsers: {
    title: "No Users Selected",
    text: "Please select at least one user.",
    type: FAIL,
    duration: 3,
  },
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 3,
  },
  accessDenied: {
    title: "Acess Denied",
    text: "You do not have permission to send announcements.",
    type: FAIL,
    duration: 3,
  },
  backendErrors: {
    title: "Send Failed",
    text: "Notification could not be sent. Please enter a valid number of characters and/or contact your organization’s SmartEdge admin for more support.",
    type: FAIL,
  },
  success: {
    title: "Message Sent!",
    type: SUCCESS,
  },
});

const addUsers = (self) => async () => {
  const {
    users,
    groups,
    selectedUsers,
  } = self.state;

  const principals = [...groups, ...users.map(userToPrincipal)];

  const result = await modal.async(
    principalSelectModal({ principals, multiSelect: true }, modal),
    false,
  );
  if (!result?.length) return;

  const userIds = Array.from(new Set(
    result
      .map((p) => {
        if (p.type === aclPrincipalTypes.INDIVIDUAL) {
          return p.id;
        }
        if (p.type === aclPrincipalTypes.GROUP) {
          return p.members.map((member) => member.id);
        }
        return null;
      })
      .flat(),
  ));

  const newUsers = userIds
    .filter((userId) => !selectedUsers.some((selectedUser) => selectedUser.id === userId))
    .map((id) => users.find((user) => user.id === id));

  self.setFullValidation(true);
  self.update({ selectedUsers: [...selectedUsers, ...newUsers] });
};

const onDeleteUser = (user, self) => async () => {
  if (
    !(await modal.confirm(`Are you sure you want to remove ${userNamesString(user)}?`))
  ) {
    return;
  }
  const selectedUsers = self.state.selectedUsers
    .filter((userItem) => userItem.id !== user.id);
  self.update({ selectedUsers });
};

const buildUserRow = (user, page) => div(
  ".user",
  {
    key: user.id,
    attrs: {
      role: "group",
      title: `${user.firstName} ${user.lastName} (${user.username})`,
    },
  },
  cameoPrincipal({
    principal: userToPrincipal(user),
    controls: [
      button.warning({
        icon: "trash-alt",
        onClick: onDeleteUser(user, page),
        iconOnly: true,
      }),
    ],
  }, modal),
);

const doSendNotification = async (e) => {
  e.preventDefault();
  e.stopPropagation();

  const self = sendNotificationView;
  const { state } = sendNotificationView;
  const usersAmount = state.selectedUsers.length;
  const messages = [];

  self.setFullValidation(true);

  if (usersAmount === 0) messages.push(staticMessages.noUsers);

  if (!self.validate().valid || messages.length > 0) {
    messages.push(staticMessages.invalid);
    messages.forEach(notification.post);
    return;
  }

  try {
    loading.show();

    await api.message.sendNotification({
      from: state.user.id,
      cc: state.selectedUsers.map((u) => u.id),
      title: self.values.title || "",
      text: self.values.notification,
    });

    notification.post(staticMessages.success);

    self.update({ selectedUsers: [] });
    document.querySelector("[name='notification']").value = "";

    loading.hide();
  } catch (err) {
    log.error(err);
    switch (err.statusCode) {
      case 403:
        notification.post(staticMessages.accessDenied);
        break;
      case 400:
      default:
        notification.post(staticMessages.backendErrors);
        break;
    }
    loading.hide();
  }
};

const showSendNotification = (self) => {
  const { state } = self;
  const onClick = addUsers(self);

  return form(
    "#send-announcement-form",
    self.bind([
      [textarea, { label: "Enter announcement here", name: "notification", required: true }],
      h2("Users", ".title-users"),
      div(".users", [
        ...state.selectedUsers.map((user) => buildUserRow(user, self)),
        button.standIn({ icon: "user-plus", label: "Add User / Group", onClick }),
      ]),
    ]),
  );
};

const showActionButtons = () => div("#actions", [
  button.primary({
    icon: "paper-plane",
    label: "Send",
    onClick: doSendNotification,
    disabled: (
      !sendNotificationView.values?.notification
      || !sendNotificationView.state.selectedUsers.length
    ),
  }),
]);

export default async function sendAnnouncement(selector) {
  const title = "Announcements";
  ({ header, loading, modal, notification } = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget([
        form("#send-announcement-form"),
      ], title),
    ],
    title,
    true,
  ));
  loading.show();

  const [config, userList, user, users, groups] = await Promise.all([
    getConfig(),
    api.user.list(),
    api.user.getMe(),
    api.user.list(),
    api.acl.listGroups(),
  ]);
  const selectedUsers = [];

  const state = {
    validation: {
      valid: true,
      fields: {},
    },
    config,
    userList,
    user,
    selectedUsers,
    messages: [],
    title,
    users,
    groups,
  };

  const doUpdate = (self) => {
    self.render();
    if (actionsView) actionsView.render();
  };

  sendNotificationView = formManagedView(
    showSendNotification,
    doUpdate,
  )("#send-announcement-form", state);

  actionsView = view.create(showActionButtons)("#actions", state);

  header.update(state);
  loading.hide();
}
