/**
 * Evaluation models.
 *
 * @module model/course/evaluation
 * @category Model
 * @subcategory Course
 */
import {
  orNull,
  required,
  validArray,
  validInt,
  validObject,
  validString,
  validUUID,
} from "model/constraints";

/**
 * @typedef Solution
 * @property {string} id
 * @property {UUID} questionId
 * @property {string} value
 * @property {Score} score
 */

/**
 * @typedef Score
 * @property {string} id
 * @property {number} points
 * @property {string} description
 */

/**
 * @typedef Evaluation
 * @property {string} id
 * @property {evaluationStatus} status
 * @property {UUID} assessmentId
 * @property {UUID} courseId
 * @property {UUID} studentId
 * @property {Solution[]} solutions
 * @property {Score} score
 */

/**
 * Converts abbreviated score response to abbreviated score object
 *
 * @function responseToScore
 * @param response
 * @param {string} response.id
 * @param {number} response.points
 * @param {string} response.description
 * @returns Score
 */
export const responseToScore = (response) => ({
  id: response.id,
  points: response.points,
  description: response.description,
});

/**
 * Converts abbreviated score response to abbreviated score object
 *
 * @function responseToSolution
 * @param response
 * @param {UUID} response.id
 * @param {UUID} response.questionId
 * @param {string} response.value
 * @param {Object} response.score
 * @returns Solution
 */
export const responseToSolution = (response) => ({
  id: response.id,
  questionId: response.questionId,
  value: response.value,
  score: response.score ? responseToScore(response.score) : null,
});

/**
 * Converts abbreviated evaluation response to abbreviated evaluation object
 *
 * @function responseToEvaluation
 * @param response
 * @param {string} response.id
 * @param {string} response.assessmentId
 * @param {string} response.studentId
 * @param {Object} response.score
 * @param {string} response.status
 * @param {string} response.courseId
 * @param {string} response.studentId
 * @param {Object[]} response.solutions
 * @returns Evaluation
 */
export const responseToEvaluation = (response) => ({
  id: response.id,
  studentId: response.studentId,
  assessmentId: response.assessmentId,
  courseId: response.courseId,
  solutions: response.solutions?.map(responseToSolution) || [],
  score: response.score,
  status: response.status,
});

/**
 * Validates and sanitizes a solution score for use in an api call.
 *
 * @function makeScoreDTO
 * @param {Score} partial
 * @param {String} partial.id
 * @param {mixed} partial.points may be a string containing an integer or an integer
 * @param {String} partial.description optional commentary from the grader
 * @returns {Partial<ScoreDTO>}
 */
export const makeScoreDTO = (partial) => {
  const points = orNull(partial.points, "ScoreDTO.points", validInt);
  return {
    id: orNull(partial.id, "ScoreDTO.id", validUUID),
    points: points === null ? undefined : points,
    description: orNull(partial.description, "ScoreDTO.description", validString) || "",
  };
};

/**
 * Validates and sanitizes a solution for use in an api call.
 *
 * @function makeSolutionDTO
 * @param {Solution} partial
 * @param {string} partial.id
 * @param {UUID} partial.questionId
 * @param {Object[]} partial.value
 * @param {Score} partial.score
 * @param {boolean} omitSolutionValues
 * @returns {Partial<Solution>}
 */
export const makeSolutionDTO = (partial, omitSolutionValues = false) => ({
  id: partial.id,
  questionId: required(partial.questionId, "SolutionsDTO.questionId", validUUID),
  ...(omitSolutionValues ? {} : { value: validArray(partial.value || [], "SolutionsDTO.solutions", validString) }),
  score: partial.score ? makeScoreDTO(partial.score) : undefined,
});

/**
 * Validates and sanitizes an evaluation for use in an api call.
 *
 * @function makeEvaluationDTO
 * @param {Evaluation} partial
 * @param {Assessment} partial.assessment
 * @param {User} partial.student
 * @param {Solution[]} partial.solutions
 * @param {string} partial.feedback
 * @param {boolean} omitSolutionValues
 * @returns {Partial<Evaluation>}
 */
export const makeEvaluationDTO = (partial, omitSolutionValues = false) => ({
  assessmentId: required(partial.assessmentId, "EvaluationDTO.assessmentId", validString),
  studentId: required(partial.studentId, "EvaluationDTO.studentId", validString),
  solutions: validArray(partial.solutions || [], "EvaluationDTO.solutions", validObject)
    .map((solution) => makeSolutionDTO(solution, omitSolutionValues)),
  feedback: partial.feedback || "",
  submitted: partial.submitted || false,
});
