/**
 * A modal for previewing course modules.
 *
 * @module ui/component/modal/course/module-preview
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { merge } from "util/object";
import { div, h3 } from "ui/html";
import button from "ui/component/form-managed/button";
import cameoLecture from "ui/component/cameo-lecture";
import { lectureUrl } from "util/course";
import { getModal } from "ui/view/modal-view";
import { metadataTypes } from "model/metadata/constants";
import { parseDescription, poster } from "./common";

const defaultState = {
  expand: false,
};

const openFirstLecture = (module) => {
  const { lectures } = module;
  const firstLecture = lectures?.[0];
  if (firstLecture) {
    window.location.replace(lectureUrl(module.courseId, module.id, firstLecture.id));
  }
};

const buildView = (state) => {
  const { module, expand } = state;
  return [
    div(".pane.poster-pane", [
      poster(),
    ]),
    div(".pane.info-pane", [
      div(".info-content", [
        h3(module.title, ".title", { props: { title: module.title } }),
        div(
          `.description${expand ? ".expanded" : ""}`,
          parseDescription(
            module.description,
            // eslint-disable-next-line no-use-before-define
            (expanded) => getModal().patch(modulePreviewModal({ ...state, expand: expanded })),
          ),
        ),
      ]),
      div('.info-footer', [
        button.primary({
          label: "Open",
          disabled: !module.lectures?.length,
          onClick: () => openFirstLecture(module),
        }),
      ]),
    ]),
    div(".pane.list-pane", [
      h3("Lectures", ".title", { props: { title: "Lectures" } }),
      div(
        ".items",
        module.lectures.map((lecture) => cameoLecture({
          lecture,
          controls: [
            button.icon({
              icon: "eye",
              onClick: () => {
                window.location.replace(
                  lectureUrl(module.courseId, module.id, lecture.id),
                );
              },
            }),
          ],
        })),
      ),
    ]),
  ];
};

export default function modulePreviewModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  return dialog({
    sel: ".preview-content.content.with-action",
    body: buildView(state),
    config: {
      dataset: {
        metaId: module.id,
        metaType: metadataTypes.MODULE,
      },
    },
  }, getModal());
}
