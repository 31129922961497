/**
 * Endpoints for the notifications system.
 *
 * @module api/notification
 * @category Backend API
 * @subcategory Notifications
 */
import cache from "cache";
import { makeNotificationDTO, responseToNotification } from "model/notification";
import { get, post } from "./request";
import { endpoints } from "./constants";

const { notificationsState } = cache;

/**
 * Get a list of all notifications.
 *
 * @function list
 * @param {string} id
 * @return {Array<NotificationItem>}
 */
export const list = async (id) => {
  const serverNotifications = (await get(`${endpoints.NOTIFICATION}/${id}`))
    .body.map(responseToNotification);
  if (serverNotifications.length > 0) {
    notificationsState.storeNotifications(serverNotifications);
  }
  return notificationsState.getNotifications();
};

/**
 * Send a notification to users list
 *
 * @function send
 * @param {string} id
 * @param {object} partial
 */
export const send = async (id, partial) => (await post(
  `${endpoints.NOTIFICATION}/${id}`,
  null,
  makeNotificationDTO(partial),
)).ok;
