/**
 * A set of radio toggles.
 *
 * Suitable when there are four or more options to present, or when some
 * options should be conditionally disabled or styled.
 *
 * Otherwise prefer multi-toggle element, since it is more compact.
 *
 * @module ui/component/form-managed/field-radio
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import div from "ui/html/div";
import label from "ui/html/label";
import input from "ui/html/input";
import span from "ui/html/span";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";
import { emit, onSpaceKey } from "util/event";

const helpTexts = frozen({
  required: "Please select an option to proceed.",
  invalid: "Please select an option to proceed.",
});

const radioTypes = frozen({
  DEFAULT: "DEFAULT",
  CONTENT_BOX: "CONTENT_BOX",
});

const getValue = (node) => {
  let value = null;
  node.elm.querySelectorAll("input[type=radio]").forEach((elm) => {
    if (!value && (elm.checked || !elm.classList.contains("inverse"))) {
      value = elm.value;
    }
  });
  return value;
};

const requiredHook = (required) => (node, validity) => {
  if (!required) return;
  const value = getValue(node);
  if (value === null) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

/**
 * @typedef RadioEntry
 * @property {String | El} label
 * @property {String} value the value of the radio field when this option is selected
 * @property {Selector} sel style classes for the individual entry
 */

const defaultState = frozen({
  entries: [], // RadioEntry[],
  disabled: false,
  helpTexts,
  name: "radio",
  required: false,
  sel: "",
  value: null,
  values: [], // provided by formView.bind where applicable
});

const makeLabel = (state, entry, wrapper) => label(
  entry.label,
  entry.name,
  null,
  {
    attrs: { target: state.name },
    on: { click: (ev) => entry.doChange(ev, wrapper) },
  },
);

const makeEntry = (state, entry, radioType) => {
  if (typeof entry?.value !== "string") {
    throw new Error(`radio: entry values must be strings, received '${entry?.value}'`);
  }
  const self = span(
    [],
    `${entry.sel}.inner`,
    { on: { click: (ev) => entry.doChange(ev, self) } },
  );
  switch (radioType) {
    case radioTypes.CONTENT_BOX: {
      self.children.push(label(
        "",
        entry.name,
        entry.label,
      ));
      break;
    }
    case radioTypes.DEFAULT:
    default:
      self.children.push(makeLabel(state, entry, self));
  }
  self.children.push(input.radio(
    entry.checked,
    state.required,
    state.name,
    entry.value,
    {
      key: `${state.name}-${state.value}`,
      attrs: {
        tabindex: state.disabled ? "-1" : "0",
      },
      class: {
        checked: entry.checked,
        inverse: !entry.checked,
        disabled: state.disabled,
      },
      on: {
        click: (ev) => entry.doChange(ev, self),
        change: entry.disabled ? undefined : (ev) => entry.doChange(ev, self),
        keydown: onSpaceKey((ev) => entry.doChange(ev, self)),
      },
      sel: entry.sel,
    },
  ));
  return self;
};

/**
 * A set of radio toggles.
 *
 * Treated as a single field when bound to a managed form, whose value contains
 * the selected radio value. If no radio option is selected, the value is reported as `null`.
 *
 * Example:
 *
 * ```js
 * // create a radio with option "one" pre-selected:
 * myForm.bind([
 *  [radio, {
 *    name: "my-radio",
 *    entries: [
 *      { label: "one", value: "1" },
 *      { label: "two", value: "2" },
 *      { label: "three", value: "3" },
 *    ],
 *  ], { "radio": "1" },
 * ]);
 * myForm.values(); // { radio: "1" }
 * ```
 *
 * Note, entry values must be in the form of strings, even if numeric, and
 * should be scalar. Same goes for any initial values passed in state.value.
 *
 * Non-string values will be cast to strings automatically if possible, or throw
 * an error if not.
 *
 * @function radioField
 * @param {object} state
 * @param {ChecklistEntry[]} entries
 * @param {boolean} disabled disables all entries
 * @param {boolean} required if true, at least one entry must be checked
 * @param {string} name
 * @param {String[]} value array of selected entries
 * @param {Selector} sel applied to containing field, not radio elements
 * @param {string} radioType
 */
export default function radioField(inState = {}, radioType = radioTypes.DEFAULT) {
  const state = merge(defaultState, inState);
  const value = state.values?.[state?.name] || state.value || null;

  if (state.disabled) {
    state.sel += ".disabled";
  }

  const display = div(`.inner-radio.${radioType}`);

  // overrides normal radio behavior to implement consistent desirable behavior in
  // both form state and wrapped elements. em:radio-change is emitted in response
  // to several user interactions form within inner elements (see makeEntry)
  const doChange = (ev, wrapper) => {
    /* eslint-disable no-param-reassign */
    ev.stopPropagation();
    display.elm.querySelectorAll(`input[type="radio"]`).forEach((elm) => {
      elm.checked = false;
      elm.classList.add("inverse");
    });
    const target = wrapper.elm.querySelector("input[type=radio]");
    target.classList.remove("inverse");
    target.checked = true;
    state.onChange?.(getValue(display));
    // triggers form value updates
    emit(ev.target, "em:form-managed-change");
    /* eslint-enable no-param-reassign */
  };

  const isChecked = (entry) => value === (entry.value?.toString?.() || entry.value);

  const items = state.entries.map((entry) => makeEntry(state, {
    ...entry,
    disabled: state.disabled,
    sel: `${state.sel}${entry.sel}${isChecked(entry) ? ".checked" : ""}`,
    name: `${state.name}`,
    checked: isChecked(entry),
    value: entry.value?.toString?.() || entry.value,
    doChange,
  }, radioType));

  display.children = items;

  const field = managedField(
    display,
    state.name,
    getValue,
    [requiredHook(state.required)],
  );

  return genericField(
    { ...state, type: "radio" },
    field,
  );
}

/**
 * A radio with boxed-style list entries. Note that adding other display classes should be done
 * in the `state.entries` array, as they are intended for individual entries.
 */
radioField.boxed = (state) => {
  state.entries.forEach((entry) => {
    /* eslint-disable no-param-reassign */
    if (entry.sel) entry.sel += `.boxed.inverse`;
    else entry.sel = `.boxed.inverse`;
    /* eslint-enable no-param-reassign */
  });
  return radioField(state);
};

radioField.contentBox = (state) => {
  state.entries.forEach((entry) => {
    /* eslint-disable no-param-reassign */
    if (entry.sel) entry.sel += ".content-box";
    else entry.sel = ".content-box";
    /* eslint-enable no-param-reassign */
  });
  return radioField(state, radioTypes.CONTENT_BOX);
};
