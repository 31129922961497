/**
 * Common definitions and functions for pipelines.
 *
 * @module data/pipeline/common
 * @category DataStreams
 * @subcategory Pipelines
 */

/**
 * An API function that retrieves an item by UUID.
 *
 * @callback {ApiItemCallback.<T>}
 * @async
 * @param {UUID} id
 * @param {boolean} [skipCache=false]
 * @return {T}
 */

/**
 * Should provide an initialized `T`, with the `id` property set.
 *
 * @callback {DefaultCallback.<T>}
 * @async
 * @param {UUID} id
 * @return {T}
 */

/**
 * Appends PLACEHOLDER property to items produced as placeholders.
 * @param {function} factory
 * @return {function} fn (params) -> obj by wy of factory
 */
export const tagPlaceholder = (factory) => (...params) => (
  { ...factory(...params), PLACEHOLDER: true }
);

/**
 * Preserves any tag properties added to an object by pipelines when passing them through
 * a model factory.
 *
 * @function presereTags
 * @param {function} producer `fn(P) -> T` a model factory
 * @return {function} `fn(Array.<P>) -> Array.<T>`
 */
export const preserveTags = (producer) => (items) => items.map((item) => {
  const out = producer(item);
  if (item.PLACEHOLDER) out.PLACEHOLDER = true;
  if (item.CACHE) out.CACHE = true;
  return out;
});

export const preserveTagsSingle = (producer) => (item) => {
  const out = producer(item);
  if (item.PLACEHOLDER) out.PLACEHOLDER = true;
  if (item.CACHE) out.CACHE = true;
  return out;
};
