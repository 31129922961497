/**
 * An asynchronous modal dialog for selecting a file.
 *
 * @module ui/component/modal/list-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { button } from "ui/html";
import itemCameo from "ui/component/cameo-list-select-item";
import { anyTextFilter } from "util/filter";
import listDialog from "ui/component/modal/layout/list";
import { getModal } from "ui/view/modal-view";

const doAddMulti = (entry, state) => {
  const modalView = getModal();
  let selectedList = [...state.selectedEntries];
  document.querySelector(`[data-label-id="${entry.id}"]`)
    .classList
    .toggle("selected");
  if (selectedList.some((e) => e.id === entry.id)) {
    selectedList = selectedList.filter((item) => item.id !== entry.id);
  } else {
    selectedList.push(entry);
  }
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(listSelectModal({
    ...state,
    selectedEntries: selectedList,
  }));
};

const entryLine = (state, modalView) => (entry, i) => itemCameo({
  label: entry.label,
  labelId: entry.id ? entry.id : i,
  key: entry.id ? entry.id : i,
  onSelect: () => {
    if (state.multiSelect) {
      doAddMulti(entry, state);
    } else {
      modalView.resolve(entry);
    }
  },
  selected: state.multiSelect && state.selectedEntries.some((e) => entry.id === e.id),
  faded: state.multiSelect,
});

const doSearch = (state) => (search) => {
  const modalView = getModal();
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(listSelectModal({
    ...state,
    search,
  }));
};

const actionButtons = (state, modalView) => {
  const {
    multiSelect,
    selectedEntries,
  } = state;
  if (multiSelect) {
    return button("Submit", () => modalView.resolve(selectedEntries));
  }
  return null;
};

/**
 * A generic list selection modal dialog. Shows a list of the given entries.
 *
 * It must be used with ModalView.async, and resolves with the user's selected entry.
 *
 * The entry object _must_ have the properties `label` and `id`. It _may_ have any other
 * properties. The `label` property will be displayed in the modal, and can be an element
 * or plain text.
 *
 * When an entry is clicked in the modal, the promise initiated by `ModalView.async` is
 * resolved with the selected entry object.
 *
 * If the dialog is closed the onSelect callback is called with null.
 *
 * When the user performs a search of the entries, the list will be filtered by matching
 * any string property of an entry against the search query.
 *
 * @param {Object} config
 * @param {Array<Object>} config.entries list of entries, which are arbitrary objects
 * @param {string} config.entries[].label an entry's display label for the list
 * @param {string} config.entries[].id an id which must be unique among the entries
 * @param {string} config.search initial search filter
 * @param {string} config.selector additional selector
 * @param {View} modalView
 *
 * @return {module:ui/common/El~el}
 */
export default function listSelectModal({
  entries = [],
  search = "",
  multiSelect = false,
  selectedEntries = [],
  selector = "",
}) {
  const modalView = getModal();
  const state = {
    entries,
    search,
    multiSelect,
    selectedEntries,
    selector,
  };
  return listDialog({
    entries: entries
      .filter(search !== "" ? anyTextFilter(search) : () => true)
      .map(entryLine(state, modalView)),
    onSearch: doSearch(state),
    searchText: search,
    actions: actionButtons(state, modalView),
    sel: `.list-select${selector}`,
  }, modalView);
}
