/**
 * A list of checkboxes, zero or more of which may be selected.
 *
 * Checklist takes a single field `name` and returns the values of checked entries
 * in an array.
 *
 * Suitable for a "select one or more of the following", multiple-choice multiple-answer,
 * todo lists, etc.
 *
 * @module ui/component/form-managed/field-checklist
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import { makeLabel, makeCheckbox } from "ui/component/form-managed/field-checkbox";
import div from "ui/html/div";
import span from "ui/html/span";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";
import { emit, onSpaceKey } from "util/event";

const helpTexts = frozen({
  required: "Please select at least one option to proceed.",
  invalid: "Please select at least one option to proceed.",
});

const getValue = (node) => {
  const values = [];
  node.elm.querySelectorAll("input[type=checkbox]").forEach((elm) => {
    if (elm?.checked) {
      values.push(elm.value);
    }
  });
  return values;
};

const requiredHook = (required) => (node, validity) => {
  if (!required) return;
  const value = getValue(node);
  if (value?.length === 0) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

/**
 * @typedef ChecklistEntry
 * @property {String} label
 * @property {String} value included in ChecklistField.value array when checked
 * @property {Selector} sel style classes for the individual entry
 */

const defaultState = frozen({
  entries: [], // ChecklistEntry[],
  disabled: false,
  helpTexts,
  name: "checklist",
  required: false,
  sel: "",
  value: [],
  values: [],
});

const onCheckboxChanged = (self, state) => {
  const checkbox = self.elm.querySelector("input");
  state.onChange?.(getValue(self));
  emit(checkbox, "em:form-managed-change");
};

const toggleCheckedState = (self, state, ev) => {
  ev.preventDefault();
  ev.stopPropagation();
  if (!state.disabled) {
    const checkbox = self.elm.querySelector("input");
    if (checkbox.checked) {
      checkbox.checked = false;
    } else {
      checkbox.checked = true;
    }
    onCheckboxChanged(self, state);
  }
};

const makeEntry = (state) => {
  if (typeof state?.value !== "string") {
    throw new Error(`checklist: entry values must be strings, received '${state?.value}'`);
  }
  const self = span(
    [],
    `${state.sel}.inner`,
    { on: { click: (ev) => toggleCheckedState(self, state, ev) } },
  );
  self.children.push(makeLabel(state));
  self.children.push(makeCheckbox(
    state,
    state.disabled ? undefined : () => {
      onCheckboxChanged(self, state);
      state.onChange?.(getValue(self));
    },
    onSpaceKey((ev) => {
      toggleCheckedState(self, state, ev);
    }),
  ));
  return self;
};

/**
 * A checklist.
 *
 * Treated as a single field when bound to a managed form, whose value contains
 * an array of selected values in the checklist.
 *
 * For example:
 *
 * ```js
 * // create a checklist with the checkbox "one" pre-selected:
 * myForm.bind([
 *  [checklist, {
 *    name: "my-checklist",
 *    entries: [
 *      { label: "one", value: "1" },
 *      { label: "two", value: "2" },
 *      { label: "three", value: "3" },
 *    ],
 *  ], { "checklist": ["1"] },
 * ]);
 * myForm.values(); // { checklist: ["1"] }
 *
 * // if user de-selects "one" and checks "two" and "three":
 * myForm.values(); // { checklist: ["2", "3"] }
 * ```
 *
 * Note, entry values must be in the form of strings, even if numeric, and
 * should be scalar. Same goes for any initial values passed in state.value.
 *
 * Non-string values will be cast to strings automatically if possible, or throw
 * an error if not.
 *
 * @function checklistField
 * @param {object} state
 * @param {ChecklistEntry[]} entries
 * @param {boolean} disabled disables all entries
 * @param {boolean} required if true, at least one entry must be checked
 * @param {string} name
 * @param {String[]} value array of selected entries
 * @param {Selector} sel applied to containing field, not individual checkbox
 *        elements
 */
export default function checklistField(inState = {}) {
  const state = merge(defaultState, inState);
  const value = state.values?.[state.name] || [];

  if (state.disabled) {
    state.sel += ".disabled";
  }

  const display = managedField(
    div(".inner-checklist", state.entries.map((entry, i) => makeEntry({
      ...entry,
      disabled: state.disabled,
      sel: `${state.sel}${entry.sel}`,
      name: `${state.name}-${i}`,
      checked: value.includes(entry.value?.toString?.()),
      value: entry.value?.toString?.() || entry.value,
    }))),
    state.name,
    getValue,
    [requiredHook(state.required)],
  );

  return genericField(
    { ...state, type: "checklist" },
    display,
  );
}

/**
 * A checklist with boxed-style list entries. Note that adding other display classes should be done
 * in the `state.entries` array, as they are intended for individual entries.
 */
checklistField.boxed = (state) => {
  state.entries.forEach((entry) => {
    /* eslint-disable no-param-reassign */
    if (entry.sel) entry.sel += `.boxed.inverse`;
    else entry.sel = `.boxed.inverse`;
    /* eslint-enable no-param-reassign */
  });
  return checklistField(state);
};
