import log from "log";
import collapsible from "ui/component/collapsible";
import button from "ui/component/form-managed/button";
import chatReportMessage from "ui/component/chat/report-message";
import chatReportThread from "ui/component/chat/report-thread";
import { getModal } from "ui/view/modal-view";
import { uuidv4 } from "util/generator";

const message = {
  from: "FOO",
  to: "BAR",
  content: "Bla bla bla",
};

const target = [
  uuidv4(),
  uuidv4(),
];

export default function chatDialogs() {
  return collapsible({
    title: "Chat Dialogs",
    open: false,
  }, [
    button.subtle({
      label: "Report Message Dialog",
      icon: "comment-alt-exclamation",
      onClick: async () => {
        log.debug("report message result", (await getModal().async(chatReportMessage({
          message,
        }))));
      },
    }),
    button.subtle({
      label: "Report Thread Dialog",
      icon: "bell-exclamation",
      onClick: async () => {
        log.debug("report thread result", (await getModal().async(chatReportThread({
          target,
        }))));
      },
    }),
  ]);
}
