/**
 * A one-line summary of an entity in a box resembling a form element.
 */
import span from "ui/html/span";

/**
 * A summary display of an entity styled like a form element.
 *
 * If `state.icon` is provided it will be shown on the left-hand side.
 *
 * If the `state.controls` parameter is provided, elements contained within will
 * be appended to the right-hand side of the cameo. If these are buttons they'll
 * be sized and spaced appropriately. Any other type of element will require
 * style intervention.
 *
 * @function cameo
 * @param {object} state
 * @param {string} state.label
 * @param {?ChildEl} state.controls
 * @param {?module:ui/component/icon~Icon} state.icon
 * @param {Selector} [sel=""]
 * @param {Object} config
 * @param {boolean} disabled
 */
export default function cameo({
  label,
  controls = null,
  icon = null,
  sel = "",
  config = {},
  disabled,
}) {
  return span([
    icon || "",
    span(label, ".label"),
    controls
      ? span(controls, ".controls")
      : "",
  ], `${sel}.cameo${disabled ? ".disabled" : ""}`, config);
}
