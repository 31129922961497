/**
 * File picker select file tab.
 * @module ui/component/form-managed/file-picker/tab-select-file
 * @private
 */
/***/
import { fileSelectionTypes } from "model/file-descriptor/constants";
import cameoFile from "ui/component/cameo-file";
import button from "ui/component/form-managed/button";
import fileSelectModal from "ui/component/modal/file-select";
import div from "ui/html/div";
import input from "ui/html/input";
import { getModal } from "ui/view/modal-view";
import { typeFilterAll, descriptorTypeFilterAll } from "util/filter";
import { filePickerModes } from "./constants";
import { makeGetInput } from "./util";

/**
 * Show a file selection modal to pick from a file already on the server.
 * @function selectFile
 * @private
 */
const selectFile = (state, onChange) => async () => {
  const selection = await getModal().async(fileSelectModal({
    uploads: state.uploadFileNames.filter(typeFilterAll(state.fileTypes)),
    descriptors: state.fileDescriptors.filter(descriptorTypeFilterAll(state.fileTypes)),
  }));
  if (selection === null) return; // user closed dialog
  /* eslint-disable no-param-reassign */
  if (selection.type === fileSelectionTypes.FILE) {
    onChange(state, {
      mode: filePickerModes.FILE,
      file: selection.file,
    });
  } else if (selection.type === fileSelectionTypes.DESCRIPTOR) {
    onChange(state, {
      mode: filePickerModes.DESCRIPTOR,
      descriptor: selection.file,
    });
  }
  /* eslint-disable no-param-reassign */
};

const makeCameo = (state, onChange) => {
  if (state.value.descriptor && state.value.mode === filePickerModes.DESCRIPTOR) {
    return cameoFile({
      file: state.value.descriptor,
      onRemove: () => onChange(state, {
        mode: filePickerModes.DESCRIPTOR,
        descriptor: null,
        file: null,
      }),
    });
  }
  if (state.value.file && state.value.mode === filePickerModes.FILE) {
    return cameoFile({
      file: { name: state.value.file },
      onRemove: () => onChange(state, {
        mode: filePickerModes.FILE,
        file: null,
        descriptor: null,
      }),
    });
  }
  return button.standIn({
    icon: "file-plus",
    label: "Select File",
    onClick: selectFile(state, onChange),
    sel: ".file-picker-select-media-file",
  });
};

/**
 * Tab for selecting from an existing file on the server.
 * @function selectFileTab
 * @private
 */
export default function selectFileTab(state, onChange) {
  const { name, value } = state;
  const getInput = makeGetInput(state.name);
  const file = value.file || getInput("file")?.value || null;
  const descriptorId = value.descriptorId || getInput("descriptorId")?.value || null;
  return div(
    ".file-select",
    {},
    [
      input("hidden", descriptorId, false, `${name}_descriptorId`),
      input("hidden", file, false, `${name}_file`),

      makeCameo(state, onChange),
    ],
  );
}
