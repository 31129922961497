/**
 * Page side-menu configuration stream.
 *
 * Provides `getMany` interface for fetching page menus.
 *
 * @module data/site/page-menu
 * @category Data Streams
 * @subcategory Site
 */
import xs from "xstream";
import { getPageMenu } from "api/v2/dynamic-page";
import { CACHE_PAGE_MENU_KEY_V2 } from "cache/constants";
import many from "data/pipeline/many";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const apiFn = (pageIds) => Promise.all(pageIds.map((id) => getPageMenu(id)));

const all$ = xs.create();

const {
  post: getMany,
  sink$: getSink$,
} = many({
  apiFn,
  cacheKey: CACHE_PAGE_MENU_KEY_V2,
  accumulator: itemMap,
  itemSink$: all$,
});

const out$ = xs.merge(
  getSink$,
);

all$.imitate(out$);

export default {
  getMany,
  all$,
};
