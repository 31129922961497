/**
 * The stats page (used as the admin landing page).
 *
 * @module ui/page/admin/stats
 * @category Pages
 * @subcategory Admin
 */
import { info } from "api/server";
import { info as mbeInfo } from "api/message";
import { wabeInfo } from "api/v2/analytics";
import { info as themeInfo } from "api/v2/theme";
import { info as feInfo } from "api/v2/frontend";
import getConfig from "config";
import documentStream from "data/metadata/document";
import groupStream from "data/acl/group";
import labelStream from "data/metadata/label";
import meStream from "data/user/me";
import pageStream from "data/dynamic-page";
import playlistStream from "data/metadata/playlist";
import userStream from "data/user";
import videoStream from "data/metadata/video";
import { managesUsers, managesMedia } from "model/user";
import widget from "ui/component/dashboard-widget";
import view from "ui/view/view";
import {
  div,
  section,
} from "ui/html";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import adminStatsHelpModal from "ui/component/modal/help/admin-dashboard";
import dashboardLayout from "ui/page/layout/dashboard";
import { merge } from "util/object";
import { emptySearchParams, defaultState } from "./state";
import checker from "./checker";
import contentList from "./content-list";
import features from "./features";
import resources from "./resources";
import services from "./services";
import settings from "./settings";
import userList from "./user-list";

const contentInfo = ({ state }) => section(
  "#statistics",
  state.user ? [
    managesUsers(state.user) ? userList(state) : "",
    managesMedia(state.user) ? contentList(state) : "",
  ] : "",
);

const systemInfo = ({ state }) => section(
  "#system",
  [
    resources(),
    services(state),
  ],
);

const configuration = ({ state }) => section(
  "#configuration",
  [
    features(state),
    settings(state),
  ],
);

export default async function stats(containerSelector) {
  const title = "Administration";
  const { loading, modal } = dashboardLayout(
    containerSelector,
    [
      widget(div("#settings"), "Configuration", ".settings"),
      widget(div("#system"), "System", ".system"),
      widget(div("#statistics"), "Statistics", ".statistics"),
    ],
    title,
  );
  loading.show();

  // FIXME these should have mono streams eventually
  const config = await getConfig();

  const state = merge(defaultState, { config });

  const extractCount = (i) => {
    const count = i.get(emptySearchParams)?.count;
    if (typeof count === "number") {
      return count;
    }
    return 0;
  };

  view.create(configuration)("#settings", state);
  const system = view.create(systemInfo)("#system", state);
  system.bindStreams([
    ["serverInfo", checker(info)],
    ["clientInfo", checker(feInfo)],
    ["messageInfo", checker(mbeInfo)],
    ["analyticsInfo", checker(wabeInfo)],
    ["themeInfo", checker(themeInfo)],
  ]);

  const content = view.create(contentInfo)("#statistics", state);

  content.bindStreams([
    ["user", meStream.get()],
    ["users", userStream.search([emptySearchParams, 0])],
    ["documentCount", documentStream.count(emptySearchParams).map(extractCount)],
    ["groupCount", groupStream.count(emptySearchParams).map(extractCount)],
    ["labelCount", labelStream.count(emptySearchParams).map(extractCount)],
    ["pageCount", pageStream.count(emptySearchParams).map(extractCount)],
    ["playlistCount", playlistStream.count(emptySearchParams).map(extractCount)],
    ["userCount", userStream.all$.map((usermap) => usermap.size || null)],
    ["videoCount", videoStream.count(emptySearchParams).map(extractCount)],
  ], () => loading.hide(), new Set(["user"]));

  if (!isHelpModalSeen(modalTypes.ADMIN_STATS)) {
    modal.show(adminStatsHelpModal());
    markModalAsSeen(modalTypes.ADMIN_STATS);
  }
}
