/**
 * Models sanitation functions for api-internal objects.
 *
 * @module model/api
 * @category Model
 * @subcategory API
 */
import { services } from "api/constants";
import { contentTypes, contentTypeValues } from "./constants";

/**
 * APIRequest
 *
 * An intermediate object specifying an API request to be sent.
 * @typedef APIRequest
 * @property {(Object|null)} body request body
 * @property {string} [contentType=application/json] request body mime-type
 * @property {string} endpoint one of the enumerated API {@link endpoints}
 * @property {("GET"|"PUT"|"POST"|"DELETE"|"OPTIONS")} method
 * @property {(Object|null)} params URI parameters
 * @property {boolean} [authenticate=true] whether to authenticate the request
 * @property {Object} [authenticate=true] whether to authenticate the request
 * @property {object} [defaultResponse] a default value for 404s, for endpoints that may 404
 * @property {services} service a service that should be requested
 */

/**
 * Takes a partial API request object and applies default values.
 *
 * @function makeAPIRequest
 * @param {Object} partial object with some APIRequest properties
 *
 * @return {APIRequest} a complete api request
 */
export const makeAPIRequest = (partial) => ({
  body: typeof partial.body === "object" ? partial.body : null,
  contentType: (partial.contentType && contentTypeValues.has(partial.contentType))
    ? partial.contentType : contentTypes.JSON,
  endpoint: typeof partial.endpoint === "string" ? partial.endpoint : "",
  method: typeof partial.method === "string" ? partial.method : "GET",
  params: typeof partial.params === "object" ? partial.params : null,
  authenticate: partial.authenticate === false ? partial.authenticate : true,
  defaultResponse: typeof partial.defaultResponse === "object" ? partial.defaultResponse : undefined,
  service: partial.service || services.DEFAULT,
});

/**
 * APIResponse
 *
 * A standard API response object.
 *
 * @typedef APIResponse
 * @property {object} partial
 * @property {APIRequest} partial.request the original request object
 * @property {Response} partial.rawResponse raw `fetch()` response
 * @property {number} status HTTP response status code
 * @property {boolean} ok whether the response was considered a success
 * @property {(object|null)} [partial.body] parsed json response body, if available
 * @property {String|null} [message] a user-facing message to display for a non-ok response
 */
export const makeAPIResponse = (partial) => ({
  body: partial.body || {},
  request: partial.request || {},
  rawResponse: partial.rawResponse,
  status: partial.status,
  ok: partial.ok ? true : (partial.status < 300 && partial.status > 199),
  message: partial.message ? partial.message : null,
});

/**
 * RequestQueueEntry
 *
 * An entry in the request queue.
 *
 * Note that sequential entries stall processing of the queue until they resolve. This is for
 * prerequisite or disruptive calls such as related to authentication.
 *
 * @typedef RequestQueueEntry
 * @property {APIRequest} request the request object
 * @property {boolean} sequential whether the request is sequential
 * @property {queueResolveCallback} resolve function to call (with {@link APIResponse}) on success
 * @property {queueRejectCallback} resolve function to call (with {@link APIResponse}) on failure
 */

/**
 * @callback queueResolveCallback
 * @param {APIResponse} response
 */

/**
 * @callback queueRejectCallback
 * @param {Error} error
 */

/**
 * Makes a {@link RequestQueueEntry} object from a set of properties.
 *
 * @function makeRequestQueueEntry
 * @param {APIRequest} request
 * @param {queueResolveCallback} resolve
 * @param {queueRejectCallback} reject
 * @param {boolean} sequential
 * @return {RequestQueueEntry}
 */
export const makeRequestQueueEntry = (request, resolve, reject, sequential = false) => ({
  request,
  resolve,
  reject,
  sequential,
});
