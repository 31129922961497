/**
 * The admin FAQ page.
 *
 * @module ui/page/admin/admin-faq
 * @category Pages
 * @subcategory Admin - Users
 */
import {
  div, p, h3, img, li, ol, span,
} from "ui/html";
import cache from "cache";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import widget from "ui/component/dashboard-widget";
import dashboardLayout from "ui/page/layout/dashboard";

const showAdminFaq = () => div(".questions", [
  h3("Overall"),
  collapsible({
    title: "What can admins do?",
  }, p(`SmartEdge Admins have complete control over the SmartEdge installation. They decide how the site is
    configured, control the user base, manage/maintain the content library, and check on the stats/status of the SmartEdge
    installation. On SmartEdge, admins can do it all!`)),
  collapsible({
    title: "Who can be an admin?",
  }, p(`Anyone! When your organization’s SmartEdge installation is deployed, someone is designated as the
    site’s SmartEdge admin. Once logged in, this admin can grant other users admin privileges.
    Please contact support@smartedge.comfor more details.`)),
  collapsible({
    title: "If I am an admin, can I make other users admins?",
  }, p(`Yes!`)),
  collapsible({
    title: "How do access control privileges work?",
  }, p(`Access control privileges allow admins to determine another user’s permission(s) to manage users,
    files, and media. Manage Users allows admins to control the organization’s SmartEdge user base. Manage Files permits
    uploading files. And Manage Media allows for media uploads and metadata/page creation. From the Manage Users page on
    the Admin Portal, click a username to modify the user profile. Under the Permissions header, simply toggle a user’s
    permission on/off. Each type of access control is managed individually- for instance, an admin can give an instructor
    files/media privileges without giving them user privileges. We are adding in more access control layers to limit
    SmartEdge access to certain parts of the system to unauthorized users. Stay tuned!`)),
  collapsible({
    title: "What if a user only has access to certain access control privileges? Does this make them an admin?",
  }, p(`If a user has certain access control privileges, he/she cannot have full admin capacity to manage the
    SmartEdge installation. Having certain privileges doesn't make that user an admin. In general, access control
    privileges allow users to manage users, files and media only. This does not allow users to have access to the full
    Admin Portal unless all the access control privileges are enabled.`)),
  collapsible({
    title: "Does SmartEdge limit certain parts of the system?",
  }, p(`Yes- SmartEdge can limit users from seeing certain parts of the system. This is done through
    access control privileges.`)),
  collapsible({
    title: "Can I redeploy the system as an admin?",
  }, p(`Yes! Please contact support@smartedge.com for more details`)),

  h3("Login"),
  collapsible({
    title: "How do I log into SmartEdge as an Admin?",
  }, p(`The admin is created once an installation is deployed. If you are the admin, please login with the
    credentials assigned to your account. Once the new admin logs in, he/she will be able to change the privileges for
    any other user who logs in. If you have external credentials and access control privileges granted by your school
    admin, please use the username and password you already have for the external credentials.`)),
  collapsible({
    title: "What happens if I forgot my password?",
  }, p(`No worries! It happens to the best of us. To reset your password, you can head to the Forgot Password
    page on the main login page. From there, you will be issued an OTP via e-mail or text. You will need to enter this
    on the Forgot Password page. Once your account has been verified, you are free to change your password. Login with
    the new credentials after this process has been completed.`)),
  collapsible({
    title: "Are there a certain number of times I can attempt logging in with the incorrect password?",
  }, p(`Yes - there are limits. You can only log in a maximum of 5 times before you are locked out of your
    account forever. Worst case scenario- please change your password by going through the Forgot Password process
    before this happens.`)),
  collapsible({
    title: "What does the eyeball in the Password entry box do? How can I turn it off?",
  }, p(`The eyeball allows you to show your password if you prefer to see what you are typing. To show your
    password, click the eyeball until the line across it disappears. To re-hide your password, click the eyeball until
    there is a line across it.`)),
  collapsible({
    title: "How can I go back to the login page from the main SmartEdge site?",
  }, p(`If you want to go back to the main login page, simply log out of your account. This function is
    accessible through the Side Bar Menu of the SmartEdge interface.`)),

  h3("Registration"),
  collapsible({
    title: "Can I register as an admin?",
  }, p(`Our registration process starts with everyone registering as a user. Once you do that, an existing
    admin can grant you admin privileges!`)),

  h3("Forgot Password"),
  collapsible({
    title: "What is the process to recover a forgotten password if I am an admin?",
  }, p(`To reset your password, you can head to the Forgot Password page on the main login page. From there,
    you will be issued an OTP via e-mail or text. You will need to enter this on the Forgot Password page. Once your
    account has been verified, you are free to change your password. Login with the new credentials after this process
    has been completed.`)),
  collapsible({
    title: "What are the password requirements?",
  }, p(`Passwords must contain at least one uppercase letter and one number, and be between 8-255 characters
    with numbers and/or symbols.`)),
  collapsible({
    title: "How do I get my one-time password/code?",
  }, p(`Head to the Forgot Password page on the main login page. From there, you will be issued an
    OTP via e-mail or text.`)),
  collapsible({
    title: "What happens if I don’t receive the code?",
  }, p(`Oh no! We’re sorry to hear that. Please get in touch with your organization’s SmartEdge admin.
    They should be able to assist you.`)),
  collapsible({
    title: "After changing the password, do I need to login with the new credentials to access SmartEdge?",
  }, p(`Yes! You will use the newly confirmed password to login.`)),

  h3("Content"),
  collapsible({
    title: "How do I stream a video?",
  }, p(`To start a video, click the Watch button on the list image or content popup.`)),
  collapsible({
    title: "How can I resume watching a video?",
  }, p(`If you paused your video or accidentally closed your browser window, you can pick up where you left
    off by selecting Resume on the list image or content popup.`)),
  collapsible({
    title: "What do these buttons on the video player do?",
  }, [
    img(".full-width", "/media/video-player.jpg", "video player"),
    ol([
      li([
        span("Back - ", ".bold"),
        "This button will take you back to the last page you were on.",
      ]),
      li([
        span("Restart - ", ".bold"),
        "If you would like to restart your video from the beginning, click this!",
      ]),
      li([
        span("Rewind 15 sec. - ", ".bold"),
        "This button will rewind your video by 15 seconds.",
      ]),
      li([
        span("Play - ", ".bold"),
        `This button will start/stop the video. If the video is playing, press the Play button to pause.
         To restart the video from the point you left off, press the Pause button.`,
      ]),
      li([
        span("Fast Forward 15 sec. - ", ".bold"),
        "This button will fast forward your video by 15 seconds.",
      ]),
      li([
        span("Watch Bar - ", ".bold"),
        "The watch bar tracks the progress of your video.",
      ]),
      li([
        span("Time Watched - ", ".bold"),
        "This indicates how much of the video you have watched in HH:MM:SS.",
      ]),
      li([
        span("Total Length - ", ".bold"),
        "This indicates the total length of the video in HH:MM:SS.",
      ]),
      li([
        span("Volume Control - ", ".bold"),
        `This control allows you to change the volume of the video on the video player. If you want to mute the video,
         move the slider all the way to the left. If you’re somewhere noisy and want to hear better, move the slider
         to the right.`,
      ]),
      li([
        span("Full screen - ", ".bold"),
        `This allows you to view the video you’re watching in full-screen mode. To get out of full-screen mode, either
         click the button again on the video player or press the ESC button.`,
      ]),
    ]),
  ]),
  collapsible({
    title: "Can I download a video?",
  }, p(`Not right now, but we're working on ways to help you watch videos without an internet connection.
    Stay tuned!`)),
  collapsible({
    title: "Can I re-watch a video?",
  }, p(`Totally! Please feel free to watch the videos in the SmartEdge Content Library as many times
    as you'd like.`)),
  collapsible({
    title: "Does SmartEdge keep track of all the videos I have watched?",
  }, p(`Yes! To revisit your favorite pieces of content, check out the Recently Watched tab on the Homepage.`)),
  collapsible({
    title: "How do I download a PDF?",
  }, p(`o view a PDF, click the Open button on the list image or content popup. From your browser’s default PDF
    viewer, you will be able to download the PDF to your computer.`)),
  collapsible({
    title: "Can I only view a PDF through the browser?",
  }, p(`You can do both! You can view the PDF through your browser’s default PDF viewer.
    You also have the option to download PDFs to view them outside of your browser`)),
  collapsible({
    title: "How do I go back and view recently watched videos?",
  }, p(`To revisit your favorite pieces of content, check out the Recently Watched tab on the Homepage.`)),
  collapsible({
    title: "What is a List?",
  }, p(`Lists inside SmartEdge are ways for instructors to group videos and files that are best viewed
    together. They’re like chapters inside a book.`)),
  collapsible({
    title: "Can I choose content to watch on a list?",
  }, p(`Of course! You can watch content in any order you like. Go to dropdown on List popup, select
    the content you'd like to view, and press Watch/View.`)),
  collapsible({
    title: "Can I watch all the content in a List? How do I do this?",
  }, p(`Definitely! Click the Watch All button on the List popup. Every video inside of a list will
    automatically play in order.`)),

  h3("Search - USER VIEW"),
  collapsible({
    title: "How does search work?",
  }, p(`The search bar is located at the top right corner of the SmartEdge interface. It allows you to quickly
    navigate your organization’s Content Library. You can search for anything- numbers, characters, phrases, course
    titles, instructor info, etc. Type your chosen terms or topics into the search bar and hit Enter. Your search
    results will appear on the next page. Search does not spell check for you, so be sure you are correctly typing
    in your terms or you will not see the intended results.`)),
  collapsible({
    title: "What if there are no results?",
  }, p(`Please double check the search terms. If these are fine, then there are no search results available
    for that given search field.`)),
  collapsible({
    title: "Does search catch misspelled words?",
  }, p(`No - unfortunately we do not check for misspelled words. You will be responsible for double
    checking your spelling.`)),
  collapsible({
    title: "Can I filter between videos, files, and lists?",
  }, p(`Yes! Please use the toggles near the top of the page to filter Videos, Files, and Lists.
    You can also sort by video length, file size, alphabetical order, or date uploaded.`)),
  collapsible({
    title: "How does sort work?",
  }, p(`The sort function allows you to sort through the results on the Search or See All pages. You can sort
    by date (Oldest, Newest), content length (Shortest, Longest), file size (Smallest, Largest),
    or alphabetical order (A-Z, Z-A).`)),
  collapsible({
    title: "What can you search for?",
  }, p(`You can search for anything- numbers, characters, phrases, course titles, instructor info, etc.`)),

  h3("Miscellaneous - USER VIEW"),
  collapsible({
    title: "What does See All do?",
  }, p(`To access See All, go to the Side Bar Menu and press See All. This page lets you view all
    Content/Lists on a single page. Pretty nifty, right?`)),
  collapsible({
    title: "Can I filter between videos, files, and lists on the Search and See All pages?",
  }, p(`Yes! Please use the toggles near the top of the page to filter Videos, Files, and Lists. You can also
    sort by video length, file size, alphabetical order, or date uploaded.`)),
  collapsible({
    title: "How does sort work?",
  }, p(`The sort function allows you to sort through the results on the Search or See All pages. You can sort
    by date (Oldest, Newest), content length (Shortest, Longest), file size (Smallest, Largest),
    or alphabetical order (A-Z, Z-A).`)),

  h3("Account Settings"),
  collapsible({
    title: "How to change my Account Settings?",
  }, p(`Select Account Settings on the Side Bar Menu. From there, you'll be able to modify the name,
    mobile number, email address, and password associated with your account. Make sure to press Save before leaving!`)),
  collapsible({
    title: "What can be changed on Account Settings as an admin?",
  }, p(`All standard user info except for username`)),
  collapsible({
    title: "Can I modify my profile if I’m an external user who has admin privileges?",
  }, p(`Unfortunately not through SmartEdge. You'll need to go to the account management page for the external
    credentials to modify your account. Once these changes on the external system are changed, feel free to log into
    SmartEdge with the new login info.`)),
  collapsible({
    title: "If I’m an external user with admin privileges, how can I modify my information?",
  }, p(`You'll need to go to the account management page for the external credentials to modify your account.
    Once these changes on the external system are changed, feel free to log into SmartEdge with the new login info.`)),
  collapsible({
    title: "Can I change my mobile number or email address associated with my SmartEdge account as an admin?",
  }, p(`Yes - but only if there aren't external credentials`)),

  h3("Forgot Password?"),
  collapsible({
    title: "If I want to change my password as an admin, how does this work?",
  }, p(`Go to the Account Settings page and enter a new password (along with the password again in the
    Confirm Password field). No need to log out and re-log in. You will not need to resubmit an OTP for user account
    verification. Make sure to remember the new password for next time you need to log into SmartEdge!`)),
  collapsible({
    title: "Do I need to receive and submit a one-time password before any changes are made?",
  }, p(`You do not need to resubmit an OTP for user account verification.`)),

  h3("Delete account"),
  collapsible({
    title: "What happens if I want to delete my account as an admin?",
  }, p(`Please make sure that you are not the only admin for your organization if you want to delete your
    account. If you are not the only admin, please ask another admin to delete your account for you.`)),
  collapsible({
    title: "What happens if I accidentally delete my account as an admin?",
  }, p(`No worries! You can re-register and request that another admin assigns you as admin again.
    Unfortunately, account deletions are permanent and your organization’s SmartEdge admin can’t restore your
    old profile. However, you can always re-create your account if necessary.`)),
  collapsible({
    title: "What happens if I delete my account if I’m an external user with admin privileges?",
  }, [
    p(`The on-site 'admin' account can't be deleted. For other admin users, another admin will have to delete
    your account for you, as you're not allowed to delete your own account.`),
    p(`Note for external accounts: deleting an account removes all records in our system but does not affect
    the external user account. Once it's deleted, you can log in again with the external credentials and create a
    new record. However, you will lose your admin privileges and any other settings associated with it.`),
  ]),

  h3("Manage Users"),
  collapsible({
    title: "How do I access the Manage Users page?",
  }, p(`Please go to the Side Bar Menu and select Manage Users.`)),
  collapsible({
    title: "What do each of the categories mean?",
  }, p(`Each column indicates the unique identifiers for each of the users. If you’d like to sort through this
    list by any of these categories, simply press the category title itself (i.e. click the actual header “USERNAME”) and
    the list will sort alphabetically by the category you selected.`)),
  collapsible({
    title: "What do Verified and External mean?",
  }, [
    p(`Verified - this means that the user received and entered a verification code either through SMS or email.
    They need to enter the OTP in order to login. If marked in the server settings, some users are required to be
    verified before being able to login. If not, they should be able to log in without verification.`),
    p(`External - This means the user’s LDAP/external username and password management system is enabled for
    the organization. If this is marked, the user will need to use their external credentials in addition to their
    SmartEdge account information to log into the system.`),
  ]),
  collapsible({
    title: "What does Permissions mean?",
  }, p(`This column indicates the different permissions for each of the users depending on access controls.`)),
  collapsible({
    title: "How can I search?",
  }, [
    p(`Basic Search - The search bar is located right on the main Manage Users interface. This allows you
    to search through all of the users. You can search by username, full name, mobile number, email address, verified,
    external, and permissions. Type your chosen words into the search bar and hit Search. Your search results will
    appear underneath in the table.`),
    p(`Advanced Search - If you’d like to add additional filters to your search, you can click on the
    Advanced button located to the right of the Search button to find more options to filter through.`),
  ]),
  collapsible({
    title: "How do I go to the User Profile from this page?",
  }, p(`Click on the user's username in the user table and you will be taken to the user’s profile page.
    You will be able to edit the user’s information there. Don’t forget to click the Save button once you’ve
    made your changes!`)),
  collapsible({
    title: "What happens if I need to add users?",
  }, p(`Great! You can do that from the Add Users interface. Click on Add User in the dashboard to
    start on the user addition process.`)),

  h3("Add Users - Add New"),
  collapsible({
    title: "How do I add a new user?",
  }, p(`Please click the Add button in the User Section of the dashboard. Once there, enter in all of the
    relevant user info. Make sure to include all of the starred fields since these are mandatory. Once completed,
    please press Add and the user will be added to the system.`)),
  collapsible({
    title: "What fields are mandatory for entry?",
  }, p(`The mandatory fields for each entry are username and password/confirm password,
    which are starred on the interface.`)),
  collapsible({
    title: "How is user verification handled?",
  }, p(`Verification is done manually on the page by turning the toggle on/off. Most users don’t need
    to be verified to use the system.`)),
  collapsible({
    title: "What if the SmartEdge system is hooked up to handle external users? Can I still add a new native user?",
  }, p(`Yes! As an admin, you can still create native users.`)),
  collapsible({
    title: "What privileges can be granted to users after a new user is created?",
  }, p(`Users can be granted the ability to manage users, files, and media by editing the user profile.`)),
  collapsible({
    title: "Do users need to provide an OTP after a user has been created, or are they good to go?",
  }, p(`Nope, they are good to go!`)),
  collapsible({
    title: "After the user has been created, how can I go to the new user’s profile?",
  }, p(`Click on the user's username in the user table and you will be taken to the user’s profile page.
    You will be able to edit the user’s information there. Don’t forget to click the Save button once
    you’ve made your changes!`)),
  collapsible({
    title: "Can I verify a user during the creation process?",
  }, p(`Open the user profile by pressing on a username on the Manage Users main page.
    Yes, you can absolutely verify a user during the creation process!`)),

  h3("Add Users - Bulk Add"),
  collapsible({
    title: "What does User Bulk Add do?",
  }, p(`If you need to add several users at once, you will want to use this feature instead of
    clicking on the Add New user page.`)),
  collapsible({
    title: "How does this process work from start to finish?",
  }, [
    p(`Step 1 - Click on Bulk Add on the Manage Users Page- this will take you to the User Bulk Creation page`),
    p(`Step 2- Add users here manually or by importing a CSV.`),
    p(`To use the CSV template:`),
    ol([
      li("Click the Import CSV button"),
      li("Upload the completed CSV"),
      li(`Make the necessary modifications after CSV is uploaded - the system will let you know which newly
      uploaded users have any issues with their info`),
    ]),
    p(`Step 3 - Once everything has been verified/corrected on your end, Press Save and the users will
    be added to the system.`),
  ]),
  collapsible({
    title: "What is a CSV? Can I download a copy of this?",
  }, p(`CSV is short for “comma separated values.” These files are like a text file version of a spreadsheet.
    And yes- you can download the CSV template on the Bulk Creation page!`)),
  collapsible({
    title: "How do I convert a Microsoft Excel spreadsheet into CSV?",
  }, p(`Open the Microsoft Excel spreadsheet and select File. Click Save As and select .csv, then save the file.`)),
  collapsible({
    title: "How do I convert a Google Spreadsheet into CSV?",
  }, p(`Open your Sheets file and select File. Click Download and select Comma-separated values.`)),
  collapsible({
    title: "Where do I upload the CSV?",
  }, p(`Click IMPORT CSV to upload your CSV.`)),
  collapsible({
    title: "What if I need to make any changes to the user information once the CSV has been uploaded?",
  }, p(`We will let you know if there are any issues with that user’s profile. You can also always update a
    user’s information once the CSV has been uploaded (except for the username).`)),
  collapsible({
    title: "What if my internet connection drops? Will I have to restart the process?",
  }, p(`Unfortunately yes- you will need to restart the process if your internet connection drops before the
    CSV can be fully imported.`)),
  collapsible({
    title: "How do I save uploaded CSV with the parsed user information?",
  }, p(`Once you upload the CSV file, make sure to press Save!`)),
  collapsible({
    title: "What if I go to the previous page and return to the Bulk Add page? Will my information be saved?",
  }, p(`Beware, your information will not be saved if you go back to the previous page. Please make sure you
    press Save before clicking out of the Bulk Add page, otherwise you will need to restart the process.`)),

  h3("User Profile"),
  collapsible({
    title: "How do I access someone’s user profile?",
  }, p(`To see the user profile of any user, click on the user's username that is hyperlinked and the user
    profile will pop right up. You will be able to edit the user’s information from here. Don’t forget to click
    Save when you’ve made your changes!`)),
  collapsible({
    title: "What fields can I edit?",
  }, p(`You can edit a user’s full name, phone number, email, password, verified status, and admin privileges.
    The one thing that cannot change is the username.`)),
  collapsible({
    title: "What if I go to the previous page and return to the Bulk Add page? Will my information be saved?",
  }, p(`Beware, your information will not be saved if you go back to the previous page. Please make sure you
    press Save before clicking out of the Bulk Add page, otherwise you will need to restart the process.`)),
  collapsible({
    title: "What do Verified and External mean?",
  }, [
    p(`Verified - this means that the user received and entered a verification code either through SMS or email.
    They need to enter the OTP in order to login. If marked in the server settings, some users are required to be
    verified before being able to login. If not, they should be able to log in without verification.`),
    p(`External - This means the user’s LDAP/external username and password management system is enabled for
    the organization. If this is marked, the user will need to use their external credentials in addition to their
    SmartEdge account information to log into the system.`),
  ]),
  collapsible({
    title: "Can I change Verified and External?",
  }, [
    p(`Verified, yes.`),
    p(`External, no.`),
  ]),
  collapsible({
    title: "What do each of the access control privileges do?",
  }, p(`Access control privileges allow you to determine a user’s permission to manage users, files, and media.
    Manage Users allows admins to control the organization’s SmartEdge user base, Manage Files permits uploading
    files, and Manage Media allows for media uploads and metadata/page creation. From the Manage Users page on the
    Admin Portal, click a username to modify the user profile. Under the Permissions header, simply toggle a
    user’s permission on/off.`)),
  collapsible({
    title: "Can I give users additional access control privileges?",
  }, p(`Yes definitely! If you want to give someone privileges related to files, media, or users, you can do
    this on an individualized basis. If someone wants to only have access to Media, then you can do this. If
    someone needs to be another admin, you can give someone access to everything.`)),
  collapsible({
    title: "Is it possible to give some users only certain access control privileges?",
  }, p(`Yes! You can customize for each user which of the three privileges they have access to. Do this by
    toggling the on/off button next to each permission in the user profile view.`)),
  collapsible({
    title: "How do I save my changes?",
  }, p(`Once you have updated the user profile, make sure to press Save`)),
  collapsible({
    title: "Are users notified of these changes?",
  }, p(`Yes! Notifications will be sent to the users if they are given additional access control privileges.`)),
  collapsible({
    title: "How do I delete an account?",
  }, p(`Click Delete. A popup will ask you to verify your decision. Please note, we cannot
    restore any deleted accounts.`)),
  collapsible({
    title: "What happens if I want to restore a deleted account? Is this possible?",
  }, p(`No, we cannot restore any deleted accounts.`)),
  collapsible({
    title: "Can I change the access control privileges over time?",
  }, p(`Yes! Just return to the user profile to toggle the permission on/off`)),

  h3("Manage Content"),
  collapsible({
    title: "How do I access the Manage Content page?",
  }, p(`Please go to the Content Section on the dashboard and click Manage`)),
  collapsible({
    title: "What do each of the categories mean?",
  }, p(`Content ID is the individual number assigned to each piece of content upon creation. This is totally
    unique to the content and cannot be changed. The title is the course name of the content, and type is
    the kind of file type it is (List, Document, or MP4).`)),
  collapsible({
    title: "What do each of the lines on the table mean?",
  }, p(`Each line represents the information about content that has been uploaded into SmartEdge.`)),
  collapsible({
    title: "How do I go to the Content Profile from this page?",
  }, p(`Click on any Content ID and the Content Profile will pop right up.`)),
  collapsible({
    title: "What happens if I need to add new content?",
  }, p(`Great! You can do that from the Manager Content interface.`)),

  h3("Add Content- CONTENT AND LIST"),
  collapsible({
    title: "How do I add a new piece of content?",
  }, [
    p(`Click Add Content to upload a new piece of content.`),
    p(`For VIDEO`),
    p(`Select Type: To upload a video, first select type MP4. Click Upload File to uploadcontent from your
    computer, or click Select File to select an existing file.`),
    p(`Edit Metadata: Upload a Poster file that users will see to visually identify the content.
    Add the Basic Information and click Continue.`),
    p(`Add Search Labels: Click Add Label and select the search labels. If the label you want to use does not
    exist, you can create a new label at the bottom. When you are done, click Add Labels and then click Continue.`),
    p(`Click Save, and your video will be uploaded!`),

    p(`For FILE`),
    p(`Select Type: To upload a PDF file, first select type PDF. Click Upload File to upload content from
    your computer, or click Select File to select an existing file.`),
    p(`Edit Metadata: Upload a Poster file that users will see to visually identify the content.
    Add the Basic Information and click Continue.`),
    p(`Add Search Labels: Click Add Label and select the search labels. If the label you want to use does not
    exist, you can create a new label at the bottom. When you are done, click Add Labels and then click Continue.`),
    p(`Click Save, and your PDF will be uploaded!`),

    p(`For LIST`),
    p(`Select Type: To upload a list, first select type LIST. Click Continue`),
    p(`Edit Metadata: Upload a Poster file that users will see to visually identify the content. Add the
    Basic Information and click Continue.`),
    p(`Add Playlist Items: Click Add Item to select preloaded content into the list. Add as many items as needed.
    Click Continue.`),
    p(`Add Search Labels: Click Add Label and select the search labels. If the label you want to use does not
    exist, you can create a new label at the bottom. When you are done, click Add Labels and then click Continue.`),
    p(`Click Save, and your list will be uploaded!`),
  ]),
  collapsible({
    title: "What types of files does the SmartEdge system accept?",
  }, p(`We currently accept Videos (MP4) and Files (PDF), which can be grouped into Lists. We will expand
    to multiple file formats in the near future!`)),
  collapsible({
    title: "What fields are mandatory for entry?",
  }, p(`The mandatory fields are those that are starred.`)),
  collapsible({
    title: "Can I go back and modify each field before the final content file is saved to the SmartEdge system?",
  }, p(`Yes!`)),
  collapsible({
    title: "How do I add new items to a list?",
  }, p(`Go to the Content Profile of an existing list and add the content manually under the Playlist
    Items section.`)),
  collapsible({
    title: "How do I add new labels?",
  }, p(`Go to the Content Profile of an existing list and add the labels manually under the Search
    Labels section.`)),
  collapsible({
    title: "Can you add an item to a list at the same time as the item is created?",
  }, p(`This feature is under development!`)),
  collapsible({
    title: "Can file names be the same? Can there be duplicates?",
  }, p(`No, each file must have a unique name.`)),
  collapsible({
    title: "What happens if the page freezes and I haven’t saved the content. Will the newly created content file be reset?",
  }, p(`No- unfortunately the content will not be saved. The newly created content will be reset and you will
    have to start the process from the start.`)),

  h3("Content Bulk Addition"),
  collapsible({
    title: "What does Content Bulk Add do?",
  }, p(`This feature allows you to add multiple pieces of content/content metadata at the same time.`)),
  collapsible({
    title: "How does this process work from start to finish?",
  }, [
    p(`Click Bulk Add - this will take you to the Content Bulk Creation pageMake sure you have uploaded all
    the poster and content files you intend to use before continuing. When you have done so, click I HAVE FINISHED
    UPLOADING FILES.Add content details here manually or by importing a CSV. There’s a sample CSV template available
    to download for reference`),
    p(`To use the CSV template:`),
    ol([
      li("Click Import CSV"),
      li("Upload the completed CSV"),
      li(`Make necessary modifications after CSV is uploaded - the system will let you know which newly
      uploaded content/content metadata have any issues with their info`),
      li("Press Save and content will be added to the system."),
    ]),
  ]),
  collapsible({
    title: "What types of files does the SmartEdge system accept?",
  }, p(`For now, MP4s and PDFs. We are expanding file format uploads in the near future. Stay tuned!`)),
  collapsible({
    title: "What’s the username/password for the FTP portal? Can I change these for security reasons?",
  }, p(`The username is: edgemedia, and the password is: edge@mediaThe FTP portal username/password are given
    to the organization’s SmartEdge admin when the system is deployed. The password can be changed for security
    reasons. For more help, please contact support@SmartEdge.com.`)),
  collapsible({
    title: "Do I need to upload the files in the FTP portal before anything?",
  }, p(`Yes! You will need to put the files in the FTP portal before the CSV can be uploaded.`)),
  collapsible({
    title: "How do I upload files in the FTP portal?",
  }, p(`Please click the up facing arrow on the top right of the site to upload. Select any file or folder
    located on your computer and click Open.`)),
  collapsible({
    title: "How do I manage a file in the FTP portal?",
  }, p(`Click the file you want to view. At the top right corner, click the pencil icon to rename the file, the
    trash icon to delete the file, the down facing arrow to download the file to your computer, and the info icon
    to see the file information.`)),
  collapsible({
    title: "How do I manage multiple files at once?",
  }, p(`Click the circle icon with a checkmark to select the files you want to manage. The layered rectangle
    icon allows you to copy the selected files to another location. The right facing icon allows you to move the
    selected files to another location. The trash icon allows you to delete the selected files from the FTP portal.
    The down facing arrow allows you to download the selected files to your computer.`)),
  collapsible({
    title: "What is a CSV? Can I download a copy of this?",
  }, p(`CSV is short for “comma separated values.” These files are like a text file version of a spreadsheet.
    And yes- you can download the CSV template on the Bulk Creation page!`)),
  collapsible({
    title: "How do I convert a Microsoft Excel spreadsheet into CSV?",
  }, p(`Open the Microsoft Excel spreadsheet and select File. Click Save As and select .csv, then save the file.`)),
  collapsible({
    title: "How do I convert a Google Spreadsheet into CSV?",
  }, p(`Open your Sheets file and select File. Click Download and select Comma-separated values.`)),
  collapsible({
    title: "Where do I upload the CSV?",
  }, p(`Click IMPORT CSV to upload your CSV.`)),
  collapsible({
    title: "What if I need to make any changes to the user information once the CSV has been uploaded?",
  }, p(`The system will let you know if there are any issues with that content’s information.
    You can also always update a content info once the CSV has been uploaded.`)),
  collapsible({
    title: `What happens if I upload the CSV without adding any files to the FTP portal? How do I go back
    and upload files to the FTP portal?`,
  }, p(`You will need to go back to the FTP portal from the Bulk Creation page. Login info will be provided
    upon SmartEdge deployment. Please contact support@SmartEdge.com for more help.`)),
  collapsible({
    title: "What if my internet connection drops? Will I have to restart the process?",
  }, p(`Unfortunately yes, you will need to restart the process if your internet connection drops before the
    CSV can be fully imported.`)),
  collapsible({
    title: "How do I save uploaded CSV with the parsed user information?",
  }, p(`Once you upload the CSV file, make sure to press Save!`)),
  collapsible({
    title: "What if I go to the previous page and return to the Bulk Add page? Will my information be saved?",
  }, p(`Your information will not be saved if you do this. Please make sure you press Save
    before clicking out of the Bulk Creation page.`)),

  h3("Content Profile"),
  collapsible({
    title: "How do I access a content profile?",
  }, p(`Please go to the Content Section in the dashboard and press Manage.
    From there, click on the Content ID to see the content profile.`)),
  collapsible({
    title: "What types of files does the SmartEdge system accept?",
  }, p(`MP4s and PDFs for now.`)),
  collapsible({
    title: "What fields can I edit?",
  }, p(`Anything that is not starred.`)),
  collapsible({
    title: "Can I change the video file or list image?",
  }, p(`Yes!`)),
  collapsible({
    title: "How can I change items in a list?",
  }, p(`Go to the Content Profile of an existing list and manually edit the content under the
    Playlist Items section.`)),
  collapsible({
    title: "Can I change the UUID?",
  }, p(`No - the UUID cannot be changed.`)),
  collapsible({
    title: "How do I save my changes?",
  }, p(`Press Save and you’re good to go!`)),
  collapsible({
    title: "Are users notified of these changes?",
  }, p(`No - this step isn’t needed.`)),
  collapsible({
    title: "How do I delete the content file?",
  }, p(`Press Delete and then confirm that you actually want to delete the file. Please note, we cannot
    restore any deleted content.`)),
  collapsible({
    title: "What happens if I want to restore a deleted content file? Is this possible?",
  }, p(`Unfortunately, we will not be able to restore any files that have been deleted at this time.`)),
  collapsible({
    title: "Can I add a content file to more than one list?",
  }, p(`Yes!`)),

  h3("Manage Pages"),
  collapsible({
    title: "How do I access the Manage Pages page?",
  }, p(`Once on the admin site, please go to the Site Section on the dashboard and then click on Manage Pages.`)),
  collapsible({
    title: "What do each of the categories mean?",
  }, p(`ID is an individual number assigned to a page. This is totally unique to the page and cannot be
    changed. The title is the page name that will appear at the top of the site page. The URL extension is the text
    input field that indicates the website URL designated to find that page
    (i.e. https://www.institution.com/url_extension)`)),
  collapsible({
    title: "What do each of the lines on the table mean?",
  }, p(`Each line represents the information about the page that has been uploaded into SmartEdge.`)),

  h3("Page Profile"),
  collapsible({
    title: "How do I access a page profile?",
  }, p(`Please go to the Site Section of the dashboard and then click Manage Pages. From there, click on
    the ID to see the page profile.`)),
  collapsible({
    title: "What fields can I edit?",
  }, p(`You can edit the page title, URL extension, and sections of the page.`)),
  collapsible({
    title: "What types of sections can I add to a page?",
  }, [
    p(`Text: This section type adds a text block to the existing page. If you want to post a course
    description or notification, you can do this on the page itself. Add a Title and Description/Corresponding Text.
    After you’re finished, click Finish Editing to save the changes. Don’t forget to press Add or Save at the
    bottom of the existing course page!`),
    p(`Slider: This section adds new slider images to the course page. You can choose however many slider
    images you would like. Please make sure each image is 1800 by 720 pixels in size and saved in JPEG format.
    After the images are uploaded, please click Finish Editing. Don’t forget to press Add or Save at
    the bottom of the existing course page!`),
    p(`Playlist Carousel: This section type adds an existing playlist to the page in carousel format.
    Carousel format allows end-users to navigate through a playlist with forward and backward arrows. If a playlist
    titled ‘Computer Science 101’ is added to the content library, you can take this newly created list and display
    it in carousel format. Add a title, choose the playlist, and click Finish Editing. Don’t forget to press Add or
    Save at the bottom of the existing course page!`),
    p(`Playlist Grid: This section type adds an existing playlist to the page in grid format.
    Grid format allows end-users to navigate through a playlist by scrolling down the screen. The content will be
    displayed side by side in multiple rows. If a playlist titled ‘Computer Science 101’ is added to the content
    library, you can take this newly created list and display it in grid format. Add a title, choose the playlist,
    and click Finish Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
    p(`Dynamic Carousel: This section type adds a preset playlist to the page in carousel format. Preset
    playlists include Recently Added, Recently Watched, Most Watched, Trending, and other options. Carousel format
    allows end-users to navigate through a playlist with forward and backward arrows. Add a title, choose the
    preset playlist, and click Finish Editing. Don’t forget to press Add or Save at the bottom of the
    existing course page!`),
    p(`Dynamic Grid: This section type adds a preset playlist to the page in grid format. Grid format
    allows end-users to navigate through a playlist by scrolling down the screen. Preset playlists include
    Recently Added, Recently Watched, Most Watched, Trending, and other options. Add a title, choose the playlist,
    and click Finish Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
  ]),
  collapsible({
    title: "How do I save my changes?",
  }, p(`Press Save and you’re good to go!`)),
  collapsible({
    title: "Are users notified of these changes?",
  }, p(`No - this step isn’t needed.`)),
  collapsible({
    title: "How do I delete the content file?",
  }, p(`Press Delete and then confirm that you actually want to delete the file.
    Please note, we cannot restore any deleted content.`)),
  collapsible({
    title: "What happens if I want to restore a deleted page? Is this possible?",
  }, p(`Unfortunately, we will not be able to restore any pages that have been deleted at this time.`)),

  h3("Add/Edit Page"),
  collapsible({
    title: "How do I add a new page?",
  }, [
    p(`From the Site Section on the dashboard, Click Add Page to create a new page. Add a title and press
    the green circle arrow icon to generate a unique URL extension. You can also add your own customized URL
    extension in the text box- feel free to choose whatever URL extension you like! Although please make sure
    that there are no spaces in the intended extension.`),
    p(`After this step, click Add Section and choose whatever page extensions you would like to add
    to the newly created page.`),
    p(`After you have finished these two steps, click Add, and your page will be created! Don’t forget
    to add the newly created page to the Sidebar Menu for easy user access.`),
  ]),
  collapsible({
    title: "What types of sections can I add to a page?",
  }, [
    p(`Text: This section type adds a text block to the existing page. If you want to post a course
    description or notification, you can do this on the page itself. Add a Title and Description/Corresponding Text.
    After you’re finished, click Finish Editing to save the changes. Don’t forget to press Add or Save at
    the bottom of the existing course page!`),
    p(`Slider: This section adds new slider images to the course page. You can choose however many
    slider images you would like. Please make sure each image is 1800 by 720 pixels in size and saved in JPEG format.
    After the images are uploaded, please click Finish Editing. Don’t forget to press Add or Save
    at the bottom of the existing course page!`),
    p(`Playlist Carousel: This section type adds an existing playlist to the page in carousel format.
    Carousel format allows end-users to navigate through a playlist with forward and backward arrows. If a
    playlist titled ‘Computer Science 101’ is added to the content library, you can take this newly created
    list and display it in carousel format. Add a title, choose the playlist, and click Finish Editing. Don’t
    forget to press Add or Save at the bottom of the existing course page!`),
    p(`Playlist Grid: This section type adds an existing playlist to the page in grid format.
    Grid format allows end-users to navigate through a playlist by scrolling down the screen. The content will be
    displayed side by side in multiple rows. If a playlist titled ‘Computer Science 101’ is added to the content
    library, you can take this newly created list and display it in grid format. Add a title, choose the playlist,
    and click Finish Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
    p(`Dynamic Carousel: This section type adds a preset playlist to the page in carousel format. Preset
    playlists include Recently Added, Recently Watched, Most Watched, Trending, and other options. Carousel format
    allows end-users to navigate through a playlist with forward and backward arrows. Add a title, choose the
    preset playlist, and click Finish Editing. Don’t forget to press Add or Save at the bottom of the
    existing course page!`),
    p(`Dynamic Grid: This section type adds a preset playlist to the page in grid format. Grid format
    allows end-users to navigate through a playlist by scrolling down the screen. Preset playlists include
    Recently Added, Recently Watched, Most Watched, Trending, and other options. Add a title, choose the playlist,
    and click Finish Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
  ]),
  collapsible({
    title: "What fields are mandatory for entry?",
  }, p(`The mandatory fields are those that are starred.`)),
  collapsible({
    title: "Can I go back and modify each field before the final content file is saved to the SmartEdge system?",
  }, p(`Yes!`)),
  collapsible({
    title: "How do I add new sections to a page?",
  }, p(`Go to the Edit Page of an existing page and manually add the sections under Sections
    section of the page.`)),
  collapsible({
    title: "Can page names be the same? Can URL extensions be the same?",
  }, p(`You can have the same page names, but each URL extension must be unique.`)),
  collapsible({
    title: "What happens if the page freezes and I haven’t saved the page? Will the page be created?",
  }, p(`No- unfortunately the page will not be created. You will have to start the process from scratch.`)),

  h3("Edit Sidebar Menu"),
  collapsible({
    title: "How do I edit the Sidebar?",
  }, p(`On the Site Section on the dashboard, click Edit Menu to edit the Sidebar Menu. Once there,
    the existing sidebar menu will be visible.`)),
  collapsible({
    title: "How do I add a new entry to the Sidebar Menu?",
  }, p(`Press Add Entry to add a new link. This is the blue button with the + sign on the page`)),
  collapsible({
    title: "What types of entries are allowed?",
  }, [
    p(`Select from 3 link options- External Link, Page Link, or Sub-Menu.`),
    p(`External link allows links to external sites that aren't on the SmartEdge installation. For instance,
    a university can link to their main page or another relevant page that needs to be associated with SmartEdge
    (such as an existing LMS or assignment dropbox).`),
    p(`Page link allows links to internal SmartEdge pages. If you have created a new page, you can
    link this directly through the sidebar menu.`),
    p(`Sub-menu allows for parent and child menus to be created within the existing sidebar menu. If you want
    to have a collection of external links, you can group these within an External sub-menu option.`),
  ]),
  collapsible({
    title: "How do I save my changes?",
  }, p(`When you are done, press Save.`)),

  h3("Grafana"),
  collapsible({
    title: "How to access Grafana?",
  }, [
    p(`SmartEdge customers have access to Grafana (grafana.SmartEdge.com) that provides monitoring of the
    SmartEdge installation where it is running.`),
    p(`Grafana is an open source analytics and interactive visualization application that provides charts,
    graphs, and alerts for SmartEdge when connected to SmartEdge’s data sources.`),
    p(`When the SmartEdge installation has been deployed, the Grafana customer environment will be
    created alongside the SmartEdge system`),
    p(`To access Grafana, the admin has to visit grafana.SmartEdge.com and request a password
    reset under on the main login page.`),
    p(`The admin will provide the main email account associated with the SmartEdge
    installation and press Send to reset their password.`),
    p(`The main email account will receive an email from Grafana with a
    password reset link. Follow the link and set a new account password.`),
    p(`After this step, the admin can access Grafana with the provided credentials.`),
  ]),
]);

export default async function adminFaq(selector) {
  const title = "Admin FAQ";
  const views = dashboardLayout(
    selector,
    [
      widget([
        div("#admin-faq"),
      ]),
    ],
    title,
    true,
  );

  const { header, loading } = views;

  loading.show();

  const [user] = await Promise.all([
    cache.getProfile(),
  ]);

  const state = {
    user,
    title,
  };

  header.update(state);
  view.create(showAdminFaq)("#admin-faq", state);
  loading.hide();
}
