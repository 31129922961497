/**
 * A confirmation dialog body for modals.
 *
 * @module ui/component/modal/confirm
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { button, p } from "ui/html";

const andClose = (view, cb, isAsync) => async () => {
  if (view && !isAsync) {
    cb(view);
    view.back();
  } else await cb();
};

const danger = (dangerous) => (dangerous ? ".danger" : "");

const warning = (warn) => (warn ? ".warn" : "");

/**
 * This should normally be launched from `ModalView.confirm()`, which hooks
 * up the view automatically. When `view` parameter is provided the dialog will
 * self-close after executing the appropriate callback function.
 *
 * @param {Object} config
 * @param {string|null} [config.title=null] the dialog title
 * @param {string|null} [config.body=null] the dialog body
 * @param {string} [config.confirmLabel="Yes"] the text for the confirm button
 * @param {string} [config.cancelLabel="Yes"] the text for the cancel button
 * @param {boolean} [isAsync=false] whether the dialog is being used asynchronously
 * @param {function} [config.onConfirm] called when the dialog is confirmed
 * @param {function} [config.onCancel] called when the dialog is canceled
 * @param {string} [dangerous=false] whether the dialog should indicate a dangerous
 *                                   action
 * @param {module:ui/view/modal-view~ModalView} view
 * @param {string} sel additional css selectors for the dialog wrapper
 *
 * @return {module:ui/common/el~El}
 */
export default function confirm({
  title = null,
  body = null,
  confirmLabel = "Yes",
  cancelLabel = "No",
  isAsync = false,
  onConfirm = () => {},
  onCancel = () => {},
  dangerous = false,
  showCloseFab = false,
  warn = false,
  selector = "",
}, modalView) {
  return dialog({
    sel: `${selector}.confirm${danger(dangerous)}`,
    header: title || null,
    body: body ? p(body) : null,
    footer: [
      button(
        confirmLabel,
        andClose(modalView, onConfirm, isAsync),
        danger(dangerous),
      ),
      button(
        cancelLabel,
        andClose(modalView, onCancel, isAsync),
        `${warn ? "" : ".inverse"}${danger(dangerous)}${warning(warn)}`,
      ),
    ],
    withCloseFab: showCloseFab,
    isAsync,
  }, modalView);
}
