/**
 * Analytics API
 *
 * @module api/v2/analytics
 * @category Backend API v2
 * @subcategory Analytics
 */
import xs from "xstream";
import { io } from "socket.io-client";
import pageVisitEvent from "@smartedge/em-message/types/analytics/page-visit";
import pageDurationEvent from "@smartedge/em-message/types/analytics/page-duration";
import pageDurationDeferredEvent from "@smartedge/em-message/types/analytics/page-duration-deferred";
import videoProgressEvent from "@smartedge/em-message/types/analytics/progress-video";
import { analyticsEvents as types } from "@smartedge/em-message/constants";
import { TOKEN_KEY } from "model/authentication/constants";
import cache from "cache";
import getConfig from "config";
import log from "log";
import { post, get } from "api/request";
import { endpoints, services } from "api/constants";
import {
  makeAnalyticsRequestDTO,
  responseToAnalyticContentDurationEntry,
  responseToAnalyticEntry,
  responseToAnalyticsEvaluation,
} from "model/analytics";
import { getDateWeekAgo } from "util/date";

const DEBUG = false;

const server$ = xs.create();

let initializing = false;
let authenticating = false;
let socketInternal;
let authState;

const makeAuthRequest = (partial) => ({ accessToken: partial.accessToken });

const waitForSocket = () => new Promise((resolve, reject) => {
  if (DEBUG) log.debug("ANALYTICS_SERVICE: already initializing, waiting for socket");
  const interval = setInterval(() => {
    if (socketInternal) {
      if (DEBUG) log.debug("ANALYTICS_SERVICE: deferred initialization complete");
      clearInterval(interval);
      resolve(socketInternal);
    } else if (!initializing) {
      if (DEBUG) log.debug("ANALYTICS_SERVICE: deferred initialization failed");
      reject();
    }
  }, 100);
});

const waitForAuth = () => new Promise((resolve, reject) => {
  let tries = 0;
  const interval = setInterval(() => {
    if (!initializing && !authenticating) {
      clearInterval(interval);
      resolve(authState);
    }
    if (tries > 10) {
      if (DEBUG) log.debug("ANALYTICS_SERVICE: gave up waiting on authentication :(");
      clearInterval(interval);
      reject(authState);
    }
    tries++;
  }, 100);
});

/**
 * Dispatches an authentication request.
 *
 * This should be run whenever access token is updated.
 *
 * @function authenticate
 * @return {Promise<void>}
 */
export const authenticate = async () => {
  if (!socketInternal && initializing) await waitForSocket();
  else if (!socketInternal) return false; // socket failed to initialize or called too early
  if (authenticating) return waitForAuth();
  const tokenPair = cache.getObject(TOKEN_KEY);
  if (!tokenPair) return false;
  const { accessToken } = tokenPair;
  if (!accessToken) {
    log.error("ANALYTICS_SERVICE: requested authentication with no token pair", tokenPair);
    return false;
  }
  if (DEBUG) log.debug("ANALYTICS_SERVICE: authenticating");

  authenticating = true;
  socketInternal.emit(types.AUTH_REQUEST, makeAuthRequest({ accessToken }));
  return waitForAuth();
};

/**
 * Updates authState based on auth response message.
 *
 * @function handleAuthResponse
 * @private
 * @param {AuthenticationResponseMessage} response
 */
const handleAuthResponse = async (response) => {
  authenticating = false;
  if (response.status) authState = true;
  else authState = false;
  if (DEBUG) log.debug("ANALYTICS_SERVICE: auth response", authState);
};

/**
 * Initializes the socket if it's not already initialized.
 *
 * Should be run before each message sent to MBE, to ensure connection
 * is alive and streams are set up.
 *
 * @function initSocket
 * @private
 * @async
 * @returns {Promise<void>}
 */
export const initSocket = async () => {
  const { analyticsServer } = await getConfig();
  if (!analyticsServer?.host || !analyticsServer?.port) {
    if (DEBUG) log.debug("ANALYTICS_SERVICE: unavailable");
    return {
      emit: (e) => {
        if (DEBUG) log.debug("ANALYTICS_SERVICE: dropped message", e);
      },
    };
  }
  if (initializing) {
    if (DEBUG) log.debug("ANALYTICS_SERVICE: already initializing, wait for complete");
    await waitForSocket();
  }
  if (authenticating) {
    if (DEBUG) log.debug("ANALYTICS_SERVICE: already authenticating, wait for complete");
    await waitForAuth();
  }
  if (socketInternal) return socketInternal;
  initializing = true;
  if (DEBUG) log.debug("ANALYTICS_SERVICE: initializing", analyticsServer);
  socketInternal = io(`https://${analyticsServer.host}:${analyticsServer.port}`);

  socketInternal.on(types.AUTH_RESPONSE, handleAuthResponse);

  socketInternal.on(types.CONNECT, () => {
    initializing = false;
    authenticate()
      .catch((e) => log.error(e, "error while pre-authenticating for analytics socket"));
  });

  const handlers = new Map();

  // we don't receive many responses or updates from analytics (unlike messaging)
  // but still need to listen for errors and other data
  server$.imitate(xs.create({
    start: (stream) => {
      [...types].forEach((type) => {
        handlers.set(type, (message) => {
          if (DEBUG) log.debug("ANALYTICS_SERVICE:", message);
          stream.next({ type, message });
        });
        socketInternal.on(type, handlers.get(type));
      });
    },
    stop: () => {
      if (DEBUG) log.debug("ANALYTICS_SERVICE: stopping server stream");
      [...types].forEach((type) => {
        socketInternal.off(type, handlers.get(type));
      });
    },
  }));
  await waitForAuth();
  return socketInternal;
};

/**
 * Subscribes to the stream of system messages.
 *
 * If type is provided, filters by only that message type.
 *
 * ```
 * --Connect---Info-Info---Disconnect
 * ```
 *
 * @function getServerStream
 * @param {?AnalyticsEventType} type
 * @return {Stream}
 */
export const getServerStream = (type) => {
  if (!type) {
    return xs.merge(server$);
  }
  if (types.has(type)) {
    return server$.filter((m) => m.type === type);
  }
  return xs.create();
};

/**
 * Check if analytics is authenticated successfully.
 *
 * @function getAuthState
 * @returns {boolean} true if authenticated
 */
export const getAuthState = () => authState;

/**
 * Emit a page visit event. Should be done during setup in each page controller.
 *
 * @function pageVisit
 * @param {string} pageKey url path for the page
 * @param {?UUID} pageId page id, for dynamic pages
 */
export const pageVisit = async (pageKey, pageId = null) => {
  const socket = await initSocket();
  if (DEBUG) log.debug("ANALYTICS_SERVICE: sending pageVisit", pageKey);
  socket.emit(types.PAGE_VISIT, pageVisitEvent({
    tags: {
      pageKey,
      visitedBy: cache.getProfile()?.id || null,
    },
    fields: {
      pageId,
      ua: window.navigator.userAgent,
    },
  }));
};

/**
 * Emit a page duration event. These are set up automatically during page initialization.
 *
 * @link {module:ui/common}
 *
 * @function pageDuration
 * @param {object} partial
 * @param {UUID} partial.pageKey url path for the page
 * @param {UUID} partial.sessionKey unique key for the viewing session
 * @param {?string} partial.hash page hash (if any)
 * @param {?string} partial.queryString page query string (if any)
 * @param {ISO8601Timestamp} partial.startTime
 * @param {ISO8601Timestamp} partial.endTime
 * @param {int} partial.duration
 */
export const pageDuration = async ({
  pageKey,
  sessionKey,
  hash,
  queryString,
  startTime,
  endTime,
  duration,
}) => {
  const socket = await initSocket();
  if (DEBUG) log.debug("ANALYTICS_SERVICE: sending pageDuration", pageKey);
  socket.emit(types.PAGE_DURATION, pageDurationEvent({
    tags: {
      pageKey,
      sessionKey,
      visitedBy: cache.getProfile()?.id || null,
    },
    fields: {
      hash,
      queryString,
      startTime,
      endTime,
      duration,
    },
  }));
};

/**
 * Emit a deferred page duration event. These are set up automatically during page initialization.
 *
 * @link {module:ui/common}
 *
 * @function pageDurationDeferred
 * @param {object} partial
 * @param {UUID} partial.pageKey url path for the page
 * @param {UUID} partial.sessionKey unique key for the viewing session
 * @param {?string} partial.hash page hash (if any)
 * @param {?string} partial.queryString page query string (if any)
 */
export const pageDurationDeferred = async ({
  pageKey,
  sessionKey,
  hash,
  queryString,
}) => {
  const socket = await initSocket();
  if (DEBUG) log.debug("ANALYTICS_SERVICE: sending pageDurationDeferred", pageKey);
  socket.emit(types.PAGE_DURATION_DEFERRED, pageDurationDeferredEvent({
    tags: {
      pageKey,
      sessionKey,
      visitedBy: cache.getProfile()?.id || null,
    },
    fields: {
      hash,
      queryString,
    },
  }));
};

/**
 * Emit a page visit event. Should be done during setup in each page controller.
 *
 * @function pageVisit
 * @param {UUID} contentKey id of the video being watched
 * @param {UUID} sessionKey unique id for the current viewing session
 * @param {int} videoTime current video timestamp in milliseconds
 * @param {int} elapsed real playback time since last event, in milliseconds
 */
export const videoProgress = async (contentKey, sessionKey, videoTime, elapsed) => {
  const socket = await initSocket();
  const watchedBy = cache.getProfile()?.id || null;
  if (DEBUG) log.debug("ANALYTICS_SERVICE: sending videoProgress", contentKey, sessionKey, watchedBy, videoTime, elapsed);
  socket.emit(types.PROGRESS_VIDEO, videoProgressEvent({
    tags: {
      contentKey,
      sessionKey,
      watchedBy,
    },
    fields: {
      videoTime,
      elapsed,
    },
  }));
};

/**
 * Get page views
 *
 * @param {AnalyticsRequest} partial
 * @returns {Promise<AnalyticsEntry[]>}
 */
export const getPageViews = async (partial) => {
  try {
    const response = (await post(
      endpoints.ANALYTICS_PAGE_VIEWS,
      null,
      makeAnalyticsRequestDTO(partial),
      false,
      services.RABE,
    )).body;
    return response.map(responseToAnalyticEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get page views by users
 *
 * @param {AnalyticsRequest} partial
 * @returns {Promise<AnalyticsEntry[]>}
 */
export const getPageViewsByUsers = async (partial) => {
  try {
    const response = (await post(
      endpoints.ANALYTICS_PAGE_VIEWS_USERS,
      null,
      makeAnalyticsRequestDTO(partial),
      false,
      services.RABE,
    )).body;
    return response.map(responseToAnalyticEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get assessments evaluation statuses
 *
 * @param {string} courseId
 * @returns {Promise<AnalyticsEvaluation[]>}
 */
export const getAssessmentStatusesByCourseId = async (courseId) => {
  try {
    const response = (await get(
      `${endpoints.ANALYTICS_ASSESSMENTS}/${courseId}`,
      null,
      null,
      true,
      null,
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsEvaluation);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get assessments evaluation statuses by course and assessment id
 *
 * @param {string} courseId
 * @param {string} assessmentId
 * @returns {Promise<AnalyticsEvaluation[]>}
 */
export const getAssessmentStatusesByCourseIdAndAssessmentId = async (
  courseId,
  assessmentId,
) => (await getAssessmentStatusesByCourseId(courseId))
  .filter((status) => status.assessmentId === assessmentId);

/**
 * Get content watch progress
 *
 * @param {string} contentId
 * @param {AnalyticsRequest} partial
 * @returns {Promise<AnalyticsContentDurationEntry[]>}
 */
export const getContentDuration = async (contentId, partial) => {
  try {
    const response = (await post(
      endpoints.ANALYTICS_CONTENT_DURATION,
      null,
      makeAnalyticsRequestDTO({
        startDate: partial?.startDate || getDateWeekAgo(),
        endDate: partial?.endDate || new Date(),
        contentId,
      }),
      false,
      services.RABE,
    )).body;
    return response
      .map(responseToAnalyticContentDurationEntry)
      // Temporary. RABE don't filter by content id
      .filter((duration) => duration.contentId === contentId);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get write analytics service info.
 *
 * @function wabeInfo
 * @return {Promise.<Object>}
 */
export const wabeInfo = async () => (
  await get.service(services.WABE)(endpoints.WABE_INFO)
).body;
