/**
 * Admin page for evaluation profile page.
 *
 * @module ui/page/admin/evaluation/profile
 * @category Pages
 * @subcategory Admin - Evaluations
 */
import api from "api";
import log from "log";
import { featureTypes } from "model/config/constants";
import { EXPAND_ASSESSMENTS, EXPAND_GRADING } from "api/course/constants";
import { courseEntryType, evaluationStatusFriendly, questionType } from "model/course/constants";
import cache from "cache";
import {
  CACHE_COURSE_ASSESSMENT_KEY_V2,
  CACHE_COURSE_EVALUATION_KEY_V2,
} from "cache/constants";
import { getQueryParams } from "util/navigation";
import icon from "ui/component/icon";
import actionBar from "ui/component/dashboard-action-bar";
import widget from "ui/component/dashboard-widget";
import view from "ui/view";
import {
  a,
  div,
  h3,
  span,
} from "ui/html";
import cameoSolution from "ui/component/cameo-solution";
import form from "ui/component/form-managed";
import formManagedView from "ui/view/form-managed";
import button from "ui/component/form-managed/button";
import { usFullDateFormat } from "util/date";
import { merge } from "util/object";
import dashboardLayout from "ui/page/layout/dashboard";
import cameoLecture from "ui/component/cameo-lecture";
import cameoModule from "ui/component/cameo-module";
import badge from "ui/component/badge";
import { sortOrders } from "ui/component/dynamic-table";
import userEvaluationStatus from "ui/page/admin/assessment/user-evaluation-status";
import { fetchAndOpenEvaluationByEvalStatus } from "util/assessments";
import { getAssessmentStatusesByCourseIdAndAssessmentId } from "api/v2/analytics";
import { getExtendedAnalyticsEvaluations, calculateScore } from "util/course";
import { guard } from "util/feature-flag";
import { staticMessages } from "./manage/state";
import { countSolutionsPoints, fullName, getEvaluationInstructor } from "./manage/common";

let loadingView;
let modalView;
let notificationView;
let userProfileView;
let evaluationProfileView;
let assessmentSectionsView;

const buildFields = (self) => {
  const { state } = self;
  const { evaluation, instructor } = state;
  const { assessment, course } = evaluation;
  const module = course.modules.find((m) => m.id === assessment.moduleId);
  const modalViewIfHasId = assessment.id ? undefined : modalView;
  return [
    div("", [
      div(".key-value-container", [
        span("User", ".label"),
        span(a(fullName(evaluation.student), `/admin/user-profile?id=${evaluation.student.id}`), ".value"),
      ]),
      div(".key-value-container", [
        span("Instructor", ".label"),
        span(a(fullName(instructor), `/admin/user-profile?id=${instructor.id}`), ".value"),
      ]),
      div(".key-value-container", [
        span("Course", ".label"),
        span(a(course.title, `/admin/course-profile?id=${course.id}`), ".value"),
      ]),
      div(".key-value-container", [
        span("Course Module", ".label"),
      ]),
      div(`.module-entry.button-course-module${assessment.id ? ".disabled" : ""}`,
        module.title === courseEntryType.SINGLE_LECTURE
          ? cameoLecture(
            {
              course,
              module,
              lecture: module?.lectures?.[0],
              controls: [button.icon({
                icon: "external-link",
                onClick: () => window.open(
                  `/admin/course-profile?id=${course.id}&moduleId=${module.id}`,
                  "_blank",
                ),
              })],
            },
            modalViewIfHasId,
            notificationView,
          )
          : cameoModule(
            {
              course,
              module,
              controls: [button.icon({
                icon: "external-link",
                onClick: () => window.open(
                  `/admin/course-profile?id=${course.id}&moduleId=${module.id}`,
                  "_blank",
                ),
              })],
            },
            modalViewIfHasId,
            notificationView,
          )),
      div(".key-value-container.wrap", [
        span("Description", ".label"),
        span(evaluation.assessment.description, ".value"),
      ]),
    ]),
  ];
};

const doSave = async () => {
  userProfileView.setFullValidation(true);
  const validation = userProfileView.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  const { state } = userProfileView;
  const { evaluation } = state;
  try {
    loadingView.show();
    const final = {
      ...evaluation,
      assessmentId: evaluation.assessment.id,
      studentId: evaluation.student.id,
      submitted: true,
    };
    const response = await api.course.updateEvaluation(evaluation.course.id, {
      ...final,
    }, true);
    // FIXME hacky cache invalidation
    cache.deleteExpiringMapEntry(CACHE_COURSE_ASSESSMENT_KEY_V2, evaluation.assessment.id);
    cache.deleteExpiringMapEntry(CACHE_COURSE_EVALUATION_KEY_V2, evaluation.id);
    userProfileView.update({
      evaluation: response,
    });
    notificationView.post(staticMessages.success);
  } catch (e) {
    log.error(e, "in evaluation profile doSave");
    notificationView.post(staticMessages.backendError);
  } finally {
    loadingView.hide();
  }
};

const showEvaluationProfileForm = (self) => {
  const fields = buildFields(self);

  return form(
    "#evaluation-profile-form",
    fields,
  );
};

const showEvaluationProfileView = (self) => {
  const { state } = self;
  const { evaluation } = state;
  const { course, assessment } = evaluation;
  return div(".evaluation-profile-container", [
    div(".key-value-container", [
      span("Start Date", ".label"),
      span(assessment.startDate ? usFullDateFormat(assessment.startDate) : "No start date", ".value"),
    ]),
    div(".key-value-container", [
      span("Stop Date", ".label"),
      span(assessment.endDate ? usFullDateFormat(assessment.endDate) : "No end date", ".value"),
    ]),
    div(".key-value-container", [
      span("Grading Scheme", ".label"),
      span(course.gradingScheme?.description, ".value"),
    ]),
    div(".key-value-container", [
      span("Status", ".label"),
      span(badge(
        evaluationStatusFriendly.get(evaluation.status),
      ), ".value"),
    ]),
    div(".key-value-container", [
      span("Result", ".label"),
      span(
        calculateScore(
          course.gradingScheme,
          evaluation?.score?.points,
          assessment.maxPoints,
        ) || "N/A", ".value"),
    ]),
  ]);
};

const onAnalyticsSortChange = (sortColumn) => {
  const { state } = userProfileView;
  let sortOrder;
  if (sortColumn === state.evaluationsTable.sortColumn) {
    if (state.evaluationsTable.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  userProfileView.update({
    evaluationsTable: {
      sortOrder,
      sortColumn,
    },
  });
};

const showEvaluationsTable = ({ state }) => userEvaluationStatus({
  evaluationStatuses: state.evaluationStatuses,
  users: state.users,
  sortColumn: state.evaluationsTable.sortColumn,
  sortOrder: state.evaluationsTable.sortOrder,
  onSortChange: onAnalyticsSortChange,
  onRowClick: async (evalStatus) => {
    await fetchAndOpenEvaluationByEvalStatus(evalStatus, loadingView);
  },
});

const showAssessmentSectionsView = (self) => {
  const { state } = self;
  const { evaluation, user, files } = state;
  const { assessment } = evaluation;
  return div(".solutions-container", { attrs: { role: "group" } }, [
    evaluation.student.id === user.id
      ? span([icon("exclamation-triangle"), "You are unable to grade your own evaluations."], ".accent")
      : div(
        ".cameo-container",
        evaluation.solutions.map((solution) => (solution.question
          ? div(".cameo-row", [
            cameoSolution({
              solution,
              files,
              onGraded: (updatedSolution) => {
                userProfileView.update({
                  evaluation: {
                    solutions: evaluation.solutions.map((ev) => {
                      if (ev.id === updatedSolution.id) {
                        return {
                          ...ev,
                          score: updatedSolution.score,
                        };
                      }
                      return ev;
                    }),
                  },
                });
              },
              controls: [
                span(solution?.score?.points || 0, ".awarded-points"),
              ],
            }),
          ])
          : "")),
      ),
    div(".total-points-container", { attrs: { role: "group" } }, [
      span("Points: "),
      span(`${countSolutionsPoints(evaluation.solutions)} / ${assessment.maxPoints}`),
    ]),
  ]);
};

const showActionButtons = (self) => div("#actions", [
  div(".title", h3(self.state.evaluation.assessment.title)),
  div(".buttons", [
    button.primary({
      icon: "save",
      label: "Save",
      disabled: userProfileView.state.saveDisabled,
      onClick: () => doSave(),
    }),
  ]),
]);

const doUpdate = (theView) => {
  theView.patch(showEvaluationProfileForm(theView));
  assessmentSectionsView?.update(theView.state);
  evaluationProfileView?.update(theView.state);
};

export default async function evaluationProfile(selector) {
  guard(featureTypes.LMS);
  const { courseId, evaluationId } = getQueryParams();

  const user = cache.getProfile();
  const evaluation = await api.course.getEvaluationById(
    courseId,
    evaluationId,
    merge.all([EXPAND_GRADING, EXPAND_ASSESSMENTS]),
  );
  const evaluationStatuses = await getAssessmentStatusesByCourseIdAndAssessmentId(
    courseId,
    evaluation.assessment.id,
  );
  const grants = await api.acl.listCourseGrants(evaluation.course);
  const { title } = evaluation.assessment;
  const users = await api.user.list();
  const descriptorsToFind = [];
  evaluation.solutions.forEach((solution) => {
    if (solution.question.questionType === questionType.UPLOAD && solution.value[0]) {
      descriptorsToFind.push(solution.value[0]);
    }
  });
  const fileList = await Promise.all(descriptorsToFind.map(api.file.getById));

  const files = new Map(fileList.map((file) => [file.id, file]));

  const views = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget([
        form("#evaluation-profile-form"),
      ], "Profile", ".user-profile"),
      widget([
        div("#assessment-sections"),
      ], "Questions", ".assessment-sections"),
      widget([
        div("#evaluation-profile"),
      ], "Evaluation", ".evaluation-profile"),
      widget(div("#evaluations"), "Evaluations", ".evaluations"),
    ],
    title,
  );
  const { header, modal, loading, notification } = views;
  loadingView = loading;
  modalView = modal;
  notificationView = notification;
  loadingView.show();

  const state = {
    user,
    users,
    evaluation,
    instructor: getEvaluationInstructor(grants),
    saveDisabled: user.id === evaluation.student.id,
    files,
    messages: [],
    evaluationsTable: {
      sortColumn: "student",
      sortOrder: sortOrders.ASC,
    },
    evaluationStatuses: getExtendedAnalyticsEvaluations({
      courseGrants: grants,
      users,
      analyticsEvaluations: evaluationStatuses,
      assessments: [evaluation.assessment],
    }),
  };

  userProfileView = formManagedView(showEvaluationProfileForm, doUpdate)(
    "#evaluation-profile-form",
    state,
  );
  evaluationProfileView = view.create(showEvaluationProfileView)("#evaluation-profile", state);
  assessmentSectionsView = view.create(showAssessmentSectionsView)("#assessment-sections", state);
  view.create(showEvaluationsTable)("#evaluations", state);

  const updateActionsFn = (actionsView) => {
    actionsView.patch(showActionButtons(actionsView));
  };
  view.create(showActionButtons, updateActionsFn)("#actions", state);

  header.update({ user, title: "Evaluation profile" });
  loadingView.hide();
}
