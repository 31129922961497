/**
 * Base functionality for list-style dialogs.
 *
 * @module ui/component/modal/select
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  div,
} from "ui/html";
import itemCameo from "ui/component/cameo-list-select-item";
import icon from "ui/component/icon";
import dialog from "ui/component/modal/layout/dialog";
import multiToggle from "ui/component/multi-toggle";
import headerSearchBar from "ui/component/modal/component/header-search-bar";
import { getModal } from "ui/view/modal-view";
import { frozen, merge } from "util/object";

const buildEntries = (entries, onSearch, modalView) => {
  if (!entries?.flat()?.length) {
    return div(".entries", itemCameo({
      label: "No Entries",
      icon: icon.sharp("xmark"),
      onSelect: () => onSearch("", modalView),
    }));
  }
  return div(".entries", entries.flat());
};

const buildBody = (state, modalView) => (
  state.tabLabels?.length
    ? [
      buildEntries(state.entries[state.selectedTab], state.onSearch, modalView),
    ]
    : buildEntries(state.entries, state.onSearch, modalView)
);

/**
 * @callback TabChangeCallback
 * @param {int} selectedTab index of tab the user selected
 */

const defaultState = frozen({
  actions: null,
  entries: [],
  header: undefined,
  isAsync: null,
  onClose: null,
  onSearch: null,
  onTabChange: null,
  searchText: "",
  sel: "",
  selectedTab: 0,
  tabLabels: null,
  title: "",
});

/**
 * Layout for list modals. Generally meant to be composed, not used directly.
 *
 * Search area is hidden by default. If a search callback is supplied it will be
 * placed in the header. The callback is responsible for patching the modal if
 * necessary. See {@link module:ui/component/modal/component/header-search-bar}
 *
 * Tab infrastructure is provided but tab changing must be handled by the caller,
 * so that wrappers can maintain their state.
 *
 * @function selectModal
 * @param {Object} state
 * @param {El[]|Array.<El[]>} state.entries entries to be displayed
 * @param {?string[]} state.tabLabels tab labels
 * @param {TabChangeCallback} state.tabChangeCallback callback for tab swaps
 * @param {?int} selectedTab index of pre-selected tab
 * @param {?El} state.actions optional footer action buttons/etc
 * @param {CloseCallback} state.onClose callback for close fab
 * @param {?Selector} state.selector additional selectors
 * @param {string} state.title a modal title
 * @return {module:ui/common/El~el}
 */
export default function listModal(inState) {
  const modalView = getModal();
  const state = merge(defaultState, inState);
  if (state.tabLabels?.length) {
    if (state.tabLabels.length !== state.entries.length) {
      throw new Error("listModal received mismatched list of tabs and entry sets");
    }
    if (!state.onTabChange) {
      throw new Error("list modal requires onTabChange with provided tabLabels");
    }
  }

  const header = (state.onSearch || state.tabLabels?.length)
    ? [
      state.onSearch ? headerSearchBar({
        searchText: state.searchText,
        onSearch: state.onSearch,
      }, modalView) : "",
      state.tabLabels?.length ? multiToggle({
        onSelect: (_, index) => state.onTabChange(index),
        selectedTab: `${state.selectedTab}`, // force string cast
        options: state.tabLabels.map((label, i) => ({
          label,
          name: `list-select-tab-${i}`,
          value: i,
        })),
      }) : "",
    ]
    : state?.header;
  return dialog({
    sel: `.list${state.tabLabels?.length ? ".tabbed" : ""}${state.sel}`,
    header: state.title ? [
      state.title,
      ...header,
    ] : header,
    body: buildBody(state, modalView),
    footer: state.actions,
    onClose: state.onClose,
    isAsync: state.isAsync === true,
  }, modalView);
}
