/**
 * Constraints specific to file descriptors.
 *
 * @module model/file-descriptor/constraints
 * @category Model
 * @subcategory File Descriptors
 */
/* eslint-disable import/prefer-default-export */
import { fileTypesValues } from "./constants";
import { validMember } from "../constraints";

export const validFileType = (value, fieldName) => validMember(
  value,
  fieldName,
  fileTypesValues,
);
