import xs from "xstream";
import { byIds, search as abstractItemSearch } from "api/v2/metadata/abstract";
import {
  CACHE_ABSTRACT_ITEM_KEY_V2 as CACHE_KEY,
  CACHE_SEARCH_ABSTRACT_ITEM_KEY_V2 as SEARCH_KEY,
} from "cache/constants";
import { makeAbstractItem } from "model/abstract-item";
import { metadataTypes } from "model/metadata/constants";
import single from "data/pipeline/single";
import many from "data/pipeline/many";
import search from "data/pipeline/search";
import { streamLog, sumStream } from "data/stream/compose";
import hashmap from "util/hash-map";
import videoStream from "data/metadata/video";
import documentStream from "data/metadata/document";
import playlistStream from "data/metadata/playlist";
import courseStream from "data/course";

const itemMap = hashmap();

const defaultFn = (id) => makeAbstractItem({ id });
const modelFn = (partial) => makeAbstractItem(partial);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const {
  post: searchAbstractItems,
  sink$: searchSink$,
  items$: searchItems$,
  pending$: searchPending$,
} = search({
  apiFn: abstractItemSearch,
  defaultFn,
  modelFn,
  cacheSearchKey: SEARCH_KEY,
  cacheItemsKey: CACHE_KEY,
  accumulator: itemMap,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
  searchItems$,
);

const pending$ = sumStream(
  singlePending$,
  manyPending$,
  searchPending$,
);

/**
 * Pushes all abstract items into the respective metadata streams, returning
 * document, playlist, and video `all$`.
 *
 * Useful for ensuring the availability of individual items for any items fetched
 * by get() or getMany(), and for extended metadata. Do not call if not needed
 * for whatever you're displaying right now, as omitting it will save requests.
 *
 * @function getItems
 * @returns {Stream} `{
 *   document$: Stream.<Map.<UUID, DocumentMetadata>>,
 *   video$: Stream.<Map.<UUID, VideoMetadata>>
 *   playlist$: Stream.<Map.<UUID, PlaylistMetadata>>
 * }`
 */
const getItems = () => {
  all$.addListener({
    next: (map) => {
      const videoIds = new Set();
      const documentIds = new Set();
      const playlistIds = new Set();
      const courseIds = new Set();
      [...map.values()].forEach((item) => {
        switch (item.type) {
          case metadataTypes.VIDEO:
            videoIds.add(item.id);
            break;
          case metadataTypes.DOCUMENT:
            documentIds.add(item.id);
            break;
          case metadataTypes.LIST:
            playlistIds.add(item.id);
            break;
          case metadataTypes.COURSE:
            courseIds.add(item.id);
            break;
          default:
            break;
        }
      });
      if (videoIds.size) videoStream.getMany([...videoIds]);
      if (documentIds.size) documentStream.getMany([...documentIds]);
      if (playlistIds.size) playlistStream.getMany([...playlistIds]);
      if (courseIds.size) courseStream.getMany([...courseIds]);
    },
  });

  return {
    document$: documentStream.all$,
    video$: videoStream.all$,
    playlist$: playlistStream.all$,
    course$: courseStream.all$,
  };
};

all$.compose(streamLog("ABSTRACT_ALL$"));
all$.imitate(out$);

export default {
  get,
  getMany,
  getItems,
  search: searchAbstractItems,
  all$,
  search$: searchSink$,
  pending$,
};
