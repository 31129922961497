/**
 * A loading overlay with optional message text.
 *
 * @module ui/component/loading
 * @category UI
 * @subcategory Components
 */
import {
  div,
  figure,
  img,
  p,
} from "ui/html";
import { getBrandingIconsUrl } from "util/ui-customization";

/**
 * A full-page loading spinner/interstitial.
 *
 * @function loading
 * @param {boolean} enabled if true, the view will be shown
 * @param {?string} message a message to display in the loading dialog
 * @return {module:ui/html/div} `div.em-loading-overlay`
 */
const loading = ({ state: { enabled, message } }) => (enabled
  ? div(".em-loading-overlay", [
    ...(message ? message.split("\n").map((text) => p(text)) : []),
    figure(".loading-spinner", img(
      "",
      getBrandingIconsUrl() || "/media/logo-mark.svg",
      "SmartEdge",
    )),
  ])
  : div(".em-loading-overlay", { style: { display: "none" } }));

export default loading;
