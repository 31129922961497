/**
 * Utility functions for manipulating player.
 *
 * @module util/player
 * @category Utilities
 */
import log from "log";
import { frozen } from "./object";
import { qs } from "./dom";
import { emit } from "./event";

/**
 * List of player actions.
 *
 * @enum {string} playerActions
 * @readonly
 * @property {string} EXPAND
 * @property {string} SHRINK
 * @property {string} VIDEO_LOAD_FAIL
 * @property {string} VIDEO_LOAD_SUCCESS
 * @property {string} TYPE
 */
export const playerActions = frozen({
  EXPAND: "EXPAND",
  SHRINK: "SHRINK",
  VIDEO_LOAD_FAIL: "VIDEO_LOAD_FAIL",
  VIDEO_LOAD_SUCCESS: "VIDEO_LOAD_SUCCESS",
  TYPE: "PLAYER_ACTION",
});

export const processPlayerAction = (playerId, action) => {
  let playerIframe;
  try {
    playerIframe = qs(`iframe#${playerId}`);
  } catch (e) {
    log.debug(`Can't find player iframe ${playerId}`, e);
  }
  switch (action) {
    case playerActions.EXPAND:
      playerIframe.classList.add("player-expanded");
      break;
    case playerActions.SHRINK:
      playerIframe.classList.remove("player-expanded");
      break;
    case playerActions.VIDEO_LOAD_FAIL:
      emit(document.body, "em:player-video-load-fail", playerId);
      break;
    case playerActions.VIDEO_LOAD_SUCCESS:
      emit(document.body, "em:player-video-load-success", playerId);
      break;
    default:
      break;
  }
};
