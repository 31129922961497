/**
 * The admin page for managing threads.
 *
 * Corresponds to `markup/admin/thread-profile.html`
 *
 * @module ui/page/admin/messaging/thread-profile
 * @category Pages
 * @subcategory Admin - Messages
 */
import log from "log";
import { featureTypes } from "model/config/constants";
import { messageTypes as types } from "@smartedge/em-message/constants";
import { notificationMessageTypes } from "model/notification/constants";
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import { threadStatuses, threadStatusesFriendly } from "model/message/constants";
import { canModerate } from "model/user";
import reportThreadModal from "ui/component/chat/report-thread";
import dashboardLayout from "ui/page/layout/dashboard";
import api from "api";
import view from "ui/view";
import widget from "ui/component/dashboard-widget";
import placeholder from "ui/component/placeholder";
import actionBar from "ui/component/dashboard-action-bar";
import { a as link, div, section, span } from "ui/html";
import button from "ui/component/form-managed/button";
import { getQueryParams } from "util/navigation";
import message from "ui/component/messaging/message";
import icon from "ui/component/icon";
import { getIconName } from "ui/component/group-icon";
import getUsername from "ui/component/messaging/username";
import messageInput from "ui/component/chat/message-input";
import { sortDate } from "util/sort";
import { frozen, merge } from "util/object";
import {
  defaultSearchParams,
  fetchDescriptors,
  processFileAttachment,
  removeAttachedFile,
} from "ui/page/chat/common";
import { sendMessageFromMe, sendMessageFromUser } from "api/message";
import channelUsersPreview from "ui/component/chat/channel-users-preview";
import { isScrolledToTop } from "ui/component/chat/common";
import messageProfileModal from "ui/component/modal/messaging/message-profile";
import reportMessageModal from "ui/component/chat/report-message";
import { guard } from "util/feature-flag";
import {
  findReportsForMessage,
  getReportedMessagesForRecentMessages,
  getThreadStatus,
} from "./common";

const defaultState = frozen({
  attachedFiles: [],
  attachedFilesDescriptors: [],
  descriptors: new Map(),
  threadSearchParams: defaultSearchParams(),
  searchMessagesFn: () => {
  },
  user: null,
  isGroup: false,
  userOneId: null,
  userTwoId: null,
  groupId: null,
  messages: [],
  users: [],
  groups: [],
  conversationId: null,
});

let loading, modal, notification, page, actions;

const loadMoreMessages = async (self) => {
  const { messages, searchMessagesFn, threadSearchParams } = self.state;
  const lastMessage = messages[messages.length - 1];
  const newMessages = await searchMessagesFn({
    ...threadSearchParams,
    endDate: lastMessage?.created,
  });
  const filteredMessages = newMessages.filter(
    (m) => !messages.some((existingMessage) => existingMessage.id === m.id),
  );
  const descriptorIds = Array.from(filteredMessages.values())
    .map((m) => m?.attachments)
    .flat();
  fetchDescriptors(self, descriptorIds);
  const sortedMessage = [...messages, ...filteredMessages]
    .sort((a, b) => sortDate(new Date(b.created), new Date(a.created)));
  const recentMessagesReports = await getReportedMessagesForRecentMessages(sortedMessage);
  self.update({
    threadSearchParams: {
      ...threadSearchParams,
      endDate: lastMessage?.created,
    },
    messages: sortedMessage,
    ...(recentMessagesReports.length ? { recentMessagesReports } : {}),
  });
};

const showThreadProfile = (self) => div(".thread-container", [
  div(
    ".thread-scrollable-container",
    {
      on: {
        scroll: async (e) => {
          if (isScrolledToTop(e.target)) {
            await loadMoreMessages(self);
          }
        },
      },
    },
    self.state.messages.length
      ? self.state.messages.map((m) => message({
        message: m,
        users: self.state.users,
        groups: self.state.groups,
        descriptors: self.state.descriptors,
        reports: findReportsForMessage(m, self.state.recentMessagesReports),
        onClick: async () => {
          modal.async(messageProfileModal({
            message: m,
            users: self.state.users,
            groups: self.state.groups,
            onReport: async () => {
              const result = await modal.async(reportMessageModal({
                message: m,
              }, modal));
              if (result) {
                await api.message.reportMessage({
                  additionalInformation: result.additionalInformation,
                  message: {
                    message: result.message,
                  },
                  reason: result.reason,
                });
                notification.post({
                  title: "Success",
                  text: "Report is sent!",
                  type: notificationMessageTypes.SUCCESS,
                });
              }
            },
            onRemove: async () => {
              await api.message.archiveMessage(m);
              notification.post({
                title: "Success",
                text: "Message is removed!",
                type: notificationMessageTypes.SUCCESS,
              });
              self.update({
                messages: self.state.messages.filter((item) => item.id !== m.id),
              });
            },
          }, modal));
        },
      }, modal))
      : div(".no-messages", "No messages yet"),
  ),
  div(".reply-container", [
    messageInput({
      attachedFiles: self.state.attachedFiles,
      attachedFilesDescriptors: self.state.attachedFilesDescriptors,
      inputPlaceholder: "Admin Super Reply",
      onSend: (text) => {
        if (!text.trim() && !self.state.attachedFilesDescriptors.length) {
          return;
        } // no empty messages!
        const { isGroup, userOneId, userTwoId, groupId, attachedFilesDescriptors } = self.state;
        const descriptorIds = attachedFilesDescriptors.map((descriptor) => descriptor.id);
        const descriptorNames = attachedFilesDescriptors.map((descriptor) => descriptor.name);
        if (isGroup) {
          sendMessageFromMe(
            groupId,
            text,
            descriptorIds,
            descriptorNames,
            true,
          );
        } else {
          sendMessageFromUser(
            userOneId,
            userTwoId,
            text,
            descriptorIds,
            descriptorNames,
            true,
          );
        }
        self.update({
          attachedFiles: [],
          attachedFilesDescriptors: [],
        });
      },
      onAttachFile: (file) => processFileAttachment(self, file, notification),
      onRemoveAttachedFile: (file) => removeAttachedFile(self, file),
    }, modal),
  ]),
]);

const getTitle = (self) => {
  const {
    isGroup,
    groupId,
    groups,
    users,
    userOne,
    userTwo,
    conversation,
  } = self.state;
  if (isGroup) {
    const group = groups.find((g) => g.id === groupId) || { members: [] };
    return div(".label", [
      icon.solid(getIconName(group.name)),
      span(systemPrivilegeGroupsFriendly.get(group.name) || group.name, ".username"),
      channelUsersPreview({
        users: group.members.map((m) => users.find((u) => u.id === m.id)),
        onClickUser: (userId) => window.location.assign(`/admin/user-profile?id=${userId}`),
      }, modal),
    ]);
  }
  return div(".label", [
    link(
      [icon.solid("user"), getUsername(userOne)],
      `/admin/user-profile?id=${userOne.id}`,
      ".username",
    ),
    span("and", ".delimiter"),
    link(
      [icon.solid("user"), getUsername(userTwo)],
      `/admin/user-profile?id=${userTwo.id}`,
      ".username",
    ),
    conversation.blockedOneWay || conversation.blockedBothWays
      ? span("(BLOCKED)")
      : "",
  ]);
};

/**
 * Report a thread
 *
 * @param {conversationId} target
 * @returns {Promise<void>}
 */
export const reportThread = async (target) => {
  const result = await modal.async(reportThreadModal({
    target,
  }, modal));
  if (result) {
    await api.message.reportThread(result);
    notification.post({
      title: "Success",
      text: "Report is sent!",
      type: notificationMessageTypes.SUCCESS,
    });
  }
};

const updateThreadStatus = (threadStatus) => {
  page.update({ threadStatus });
  actions.update({ threadStatus });
};

const closeThread = async () => {
  if ((await modal.confirm("Users will be allowed to view the thread, but cannot send messages. Are you sure you want to close the thread?"))) {
    if (page.state.isGroup) {
      await api.message.sendCloseGroupThread(page.state.groupId);
    } else {
      await api.message.sendCloseDMThread([page.state.userOneId, page.state.userTwoId]);
    }
    updateThreadStatus(threadStatuses.CLOSED);
  }
};

const openThread = async () => {
  if ((await modal.confirm("If you re-open this thread, users will be allowed to send messages again. Are you sure you want to re-open this thread?"))) {
    if (page.state.isGroup) {
      await api.message.sendOpenGroupThread(page.state.groupId);
    } else {
      await api.message.sendOpenDMThread([page.state.userOneId, page.state.userTwoId]);
    }
    updateThreadStatus(threadStatuses.OPEN);
  }
};

const removeThread = async () => {
  if ((await modal.confirm("This thread will no longer be accessible to users. Are you sure you want to remove this thread?"))) {
    if (page.state.isGroup) {
      await api.message.sendRemoveGroupThread(page.state.groupId);
    } else {
      await api.message.sendRemoveDMThread([page.state.userOneId, page.state.userTwoId]);
    }
    updateThreadStatus(threadStatuses.REMOVED);
  }
};

const restoreThread = async () => {
  if ((await modal.confirm("This thread will be accessible to users again. Are you sure you want to restore this thread?"))) {
    if (page.state.isGroup) {
      await api.message.sendRestoreGroupThread(page.state.groupId);
    } else {
      await api.message.sendRestoreDMThread([page.state.userOneId, page.state.userTwoId]);
    }
    updateThreadStatus(threadStatuses.OPEN);
  }
};

const isModeratorChat = (state) => (
  state.userOne
  && state.userTwo
  && (canModerate(state?.userOne) && canModerate(state?.userTwo))
);

const showActionButtons = (self) => div("#actions", [
  div(".thread-title", getTitle(self)),
  div(".controls", [
    div(".thread-status", [
      span("Status: ", ".status-label"),
      span(threadStatusesFriendly.get(page.state.threadStatus), ".status-value"),
    ]),
    button.alternate({
      icon: "flag",
      label: "Report",
      onClick: () => {
        if (self.state.isGroup) {
          reportThread(self.state.groupId).catch((e) => log.error(e, "in reportThread"));
        } else if (self.state.userOne && self.state.userTwo) {
          reportThread([self.state.userOne.id, self.state.userTwo.id]).catch((e) => log.error(e, "in reportThread"));
        }
      },
    }),
    page.state.threadStatus !== threadStatuses.CLOSED
      ? button.accent({
        icon: "lock",
        label: "Close Thread",
        disabled: isModeratorChat(self.state) || page.state.threadStatus === threadStatuses.REMOVED,
        onClick: closeThread,
      })
      : button.secondary({
        icon: "lock-open",
        label: "Open Thread",
        disabled: isModeratorChat(self.state) || page.state.threadStatus === threadStatuses.REMOVED,
        onClick: openThread,
      }),
    page.state.threadStatus !== threadStatuses.REMOVED
      ? button.warning({
        icon: "trash",
        label: "Remove Thread",
        onClick: removeThread,
        disabled: isModeratorChat(self.state),
      })
      : button.secondary({
        icon: "trash-undo",
        label: "Restore Thread",
        disabled: isModeratorChat(self.state) || page.state.threadStatus === threadStatuses.CLOSED,
        onClick: restoreThread,
      }),
  ]),
]);

const bindMessages = async (state) => {
  const { groupId, userOneId, userTwoId, isGroup } = state;
  const ids = new Set([groupId, userOneId, userTwoId]);
  const message$ = api.message.getMessageStream(types.CHAT_TEXT);
  message$.map((m) => m.message)
    .filter((m) => (isGroup
      ? ids.has(m.to)
      : ids.has(m.to) && ids.has(m.from)
    ))
    .addListener({
      next: (receivedMessage) => {
        log.info("received chat message", receivedMessage);
        page.update({
          messages: [
            receivedMessage,
            ...page.state.messages,
          ],
        });
        fetchDescriptors(page, receivedMessage.attachments);
      },
    });
  await api.message.onAuthenticatedConnect(() => {
    api.message.requestModeratorSpy(userOneId, userTwoId);
  }).catch((e) => log.error(e, "while listening for moderator spy"));
};

export default async function threadProfile(selector) {
  guard(featureTypes.MESSAGING);
  const title = "Thread Profile";
  ({ modal, loading, notification } = dashboardLayout(
    selector,
    [
      actionBar(section("#actions")),
      widget(placeholder("Thread", "#thread"), "", ".thread"),
      widget(placeholder("Analytics", "#analytics"), "", ".analytics"),
    ],
    title,
  ));

  loading.show("Prefetching content...");

  // TODO tidy this up
  const { userOneId, userTwoId, groupId } = getQueryParams();
  const isGroup = !!groupId;
  let searchMessagesFn;
  if (isGroup) {
    searchMessagesFn = (params) => api.message.searchGroupMessages(groupId, params);
  } else {
    searchMessagesFn = (params) => api.message
      .searchMessagesWithUser(userOneId, userTwoId, params);
  }

  const getConversationIdFn = isGroup
    ? () => api.message.getGroupConversationId(groupId)
    : () => api.message.getDirectConversationIdByUserIds(userOneId, userTwoId);

  const getConversationFn = isGroup
    ? () => api.message.getGroupConversation(groupId)
    : () => api.message.getDirectConversationByUserIds(userOneId, userTwoId);

  const state = merge(defaultState, {
    isGroup,
    userOneId,
    userTwoId,
    groupId,
    searchMessagesFn,
  });

  page = view.create(showThreadProfile)("#thread", state);
  await bindMessages(page.state);

  const threadSearchParams = defaultSearchParams();

  const [
    user,
    messages,
    users,
    groups,
    conversationId,
  ] = await Promise.all([
    api.user.getMe(),
    searchMessagesFn(threadSearchParams)
      .then((allMessages) => allMessages
        .sort((a, b) => sortDate(new Date(b.created), new Date(a.created)))),
    api.user.list(),
    api.acl.listGroups(),
    getConversationIdFn(),
  ]);

  const recentMessagesReports = await getReportedMessagesForRecentMessages(messages);
  const conversation = await getConversationFn();

  page.updateState({
    user,
    messages,
    users,
    groups,
    conversationId,
    conversation,
    recentMessagesReports,
  });

  const threadStatus = await getThreadStatus(page.state);
  page.update({ threadStatus });

  const userOne = users.find((u) => u.id === userOneId);
  const userTwo = users.find((u) => u.id === userTwoId);

  const actionsState = {
    user,
    userOne,
    userTwo,
    groupId,
    users,
    groups,
    conversationId,
    conversation,
    threadStatus,
    isGroup,
  };

  actions = view.create(showActionButtons)("#actions", actionsState);

  const descriptorIds = Array.from(messages.values())
    .map((m) => m?.attachments)
    .flat();
  fetchDescriptors(page, descriptorIds);

  const message$ = api.message.getMessageStream();

  message$.map((m) => m.message).addListener({
    next: (mess) => {
      if (mess.type === types.REPORT) {
        page.update({
          recentMessagesReports: [
            ...page.state.recentMessagesReports,
            mess,
          ],
        });
      }
    },
  });

  loading.hide();
}
