/**
 * A single-word badge indicating a state or attribute.
 *
 * It receives a class-name matching its value.
 *
 * @module ui/component/badge
 * @category UI
 * @subcategory Components
 */

import span from "ui/html/span";

/**
 * @function badge
 * @param {string} type should be a single word without spaces
 * @param {string} sel css selector
 */
export default function badge(type, sel) {
  const classname = sel || `.${type.split(" ")[0].toLowerCase()}`;
  return span(type, `.badge${classname}`);
}
