/**
 * Utilities for setting up and interacting with managed forms.
 *
 * @module ui/view/managed-form/util
 * @category UI
 * @subcategory Views
 */
import { clone, frozen, merge } from "util/object";

const defaultValidity = frozen({
  valid: true,
});

/**
 * @callback ValidHook
 * @param {El} self
 * @param {Map<string, ValidityState>} validityMap <name, validity>
 */

/**
 * @callback GetValueCallback
 * @param {El} self
 * @param {Map<string, ?mixed>} formData <name, value>
 */

const defaultGetValue = (self, formData) => {
  if (self.elm?.value) formData.set(self.managedField.name, self.elm.value);
};

const defaultFieldConfig = frozen({
  name: "UNNAMED_FIELD",
  validHooks: null,
  getValue: null,
  value: null,
});

const recurseNodes = (node, callback) => {
  if (callback(node) && node.children) {
    // in case of undefined children, skip
    node.children?.forEach((child) => (child ? recurseNodes(child, callback) : true));
  }
};

const getValueCallback = (data) => (node) => {
  const { managedField } = node;
  if (
    typeof managedField?.getValue === "function"
    && typeof managedField?.name === "string"
    && managedField?.name
  ) {
    data.set(managedField.name, managedField.getValue(node));
    return false;
  }

  if (node.elm?.name && node.elm?.value !== undefined) {
    if (node.elm?.type === "radio" || node.elm?.type === "checkbox") {
      if (node.elm?.value && node.elm?.checked) {
        data.set(node.elm?.name, node.elm?.value);
      }
      return false;
    }
    data.set(node.elm?.name, node.elm?.value);
    return false;
  }
  return true;
};

export const copyValidity = (validity) => {
  // we have to manually copy each property because ValidityState properties are not
  // enumerable. But our copy doesn't need to keep any `false` or empty states.
  const copy = {};
  if (validity?.badInput) copy.badInput = true;
  if (validity?.customError) copy.customError = true;
  if (validity?.patternMismatch) copy.patternMismatch = true;
  if (validity?.rangeOverflow) copy.rangeOverflow = true;
  if (validity?.rangeUnderflow) copy.rangeUnderflow = true;
  if (validity?.stepMismatch) copy.stepMismatch = true;
  if (validity?.tooLong) copy.tooLong = true;
  if (validity?.tooShort) copy.tooShort = true;
  if (validity?.typeMismatch) copy.typeMismatch = true;
  if (validity?.valueMissing) copy.valueMissing = true;
  copy.valid = validity?.valid || false;

  return copy;
};

/**
 * Collects values in a form into a Map.
 *
 * We do it this way instead of using FormData because FormData does not support booleans.
 *
 * @function getFormValueMap
 * @param {El} formNode root form node (managing a FORM element)
 * @return {Map<string, mixed>}
 */
export const getFormValueMap = (formNode) => {
  const data = new Map();
  recurseNodes(formNode, getValueCallback(data));
  return data;
};

/**
 * Translates form values from getFormValueMap into a FormData object.
 *
 * @function getFormData
 * @param {El} formNode root form node (managing a FORM element)
 * @return {FormData}
 */
export const getFormData = (formNode) => {
  const formData = new FormData();
  const data = getFormValueMap(formNode);

  [...data.entries()].forEach(([k, v]) => {
    formData.set(k, v);
  });

  return formData;
};

/**
 * Gets form values as a simple dictionary object.
 *
 * @function getFormValues
 * @param {El} formNode root form node (managing a FORM element)
 * @return {Object.<string, mixed>}
 */
export const getFormValues = (formNode) => {
  const data = getFormValueMap(formNode);
  const values = {};
  [...data.entries()].forEach(([k, v]) => {
    values[k] = v;
  });
  return frozen(values);
};

/**
 * Used by `getValidity` to determine whether the only validation error is a missing
 * required field.
 *
 * This is so the UI can avoid showing `required` error messages until we do a final
 * validation pass.
 *
 * @function nonRequiredValidity
 * @private
 * @param {object} validState
 * @return {boolean}
 */
const nonRequiredValidity = (validState) => [...Object.entries(validState)].reduce(
  (acc, [k, v]) => {
    if (k === "valueMissing" || k === "valid") return acc;
    return acc && !v;
  },
  true,
);

/**
 * Checks the validation state of a single node.
 *
 * If it is a managedField node wrapping an HTML form element it will first ask for the
 * validation state of the form element, then run validation hooks.
 *
 * If it is a managedField but does not wrap a form element it will only run validation
 * hooks.
 *
 * If it is not a managedField, but provides `name` and `validity` properties, those will
 * be used.
 *
 * Any of the above options will be inserted into the provided validStates map.
 *
 * @function getValidityCallback
 * @param {Map<string, object>} validStates
 * @return {function(node)}
 */
const getValidityCallback = (validStates) => (node) => {
  const { managedField } = node;
  let validity;
  node.elm?.checkValidity?.();
  if (node.elm?.validity) validity = copyValidity(node.elm?.validity);
  else validity = clone(defaultValidity);

  if (
    managedField?.validHooks
    && managedField?.validHooks instanceof Array
    && typeof managedField?.name === "string"
  ) {
    node.managedField.validHooks.forEach((hook) => hook(node, validity));
    validStates.set(managedField.name, validity);
    return false;
  }
  // maybe a managed field but we have no validHooks so just use the provided validation
  if (node.elm.name && node.elm.validity) {
    validStates.set(node.elm.name, validity);
    return false;
  }
  return true;
};

/**
 * Recurses a formView's form vNode, gathering the validity state of all its children.
 *
 * Child nodes that have the `managedField` property object will have their validHooks
 * run.
 *
 * Other nodes will fall back to default HTML validity.
 *
 * Recursion treats each node that can satisfy validation as a "leaf" and stops searching
 * any deeper in the tree (in case they happen to have child nodes, e.g. with managed fields
 * composed of several internal HTML input elements).
 *
 * Mainly used internally by `ManagedFormView` but kept here so validation functionality is
 * all in one place.
 *
 * @function getValidity
 * @param {El} formNode
 * @param {boolean} checkRequired
 * @return {object} form validity object
 */
export const getValidity = (formNode, checkRequired = false) => {
  const validStates = new Map();
  recurseNodes(formNode, getValidityCallback(validStates));
  const validity = { fields: {}, valid: true };
  [...validStates.entries()].forEach(([k, v]) => {
    let altered;
    if (!checkRequired) {
      altered = clone(v);
      delete altered.valueMissing;
      if (nonRequiredValidity(v)) altered.valid = true;
    } else {
      altered = v;
    }
    if (altered) validity.fields[k] = altered;
    if (altered && altered?.valid === false) validity.valid = false;
  });
  return frozen(validity);
};

/**
 * Appends properties for managed form fields to an `El`.
 *
 * This can be done manually by adding a `managedField` object property to the `El` config
 * object with the same properties as the `name`, `getValue`, and `validHooks` parameters,
 * but this function does a little extra work to ensure the resulting config is valid and
 * provides useful defaults for `getValue` and `validHooks`.
 *
 * @function managedField
 * @param {El} node
 * @param {string} name as an HTML form field's name
 * @param {GetValueCallback} getValue
 * @param {ValidHook[]} validHooks
 */
export const managedField = (node, name, getValue = defaultGetValue, validHooks = []) => {
  /* eslint-disable no-param-reassign */
  if (!name) throw new Error("MANAGED FORMS :: makeField: no field name provided");
  if (!getValue) {
    throw new Error("MANAGED FORMS :: makeField: no field value callback provided");
  }
  const state = merge(defaultFieldConfig, { name, getValue, validHooks });
  node.managedField = state;
  return node;
  /* eslint-enable no-param-reassign */
};
