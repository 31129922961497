/**
 * A rich text editor.
 *
 * Generally should be used via
 * [RichTextEditView]{@link module:ui/view/rich-text-edit-view}, not on its own.
 *
 * The view handles maintaining state and provides convenience functions for interacting
 * with the editor.
 *
 * @module ui/component/rich-text-editor
 * @category UI
 * @subcategory Components - Admin
 */
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import { div, button, span } from "ui/html";
import buttonGroup from "ui/component/button-group";
import linkSelectModal from "ui/component/modal/link-select";
import icon from "ui/component/icon";
import { emit } from "util/event";
import { capitalize } from "util/format";
import { frozen, merge } from "util/object";
import { PaletteSpan, PaletteColor, colors } from "./palette";

let count = 0;
const editors = [];

const spacer = () => span("", ".spacer");
const defaultState = frozen({
  pages: [],
});

/**
 * Commands associated with each mark type.
 */
const cmdMap = new Map([
  ["bold", "toggleBold"],
  ["italic", "toggleItalic"],
  ["underline", "toggleUnderline"],
  ["heading 1", "toggleHeading"],
  ["heading 2", "toggleHeading"],
  ["heading 3", "toggleHeading"],
  [`default color`, "setPaletteColor"],
  ["strike", "toggleStrike"],
  ["horizontal rule", "setHorizontalRule"],
  ["bullet list", "toggleBulletList"],
  ["numbered list", "toggleOrderedList"],
  ["subscript", "toggleSubscript"],
  ["superscript", "toggleSuperscript"],
  ["code", "toggleCode"],
  ["code block", "toggleCodeBlock"],
  ["undo", "undo"],
  ["redo", "redo"],
]);

/**
 * If the "active" state has a different name than the `type`
 * it should be listed here. Otherwise does not need to be
 * specified.
 */
const activeMap = new Map([
  ["bullet list", "bulletList"],
  ["numbered list", "orderedList"],
  ["code block", "codeBlock"],
  ["heading 1", "heading"],
  ["heading 2", "heading"],
  ["heading 3", "heading"],
]);

const command = (
  id,
  type,
  buttonIcon,
  sel,
  ...params
) => {
  const cmd = cmdMap.get(type);
  const active = editors[id]?.isActive(activeMap.get(type) || type, ...params);
  const disabled = !editors[id]?.can()[cmd]?.(...params);
  const title = capitalize(type);
  let selector = "";
  if (sel) selector = sel;
  else if (!active) selector = ".inverse";

  return button(
    buttonIcon,
    () => editors[id]?.chain().focus()[cmd](...params).run(),
    selector,
    "button",
    title,
    disabled,
    { props: { title } },
  );
};

const clearFormatting = (id) => button(
  icon("remove-format"),
  () => editors[id]?.chain().unsetAllMarks().clearNodes().run(),
  ".danger.inverse",
  "button",
  "clear formatting",
  !editors[id]?.can().chain().unsetAllMarks().clearNodes(),
  { props: { title: "clear formatting" } },
);

const createLink = (id, pages, modalView) => {
  const selector = editors[id]?.isActive("link") ? "" : ".inverse";

  const onCreateLink = async () => {
    const url = editors[id]?.getAttributes("link")?.href;
    const href = await modalView.async(linkSelectModal({
      pages,
      url,
    }, modalView));
    if (href) {
      const target = href?.charAt(0) === "/" ? "" : "_blank";
      editors[id]?.chain().focus().setLink({ href, target }).run();
    }
  };

  return button(
    icon("link"),
    onCreateLink,
    selector,
    "button",
    "create link",
    !editors[id]?.can().chain().focus().setLink(),
  );
};

const removeLink = (id) => button(
  icon("unlink"),
  () => editors[id]?.chain().focus().unsetLink().run(),
  ".danger.inverse",
  "button",
  "remove link",
  !editors[id]?.can().chain().focus().unsetLink(),
);

const color = (id, paletteColor) => button(
  span("", `.palette.${paletteColor}`),
  () => editors[id]?.chain().focus().setPaletteColor({ paletteColor }).run(),
  ".inverse",
  "button",
  `${paletteColor || "default"} color`,
  false,
  { props: { title: `${paletteColor || "Default"} color` } },
);

const heading = (id, level) => command(
  id,
  `heading ${level}`,
  icon(`h${level}`),
  "",
  { level },
);

export const getEditor = (id) => editors[id];

/**
 * A rich text editor.
 *
 * @function richTextEditor
 * @param {object} state
 * @param {int} [state.id]
 * @param {object} [state.active]
 * @param {boolean} [state.active[name]] whether the cursor is on an element of `name`
 * @param {string} [state.content] initial content for the editor
 * @param {Array.<DynamicPage>} [state.pages] list of pages (for links)
 * @return {module:ui/html/div~Div}
 */
export default function richTextEditor(inState, modalView) {
  const state = merge(defaultState, inState);
  const id = state.id || ++count;
  return div(`#rte-container-${id}.rte-container`, [
    div(`.rte-controls`, [
      buttonGroup([
        command(id, "bold", icon("bold")),
        command(id, "italic", icon("italic")),
        command(id, "underline", icon("underline")),
        command(id, "strike", icon("strikethrough")),
        command(id, "subscript", icon("subscript")),
        command(id, "superscript", icon("superscript")),
        command(id, "code", icon("code")),
        clearFormatting(id),
      ]),
      buttonGroup([
        heading(id, 1),
        heading(id, 2),
        heading(id, 3),
      ]),
      buttonGroup([
        command(id, "bullet list", icon("list-ul")),
        command(id, "numbered list", icon("list-ol")),
        command(id, "horizontal rule", icon("horizontal-rule")),
        command(id, "code block", icon("brackets-curly")),
      ]),
      buttonGroup([
        createLink(id, state.pages, modalView),
        removeLink(id),
      ]),
      buttonGroup([
        color(id, colors.PRIMARY),
        color(id, colors.SECONDARY),
        color(id, colors.THIRD),
        color(id, colors.ACCENT),
        color(id, colors.WARNING),
        color(id, colors.DEFAULT),
      ]),
      spacer(),
      buttonGroup([
        command(id, "undo", icon("undo-alt"), ".danger"),
        command(id, "redo", icon("redo-alt"), ".secondary"),
      ]),
    ]),
    div(`#rte-${id}.rte-element`, {
      data: {
        editorId: id,
      },
      hook: {
        insert: (node) => {
          editors[id] = new Editor({
            element: node.elm,
            extensions: [
              StarterKit.configure({
                heading: {
                  levels: [1, 2, 3],
                },
              }),
              Underline,
              Superscript,
              Subscript,
              Typography,
              Link,
              PaletteSpan,
              PaletteColor,
            ],
            onCreate: () => emit(node.elm, "rte:create", id),
            onUpdate: () => emit(node.elm, "rte:change", id),
            onSelectionUpdate: () => emit(node.elm, "rte:change", id),
            onFocus: () => emit(node.elm, "rte:focus", id),
            onBlur: () => emit(node.elm, "rte:blur", id),
            onDestroy: () => emit(node.elm, "rte:destroy", id),
            content: state.content || "<p>Hello, world!</p>",
          });
        },
      },
    }),
  ]);
}
