import { threadStatuses } from "@smartedge/em-message/constants";
import log from "log";
import button from "ui/component/form-managed/button";
import input from "ui/component/form-managed/field-input";
import div from "ui/html/div";
import { onEnterKey } from "util/event";
import { frozen, merge } from "util/object";
import messageAttachmentPreview from "./message-attachment-preview";

const defaultState = frozen({
  onSend: () => {
  },
  onAttachFile: () => {
  },
  onRemoveAttachedFile: () => {
  },
  inputPlaceholder: "Your message...",
});

const dispatchMessage = (node, state) => {
  const msgInput = node.elm.querySelector("input[name=message]");
  if (msgInput) {
    if (typeof state.onSend === "function") state.onSend(msgInput.value, state.attachedFiles);
    else log.error("component/chat/messageInput: no onSend callback provided", node.elm);
    msgInput.value = "";
  }
};

const attachFile = (node) => {
  const fileInput = node.elm.querySelector(".chat-file-input").querySelector("input[name=file]");
  if (fileInput) {
    fileInput.value = null;
    fileInput.click();
  }
};

const onAttachFile = (event, state) => {
  const file = event?.target?.files?.[0] || null;
  state.onAttachFile(file);
};

const onRemoveAttachedFile = (file, state) => {
  state.onRemoveAttachedFile(file);
};

export default function messageInput(inState, modalView) {
  const state = merge(defaultState, inState);

  const outerWrapper = div(".chat-input-outer-wrapper");
  const wrapper = div(".chat-input");
  const attachedFilesWrapper = div(".chat-attachments");

  const status = state.selectedChannel?.status;

  const disabled = (
    status === threadStatuses.CLOSED
    || status === threadStatuses.REMOVED
  );

  const getDisabledInputPlaceholder = () => {
    if (status === threadStatuses.CLOSED) {
      return "Channel is closed.";
    }
    if (status === threadStatuses.REMOVED) {
      return "Channel is no longer available.";
    }
    return "";
  };

  const text = input({
    label: disabled ? getDisabledInputPlaceholder() : state.inputPlaceholder,
    name: "message",
    onInput: onEnterKey(() => dispatchMessage(wrapper, state)),
    disabled,
  });

  const file = input({
    type: "file",
    name: "file",
    onChange: (event) => onAttachFile(event, state),
    sel: ".chat-file-input",
    disabled,
  });

  const attachBtn = button({
    icon: "paperclip",
    label: "Attach File",
    iconOnly: true,
    onClick: () => attachFile(wrapper),
    disabled,
  });

  const sendBtn = button({
    icon: "paper-plane",
    iconOnly: true,
    onClick: () => dispatchMessage(wrapper, state),
    disabled: disabled || state.attachedFiles
      .map((f) => f.fileAttachInProgress)
      .filter((loading) => loading).length,
  });

  attachedFilesWrapper.children = state.attachedFiles.map((f) => {
    if (f.fileAttachInProgress || f.failed) {
      const fakeDescriptor = {
        name: f.name,
        fileMetadata: f,
      };
      return messageAttachmentPreview({
        attachment: fakeDescriptor,
        onRemoveAttachedFile: () => onRemoveAttachedFile(f, state),
      }, modalView);
    }
    const descriptor = state.attachedFilesDescriptors.find((d) => d.fileMetadata?.uuid === f.uuid);
    if (descriptor) {
      return messageAttachmentPreview({
        attachment: descriptor,
        onRemoveAttachedFile: () => onRemoveAttachedFile(f, state),
      }, modalView);
    }
    return div();
  });

  wrapper.children = [text, file, attachBtn, sendBtn];

  outerWrapper.children = [
    wrapper,
    attachedFilesWrapper,
  ];

  return outerWrapper;
}
