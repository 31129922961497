/**
 * A form field for general single-line text inputs.
 *
 * @module ui/component/form-managed/field-text-input
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import button from "ui/component/form-managed/button";
import input from "ui/html/input";
import { frozen, merge } from "util/object";
import { managedField } from "ui/view/form-managed/util";

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "off",
  autofocus: false,
  constraints: null,
  disabled: false,
  label: "Generic Text",
  name: "generic-text",
  onChange: null,
  onInput: null,
  required: false,
  sel: "",
  taken: null,
  type: "text",
  value: "",
  listId: null,
  onHelp: null,
  filterIncomingValue: (value) => value,
  filterOutgoingValue: (value) => value,
});

const takenHook = (taken) => (node, validity) => {
  if (!(taken instanceof Array)) return;
  if (taken?.includes(node.elm.value)) {
    /* eslint-disable no-param-reassign */
    validity.taken = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

/**
 * Exported for use in other form fields that share text input functionality but need
 * to customize elements.
 *
 * The `state` parameter is expected to conform to the common state used by input fields.
 *
 * @function makeInputBindings
 * @param {object} state
 */
export const makeInputBindings = (state) => ({
  attrs: {
    "aria-label": state.label,
    placeholder: `${state.label}${state.required ? "*" : ""}`,
    autocomplete: state.autocomplete,
    autocapitalize: state.autocapitalize,
    autocorrect: state.autocorrect,
    title: state.label,
    list: state.listId || undefined,
  },
  props: {
    disabled: state.disabled,
    autofocus: state.autofocus,
  },
  class: {
    invalid: state.validity?.fields?.[state.name]?.valid === false,
  },
  on: {
    change: state.onChange || undefined,
    keyup: state.onInput || undefined,
  },
  key: `field-${state.name}`,
});

/**
 * Processes the value passed to textInput's state parameter.
 *
 * Should take the format of the OutgoingValueFilter and produce a string.
 *
 * @example
 * ```js
 * // here the incoming value is expected to be an array of strings
 * const filterIncomingValue = (value) => value.join(" ");
 * textInput({ filterIncomingValue });
 * ```
 * @callback IncomingValueFilter
 * @param {mixed} value value in the format produced by GetValueCallback
 * @return {string} value in string format to be provided to the text input
 */

/**
 * Processes the value of the input before appending to form values collection.
 *
 * Should take a string and produce a value in the expected format.
 *
 * @example
 * ```js
 * // here the outgoing value is expected to be an array of strings
 * const filterOutgoingValue = (value) => value.split(" ");
 * textInput({ filterOutgoingValue });
 * ```
 * @callback GetValueCallback
 * @param {string} value current value of the input element
 * @return {mixed} node value as it should appear in form values collection
 */

/**
 * A general purpose text input.
 *
 * If the `taken` property is provided with a list of username strings, validation will
 * check if the current value is in the list.
 *
 * @function textInput
 * @param {object} state
 * @param {string} [state.autocomplete="off"] autocomplete type
 * @param {string} [state.autofocus=false] autofocus attribute state
 * @param {?object} state.constraints
 * @param {boolean} [state.disabled=false]
 * @param {string} [state.label="Generic Text"]
 * @param {FieldValidity} [state.validity=defaultValidity]
 * @param {string} [state.name="genericText"]
 * @param {boolean} [state.required=false]
 * @param {Selector} [state.sel=""]
 * @param {?string[]} state.taken
 * @param {string} [state.type="text"]
 * @param {string} [state.value=""]
 * @param {string} [state.override=""] override field value with given state.value
 * @param {function} onChange called on blur when field has changed
 * @param {function} onInput called on keyup
 * @param {function} onHelp if present, input shows a help icon which calls this callback on click
 * @param {?IncomingValueFilter} filterIncomingValue process value passed in state to
 *        produce the value shown in the input (default: passthrough)
 * @param {?OutgoingValueFilter} filterOutgoingValue process value from form element before
 *        appending to form values (default: passthrough)
 * @return {module:ui/common/el~El}
 */
export default function genericInput(inState = {}) {
  const state = merge(defaultState, inState);

  let validHooks = [];
  if (state.validHooks) validHooks = validHooks.concat(state.validHooks);
  if (state.taken) validHooks.push(takenHook(state.taken));

  const value = state.filterIncomingValue(state.override
    ? state.value || state.values?.[state.name] || defaultState.value
    : state.values?.[state.name] || state.value || defaultState.value);

  return genericField(
    state,
    [
      managedField(
        input(
          state.type,
          value,
          state.required,
          state.name,
          state.constraints || {},
          makeInputBindings(state),
        ),
        state.name,
        (node) => state.filterOutgoingValue(node.elm.value),
        validHooks,
      ),
      state.onHelp
        ? button.subtle({
          iconOnly: true,
          icon: "question-square",
          onClick: state.onHelp,
          label: "more info",
        })
        : "",
    ],
    `${state.sel}.text-input`,
  );
}
