/**
 * A cameo for lecture.
 *
 * @module ui/component/cameo-lecture
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import lectureModal from "ui/component/modal/course/lecture";
import { getModal } from "ui/view/modal-view";

const showLecture = (
  course,
  module,
  lecture,
  schedule,
  metadataList,
  users,
  onUpdate,
) => async () => {
  const updatedLecture = await getModal().async(lectureModal({
    course,
    module,
    lecture,
    schedule,
    showSchedule: !!schedule,
    metadataList,
    users,
  }), false);
  if (updatedLecture) {
    onUpdate({
      ...updatedLecture,
      // a hack to know that the lecture was updated
      updated: true,
    });
  }
};

const placeholderCameo = ({ sel, config }) => cameo({ sel: `${sel}.cameo-course-entry.cameo-course-lecture.placeholder`, config });

export default function cameoLecture({
  course,
  module,
  lecture,
  schedule = null,
  metadataList,
  users,
  onRemove = null,
  onUpdate = null,
  controls = null,
  sel = "",
  config = {},
}) {
  if (!lecture || lecture.PLACEHOLDER) return placeholderCameo({ sel, config });
  return cameo({
    label: lecture.title || "",
    icon: icon.solid("presentation"),
    controls: [
      onUpdate ? button({
        icon: "edit",
        iconOnly: true,
        onClick: showLecture(course, module, lecture, schedule, metadataList, users, onUpdate),
      }) : "",
      onRemove ? button.warning({
        icon: "trash-alt",
        iconOnly: true,
        onClick: () => onRemove(lecture),
      }) : "",
      ...(controls || []),
    ],
    config,
    sel: `${sel}.cameo-course-entry.cameo-course-lecture`,
  });
}
