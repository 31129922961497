/**
 * A H4 element.
 *
 * @module ui/html/h3
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 *
 * @function h4
 * @param {mixed} [children=""] string, vnode, or vnode[]
 * @param {string} [sel=""] css selectors
 * @param {object} [config={}] snabbdom config object
 * @return {module:ui/common/el~El}
 */
export default (children = "", sel = "", config = {}) => el(`h4${sel}`, config, children);
