/**
 * API requests for various server info and metric endpoints.
 *
 * @module api/server
 * @category Backend API
 * @subcategory Configuration
 */
import cache from "cache";
import { TOKEN_KEY } from "model/authentication/constants";
import * as request from "./request";
import { endpoints } from "./constants";

const auth = () => `X-Access-Token=${cache.getObject(TOKEN_KEY).accessToken}`;

/**
 * Server information
 *
 * @function info
 * @return {Object} { name: "SmartEdge Dev", version: "TBD" }
 */
export const info = async () => (await request.get(`${endpoints.SERVER_INFO}`, null, false, false)).body;

/**
 * Get current server health status.
 *
 * @function health
 * @return {Object} { "status": <"ok":?> }
 */
export const health = async () => (await request.get(`${endpoints.SERVER_HEALTH}?${auth()}`)).body;

/**
 * Get a parsed JSON response from a specific server metric.
 *
 * @function metric
 * @param {string} [path="jvm.memory.used"] the actuator path for the desired metric
 *
 * @return {Object}
 */
export const metric = async (path = "jvm.memory.used") => (await request.get(`${endpoints.SERVER_HEALTH}/${path}?${auth()}`)).body;

/**
 * Get installation-specific configuration parameters from the server.
 *
 * @function config
 * @return {Object}
 */
export const config = async () => (await request.get(`${endpoints.SERVER_CONFIG}?${auth()}`)).body;
