/**
 * This is a proxy for handling logging.
 *
 * For now it should go to console when in debug mode,
 * or be silent in production.
 *
 * @module log
 * @category Utilities
 */
import { environments } from "./api/constants";
import getConfig from "./config";

/**
 * Which logging strategy is in use.
 * @private
 */
let strategy = null; // "production";

/**
 * Initializes the log module. Called automatically when the module is loaded,
 * but logging is not always immediately available if you try to call it
 * before the module figures out its environment.
 *
 * FIXME somehow build in the env so this doesn't have to be async
 *
 * @function init
 * @private
 */
const init = async () => {
  try {
    strategy = (await getConfig()).env;
  } catch (e) {
    strategy = environments.DEVELOPMENT;
  }
};

/* eslint no-console: 0 */

/**
 * The debug log strategy. All methods accept the same parameters as the console
 * object, so this is a drop-in substitute (i.e. a list of one or more strings
 * or things that coerce to strings).
 *
 * @constant debugLog
 * @type object
 * @property {function} debug log at debug-level
 * @property {function} error log at error-level
 * @property {function} info log at info-level
 * @property {function} warn log at warn-level
 */
const debugLog = Object.freeze({
  debug: (...msg) => console.debug(...msg),
  error: (...msg) => console.error(...msg),
  info: (...msg) => console.info(...msg),
  warn: (...msg) => console.warn(...msg),
});

/**
 * The production log strategy just silently ignores all log output.
 *
 * @constant productionLog
 * @type object
 * @property {function} debug log at debug-level
 * @property {function} error log at error-level
 * @property {function} info log at info-level
 * @property {function} warn log at warn-level
 */
const productionLog = Object.freeze({
  debug: () => {},
  error: () => {},
  info: () => {},
  warn: () => {},
});

/**
 * Finds the current logging strategy based on the frontend environment.
 *
 * @function getStrategy
 * @private
 * @return {Promise<string>} environment
 */
const getStrategy = async () => {
  switch (strategy) {
    case environments.PRODUCTION:
      return productionLog;
    case environments.DEVELOPMENT:
      return debugLog;
    default:
      try {
        await init();
        return getStrategy();
      } catch (e) {
        return debugLog;
      }
  }
};

const writeLog = async (type, msg) => {
  const logger = await getStrategy();
  logger[type](...msg);
};

/**
 * Passes logging calls through to the selected strategy.
 * @see {module:log~productionLog}
 * @see {module:log~debugLog}
 *
 * @constant log
 * @type object
 * @property {function} debug log at debug-level
 * @property {function} error log at error-level
 * @property {function} info log at info-level
 * @property {function} warn log at warn-level
 */
const log = Object.freeze({
  debug: (...msg) => writeLog("debug", msg),
  warn: (...msg) => writeLog("warn", msg),
  info: (...msg) => writeLog("info", msg),
  error: (...msg) => writeLog("error", msg),
});

export default log;
