/**
 * A modal dialog for selecting a grading scheme.
 *
 * @module ui/component/modal/course/module-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { button, div, h3, input, span } from "ui/html";
import dialog from "ui/component/modal/layout/dialog";
import message from "ui/component/message";
import toggle from "ui/component/form-managed/field-toggle";
import radioField from "ui/component/form-managed/field-radio";
import icon from "ui/component/icon";

let selectedSchemeKey = null;

const pleaseSelect = { type: "error", text: "Please select a scheme to continue." };

/* this is a little nasty, we'll have to find a better way eventually */
const findSchemeKey = (scheme, schemes) => [...schemes.entries()]
  .find((pair) => pair[1]?.description === scheme?.description)?.[0] || null;

const saveScheme = ({ gradingSchemes, selectedScheme }, modalView) => () => {
  const key = modalView.qs("[name=selected-scheme]")?.value;
  const automatic = selectedScheme.automatic || false;
  if (key) {
    modalView.resolve({
      ...gradingSchemes.get(key),
      automatic,
    });
  } else {
    /* eslint-disable-next-line no-use-before-define */
    modalView.patch(gradingSchemeSelectModal({
      selectedScheme: null,
      gradingSchemes,
      automatic,
      messages: [pleaseSelect],
    }, modalView));
  }
};

const selectScheme = (state, selected, modalView) => {
  /* eslint-disable-next-line no-param-reassign */
  modalView.qs("[name=selected-scheme]").value = selected;
  const newState = {
    ...state,
    selectedScheme: {
      ...state.gradingSchemes.get(selected),
      automatic: state.selectedScheme?.automatic || false,
    },
    messages: null,
  };
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(gradingSchemeSelectModal(newState, modalView));
};

export default function gradingSchemeSelectModal(state, modalView) {
  selectedSchemeKey = findSchemeKey(state.selectedScheme, state.gradingSchemes);
  return dialog({
    sel: ".grading-scheme-select",
    header: h3("Grading Scheme"),
    body: [
      input.hidden(selectedSchemeKey, "selected-scheme"),
      toggle({
        toggled: state.selectedScheme?.automatic || false,
        name: "automatic",
        label: "Automatic Grading",
        onToggle: (toggled) => {
          modalView.patch(gradingSchemeSelectModal({
            ...state,
            selectedScheme: {
              ...state.selectedScheme,
              automatic: toggled,
            },
          }, modalView));
        },
      }),
      radioField.contentBox({
        entries: Array.from(state.gradingSchemes.entries())
          .map(([gradingSchemeKey, gradingScheme]) => ({
            label: div(".entry-content", [
              icon("trophy-star"),
              span(gradingSchemeKey, ".title"),
              span(gradingScheme.description, ".subtitle"),
            ]),
            value: gradingSchemeKey,
          })),
        onChange: (gradingSchemeKey) => selectScheme(state, gradingSchemeKey, modalView),
        value: selectedSchemeKey,
      }),
      ...(state.messages?.length ? state.messages.map(message) : []),
    ],
    footerSpacer: true,
    footer: button("Save", saveScheme(state, modalView)),
  }, modalView);
}
