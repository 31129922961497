/**
 * File picker tab for filePickerModes.KEEP
 * @module ui/component/form-managed/file-picker/tab-keep
 * @private
 */
/***/
import { fileTypeMimeTypes } from "model/file-descriptor/constants";
import cameoFile from "ui/component/cameo-file";
import button from "ui/component/form-managed/button";
import fileUrlModal from "ui/component/modal/file-url";
import div from "ui/html/div";
import input from "ui/html/input";
import { getModal } from "ui/view/modal-view";
import { filePickerModes } from "./constants";
import { makeGetInput } from "./util";

const onRemoveUploadFile = (state, onChange, getInput) => () => {
  const url = getInput("url");
  const upload = getInput("upload");
  if (url) url.value = "";
  if (upload) upload.value = null;
  onChange(state, {
    mode: filePickerModes.UPLOAD,
    upload: "",
    url: "",
  });
};

const makeUrlInput = (state, getInput, onChange) => input( // a hidden input for the file URL
  "hidden",
  state.value.url || getInput("url")?.value || "",
  false,
  `${state.name}_url`,
  {
    on: {
      change: (e) => {
        if (e.target.value) {
          onChange(state, {
            mode: filePickerModes.URL,
            url: e.target.value,
          });
        }
      },
    },
  },
);

const makeFileInput = (state, onChange) => {
  const mimeTypes = state.fileTypes.map((type) => fileTypeMimeTypes.get(type)).flat();
  return input.file(
    false,
    `${state.name}_upload`,
    mimeTypes,
    {
      on: {
        change: (e) => {
          if (e.target.value) {
            onChange(state, {
              mode: filePickerModes.UPLOAD,
              upload: e.target.files[0],
            });
          }
        },
      },
    },
  );
};

/**
 * Show a file URL modal to specify a remote file to download to the server.
 */
const selectUrl = (state, getInput, onChange) => async () => {
  const url = getInput("url")?.value;
  const newUrl = await getModal().async(fileUrlModal({ url }));
  if (newUrl) {
    /* eslint-disable-next-line no-param-reassign */
    onChange(state, {
      mode: filePickerModes.URL,
      url: newUrl,
    });
  }
};

/**
 * Tab for uploading a new file, or providing a file URL to download.
 * @function uploadFileTab
 * @private
 */
export default function filePickerUploadFileTab(state, onChange = () => {}) {
  const hasCurrentSelection = (
    (
      (state.value.mode === filePickerModes.URL && state.value.url)
      || (state.value.mode === filePickerModes.UPLOAD && state.value.upload)
    )
  );
  let fileName = "";
  if (state.value.mode === filePickerModes.URL && state.value.url) {
    fileName = state.value.url;
  } else if (state.value.mode === filePickerModes.UPLOAD && state.value.upload) {
    fileName = state.value.upload.name;
  }
  const getInput = makeGetInput(state.name);
  const urlInput = makeUrlInput(state, getInput, onChange);
  const fileInput = makeFileInput(state, onChange);

  return div(
    ".file-upload",
    {},
    [
      ...hasCurrentSelection
        ? [cameoFile({
          file: { name: fileName },
          valid: true,
          onRemove: onRemoveUploadFile(state, onChange, getInput),
          controls: [
            state.value.mode === filePickerModes.URL ? button({
              icon: "edit",
              iconOnly: true,
              onClick: selectUrl(state, getInput, onChange),
              sel: ".file-picker-upload-url-edit",
            }) : "",
          ],
        })]
        : [
          state.allowedModes.includes(filePickerModes.UPLOAD) ? button.standIn({
            icon: "upload",
            label: "Upload",
            onClick: () => fileInput.elm?.click?.(),
            sel: ".file-picker-upload-file",
          }) : "",
          state.allowedModes.includes(filePickerModes.URL)
            ? button.standIn({
              icon: "download",
              label: "Select URL",
              onClick: selectUrl(state, getInput, onChange),
              sel: ".file-picker-upload-url",
            })
            : "",
        ],
      urlInput,
      fileInput,
    ],
  );
}
