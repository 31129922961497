/**
 * Components for the header/navbar used on most pages.
 *
 * @module ui/component/header
 * @category UI
 * @subcategory Components
 */
import { userIsManager } from "model/user";
import { featureTypes } from "model/config/constants";
import {
  a,
  button,
  div,
  figure,
  form,
  h1,
  header,
  img,
  input,
  span,
} from "ui/html";
import formButton from "ui/component/form-managed/button";
import avatar from "ui/component/avatar";
import icon from "ui/component/icon";
import { qs } from "util/dom";
import { frozen, merge } from "util/object";
import { emit } from "util/event";
import { getBrandingLogoUrl } from "util/ui-customization";
import hashmap from "util/hash-map";
import { isAnonymous } from "util/user";
import { getModal } from "ui/view/modal-view";
import loginModal from "ui/component/modal/login";
import { featureEnabled } from "util/feature-flag";
import getConfig from "config";
import menuV2 from "./menu-v2";
import * as staticMenus from "./static-menus";
import menu from "./menu";

/**
 * Gets the current page slug
 */
const getSlug = () => {
  let slug = document.location.pathname.replace(/^\//, "");
  if (slug === "") slug = "home";
  return slug;
};

/**
 * The search section.
 */
const search = (terms) => form(
  "#search",
  {
    props: {
      method: "GET",
      action: "/search",
    },
    on: {
      click: () => qs("form#search input")
        .focus(),
      submit: (e) => {
        const slug = getSlug();
        // let search page handle this without redirecting
        if (slug === "search" || slug === "see-all") {
          e.preventDefault();
          const newTerms = qs("form#search input")?.value;
          emit(document.body, "em:search-terms", { value: newTerms });
        }
      },
    },
  },
  [
    button(
      [icon.sharp("magnifying-glass", ".open"), icon.sharp("xmark", ".close")],
      () => {
      },
      "#search-toggle",
      "button",
      "search",
      false,
      { attrs: { tabindex: "-1" } },
    ),
    input.text(terms === null ? "" : terms, false, "terms", {
      props: {
        placeholder: "Search...",
      },
      attrs: {
        "aria-label": "Search Query",
        tabindex: 0,
      },
    }),
    button(
      icon.sharp("magnifying-glass"),
      false,
      ".search",
      "submit",
      "submit search",
      false,
      { attrs: { tabindex: "-1" } },
    ),
    button(
      icon.sharp("xmark"),
      () => {
      },
      ".clear",
      "button",
      "clear search query",
      false,
      {
        // letting this fire on 'click' causes a problem where the search
        // bar instantly closes in mobile layouts
        on: {
          mousedown: () => {
            qs("#search input").value = "";
            document.activeElement.blur();
            // then the button becomes focused...
            setTimeout(() => document.activeElement.blur(), 100);
          },
        },
      },
    ),
  ],
);

const logo = () => figure(".logo", [
  a(
    img("", getBrandingLogoUrl() || "/media/logo-smartedge-padded.svg", "SmartEdge"),
    "/",
    "",
    { attrs: { tabindex: "-1" } },
  ),
]);

const defaultState = frozen({
  user: null,
  title: "",
  terms: "",
  pages: hashmap(),
  horizontal: true,
});

const backButton = () => button(
  icon.sharp("arrow-left"),
  () => window.history.back(),
  ".back-button",
);

const loginOrRegisterButton = () => {
  if (!featureEnabled(featureTypes.USER_MANAGEMENT)) return "";
  return formButton.primary({
    label: "Login",
    onClick: async () => {
      const config = await getConfig();
      getModal().async(loginModal({ config }));
    },
    sel: ".login-button",
  });
};

/**
 * Create a user-area header bar from the given state.
 * @function headerBar
 * @param {object} state
 * @param {?module:api/types/user~User} [state.user]
 * @param {?module:model/notification~Array<NotificationItem>} [state.notifications]
 * @param {string} [state.searchParams.terms] search terms taken from the query string
 * @param {string} [state.title] page title (unused)
 * @param {boolean} [state.horizontal] horizontal menu shape
 */
export default function headerBar({ state: inState }) {
  const state = merge(defaultState, inState || {});

  // we can't build a header without knowing the user's state
  // and nothing without a user should be showing a header anyway
  // if we end up with a public/unauthenticated interface someday
  // we will need a userless header instead
  if (!state.user) return header();
  const sel = state.horizontal ? ".with-back-button-container" : "";
  return header(
    state.horizontal ? ".horizontal" : "",
    [
      menu.user(state),
      state.title
        ? div(sel, [
          state.horizontal ? backButton() : "",
          span([
            state.title ? h1(state.title, "", {
              attrs: {
                title: state.title,
              },
            }) : logo(),
          ], ".logo-title"),
        ])
        : logo(),
      div(".header-right", [
        search(state.searchParams?.terms?.join?.(" ") || ""),
        isAnonymous(state.user)
          ? loginOrRegisterButton()
          : avatar({
            ...state,
            menuItems: userIsManager(state.user)
              ? staticMenus.adminAccountActions
              : staticMenus.accountActions,
          }),
      ]),
    ],
  );
}

/**
 * create an admin-area header bar from the given state.
 *
 * @member admin
 * @type function
 * @param {object} state
 * @param {object} state.user
 */
headerBar.admin = ({ state = defaultState }) => header(
  ".admin",
  [menu.admin(state.user), logo(), span()],
);

/**
 * Dashboards use a different header and body layout than other admin
 * pages.
 *
 * @member dashboard
 * @type function
 * @param {object} view
 * @param {object} view.state.user
 */
headerBar.dashboard = ({ state = defaultState }) => header(
  ".dashboard",
  [
    menuV2.admin(state.user),
    backButton(),
    span([
      logo(),
      h1(state.title),
    ], ".logo-title"),
    avatar({
      ...state,
      menuItems: staticMenus.adminAccountActions,
    }),
  ],
);

/**
 * Static header for the login / register / etc pages
 *
 * @member login
 * @type function
 */
headerBar.login = () => header(
  "",
  [
    logo(),
  ],
);

/**
 * Static header for the login / register / etc pages
 *
 * @member register
 * @type function
 */
headerBar.register = () => header(
  "",
  [
    logo(),
  ],
);
