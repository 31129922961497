/**
 * Detailed message item component (used in all messages)
 *
 * @module ui/component/chat/message-detailed
 * @category UI
 * @subcategory Chat
 */
import { merge } from "util/object";
import { a, div, span } from "ui/html";
import { usHourMinute, weekdayNameWithMonthName } from "util/date";
import getAvatar from "ui/component/messaging/avatar";
import getUsername from "ui/component/messaging/username";
import { chatMode } from "./common";
import messageAttachmentPreview from "./message-attachment-preview";

const defaultState = {
  message: null,
  counter: 0,
  onClickUser: () => {},
  onSelectMessage: () => {},
};

const selectableSelector = (state) => (state.chatMode === chatMode.MESSAGE_SELECTION ? ".selectable" : "");

const selectedSelector = (state, message) => (state.selectedMessages.some((m) => m.id === message.id) ? ".selected" : "");

const typeSelector = (message, fromUser) => {
  let sel = "";
  if (fromUser?.isModerator) sel += ".moderator";
  if (message.superReply) sel += ".super-reply";
  return sel;
};

export default function messageDetailed(inState, modalView) {
  const state = merge(defaultState, inState);
  const { me, message, counter } = state;
  let fromUser = state.knownUsers.get(message.from);
  if (!fromUser) fromUser = message.fromUser;
  if (!fromUser) fromUser = { username: "(unknown user)", PLACEHOLDER: true };
  return div(".message-detailed.other", [
    div(".date", weekdayNameWithMonthName(new Date(message.created))),
    div(
      `.message-details${selectableSelector(state)}${selectedSelector(state, message)}${typeSelector(message, fromUser)}`,
      {
        on: {
          click: () => {
            if (state.chatMode === chatMode.MESSAGE_SELECTION) {
              state.onSelectMessage(message);
            } else {
              state.onClickUser(fromUser.id);
            }
          },
        },
      },
      [
        div(".chat-avatar", a.fn(
          getAvatar(fromUser),
          () => { if (fromUser.id !== me.id) state.onClickUser(fromUser.id); },
        )),
        div(".container-text", [
          div(".container-name", [
            a.fn(
              div(".name", getUsername(fromUser)),
              () => { if (fromUser.id !== me.id) state.onClickUser(fromUser.id); },
            ),
            div(".time", usHourMinute(new Date(message.created))),
          ]),
          div(".text-wrapper", [
            div(".text", message.text),
            counter > 0 ? span(counter, ".mention-badge") : "",
          ]),
          div(
            ".chat-attachments",
            message?.attachments?.map((attachment) => (state.descriptors?.get(attachment)
              ? messageAttachmentPreview({
                attachment: state.descriptors.get(attachment),
              }, modalView)
              : "")),
          ),
        ]),
        // No functionality for button group as of now
        // buttonGroup([
        //   button.icon({
        //     icon: "ellipsis-v",
        //     sel: ".inverse",
        //   }),
        // ]),
      ],
    ),
  ]);
}
