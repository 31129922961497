import { endpoints, services } from "./api/constants";
import { responseToServerConfig } from "./model/config";

let cache = null;
let fetching = false;

/**
 * Cache for a generated base URLs, so that it only has to be generated once.
 *
 * @private
 * @constant URL_MAP
 * @type {Map.<services, string>}
 * @readonly
 */
const URL_MAP = new Map([
  [services.DEFAULT, null],
  [services.FE, null],
  [services.MBE, null],
  [services.RABE, null],
  [services.THEME, null],
  [services.WABE, null],
]);

const generateUrlFromConfig = (configuration) => {
  const { protocol, host, port } = configuration || {};
  const aPort = port ? `:${port}` : "";
  const aProto = protocol || "https";
  return `${aProto}://${host}${aPort}`;
};

export const getURLCacheByService = (service) => URL_MAP.get(service);

export const getConfigFromCache = () => cache;

export default async function getConfig() {
  if (cache === null && fetching === false) {
    try {
      fetching = true;
      const config = await fetch("/config").then((res) => res.json());
      const backendUrl = generateUrlFromConfig(config.server);
      const analyticsUrl = generateUrlFromConfig(config.analyticsServer);
      const analyticsReadUrl = generateUrlFromConfig(config.analyticsReadServer);
      URL_MAP.set(services.DEFAULT, backendUrl);
      URL_MAP.set(services.WABE, analyticsUrl);
      URL_MAP.set(services.RABE, analyticsReadUrl);
      URL_MAP.set(services.MBE, generateUrlFromConfig(config.messageServer));
      URL_MAP.set(services.THEME, generateUrlFromConfig(config.themeServer));
      URL_MAP.set(services.FE, generateUrlFromConfig({
        host: window.location.host,
      }));
      const [backend, info] = await Promise.all([
        fetch(
          `${backendUrl}${endpoints.SERVER_CONFIG}`,
          { mode: "cors" },
        ).then((res) => res.json()),
        fetch(
          `${backendUrl}${endpoints.SERVER_INFO}`,
          { mode: "cors" },
        ).then((res) => res.json()),
      ]);
      config.server = responseToServerConfig({ ...config.server, ...backend, ...info });
      fetching = false;
      cache = config;
    } catch (e) {
      /* we do want this to go to console regardless of environment */
      /* eslint-disable-next-line no-console */
      console.error("Failed to load system configuration!", e);
    }
  } else if (cache === null && fetching === true) {
    return new Promise((res, rej) => {
      try {
        const inter = setInterval(() => {
          if (cache) {
            res(cache);
            clearInterval(inter);
          }
        }, 10);
      } catch (e) {
        rej(e);
      }
    });
  } else if (fetching === true) {
    /* we do want this to go to console regardless of environment */
    /* eslint-disable-next-line no-console */
    console.error("Something went terribly wrong while waiting for server config...");
  }
  return cache;
}

window.config = getConfig;
