/**
 * Error subclasses.
 *
 * @module model/error
 * @category Model
 * @subcategory Error
 */

/**
 * An error thrown when a model constraint fails.
 *
 * @class ConstraintError
 * @extends Error
 * @param {string} fieldName
 * @param {string} message describing the problem
 * @param {mixed} value the value that triggered the error
 * @param {mixed[]} ...params other params to pass to the Error class
 * @property {string} property the name of the invalid property
 * @property {string} problem the problem with the invalid property
 * @property {string} name ConstraintError
 * @property {string} message a full error message for logging
 */
/* eslint-disable-next-line import/prefer-default-export */
export class ConstraintError extends Error {
  constructor(fieldName = "an unspecified field", message = "", value = null, ...params) {
    super(...params);
    this.property = fieldName;
    this.problem = message;
    this.name = 'ConstraintError';
    this.message = `Constraint error in ${fieldName}: ${message} (type: "${typeof value}", value: "${JSON.stringify(value)}")`;
  }
}

/**
 * @class APIResponseError
 * @extends Error
 * @memberof {module:model/error}
 * @param {Response} response fetch response
 * @property {string} name
 * @property {APIRequest} request originating request object
 * @property {mixed} body parsed response body
 * @property {integer} statusCode http status code
 * @property {string} statusText parsed status/error text, if available
 */
export class APIResponseError extends Error {
  constructor(response) {
    if (response.body) {
      if (response.body.errors instanceof Array) super(response.body.errors.join("\n"));
      else if (response.body.errors) super(response.body.errors);
      else if (response.body.message) super(response.body.message);
      else super("API Error");
    } else super("API Error");
    this.name = "APIResponseError";
    this.request = response.request;
    this.body = response.body;
    this.statusCode = response.status;
    this.statusText = response.body.status || response.statusText || response.status;
  }
}

/**
 * Thrown in pages when checking user authentication.
 * @class UnauthorizedError
 */
export class UnauthorizedError extends Error {
  constructor() {
    super();
    this.name = "UnauthorizedError";
  }
}

/**
 * Thrown when trying to modify anonymous user.
 * @class AnonymousUserModificationError
 */
export class AnonymousUserModificationError extends Error {
  constructor() {
    super();
    this.name = "AnonymousUserModificationError";
  }
}
