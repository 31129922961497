/**
 * API endpoints for the `fe-cfg` key:value store. Used to store
 * arbitrary site configuration details.
 *
 * @module api/site
 * @category Backend API
 * @subcategory Configuration
 */
import log from "log";
import cache from "cache";
import { defaultMenu, makeMenuDTO, makePageMenuDTO, responseToMenu } from "model/site/menu";
import {
  BRANDING_KEY,
  COLOR_PALETTE_KEY,
  CONFIGURATION_KEY,
  MAIN_MENU_KEY,
  CURRENT_MENU_VERSION,
  PAGE_MENU_KEY,
} from "model/site/constants";
import {
  makeColorPaletteDTO,
  makeUIConfigurationDTO,
  responseToColorPalette,
  responseToConfiguration,
} from "model/site";
import { makeBrandingDTO, responseToBranding } from "model/site/branding";
import { get, post } from "./request";
import { endpoints } from "./constants";

const MENU_URL = `${endpoints.FE_CFG}/${MAIN_MENU_KEY}`;

const PAGE_MENU_URL = (pageId) => `${endpoints.FE_CFG}/${PAGE_MENU_KEY(pageId)}`;

const getMenuUncached = async () => {
  try {
    return responseToMenu(JSON.parse((await get(MENU_URL))?.body));
  } catch (e) {
    log.error(e);
    log.debug("failed to load menu, using default");
    // menu may not be populated yet; that's OK
    return defaultMenu;
  }
};

/**
 * Get admin-managed navigation menu.
 *
 * @function getMenu
 * @param {boolean} [skipCache=false] skips the in-browser cache if true
 * @return {module:model/site/menu~Menu}
 */
export const getMenu = async (skipCache = false) => {
  if (skipCache) {
    const response = await getMenuUncached();
    cache.storeExpiring(MAIN_MENU_KEY, response);
    return response;
  }
  const cached = cache.getOrRefreshExpiring(
    MAIN_MENU_KEY,
    getMenuUncached,
  );
  // always get a new menu if the cached version is out of date
  // this means the user last loaded the site before receiving an updated menu
  // model and will force him to pull the latest, plus trigger any upgrades
  if (cached.version !== CURRENT_MENU_VERSION) return getMenuUncached();
  return cached;
};

/**
 * Save the admin-managed navigation menu.
 *
 * @function saveMenu
 * @param {object} partial matching the [Menu model]{@link module:model/site/menu~Menu}
 * @return {module:model/site/menu~Menu} the menu, as saved
 */
export const saveMenu = async (partial) => {
  await post(endpoints.FE_CFG, null, makeMenuDTO(partial));
  return getMenu(true);
};

const getPageMenuUncached = async (pageId) => {
  try {
    return responseToMenu(JSON.parse((await get(PAGE_MENU_URL(pageId)))?.body));
  } catch (e) {
    log.error(e);
    log.debug("failed to load page menu, using default");
    // menu may not be populated yet; that's OK
    return defaultMenu;
  }
};

/**
 * Get admin-managed page menu.
 *
 * @function getPageMenu
 * @param {string} pageId
 * @param {boolean} [skipCache=false] skips the in-browser cache if true
 * @return {module:model/site/menu~Menu}
 */
export const getPageMenu = async (pageId, skipCache = false) => {
  if (!pageId) return null;
  if (skipCache) {
    const response = await getPageMenuUncached(pageId);
    cache.storeExpiring(PAGE_MENU_KEY(pageId), response);
    return response;
  }
  const cached = cache.getOrRefreshExpiring(
    PAGE_MENU_KEY(pageId),
    () => getPageMenuUncached(pageId),
  );
  // always get a new menu if the cached version is out of date
  // this means the user last loaded the site before receiving an updated menu
  // model and will force him to pull the latest, plus trigger any upgrades
  if (cached.version !== CURRENT_MENU_VERSION) return getPageMenuUncached(pageId);
  return cached;
};

/**
 * Save the admin-managed page menu.
 *
 * @function savePageMenu
 * @param {string} pageId
 * @param {object} partial matching the [Menu model]{@link module:model/site/menu~Menu}
 * @return {module:model/site/menu~Menu} the menu, as saved
 */
export const savePageMenu = async (pageId, partial) => {
  await post(endpoints.FE_CFG, null, makePageMenuDTO(pageId, partial));
  return getPageMenu(pageId, true);
};

const BRANDING_URL = `${endpoints.FE_CFG}/${BRANDING_KEY}`;

const getBrandingUncached = async () => {
  try {
    return responseToBranding(JSON.parse((await get(BRANDING_URL))?.body));
  } catch (e) {
    return {};
  }
};

/**
 * Get admin-managed branding.
 *
 * @function getBranding
 * @param {boolean} [skipCache=false] skips the in-browser cache if true
 * @return {Branding}
 */
export const getBranding = async (skipCache = false) => {
  if (skipCache) {
    const response = await getBrandingUncached();
    cache.storeExpiring(BRANDING_KEY, response);
    return response;
  }
  return cache.getOrRefreshExpiring(
    BRANDING_KEY,
    getBrandingUncached,
  );
};

/**
 * Save the admin-managed branding.
 *
 * @function saveBranding
 * @param {object} partial matching the
 * [Color palette model]{Branding}
 * @return {Branding} the branding model, as saved
 */
export const saveBranding = async (partial) => {
  await post(endpoints.FE_CFG, null, makeBrandingDTO(partial));
  return getBranding(true);
};

const COLOR_PALETTE_URL = `${endpoints.FE_CFG}/${COLOR_PALETTE_KEY}`;

const getColorPaletteUncached = async () => {
  try {
    return responseToColorPalette(JSON.parse((await get(COLOR_PALETTE_URL))?.body));
  } catch (e) {
    return {};
  }
};

/**
 * Get admin-managed color palette.
 *
 * @function getColorPalette
 * @param {boolean} [skipCache=false] skips the in-browser cache if true
 * @return {module:model/site/color-palette~ColorPalette}
 */
export const getColorPalette = async (skipCache = false) => {
  if (skipCache) {
    const response = await getColorPaletteUncached();
    cache.storeExpiring(COLOR_PALETTE_KEY, response);
    return response;
  }
  return cache.getOrRefreshExpiring(
    COLOR_PALETTE_KEY,
    getColorPaletteUncached,
  );
};

/**
 * Save the admin-managed color palette.
 *
 * @function saveColorPalette
 * @param {object} partial matching the
 * [Color palette model]{@link module:model/site/color-palette~ColorPalette}
 * @return {module:model/site/color-palette~ColorPalette} the color palette model, as saved
 */
export const saveColorPalette = async (partial) => {
  await post(endpoints.FE_CFG, null, makeColorPaletteDTO(partial));
  return getColorPalette(true);
};

const CONFIGURATION_URL = `${endpoints.FE_CFG}/${CONFIGURATION_KEY}`;

const getConfigurationUncached = async () => {
  try {
    return responseToConfiguration(JSON.parse((await get(CONFIGURATION_URL))?.body));
  } catch (e) {
    return responseToConfiguration({});
  }
};

/**
 * Get admin-managed UI configuration.
 *
 * @function getUIConfiguration
 * @param {boolean} [skipCache=false] skips the in-browser cache if true
 * @return {module:model/site/configuration~UIConfiguration}
 */
export const getUIConfiguration = async (skipCache = false) => {
  if (skipCache) {
    const response = await getConfigurationUncached();
    cache.storeExpiring(CONFIGURATION_KEY, response);
    return response;
  }
  return cache.getOrRefreshExpiring(
    CONFIGURATION_KEY,
    getConfigurationUncached,
  );
};

/**
 * Save the admin-managed UI configuration.
 *
 * @function saveUIConfiguration
 * @param {object} partial matching the
 * [UI configuration model]{@link module:model/site/configuration~UIConfiguration}
 * @return {module:model/site/configuration~UIConfiguration} the UI configuration model, as saved
 */
export const saveUIConfiguration = async (partial) => {
  await post(endpoints.FE_CFG, null, makeUIConfigurationDTO(partial));
  return getUIConfiguration(true);
};
