/**
 * Helpers for internal forms in modals.
 *
 * @module ui/component/modal/bound-form
 * @category UI
 * @subcategory Modal Dialogs
 */
import span from "ui/html/span";
import formManagedView from "ui/view/form-managed";
import { getModal } from "ui/view/modal-view";

const storedForms = new Map();

const createPlaceholderElements = (formId) => {
  const formEl = document.createElement("FORM");
  formEl.id = formId;
  getModal().element.querySelector(".bound-modal-form").replaceChildren(formEl);
};

const bindInnerView = (formId) => {
  createPlaceholderElements(formId);
  const stored = storedForms.get(formId);
  if (stored) {
    stored.rebind(`#${formId}`);
    stored.qs(`:is(input, textarea)`)?.focus?.();
    return true;
  }
  return false;
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initInnerViews
 * @private
 */
export const initInnerView = (formId, contentFactory, state) => () => {
  if (bindInnerView(formId)) return;

  createPlaceholderElements(formId);
  const updateFn = (self) => {
    self.render();
  };
  const stored = formManagedView(contentFactory, updateFn)(`#${formId}`, state);
  storedForms.set(formId, stored);
};

export const deleteStoredForm = (formId) => storedForms.delete(formId);

/**
 * Handles setting up an internal form for a modal.
 *
 * For now this is experimental, and destroys the form when the node it's attached to
 * is destroyed. This is to prevent memory leaks. After further testing it may be safe
 * to disable this, or replace it with better logic. Note that this will mean nested
 * form modals won't work yet.
 *
 * Exposes a `view` property on the node for accessing the internal view directly.
 *
 * Example:
 * ```js
 * // in your modal component...
 * export default function myFormModal() {
 *   return dialog({
 *     // use the returned value just like any other element or component
 *     body: bindModalForm(
 *       "my-inner-form",
 *       (formView) => form("", formView.bind(
 *         // formView is a managed form view so we can bind form elements here
 *         [
 *           [textField, { name: "foo" }],
 *           [button, {
 *             label: formView.state.buttonLabel,
 *             onClick: () => console.log(myInnerForm.view.values),
 *           }],
 *         // and provide default form values
 *         ], { foo: "bar" })),
 *       // third parameter is for an initial state for the form view, if it needs one
 *       { buttonLabel: "clickme" },
 *     )
 *   });
 * }
 *
 * // elsewhere, open an instance of the modal
 * modalView.open(myFormModal());
 *
 * // then click the button...
 * // console logs: { foo: "bar" }
 * ```
 *
 *
 * @function bindModalForm
 * @param {string} formId must be unique in the current modal stack
 * @param {function} contentFactory as a view content factory
 * @param {object} state initial state of the form
 * @param {boolean} autoDestroy destroy node when unmounted
 * @return {module:ui/common/el~El} node to be used in a vNode tree
 */
export default function bindModalForm(formId, contentFactory, state = {}, autoDestroy = true) {
  const placeholderNode = span("", `.bound-modal-form`, {
    hook: {
      insert: initInnerView(formId, contentFactory, state),
      postpatch: initInnerView(formId, contentFactory, state),
      destroy: () => {
        if (autoDestroy) {
          storedForms.delete(formId);
        }
      },
    },
  });

  Object.defineProperties(placeholderNode, {
    // just in case you need a way to get at the internal view
    view: {
      get: () => storedForms.get(formId),
    },
    destroy: {
      get: () => () => deleteStoredForm(formId),
    },
  });

  return placeholderNode;
}
