/**
 * Builds course module carousels for a dynamic page.
 *
 * @module ui/page/dynamic/section-course-carousel
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import {
  h2,
  div,
  section,
} from "ui/html";
import { isEmptyModule, isSingleLecture } from "model/course";
import cardGrid from "ui/component/card-grid";
import { moduleItemCard } from "ui/component/card/module-item";
import singleLectureModuleCard from "ui/component/card/single-lecture-module";
import placeholder from "ui/component/placeholder";
import { getAttendance, showSingleLecture, shouldSkipModule } from "./common";

const makeSectionId = (pageSection, moduleId) => `course-grid-${pageSection.id}:${moduleId}`;

const makeModuleGridSection = (pageSection, state) => (moduleId) => {
  const module = state.courseModules?.get(moduleId);
  if (!module) return section(`#course-grid-${moduleId}.course-grid`, cardGrid.placeholder(`#course-grid-${moduleId}`, makeSectionId(pageSection, moduleId)));
  if (shouldSkipModule(module)) return "";

  const assessments = module.assessmentIds.map(
    (id) => state.assessments.get(id) || { PLACEHOLDER: true },
  );

  const attendance = getAttendance(state, module.courseId);

  const lectures = module.lectureIds.map(
    (id) => state.courseLectures.get(id) || { PLACEHOLDER: true },
  );

  if (showSingleLecture(module, lectures)) {
    return singleLectureModuleCard(state, lectures[0], attendance);
  }

  const title = isSingleLecture(module) ? lectures[0].title : module.title;
  const items = isEmptyModule(lectures) ? [...assessments] : [...lectures, ...assessments];

  return section(
    `.course-grid${attendance ? ".enabled" : ".disabled"}`,
    { key: makeSectionId(pageSection, moduleId) },
    [
      title ? h2(title) : "",
      cardGrid({
        id: makeSectionId(pageSection, moduleId),
        type: pageSection.type,
        items,
        title,
        text: module.text,
        cardFactory: moduleItemCard(state),
      }),
    ],
  );
};

const buildPlaceholder = (id) => div(`#course-grid-${id}.course-grid`, [
  h2(placeholder()),
  cardGrid.placeholder(".playlist-grid"),
]);

export default function sectionCourseGrid(state, id) {
  const pageSection = state.sections.get(id);
  if (!pageSection || pageSection.PLACEHOLDER) return buildPlaceholder();
  const course = state.courses?.get(pageSection.itemId);
  if (!course) return buildPlaceholder();

  return div(
    `#course-grid-${id}.course-grids`,
    { key: `course-grid-${id}` },
    course.moduleIds.map(
      makeModuleGridSection(pageSection, state),
    ),
  );
}
