/**
 * Helpers for showing modal dialogs in dynamic page forms.
 *
 * @module ui/page/admin/dynamic/modals
 * @private
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
/***/
import {
  pageTypes,
  pageTypesFriendly,
  pageTypeFeatureRequirements,
} from "model/dynamic-page/constants";
import {
  span,
} from "ui/html";
import sectionIcon from "ui/component/section-icon";
import listSelectModal from "ui/component/modal/list-select";
import sectionEditModal from "ui/component/modal/section-edit";
import { featuresEnabled } from "util/feature-flag";

/**
 * Raise the section select dialog.
 *
 * @function selectSectionType
 * @async
 * @param {ModalDialog} modalDialog
 * @return {Promise<object>} partial section state with section type populated
 */
export const selectSectionType = async (modalDialog) => {
  const entries = [...pageTypesFriendly.entries()]
    .filter(([key]) => (
      (key !== pageTypes.INDEPENDENT)
      && featuresEnabled(pageTypeFeatureRequirements.get(key) || [])
    ))
    .map(([key, value]) => ({
      label: span([sectionIcon({ type: key }), value]),
      title: value,
      type: key,
    }));

  return modalDialog.async(listSelectModal({
    entries,
  }, modalDialog));
};

/**
 * Raise the edit section dialog.
 *
 * @function editSection
 * @async
 * @param {object} state section state
 * @return {Promise<object>} updated section
 */
export const editSection = async (state, modalDialog, notificationView) => modalDialog.async(
  sectionEditModal(state, modalDialog, notificationView),
  false,
);
