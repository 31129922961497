/**
 * Notification test examples
 *
 * @module ui/page/admin/test/notifications
 * @private
 */
/***/
import div from "ui/html/div";
import h3 from "ui/html/h3";
import button from "ui/html/button";
import collapsible from "ui/component/collapsible";
import { notificationMessageTypes } from "model/notification/constants";

const notificationMockText = "This is sample notification text";

export default (notificationView) => () => div(
  "#notification-sample",
  [
    h3("Announcements"),
    collapsible({
      title: "Notifications",
    }, [
      button("Open basic notification", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.BASIC,
        });
      }),
      button("Open success notification", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.SUCCESS,
        });
      }),
      button("Open fail notification", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.FAIL,
        });
      }),
      button("Open basic notification (3 sec)", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.BASIC,
          duration: 3,
        });
      }),
      button("Open success notification (3 sec)", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.SUCCESS,
          duration: 3,
        });
      }),
      button("Open fail notification (3 sec)", () => {
        notificationView.post({
          title: "Title",
          text: notificationMockText,
          type: notificationMessageTypes.FAIL,
          duration: 3,
        });
      }),
      button("Title only", () => {
        notificationView.post({
          title: "Title",
          type: notificationMessageTypes.SUCCESS,
        });
      }),
    ]),
  ],
);
