/**
 * Renders the plain-text header for dynamic pages.
 *
 * @module ui/page/dynamic/section-text-header
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import { h1, section } from "ui/html";

export default function sectionTextHeader(pageSection) {
  if (!pageSection.title) return "";
  return section(".text-header", h1(pageSection.title));
}
