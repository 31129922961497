/**
 * Shared default state and content for evaluation pages.
 *
 * @module ui/page/admin/evaluation/manage/state
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import { frozen } from "util/object";
import { sortOrders } from "ui/component/dynamic-table";
import { notificationMessageTypes } from "model/notification/constants";

export const defaultSearchParams = frozen({
  user: null,
  course: null,
  assessment: null,
  pageNumber: 1,
  search: "",
});

export const manageModes = frozen({
  INCOMPLETE: "INCOMPLETE",
  UNGRADED: "UNGRADED",
  GRADED: "GRADED",
});

export const manageModeTabIndex = frozen(new Map([
  [manageModes.INCOMPLETE, 0],
  [manageModes.UNGRADED, 1],
  [manageModes.GRADED, 2],
]));

export const searchModes = frozen({
  ADVANCED: "ADVANCED",
  BASIC: "BASIC",
  NONE: null,
});

export const defaultEvaluationsPage = frozen({
  currentPage: 0,
  totalPages: 0,
  items: [],
});

export const defaultManageState = frozen({
  searchParams: { ...defaultSearchParams },
  evaluations: [],
  evaluationsPage: defaultEvaluationsPage,
  users: [],
  mode: manageModes.UNGRADED,
  searchMode: searchModes.NONE,
  sortOrder: sortOrders.ASC,
  sortColumn: "title",
});

export const staticMessages = frozen({
  success: {
    title: "Success",
    text: "Saved!",
    type: notificationMessageTypes.SUCCESS,
  },
  invalid: { title: "Error", text: "Please correct the errors.", type: notificationMessageTypes.FAIL },
  backendError: { title: "Error", text: "Failed to save", type: notificationMessageTypes.FAIL },
});
