import { clone } from "util/object";
import { lecturesMock } from "./lecture";
import { mockFileDescriptor } from "./file";

export const assessmentMock = {
  id: "FAKE_QUIZ_ID_1",
  title: "Quiz 1",
  description: "Quiz description",
  slug: "test-slug",
  startDateTime: "2021-12-07T10:34:39.703Z",
  endDateTime: "2021-12-07T10:34:39.703Z",
  maxPoints: 5,
  randomizeQuestions: true,
  questions: [
    {
      id: "FAKE_QUESTION_1",
      title: "string",
      text: "string",
      weight: 0,
      randomizeAnswers: true,
      questionType: "MULTIPLE_CHOICE_SINGLE_ANSWER",
      answers: [
        {
          value: "Answer 1",
          correct: false,
        },
        {
          value: "Answer 2",
          correct: true,
        },
      ],
    },
  ],
  mandatory: false,
};

export const assessmentMocks = [
  {
    ...assessmentMock,
    title: "Assessment 1",
  },
  {
    ...assessmentMock,
    id: "FAKE_QUIZ_ID_2",
    title: "Assessment 2",
    startDateTime: "2021-11-07T10:34:39.703Z",
    endDateTime: "2021-12-22T10:34:39.703Z",
  },
];

export const courseMock = {
  id: "FAKE_COURSE_1",
  title: "Course 1",
  description: "Fake description",
  modules: [
    {
      id: "FAKE_MODULE_1",
      title: "Module 1",
      description: "Fake module description",
      lectures: [...lecturesMock],
      assessments: [...assessmentMocks],
    },
    {
      id: "FAKE_MODULE_2",
      title: "Module 2",
      description: "Fake module 2 description",
      lectures: [...lecturesMock],
    },
  ],
};

export const coursesMock = [
  courseMock,
  {
    ...courseMock,
    id: "FAKE_COURSE_2",
    title: "Course 2",
  },
  {
    ...courseMock,
    id: "FAKE_COURSE_3",
    title: "Course 3",
  },
  {
    ...courseMock,
    id: "FAKE_COURSE_4",
    title: "Course 4",
  },
];

export const createCourseMock = {
  ...courseMock,
  id: "NEW_COURSE_ID",
  title: "New title",
};

export const answerMock = {
  value: "Answer 1",
  correct: false,
};

export const questionMock = {
  id: "FAKE_QUESTION_1",
  title: "Question Number 1",
  text: "Please answer question number 1.",
  weight: 0,
  randomizeAnswers: true,
  questionType: "MULTIPLE_CHOICE_SINGLE_ANSWER",
  answers: [
    clone(answerMock),
    {
      value: answerMock.value.replace("1", "2"),
      correct: true,
    },
  ],
};

export const gradingMock = {
  id: "FAKE_GRADING_ID_1",
  manualOrAutomatic: true,
  gradeBrackets: [
    {
      id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      pointsFrom: 0,
      pointsTo: 10,
      description: "string",
    },
  ],
};

export const mockSolutionUpload = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  question: {
    id: "sea85f64-5717-4562-b3fc-2c963f66afa6",
    title: "string",
    text: "What about file uploading?",
    weight: 0,
    randomizeAnswers: true,
    questionType: "UPLOAD",
    answers: [],
  },
  value: [
    mockFileDescriptor,
    {
      ...mockFileDescriptor,
      name: "File 2",
    },
  ],
  score: {
    id: "3ff85f64-5717-4562-b3fc-2c963f66afa6",
    points: null,
    description: "I like the answer",
  },
};

export const mockSolutionMultipleChoice = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  question: {
    id: "sea85f64-5717-4562-b3fc-2c963f66afa6",
    title: "string",
    text: "How about multiple choice?",
    weight: 2,
    randomizeAnswers: true,
    questionType: "MULTIPLE_CHOICE_MULTI_ANSWER",
    answers: [
      {
        value: "Correct 1",
        correct: true,
      },
      {
        value: "Incorrect 1",
        correct: false,
      },
      {
        value: "Correct 2",
        correct: true,
      },
    ],
  },
  value: [
    "Correct 1",
    "Incorrect 1",
  ],
  score: {
    id: "3ff85f64-5717-4562-b3fc-2c963f66afa6",
    points: 2,
    description: "I like the answer",
  },
};

export const mockSolutionTextBox = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  question: {
    id: "sea85f64-5717-4562-b3fc-2c963f66afa6",
    title: "string",
    text: "What about text answer?",
    weight: 0,
    randomizeAnswers: true,
    questionType: "FILL_IN_LONG",
    answers: [],
  },
  value: [
    "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores at"
    + " consequatur cum delectus distinctio earum esse explicabo iusto numquam, "
    + "placeat praesentium quidem quis quo reprehenderit sed similique tempore"
    + " velit vitae.",
  ],
  score: {
    id: "3ff85f64-5717-4562-b3fc-2c963f66afa6",
    points: 2,
    description: "I like the answer",
  },
};

export const evaluationMock = {
  id: "fake-eval-1",
  status: "IN_PROGRESS",
  assessment: {
    id: "df3f894d-0385-4683-a906-25081dd08308",
    title: "Quiz 1",
    description: "Quiz description",
    slug: "test-slug",
    startDateTime: "2021-12-07T10:34:39.703Z",
    endDateTime: "2021-12-07T10:34:39.703Z",
    maxPoints: 5,
    randomizeQuestions: true,
    questions: [
      {
        id: "FAKE_QUESTION_1",
        title: "Multiple question single answer???",
        text: "string",
        weight: 3,
        randomizeAnswers: true,
        questionType: "MULTIPLE_CHOICE_SINGLE_ANSWER",
        answers: [
          {
            value: "Answer 1",
            correct: false,
          },
          {
            value: "Answer 2",
            correct: true,
          },
          {
            value: "Answer 3",
            correct: false,
          },
        ],
      },
      {
        id: "FAKE_QUESTION_2",
        title: "Text question??",
        text: "string",
        weight: 6,
        randomizeAnswers: false,
        questionType: "FILL_IN_LONG",
        answers: [],
      },
      {
        id: "FAKE_QUESTION_3",
        title: "File question??",
        text: "string",
        weight: 9,
        randomizeAnswers: false,
        questionType: "UPLOAD",
        answers: [],
      },
    ],
    mandatory: false,
  },
  publishTime: new Date("2022-02-09T11:50:56.225Z"),
  student: {
    cellPhone: null,
    email: null,
    enabled: true,
    external: false,
    firstName: "John",
    groups: [],
    id: "1b1d888c-42e1-4425-8374-6e522a042419",
    lastName: "Doe",
    username: "admin",
  },
  solutions: [
    mockSolutionMultipleChoice,
    mockSolutionTextBox,
    mockSolutionUpload,
  ],
  score: {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    points: 0,
    description: "Score description",
  },
};
