/**
 * A managed hidden field.
 *
 * Note that hidden inputs do not fire onchange events, so when updating the value
 * you'll need to emit "em:form-managed-change" manually in order for its new
 * value to be reflected in the form's values object. If you do not, its value
 * will not be updated until the next time a form field changes.
 *
 * TODO: find some kind of workaround for the onChange problem
 *
 * @module ui/component/form-managed/field-generic
 * @category UI
 * @subcategory Forms
 */
import input from "ui/html/input";
import { frozen, merge } from "util/object";
import { managedField } from "ui/view/form-managed/util";

const defaultState = frozen({
  name: "hidden",
  value: null,
  disabled: false,
});

export default function hiddenInput(inState = {}) {
  const state = merge(defaultState, inState);
  const { disabled, name, value } = state;
  return managedField(
    input.hidden(value, name, { attrs: { disabled } }),
    name,
    (node) => node.elm.value,
  );
}
