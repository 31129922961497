/**
 * Shared functions internal to utility modules (not meant for re-export).
 *
 * @module util/internal
 * @private
 * @category Utilities
 */

export const undefinedOrNull = (a) => a === null || a === undefined;

export const normalizeToNull = (a) => (
  a !== ""
  && a !== undefined
  && a !== null
  && (!(a instanceof Array) || a.length)
  && (!(a instanceof Object) || Object.keys(a).length)
    ? a : null);
