import { userTypes } from "model/user/constants";

export const fakeUser = {
  id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  username: "fakeuser",
  firstName: "Fake",
  lastName: "User",
  cellPhone: "+11234567890",
  email: "fake.user@smartedge.com",
  enabled: true,
  external: true,
  userLists: [
    {
      userGroupId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      userGroupName: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    },
  ],
  userType: userTypes.REGISTERED,
};

export const fakeAnonymousUser = {
  ...fakeUser,
  userType: userTypes.ANONYMOUS,
};

export const userList = [
  { ...fakeUser, username: "fakeuser1", lastName: "One", id: "FAKE_USER_1" },
  { ...fakeUser, username: "fakeuser2", lastName: "Two", id: "FAKE_USER_2" },
  { ...fakeUser, username: "fakeuser3", lastName: "Three", id: "FAKE_USER_3" },
  { ...fakeUser, username: "fakeuser4", lastName: "Four", id: "FAKE_USER_4" },
  { ...fakeUser, username: "fakeuser5", lastName: "Five", id: "FAKE_USER_5" },
  { ...fakeUser, username: "fakeuser6", lastName: "Six", id: "FAKE_USER_6" },
];
