/**
 * Metadata label streams.
 *
 * @module data/metadata/label
 * @category Data Streams
 * @subcategory Metadata
 */
import { countLabels } from "api/v2/metadata/label";
import countPipe from "data/pipeline/count";
import hashmap from "util/hash-map";
import { streamLog } from "data/stream/compose";

const countMap = hashmap();

const {
  post: count,
  sink$: countSink$,
} = countPipe({
  apiFn: countLabels,
  defaultFn: () => 0,
  modelFn: (r) => r,
  accumulator: countMap,
});

export default {
  count,
  count$: countSink$.compose(streamLog("LABEL_COUNT$")),
};
