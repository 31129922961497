/**
 * A page for adding a user.
 *
 * Corresponds to `markup/admin/add-user.html`
 *
 * @module ui/page/admin/user/add
 * @category Pages
 * @subcategory Admin - Users
 */
import api from "api";
import getConfig from "config";
import { featureTypes } from "model/config/constants";
import { notificationMessageTypes } from "model/notification/constants";
import div from "ui/html/div";
import actionBar from "ui/component/dashboard-action-bar";
import form from "ui/component/form-managed";
import cellPhone from "ui/component/form-managed/field-phone-number";
import email from "ui/component/form-managed/field-email-address";
import firstName from "ui/component/form-managed/field-given-name";
import lastName from "ui/component/form-managed/field-family-name";
import passwordConfirmed from "ui/component/form-managed/field-password-confirmed";
import toggle from "ui/component/form-managed/field-toggle";
import username from "ui/component/form-managed/field-username";
import button from "ui/component/form-managed/button";
import widget from "ui/component/dashboard-widget";
import view from "ui/view";
import { guard } from "util/feature-flag";
import formManagedView from "ui/view/form-managed";
import { frozen, merge } from "util/object";
import { tidyBackendError } from "util/error";
import dashboardLayout from "ui/page/layout/dashboard";

let actions, header, modal, notification, profileView;
const { FAIL } = notificationMessageTypes;

const defaultState = frozen({ user: {} });

const staticMessages = frozen({
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: FAIL },
  noPermission: { title: "Access Denied", text: "You do not have permission to create a user.", type: FAIL },
});
const defaultError = { error: { text: "", type: "error" } };

const doSave = async () => {
  const self = profileView;
  self.setFullValidation(true);
  const validation = self.validate();
  if (!validation.valid) {
    self.update({
      validation,
      messages: [staticMessages.invalid],
    });
    return;
  }

  const data = {
    username: self.element.querySelector("[name=username]").value,
    firstName: self.element.querySelector("[name=firstName]").value,
    lastName: self.element.querySelector("[name=lastName]").value,
    email: self.element.querySelector("[name=email]").value,
    cellPhone: self.element.querySelector("[name=cellPhone]").value,
    password: self.element.querySelector("[name=newPasswordConfirm]").value,
    enabled: self.values.enabled,
  };

  try {
    const { id: userId } = await api.user.create(data);
    const choice = await modal.confirm(
      "User profile created.",
      "",
      "Add Another",
      "Go to User Profile",
    );
    if (choice) {
      window.location.reload();
    } else {
      window.location.replace(`/admin/user-profile?id=${userId}`);
    }
  } catch (e) {
    switch (e.statusCode) {
      case 403:
        notification.post(staticMessages.permissionError);
        break;
      case undefined:
        notification.post({
          title: "API Error",
          text: e.message,
          type: "error",
        });
        break;
      default:
        tidyBackendError(e.body).forEach((err) => {
          defaultError.error.text += `\r\n ${err}`;
        });
        notification.post(defaultError.error);
    }
  }
};

const showAddUserForm = (self) => {
  const { server } = self.state.config;
  const required = true;
  const { takenUsernames, takenEmails, takenPhones } = self.state;

  return form("#user-profile-form", self.bind([
    [username, { required, taken: takenUsernames }],
    [firstName, { required: server.firstNameRequired }],
    [lastName, { required: server.lastNameRequired }],
    [cellPhone, { required: server.cellPhoneRequired, taken: takenPhones }],
    [email, { required: server.emailRequired, taken: takenEmails }],
    [passwordConfirmed, { required }],
    [toggle.boxed.inverse, { name: "enabled", label: "Verified" }],
  ]));
};

const showActions = () => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: doSave,
    disabled: !profileView.validate().valid,
  }),
]);

/*
 * An updateFn callback for the form view.
 *
 * This will get called any time the form changes (defined as when a form element
 * goes out of focus with new content), or when `page.update()` is called.
 *
 * Before this hook is called the page runs validation and exposes updated validation
 * state on `page.validation`.
 *
 * Here we take values from the form exposed in `page.values` and update the user model
 * in page.state, then we patch the form view with the updated model and validation
 * status.
 *
 * @function doUpdate
 * @private
*/
const doUpdate = (page) => {
  page.updateState({
    user: page.values,
  });
  page.render();
  if (actions) actions.render();
};

/**
 * The page initializer for add users.
 *
 * @function addUser
 * @param {module:ui/html~Selector} selector the root element for the add user form
 * @param {object} initialState (deprecated)
 */
export default async function addUser(selector, initialState = defaultState) {
  guard(featureTypes.USER_MANAGEMENT);
  const title = "Add User";
  ({ header, modal, notification } = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget(form("#add-user-form"), "Profile"),
    ],
    title,
    true,
  ));
  const [
    config,
    allUsers,
    me,
  ] = await Promise.all([
    getConfig(),
    api.user.list(),
    api.user.getMe(),
  ]);
  const state = merge(defaultState, merge(initialState, {
    user: {},
    me,
    allUsers,
    takenUsernames: allUsers.map((u) => u.username),
    takenEmails: allUsers.map((u) => u.email),
    takenPhones: allUsers.map((u) => u.cellPhone),
    config,
  }));

  profileView = formManagedView(showAddUserForm, doUpdate)("#add-user-form", state);
  actions = view.create(showActions)("#actions", state);
  header.update({ title, user: me });
}
