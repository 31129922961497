/**
 * A answer on the student assessment page.
 *
 * @module ui/page/assessment/answer
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import {
  div,
  h3,
  p,
} from "ui/html";
import { splitParagraphs } from "util/format";
import { frozen, merge } from "util/object";
import { questionType } from "model/course/constants";
import answerRadio from "./answer-radio";
import answerChecklist from "./answer-checklist";
import answerFileUpload from "./answer-file-upload";
import answerTextInput from "./answer-text-input";
import answerTextarea from "./answer-textarea";

const typeClass = (type) => type.replace(/_/g, "-").toLowerCase();

const shuffled = (randomize) => (randomize ? ".shuffled" : "");

const defaultShowQuestionState = frozen({
  question: null,
  solution: [],
  validity: { fields: [], valid: true },
  onFileSelect: () => {},
  onFileRemove: () => {},
  files: [],
  required: false,
});

const showQuestionBlock = (state, answerFactory, view) => {
  const { question } = state;
  return div(
    `.question.question-${typeClass(question.questionType)}${shuffled(question.randomizeAnswers)}`,
    [
      h3(question.title),
      ...splitParagraphs(question.text).map((line) => p(line)),
      answerFactory(state, view),
    ],
  );
};

export default function showQuestion(inState, view) {
  const state = merge(defaultShowQuestionState, inState);
  switch (state.question.questionType) {
    case questionType.FILL_IN_LONG:
      return showQuestionBlock(state, answerTextarea, view);
    case questionType.MULTIPLE_CHOICE_MULTI_ANSWER:
      return showQuestionBlock(state, answerChecklist, view);
    case questionType.MULTIPLE_CHOICE_SINGLE_ANSWER:
      return showQuestionBlock(state, answerRadio, view);
    case questionType.UPLOAD:
      return showQuestionBlock(state, answerFileUpload, view);
    case questionType.FILL_IN_SHORT:
    default:
      return showQuestionBlock(state, answerTextInput, view);
  }
}
