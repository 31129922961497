import { videoMetadataList } from "api/mocks/metadata";
import { aclGroupList } from "api/mocks/acl";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import { button } from "ui/html";
import contentPreviewDialog from "ui/component/modal/content";
import groupPreviewDialog from "ui/component/modal/group";
import userPreviewDialog from "ui/component/modal/user";
import { fakeAnonymousUser } from "api/mocks/user";
import { fakeUser } from "../mocks";

export default (modalView) => collapsible({ title: "Preview Dialogs", open: false }, [
  button(["Content Preview", icon("photo-video")], () => {
    modalView.async(contentPreviewDialog({
      metadata: videoMetadataList[0],
      sameTab: false,
    }, modalView));
  }, ".subtle.forward"),
  button(["User Preview", icon("user")], () => {
    modalView.async(userPreviewDialog({
      user: fakeUser,
    }, modalView));
  }, ".subtle.forward"),
  button(["User Preview (With Groups)", icon("user")], () => {
    modalView.async(userPreviewDialog({
      user: {
        ...fakeUser,
        groups: aclGroupList,
      },
    }, modalView));
  }, ".subtle.forward"),
  button(["Anonymous User Preview", icon("user")], () => {
    modalView.async(userPreviewDialog({
      user: fakeAnonymousUser,
    }, modalView));
  }, ".subtle.forward"),
  button(["Group Preview", icon("users")], () => {
    modalView.async(groupPreviewDialog({
      group: aclGroupList[0],
    }, modalView));
  }, ".subtle.forward"),
]);
