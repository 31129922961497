import api from "api";
import log from "log";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import advancedAssessmentSearch from "ui/component/modal/advanced-assessment-search";
import advancedContentSearch from "ui/component/modal/advanced-content-search";
import advancedCourseSearch from "ui/component/modal/advanced-course-search";
import advancedEvaluationSearch from "ui/component/modal/advanced-evaluation-search";
import advancedGroupSearch from "ui/component/modal/advanced-group-search";
import advancedUserSearch from "ui/component/modal/advanced-user-search";
import { button } from "ui/html";

export default (modalView) => collapsible(
  { title: "Advanced Search", open: false },
  [
    button(
      ["Advanced Assessment Search", icon.sharp("magnifying-glass")],
      async () => {
        const params = await modalView.async(
          advancedAssessmentSearch({}, modalView),
        );
        log.debug("user selected search params", params);
      },
      ".subtle.forward",
    ),
    button(
      ["Advanced Content Search", icon.sharp("magnifying-glass")],
      async () => {
        const params = await modalView.async(advancedContentSearch({
          labels: ["label1", "label2", "label3"],
        }, modalView));
        log.debug("user selected search params", params);
      },
      ".subtle.forward",
    ),
    button(
      ["Advanced Course Search", icon.sharp("magnifying-glass")],
      async () => {
        const params = await modalView.async(
          advancedCourseSearch({}, modalView),
        );
        log.debug("user selected search params", params);
      },
      ".subtle.forward",
    ),
    button(
      ["Advanced Evaluation Search", icon.sharp("magnifying-glass")],
      async () => {
        const [users, courses] = await Promise.all([
          api.user.list(),
          api.course.listCourses(),
        ]);
        await modalView.async(advancedEvaluationSearch({
          users,
          courses,
        }, modalView));
      },
      ".subtle.forward",
    ),
    button(
      ["Advanced Group Search", icon.sharp("magnifying-glass")],
      async () => {
        const params = await modalView.async(
          advancedGroupSearch({}, modalView),
        );
        log.debug("user selected search params", params);
      },
      ".subtle.forward",
    ),
    button(
      ["Advanced User Search", icon.sharp("magnifying-glass")],
      async () => {
        const params = await modalView.async(
          advancedUserSearch({}, modalView),
        );
        log.debug("user selected search params", params);
      },
      ".subtle.forward",
    ),
  ],
);
