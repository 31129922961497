/**
 * API requests for abstract items.
 *
 * @module api/abstract
 */
import log from "log";
import cache from "cache";
import {
  getRecentlyWatched,
  getTrending,
  getMostWatched,
} from "api/v2/analytics/video-playback";
import { byIds as videosByIds } from "api/v2/metadata/video";
import { endpoints } from "api/constants";
import { responseToAbstractItem, makeAbstractSearchParams } from "model/abstract-item";
import { get } from "api/request";
import { searchDataModes, searchDataPresets } from "model/search/constants";
import { metadataTypes } from "model/metadata/constants";

/**
 * Get abstract items by UUID.
 *
 * @function byId
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~VideoMetadata}
 */
export const byId = async (uuid) => responseToAbstractItem(
  (await get(`${endpoints.ABSTRACT_ITEM}/${uuid}`)).body,
);

/**
 * Get multiple abstract items by id.
 *
 * @function byIds
 * @param {UUID[]} uuids
 * @returns {Promise.<VideoMetadata[]>}
 */
export const byIds = async (uuids) => (await Promise.all(
  uuids.map((uuid) => byId(uuid).catch(() => null)),
)).filter((i) => i !== null);

/**
 * Do preset searches.
 *
 * TODO refactor such that batch streams can handle video results,
 *      to save on duplicate requests.
 *
 * @function doPresetSearch
 */
const doPresetSearch = async (partial) => {
  let ids;
  switch (partial.preset) {
    case searchDataPresets.RECENTLY_ADDED:
      return (await get(
        endpoints.ABSTRACT_ITEM,
        makeAbstractSearchParams({
          sortCriteriaList: ["BY_CREATION_DATE_DESC"],
          maxItems: partial.maxItems,
        }),
      )).body;
    case searchDataPresets.RECENTLY_WATCHED:
      ids = await getRecentlyWatched(cache.getProfile()?.id);
      return videosByIds(ids.map((v) => v.id));
    case searchDataPresets.MOST_WATCHED:
      ids = await getMostWatched(partial.maxItems);
      return videosByIds(ids.map((v) => v.id));
    case searchDataPresets.TRENDING:
      ids = await getTrending(partial.maxItems);
      return videosByIds(ids.map((v) => v.id));
    default:
      return [];
  }
};

const excludeItemTypes = (params) => (item) => {
  if (
    !params.searchVideos
    && !params.searchDocuments
    && !params.searchPlaylists
  ) return true;
  if (params.searchVideos && item.type === metadataTypes.VIDEO) return true;
  if (params.searchDocuments && item.type === metadataTypes.DOCUMENT) return true;
  return !!(params.searchPlaylists && item.type === metadataTypes.LIST);
};

/**
 * Search metadata.
 *
 * @function search
 * @param {object} partial matching {@link SearchModel}
 * @return {AbstractItem[]}
 */
export const search = async (partial) => {
  let result = [];
  switch (partial.mode) {
    case searchDataModes.PRESET:
      try {
        // FIXME limiting results here shouldn't have to be done but not all apis support limits
        result = (await doPresetSearch(partial)).slice(0, partial.maxItems);
      } catch (e) {
        log.debug("abstract item search error", e);
        result = [];
      }
      break;
    case searchDataModes.ADVANCED:
    default:
      /// FIXME shouldn't have to try/catch because this api should not have errors,
      //        it should only return empty set when nothing can be provided
      try {
        result = ((await get(
          endpoints.ABSTRACT_ITEM,
          makeAbstractSearchParams(partial),
        )).body);
      } catch (e) {
        log.debug("abstract item search error", e);
        result = [];
      }
  }

  const filtered = result.filter(excludeItemTypes(partial));
  return filtered.map(responseToAbstractItem);
};
