/**
 * Fontawesome icons.
 *
 * @module ui/component/icon
 * @category UI
 * @subcategory Components
 */
import el from "ui/common/el";
import { merge } from "util/object";

/**
 * A fontawesome icon. Pass it the icon's name minus the 'fa-' prefix.
 *
 * For example to display "fa-check", call `icon("check")`.
 *
 * Note you can stick other class names after the icon name in CSS selector style,
 * e.g.: `icon("check.warn")` but this is not officially supported and could break
 * someday.
 *
 * @example
 * ```js
 * const okIcon = icon("check");
 * ```
 *
 * @function icon
 * @param {string} [iconName="bug"] the name of the icon (without the fa- prefix)
 * @param {object} [properties] additional snabbbdom properties
 */
const icon = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.fa.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

/**
 * Icons from the fontawesome "solid" (`fas`) set.
 */
icon.solid = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.fas.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

/**
 * Icons from the fontawesome "regular" (`far`) set.
 */
icon.regular = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.far.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

icon.light = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.fal.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

icon.duotone = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.fad.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

icon.sharp = (iconName = "bug", sel = "", properties = {}) => el(
  `i.icon.fa.fa-solid.fa-sharp.fa-${iconName}${sel}`,
  merge(
    { attrs: { "aria-hidden": "true" } },
    properties,
  ),
);

export default icon;
