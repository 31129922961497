import { featureFlagsFriendly, featureTypesFrontend } from "model/config/constants";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import span from "ui/html/span";
import badge from "ui/component/badge";
import { featureEnabled } from "util/feature-flag";

const featureEntry = ([feature, title]) => [
  span(title, ".label"),
  span(badge(featureEnabled(feature) ? 'enabled' : 'disabled'), '.value'),
];

export default function settingsBlock() {
  return div(
    "#features.block",
    [
      h3("SmartEdge Features"),
      ...[...featureFlagsFriendly.entries()].map(featureEntry).flat(),
      span("Mobile App", ".label"),
      span(
        featureEnabled(featureTypesFrontend.PWA)
          ? badge("enabled")
          : badge("disabled"),
        ".value",
      ),
    ],
  );
}
