import { merge } from "util/object";
import dialog from "ui/component/modal/layout/dialog";
import { a as link, div, span } from "ui/html";
import icon from "ui/component/icon";
import getUsername from "ui/component/messaging/username";
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import { usFullDateFormat } from "util/date";
import button from "ui/component/form-managed/button";
import { getIconName } from "ui/component/group-icon";

const defaultState = {
  message: {},
  onReport: () => {},
  onRemove: () => {},
};

const buildBody = (state, modalView) => {
  const { message, onReport, onRemove } = state;
  const {
    fromUser, toUser, toGroup, created, status, text,
  } = message;
  return div("", [
    div(".title", [
      div(".label", [
        link(
          [icon.solid("user"), getUsername(fromUser)],
          `/admin/user-profile?id=${fromUser.id}`,
          ".username",
        ),
        span("to", ".delimiter"),
        toGroup
          ? div(".label", [
            icon.solid(getIconName(toGroup.name)),
            span(systemPrivilegeGroupsFriendly.get(toGroup.name) || toGroup.name, ".username"),
          ])
          : link(
            [icon.solid("user"), getUsername(toUser)],
            `/admin/user-profile?id=${toUser.id}`,
            ".username",
          ),
      ]),
    ]),

    div(".container-justified", [
      div(".created", [
        span(usFullDateFormat(created)),
      ]),
      div(".status", [
        span("Status: "),
        span(status),
      ]),
    ]),

    div(".message-body", text),

    div(".controls", [
      button.alternate({
        label: "Report",
        onClick: async () => {
          if (
            !(await modalView.confirm(
              "Are you sure you want to report the message?",
              null,
              "Yes",
              "No",
              true,
            ))
          ) {
            return;
          }
          modalView.resolve();
          onReport();
        },
      }),
      button.warning({
        label: "Remove",
        onClick: async () => {
          if (
            !(await modalView.confirm(
              "Are you sure you want to remove the message?",
              null,
              "Yes",
              "No",
              true,
            ))
          ) {
            return;
          }
          modalView.resolve();
          onRemove();
        },
      }),
    ]),
  ]);
};

/**
 * A modal for taking action on a message.
 *
 * @function messageProfileModal
 * @param {Object} inState
 * @param {Object} modalView
 */
export default function messageProfileModal(inState, modalView = null) {
  const state = merge(defaultState, inState);

  return dialog({
    sel: ".message-profile-dialog",
    body: buildBody(state, modalView),
  }, modalView);
}
