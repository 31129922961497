/**
 * A modal dialog for selecting a metadata item.
 *
 * @module ui/component/modal/metadata-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import listModal from "ui/component/modal/layout/list";
import cameoMetadata from "ui/component/cameo-metadata";
import { metadataTitleFilter } from "util/filter";
import { merge, frozen } from "util/object";
import { getModal } from "ui/view/modal-view";

const doSearch = (state) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(metadataSelectModal(merge(state, { search })));
};

const sortByTitle = (a, b) => {
  const at = a.title.toLowerCase();
  const bt = b.title.toLowerCase();
  if (at < bt) return -1;
  if (at > bt) return 1;
  return 0;
};

const defaultOnSelect = () => {};

const defaultState = frozen({
  entries: [],
  onSelect: defaultOnSelect,
  isAsync: null,
  search: "",
});

/**
 * A modal dialog for selecting an item from a list of metadata.
 * @param {Object} state
 * @param {MetadataItem[]} state.entries
 * @param {?function} state.onClose
 * @param {?function} state.onSelect
 * @param {?boolean} [state.isAsync=false]
 * @param {string} [state.search=""]
 * @return {El}
 */
export default function metadataSelectModal(inState) {
  const state = merge(defaultState, inState);
  return listModal({
    entries: state.entries
      .filter(metadataTitleFilter(state.search))
      .sort(sortByTitle)
      .map((metadata) => cameoMetadata({
        metadata,
        config: state.onSelect
          ? {
            on: {
              click: () => {
                state.onSelect(metadata);
                if (!state.isAsync) getModal().close();
              },
            },
          }
          : [],
      })),
    onSearch: doSearch(state),
    searchText: state.search,
    sel: ".metadata-select",
    isAsync: state.isAsync === true,
  });
}

metadataSelectModal.async = (state) => metadataSelectModal({
  ...state,
  isAsync: true,
  onSelect: (selected) => getModal().resolve(selected),
});
