/**
 * The basic INPUT element and various factories for specific input types with
 * handling for their properties. Applies sane default constraint properties
 * for each input type.
 *
 * @example
 * // an email input. it will not be flagged required and will have the name emailAddress
 * const email = ui.html.input.email("someone@somewhere.com", false, "emailAddress");
 *
 * @module ui/html/input
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';
import { merge } from "util/object";
import {
  emailConstraints,
  passwordConstraints,
  textConstraints,
  usernameConstraints,
} from "ui/common/validation-constraints";

/**
 * Creates an input element.
 *
 * Accepts any number of additional parameters as configuration objects, which
 * are merged in order.
 *
 * @function input
 * @param {string} [type=text] any valid input type
 * @param {mixed} [value=""]
 * @param {boolean} [required=false]
 * @param {string} [name=username]
 * @param {object} ...configs
 */
const input = (type = "text", value = "", required = false, name = "generic", ...configs) => {
  const merged = merge.all([
    {
      props: { name, required, type },
      // note: text inputs will not have value in their dom if this is set by a prop
      // this doesn't break anything UI-wise but it makes life hard for QA
      attrs: value ? { value } : undefined,
    },
    ...configs,
  ]);
  let sel = "";
  if (merged.sel) sel = merged.sel;
  delete merged.sel;
  return el(`input${sel || ""}`, merged);
};

/**
 * A color input element
 *
 * @param {string} value
 * @param {boolean} required
 * @param {string} name
 * @param {object} configs
 */
input.color = (value = "", required = false, name = "color", ...configs) => input(
  "color",
  value,
  required,
  name,
  ...configs,
);

/**
 * A checkbox-type input element.
 *
 * @function checkbox
 * @param {boolean} [checked=false]
 * @param {boolean} [required=false]
 * @param {string} [name=checkbox]
 * @param {object} ...configs
 */
input.checkbox = (checked = false, required = false, name = "checkbox", ...configs) => input(
  "checkbox",
  "on",
  required,
  name,
  { attrs: { checked } },
  ...configs,
);

/**
 * A radio-type input element.
 *
 * @function radio
 * @param {boolean} [checked=false]
 * @param {boolean} [required=false]
 * @param {string} [name="radio"]
 * @param {string} [value=name]
 * @param {object} ...configs
 */
input.radio = (checked = false, required = false, name = "radio", value = null, ...configs) => input(
  "radio",
  value === null ? name : `${value}`,
  required,
  name,
  { attrs: { checked } },
  ...configs,
);

/**
 * An email-type input element with basic validation constraints.
 *
 * @function email
 * @param {boolean} [value=""]
 * @param {boolean} [required=false]
 * @param {string} [name=remember]
 * @param {object} ...configs
 */
input.email = (value = "", required = false, name = "email", ...configs) => input(
  "email",
  value,
  required,
  name,
  emailConstraints,
  ...configs,
);

/**
 * A password-type input element with standard SmartEdge password constraints.
 *
 * @function password
 * @param {mixed} [value=""]
 * @param {boolean} [required=false]
 * @param {string} [name=password]
 * @param {object} ...configs
 */
input.password = (value = "", required = false, name = "password", ...configs) => input(
  "password",
  value,
  required,
  name,
  passwordConstraints,
  ...configs,
);

/**
 * A text-type input with standard validation settings.
 *
 * @function text
 * @param {boolean} [checked=false]
 * @param {boolean} [required=false]
 * @param {string} [name=remember]
 * @param {object} ...configs
 */
input.text = (value = "", required = false, name = "text", ...configs) => input(
  "text",
  value,
  required,
  name,
  textConstraints,
  ...configs,
);

/**
 * An input specifically for usernames, with username-specific constraints.
 *
 * @function username
 * @param {mixed} [value=""]
 * @param {boolean} [required=false]
 * @param {string} [name=username]
 * @param {object} ...configs
 */
input.username = (value = "", required = false, name = "username", ...configs) => input(
  "text",
  value,
  required,
  name,
  usernameConstraints,
  ...configs,
);

input.file = (required = false, name = "username", accept = "*", ...configs) => input(
  "file",
  undefined,
  required,
  name,
  { attrs: { accept } },
  ...configs,
);

input.date = (value = "", required = false, name = "date", ...configs) => input(
  "date",
  value,
  required,
  name,
  // pattern fallback for unsupported browsers
  { attrs: { pattern: /\d{4}-\d{2}-\d{2}/ } },
  ...configs,
);

input.time = (value = "", required = false, name = "date", ...configs) => input(
  "time",
  value,
  required,
  name,
  // pattern fallback for unsupported browsers
  { attrs: { pattern: /\d{2}:\d{2}/ } },
  ...configs,
);

input.hidden = (value = "", name = "hidden", ...configs) => input(
  "hidden",
  value,
  false,
  name,
  ...configs,
);

/**
 * A range input element
 *
 * @param {string} value
 * @param {boolean} required
 * @param {string} name
 * @param {object} configs
 */
input.range = (value = "", required = false, name = "range", ...configs) => input(
  "range",
  value,
  required,
  name,
  ...configs,
);

export default input;
