/**
 * A password input that can be toggled between masked and cleartext display.
 *
 * @module ui/component/form-managed/password-input-toggleable
 * @category UI
 * @subcategory Forms
 */
import icon from "ui/component/icon";
import genericField from "ui/component/form-managed/field-generic";
import { makeInputBindings } from "ui/component/form-managed/field-input";
import div from "ui/html/div";
import input from "ui/html/input";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Passwords must contain at least one lowercase letter, one uppercase letter and one number, and be between 8-255 characters.",
  required: "Please enter your password.",
  strengthLowerCaseMissing: "Passwords must contain at least one lowercase letter.",
  strengthNumberMissing: "Passwords must contain at least one number.",
  strengthUpperCaseMissing: "Passwords must contain at least one uppercase letter.",
  targetMismatch: "This password does not match.",
  tooShort: "Passwords must be at least 8 characters long.",
  tooLong: "Passwords must be less than 255 characters long.",
});

const constraints = frozen({
  attrs: {
    minlength: 8,
    maxlength: 255,
  },
});

const weakConstraints = frozen({
  attrs: {
    minlength: 1,
    maxlength: 255,
  },
});

const defaultState = ({
  name: "password",
  type: "password",
  label: "Password",
  autocomplete: "current-password",
  constraints,
  sel: "",
  helpTexts,
  weak: false,
  validHooks: [],
});

/* eslint-disable no-param-reassign */
const strongPasswordHook = (node, validity) => {
  const { value } = node.elm;
  if (!node.elm.value || validity.valueMissing) return;
  if (value.search(/[a-z]+/) === -1) {
    validity.strengthLowerCaseMissing = true;
    validity.valid = false;
  }
  if (value.search(/[A-Z]+/) === -1) {
    validity.strengthUpperCaseMissing = true;
    validity.valid = false;
  }
  if (value.search(/\d+/) === -1) {
    validity.strengthNumberMissing = true;
    validity.valid = false;
  }
  if (value.length < constraints.attrs.minlength) {
    validity.tooShort = true;
    validity.valid = false;
  }
  if (value.length > constraints.attrs.maxlength) {
    validity.tooLong = true;
    validity.valid = false;
  }
};

const toggleVisibility = (node) => {
  const visible = (node.elm.type === "text");

  if (visible) {
    node.elm.type = "password";
    node.elm.parentElement.querySelector("i").classList.remove("fa-eye-slash");
    node.elm.parentElement.querySelector("i").classList.add("fa-eye");
  } else {
    node.elm.type = "text";
    node.elm.parentElement.querySelector("i").classList.remove("fa-eye");
    node.elm.parentElement.querySelector("i").classList.add("fa-eye-slash");
  }
};

/**
 * Exported for use in paired password fields.
 */
export const internalElement = (state) => {
  const validHooks = [...state.validHooks];

  if (state.weak === false) validHooks.push(strongPasswordHook);

  const inputEl = input(
    state.type,
    state.value,
    state.required,
    state.name,
    state.weak ? constraints : weakConstraints,
    makeInputBindings(state),
  );

  return div(".inner", [
    managedField(
      inputEl,
      state.name,
      (node) => node.elm.value,
      validHooks,
    ),
    icon.light(
      "eye",
      "",
      {
        attrs: { ariaLabel: "show password", title: "show password" },
        on: {
          click: () => toggleVisibility(inputEl),
        },
      },
    ),
  ]);
};

/**
 * A password input that can be toggled between masked and cleartext display.
 *
 * Has to handle its validity internally since the input is wrapped by a div and can't
 * be bootstrapped by the field() factory.
 *
 * @function passwordInputToggleable
 * @param {Object} state
 */
export default function fieldPassword(inState) {
  const state = merge.all([
    defaultState,
    inState,
    {
      validHooks: [...defaultState.validHooks, ...inState?.validHooks || []],
    },
  ]);
  return genericField(
    state,
    internalElement(state),
    `${state.sel}.password`,
  );
}
/* eslint-enable no-param-reassign */
