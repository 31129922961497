/**
 * A component for displaying several elements in a tabbed layout.
 *
 * FIXME refactor to accept a single state parameter
 *
 * @module ui/component/tabs
 * @category UI
 * @subcategory Components
 */
import {
  div,
  span,
} from "ui/html";
import cutout from "ui/component/cutout";
import { merge } from "util/object";
import { emit } from "util/event";

let count = 0;

const getTabsContainer = (elm) => {
  if (elm.classList.contains("tab-container")) return elm;
  if (elm.parentElement) return getTabsContainer(elm.parentElement);
  return null;
};

const selectTab = (e, id) => {
  const container = getTabsContainer(e.currentTarget);
  if (!container) return;
  container.dataset.selectedTab = id;
  emit(container, "em:tab-change", { id });
};

const tab = (children, tabId) => cutout(
  children,
  ".tab",
  { dataset: { tabId: `${tabId}` } },
);

const notch = (onSelectTab) => (label, tabId) => span(
  label,
  ".notch",
  {
    dataset: { tabId: `${tabId}` },
    on: {
      click: (ev) => {
        selectTab(ev, tabId);
        onSelectTab(tabId);
      },
    },
  },
);

const setHeight = (_old, node) => {
  let height = 0;
  node.elm.querySelector(".tabs").childNodes.forEach((entry) => {
    height = Math.max(height, entry.offsetHeight);
  });
  /* eslint-disable-next-line no-param-reassign */
  node.elm.querySelector(".tabs").style.height = `${height}px`;
};

const defaultOnSelect = () => { /* nothing */ };

/**
 * @function tabs
 * @param {string[]} labels text labels for each tab
 * @param {module:ui/common/el~El[]} contents content node tree for each tab
 * @param {number} [selectedTab=0] currently selected tab
 * @param {module:ui/html~Selector} [sel=""] selector for the root element
 * @param {module:ui/html~Config} [config={}]
 * @param {boolean} [disabled=false]
 * @return {module:ui/html/div} `div.tab-container`
 */
const tabs = (
  labels,
  contents,
  selectedTab = 0,
  sel = "",
  data = {},
  disabled = false,
  onSelectTab = defaultOnSelect,
) => {
  if (labels.length !== contents.length) {
    throw new Error(
      "tabs component received mismatched labels and contents",
      "label length was",
      labels.length,
      "contents length was",
      contents.length,
      labels,
      contents,
    );
  }
  return div(
    `${sel}.tab-container${disabled ? ".disabled" : ""}`,
    merge(
      {
        dataset: {
          tabCount: `${labels.length}`,
          selectedTab: `${selectedTab}`,
          tabsId: `${(++count)}`,
        },
        hook: {
          insert: (node) => setHeight(undefined, node),
          postpatch: setHeight,
          // insert: setHeight,
          // update: setHeight,
        },
      },
      data,
    ),
    [
      div(".notches", labels.map(notch(onSelectTab))),
      div(".tabs", contents.map(tab)),
    ],
  );
};

export default tabs;
