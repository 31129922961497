/**
 * A hint message for form components.
 *
 * @module ui/component/form-managed/hint
 * @category UI
 * @subcategory Forms
 */
import message, { messageTypes } from "ui/component/message";
import { frozen, merge } from "util/object";

const { HINT, REQUIRED, INVALID } = messageTypes;

export const genericHelp = frozen({
  required: "This field is required.",
  tooShort: "This value is too short.",
  tooLong: "This value is too short.",
  invalid: "Please enter a valid value.",
  targetMismatch: "This field does not match its partner.",
  taken: "This value is already in use.",
});

const help = (text, type, key) => ({ text, type, sel: ".help", key });

const selectHelps = (helpTexts = {}, validity = { valid: true }) => {
  const texts = merge(genericHelp, helpTexts);
  const helps = [];
  if (!validity.valid) {
    Object.keys(validity)
      .filter((k) => k !== "valueMissing" && k !== "valid")
      .forEach((k) => {
        if (texts[k]) helps.push(help(texts[k], INVALID, k));
      });
    // if no more specific helps were available use the basic invalid help
    if (!helps.length) {
      if (validity.valueMissing) {
        helps.push(help(texts.required, REQUIRED, "valueMissing"));
      } else helps.push(help(texts.invalid, INVALID, "invalid"));
    }
  } else if (texts.hint) {
    helps.push(help(texts.hint, HINT));
  }

  return helps;
};

/**
 * Creates a collection of `message` elements based on form state and element.
 *
 * This is only meant to be used internally, e.g. by `generic-field`.
 *
 * @function hint
 * @return {El[]}
 */
export default function hint({ name, helpTexts = {}, validity = { valid: true } }) {
  return selectHelps(
    helpTexts,
    validity.fields?.[name] || { valid: true },
  ).map(message) || [];
}
