/**
 * A modal dialog for selecting a pre-existing group.
 *
 * @module ui/component/modal/acl/group-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { aclPrincipalTypes } from "model/acl/constants";
import listModal from "ui/component/modal/layout/list";
import cameoPrincipal from "ui/component/cameo-principal";
import { userGroupNameFilter } from "util/filter";
import { merge, frozen } from "util/object";
import { getModal } from "ui/view/modal-view";

const doSearch = (state) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(groupSelectModal({
    ...state,
    search,
  }));
};

const defaultState = frozen({
  groups: [],
  search: "",
  title: "",
});

/**
 * A modal dialog for selecting an ACL user group.
 *
  * Resolves with:
 * ```js
 * {
 *   type: aclPrincipalTypes,
 *   principal: UserGroupEntry,
 * }
 * ```
*
 * @function groupSelectModal
 * @async
 * @param {object} [inState=defaultState]
 * @param {UserGroupEntry[]} inState.groups
 * @param {string} inState.search
 * @return {module:ui/common/el~El}
 */
export default function groupSelectModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  return listModal({
    entries: state.groups
      .filter(userGroupNameFilter(state.search))
      .map((principal) => cameoPrincipal({
        principal,
        config: {
          on: {
            click: () => {
              getModal().resolve({ type: aclPrincipalTypes.GROUP, principal });
            },
          },
        },
      })),
    sel: ".group-select",
    onSearch: doSearch(state),
    searchText: state.search,
    title: state.title,
  });
}
