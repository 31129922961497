/**
 * A view for header menus.
 *
 * Just a shortcut for creating the header - doesn't do anything special.
 *
 * The header  view is set up automatically by the
 * [layout]{@link module:ui/page/layout~layout} helper function, so it shouldn't need to
 * be initialized by a page module.
 *
 * @module lib/ui/view/header-view
 * @category UI
 * @subcategory Views
 */
import xs from "xstream";
import cache from "cache";
import notificationStream from "data/notification";
import { MAIN_MENU_KEY, menuLinkTypes } from "model/site/constants";
import header from "ui/component/header";
import meStream from "data/user/me";
import configurationStream from "data/site/ui-configuration";
import pageStream from "data/dynamic-page";
import { frozen, merge } from "util/object";
import view from "./view";

const defaultState = frozen({
  user: {},
  title: "",
  horizontal: false,
  notifications: [],
});

const updateFn = (self) => {
  const { user, notifications } = self.state;
  // filter out received notifs from self but not to self, easier done here than
  // in stream
  self.updateState({
    notifications: notifications.filter(
      (m) => (m.from !== user.id) || (m.from === user.id && m.to === user.id),
    ),
  });
  self.patch(self.factory(self));
};

const getPageIds = (entries) => {
  let pageIds = [];
  entries.forEach((entry) => {
    if (entry.linkType === menuLinkTypes.LINK_PAGE && entry.pageId) {
      pageIds.push(entry.pageId);
      if (entry.entries?.length) pageIds = pageIds.concat(getPageIds(entry.entries));
    }
  });
  return pageIds;
};

const defaultMenu = Object.freeze({
  entries: [],
});

export default function headerView(selector, state = defaultState, factory = header) {
  const self = view(selector, merge(defaultState, state), factory, updateFn);
  const user$ = meStream.get();
  const notification$ = user$.map((u) => notificationStream.get(u)).flatten();
  const menu = cache.getExpired(MAIN_MENU_KEY) || defaultMenu;
  self.bindStreams([
    ["user", user$],
    ["horizontal", configurationStream.get().map((c) => c.horizontalMenu)],
    ["notifications", notification$],
    ["menu", xs.of(menu)],
    ["pages", pageStream.getMany(getPageIds(menu?.entries || []))],
  ]);
  self.update(state);
  return self;
}

headerView.admin = (selector, state = {}) => headerView(selector, state, header.admin);
headerView.dashboard = (selector, state = {}) => headerView(selector, state, header.dashboard);
