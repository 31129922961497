/**
 * A modal for displaying users attended to a chat channel
 *
 * @module ui/component/modal/chat/chat-user-list
 * @category UI
 * @subcategory Modal Dialogs
 */
import listModal from "ui/component/modal/layout/list";
import { merge } from "util/object";
import { h3 } from "ui/html";
import cameoUser from "ui/component/cameo-user";
import button from "ui/component/form-managed/button";
import { userNamesString } from "util/format";

const defaultState = {
  users: [],
  selectable: false,
  search: "",
  onUnblockUser: () => false,
};

const buildEntries = (state, modalView) => state.users
  .filter((user) => userNamesString(user).toLowerCase().includes(state.search.toLowerCase()))
  .map((user) => cameoUser({
    user,
    controls: [
      state.blockedUserIds?.has(user.id)
        ? button.warning({
          iconOnly: true,
          icon: "stop-circle",
          label: "blocked",
          onClick: async () => {
            const confirmed = await state.onUnblockUser(user.id);
            if (confirmed && modalView) {
              modalView.resolve(null);
            }
          },
        })
        : "",
    ],
    sel: `${user.isModerator ? ".moderator" : ""}`,
    config: {
      on: {
        click: () => {
          if (state.selectable && modalView) {
            modalView.resolve(user);
          }
        },
      },
    },
  }));

export default function chatUserListModal(inState = {}, modalView = null) {
  const state = merge(defaultState, inState);
  return listModal({
    sel: ".chat-user-list-dialog",
    header: h3("Users"),
    entries: buildEntries(state, modalView),
  }, modalView);
}
