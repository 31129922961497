/**
 * A modal dialog for managing ACL rights grants.
 *
 * @module ui/component/modal/acl/manage-grants
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  aclPrincipalTypes,
  aclRights,
  aclSubjectTypes,
  aclSubjectTypesFriendly,
  defaultGrant,
} from "model/acl/constants";
import form from "ui/component/form-managed";
import toggle from "ui/component/form-managed/field-toggle";
import button from "ui/component/form-managed/button";
import formManagedView from "ui/view/form-managed";
import closeFab from "ui/component/modal/component/close-fab";
import metadataSelectModal from "ui/component/modal/metadata-select";
import courseSelectModal from "ui/component/modal/course-select";
import principalSelectModal from "ui/component/modal/acl/principal-select";
import pageSelectModal from "ui/component/modal/page-select";
import cameoCourse from "ui/component/cameo-course";
import cameoPage from "ui/component/cameo-page";
import cameoMetadata from "ui/component/cameo-metadata";
import cameoPrincipal from "ui/component/cameo-principal";
import { getSubjectIconName } from "ui/component/subject-icon";
import { div, h3 } from "ui/html";
import { findCollateralRights } from "util/acl";
import { principalDisplayName, subjectDisplayName } from "util/format";
import { frozen, merge } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";
import { difference } from "util/array";
import { getNotification } from "ui/view/notification-view";
import getUsername from "ui/component/messaging/username";

let count = 0;
let storedFormView;

const { FAIL, BASIC } = notificationMessageTypes;

const staticMessages = frozen({
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 5,
  },
  selectPrincipal: {
    title: "Error",
    text: "Please select a user or group to continue.",
    type: FAIL,
    duration: 5,
  },
  selectSubject: {
    title: "Error",
    text: "Please select content to continue.",
    type: FAIL,
    duration: 5,
  },
  selectGrants: {
    title: "Error",
    text: "Please assign at least one right.",
    type: FAIL,
    duration: 5,
  },
  inheritsOwner: {
    title: "Ownership",
    text: "This user inherits ownership from a group, so no individual rights can be applied.",
    type: BASIC,
    duration: 5,
  },
  grantsNotChanged: (username) => ({
    title: "Access Rights Unchanged",
    text: `Your action did not result in a change to ${username}'s rights, so saving is unnecessary.`,
    type: BASIC,
    duration: 5,
  }),
});

const bindForm = (modalView) => {
  const formEl = document.createElement("FORM");
  formEl.id = "grants";
  modalView.element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#grants");
};

const selectSubject = async (modalView) => {
  const { subjectType, subjects } = storedFormView.state;
  let subject;
  switch (subjectType) {
    case aclSubjectTypes.PAGE:
      subject = await modalView.async(pageSelectModal({
        pages: subjects,
      }, modalView));
      break;
    case aclSubjectTypes.COURSE:
      subject = await modalView.async(courseSelectModal({
        courses: subjects,
      }, modalView));
      break;
    case aclSubjectTypes.MEDIA:
    default:
      subject = await modalView.async(metadataSelectModal.async({
        entries: subjects,
      }, modalView), false);
      break;
  }
  if (subject) {
    storedFormView.updateState({
      grant: { subject },
      messages: [],
    });
    bindForm(modalView);
  }
};

const findCollateralRightsInMultipleGrants = (principals, grants) => new Set(
  principals
    .map((principal) => {
      if (principal.type === aclPrincipalTypes.INDIVIDUAL) {
        return findCollateralRights(principal, grants);
      }
      return new Set();
    })
    .map((set) => Array.from(set))
    .flat(),
);

const selectPrincipals = async (modalView) => {
  const { principals, grant, grants } = storedFormView.state;
  const result = await modalView.async(principalSelectModal({
    principals,
    selectedPrincipals: grant.principals,
    multiSelect: true,
  }, modalView), false);
  if (result) {
    // first wipe the stored principal if any, otherwise old data will be merged
    // recursively into the new principal
    storedFormView.updateState({ grant: { principals: [] } });
    storedFormView.updateState({
      grant: {
        principals: result,
        rights: findCollateralRightsInMultipleGrants(result, grants),
      },
    });
    bindForm(modalView);
  }
};

const makeChecked = (rights, collateralRights) => (target) => (
  rights.has(target)
  || collateralRights.has(target)
  || collateralRights.has(aclRights.OWNER)
  || rights.has(aclRights.OWNER)
);

const makeDisabled = (rights, collateralRights) => (target) => (
  collateralRights.has(target)
  || (target !== aclRights.OWNER && (
    rights.has(aclRights.OWNER) || collateralRights.has(aclRights.OWNER)
  ))
);

const makeTooltip = (collateralRights) => (target) => {
  if (collateralRights.has(target)) return "This right is granted by a group.";
  return null;
};

const makeOnRightsChange = (modalView) => (target) => async (on) => {
  const { rights } = storedFormView.state.grant;
  const newRights = new Set([...rights]);
  if (!on) {
    newRights.delete(target);
  } else {
    newRights.add(target);
  }
  if (target === aclRights.OWNER) {
    newRights.add(aclRights.READ);
    newRights.add(aclRights.WRITE);
    newRights.add(aclRights.DELETE);
  }

  storedFormView.update({ grant: { rights: newRights } });
  bindForm(modalView);
};

const makeHeader = (state) => {
  const { principals, subjects } = state;
  const { principal, subject } = state.grant;
  if (!principals?.length && principal) {
    return `Manage Rights for ${principalDisplayName(principal)}`;
  }

  if (!subjects?.length && subject) {
    return `Manage Rights for ${subjectDisplayName(subject)}`;
  }

  return "Manage Rights";
};

const removeSubject = () => storedFormView.update({ grant: { subject: null } });

const buildSubject = (formView, modalView) => {
  const { subjectType, grant, subjects } = formView.state;
  const subjectTypeName = aclSubjectTypesFriendly.get(subjectType);

  if (!grant.subject && subjects?.length) {
    return button.standIn({
      icon: getSubjectIconName(subjectType),
      label: `Select ${subjectTypeName}`,
      onClick: () => selectSubject(modalView),
    });
  }

  if (subjects?.length) {
    const remove = [
      button.icon({
        icon: "trash",
        onClick: () => removeSubject(),
        sel: ".danger",
      }),
    ];

    switch (subjectType) {
      case aclSubjectTypes.PAGE:
        return cameoPage(grant.subject, remove);
      case aclSubjectTypes.COURSE:
        return cameoCourse({ course: grant.subject, controls: remove }, modalView);
      case aclSubjectTypes.MEDIA:
        return cameoMetadata({ metadata: grant.subject, controls: remove });
      default:
        return "";
    }
  }
  return "";
};

const removePrincipal = (principal, grant) => storedFormView.update({
  grant: {
    principals: grant.principals.filter((p) => p.id !== principal.id),
  },
});

const buildPrincipal = (formView, modalView) => {
  const { principals, grant } = formView.state;
  return principals?.length ? [
    ...grant.principals.map((principal) => cameoPrincipal({
      principal,
      controls: [
        button.icon({
          icon: "trash",
          onClick: () => removePrincipal(principal, grant),
          sel: ".danger",
        }),
      ],
    }, modalView)),
    button.standIn({
      icon: "users",
      label: "Select User/Group",
      onClick: () => selectPrincipals(modalView),
    }),
  ] : "";
};

const buildEditForm = (modalView) => (self) => {
  const {
    grant,
    grants,
  } = self.state;
  const collateralRightsSinglePrinciple = grant.principal?.type === aclPrincipalTypes.INDIVIDUAL
    ? findCollateralRights(grant.principal, grants)
    : new Set();
  const collateralRightsMultiPrinciples = findCollateralRightsInMultipleGrants(
    grant.principals,
    grants,
  );
  const collateralRights = new Set([
    ...Array.from(collateralRightsSinglePrinciple),
    ...Array.from(collateralRightsMultiPrinciples),
  ]);
  if (collateralRights.has(aclRights.OWNER)) {
    self.updateState({
      infoMessages: [
        ...self.state.infoMessages,
        staticMessages.inheritsOwner,
      ],
    });
  }
  const checked = makeChecked(grant.rights, collateralRights);
  // disable all buttons until principal is selected
  const disabled = (grant.principal || grant.principals?.length)
    ? makeDisabled(grant.rights, collateralRights)
    : () => true;
  const tooltip = makeTooltip(collateralRights);
  const onRightsChange = makeOnRightsChange(modalView);
  return form(
    "#grants",
    [
      div(".pane", [
        buildSubject(self, modalView),
        ...buildPrincipal(self, modalView),
      ]),
      div(".pane", self.bind([
        [
          toggle.boxed.inverse,
          {
            toggled: checked(aclRights.OWNER),
            name: "owner",
            label: "Owner",
            disabled: disabled(aclRights.OWNER),
            onToggle: onRightsChange(aclRights.OWNER),
            tooltip: tooltip(aclRights.OWNER),
          },
        ],
        [
          toggle.boxed.inverse,
          {
            toggled: checked(aclRights.READ),
            name: "view",
            label: "View",
            disabled: disabled(aclRights.READ),
            onToggle: onRightsChange(aclRights.READ),
            tooltip: tooltip(aclRights.READ),
          },
        ],
        [
          toggle.boxed.inverse,
          {
            toggled: checked(aclRights.WRITE),
            name: "edit",
            label: "Edit",
            disabled: disabled(aclRights.WRITE),
            onToggle: onRightsChange(aclRights.WRITE),
            tooltip: tooltip(aclRights.WRITE),
          },
        ],
        [
          toggle.boxed.inverse,
          {
            toggled: checked(aclRights.DELETE),
            name: "delete",
            label: "Delete",
            disabled: disabled(aclRights.DELETE),
            onToggle: onRightsChange(aclRights.DELETE),
            tooltip: tooltip(aclRights.DELETE),
          },
        ],
      ])),
      div(
        ".info-messages",
        self.state.infoMessages.map((m) => div(`.info-message.${m.type.toLowerCase()}`, m.text)),
      ),
    ],
  );
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  inner.patch(inner.factory(inner));
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initForm
 * @private
 */
const initForm = (state, modalView) => {
  if (storedFormView) {
    bindForm(modalView);
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "grants";
  modalView.element.querySelector(".body").replaceChildren(formEl);
  storedFormView = formManagedView(
    buildEditForm(modalView),
    onFormUpdate,
  )("#grants", state);
};

const prepareGrantsToResolve = () => {
  const { grant, grants } = storedFormView.state;
  const grantsToProcess = [
    ...(grant.principal ? [grant.principal] : []),
    ...grant.principals,
  ];
  const preparedGrants = [];
  grantsToProcess.forEach((principal) => {
    const collateralRights = findCollateralRights(principal, grants);
    let nonCollateralRights = new Set(
      difference(Array.from(grant.rights), Array.from(collateralRights)),
    );
    if (nonCollateralRights.has(aclRights.OWNER)) {
      nonCollateralRights = new Set([aclRights.OWNER]);
    }
    if (nonCollateralRights.size > 0) {
      preparedGrants.push({
        principal,
        subject: grant.subject,
        type: grant.type,
        rights: nonCollateralRights,
      });
    } else {
      getNotification().post(staticMessages.grantsNotChanged(getUsername(principal.entity)));
    }
  });
  return preparedGrants;
};

const onSave = async (modalView) => {
  const { grant } = storedFormView.state;
  if (!grant.subject) {
    storedFormView.update({
      infoMessages: [
        ...storedFormView.state.infoMessages,
        staticMessages.selectSubject,
      ],
    });
    return;
  }

  if (!grant.principal && !grant.principals?.length) {
    storedFormView.update({
      infoMessages: [
        ...storedFormView.state.infoMessages,
        staticMessages.selectPrincipal,
      ],
    });
    return;
  }
  if (grant.rights.size === 0) {
    storedFormView.update({
      infoMessages: [
        ...storedFormView.state.infoMessages,
        staticMessages.selectGrants,
      ],
    });
    return;
  }

  if (grant.rights.has(aclRights.OWNER)) {
    if (!(await modalView.confirm(
      "The selected user or group will be granted full ownership. Are you sure?",
    ))) {
      return;
    }
  }

  modalView.resolve(prepareGrantsToResolve());
};

const defaultState = frozen({
  grant: merge({}, defaultGrant),
  grants: [],
  principals: null,
  subjects: null,
  subjectType: aclSubjectTypes.MEDIA,
  infoMessages: [],
});

/**
 * An async modal dialog for assigning ACL rights to a principal (user or group) in
 * regard to a piece of content.
 *
 * If the `grants` parameter is supplied, any rights granted to the selected principal
 * on the selected subject will be toggled on and disabled to demonstrate that they're
 * provided by other grants and can't be removed here.
 *
 * TODO adapt this to use the base dialog component
 *
 * @function manageGrantsModal
 * @param {object} state
 * @param {ACLGrantEntry} grant
 * @param {ACLGrantEntry[]} grants other grants for the subject
 * @param {ACLPrincipalEntry[]} [principals=[]] users that may be selected as principal
 * @param {ACLSubjectEntry[]} [subjects=[]] content items that may be selected
 * @return {?ACLGrantEntry[]}
 */
export default function manageGrantsModal(
  inState = defaultState,
  modalView = null,
) {
  storedFormView = null;
  const state = merge(defaultState, inState);
  const noSelect = (state.principals?.length || state.subjects?.length)
    ? ""
    : ".no-select";
  return div(
    `.dialog.with-header.with-footer.manage-grants${noSelect}`,
    {
      hook: {
        insert: () => initForm(state, modalView),
        postPatch: () => initForm(state, modalView),
      },
      dataset: { advancedSearchId: (++count) },
    },
    [
      closeFab(true, modalView),
      div(".header", h3(makeHeader(state))),
      div(".content", div(`.body`)),
      div(".footer", [
        div(".buttons", { attrs: { role: "group" } }, [
          button.primary({
            label: "Save",
            onClick: () => onSave(modalView),
          }),
        ]),
      ]),
    ],
  );
}
