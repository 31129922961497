/**
 * @module ui/page/admin/assessment/user-assessment-status
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import { div, section } from "ui/html";
import table from "ui/component/dynamic-table";
import { evaluationStatusFriendly } from "model/course/constants";
import badge from "ui/component/badge";
import getUsername from "ui/component/messaging/username";

const tableRow = (evaluationStatus, users = []) => ({
  student: getUsername(users.find((u) => u.id === evaluationStatus.userId)),
  evaluationStatus: badge(
    evaluationStatusFriendly.get(evaluationStatus.status),
    `.${evaluationStatus.status?.toLowerCase()}`,
  ),
});

/**
 * Sets up the user assessment status table.
 *
 * @function userAssessmentStatus
 * @param {User[]} users
 * @param {AnalyticsEvaluation[]} evaluationStatuses
 * @param {string} sortColumn
 * @param {sortOrder} sortOrder
 * @param {Function} onSortChange
 * @param {Function} onRowClick
 * @private
 */
export default function userEvaluationStatus({
  evaluationStatuses = [],
  users = [],
  sortColumn,
  sortOrder,
  onSortChange,
  onRowClick = () => {},
}) {
  return evaluationStatuses.length ? section("#user-evaluation-status", table({
    columnLabels: [
      "Student",
      "Evaluation Status",
    ],
    rows: evaluationStatuses.map((status) => tableRow(status, users)),
    onRowClick: (row, i) => {
      onRowClick(evaluationStatuses[i]);
    },
    sortColumn,
    sortOrder,
    onSortChange,
  })) : div(".user-evaluation-status-no-entries", "None Available");
}
