/**
 * A cameo for a report.
 *
 * @module ui/component/cameo-report
 * @category UI
 * @subcategory Components
 */
import { takeActionOnReportedMessage } from "ui/page/admin/messaging/common";
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";

const showReport = async (report, users) => {
  await takeActionOnReportedMessage(report, users);
};

export default function cameoReport({
  report,
  users = [],
  controls = [],
  config = {},
  sel = "",
}) {
  return cameo({
    icon: icon.solid("flag-alt"),
    label: report.text,
    config,
    controls: [
      button({ icon: "eye", iconOnly: true, onClick: () => showReport(report, users) }),
      ...controls,
    ],
    sel: `${sel}.cameo-report`,
  });
}
