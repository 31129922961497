/**
 * Utility functions related to formatting text.
 *
 * @module util/format
 * @category Utilities
 */
import { aclPrincipalTypes, systemPrivilegeGroupsFriendly } from "model/acl/constants";

/**
 * Shortens a string, respecting word breaks. Appends an elipsis
 * if the text was shortened.
 *
 * @example
 * shortDescription("this string is too long", 10); // "this str..."
 *
 * @function shortDescription
 * @param {string} desc text to shorten
 * @param {Number} length desired max length in characters
 * @param {boolean} showEllipsis whether to show three dots in the end
 * @return {string}
 */
export const shortDescription = (desc, length = 100, showEllipsis = true) => {
  if (!desc) return ""; // normalize nulls, undefined, etc
  if (desc.length < length) return desc;

  const words = desc.split(" ");
  const selected = [];
  let total = 0;
  if (words[0].length > length - 1) {
    return `${words[0].slice(0, length - 1)}${showEllipsis ? "…" : " "}`;
  }
  // FIXME NOT a super-efficient way to do this
  while (total < length && words.length) {
    const next = words.shift();
    if ((total + next.length) < length) {
      selected.push(next);
    }
    total += next.length;
  }

  if (showEllipsis) {
    selected.push("…");
  } else {
    selected.push(" ");
  }

  return selected.join(" ");
};

/**
 * Displays the user's first & last name plus username if avialable in a string like,
 * `firstName lastName (username)`. Attempts to shorten the names if they would
 * exceed maxLength, giving username preferential space.
 *
 * @param {User} user
 * @param {int} [maxLength=Infinity]
 * @return {string}
 */
export const userNamesString = (user, maxLength = Infinity) => {
  if (user.firstName && user.lastName) {
    const str = `${user.firstName} ${user.lastName} (${user.username})`;
    if (str.length > maxLength) {
      // username can take up to 2/3rds of length
      const shortenedUsername = shortDescription(user.username, (maxLength - 5) * (2 / 3));
      const shortenedNames = shortDescription(
        `${user.firstName} ${user.lastName}`,
        maxLength - shortenedUsername.length - 5,
      );
      return `${shortenedNames} (${shortenedUsername})`;
    }
    return str;
  }
  return shortDescription(user.username, maxLength);
};

/**
 * Returns an appropriate display name for an ACL principal.
 *
 * @param {ACLPrincipalEntry} principal
 * @return {string}
 */
export const principalDisplayName = (principal) => (
  principal.type === aclPrincipalTypes.INDIVIDUAL
    ? userNamesString(principal.entity)
    : systemPrivilegeGroupsFriendly.get(principal.name) || principal.name
);

/**
 * Return an appropriate display name string for a subject.
 *
 * For now does nothing because all subject types have a `title` field.
 *
 * @param {ACLSubjectEntry} subject
 * @return {string}
 */
export const subjectDisplayName = (subject) => subject.title;

/**
 * Formats a decimal number to a local-appropriate representation of a
 * percentage value, using the user's locale.
 *
 * @function formatPercent
 * @param {number} num
 * @return {string}
 */
export const formatPercent = (num) => num.toLocaleString(undefined, { style: "percent", maximumFractionDigits: 0 });

const wordRegexp = new RegExp(/\b\w/g);
/**
 * Capitalizes all words in a string.
 * @function capitalize
 * @param {string} phrase
 * @return {string} phrase with each word capitalized
 */
export const capitalize = (phrase) => phrase.replace(wordRegexp, (c) => c.toUpperCase());

const paragraphSplitter = new RegExp(/(\r\n)+|\r+|\n+/);
const cleanFilter = (string) => (!!string?.trim());

/**
 * splits paragraphs from a plain text section.
 *
 * @function splitParagraphs
 * @param {string} text
 * @return {string[]}
 */
export const splitParagraphs = (text) => text
  ?.split(paragraphSplitter)
  ?.filter(cleanFilter) || [];

const MINUTES = 60;
const HOURS = MINUTES * 60;
const DAYS = HOURS * 24;

/**
 * Produces a nicely formatted time string from a value in seconds.
 *
 * @example
 * prettyTime(moreThanTwoDaysWorthOfSeconds); // "> 2 days"
 * prettyTime(atLeastOneDayWorthOfSeconds); // "> 1 day"
 * prettyTime(aFewHoursAndChange); // "12:13:04"
 * prettyTime(aFewMinutesAndChange); // "17:04"
 * prettyTime(aFewSeconds); // "23 seconds"
 *
 * @function prettyTime
 * @param {number} seconds
 * @return {string}
 */
export const prettyTime = (seconds) => {
  const d = Math.floor(seconds / DAYS);
  const h = Math.floor((seconds % DAYS) / HOURS).toString();
  const m = Math.floor((seconds % HOURS) / MINUTES).toString();
  const s = (seconds % MINUTES).toString();
  if (d > 1) return `> ${d} days`;
  if (d === 1) return `> 1 day`;
  if (h !== "0") return `${h.padStart(2, "0")}:${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
  if (m !== "0") return `${m.padStart(2, "0")}:${s.padStart(2, "0")}`;
  return `${seconds} seconds`;
};
