/**
 * A sticky-positioned action bar meant to be placed at the top of a dashboard page.
 *
 * @module ui/component/dashboard-action-bar
 * @category UI
 * @subcategory Components - Dashboard
 */
import widget from "ui/component/dashboard-widget";

/**
 * A dashboard widget with the action-bar class applied.
 *
 * The component doesn't do anything fancy, but the applied class controls layout
 * and sticky behavior within the dashboard grid.
 *
 * @function dashboardActionBar
 * @param {module:ui/html~ChildEl} children
 * @return {module:ui/common/el~El}
 */
export default function dashboardActionBar(children) {
  return widget(children, "", ".action-bar");
}
