/**
 * A textarea answer on the student assessment page.
 *
 * For use with fill-in-long type questions.
 *
 * @module ui/page/assessment/answer-text-input
 * @category Pages
 * @subcategory Courses
 * @private
 */
/***/
import div from "ui/html/div";
import textarea from "ui/component/form-managed/field-textarea";

const getSolutionValue = (solution) => solution?.value?.[0] || null;

export default function answerTextInput(state, view) {
  const { question, solution, validity, required } = state;
  const value = solution?.[0] || "";
  return div(".answer.answer-text-input", view.bind([[textarea, {
    name: question.id,
    value,
    label: "Your Answer",
    validity,
    required,
    onInput: () => view.update({}),
  }]], { value: getSolutionValue(solution) }));
}
