/**
 * A modal dialog for doing advanced user searches on the manage users page.
 *
 * @module ui/component/modal/advanced-user-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  button,
  div,
  form, h3,
  input,
  label,
} from "ui/html";
import closeFab from "ui/component/modal/component/close-fab";
import multiToggle from 'ui/component/multi-toggle';
import { frozen } from "util/object";

let count = 0;

const getFormCheckboxState = (selector, dialog) => {
  const element = dialog.querySelector(`${selector}:checked`);
  if (element && element.value === "true") {
    return true;
  }
  if (element && element.value === "false") {
    return false;
  }
  return null;
};

const doSearch = (id, modalView) => {
  const dialog = document.querySelector(`[data-advanced-search-id="${id}"]`);
  const managesUsers = getFormCheckboxState("[name=manageUsers]", dialog);
  const managesFiles = getFormCheckboxState("[name=manageFiles]", dialog);
  const managesMedia = getFormCheckboxState("[name=manageMedia]", dialog);
  const enabled = getFormCheckboxState("[name=enabled]", dialog);
  const external = getFormCheckboxState("[name=external]", dialog);
  const searchParams = {
    username: dialog.querySelector("[name=username]").value,
    firstName: dialog.querySelector("[name=firstName]").value,
    lastName: dialog.querySelector("[name=lastName]").value,
    email: dialog.querySelector("[name=email]").value,
    cellPhone: dialog.querySelector("[name=cellPhone]").value,
    managesUsers,
    managesFiles,
    managesMedia,
    external,
    enabled,
    sortOrder: dialog.querySelector("[name=sort-order]").value,
    sortField: dialog.querySelector("[name=sort-field]").value,
  };
  modalView.resolve(searchParams);
};

const defaultParams = frozen({
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  cellPhone: null,
  sortField: null,
  sortOrder: null,
});

const setFocus = () => {
  document.querySelector(`#advanced-form input[name="username"]`).focus();
};

/**
 * A modal dialog for doing advanced searches for users.
 */
const advancedUserSearchModal = (
  {
    params = defaultParams,
  }, modalView = null,
) => div(
  ".dialog.form.with-footer.advanced-search.advanced-search-users",
  {
    dataset: { advancedSearchId: (++count) },
    hook: {
      insert: setFocus,
      update: setFocus,
    },
  },
  [
    closeFab(true, modalView),
    div(".content", [
      div(".header", h3("Advanced Search")),
      div(".body", form("#advanced-form", [
        input("hidden", params.sortOrder, false, "sort-order"),
        input("hidden", params.sortField, false, "sort-field"),
        div(".pane", [
          div("", { attrs: { role: "group" } }, [
            label("Username"),
            input.text(params.username, false, "username", { props: { placeholder: "Username" } }),
          ]),
          div("", { attrs: { role: "group" } }, [
            label("First Name"),
            input.text(params.firstName, false, "firstName", { props: { placeholder: "First Name" } }),
          ]),
          div("", { attrs: { role: "group" } }, [
            label("Last Name"),
            input.text(params.lastName, false, "lastName", { props: { placeholder: "Last Name" } }),
          ]),
          div("", { attrs: { role: "group" } }, [
            label("Email Address"),
            input.text(params.email, false, "email", { props: { placeholder: "Email Address" } }),
          ]),
          div("", { attrs: { role: "group" } }, [
            label("Mobile Phone"),
            input.text(params.cellPhone, false, "cellPhone", { props: { placeholder: "Mobile Phone" } }),
          ]),
        ]),
        div(".pane", [
          multiToggle(
            {
              label: 'Verified',
              options: [
                {
                  label: "N/A",
                  value: "na",
                  name: "enabled",
                  id: "enabled-na",
                },
                {
                  label: "Yes",
                  value: "true",
                  name: "enabled",
                  id: "enabled-yes",
                },
                {
                  label: "No",
                  value: "false",
                  name: "enabled",
                  id: "enabled-no",
                },
              ],
            },
          ),
          multiToggle(
            {
              label: 'External',
              options: [
                {
                  label: "N/A",
                  value: "na",
                  name: "external",
                  id: "external-na",
                },
                {
                  label: "Yes",
                  value: "true",
                  name: "external",
                  id: "external-yes",
                },
                {
                  label: "No",
                  value: "false",
                  name: "external",
                  id: "external-no",
                },
              ],
            },
          ),
          div('.permissions-wrapper', [
            multiToggle(
              {
                label: 'Manage Users',
                options: [
                  {
                    label: "N/A",
                    value: "na",
                    name: "manageUsers",
                    id: 'manage-users-na',
                  },
                  {
                    label: "Yes",
                    value: "true",
                    name: "manageUsers",
                    id: 'manage-users-yes',
                  },
                  {
                    label: "No",
                    value: "false",
                    name: "manageUsers",
                    id: 'manage-users-no',
                  },
                ],
              },
            ),
            multiToggle(
              {
                label: 'Manage Files',
                options: [
                  {
                    label: "N/A",
                    value: "na",
                    name: 'manageFiles',
                    id: 'manage-files-na',
                  },
                  {
                    label: "Yes",
                    value: "true",
                    name: "manageFiles",
                    id: 'manage-files-yes',
                  },
                  {
                    label: "No",
                    value: "false",
                    name: "manageFiles",
                    id: 'manage-files-no',
                  },
                ],
              },
            ),
            multiToggle(
              {
                label: 'Manage Media',
                options: [
                  {
                    label: "N/A",
                    value: "na",
                    name: 'manageMedia',
                    id: 'manage-media-na',
                  },
                  {
                    label: "Yes",
                    value: "true",
                    name: "manageMedia",
                    id: 'manage-media-yes',
                  },
                  {
                    label: "No",
                    value: "false",
                    name: "manageMedia",
                    id: 'manage-media-no',
                  },
                ],
              },
            ),
          ]),
        ]),
      ])),
    ]),
    div(".footer", [
      div(".buttons", { attrs: { role: "group" } }, [
        button("Search", () => doSearch(count, modalView)),
      ]),
    ]),
  ],
);

export default advancedUserSearchModal;
