/**
 * Lecture models.
 *
 * @module model/lecture
 * @category Model
 * @subcategory Lecture
 */
import { responseToDocumentMetadata, responseToVideoMetadata } from "../metadata";
import { responseToUser } from "../user";
import { required, validArray, validString } from "../constraints";

/**
 * A lecture object.
 *
 * @typedef Lecture
 * @property {string} id UUID
 * @property {string} title
 * @property {string} description
 * @property {VideoMetadata} video
 * @property {UUID} videoId
 * @property {DocumentMetadata} poster
 * @property {UUID} posterId
 * @property {DocumentMetadata} slides
 * @property {UUID} slidesId
 * @property {DocumentMetadata[]} additionalMaterial
 * @property {UUID[]} additionalMaterialIds
 * @property {User} instructor
 * @property {UUID} instructorId
 */

/**
 * Makes a lecture object from partial data, with defaults for missing properties.
 *
 * @function makeLecture
 * @property {object} partial
 * @returns Lecture
 */
export const makeLecture = (partial) => ({
  id: partial.id || null,
  moduleId: partial.moduleId || null,
  courseId: partial.courseId || null,
  title: partial.title,
  description: partial.description,
  videoId: partial.videoItem || null,
  video: null,
  posterId: partial.poster || null,
  poster: null,
  slidesId: partial.slidesId || null,
  slides: partial.slides || null,
  additionalMaterialIds: partial.additionalMaterialIds || [],
  additionalMaterial: partial.additionalMaterial || [],
  instructorId: partial.instructorId || null,
  instructor: partial.instructor || null,
});

/**
 * Converts restored lecture object to lecture object model
 *
 * @param data
 * @param {string} data.id
 * @param {string} data.title
 * @param {string} data.description
 * @param {Object} data.videoItem
 * @param {Object} data.poster
 * @param {Object} data.slides
 * @param {Object[]} data.additionalMaterial
 * @param {Object} data.instructor
 * @returns Lecture
 */
export const responseToLecture = async (data) => ({
  id: data.id,
  moduleId: data.moduleId || null,
  courseId: data.courseId || null,
  title: data.title,
  description: data.description,
  videoMetadata: data.videoItem ? responseToVideoMetadata(data.videoItem) : null,
  poster: data.poster ? responseToDocumentMetadata(data.poster) : null,
  slides: data.slides ? responseToDocumentMetadata(data.slides) : null,
  additionalMaterial: await Promise.all(
    data.additionalMaterial?.map(responseToDocumentMetadata) || [],
  ),
  instructor: data?.instructor ? responseToUser(data.instructor) : null,
  // used to populate instructors later, then removed
  instructorId: data.instructorId,
});

/**
 * Response to lecture without shenanigans.
 *
 * @param {object} data
 * @returns Lecture
 */
export const responseToLectureFlat = (data) => ({
  id: data.id,
  courseId: data.courseId,
  moduleId: data.moduleId,
  title: data.title,
  description: data.description,
  videoId: data.videoItemId || null,
  video: null,
  posterId: data.posterId || null,
  poster: null,
  slidesId: data.slidesId || null,
  slides: null,
  additionalMaterialIds: data.additionalMaterialIds || [],
  additionalMaterial: [],
  // used to populate instructors later, then removed
  instructorId: data.instructorId || null,
  instructor: null,
});

/**
 * A DTO for create/update lecture.
 *
 * @typedef LectureDTO
 * @property {string} videoItemId UUID
 * @property {string} posterId UUID
 * @property {string} slidesId UUID
 * @property {string[]} additionalMaterialIds UUID
 * @property {string} instructorId UUID
 */

/**
 * Make DTO for create/update lecture
 *
 * @param partial
 * @param {string} partial.id
 * @param {string} partial.title
 * @param {string} partial.description
 * @param {VideoMetadata} partial.videoMetadata
 * @param {DocumentMetadata} partial.poster
 * @param {DocumentMetadata} partial.slides
 * @param {DocumentMetadata[]} partial.additionalMaterial
 * @param {User} partial.instructor
 * @returns LectureDTO
 */
export const makeLectureDTO = (partial) => ({
  id: partial.id,
  title: required(partial.title, "LectureDTO.title", validString),
  description: partial.description,
  videoItemId: partial.videoMetadata?.id,
  posterId: partial.poster?.id,
  slidesId: partial.slides?.id,
  additionalMaterialIds: validArray(
    partial.additionalMaterial?.map((material) => material.id) || [],
    "LectureDTO.additionalMaterialIds",
    validString,
  ),
  instructorId: partial.instructor?.id,
});
