/**
 * Type definitions for user models.
 *
 * @module model/user
 * @category Model
 * @subcategory User
 */
import {
  orNull,
  required,
  validBoolean,
  validMember,
  validString,
} from "model/constraints";
import { responseToUserGroupEntry, makeGroup } from "model/acl";
import { systemManagementGroupsSet, systemPrivilegeGroups } from "model/acl/constants";
import {
  userTypes,
  verificationResendTypeValues,
} from "./constants";
import { validUserType } from "./constraints";

/**
 * A user profile object.
 * @typedef User
 * @property {string} id UUID
 * @property {string} username
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} cellPhone
 * @property {string} email
 * @property {?Array.<ACLUserGroupEntry>} groups
 * @property {boolean} enabled
 * @property {boolean} external whether the user login is supplied by an external source (LDAP, etc)
 * @property {boolean} groups acl user groups
 * @property {userTypes} userType
 */

/**
 * A user profile object.
 * @typedef UserWithAssessmentsAndEvaluations
 * @property {string} id UUID
 * @property {string} username
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} cellPhone
 * @property {string} email
 * @property {?Array.<ACLUserGroupEntry>} groups
 * @property {boolean} enabled
 * @property {boolean} external whether the user login is supplied by an external source (LDAP, etc)
 * @property {boolean} groups acl user groups
 * @property {AssessmentWithEvaluation[]} assessments
 */

/**
 * Creates a standard user object from partial data, providing defaults for missing properties.
 *
 * @function makeUser
 * @return {User}
 */
export const makeUser = (partial) => ({
  id: partial.id || "",
  username: partial.username || "Unknown User",
  firstName: partial.firstName || "",
  lastName: partial.lastName || "",
  cellPhone: partial.cellPhone || "",
  email: partial.email || "",
  enabled: partial.enabled || false,
  external: partial.external || false,
  userType: partial.userType,
  groups: partial.groups?.map(makeGroup) || [],
});

/**
 * Translate an API response to a {@link User} object (for now, just a passthrough).
 *
 * @todo implement any sanitation needed here
 *
 * @function responseToUser
 * @param {APIResponse} response
 * @param {object} response
 *
 * @return {User}
 */
export const responseToUser = (response) => {
  const user = {
    id: response.id,
    username: response.username,
    firstName: response.firstName,
    lastName: response.lastName,
    cellPhone: response.cellPhone,
    email: response.email,
    enabled: response.enabled,
    external: response.external,
    userType: response.userType,
    // when thawing from cache, this mapping will already be done
    groups: response.userLists?.map(responseToUserGroupEntry) || response.groups || [],
  };

  return user;
};

/**
 * For updating user accounts.
 *
 * @typedef UserUpdateDTO
 * @property {string} username
 * @property {string} [firstName]
 * @property {string} [lastName]
 * @property {string} [email]
 * @property {string} [cellPhone]
 * @property {string} [password]
 * @property {userType?} [userType]
 * @property {boolean} [resetVerificationCodeResendCount]
 * @property {boolean} [enabled] only valid for admin endpoints
 */

/**
 * Produces a DTO for updating user accounts.
 *
 * @function makeUserUpdateDTO
 * @param {object} partial
 * @property {string} partial.username
 * @property {string} partial.[firstName]
 * @property {string} partial.[lastName]
 * @property {string} partial.[email]
 * @property {string} partial.[cellPhone]
 * @property {string} partial.[password]
 * @property {userTypes} partial.[userType]
 * @property {boolean} partial.[resetVerificationCodeResendCount]
 * @property {boolean} partial.[enabled] only valid for admin endpoints
 *
 * @return {UserUpdateDTO}
 */
export const makeUserUpdateDTO = (partial) => {
  const user = { username: required(partial.username, "UserUpdateDTO.username", validString) };
  if (partial.firstName) user.firstName = validString(partial.firstName);
  if (partial.lastName) user.lastName = validString(partial.lastName);
  if (partial.cellPhone) user.cellPhone = validString(partial.cellPhone);
  if (partial.email) user.email = validString(partial.email);
  if (partial.password) user.password = validString(partial.password);
  if (partial.userType) user.userType = orNull(partial.userType, "User.userType", validUserType) || userTypes.REGISTERED;
  if (partial.resetVerificationCodeResendCount) {
    user.resetVerificationCodeResendCount = validBoolean(partial.resetVerificationCodeResendCount, "User.resetVerificationCodeResendCount");
  }

  /* note that the below are not supported by /api/user/me, only by /api/user/:id */
  /* TODO create a separate DTO for /api/user/me vs. /api/user/:id */
  if (typeof partial.enabled !== "undefined") user.enabled = validBoolean(partial.enabled);

  return user;
};

/**
 * Determine whether a User model has any management privileges.
 * @param {module:model/user~User} user
 * @return boolean true if the user is a manager
 */
export const userIsManager = (user) => user.userType === userTypes.ROOT
  || user.groups?.some((g) => systemManagementGroupsSet.has(g.name));

/**
 * Determine whether a user belongs to the named group.
 *
 * @param {module:model/user~User} user
 * @param {string} groupName
 * @return boolean true if the user belongs to the group
 */
export const userInGroupName = (user, groupName) => {
  if (!user.groups?.length) return false;
  return user.groups.some((g) => g.name === groupName);
};

/**
 * Determine whether the user belongs to the admin group.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const isAdmin = (user) => user.userType === userTypes.ROOT || userInGroupName(
  user,
  systemPrivilegeGroups.ADMIN,
);

/**
 * Determine whether the user is root user.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const isRoot = (user) => user.userType === userTypes.ROOT;

/**
 * Determine whether the user belongs to the moderators group.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const isModerator = (user) => userInGroupName(
  user,
  systemPrivilegeGroups.ADMIN_MESSAGING,
);

/**
 * Determine whether the user has chat rights.
 *
 * @function canChat
 * @param {module:model/user~User} user
 * @return boolean
 */
export const canChat = (user) => isRoot(user) || userInGroupName(
  user,
  systemPrivilegeGroups.USER_MESSAGING,
);

/**
 * Determine whether the user has moderator rights.
 *
 * @function canModerate
 * @param {module:model/user~User} user
 * @return boolean
 */
export const canModerate = (user) => (
  isModerator(user)
  || isAdmin(user)
);

/**
 * Determine whether the user belongs to the user management group.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const managesUsers = (user) => isAdmin(user) || userInGroupName(
  user,
  systemPrivilegeGroups.USER_MANAGEMENT,
);

/**
 * Determine whether the user belongs to the file management group.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const managesFiles = (user) => isAdmin(user) || userInGroupName(
  user,
  systemPrivilegeGroups.FILE_MANAGEMENT,
);

/**
 * Determine whether the user belongs to the media management group.
 *
 * @param {module:model/user~User} user
 * @return boolean
 */
export const managesMedia = (user) => isAdmin(user) || userInGroupName(
  user,
  systemPrivilegeGroups.MEDIA_MANAGEMENT,
);

/**
 * User registration data transfer object. Used in {@link module:api/user.register}
 *
 * @typedef UserRegistrationDTO
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} cellPhone
 * @property {string} username
 * @property {string} password
 * @property {userTypes} userType
 */

/**
 * Produces a user registration DTO from an object.
 *
 * Note that the server determines which fields besides username and password are
 * required, and this must be enforced at a higher level to avoid making this function
 * async and/or introducing dependency cycles. Here we trust that the call is being made
 * with required fields.
 *
 * @function makeUserRegisterDTO
 * @param {object} partial
 * @param {string} [partial.cellPhone]
 * @param {string} [partial.email],
 * @param {string} [partial.firstName]
 * @param {string} [partial.lastName]
 * @param {boolean} [partial.enabled]
 * @param {string} partial.password
 * @param {string} partial.username
 *
 * @return {UserRegistrationDTO}
 */
export const makeUserRegisterDTO = (partial) => {
  const dto = {
    username: partial.username,
    password: partial.password,
  };
  if (partial.cellPhone) dto.cellPhone = partial.cellPhone;
  if (partial.email) dto.email = partial.email;
  if (partial.firstName) dto.firstName = partial.firstName;
  if (partial.lastName) dto.lastName = partial.lastName;
  if (partial.enabled) dto.enabled = partial.enabled;

  return dto;
};

/**
 * User verification data transfer object. Used in {@link module:api/user.verify}
 *
 * @typedef UserVerifyDTO
 * @property {number} code
 * @property {string} username
 */

/**
 * Produces a DTO for verifying a user account.
 *
 * @function makeUserVerifyDTO
 * @param {object} partial
 * @param {number} partial.code
 * @param {string} partial.username
 *
 * @return UserVerifyDTO
 */
export const makeUserVerifyDTO = (partial) => ({
  code: partial.code,
  username: partial.username,
});

/**
 * User reverify DTO. Used to send a new verification code in {@link module:api/user.reverify}.
 *
 * @typedef UserReverifyDTO
 * @property {string} username
 * @property {boolean} viaEmail
 * @property {boolean} viaSms
 */

/**
 * Produces a DTO for initializing a verification request for an existing user.
 *
 * @function makeUserReverifyDTO
 * @param {object} partial
 * @param {string} partial.username
 * @param {boolean} partial.viaEmail
 * @param {boolean} partial.viaSms
 * @return {UserReverifyDTO}
 */
export const makeUserReverifyDTO = (partial) => ({
  username: partial.username,
  viaEmail: !!partial.viaEmail,
  viaSms: !!partial.viaSms,
  resendType: validMember(
    partial.resendType,
    "resendType",
    verificationResendTypeValues,
  ),
});

/**
 * User password reset DTO. Used to reset password in {@link module:api/user.resetPassword}.
 *
 * @typedef UserPasswordResetDTO
 * @property {string} username
 * @property {number} code
 * @property {string} password
 */

/**
 * Produces a DTO for resetting a user's password.
 *
 * @function makeUserPasswordResetDTO
 * @param {object} partial
 * @property {string} partial.username
 * @property {number} partial.code
 * @property {string} partial.password
 * @return {UserPasswordResetDTO}
 */
export const makeUserPasswordResetDTO = (partial) => ({
  username: partial.username,
  code: partial.code,
  password: partial.password,
});

export const responseToAbbreviatedUser = (partial, userType) => ({
  id: partial.id,
  username: partial.username,
  firstName: partial.firstName,
  lastName: partial.lastName,
  groupIds: partial.groupIds,
  userType: userType || partial.userType,
  isModerator: partial.isModerator || false,
});
