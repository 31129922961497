/**
 * General user authentication and registration form.
 *
 * @module ui/page/login
 * @category Pages
 * @subcategory User Accounts
 */
import { pageVisit } from "api/v2/analytics";
import cache from "cache";
import getConfig from "config";
import { featureTypes } from "model/config/constants";
import main from "ui/html/main";
import form from "ui/component/form-managed";
import footer from "ui/component/footer";
import unauthenticatedLayout from "ui/page/layout/unauthenticated";
import formManagedView from "ui/view/form-managed";
import { guard } from "util/feature-flag";
import { frozen, merge } from "util/object";
import { getSlug } from "util/navigation";
import { isAnonymous } from "util/user";
import { setFocus, focus } from "./common";
import { MODES, defaultState } from "./state";
import showRegister from "./register";
import showLogin from "./login";
import showVerification from "./verification";
import showPasswordReset, { showPasswordResetUsername } from "./reset";

let modal, notification, loading;

/**
 * Shows only the messages in the message queue.
 *
 * @function showMessage
 * @private
 * @param {View} page
 */
const showMessage = (self) => {
  // fixme messages should go to notifications
  form("#login", []);
  self.render();
  setFocus(self);
};

const factory = (self) => {
  switch (self.state.mode) {
    case MODES.register:
      return showRegister(self, notification);
    case MODES.passwordResetUsername:
      return showPasswordResetUsername(self, notification);
    case MODES.passwordReset:
      return showPasswordReset(self, notification);
    case MODES.messageOnly:
      return showMessage(self);
    case MODES.verify:
      return showVerification(self, notification);
    case MODES.login:
    default:
      /* eslint-disable-next-line no-param-reassign */
      self.updateState({ user: {} }); // clear left-over user state
      return showLogin(self, modal, notification);
  }
};

/**
 * Callback for page.update(). Gets called whenever the page's state is updated
 * without doing an explicit page.updateState().
 *
 * @function doUpdate
 * @private
 * @param {FormView} self
 */
const doUpdate = (self) => {
  self.updateState({
    user: merge(self.state.user, self.values),
  });
  self.render();
};

const routeMode = () => {
  switch (getSlug()) {
    case "verify":
      return MODES.verify;
    case "register":
      return MODES.register;
    case "forgot-password":
      return MODES.passwordResetUsername;
    case "login":
    default:
      return MODES.login;
  }
};

/**
 * General user authentication and registration forms.
 *
 * @function login
 * @param {module:ui/html~Selector} containerSelector form element for root node
 */
export default async function login(selector) {
  ({ loading, modal, notification } = unauthenticatedLayout(
    selector,
    [
      main('', {}, [
        form("#sign-in-form"),
      ]),
      footer.login(),
    ],
    "Login",
  ));
  loading.show();

  const params = new URL(window.location).searchParams;
  const user = cache.getProfile();
  const config = await getConfig();
  pageVisit(window.location.pathname);

  const mode = routeMode();
  switch (mode) {
    case MODES.verify:
      guard(featureTypes.OTP_VERIFICATION);
      break;
    case MODES.register:
      guard(featureTypes.REGISTRATION);
      break;
    default:
      break;
  }

  const state = {
    ...defaultState,
    config,
    mode,
    verificationCode: params.get("code") || "",
  };

  if (params.get("no-redirect") === null) {
    const redirect = params.get("redirect") || "/";
    // last-page is set during beforeunload by an event listener callback in app.js
    const lastPage = cache.getValue("last-page");
    if (lastPage === redirect) state.redirect = "/";
    else state.redirect = redirect;
  }

  if ((user && !isAnonymous(user)) && state.redirect) {
    window.location.assign(state.redirect);
  }

  const signInPage = formManagedView(factory, doUpdate)("#sign-in-form", state);
  focus(signInPage);

  window.addEventListener("popstate", () => {
    signInPage.update({ mode: routeMode() });
  });
  loading.hide();

  return signInPage;
}

login.MODES = MODES;
frozen(login);
