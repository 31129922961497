/**
 * A component for scheduling content.
 *
 * @module ui/component/content-schedule
 * @category UI
 * @subcategory Components
 */
import icon from "ui/component/icon";
import dateTimePicker from "ui/component/form-managed/field-date-time";
import { button, div, span } from "ui/html";

/**
 * @function contentSchedule
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {function} [onEnable] callback for enabling scheduling
 * @param {function} [onDisable] callback for disabling scheduling
 * @return {module:ui/html/div~Div}
 */
export default function contentSchedule({
  startDate,
  endDate,
  onEnable = () => {},
  onDisable = () => {},
}) {
  return div(
    ".content-schedule",
    (startDate && endDate)
      ? [
        span("Start"),
        dateTimePicker({
          name: "startDate",
          value: startDate,
        }),
        span("End"),
        dateTimePicker({
          min: startDate,
          name: "endDate",
          value: endDate,
        }),
        button([icon.solid("history"), "Clear Schedule"], onDisable, ".danger"),
      ]
      : [
        button([icon.solid("clock"), "Set Schedule"], onEnable, ".secondary"),
      ],
  );
}
