/**
 * A cameo for file descriptors.
 *
 * @module ui/component/cameo-file
 * @category UI
 * @subcategory Components
 */
import span from "ui/html/span";
import cameo from "ui/component/cameo";
import buttonIcon from "ui/component/icon-button";
import icon from "ui/component/icon";

const downloadFile = (file) => () => {
  window.open(file.fileUrlStatic, "_blank");
};

export default function cameoFile({
  file,
  valid = true,
  onRemove = null,
  downloadable = false,
  loading = false,
  controls = [],
}) {
  /* eslint-disable no-param-reassign */
  if (!file?.name) {
    valid = false;
    downloadable = false;
  }
  return cameo({
    label: file?.name || span("No File Selected", ".warning"),
    icon: valid
      ? icon.regular("check-circle", ".ok")
      : icon.regular("times-circle", ".danger"),
    controls: loading ? [
      icon.regular("spinner-third", ".spin"),
    ] : [
      ...controls,
      downloadable ? buttonIcon("file-download", "Download", downloadFile(file)) : "",
      onRemove ? buttonIcon("trash", "Remove", onRemove, ".danger") : "",
    ],
    sel: `.cameo-file${valid ? ".ok" : ".danger"}`,
  });
}
