/**
 * A widget for managing user content access.
 *
 * @module ui/component/user-content-access
 * @category UI
 * @subcategory Components - ACL
 */
import cache from "cache";
import { aclPrincipalTypes, aclSubjectTypes } from "model/acl/constants";
import { featureTypes } from "model/config/constants";
import {
  button,
  div,
} from "ui/html";
import icon from "ui/component/icon";
import manageGrantsModal from "ui/component/modal/acl/manage-grants";
import cameoPrincipal from "ui/component/cameo-principal";
import message from "ui/component/message";
import { principalDisplayName } from "util/format";
import { merge, frozen } from "util/object";
import { userToPrincipal } from "model/acl";
import {
  bootstrapGroupMembers,
  findAnonymousGrant,
  getAnonymousUserUpdateGrantEntry,
} from "util/acl";
import { isAnonymous } from "util/user";
import { featureEnabled } from "util/feature-flag";
import { isRoot } from "model/user";
import toggle from "./form-managed/field-toggle";

let notification;

const unusedPrincipals = (grants) => (principal) => !grants.some(
  (g) => g.principal.id === principal.id,
);

const onRemoveGrant = ({ grant, onRemove }, modalDialog) => async () => {
  if (!(await modalDialog.confirm(
    `Are you sure you want to remove ${principalDisplayName(grant.principal)}?`,
  ))) return;
  onRemove?.(grant);
};

const onEditGrant = ({
  grants,
  grant,
  onUpdate,
}, modalDialog) => async () => {
  const result = await modalDialog.async(manageGrantsModal({
    grant,
    grants,
  }, modalDialog, notification));
  if (!result) return;
  onUpdate?.(result?.[0]);
};

const onAddGrant = (state, modalDialog) => async () => {
  const { grants, subject, onAdd } = state;
  const principals = state.principals.filter(unusedPrincipals(grants));
  const result = await modalDialog.async(manageGrantsModal({
    grant: {
      subject,
    },
    multiGrant: {
      subject,
    },
    grants,
    principals,
  }, modalDialog, notification));
  if (!result) return;
  result.forEach((grant) => onAdd?.(grant));
};

const buildUserRows = ({
  grants,
  principals,
  onUpdate,
  onRemove,
}, modalDialog) => div(".users", grants
  .filter((grant) => !isAnonymous(grant?.principal?.entity))
  .map((grant) => {
    let { principal } = grant;
    const principalMap = new Map(principals.map((p) => ([p.id, p])));
    // bootstrap members with their user entities
    // which should be included in the principalMap
    // but group members by default only have abbreviated entries
    // needed so the cameo can provide a proper preview button
    // because the group preview modal relies on members being populated
    if (principal.type === aclPrincipalTypes.GROUP) {
      principal = {
        ...principal,
        members: principal.members.map((m) => principalMap.get(m.id)),
      };
    }

    return cameoPrincipal({
      principal,
      controls: [
        button(
          icon.solid("pencil"),
          onEditGrant({
            grant,
            grants,
            onUpdate,
            principals,
          }, modalDialog),
          ".secondary",
        ),
        button(
          icon.sharp("xmark"),
          onRemoveGrant({ grant, onRemove }, modalDialog),
          ".danger",
        ),
      ],
    }, modalDialog);
  }));

const generatePrincipals = (state) => {
  const { users, groups } = state;
  return {
    ...state,
    principals: [
      ...users.map(userToPrincipal),
      ...bootstrapGroupMembers(groups, users),
    ],
  };
};

const defaultState = frozen({
  grants: [],
  principals: [],
  users: [],
  groups: [],
  subject: null,
  subjectType: aclSubjectTypes.MEDIA,
  aclAccessible: true,
  onRemove: null,
  onUpdate: null,
  onAdd: null,
  anonymousUser: null,
});

/**
 * @callback ContentAccessCallback
 * @param {ACLGrantEntry} grant
 */

/**
 * Component for displaying users and groups list that have access to given metadata item
 *
 * @param {object} state
 * @param {ACLGrantEntry[]} [state.grants=[]] existing grants
 * @param {User[]} [state.users=[]] all available users
 * @param {ACLPrincipalEntry[]} [state.groups=[]] all available groups
 * @param {ACLSubjectEntry} state.subject subject for which to grant rights
 * @param {?ContentAccessCallback} state.onRemove remove user callback
 * @param {?ContentAccessCallback} state.onUpdate callback
 * @param {?ContentAccessCallback} state.onAdd user callback
 * @returns {*|El}
 */
export default function userContentAccess(inState, modalDialog, notificationView) {
  const state = generatePrincipals(
    merge(defaultState, {
      ...inState,
      anonymousAllowed: findAnonymousGrant(inState?.grants),
    }),
  );
  notification = notificationView;
  if (!(state.grants?.length && state.principals?.length) && !isRoot(cache.getProfile())) {
    return div(".user-access-container.no-permission", message({
      text: "You do not have permission to manage access rights for this item.",
      type: "info",
    }));
  }
  return div(".user-access-container", [
    featureEnabled(featureTypes.OPEN_SITE_ACCESS)
      ? toggle.boxed.inverse({
        toggled: state.anonymousAllowed,
        name: "anonymousAllowed",
        label: "Allow Anonymous Access",
        onToggle: (toggled) => {
          const grant = getAnonymousUserUpdateGrantEntry(
            state.anonymousUser,
            toggled,
            state.subject,
            state.subjectType,
          );
          if (grant && toggled) {
            state.onAdd(grant);
          } else {
            state.onRemove(grant);
          }
        },
      })
      : "",
    ...(featureEnabled(featureTypes.USER_MANAGEMENT)
      ? [
        buildUserRows(state, modalDialog),
        button(
          [icon("plus-circle"), "Add User / Group"],
          onAddGrant(state, modalDialog),
          ".stand-in",
        ),
      ]
      : [""]),
  ]);
}
