/**
 * Endpoints related to authentication and account verification.
 *
 * @module api/authentication
 * @category Backend API
 * @subcategory User
 */
import getConfig from "config";
import log from "log";
import { authenticate as authenticateMessaging } from "api/message";
import { authenticate as authenticateAnalytics } from "api/v2/analytics";
import { getMe } from "api/user";
import { post } from "api/request";
import { endpoints, storageStrategies } from "api/constants";
import {
  makeAccessTokenRefreshRequestDTO,
  makeUserLoginDTO,
  refreshResponseToAuthenticationTokenPair,
  responseToAuthenticationTokenPair,
} from "model/authentication";
import cache from "cache";
import {
  ANONYMOUS_SESSION_ID_KEY,
  REFRESH_MARGIN,
  TOKEN_KEY,
} from "model/authentication/constants";
import { uuidv4 } from "util/generator";
import { isAnonymous } from "util/user";

/**
 * Whether or not an authentication refresh is currently processing.
 * @constant REFRESHING
 * @private
 * @type {boolean}
  */
let REFRESHING = false; // whether a refresh call is underway

/**
 * Log in as an anonymous user
 *
 * Stores the user in the cache as a side effect.
 *
 * @function anonymousLogin
 * @return {?module:model/authentication/AuthenticationTokenPair}
 */
export const anonymousLogin = async () => {
  const tokenPair = responseToAuthenticationTokenPair((await post(
    endpoints.ANONYMOUS_LOGIN,
    null,
    null,
    true,
  )).body);
  cache.setStrategy(storageStrategies.LOCAL);
  cache.storeObject(TOKEN_KEY, tokenPair);
  cache.storeValue(ANONYMOUS_SESSION_ID_KEY, uuidv4());
  // just to update the cache
  await getMe();
  return tokenPair;
};

/**
 * Log a user in with the given username & password.
 *
 * Stores the user in the cache as a side effect.
 *
 * @function login
 * @param {object} data as {@link module:api/types/api~makeUserLoginDTO}
 * @return {?module:model/authentication/AuthenticationTokenPair}
 */
export const login = async (data) => {
  const tokenPair = responseToAuthenticationTokenPair((await post(
    endpoints.USER_LOGIN,
    null,
    makeUserLoginDTO(data),
    true,
  )).body);
  cache.setStrategy(storageStrategies.LOCAL);
  cache.storeObject(TOKEN_KEY, tokenPair);
  cache.deleteValue(ANONYMOUS_SESSION_ID_KEY);
  // just to update the cache
  await getMe();
  return tokenPair;
};

const clearAuthCookie = async () => {
  const { domain } = (await getConfig());
  const newCookie = `Authorization=0; path=/; domain=.${domain}; SameSite=lax; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = newCookie;
  // NOTE prevent regression after token change
  const futureCookie = `X-Access-Token=0; path=/; domain=.${domain}; SameSite=lax; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  document.cookie = futureCookie;
};

/**
 * Log a user out and clears the cache.
 *
 * @function logout
 * @return {module:api/types/api~APIResponse}
 */
export const logout = async () => {
  const { refreshToken } = cache.getObject(TOKEN_KEY);
  const response = await post(endpoints.USER_LOGOUT, null, { refreshToken }, true);
  if (response.ok) {
    cache.clear();
    await clearAuthCookie();
  }
  return response;
};

/**
 * Requests a new access token using a valid refresh token.
 * @function refreshAccess
 * @private
 * @return AuthenticationTokenPair
 */
const refreshAccess = async (refreshToken) => refreshResponseToAuthenticationTokenPair(
  (await post(
    endpoints.USER_REFRESH,
    null,
    makeAccessTokenRefreshRequestDTO({ refreshToken }),
    true,
  )).body,
  refreshToken,
);

/**
 * Find the time from now in seconds before the access token expires.
 * @function nextRefreshTimeInSeconds
 * @return {?int} time in seconds
 */
export const nextRefreshTimeInSeconds = () => {
  const tokenPair = cache.getTokens();
  if (!tokenPair) return null;
  const time = new Date(tokenPair.time);
  time.setSeconds(time.getSeconds() + tokenPair.validityPeriod - REFRESH_MARGIN);
  return Math.floor((time - new Date()) / 1000);
};

/**
 * Cookies are used instead of header or query parameter tokens in the case
 * of images and videos. This sets up the cookie. Called whenever the auth token
 * is refreshed.
 *
 * @function setAuthCookie
 * @private
 */
const setAuthCookie = async (token) => {
  const { domain } = (await getConfig());
  const newCookie = `X-Access-Token=${token}; path=/; domain=.${domain}; SameSite=lax;`;
  document.cookie = newCookie;
};

/**
 * Refresh user authentication tokens.
 *
 * @function refresh
 * @param {boolean} force forces a re-authentication
 * @returns {?AuthenticationToken}
 * @modifies REFRESHING
 */
export const refresh = async (force = false) => {
  let tokenPair = cache.getTokens();
  if (!tokenPair) return null;
  if (REFRESHING) return tokenPair;
  // skip if the token pair is still valid and we're not forcing reauth
  if (!force && nextRefreshTimeInSeconds() > 0) return tokenPair;
  REFRESHING = true;
  try {
    tokenPair = await refreshAccess(tokenPair.refreshToken);
    cache.storeObject(TOKEN_KEY, tokenPair);
    await setAuthCookie(tokenPair.accessToken);
    await Promise.all([
      authenticateMessaging(tokenPair.accessToken),
      authenticateAnalytics(tokenPair.accessToken),
    ]);
  } catch (e) {
    if (e?.status === 403) {
      log.error("authentication error while refreshing access token");
    } else {
      const { openSiteAccessEnabled } = await getConfig();
      if (openSiteAccessEnabled && isAnonymous(cache.getProfile())) {
        cache.clear();
        window.location.reload();
      }
      log.error("unknown error during authentication refresh", e);
    }
    throw e;
  } finally {
    REFRESHING = false;
  }
  return tokenPair;
};
