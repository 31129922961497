/**
 * Help section for the bulk user form.
 *
 * @module ui/page/admin/user/bulk/help
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import {
  a,
  button,
  div,
  hr,
  li,
  ol,
  p,
  section,
  span,
  strong,
  ul,
} from "ui/html";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

export default function helpSection(userGroupMode) {
  return section("#help", [
    userGroupMode ? div(".create-groups-notice", [
      span([icon.solid("exclamation-triangle"), "Please ensure all groups you wish to use have been created before uploading the CSV."], ".warning"),
      button("Manage Groups", () => window.location.assign("/admin/manage-groups"), ".warn"),
      hr(),
    ]) : "",
    collapsible({
      title: "How Do I Mass Create Users",
    }, [
      p([strong("Step 1"), " - Click on Mass Create on the User menu - this will take you to the Mass User Creation page"]),
      p([strong("Step 2"), " - Add users here manually or by importing a CSV."]),
      p("To use the CSV template:"),
      ul([
        li("Click the Import CSV button"),
        li("Upload the completed CSV"),
        li(`Make the necessary modifications after CSV is uploaded - the system
            will let you know which newly uploaded users have any issues with
            their info`),
      ]),
      p([strong("Step 3"), " - Once everything has been verified/corrected on your end, press save and the users will be added to the system."]),
      p([
        "If you have any questions, please visit our ",
        a("Admin FAQ", "/admin/admin-faq"),
        " for more help",
      ]),
    ], ".important"),
    collapsible({
      title: "How do I use the CSV template?",
    }, [
      p([
        `Download the CSV template here `,
        a(
          [icon.solid("file-download"), "template CSV file"],
          "/media/user_bulk_upload_template.csv",
        ),
        `. Enter the user information into the spreadsheet.`,
      ]),
    ]), // spreadsheet format
    collapsible({
      title: "How do I save my spreadsheet in CSV format?",
    }, [
      collapsible({
        title: "Using Google Sheets",
      }, ol([
        li(`Click "File" in the main menu.`),
        li(`Click "Download".`),
        li(`Select "Comma separated values (.csv, current sheet)".`),
      ])),
      collapsible({
        title: "Using Open/Libre Office",
      }, ol([
        li(`Click "File" in the main menu.`),
        li(`Click "Save As".`),
        li(`Select "Text CSV (.csv)" in the file type dropdown menu.`),
        li([
          `An options dialog will appear if your spreadsheet is in a different format
           than CSV. Ensure the following options are set:`,
          ul([
            li(`"Character set" is set to "Unicode (UTF-8)".`),
            li(`"Field delimiter" is a comma (,).`),
            li(`"String delimiter" is a double-quote (").`),
            li(`"Save cell content as shown" is checked.`),
            li(`"Save cell formulas instead of calculated values" is un-checked.`),
            li(`"Quote all text cells" is checked.`),
            li(`"Fixed column width" is un-checked.`),
          ]),
        ]),
        li(`Click "ok" to save the file.`),
      ])),
      collapsible({
        title: "Using Microsoft Excel",
      }, ol([
        li(`Go to "File -> Save As" in the main menu.`),
        li(`Click "Browse".`),
        li(`In the "Save As" dialog box, under the "Save as Type" box, choose "CSV (comma delimited)".`),
        li(`Click "Save".`),
      ])),
    ]),
    collapsible({
      title: "What if I already have a spreadsheet in a different layout?",
    }, [
      p(`SmartEdge will do its best to find the column labels it needs, provided the first row of your
      spreadsheet contains labels. It will ignore any columns it doesn't recognize, and attempt to convert
      any cell values it finds to its required format. This will not always work, so when possible it's
      best to follow the specs.`),
      p(`After you have prepared the CSV, go to Import CSV to upload the completed CSV. Our verification
      system will scan for any issues and confirm the correct metadata entries. Double check and verify
      the necessary changes in red.`),
      p(`Click SAVE. When the Status column for each line item displays green icon,
      you have successfully uploaded all content metadata.`),
      p(`The user addition bar will indicate the progress status at the bottom of the screen.
      Please confirm the newly uploaded and created content metadata on the Manage Content interface.
      You’re all set!`),
    ]),
    collapsible({
      title: "What format should my spreadsheet be in?",
    }, [
      p(`For the most reliable results, please ensure your spreadsheet file adheres to the following format:`),
      ul([
        li("The first row of the sheet should contain column labels for each column."),
        li([
          "The columns should be labeled with the following text and in the same order, starting from the first column:",
          ul([
            li("First Name"),
            li("Last Name"),
            li("Username"),
            li("Cellphone"),
            li("Email Address"),
            li("Password"),
            li("Verified"),
            li("Group Names"),
            li("(Any other columns after these will be ignored)"),
          ]),
        ]),
        li(`First/given names may contain only letters and spaces, and must be between 0-255 characters.`),
        li(`Last/family names may contain only letters and spaces, and must be between 0-255 characters.`),
        li(`Usernames may contain only letters, numbers, and underscores, and must be between 3-255 characters.`),
        li(`The cell phone column must be in the format "+13215551234" with country code included
        (+1, +91, etc.)". Other entries will not be recognized.`),
        li(`Passwords must contain at least one uppercase letter and one number, and be between 8-255
        characters with numbers and/or symbols.`),
        li(`Verified must contain either "yes" or "no". No other values will be recognized.`),
        li(`Group Names may contain a list of user groups separated by commas, and is only recognized when "User Groups" was selected after clicking "Mass Creation" in the menu.`),
        li([
          `Users may be added to system groups using special keywords in the Group Name column:`,
          ul([
            li(`"FILE_MANAGEMENT" for File Managers`),
            li(`"MEDIA_MANAGEMENT" for Media Managers`),
            li(`"USER_MANAGEMENT" for File Managers`),
            li(`"ADMINISTRATORS" for Administrators`),
          ]),
        ]),
        li(`Users are always added to the Public Access group when they are created, so there is no need to put Public Access in your CSV.`),
      ]),
    ]),
  ]);
}
