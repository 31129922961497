/**
 * Renders the playlist-grid section type for dynamic pages.
 *
 * @module ui/page/dynamic/section-playlist-grid
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import h2 from "ui/html/h2";
import section from "ui/html/section";
import cardGrid from "ui/component/card-grid";
import placeholder from "ui/component/placeholder";
import { upgradeMetadataItem } from "./common";

const makeSectionId = (id) => `page-section-playlist-grid-${id}`;

/**
 * A grid display for a playlist section.
 *
 * @function sectionPlaylistGrid
 * @param {module:model/dynamic-page~PageSection} section of type `PLAYLIST_GRID`
 * @param {object} listEntry collection of playlist items
 * @return {module:ui/common/el~El} section element
 */
export default function sectionPlaylistGrid(state, sectionId) {
  const pageSection = state.sections.get(sectionId);
  if (!pageSection) return "";

  const playlist = state.playlists.get(pageSection.itemId);
  if (!playlist?.items) {
    return section(".content-grid.playlist-grid", [
      h2(placeholder()),
      cardGrid.placeholder(".playlist-grid"),
    ]);
  }

  // issue: playlists do not filter their metadata items based on the item's own ACL
  // status, and yet users cannot watch a video or download a PDF if they do not have
  // individual access rights to the item. Here we're detecting items that lack
  // those rights and filtering them out to avoid user frustration.

  let items = playlist.items.map(upgradeMetadataItem(state));
  if (items.some((i) => i.PLACEHOLDER) && items.some((i) => !i.PLACEHOLDER)) {
    items = items.filter((i) => !i.PLACEHOLDER);
  }

  return section(".content-grid.playlist-grid", { key: makeSectionId(sectionId) }, [
    pageSection.title ? h2(pageSection.title) : "",
    cardGrid({
      id: makeSectionId(sectionId),
      type: pageSection.type,
      items,
      text: pageSection.text,
      playback: state.playback,
    }),
  ]);
}
