/**
 * A modal for doing advanced content searches on the manage content page.
 *
 * @module ui/component/modal/advanced-content-search
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  div,
  label,
  input, h3,
} from "ui/html";
import form from "ui/component/form-managed";
import group from "ui/component/form-managed/field-group";
import button from "ui/component/form-managed/button";
import closeFab from "ui/component/modal/component/close-fab";
import { metadataTypes, metadataTypesFriendly } from "model/metadata/constants";
import { merge, frozen } from "util/object";
import labelSelectModal from "./label-select";

let currentState = {}; // stored internally in the module

const getCurrentParams = (modalView) => {
  const dqs = modalView.element.querySelector.bind(modalView.element);
  const lab = dqs("[name=label]").value;
  const type = metadataTypes[dqs("[name=type]").value.toUpperCase()];
  return {
    id: dqs("[name=id]").value || null,
    title: dqs("[name=title]").value || null,
    description: dqs("[name=description]").value || null,
    labels: lab && lab !== "Label" ? [lab] : null,
    type: type && type !== "Type" ? type : null,
    sortOrder: dqs("[name=sort-order]").value,
    sortField: dqs("[name=sort-field]").value,
  };
};

const doSearch = (modalView) => {
  modalView.resolve(getCurrentParams(modalView));
};

const doUpdate = (state, modalView) => {
  const newState = frozen(merge.all([
    currentState,
    { params: getCurrentParams(modalView) },
    state,
  ]));
  // FIXME timeout to hack around a race condition...
  /* eslint-disable-next-line no-use-before-define */
  setTimeout(() => modalView.patch(advancedContentSearchModal(newState, modalView)),
    0);
};

const makeTypeOptions = (types) => types.map((type) => ({
  name: metadataTypesFriendly.get(type),
  description: "",
}));

const defaultParams = frozen({
  id: "",
  title: "",
  description: "",
  labels: [],
  type: "",
  sortField: "",
  sortOrder: "",
});

const defaultTypes = frozen([
  metadataTypes.DOCUMENT,
  metadataTypes.VIDEO,
  metadataTypes.LIST,
]);

const defaultState = frozen({
  labels: [],
  types: defaultTypes,
  params: defaultParams,
});

const setFocus = () => {
  document.querySelector(`#advanced-form input[name="id"]`).focus();
};

/**
 * An async modal dialog for doing advanced searches for content.
 *
 * @param {object} state
 * @param {string[]} [state.labels=[]] list of existing labels
 * @param {string[]} [state.types] list of existing metadata types
 *                                 (default video, document list)
 * @param {object} [state.params] pre-existing search params
 * @param {module:ui/view/modal~modalView} modalView modal dialog view
 * @return {?object} updated search parameters for use with advancedMetadataFilter
 */
const advancedContentSearchModal = (state, modalView = null) => {
  currentState = merge(defaultState, state);
  const { params, types } = currentState;
  let displayedType;
  if (metadataTypesFriendly.get(params.type)) {
    displayedType = metadataTypesFriendly.get(params.type);
  } else if (params.type) {
    displayedType = params.type;
  } else {
    displayedType = "Type";
  }
  return div(
    ".dialog.form.advanced-search.advanced-content-search",
    {
      hook: {
        insert: setFocus,
        update: setFocus,
      },
    },
    [
      closeFab(true, modalView),
      div(".content", [
        div(".header", h3("Advanced Search")),
        div(".body", form("#advanced-form", [
          input("hidden", params.sortOrder, false, "sort-order"),
          input("hidden", params.sortField, false, "sort-field"),
          group([
            label("Content ID"),
            input.text(params.id, false, "id", { props: { placeholder: "Content ID" } }),
          ]),
          group([
            label("Title"),
            input.text(params.title, false, "title", { props: { placeholder: "Title" } }),
          ]),
          group([
            label("Description"),
            input.text(
              params.description,
              false,
              "description",
              { props: { placeholder: "Description" } },
            ),
          ]),
          div('.select-wrapper', [
            input(
              "text",
              params?.labels?.length > 0 ? params.labels[0].name : 'Label',
              false,
              "label",
              { attrs: { readonly: true } },
            ),
            button.icon({
              icon: "plus-circle",
              onClick: async () => {
                const currentParams = getCurrentParams(modalView);
                const newLabel = await modalView.async(labelSelectModal(
                  {
                    search: "",
                    entries: currentState.labels,
                    hideAddfield: true,
                  },
                  modalView,
                ));
                if (newLabel) {
                  currentParams.labels = [newLabel];
                  doUpdate({ params: currentParams }, modalView);
                }
              },
              sel: ".subtle",
            }),
          ]),
          div('.select-wrapper', [
            input(
              "text",
              displayedType,
              false,
              "type",
              { attrs: { readonly: true } },
            ),
            button.icon({
              icon: "plus-circle",
              onClick: async () => {
                const currentParams = getCurrentParams(modalView);
                const selectedType = await modalView.async(labelSelectModal(
                  {
                    search: "",
                    entries: makeTypeOptions(types),
                    hideAddfield: true,
                  },
                  modalView,
                ));
                if (selectedType) {
                  currentParams.type = selectedType.name;
                  doUpdate({ params: currentParams }, modalView);
                }
              },
              sel: ".subtle",
            }),
          ]),
          div(".buttons", button.primary({
            label: "Search",
            onClick: () => doSearch(modalView),
          })),
        ])),
      ]),
    ],
  );
};
export default advancedContentSearchModal;
