/**
 * All messages interface
 *
 * @module ui/component/chat/all-messages
 * @category UI
 * @subcategory Chat
 */
import { merge } from "util/object";
import { div, span } from "ui/html";
import button from "ui/component/form-managed/button";
import { messageStatus } from "@smartedge/em-message/constants";
import { canChat } from "model/user";
import { messageStatuses } from "model/message/constants";
import { sortDate, sortText } from "util/sort";
import icon from "ui/component/icon";
import messageDetailed from "./message-detailed";
import { chatMode, chatTypes, getChannelUsersExtended } from "./common";
import messageSearch from "./message-search";

const defaultState = {};

const isMessageSelected = (state) => state.selectedMessages.length;

const searchByStatus = (state, status) => {
  const { searchParams } = state;
  const { params } = searchParams;
  state.onAdvancedSearch({
    ...searchParams,
    params: {
      ...searchParams.params,
      messageStatus: params.messageStatus === status ? undefined : status,
    },
  });
};

const lastMessage = (messages = [], unreadOnly = false) => {
  const sortedMessages = [...messages]
    .sort((a, b) => sortDate(new Date(b.created), new Date(a.created)));
  return (unreadOnly
    ? sortedMessages.find((m) => m.messageStatus === messageStatuses.UNREAD) || sortedMessages[0]
    : sortedMessages[0]
  );
};

const getUnreadMessagesLength = (me, messages = []) => {
  const uniqueMessages = Array
    .from(new Set(messages.map((m) => m.id)))
    .map((id) => messages.find((m) => m.id === id));
  return uniqueMessages
    .filter((m) => m.status === messageStatus.UNREAD && (m?.toUser?.id === me.id || m.to === me.id))
    .length;
};

export default function allMessages(inState, modalView) {
  const state = merge(defaultState, inState);
  const unreadOnly = state.searchParams?.params?.messageStatus === messageStatuses.UNREAD;
  const lbl = state.messagesLoading ? "Loading..." : "No Messages Found";
  return [
    div(".chat-header.action-bar", [
      div(".channel-title", div(".label", span([icon.solid("comments"), "ALL MESSAGES"]))),
      messageSearch({
        ...state,
        users: getChannelUsersExtended(state),
      }, modalView),
    ]),
    div(".chat-header", [
      div(".channel-actions", [
        div(".actions-left-side", [
          button.icon({
            icon: state.chatMode === chatMode.DEFAULT ? "check" : "xmark",
            label: "Select a message",
            onClick: () => {
              if (state.chatMode === chatMode.DEFAULT) {
                state.onSelectChatMode(chatMode.MESSAGE_SELECTION);
              } else {
                state.onSelectChatMode(chatMode.DEFAULT);
              }
            },
            disabled: !canChat(state.me),
          }),
          button.icon({
            icon: "envelope",
            sel: `.primary${unreadOnly ? "" : ".stand-in"}`,
            label: "Show Unread messages",
            onClick: () => searchByStatus(state, messageStatus.UNREAD),
          }),
          button.icon({
            icon: "envelope-open",
            sel: `.secondary${unreadOnly ? ".stand-in" : ""}`,
            label: "Show Read messages",
            onClick: () => searchByStatus(state, messageStatus.READ),
          }),
          button.icon({
            icon: "reply",
            sel: ".alternate",
            label: "Reply to a message. Select a message first",
            onClick: () => {
              const {
                channels,
                selectedMessages,
                onSelectChannel,
                onSelectChatType,
                onSelectChatMode,
              } = state;
              if (isMessageSelected(state)) {
                onSelectChatType(chatTypes.REGULAR_MESSAGING);
                onSelectChatMode(chatMode.DEFAULT);
                onSelectChannel(
                  channels.find((c) => c.id === selectedMessages[0].from),
                  selectedMessages[0],
                );
              }
            },
            disabled: !isMessageSelected(state) || !canChat(state.me),
          }),
          // No such functionality right now
          // button.icon({
          //   icon: "print",
          //   sel: ".accent",
          //   label: "Print",
          //   disabled: !isMessageSelected(state),
          // }),
          // No such functionality right now
          // button.icon({
          //   icon: "trash",
          //   sel: ".danger",
          //   label: "Delete",
          //   disabled: !isMessageSelected(state),
          // }),
        ]),
        // Pagination does not work on BE right now
        // Impossible to do for latest messages
        // Because there is no way we can search by latest messages
        // We fetch all and select the latest ones
        // div(".actions-right-side", [
        //   button.icon({
        //     icon: "chevron-left",
        //     label: "Previous Page",
        //   }),
        //   button.icon({
        //     icon: "chevron-right",
        //     label: "Next Page",
        //   }),
        // ]),
      ]),
    ]),
    Array.from(state.latestDirectMessages.values()).length
      ? div(".messages", [
        ...Array.from(state.latestDirectMessages.values())
          .sort((a, b) => sortText(b[0].created, a[0].created))
          .map((messages) => messageDetailed({
            ...state,
            message: lastMessage(messages, unreadOnly),
            counter: getUnreadMessagesLength(state.me, messages),
          }, modalView)),
      ])
      : div(".chat-no-channel-selected", div(".label", div(".label", lbl))),
  ];
}
