/**
 * The user-facing account deletion FAQ page.
 *
 * @module ui/page/faq/account-deletion
 * @category Pages
 * @subcategory User Facing
 */
import { div, h1, h3, p, a } from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "account-deletion-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showAccountDeletionFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Account Deletion"]),
  collapsible({
    title: "How do I delete my account?",
    autoCloseConfiguration,
  }, p(`If you are a registered user, go to Account Settings and select the Delete button. You will be asked
    to confirm that you would like to delete your account.`)),
  collapsible({
    title: "What happens if I accidentally delete my account?",
    autoCloseConfiguration,
  }, p(`Unfortunately, account deletions are permanent and your organization’s SmartEdge admin can’t restore
    your old profile. However, you can always re-create your account if necessary.`)),
  collapsible({
    title: "What happens if I delete my account if I'm an external user?",
    autoCloseConfiguration,
  }, p(`Your account will be deleted on SmartEdge but not on your organization’s external system. If you
    re-login with the external info again, your SmartEdge account will be recreated.`)),
]);

export default async function accountDeletionFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Account Deletion"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showAccountDeletionFaq)("#end-user-faq", state);
  loading.hide();
}
