/**
 * A toggle element supporting multiple labeled states.
 *
 * @module ui/component/form-managed/field-multi-toggle
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import div from "ui/html/div";
import span from "ui/html/span";
import input from "ui/html/input";
import p from "ui/html/p";
import { managedField } from "ui/view/form-managed/util";
import { frozen, merge } from "util/object";
import { emit } from "util/event";

const getValue = (node) => {
  let value = null;
  node.elm.querySelectorAll("input[type=radio]").forEach((elm) => {
    if (elm.checked) {
      value = elm.value;
    }
  });
  return value;
};

const requiredHook = (required) => (node, validity) => {
  if (!required) return;
  const value = getValue(node);
  if (value === null) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

const selectTab = (self, value, index) => () => {
  /* eslint-disable no-param-reassign */
  [...self.elm.querySelectorAll(`input[type=radio]`)].forEach((el) => {
    if (el.value === value) {
      el.checked = true;
    } else {
      el.checked = false;
    }
  });
  self.elm.dataset.selectedTab = index;
  emit(self.elm, "em:tab-change", { value });
  emit(self.elm, "em:form-managed-change", { value });
  /* eslint-enable no-param-reassign */
};

const notch = (self, onSelect) => (option, index) => {
  const { label, value } = option;
  return span(
    label,
    ".notch",
    {
      dataset: { tabId: `${index}` },
      on: {
        click: (e) => {
          selectTab(self, value, index)(e);
          if (onSelect) onSelect(option, index);
        },
      },
    },
  );
};

const buildRadio = (selected) => ({ name, id, value }) => input.radio(
  value === selected,
  false,
  name,
  value,
  { attrs: { id } },
);

/**
 * @typedef MultiToggleOption
 *
 * @property {string} label
 * @property {string} [name] defaults to multi-toggle field name
 * @property {string} value
 */

const defaultState = frozen({
  label: "Multi-Toggle",
  name: "multi-toggle",
  options: [], // MultiToggleOption[]
  onSelect: null,
  value: 0,
});

/**
 * @function multiToggleField
 * @param {object} state
 * @param {string} state.label label for the entire toggle element
 * @param {string} state.name field name
 * @param {MultiToggleOption[]} state.options options to be displayed on the toggle
 * @param {string} [value=state.options[0].value] value of the toggle option
 * @param {string} [value=state.options[0].label] label of the toggle option
 * @param {function} onSelect callback function called when the user makes a selection
 * @return {module:ui/html/div} `div.tab-container`
 */
export default function multiToggleField(inState = {}) {
  const state = merge(defaultState, inState);
  if (state.options?.length === 0) {
    throw new Error("multi-toggle requires at least one option");
  }
  state.value = state.value || state.options?.[0]?.value;

  state.options = state.options.map((opt) => ({
    ...opt,
    name: opt.name || state.name,
  }));

  const display = div(
    '.tab-container.multi-toggle',
    merge(
      {
        dataset: {
          tabCount: `${state.options.length}`,
          selectedTab: state.options.findIndex((opt) => opt.value === state.value),
        },
      },
      {},
    ),
  );

  const inner = div('.notches-wrapper', [
    p(state.label),
    div(".notches", state.options.map(notch(display, state.onSelect))),
    div('.radio-notches-wrapper', state.options.map(buildRadio(state.value))),
  ]);

  display.children = [inner];

  const field = managedField(
    display,
    state.name,
    getValue,
    [requiredHook(state.required)],
  );

  return genericField(
    { ...state, type: "multi-toggle" },
    field,
  );
}
