/**
 * Utility functions related to file handling and naming.
 *
 * @module util/file
 * @category Utilities
 */
import { fileTypes, fileExtensions, fileMimeTypes } from "model/file-descriptor/constants";
import { getConfigFromCache } from "config";

/**
 * Removes special characters from a file name and replaces spaces with underscores.
 * @function cleanFileName
 * @deprecated in favor of whitelisted normalizeFileName
 */
export const cleanFileName = (file) => file
  .replace(/\s/gi, "_")
  .replace(/[[\]!@#$%^&*=|:;"',?/~`{}()!<>+\\]/gi, '')
  .replace(/[_]+/gi, '_');

/**
 * Guesses a file type based on its file extension name, as defined in
 * {@link module:model/file-descriptor/constants}
 *
 * @function getFileType
 * @param {string} fileName
 * @return {module:api/constants~fileTypes}
 */
export const getFileType = (fileName) => {
  const split = fileName.split(".");
  return fileExtensions.get(split[split.length - 1]) || fileTypes.UNKNOWN;
};

/**
 * Get supported mime types for a given fileType.
 *
 * @function getMimeTypes
 * @param {module:api/constants~fileTypes} fileType
 * @return {module:api/constants~fileMimeTypes[]}
 */
export const getMimeTypes = (fileType) => [...fileMimeTypes.entries()]
  .filter((entry) => entry[1] === fileType)
  .map((entry) => entry[0]);

/**
 * Normalizes a funky file name, stripping out or replacing bad characters.
 *
 * @example
 * normalizeFileName("My F^unky_File name $$$.MP4"); // "my_funky_file_name.mp4"
 *
 * @function normalizeFileName
 * @param {string} name a file name
 * @return {string} a file name normalized to the backend API's expected conventions
 */
export const normalizeFileName = (name) => {
  const split = name.split(".");
  const ext = split[split.length - 1];
  return name
    .slice(0, name.indexOf(ext))
    .replace(/[^A-Za-z0-9\-_]/g, "_")
    .replace(/_+/g, "_")
    .replace(/^_+/, "")
    .replace(/_+$/, "")
    .concat(`.${ext}`)
    .toLowerCase();
};

const kbytes = 1024;
const mbytes = kbytes * 1024;
const gbytes = mbytes * 1024;

/**
 * Format numeric bytes to a nice user-readable string.
 *
 * @example
 * prettyBytes(2312010541); // "1.3gb"
 * prettyBytes(1008123); // "1mb"
 * prettyBytes(123456); // "1kb"
 * prettyBytes(432); // "432b"
 *
 *
 * @function prettyBytes
 * @param {number} bytes
 */
export const prettyBytes = (bytes) => {
  if (bytes > gbytes) return `${(bytes / gbytes).toFixed(1)}gb`;
  if (bytes > mbytes) return `${Math.round(bytes / mbytes)}mb`;
  if (bytes > kbytes) return `${Math.round(bytes / kbytes)}kb`;
  return `${bytes}b`;
};

export const getFileStorageAvailable = () => getConfigFromCache()
  ?.server?.featureSetConfig?.gridFsSizeLimitInBytes;

export const getFileStorageUsed = () => getConfigFromCache()?.server?.gridFsCurrentSizeInBytes;

export const getFileStorageRemaining = () => {
  const used = getFileStorageUsed() || 0;
  const available = getFileStorageAvailable() || 0;
  return available - used;
};

export const getFileStoragePercentUsed = () => {
  const used = getFileStorageUsed() || 0;
  const available = getFileStorageAvailable() || 0;
  return used === 0 ? 0 : (used / available) * 100;
};
