/**
 * A help modal dialog for a course page.
 *
 * @module ui/component/modal/help/course-welcome
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function courseWelcomeModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.COURSE_WELCOME,
    skipCache,
    async,
    sel: ".help-modal-course-welcome",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        lastSlideLabel: "Get Started",
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom.img-md", "/media/modal-help-course-welcome.svg"),
            p("Please enroll in the course before getting started", ".title"),
            div(".text-container", [
              p("If you have any issues, please get in touch with your organization’s"),
              p("SmartEdge administrator", ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
