/**
 * Type definitions and sanitation functions for file descriptor models.
 *
 * @module model/file-descriptor
 * @category Model
 * @subcategory File Descriptors
 */
import { endpoints } from "api/constants";
import { baseUrlSync } from "api/core-util";
import {
  required,
  validString,
} from "model/constraints";
import { ConstraintError } from "model/error";
import { validFileType } from "./constraints";

/**
 * Generates a URL for the file descriptor's attached file with the current access token
 * as a query parameter. Allows us to fetch the file without appending an auth header,
 * so the URL can be used in an `<img>` or similar element.
 *
 * @function getContentFileURL
 * @private
 */
export const getContentFileUrl = (id) => () => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_FILE}/${id}`);

/**
 * A file descriptor object.
 *
 * @typedef FileDescriptor
 * @property {string} id UUID
 * @property {string} fileId identity number/hash in file storage
 * @property {FileType} type the file's type
 * @property {string} name the file's original name
 * @property {int} size file size in bits
 * @property {string} checksum file checksum (md5 hash)
 * @property {function} fileUrl generates an authenticated URL for retrieving the file
 *                              contents
 * @property {string} fileUrlStatic a file url that doesn't change
 *                                  (must be used before auth token expires)
 */

/**
 * Converts a smartedge API file descriptor response DTO to a FileDescriptor object.
 *
 * Found in `/api/file/descriptor` and `/api/page` endpoints.
 *
 * @function responseToFileDescriptor
 * @param {object} response DTO from the smartedge API
 * @param {string} response.id
 * @param {string} response.fileId
 * @param {string} response.name
 * @param {FileType} response.type
 * @param {int} response.size
 * @param {string} response.checksum
 * @return {FileDescriptor}
 */
export const responseToFileDescriptor = (response) => ({
  id: response.id,
  fileId: response.fileId,
  name: response.fileName,
  type: response.fileType,
  checksum: response.checksum,
  size: response.fileSize,
  fileUrl: getContentFileUrl(response.id),
  fileUrlStatic: getContentFileUrl(response.id)(),
});

/**
 * A file descriptor create DTO.
 *
 * @typedef FileDescriptorCreateDTO
 * @property {string} name file name from media dump
 * @property {string} type the file's type
 */

/**
 * DTO for creating a file descriptor entity.
 *
 * Sent to `/api/file/descriptor` endpoints.
 *
 * @property {object} partial
 * @property {string} partial.fileName a file name that exists in the uploaded files list
 * @property {FileType} partial.fileType as {@link FileDescriptor.fileType}
 * @return {FileDescriptorCreateDTO}
 */
export const makeFileDescriptorCreateDTO = (partial) => ({
  fileName: required(partial.name, "FileDescriptorCreateDTO.fileName", validString),
  fileType: required(partial.type, "FileDescriptorCreateDTO.fileType", validFileType),
});

/**
 * Media file upload partial. Main thing is to cast `data` to a FormData object.
 *
 * @typedef FileUploadPartial
 * @property {mixed} file
 */

/**
 * produce a DTO for uploading a media file.
 *
 * @todo implement correctly
 *
 * @function makeFileUploadDTO
 * @property {object} partial
 * @property {FormData} partial.data
 *
 * @return {FileUploadDTO}
 */
export const makeFileUploadDTO = (partial) => {
  if (!(partial.data instanceof FormData)) throw new ConstraintError("form file", "must be an instance of FormData", null);
  if (!(partial.data.get("file"))) throw new ConstraintError("form file", "file missing");
  return partial.data;
};
