/**
 * Builds a dynamic collapsible faq section.
 *
 * @module ui/page/dynamic/section-faq
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import h2 from "ui/html/h2";
import p from "ui/html/p";
import sect from "ui/html/section";
import collapsible from "ui/component/collapsible";

let count = 0;

export default function sectionFaq(section) {
  const { searchData, title } = section;
  count++;
  return sect(
    ".section-faq",
    [
      title ? h2(title) : "",
      ...(searchData?.entries?.map((entry) => collapsible({
        title: entry.label,
        autoCloseConfiguration: {
          level: 0,
          autoCloseSameLevel: true,
          id: count,
        },
      }, p(entry.text))) || []),
    ],
  );
}
