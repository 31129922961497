/**
 * Playlist metadata streams.
 *
 * Provides `get` interface for fetching playlist by `playlistId`.
 *
 * Provides `getMany` interface for fetching many playlists by `[playlistId]`.
 *
 * @module data/metadata/playlist
 * @category Data Streams
 * @subcategory Metadata
 */
import xs from "xstream";
import { metadataTypes } from "model/metadata/constants";
import { byIds, search as playlistSearch, countPlaylists } from "api/v2/metadata/playlist";
import { makePlaylistMetadata } from "model/metadata";
import {
  CACHE_PLAYLIST_KEY_V2 as CACHE_KEY,
  CACHE_SEARCH_PLAYLIST_KEY_V2 as SEARCH_KEY,
} from "cache/constants";
import videoStream from "data/metadata/video";
import documentStream from "data/metadata/document";
import many from "data/pipeline/many";
import countPipe from "data/pipeline/count";
import search from "data/pipeline/search";
import single from "data/pipeline/single";
import { streamLog, sumStream } from "data/stream/compose";
import hashmap from "util/hash-map";

const itemMap = hashmap();
const countMap = hashmap();

const defaultFn = (id) => makePlaylistMetadata({ id });
const modelFn = (partial) => makePlaylistMetadata(partial);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const {
  post: searchPost,
  sink$: searchSink$,
  pending$: searchPending$,
  items$: searchItems$,
} = search({
  apiFn: playlistSearch,
  defaultFn,
  modelFn,
  cacheSearchKey: SEARCH_KEY,
  cacheItemsKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: count,
  sink$: countSink$,
  pending$: countPending$,
} = countPipe({
  apiFn: countPlaylists,
  defaultFn: () => 0,
  modelFn: (r) => r,
  cacheKey: CACHE_KEY,
  accumulator: countMap,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
  searchItems$,
);

out$.compose(streamLog("PL_OUT_$"));

const pending$ = sumStream(
  singlePending$,
  manyPending$,
  searchPending$,
  countPending$,
);

/**
 * Pushes all playlist items into the respective metadata streams, returning
 * document and video `all$`.
 *
 * Useful for ensuring the availability of individual items for any playlists fetched
 * by get() or getMany().
 *
 * @example
 * const playlistA$ = playlistStream.getMany(playlistIdsA);
 * const playlistB$ = playlistStream.get(playlistIdsB);
 * const { document$, video$ } = playlistStream.getItems();
 * // maps of all metadatas in all lists requested in playlistIdsA and playlistIdsB
 *
 * @function getPlaylistItems
 * @returns {Stream} `{
 *   document$: Stream.<Map.<UUID, DocumentMetadata>>,
 *   video$: Stream.<Map.<UUID, VideoMetadata>>
 * }`
 */
const getItems = () => {
  all$.addListener({
    next: (lists) => {
      const videoIds = new Set();
      const documentIds = new Set();
      [...lists.values()].forEach((list) => {
        list.items?.forEach((item) => {
          switch (item.type) {
            case metadataTypes.VIDEO:
              videoIds.add(item.id);
              break;
            case metadataTypes.DOCUMENT:
              documentIds.add(item.id);
              break;
            default:
              break;
          }
        });
      });
      if (videoIds.size) videoStream.getMany([...videoIds]);
      if (documentIds.size) documentStream.getMany([...documentIds]);
    },
  });

  return {
    document$: documentStream.all$,
    video$: videoStream.all$,
  };
};

all$.imitate(out$);

export default {
  count,
  get,
  getMany,
  getItems,
  search: searchPost,
  all$,
  search$: searchSink$,
  count$: countSink$.compose(streamLog("PLAYLIST_COUNT$")),
  pending$,
};
