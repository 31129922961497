/**
 * The admin page for managing groups.
 *
 * Corresponds to `markup/admin/manage-groups.html`
 *
 * @module ui/page/admin/group/manage
 * @category Pages
 * @subcategory Admin - Groups
 */
import {
  a,
  div,
  section,
} from "ui/html";
import api from "api";
import { featureTypes } from "model/config/constants";
import actionBar from "ui/component/dashboard-action-bar";
import actionBarSearch from "ui/component/action-bar-search";
import advancedGroupSearch from "ui/component/modal/advanced-group-search";
import table, { sortOrders } from "ui/component/dynamic-table";
import widget from "ui/component/dashboard-widget";
import userGroupName from "ui/component/user-group-name";
import view from "ui/view";
import { groupSort } from "util/sort";
import {
  groupSortCriteria,
  aclPrincipalTypes,
} from "model/acl/constants";
import { groupTextFilter, groupAdvancedFilter } from "util/filter";
import dashboardLayout from "ui/page/layout/dashboard";
import { guard } from "util/feature-flag";
import {
  defaultSearchParams,
  defaultManageState as defaultState,
  searchModes,
} from "./state";

let loadingView, modalView, manageGroupsView, searchView;

/**
 * Clears search results.
 * @function onClear
 * @private
 */
const onClear = () => {
  manageGroupsView.update({
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  });
};

/**
 * Figures out which sortCriteria to use based on state sort params.
 * @function getSortCriteria
 * @private
 */
const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "id":
      return (sortOrder === sortOrders.DESC
        ? groupSortCriteria.ID_DESC
        : groupSortCriteria.ID_ASC);
    case "count":
      return (sortOrder === sortOrders.DESC
        ? groupSortCriteria.COUNT_DESC
        : groupSortCriteria.COUNT_ASC);
    case "name":
    default:
      return (sortOrder === sortOrders.DESC
        ? groupSortCriteria.NAME_DESC
        : groupSortCriteria.NAME_ASC);
  }
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function showAdvancedSearchModal
 * @private
 */
const onAdvancedSearch = async () => {
  onClear();

  const searchParams = await modalView.async(
    advancedGroupSearch({
      async: true,
      params: manageGroupsView.state.searchParams,
    }, modalView),
  );
  if (searchParams) {
    manageGroupsView.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
  }
};

/**
 * Handles changing table sort order.
 * @function onSortChange
 * @private
 */
const onSortChange = (theView) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === theView.state.sortColumn) {
    if (theView.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  theView.update({
    sortOrder,
    sortColumn,
  });
};

/**
 * Updates the search params with the contents of the basic search filter.
 *
 * @function onSearch
 * @private
 */
const onSearch = (filter) => {
  manageGroupsView.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  });
};

/**
 * Maps a user group to table row properties.
 * @function tableRow
 * @private
 */
const tableRow = (group) => ({
  id: a(group.id, `/admin/group-profile?id=${group.id}`),
  name: userGroupName(group),
  count: group.members?.length || 0,
});

/**
 * Sets up the results table.
 *
 * @function showManageContent
 * @private
 */
const showManageGroups = (theView) => section('#manage-groups', table({
  columnLabels: ["Group ID", "Group Name", "Members"],
  sortColumn: theView.state.sortColumn,
  sortOrder: theView.state.sortOrder,
  rows: theView.state.filteredGroups.map(tableRow),
  onSortChange: onSortChange(theView),
  placeholder: "No groups found.",
}));

/**
 * Sets up the search action bar.
 * @function showSearch
 * @private
 */
const showSearch = ({ state }) => actionBarSearch({
  resultCount: state.filteredGroups.length,
  filter: state.searchParams.filter,
  showClear: state.searchMode !== searchModes.NONE,
  title: "All Groups",
  onSearch,
  onAdvancedSearch,
  onClear,
});

/**
 * Updates the table view, then the search view, after applying filters and sorting.
 * @function doUpdate
 * @private
 */
const doUpdate = (self) => {
  const {
    searchMode,
    groups,
    sortColumn,
    sortOrder,
    searchParams,
  } = self.state;
  let filteredGroups;
  switch (searchMode) {
    case searchModes.BASIC:
      filteredGroups = groups.filter(groupTextFilter(searchParams.filter));
      break;
    case searchModes.ADVANCED:
      filteredGroups = groups.filter(groupAdvancedFilter(searchParams));
      break;
    case searchModes.NONE:
    default:
      filteredGroups = [...groups];
  }
  filteredGroups.sort(groupSort(getSortCriteria(sortColumn, sortOrder)));

  self.updateState({ filteredGroups });

  self.render();
  if (searchView) searchView.update(self.state);
};

/**
 * The manage groups page.
 * @function manageGroups
 * @param {Selector} selector
 */
export default async function manageGroups(selector) {
  guard(featureTypes.USER_MANAGEMENT);
  const title = "Manage Groups";
  const { header, loading, modal } = dashboardLayout(
    selector,
    [
      actionBar(section("#actions")),
      widget(div("#manage-groups")),
    ],
    title,
    true,
  );
  loadingView = loading;
  modalView = modal;
  loadingView.show();

  const [user, groupList] = await Promise.all([
    api.user.getMe(),
    api.acl.listGroups(),
  ]);

  const filteredGroups = groupList.filter((item) => item.type === aclPrincipalTypes.GROUP);

  const state = {
    ...defaultState,
    user,
    groups: filteredGroups,
    filteredGroups,
    title,
  };

  header.update(state);
  manageGroupsView = view.create(showManageGroups, doUpdate)("#manage-groups", state);
  searchView = view.create(showSearch)("#actions", state);

  loadingView.hide();
}
