/**
 * A common functions for cards
 *
 * @module ui/component/card/common
 * @category UI
 * @subcategory Components
 */
import { cardFormatOptions } from "model/dynamic-page/constants";
import { div, progress } from "ui/html";
import { txt } from "util/dom";
import { formatPercent, shortDescription } from "util/format";
import placeholder from "ui/component/placeholder";
import button from "ui/component/form-managed/button";
import { emit } from "util/event";

export const desc = (
  description,
  temp = false,
  cardSize = cardFormatOptions.VERTICAL,
) => {
  const length = cardSize === cardFormatOptions.HORIZONTAL ? 500 : 100;
  return (description || !temp
    ? div(
      ".description",
      { props: { title: txt(description) } },
      shortDescription(description, length),
    )
    : div(".description", placeholder())
  );
};

export const openModalButton = (metadata) => button({
  label: "view",
  onClick: (e) => emit(e.currentTarget, "em:modal", { id: metadata.id, type: metadata.type, metadata }),
});

export const posterPlaceholder = () => div(".poster", placeholder());

export const position = (pos = 0, duration = 0) => {
  const label = formatPercent(pos);
  const min = 0;
  const max = 100;
  const value = (pos && duration)
    ? Math.floor((pos / duration) * 100)
    : 0;
  return progress(label, value, min, max);
};
