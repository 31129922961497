/**
 * Messages used by the bulk content form.
 *
 * @module ui/page/admin/user/bulk/messages
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { frozen } from "util/object";

export default frozen({
  ok: { text: `User Added to SmartEdge. A verification code has been sent to the provided email and cell phone.\r\nWould you like to edit this user or add another?`, type: "ok" },
  invalid: { text: "Please correct the above errors.", type: "error" },
  permissionError: { text: "You do not have permission to create a user.", type: "error" },
  csvParseSuccess: { text: "Completed CSV upload.", type: "ok", temporary: true },
});
