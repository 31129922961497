/**
 * Wrapper for dashboard widgets, for dashboard-style admin pages.
 *
 * @module ui/component/dashboard-widget
 * @category UI
 * @subcategory Components - Dashboard
 */
import { div, h2 } from "ui/html";

export default function dashboardWidget(children, title, selector = "") {
  let childElements = [];
  if (title) childElements.push(div(".title-container", h2(title)));
  if (children instanceof Array) {
    childElements = childElements.concat(children);
  } else childElements.push(children);
  return div(`${selector}.dashboard-widget`, childElements);
}
