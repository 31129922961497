/**
 * Constraints specific to user models.
 *
 * @module model/user/constraints
 * @category Model
 * @subcategory User
 */
import { validMember } from "model/constraints";
import { userTypesSet } from "./constants";

/**
 * Ensures the value is one of the enumerated criteria in
 * {@link userTypesSet}
 *
 * @function validUserType
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not valid user type
 *
 * @return {string} the value
 */
// eslint-disable-next-line import/prefer-default-export
export const validUserType = (value, fieldName) => validMember(
  value,
  fieldName,
  userTypesSet,
);
