/**
 * Admin page for creating new dynamic pages.
 *
 * @module ui/page/admin/dynamic/messages
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 * @private
 */
/***/
import { frozen } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";

const { SUCCESS, FAIL } = notificationMessageTypes;

export default frozen({
  ok: { title: "Success", text: `Page Added to SmartEdge.`, type: SUCCESS },
  unknown: { title: "Error", text: "An unknown error occurred.", type: FAIL },
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: FAIL },
  accessDenied: { title: "Access Denied", text: "You do not have permission to create a page.", type: FAIL },
  backendErrors: { title: "Error", text: "A server error ocurred. Please report this to the operators.", type: FAIL },
  errorDeleting: {
    title: "Error",
    text: "The page could not be deleted due to an internal server error.",
    type: FAIL,
  },
  successDelete: {
    title: "Success",
    text: "The page was successfully deleted. Returning to manage pages...",
    type: SUCCESS,
  },
  accessSaveError: {
    title: "Access Rights Error",
    text: "Failed to update access rights.",
    type: notificationMessageTypes.FAIL,
  },
});
