/**
 * @module ui/page/admin/users/manage/manage-users-table
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import api from "api";
import log from "log";
import {
  button,
  div,
  section,
  span,
} from "ui/html";
import icon from "ui/component/icon";
import table from "ui/component/dynamic-table";
import { merge } from "util/object";
import { fullName, onSortChange } from "./common";

const onApprove = (user, tableView, modalView) => async () => {
  const toSave = merge(user, { enabled: true });
  try {
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === user.id) {
          return merge(user, { approving: true });
        }
        return u;
      }),
    });
    const newUser = await api.user.update(toSave);
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === newUser.id) {
          return merge(newUser, { approved: true });
        }
        return u;
      }),
    });
  } catch (err) {
    log.error(err);
    modalView.alert(
      `Error`,
      `User ${user.username} could not be approved at this time.`,
      "Ok",
      true,
    );
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === user.id) {
          return user;
        }
        return u;
      }),
    });
  }
};

const onDelete = (user, tableView, modalView) => async () => {
  if (!(await modalView.confirm(
    `Are you sure you want to delete ${user.username}?`,
    `This action cannot be undone.`,
    "Yes",
    "No",
    true,
  ))) {
    return;
  }
  try {
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === user.id) {
          return merge(user, { deleting: true });
        }
        return u;
      }),
    });
    await api.user.deleteById(user.id);
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === user.id) {
          return merge(user, { deleted: true });
        }
        return u;
      }),
    });
  } catch (err) {
    log.error(err);
    modalView.alert(
      `Error`,
      `User ${user.username} could not be deleted at this time.`,
      "Ok",
      true,
    );
    tableView.update({
      users: tableView.state.users.map((u) => {
        if (u.id === user.id) {
          return user;
        }
        return u;
      }),
    });
  }
};

const approval = (user, tableView, modalView) => {
  if (user.approving) {
    return span([icon("circle-notch", ".spinner"), "Approving"], ".primary");
  }
  if (user.approved) {
    return span([icon("check-circle"), "Approved"], ".secondary");
  }
  if (user.deleting) {
    return span([icon("circle-notch", ".spinner"), "Deleting"], ".accent");
  }
  if (user.deleted) {
    return span([icon("times-circle"), "Deleted"], ".warning");
  }
  return span([
    button(
      [icon.solid("user-check"), "Approve"],
      onApprove(user, tableView, modalView),
      ".subtle.ok",
    ),
    button(
      [icon("user-times"), "Delete"],
      onDelete(user, tableView, modalView),
      ".subtle.danger",
    ),
  ]);
};

/**
 * Sets up a user's row data for the table.
 * @function tableRow
 * @private
 */
const tableRow = (tableView, modalView) => (user) => {
  const row = ({
    username: user.username,
    fullName: fullName(user),
    cellPhone: user.cellPhone === null ? '—' : user.cellPhone,
    email: user.email === null ? '—' : user.email,
    approval: approval(user, tableView, modalView),
  });
  return row;
};

export default function approveUsersTable(tableView, modalView) {
  if (tableView.state.unapprovedUsers?.length === 0) {
    return section("#approve-users", div(".empty", "No users to approve at this time."));
  }
  return section("#approve-users", table({
    columnsLabels: [
      "User Name",
      "Full Name",
      "Mobile Number",
      "Email Address",
      "Controls",
    ],
    sortColumn: tableView.state.sortColumn,
    sortOrder: tableView.state.sortOrder,
    rows: tableView.state.unapprovedUsers.map(tableRow(tableView, modalView)),
    rowSelectors: tableView.state.unapprovedUsers.map((u) => {
      if (u.approved) return ".approved";
      if (u.deleted) return ".deleted";
      return undefined;
    }),
    onSortChange: onSortChange(tableView),
  }));
}
