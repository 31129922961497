import log from "log";
import { subDays } from "date-fns";
import { endpoints, services } from "api/constants";
import { post } from "api/request";
import {
  makeAnalyticsRequestDTO,
} from "model/analytics";

/**
 * Get MBE statistics for the given date range.
 *
 * @function getMBEStatusForPeriod
 * @param {AnalyticsRequest} partial
 * @param {Date} partial.startDate
 * @param {Date} partial.endDate
 * @returns {Promise<AnalyticsEntry[]>}
 */
export const getMBEStatusForPeriod = async (partial) => {
  try {
    const response = (await post(
      endpoints.ANALYTICS_MBE_STATUS,
      null,
      makeAnalyticsRequestDTO(partial),
      false,
      services.RABE,
    )).body;
    return response;
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get daily MBE status report.
 */
export const getMBEStatusDaily = async () => getMBEStatusForPeriod({
  startDate: subDays(new Date(), 1),
  endDate: new Date(),
});

/**
 * Get weekly MBE status report.
 */
export const getMBEStatusWeekly = async () => getMBEStatusForPeriod({
  startDate: subDays(new Date(), 7),
  endDate: new Date(),
});
