/**
 * A modal for previewing courses.
 *
 * @module ui/component/modal/course/course-preview
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import { merge } from "util/object";
import { div, h3 } from "ui/html";
import { metadataTypes } from "model/metadata/constants";
import button from "ui/component/form-managed/button";
import cameoModule from "ui/component/cameo-module";
import { isSingleLecture } from "model/course";
import { lectureUrl } from "util/course";
import { getModal } from "ui/view/modal-view";
import modulePreviewModal from "./module-preview";
import { parseDescription, poster } from "./common";

const defaultState = {
  sel: "",
  expand: false,
};

const openFirstModule = (metadata) => {
  const { modules } = metadata;
  const firstModule = modules?.[0];
  if (firstModule) {
    window.location.replace(lectureUrl(metadata.id, firstModule.id, firstModule.lectureIds?.[0]));
  }
};

const description = (state) => {
  const { expand, metadata } = state;

  return div(
    `.description${expand ? ".expanded" : ""}`,
    parseDescription(
      metadata.description,
      // eslint-disable-next-line no-use-before-define
      (expanded) => getModal().patch(coursePreviewModal({ ...state, expand: expanded })),
    ),
  );
};

const buildView = (state) => {
  const { metadata } = state;
  return [
    div(".pane.poster-pane", [
      poster(),
    ]),
    div(".pane.info-pane", [
      div(".info-content", [
        h3(metadata.title, ".title", { props: { title: metadata.title } }),
        description(state),
      ]),
      div('.info-footer', [
        button.primary({
          label: "Open",
          disabled: !metadata.modules?.length,
          onClick: () => openFirstModule(metadata),
        }),
      ]),
    ]),
    div(".pane.list-pane", [
      h3("Modules", ".title", { props: { title: "Modules" } }),
      div(".items", [
        ...metadata.modules.map((module) => cameoModule({
          module,
          course: metadata,
          controls: [
            button.icon({
              icon: "eye",
              onClick: async () => {
                if (isSingleLecture(module)) {
                  window.location.replace(
                    lectureUrl(metadata.id, module.id, module.lectureIds?.[0]),
                  );
                  return;
                }
                getModal().async(modulePreviewModal({
                  module,
                }));
              },
            }),
          ],
        })),
      ]),
    ]),
  ];
};

export default function coursePreviewModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  const { metadata, sel } = state;
  return dialog({
    sel: `${sel}.preview-content.content.with-action`,
    body: buildView(state),
    config: {
      dataset: {
        metaId: metadata.id,
        metaType: metadataTypes.COURSE,
      },
    },
  }, getModal());
}
