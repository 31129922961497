/**
 * Utility functions for help modal dialogs.
 *
 * @module ui/component/modal/help/utils
 * @category UI
 * @subcategory Modal Dialogs
 */
import cache from "cache";

/**
 * Help modal types
 *
 * @enum {string} modalTypes
 * @readonly
 * @property {string} ADMIN_ADD_CONTENT
 * @property {string} ADMIN_ADD_CONTENT_BULK
 * @property {string} ADMIN_ADD_NEW_PAGE
 * @property {string} ADMIN_ADD_NEW_PAGE_SECTION
 * @property {string} ADMIN_ADD_USER_BULK
 * @property {string} ADMIN_EDIT_MENU
 * @property {string} ADMIN_MANAGE_PAGES
 * @property {string} ADMIN_STATS
 * @property {string} ADMIN_FTP
 * @property {string} EVALUATION_ADVANCED_SEARCH
 * @property {string} PWA_IOS
 * @property {string} PWA_ANDROID
 * @property {string} HOMEPAGE
 * @property {string} SEARCH
 * @property {string} QUESTION_TEXT_LENGTH
 * @property {string} QUESTION_GRADING
 */
export const modalTypes = Object.freeze({
  ADMIN_ADD_CONTENT: "helpAdminAddContent",
  ADMIN_ADD_CONTENT_BULK: "helpAdminAddContentBulk",
  ADMIN_ADD_NEW_PAGE: "helpAdminAddNewPage",
  ADMIN_ADD_NEW_PAGE_SECTION: "helpAdminAddNewPageSection",
  ADMIN_ADD_USER_BULK: "helpAdminAddUserBulk",
  ADMIN_EDIT_MENU: "helpAdminEditMenu",
  ADMIN_FTP: "helpAdminFtp",
  ADMIN_MANAGE_PAGES: "helpAdminManagePages",
  ADMIN_STATS: "helpAdminStats",
  ADMIN_SYSTEM_PRIVELEGE: "helpSystemPrivelege",
  COURSE_WELCOME: "helpCourseWelcome",
  PWA_ANDROID: "helpPWAAndroid",
  PWA_IOS: "helpPWAIOS",
  EVALUATION_ADVANCED_SEARCH: "helpEvaluationAdvancedSearch",
  HOMEPAGE: "helpHomepage",
  QUESTION_GRADING: "helpQuestionGrading",
  QUESTION_TEXT_LENGTH: "helpQuestionTextLength",
  SEARCH: "helpSearch",
});

/**
 * Marks help modal as seen before
 *
 * @param {modalTypes} modalType
 */
export const markModalAsSeen = (modalType) => {
  cache.storeObject(modalType, true);
};

/**
 * Returns boolean whether help modal was seen before
 *
 * @param {modalTypes} modalType
 * @returns {boolean}
 */
export const isHelpModalSeen = (modalType) => !!cache.getObject(modalType);
