/**
 * @module ui/component/header/menu-v2
 * @private
 * @category UI
 * @subcategory Components
 */
/***/
import log from "log";
import { menuEntryTypes } from "model/site/menu/constants";
import { userInGroupName } from "model/user";
import { userTypes } from "model/user/constants";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import dialog from "ui/component/modal/layout/dialog";
import a from "ui/html/a";
import div from "ui/html/div";
import hr from "ui/html/hr";
import nav from "ui/html/nav";
import { featureEnabled } from "util/feature-flag";
import { getModal } from "ui/view/modal-view";
import { isAnonymous } from "util/user";
import * as staticMenus from "./static-menus";

const selectorEventHandler = (label, confirmLink, cancelLink) => {
  const modalView = getModal();
  if (modalView) {
    modalView.async(dialog({
      sel: ".menu-confirm.confirm",
      header: label,
      footer: [
        a("Individual users", confirmLink, ".button.primary"),
        a("User groups", cancelLink, ".button.inverse"),
      ],
    }, modalView)).catch((e) => log.error(e));
  }
};

window.addEventListener("em:add-user-or-group-selector", () => {
  selectorEventHandler(
    "Who do you want to add?",
    "/admin/add-user",
    "/admin/add-group",
  );
});

window.addEventListener("em:manage-user-or-group-selector", () => {
  selectorEventHandler(
    "What do you want to manage?",
    "/admin/manage-users",
    "/admin/manage-groups",
  );
});

window.addEventListener("em:bulk-user-import-selector", () => {
  selectorEventHandler(
    "What would you like to Mass Create?",
    "/admin/mass-user-create",
    "/admin/mass-user-group-create",
  );
});

const filterEntry = (user) => (entry) => {
  const { privileges, accountRequired, features } = entry;
  if (features) {
    /* eslint-disable-next-line no-restricted-syntax */
    for (const feature of features) {
      if (!featureEnabled(feature)) {
        return false;
      }
    }
  }
  if (user?.userType === userTypes.ROOT) return true;
  if ((!user && accountRequired) || isAnonymous(user)) return false;
  if (privileges) {
    if (!privileges.find((priv) => userInGroupName(user, priv))) return false;
  }
  return true;
};

const buildItem = (item, user, menuLevel = 0) => {
  let entryType = item.type;
  let itemIcon = item.icon;
  const {
    label, slug, entries, cb, linkType, url,
  } = item;
  if (linkType) {
    entryType = linkType;
  }
  if (!itemIcon) {
    itemIcon = "bars";
  }
  switch (entryType) {
    case menuEntryTypes.STATIC:
    case menuEntryTypes.LINK_PAGE:
      return div(".item.static", [
        a([icon.sharp(itemIcon), label], slug),
      ]);
    case menuEntryTypes.LINK_EXTERNAL:
      return div(".item.static", [
        a([icon.sharp("external-link"), label], url),
      ]);
    case menuEntryTypes.FUNCTION:
      return div(".item.function", [
        a.fn([icon.sharp(itemIcon), label], cb),
      ]);
    case menuEntryTypes.ENTRY:
      return collapsible(
        {
          title: [icon.sharp(itemIcon), label],
          label,
          autoCloseConfiguration: {
            autoCloseSameLevel: true,
            level: menuLevel,
            id: "dashboard-menu",
          },
        },
        entries.filter(filterEntry(user)).map((entry) => buildItem(entry, user, menuLevel + 1)),
        ".item.collapsible",
      );
    default:
      return "";
  }
};

const buildEntry = (entry, user) => div(".entry", [
  ...entry.entries.filter(filterEntry(user)).map((item) => buildItem(item, user)),
  hr(),
]);

const menuV2 = (entries = [], user = null) => nav(
  "#main-menu-v2",
  div(".main-menu-container", entries.filter(filterEntry(user)).map((entry) => buildEntry(entry, user))),
);

/**
 * Creates the menu for the admin site.
 *
 * @function admin
 * @param {User} user
 * @return {El} `nav#main-menu-v2`
 */
menuV2.admin = (user) => menuV2([
  staticMenus.siteAdminGeneral,
  staticMenus.siteAdminSocial,
  staticMenus.siteAdminUsers,
  staticMenus.siteAdminContent,
  staticMenus.siteAdminCourse,
  staticMenus.siteAdminSite,
], user);

menuV2.dynamic = (entries = [], user = null) => {
  const mappedEntries = entries.map((entry) => ({
    accountRequired: !!entry?.accountRequired,
    features: entry?.features || [],
    entries: [entry],
  }));
  return menuV2(mappedEntries, user);
};

export default menuV2;
