/**
 * @module ui/page/admin/users/manage/common
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
import { sortOrders } from "ui/component/dynamic-table";

/**
 * Makes the full name column for a user.
 * @function fullName
 * @private
 */
export const fullName = (user) => {
  const { lastName, firstName } = user;
  const firstNameValidated = firstName === null ? '' : firstName;
  const lastNameValidated = lastName === null ? '' : lastName;
  if (!firstNameValidated && !lastNameValidated) return '—';
  return `${firstNameValidated} ${lastNameValidated}`;
};

/**
 * Handles changing sort order.
 * @function onSortChange
 * @private
 */
export const onSortChange = (theView) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === theView.state.sortColumn) {
    if (theView.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  theView.update({
    sortOrder,
    sortColumn,
  });
};
