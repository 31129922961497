/**
 * A cameo for metadata items.
 *
 * @module ui/component/cameo-metadata
 * @category UI
 * @subcategory Components
 */
import {
  button,
  span,
} from "ui/html";
import cameo from "ui/component/cameo";
import contentPreview from "ui/component/modal/content";
import icon from "ui/component/icon";
import metadataIcon from "ui/component/metadata-icon";
import { getModal } from "ui/view/modal-view";

const viewItem = async (metadata) => getModal().async(
  contentPreview({ metadata, sameTab: false }, getModal()),
);

/**
 * A summary display of a metadata item which may present a preview
 * button and optionally other controls.
 *
 * If the `modalDialog` parameter is supplied the preview button will be
 * generated and managed automatically by the cameo.
 *
 * @function cameoMetadata
 * @param {object} state
 * @param {ListMetadata} state.metadata
 * @param {?ChildEl} state.controls additional buttons
 * @param {?string} state.explicitMetadataType explicit icon to set by type
 *                  (TODO: remove it when we have other metadata types (e.g. image)
 * @param {object} state.config additional element configs
 */
export default function cameoMetadata({
  metadata,
  controls = null,
  explicitMetadataType = undefined,
  config = {},
}) {
  return metadata ? cameo({
    label: metadata.title,
    icon: metadataIcon(explicitMetadataType || metadata.type),
    controls: [
      button(icon.solid("eye"), () => viewItem(metadata)),
      ...controls?.length
        ? controls
        : [],
    ],
    sel: ".cameo-metadata",
    config,
  }) : span(
    [icon("question-circle"), span("No Selection")],
    ".metadata.stand-in",
  );
}
