/**
 * Request types by method.
 *
 * These functions are used as the basis for individual API calls. Rely on the
 * documented parameters and return types rather than the source code. There's
 * some elaborate bootstrapping going on under the hood to put an API request
 * object into the request queue and then hand back a promised response.
 *
 * @module api/requests
 * @category Backend API
 * @subcategory Core
 */
import { makeAPIRequest } from "model/api";
import { contentTypes } from "model/api/constants";
import { enqueue } from "./queue";

/**
 * Enqueue a GET request.
 *
 * Note that defaultResponse is a hack to overcome issues with backend responding
 * 404 on an endpoint where it's OK to have no results (e.g. search results,
 * video playback position).
 *
 * @function get
 * @param {string} endpoint {@link module:api/constants.endpoints}
 * @param {(Object|null)} [params=null] URL params as an object of { param: value }
 * @param {boolean} [sequential=false] whether the request is sequential
 * @param {boolean} [authenticate=true] whether the request must be authenticated
 * @param {object} [defaultResponse] a default value for 404s, for endpoints that may 404
 * @param {services} service a service that should be requested
 *
 * @return {APIResponse}
 */
export const get = async (
  endpoint,
  params = null,
  sequential = false,
  authenticate = true,
  defaultResponse = null,
  service = undefined,
) => enqueue(
  makeAPIRequest({
    endpoint,
    params,
    method: "GET",
    authenticate,
    defaultResponse,
    service,
  }),
  sequential,
);

get.service = (service) => (
  endpoint, params, sequential, authenticate, defaultResponse,
) => get(endpoint, params, sequential, authenticate, defaultResponse, service);

/**
 * Enqueue a POST request.
 *
 * @function post
 * @param {string} endpoint {@link module:api/constants.endpoints}
 * @param {(Object|null)} [params=null] URL params as an object of { param: value }
 * @param {(Object|null)} [body=null] request body as an object of { param: value }
 * @param {boolean} [sequential=false] whether the request is sequential
 * @param {services} service a service that should be requested
 *
 * @return {APIResponse}
 */
export const post = async (
  endpoint,
  params = null,
  body = null,
  sequential = false,
  service = undefined,
) => enqueue(
  makeAPIRequest({
    endpoint,
    params,
    body,
    method: "POST",
    service,
  }),
  sequential,
);

/**
 * Do a POST request, but with `multipart/form-data` content type. Mainly for
 * uploading files.
 *
 * @function postMultipart
 * @param {string} endpoint {@link module:api/constants.endpoints}
 * @param {(Object|null)} [params=null] URL params as an object of { param: value }
 * @param {(Object|null)} [body=null] request body as an object of { param: value }
 * @param {boolean} [sequential=false] whether the request is sequential
 * @param {services} service a service that should be requested
 *
 * @return {APIResponse}
 */
export const postMultipart = async (
  endpoint,
  params = null,
  body = {},
  sequential = false,
  service = undefined,
) => enqueue(
  makeAPIRequest({
    endpoint,
    params,
    body,
    method: "POST",
    contentType: contentTypes.FORM_DATA,
    service,
  }),
  sequential,
);

/**
 * Do a PUT request.
 *
 * @function put
 * @param {string} endpoint {@link module:api/constants.endpoints}
 * @param {(Object|null)} [params=null] URL params as an object of { param: value }
 * @param {(Object|null)} [body=null] request body as an object of { param: value }
 * @param {boolean} [sequential=false] whether the request is sequential
 * @param {services} service a service that should be requested
 *
 * @return {APIResponse}
 */
export const put = async (
  endpoint,
  params = null,
  body = null,
  sequential = false,
  service = undefined,
) => enqueue(
  makeAPIRequest({
    endpoint,
    params,
    body,
    method: "PUT",
    service,
  }),
  sequential,
);

/**
 * Do a DELETE request.
 *
 * @function del
 * @param {string} endpoint {@link module:api/constants.endpoints}
 * @param {(Object|null)} [params=null] URL params as an object of { param: value }
 * @param {(Object|null)} [body=null] request body as an object of { param: value }
 * @param {boolean} [sequential=false] whether the request is sequential
 * @param {services} service a service that should be requested
 *
 * @return {APIResponse}
 */
export const del = async (
  endpoint,
  params = null,
  body = null,
  sequential = false,
  service = undefined,
) => enqueue(
  makeAPIRequest({
    endpoint,
    params,
    body,
    method: "DELETE",
    service,
  }),
  sequential,
);
