/**
 * A set of carousels for a collection of list metadata.
 *
 * @module ui/component/card-carousels
 * @category UI
 * @subcategory Components
 */
import { metadataListTypes } from "model/metadata/constants";
import carousel from "ui/component/card-carousel";
import { section } from "ui/html";

const findListItems = (list) => list.filter(
  (entry) => entry.list.type && metadataListTypes.has(entry.list.type),
);

const findContentItems = (list) => list.filter(
  (entry) => entry.list.type && !metadataListTypes.has(entry.list.type),
);

/**
 * @typedef ListStateEntry
 * @property {Array<module:api/types~MetadataListItem>} list
 * @property {Number} position

/**
 *
 * @function contentCard
 * @param {Object} state
 * @param {Array<ListStateEntry>} state.lists
 * @return {module:ui/common/el~El}
 */
export default (state) => {
  if (!state) return section(".card-carousels");
  const looseItems = findContentItems(state.lists);
  return section(".card-carousels", [
    ...findListItems(state.lists)
      .filter(({ list }) => list?.items?.length)
      .map(carousel),
    looseItems.length ? carousel({
      list: {
        items: looseItems,
        title: "Miscellaneous",
      },
      position: 0,
    }) : "",
  ]);
};
