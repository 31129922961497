/**
 * Default state and state constants/enums for login views.
 *
 * @module ui/page/login/state
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import { frozen } from "util/object";
import { defaultValidity } from "ui/common/validation-constraints";

/**
 * States the login form can be in.
 *
 * @readonly
 * @enum {string}
 */
export const MODES = frozen({
  login: "login",
  register: "register",
  passwordResetUsername: "passwordResetUsername",
  passwordReset: "passwordReset",
  messageOnly: "messageOnly",
  verify: "verify",
});

export const defaultState = frozen({
  mode: MODES.login,
  redirect: "/",
  remember: false,
  cleartextPassword: false,
  cleartextPasswordConfirm: false,
  messages: [],
  validation: defaultValidity,
  login: {}, // state of login form
  verification: {}, // state of verification form
  user: {}, // state of registration form
  takenPhones: [], // acccumulates failed phone numbers during registration
  takenEmails: [], // acccumulates failed email addresses during registration
  takenNames: [], // accumulates failed usernames during registration
});
