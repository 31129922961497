/**
 * Constants related to ACL models.
 *
 * For consistency's sake, we use some universal terms to refer to ACL relationships:
 *
 * - principal: a user or group who has a grant
 * - subject: an object on which a `right` can be granted
 * - right: a privilege granted to a `principal`, in regard to a `subject`
 * - grant: a collection of `rights` given to one `principal` on one `subject`
 *
 * So in other words, a _principal_ has one or more _rights_ in regard to a
 * _subject_. The collection of principal, subject, and rights is a _grant_.
 *
 * @module model/acl/constants
 * @category Model
 * @subcategory ACL
 */
import { frozen } from "util/object";

/**
 * User access rights. Determines what rights a user is allowed to perform.
 *
 *
 * @enum {string} aclRights
 * @readonly
 * @property {string} READ permission to read
 * @property {string} WRITE permission to write
 * @property {string} DELETE permission to delete
 * @property {string} OWNER owner permissions
 */
export const aclRights = frozen({
  READ: "READ",
  WRITE: "WRITE",
  DELETE: "DELETE",
  OWNER: "OWNER",
});

/**
 * Set of user access rights strings accepted by the backend API,
 * as [aclRights]{@link aclRights}
 *
 * @constant aclRightsSet
 * @type {Set.<string>}
 * @readonly
 */
export const aclRightsSet = frozen(
  new Set(Object.values(aclRights)),
);

/**
 * Types of ACL objects to which privileges can be granted.
 *
 * @enum {string} aclPrincipalTypes
 * @readonly
 * @property {string} INDIVIDUAL user himself
 * @property {string} GROUP group
 */
export const aclPrincipalTypes = frozen({
  INDIVIDUAL: "INDIVIDUAL",
  GROUP: "GROUP",
});

/**
 * Set of ACL principal type strings accepted by the backend API,
 * as [aclPrincipalTypes]{@link aclPrincipalTypes}
 *
 * @constant aclPrincipalTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const aclPrincipalTypesSet = frozen(
  new Set(Object.values(aclPrincipalTypes)),
);

/**
 * Types of objects for which privileges can be granted.
 *
 * @enum {string} aclSubjectTypes
 * @readonly
 * @property {string} COURSE
 * @property {string} FILE
 * @property {string} MEDIA
 * @property {string} PAGE
 */
export const aclSubjectTypes = frozen({
  COURSE: "course",
  FILE: "file",
  MEDIA: "media",
  PAGE: "page",
  CONVERSATION: "conversation",
});

/**
 * Set of ACL subject type strings accepted by the backend API,
 * as [aclPrincipalTypes]{@link aclPrincipalTypes}
 *
 * @constant aclPrincipalTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const aclSubjectTypesSet = frozen(
  new Set(Object.values(aclSubjectTypes)),
);

export const aclSubjectTypesFriendly = frozen(new Map([
  [aclSubjectTypes.COURSE, "Course"],
  [aclSubjectTypes.FILE, "File"],
  [aclSubjectTypes.MEDIA, "Media"],
  [aclSubjectTypes.PAGE, "Page"],
]));

/**
 * Sort criteria applicable to all groups. Used in searching, filtering, and
 * table column sorting.
 *
 * @enum {string} groupSortCriteria
 * @readonly
 * @property {string} USER_ID_ASC
 * @property {string} USER_ID_DESC
 * @property {string} USERNAME_ASC
 * @property {string} USERNAME_DESC
 * @property {string} COUNT_ASC
 * @property {string} COUNT_DESC
 */
export const groupSortCriteria = frozen({
  ID_ASC: 'BY_ID_ASC',
  ID_DESC: 'BY_ID_DESC',
  NAME_ASC: 'BY_NAME_ASC',
  NAME_DESC: 'BY_NAME_DESC',
  COUNT_ASC: 'BY_COUNT_ASC',
  COUNT_DESC: 'BY_COUNT_DESC',
});

/**
 * Special groups that cannot be modified by users (even admins). These
 * grant system-level rights, and are identified by their name.
 *
 * @enum string systemPrivilegeGroups
 * @readonly
 * @property {string} USER_MANAGEMENT
 * @property {string} FILE_MANAGEMENT
 * @property {string} MEDIA_MANAGEMENT
 * @property {string} ADMIN
 */
export const systemPrivilegeGroups = frozen({
  USER_MANAGEMENT: "USER_MANAGEMENT",
  FILE_MANAGEMENT: "FILE_MANAGEMENT",
  MEDIA_MANAGEMENT: "MEDIA_MANAGEMENT",
  ADMIN: "ADMIN",
  ADMIN_MESSAGING: "ADMIN_MESSAGING",
  PUBLIC: "PUBLIC_READ",
  USER_MESSAGING: "PUBLIC_MESSAGING",
});

/**
 * Special groups that grant management privileges.
 *
 * @constant systemManagementGroupsSet
 * @type {Set.<string>}
 * @readonly
 */
export const systemManagementGroupsSet = frozen(new Set([
  systemPrivilegeGroups.ADMIN,
  systemPrivilegeGroups.ADMIN_MESSAGING,
  systemPrivilegeGroups.MEDIA_MANAGEMENT,
  systemPrivilegeGroups.USER_MANAGEMENT,
  systemPrivilegeGroups.FILE_MANAGEMENT,
]));

/**
 * The set of all special system privilege groups.
 *
 * @constant systemPrivilegeGroupsSet
 * @type {Set.<string>}
 * @readonly
 */
export const systemPrivilegeGroupsSet = frozen(
  new Set(Object.values(systemPrivilegeGroups)),
);

/**
 * User-friendly labels for system privilege groups.
 *
 * @constant systemPrivilegeGroupsFriendly
 * @type {Map.<SystemPrivilegeGroup, string>}
 */
export const systemPrivilegeGroupsFriendly = frozen(new Map([
  [systemPrivilegeGroups.USER_MANAGEMENT, "User Managers"],
  [systemPrivilegeGroups.FILE_MANAGEMENT, "File Creation Managers"],
  [systemPrivilegeGroups.MEDIA_MANAGEMENT, "Content Managers"],
  [systemPrivilegeGroups.ADMIN, "Administrators"],
  [systemPrivilegeGroups.ADMIN_MESSAGING, "Moderators"],
  [systemPrivilegeGroups.USER_MESSAGING, "Chat Enabled"],
  [systemPrivilegeGroups.PUBLIC, "Registered Users"],
]));

/**
 * User friendly descriptions of the authorities granted by system privilege groups.
 *
 * Used in modal confirm dialogs.
 * @constant systemPrivilegeNames
 * @type {Map.<SystemPrivilegeGroup, string>}
 */
export const systemPrivilegeNames = frozen(new Map([
  [systemPrivilegeGroups.USER_MANAGEMENT, "user management"],
  [systemPrivilegeGroups.FILE_MANAGEMENT, "file management"],
  [systemPrivilegeGroups.MEDIA_MANAGEMENT, "media management"],
  [systemPrivilegeGroups.ADMIN, "site administration"],
  [systemPrivilegeGroups.ADMIN_MESSAGING, "chat moderation"],
  [systemPrivilegeGroups.USER_MESSAGING, "chat access"],
  [systemPrivilegeGroups.PUBLIC, "view public content"],
]));

/**
 * Privilege groups excluded from ACL interfaces (handled by some other UI element).
 */
export const excludedPrivilegeGroups = frozen(new Set([
  systemPrivilegeGroups.USER_MESSAGING,
]));

export const defaultGrant = frozen({
  principal: null,
  principals: [],
  subject: null,
  rights: new Set(),
  type: null,
});
