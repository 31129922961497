import collapsible from "ui/component/collapsible";
import { fileList } from "api/mocks/file";
import filePicker, { filePickerModes } from "ui/component/form-managed/file-picker";
import h4 from "ui/html/h4";
import { descriptorList } from "../mocks";

export default function filePickerExamples(self) {
  const required = true;

  return collapsible({
    title: "File Picker",
    open: self.state.filePickerOpen,
    onChange: (filePickerOpen) => self.updateState({ filePickerOpen }),
  }, self.bind([
    h4("Basic Picker"),
    [filePicker, {
      name: "file-picker-basic",
      fileDescriptors: descriptorList,
      uploadFileNames: fileList,
      required,
    }],
    h4("With Previous Attachment"),
    [filePicker, {
      name: "file-picker-keep",
      fileDescriptors: descriptorList,
      uploadFileNames: fileList,
      required,
    }],
    h4("Video Picker"),
    [filePicker.video, {
      name: "file-picker-video",
      fileDescriptors: descriptorList,
      uploadFileNames: fileList,
      required,
    }],
  ], { "file-picker-keep": { mode: filePickerModes.KEEP, current: descriptorList[2] } }),
  );
}
