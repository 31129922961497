/**
 * A toggle-switch meant to be used in place of a checkbox element.
 *
 * @module ui/component/form-managed/field-toggle
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import labelEl from "ui/html/label";
import span from "ui/html/span";
import { managedField } from "ui/view/form-managed/util";
import { emit, onSpaceKey } from "util/event";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "This element must be toggled on to proceed.",
  invalid: "This element must be toggled on to proceed.",
});

const requiredHook = (required) => (node, validity) => {
  if (!required) return;
  if (!node.elm.classList.contains("on")) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

const getValue = (node) => node.elm.classList.contains("on");

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "off",
  boxed: false,
  toggled: undefined,
  disabled: false,
  helpTexts,
  label: "Toggle",
  name: "toggle",
  required: false,
  override: false,
  sel: "",
});

/**
 * A toggle-styled wrapper for a checkbox.
 *
 * The state of the element is stored in a hidden checkbox. The display element provides
 * visual feedback and is controlled by the `on` class.
 *
 * See also the shortcut methods for setting up styled toggles, which are a little
 * easier than filling in correct configurations:
 *
 * - toggle.boxed
 * - toggle.boxed.inverse
 * - toggle.boxed.secondary
 * - toggle.boxed.alternate
 * - toggle.boxed.warning
 * - toggle.boxed.danger
 * - toggle.boxed.disabled
 *
 * @function toggle
 * @param {object} state
 * @param {string} [state.autocomplete="off"] autocomplete type
 * @param {string} [state.boxed=false] if true, will be presented like a button
 * @param {boolean} [state.toggled=false] whether the toggle should be in on or off state
 * @param {boolean} [state.disabled=false]
 * @param {string} [state.label="Toggle"]
 * @param {FieldValidity} [state.validity=defaultValidity]
 * @param {string} [state.name="toggle"]
 * @param {boolean} [state.required=false] if true form will be considered invalid until
 *        the toggle is turned on
 * @param {string} [state.override=""] override field value with given state.value
 * @param {Selector} [state.sel=""]
 * @param {function} [state.onToggle] called with toggled state when toggle state changes
 * @return {module:ui/common/el~El}
 */
export default function toggle(inState = {}) {
  const state = merge.all([
    defaultState,
    inState,
    { displayLabel: inState.displayLabel ?? true },
  ]);

  if (!state.override && state.values?.[state?.name]) state.toggled = state.values[state.name];
  const display = managedField(
    span('', `.switch${state.toggled ? ".on" : ""}`, { class: { on: state.toggled } }),
    state.name,
    getValue,
    [requiredHook(state.required)],
  );

  const label = state.displayLabel ? labelEl(
    `${state.label}`,
    state.name,
    null,
    { class: { required: state.required } },
  ) : "";

  const makeSel = () => {
    let sel = "";
    if (state.sel) sel += state.sel;
    if (state.disabled) sel += ".disabled";
    if (state.required) sel += ".required";
    if (state.boxed) sel += ".boxed";
    sel += ".inner";
    return sel;
  };

  const inner = span(
    [label, display],
    makeSel(),
    {
      class: {
        on: state.toggled,
        disabled: state.disabled,
      },
      attrs: {
        tabindex: state.disabled ? "-1" : "0",
      },
      on: {
        click: state.disabled ? undefined : () => {
          const { elm } = display;
          const currentlyToggled = elm.classList.contains("on");
          if (currentlyToggled) {
            if (!state.disabledAutoToggle) elm.classList.remove("on");
            if (state.onToggle) state.onToggle(false);
          } else {
            if (!state.disabledAutoToggle) elm.classList.add("on");
            if (state.onToggle) state.onToggle(true);
          }
          elm.dispatchEvent(
            new Event("em:form-managed-change", { bubbles: true }),
          );
        },
        keydown: onSpaceKey((ev) => { ev.preventDefault(); emit(ev.target, "click"); }),
      },
      hook: {
        postpatch: () => {
          inner.elm.title = `${state.label} ${display.elm.classList.contains("on") ? "on" : "off"}`;
        },
      },
    },
  );

  return genericField(
    state,
    inner,
    `.toggle${state.sel}`,
  );
}

/**
 * A shortcut method to set up a boxed toggle.
 */
toggle.boxed = (inState) => toggle(merge(inState, { boxed: true }));

/**
 * A shortcut method to set up a secondary boxed toggle.
 */
toggle.boxed.secondary = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.secondary` }),
);

/**
 * A shortcut method to set up a secondary boxed toggle.
 */
toggle.boxed.alternate = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.alternate` }),
);

/**
 * A shortcut method to set up an inverted boxed toggle.
 */
toggle.boxed.inverse = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.inverse` }),
);

/**
 * A shortcut method to set up a dangerous boxed toggle.
 */
toggle.boxed.danger = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.danger` }),
);

/**
 * A shortcut method to set up a warning boxed toggle.
 */
toggle.boxed.warning = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.warn` }),
);

/**
 * A shortcut method to set up a disabled boxed toggle.
 */
toggle.boxed.disabled = (inState) => toggle.boxed(
  merge(inState, { sel: `${inState.sel || ""}.inverse`, disabled: true }),
);
