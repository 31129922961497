/**
 * A modal component for reporting messages.
 *
 * @module ui/component/chat/report-message
 * @category UI
 * @subcategory Chat
 */
import userChatReport from "ui/component/modal/chat/user-report";
import { merge } from "util/object";

const defaultState = ({
  message: null,
  title: "Report Message",
});

export default function reportMessage(inState) {
  const state = merge.all([defaultState, inState, { title: defaultState.title }]);
  return userChatReport(state);
}
