/**
 * Constants related to charts
 *
 * @module model/chart/constants
 * @category Model
 * @subcategory Chart
 */

/**
 * @typedef ChartData
 * @property {chartTypes} type
 * @property {ChartDataset} data
 */

/**
 * @typedef ChartDataset
 * @property {string[]} labels
 * @property {Dataset[]} datasets
 */

/**
 * @typedef Dataset
 * @property {string} label
 * @property {string[]} data
 * @property {?string} backgroundColor
 * @property {?string} borderColor
 * @property {?number} barPercentage
 * @property {?number} barThickness
 * @property {?number} maxBarThickness
 * @property {?number} minBarLength
 */

/**
 * Chart types
 *
 * @enum {string} chartTypes
 * @readonly
 * @property {string} BAR bar
 * @property {string} LINE line
 */
export const chartTypes = Object.freeze({
  BAR: "bar",
  LINE: "line",
});

/**
 * Set of chart type strings,
 * as [chartTypesSet]{@link chartTypes}
 *
 * @constant chartTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const chartTypesSet = Object.freeze(
  new Set(Object.values(chartTypes)),
);

/**
 * Default chart settings
 *
 * @enum {string} defaultChartSettings
 * @readonly
 * @property {string} primaryColor
 * @property {string} backgroundColor
 * @property {string} hoverColor
 * @property {number} legendWidth
 * @property {number} tooltipColoredBoxPadding
 */
export const defaultChartSettings = Object.freeze({
  primaryColor: "#12348e",
  backgroundColor: "#717dab",
  hoverColor: "#ed7450",
  legendWidth: 12,
  tooltipColoredBoxPadding: 6,
});
