/**
 * Unauthenticated UI layout component.
 *
 * @module ui/page/layout/unauthenticated
 * @category Layout
 * @subcategory Admin
 */
import headerComponent from "ui/component/header";
import { div, header } from "ui/html";
import el, { patch } from "ui/common/el";
import view from "ui/view";
import { setTitle } from "util/navigation";

/**
 * A layout for "unauthenticated" style pages.
 *
 * @function unauthenticatedLayout
 * @param {Selector} selector selects the containing app element
 * @param {ChildEl} elements
 * @param {string} title page title
 */
export default function unauthenticatedLayout(selector, elements = [], title = "") {
  const structure = div(`${selector}.page`, [
    header(),
    ...elements,
    div("#modal"),
    div("#loading"),
    div("#notifications"),
  ]);

  const container = el.fromDOM(document.querySelector(selector));

  patch(container, structure);

  const loadingView = view.loading("#loading");
  const modalView = view.modal(() => "")("#modal", {});
  const notificationView = view.notification("#notifications", {});

  setTitle(title);

  const headerView = view.create(headerComponent.login)("header");

  return {
    loading: loadingView,
    modal: modalView,
    header: headerView,
    notification: notificationView,
  };
}
