/**
 * Editor fields for playlist sections.
 *
 * @module ui/component/modal/section-edit/playlist
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import { metadataTypes } from "model/metadata/constants";
import {
  button,
  hr,
  label,
} from "ui/html";
import iconButton from "ui/component/icon-button";
import metadataSelectModal from "ui/component/modal/metadata-select";
import cameoMetadata from "ui/component/cameo-metadata";
import title from "ui/component/form-managed/field-title";
import input from "ui/component/form-managed/field-input";
import {
  cardFormatOptions,
  cardSizeOptionsLabeled,
  pageTypes,
} from "model/dynamic-page/constants";
import multiToggle from "ui/component/multi-toggle";

const selectPlaylist = async (self, modalView) => {
  const { metadata } = self.state;
  const entries = metadata.filter((m) => m.type === metadataTypes.LIST);
  const selected = await modalView.async(metadataSelectModal.async({
    entries,
  }, modalView), false);
  if (selected) {
    const sectionTitle = !self.state.section.item
      || (self.state.section.title === self.state.section.item.title)
      ? selected.title
      : self.state.section.title;
    /* eslint-disable-next-line no-param-reassign */
    self.element.querySelector("[name=title]").value = sectionTitle;

    self.update({
      section: {
        itemId: selected.id,
        item: selected,
        title: sectionTitle,
      },
      messages: [],
    });
  }
};

const cardSizeSelect = (formView) => multiToggle({
  label: "Card Format",
  onSelect: (option) => {
    formView.update({
      section: {
        searchData: { cardSize: option.value },
      },
    });
  },
  selectedTab: (formView.state.section.searchData.cardSize
    || cardFormatOptions.VERTICAL) === cardFormatOptions.VERTICAL
    ? 0 : 1,
  options: [
    {
      id: "card-size-small",
      label: cardSizeOptionsLabeled.get(cardFormatOptions.VERTICAL),
      name: "cardSize",
      value: cardFormatOptions.VERTICAL,
    },
    {
      id: "card-size-big",
      label: cardSizeOptionsLabeled.get(cardFormatOptions.HORIZONTAL),
      name: "cardSize",
      value: cardFormatOptions.HORIZONTAL,
    },
  ],
});

/**
 * @function playlistFields
 * @private
 *
 * @param self
 * @param {module:ui/view/modal~modalView} modalView
 * @return {module:ui/html~ChildEl[]}
 */
export default (self, modalView) => {
  const { section, metadata } = self.state;
  let currentMeta = section.item;
  if (!currentMeta && section.itemId) {
    currentMeta = metadata.find((m) => m.id === section.itemId);
  }
  return currentMeta
    ? self.bind([
      [title, { value: section.title }],
      [input, { value: section.text, label: "No entries text" }],
      section.type === pageTypes.PLAYLIST_CAROUSEL ? cardSizeSelect(self) : "",
      hr(),
      label("Playlist"),
      cameoMetadata({
        metadata: currentMeta,
        controls: [
          iconButton(
            "edit",
            "Change Metadata",
            () => selectPlaylist(self, modalView),
            ".primary",
          ),
        ],
      }),
      [input, { type: "hidden", name: "itemId", required: true, value: currentMeta.id }],
    ])
    : self.bind([
      [title, { value: section.title, required: true, disabled: true }],
      [input, { value: section.text, label: "No entries text" }],
      section.type === pageTypes.PLAYLIST_CAROUSEL ? cardSizeSelect(self) : "",
      hr(),
      label("Playlist", ".required"),
      button("Please Select Playlist", () => selectPlaylist(self, modalView), ".stand-in"),
    ]);
};
