/**
 * Utilities for courses and related modules.
 *
 * @module util/course
 * @category Utilities
 */
import deepequal from "deep-equal"; import { merge } from "util/object";
import { MAX_DATE, MIN_DATE } from "util/date";
import { aclPrincipalTypes, aclRights, systemPrivilegeGroups } from "model/acl/constants";
import { evaluationStatus } from "model/course/constants";

/**
 * Checks if two grading schemes are equivalent, ignoring manual/automatic.
 *
 * Used to compare stored grading schemes to the default list of schemes.
 *
 * @param {GradingScheme} a
 * @param {GradingScheme} b
 * @return boolean true if equilalent
 */
/* eslint-disable-next-line import/prefer-default-export */
export const gradingSchemesEquivalent = (a, b) => deepequal(
  merge(a, { automatic: null }),
  merge(b, { automatic: null }),
);

/**
 * Finds the date the earliest module in the course starts.
 *
 * @param {Course} course
 * @return {Date}
 */
export const courseStartDate = (course) => {
  const result = course.modules.reduce(
    (acc, cur) => (cur.startDate !== null && cur.startDate < acc
      ? cur.startDate
      : acc),
    new Date(MAX_DATE),
  );
  return new Date(result);
};

/**
 * Finds the date the latest module in the course ends.
 *
 * May return null if no modules have an end date.
 *
 * @param {Course} course
 * @return {?Date}
 */
export const courseEndDate = (course) => {
  const result = course.modules.reduce(
    (acc, cur) => {
      if (cur.endDate === null) return null;
      if (acc === null) return cur.endDate;
      if (cur.endDate > acc) return cur.endDate;
      return new Date(acc);
    },
    new Date(MIN_DATE),
  );
  if (result !== null) return new Date(result);
  return null;
};

/**
 * Counts the number of lectures in a course.
 *
 * @param {Course} course
 */
export const courseLectureCount = (course) => course.modules.reduce(
  (acc, cur) => acc + (cur.lectures?.length || 0),
  0,
);

/**
 * Counts the number of lectures in a course.
 *
 * @param {Course} course
 */
export const courseAssessmentCount = (course) => course.modules.reduce(
  (acc, cur) => acc + (cur.assessments?.length || 0),
  0,
);

export const collectCourseAssessments = (course) => course.modules.map(
  (m) => m.assessments,
).flat();

/**
 * Generates a URL to visit a lecture page directly.
 *
 * NOTE This is placed in util because it's used in several places and may change in the
 *      future. The interface may have to change too but at least we can search for it
 *      easily this way.
 *
 * @param {UUID} courseId
 * @param {UUID} moduleId
 * @param {UUID} lectureId
 * @return {string}
 */
export const lectureUrl = (courseId, moduleId, lectureId) => `/lecture?course=${courseId}&module=${moduleId}&id=${lectureId}&previousLocation=${window.location.pathname.substring(1)}`;

/**
 * Calculate a grade client-side.
 *
 * @param {GradingScheme} gradingScheme
 * @param {number} score
 * @param {number} possible
 * @return String
 */
export const calculateScore = (scheme, score, possible) => {
  const percent = (score / possible) * 100;
  // eslint-disable-next-line no-restricted-syntax
  for (const bracket of scheme.gradeBrackets) {
    if (percent >= bracket.pointsFrom && percent <= bracket.pointsTo) {
      return bracket.description;
    }
  }
  return "?";
};

/**
 * Combines analytics evaluation statuses with ACL assigned users
 *
 * @param {Assessment[]} assessments all course assessments
 * @param {ACLGrantEntry[]} courseGrants
 * @param {User[]} users
 * @param {AnalyticsEvaluation[]} analyticsEvaluations
 * @returns AnalyticsEvaluation[]
 */
export const getExtendedAnalyticsEvaluations = ({
  courseGrants,
  users,
  analyticsEvaluations,
  assessments,
}) => {
  const readGrants = courseGrants
    .filter((grant) => grant.rights.has(aclRights.READ) && grant.rights.size === 1)
    .filter((grant) => !(grant.principal.type === aclPrincipalTypes.GROUP
      && grant.principal.name === systemPrivilegeGroups.PUBLIC));
  const readUsersMap = new Map();
  readGrants
    .map((grant) => grant.principal)
    .forEach((principal) => {
      if (principal.type === aclPrincipalTypes.GROUP) {
        principal.members.forEach((m) => {
          const user = users.find((u) => u.id === m.id);
          readUsersMap.set(user.id, user);
        });
      } else {
        readUsersMap.set(principal.entity.id, principal.entity);
      }
    });
  const userAssessmentCombination = [];
  assessments.forEach((assessment) => {
    Array.from(readUsersMap.values()).forEach((user) => {
      const existingInAnalytics = analyticsEvaluations
        .find(
          (ev) => ev.assessmentId === assessment.id && ev.userId === user.id,
        );
      if (existingInAnalytics) {
        userAssessmentCombination.push(existingInAnalytics);
      } else {
        userAssessmentCombination.push({
          assessmentId: assessment.id,
          moduleId: null,
          courseId: null,
          userId: user.id,
          status: evaluationStatus.ASSIGNED,
        });
      }
    });
  });
  return userAssessmentCombination;
};
