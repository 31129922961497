/**
 * Content for button pane on test page.
 *
 * @module ui/page/admin/test/buttons
 */
import collapsible from "ui/component/collapsible";
import button from "ui/component/form-managed/button";
import message from "ui/component/message";
import div from "ui/html/div";
import p from "ui/html/p";
import span from "ui/html/span";

const buttonGroup = (self, children) => span(
  self.bind(children),
  ".buttons",
);

const info = (text) => div(
  ".floating-message",
  message({ text, type: "info" }),
);

const buildButtons = (self, faux) => ([
  faux
    ? info("These buttons are not interactable.")
    : "",
  buttonGroup(self, [
    [button, { label: "primary", sel: ".primary", faux }],
    [button, { label: "secondary", sel: ".secondary", faux }],
  ]),
  buttonGroup(self, [
    [button, { label: "third", sel: ".alternate", faux }],
    [button, { label: "warning", sel: ".danger", faux }],
    [button, { label: "accent", sel: ".warn", faux }],
  ]),
  buttonGroup(self, [
    [button, { label: "inverse", sel: ".inverse", faux }],
    [button, { label: "stand-in", sel: ".stand-in", faux }],
  ]),
  p("Icon Buttons"),
  buttonGroup(self, [
    [button, { icon: "check-circle", label: "Left Icon", sel: ".primary", faux }],
    [button, {
      icon: "trash",
      label: "Right Icon",
      sel: ".primary",
      swap: true,
      faux,
    }],
  ]),
  buttonGroup(self, [
    [button.icon, { icon: "trash", label: "Delete", sel: ".primary", faux }],
    [button.icon, { icon: "file-pdf", label: "Download PDF", sel: ".secondary", faux }],
    [button.icon, { icon: "save", label: "Save", sel: ".alternate", faux }],
    [button.icon, { icon: "trash", label: "Garbage", sel: ".danger", faux }],
    [button.icon, { icon: "eye", label: "Sauron", sel: ".warn", faux }],
  ]),
  p("Subtle Buttons"),
  buttonGroup(self, [
    [button, {
      icon: "arrow-left",
      label: "subtle back link",
      sel: ".subtle.back",
      faux,
    }],
    [button, {
      label: "subtle forward link",
      icon: "arrow-right",
      swap: true,
      sel: ".subtle.forward",
      faux,
    }],
  ]),
]);

export default (self) => div(
  "#managed-buttons",
  [
    collapsible({
      title: "Buttons",
      open: self.state.buttonsOpen,
      onChange: (buttonsOpen) => self.updateState({ buttonsOpen }),
    }, buildButtons(self, false)),
    collapsible({
      title: "Faux Buttons",
      open: self.state.fauxButtonsOpen,
      onChange: (fauxButtonsOpen) => self.updateState({ fauxButtonsOpen }),
    }, buildButtons(self, true)),
  ],
);
