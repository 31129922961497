/**
 * A progress element (canned progress bar).
 *
 * @module ui/html/progress
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';
import { merge } from "util/object";

/**
 * @function progress
 * @param {string} label
 * @param {string} value
 * @param {int} [min=0]
 * @param {int} [max=100]
 * @param {module:ui/html~Selector} [sel=""]
 * @param {module:ui/html~Config} {config={}}
 * @return {module:ui/common/el~El}
 */
const progress = (label, value, min = 0, max = 100, sel = "", config = "") => el(
  `progress${sel}`,
  merge(
    {
      props: { min, max },
      attrs: { value },
    },
    config,
  ),
  label,
);

export default progress;
