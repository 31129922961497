/**
 * Analytics requests related to video playback.
 *
 * @module api/v2/analytics/video-playback
 * @category Backend API v2
 * @subcategory Analytics
 */
import log from "log";
import { endpoints, services } from "api/constants";
import { get } from "api/request";
import {
  responseToAnalyticsPlaybackEntry,
  responseToAnalyticsVideoTrendsEntry,
} from "model/analytics";

/**
 * Get user playback positions
 *
 * @param {string} userId
 * @param {AnalyticsRequest} partial
 * @returns {Promise.<AnalyticsContentDurationEntry[]>}
 */
export const getPositionsForUser = async (userId) => {
  try {
    const response = (await get(
      endpoints.ANALYTICS_VIDEO_PLAYBACK(userId),
      null,
      false,
      true,
      [],
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsPlaybackEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get trending videos.
 *
 * @function getTrending
 * @param {int} count
 * @return {Promise.<AnalyticsVideoTrendsEntry[]>}
 */
export const getTrending = async (count) => {
  try {
    const response = (await get(
      `${endpoints.ANALYTICS_VIDEO_TRENDING}/30d/${count}`,
      null,
      false,
      true,
      [],
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsVideoTrendsEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get most watched videos.
 *
 * @function getMostWatched
 * @param {int} count
 * @return {Promise.<AnalyticsVideoTrendsEntry[]>}
 */
export const getMostWatched = async (count) => {
  try {
    const response = (await get(
      `${endpoints.ANALYTICS_VIDEO_TRENDING}/100y/${count}`,
      null,
      false,
      true,
      [],
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsVideoTrendsEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get recently watched videos for the user.
 *
 * @function getRecentlyWatched
 * @param {UUID} userId
 * @return {Promise.<AnalyticsVideoTrendsEntry[]>}
 */
export const getRecentlyWatched = async (userId) => {
  try {
    const response = (await get(
      `${endpoints.ANALYTICS_VIDEO_WATCHED}/${userId}/30d/`,
      null,
      false,
      true,
      [],
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsVideoTrendsEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * Get recently added videos.
 *
 * @function getRecentlyAdded
 * @param {int} count
 * @return {Promise.<AnalyticsVideoTrendsEntry[]>}
 */
export const getRecentlyAdded = async (count) => {
  try {
    const response = (await get(
      `${endpoints.ANALYTICS_VIDEO_ADDED}/30d/${count}`,
      null,
      false,
      true,
      [],
      services.RABE,
    )).body;
    return response.map(responseToAnalyticsVideoTrendsEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};
