/**
 * Functions for generating file URLs for metadata objects.
 *
 * @module model/metadata/url
 * @category Model
 * @subcategory Metadata
 */
import {
  endpoints,
} from "api/constants";
import { baseUrlSync } from "api/core-util";

/**
 * Get video file URL.
 *
 * @function getVideoFileURL
 * @param {UUID} id
 * @return {string} video file URL
 */
export const getVideoFileURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_VIDEO}/${id}`); // ?X-Access-Token=${getToken()}`);

/**
 * Get video file URL without embedded authentication token. Video player needs this
 * because it has to use cookie-based auth.
 *
 * @function getVideoFileURLNoAuth
 * @param {UUID} id
 * @return {string} video file URL
 */
export const getVideoFileURLNoAuth = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_VIDEO}/${id}`);

/**
 * Get video poster URL.
 *
 * @function getVideoPosterURL
 * @param {UUID} id
 * @return {string} poster image URL
 */
export const getVideoPosterURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_METADATA_POSTER(id)}`);

/**
 * Get Document file URL.
 *
 * @function getDocumentFileUrl
 * @param {UUID} id
 * @return {string} document file URL
 */
export const getDocumentFileURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_DOCUMENT}/${id}`); // ?X-Access-Token=${getToken()}`);

/**
 * Get Document poster URL.
 *
 * @function getDocumentPosterURL
 * @param {UUID} id
 * @return {string} poster image URL
 */
export const getDocumentPosterURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_METADATA_POSTER(id)}`);

/**
 * Get list poster URL.
 *
 * @function getListPosterURL
 * @param {UUID} id
 * @return {string} poster image URL
 */
export const getListPosterURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_METADATA_POSTER(id)}`);

/**
 * Get the url for an image file stored as a file descriptor.
 *
 * @function getImageFileUrl
 * @param {uuid} id file descriptor id, not metadata id
 * @return {string} image URL
 */
export const getImageFileURL = (id) => (id === null ? "" : `${baseUrlSync()}${endpoints.CONTENT_METADATA_POSTER(id)}`);
