/**
 * Requests related to users and authentication.
 *
 * @module api/user
 * @category Backend API
 * @subcategory User
 */
import cache from "cache";
import { endpoints } from "api/constants";
import {
  del,
  get,
  post,
  put,
} from "api/request";
import {
  makeUserPasswordResetDTO,
  makeUserRegisterDTO,
  makeUserReverifyDTO,
  makeUserUpdateDTO,
  makeUserVerifyDTO,
  responseToAbbreviatedUser,
  responseToUser,
} from "model/user";
import { isAnonymous } from "util/user";
import { AnonymousUserModificationError } from "model/error";
import { userTypes } from "model/user/constants";

export const CACHE_USERS_KEY = "allUsers";

export const CACHE_ANONYMOUS_USER_KEY = "anonymousUser";

/**
 * Get the currently authenticated user's profile data.
 *
 * @modifies cached user profile in auth state
 *
 * @function getMe
 * @return {module:api/types/user~User}
 */
export const getMe = async () => {
  const user = responseToUser((await get(`${endpoints.USER}/me`)).body);
  cache.storeProfile(user);
  return user;
};

/**
 * Get the anonymous user
 *
 * @modifies cached anonymous user profile in state
 *
 * @function getAnonymousUser
 * @return {User}
 */
export const getAnonymousUser = async (skipCache = false) => {
  if (!skipCache) {
    const user = await cache.getOrRefreshExpiring(
      CACHE_ANONYMOUS_USER_KEY,
      async () => (await get(endpoints.USER_ANONYMOUS)).body,
    );
    if (user) return responseToAbbreviatedUser(user, userTypes.ANONYMOUS);
  }
  return responseToAbbreviatedUser(cache.storeExpiring(
    CACHE_ANONYMOUS_USER_KEY,
    (await get(endpoints.USER_ANONYMOUS)).body,
  ), userTypes.ANONYMOUS);
};

/**
 * Get a user by ID.
 *
 * @function getById
 * @param {string} id
 * @param {boolean} skipCache
 *
 * @return {module:api/types/user~User}
 */
export const getById = async (id, skipCache = false) => {
  if (!skipCache) {
    const user = await cache.getOrRefreshExpiringMapEntry(
      CACHE_USERS_KEY,
      id,
      async () => (await get(`${endpoints.USER}/${id}`)).body,
    );
    if (user) return responseToUser(user);
  }
  return responseToUser(cache.storeExpiringMapEntry(
    CACHE_USERS_KEY,
    id,
    (await get(`${endpoints.USER}/${id}`)).body,
  ));
};

/**
 * Delete a user by ID.
 *
 * @function deleteById
 * @param {number} id
 *
 * @return {module:api/types/user~User}
 */
export const deleteById = async (id) => {
  const response = (await del(`${endpoints.USER}/${id}`));
  cache.deleteValue(CACHE_USERS_KEY);
  return response.ok;
};

/**
 * Get a list of all users visible to the current user.
 *
 * @function list
 * @return {module:api/types/user~User[]}
 */
export const list = async () => {
  const users = (await get(endpoints.USER)).body;
  if (users.length) {
    const pairs = users.map((u) => [u.id, u]);
    cache.storeExpiringMapEntries(CACHE_USERS_KEY, pairs);
  }
  return users.map(responseToUser);
};

/**
 * Search users by user object properties.
 *
 * @function search
 * @param {object} props
 * @param {string} [props.firstName]
 * @param {string} [props.lastName]
 * @param {string} [props.email]
 * @param {string} [props.cellPhone]
 * @param {string} [props.username]
 *
 * @return {module:api/types/user~User[]}
 */
export const search = async (props) => (await get(endpoints.USER, props)).body;

/**
 * Create a new user (admin endpoint).
 *
 * @function create
 * @param {object} data as {@link module:api/types/user~makeUserRegisterDTO}
 *
 * @return {module:api/types/api~APIResponse}
 */
export const create = async (data) => {
  const user = responseToUser((await post(
    endpoints.USER,
    null,
    makeUserRegisterDTO(data),
    true,
  )).body);
  // let's clear the cache now
  cache.deleteValue(CACHE_USERS_KEY);
  return user;
};

/**
 * Create new users batch (admin endpoint).
 *
 * @function batchCreate
 * @param {object[]} data as {@link module:api/types/user~makeUserRegisterDTO}
 *
 */
export const batchCreate = async (data = []) => {
  const response = (await post(
    endpoints.USER_BATCH,
    null,
    data.map(makeUserRegisterDTO),
  )).body;
  cache.deleteValue(CACHE_USERS_KEY);
  return {
    entries: response.entries.map((user) => (user ? responseToUser(user) : null)),
    errors: response.errors,
  };
};

/**
 * Updates current user's account information.
 *
 * @function updateMe
 * @param {object} data
 * @param {string} data.username
 * @param {string} [data.firstName]
 * @param {string} [data.lastName]
 * @param {string} [data.cellPhone]
 * @param {string} [data.email]
 * @param {string} [data.password]
 *
 * @return {module:api/types/api~APIResponse}
 */
export const updateMe = async (data) => responseToUser((await put(
  endpoints.USER_SELF,
  null,
  makeUserUpdateDTO(data),
)).body);

/**
 * Deletes the current user's account.
 *
 * @function deleteMe
 * @return {module:api/types/api~APIResponse}
 */
export const deleteMe = async () => {
  const { ok } = (await del(endpoints.USER_SELF));
  if (ok) {
    cache.clear();
  }
  return ok;
};

/**
 * Updates user account information.
 *
 * @function update
 * @param {object} data
 * @param {UUID} data.id
 * @param {string} data.username
 * @param {string} [data.firstName]
 * @param {string} [data.lastName]
 * @param {string} [data.cellPhone]
 * @param {string} [data.email]
 * @param {string} [data.password]
 * @param {userTypes} [data.userType]
 * @return {module:api/types/api~APIResponse}
 */
export const update = async (data) => {
  if (isAnonymous(data)) {
    throw new AnonymousUserModificationError();
  }
  const response = (await put(
    `${endpoints.USER}/${data.id}`,
    null,
    makeUserUpdateDTO(data),
  )).body;
  if (response) cache.deleteValue(CACHE_USERS_KEY);
  return responseToUser(response);
};

/**
 * Register a new user.
 *
 * @function register
 * @param {object} data as {@link module:api/types/user~makeUserRegisterDTO}
 *
 * @return {module:api/types/api~APIResponse}
 */
export const register = async (data) => responseToUser((await post(
  endpoints.USER_REGISTER,
  null,
  makeUserRegisterDTO(data),
  true,
)).body);

/**
 * Verifies a user account.
 *
 * @function verify
 * @param {object} data as {@link module:api/types/user~makeUserVerifyDTO}
 *
 * @return {module:api/types/api~APIResponse}
 */
export const verify = async (data) => {
  if (isAnonymous(data)) {
    throw new AnonymousUserModificationError();
  }
  return post(
    endpoints.USER_VERIFY,
    null,
    makeUserVerifyDTO(data),
    true,
  );
};

/**
 * Requests a new verification code.
 *
 * @function reverify
 * @param {object} data as {@link module:api/types/user~makeUserReverifyDTO}
 *
 * @return {module:api/types/api~APIResponse}
 */
export const reverify = async (data) => {
  if (isAnonymous(data)) {
    throw new AnonymousUserModificationError();
  }
  return post(
    endpoints.USER_REVERIFY,
    null,
    makeUserReverifyDTO(data),
    true,
  );
};

/**
 * Requests a user password reset.
 *
 * @function resetPassword
 * @param {object} data as {@link module:api/types/user~makeUserPasswordResetDTO}
 *
 * @return {module:api/types/api~APIResponse}
 */
export const resetPassword = async (data) => {
  if (isAnonymous(data)) {
    throw new AnonymousUserModificationError();
  }
  return post(
    endpoints.USER_PASSWORD_RESET,
    null,
    makeUserPasswordResetDTO(data),
  );
};
