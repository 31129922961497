/**
 * A view for hero sliders.
 *
 * Not currently used.
 *
 * @module ui/view/hero-slider
 * @deprecated
 * @category UI
 * @subcategory Views
 */
import equal from "deep-equal";
import heroSlider from "ui/component/hero-slider";
import { merge, frozen } from "util/object";
import baseView from "./view";

const bootstrapSlider = (view) => {
  let interval;

  const initRotation = () => {
    if (interval) clearInterval(interval);
    interval = setInterval(() => {
      view.update({ current: view.state.current + 1 });
    }, view.state.interval);
  };

  const handleNavigation = (e) => {
    view.update({ current: e.detail.selected });
    initRotation();
  };

  const initListeners = () => {
    view.element.removeEventListener("em:slider-navigation", handleNavigation);
    view.element.addEventListener("em:slider-navigation", handleNavigation);
  };

  const rotate = (parent, cur, count) => {
    const prev = parent.querySelector(".current");
    if (prev) prev.classList.remove("current");
    const next = parent.children[cur % count];
    if (next) next.classList.add("current");
  };

  /* eslint-disable no-param-reassign */
  view.update = (state) => {
    /*
     * When the element is rendered and the slide list is the same as before
     * we only want to change class. Doing a patch will arbitrarily rearrange
     * or alter the slides, which can break slide animations.
     *
     * If the list has changed then we'll do a patch instead.
     */
    const oldState = merge({}, view.state);
    view.updateState(state);
    if (
      view.node
      && equal(oldState.slides, view.state.slides)
      && view.element
    ) {
      const el = view.element;
      rotate(el, view.state.current, view.state.slides.length);
      rotate(el.querySelector("nav"), view.state.current, view.state.slides.length);

      if (!interval || (state.interval !== oldState.interval)) initRotation();
    } else {
      // this should happen when the slide array changes and when the element is
      // uninitialized
      view.patch(heroSlider(view.state));
      initRotation();
      initListeners();
    }
  };

  /* eslint-enable no-param-reassign */
  return view;
};

const defaultState = frozen({
  slides: [],
  current: 0,
  interval: 6000,
});

/**
 * @function heroSlider
 * @param {string} selector css selector for root element
 * @param {Object} [state] state object
 * @param {Array<Object>} [state.slides=[]] list of slide image config objects
 * @param {string} [state.slides[n].src] slide image URL
 * @param {int} [state.current=0] index of initial slide to show
 * @param {int} [interval=6000] slide auto-rotate interval in milliseconds
 */
export default (selector, initState = defaultState) => {
  const state = merge(defaultState, initState);
  const slider = bootstrapSlider(baseView(selector, heroSlider, defaultState));
  slider.update(state);
  return slider;
};
