/**
 * A generic modal dialog. Not really used except as an example.
 *
 * @module ui/components/modal/basic
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, h3, span } from "ui/html";
import icon from "ui/component/icon";

/**
 * A basic modal dialog.
 *
 * @example
 * const dialog = api.components.modal(
 *   { // content
 *     title: api.html.h1("My Modal"),
 *     body: api.html.p("This is my cool modal dialog."),
 *     footer: api.html.span("This appears on the bottom."),
 *   },
 *   { // hooks
 *     close: () => alert("You clicked close!"),
 *     back: () => alert("You clicked close!"),
 *   },
 *   ".cool-modal"
 * );
 *
 * const modalView = api.view.modal("#my-modal-container");
 * modalView.show(dialog);
 *
 * @param {object} content
 * @param {string|vnode|vnode[]} content.title content for the title area
 * @param {string|vnode|vnode[]} content.body content for the body area
 * @param {string|vnode|vnode[]} content.footer content for the footer area
 * @param {object} hooks
 * @param {function} hooks.close a callback for the close button. if present a
 *                               close button will be displayed
 * @param {function} hooks.back  a callback for the back button. if present a
 *                               back button will be displayed
 * @param {string} [sel=""] additional selectors for the modal container
 *
 * @return {module:ui/common/el~El}
 */
const basic = (
  {
    title = "Modal Dialog",
    body = "No content.",
    footer = "",
  },
  {
    close = null,
    back = null,
  },
  sel = "",
) => div(
  `${sel}.dialog.basic`,
  [
    div(".header", [
      (back
        ? icon("chevron-left.alternate.button", {
          on: { click: () => back() },
        })
        : span()
      ),
      h3(title),
      (close
        ? icon("times.ok.button", {
          on: { click: () => close() },
        })
        : span()
      ),
    ]),
    div(".body", body),
    (footer ? div(".footer", footer) : ""),
  ],
);

export default basic;
