/**
 * State management utilities for student assessments.
 *
 * @module ui/page/assessment/state
 * @category Pages
 * @subcategory Courses
 */
import { frozen } from "util/object";

/* eslint-disable-next-line import/prefer-default-export */
export const defaultState = frozen({
  user: null,
  assessment: {
    questions: [],
  },
  attendance: null,
  evaluation: null,
  validity: { valid: true, fields: {} },
  /// next position requested
  requested: 0,
  /// position of carousel
  position: 0,
  /// collection of questionIds indexed by shuffled order
  questionOrder: [],
  /// last question to be fully validated
  validPosition: (-1),
  timeLeft: Infinity,
  over: false,
});
