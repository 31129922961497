/**
 * Utility functions for helping with ACL changes.
 *
 * @module util/acl
 * @category Utilities
 */
import api from "api";
import log from "log";
import { addUserToGroup, deleteUserFromGroup } from "api/acl";
import { userToPrincipal } from "model/acl";
import {
  aclPrincipalTypes,
  aclSubjectTypes,
  aclRights,
  systemPrivilegeGroups,
} from "model/acl/constants";
import { merge } from "util/object";
import { getModal } from "ui/view/modal-view";
import { userTypes } from "model/user/constants";
import { isAnonymous } from "util/user";

/**
 * Finds the users in a set of selected users that are not in a group's current
 * list of users.
 *
 * @function findInsertedGroupMembers
 * @param {ACLPrincipalEntry} group principal of type `GROUP`
 * @param {ACLPrincipalEntry[]} selectedUsers principals of type `INDIVIDUAL`
 * @return {ACLPrincipalEntry[]} `selectedUsers` who were not members of `group`
 */
export const findInsertedGroupMembers = (group, selectedUsers) => selectedUsers
  .filter(
    (newUser) => !group.members.some((oldUser) => oldUser.id === newUser.id),
  );

/**
 * Finds the users in a set of selected users who are in a group's member list, and
 * should be removed.
 *
 * @function findRemovedGroupMembers
 * @param {ACLPrincipalEntry} group principal of type `GROUP`
 * @param {ACLPrincipalEntry[]} selectedUsers principles of type `INDIVIDUAL`
 * @return {ACLPrincipalEntry[]} `selectedUsers` who were members of `group`
 */
export const findRemovedGroupMembers = (group, selectedUsers) => group.members
  .filter(
    (oldUser) => !selectedUsers.some((newUser) => oldUser.id === newUser.id),
  );

/**
 * @typedef ACLGroupMembershipChanges
 * @property {ACLPrincipalEntry[]} inserted
 * @property {ACLPrincipalEntry[]} removed
 */
/**
 * Compares a `group` and a list of users, finding changes between the group's member
 * list and the selected users list.
 *
 * @function findGroupMemberChanges
 * @param {ACLPrincipalEntry} group principal of type `GROUP`
 * @param {ACLPrincipalEntry[]} selectedUsers principals of type `INDIVIDUAL`
 * @return {ACLGroupMembershipChanges}
 */
export const findGroupMemberChanges = (group, selectedUsers) => ({
  inserted: findInsertedGroupMembers(group, selectedUsers),
  removed: findRemovedGroupMembers(group, selectedUsers),
});

/**
 * Compares an original group to its modified version and finds the users who are
 * inserted or removed from the original.
 *
 * @function findUserMembershipChanges
 * @param {ACLPrincipalEntry[]} group modified group
 * @param {ACLPrincipalEntry[]} original unmodified group
 * @return {ACLGroupMembershipChanges}
 */
export const findUserMembershipChanges = (groups, original) => ({
  inserted: groups.filter((a) => !original.some((b) => a.id === b.id)),
  removed: original.filter((a) => !groups.some((b) => a.id === b.id)),
});

/**
 * Prepares a series of API requests for changing the members of a group.
 *
 * @function makeGroupPatchQueue
 * @param {ACLGroupMembershipChanges} changes
 * @return {function[]} API calls
 */
export const makeGroupPatchQueue = (changes) => {
  const calls = [];
  changes.inserted.forEach((insert) => {
    calls.push(() => addUserToGroup(insert));
  });
  changes.removed.forEach((removal) => {
    calls.push(() => deleteUserFromGroup(removal));
  });
  return calls;
};

/**
 * Given a group and a list of selected users, prepare a queue of API calls.
 *
 * @function prepareGroupMembershipPatchRequests
 * @param {ACLPrincipalEntry} group principal of type `GROUP`
 * @param {ACLPrincipalEntry[]} selectedUsers principals of type `INDIVIDUAL`
 * @return {function[]} API calls
 */
export const prepareGroupMembershipPatchRequests = (group, selectedUsers) => {
  const changes = findGroupMemberChanges(group, selectedUsers);
  return makeGroupPatchQueue({
    inserted: changes.inserted.map((change) => ({
      userId: change.id,
      groupId: group.id,
    })),
    removed: changes.removed.map((change) => ({
      userId: change.id,
      groupId: group.id,
    })),
  });
};

/**
 * Given a user and a set of group changes, prepare a queue of API calls.
 *
 * @function prepareGroupMembershipPatchRequests
 * @param {ACLPrincipalEntry} user principal of type `GROUP`
 * @param {ACLGroupMembershipChanges} changes
 * @return {function[]} API calls
 */
export const prepareUserMembershipPatchRequests = (user, changes) => makeGroupPatchQueue({
  inserted: changes.inserted.map((change) => ({
    userId: user.id,
    groupId: change.id,
  })),
  removed: changes.removed.map((change) => ({
    userId: user.id,
    groupId: change.id,
  })),
});

/**
 * Extracts names of groups into a set.
 *
 * @function groupNameSet
 * @param {ACLPrincipalEntry[]} groups
 * @return {Set.<string>}
 */
export const groupNameSet = (groups) => new Set(groups.map((g) => g.name));

/**
 * Finds the entries in a list of groups that grant system privileges and
 * creates a map of them keyed by name.
 *
 * @function mapPrivilegeGroups
 * @param {ACLPrincipalEntry[]} group
 * @return {Map.<string, ACLPrincipalEntry>}
 */
export const mapPrivilegeGroups = (groups) => {
  const privilegedGroups = [];
  groups.forEach((group) => {
    if (group.system) privilegedGroups.push([group.name, group]);
  });
  return new Map(privilegedGroups);
};

/**
 * Find whether a user is a member of a group.
 * @param {User} user
 * @param {ACLPrincipalEntry} group
 * @return boolean
 */
export const userIsMemberOfGroup = (user, group) => !!group.members.find(
  (member) => member.id === user.id,
);

/**
 * Find any rights the user has which are *not* explicitly granted, but are granted
 * via membership of a group.
 * @param {User} user
 * @param {Array.<ACLGrantEntry>} groups principal entries with appended rights list
 * @return {Set.<ACLRight>}
 */
export const findCollateralRights = (user, grants) => {
  const collateralRights = new Set();
  grants.forEach((grant) => {
    if (
      grant.principal.type === aclPrincipalTypes.GROUP
      && userIsMemberOfGroup(user, grant.principal)
    ) {
      [...grant.rights].forEach((right) => collateralRights.add(right));
    }
  });
  return collateralRights;
};

export const anyoneHasRight = (right, grants) => grants.reduce(
  (acc, cur) => acc || cur.rights.has(right),
  false,
);

/**
 * Check if grant is an anonymous user
 *
 * @param {ACLGrantEntry} grant
 * @returns boolean
 */
export const isGrantAnonymousUser = (grant) => {
  if (grant.type === aclPrincipalTypes.INDIVIDUAL) {
    return grant.principal?.entity?.userType === userTypes.ANONYMOUS;
  }
  return false;
};

/**
 * Find any rights the user is granted in the list of grants.
 * @param {ACLPrincipalEntry} principal
 * @param {Array.<ACLGrantEntry>} groups principal entries with appended rights list
 * @return {Set.<ACLRight>}
 */
export const findRights = (principal, grants) => {
  const rights = new Set();
  grants.forEach((grant) => {
    if (
      (
        grant.principal.type === aclPrincipalTypes.INDIVIDUAL
        && grant.principal.id === principal.id
      )
      || (
        grant.principal.type === aclPrincipalTypes.GROUP
        && grant.principal.id === principal.id
      )
      || (
        grant.principal.type === aclPrincipalTypes.GROUP
        && userIsMemberOfGroup(principal, grant.principal)
      )
    ) {
      [...grant.rights].forEach((right) => rights.add(right));
    }
  });
  return rights;
};

/**
 * Used by ACLGrantQueues, adds a grant to the given queue if the queue doesn't
 * already have one for the existing principal. Otherwise updates the existing one.
 * @function addToQueue
 * @private
 * @param {ACLGrantEntry} grant
 * @param {Array.<ACLGrantEntry>} queue
 */
const addToQueue = (grant, queue) => {
  if (isGrantAnonymousUser(grant)) {
    grant.rights.delete(aclRights.OWNER);
    grant.rights.delete(aclRights.WRITE);
    grant.rights.delete(aclRights.DELETE);
    log.debug("Removed every right except READ for", grant);
  }
  const existingItemIndex = queue
    .findIndex((g) => g.principal.id === grant.principal.id);
  if (existingItemIndex > -1) {
    /* eslint-disable-next-line no-param-reassign */
    queue[existingItemIndex] = grant;
  } else {
    queue.push(grant);
  }
};

/**
 * A utiltiy for accumulating ACL grant changes as a user interacts with an ACL UI
 * widget.
 *
 * Keeps changes in sync and deduped while the user is working, and then
 * processes all the changes in parallel when the `process` method is called.
 *
 * @typedef ACLGrantQueue
 * @property {function(grant)} update enqueues an update
 * @property {function(grant)} remove enqueues a removal
 * @property {function} process processes the queue

/**
 * Makes an ACL grant quuee.
 * @param {UUID} subjectId
 * @param {ACLSubjectType} subjectType
 * @return {ACLGrantQueue}
 */
export const makeGrantQueue = (subjectId, subjectType) => {
  const queue = [];
  let updateFn;
  switch (subjectType) {
    case aclSubjectTypes.PAGE:
      updateFn = api.acl.updateGrantForPage;
      break;
    case aclSubjectTypes.MEDIA:
      updateFn = api.acl.updateGrantForMetadata;
      break;
    case aclSubjectTypes.COURSE:
      updateFn = api.acl.updateGrantForCourse;
      break;
    case aclSubjectTypes.CONVERSATION:
      updateFn = api.acl.updateGrantForConversation;
      break;
    default:
      throw new Error("Unsupported subject type in ACL grant queue", subjectType);
  }

  const process = async () => {
    try {
      await updateFn(subjectId, queue);
    } catch (e) {
      log.error(e);
      throw new Error("Failed to update access control privileges.");
    }
  };

  return {
    update: (grant) => addToQueue(grant, queue),
    remove: (grant) => addToQueue({ ...grant, rights: [] }, queue),
    process,
  };
};

export const validateOwnRights = async (user, newGrants, oldGrants) => {
  const modalView = getModal();
  const newRights = findRights(user, newGrants);
  const oldRights = findRights(user, oldGrants);
  if (
    (!newRights.has(aclRights.OWNER) && !newRights.has(aclRights.READ))
    && (oldRights.has(aclRights.OWNER) || oldRights.has(aclRights.READ))
  ) {
    return modalView.confirm(
      `You will lose ALL access to this content. Are you sure you want to do this?`,
    );
  }
  if (
    (!newRights.has(aclRights.OWNER) && !newRights.has(aclRights.WRITE))
    && (oldRights.has(aclRights.OWNER) || oldRights.has(aclRights.WRITE))
  ) {
    return modalView.confirm(
      `You will no longer be able to edit to this content. Are you sure you want to do this?`,
    );
  }
  if (
    !newRights.has(aclRights.OWNER)
    && oldRights.has(aclRights.OWNER)
  ) {
    return modalView.confirm(
      `You will no longer be the owner of this content. Are you sure you want to do this?`,
    );
  }
  return true;
};

export const validateRights = async (principal, newGrants, oldGrants) => {
  const modalView = getModal();
  const newRights = findRights(principal, newGrants);
  const oldRights = findRights(principal, oldGrants);
  if (principal.name === systemPrivilegeGroups.PUBLIC) {
    if (
      (newRights.has(aclRights.OWNER) && !oldRights.has(aclRights.OWNER))
      || (
        (newRights.has(aclRights.DELETE) && !oldRights.has(aclRights.DELETE))
        && (newRights.has(aclRights.WRITE) && !oldRights.has(aclRights.WRITE))
      )
    ) {
      return modalView.confirm(
        `You are about to allow ANYBODY to edit and delete this content. Are you sure you want to do this?`,
      );
    }
    if (newRights.has(aclRights.DELETE) && !oldRights.has(aclRights.DELETE)) {
      return modalView.confirm(
        `You are about to allow ANYBODY to delete this content. Are you sure you want to do this?`,
      );
    }
    if (newRights.has(aclRights.WRITE) && !oldRights.has(aclRights.WRITE)) {
      return modalView.confirm(
        `You are about to allow ANYBODY to edit this content. Are you sure you want to do this?`,
      );
    }
  }

  if (!anyoneHasRight(aclRights.OWNER, newGrants)) {
    await modalView.alert(
      `This change would leave the content without an owner, so it has been reverted automatically.`,
    );
    return false;
  }
  return true;
};

/**
 * Ensures every member of every group has a User entity attached.
 * @function bootstrapGroupMembers
 * @param {AclPrincipalEntry[]} groups
 * @param {User[]} users
 * @return {AclPrincipalEntry[]}
 */
export const bootstrapGroupMembers = (groups, users) => {
  const unknownUser = { id: "UNKNOWN", username: "Unknown User" };
  const userMap = new Map(users.map((user) => [user.id, user]));
  const outGroups = groups.map((group) => merge(group, {
    members: group.members.map((member) => userToPrincipal(userMap.get(member.id) || unknownUser)),
  }));
  return outGroups;
};

/**
 * Convert user object to ACL principal entry
 *
 * @param {User} user
 * @returns {ACLPrincipalEntry}
 */
export const convertAnonymousUserToPrincipal = (user) => ({
  id: user.id,
  name: user.username,
  type: aclPrincipalTypes.INDIVIDUAL,
  entity: user,
});

/**
 * Find anonymous grant in a grants list
 *
 * @param {ACLGrantEntry[]} grants
 * @returns {ACLGrantEntry}
 */
export const findAnonymousGrant = (grants = []) => grants.find(
  (grant) => isAnonymous(grant.principal?.entity),
);

/**
 * Get anonymous update grant entry
 *
 * @param {User} anonymousUser
 * @param {boolean} anonymousAllowanceEnabled
 * @param {ACLSubjectEntry} subject
 * @param {aclSubjectTypes} subjectType
 * @returns {ACLGrantEntry|null}
 */
export const getAnonymousUserUpdateGrantEntry = (
  anonymousUser,
  anonymousAllowanceEnabled,
  subject,
  subjectType,
) => {
  if (!anonymousUser) {
    return null;
  }
  if (anonymousAllowanceEnabled) {
    return {
      principal: convertAnonymousUserToPrincipal(anonymousUser),
      rights: new Set([aclRights.READ]),
      subject,
      type: subjectType,
    };
  }
  return {
    principal: convertAnonymousUserToPrincipal(anonymousUser),
    rights: new Set(),
    subject,
    type: subjectType,
  };
};

/**
 * Copies the grants from one subject to another.
 *
 * @param {ListMetadata|DynamicPage|Course} subject
 * @param {ACLSubjectType} subjectType
 * @param {ACLGrantEntry[]} sourceGrants the grants to be copied
 * @param {ACLGrantEntry[]} targetGrants the original grants to be replaced
 * @return {AclGrantQueue} a prepared queue with grant changes
 */
export const cloneGrants = (subjectId, subjectType, sourceGrants, targetGrants) => {
  const queue = makeGrantQueue(subjectId, subjectType);
  sourceGrants.forEach((grant) => {
    queue.remove(grant);
  });
  targetGrants.forEach((grant) => {
    queue.update({ ...grant, subject: { id: subjectId }, type: subjectType });
  });
  return queue;
};
