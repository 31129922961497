/**
 * Constants for dynamic page endpoints.
 *
 * @module api/page/constants
 * @category Backend API
 * @subcategory Page
 */

export const CACHE_PAGE_KEY = "page";
export const CACHE_PAGE_SLUG_KEY = "pageSlug";
