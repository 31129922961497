/**
 * Utilities for manipulating date objects.
 *
 * @module util/date
 * @category Utilities
 */
import { isBefore, isAfter } from "date-fns";
import { format as dateFmt, parse as dateParse, parseISO, startOfDay } from "date-fns/fp";
import compareAsc from "date-fns/compareAsc";
import { zonedTimeToUtc } from "date-fns-tz";
import { frozen } from "util/object";

/**
 * Format a Date in ISO 8601 format.
 *
 * @function iso8601
 * @param {Date} date
 * @return {string}
 */
export const iso8601 = (date) => zonedTimeToUtc(
  date,
  Intl.DateTimeFormat().resolvedOptions().timeZone,
).toISOString();

/**
 * Default minimum date as ISO8601 string.
 *
 * @constant {string}
 */
export const MIN_DATE = frozen(iso8601(startOfDay(new Date("1900-01-01"))));

/**
 * Default maximum date as ISO8601 string.
 *
 * @constant {string}
 */
export const MAX_DATE = frozen(iso8601(startOfDay(new Date("2100-01-01"))));

/**
 * Get current timestamp plus the given number of minutes.
 *
 * @function minutesFromNow
 * @param {int} minutes
 * @return {int} timestamp
 */
export const minutesFromNow = (minutes) => Date.now() + (minutes * 60 * 1000);

/**
 * Get current timestamp plus the given number of minutes.
 *
 * @function secondsFromNow
 * @param {int} seconds
 * @return {int} timestamp
 */
export const secondsFromNow = (seconds) => Date.now() + (seconds * 1000);

/**
 * Get current timestamp plus the given number of days, rounded to start of day.
 *
 * @function daysFromNow
 * @param {int} days
 * @return {int} timestamp
 */
export const daysFromNow = (days) => startOfDay(
  Date.now() + (days * 1000 * 60 * 60 * 24),
).getTime();

/**
 * Format a Date in "yyyy-mm-dd" format.
 *
 * @function mdy
 * @param {Date} date
 * @return {string}
 */
export const yearMonthDay = dateFmt("yyyy-MM-dd");

/**
 * Format a Date in "MM/dd/yyyy" format.
 *
 * @function monthDayYear
 * @param {Date} date
 * @return {string}
 */
export const monthDayYear = dateFmt("MM/dd/yyyy");

/**
 * Format a Date in "hh:mm" format.
 *
 * @function hourMinute
 * @param {Date} date
 * @return {string}
 */
export const hourMinute = dateFmt("HH:mm");

/**
 * Format a Date in "hh:mm a" format (US).
 *
 * @function usHourMinute
 * @param {Date} date
 * @return {string}
 */
export const usHourMinute = dateFmt("hh:mm a");

/**
 * Format a Date in "yyyy-MM-dd HH:mm" format.
 *
 * @function yearMonthDayHourMinute
 * @param {Date} date
 * @return {string}
 */
export const yearMonthDayHourMinute = dateFmt("yyyy-MM-dd HH:mm");

/**
 * Format a Date in "MM/dd/yyyy hh:mm a" format. (US format)
 *
 * @function usFullDateFormat
 * @param {Date|string} date Date or ISO datestring
 * @return {string}
 */
export const usFullDateFormat = (date) => (date instanceof Date
  ? dateFmt("MM/dd/yyyy hh:mm a")(date)
  : dateFmt("MM/dd/yyyy hh:mm a")(parseISO(date)));

/**
 * Format a Date in "EEEE, MMMM do" format. (Wednesday, June 8th)
 *
 * @function usFullDateFormat
 * @param {Date} date
 * @return {string}
 */
export const weekdayNameWithMonthName = dateFmt("EEEE, MMMM do");

export const parseYMDHM = dateParse(new Date())("yyyy-MM-dd HH:mm");

/**
 * Checks if two dates are equivalent.
 *
 * Accepts anything that `new Date()` accepts as parameters, and creates
 * date objects out of them before comparing.
 *
 * @example
 * datesEquivalent("2020-01-01:08:00:00Z", "2020-01-01:08:00:00.000Z"); // true
 * datesEquivalent("2020-01-01:10:00:00Z", "2020-01-01:08:00:00Z"); // false
 * datesEquivalent(100000, 100000); // true
 * datesEquivalent(new Date(), new Date()); // ?false (possible microtime difference)
 *
 * @function datesEquivalent
 * @param {mixed} first
 * @param {mixed} second
 * @return boolean
 */
export const datesEquivalent = (first, second) => compareAsc(
  new Date(first),
  new Date(second),
) === 0;

/**
 * Make a valid Date object if possible.
 *
 * @function maybeDate
 * @param {mixed} input
 * @return {Date|null} null if input can't be made into a date
 */
export const maybeDate = (input) => {
  if (Number.isNaN(Date.parse(input))) return null;
  return new Date(input);
};

/**
 * Checks if a date is before the current time.
 *
 * If date is invalid, always returns true.
 *
 * @function isBeforeNow
 * @param {Date|ISO8601} date
 * @return boolean
 */
export const isBeforeNow = (date) => isBefore(maybeDate(date) || new Date(MIN_DATE), Date.now());

/**
 * Checks if a date is before the current time.
 *
 * If date is invalid, always returns true.
 *
 * @function isAfterNow
 * @param {Date|ISO8601} date
 * @return boolean
 */
export const isAfterNow = (date) => isAfter(maybeDate(date) || new Date(MAX_DATE), Date.now());

/**
 * Checks if a date is before the current time.
 *
 * @function isNowWithin
 * @param {Date|ISO8601} before
 * @param {Date|ISO8601} after
 * @return boolean
 */
export const isNowWithin = (before, after) => isBeforeNow(before) && isAfterNow(after);

/**
 * Returns a date given amount of days ago
 *
 * @param {number} daysAmount
 * @returns {Date}
 */
export const getDateDaysAmountAgo = (daysAmount) => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate() - daysAmount);
};

/**
 * Returns a date week ago
 *
 * @returns {Date}
 */
export const getDateWeekAgo = () => getDateDaysAmountAgo(7);
