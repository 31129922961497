/**
 * A basic HTML form element.
 *
 * @module ui/html/form
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * A basic FORM element with no special handling. See also
 * {@link module:ui/component/validated-form} for a more structured form.
 *
 * @function form
 * @param {string} sel css selectors applied to form
 * @param {mixed} ...args as {@link module:ui/common/el}
 */
export default (sel = "", ...args) => el(`form${sel}`, ...args);
