/**
 * Cache helpers for dynamic page endpoints.
 *
 * @module api/page/index
 * @category Backend API
 * @subcategory Page
 */
import cache from "cache";
import { CACHE_PAGE_KEY, CACHE_PAGE_SLUG_KEY } from "./constants";

export const storePageDTO = (page) => {
  if (page.slug) {
    cache.storeExpiringMapEntry(CACHE_PAGE_SLUG_KEY, page.slug, page.id);
  }
  cache.storeExpiringMapEntry(CACHE_PAGE_KEY, page.id, page);
};

export const removePageDTO = (uuid) => {
  const page = cache.getExpiringMapEntry(CACHE_PAGE_KEY, uuid);
  if (page) {
    if (page.slug) {
      cache.deleteExpiringMapEntry(CACHE_PAGE_SLUG_KEY, page.slug);
    }
    cache.deleteExpiringMapEntry(CACHE_PAGE_KEY, page.id);
  }
};
