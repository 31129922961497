/**
 * A `select` element.
 *
 * @module ui/html/select
 * @category UI
 * @subcategory Elements
 */
import el from "ui/common/el";
import { merge } from "util/object";
import option from "./option";

/**
 * @function select
 * @param {string} name
 * @param {module:ui/html/option[]} [options=[]]
 * @param {module:ui/html~Selector} [sel=""] css-style selector
 * @param {module:ui/html~Config} [config={}] additional configuration
 * @return {ui/common/el~El}
 */
const select = (name, options = [], sel = "", config = {}) => el(
  `select${sel}`,
  merge(
    { props: { name } },
    config,
  ),
  options,
);

/**
 * A helpful select factory that populates the select options and sets
 * up the selected value.
 *
 * @function populuated
 * @memberof select
 * @param {string} name
 * @param {object[]} options
 * @param {string} options[].value option value
 * @param {boolean} [options[].label=value] option value
 * @param {boolean} [options[].selected=false] option value
 * @param {string} [sel=""] css-style selector
 * @param {object} [config={}] additional configuration
 * @return {ui/common/el~El}
 */

select.populated = (name, options = [], sel = "", config = {}) => select(
  name,
  options.map(({
    value,
    label,
    selected,
    disabled,
    hidden,
  }) => option(
    value,
    label,
    "",
    { props: { selected, disabled, hidden } },
  )),
  sel,
  config,
);

export default select;
