/**
 * A select option element.
 *
 * @module ui/html/option
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';
import { merge } from "util/object";

/**
 * @function option
 * @param {string} value
 * @param {string} [label=value]
 * @param {module:ui/html~Selector} [sel=""] css-style selector
 * @param {module:ui/html~Config} [config={}] additional config options
 * @return {module:ui/common/el~El}
 */
const option = (value, label = value, sel = "", config = {}) => el(
  `option${sel}`,
  merge(
    {
      props: { value },
    },
    config,
  ),
  label,
);

export default option;
