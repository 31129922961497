/**
 * Common utilities for layouts.
 *
 * @module ui/page/layout
 * @category Layout
 * @subcategory Admin
 */
import notification from "data/notification";
import notificationsModal from "ui/component/modal/notifications";
import { getModal } from "ui/view/modal-view";

/**
 * Creates poll to notifications with interval
 *
 * @function subscribeToNotifications
 * @param {object} headerView
 * @param {object} state
 * @param {string} userId
 */
// eslint-disable-next-line import/prefer-default-export
export const bindNotificationModal = (headerView) => {
  const modalView = getModal();
  document.body.addEventListener("em:dismiss-notifications", () => {
    notification.markAllRead(headerView.state.notifications.map((m) => m.id));
  });

  document.body.addEventListener("em:open-notifications", (e) => {
    const notifications = e.detail;
    if (modalView) {
      modalView.show(notificationsModal(modalView, notifications), modalView);
    }
  });
};
