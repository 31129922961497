/**
 * A cameo for course entry.
 *
 * @module ui/component/cameo-module
 * @category UI
 * @subcategory Components
 */
import { isSingleLecture } from "model/course";
import cameo from "ui/component/cameo";
import cameoLecture from "ui/component/cameo-lecture";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import moduleModal from "ui/component/modal/course/module";
import { frozen, merge } from "util/object";
import { getModal } from "ui/view/modal-view";

const showModule = ({
  course,
  module,
  users,
  onUpdate,
  metadataList,
}) => async () => {
  const updatedModule = await getModal().async(moduleModal({
    course,
    metadataList,
    module,
    users,
  }), false);
  if (updatedModule) {
    onUpdate({
      ...updatedModule,
      updated: true,
    });
  }
};

const defaultState = frozen({
  course: null,
  module: null,
  metadataList: [],
  users: [],
  onRemove: null,
  onUpdate: null,
  controls: null,
  sel: "",
  config: {},
});

export default function cameoModule(inState = {}) {
  const state = merge(defaultState, inState);
  if (isSingleLecture(state.module) && state.module.lectures?.length) {
    return cameoLecture({
      ...state,
      module: state.module,
      lecture: state.module.lectures[0],
    });
  }
  return cameo({
    label: state.module.title || "",
    icon: icon.solid("books"),
    controls: [
      state.onUpdate
        ? button({
          icon: "edit",
          iconOnly: true,
          onClick: showModule(state),
        }) : "",
      state.onRemove
        ? button.warning({
          icon: "trash-alt",
          iconOnly: true,
          onClick: () => state.onRemove(state.module),
        })
        : "",
      ...(state.controls || []),
    ],
    config: state.config,
    sel: `${state.sel}.cameo-course-entry.cameo-course-module`,
  });
}
