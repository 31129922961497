/**
 * An ordered list of metadata items. Used in various form elements and
 * dialogs.
 *
 * @module ui/component/meta-list
 * @category UI
 * @subcategory Components
 */
import {
  button,
  div,
  li,
  ol,
} from "ui/html";
import icon from "ui/component/icon";
import cameoMetadata from "ui/component/cameo-metadata";
import { emit } from "util/event";

const removeItem = (item, onRemove) => (e) => {
  emit(e.target, "em:meta-item-remove", { id: item.id });
  if (typeof onRemove === "function") onRemove(item);
};

const listItem = (onRemove) => (metadata) => li(
  cameoMetadata({
    metadata,
    controls: onRemove
      ? [button(icon.regular("trash-alt"), removeItem(metadata, onRemove), ".danger")]
      : [],
  }),
);

/**
 * @function metaList
 * @param {module:api/types/metadata~ListMetadata[]} items metadata items to display
 * @param {function} onRemove callback when an item is clicked and should be removed
 * @return {module:ui/html/div} `div.meta-list`
 */
export default (items, onRemove = null) => div(
  ".meta-list",
  ol(items.map(listItem(onRemove))),
);
