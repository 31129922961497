/**
 * API requests for playlist metadata.
 *
 * @module api/v2/metadata/playlist
 * @category Backend API v2
 * @subcategory Metadata
 */
import { endpoints } from "api/constants";
import { makePlaylistSearchParams, responseToPlaylistMetadata } from "model/metadata";
import {
  // del,
  get,
  // post,
  // put,
} from "api/request";

/**
 * Get playlist metadata by UUID.
 *
 * @function byId
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~PlaylistMetadata}
 */
export const byId = async (uuid) => responseToPlaylistMetadata(
  (await get(`${endpoints.META_LIST}/${uuid}`)).body,
);

/**
 * Get multiple playlist metadatas by id.
 *
 * @function byIds
 * @param {UUID[]} uuids
 * @returns {Promise.<PlaylistMetadata[]>}
 */
export const byIds = async (uuids) => (await get(
  `${endpoints.META_LIST}/batch/?ids=${uuids.join(",")}`,
)).body.map(responseToPlaylistMetadata);

/**
 * Search playlist metadata.
 *
 * @function searchPlaylists
 * @param {object} partial matching {@link module:api/types/metadata.PlaylistSearchParams}
 *
 * @return {PlaylistMetadata[]}
 */
export const search = async (partial) => {
  const res = ((await get(
    endpoints.META_LIST,
    makePlaylistSearchParams(partial),
  )).body);
  return res.map(responseToPlaylistMetadata);
};

/**
 * List all existing playlist metadata objects.
 * @function list
 * @return {PlaylistMetadata[]}
 */
export const list = async () => search({ maxItems: 1000000 });

/**
 * Get a count of total search results for the given parameters.
 *
 * @function countPlaylists
 * @return int
 */
export const countPlaylists = async (params) => (await get(
  `${endpoints.META_LIST}/count`,
  makePlaylistSearchParams(params),
)).body;
