/**
 * Paginator component.
 *
 * @module ui/component/paginator
 * @category UI
 * @subcategory Components
 */
import buttonGroup from "ui/component/button-group";
import { button, div } from "ui/html";
import icon from "ui/component/icon";

const MAX_SHOWN_PAGES = 5;

const pageRange = (currentPage, totalPages, maxShownPages) => {
  const leftOffset = Math.floor((maxShownPages - 1) / 2);
  const rightOffset = Math.ceil((maxShownPages - 1) / 2);
  let start = currentPage - leftOffset;
  let end = currentPage + rightOffset;

  if (end > totalPages) {
    start -= (end - totalPages);
    end = totalPages;
  }
  if (start <= 0) {
    end += ((start - 1) * (-1));
    start = 1;
  }

  end = end > totalPages ? totalPages : end;

  return [start, end];
};

const isFirstPage = (currentPage, totalPages) => currentPage === 1
  || (currentPage === 0 && totalPages === 0);

const isLastPage = (currentPage, totalPages) => currentPage === totalPages;

const generateButtons = (currentPage, totalPages, maxShownPages, onPageChange) => {
  const leftControls = [
    button(
      [icon("chevron-double-left")],
      () => onPageChange(1),
      ".inverse",
      "button",
      "",
      isFirstPage(currentPage, totalPages),
    ),
    button(
      [icon("chevron-left")],
      () => onPageChange(currentPage - 1),
      ".inverse",
      "button",
      "",
      isFirstPage(currentPage, totalPages),
    ),
  ];
  const rightControls = [
    button(
      [icon("chevron-right")],
      () => onPageChange(currentPage + 1),
      ".inverse",
      "button",
      "",
      isLastPage(currentPage, totalPages),
    ),
    button(
      [icon("chevron-double-right")],
      () => onPageChange(totalPages),
      ".inverse",
      "button",
      "",
      isLastPage(currentPage, totalPages),
    ),
  ];
  const numberButtons = [];
  const [start, end] = pageRange(currentPage, totalPages, maxShownPages);
  if (start === 1 && end === 0) {
    numberButtons.push(button("1", () => {}, ".disabled"));
  } else {
    for (let i = start; i <= end; i++) {
      numberButtons.push(
        button(
          i,
          () => onPageChange(i),
          `${i === currentPage ? "" : ".inverse"}`,
          "button",
          i,
          i === currentPage,
        ),
      );
    }
  }
  return [
    ...leftControls,
    ...numberButtons,
    ...rightControls,
  ];
};

/**
 * Paginator component. Can be used with anything that requires paging.
 *
 * @param {number} totalPages
 * @param {number} currentPage
 * @param {number} maxShownPages amount of pages visible on the UI
 * @param {function} onPageChange called with page number when page is clicked
 * @returns {module:ui/html/div~Div|*}
 */
export default function paginator({
  totalPages,
  currentPage,
  maxShownPages = MAX_SHOWN_PAGES,
  onPageChange = () => {},
}) {
  return div(".paginator", buttonGroup(generateButtons(
    currentPage,
    totalPages,
    maxShownPages,
    onPageChange,
  )));
}
