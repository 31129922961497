/**
 * Utility functions for helping with feature flags.
 *
 * @module util/feature-flag
 * @category Utilities
 */
import log from "log";
import { featureSources, featureTypes, featureTypesFrontend } from "model/config/constants";
import { getConfigFromCache } from "config";
import { notAuthorized } from "util/navigation";

/**
 * Feature type mapping to the actual value (server config)
 *
 * @returns {Map<featureTypes, boolean|*>}
 */
const featureTypeToServerConfig = () => {
  const config = getConfigFromCache()?.server;
  return new Map([
    [featureTypes.OPEN_SITE_ACCESS, !!config.featureSetConfig?.allowAnonymousAccess],
    [featureTypes.LMS, !!config.featureSetConfig?.lmsEnabled],
    [featureTypes.LDAP, !!config.featureSetConfig?.ldapEnabled],
    [featureTypes.GRID_FS_SIZE_IN_BYTES, config.featureSetConfig?.gridFsSizeInBytes],
    [featureTypes.GRID_FS_SIZE_LIMIT_IN_MB, config.featureSetConfig?.gridFsSizeLimitInMB],
    [featureTypes.MESSAGING, !!config.featureSetConfig?.messagingEnabled],
    [featureTypes.MAX_CONCURRENT_LOGINS, config.featureSetConfig?.maxConcurrentLogins],
    [featureTypes.REGISTRATION, !!config.featureSetConfig?.registrationEnabled],
    [featureTypes.USER_MANAGEMENT, !!config.featureSetConfig?.userManagementEnabled],
    [featureTypes.ZOOM_INTEGRATION, !!config.featureSetConfig?.zoomIntegrationEnabled],
    [featureTypes.OTP_VERIFICATION, !!config.userVerification],
  ]);
};

const featureTypeToFrontendConfig = () => {
  const config = getConfigFromCache()?.features;
  return new Map([
    [featureTypesFrontend.PWA, config.pwa === 1],
    [featureTypesFrontend.DOC_DEV, config.jsdoc === 1],
    [featureTypesFrontend.DOC_STYLEGUIDE, config.styleguide === 1],
  ]);
};

/**
 * Returns feature source Map
 *
 * @returns {Map<featureSources, Map<featureTypes, boolean|*>>}
 */
const featureSourceMap = () => new Map([
  [featureSources.SERVER_CONFIG, featureTypeToServerConfig()],
  [featureSources.FRONTEND_CONFIG, featureTypeToFrontendConfig()],
]);

/**
 * Get feature value by type from feature source
 *
 * @param {featureTypes} feature
 * @returns {boolean|*}
 */
// eslint-disable-next-line import/prefer-default-export
export const featureEnabled = (feature) => {
  const featureMaps = [...featureSourceMap().values()];
  const featureValue = featureMaps.find((featureMap) => featureMap.has(feature))?.get(feature);
  if (featureValue === undefined) {
    return true;
  }
  return featureValue;
};

/**
 * Checks if all of a list of features is enabled.
 *
 * @function featuresEnabled
 * @param {featureTypes[]} feature
 * @return boolean
 */
export const featuresEnabled = (features) => features.reduce(
  (p, c) => p && featureEnabled(c),
  true,
);

/**
 * Helper to check if a feature is enabled and redirect to not authorized page if not.
 *
 * @function notAuthorized
 * @param {featureTypes} feature
 */
export const guard = (feature) => {
  if (!featureEnabled(feature)) {
    log.error(`Feature ${feature} is not available.`);
    notAuthorized();
  }
};
