/**
 * Maps with arbitrary hashed keys.
 *
 * HashMaps work similarly to normal Maps, but when supplied with Object type keys
 * it compares the hash of the key instead of its identity.
 *
 * @module util/hash-map
 * @category Utilities
 */
import ohash from "object-hash";
import { uuidv4 } from "util/generator";

/**
 * Exported for testing only.
 */
export const hashKey = (key) => {
  if (key === undefined || key === null) return key;
  if (typeof key === "string") return key;
  if (typeof key === "number") return key;
  return ohash(key);
};

/** not to be used directly, just want self to have the type HashMap */
function HashMap() {
  const self = this;
  const id = uuidv4();
  let iteration = 0;

  const keyMap = new Map();
  const valueMap = new Map();

  Object.defineProperties(self, {
    keyPairs: {
      value: () => keyMap.entries(),
      enumerable: false,
      configurable: false,
    },
    keys: {
      value: () => keyMap.keys(),
      enumerable: false,
      configurable: false,
    },
    get: {
      value: (complexKey) => valueMap.get(hashKey(complexKey)),
      enumerable: false,
      configurable: false,
    },
    iteration: {
      get: () => iteration,
      enumerable: true,
      configurable: false,
    },
    id: {
      get: () => id,
      enumerable: true,
      configurable: false,
    },
    /**
     * Returns the hash of the provided complex key, if that hash
     * is stored in the map. Otherwise returns null.
     */
    getHash: {
      value: (complexKey) => {
        const hash = hashKey(complexKey);
        if (keyMap.has(hash)) return hash;
        return null;
      },
      enumerable: false,
      configurable: false,
    },
    set: {
      value: (complexKey, value) => {
        const hash = hashKey(complexKey);
        keyMap.set(hash, complexKey);
        valueMap.set(hash, value);
        iteration++;
      },
      enumerable: false,
      configurable: false,
    },
    size: {
      get: () => keyMap.size,
      enumerable: false,
      configurable: false,
    },
    clear: {
      value: () => {
        keyMap.clear();
        valueMap.clear();
        iteration++;
      },
      enumerable: false,
      configurable: false,
    },
    delete: {
      value: (key) => {
        keyMap.delete(hashKey(key));
        valueMap.delete(hashKey(key));
        iteration++;
      },
      enumerable: false,
      configurable: false,
    },
    entries: {
      /* eslint-disable no-restricted-syntax */
      * value() {
        for (const [k, v] of keyMap.entries()) {
          yield [v, valueMap.get(k)];
        }
      },
      enumerable: false,
      configurable: false,
      /* eslint-enable no-restricted-syntax */
    },
    has: {
      value: (complexKey) => keyMap.has(hashKey(complexKey)),
      enumerable: false,
      configurable: false,
    },
    /**
     * Set multiple key/value pairs. Meant to be used with an array of
     * [[key, value], ...] or something else with a `forEach`
     * implementation that matches (like a normal Map).
     *
     * @throws Error when attempting to append to an already-existing key.
     */
    append: {
      value: (pairs) => {
        pairs.forEach(([k, v]) => {
          if (self.has(k)) throw new Error("HashMap: attempted to append with pre-existing key");
          self.set(k, v);
        });
      },
      enumerable: false,
      configurable: false,
    },
    /**
     * Set multiple key/value pairs. Meant to be used with an array of
     * [[key, value], ...] or something else with a `forEach`
     * implementation that matches (like a normal Map).
     */
    update: {
      value: (pairs) => {
        pairs.forEach(([k, v]) => {
          self.set(k, v);
        });
      },
      enumerable: false,
      configurable: false,
    },
    /**
     * Like Map.forEach
     */
    forEach: {
      /* eslint-disable no-restricted-syntax */
      value: (cb) => {
        for (const entry of self.entries()) {
          cb(entry);
        }
      },
      /* eslint-entry no-restricted-syntax */
      enumerable: false,
      configurable: false,
    },
    /**
     * Like Array.filter, but calls with `[key, value]` and returns a new hash-map.
     */
    filter: {
      value: (cb) => {
        /* eslint-disable-next-line no-use-before-define */
        const newCol = makeHashMap();
        self.forEach(([key, value]) => {
          if (cb([key, value])) newCol.set(key, value);
        });

        return newCol;
      },
      enumerable: false,
      configurable: false,
    },
    /**
     * Filters on value only (works with array-style filter callbacks).
     */
    filterValues: {
      value: (cb) => {
        /* eslint-disable-next-line no-use-before-define */
        const newCol = makeHashMap();
        self.forEach(([key, value]) => {
          if (cb(value)) newCol.set(key, value);
        });

        return newCol;
      },
      enumerable: false,
      configurable: false,
    },
    // cb([key, value]) -> [newKey, newValue]
    map: {
      value: (cb) => {
        /* eslint-disable-next-line no-use-before-define */
        const newCol = makeHashMap();
        self.forEach((pair) => newCol.set(...cb(pair)));
        return newCol;
      },
      enumerable: false,
      configurable: false,
    },
    filterMap: {
      value: (filterCb, mapCb) => {
        /* eslint-disable-next-line no-use-before-define */
        const newCol = makeHashMap();
        self.forEach(([key, value]) => {
          if (filterCb([key, value])) newCol.set(...mapCb([key, value]));
        });

        return newCol;
      },
      enumerable: false,
      configurable: false,
    },
    values: {
      value: () => valueMap.values(),
      enumerable: false,
      configurable: false,
    },
    /**
     * Used internally for type detection in util/object because otherwise dep cycle :/
     */
    doNotMergeOrClone: {
      value: true,
      enumerable: false,
      configurable: false,
    },
    merge: {
      value: (old) => {
        if (!(old instanceof HashMap)) throw new Error("can only merge other hashmaps");
        iteration += old.iteration;
        self.update(old);
      },
      enumerable: false,
      configurable: false,
    },
  });

  return this;
}

/**
 * Create a HashMap.
 *
 * HashMaps work similarly to normal Maps, but when supplied with Object type keys
 * it compares the hash of the key instead of its identity.
 *
 * Note that keys are cloned and their values frozen, so manipulating the
 * original will not change the key in the map or invalidate its hash; likewise,
 * iterating over keys will yield immutable keys.
 *
 * Couple other things to keep in mind:
 * - the map is internally mutable, so Object.freeze / util.frozen will not prevent its
 *   contents from being altered
 * - it takes about 2x the memory and 2x the lookup time as a regular map
 *
 * For those reasons it should only be used when actually needed.
 *
 * @example
 * const cm = makeHashMap();
 * cm.set(["foo", "bar"], "baz"); // HashMap hashes the key, so the following works:
 * cm.get(["foo", "bar"]); // -> "baz"
 *
 * // Further, HashMap acts as a factory, not a constructor, so the following
 * // is valid and works without function binding:
 *
 * const { get, set } = makeHashMap();
 * set(["foo", "bar"], "baz");
 * get(["foo", "bar"]); // -> "baz"
 *
 * @function makeHashMap
 * @param {?Map|HashMap} old old map to copy from
 */
export default function makeHashMap(old) {
  const self = new HashMap();

  if (old instanceof HashMap) {
    self.merge(old);
  }

  // clone the old map if there was one
  if (old instanceof Map || old instanceof Array) {
    old.forEach(([k, v]) => {
      self.set(k, v);
    });
  }

  return self;
}
