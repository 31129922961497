/**
 * Chat layout provider
 *
 * @module ui/component/chat/interface
 * @category UI
 * @subcategory Chat
 */
import { threadStatuses } from "@smartedge/em-message/constants";
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import div from "ui/html/div";
import messageInput from "ui/component/chat/message-input";
import chatWindow from "ui/component/chat/window";
import button from "ui/component/form-managed/button";
import { frozen, merge } from "util/object";
import { filterMap } from "util/array";
import { getIconName } from "ui/component/group-icon";
import { inboxTypes } from "model/message/constants";
import collapsible from "ui/component/collapsible";
import { hasUnreadMessagesInChannel } from "ui/page/chat/common";
import getUsername from "ui/component/messaging/username";
import currentChatHeader from "./current-chat-header";
import { chatTypes } from "./common";
import allMessages from "./all-messages";

const defaultState = frozen({
  selectedChannel: null,
  onSelectChannel: () => {},
  onSelectChatType: () => {},
  onAddUser: () => {},
  onBlockUser: () => {},
  onClickUser: () => {},
  onRemoveUser: () => {},
  sel: "",
  selectedChatType: chatTypes.NONE,
});

const getChatLayout = (state, modalView) => {
  const { selectedChatType } = state;
  switch (selectedChatType) {
    case chatTypes.REGULAR_MESSAGING:
      return [
        currentChatHeader(state, modalView),
        chatWindow(state, modalView),
        messageInput(state, modalView),
      ];
    case chatTypes.ALL_MESSAGES:
      return [
        ...allMessages(state, modalView),
      ];
    case chatTypes.NONE:
    default:
      return [
        div(".chat-no-channel-selected", [
          div(".label", "No channel selected"),
        ]),
      ];
  }
};

const channelBtn = (state) => (channel) => {
  const hasUnreadMessages = hasUnreadMessagesInChannel(state.channelMessages.get(channel.id));
  return div(".channel-container", [
    button({
      label: channel.type === inboxTypes.GROUP
        ? systemPrivilegeGroupsFriendly.get(channel.name) || channel.name
        : getUsername(channel),
      icon: channel.type === inboxTypes.GROUP
        ? getIconName(channel.name)
        : "user",
      onClick: () => {
        state.onSelectChatType(chatTypes.REGULAR_MESSAGING);
        state.onSelectChannel(channel);
      },
      sel: `.channel${hasUnreadMessages ? ".unread-channel" : ""}${state.selectedChannel?.id === channel.id
        ? ".primary"
        : ".inverse"}`,
    }),
    channel.type === inboxTypes.DM ? button.icon({
      icon: "trash",
      sel: ".remove-channel-btn",
      onClick: () => {
        state.onRemoveUser(channel);
      },
    }) : "",
  ]);
};

const channels = (state, self) => div(".chat-channels", [
  button({
    label: "Latest DMs",
    icon: "comments",
    sel: `.channel.top-level${state.selectedChatType === chatTypes.ALL_MESSAGES ? ".primary" : ""}`,
    onClick: () => {
      state.onSelectChatType(chatTypes.ALL_MESSAGES);
    },
  }),
  collapsible({
    title: "Threads",
    open: state.channelsOpened,
    onChange: (opened) => {
      self.updateState({
        channelsOpened: opened,
      });
    },
  }, [
    ...state.channels
      .reduce(filterMap(
        (channel) => (
          channel.type === inboxTypes.GROUP
          && channel.status !== threadStatuses.REMOVED
        ),
        channelBtn(state),
      ), []),
  ]),
  collapsible({
    title: "Direct Messages",
    open: state.directMessagesOpened,
    onChange: (opened) => {
      self.updateState({
        directMessagesOpened: opened,
      });
    },
  }, [
    ...state.channels
      .reduce(filterMap(
        (channel) => (
          channel.type === inboxTypes.DM
          && channel.id !== state.me.id
          && !channel.conversation?.blocked
        ),
        channelBtn(state),
      ), []),
  ]),
  button({
    icon: "plus-circle",
    label: "add user",
    onClick: state.onAddUser,
    sel: ".add-dm-user.channel",
  }),
]);

export default function chatInterface(inState, self, modalView) {
  const state = merge(defaultState, inState);

  return div(`${state.sel}.chat-interface`, [
    div(".panes", [
      div(".pane", [
        channels(state, self),
      ]),
      div(".pane", getChatLayout(state, modalView)),
    ]),
  ]);
}
