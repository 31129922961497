/**
 * Formats a username for use in admin messaging.
 *
 * @module ui/component/messaging/username
 * @category UI
 * @subcategory Messaging Components
 */

export default function getUsername(user, short = false) {
  if (!user) return "(unknown user)";
  let name = "";
  if (!short) {
    if (user.firstName?.length && user.lastName?.length) {
      name = `${user.firstName} ${user.lastName} (${user.username || user.name})`;
    } else if (user.firstName?.length) {
      name = `${user.firstName} (${user.username || user.name})`;
    } else if (user.firstName?.length === 0) {
      name = user.username || user.name;
    }
  } else name = user.username;
  // is a group if it has just 'name'
  if (!name && user.name) return user.name || "(unknown group)";
  return name || "(unknown user)";
}
