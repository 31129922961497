/**
 * The password reset view for the login self.
 *
 * @module ui/self/login/reset
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import log from "log";
import api from "api";
import { verificationMethods } from "model/config/constants";
import { verificationResendTypes } from "model/user/constants";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import username from "ui/component/form-managed/field-username";
import passwordConfirmed from "ui/component/form-managed/field-password-confirmed";
import otp from "ui/component/form-managed/field-otp";
import message from "ui/component/message";
import h1 from "ui/html/h1";
import { tidyBackendError } from "util/error";
import { onEnterKey } from "util/event";
import { merge } from "util/object";
import { sendCode } from "./verification";
import messages from "./messages";
import {
  initLogin,
  initPasswordReset,
} from "./common";

/**
 * Handles submission of the password reset form.
 *
 * @function doPasswordReset
 * @private
 */
const doPasswordReset = async (self, notification) => {
  self.setFullValidation(true);
  if (!self.validate().valid) {
    notification.post(messages.invalid);
    return;
  }

  try {
    self.disable();
    self.setFullValidation(false);
    const response = await api.user.resetPassword(self.values);
    if (response) {
      notification.post(messages.successfulPasswordReset);
      initLogin(self, self.values);
    } else {
      notification.post(messages.signInInvalid);
    }
  } catch (error) {
    log.error("error submitting password reset", error);
    tidyBackendError(error).map((text) => notification.post(
      merge(messages.apiError, { text }),
    ));
  }
  self.enable();
};

/**
 * Initiate a password reset.
 *
 * A verification code is dispatched to the user's registered email address or
 * phone number so that he can verify his account and complete the process.
 *
 * @function doPasswordResetUsername
 */
const doPasswordResetUsername = async (self, notification) => {
  self.setFullValidation(true);
  if (!self.validate().valid) {
    notification.post(messages.invalid);
    return;
  }
  self.disable();
  if ((await sendCode(
    self,
    verificationResendTypes.RESET_PASSWORD,
    notification,
  ))) {
    initPasswordReset(self);
  }
  self.enable();
};

/**
 * Show the username entry step for password resets.
 *
 * @function showPasswordResetUsername
 * @private
 * @param {View} self
 */
export const showPasswordResetUsername = (self, notification) => {
  const { userVerification } = self.state.config.server;
  // if neither email nor SMS verification is required we have no
  // reliable way to dispatch a reset code, so an administrator will
  // have to intervene instead
  if (userVerification === verificationMethods.ADMIN) {
    notification.post(messages.passwordResetContactAdmin);
    initLogin(self);
    return form("#password-reset");
  }

  return form(
    "#password-reset",
    self.bind([
      h1("Forgot Password"),
      message({
        type: "hint",
        text: "Please enter your registered username to continue.",
        key: "forgot-password-hint",
      }),
      [username, { required: true, autofocus: true }],
      [button, {
        label: "Continue",
        onClick: () => doPasswordResetUsername(self, notification),
        sel: "#continue-button",
      }],
      [button, {
        label: "Back to Login",
        onClick: () => initLogin(self),
        sel: ".subtle.ok",
      }],
    ], self.state.login),
    {
      on: {
        submit: (e) => {
          e.preventDefault();
          onEnterKey(doPasswordResetUsername(self, notification))(e);
        },
      },
    },
  );
};

/**
 * Show the password reset form.
 *
 * @function showPasswordReset
 * @private
 * @param {View} self
 */
export default (self, notification) => form(
  "#password-reset",
  self.bind([
    h1("Forgot Password"),
    [username, { required: true, autofocus: true }],
    [otp, { required: true }],
    [passwordConfirmed, { required: true, name: "password" }],
    [button, {
      label: "Save New Password",
      onClick: () => doPasswordReset(self, notification),
      sel: "#save-password-button",
    }],
    [button, {
      label: "Back to Login",
      onClick: () => initLogin(self, self.values),
      sel: ".subtle.ok",
    }],
  ], self.state.login),
);
