/**
 * The main management page for courses.
 *
 * Corresponds to `markup/admin/manage-courses.html`
 *
 * @module ui/page/admin/course/manage
 * @category Pages
 * @subcategory Admin - Course
 */
import { featureTypes } from "model/config/constants";
import courseStream from "data/course";
import meStream from "data/user/me";
import actionBar from "ui/component/dashboard-action-bar";
import {
  a, div, section, span,
} from "ui/html";
import table, { sortOrders } from "ui/component/dynamic-table";
import widget from "ui/component/dashboard-widget";
import placeholder from "ui/component/placeholder";
import view from "ui/view";
import actionBarSearch from "ui/component/action-bar-search";
import icon from "ui/component/icon";
import { courseAdvancedFilter, courseTextFilter } from "util/filter";
import { courseSort } from "util/sort";
import { courseSortCriteria } from "model/course/constants";
import advancedCourseSearchModal from "ui/component/modal/advanced-course-search";
import dashboardLayout from "ui/page/layout/dashboard";
import spinner from "ui/component/spinner";
import { guard } from "util/feature-flag";
import { defaultManageState, searchModes, defaultSearchParams } from "./state";

let header, loading, manage, modal, search;

/**
 * Clears search results.
 * @function clearSearchResult
 * @private
 */
const clearSearchResult = () => {
  const update = {
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  };
  manage.update(update);
  search.update(update);
};

/**
 * Updates the search params with the courses of the basic search filter.
 *
 * @function doFilter
 * @private
 */
const doFilter = (self, filter) => {
  if (!filter) {
    clearSearchResult();
    return;
  }
  const update = {
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  };
  self.update(update);
  manage.update(update);
};

/**
 * Figures out which courseSortCriteria to used based on sortColumn and sortOrder
 * state properties.
 * @function getSortCriteria
 * @private
 */
export const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "createdAt":
      return (sortOrder === sortOrders.ASC
        ? courseSortCriteria.DATE_ASC
        : courseSortCriteria.DATE_DESC);
    case "id":
      return (sortOrder === sortOrders.ASC
        ? courseSortCriteria.ID_ASC
        : courseSortCriteria.ID_DESC);
    case "entriesAmount":
      return (sortOrder === sortOrders.ASC
        ? courseSortCriteria.ENTRIES_ASC
        : courseSortCriteria.ENTRIES_DESC);
    case "title":
    default:
      return (sortOrder === sortOrders.ASC
        ? courseSortCriteria.TITLE_ASC
        : courseSortCriteria.TITLE_DESC);
  }
};

/**
 * Handles changing sort order.
 * @function onSortChange
 * @private
 */
const onSortChange = (self) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === self.state.sortColumn) {
    if (self.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  self.update({
    sortOrder,
    sortColumn,
  });
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function showAdvancedSearchModal
 * @private
 */
const showAdvancedSearchModal = (self) => async () => {
  clearSearchResult();
  const searchParams = await modal.async(
    advancedCourseSearchModal({
      params: self.state.searchParams,
    }, modal),
  );
  if (searchParams) {
    manage.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
  }
};

/**
 * Creates row objects for dynamicTable out of metadata.
 * @function tableRow
 * @private
 */
const tableRow = (course) => ({
  createdAt: span(course.createdAt?.toLocaleDateString() || '--'),
  id: a([
    icon.solid("edit"),
    course.id,
  ], `/admin/course-profile?id=${course.id}`),
  title: span(course.title),
  entriesAmount: span(course.moduleIds?.length),
});

const showManageCourses = (self) => section("#manage-courses", table({
  columnLabels: ["Created", "ID", "Title", "Modules"],
  columns: ["createdAt", "id", "title", "entriesAmount"],
  placeholder: self.state.loading > 0 ? spinner() : "No courses found.",
  sortColumn: self.state.sortColumn,
  sortOrder: self.state.sortOrder,
  rows: self.state.filteredCourses.map(tableRow),
  onSortChange: onSortChange(self),
  loading: self.state.loading > 0,
}));

/**
 * Sets up the search action bar.
 * @function showSearch
 * @private
 */
const showSearch = (self) => actionBarSearch({
  resultCount: self.state.filteredCourses.length,
  filter: self.state.searchParams.filter,
  showClear: self.state.searchMode !== searchModes.NONE,
  onSearch: (filterText) => doFilter(self, filterText),
  onAdvancedSearch: showAdvancedSearchModal(self),
  onClear: () => clearSearchResult(),
  title: "All Courses",
});

const doUpdate = (self) => {
  const {
    searchMode,
    courses,
    sortColumn,
    sortOrder,
    searchParams,
  } = self.state;
  let filteredCourses;
  const courseItems = [...courses.get([defaultSearchParams, 0])?.items || []];
  switch (searchMode) {
    case searchModes.BASIC:
      filteredCourses = courseItems.filter(courseTextFilter(searchParams.filter));
      break;
    case searchModes.ADVANCED:
      filteredCourses = courseItems.filter(courseAdvancedFilter(searchParams));
      break;
    case searchModes.NONE:
    default:
      filteredCourses = courseItems;
  }
  filteredCourses.sort(courseSort(getSortCriteria(sortColumn, sortOrder)));

  self.updateState({ filteredCourses });
  self.render();
  if (search) search.update(self.state);
};

export default async function manageCourses(selector) {
  guard(featureTypes.LMS);
  const title = "Manage Courses";
  ({ modal, loading, header } = dashboardLayout(
    selector,
    [
      actionBar(div("#search")),
      widget(placeholder("Loading...", "#manage-courses")),
    ],
    title,
    true,
  ));
  loading.show();
  header.update({ title });
  manage = view.create(showManageCourses, doUpdate)('#manage-courses', defaultManageState);
  search = view.create(showSearch)("#search", defaultManageState);

  manage.bindStreams(
    [
      ["user", meStream.get()],
      ["courses", courseStream.search([defaultSearchParams, 0])], // FIXME search params when API is available
      ["courseModules", courseStream.getModules()],
      ["loading", courseStream.pending$],
    ],
    () => loading.hide(),
    new Set(["user", "courses"]),
  );

  loading.hide();
}
