/**
 * A collection of labels for display to users.
 *
 * @module ui/component/label-collection
 * @category UI
 * @subcategory Components
 */
import {
  div,
  span,
} from "ui/html";
import icon from "ui/component/icon";

const clickHandler = (choice, callback) => () => {
  if (callback) callback(choice);
};

/**
 * A collection of content labels with optional onclick handler.
 * @param {Category[]} labels labels to display
 * @param {function} [onClick] called with the selected label when the user clicks it
 * @param {boolean} [showDelete] show times icon to remove a label
 * @return {module:ui/html/div} `div.label-collection`
 */
const labelCollection = (labels, onClick = () => {}, showDelete = true) => div(
  ".label-collection",
  labels.map((label) => span(
    [label.name, showDelete ? icon.sharp("xmark") : ""],
    ".label",
    { on: { click: clickHandler(label, onClick) } },
  )),
);

export default labelCollection;
