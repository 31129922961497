/**
 * A modal for manual evaluation grading.
 *
 * @module ui/component/modal/evaluation/evaluation-manual-grading
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  div, h3, h4, span,
} from "ui/html";
import icon from "ui/component/icon";
import cameoAnswer from "ui/component/cameo-answer";
import { questionType } from "model/course/constants";
import cameoFile from "ui/component/cameo-file";
import form from "ui/component/form-managed";
import textarea from "ui/component/form-managed/field-textarea";
import number from "ui/component/form-managed/field-number";
import description from "ui/component/form-managed/field-description";
import button from "ui/component/form-managed/button";
import dialog from "ui/component/modal/layout/dialog";
import { merge } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import bindModalForm, { deleteStoredForm } from "ui/component/modal/bound-form";

const staticMessages = {
  invalid: { title: "Invalid Form", text: "Please fix the errors.", type: notificationMessageTypes.FAIL },
  maxPointsExceeded: { title: "Invalid Value", text: "Maximum points exceeded", type: notificationMessageTypes.FAIL },
};

const defaultState = {
  solution: {},
  files: new Map(),
};

const buildAnswerBlock = (self) => {
  const { state } = self;
  const { solution } = state;
  switch (solution.question.questionType) {
    case questionType.FILL_IN_LONG:
    case questionType.FILL_IN_SHORT:
      return self.bind([
        [textarea, { name: "value", value: solution.value?.[0], label: "Answer", required: true, disabled: true }],
      ]);
    case questionType.UPLOAD:
      return solution.value.map((fileId) => cameoFile({
        file: state.files.get(fileId),
        downloadable: true,
      }));
    case questionType.MULTIPLE_CHOICE_SINGLE_ANSWER:
    case questionType.MULTIPLE_CHOICE_MULTI_ANSWER:
    default:
      return solution.question.answers.map((answer) => cameoAnswer({
        answer,
        existingAnswers: solution.question.answers,
        viewOnly: true,
        highlighted: solution.value.some(
          (userValue) => userValue.toLowerCase() === answer.value.toLowerCase(),
        ),
      }));
  }
};

const missingQuestionWarning = (question) => {
  switch (question.type) {
    case questionType.MISSING:
      return span([icon("exclamation-square"), "The original question could not be found. The participant's answers are provided below without context."], ".danger");
    case questionType.SUBSTITUTED:
      return span([icon("exclamation-triangle"), "This question was changed or removed after the participant completed the evaluation. The question text and given answers may not match the new version."], ".accent");
    default:
      return "";
  }
};

const isAutoGraded = (type) => (
  type === questionType.MULTIPLE_CHOICE_MULTI_ANSWER
  || type === questionType.MULTIPLE_CHOICE_SINGLE_ANSWER
);

const buildManualEvaluationForm = (self) => {
  const { state } = self;
  const { solution } = state;
  return form(
    "#evaluation-manual-grading",
    [
      div(".pane.grow", [
        missingQuestionWarning(solution.question),
        textarea({ required: true, disabled: true, name: "text", value: solution.question?.text, label: "Question" }),
        h4("Answers"),
        ...buildAnswerBlock(self),
      ]),
      div(".pane.with-divider", div(".divider")),
      div(".pane.grow", [
        h4("Points awarded"),
        number({
          required: true,
          name: "points",
          value: solution?.score?.points !== undefined && solution?.score?.points !== null ? `${solution.score.points}` : "",
          label: "Points Awarded",
          disabled: isAutoGraded(solution.question.questionType),
        }),
        div(".used-points", `Max points: ${solution.question.weight}`),
        h4("Feedback"),
        description({ value: solution.score?.description, label: "Feedback" }),
      ]),
    ],
  );
};

const onSave = (view) => {
  view.setFullValidation(true);
  const validation = view.validate(true);
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return null;
  }
  const { solution } = view.state;
  if (view.values.points > solution.question.weight) {
    getNotification().post(staticMessages.maxPointsExceeded);
    return null;
  }
  return getModal().resolve({
    ...solution,
    score: {
      ...solution.score,
      description: view.values.description,
      points: view.values.points,
    },
  });
};

/**
 * Manual grading modal
 *
 * @param inState
 * @param {Partial<Solution>} inState.solution
 * @param getModal()
 * @param notificationView
 * @returns {*|El}
 */
const evaluationManualGrading = (inState = defaultState) => {
  const state = merge(defaultState, inState);
  deleteStoredForm("evaluation-manual-grading");
  const formNode = bindModalForm("evaluation-manual-grading", buildManualEvaluationForm, state, false);
  return dialog({
    sel: ".evaluation-manual-grading",
    header: h3("Manual Grading"),
    body: formNode,
    footerSpacer: true,
    footer: [
      div(".buttons", { attrs: { role: "group" } }, [
        button.primary({
          label: "Save",
          onClick: () => onSave(formNode.view),
        }),
      ]),
    ],
  }, getModal());
};

export default evaluationManualGrading;
