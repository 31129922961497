/**
 * Default grading schemes to show when none are available from backend.
 *
 * @module model/course/default-grading-schemes
 * @category Model
 * @subcategory Course
 */
import { frozen } from "util/object";
/**
 * Grading scheme types
 *
 * @enum {string} gradingSchemeTypes
 * @readonly
 * @property {string} AMERICAN
 * @property {string} CALIFORNIA
 */
export const defaultGradingSchemeTypes = Object.freeze({
  AMERICAN: "American",
  LETTER_DETAIL: "Detailed Letter",
  CALIFORNIA: "California",
});

/**
 * Objects for grading schemes.
 *
 * @constant gradingSchemes
 * @type {Map.<gradingSchemeTypes, object>}
 */
export const defaultGradingSchemes = frozen(new Map([
  [
    defaultGradingSchemeTypes.AMERICAN,
    {
      description: "(A, B, C, D, F)",
      gradeBrackets: [
        {
          pointsFrom: 90,
          pointsTo: 100,
          description: "A",
        },
        {
          pointsFrom: 80,
          pointsTo: 89,
          description: "B",
        },
        {
          pointsFrom: 70,
          pointsTo: 79,
          description: "C",
        },
        {
          pointsFrom: 60,
          pointsTo: 69,
          description: "D",
        },
        {
          pointsFrom: 0,
          pointsTo: 59,
          description: "F",
        },
      ],
    },
  ],
  [
    defaultGradingSchemeTypes.CALIFORNIA,
    {
      description: "(A, B, C)",
      gradeBrackets: [
        {
          pointsFrom: 90,
          pointsTo: 100,
          description: "A",
        },
        {
          pointsFrom: 80,
          pointsTo: 89,
          description: "B",
        },
        {
          pointsFrom: 0,
          pointsTo: 79,
          description: "C",
        },
      ],
    },
  ],
  [
    defaultGradingSchemeTypes.LETTER_DETAIL,
    {
      description: "(A+ … C-, D, F)",
      gradeBrackets: [
        {
          pointsFrom: 98,
          pointsTo: 100,
          description: "A+",
        },
        {
          pointsFrom: 93,
          pointsTo: 97,
          description: "A",
        },
        {
          pointsFrom: 90,
          pointsTo: 92,
          description: "A-",
        },
        {
          pointsFrom: 87,
          pointsTo: 89,
          description: "B+",
        },
        {
          pointsFrom: 83,
          pointsTo: 86,
          description: "B",
        },
        {
          pointsFrom: 80,
          pointsTo: 82,
          description: "B-",
        },
        {
          pointsFrom: 77,
          pointsTo: 79,
          description: "C+",
        },
        {
          pointsFrom: 73,
          pointsTo: 76,
          description: "C",
        },
        {
          pointsFrom: 70,
          pointsTo: 72,
          description: "C-",
        },
        {
          pointsFrom: 60,
          pointsTo: 69,
          description: "D",
        },
        {
          pointsFrom: 0,
          pointsTo: 59,
          description: "F",
        },
      ],
    },
  ],
]));
