/**
 * A collection of modal components.
 *
 * @see module:ui/component/modal/basic
 * @see module:ui/component/modal/content
 *
 * @module ui/component/modal
 * @category UI
 * @subcategory Modal Dialogs
 */
import basic from "./layout/basic";
import content from "./content";
import advancedContentSearch from "./advanced-content-search";
import advancedUserSearch from "./advanced-user-search";
import advancedGroupSearch from "./advanced-group-search";

basic.content = content;
basic.advancedContentSearch = advancedContentSearch;
basic.advancedUserSearch = advancedUserSearch;
basic.advancedGroupSearch = advancedGroupSearch;

export default basic;
