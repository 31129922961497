/**
 * A modal for text entry question editing.
 *
 * @module ui/component/modal/assessment/question-text-entry
 * @category UI
 * @subcategory Modal Dialogs
 */
import { questionType } from "model/course/constants";
import { notificationMessageTypes } from "model/notification/constants";
import icon from "ui/component/icon";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import number from "ui/component/form-managed/field-number";
import textarea from "ui/component/form-managed/field-textarea";
import dialog from "ui/component/modal/layout/dialog";
import multiToggle from "ui/component/multi-toggle";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import h4 from "ui/html/h4";
import span from "ui/html/span";
import formManagedView from "ui/view/form-managed";
import { getModal } from "ui/view/modal-view";
import { getNotification } from "ui/view/notification-view";
import { merge } from "util/object";
import questionGradingHelpModal from "../help/question-grading";
import questionTextLengthHelpModal from "../help/question-text-length";

let storedFormView;

const staticMessages = {
  invalid: { title: "Invalid Form", text: "Please correct all errors to continue.", type: notificationMessageTypes.FAIL },
  pointsExceededLimit: { title: "Error", text: "Weight limit exceed. Reduce question weight.", type: notificationMessageTypes.FAIL },
};

const defaultState = {
  validation: {
    fields: {},
  },
  question: {
    text: "",
    questionType: questionType.FILL_IN_SHORT,
    answers: [],
    weight: undefined,
  },
  messages: [],
};

const bindForm = () => {
  const formEl = document.createElement("FORM");
  formEl.id = "text-entry";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView.rebind("#text-entry");
};

/**
 * @function onFormUpdate
 * @private
 */
const onFormUpdate = (inner) => {
  inner.patch(inner.factory(inner));
};

const buildMultipleChoiceForm = (self) => {
  const { state } = self;
  return form(
    "#text-entry",
    self.bind([
      [textarea, { name: "text", label: "Question", required: true }],
      div(".container-help", [
        h4("Text Field"),
        icon("question-square", ".show-help", {
          on: {
            click: () => getModal().async(questionTextLengthHelpModal()),
          },
        }),
        multiToggle({
          options: [
            {
              label: 'Short',
              name: "questionType",
              id: questionType.FILL_IN_SHORT,
              value: questionType.FILL_IN_SHORT,
            },
            {
              label: 'Long',
              name: "questionType",
              id: questionType.FILL_IN_LONG,
              value: questionType.FILL_IN_LONG,
            },
          ],
          selectedTab: state.question.questionType === questionType.FILL_IN_SHORT ? 0 : 1,
          onSelect: (qt) => self.updateState({ question: { questionType: qt } }),
        }),
      ]),
      div(".container-help", [
        h4("Grading"),
        icon("question-square", ".show-help", {
          on: {
            click: () => getModal().async(questionGradingHelpModal()),
          },
        }),
      ]),
      [number, { required: true, label: "Weight", name: "weight" }],
      div(".used-points", `Points used: ${state.usedPoints} of ${state.maxPoints}`),
    ], state.question),
  );
};

const initForm = (state) => {
  if (storedFormView) {
    bindForm();
    return;
  }
  const formEl = document.createElement("FORM");
  formEl.id = "text-entry";
  getModal().element.querySelector(".body")
    .replaceChildren(formEl);
  storedFormView = formManagedView(
    buildMultipleChoiceForm,
    onFormUpdate,
  )(
    "#text-entry",
    state,
  );
};

const onSave = () => {
  storedFormView.setFullValidation(true);
  const validation = storedFormView.validate(true);
  if (!validation.valid) {
    getNotification().post(staticMessages.invalid);
    return null;
  }
  const { state, values } = storedFormView;
  if (state.usedPoints - (state.question.weight || 0) + (+values.weight) > state.maxPoints) {
    getNotification().post(staticMessages.pointsExceededLimit);
    return null;
  }
  storedFormView.updateState({
    question: {
      text: storedFormView.values.text,
      weight: parseInt(storedFormView.values.weight, 10) || 0,
      questionType: storedFormView.values.questionType,
    },
  });
  return getModal().resolve(storedFormView.state.question);
};

/**
 * Text entry modal
 *
 * @param inState
 * @param {Partial<Question>} inState.question
 * @param {number} inState.maxPoints
 * @param {number} inState.usedPoints
 * @param getModal()
 * @param notificationView
 * @returns {*|El}
 */
export default function questionTextEntryModal(inState = defaultState) {
  storedFormView = null;
  const state = merge(defaultState, inState);
  return dialog({
    sel: ".form.assessment-question.question-text-entry",
    config: {
      hook: {
        insert: () => initForm(state),
        postPatch: () => initForm(state),
      },
    },
    header: h3("Text Entry"),
    body: span("placeholder"),
    footer: [
      div(".buttons", { attrs: { role: "group" } }, [
        button({ label: "Save", onClick: onSave }),
      ]),
    ],
  }, getModal());
}
