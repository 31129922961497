/**
 * Universal layout structure for modal dialogs.
 *
 * @module ui/component/modal/layout/dialog
 * @category UI
 * @subcategory Modal Dialogs
 */
import div from "ui/html/div";
import closeFab from "ui/component/modal/component/close-fab";
import { span } from "ui/html";

export default function Dialog({
  sel = "",
  header = null,
  body = null,
  footer = null,
  footerSpacer = false,
  onClose = null,
  isAsync = true,
  withCloseFab = true,
  config = {},
}, modalView) {
  return div(
    `.dialog${body ? "" : ".no-body"}${header ? ".with-header" : ""}${footer ? ".with-footer" : ""}${withCloseFab ? "" : ".no-close"}${sel}`,
    config,
    [
      withCloseFab ? closeFab(isAsync, modalView, onClose) : "",
      header ? div(".header", header) : "",
      // wrapping body here is mainly to fix styling issues with scrollable bodies
      body ? div(".content", div(".body", body)) : "",
      footerSpacer ? div(".spacer", span("", ".divider")) : "",
      footer ? div(".footer", footer) : "",
    ],
  );
}
