import collapsible from "ui/component/collapsible";
import addContentBulk from "ui/component/modal/help/add-content-bulk";
import addContent from "ui/component/modal/help/add-content";
import addNewPage from "ui/component/modal/help/add-new-page";
import addNewPageSection from "ui/component/modal/help/add-new-page-section";
import addUserBulk from "ui/component/modal/help/add-user-bulk";
import adminDashboard from "ui/component/modal/help/admin-dashboard";
import courseWelcome from "ui/component/modal/help/course-welcome";
import editMenu from "ui/component/modal/help/edit-menu";
import evaluationAdvancedSearch from "ui/component/modal/help/evaluation-advanced-search";
import ftp from "ui/component/modal/help/ftp";
import home from "ui/component/modal/help/home";
import managePages from "ui/component/modal/help/manage-pages";
import pageSectionNoEntries from "ui/component/modal/help/page-section-no-entries";
import pageSectionSearchTerms from "ui/component/modal/help/page-section-search-terms";
import questionGrading from "ui/component/modal/help/question-grading";
import questionTextLength from "ui/component/modal/help/question-text-length";
import search from "ui/component/modal/help/search";
import systemPrivilege from "ui/component/modal/help/system-privilege";
import button from "ui/component/form-managed/button";
import { getModal } from "ui/view/modal-view";

const helpModalButton = (label, modal) => button({
  label,
  icon: "question-square",
  onClick: async () => getModal().async(modal(true, true)),
  sel: ".subtle.forward",
});

export default () => collapsible({
  title: "Help Modals",
  open: false,
}, [
  helpModalButton("Add Content Bulk", addContentBulk),
  helpModalButton("Add Content", addContent),
  helpModalButton("Add Page", addNewPage),
  helpModalButton("Add Page Section", addNewPageSection),
  helpModalButton("Add User Bulk", addUserBulk),
  helpModalButton("Admin Dashboard", adminDashboard),
  helpModalButton("Course Welcome", courseWelcome),
  helpModalButton("Edit Menu", editMenu),
  helpModalButton("Evaluation Advanced Search", evaluationAdvancedSearch),
  helpModalButton("FTP", ftp),
  helpModalButton("Home", home),
  helpModalButton("Manage Pages", managePages),
  helpModalButton("Page Section No Entries", pageSectionNoEntries),
  helpModalButton("Page Section Search Terms", pageSectionSearchTerms),
  helpModalButton("Question Grading", questionGrading),
  helpModalButton("Question Text Length", questionTextLength),
  helpModalButton("Search", search),
  helpModalButton("System Privilege", systemPrivilege),
]);
