/**
 * The user-facing send feedback page.
 *
 * @module ui/page/feedback
 * @category Pages
 * @subcategory User Facing
 */
import { pageVisit } from "api/v2/analytics";
import cache from "cache";
import {
  div,
  h1,
} from "ui/html";
import layout from "ui/page/layout";
import { frozen } from "util/object";
import getConfig from "config";
import formManagedView from "ui/view/form-managed";
import button from "ui/component/form-managed/button";
import textarea from "ui/component/form-managed/field-textarea";
import form from "ui/component/form-managed";
import api from "api";
import { notificationMessageTypes } from "model/notification/constants";

let notificationView;

const staticMessages = frozen({
  success: {
    title: "Success",
    text: "Thank you for your feedback!",
    type: notificationMessageTypes.SUCCESS,
  },
  backendErrors: {
    title: "Error",
    text: "A server error occurred. Please report this to the operators.",
    type: notificationMessageTypes.FAIL,
  },
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: notificationMessageTypes.FAIL,
    duration: 3,
  },
});

const doUpdate = (self) => {
  self.render();
};

const buildFeedbackObject = (page) => {
  const { state, values } = page;
  const template = `
    <style>p {margin: 0}</style>
    <p><b>Backend Version:</b> ${state.config.server.build.version}</p>
    <p><b>Frontend Version:</b> ${state.frontendConfig.version}-${state.frontendConfig.commit.slice(0, 5)}</p>
    <p><b>Domain:</b> ${state.config.domain}</p>
    <p><b>Page:</b> ${document.referrer}</p>
    <p><b>User:</b> ${state.user.username} (${state.user.email})</p>
    <p><b>Feedback:</b> <p style="white-space: pre-line">${values.feedback}</p></p>
  `;
  return {
    senderName: state.user.username,
    senderEmail: state.user.email,
    body: template.trim().replace(/\r\n|\r|\n/g, "<br>"),
  };
};

const sendFeedback = async (self) => {
  self.setFullValidation(true);
  const validation = self.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  try {
    await api.feedback.send(buildFeedbackObject(self));
    notificationView.post(staticMessages.success);
  } catch (e) {
    notificationView.post(staticMessages.backendErrors);
  }
  self.update({
    validation,
  });
};

const showSendFeedbackForm = (self) => form("#send-feedback-form", self.bind([
  [textarea, { label: "Feedback message", name: "feedback", required: true }],
  button.primary({
    label: "Send",
    onClick: () => sendFeedback(self),
  }),
]));

export default async (selector) => {
  const {
    header,
    loading,
    notification,
  } = layout(
    selector,
    [
      div("#send-feedback", {}, [
        h1("Send Feedback"),
        form("#send-feedback-form"),
      ]),
    ],
  );
  notificationView = notification;

  loading.show();
  pageVisit(window.location.pathname);

  const [user, config, frontendConfig, uiConfig] = await Promise.all([
    cache.getProfile(),
    getConfig(),
    api.configuration.frontendConfig(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    validation: {
      valid: true,
      fields: {},
    },
    messages: [],
    config,
    frontendConfig,
    horizontal: uiConfig.horizontalMenu,
  };

  formManagedView(showSendFeedbackForm, doUpdate)("#send-feedback-form", state);

  header.update(state);

  loading.hide();
};
