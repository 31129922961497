/**
 * A table element.
 *
 * @module ui/html/table
 * @category UI
 * @subcategory elements
 */
import el from "ui/common/el";

/**
 * @function table
 * @param {ChildEl} children
 * @param {Selector} sel
 * @param {ElArgs} config
 */
export default function table(children = [], sel = "", config = {}) {
  return el(`table${sel}`, config, children);
}
