/**
 * Implementations for fetching from search endpoints by a section's search config.
 *
 * @module api/page/search
 * @private
 * @category Backend API
 * @subcategory Page
 */
/***/
import {
  recentlyAdded,
  recentlyWatched,
  mostWatched,
  trending,
  searchDocuments,
  searchVideos,
  searchPlaylists,
} from "api/metadata";
import log from "log";
import { searchDataModes, searchDataPresets } from "model/search/constants";

const doPresetSearch = async (searchData) => {
  switch (searchData.preset) {
    case searchDataPresets.RECENTLY_ADDED:
      try {
        return await recentlyAdded(searchData.maxItems || 12);
      } catch (e) {
        log.debug("error fetching recently added", e);
        // no results / other error shouldn't crash home page
        return [];
      }
    case searchDataPresets.RECENTLY_WATCHED:
      try {
        return await recentlyWatched(searchData.maxItems || 12);
      } catch (e) {
        log.debug("error fetching recently watched", e);
        // no results / other error shouldn't crash home page
        return [];
      }
    case searchDataPresets.MOST_WATCHED:
      try {
        return await mostWatched(searchData.maxItems || 12);
      } catch (e) {
        log.debug("error fetching most watched", e);
        // no results / other error shouldn't crash home page
        return [];
      }
    case searchDataPresets.TRENDING:
      try {
        return await trending(searchData.maxItems || 12);
      } catch (e) {
        log.debug("error fetching trending", e);
        // no results / other error shouldn't crash home page
        return [];
      }
    default:
      return [];
  }
};

const doAdvancedSearch = async (searchData) => {
  const queries = [];
  if (searchData.searchDocuments) {
    queries.push(searchDocuments({
      ...searchData,
      terms: null,
      categories: searchData.terms,
    }));
  }
  if (searchData.searchVideos) {
    queries.push(searchVideos({
      ...searchData,
      terms: null,
      categories: searchData.terms,
    }));
  }
  if (searchData.searchPlaylists) {
    queries.push(searchPlaylists({
      ...searchData,
      terms: null,
      categories: searchData.terms,
    }));
  }
  return (await Promise.all(queries)).flat();
};

/**
 * Executes a search based on a dynamic section's search parameters.
 *
 * @function doSearch
 * @param {module:model/dynamic-page~PageSectionSearchData} searchData
 * @return {Array.<mixed>} search results
 */
/* eslint-disable-next-line import/prefer-default-export */
export const doSearch = async (searchData) => {
  switch (searchData.mode) {
    case searchDataModes.ADVANCED:
      return doAdvancedSearch(searchData);
    case searchDataModes.PRESET:
    default:
      return doPresetSearch(searchData);
  }
};
