/**
 * Help section for the assessment profile.
 *
 * @module ui/page/admin/dynamic/help
 * @private
 * @category Pages
 * @subcategory Admin - Dynamic
 */
/***/
import { a, li, ol, p, section } from "ui/html";

export default function helpSection() {
  return section(".container-help", [
    p("Pages are the primary way to structure your SmartEdge installation. Many different types of pages can be created on SmartEdge using our wide array of page elements."),
    p("To add a page:"),
    ol([
      li("To create a new page, add a title and URL extension. You can press the green circle arrow icon for the system to automatically generate a unique URL extension or you can add your own text! Please make sure there are no spaces in the intended extension."),
      li("Click Add Section to choose the page extensions you would like to add to the page. You can choose to add among the following:"),
      ol([
        li("Text: A text block to post course description or notification"),
        li("Embedded Item: Either a video, document, or image with additional text on the right or lefthand sides"),
        li("FAQ: FAQ-style section with options for adding different question entries"),
        li("Rich Text: A text block that allows for granular formatting"),
        li("Slider: A section for slider images - these images will need to be 1800 by 720 pixels and in JPEG format"),
        li("Playlist Carousel: A section to add an existing playlist to the page in carousel format"),
        li("Playlist Grid: A section to add an existing playlist to the page in grid format"),
        li("Dynamic Carousel: A section to add a preset playlist (Recently Added, Recently Watched, Most Watched, Trending, etc) to the page in carousel format"),
        li("Dynamic Grid: A section to add a preset playlist (Recently Added, Recently Watched, Most Watched, Trending, etc) to the page in grid format"),
        li("Course Overview: A predefined overview for an already created course"),
        li("Course Module Carousel: A predefined course module displayed in carousel format"),
        li("Course Module Grid: A predefined course module displayed in grid format"),
      ]),
      li([
        "Press SAVE and the page will be created. To make any further edits, please visit the page profile. To delete the page, click the red DELETE button at the top of the page profile",
      ]),
    ]),
    p([
      "In case of any issues, please email ",
      a("support@smartedge.com", "mailto:support@smartedge.com"),
      " or contact your organization’s SmartEdge administrator.",
    ]),
  ]);
}
