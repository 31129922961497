/**
 * v2 API endpoints for grading schemes.
 *
 * @module api/v2/course/grading-scheme
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import log from "log";
import { responseToGradingScheme } from "model/course/grading-scheme";

/**
 * Get grading scheme by id
 *
 * @function getGradingSchemeById
 * @param {UUID} id
 * @param {boolean} skipCache
 * @returns {Promise<GradingScheme>}
 */
/* eslint-disable-next-line import/prefer-default-export */
export const byId = async (id) => {
  try {
    return responseToGradingScheme((await get(endpoints.GRADING_SCHEME(id))).body);
  } catch (e) {
    log.error(e, `API :: COURSE : failed to fetch grading scheme ${id}`);
    return null;
  }
};
