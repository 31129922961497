/**
 * Cache handling for the course API.
 *
 * @module api/course/cache
 * @private
 * @category Backend API
 * @subcategory Course
 */
/***/
import { endpoints } from "api/constants";
import { get } from "api/request";
import { CACHE_USERS_KEY } from "api/user";
import cache from "cache";
import { responseToUser } from "model/user";

export const CACHE_COURSE_KEY = "course";
export const CACHE_COURSE_GRADING_KEY = "courseGrading";
export const CACHE_COURSE_KEY_SEARCH = "courseSearch";
export const CACHE_COURSE_LECTURE_KEY = "courseLecture";
export const CACHE_COURSE_MODULE_KEY = "courseModule";
export const CACHE_COURSE_ASSESSMENT_KEY = "courseAssessment";
export const CACHE_COURSE_EVALUATION_PREFIX = "courseEvaluation";

export const CACHE_SEARCH_KEYS = Object.freeze([
  CACHE_COURSE_KEY_SEARCH,
]);

/**
 * Removes all course-related searches from cache.
 *
 * @function expireAllSearches
 */
export const expireAllSearches = () => {
  CACHE_SEARCH_KEYS.forEach((key) => {
    cache.storeExpiringMap(key, new Map());
  });
};

/**
 * Get lecture instructor object.
 *
 * This exists so unprivileged users can fetch an instructor profile, which they couldn't
 * otherwise due for lack of MANAGE_USERS rights.
 *
 * Placed here instead of main api to avoid cyclic dependency.
 *
 * @function getLectureInstructor
 * @param {UUID} courseId
 * @param {UUID} moduleId
 * @param {UUID} lectureId
 * @param {UUID} instructorId
 * @return {User}
 */
export const getInstructor = async ({
  courseId,
  moduleId,
  lectureId,
  instructorId,
}) => {
  let user = cache.getExpiredMapEntry(CACHE_USERS_KEY, instructorId);
  if (!user) {
    user = (await get(
      `${endpoints.COURSE(courseId)}/module/${moduleId}/lecture/${lectureId}/instructor`,
    )).body;
    if (user) {
      cache.storeExpiringMapEntry(CACHE_USERS_KEY, instructorId, user);
    }
  }
  if (user) {
    return responseToUser(user);
  }
  return null;
};

/**
 * Populate all lecture instructors for a collection of courses.
 *
 * Minimizes requests by only fetching unique instructors.
 *
 * Minor shenanigans here: we deal with references to lectures and modify them
 * directly, so this function is _not_ pure.
 *
 * @param {Course[]} courses
 */
export const populateInstructors = async (courses) => {
  const queue = new Map();
  const insertInstructor = ({ courseId, moduleId, lecture }) => {
    let previous = queue.get(lecture.instructorId);
    if (!previous) {
      previous = {
        courseId,
        moduleId,
        lectureId: lecture.id,
        instructorId: lecture.instructorId,
        todo: [],
      };
    }
    previous.todo.push(lecture);
    queue.set(lecture.instructorId, previous);
  };
  const populateInstructorFromQueue = async (data) => {
    const instructor = await getInstructor(data);
    data.todo.forEach((lecture) => {
      /* eslint-disable-next-line no-param-reassign */
      lecture.instructor = instructor;
      /* eslint-disable-next-line no-param-reassign */
      delete lecture.instructorId;
    });
  };
  courses.forEach((course) => {
    course?.modules?.forEach((module) => {
      module?.lectures?.forEach((lecture) => {
        // we only need to fetch it once, so it doesn't matter what IDs end up in here
        if (lecture.instructorId) {
          insertInstructor({
            courseId: course.id,
            moduleId: module.id,
            lecture,
          });
        }
      });
    });
  });
  await Promise.all([...queue.values()].map(populateInstructorFromQueue));
};

/**
 * Retreive a collection of cached lectures, returning null if any item isn't found.
 *
 * @function getCachedLectures
 * @param {UUID[]} lectureIds
 * @return {?CourseLectureDTO[]}
 */
export const getCachedLectures = (lectureIds) => {
  const lectures = lectureIds.map(
    (id) => cache.getExpiredMapEntry(CACHE_COURSE_LECTURE_KEY, id),
  );
  if (lectures.includes(null)) return null;
  return lectures;
};

/**
 * Store lecture DTOs in cache.
 *
 * @function cacheLectures
 * @param {CourseLectureDTO[]}
 */
export const cacheLectures = (lectures) => {
  lectures.forEach(
    (lecture) => {
      cache.storeExpiringMapEntry(CACHE_COURSE_LECTURE_KEY, lecture.id, lecture);
    },
  );
};

/**
 * Remove lecture IDs from cache.
 *
 * @function deleteCachedLectures
 * @param {UUID[]} lectureIds
 */
export const deleteCachedLectures = (lectureIds) => {
  lectureIds.forEach(
    (id) => cache.deleteExpiringMapEntry(CACHE_COURSE_LECTURE_KEY, id),
  );
};

/**
 * Retreive a collection of cached assessments, returning null if any item isn't found.
 *
 * @function getCachedAssessments
 * @param {UUID[]} assessmentIds
 * @return {?CourseAssessmentDTO[]}
 */
export const getCachedAssessments = (assessmentIds) => {
  const assessments = assessmentIds.map(
    (id) => cache.getExpiredMapEntry(CACHE_COURSE_ASSESSMENT_KEY, id),
  );
  if (assessments.includes(null)) return null;
  return assessments;
};

/**
 * Remove assessment IDs from cache.
 *
 * @function deleteCachedAssessments
 * @param {UUID[]} assessmentIds
 */
export const deleteCachedAssessments = (assessmentIds) => {
  assessmentIds.forEach(
    (id) => cache.deleteExpiringMapEntry(CACHE_COURSE_ASSESSMENT_KEY, id),
  );
};

/**
 * Store assessment DTOs in cache.
 *
 * @function cacheAssessments
 * @param {CourseAssessmentDTO[]}
 */
export const cacheAssessments = (assessments) => {
  assessments.map(
    (assessment) => cache.storeExpiringMapEntry(
      CACHE_COURSE_ASSESSMENT_KEY,
      assessment.id,
      assessment,
    ),
  );
};

/**
 * Retrieve a collection of cached modules, returning null if any item isn't found.
 *
 * @param {UUID[]} moduleIds
 * @return {?CourseModuleDTO[]}
 */
export const getCachedModules = (moduleIds) => {
  const modules = moduleIds.map(
    (id) => cache.getExpiringMapEntry(CACHE_COURSE_MODULE_KEY, id),
  );
  if (modules.includes(null)) return null;
  return modules;
};

/**
 * Store module DTOs in cache.
 *
 * @function cacheModules
 * @param {CourseModuleDTO[]}
 */
export const cacheModules = (modules) => {
  modules.map(
    (module) => cache.storeExpiringMapEntry(CACHE_COURSE_MODULE_KEY, module.id, module),
  );
};

/**
 * Remove module ids and children from cache.
 *
 * @function deleteCachedModules
 * @param {UUID[]} moduleIds
 */
export const deleteCachedModules = (moduleIds) => {
  moduleIds.forEach((id) => {
    const cachedModule = cache.getExpiredMapEntry(CACHE_COURSE_MODULE_KEY, id);
    if (cachedModule) {
      deleteCachedLectures(cachedModule.lectures || []);
      deleteCachedAssessments(cachedModule.assessments || []);
      cache.deleteExpiringMapEntry(CACHE_COURSE_MODULE_KEY, id);
    }
  });
};

/**
 * Removes a course and its children from cache.
 *
 * @function deleteCachedCourse
 * @param {UUID} courseId
 */
export const deleteCachedCourse = (courseId) => {
  const cachedCourse = cache.getExpiredMapEntry(CACHE_COURSE_KEY, courseId);
  if (cachedCourse?.moduleItemDtos?.length) deleteCachedModules(cachedCourse.moduleItemDtos);
  cache.deleteExpiringMapEntry(CACHE_COURSE_KEY, courseId);
};

/**
 * Makes a unique cache key for in-progress evaluations.
 */
export const makeEvaluationKey = (evalId) => `${CACHE_COURSE_EVALUATION_PREFIX}-${evalId}`;
