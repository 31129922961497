/**
 * A pair of password fields. The first must be confirmed by the second.
 *
 * @module ui/component/form-managed/field-password-confirmed
 * @category UI
 * @subcategory Forms
 */
import div from "ui/html/div";
import password from "ui/component/form-managed/field-password";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  mismatch: "The provided passwords do not match.",
  required: "Please enter your new password.",
});

const matchHook = (first) => (node, validity) => {
  if (
    first.elm.querySelector("input").value
    !== node.elm.value
  ) {
    /* eslint-disable no-param-reassign */
    validity.valid = false;
    validity.mismatch = true;
    /* eslint-enable no-param-reassign */
  }
};

const defaultState = frozen({
  confirmValue: "",
  helpTexts,
  name: "newPassword",
  sel: "",
  type: "password",
  validHooks: [],
});

export default function fieldPasswordConfirmed(inState) {
  const firstState = merge.all([
    defaultState,
    inState,
    {
      validHooks: [...defaultState.validHooks, ...inState?.validHooks || []],
      name: "newPassword",
      label: "New Password",
      autocomplete: "new-password",
    },
  ]);

  const first = password(firstState);
  const secondState = merge(
    firstState,
    {
      validHooks: [...firstState.validHooks, matchHook(first)],
      name: "newPasswordConfirm",
      label: "Confirm Password",
      weak: true,
      helpTexts: {
        required: "Please confirm your new password.",
      },
    },
  );
  const second = password(secondState);

  return div(
    `.password-confirmed-pair`,
    [first, second],
  );
}
