/**
 * A help modal dialog for search page.
 *
 * @module ui/component/modal/help/search
 * @category UI
 * @subcategory Modal Dialogs
 */
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function searchHelpModal(skipCache = false, async = false) {
  return commonHelpModal({
    modalType: modalTypes.SEARCH,
    skipCache,
    async,
  }, "Filter by Video, File, or List to refine your search.");
}
