/**
 * An individualized icon for a user metadata.
 *
 * If the user metadata is a system privilege metadata, provides
 * the corresponding icon. Otherwise, a generic user metadata icon.
 *
 * @module ui/component/metadata-icon
 * @category UI
 * @subcategory Components - ACL
 */
import {
  metadataTypes,
  metadataTypesFriendly,
} from "model/metadata/constants";
import icon from "ui/component/icon";

const getIconName = (metadataType) => {
  switch (metadataType) {
    case metadataTypes.LIST:
      return "album-collection";
    case metadataTypes.VIDEO:
      return "video";
    case metadataTypes.DOCUMENT:
      return "file-pdf";
    case metadataTypes.IMAGE:
      return "image";
    default:
      return "file";
  }
};

export default function metadataIcon(metadataType) {
  return icon.solid(
    getIconName(metadataType),
    ".metadata-icon",
    {
      props: {
        title: metadataTypesFriendly.get(metadataTypes),
      },
    },
  );
}
