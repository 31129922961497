/**
 * API requests for video metadata.
 *
 * @module api/v2/metadata/video
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { makeVideoSearchParams, responseToVideoMetadata } from "model/metadata";
import { get } from "api/request";

/**
 * Get video metadata by UUID.
 *
 * @function byId
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~VideoMetadata}
 */
export const byId = async (uuid) => responseToVideoMetadata(
  (await get(`${endpoints.META_VIDEO}/${uuid}`)).body,
);

/**
 * Get multiple video metadatas by id.
 *
 * @function byIds
 * @param {UUID[]} uuids
 * @returns {Promise.<VideoMetadata[]>}
 */
export const byIds = async (uuids) => (
  await get(`${endpoints.META_VIDEO}/batch/?ids=${uuids.join(",")}`)
).body.map(responseToVideoMetadata);

/**
 * Search video metadata.
 *
 * @function searchVideos
 * @param {object} partial matching {@link module:api/types/metadata.VideoSearchParams}
 *
 * @return {VideoMetadata[]}
 */
export const searchVideos = async (partial) => ((await get(
  endpoints.META_VIDEO,
  makeVideoSearchParams(partial),
)).body).map(responseToVideoMetadata);

/**
 * List all existing video metadata objects.
 * @function list
 * @return {VideoMetadata[]}
 */
export const list = async () => searchVideos({ maxItems: 1000000 });

/**
 * Get a count of total search results for the given parameters.
 *
 * @function countVideos
 * @return int
 */
export const countVideos = async (params) => (await get(
  `${endpoints.META_VIDEO}/count`,
  makeVideoSearchParams(params),
)).body;
