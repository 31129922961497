/**
 * A chart view.
 *
 * @module ui/view/chart
 * @category UI
 * @subcategory Views
 */
import { uuidv4 } from "util/generator";
import { merge } from "util/object";
import { Chart } from "chart.js/auto";
import { chartTypesSet, defaultChartSettings } from "model/chart/constants";
import baseView from "ui/view/view";
import log from "log";

const legendMarginBottomPlugin = {
  id: uuidv4(),
  beforeInit: (aChart) => {
    const originalFit = aChart.legend.fit;
    // eslint-disable-next-line no-param-reassign
    aChart.legend.fit = function () {
      originalFit.bind(aChart.legend)();
      this.height += 20;
    };
  },
};

Chart.defaults.font.family = "Edge";
Chart.defaults.hoverBackgroundColor = defaultChartSettings.hoverColor;
Chart.defaults.color = defaultChartSettings.primaryColor;
Chart.defaults.backgroundColor = defaultChartSettings.backgroundColor;
Chart.defaults.plugins.legend.labels.boxWidth = defaultChartSettings.legendWidth;
Chart.defaults.plugins.tooltip.boxPadding = defaultChartSettings.tooltipColoredBoxPadding;
Chart.register(legendMarginBottomPlugin);

const chartMap = new Map();

const defaultState = {
  canvasId: null,
  type: null,
  data: {
    labels: [],
    datasets: [],
  },
  options: {},
  onPointClick: () => {
  },
};

function bootstrapView(self) {
  return self;
}

const updateFn = (self) => {
  const { state } = self;
  const { type, data, onPointClick } = state;
  self.patch(self.factory(self));
  if (chartMap.has(state.canvasId)) {
    chartMap.get(state.canvasId).destroy();
    chartMap.delete(state.canvasId);
  }
  const canvas = document.getElementById(state.canvasId);
  if (!canvas) {
    return;
  }
  const chart = new Chart(
    canvas,
    {
      type,
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        ...data.options,
        onClick: (e) => {
          const points = e.chart.getElementsAtEventForMode(e, "nearest", { intersect: true }, true);
          points.forEach((point) => {
            try {
              onPointClick({
                label: e.chart.data.labels[point.index],
                data: e.chart.data.datasets[point.datasetIndex].data[point.index],
              });
            } catch {
              log.error("Error getting data from chart point");
            }
          });
        },
      },
    },
  );
  chartMap.set(state.canvasId, chart);
};

/**
 * Creates a chart view factory. Used for bootstrapping views.
 *
 * @function chart
 * @param {module:ui/view/view~ComponentFactory} [componentFactory=defaultComponentFactory]
 * @return ViewInitializer<ChartData>
 */
export default (componentFactory) => (selector, initState) => {
  const canvasId = uuidv4();
  const state = merge({ ...defaultState, canvasId }, initState);
  if (state.type && !chartTypesSet.has(state.type)) {
    throw new Error("chart type is not supported");
  }
  const self = bootstrapView(
    baseView(selector, state, componentFactory, updateFn, false),
  );
  self.update(state);
  return self;
};
