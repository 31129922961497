/**
 * Builds a dynamic embedded video or image with (or without)
 * wrapped text.
 *
 * @module ui/page/dynamic/section-embedded-video-image
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import { div, h2, img, section as sect } from "ui/html";
import { metadataTypes } from "model/metadata/constants";
import el from "ui/common/el";
import button from "ui/component/form-managed/button";
import contentCard from "ui/component/card/content";
import { cardFormatOptions } from "model/dynamic-page/constants";

const showAsContentCard = (section) => !!section.searchData?.asContentCard;

const isMetadata = (section) => !!section.itemId;

const isImage = (section) => !!section.images.length;

let iframeKey = 0;
const iframe = (src, sel = "") => el(
  `iframe${sel}`,
  {
    props: { src },
    // key is important here to force replacement of the iframe. If we only update it
    // then the src property is updated, which counts as navigation and introduces a
    // new window.history state entry, which messes up pushState / onpopstate navigation.
    // replacing it doesn't register in navigation history.
    key: ++iframeKey,
  },
);

const documentViewer = (doc) => div(
  ".document-viewer",
  [
    iframe(`${doc?.getFileUrl()}#view=FitH&toolbar=0`, "#viewer"),
    button({
      icon: "external-link",
      swap: true,
      label: "Open in PDF Viewer",
      sel: ".warn",
      onClick: () => window.open(`/pdf?id=${doc.id}`, "_blank"),
    }),
  ],
);

const videoPlayer = (videoId) => div(
  ".video-player",
  [
    iframe(
      `/video?type=${metadataTypes.VIDEO}&id=${videoId}&no-auto=true&enable-expand=true`,
      "#player",
    ),
  ],
);

const showEmbeddedItem = (state, section) => {
  if (showAsContentCard(section)) {
    const item = state.pdf?.get(section.itemId) || state.videos?.get(section.itemId);
    return contentCard({
      metadata: item,
      playbackPosition: item?.positionInSeconds,
      cardSize: section.searchData?.collapsible
        ? cardFormatOptions.VERTICAL
        : cardFormatOptions.HORIZONTAL,
    });
  }
  if (isMetadata(section)) {
    if (state.pdf?.has(section.itemId)) return documentViewer(state.pdf.get(section.itemId));
    if (state.videos?.has(section.itemId)) return videoPlayer(section.itemId);
    return "";
  }
  if (isImage(section)) {
    const image = section.images[0];
    return img("", image.fileUrlStatic);
  }
  return "";
};

const isVideoMetadata = (state, section) => isMetadata(section)
  && state.videos?.has(section.itemId) && !showAsContentCard(section);

const additionalSelector = ({ leftSideText, rightSideText, collapsible, asContentCard }) => {
  let selector = "";
  if (leftSideText) selector += ".with-left-side";
  if (rightSideText) selector += ".with-right-side";
  if (collapsible) selector += ".small";
  if (asContentCard) selector += ".with-content-card";
  return selector;
};

export default function sectionEmbeddedItem(state, sectionId) {
  const section = state.sections.get(sectionId);
  const { searchData, title } = section;
  const { leftSideText, rightSideText } = searchData;
  return sect(`#${sectionId}.section-embedded-item${additionalSelector(searchData)}${isVideoMetadata(state, section) ? ".hidden" : ""}`, [
    title ? h2(title) : "",
    div(".embed-wrapper", [
      leftSideText ? div(".text.left-side", leftSideText) : "",
      div(".embedded-item", showEmbeddedItem(state, section)),
      rightSideText ? div(".text.right-side", rightSideText) : "",
    ]),
  ]);
}
