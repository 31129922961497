/**
 * A notification message overlay that pops on a screen
 *
 * @module ui/component/notification-overlay
 * @category UI
 * @subcategory Components
 */
import { button, div, span } from "ui/html";
import icon from "./icon";

let notificationsView;

const notificationTypeSelector = (type) => (type ? `.${type?.toLowerCase()}` : "");

const notificationItem = (notification) => {
  const {
    title, text, closing, type,
  } = notification;
  return div(`.notification${closing ? ".closing" : ""}${notificationTypeSelector(type)}`, [
    div(".header", [
      span(title),
      button(
        icon.sharp("xmark"),
        () => {
          notificationsView.close(notification.id);
        },
        ".subtle",
      ),
    ]),
    div(".text", text),
  ]);
};

const notificationOverlay = (view) => {
  notificationsView = view;
  const { state } = view;
  const { notifications } = state;
  return div(
    ".em-notifications-overlay",
    notifications.map(notificationItem),
  );
};

export default notificationOverlay;
