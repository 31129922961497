/**
 * A `textarea` element.
 *
 * @module ui/html/textarea
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';
import { merge } from "util/object";

/**
 * @function textarea
 * @param {string} [value=""]
 * @param {boolean} [required=false]
 * @param {string} [name="text"]
 * @param {module:ui/html~Config} [config={}]
 * @return {module:ui/common/el~El}
 */
const textarea = (value = "", required = false, name = "text", config = {}) => el(`textarea`, merge({ props: { required, name } }, config), value);

export default textarea;
