/**
 * A scrub bar component
 *
 * @module ui/component/scrub-bar
 * @category UI
 * @subcategory Components
 */
import { div, input } from "ui/html";
import { merge } from "util/object";

const defaultState = {
  min: 0,
  max: 100,
  defaultValue: 100,
  onScrubBarValueChange: () => {},
};

export default function scrubBar(inState = defaultState, sel = "") {
  const state = merge(defaultState, inState);
  return div(`.scrub-bar${sel}`, [
    input.range(0, false, "range", {
      on: {
        input: (e) => {
          const { target } = e;
          const min = target.min || state.min;
          const max = target.max || state.max;
          const val = target.value;
          const currentValue = ((val - min) * 100) / (max - min);
          target.style.backgroundSize = `${currentValue}% 100%`;
          target.value = currentValue;
        },
        mouseup: (e) => {
          state.onScrubBarValueChange(e.target.value);
        },
        touchend: (e) => {
          state.onScrubBarValueChange(e.target.value);
        },
      },
      hook: {
        insert: (e) => {
          e.elm.style.backgroundSize = `${state.defaultValue}% 100%`;
          e.elm.value = state.defaultValue;
        },
      },
    }),
  ]);
}
