/**
 * Constants related to features
 *
 * @module model/config/constants
 * @category Model
 * @subcategory Config
 */

/**
 * Feature types
 *
 * @enum {string} featureTypes
 * @readonly
 * @property GRID_FS_SIZE_IN_BYTES
 * @property GRID_FS_SIZE_LIMIT_IN_MB
 * @property LDAP
 * @property LMS
 * @property MAX_CONCURRENT_LOGINS
 * @property MESSAGING
 * @property OPEN_SITE_ACCESS
 * @property REGISTRATION
 * @property ZOOM_INTEGRATION
 */
export const featureTypes = Object.freeze({
  GRID_FS_SIZE_IN_BYTES: "GRID_FS_SIZE_IN_BYTES",
  LDAP: "LDAP",
  LMS: "LMS",
  MAX_CONCURRENT_LOGINS: "MAX_CONCURRENT_LOGINS",
  MESSAGING: "MESSAGING",
  OPEN_SITE_ACCESS: "OPEN_SITE_ACCESS",
  REGISTRATION: "REGISTRATION",
  USER_MANAGEMENT: "USER_MANAGEMENT",
  ZOOM_INTEGRATION: "ZOOM_INTEGRATION",
  OTP_VERIFICATION: "OTP_VERIFICATION",
});

export const featureFlagsFriendly = Object.freeze(new Map([
  [featureTypes.LDAP, "LDAP"],
  [featureTypes.LMS, "Learning Management"],
  [featureTypes.MESSAGING, "Chat & Messaging"],
  [featureTypes.OPEN_SITE_ACCESS, "Open Site Acccess"],
  [featureTypes.USER_MANAGEMENT, "User Management"],
  [featureTypes.REGISTRATION, "User Registration"],
  [featureTypes.ZOOM_INTEGRATION, "Zoom Integration"],
]));

export const featureTypesFrontend = Object.freeze({
  PWA: "FE_PWA",
  DOC_STYLEGUIDE: "FE_DOC_STYLEGUIDE",
  DOC_DEV: "FE_DOC_DEV",
});

/**
 * Set of feature sources
 *
 * @enum {string} featureSources
 * @readonly
 * @property SERVER_CONFIG
 */
export const featureSources = Object.freeze({
  SERVER_CONFIG: "SERVER_CONFIG",
  FRONTEND_CONFIG: "FRONTEND_CONFIG",
});

/**
 * Possible values for user verification setting.
 * @enum {string} verificationMethods
 * @readonly
 * @property {string} ADMIN verified manually by admin
 * @property {string} DISABLED not required (auto-verify)
 * @property {string} OTP verified by email/sms OTP
 */
export const verificationMethods = Object.freeze({
  ADMIN: "ADMIN",
  DISABLED: "DISABLED",
  OTP: "VIA_OTP",
});
