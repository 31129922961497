/**
 * Utility functions for manipulating and normalizing metadata.
 *
 * @module util/metadata
 * @category Utilities
 */
import { metadataTypes } from "model/metadata/constants";

/**
 * Returns an appropriate icon component for a metadata type.
 *
 * This has to be fed the icon module because importing it in this
 * module introduces a cyclical dependency.
 *
 * @function getMetadataIcon
 * @param {module:ui/component/icon~icon} icon the icon component module
 * @param {string} metadataType
 * @return {module:ui/component/icon~icon}
 */
export const getMetadataIcon = (icon, type) => {
  switch (type) {
    case metadataTypes.LIST:
      return icon.solid("list-alt");
    case metadataTypes.VIDEO:
      return icon.solid("play-circle");
    case metadataTypes.DOCUMENT:
    default:
      return icon.solid("file-alt");
  }
};

/**
 * Collect all the metadata entries in a tree of mixed metadata types, including
 * playlists, such that the resulting array contains at least one entry of each
 * metadta entity found anywhere in the tree.
 *
 * @function collectMetadataEntries
 * @param {module:api/types/metadata~ListMetadata} tree
 * @return {module:api/types/metadata~ListMetadata[]}
 */
const collectMetadataEntries = (tree) => {
  let entries = [];
  if (tree.items) {
    tree.items.forEach((entry) => {
      if (entry) {
        entries.push(entry);
        if (entry.items) {
          entries = entries.concat(collectMetadataEntries(entry));
        }
      }
    });
  }
  return entries;
};

/**
 * Builds a flat, unique collection of all metadata entries out of a nested list.
 *
 * @function buildContentDictionary
 * @param {module:api/types/metadata~PlaylistMetadata} tree
 * @return {Map.<UUID, module:api/types/metadata~ListMetadata>}
 */
export const buildContentDictionary = (tree) => {
  const dict = new Map();
  collectMetadataEntries(tree).forEach((item) => {
    dict.set(item.id, item);
  });

  return dict;
};

/**
 * Returns the best available URL for a direct document file download.
 *
 * @function documentUrlDirect
 * @param {DocumentMetadata} metadata
 * @return string
 */
export const documentUrlDirect = (metadata) => metadata.getFileUrl?.()
  || metadata.documentFile?.fileUrl?.()
  || metadata.documentFile?.fileUrlStatic;

/**
 * Generates a URL appropriate to a document metadata type.
 *
 * PDFs open in the PDF page, other documents directly link to the file.
 *
 * @function documentUrl
 * @param {DocumentMetadata} metadata
 * @return string
 */
export const documentUrl = (metadata) => {
  if (!metadata.documentFile?.name) return null;
  if (metadata.documentFile.name?.endsWith?.("pdf")) {
    return `/pdf?id=${metadata.id}`;
  }
  return documentUrlDirect(metadata);
};

/**
 * Generates a URL appropriate to a video metadata type.
 *
 * @function videoUrl
 * @param {VideoMetadata} metadata
 * @return string
 */
export const videoUrl = (metadata, playback = 0) => {
  const pos = playback?.position ? `&pos=${playback?.position}` : "";
  return `/video?type=${metadata.type}&id=${metadata.id}${pos}`;
};
