/**
 * A form field for family names.
 *
 * @module ui/component/form-managed/field-family-name
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { nameConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  invalid: "Last/family names may contain only letters and spaces, and must be between 0-255 characters.",
  required: "Please enter a last name or family name.",
});

/**
 * @private
 */
const defaultState = frozen({
  name: "lastName",
  label: "Last Name",
  constraints: nameConstraints,
  autocomplete: "family-name",
  sel: "",
  helpTexts,
});

/**
 * A form field for family names.
 *
 * @function familyName
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="lastName"]
 * @param {string} [label="Last Name"]
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function familyName(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.last-name`,
  });
}
