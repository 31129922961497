/**
 * Content for cameo pane on test page.
 *
 * @module ui/page/admin/test/cameos
 * @private
 */
/***/
import log from "log";
import {
  defaultGradingSchemes,
  defaultGradingSchemeTypes,
} from "model/course/default-grading-scheme";
import { menuLinkTypes } from "model/site/constants";
import { videoMetadataList, documentMetadataList } from "api/mocks/metadata";
import { assessmentMock, courseMock } from "api/mocks/course";
import { pageTypes, pageTypesFriendly } from "model/dynamic-page/constants";
import { lectureMock } from "api/mocks/lecture";
import { fakeUser, userList } from "api/mocks/user";
import { metadataTypes } from "model/metadata/constants";
import { questionType } from "model/course/constants";
import { systemPrivilegeGroups } from "model/acl/constants";
import { makeUser } from "model/user";
import cameoAnswer from "ui/component/cameo-answer";
import cameoFile from "ui/component/cameo-file";
import cameoAssessment from "ui/component/cameo-assessment";
import cameoCourse from "ui/component/cameo-course";
import cameoGradingScheme from "ui/component/cameo-grading-scheme";
import cameoGroupMember from "ui/component/cameo-group-member";
import cameoLecture from "ui/component/cameo-lecture";
import cameoListSelectItem from "ui/component/cameo-list-select-item";
import cameoMemberGroup from "ui/component/cameo-member-group";
import cameoMenuEntry from "ui/component/cameo-menu-entry";
import cameoMetadata from "ui/component/cameo-metadata";
import cameoModule from "ui/component/cameo-module";
import cameoPage from "ui/component/cameo-page";
import cameoPageSection from "ui/component/cameo-page-section";
import cameoPrincipal from "ui/component/cameo-principal";
import cameoQuestion from "ui/component/cameo-question";
import cameoSolution from "ui/component/cameo-solution";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import h4 from "ui/html/h4";
import { merge } from "util/object";
import { principalList } from "./mocks";

const onGradingSchemeModeChange = (state) => {
  log.debug("changed grading scheme automation to", state);
};

export default () => () => div(
  "#cameo-collections",
  [
    h3("Cameos"),
    collapsible(
      { title: "Content", open: false },
      [
        h4("Metadata"),
        cameoMetadata({
          metadata: videoMetadataList[0],
        }),
        cameoMetadata({
          explicitMetadataType: metadataTypes.IMAGE,
          metadata: merge(
            videoMetadataList[0],
            { title: "Explicit Metadata Type Option" },
          ),
        }),
        h4("File"),
        cameoFile({
          file: { ...videoMetadataList[0].posterFile, name: "testing loading state..." },
          loading: true,
        }),
        cameoFile({
          file: videoMetadataList[0].posterFile,
          valid: false,
        }),
        cameoFile({
          file: videoMetadataList[0].posterFile,
          onRemove: () => log.debug("user removed poster file"),
          downloadable: true,
        }),
        cameoFile({
          file: videoMetadataList[0].videoFile,
          onRemove: () => log.debug("user removed video file"),
        }),
      ],
    ),
    collapsible(
      { title: "Menus", open: false },
      [
        cameoMenuEntry(
          {
            type: menuLinkTypes.LINK_EXTERNAL,
            label: "External Link",
            title: "External Link",
            entries: [0, 1],
          },
          0,
          () => log.debug("user edited edit external link"),
          () => log.debug("user deleted external link"),
        ),
        cameoMenuEntry(
          {
            type: menuLinkTypes.LINK_PAGE,
            label: "Page Link",
            title: "Page Link",
            entries: [0],
          },
          1,
          () => log.debug("user edited edit page link"),
          () => log.debug("user deleted page link"),
        ),
      ],
    ),
    collapsible(
      { title: "Utility", open: false },
      [
        h4("List-Select Item (Generic)"),
        cameoListSelectItem({
          label: "List Item",
          icon: icon("question-circle"),
          controls: [
            icon("octagon"),
          ],
          onSelect: () => log.debug("user clicked list selecct item"),
        }),
        cameoListSelectItem({
          label: "List Item (Selected)",
          icon: icon("check-circle"),
          controls: [
            icon("octagon"),
          ],
          selected: true,
          onSelect: () => log.debug("user clicked list select item (selected)"),
        }),
        cameoListSelectItem({
          label: "List Item (Faded)",
          icon: icon("times-circle"),
          controls: [
            icon("octagon"),
          ],
          faded: true,
          onSelect: () => log.debug("user clicked list selecct item (faded)"),
        }),
      ],
    ),
    collapsible(
      { title: "Pages and Sections", open: false },
      [
        cameoPage({
          page: { title: "Fake Page", id: "FAKE_PAGE_1" },
        }),
        ...[...pageTypesFriendly.entries()]
          .filter(([type]) => type !== pageTypes.INDEPENDENT)
          .map(([type, title]) => cameoPageSection({
            section: { type, title, id: "FAKE_ID" },
            onEdit: () => log.debug(`user edited ${title}`),
            onDelete: () => log.debug(`user deleted ${title}`),
          })),
      ],
    ),
    collapsible(
      { title: "LMS", open: false },
      [
        h4("Objects"),
        cameoCourse({
          course: courseMock,
        }),
        cameoModule({
          course: courseMock,
          module: courseMock.modules[0],
          users: userList,
          metadataList: [...videoMetadataList, ...documentMetadataList],
          onUpdate: (m) => log.debug("user updated module", m),
          onRemove: () => log.debug("user removed module"),
        }),
        cameoLecture({
          course: courseMock,
          module: courseMock.modules[0],
          lecture: lectureMock,
          metadataList: [...videoMetadataList, ...documentMetadataList],
          onUpdate: (l) => log.debug("user updated lecture", l),
          onRemove: () => log.debug("user removed lecture"),
        }),
        cameoAssessment({
          assessment: assessmentMock,
          onRemove: () => log.debug("deleted assessment"),
        }),
        h4("Answers"),
        cameoAnswer({
          answer: {
            value: "Test 1 answer. It is probably correct",
            correct: true,
          },
          onEdit: () => {},
          onRemove: () => {},
        }),
        cameoAnswer({
          answer: {
            value: "Test 2 answer. It is probably incorrect",
            correct: false,
          },
          onEdit: () => {},
          onRemove: () => {},
        }),
        h4("Questions"),
        cameoQuestion({
          question: {
            text: "Question 1. How about test question?",
            questionType: questionType.MULTIPLE_CHOICE_MULTI_ANSWER,
          },
          onEdit: () => {},
          onRemove: () => {},
        }),
        cameoQuestion({
          question: {
            text: "Question 2",
            questionType: questionType.FILL_IN_LONG,
          },
          onEdit: () => {},
          onRemove: () => {},
        }),
        cameoQuestion({
          question: {
            text: "Question 3",
            questionType: questionType.UPLOAD,
            answers: [{
              correct: true,
              value: 100,
            }],
            weight: 50,
          },
          onEdit: () => {},
          onRemove: () => {},
        }),
        h4("Solutions"),
        cameoSolution({
          solution: {
            question: assessmentMock.questions[0],
            value: [assessmentMock.questions[0].answers[0].value],
            score: { points: 1 },
          },
          onGraded: (g) => log.debug("user graded solution", g),
        }),
        cameoSolution({
          solution: {
            question: assessmentMock.questions[0],
            value: [assessmentMock.questions[0].answers[0].value],
            score: {},
          },
          onGraded: (g) => log.debug("user graded solution", g),
        }),
        h4("Grading Schemes"),
        cameoGradingScheme({
          gradingScheme: merge(
            defaultGradingSchemes.get(defaultGradingSchemeTypes.AMERICAN),
            { automatic: false },
          ),
          onChangeMode: onGradingSchemeModeChange,
        }),
        cameoGradingScheme({
          gradingScheme: merge(
            defaultGradingSchemes.get(defaultGradingSchemeTypes.CALIFORNIA),
            { automatic: true },
          ),
          onChangeMode: onGradingSchemeModeChange,
        }),
        cameoGradingScheme({
          gradingScheme: merge(
            defaultGradingSchemes.get(defaultGradingSchemeTypes.AMERICAN),
            { automatic: false },
          ),
          selected: true,
        }),
        cameoGradingScheme({
          gradingScheme: merge(
            defaultGradingSchemes.get(defaultGradingSchemeTypes.CALIFORNIA),
            { automatic: true },
          ),
          selected: true,
        }),
      ],
    ),
    collapsible(
      { title: "Users + Groups", open: false },
      [
        h4("Principal"),
        cameoPrincipal({
          principal: principalList[0],
          onRemove: () => log.debug("user removed group member"),
        }),
        h4("Group Member"),
        cameoGroupMember({
          user: fakeUser,
          onRemove: () => log.debug("user removed group member"),
        }),
        h4("Member Group"),
        cameoMemberGroup({
          user: makeUser(fakeUser),
          group: { name: systemPrivilegeGroups.ADMIN, system: true },
          onManage: () => log.debug("user clicked manage admin"),
          onRemove: () => log.debug("user removed admin group"),
        }),
        cameoMemberGroup({
          user: makeUser(fakeUser),
          group: { name: "Fake Group", system: false },
          onManage: () => log.debug("user clicked manage group"),
          onRemove: () => log.debug("user removed group member"),
        }),
      ],
    ),
  ],
);
