/**
 * A modal dialog for selecting a user or principal to assign ACL privileges to.
 *
 * @module ui/component/modal/acl/principal-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import { aclPrincipalTypes } from "model/acl/constants";
import cameoPrincipal from "ui/component/cameo-principal";
import listModal from "ui/component/modal/layout/list";
import { userGroupNameFilter, userNamesFilter } from "util/filter";
import { merge, frozen } from "util/object";
import { button } from "ui/html";
import { getModal } from "ui/view/modal-view";

const makeClickCallbackForMultiselect = (principal, state) => ({
  on: {
    click: () => {
      const { selectedPrincipals } = state;
      const principalAlreadySelected = selectedPrincipals.some((p) => p.id === principal.id);
      let updatedPrincipals = selectedPrincipals;
      if (principalAlreadySelected) {
        updatedPrincipals = selectedPrincipals.filter((p) => p.id !== principal.id);
      } else {
        updatedPrincipals = [...updatedPrincipals, principal];
      }
      // eslint-disable-next-line no-use-before-define
      getModal().patch(principalSelectModal({
        ...state,
        selectedPrincipals: updatedPrincipals,
      }));
    },
  },
});

const makeClickCallback = (principal) => ({
  on: {
    click: () => getModal().resolve(principal),
  },
});

const isPrincipalSelected = (state, principal) => state.selectedPrincipals
  .some((p) => p.id === principal.id);

const makeList = (state) => ([
  state.principals
    .filter((p) => (
      p.type === aclPrincipalTypes.INDIVIDUAL
      && userNamesFilter(state.search)(p.entity)
    ))
    .map((principal) => cameoPrincipal({
      principal,
      sel: isPrincipalSelected(state, principal) ? ".highlighted" : "",
      config: state.multiSelect
        ? makeClickCallbackForMultiselect(principal, state)
        : makeClickCallback(principal),
    })),
  state.principals
    .filter((p) => (
      p.type === aclPrincipalTypes.GROUP
      && userGroupNameFilter(state.search)(p)
    ))
    .map((principal) => cameoPrincipal({
      principal,
      sel: isPrincipalSelected(state, principal) ? ".highlighted" : "",
      config: state.multiSelect
        ? makeClickCallbackForMultiselect(principal, state)
        : makeClickCallback(principal),
    })),
]);

const doSearch = (state) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(principalSelectModal({
    ...state,
    search,
  }));
};

const doTabChange = (state) => (selectedTab) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(principalSelectModal({
    ...state,
    selectedTab,
  }));
};

const defaultState = frozen({
  multiSelect: false,
  principals: [],
  selectedPrincipals: [],
  search: "",
  selectedTab: 0,
});

/**
 * A dialog for selecting a user or group to which to assign ACL rights.
 *
 * Resolves with:
 * ```js
 * {
 *   type: PrincipalType,
 *   principal: UserGroupEntry | User,
 * }
 * ```
 *
 * @function principalSelectModal
 * @param {object} inState
 * @param {ACLPrincipalEntry[]} [inState.principals=[]]
 * @param {ACLPrincipalType} [inState.type=aclPrincipalTypes.INDIVIDUAL]
 * @param {string} [inState.search=""]
 * @return {module:ui/html/div~Div}
 */
export default function principalSelectModal(inState) {
  const state = merge(defaultState, inState);
  return listModal({
    sel: ".principal-select",
    entries: makeList(state),
    search: state.search,
    onSearch: doSearch(state),
    tabLabels: ["Users", "User Groups"],
    onTabChange: doTabChange(state),
    selectedTab: state.selectedTab,
    actions: state.multiSelect
      ? button("Submit", () => getModal().resolve(state.selectedPrincipals))
      : "",
  });
}
