/**
 * Collection of universal messages for content management pages.
 *
 * @module ui/page/admin/content/messages
 * @category Pages
 * @subcategory Content Management
 */
import { frozen } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";

const { BASIC, FAIL, SUCCESS } = notificationMessageTypes;

export const staticMessages = frozen({
  invalid: {
    title: "Invalid Form",
    text: "Please correct the errors.",
    type: FAIL,
    duration: 5,
  },
  unknown: {
    title: "Error",
    text: "An unknown error occurred.",
    type: FAIL,
    duration: 5,
  },
  accessDeniedCreate: {
    title: "Warning",
    text: "You do not have permission to create content.",
    type: BASIC,
    duration: 5,
  },
  accessDeniedEdit: {
    title: "Warning",
    text: "You do not have permission to edit this content.",
    type: BASIC,
    duration: 5,
  },
  doesNotExist: {
    title: "Error",
    text: "The content item no longer exists.",
    type: FAIL,
    duration: 5,
  },
  backendErrors: {
    title: "Error",
    text: "A server error occurred. Please report this to the operators.",
    type: FAIL,
    duration: 5,
  },
  errorDeleting: {
    title: "Error",
    text: "The item could not be deleted due to an internal server error.",
    type: FAIL,
    duration: 5,
  },
  noContentFileSelected: {
    title: "Warning",
    text: "Please choose a content type and select a file.",
    type: BASIC,
    duration: 5,
  },
  noPosterFileSelected: {
    title: "Warning",
    text: "Please select a poster file.",
    type: BASIC,
    duration: 5,
  },
  badVideoFileUpload: {
    title: "Error",
    text: "Please upload a valid video file.",
    type: FAIL,
    duration: 5,
  },
  badVideoFileSelection: {
    title: "Error",
    text: "Please upload a valid video file.",
    type: FAIL,
    duration: 5,
  },
  badDocumentFileUpload: {
    title: "Error",
    text: "Please upload a valid document file.",
    type: FAIL,
    duration: 5,
  },
  badDocumentFileSelection: {
    title: "Error",
    text: "Please upload a valid document file.",
    type: FAIL,
    duration: 5,
  },
  badPosterFileUpload: {
    title: "Error",
    text: "Please upload a valid poster image.",
    type: FAIL,
    duration: 5,
  },
  badPosterFileSelection: {
    title: "Error",
    text: "Please upload a valid poster image.",
    type: FAIL,
    duration: 5,
  },
  posterFileUploadFailed: {
    title: "Error",
    text: `An unexpected error occured while processing the selected poster file.
           Please check that the file type and format are correct, or try a different
           file.`,
    type: FAIL,
    duration: 5,
  },
  documentFileUploadFailed: {
    title: "Error",
    text: `An unexpected error occured while processing the selected document file.
           Please check that the file type and format are correct, or try a different
           file.`,
    type: FAIL,
    duration: 5,
  },
  videoFileUploadFailed: {
    title: "Error",
    text: `An unexpected error occured while processing the selected video file.
           Please check that the file type and format are correct, or try a different
           file.`,
    type: FAIL,
    duration: 5,
  },
  successSaved: {
    title: "Success",
    text: "Saved!",
    type: SUCCESS,
  },
  successDelete: {
    title: "Success",
    text: "The item was successfully deleted. Returning to manage content...",
    type: SUCCESS,
  },
});

/**
 * Messages related to poster file validation issues.
 *
 * @const posterMessages
 * @type Set
 * @readonly
 * @private
 */
export const posterMessages = frozen(new Set([
  staticMessages.badPosterFileUpload,
  staticMessages.badPosterFileSelection,
]));

/**
 * Messages related to video file validation issues.
 *
 * @const posterMessages
 * @type Set
 * @readonly
 * @private
 */
export const videoMessages = frozen(new Set([
  staticMessages.badVideoFileUpload,
  staticMessages.badVideoFileSelection,
]));

/**
 * Messages related to document file validation issues.
 *
 * @const posterMessages
 * @type Set
 * @readonly
 * @private
 */
export const documentMessages = frozen(new Set([
  staticMessages.badDocumentFileUpload,
  staticMessages.badDocumentFileSelection,
]));
