import { videoMetadataList } from "./metadata";

export const aclUserList = [
  {
    id: "1",
    name: "user1",
    type: "INDIVIDUAL",
  },
  {
    id: "2",
    name: "user2",
    type: "GROUP",
  },
  {
    id: "3",
    name: "user3",
    type: "GROUP",
  },
  {
    id: "4",
    name: "user4",
    type: "INDIVIDUAL",
  },
  {
    id: "5",
    name: "Amazing Group",
    type: "GROUP",
  },
  {
    id: "6",
    name: "user6",
    type: "GROUP",
  },
  {
    id: "7",
    name: "Hi Group",
    type: "GROUP",
  },
];

export const usersFromOneGroup = [
  {
    id: "9c799b70-faad-4d86-8bc0-3639dd76725f",
    name: "racheltest123",
    type: "INDIVIDUAL",
  },
  {
    id: "4daa0561-2e01-4272-a698-c621397b8707",
    name: "admin",
    type: "INDIVIDUAL",
  },
];

export const usersWithEntities = [
  {
    id: "9c799b70-faad-4d86-8bc0-3639dd76725f",
    name: "racheltest123",
    type: "INDIVIDUAL",
    entity: {
      firstName: "Rachel",
      lastName: "Test",
      username: "racheltest123",
    },
  },
  {
    id: "4daa0561-2e01-4272-a698-c621397b8707",
    name: "admin",
    type: "INDIVIDUAL",
    entity: {
      firstName: "Admin",
      lastName: "Istrator",
      username: "admin",
    },
  },
];

export const usersAndGroupsForMetadata = [
  {
    id: "9c799b70-faad-4d86-8bc0-3639dd76725f",
    name: "racheltest123",
    type: "INDIVIDUAL",
  },
  {
    id: "4daa0561-2e01-4272-a698-c621397b8707",
    name: "admin",
    type: "INDIVIDUAL",
  },
  {
    id: "1fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Amazing Group",
    type: "GROUP",
  },
];

export const mockACLRights = [
  "READ",
  "DELETE",
];

export const aclGroupList = [
  {
    id: "1fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 1",
    type: "GROUP",
    members: [
      ...usersWithEntities,
      ...usersWithEntities,
      ...usersWithEntities,
      ...usersWithEntities,
      ...usersWithEntities,
    ],
  },
  {
    id: "2fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 2",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 3",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 4",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 5",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 6",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 7",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 8",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 9",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 10",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 11",
    type: "GROUP",
    members: usersWithEntities,
  },
  {
    id: "4fa85f64-5717-4562-b3fc-2c963f66afa6",
    name: "Group 12",
    type: "GROUP",
    members: usersWithEntities,
  },
];

export const mockGrants = [
  {
    principal: usersWithEntities[0],
    rights: new Set(["READ", "WRITE"]),
    subject: videoMetadataList[0],
    type: "media",
  },
  {
    principal: usersWithEntities[1],
    rights: new Set(["OWNER"]),
    subject: videoMetadataList[0],
    type: "media",
  },
];
