/**
 * Default state for the bulk content form.
 *
 * @module ui/page/admin/content/bulk/state
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/**/
import { frozen } from "util/object";

export const displayModes = frozen({
  BULK_UPLOAD: "BULK_UPLOAD",
  HELP: "HELP",
});

export const defaultState = frozen({
  contents: [],
  files: [],
  messages: [],
  displayMode: displayModes.BULK_UPLOAD,
  validation: {
    fields: {},
  },
  config: {
  },
});
