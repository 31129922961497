/**
 * Any content slider.
 *
 * @module ui/component/slider
 * @category UI
 * @subcategory Components
 */
import {
  a,
  div,
  nav,
  section,
} from "ui/html";
import { qs } from "util/dom";
import { merge } from "util/object";
import button from "./form-managed/button";

const SLIDE_TIME = 5000;

let counter = 0;

const intervals = {};

const defaultState = () => ({
  slides: [],
  sliderIndex: ++counter,
  dotsClickable: true,
  current: 0,
  slideTime: SLIDE_TIME,
  onNavigate: () => {
  },
  key: null,
  controlButtonsConfiguration: {
    withControlButtons: false,
    previousLabel: "Previous",
    nextLabel: "Next Step",
    lastSlideLabel: "Got it",
    onLastSlideControlClick: () => {
    },
  },
});

const makeSliderId = (sliderIndex) => `#slides-${sliderIndex}`;

const makeSlideId = (sliderIndex, slideIndex) => `#slide-${sliderIndex}-${slideIndex}`;

const getSliderSelector = (sliderIndex, withControlButtons) => `${makeSliderId(sliderIndex)}${withControlButtons ? ".with-control-buttons" : ""}.slider-component`;

const updateControlButtonsLabel = (controlButtonsConfiguration, controlsBar, nextIndex, slides) => {
  if (controlButtonsConfiguration?.withControlButtons) {
    const controlButtons = controlsBar?.querySelector(".control-buttons")?.children;
    const previousButton = controlButtons[0];
    const nextButton = controlButtons[controlButtons.length - 1];
    if (controlButtons.length === 2) {
      previousButton.disabled = nextIndex === 0;
    }
    if (nextIndex === slides.length - 1) {
      nextButton.innerText = controlButtonsConfiguration.lastSlideLabel;
    } else {
      nextButton.innerText = controlButtonsConfiguration.nextLabel;
    }
  }
};

const rotateToIndex = (parent, index, controlButtonsConfiguration, rotateNav = true) => {
  if (!parent) return;
  const slides = Array.from(parent.querySelectorAll(".slide"));
  slides.forEach((slide) => slide.classList.remove("current"));
  slides[index].classList.add("current");
  const controlsBar = qs(".buttons-navigation-container");
  if (rotateNav) {
    const navs = parent.querySelector("nav")?.children || controlsBar.querySelector("nav")?.children;
    Array.from(navs).forEach((item) => item.classList.remove("current"));
    navs.item(index).classList.add("current");
  }
  updateControlButtonsLabel(controlButtonsConfiguration, controlsBar, index, slides);
};

const onNavigationClick = (slideIndex, sliderIndex, onNavigate, dotsClickable) => (e) => {
  e.preventDefault();
  if (!dotsClickable) {
    return;
  }
  const sliderElement = qs(getSliderSelector(sliderIndex));
  rotateToIndex(sliderElement, slideIndex);
  onNavigate(slideIndex);
};

const navigation = (slides, current, sliderIndex, onNavigate, dotsClickable) => nav(
  `#slides-${counter}-nav.slider-nav`,
  slides.map((_, i) => a.fn(
    "",
    onNavigationClick(i, sliderIndex, onNavigate, dotsClickable),
    `${i === current ? ".current" : ""}`,
  )),
);

const rotate = (
  parent, onNavigate, controlButtonsConfiguration, rotateNav = true, rotateBack = false,
) => {
  if (!parent) return;
  const slides = Array.from(parent.querySelectorAll(".slide"));
  const currentIndex = slides.findIndex((element) => element.classList.contains("current"));
  let nextIndex;
  if (rotateBack) {
    nextIndex = (slides.length + 1) === currentIndex ? 0 : (currentIndex - 1);
  } else {
    nextIndex = (slides.length - 1) === currentIndex ? 0 : (currentIndex + 1);
    if (nextIndex === 0 && controlButtonsConfiguration?.withControlButtons) {
      controlButtonsConfiguration?.onLastSlideControlClick();
      return;
    }
  }
  slides[currentIndex].classList.remove("current");
  slides[nextIndex].classList.add("current");
  const controlsBar = qs(".buttons-navigation-container");
  if (rotateNav) {
    const navs = parent.querySelector("nav")?.children || controlsBar.querySelector("nav")?.children;
    navs.item(currentIndex).classList.remove("current");
    navs.item(nextIndex).classList.add("current");
  }
  updateControlButtonsLabel(controlButtonsConfiguration, controlsBar, nextIndex, slides);
  onNavigate(nextIndex);
};

const initRotation = (sliderIndex, onNavigate, sliderTime, controlButtonsConfiguration) => {
  if (!sliderTime) return;
  if (intervals[sliderIndex]) clearInterval(intervals[sliderIndex]);
  intervals[sliderIndex] = setInterval(() => {
    const sliderElement = qs(getSliderSelector(sliderIndex));
    rotate(sliderElement, onNavigate, controlButtonsConfiguration);
  }, sliderTime);
};

const buttonNavigation = (
  slides, current, controlButtonsConfiguration, sliderIndex, onNavigate,
) => {
  const isLastSlide = current === slides.length - 1;
  return div(".control-buttons", [
    slides?.length > 1
      ? button.inverse({
        label: controlButtonsConfiguration.previousLabel,
        disabled: current === 0,
        onClick: () => {
          const sliderElement = qs(getSliderSelector(sliderIndex));
          rotate(sliderElement, onNavigate, controlButtonsConfiguration, true, true);
        },
      })
      : "",
    button.primary({
      label: isLastSlide
        ? controlButtonsConfiguration.lastSlideLabel
        : controlButtonsConfiguration.nextLabel,
      onClick: () => {
        const sliderElement = qs(getSliderSelector(sliderIndex));
        rotate(sliderElement, onNavigate, controlButtonsConfiguration);
      },
    }),
  ]);
};

export default function slider(inState) {
  const state = merge(defaultState(), inState);
  const {
    slides, current, sliderIndex, onNavigate, controlButtonsConfiguration, key, dotsClickable,
    slideTime,
  } = state;
  const dotsNavigation = (slides.length > 1) ? navigation(slides, current, sliderIndex, onNavigate, dotsClickable) : "";
  return div(".slider-container", [
    section(
      getSliderSelector(sliderIndex, controlButtonsConfiguration.withControlButtons),
      key ? { key } : {},
      [
        ...slides.map((item, index) => div(
          `${makeSlideId(sliderIndex, index)}${index === (current % slides.length) ? ".current" : ""}.slide`,
          {
            key: `slide-${sliderIndex}-${index}`,
            hook: {
              insert: () => initRotation(
                sliderIndex,
                onNavigate,
                slideTime,
                controlButtonsConfiguration,
              ),
              postpatch: () => initRotation(
                sliderIndex,
                onNavigate,
                slideTime,
                controlButtonsConfiguration,
              ),
            },
          },
          [
            item.title ? div(".header", item.title) : "",
            item.content,
          ],
        )),
        controlButtonsConfiguration.withControlButtons ? "" : dotsNavigation,
      ],
    ),
    controlButtonsConfiguration.withControlButtons
      ? div(".buttons-navigation-container", [
        div(".divider", []),
        buttonNavigation(slides, current, controlButtonsConfiguration, sliderIndex, onNavigate),
        dotsNavigation,
      ])
      : "",
  ]);
}
