/**
 * A cutout box.
 *
 * Should be displayed as a fixed-size box with a border to set it out from
 * surrounding content.
 *
 * Used by tabs, file pickers, etc.
 *
 * @module ui/component/cutout
 * @category UI
 * @subcategory Components
 */
import div from "ui/html/div";
import { merge } from "util/object";

/**
 * @function cutout
 * @param {ChildEl} content
 * @param {Selector} [sel=""]
 * @param {?object} [...configs]
 * @return {module:ui/common/el~El}
 */
export default function cutout(content, sel = "", ...configs) {
  return div(
    `${sel}.cutout`,
    merge.all([{}, ...configs]),
    content,
  );
}
