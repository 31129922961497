/**
 * A modal dialog for previewing a group.
 *
 * @module ui/component/modal/group
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, h2, p } from "ui/html";
import closeFab from "ui/component/modal/component/close-fab";
import cameoUser from "ui/component/cameo-user";
import { principalDisplayName } from "util/format";
import icon from "ui/component/icon";
import { systemManagementGroupsSet } from "model/acl/constants";
import { getModal } from "ui/view/modal-view";
import adminSystemPrivilegeHelpModal from "./help/system-privilege";

const empty = (value) => (
  value === undefined
  || value === null
  || value === ""
  || value?.length === 0
);

const members = (group) => (!empty(group.members)
  ? div(".members", group.members.map((principal) => cameoUser({ user: principal.entity })))
  : div(".members", p("No members in this group."))
);

const isSystemGroup = (group) => systemManagementGroupsSet.has(group.name);

/**
 * Relies on the group's membership being expanded with user model entities.
 *
 * When using, if you get an error along the lines of `firstName is undefined` from
 * the user cameo, you forgot to bootstrap the group's members before passing the
 * group to the preview modal.
 *
 * @function modalGroup
 * @param {object} config
 * @param {ACLPrincipalEntry} config.group
 * @param {Selector} [config.sel=""]
 * @param {ModalView} modalView
 */
export default function modalGroup({ group, sel = "" }) {
  return div(
    `${sel}.dialog.group`,
    [
      closeFab(true, getModal()),
      div(
        `.body`,
        [
          div(".group-container-help", [
            h2(principalDisplayName(group)),
            isSystemGroup(group) ? icon("question-square", ".open-help-fab", {
              on: {
                click: () => {
                  getModal().async(adminSystemPrivilegeHelpModal());
                },
              },
            }) : "",
          ]),
          members(group),
        ],
      ),
    ],
  );
}
