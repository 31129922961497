/**
 * Convenience component for displaying ACL user group names.
 * @module ui/component/user-group-name
 * @category UI
 * @subcategory Components - ACL
 */
import { systemPrivilegeGroupsFriendly } from "model/acl/constants";
import span from "ui/html/span";
import groupIcon from "ui/component/group-icon";

/**
 * Presents the user group name, converting system privilege groups into a nicer
 * text representation with an icon to indicate their special status.
 *
 * @function userGroupName
 * @param {ACLGroupEntry} group
 * @return {module:ui/html/span~span}
 */
export default function userGroupName(group) {
  return (group.system
    ? span([
      groupIcon(group.name),
      systemPrivilegeGroupsFriendly.get(group.name),
    ], ".user-group-name")
    : span(group.name, ".user-group-name"));
}
