import log from "log";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import { button } from "ui/html";

export default (modalView) => collapsible({
  title: "Confirm Dialogs",
  open: false,
}, [
  button(["Basic Confirm Dialog", icon("question")], async () => {
    const response = await modalView.confirm(
      "Is this a drop-in replacement for window.confirm()?",
    );
    if (response) log.debug("The user agreed.");
    else log.debug("The user is technically correct, which is the best kind of correct.");
  }, ".subtle.forward"),
  button(["Custom Confirm Dialog", icon("question-circle")], () => {
    modalView.customConfirm({
      title: "Confirm Dialog",
      body: "This is a confirm dialog. Please confirm or cancel.",
      confirmLabel: "Confirm",
      cancelLabel: "Cancel",
      onConfirm: () => log.debug("Confirm dialog confirmed."),
      onCancel: () => log.debug("Confirm dialog canceled."),
    });
  }, ".secondary.subtle.forward"),
  button(["Confirm With Custom Buttons", icon("comment-alt")], async () => {
    const response = await modalView.confirm(
      "Check it Out",
      "This works like a confirm dialog, but you can control the buttons.",
      "Wow, neat",
      "I don't need this",
    );
    if (response) log.debug("The user is impressed.");
    else log.debug("The user is unimpressed.");
  }, ".subtle.forward"),
  button(["Confirm With Body", icon("comment-alt")], async () => {
    const response = await modalView.confirm(
      null,
      "Is this a drop-in replacement for window.confirm()?",
    );
    if (response) log.debug("The user agreed.");
    else log.debug("The user is technically correct, which is the best kind of correct.");
  }, ".subtle.forward"),
  button(["Dangerous Confirm Dialog", icon("question-circle")], () => {
    modalView.customConfirm({
      title: "Woah Are You Sure?",
      body: "You're about to break something.",
      confirmLabel: "Do it",
      cancelLabel: "Nevermind",
      onConfirm: () => log.debug("The user likes to live dangerously."),
      onCancel: () => log.debug("The user played it safe."),
      dangerous: true,
    });
  }, ".secondary.subtle.forward"),
]);
