/**
 * A card for displaying lectures, used in grids and carousels.
 *
 * @module ui/component/card/content
 * @category UI
 * @subcategory Components
 */
import { evaluationStatus, evaluationStatusFriendly } from "model/course/constants";
import a from "ui/html/a";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import img from "ui/html/img";
import p from "ui/html/p";
import span from "ui/html/span";
import icon from "ui/component/icon";
import placeholder from "ui/component/placeholder";
import { lectureUrl } from "util/course";
import { splitParagraphs } from "util/format";
import { txt } from "util/dom";

const makeCb = (node, url) => () => {
  /* eslint-disable no-param-reassign */
  if (!node.elm.style.backgroundImage) node.elm.style.backgroundImage = `url(${url})`;
  if (node.elm.dataset.imageLoaded === "not-loaded") {
    setTimeout(() => {
      node.elm.dataset.imageLoaded = "loaded";
    }, 333); // give it a little time to draw itself
    /* eslint-enable no-param-reassign */
  }
};

const poster = (url, metadata) => {
  const post = div(
    ".poster",
    {
      key: metadata.id,
      hook: {
        /* eslint-disable-next-line no-param-reassign */
        insert: (node) => { node.elm.dataset.imageLoaded = "not-loaded"; },
      },
    },
  );
  post.children = [
    img(".preloader", url, "", {
      on: { load: makeCb(post, url) },
    }),
    a(metadata),
  ];
  return post;
};

const desc = (description, full = false) => div(
  ".description",
  { props: { title: full ? "" : txt(description) } },
  full
    ? splitParagraphs(description).map((line) => p(line))
    : splitParagraphs(description)[0],
);

const posterPlaceholder = () => div(".poster", placeholder());

const lectureBody = (lecture, state) => {
  const video = state.videos?.get(lecture.videoId);
  const slides = state.documents?.get(lecture.slidesId);
  const mats = lecture.additionalMaterialIds?.map((mid) => state.documents?.get(mid));
  let posterUrl;
  if (video) posterUrl = video?.getPosterUrl();
  else if (slides) posterUrl = slides?.getPosterUrl();
  else if (mats?.length) posterUrl = mats[0]?.getPosterUrl();
  return div(".content-card.lecture-card", [
    posterUrl
      ? poster(posterUrl, lecture)
      : posterPlaceholder(),
    h3(lecture.title, "", { props: { title: lecture.title } }),
    desc(lecture.description, false),
    a(
      "view",
      lectureUrl(lecture.courseId, lecture.moduleId, lecture.id),
      ".button",
    ),
  ]);
};

const assessmentBody = (assessment, state) => {
  const { slug } = state.page;
  const ev = [...state.evaluations.values()]
    .find((e) => e.assessmentId === assessment.id);
  if (assessment && ev) {
    const status = evaluationStatusFriendly.get(ev.status);
    let btn;
    switch (ev.status) {
      case evaluationStatus.NEW:
      case evaluationStatus.IN_PROGRESS:
        btn = a("continue", `/assessment?id=${assessment.id}&courseId=${assessment.courseId}&moduleId=${assessment.moduleId}&prevPage=${slug}`, ".button");
        break;
      case evaluationStatus.SCORED:
        btn = a("score", `/score?courseId=${assessment.courseId}&evaluationId=${ev.id}`, ".button");
        break;
      case evaluationStatus.INCOMPLETE:
      default:
        btn = a("pending", "", ".button.disabled");
    }
    return div(".content-card.assessment-card", [
      span(icon("award"), ".poster"),
      h3(assessment.title),
      desc(status),
      btn,
    ]);
  }

  return div(".content-card.assessment-card", [
    span(icon("award"), ".poster"),
    h3(assessment.title),
    desc(assessment.description),
    a(
      "view",
      `/assessment?id=${assessment.id}&courseId=${assessment.courseId}&moduleId=${assessment.moduleId}&prevPage=${slug}`,
      ".button",
    ),
  ]);
};

/**
 *
 * @function moduleItemCard
 * @param {module:api/types~Lecture} item
 * @param {Object} state
 * @return {module:ui/common/el~El}
 */
// eslint-disable-next-line import/prefer-default-export
export const moduleItemCard = (state) => ({ metadata: item }) => {
  if (item === null) return "";
  if (item.questions) { // an assessment
    return assessmentBody(item, state);
  }
  return lectureBody(item, state);
};
