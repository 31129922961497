/**
 * A help modal dialog for add new page section.
 *
 * @module ui/component/modal/help/add-new-page-section
 * @category UI
 * @subcategory Modal Dialogs
 */
import { p } from "ui/html";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminAddNewPageSectionHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_ADD_NEW_PAGE_SECTION,
    skipCache,
    async,
  }, [
    p("What types of sections can I add to a page?"),
    p(`Text: This section type adds a text block to the existing page. If you want to post a course
    description or notification, you can do this on the page itself. Add a Title and Description/Corresponding
    Text. After you’re finished, click Finish Editing to save the changes. Don’t forget to press Add or
    Save at the bottom of the existing course page!`),
    p(`Slider: This section adds new slider images to the course page. You can choose however many
    slider images you would like. Please make sure each image is 1800 by 720 pixels in size and
    saved in JPEG format. After the images are uploaded, please click Finish Editing. Don’t forget
    to press Add or Save at the bottom of the existing course page!`),
    p(`Playlist Carousel: This section type adds an existing playlist to the page in carousel format.
    Carousel format allows end-users to navigate through a playlist with forward and backward arrows.
    If a playlist titled ‘Computer Science 101’ is added to the content library, you can take this newly
    created list and display it in carousel format. Add a title, choose the playlist, and click Finish
    Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
    p(`Playlist Grid: This section type adds an existing playlist to the page in grid format. Grid format
    allows end-users to navigate through a playlist by scrolling down the screen. The content will be displayed
    side by side in multiple rows. If a playlist titled ‘Computer Science 101’ is added to the content
    library, you can take this newly created list and display it in grid format. Add a title, choose the playlist,
    and click Finish Editing. Don’t forget to press Add or Save at the bottom of the existing course page!`),
    p(`Dynamic Carousel: This section type adds a preset playlist to the page in carousel format.
    Preset playlists include Recently Added, Recently Watched, Most Watched, Trending, and other options.
    Carousel format allows end-users to navigate through a playlist with forward and backward arrows.
    Add a title, choose the preset playlist, and click Finish Editing. Don’t forget to press Add or Save
    at the bottom of the existing course page!`),
    p(`Dynamic Grid: This section type adds a preset playlist to the page in grid format. Grid
    format allows end-users to navigate through a playlist by scrolling down the screen.
    Preset playlists include Recently Added, Recently Watched, Most Watched, Trending, and other options.
    Add a title, choose the playlist, and click Finish Editing. Don’t forget to press Add or Save at the
    bottom of the existing course page!`),
  ]);
}
