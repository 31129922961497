/**
 * A table that supports sorting by columns and pagination.
 *
 * @module ui/component/dynamic-table-pageable
 * @category UI
 * @subcategory Components
 */
import { div } from "ui/html";
import dynamicTable, { sortOrders } from "./dynamic-table";
import paginator from "./paginator";

const MAX_SHOWN_PAGES = 5;

/**
 *
 * @param {number} currentPage
 * @param {number} totalPages
 * @param {number} maxShownPages
 * @param {function} onPageChange
 * @param {boolean} loading use to blur the table (to show that something is loading)
 * @param {?Array.<String>} columnLabels
 * @param {?Array.<String>} columns ordered array of property keys for row objects
 * @param {?Array.<Object>} rows ordered array of objects to populate rows with
 * @param {?Array.<Selector>} rowSelectors css selectors to be applied to rows
 * @param {string|number|El} rows[row[prop]] row properies for display in columns
 * @param {?OnSortChangeCallback} onSortChange called when a column label is clicked
 * @param {string} sortColumn
 * @param {sortOrders} sortOrder
 * @returns {El|*}
 */
export default function dynamicTablePageable({
  currentPage,
  totalPages,
  maxShownPages = MAX_SHOWN_PAGES,
  onPageChange = () => {},
  loading = false,
  columnLabels = null,
  columns = null,
  rows = [],
  rowSelectors = [],
  onSortChange = () => {},
  sortColumn = null,
  placeholder = "N/A",
  sortOrder = sortOrders.ASC,
}) {
  return div(`.table-pageable${loading ? ".loading" : ""}`, [
    dynamicTable({
      columnLabels,
      columns,
      rows,
      rowSelectors,
      onSortChange,
      sortColumn,
      sortOrder,
      placeholder,
    }),
    paginator({
      currentPage,
      totalPages,
      maxShownPages,
      onPageChange: (page) => onPageChange(page),
    }),
  ]);
}
