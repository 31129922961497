/**
 * Editor fields for text block sections.
 *
 * @module ui/component/modal/section-edit/edit-text-block
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
import dialog from "ui/component/modal/layout/dialog";
import bindModalForm from "ui/component/modal/bound-form";
import form from "ui/component/form-managed";
import input from "ui/component/form-managed/field-input";
import { getModal } from "ui/view/modal-view";
import { h3, div } from "ui/html";
import button from "ui/component/form-managed/button";

const formContent = (formView) => form("#edit-text-block-form", formView.bind([
  [input, {
    name: "text",
    label: "Text",
    required: true,
  }],
], formView.state));

const onSave = (formView) => {
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  getModal().resolve(formView.values.text);
};

export default function editTextBlockModal({ text = "" }) {
  const formNode = bindModalForm("edit-text-block-form", formContent, { text });
  return dialog({
    sel: ".edit-text-block-form",
    header: h3("Edit Text Block"),
    body: formNode,
    footerSpacer: true,
    footer: [
      div(".buttons", { attrs: { role: "group" } }, [
        button({ label: "Save", onClick: () => onSave(formNode.view) }),
      ]),
    ],
  }, getModal());
}
