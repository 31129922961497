/**
 * Type definitions and sanitation functions for notifications.
 *
 * @module model/notification
 * @category Model
 * @subcategory Notification
 */
import { required, validArray, validString } from "../constraints";

/**
 * A notification message object. Used in notification message that pops on the screen
 *
 * @typedef NotificationMessage
 * @property {string} title
 * @property {string} text
 * @property {notificationMessageTypes} type
 * @property {number} duration
 */

/**
 * A notification object.
 *
 * @typedef NotificationItem
 * @property {string} message
 * @property {Date} createdAt
 */

/**
 * Converts a smartedge API notification response DTO to a NotificationItem object.
 *
 * Found in `/api/notification/{id}` endpoint.
 *
 * @function responseToNotification
 * @param {object} response DTO from the smartedge API
 * @param {string} response.message
 * @param {Date} response.createdAt
 * @return {NotificationItem}
 */
export const responseToNotification = (response) => ({
  message: response.message,
  createdAt: response.createdAt ? new Date(response.createdAt) : new Date(),
});

/**
 * A notification DTO.
 *
 * @typedef NotificationDto
 * @property {string} message
 * @property {Array} userIds
 */

/**
 * DTO for sending notifications.
 *
 * Sent to `/api/notification/{id}` endpoint.
 *
 * @function makeNotificationDTO
 * @param {object} partial
 * @param {string} partial.message
 * @param {Array} partial.userIds
 * @return {NotificationDto}
 */
export const makeNotificationDTO = (partial) => ({
  message: required(partial.message, "NotificationDto.message", validString),
  userIds: required(partial.userIds, "NotificationDto.userIds", validArray),
});
