/**
 * A field for displaying and editing labels.
 *
 * @module ui/component/form-managed/field-label-set
 * @category UI
 * @subcategory Forms
 */
import {
  div,
  span,
} from "ui/html";
import button from "ui/component/form-managed/button";
import icon from "ui/component/icon";
import labelSelect from "ui/component/modal/label-select";
import { managedField } from "ui/view/form-managed/util";
import { emit } from "util/event";
import { frozen, merge } from "util/object";
import { sortText } from "util/sort";

const helpTexts = frozen({
  required: "Please select at least one label.",
  invalid: "Please enter one or more valid labels.",
});

const defaultState = frozen({
  disabled: false,
  existing: [],
  helpTexts,
  label: "Labels",
  name: "labels",
  required: false,
  sel: "",
  validity: null,
  value: [],
  values: null,
});

const getValue = (state) => () => state.value;

const onDelete = (state, rootNode, deleted) => () => {
  const newState = merge(state, {
    value: state.value.filter((l) => l.name !== deleted.name),
  });
  // below we replace the getValue function to return the filtered labels
  // and then trigger a form render. This may fail if the form's state has been
  // heavily meddled with
  /* eslint-disable-next-line no-param-reassign */
  rootNode.managedField.getValue = getValue(newState);
  emit(rootNode.elm, "em:form-managed-change");
};

const onAddLabel = (state, rootNode, modal) => async () => {
  const selected = await modal.async(labelSelect({
    entries: state.existing,
    selectedLabels: state.value,
    multiSelect: true,
  }, modal));
  if (selected) {
    const newState = merge(state, { value: selected, values: null });
    // below we replace the getValue function to return the newly selected labels
    // and then trigger a form render. This may fail if the form's state has been
    // heavily meddled with
    /* eslint-disable-next-line no-param-reassign */
    rootNode.managedField.getValue = getValue(newState);
    emit(rootNode.elm, "em:form-managed-change");
  }
};

const uniqueLabels = (selected = [], all = []) => {
  const found = new Set();
  const unique = [];
  [...selected, ...all].forEach((label) => {
    if (!found.has(label.name)) {
      found.add(label.name);
      unique.push(label);
    }
  });
  return unique.sort((a, b) => sortText(a.name, b.name));
};

/**
 * @function labelSet
 * @param {object} state
 * @param {string} [state.label="Labels"]
 * @param {string} [state.name="labels"]
 * @param {string[]} [state.value=[]] (or taken from state.values[state.name])
 * @param {Label[]} [state.existing=[]] labels that exist elsewhere in the system
 */
export default function labelSet(inState, modal) {
  const state = merge(defaultState, inState);
  state.value = state?.values?.[state.name] || state.value || [];
  state.existing = uniqueLabels(state.value, state.existing);

  const rootNode = managedField(
    div(`${state.sel}.field.label-set${state.disabled ? ".disabled" : ""}`),
    state.name,
    getValue(state),
  );

  const display = div(
    `.labels`,
    state.value?.map((label) => span(
      [icon("tag"), icon.sharp("xmark"), label.name],
      ".label",
      { on: { click: state.disabled ? undefined : onDelete(state, rootNode, label) } },
    )),
  );

  const edit = button.inverse({
    label: "Edit Labels",
    icon: "pencil",
    onClick: onAddLabel(state, rootNode, modal),
    disabled: state.disabled,
  });

  rootNode.children = [display, edit];
  return rootNode;
}
