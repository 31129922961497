/**
 * Utility functions related to error handling.
 *
 * @module util/error
 * @category Utilities
 */
import cache from "cache";
import log from "log";
import { frozen } from "util/object";

// TODO collect this elsewhere
export const PATH_LOGIN = "/login";

/**
 * @enum errorCodes
 */
export const errorCodes = frozen({
  USER_BAD_LOGIN: "USER_BAD_LOGIN",
  USER_BAD_LOGIN_LDAP: "USER_BAD_LOGIN_LDAP",
  USER_TAKEN_USERNAME: "USER_TAKEN_USERNAME",
  USER_TAKEN_EMAIL: "USER_TAKEN_EMAIL",
  USER_TAKEN_PHONE: "USER_TAKEN_PHONE",
  CONTENT_IN_USE: "CONTENT_IN_USE",
  CONTENT_BAD_ATTACHMENT: "CONTENT_BAD_ATTACHMENT",
  CONTENT_BAD_LABEL: "CONTENT_BAD_LABEL",
  CONTENT_BAD_FILE: "CONTENT_BAD_FILE",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  UNKNOWN: "UNKNOWN",
});

/**
 * Deal with different API error codes.
 *
 * @constant errorPatterns
 * @type {Array.<object>}
 * @private
 * @readonly
 */
const errorPatterns = Object.freeze([
  {
    pattern: /(javax.naming.NameNotFoundException)|(Ldap auth failed)/,
    replacement: "Wrong credentials entered. Please input valid user information.",
    code: errorCodes.USER_BAD_LOGIN_LDAP,
  },
  {
    pattern: /User already exists|User with username [\S]+ already exists/,
    replacement: "User name is already taken.",
    code: errorCodes.USER_TAKEN_USERNAME,
  },
  {
    pattern: /LDAP: error code 32/,
    replacement: "Incorrect login information. Please check your username and password and try again.",
    code: errorCodes.USER_BAD_LOGIN_LDAP,
  },
  {
    pattern: /A user with email [\S]+ already exists/,
    replacement: "Email address is already in use.",
    code: errorCodes.USER_TAKEN_EMAIL,
  },
  {
    pattern: /A user with cellphone [\S]+ already exists/,
    replacement: "Phone number is already in use.",
    code: errorCodes.USER_TAKEN_PHONE,
  },
  {
    pattern: /Cannot delete the item as it is a part of one or more item lists/,
    replacement: "This item cannot be deleted because it is part of one or more item lists.",
    code: errorCodes.CONTENT_IN_USE,
  },
  {
    pattern: /Wrong verification code/,
    replacement: "Incorrect verification code. Please check that your verification code matches and try again.",
    code: errorCodes.USER_BAD_VERIFICATION,
  },
  // this should always be the last entry
  {
    pattern: /Invalid user properties/,
    replacement: null, // always comes with a better message
    code: errorCodes.USER_BAD_LOGIN,
  },
  {
    pattern: /Not a valid media file/,
    replacement: "One or more of the attached files is corrupt or has an incorrect file extension. Please check that the file extension matches the file type and that it can be viewed/played back locally, then try again.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /Forbidden/,
    replacement: "You do not have permission to make this change. Please contact a site administrator for assistance.",
    code: errorCodes.PERMISSION_DENIED,
  },
  {
    pattern: /categories.*must match/,
    replacement: "One or more of the chosen labels were invalid. Please check them and try again.",
    code: errorCodes.CONTENT_BAD_LABEL,
  },
  {
    pattern: /categories.*must not be blank/,
    // usually redundant with "must match" variant
    replacement: null,
    code: errorCodes.CONTENT_BAD_LABEL,
  },
  {
    pattern: /Cannot calculate video resolution/,
    replacement: "The video you selected is corrupt or in an unsupported format. Please re-encode it as a web-compatible MP4 and try again.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /No VideoFileDescriptor instances found/,
    replacement: "The video you selected is corrupt or in an unsupported format. Please re-encode it as a web-compatible MP4 and try again.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /Bad request while operating with instance VideoFileDescriptor/,
    replacement: "The video you selected is corrupt or in an unsupported format. Please re-encode it as a web-compatible MP4 and try again.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /For input string/,
    replacement: "The video you selected is corrupt or in an unsupported format. Please re-encode it as a web-compatible MP4 and try again.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /bad Mime type/,
    replacement: "The video or poster file you selected is an invalid file type. Please select a valid file.",
    code: errorCodes.CONTENT_BAD_ATTACHMENT,
  },
  {
    pattern: /ConstraintViolationException/,
    replacement: "This item is linked to or in use by another part of the system. Please remove the item from all playlists, pages, and other content and try again.",
    code: errorCodes.CONTENT_IN_USE,
  },
]);

/**
 * Maps a backend error body to a Set of standardized error codes.
 *
 * Used for doing smarter things with error messages than just showing them to the user.
 *
 * @function getErrorCodes
 * @param {Array.<String>} message
 * @return {errorCodes}
 */
export const getErrorCode = (message) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const group of errorPatterns) {
    if (group.pattern.test(message)) {
      return group.code;
    }
  }
  return errorCodes.UNKNOWN;
};

/**
 * Tests backend error messages against the patterns in errorPatterns, and replaces
 * them with a user-friendly string if one is found.
 *
 * @function mapBackendError
 * @param {string} message from the backend
 * @return {string} a nicer message
 */
export const mapBackendError = (message) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const errorPattern of errorPatterns) {
    const { pattern, replacement } = errorPattern;
    if (pattern.test(message)) return replacement;
  }
  return `Unhandled exception: ${message}`;
};

/**
 * An all-in-one function that takes a backend Error body and produces
 * formatted spans for use in a form's notification area.
 *
 * Preferentially uses `errorBody.errors` array, and failing that `errorBody.message`.
 *
 * @function tidyBackendError
 * @param {object} errorBody
 * @param {string[]} [errorBody.errors]
 * @param {string} [errorBody.message]
 * @return {string[]} transformed errors
 */
export const tidyBackendError = (errorBody) => {
  if (errorBody.errors instanceof Array && errorBody.errors.length) {
    return [...new Set(errorBody.errors.map(mapBackendError))]
      .filter((m) => m !== null);
  }
  if (errorBody?.message?.length) {
    const message = mapBackendError(errorBody.message);
    if (message !== null) return [message];
    return [];
  }
  return ["An unknown error occurred. Please report this to the operators."];
};

/**
 * Deals with API errors thrown by the backend.
 *
 * Side effect: if the error is a `403 Unauthorized` it will clear the storage and
 * authentication state and redirect the user to the login page.
 *
 * @function handleAPIError
 * @param {Error} error
 * @deprecated this handling is now done at the root `app.js`
 */
export const handleAPIError = async (error) => {
  if (error.statusCode === 403) {
    if (window.location.pathname !== PATH_LOGIN) {
      if (cache.getProfile() !== null) {
        /* eslint-disable-next-line no-alert */
        window.alert("You have been logged out due to inactivity. Please log back in to continue...");
      }
      cache.clear();
      window.location.replace(PATH_LOGIN);
    }
  } else {
    log.error(error);
  }
};
