/**
 * Help section for the assessment profile.
 *
 * @module ui/page/admin/assessment/help
 * @private
 * @category Pages
 * @subcategory Admin - Assessment
 */
/***/
import { featureTypes } from "model/config/constants";
import { a, li, ol, p, section } from "ui/html";
import { guard } from "util/feature-flag";

export default function helpSection() {
  guard(featureTypes.LMS);
  return section(".container-help", [
    p("Assessments are a way to test students and gauge course progress. Many different types of assessments can be created on SmartEdge, including tests, quizzes, essay drops, or extra credit."),
    p("To add an assessment:"),
    ol([
      li("Add a title (mandatory field) and description."),
      li("You can set a schedule for assessment. This allows for assessments to display at a certain time or wrap up before a certain date."),
      li("Add in maximum points (numerical value) and time limit (mandatory)."),
      li("If the assessment is mandatory for course progress, turn the MANDATORY toggle green."),
      li("Assessments need to be linked to course modules within the course itself. Please select the intended course module. This step is mandatory."),
      li("We currently support three question types- multiple choice, text entry, and file drop. To add questions to the assessment, click ADD QUESTION and choose which question type you would like to add. For multiple choice, type in the question and add answers in the right column. You can randomize your answers for end-user display. Within each answer, you can indicate which answer is the correct one. If you choose a correct answer, autograding will be enabled on the evaluation interface (i.e. you cannot manually grade these questions). You can also choose more than one correct answer if Multi Answer is enabled. You need to add in a weight for the question. The sum of the weights for each question should match the Max Points for the assessment. Text entry questions are similar. Please add the question then figure out whether the question is short or long form (i.e. less than 250 characters or greater than 250 characters for the answers). Again, please add the weight for points awarded for the question. File drop questions involve end-user upload of files for further review by the instructors. Please add the question and indicate the weight for points awarded for the question."),
      li([
        "Press SAVE and the assessment should be created. To make any further edits, please visit the assessment profile. To delete the assessment, click the red DELETE button at the top of the assessment profile.",
      ]),
    ]),
    p([
      "In case of any issues, please email ",
      a("support@smartedge.com", "mailto:support@smartedge.com"),
      " or contact your organization’s SmartEdge administrator.",
    ]),
  ]);
}
