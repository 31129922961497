/**
 * Admin page for creating new dynamic pages.
 *
 * @module ui/page/admin/dynamic/add
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
import log from "log";
import api from "api";
import getConfig from "config";
import { pageTemplates, pageTemplateTypes } from "model/dynamic-page/templates";
import cameoPageSection from "ui/component/cameo-page-section";
import sortable from "ui/component/sortable";
import widget from "ui/component/dashboard-widget";
import {
  div,
  span,
} from "ui/html";
import view from "ui/view";
import { tidyBackendError } from "util/error";
import { getQueryParams } from "util/navigation";
import { merge } from "util/object";
import actionBar from "ui/component/dashboard-action-bar";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import dashboardLayout from "ui/page/layout/dashboard";
import slug from "ui/component/form-managed/field-slug";
import title from "ui/component/form-managed/field-title";
import button from "ui/component/form-managed/button";
import { notificationMessageTypes } from "model/notification/constants";
import { qs } from "util/dom";
import { defaultPage, defaultEditState, displayModes } from "./state";
import staticMessages from "./messages";
import {
  addSection,
  handleSectionImages,
  onDeleteSection,
  onEditSection,
  onSortSection,
  savePage,
} from "./common";
import helpSection from "./help";

let actionsView;
let modalView;
let loadingView;
let addPageView;
let sectionsView;
let notificationView;

// TODO formalize these channels if we end up using them a lot
const pageUpdateChannel = (typeof BroadcastChannel !== "undefined")
  ? new BroadcastChannel("page_updates")
  : null;

const doSave = async (self) => {
  self.setFullValidation(true);
  const validation = self.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  const { values, state } = self;
  // deep clone to stip out any protections and avoid accidentally
  // changing anything
  const page = merge({}, { ...self.state.page, ...values });
  if (
    page.sections.length === 0
    && !(await modalView.confirm("You haven't added any sections. Are you sure you want to save?"))
  ) return;
  log.debug("trying to save", page);
  // now let's handle images
  let abort = false;
  loadingView.show("Saving page multimedia...");
  try {
    page.sections = await Promise.all(page.sections.map(
      handleSectionImages(self, loadingView),
    ));
  } catch (e) {
    abort = true;
  }
  if (abort) {
    loadingView.hide();
    return;
  }
  if (abort) return;
  // otherwise images went fine, let's save the page object
  try {
    loadingView.show(`Saving ${page.title}`);
    const res = await savePage(page);
    log.debug("got result", res);
    if (state.sideMenu.entries?.length) {
      await api.site.savePageMenu(res.id, state.sideMenu);
    }
    modalView.customConfirm({
      title: 'Page was successfully added',
      body: '',
      confirmLabel: "Add More",
      cancelLabel: `Go To Page Profile`,
      onConfirm: () => window.location.assign('/admin/add-page'),
      onCancel: () => window.location.assign(
        `/admin/page-profile?id=${res.id}`,
      ),
    });
    pageUpdateChannel?.postMessage({ type: "page_created", res });
    loadingView.show("Redirecting...");
  } catch (err) {
    log.error(err);
    if (err.statusCode) {
      switch (err.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDenied);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
    } else {
      notificationView.post(staticMessages.backendErrors);
    }
    loadingView.hide();
  }
};

const buttons = (self) => ([
  self.state.displayMode === displayModes.HELP
    ? button.alternate({
      icon: "table",
      label: "Page Profile",
      onClick: () => {
        // need to double-update to get validation back
        qs("main.dashboard").classList.remove("show-help");
        addPageView.update({ displayMode: displayModes.PAGE_PROFILE });
        addPageView.update({});
      },
      ariaLabel: "Show Page Profile",
    })
    : button.alternate({
      icon: "question-circle",
      label: "Help",
      onClick: () => {
        qs("main.dashboard").classList.add("show-help");
        addPageView.update({ displayMode: displayModes.HELP });
      },
      ariaLabel: "Show Help",
    }),
  button.primary({
    icon: "save",
    label: "Save",
    onClick: () => doSave(self),
  }),
]);

const showActions = () => div("#actions", buttons(addPageView));

const showSections = ({ state }) => {
  const onEdit = onEditSection(addPageView, modalView, notificationView);
  const onDelete = onDeleteSection(addPageView, modalView);
  const onSort = onSortSection(addPageView, modalView);

  return div("#sections.container", [
    sortable({ childSelector: "span.cameo-page-section.cameo", onSort }, div(
      ".sections",
      {},
      [
        ...state.page.sections.map(
          (section, id) => cameoPageSection({
            section,
            id,
            onEdit,
            onDelete,
          }),
        ),
      ],
    )),
    span([
      button.standIn({
        sel: "#add-section",
        icon: "plus-circle",
        label: "Add Section",
        onClick: addSection(addPageView, modalView, notificationView),
      }),
    ], ".add-section"),
  ]);
};

const showAddPage = (self) => {
  const takenSlugs = self.state.pages.map((page) => page.slug);

  return form("#add-page-form", self.bind([
    [title, { required: true }],
    [slug, { required: true, title: self.values?.title, taken: takenSlugs }],
  ]));
};

const doUpdate = (self) => {
  const { values } = self;
  if (self.state.validation.valid) {
    self.updateState({
      messages: self.state.messages.filter((m) => m !== staticMessages.invalid),
    });
  }
  self.updateState({ page: { ...values } });
  self.render();
  if (actionsView) actionsView.update({ displayMode: self.state.displayMode });
  if (sectionsView) sectionsView.update(self.state);
};

export default async function addPage(selector) {
  const pageTitle = "Add Page";
  const { header, loading, modal, notification } = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget([
        form("#add-page-form"),
        div("#sections"),
      ], "Profile", ".page-profile"),
      widget(div("#help"), null, ".help"),
    ],
    pageTitle,
    true,
  );
  modalView = modal;
  loadingView = loading;
  notificationView = notification;
  loading.show();

  const params = getQueryParams();

  const [config, user, files, metadata, descriptors, courses, pages] = await Promise.all([
    getConfig(),
    api.user.getMe(),
    api.media.list(),
    api.metadata.list(),
    api.file.list().catch(() => []),
    api.course.listCourses(),
    api.page.list(),
  ]);

  let templateTarget;
  let page = defaultPage;
  if (params.template) {
    if (params.template === pageTemplateTypes.COURSE_CAROUSEL) {
      if (params.courseId) {
        templateTarget = courses.find((c) => c.id === params.courseId);
      }
      if (templateTarget) {
        page = pageTemplates.get(params.template)(templateTarget, pages) || defaultPage;
      } else {
        await modal.alert(
          "Error Creating Course Page",
          "An invalid course ID was provided.",
          true,
        );
      }
    }
  }

  const state = {
    ...defaultEditState,
    config,
    courses,
    user,
    files,
    metadata,
    descriptors,
    pages,
    title: pageTitle,
    page,
  };
  header.update(state);
  addPageView = formManagedView(showAddPage, doUpdate)("#add-page-form", state);
  sectionsView = view.create(showSections)("#sections", state);
  actionsView = view.create(showActions)("#actions", state);
  view.create(helpSection)("#help", state);
  pageUpdateChannel?.postMessage({ type: "miscellany", text: "Initiated" });
  loading.hide();
}
