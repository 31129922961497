/**
 * @module ui/page/admin/messaging/common
 * @private
 * @category Pages
 * @subcategory Admin - Messaging
 */
/***/
import reportedMessageActionModal from "ui/component/modal/chat/reported-message-action";
import { defaultSearchParams, fetchDescriptors } from "ui/page/chat/common";
import api from "api";
import log from "log";
import { aclPrincipalTypes, aclRights, systemPrivilegeGroupsFriendly } from "model/acl/constants";
import { canModerate } from "model/user";
import { threadStatuses } from "model/message/constants";
import getUsername from "ui/component/messaging/username";
import reportedThreadAction from "ui/component/modal/chat/reported-thread-action";
import { reportTypes } from "@smartedge/em-message/constants";
import { getModal } from "ui/view/modal-view";

export const loadDescriptors = (self, messages = []) => {
  messages.forEach((m) => fetchDescriptors(self, m.attachments?.filter((a) => a) || []));
};

// eslint-disable-next-line max-len
export const isScrolledToBottom = (node) => (node.scrollTop + node.offsetHeight + 50) >= node.scrollHeight;

const getGroupThreadStatus = async (conversationId, groupId, groups) => {
  const aclEntries = await api.acl.listPrincipalsForConversationId(conversationId);
  let principal = aclEntries.find((entry) => entry.id === groupId);
  if (!principal) {
    const group = groups.find((g) => g.id === groupId);
    principal = aclEntries.find((entry) => entry?.name === group?.name);
    if (!principal) {
      return threadStatuses.REMOVED;
    }
  }
  const grants = await api.acl.listPrincipalAccessRightsForConversationId(
    conversationId, principal.id, aclPrincipalTypes.GROUP,
  );
  if (grants.includes(aclRights.READ) && grants.includes(aclRights.WRITE)) {
    return threadStatuses.OPEN;
  }
  if (!grants.includes(aclRights.WRITE)) {
    return threadStatuses.CLOSED;
  }
  if (grants.length === 0) {
    return threadStatuses.REMOVED;
  }
  return threadStatuses.REMOVED;
};

const getDmThreadStatus = async (conversationId, userOne, userTwo) => {
  // limited principal rights do not include group rights
  // makes logic below simpler
  const userOneGrants = await api.acl.listLimitedPrincipalAccessRightsForConversationId(
    conversationId, userOne.id, aclPrincipalTypes.INDIVIDUAL,
  );
  const userTwoGrants = await api.acl.listLimitedPrincipalAccessRightsForConversationId(
    conversationId, userTwo.id, aclPrincipalTypes.INDIVIDUAL,
  );
  let userOneRights, userTwoRights;
  if (
    userOneGrants.includes(aclRights.READ)
    && userOneGrants.includes(aclRights.WRITE)) {
    userOneRights = "RW";
  } else if (userOneGrants.includes(aclRights.READ)) {
    userOneRights = "R";
  } else userOneRights = "NONE";
  if (
    userTwoGrants.includes(aclRights.READ)
    && userTwoGrants.includes(aclRights.WRITE)) {
    userTwoRights = "RW";
  } else if (userTwoGrants.includes(aclRights.READ)) {
    userTwoRights = "R";
  } else userTwoRights = "NONE";

  // moderator <-> moderator can't be closed/removed
  // moderator <-> user only counts the non-moderator user
  if (canModerate(userOne) && canModerate(userTwo)) return threadStatuses.OPEN;
  // RW for both = open
  if (userOneRights === "RW" && userTwoRights === "RW") {
    return threadStatuses.OPEN;
  }
  // Only R for non-moderators = closed
  if (
    (userOneRights === "R" && userTwoRights === "R")
    || (canModerate(userOne) && userTwoRights === "R")
    || (canModerate(userTwo) && userOneRights === "R")
  ) return threadStatuses.CLOSED;
  // No rights for non-moderators = removed
  if (
    (userOneRights === "NONE" && userTwoRights === "NONE")
    || (canModerate(userOne) && userTwoRights === "NONE")
    || (canModerate(userTwo) && userOneRights === "NONE")
  ) return threadStatuses.REMOVED;
  // something is wrong
  log.error("Invalid state of rights for thread,", {
    userOneGrants,
    userTwoGrants,
    userOneModerator: canModerate(userOne),
    userTwoModerator: canModerate(userTwo),
  });
  return threadStatuses.REMOVED;
};

/**
 * Get a thread status
 *
 * @param {object} self
 * @returns {Promise<threadStatuses>}
 */
export const getThreadStatus = async ({
  isGroup,
  userOneId,
  userTwoId,
  groupId,
  groups,
  conversationId,
  users,
}) => {
  const reports = (await api.message.getAllReports())
    .filter((r) => r.conversation === conversationId);
  if (reports.length) {
    return threadStatuses.REPORTED;
  }
  if (isGroup) {
    return getGroupThreadStatus(conversationId, groupId, groups);
  }
  const userOne = users.find((u) => u.id === userOneId);
  const userTwo = users.find((u) => u.id === userTwoId);
  return getDmThreadStatus(conversationId, userOne, userTwo);
};

export const suspendUserDialog = async (user, modal) => {
  const ok = await modal.confirm(`${user.username}'s chat privileges will be suspended. Are you sure?`, "This action is effective immediately and does not require you to click save.");
  if (ok) {
    await api.message.sendSuspendUser(user.id);
  }
  return ok;
};

export const restoreUserDialog = async (user, modal) => {
  const ok = await modal.confirm(`${user.username}'s chat privileges will be restored. Are you sure?`, "This action is effective immediately and does not require you to click save.");
  if (ok) {
    await api.message.sendRestoreUser(user.id);
  }
  return ok;
};

const getReportedUserId = (report) => (report.from === report.reportedMessage.from
  ? report.reportedMessage.to
  : report.reportedMessage.from);

export const takeActionOnReportedMessage = async (report, users) => {
  const reportedUserId = getReportedUserId(report);
  const reportedUser = users.find((u) => u.id === reportedUserId);
  const canSuspend = (!canModerate(reportedUser));
  await getModal().async(reportedMessageActionModal({
    report,
    users,
    onSuspendUser: canSuspend
      ? async () => {
        const ok = await suspendUserDialog(reportedUser, getModal());
        if (ok) {
          await api.message.markMessagesRead([report.id]);
        }
      }
      : null,
  }, getModal()));
};

export const getThreadName = (target, users = [], groups = []) => {
  if (Array.isArray(target)) {
    const [userOneId, userTwoId] = target;
    const userOne = users.find((user) => user.id === userOneId);
    const userTwo = users.find((user) => user.id === userTwoId);
    return `${getUsername(userOne, true)} <-> ${getUsername(userTwo, true)}`;
  }
  const group = groups.find((g) => g.id === target);
  if (group) {
    return systemPrivilegeGroupsFriendly.get(group?.name)
      || group?.name
      || "";
  }
  return null;
};

export const getThreadProfileLink = (target) => {
  if (Array.isArray(target)) {
    return `/admin/thread-profile?userOneId=${target[0]}&userTwoId=${target[1]}`;
  }
  return `/admin/thread-profile?groupId=${target}`;
};

export const takeActionOnThread = async (report, users, groups, modal) => {
  const threadName = getThreadName(report.target, users, groups);
  await modal.async(reportedThreadAction({
    report,
    threadName,
  }, modal));
};

/**
 * Get all reports for provided messages.
 *
 * @function getReportedMessagesForRecentMessages
 * @param {Message[]} recentMessages
 * @returns {Promise<Message[]>}
 */
export const getReportedMessagesForRecentMessages = async (recentMessages = []) => {
  const lastRecentMessage = recentMessages[recentMessages.length - 1];
  if (!lastRecentMessage) return [];
  const searchParams = {
    ...defaultSearchParams(),
    startDate: lastRecentMessage.created,
    messageCount: undefined,
    params: {
      reportType: reportTypes.MESSAGE,
    },
  };
  return api.message.getAllReports(searchParams);
};

/**
 * Find all reports for provided message.
 *
 * @function findReportsForMessage
 * @param {Message} message
 * @param {ReportMessage[]} allReports
 * @returns {Promise<ReportMessage[]>}
 */
export const findReportsForMessage = (message, allReports = []) => allReports
  .filter((report) => report?.reportedMessage?.id === message.id);
