import xs from "xstream";

export default function checker(request) {
  return xs.merge(
    xs.fromPromise(request()),
    xs.periodic(10000)
      .map(() => xs.fromPromise(request()))
      .flatten(),
  );
}
