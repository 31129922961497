/**
 * Defaults and utilities for content records.
 *
 * @module ui/page/admin/content/bulk/content-record
 * @private
 * @category Pages
 * @subcategory Admin - Content
 */
/***/
import { metadataTypes } from "model/metadata/constants";
import { fileTypes } from "model/file-descriptor/constants";
import { getHelpText } from "ui/common/help-texts";
import { qs } from "util/dom";
import { getFileType } from "util/file";

export const contentFields = [
  "type",
  "title",
  "description",
  // "labels", # skip this because it's managed by the label-select
  "posterFileName",
  "contentFileName",
];

export const saveStates = {
  PENDING: "pending",
  SAVING: "saving",
  SUCCESS: "success",
  FAILURE: "failure",
};

// used for record defaults
export const currentYear = new Date().getFullYear();

/**
 * Standard format for input names by rowId.
 * @function fieldName
 * @private
 */
export const fieldName = (field, rowNum) => `${field}_${rowNum}`;

/**
 * Populates a partial content record with defaults for empty fields.
 * @function makeContentRecord
 * @private
 */
export const makeContentRecord = (partial = {}) => ({
  id: partial.id || null,
  type: partial.type || null,
  title: partial.title || '',
  description: partial.description || '',
  labels: partial.labels || [],
  posterFileName: partial.posterFileName || '',
  contentFileName: partial.contentFileName || '',
  language: "eng",
  year: partial.year || currentYear,
  saveStatus: partial.saveStatus || saveStates.PENDING,
  errors: partial.errors || [],
  startDate: partial.startDate ? new Date(partial.startDate) : null,
  endDate: partial.endDate ? new Date(partial.endDate) : null,
  messages: partial.messages || [],
  markedForDeletion: partial.markedForDeletion || false,
  validity: partial.validity || {
    valid: true,
  },
});

export const exists = (value) => value !== null && value !== "" && value !== undefined;

const fileExists = (content, validStates, field, existingFiles) => {
  /* eslint-disable no-param-reassign */
  if (exists(content[field])) {
    if (!existingFiles.includes(content[field])) {
      validStates[field].fileMissing = true;
      validStates[field].valid = false;
    }
  }
  /* eslint-enable no-param-reassign */
};

const fileValid = (content, validStates, field, fileType) => {
  /* eslint-disable no-param-reassign */
  if (exists(content[field])) {
    if (getFileType(content[field]) !== fileType) {
      validStates[field].badInput = true;
      validStates[field].valid = false;
    }
  }
  /* eslint-enable no-param-reassign */
};

const needsMessageFilter = ([field, validState]) => {
  if (!contentFields.includes(field)) return false;
  return !validState.valid;
};

/**
 * For use with array.map on a content list
 */
export const refreshContentRecords = (
  values = [],
  validity = {},
  existingFiles = [],
) => (oldContent, id) => {
  const content = {};
  const contentValidity = {};
  contentFields.forEach((field) => {
    const name = fieldName(field, id);
    if (values[name]) content[field] = values[name];
    else {
      // get updated values from dom - since these aren't two-way bound
      const input = qs(`[name="${fieldName(field, id)}"]`);
      content[field] = input ? input.value : oldContent[field];
    }
    const state = validity[fieldName(field, id)];
    // clone so we can modify
    const validState = state ? { ...state } : {
      valid: true,
    };
    contentValidity[field] = validState;
  });

  fileExists(content, contentValidity, "posterFileName", existingFiles);
  fileExists(content, contentValidity, "contentFileName", existingFiles);

  fileValid(content, contentValidity, "posterFileName", fileTypes.IMAGE);

  switch (content.type) {
    case metadataTypes.VIDEO:
      fileValid(content, contentValidity, "contentFileName", fileTypes.VIDEO);
      break;
    case metadataTypes.DOCUMENT:
      fileValid(content, contentValidity, "contentFileName", fileTypes.DOCUMENT);
      break;
    default:
      break;
  }

  contentValidity.valid = Object.values(contentValidity)
    .reduce((acc, val) => acc && val.valid, true);

  const messages = [
    ...oldContent.errors.map((text) => ({ text, type: "invalid" })),
    ...Object.entries(contentValidity)
      .filter(needsMessageFilter)
      .map(([field, validState]) => getHelpText(field, validState))
      .flat(),
  ];

  return makeContentRecord({
    ...oldContent,
    ...content,
    messages,
    validity: contentValidity,
    errors: [...oldContent.errors],
  });
};

export const allContentValid = (contents) => contents.length && contents
  .reduce((acc, cur) => (cur.saveStatus !== saveStates.SAVING)
    && cur.type
    && cur.posterFileName
    && cur.contentFileName
    && cur.title
    && cur.validity.valid
    && acc,
  true);

export const countStatuses = (contents, status) => contents.reduce(
  (acc, content) => acc + (content.saveStatus === status ? 1 : 0),
  0,
);
