/**
 * Default state and enums for the bulk content form.
 *
 * @module ui/page/admin/user/bulk/state
 * @private
 * @category Pages
 * @subcategory Admin - Users
 */
/***/
import { frozen } from "util/object";

export const displayModes = frozen({
  BULK_UPLOAD: "BULK_UPLOAD",
  HELP: "HELP",
});

export const uploadStates = frozen({
  RETRY: "RETRY",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_READY: "NOT_READY",
  ERRORS: "ERRORS",
  READY: "READY",
  DONE: "DONE",
});

export const userFields = [
  "groupNames",
  "firstName",
  "lastName",
  "username",
  "cellPhone",
  "email",
  "password",
  "enabled",
];

export const saveStates = {
  PENDING: "pending",
  SAVING: "saving",
  SUCCESS: "success",
  FAILURE: "failure",
};

export const defaultState = {
  allVerified: false,
  users: [],
  displayMode: displayModes.BULK_UPLOAD,
  existing: [],
  takenEmails: [],
  takenCellPhones: [],
  takenUsernames: [],
  messages: [],
  groups: [],
  saveStatuses: new Map(),
  saveErrors: new Map(),
  validation: {
    fields: {},
  },
  config: {
    server: {},
  },
  uploadState: uploadStates.NOT_READY,
};
