/**
 * Preset messages used by the section edit dialog
 *
 * @module ui/component/modal/section-edit/messages
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
import { frozen } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";

export default frozen({
  invalid: { title: "Invalid Form", text: "Please fix the errors listed.", type: notificationMessageTypes.FAIL },
  selectPlaylist: { title: "Error", text: "Please select a playlist to continue.", type: notificationMessageTypes.FAIL },
  selectCourse: { title: "Error", text: "Please select a course to continue.", type: notificationMessageTypes.FAIL },
});
