/**
 * Creates a button element.
 *
 * @example
 * // a button that calls a function when it's clicked
 * const btn = ui.html.btn("careful with this!", () => alert("You monster!"), ".danger");
 *
 * @module ui/html/button
 * @category UI
 * @subcategory Elements
 */
import el, { textNodesToSpans } from 'ui/common/el';

/**
 * @type buttonClickCallback
 *
 * @param {module:ui/common/el~El} button
 */

/**
 * A button element. Accepts a callback function that is called when the button
 * is clicked.
 *
 * @function button
 * @param {string|El|El[]} children text label or child nodes
 * @param {buttonClickCallback} [click] callback function for click event
 * @param {string} sel class/id selectors in css format
 * @param {string} [type=button] button `type` property
 * @param {string} [ariaLabel=""] assistive technology label for icon buttons
 * @param {boolean} [disabled=false] whether the button is disabled
 */
export default (
  children,
  click = () => {},
  sel = '',
  type = 'button',
  ariaLabel = "",
  disabled = false,
  ...configs
) => {
  const button = el(
    `button${sel}`,
    { props: { type, disabled } },
    textNodesToSpans(children),
  );

  if (click) {
    button.merge({
      on: {
        click: [click, button],
      },
    });
  }
  if (ariaLabel) button.merge({ attrs: { "aria-label": ariaLabel, title: ariaLabel } });
  configs.forEach((config) => button.merge(config));
  return button;
};
