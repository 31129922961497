/**
 * Possible values for the `type` field on {@link NotificationMessage}
 *
 * @enum {string} notificationMessageTypes
 * @readonly
 * @property {string} BASIC
 * @property {string} SUCCESS
 * @property {string} FAIL
 */
// eslint-disable-next-line import/prefer-default-export
export const notificationMessageTypes = Object.freeze({
  BASIC: "BASIC",
  SUCCESS: "SUCCESS",
  FAIL: "FAIL",
});
