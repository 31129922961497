/**
 * A view specialized for notification messages overlay.
 *
 * The notification message view is set up automatically by the
 * [notificationOverlay]{@link notificationOverlay} helper function
 *
 * @module ui/view/notification-view
 * @category UI
 * @subcategory Views
 */
import notificationOverlay from "ui/component/notification-overlay";
import { merge } from "util/object";
import { notificationMessageTypes } from "model/notification/constants";
import baseView from "./view";

const closeDurationInSeconds = 0.2;
let storedNotificationView = null;

const defaultState = {
  notifications: [],
};

const generateId = () => Math.ceil(Math.random() * 100000);

const calculateDurationInSeconds = (
  duration,
  queue = [],
) => {
  const notificationsWithDurationLength = queue
    .filter((notification) => notification.duration)?.length || 1;
  return (duration + (closeDurationInSeconds * notificationsWithDurationLength * 2)) * 1000;
};

/**
 * A notification view has a couple extensions
 *
 * @function bootstrapNotifications
 * @private
 */
function bootstrapNotifications(view) {
  /* eslint-disable no-param-reassign */

  /**
   * @private
   * @property {Array<Object>} queue, for use with push/pop notifications
   */
  let queue = [];

  /**
   * Open notification message view
   *
   * @param {NotificationMessage} message
   * @returns {number} notification id (can be used for close method)
   */
  view.post = ({
    title,
    text,
    type = notificationMessageTypes.BASIC,
    duration = 5,
  }) => {
    const notificationExtended = {
      id: generateId(),
      title,
      text,
      type,
      closing: false,
      duration,
    };
    const notificationInQueue = view.state.notifications
      .find((notification) => notification.title === title && notification.text === text);
    if (notificationInQueue) {
      return notificationInQueue.id;
    }
    queue.push(notificationExtended);
    view.update({
      notifications: [...queue],
    });
    if (duration) {
      setTimeout(() => {
        view.close(notificationExtended.id);
      }, calculateDurationInSeconds(duration, queue));
    }
    return notificationExtended.id;
  };

  /**
   * Manually close notification message
   *
   * @param {string} id notification id
   */
  view.close = (id) => {
    queue = queue.map((notification) => {
      if (notification.id === id) {
        return {
          ...notification,
          closing: true,
        };
      }
      return notification;
    });
    view.update({
      notifications: [...queue],
    });
    setTimeout(() => {
      queue = queue.filter((notification) => notification.id !== id);
      view.update({
        notifications: [...queue],
      });
    }, closeDurationInSeconds * 1000);
  };

  /**
   * Replace an existing notification with new text, for persistent/progress style
   * notifications.
   *
   * If the given id isn't found the notification will be placed at the bottom of the
   * list.
   *
   * @function replace
   * @param {string} id notification id
   * @param {NotificationMessage} message
   * @return string id of the replaced or inserted notification
   */
  view.replace = (id, newNotif) => {
    const notifIndex = queue.findIndex((n) => n.id === id);
    if (notifIndex === -1) {
      return view.post(newNotif);
    }
    queue[notifIndex] = merge(queue[notifIndex], newNotif);
    view.update({
      notifications: [...queue],
    });
    return id;
  };

  return view;
}

const updateFn = (view) => {
  view.patch(notificationOverlay(view));
};

/**
 * Bootstraps a notification view.
 *
 * Set up by the page module layout helper, so it should not need to be called.
 *
 * @function init
 * @param {module:ui/view/view~UpdateFn} [updateFn=defaultUpdateFn]
 * @return {module:ui/view/view~ViewInitializer}
 */
export default (selector, initState) => {
  const state = merge(defaultState, initState);
  const self = bootstrapNotifications(
    baseView(selector, state, notificationOverlay, updateFn),
  );
  self.update(initState);
  storedNotificationView = self;
  return self;
};

export const getNotification = () => {
  if (!storedNotificationView) {
    throw new Error("NOTIFICATION VIEW :: tried to retrieve stored view before it was initialized!");
  }
  return storedNotificationView;
};
