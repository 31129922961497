/**
 * Editor fields for rich text sections.
 *
 * @module ui/component/modal/section-edit/rich-text
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import { div, label } from "ui/html";
import richTextEditorView from "ui/view/rich-text-edit-view";
import title from "ui/component/form-managed/field-title";
import toggle from "ui/component/form-managed/field-toggle";

let storedRTEView;

export const bindRTE = (RTEView, modalView) => {
  const placeholderEl = document.createElement("div");
  placeholderEl.id = "rte-section-view";
  modalView.element.querySelector(".rte-wrapper")
    .replaceChildren(placeholderEl);
  RTEView.rebind("#rte-section-view");
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initForm
 * @private
 */
const initRTE = (storedFormView, modalView) => {
  if (storedRTEView) {
    bindRTE(storedRTEView, modalView);
    return;
  }
  const placeholderEl = document.createElement("div");
  placeholderEl.id = "rte-section-view";
  modalView.element.querySelector(".body .rte-wrapper")
    .replaceChildren(placeholderEl);
  storedRTEView = richTextEditorView(
    "#rte-section-view",
    modalView,
    {
      content: storedFormView.state.section.text,
      pages: storedFormView.state.pages,
    },
    ({ html }) => {
      storedFormView.updateState({ section: { text: html } });
      storedRTEView.updateState({ content: html });
    },
  );
};

export const destroyRTE = () => {
  if (storedRTEView) storedRTEView = null;
};

/**
 * @function richTextFields
 * @private
 *
 * @param self
 * @return {module:ui/html~ChildEl[]}
 */
export default (self, modalView) => {
  const {
    section,
  } = self.state;
  const contents = self.bind([
    [title, { value: section.title }],
    [toggle.boxed, {
      toggled: section.searchData?.collapsible || false,
      name: "collapsible",
      label: "Collapsible",
      sel: ".inverse",
    }],
    label("Content"),
    div(".rte-wrapper", {
      hook: {
        insert: () => initRTE(self, modalView),
      },
    }),
  ]);

  return contents;
};
