/**
 * Defaults and constants related to content management states.
 *
 * @module ui/page/admin/content/state
 * @category Pages
 * @subcategory Admin - Content Management
 */
import { metadataTypes } from "model/metadata/constants";
import { sortOrders } from "ui/component/dynamic-table";
import { frozen } from "util/object";

/**
 * Stages the add content accordion can be in.
 * @enum addSteps
 * @property ADD
 * @property POSTER
 * @property BASIC
 * @property SCHEDULE
 * @property PLAYLIST
 * @property LABEL
 * @property COMPLETE
 */
export const addSteps = frozen({
  ADD: 0,
  POSTER: 1,
  BASIC: 2,
  SCHEDULE: 3,
  PLAYLIST: 4,
  LABEL: 5,
  COMPLETE: 6,
});

/**
 * Stages the content profile accordion can be in.
 *
 * @enum profileSteps
 * @property METADATA
 * @property BASIC
 * @property FILE_INFO
 * @property SCHEDULE
 * @property PLAYLIST
 * @property LABEL
 * @property FILE
 * @property POSTER
 * @property COMPLETE
 */
export const profileSteps = frozen({
  METADATA: 0,
  BASIC: 1,
  FILE_INFO: 2,
  SCHEDULE: 3,
  PLAYLIST: 4,
  LABEL: 5,
  FILE: 6,
  POSTER: 7,
  COMPLETE: 8,
});

/**
 * steps that are included under the metadata header in the profile page
 *
 * @const profileMetadataOpenSteps
 * @type Set
 */
export const profileMetadataOpenSteps = frozen(new Set([
  profileSteps.METADATA,
  profileSteps.BASIC,
  profileSteps.FILE_INFO,
  profileSteps.SCHEDULE,
]));

/**
 * steps that are included under the files header in the profile page
 *
 * @const profileMetadataOpenSteps
 * @type Set
 */
export const profileFileOpenSteps = frozen(new Set([
  profileSteps.POSTER,
  profileSteps.FILE,
]));

/**
 * metadata types that should display a file information section in the profile page
 *
 * @const profileMetadataOpenSteps
 * @type Set
 */
export const profileMetadataFileInfoTypes = frozen(new Set([
  metadataTypes.VIDEO,
  metadataTypes.DOCUMENT,
  metadataTypes.LIST,
]));

/**
 * Default state for the add-content page.
 * @const defaultAddState
 * @type object
 */
export const defaultAddState = {
  isFormChanged: false,
  files: [],
  descriptors: [],
  invalidSteps: [],
  labels: [],
  listItems: [],
  messages: [],
  metadata: [],
  newDocumentFile: null,
  searchParams: { terms: [] },
  selectedLabels: [],
  schedule: {
    startDate: null,
    endDate: null,
  },
  step: addSteps.ADD,
  user: {},
  validation: {
    valid: true,
    fields: {},
  },
};

/**
 * Default state for the content-profile page.
 * @const defaultAddState
 * @type object
 */
export const defaultProfileState = frozen({
  isFormChanged: false,
  files: [],
  descriptors: [],
  labels: [],
  listItems: [],
  invalidSteps: [],
  messages: [],
  metadata: [],
  newDocumentFile: null,
  searchParams: { terms: [] },
  selectedLabels: [],
  step: profileSteps.COMPLETE,
  user: {},
  userRights: new Set(),
  validation: {
    valid: true,
    fields: {},
  },
});

export const defaultSearchParams = frozen({
  id: null,
  title: null,
  description: null,
  labels: [],
  type: null,
  sortField: "",
  sortOrder: "",
  filter: null,
});

/**
 * @enum searchModes
 */
export const searchModes = frozen({
  NONE: null,
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
});

/**
 * @enum searchModes
 * @readonly
 * @property DEFAULT
 * @property VISIBILITY
 */
export const viewModes = frozen({
  DEFAULT: "DEFAULT",
  VISIBILITY: "VISIBILITY",
  HELP: "HELP",
});

export const defaultManageState = frozen({
  searchParams: { ...defaultSearchParams },
  config: {},
  content: [],
  messages: [],
  filteredContent: [],
  searchMode: searchModes.NONE,
  sortColumn: "created",
  sortOrder: sortOrders.DESC,
  viewMode: viewModes.DEFAULT,
  initialMetadataAccessMap: new Map(),
});

/**
 * @enum saveStates
 * @readonly
 * @property SUCCESS
 * @property FAILURE
 * @property NEED_SAVE
 * @property SAVING
 */
export const saveStates = frozen({
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  NEED_SAVE: "NEED_SAVE",
  SAVING: "SAVING",
});
