/**
 * Chart models.
 *
 * @module model/chart
 * @category Model
 * @subcategory Chart
 */
import log from "log";
import { monthDayYear } from "util/date";
import { sortDate } from "util/sort";
import { defaultChartSettings } from "./constants";

const defaultDatasetConfiguration = {
  backgroundColor: defaultChartSettings.backgroundColor,
  borderColor: defaultChartSettings.backgroundColor,
  barPercentage: 0.5,
  barThickness: 6,
  maxBarThickness: 8,
  minBarLength: 2,
};

/**
 * Converts page views analytics to chart js acceptable model
 *
 * @param {AnalyticsEntry[]} data
 * @param datasetConfiguration
 * @returns ChartDataset
 */
// eslint-disable-next-line import/prefer-default-export
export const analyticsPageViewsToDataset = (
  data,
  datasetConfiguration = defaultDatasetConfiguration,
) => {
  const startDateMap = new Map();
  data.forEach((entry) => {
    if (entry.startDate) {
      startDateMap.set(entry.startDate.getTime(), entry);
    }
  });
  const processedData = Array.from(startDateMap.values())
    .sort((a, b) => sortDate(new Date(a.startDate), new Date(b.startDate)));
  return {
    labels: processedData
      .map((analyticsEntry) => monthDayYear(analyticsEntry.startDate)),
    datasets: [
      {
        label: "PAGE VIEWS",
        data: processedData
          .map((analyticsEntry) => analyticsEntry.value),
        ...datasetConfiguration,
      },
    ],
    options: {
      scales: {
        x: {
          ticks: {
            minRotation: 45,
          },
        },
        y: {
          ticks: {
            precision: 0,
          },
        },
      },
    },
  };
};

/**
 * Converts page views by users analytics to chart js acceptable model
 *
 * @param {AnalyticsEntry[]} data
 * @param datasetConfiguration
 * @returns ChartDataset
 */
export const analyticsPageViewsByUserToDataset = (
  data,
  datasetConfiguration = defaultDatasetConfiguration,
) => {
  const epochValueMap = new Map();
  data.forEach((analyticsEntry) => {
    if (analyticsEntry.startDate) {
      const epoch = analyticsEntry.startDate.getTime();
      const existingNumberOfViewsOnThatDate = epochValueMap.get(epoch) || 0;
      epochValueMap.set(epoch, existingNumberOfViewsOnThatDate + analyticsEntry.value);
    }
  });
  const sortedEpochs = Array.from(epochValueMap.keys())
    .sort((a, b) => sortDate(new Date(a), new Date(b)));
  return {
    labels: sortedEpochs
      .map((epoch) => monthDayYear(new Date(epoch))),
    datasets: [
      {
        label: "PAGE VIEWS",
        data: sortedEpochs.map((epoch) => epochValueMap.get(epoch)),
        ...datasetConfiguration,
      },
    ],
    options: {
      scales: {
        x: {
          ticks: {
            minRotation: 45,
          },
        },
        y: {
          ticks: {
            precision: 0,
          },
        },
      },
    },
  };
};

/**
 * Converts content watch duration analytics to chart js acceptable model
 *
 * @param {AnalyticsContentDurationEntry[]} data
 * @param {number} videoLength in seconds
 * @param datasetConfiguration
 */
export const analyticsContentDurationToDataset = (
  data,
  videoLength,
  datasetConfiguration = defaultDatasetConfiguration
) => {
  const progressMap = new Map();
  for (let i = 10; i <= 100; i += 10) {
    progressMap.set(i, 0);
  }
  const videoLengthInMs = videoLength * 1000;
  data.forEach((item) => {
    try {
      const watchedPercentage = (item.elapsed / videoLengthInMs) * 100;
      const flooredDuration = Math.floor(watchedPercentage / 10) * 10;
      if (flooredDuration >= 10 && flooredDuration <= 100 && flooredDuration % 10 === 0) {
        progressMap.set(flooredDuration, progressMap.get(flooredDuration) + 1);
      }
    } catch (e) {
      log.error("Failed to calculate watch percentage for ", item);
    }
  });
  return {
    labels: Array.from(progressMap.keys()).map((percent) => `${percent}%`),
    datasets: [
      {
        label: "WATCH PROGRESS",
        data: Array.from(progressMap.values()).map((views) => views),
        ...datasetConfiguration,
      },
    ],
    options: {
      scales: {
        y: {
          ticks: {
            precision: 0,
          },
        },
      },
    },
  };
};
