import div from "ui/html/div";
import h3 from "ui/html/h3";
import span from "ui/html/span";
import badge from "ui/component/badge";
import indicator from "ui/component/indicator";
import spinner from "ui/component/spinner";

const checking = (label) => [
  span(label, ".label"),
  span([
    badge("Checking..."),
    spinner(),
  ], ".value"),
];

const states = {
  HEALTHY: 0,
  DEGRADED: 1,
  OFFLINE: 2,
};

const serviceEntry = (label, info) => {
  if (info === null) return checking(label);
  const { HEALTHY, DEGRADED, OFFLINE } = states;
  let state = HEALTHY;
  if (
    !info?.version
    || (info.health !== undefined && !info.health)) state = DEGRADED;
  if (!info) state = OFFLINE;
  let ind;
  let bdg;
  switch (state) {
    case HEALTHY:
      ind = indicator({ sel: ".ok" });
      bdg = info.version || "ok";
      break;
    case DEGRADED:
      ind = indicator({ sel: ".warning" });
      bdg = badge("degraded", ".warning");
      break;
    case OFFLINE:
    default:
      ind = indicator({ sel: ".error" });
      bdg = badge("offline", ".error");
      break;
  }
  return [
    span(label, ".label"),
    span([bdg, ind], ".value"),
  ];
};

export default function serverList(state) {
  return div(
    "#server.block",
    [
      h3("Services"),
      ...serviceEntry("Backend", state.serverInfo?.build),
      ...serviceEntry("Client", state.clientInfo),
      ...serviceEntry("Messaging", state.messageInfo),
      ...serviceEntry("Analytics", state.analyticsInfo),
      ...serviceEntry("Themes", state.themeInfo),
    ],
  );
}
