/**
 * Type definitions for authentication models.
 *
 * @module model/authentication
 * @category Model
 * @subcategory Authentication
 */
import {
  orNull,
  validString,
  required,
  validUUID,
} from "model/constraints";

/**
 * User login data transfer object. Used in {@link module:api/user.login}
 *
 * @typedef UserLoginDTO
 * @property {string} username
 * @property {string} password
 * @property {string|null} [networkUserId=null]
 */

/**
 * Produces a DTO for user login from an object.
 *
 * @function makeUserLoginDTO
 * @param {object} partial
 * @param {string} partial.username
 * @param {string} partial.password
 * @param {string} partial.[networkUserId]
 *
 * @return {UserLoginDTO}
 */
export const makeUserLoginDTO = (partial) => ({
  username: required(partial.username, "UserLoginDTO.username", validString),
  password: required(partial.password || partial.newPassword, "UserLoginDTO.password", validString),
  networkUserId: orNull(partial.networkUserId, "UserLoginDTO.networkUserId", validString),
});

/**
 * A user access & refresh token pair object.
 *
 * @typedef AuthenticationTokenPair
 * @property {string} accessToken an authentication token hash
 * @property {UUID} refreshToken a refresh token UUID
 * @property {number} validityPeriod how many seconds the accessToken is good for
 * @property {Date} time the last time the tokens were refreshed
 */

/**
 * Produces an authentication token pair from a response object.
 *
 * @property {object} response
 * @returns AuthenticationTokenPair
 */
export const responseToAuthenticationTokenPair = (response) => ({
  accessToken: response.accessToken,
  refreshToken: response.refreshToken,
  validityPeriod: parseInt(response.accessTokenValidityPeriodInSeconds, 10),
  time: new Date(),
});

export const refreshResponseToAuthenticationTokenPair = (response, refreshToken) => ({
  accessToken: response.accessToken,
  validityPeriod: parseInt(response.accessTokenValidityPeriodInSeconds, 10),
  time: new Date(),
  refreshToken,
});

/**
 * A token refresh request DTO.
 *
 * @typedef AccessTokenRefreshRequestDTO
 * @property {string} refreshToken
 */

/**
 * @function makeAccessTokenRefreshRequestDTO
 * @param {object} partial
 * @param {string} partial.refreshToken a valid refresh token
 */
export const makeAccessTokenRefreshRequestDTO = (partial) => ({
  refreshToken: validUUID(partial.refreshToken),
});
