/**
 * Utility functions and constants related to form validation.
 *
 * @module util/validation
 * @category Utility
 */
import { frozen, merge } from "util/object";

export const customValidities = frozen({
  TAKEN: "EM:TAKEN",
});

export const customValidityPropertiesMap = frozen(new Map([
  [customValidities.TAKEN, "taken"],
]));

export const bootstrapValidity = (elm, validity) => {
  const prop = customValidityPropertiesMap.get(elm.validationMessage);

  if (prop) {
    return merge(validity, { [prop]: true });
  }

  return validity;
};

/**
 * Checks whether a string is empty or non-existent.
 *
 * @function isEmptyString
 * @param {string} string
 * @return {boolean}
 */
export const isEmptyString = (string) => (
  string === null
  || string === undefined
  || string?.trim() === ""
);
