/**
 * A page for bulk content entry.
 *
 * Corresponds to `markup/admin/add-content-bulk.html`
 *
 * @module ui/page/admin/content/bulk
 * @category Pages
 * @subcategory Admin - Content
 */
import Papa from "papaparse";
import api from "api";
import log from "log";
import { externalURLs } from "api/constants";
import { metadataTypes } from "model/metadata/constants";
import {
  a,
  button,
  input,
  label,
  p,
  span,
  section,
} from "ui/html";
import view from "ui/view";
import getConfig from "config";
import form from "ui/component/form-managed";
import formManagedView from "ui/view/form-managed";
import icon from "ui/component/icon";
import listSelectModal from "ui/component/modal/list-select";
import actionBar from "ui/component/dashboard-action-bar";
import widget from "ui/component/dashboard-widget";
import placeholder from "ui/component/placeholder";
import { qs } from "util/dom";
import { merge } from "util/object";
import { tidyBackendError } from "util/error";
import adminAddContentBulkHelpModal from "ui/component/modal/help/add-content-bulk";
import adminFTPHelpModal from "ui/component/modal/help/ftp";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import dashboardLayout from "ui/page/layout/dashboard";
import { notificationMessageTypes } from "model/notification/constants";
import { fileTypes } from "model/file-descriptor/constants";
import contentRow from "./content-row";
import messages from "./messages";
import { displayModes, defaultState } from "./state";
import helpSection from "./help";
import {
  allContentValid,
  countStatuses,
  makeContentRecord,
  refreshContentRecords,
  saveStates,
} from "./content-record";
import { processCSV } from "./csv-utils";

let modalView, actionsView, bulkContentView, notificationView;
const savedDescriptors = new Map();

/**
 * Shows a confirmation modal instructing users to ensure they upload files before
 * populating the form. After they click the confirmation button the filename
 * cache is refreshed.
 *
 * @function showUploadFiles
 * @private
 */
const showUploadFiles = (formView, server) => new Promise((res) => {
  modalView.customAlert({
    body: [
      p("Please ensure you have uploaded all the poster and content files you intend to use before continuing."),
      a(
        [
          "Go to the File Management Utility",
          icon.solid("long-arrow-alt-right"),
        ],
        "#",
        "",
        {
          on: {
            click: async () => {
              if (!isHelpModalSeen(modalTypes.ADMIN_FTP)) {
                await modalView.async(adminFTPHelpModal(true, true));
              }
              window.open(externalURLs.fileManager(server), "_blank");
            },
          },
        },
      ),
    ],
    confirmLabel: "I have finished uploading files",
    onConfirm: async () => {
      const files = await api.media.files();
      formView.update({ files });
      res();
    },
  });
});

/**
 * Deals with backend errors during saving.
 * @function handleBackendError
 * @private
 */
const handleBackendError = (e) => {
  const errors = [];
  switch (e.statusCode) {
    case 403:
      errors.push(messages.metadataPermissionError);
      break;
    case undefined:
      errors.push(e.message);
      break;
    default:
      errors.push(tidyBackendError(e.body).join("\r\n"));
  }
  return errors;
};

/**
 * Builds the message displayed while entries are processing.
 * @function processingMessages
 * @private
 */
const processingMessages = (contents) => {
  const done = contents.length - countStatuses(contents, saveStates.SAVING);
  const remaining = contents.length - done;
  return [
    remaining > 0
      ? {
        title: "In progress",
        text: `Processing: ${done} out of ${contents.length} entries created.
          ${countStatuses(contents, saveStates.SUCCESS)} successful.
          ${countStatuses(contents, saveStates.FAILURE)} failed.`,
        type: notificationMessageTypes.SUCCESS,
        duration: 3,
      }
      : {
        title: "Done",
        text: `Upload complete. ${contents.length} entries processed.
          ${countStatuses(contents, saveStates.SUCCESS)} successful.
          ${countStatuses(contents, saveStates.FAILURE)} failed.`,
        type: notificationMessageTypes.SUCCESS,
        duration: 3,
      },
  ];
};

const createDescriptors = (entries) => {
  const descriptors = [];
  entries.forEach((entry) => {
    descriptors.push(
      {
        name: entry.posterFileName,
        type: fileTypes.IMAGE,
      },
      {
        name: entry.contentFileName,
        type: entry.type === metadataTypes.VIDEO ? fileTypes.VIDEO : fileTypes.DOCUMENT,
      },
    );
  });
  return descriptors;
};

const filterAndSaveDescriptors = async (descriptors) => {
  const filteredDescriptors = descriptors.filter(
    (descriptor) => !savedDescriptors.has(descriptor.name),
  );
  if (!filteredDescriptors.length) return;
  const saved = await api.file.batchCreate(filteredDescriptors);
  saved.entries.forEach((entry) => savedDescriptors.set(entry.name, entry));
};

const updateEntriesWithFileIds = (entries) => entries.map((entry) => ({
  ...entry,
  posterFileId: savedDescriptors.get(entry.posterFileName)?.id,
  ...(entry.type === metadataTypes.VIDEO
    ? { videoFileId: savedDescriptors.get(entry.contentFileName)?.id }
    : { documentFileId: savedDescriptors.get(entry.contentFileName)?.id }),
  categories: entry.labels,
}));

const batchCreateVideoEntries = async (entries) => {
  const videoEntries = entries.filter((entry) => entry.type === metadataTypes.VIDEO);
  if (!videoEntries.length) return [];
  const response = await api.metadata.batchCreateVideo(updateEntriesWithFileIds(videoEntries));
  const videos = response.entries;
  const { errors } = response;
  videos.forEach((video, index) => {
    if (video) {
      videoEntries[index].saveStatus = saveStates.SUCCESS;
      videoEntries[index].id = video.id;
    } else if (errors[index]) {
      videoEntries[index].saveStatus = saveStates.FAILURE;
      videoEntries[index].errors = handleBackendError(errors[index]);
    }
  });
  return response;
};

const batchCreateDocumentEntries = async (entries) => {
  const documentEntries = entries.filter((entry) => entry.type === metadataTypes.DOCUMENT);
  if (!documentEntries.length) return [];
  const response = await api.metadata.batchCreateDocument(
    updateEntriesWithFileIds(documentEntries),
  );
  const documents = response.entries;
  const { errors } = response;
  documents.forEach((document, index) => {
    if (document) {
      documentEntries[index].saveStatus = saveStates.SUCCESS;
      documentEntries[index].id = document.id;
    } else if (errors[index]) {
      documentEntries[index].saveStatus = saveStates.FAILURE;
      documentEntries[index].errors = handleBackendError(errors[index]);
    }
  });
  return response;
};

/**
 * Saves content rows.
 * @function saveContentEntries
 * @private
 */
const saveContentEntries = async (entries = []) => {
  const descriptors = createDescriptors(entries);
  await filterAndSaveDescriptors(descriptors);
  return Promise.all([
    batchCreateVideoEntries(entries),
    batchCreateDocumentEntries(entries),
  ]);
};

/// id of the last success notification
let lastSuccess;

/**
 * Save all completed rows in the form.
 * @function doSave
 * @private
 */
const doSave = async () => {
  const page = bulkContentView;
  const validation = page.validate(true);
  if (!validation.valid) {
    page.update({
      validation,
      messages: [messages.invalid],
    });
    notificationView.post(messages.invalid);
    return;
  }

  const contents = page.state.contents.map((content) => merge(content, {
    errors: [],
    saveStatus: content.saveStatus === saveStates.SUCCESS
      ? saveStates.SUCCESS
      : saveStates.SAVING,
  }));
  page.update({ contents: contents.map(makeContentRecord), messages: [] });

  const unsavedContents = contents
    .filter((content) => content.saveStatus !== saveStates.SUCCESS);
  try {
    await saveContentEntries(unsavedContents);
    const procMessages = processingMessages(contents);
    page.update({
      contents: contents.map(makeContentRecord),
      messages: procMessages,
    });
    lastSuccess = notificationView.replace(lastSuccess, procMessages[0]);
  } catch (e) {
    notificationView.post(messages.fatalError);
    log.error("Failed batching content: ", e);
  }
};

/**
 * Makes labels for the header row of the bulk upload form.
 * @function makeColumnLabel
 * @private
 */
const makeColumnLabel = (text, required = false) => label(
  `${text}${required ? "*" : ""}`,
);

/**
 * Initiate CSV parsing.
 * @function handleCSV
 * @private
 */
const handleCSV = (page) => new Promise((res, rej) => {
  const file = qs(`input[type="file"][name="csv_upload"]`).files[0];
  Papa.parse(file, {
    complete: (out) => processCSV(
      out, modalView, page.state.contents, page.state.files,
    ).then(res),
    error: (err) => rej(err),
  });
});

/**
 * Remove rows that succeeded after a save attempt.
 * @function removeSuccessRows
 * @private
 */
const removeSuccessRows = async () => {
  if (!await modalView.confirm(`Are you sure you want to delete uploaded rows?`)) {
    return;
  }
  bulkContentView.update({
    contents: bulkContentView.state.contents.filter(
      (item) => item.saveStatus !== saveStates.SUCCESS,
    ),
    messages: [],
  });
};

/**
 * Remove rows that failed to upload after a save attempt.
 * @function removeFailedRows
 * @private
 */
const removeFailedRows = async () => {
  if (!await modalView.confirm(`Are you sure you want to delete failed rows?`)) {
    return;
  }
  bulkContentView.update({
    contents: bulkContentView.state.contents.filter(
      (item) => item.saveStatus !== saveStates.FAILURE,
    ),
    messages: [],
  });
};

/**
 * Handles adding successful items to a playlist. The user is directed to a playlist
 * creation page with the successful rows appended to the playlist contents.
 * @function addToPlaylist
 * @private
 */
const addToPlaylist = async () => {
  const createNewPlaylist = await modalView.confirm(
    "Select option",
    null,
    "Create new playlist",
    "Add to existing playlist",
  );
  const selectedValidMetadataIdsString = bulkContentView.state.contents
    .filter((item) => item.id && item.saveStatus === saveStates.SUCCESS)
    .map((item) => item.id)
    .join(",");
  if (createNewPlaylist) {
    const queryParams = new URLSearchParams();
    queryParams.append("metadataIds", selectedValidMetadataIdsString);
    window.location.assign(`/admin/add-content?${queryParams.toString()}`);
  } else {
    const playlist = await modalView.async(
      listSelectModal({
        entries: bulkContentView.state.playlists.map((item) => ({
          ...item,
          label: item.title,
        })),
      }, modalView),
    );
    if (playlist?.id) {
      const queryParams = new URLSearchParams();
      queryParams.append("type", "LIST_METADATA");
      queryParams.append("id", playlist.id);
      queryParams.append("metadataIds", selectedValidMetadataIdsString);
      window.open(`/admin/content-profile?${queryParams.toString()}`, "_blank");
    }
  }
};

/**
 * Builds the main form area. If `displayMode` is help shows the help section.
 * @function showForm
 * @private
 */
const showForm = (theView) => {
  if (theView.state.displayMode === displayModes.HELP) {
    return helpSection({
      formView: bulkContentView,
      server: theView.state.config.server,
      showUploadFiles,
    });
  }

  const headers = [
    makeColumnLabel(`Type`, true),
    makeColumnLabel(`Title`, true),
    makeColumnLabel(`Description`, false),
    makeColumnLabel(`Labels`, false),
    makeColumnLabel(`Poster File`, true),
    makeColumnLabel(`Content File`, true),
    span(icon.solid("clock"), ".schedule-column.icon-label"),
    span(icon.solid("check-circle"), ".status-column.icon-label"),
    span(icon.solid("trash"), ".remove-column.icon-label"),
  ];

  return form(
    "#bulk-content-form.bulk",
    [
      ...headers,
      ...theView.state.contents.map(contentRow(theView, modalView)).flat(),
      button(
        [icon.solid("plus-circle"), "Add Content"],
        () => theView.update({
          contents: [
            ...theView.state.contents,
            makeContentRecord(),
          ],
        }),
        "#add-content.stand-in",
      ),
      input.file(
        false,
        `csv_upload`,
        "text/csv",
        {
          on: {
            change: async () => {
              const contents = await handleCSV(theView);
              theView.update({
                contents: [...theView.state.contents, ...contents],
                messages: [],
              });
              theView.update({
                validation: theView.validate(false),
              });
            },
          },
        },
      ),
    ],
  );
};

/**
 * Builds contents of the action bar.
 * @function showActions
 * @private
 */
const showActions = ({ state }) => {
  let saveButtonText = "Save";
  let saveButtonDisabled = false;

  if (
    countStatuses(state.contents, saveStates.FAILURE) > 0
    && countStatuses(state.contents, saveStates.PENDING) === 0
  ) saveButtonText = "Retry";
  if (
    !allContentValid(state.contents)
    || countStatuses(state.contents, saveStates.SUCCESS) === state.contents.length
  ) saveButtonDisabled = true;
  const showAddToList = (countStatuses(state.contents, saveStates.SUCCESS) > 0);
  const showRemoveFailed = (countStatuses(state.contents, saveStates.FAILURE) > 0);
  const showRemoveSuccess = (countStatuses(state.contents, saveStates.SUCCESS) > 0);

  return section("#actions", [
    span([
      button(
        [icon.solid("file-csv"), "Import"],
        () => qs("input[name=csv_upload]").click(),
        "#import",
        "button",
        "Import CSV",
        state.displayMode === displayModes.HELP,
      ),
      button(
        [icon.solid("file-download"), "Template"],
        () => window.open("/media/content_bulk_upload_template.csv", "_blank"),
        ".secondary",
        "button",
        "Download Template",
      ),
      state.displayMode === displayModes.HELP
        ? button(
          [icon.solid("table"), "Upload"],
          () => bulkContentView.update({ displayMode: displayModes.BULK_UPLOAD }),
          ".alternate",
          "button",
          "Show Upload",
        )
        : button(
          [icon.solid("question-circle"), "Help"],
          () => bulkContentView.update({ displayMode: displayModes.HELP }),
          ".alternate",
          "button",
          "Show Help",
        ),
    ]),
    span([
      showRemoveSuccess ? button(
        [icon.solid("check-circle"), "remove successful"],
        () => removeSuccessRows(),
        ".ok",
        "button",
        "Remove Successful Rows",
      ) : "",
      showRemoveFailed ? button(
        [icon.solid("times-circle"), "remove failed"],
        () => removeFailedRows(),
        ".danger",
        "button",
        "Remove Failed Rows",
      ) : "",
      showAddToList ? button(
        [icon.solid("list"), "add to list"],
        () => addToPlaylist(),
        ".secondary",
        "button",
        "Add to Playlist",
      ) : "",
      button(
        [icon("save"), saveButtonText],
        () => doSave(),
        "",
        "button",
        "Save",
        saveButtonDisabled,
      ),
      button(
        [icon.solid("folder-upload"), "Files"],
        () => showUploadFiles(bulkContentView, state.config.server),
        ".warn",
      ),
    ]),
  ]);
};

/**
 * An updateFn callback for the form view.
 *
 * This will get called any time the form changes (defined as when a form element
 * goes out of focus with new content), or when `page.update()` is called.
 *
 * Before this hook is called the page runs validation and exposes updated validation
 * state on `page.validation`.
 *
 * Here we take values from the form exposed in `page.values` and update the content
 * model in page.state, then we patch the form view with the updated model and validation
 * status.
 *
 * @function doUpdate
 * @private
 */
const doUpdate = (self) => {
  self.updateState({
    contents: self.state.contents.map(
      refreshContentRecords(self.values, self.state.validation.fields, self.state.files),
    ).filter((item) => item.markedForDeletion !== true),
  });
  self.render();
  if (actionsView) actionsView.update(self.state);
};

/**
 * The page initializer for content bulk uploads.
 *
 * @function bulkAddContent
 * @param {module:ui/html~Selector} selector the root element for the form
 * @param {object} initialState (deprecated)
 */
export default async function bulkAddContent(selector) {
  const title = "Mass Creation — Content";
  const { loading, modal, header, notification } = dashboardLayout(selector, [
    actionBar(placeholder("Loading...", "#actions")),
    widget(form("#add-content-bulk-form"), "", "#bulk-upload-widget"),
  ], title, true);
  modalView = modal;
  notificationView = notification;

  loading.show();
  const [user, config, labels, playlists] = await Promise.all([
    api.user.getMe(),
    getConfig(),
    api.metadata.listCategories(),
    api.metadata.listPlaylists(),
  ]);

  const state = merge(defaultState, {
    user,
    config,
    labels,
    playlists,
  });
  header.update(state);
  bulkContentView = formManagedView(showForm, doUpdate)("#add-content-bulk-form", state);
  actionsView = view.create(showActions)("#actions", state);
  loading.hide();
  if (!isHelpModalSeen(modalTypes.ADMIN_ADD_CONTENT_BULK)) {
    await modal.async(adminAddContentBulkHelpModal(false, true));
    markModalAsSeen(modalTypes.ADMIN_ADD_CONTENT_BULK);
  }
  await showUploadFiles(bulkContentView, config.server);
}
