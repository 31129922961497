/**
 * Course models.
 *
 * @module model/course
 * @category Model
 * @subcategory Course
 */
import {
  orNull,
  required,
  validArray,
  validDate,
  validObject,
  validString,
  validUUID,
} from "model/constraints";
import { datesEquivalent, MAX_DATE, MIN_DATE } from "util/date";
import { ConstraintError } from "model/error";
import { courseEntryType } from "./constants";
import { makeSolutionDTO, responseToSolution } from "./evaluation";

/**
 * @typedef Answer
 * @property {string} value
 * @property {boolean} correct
 */

/**
 * Converts data to answer object
 *
 * @param data
 * @param {string} data.value
 * @param {string} data.correct
 * @returns Answer
 */
const responseToAnswer = (data) => ({
  value: data.value,
  correct: data.correct,
});

/**
 * @typedef Question
 * @property {string} id
 * @property {string} title
 * @property {string} text
 * @property {number} weight
 * @property {boolean} randomizeAnswers
 * @property {questionType} questionType
 * @property {Answer[]} answers
 */

/**
 * Converts data to question object.
 *
 * @param data
 * @param {string} data.id
 * @param {string} data.title
 * @param {string} data.text
 * @param {number} data.weight
 * @param {boolean} data.randomizeAnswers
 * @param {string} data.questionType
 * @param {Object[]} data.answers
 * @returns Question
 */
const responseToQuestion = (data) => ({
  id: data.id,
  title: data.title,
  text: data.text,
  weight: data.weight,
  randomizeAnswers: data.randomizeAnswers,
  questionType: data.questionType,
  answers: data.answers?.length ? data.answers.map(responseToAnswer) : [],
});

/**
 * @typedef Assessment
 * @param {string} id
 * @param {string} title
 * @param {string} description
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {number} maxPoints
 * @param {boolean} randomizeQuestions
 * @param {Question[]} questions
 * @param {boolean} mandatory
 */
export const makeAssessment = (data) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  startDate: data.startDate && !datesEquivalent(data.startDate, MIN_DATE)
    ? new Date(data.startDate)
    : null,
  endDate: data.endDate && !datesEquivalent(data.endDate, MAX_DATE)
    ? new Date(data.endDate)
    : null,
  randomizeQuestions: data.randomizeQuestions,
  maxPoints: data.maxPoints,
  questions: data.questions?.length ? data.questions.map(responseToQuestion) : [],
  mandatory: data.mandatory,
  durationInMin: data.durationInMin,
  courseId: data?.courseId,
  moduleId: data?.moduleId,
});

/**
 * @typedef AssessmentWithEvaluation
 * @param {string} id
 * @param {string} title
 * @param {string} description
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {number} maxPoints
 * @param {boolean} randomizeQuestions
 * @param {Question[]} questions
 * @param {boolean} mandatory
 * @param {Evaluation} evaluation
 */

/**
 * Converts data to assessment object
 *
 * @param data
 * @param {string} data.id
 * @param {string} data.title
 * @param {string} data.description
 * @param {string} data.startDateTime
 * @param {string} data.endDateTime
 * @param {string} data.description
 * @param {number} data.maxPoints
 * @param {boolean} data.randomizeQuestions
 * @param {Object[]} data.questions
 * @param {boolean} data.mandatory
 * @param {number} data.durationInMin
 * @param {string} data.courseId (added manually)
 * @param {string} data.moduleId (added manually)
 * @returns Assessment
 */
export const responseToAssessment = (data) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  startDate: data.startDateTime && !datesEquivalent(data.startDateTime, MIN_DATE)
    ? new Date(data.startDateTime)
    : null,
  endDate: data.endDateTime && !datesEquivalent(data.endDateTime, MAX_DATE)
    ? new Date(data.endDateTime)
    : null,
  randomizeQuestions: data.randomizeQuestions,
  maxPoints: data.maxPoints,
  questions: data.questions?.length ? data.questions.map(responseToQuestion) : [],
  mandatory: data.mandatory,
  durationInMin: data.durationInMin,
  courseId: data?.courseId,
  moduleId: data?.moduleId,
});

/**
 * A course object.
 *
 * @typedef Course
 * @property {UUID} id UUID
 * @property {string} title
 * @property {string} description
 * @property {CourseModule[]} modules
 * @property {UUID[]} moduleIds
 * @property {GradingScheme} gradingScheme
 * @property {UUID} gradingSchemeId
 */

/**
 * Makes a course module from partial data, providing defaults.
 *
 * @function makeCourse
 * @param {object} partial
 * @return {Course}
 */
export const makeCourse = (partial = {}) => ({
  id: partial.id || null,
  title: partial.title || "",
  description: partial.description || "",
  modules: partial.modules || [],
  moduleIds: partial.moduleIds || [],
  createdAt: partial.createdAt ? new Date(partial.createdAt) : null,
  gradingScheme: partial.gradingScheme ? partial.gradingScheme : null,
  gradingSchemeId: partial.gradingSchemeId || null,
});

/**
 * A module object.
 *
 * @typedef CourseModule
 * @property {string} id UUID
 * @property {string} title
 * @property {string} description
 * @property {Lecture[]} lectures
 * @property {UUID[]} lectureIds
 * @property {Assessment[]} assessments
 * @property {UUID[]} assessmentIds
 * @property {Date} startDate
 * @property {Date} endDate
 */

/**
 * Creates a course module from partial data with defaults for missing properties.
 *
 * @function makeCourseModule
 * @param {object} partial
 * @return {CourseModule}
 */
export const makeCourseModule = (partial) => ({
  id: partial.id || null,
  courseId: partial.courseId || null,
  title: partial.title || "",
  description: partial.description || "",
  lectures: partial.lectures || [],
  lectureIds: partial.lectureIds || [],
  assessments: partial.assessments || [],
  assessmentIds: partial.assessmentIds || [],
  startDate: partial.startDateTime && !datesEquivalent(partial.startDateTime, MIN_DATE)
    ? new Date(partial.startDateTime)
    : null,
  endDate: partial.endDateTime && !datesEquivalent(partial.endDateTime, MAX_DATE)
    ? new Date(partial.endDateTime)
    : null,

});

/**
 * @typedef AssessmentDTO
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {number} maxPoints
 * @property {boolean} randomizeQuestions
 * @property {object[]} questions
 * @property {boolean} mandatory
 * @property {number} durationInMin
 * @property {Date} startDateTime
 * @property {Date} endDateTime
 */

/**
 * Validates and sanitizes an assessment for use in an api call.
 *
 * @param {Assessment | {}} partial
 * @returns {AssessmentDTO}
 */
export const makeAssessmentDTO = (partial) => ({
  id: partial.id,
  title: required(partial.title, "AssessmentDTO.title", validString),
  description: partial.description,
  maxPoints: partial.maxPoints,
  randomizeQuestions: partial.randomizeQuestions,
  mandatory: partial.mandatory,
  questions: partial.questions,
  startDateTime: orNull(partial.startDate, `AssessmentDTO.startDate`, validDate)
    // we need add some time to pass future date validation on BE
    || new Date(new Date().getTime() + 5000),
  endDateTime: orNull(partial.endDate, `AssessmentDTO.endDate`, validDate)
    || new Date(MAX_DATE),
  durationInMin: partial.durationInMin,
});

/**
 * Validates and sanitizes an assessment for use in an api call.
 *
 * @param {Assessment | {}} partial
 * @returns {Partial<AssessmentDTO>}
 */
export const makeAbbreviatedAssessmentDTO = (partial) => ({
  id: partial.id,
  description: partial.description,
  startDateTime: orNull(partial.startDate, `AssessmentDTO.startDate`, validDate)
    || new Date(MIN_DATE),
  endDateTime: orNull(partial.endDate, `AssessmentDTO.endDate`, validDate)
    || new Date(MAX_DATE),
  title: partial.title,
});

/**
 * Converts data to full course module object.
 *
 * @function responseToCourseModule
 * @param data
 * @param {string} data.id
 * @param {string} data.title
 * @param {string} data.description
 * @param {string[]} data.lectures
 * @param {string[]} data.assessments
 * @returns CourseModule
 */
export const responseToCourseModule = (data) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  lectures: data.lectures || [],
  assessments: data.assessments || [],
  startDate: data.startDateTime && !datesEquivalent(data.startDateTime, MIN_DATE)
    ? new Date(data.startDateTime)
    : null,
  endDate: data.endDateTime && !datesEquivalent(data.endDateTime, MAX_DATE)
    ? new Date(data.endDateTime)
    : null,
});

/**
 * Course module response mapper without shenanigans.
 */
export const responseToCourseModuleFlat = (data) => ({
  id: data.id,
  courseId: data.courseId,
  title: data.title,
  description: data.description,
  lectures: [],
  lectureIds: data.lectures || [],
  assessments: [],
  assessmentIds: data.assessments || [],
  startDate: data.startDateTime && !datesEquivalent(data.startDateTime, MIN_DATE)
    ? new Date(data.startDateTime)
    : null,
  endDate: data.endDateTime && !datesEquivalent(data.endDateTime, MAX_DATE)
    ? new Date(data.endDateTime)
    : null,
});

/**
 * Converts course response to course object
 *
 * Note that modules and gradingSchemes should have been processed separately, because
 * they come from different endpoints and are not part of the CourseDTO. They can be
 * provided here in which case they're uncritically passed through to the Course object,
 * or attached after the fact.
 *
 * @param data
 * @param {UUID} data.id
 * @param {string} data.title
 * @param {string} data.description
 * @param {UUID[]} data.moduleIds
 * @param {CourseModule[]} data.modules
 * @param {GradingScheme} data.gradingScheme
 * @param {UUID} gradingSchemeId
 * @param {Date} createdDate
 * @returns Course
 */
export const responseToCourse = (data) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  moduleIds: data.moduleItemDtos || [],
  modules: data.modules || [],
  createdAt: data.createdDate ? new Date(data.createdDate) : null,
  gradingScheme: data.gradingScheme ? data.gradingScheme : null,
  gradingSchemeId: data.gradingDto || null,
});

/**
 * A course module DTO object.
 *
 * @typedef CourseModule
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {object[]} lectures
 * @property {object[]} assessments
 */

/**
 * Handle lecture items whether they're strings or lecture objects.
 */
const makeModuleLectureItem = (item) => {
  if (typeof item === "string") return validUUID(item, "ModuleLectureCollection.itemId", item);
  if (typeof item === "object" && item?.id) {
    return validUUID(
      item.id,
      "ModuleLectureCollection.itemId",
      item,
    );
  }
  throw new ConstraintError("ModuleLectureCollection.itemId", "expected item", item);
};

/**
 * Handle assessment items whether they're strings or assessment objects.
 */
const makeModuleQuizItem = (item) => {
  if (typeof item === "string") return validUUID(item, "ModuleQuizCollection.itemId", item);
  if (typeof item === "object" && item?.id) {
    return validUUID(
      item.id,
      "ModuleQuizCollection.itemId",
      item,
    );
  }
  throw new ConstraintError("ModuleQuizCollection.itemId", "expected item", item);
};

/**
 * Make module DTO for create/update module
 *
 * @param partial
 * @param {string} partial.id
 * @param {string} partial.title
 * @param {string} partial.description
 * @param {Lecture[]} partial.lectures
 * @param {Assessment[]} partial.assessments
 * @returns CourseModule
 */
export const makeModuleDTO = (partial) => ({
  id: partial.id || null,
  title: required(partial.title, "ModuleDTO.title", validString),
  description: partial.description,
  lectures: partial.lectures?.map(makeModuleLectureItem) || [],
  assessments: partial?.assessments?.map(makeModuleQuizItem) || [],
  startDateTime: orNull(partial.startDate, `ModuleDTO.startDate`, validDate)
    || new Date(MIN_DATE),
  endDateTime: orNull(partial.endDate, `ModuleDTO.endDate`, validDate)
    || new Date(MAX_DATE),
});

/**
 * A course DTO object.
 *
 * @typedef CourseDTO
 * @property {string} id
 * @property {string} title
 * @property {string} description
 * @property {object[]} moduleItemDtos
 * @property {object} gradingDto
 */

/**
 * Make course DTO for create/update course
 *
 * @param partial
 * @param {string} partial.id
 * @param {string} partial.title
 * @param {string} partial.description
 * @param {CourseModule[]} partial.modules
 * @param {GradingScheme} partial.gradingScheme
 * @returns CourseDTO
 */
export const makeCourseDTO = (partial) => ({
  id: orNull(partial.id, "CourseDTO.id", validUUID),
  title: required(partial.title, "CourseDTO.title", validString),
  description: partial.description,
  moduleItemDtos: partial.modules.map((module) => module.id).filter((id) => !!id),
  gradingDto: validUUID(partial.gradingScheme?.id, "CourseDTO.gradingScheme"),
});

/**
 * @typedef Solution
 * @property {string} id
 * @property {Question} question
 * @property {string} value
 * @property {Score} score
 */

/**
 * @typedef Score
 * @property {string} id
 * @property {number} points
 * @property {string} description
 */

/**
 * @typedef Evaluation
 * @property {string} id
 * @property {evaluationStatus} status
 * @property {Assessment} assessment
 * @property {User} student
 * @property {Solution[]} solutions
 * @property {Score} score
 */

/**
 * @typedef EvaluationTime
 * @property {number} timeLeftInSeconds
 * @property {boolean} over
 */

/**
 * Converts abbreviated evaluation time response to abbreviated evaluation time object
 *
 * @param response
 * @param {number} response.timeLeftInSeconds
 * @param {boolean} response.over
 * @returns EvaluationTime
 */
export const responseToEvaluationTime = (response) => ({
  timeLeftInSeconds: response.timeLeftInSeconds,
  over: response.over,
});

/**
 * @typedef AbbreviatedSolution
 * @property {string} id
 * @property {string} questionId
 * @property {string} value
 * @property {Score} score
 */

/**
 * @typedef AbbreviatedEvaluation
 * @property {string} id
 * @property {evaluationStatus} status
 * @property {string} assessmentId
 * @property {string} courseId
 * @property {string} studentId
 * @property {Solution[]} solutions
 * @property {Score} score
 */

/**
 * Converts abbreviated evaluation response to abbreviated evaluation object
 *
 * @deprecated see model/course/evaluation
 * @param response
 * @param {string} response.id
 * @param {string} response.assessmentId
 * @param {string} response.studentId
 * @param {Object} response.score
 * @param {string} response.status
 * @param {string} response.courseId
 * @param {string} response.studentId
 * @param {Object[]} response.solutions
 * @returns AbbreviatedEvaluation
 */
export const responseToAbbreviatedEvaluation = (response) => ({
  id: response.id,
  studentId: response.studentId,
  assessmentId: response.assessmentId,
  solutions: response.solutions?.map(responseToSolution) || [],
  score: response.score,
  status: response.status,
  courseId: response.courseId,
});

/**
 * Validates and sanitizes an evaluation for use in an api call.
 *
 * @function makeEvaluationDTO
 * @deprecated see model/course/evaluation
 * @param {Evaluation} partial
 * @param {Assessment} partial.assessment
 * @param {User} partial.student
 * @param {Solution[]} partial.solutions
 * @param {string} partial.feedback
 * @returns {Partial<AbbreviatedEvaluation>}
 */
export const makeEvaluationDTO = (partial) => ({
  assessmentId: required(partial.assessment?.id, "EvaluationDTO.assessment.id", validString),
  studentId: required(partial.student?.id, "EvaluationDTO.student.id", validString),
  solutions: validArray(partial.solutions || [], "EvaluationDTO.solutions", validObject)
    .map(makeSolutionDTO),
  feedback: partial.feedback || "",
  submitted: partial.submitted || false,
});

/**
 * @typedef CourseAttendance
 * @property {string} id
 * @property {Course} course
 * @property {User} student
 * @property {Date} creationDate
 * @property {Date} expirationDate
 * @property {Evaluation[]} evaluations
 * @property {AbbreviatedGrade} grade
 */

/**
 * @typedef AbbreviatedGrade
 * @property {string} id
 * @property {number} points
 */

/**
 * @typedef AbbreviatedCourseAttendance
 * @property {string} id
 * @property {string} courseId
 * @property {string} studentId
 * @property {Date} creationDateTime
 * @property {Date} expirationDateTime
 * @property {string[]} evaluationIds
 * @property {AbbreviatedGrade} grade
 */

export const makeAttendance = (partial) => ({
  id: partial.id || null,
  courseId: partial.courseId || undefined,
  studentId: partial.studentId || undefined,
  creationDateTime: partial.creationDateTime
    ? new Date(partial.creationDateTime)
    : null,
  expirationDateTime: partial.expirationDateTime
    ? new Date(partial.expirationDateTime)
    : null,
  evaluationIds: partial.evaluationIds || [],
  grade: partial.grade || null,
});

/**
 * Converts response to abbreviated course attendance
 *
 * @param response
 * @param {string} response.id
 * @param {string} response.courseId
 * @param {string} response.studentId
 * @param {string} response.creationDateTime
 * @param {string} response.expirationDateTime
 * @param {string[]} response.assessmentIds
 * @param {AbbreviatedGrade} response.grade
 * @returns AbbreviatedCourseAttendance
 */
export const responseToAbbreviatedCourseAttendance = (response) => ({
  id: response.id,
  courseId: response.courseId,
  studentId: response.studentId,
  creationDateTime: response.creationDateTime ? new Date(response.creationDateTime) : null,
  expirationDateTime: response.expirationDateTime ? new Date(response.expirationDateTime) : null,
  evaluationIds: response.assessmentIds || [],
  grade: response.grade || {},
});

/**
 * Single lecture modules are denoted by a special string in the module's title.
 */
export const isSingleLecture = (module) => (
  module.title === courseEntryType.SINGLE_LECTURE
);

/**
 * True if the module has no lectures, or if the module has one lecture with no content.
 */
export const isEmptyModule = (lectures) => (
  lectures?.length === 0
) || (
  lectures.length === 1
  && !lectures[0].videoId
  && !lectures[0].additionalMaterialIds?.length
  && !lectures[0].slidesId
  && !lectures[0].posterId
);
