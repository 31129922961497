/**
 * Defaults and constants related to assessments management states.
 *
 * @module ui/page/admin/assessment/state
 * @category Pages
 * @subcategory Admin - Assessment
 */
import { frozen } from "util/object";
import { sortOrders } from "ui/component/dynamic-table";
import { notificationMessageTypes } from "model/notification/constants";
import hashmap from "util/hash-map";

const { FAIL, SUCCESS } = notificationMessageTypes;

export const displayModes = frozen({
  ASSESSMENT_PROFILE: "ASSESSMENT_PROFILE",
  HELP: "HELP",
});

export const staticMessages = frozen({
  success: {
    title: "Saved!",
    type: SUCCESS,
  },
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 3,
  },
  startDate: { title: "Error", text: "Start date must be in the future", type: FAIL },
  questionsLength: { title: "Error", text: "Verify that there are more than 1 question", type: FAIL },
  maxPoints: { title: "Error", text: "Maximum points are less than questions value", type: FAIL },
  selectCourseModule: { title: "Error", text: "Please select course module", type: FAIL },
  accessDenied: { title: "Access Denied", text: "You do not have permission to create a page.", type: FAIL },
  backendErrors: { title: "Error", text: "A server error occurred. Please report this to the operators.", type: FAIL },
});

export const defaultSearchParams = frozen({
  title: null,
  startDate: null,
  endDate: null,
  gradingScheme: null,
});

/**
 * @enum searchModes
 */
export const searchModes = frozen({
  NONE: null,
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
});

export const defaultManageState = frozen({
  searchParams: defaultSearchParams,
  assessments: hashmap(),
  modules: hashmap(),
  courses: hashmap(),
  filteredAssessments: hashmap(),
  gradingSchemes: hashmap(),
  loading: 0,
  searchMode: searchModes.NONE,
  sortColumn: "title",
  sortOrder: sortOrders.ASC,
  hasModules: false,
});

/**
 * An empty assessment
 *
 * @type {Partial<Assessment>}
 */
export const defaultAssessment = {
  title: "",
  startDate: null,
  endDate: null,
  gradingScheme: "",
  questions: [],
};

export const defaultProfileState = {
  assessment: defaultAssessment,
  displayMode: displayModes.ASSESSMENT_PROFILE,
  selectedCourse: null,
  selectedCourseModule: null,
  validation: {
    fields: {},
  },
  messages: [],
  analytics: {
    sortColumn: "student",
    sortOrder: sortOrders.ASC,
  },
};
