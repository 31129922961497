/**
 * A modal dialog for selecting metadata labels.
 *
 * @module ui/component/modal/label-select
 * @category UI
 * @subcategory Modal Dialogs
 */

import button from "ui/component/form-managed/button";
import input from "ui/component/form-managed/field-input";
import dialog from "ui/component/modal/layout/dialog";
import headerSearchBar from "ui/component/modal/component/header-search-bar";
import li from "ui/html/li";
import span from "ui/html/span";
import ul from "ui/html/ul";
import { unique } from "util/array";
import { shortDescription } from "util/format";
import { merge, frozen } from "util/object";

const doSearch = (state) => (search, modalView) => {
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(labelSelectModal({
    ...state,
    search,
  }, modalView));
};

const resolve = (state, result, modalView) => {
  if (state.onSelect) {
    state.onSelect(result);
    modalView.close();
  } else {
    modalView.resolve(result);
  }
};

const doAdd = (state, modalView) => () => {
  const value = modalView.qs("[name=label_entry]").value.trim();
  // prevent duplicating labels by changes in case
  let newLabel = state.entries.find(
    (entry) => value.toLowerCase() === entry.name.toLowerCase(),
  ) || {
    name: value,
    description: "",
  };
  if (newLabel.name.trim()) {
    newLabel = {
      name: newLabel.name.trim(),
      description: "",
    };
  } else {
    return;
  }
  /* eslint-disable-next-line no-param-reassign */
  modalView.qs("[name=label_entry]").value = "";
  if (state.multiSelect) {
    const newEntries = unique([...state.entries, newLabel]);
    const nowSelected = unique([...state.selectedLabels, newLabel]);
    /* eslint-disable-next-line no-use-before-define */
    modalView.patch(labelSelectModal({
      ...state,
      entries: [...newEntries],
      selectedLabels: nowSelected,
    }, modalView));
  } else resolve(state, newLabel, modalView);
};

const filterEntries = (search) => (entry) => {
  const lower = search.toLowerCase();
  return entry.name.toLowerCase().includes(lower);
};

const doAddMulti = (entry, state, modalView) => {
  const selectedList = [...state.selectedLabels];
  document.querySelector(`[data-label-id="${entry.name}"]`).classList.toggle('selected');
  if (selectedList.indexOf(entry) >= 0) {
    selectedList.splice(selectedList.indexOf(entry), 1);
  } else {
    selectedList.push(entry);
  }
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(labelSelectModal({
    ...state,
    selectedLabels: selectedList,
  }, modalView));
};

const entryLine = (state, modalView) => (entry) => li(
  [span(shortDescription(entry.name, 30))],
  `.label${
    state.multiSelect && state.selectedLabels.some((lbl) => lbl.name === entry.name)
      ? ".selected"
      : ""
  }`,
  {
    dataset: { labelId: entry.name },
    on: {
      click: () => {
        if (state.multiSelect) {
          doAddMulti(entry, state, modalView);
        } else {
          resolve(state, entry, modalView);
        }
      },
    },
    key: entry.name,
  },
);

const actionButtons = (state, modalView) => {
  const {
    noAdd,
    multiSelect,
    selectedLabels,
  } = state;
  const actions = [];
  if (noAdd === false) {
    actions.push(
      span(
        [
          input({
            name: "label_entry",
            label: "Create a new label...",
            onChange: doAdd(state, modalView),
          }),
          button.secondary({
            iconOnly: true,
            icon: "plus",
            onClick: () => doAdd(state, modalView),
          }),
        ],
        ".add.search",
      ),
    );
  }
  if (multiSelect) {
    actions.push(
      button({
        label: 'Save',
        onClick: () => resolve(state, selectedLabels, modalView),
      }),
    );
  }
  return actions;
};

const sortAlpha = (a, b) => {
  const at = a.name.toLowerCase();
  const bt = b.name.toLowerCase();
  if (at < bt) return -1;
  if (at > bt) return 1;
  return 0;
};

const defaultState = frozen({
  entries: [],
  search: "",
  onSelect: null,
  multiSelect: false,
  noAdd: false,
  selectedLabels: [],
});

/**
 * Label select modal dialog.
 *
 * Supports async modal mode. In async mode it resolves with an array of selected labels.
 * In sync mode, triggered by the presence of the `onSelect` callback, it calls the
 * callback with an array of selected labels.
 *
 * @param {object} state
 * @param {string[]} state.entries list of all selectable labels
 * @param {function} [onSelect] if present, will be called instead of using async resolve
 * @param {string} [search=""] initial search filter value
 * @param {boolean} [noAdd=false] hides the add new label field
 * @param {boolean} [multiSelect=false] enables multiple label select mode
 * @param {string[]} [selectedLabels=[]] list of currently selected labels
 * @return {module:ui/html/div~Div}
 */
export default function labelSelectModal(inState, modalView = null) {
  const state = merge(defaultState, inState);
  const {
    entries,
    // onSelect = defaultOnSelect,
    search = "",
  } = state;
  return dialog({
    sel: `.form.label-select${state.multiSelect ? ".multi" : ""}`,
    header: headerSearchBar({ searchText: search, onSearch: doSearch(state) }, modalView),
    body: [
      ul(entries
        .filter(filterEntries(search))
        .sort(sortAlpha)
        .map(entryLine(state, modalView)), ".entries"),
    ],
    footer: actionButtons(state, modalView),
  }, modalView);
}
