/**
 * User facing chat interface.
 *
 * @module ui/page/chat
 * @category Pages
 * @subcategory User Facing
 */
import log from "log";
import {
  messageTypes as types,
  channelUpdateTypes,
  userUpdates,
} from "@smartedge/em-message/constants";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import userStream from "data/user";
import { featureTypes } from "model/config/constants";
import { canChat, isRoot } from "model/user";
import chatInterface from "ui/component/chat/interface";
import main from "ui/html/main";
import layoutUser from "ui/page/layout/user";
import userSelectModal from "ui/component/modal/user-select";
import { frozen, merge } from "util/object";
import view from "ui/view/view";
import { chatMode, chatTypes } from "ui/component/chat/common";
import { systemPrivilegeGroups } from "model/acl/constants";
import { inboxTypes } from "model/message/constants";
import { filterMap } from "util/array";
import hashmap from "util/hash-map";
import { isAnonymous } from "util/user";
import { doLoginRedirect } from "util/navigation";
import cache from "cache";
import getUsername from "ui/component/messaging/username";
import { guard } from "util/feature-flag";
import {
  blockUser,
  clearSearchParams,
  defaultSearchParams,
  highlightMessage,
  loadMoreMessages,
  makeDMChannelDescriptorFactory,
  onSearch,
  onSearchAllLatestMessages,
  processFileAttachment,
  processReturnedMessages,
  removeAttachedFile,
  reportMessage,
  selectChannel,
  selectChatType,
  selectMessage,
  sendMessage,
  unblockUser,
  updateChatMode,
} from "./common";

const CHANNEL_UPDATE_TIME = 30000;

const defaultState = frozen({
  me: null,
  knownUsers: hashmap(),
  knownGroups: hashmap(),
  blockedUserIds: new Set(),
  blockedByUserIds: new Set(),
  channelMembers: hashmap(),
  unreadMessages: [],
  unreadMessagesProcessed: false,
  channelMessages: new Map(),
  channels: [],
  deferredChannelSelect: null,
  selectedChannel: null,
  channelsOpened: true,
  directMessagesOpened: true,
  selectedChatType: chatTypes.NONE,
  chatMode: chatMode.DEFAULT,
  selectedMessages: [],
  attachedFiles: [],
  attachedFilesDescriptors: [],
  fileAttachInProgress: false,
  messagesLoading: false,
  searchParams: defaultSearchParams(),
});

let loading, notification, page, modal;

const search = async (self) => {
  if (self.state.selectedChatType === chatTypes.ALL_MESSAGES) {
    await onSearchAllLatestMessages(self, self.state.searchParams);
  } else {
    await onSearch(self, self.state.searchParams);
  }
};

const addUser = async (self) => {
  const selectedUser = await modal.async(userSelectModal({
    users: [...self.state.knownUsers.values()],
  }, modal));
  if (selectedUser) {
    api.message.sendAddFriend(selectedUser.id);
    self.update({ deferredChannelSelect: selectedUser.id });
  }
};

const removeUser = async (self, channel) => {
  if (!(await modal.confirm(
    `Are you sure you want to remove ${getUsername(channel)} from DM list?`,
    null,
    "Yes",
    "No",
    true,
  ))) {
    return;
  }
  await api.message.sendRemoveFriend(channel.id);
  if (self.state.selectedChannel?.id === channel.id) {
    await selectChatType(page, chatTypes.ALL_MESSAGES);
  }
};

const onClickUser = async (userId) => {
  const selectedChannel = page.state.channels.find((c) => c.id === userId);
  if (selectedChannel) {
    await selectChannel(page, selectedChannel);
    await selectChatType(page, chatTypes.REGULAR_MESSAGING);
  } else {
    api.message.sendAddFriend(userId);
    page.update({ deferredChannelSelect: userId });
  }
};

const showChat = (self) => chatInterface({
  sel: "#chat",
  ...self.state,
  onAdvancedSearch: (params) => {
    self.updateState({
      searchParams: {
        ...self.state.searchParams,
        ...params,
      },
    });
    search(self).catch((e) => log.error(e, "while executing search"));
  },
  onSearch: (searchString) => {
    self.updateState({
      searchParams: {
        ...defaultSearchParams(),
        searchString,
      },
    });
    search(self).catch((e) => log.error(e, "while executing search"));
  },
  onSend: (text, attachedFiles) => sendMessage(self, text, attachedFiles),
  onSelectChannel: async (channel, messageToHighlight) => {
    clearSearchParams(self);
    await selectChannel(self, channel);
    if (messageToHighlight) {
      await highlightMessage(self, messageToHighlight);
    }
  },
  onSelectChatType: async (chatType) => {
    clearSearchParams(self);
    await selectChatType(self, chatType);
  },
  onSelectMessage: (message) => selectMessage(self, message),
  onSelectChatMode: (mode) => updateChatMode(self, mode),
  onReportMessage: (message) => reportMessage(message, notification, modal),
  onBlockUser: (message) => blockUser(message, page, notification, modal),
  onUnblockUser: (id) => unblockUser(id, page, notification, modal),
  onAttachFile: (file) => processFileAttachment(self, file, notification),
  onRemoveAttachedFile: (file) => removeAttachedFile(self, file),
  onScrollReachedTop: () => loadMoreMessages(self),
  onAddUser: () => addUser(self),
  onRemoveUser: (channel) => removeUser(self, channel),
  onClickUser: (id) => onClickUser(id).catch((e) => log.error(e, "while selecting user")),
}, self, modal);

let channelListUpdateTimeout;

const channelListUpdate = () => {
  if (channelListUpdateTimeout) clearTimeout(channelListUpdateTimeout);
  if (api.message.getAuthState()) {
    api.message.requestChannelList();
    channelListUpdateTimeout = setTimeout(channelListUpdate, CHANNEL_UPDATE_TIME);
  } else {
    // no auth, let's check again in a little while
    channelListUpdateTimeout = setTimeout(channelListUpdate, 250);
  }
};

const isModerator = (user, knownGroups) => isRoot(user) || user.groupIds?.reduce((acc, cur) => {
  const gname = knownGroups?.get(cur)?.name;
  if (!gname) return acc || false;
  const isMod = ((
    gname === systemPrivilegeGroups.ADMIN
    || gname === systemPrivilegeGroups.ADMIN_MESSAGING
  ));
  return acc || isMod;
}, false) || false;

const processUsers = (users, knownGroups) => {
  const processed = hashmap([...users.entries()].map(([uid, u]) => [uid, {
    ...u,
    isModerator: isModerator(u, knownGroups),
  }]));
  return processed;
};

const makeChannelList = (me, users, groups) => [
  ...groups
    .map((g) => ({ ...g, type: inboxTypes.GROUP }))
    .sort((a, b) => a.name.localeCompare(b.name)),
  ...users
    .reduce(filterMap(
      (u) => !u.conversation?.blocked,
      makeDMChannelDescriptorFactory(me),
    ), [])
    .sort((a, b) => a.name.localeCompare(b.name)),
];

const handleChannelList = async ({
  users: usrs,
  groups,
  blockedUserIds,
  blockedByUserIds,
  knownGroups: kG,
}) => {
  const { me, unreadMessages } = page.state;
  const knownGroups = hashmap(kG.map((g) => [g.id, g]));
  page.updateState({
    channels: makeChannelList(me, usrs, groups),
    blockedUserIds: new Set(blockedUserIds),
    blockedByUserIds: new Set(blockedByUserIds),
    knownGroups,
    knownUsers: processUsers(page.state.knownUsers, knownGroups),
  });
  if (page.state.deferredChannelSelect) {
    const selectedChannel = page.state.channels.find(
      (c) => c.id === page.state.deferredChannelSelect,
    );
    page.updateState({ deferredChannelSelect: null });
    if (selectedChannel) {
      await selectChannel(page, selectedChannel);
      await selectChatType(page, chatTypes.REGULAR_MESSAGING);
    }
  }
  if (!page.state.unreadMessagesProcessed) {
    processReturnedMessages(page, unreadMessages, true);
  }
  page.update({ unreadMessagesProcessed: true });
};

const channelIsTarget = (channel, target) => {
  if (channel?.type === inboxTypes.GROUP && channel?.id === target) return true;
  return !!(target?.length
    && target?.includes(channel.members?.[0]?.id)
    && target?.includes(channel.members?.[1]?.id));
};

const handleChannelUpdate = async (content) => {
  switch (content.updateType) {
    case channelUpdateTypes.THREAD_STATUS:
      if (content.target && content.status) {
        if (channelIsTarget(page.state?.selectedChannel, content.target)) {
          page.updateState({ selectedChannel: { status: content.status } });
        }

        page.update({
          channels: page.state.channels.map((channel) => {
            if (channelIsTarget(channel, content.target)) {
              return {
                ...channel,
                status: content.status,
              };
            }
            return channel;
          }),
        });
      }
      break;
    case channelUpdateTypes.USER:
      // prompt for immediate channel update
      channelListUpdate();
      if (
        content.target === page.state.me.id
        && content.status === userUpdates.SUSPENDED
      ) {
        const me = await api.user.getMe();
        page.update({ me });
        await selectChatType(page, chatTypes.ALL_MESSAGES);
        await modal.alert("Your chat privileges have been suspended.", "You will be unable to participate in chat until a moderator enables your chat privileges.");
      }
      if (
        content.target === page.state.me.id
        && content.status === userUpdates.RESTORED
      ) {
        const me = await api.user.getMe();
        page.update({ me });
        await selectChatType(page, chatTypes.ALL_MESSAGES);
        await modal.alert("Your chat privileges have been restored.", "Please allow a moment for all functionality to be enabled.");
      }
      break;
    default:
      break;
  }
};

export default async function chat(selector) {
  guard(featureTypes.MESSAGING);
  const title = "Messages";
  ({ loading, notification, modal } = layoutUser(selector, [
    main("#chat"),
  ], title));
  loading.show();
  pageVisit(window.location.pathname);

  const [me, unreadMessages] = await Promise.all([
    api.user.getMe(),
    api.message.getAllUnreadMessagesInChannels()
      .then((messages) => messages.filter((m) => (m.type === types.CHAT_TEXT))),
  ]);

  if (isAnonymous(me)) doLoginRedirect();

  const state = merge(defaultState, {
    me,
    selectedChannel: null,
    latestDirectMessages: new Map(),
    unreadMessages,
    reportedMessageIds: cache.reportedMessages.getReportedMessageIds(),
  });

  page = view.create(showChat)("#chat", state);

  page.bindStreams([
    ["knownUsers", userStream.known().map((users) => processUsers(users, page.state.knownGroups))],
    ["channelMembers", userStream.knownGroupMembers$],
  ]);

  await selectChatType(page, chatTypes.ALL_MESSAGES);

  const message$ = api.message.getMessageStream();
  const server$ = api.message.getServerStream();

  message$.map((m) => m.message).addListener({
    next: (message) => {
      processReturnedMessages(page, [message]);
      if (message.type === types.REPORT) {
        cache.reportedMessages.storeReportedMessageId(message.reportedMessage.id);
        page.update({
          reportedMessageIds: cache.reportedMessages.getReportedMessageIds(),
        });
      }
    },
  });

  // start periodic channel update
  channelListUpdate();

  server$.addListener({
    next: ({ type, message }) => {
      switch (type) {
        case types.LIST_CHANNELS:
          handleChannelList(message.content)
            .catch((e) => log.error(e, "while handling channel list"));
          break;
        case types.CHANNEL_UPDATE:
          handleChannelUpdate(message.content)
            .catch((e) => log.error(e, "while handling channel update"));
          break;
        default:
          break;
      }
    },
  });

  loading.hide();
  if (!canChat(me)) modal.alert("Your chat privileges are suspended.", "Most chat features will be unavailable.");
}
