/**
 * The landing page for the page management section in the admin area.
 *
 * Displays a table of current pages in the system and permits editing and deleting them.
 *
 * @module ui/page/admin/dynamic/manage
 * @category Pages
 * @subcategory Admin - Dynamic Pages
 */
import api from "api";
import getConfig from "config";
import { a, div, section } from "ui/html";
import { pageSortCriteria, pageTypes } from "model/dynamic-page/constants";
import widget from "ui/component/dashboard-widget";
import table, { sortOrders } from "ui/component/dynamic-table";
import placeholder from "ui/component/placeholder";
import actionBar from "ui/component/dashboard-action-bar";
import actionBarSearch from "ui/component/action-bar-search";
import { getQueryParams } from "util/navigation";
import view from "ui/view";
import { pageTextFilter } from "util/filter";
import { pageSort } from "util/sort";
import adminManagePagesHelpModal from "ui/component/modal/help/manage-pages";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import dashboardLayout from "ui/page/layout/dashboard";
import { searchModes, defaultSearchParams, defaultManageState } from "./state";

let searchView, managePagesView;

/**
 * Handles changing sort order.
 * @function onSortChange
 * @private
 */
const onSortChange = (theView) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === theView.state.sortColumn) {
    if (theView.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  theView.update({
    sortOrder,
    sortColumn,
  });
};

/**
 * Figures out which pageSortCriteria to used based on sortColumn and sortOrder
 * state properties.
 * @function getSortCriteria
 * @private
 */
export const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "title":
      return (sortOrder === sortOrders.DESC
        ? pageSortCriteria.TITLE_DESC
        : pageSortCriteria.TITLE_ASC);
    case "slug":
      return (sortOrder === sortOrders.DESC
        ? pageSortCriteria.SLUG_DESC
        : pageSortCriteria.SLUG_ASC);
    case "id":
    default:
      return (sortOrder === sortOrders.DESC
        ? pageSortCriteria.ID_DESC
        : pageSortCriteria.ID_ASC);
  }
};

/**
 * Clears search results.
 * @function onClear
 * @private
 */
const onClear = () => {
  managePagesView.update({
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  });
};

/**
 * Updates the search params with the contents of the basic search filter.
 *
 * @function onSearch
 * @private
 */
const onSearch = (filter) => {
  managePagesView.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  });
};

const tableRow = (page) => ({
  id: a(page.id, `/admin/page-profile?id=${page.id}`),
  title: page.title === null || page.title.trim() === "" ? '—' : page.title,
  slug: page.slug === null || page.slug.trim() === ""
    ? '—'
    : a(page.slug, `/${page.slug}`),
});

/**
 * Sets up the results table.
 *
 * @function showManagePages
 * @private
 */
const showManagePages = (theView) => section("#manage-pages", table({
  columnLabels: [
    "ID",
    "Title",
    "Slug",
  ],
  sortColumn: theView.state.sortColumn,
  sortOrder: theView.state.sortOrder,
  placeholder: "No pages found.",
  rows: theView.state.filteredPages?.map(tableRow) || [],
  onSortChange: onSortChange(theView),
}));

/**
 * Sets up the search action bar.
 * @function showSearch
 * @private
 */
const showSearch = ({ state }) => actionBarSearch({
  resultCount: state.filteredPages.length,
  filter: state.searchParams.filter,
  showClear: state.searchMode !== searchModes.NONE,
  title: "All Pages",
  onSearch,
  onClear,
});

const doUpdate = (theView) => {
  const {
    searchMode,
    pages,
    sortColumn,
    sortOrder,
    searchParams,
  } = theView.state;
  let filteredPages;
  switch (searchMode) {
    case searchModes.BASIC:
      filteredPages = pages.filter(pageTextFilter(searchParams.filter));
      break;
    case searchModes.NONE:
    default:
      filteredPages = [...pages];
  }
  filteredPages.sort(pageSort(getSortCriteria(sortColumn, sortOrder)));

  theView.updateState({ filteredPages });
  theView.patch(showManagePages(theView));
  if (searchView) searchView.update(managePagesView.state);
};

/**
 * The manage view for dynamic pages.
 *
 * @function managePages
 * @param {module:ui/html~Selector} selector root element for the manage pages view
 */
export default async function managePages(selector) {
  const title = "Manage Pages";
  const params = getQueryParams();
  const { header, loading, modal } = dashboardLayout(
    selector,
    [
      actionBar(div("#action-bar-search")),
      widget(placeholder("Loading...", "#manage-pages")),
    ],
    title,
    true,
  );
  loading.show();

  const [config, user, pages] = await Promise.all([
    getConfig(),
    api.user.getMe(),
    api.page.list().then((res) => (params.sections
      // for debugging only! _NOT_ a supported feature
      ? res
      : res.filter((page) => page.type === pageTypes.INDEPENDENT))),
  ]);

  const state = {
    ...defaultManageState,
    config,
    user,
    pages,
    title,
  };

  header.update(state);
  managePagesView = view.create(showManagePages, doUpdate)("#manage-pages", state);
  searchView = view.create(showSearch)("#action-bar-search", state);
  if (!isHelpModalSeen(modalTypes.ADMIN_MANAGE_PAGES)) {
    modal.show(adminManagePagesHelpModal());
    markModalAsSeen(modalTypes.ADMIN_MANAGE_PAGES);
  }
  loading.hide();
}
