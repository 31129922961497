/**
 * A form field for slugs.
 *
 * @module ui/component/form-managed/field-slug
 * @category UI
 * @subcategory Forms
 */
import genericField from "ui/component/form-managed/field-generic";
import { makeInputBindings } from "ui/component/form-managed/field-input";
import { slugConstraints } from "ui/common/validation-constraints";
import iconButton from "ui/component/icon-button";
import input from "ui/html/input";
import { frozen, merge } from "util/object";
import { isEmptyString } from "util/validation";

const helpTexts = frozen({
  invalid: "Slugs must be 3-128 characters long and may contain only lower-case letters, numbers, and dashes and may not end or start with a dash.",
  required: "Please supply a URL slug.",
  taken: "This title is already in use.",
});

/**
 * Helper function to generate a page slug based on a given string (e.g. title).
 *
 * @function generateSlug
 * @param {String} title page title
 * @param {DynamicPage[]} existingPages used to avoid slug collisions
 * @return {String}
 */
const generateSlug = (title, taken) => {
  if (isEmptyString(title)) return "";
  let processed = title.toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/-+/g, "-")
    .replace(/-+$/g, "");
  while (taken.includes(processed)) {
    const tail = /\d*$/.exec(processed)?.[0];
    if (tail?.length) {
      processed = `${processed.slice(0, -tail.length)}${parseInt(tail, 10) + 1}`;
    } else processed = `${processed}-2`;
  }
  return processed;
};

/**
 * @private
 */
const defaultState = frozen({
  name: "slug",
  label: "URL Extension",
  constraints: slugConstraints,
  helpTexts,
  sel: "",
});

/**
 * A form field for slugs.
 *
 * If `title` parameter is provided it will be used to auto-generate a title when the
 * generate slug button is clicked. If it isn't provided the generate slug button will be
 * hidden.
 *
 * @function slug
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="slug"]
 * @param {string} [label="Slug"]
 * @param {?string[]} taken list of already taken slugs
 * @param {?string} [title] title from which to generate a slug
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function slug(inState) {
  const state = merge(defaultState, inState);
  const inputEl = input(
    state.type,
    state.values?.[state.name] || state.value || "",
    state.required,
    state.name,
    state.constraints || {},
    makeInputBindings(state),
  );
  return genericField(
    state,
    [
      inputEl,
      state.title ? iconButton(
        "redo",
        "Generate Slug",
        () => {
          inputEl.elm.value = generateSlug(state.title, state.taken);
        },
        ".secondary",
        "solid",
        state.disabled,
      ) : "",
    ],
    `${state.sel}.slug`,
  );
}
