/**
 * Collection of constants used elsewhere in the api module.
 *
 * @module api/constants
 * @category Backend API
 * @subcategory Constants
 */

/**
 * API endpoint urls. These occasionally change so we store them as constants instead
 * of hardcoding them.
 *
 * @enum {string} endpoints
 * @readonly
 */
export const endpoints = Object.freeze({
  ABSTRACT_ITEM: "/api/item-metadata",
  ACL: "/api/acl",
  ACL_USER: "/api/acl/user",
  ACL_GROUP: "/api/acl/group",
  ACL_USER_GROUP: "/api/acl/user/group",
  ACL_MEDIA_DOCUMENT: "/api/acl/media/document",
  ACL_MEDIA_VIDEO: "/api/acl/media/video",
  ACL_MEDIA_LIST: "/api/acl/media/list",
  ACL_COURSE: "/api/acl/course",
  ACL_MESSAGE_USER: "/api/acl/msg/user",
  ANALYTICS_ASSESSMENTS: "/api/read/assessments",
  ANALYTICS_CONTENT_DURATION: "/api/read/content/duration",
  ANALYTICS_PAGE_VIEWS: "/api/read/pagecount",
  ANALYTICS_PAGE_VIEWS_USERS: "/api/read/pagecount/users",
  ANALYTICS_MBE_STATUS: "/api/read/mbe",
  ANALYTICS_VIDEO_ADDED: "/api/read/recently/watched",
  ANALYTICS_VIDEO_PLAYBACK: (userId) => `/api/read/content/playback/${userId}`,
  ANALYTICS_VIDEO_TRENDING: "/api/read/trending",
  ANALYTICS_VIDEO_WATCHED: "/api/read/recently/watched",
  ANONYMOUS_LOGIN: "/api/anonymous-login",
  ASSESSMENT_EVALUATION: (courseId) => `/api/course/${courseId}/eval`,
  CATEGORY: "/api/category",
  COURSE: (id) => `/api/course/${id}`,
  EVALUATION: "/api/eval",
  GRADING_SCHEME: (id) => `/api/grading/${id}`,
  GRADING_SCHEMES: `/api/grading`,
  /** file data for file descriptor contents */
  CONTENT_FILE: `/content/file/descriptor`,
  CONTENT_VIDEO: `/content/video`,
  CONTENT_DOCUMENT: `/content/document`,
  CONTENT_METADATA_POSTER: (id) => `/content/${id}/poster`,
  CONVERSATION_GRANTS: (
    principalId, conversationId, principalType,
  ) => `/api/acl/conversation/${conversationId}/user/${principalId}/${principalType}`,
  CONVERSATION_PRINCIPALS: (id) => `/api/acl/conversation/user/all/${id}`,
  COURSES: "/api/course",
  FE_CFG: "/api/fe-cfg",
  FE_INFO: "/info.json",
  FILE: "/api/file",
  FILE_DESCRIPTOR: "/api/file/descriptor",
  FILE_DESCRIPTOR_BATCH: "/api/file/descriptor/batch",
  MEDIA_DOCUMENT: "/api/media/document",
  MEDIA_POSTER: "/api/media/poster",
  MEDIA_VIDEO: "/api/media/video",
  MESSAGING: "/api/messaging",
  MESSAGING_CONVERSATION: "/api/messaging/conversation",
  MESSAGING_INBOX: "/api/messaging/inbox",
  META_HOME_LIST: "/api/list-metadata/homelist",
  MBE_INFO: "/info",
  META_LIST: "/api/list-metadata",
  META_DOCUMENT: "/api/document-metadata",
  META_DOCUMENT_BATCH: "/api/document-metadata/batch",
  META_VIDEO: "/api/video-metadata",
  META_VIDEO_BATCH: "/api/video-metadata/batch",
  META_MOST_WATCHED: "/api/video-metadata/most-watched",
  META_RECENTLY_ADDED: "/api/item-metadata/recently-added",
  META_RECENTLY_WATCHED: "/api/video-metadata/recently-watched",
  META_TRENDING: "/api/video-metadata/trending",
  NOTIFICATION: "/api/notification",
  FEEDBACK: "/api/feedback",
  PAGE: "/api/page",
  PAGE_SLUG: "/api/page/slug",
  PAGE_SEARCH: "/api/page/search",
  PAGE_SUBPAGE: "/api/page/subpage",
  PAGE_FILE: (pageId, subPageId) => `/content/page/${pageId}/sub-page/${subPageId}/slider-image`,
  SERVER_CONFIG: "/api/config",
  SERVER_HEALTH: "/actuator/health",
  SERVER_INFO: "/actuator/info",
  SERVER_METRICS: "/actuator/health",
  THEME_INFO: "/api/v1",
  USER: "/api/user",
  USER_ANONYMOUS: "/api/user/anonymous",
  USER_BATCH: "/api/user/batch",
  USER_LOGIN: "/api/login",
  USER_LOGOUT: "/api/logout",
  USER_FILE_DESCRIPTOR_ACCESS: (userId, fileId) => `/api/acl/file/${fileId}/user/${userId}`,
  USER_LIST_FILE_DESCRIPTOR: (userId, fileId) => `/api/acl/file/${fileId}/user/${userId}/all`,
  USER_LIST_MEDIA: (
    subjectId,
    metadataTypeEndpoint,
  ) => `/api/acl/media/${metadataTypeEndpoint}/user/all/${subjectId}`,
  USER_LIST_PAGE: (subjectId) => `/api/acl/page/user/all/${subjectId}`,
  USER_PAGE_ACCESS: (principalId, subjectId, subjectType) => `/api/acl/page/${subjectId}/user/${principalId}/${subjectType}`,
  USER_PASSWORD_RESET: "/api/reset-password",
  USER_REFRESH: "/api/refresh-access-token",
  USER_REGISTER: "/api/register-account",
  USER_REVERIFY: "/api/resend-verification-code",
  USER_ROLE: "/api/acl/user/role",
  USER_SELF: "/api/user/me",
  USER_VERIFY: "/api/verify-account",
  UPDATE_GRANTS: (type, subjectId) => `/api/acl/${type}/${subjectId}`,
  WABE_INFO: "/health",
});

/**
 * List of *BE services.
 *
 * @enum {string} services
 * @readonly
 * @property {string} DEFAULT default service
 * @property {string} MBE message service
 * @property {string} WABE write analytics service
 * @property {string} RABE read analytics service
 * @property {string} THEME themes service
 */
export const services = Object.freeze({
  DEFAULT: "DEFAULT",
  FE: "FE",
  MBE: "MBE",
  WABE: "WABE",
  RABE: "RABE",
  THEME: "THEME",
});

/**
 * Functions that take server config object and return urls of various apps in
 * the smartedge ecosystem.
 *
 * @readonly
 * @enum {function} externalURLs
 * @property {function} fileManager generates URL for file manager utility
 */
export const externalURLs = Object.freeze({
  fileManager: (server) => `${server.protocol}://${server.host}:${server.fileManagerPort || "8888"}/login?redirect=%2Ffiles%2F`,
});

/**
 * List of environments.
 *
 * @enum {string} environments
 * @readonly
 * @property {string} PRODUCTION production environment disables console logging and source maps
 * @property {string} DEVELOPMENT enables verbose console logging, source maps and other dev utils
 */
export const environments = Object.freeze({
  PRODUCTION: "production",
  DEVELOPMENT: "development",
  LOCAL: "local", // deprecated
});

/**
 * Storage strategies.
 *
 * @enum {string} storageStrategies
 * @readonly
 * @property {window.sessionStorage} SESSION store application data in session storage
 * @property {window.localStorage} LOCAL store application data in local storage
 */
export const storageStrategies = Object.freeze({
  SESSION: window.sessionStorage,
  LOCAL: window.localStorage,
});

/**
 * List of the possible storage strategy types.
 *
 * @constant storageStrategyList
 * @type {Set.<string>}
 * @readonly
 */
export const storageStrategyList = Object.freeze(
  new Set(Object.keys(storageStrategies)),
);

/**
 * statically assigned UUID for the homelist item. Used for filtering in search
 * results as well as enabling/disabling certain features in content edit.
 *
 * @constant HOMELIST_ID
 * @type UUID
 * @default
 * @readonly
 * @deprecated home page is a DynamicPage now, not a ListMetadata
 */

export const HOMELIST_ID = "00000000-0000-0000-0000-000000000000";
