/**
 * File picker tab for filePickerModes.KEEP
 * @module ui/component/form-managed/file-picker/tab-keep
 * @private
 */
/***/
import cameoFile from "ui/component/cameo-file";
import div from "ui/html/div";

/**
 * A tab for keeping a file that has already been selected during a previous editing
 * session.
 * @function keepFileTab
 * @param {object} state file picker state
 * @private
 */
export default function filePickerTabKeepFile(state) {
  return div(
    ".file-keep",
    state.value.current?.name
      ? cameoFile({ file: state.value.current, valid: true })
      : cameoFile({ file: { name: "No file attached" }, valid: false }),
  );
}
