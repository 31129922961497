/**
 * A help modal dialog for manage pages page.
 *
 * @module ui/component/modal/help/manage-pages
 * @category UI
 * @subcategory Modal Dialogs
 */
import { p } from "ui/html";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminManagePagesHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_MANAGE_PAGES,
    skipCache,
    async,
  }, [
    p("Click on the page ID to view the page profile."),
  ]);
}
