/**
 * A form field for email addresses.
 *
 * @module ui/component/form-managed/field-email-address
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { emailConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  required: "Please enter an email address.",
  invalid: "Please enter a valid email address.",
  taken: "This email address is already in use by another account.",
});

/**
 * @private
 */
const defaultState = frozen({
  autocomplete: "email",
  constraints: emailConstraints,
  helpTexts,
  label: "Email Address",
  name: "email",
  sel: "",
  taken: null,
});

/**
 * A form field for email addresses.
 *
 * If the `taken` property is provided with a list of email address strings,
 * validation will check if the current value is in the list.
 *
 * @function emailAddress
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="email"]
 * @param {string} [label="Email Address"]
 * @param {Selector} [sel=""]
 * @param {?string[]} taken list of already used email addresses
 * @return {module:ui/common/el~El}
 */
export default function email(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.email-address`,
  });
}
