/**
 * Video metadata streams.
 *
 * Provides `get` interface for fetching video by `videoId`.
 *
 * Provides `getMany` interface for fetching many videos by `[videoId]`.
 *
 * @module data/metadata/video
 * @category Data Streams
 * @subcategory Metadata
 */
import xs from "xstream";
import cache from "cache";
import { byIds, countVideos } from "api/v2/metadata/video";
import { makeVideoMetadata } from "model/metadata";
import { CACHE_VIDEO_KEY_V2 as CACHE_KEY } from "cache/constants";
import playbackStream from "data/metadata/playback-position";
import many from "data/pipeline/many";
import single from "data/pipeline/single";
import countPipe from "data/pipeline/count";
import { streamLog, sumStream } from "data/stream/compose";
import hashmap from "util/hash-map";

const itemMap = hashmap();
const countMap = hashmap();

const defaultFn = (id) => makeVideoMetadata({ id });
const modelFn = (partial) => makeVideoMetadata(partial);

const all$ = xs.create();

const {
  post: get,
  sink$: getSink$,
  pending$: singlePending$,
} = single({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: getMany,
  sink$: getManySink$,
  pending$: manyPending$,
} = many({
  apiFn: byIds,
  defaultFn,
  modelFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const {
  post: count,
  sink$: countSink$,
  pending$: countPending$,
} = countPipe({
  apiFn: countVideos,
  defaultFn: () => 0,
  modelFn: (r) => r,
  cacheKey: CACHE_KEY,
  accumulator: countMap,
});

const out$ = xs.merge(
  getSink$,
  getManySink$,
);

const pending$ = sumStream(
  singlePending$,
  manyPending$,
  countPending$,
);

all$.compose(streamLog("VIDEO_ALL$"));
all$.imitate(out$);

const getPlaybackPositions = () => playbackStream.get(cache.getProfile()?.id || null);

export default {
  count,
  get,
  getMany,
  getPlaybackPositions,
  all$,
  count$: countSink$,
  pending$,
};
