/**
 * A help modal dialog explaining the "no entries" field for page sections.
 *
 * @module ui/component/modal/help/page-section-no-entries
 * @category UI
 * @subcategory Modal Dialogs
 */
import { p } from "ui/html";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function pageSectionNoEntriesHelpModal(
  skipCache = true,
  async = true,
) {
  return commonHelpModal({
    modalType: modalTypes.QUESTION_GRADING,
    skipCache,
    async,
  }, [
    p("This text will be displayed if there are no entries in the playlist accessible to the user, or when there are no entries meeting search criteria for a dynamic playlist."),
    p("It will be hidden if any entries are available to display."),
  ]);
}
