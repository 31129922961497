/**
 * The control bar for the top of the search results page.
 *
 * @module ui/component/search-controls
 * @category UI
 * @subcategory Components
 */
import { sortCriteriaLabeled } from "model/metadata/constants";
import { div, h2, section } from "ui/html";
import { emit } from "util/event";
import select from "ui/component/form-managed/field-select";
import button from "./form-managed/button";

export const searchModes = {
  RESULTS: "results",
  ALL: "all",
};

const sortBlock = (items, params) => (items.length > 0 ? select({
  name: "sort-by",
  options: [
    { label: "Auto", value: "AUTO", selected: params.sortCriteriaList.length === 0 },
    ...([...sortCriteriaLabeled].map(({ label, value }) => ({
      label,
      value,
      selected: params.sortCriteriaList.includes(value),
    }))),
  ],
  value: params.sortCriteriaList?.[0] || "AUTO",
  label: "Sort By:",
  onChange: (newValue) => {
    emit(document.body, "em:sort-criteria", { value: newValue === "AUTO" ? "" : newValue });
  },
  placeholder: "Sort By:",
}) : "");

const titleLine = (params, searchMode, items) => {
  if (searchMode === searchModes.ALL || !(params.terms?.length)) {
    return [
      h2("See All"),
      div(".search-criteria-container", [
        sortBlock(items, params),
      ]),
    ];
  }
  if ((params?.terms?.length || 0) > 0 || (params?.labels?.length || 0) > 0) {
    return [
      div(".title-bar", [
        h2("Search Results"),
        button.icon({
          sel: ".danger",
          icon: "xmark",
          onClick: () => emit(document.body, "em:search-see-all"),
        }),
      ]),
      div(".search-criteria-container", [
        sortBlock(items, params),
      ]),
    ];
  }
  return [h2("No Results")];
};

/**
 * Header & control bar for search results page.
 *
 * @function searchControls
 * @param {Object} state
 * @param {Array} state.items
 * @param {Object} state.searchParams
 * @param {Set.<MetadataType>} [state.searchParams.types]
 * @param {Object} state.searchParams.sortCriteria
 * @emits "em:sort-criteria" with a new sort criteria entry (should be handled by a page)
 * @return {module:ui/html/section} section#search-controls
 */
export default ({
  searchParams,
  items,
  searchMode = searchModes.ALL,
}) => section("#search-controls", [
  ...titleLine(searchParams, searchMode, items),
]);
