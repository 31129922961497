import cache from "cache";
import getConfig from "config";
import log from "log";
import { io } from "socket.io-client";
import xs from "xstream";
import { fromArchive } from "@smartedge/em-message";
import {
  messageTypes as types,
  chatMessageTypes,
  moderatorActionTypes,
  systemMessageTypes,
  cacheUpdateTypes,
  reportTypes,
} from "@smartedge/em-message/constants";
import { make as makeAuthRequest } from "@smartedge/em-message/types/auth-request";
import { make as makeAdminNotification } from "@smartedge/em-message/types/admin-notification";
import { make as makeUpdateRead } from "@smartedge/em-message/types/update-read";
import { make as makeChat } from "@smartedge/em-message/types/chat-text";
import { make as makeReport } from "@smartedge/em-message/types/report";
import { make as makeModeratorAction } from "@smartedge/em-message/types/moderator-action";
import { make as makeUserBlock } from "@smartedge/em-message/types/user-block";
import { make as makeUserUnblock } from "@smartedge/em-message/types/user-unblock";
import { make as makeCacheUpdate } from "@smartedge/em-message/types/cache-update";
import { make as makeFriendAdd } from "@smartedge/em-message/types/user-friend-add";
import { make as makeFriendRemove } from "@smartedge/em-message/types/user-friend-remove";
import { make as makeArchiveMessage } from "@smartedge/em-message/types/archive-message";
import { TOKEN_KEY } from "model/authentication/constants";
import { get, post } from "api/request";
import { endpoints, services } from "api/constants";
import {
  makeSearchMessageDTO,
  responseToConversationId,
  responseToInbox,
} from "model/message";
import { messageStatuses, messageFilterType, sortOrders } from "model/message/constants";
import { sortText } from "util/sort";

const DEBUG = false;

// master streams - these should NOT be exported, only imitated by exports
const message$ = xs.create(); // chat messages
const server$ = xs.create(); // server info and housekeeping

let socketInternal;
let connecting = false;
let authState;

/**
 * Dispatches an authentication request.
 *
 * This should be run whenever access token is updated.
 *
 * @function authenticate
 * @return {Promise<void>}
 */
export const authenticate = async () => {
  if (!socketInternal) return;
  const tokenPair = cache.getObject(TOKEN_KEY);
  if (!tokenPair) return;
  let accessToken;
  if (tokenPair) ({ accessToken } = tokenPair);
  if (!accessToken) {
    log.error("MESSAGE_SERVICE: requested authentication with no token pair", tokenPair);
    return;
  }
  if (DEBUG) log.debug("MESSAGE_SERVICE: authenticating");

  socketInternal.emit(types.AUTH_REQUEST, makeAuthRequest({ accessToken }));
};

/**
 * Updates authState based on auth response message.
 *
 * @function handleAuthResponse
 * @private
 * @param {AuthenticationResponseMessage} response
 */
const handleAuthResponse = async (response) => {
  if (response.status) authState = true;
  else authState = false;
};

const awaitConnection = () => new Promise((res) => {
  if (socketInternal) res(socketInternal);
  const interval = setInterval(() => {
    if (DEBUG) log.debug("MESSAGE_SERVICE: checking awaited connection");
    if (socketInternal) {
      if (DEBUG) log.debug("MESSAGE_SERVICE: connection ready");
      clearInterval(interval);
      res(socketInternal);
      return;
    }
    if (DEBUG) log.debug("MESSAGE_SERVICE: still awaiting connection");
  }, 250);
});

/**
 * Initializes the socket if it's not already initialized.
 *
 * Should be run before each message sent to MBE, to ensure connection
 * is alive and streams are set up.
 *
 * @function initSocket
 * @private
 * @async
 * @returns {Promise<void>}
 */
const initSocket = async () => {
  const { messageServer } = await getConfig();
  if (socketInternal) return socketInternal;
  if (connecting) {
    if (DEBUG) log.debug("MESSAGE_SERVICE: initSocket already connecting, waiting");
    return awaitConnection();
  }
  connecting = true;
  socketInternal = io(`${messageServer.protocol}://${messageServer.host}:${messageServer.port}`);

  socketInternal.on(types.CONNECT, () => {
    if (DEBUG) log.debug("MESSAGE_SERVICE: new connection");
    authenticate();
  });

  socketInternal.on(types.DISCONNECT, () => {
    if (DEBUG) log.debug("MESSAGE_SERVICE: disconnected");
  });

  socketInternal.on("reconnect_attempt", () => {
    if (DEBUG) log.debug("MESSAGE_SERVICE: reconnecting");
    authenticate();
  });

  socketInternal.on("reconnect", () => {
    if (DEBUG) log.debug("MESSAGE_SERVICE: reconnected");
    authenticate();
  });

  socketInternal.on("error", (e) => log.error(e, "MESSAGE_SERVICE: error!"));

  socketInternal.on(types.AUTH_RESPONSE, handleAuthResponse);

  const handlers = new Map();

  // using imitate below passes the socketInternal events into the master streams even though
  // they were created when the module was initiated
  server$.imitate(xs.create({
    start: (stream) => {
      [...systemMessageTypes].forEach((type) => {
        handlers.set(type, (message) => {
          if (DEBUG) log.debug("MESSAGE_SERVICE: server message", message);
          stream.next({ type, message });
        });
        socketInternal.on(type, handlers.get(type));
      });
    },
    stop: () => {
      if (DEBUG) log.debug("MESSAGE_SERVICE: stopping server stream");
      [...systemMessageTypes].forEach((type) => {
        socketInternal.off(type, handlers.get(type));
      });
    },
  }));

  message$.imitate(xs.create({
    start: (stream) => {
      [...chatMessageTypes].forEach((type) => {
        handlers.set(type, (message) => {
          if (DEBUG) log.debug("MESSAGE_SERVICE: chat message", message);
          stream.next({ type, message });
        });
        socketInternal.on(type, handlers.get(type));
      });
    },
    stop: () => {
      [...chatMessageTypes].forEach((type) => {
        socketInternal.off(type, handlers.get(type));
      });
    },
  }));
  connecting = false;
  return socketInternal;
};

initSocket();

/**
 * Subscribes to the stream of messages.
 *
 * If type is provided, filters by only that message type.
 *
 * ```
 * --Message---Message--Message-
 * ```
 *
 * @function getMessageStream
 * @param {?MessageType} type
 * @return {Stream}
 */
export const getMessageStream = (type) => {
  if (DEBUG) log.debug("MESSAGE_SERVICE :: binding message type", type);
  if (!type) {
    return xs.merge(message$);
  }
  if (chatMessageTypes.has(type) || systemMessageTypes.has(type)) {
    return message$.filter((m) => m.type === type);
  }
  if (DEBUG) log.debug("MESSAGE_SERVICE :: unknown message type", type);
  return xs.create();
};

/**
 * Subscribes to the stream of system messages.
 *
 * If type is provided, filters by only that message type.
 *
 * ```
 * --Connect---Info-Info---Disconnect
 * ```
 *
 * @function getServerStream
 * @param {?MessageType} type
 * @return {Stream}
 */
export const getServerStream = (type) => {
  if (DEBUG) log.debug("MESSAGE_SERVICE :: binding system message type", type);
  if (!type) {
    return xs.merge(server$);
  }
  if (systemMessageTypes.has(type)) {
    return server$.filter((m) => m.type === type);
  }
  if (DEBUG) log.debug("MESSAGE_SERVICE :: unknown system message type", type);
  return xs.create();
};

/**
 * Sends a message to chat from a given user ID.
 *
 * Care should be taken not to use the wrong `from` ID here. Message
 * server *should* prevent shenanigans from unprivileged users, but
 * admins should only be presented this ability in very clear contexts.
 *
 * @function sendMessageFromUser
 * @param {UUID} from
 * @param {UUID} to
 * @param {string} text
 * @param {any[]} attachments
 * @param {string[]} attachmentFileNames
 * @param {boolean} superReply
 * @returns {Promise<void>}
 */
export const sendMessageFromUser = async (
  from,
  to,
  text,
  attachments = [],
  attachmentFileNames = [],
  superReply = false,
) => {
  const socket = await initSocket();
  socket.emit(types.CHAT_TEXT, makeChat({
    from,
    to,
    text,
    attachments,
    attachmentFileNames,
    superReply,
  }));
};

/**
 * Sends a system notification.
 *
 * @function sendNotification
 * @param {object} content
 * @param {UUID[]} content.to list of user / group IDs to dispatch
 * @param {string} content.title
 * @param {string} content.text
 * @returns {Promise<void>}
 */
export const sendNotification = async (content) => {
  const socket = await initSocket();
  socket.emit(types.ADMIN_NOTIFICATION, makeAdminNotification(content));
};

/**
 * Sends a message to chat from the user.
 *
 * @function sendMessageFromMe
 * @param {UUID} to
 * @param {string} text
 * @param {any[]} attachments
 * @param {string[]} attachmentFileNames
 * @param {boolean} superReply
 * @returns {Promise<void>}
 */
export const sendMessageFromMe = async (
  to,
  text,
  attachments = [],
  attachmentFileNames = [],
  superReply = false,
) => {
  const me = cache.getProfile();
  sendMessageFromUser(me.id, to, text, attachments, attachmentFileNames, superReply);
};

/**
 * Archive a message
 *
 * @function archiveMessage
 * @param {Object} message
 * @returns {Promise<void>}
 */
export const archiveMessage = async (message) => {
  const socket = await initSocket();
  socket.emit(types.ARCHIVE_MESSAGE, makeArchiveMessage({
    targetMessage: message,
  }));
};

/**
 * Report a message
 *
 * @function reportMessage
 * @param data
 * @param {reportReasons} data.reason
 * @param {object} data.message
 * @param {string} data.additionalInformation
 * @returns {Promise<void>}
 */
export const reportMessage = async (data) => {
  const socket = await initSocket();
  const me = cache.getProfile();
  const { additionalInformation, message: fullMessage, reason } = data;
  const { message } = fullMessage;
  socket.emit(types.REPORT, makeReport({
    messageId: message.id,
    from: me.id,
    reason,
    reportedMessage: message,
    reportType: reportTypes.MESSAGE,
    text: additionalInformation,
    target: message.id,
  }));
};

/**
 * Report a thread.
 *
 * @function reportThread
 * @param data
 * @param {reportReasons} data.reason
 * @param {string} data.additionalInformation
 * @param {UUID} data.target conversation id
 * @returns {Promise<void>}
 */
export const reportThread = async (data) => {
  const socket = await initSocket();
  const me = cache.getProfile();
  const { target, additionalInformation, reason } = data;
  socket.emit(types.REPORT, makeReport({
    from: me.id,
    reason,
    reportType: reportTypes.THREAD,
    text: additionalInformation,
    target,
  }));
};

/**
 * Request an updated list of channels.
 *
 * @function requestChannelList
 * @return {Promise<void>}
 */
export const requestChannelList = async () => {
  const socket = await initSocket();
  socket.emit(types.LIST_CHANNELS);
};

/**
 * Request message server stats.
 *
 * Only works for admins.
 *
 * @function requestChannelList
 * @return {Promise<void>}
 */
export const requestMessageServerStatus = async () => {
  const socket = await initSocket();
  socket.emit(types.SERVER_STATUS);
};

/**
 * Message API keeps track of whether authentication with MBE was successful inbox
 * a boolean.
 *
 * @function getAuthState
 * @returns {boolean} true if authenticated
 */
export const getAuthState = () => authState;

/**
 * Get inbox by user id
 *
 * @param {string} userId
 * @returns {Promise<Inbox>}
 */
export const getInboxByUserId = async (userId) => {
  const response = (await get(`${endpoints.MESSAGING_INBOX}/${userId}`)).body;
  return responseToInbox(response);
};

/**
 * Get conversation id by inboxes ids
 *
 * @param {string} firstInboxId
 * @param {string} secondInboxId
 * @returns {Promise<string>}
 */
export const getDirectConversationIdByInboxIds = async (firstInboxId, secondInboxId) => {
  const response = (await get(`${endpoints.MESSAGING_CONVERSATION}/${firstInboxId}/${secondInboxId}`)).body;
  return responseToConversationId(response);
};

/**
 * Get conversation by inboxes ids
 *
 * @param {string} firstInboxId
 * @param {string} secondInboxId
 * @returns {Promise<string>}
 */
export const getDirectConversationByInboxIds = async (firstInboxId, secondInboxId) => {
  const response = (await get(`${endpoints.MESSAGING_CONVERSATION}/${firstInboxId}/${secondInboxId}`)).body;
  return response;
};

/**
 * Get conversation id by group inbox id
 *
 * @param {string} groupInboxId
 * @returns {Promise<string>}
 */
export const getGroupConversationId = async (groupInboxId) => {
  const response = (await get(`${endpoints.MESSAGING_CONVERSATION}/group/${groupInboxId}`)).body;
  return responseToConversationId(response);
};

/**
 * Get conversation id by group inbox id
 *
 * @param {string} groupInboxId
 * @returns {Promise<string>}
 */
export const getGroupConversation = async (groupInboxId) => {
  const response = (await get(`${endpoints.MESSAGING_CONVERSATION}/group/${groupInboxId}`)).body;
  return response;
};

/**
 * Activate user inbox.
 * ACL: user calling this API should have OWNER/WRITE permissions
 *
 * @param {string} userId
 * @returns {Promise<Inbox>}
 */
export const activateUserInbox = async (userId) => {
  const response = (await post(`${endpoints.MESSAGING_INBOX}/active/${userId}`)).body;
  return responseToInbox(response);
};

/**
 * Block user inbox.
 * ACL: user calling this API should have OWNER/WRITE permissions
 *
 * @param {string} userId
 * @returns {Promise<Inbox>}
 */
export const blockUserInbox = async (userId) => {
  const response = (await post(`${endpoints.MESSAGING_INBOX}/block/${userId}`)).body;
  return responseToInbox(response);
};

/**
 * Open user inbox to everyone.
 * ACL: user calling this API should have OWNER/WRITE permissions
 *
 * @param {string} userId
 * @returns {Promise<Inbox>}
 */
export const openUserInbox = async (userId) => {
  const response = (await post(`${endpoints.MESSAGING_INBOX}/open/${userId}`)).body;
  return responseToInbox(response);
};

/**
 * Restrict user inbox.
 * ACL: user calling this API should have OWNER/WRITE permissions
 *
 * @param {string} userId
 * @returns {Promise<Inbox>}
 */
export const restrictUserInbox = async (userId) => {
  const response = (await post(`${endpoints.MESSAGING_INBOX}/restrict/${userId}`)).body;
  return responseToInbox(response);
};

/**
 * Search messages by params
 *
 * @param {string} conversationId
 * @param {SearchMessage} searchParams
 * @returns {Promise<object[]>}
 */
export const searchMessages = async (conversationId, searchParams = {}) => {
  const response = (await post(
    `${endpoints.MESSAGING_CONVERSATION}/${conversationId}/search`,
    null,
    makeSearchMessageDTO(searchParams),
  )).body;
  return response.map(fromArchive);
};

/**
 * Get direct conversation id by user ids
 *
 * @param {string} fromUserId
 * @param {string} toUserId
 * @returns {Promise<string|null>}
 */
export const getDirectConversationIdByUserIds = async (fromUserId, toUserId) => {
  try {
    const [inboxFrom, inboxTo] = await Promise.all([
      getInboxByUserId(fromUserId),
      getInboxByUserId(toUserId),
    ]);
    return getDirectConversationIdByInboxIds(inboxFrom.id, inboxTo.id);
  } catch (e) {
    log.error("failed to get conversation id", e);
    return null;
  }
};

/**
 * Get direct conversation by user ids
 *
 * @function getDirectConversationByUserIds
 * @param {string} fromUserId
 * @param {string} toUserId
 * @returns {Promise<string|null>}
 */
export const getDirectConversationByUserIds = async (fromUserId, toUserId) => {
  try {
    const [inboxFrom, inboxTo] = await Promise.all([
      getInboxByUserId(fromUserId),
      getInboxByUserId(toUserId),
    ]);
    return getDirectConversationByInboxIds(inboxFrom.id, inboxTo.id);
  } catch (e) {
    log.error("failed to get conversation id", e);
    return null;
  }
};

/**
 * Search messages by users ids and search params
 *
 * @param {string} fromUserId
 * @param {string} toUserId
 * @param {SearchMessage} searchParams
 * @returns {Promise<object[]>}
 */
export const searchMessagesWithUser = async (fromUserId, toUserId, searchParams = {}) => {
  try {
    const conversationId = await getDirectConversationIdByUserIds(fromUserId, toUserId);
    return searchMessages(conversationId, searchParams);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Search messages by group id and search params
 *
 * @param {string} groupId
 * @param {SearchMessage} searchParams
 * @returns {Promise<object[]>}
 */
export const searchGroupMessages = async (groupId, searchParams = {}) => {
  try {
    const conversationId = await getGroupConversationId(groupId);
    return searchMessages(conversationId, searchParams);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get unread messages by conversation id
 *
 * @param {string} conversationId
 * @returns {Promise<object[]>}
 */
export const getUnreadMessagesByConversationId = async (conversationId) => {
  try {
    const response = (await get(
      `${endpoints.MESSAGING_CONVERSATION}/${conversationId}/unread`,
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get latest direct messages
 *
 * @param {string} meId
 * @param {SearchMessage} searchParams
 * @returns {Promise<Map<string, object>>}
 */
export const getLatestDirectMessages = async (meId, searchParams) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO(searchParams),
    )).body;
    const messages = response.map(fromArchive);
    const senders = {};
    messages.forEach((m) => {
      if (m.to !== meId || m.superReply) return;
      if (m.from !== meId) {
        if (senders[m.from]) senders[m.from].push(m);
        else senders[m.from] = [m];
      }
    });
    return new Map(Object.entries(senders).map(([k, v]) => ([
      k,
      v.sort((a, b) => sortText(a.created, b.created)),
    ])));
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return new Map();
  }
};

/**
 * Get all unread messages
 *
 * @function getAllUnreadMessagesInChannels
 * @async
 * @returns {Promise<Message[]>}
 */
export const getAllUnreadMessagesInChannels = async () => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        params: {
          messageStatus: messageStatuses.UNREAD,
        },
        sortOrder: sortOrders.DESC,
        filters: [
          {
            name: "type",
            filterType: messageFilterType.EXACT_MATCH,
            value: types.CHAT_TEXT,
          },
        ],
      }),
    )).body;
    return response.map(fromArchive).filter((m) => !m.toGroup);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get messages sent by user
 *
 * @function getSentMessages
 * @param {UUID} userId
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getSentMessagesByUserId = async (userId, params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({ params: { userId }, ...params }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get messages received by user
 *
 * @function getRecevedMessagesByUserId
 * @param {UUID} userId
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getReceivedMessagesByUserId = async (userId, params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        params: {
          filters: [
            {
              name: "receiverInboxId",
              filterType: messageFilterType.EXACT_MATCH,
              value: userId,
            },
          ],
          ...params,
        },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get reports made by user.
 *
 * @function getReportsByUserId
 * @param {UUID} userId
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getReportsByUserId = async (userId, params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        params: {
          userId,
          filters: [
            {
              name: "type",
              filterType: messageFilterType.EXACT_MATCH,
              value: types.REPORT,
            },
          ],
          ...params,
        },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get reports made about a user.
 *
 * @function getReportsForUserId
 * @param {UUID} userId
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getReportsForUserId = async (userId, params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        params: {
          filters: [
            {
              name: "type",
              filterType: messageFilterType.EXACT_MATCH,
              value: types.REPORT,
            },
            {
              name: "attributes.reportedMessageFrom",
              filterType: messageFilterType.EXACT_MATCH,
              value: userId,
            },
          ],
          ...params,
        },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get all reports.
 *
 * @function getAllReports
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getAllReports = async (params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        ...params,
        sortOrder: sortOrders.DESC,
        params: { ...params.params, type: types.REPORT },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Get notifications for user.
 *
 * @function getNotificationsForUserId
 * @param {UUID} userId
 * @param {SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getNotificationsForUserId = async (toId, params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        params: {
          toId,
          type: types.ADMIN_NOTIFICATION,
          ...params,
        },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Marks a list of messages read.
 *
 * MBE determines userId by who dispatched the message, and BE will only update messages
 * sent to the dispatching user (i.e. whose userId === receiverInboxId).
 *
 * So it should be safe to just dump a list of messageIds in here, but take care to only
 * list ids of messages sent to the user in order to not waste resources.
 *
 * @function markMessagesRead
 * @param {UUID[]} messageIds
 * @returns {Promise<void>}
 */
export const markMessagesRead = async (messageIds) => {
  const socket = await initSocket();
  socket.emit(
    types.UPDATE_READ,
    makeUpdateRead({
      messageIds,
    }),
  );
};

/**
 * Get all messages
 *
 * @param {?SearchMessage} params
 * @returns {Promise<Message[]>}
 */
export const getAllMessages = async (params = {}) => {
  try {
    const response = (await post(
      `${endpoints.MESSAGING}/search`,
      null,
      makeSearchMessageDTO({
        ...params,
        sortOrder: sortOrders.DESC,
        params: { ...params.params, type: types.CHAT_TEXT },
      }),
    )).body;
    return response.map(fromArchive);
  } catch (e) {
    log.error("failed to retrieve messages", e);
    return [];
  }
};

/**
 * Request to listen to a DM thread as a moderator.
 *
 * @function requestModeratorSpy
 * @returns {Promise<void>}
 */
export const requestModeratorSpy = async (from, to) => {
  const socket = await initSocket();
  socket.emit(types.MODERATOR_SPY, { content: { from, to } });
};

/**
 * Dispatch a moderator action.
 *
 * @function sendModerationAction
 * @param {object} content
 * @param {ModeratorActionType} content.action
 * @param {UUID} target object being acted upon
 * @return {Promise<boolean>} true if initial validation passed
 */
export const sendModerationAction = async ({ action, target }) => {
  const me = cache.getProfile();
  if (!Object.values(moderatorActionTypes).includes(action)) {
    log.error("unknown moderator action", action);
    return false;
  }
  const socket = await initSocket();
  socket.emit(types.MODERATOR_ACTION, makeModeratorAction({ from: me.id, action, target }));
  return true;
};

/**
 * Dispatch a request to suspend a user account.
 *
 * @function sendSuspendUser
 * @param {UUID} target userId to suspend
 * @return {Promise<boolean>} true if send was successful
 */
export const sendSuspendUser = async (target) => sendModerationAction({
  action: moderatorActionTypes.USER_SUSPEND,
  target,
});

/**
 * Dispatch a request to restore chat privileges to a user account.
 *
 * @function sendRestoreUser
 * @param {UUID} target userId to suspend
 * @return {Promise<boolean>} true if send was successful
 */
export const sendRestoreUser = async (target) => sendModerationAction({
  action: moderatorActionTypes.USER_RESTORE,
  target,
});

/**
 * Dispatch a block user request.
 *
 * @function sendBlockUser
 * @param {UUID} target user id to block
 * @returns {Promise<void>}
 */
export const sendBlockUser = async (target) => {
  const me = cache.getProfile();
  const socket = await initSocket();
  socket.emit(types.USER_BLOCK, makeUserBlock({ from: me.id, target }));
};

/**
 * Dispatch an unblock user request.
 *
 * @function sendUnblockUser
 * @param {UUID} target user id to unblock
 * @returns {Promise<void>}
 */
export const sendUnblockUser = async (target) => {
  const me = cache.getProfile();
  const socket = await initSocket();
  socket.emit(types.USER_UNBLOCK, makeUserUnblock({ from: me.id, target }));
};

/**
 * Dispatch an add friend request.
 *
 * @function sendAddFriend
 * @param {UUID} target user id to add to DM list
 * @returns {Promise<void>}
 */
export const sendAddFriend = async (target) => {
  const me = cache.getProfile();
  const socket = await initSocket();
  socket.emit(types.USER_FRIEND_ADD, makeFriendAdd({ from: me.id, target }));
};

/**
 * Dispatch a remove friend request.
 *
 * @function sendRemoveFriend
 * @param {UUID} target user id to remove from DM list
 * @returns {Promise<void>}
 */
export const sendRemoveFriend = async (target) => {
  const me = cache.getProfile();
  const socket = await initSocket();
  socket.emit(types.USER_FRIEND_REMOVE, makeFriendRemove({ from: me.id, target }));
};

/**
 * Dispatch a request to close a group thread.
 *
 * @function sendCloseGroupThread
 * @param {UUID} target groupId to close
 * @return {Promise<boolean>} true if send was successful
 */
export const sendCloseGroupThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.GROUP_THREAD_CLOSE,
  target,
});

/**
 * Dispatch a request to open a group thread.
 *
 * @function sendOpenGroupThread
 * @param {UUID} target groupId to open
 * @return {Promise<boolean>} true if send was successful
 */
export const sendOpenGroupThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.GROUP_THREAD_OPEN,
  target,
});

/**
 * Dispatch a request to remove a group thread.
 *
 * @function sendRemoveGroupThread
 * @param {UUID} target groupId to remove
 * @return {Promise<boolean>} true if send was successful
 */
export const sendRemoveGroupThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.GROUP_THREAD_REMOVE,
  target,
});

/**
 * Dispatch a request to restore a group thread.
 *
 * @function sendRestoreGroupThread
 * @param {UUID} target groupId to restore
 * @return {Promise<boolean>} true if send was successful
 */
export const sendRestoreGroupThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.GROUP_THREAD_RESTORE,
  target,
});

/**
 * Dispatch a request to close a direct message thread.
 *
 * @function sendCloseDMThread
 * @param {UUID} target conversationId to close
 * @return {Promise<boolean>} true if send was successful
 */
export const sendCloseDMThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.DM_THREAD_CLOSE,
  target,
});

/**
 * Dispatch a request to open a direct message thread.
 *
 * @function sendOpenDMThread
 * @param {UUID} target conversationId to open
 * @return {Promise<boolean>} true if send was successful
 */
export const sendOpenDMThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.DM_THREAD_OPEN,
  target,
});

/**
 * Dispatch a request to remove a direct message thread.
 *
 * @function sendRemoveDMThread
 * @param {UUID} target conversationId to remove
 * @return {Promise<boolean>} true if send was successful
 */
export const sendRemoveDMThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.DM_THREAD_REMOVE,
  target,
});

/**
 * Dispatch a request to restore a direct message thread.
 *
 * @function sendRestoreDMThread
 * @param {UUID} target conversationId to restore
 * @return {Promise<boolean>} true if send was successful
 */
export const sendRestoreDMThread = async (target) => sendModerationAction({
  action: moderatorActionTypes.DM_THREAD_RESTORE,
  target,
});

/**
 * Dispatch a cache update message.
 *
 * @function sendCacheUpdate
 * @param {CacheUpdateType} updateType
 * @param {?UUID} objectId
 */
export const sendCacheUpdate = async (updateType, objectId = null) => {
  const me = cache.getProfile();
  if (!Object.values(cacheUpdateTypes).includes(updateType)) {
    log.error("unknown cache entry type", updateType);
    return false;
  }
  const socket = await initSocket();
  socket.emit(types.CACHE_UPDATE, makeCacheUpdate({ from: me.id, objectId, updateType }));
  return true;
};

/**
 * Get MBE service info.
 *
 * @function getInfo
 * @return {Promise.<Object>}
 */
export const info = async () => (
  await get.service(services.MBE)(endpoints.MBE_INFO)
).body;

/**
 * Run a callback after first authentication success on a new connection.
 *
 * @function onAuthenticatedConnect
 * @param {function} callback
 * @return {Promise}
 */
export const onAuthenticatedConnect = async (cb) => {
  if (DEBUG) log.debug("MESSAGE_SERVICE: initializing authenticated connect callback");
  const socket = await awaitConnection();
  const auth$ = getServerStream(types.AUTH_RESPONSE);
  let isSubscribed = false;
  const authListener = {
    next: () => {
      if (authState === true) {
        if (DEBUG) log.debug("MESSAGE_SERVICE: running one-time auth connect callback");
        auth$.removeListener(authListener);
        isSubscribed = false;
        cb();
      }
    },
  };
  const connectListener = () => {
    if (!isSubscribed) {
      if (DEBUG) log.debug("MESSAGE_SERVICE: new connection, renewing auth subscription.");
      auth$.addListener(authListener);
      isSubscribed = true;
    }
  };
  const disconnectListener = () => {
    if (isSubscribed) {
      if (DEBUG) log.debug("MESSAGE_SERVICE: disconnection, removing auth subscription.");
      auth$.removeListener(authListener);
      isSubscribed = false;
    }
  };
  socket.on(types.CONNECT, connectListener);
  socket.on(types.DISCONNECT, disconnectListener);
  if (authState === true) cb();
};
