/**
 * Endpoints for the courses.
 *
 * - LIST_FNS - search for / retrieve lists of course objects
 * - EXPAND_FNS - fetches children of objects in course hierarchy
 * - GET_FNS - fetching items by id
 *
 * @module api/course
 * @category Backend API
 * @subcategory Course
 * @deprecated use the v2 apis when possible
 */
/***/
import deepequal from "deep-equal";
import md5 from "md5";
import { getDocument, getVideo, list as metadataList } from "api/metadata";
import { endpoints } from "api/constants";
import { getById as getUserById, list as userList } from "api/user";
import { del, get, post, put } from "api/request";
import cache from "cache";
import { CACHE_COURSE_KEY_V2 } from "cache/constants";
import log from "log";
import {
  makeAssessmentDTO,
  makeCourseDTO,
  makeModuleDTO,
  responseToAbbreviatedCourseAttendance,
  responseToAbbreviatedEvaluation,
  responseToAssessment,
  responseToCourse,
  responseToCourseModule,
  responseToEvaluationTime,
} from "model/course";
import {
  makeEvaluationDTO,
} from "model/course/evaluation";
import { APIResponseError } from "model/error";
import { makeLectureDTO, responseToLecture } from "model/lecture";
import { difference, filterMap, mapFilter } from "util/array";
import { merge } from "util/object";
import { responseToPageable } from "model/pageable";
import {
  CACHE_COURSE_KEY,
  CACHE_COURSE_KEY_SEARCH,
  cacheAssessments,
  cacheLectures,
  cacheModules,
  deleteCachedAssessments,
  deleteCachedCourse,
  deleteCachedModules,
  expireAllSearches,
  getCachedAssessments,
  getCachedLectures,
  getCachedModules,
  makeEvaluationKey,
  populateInstructors,
} from "./cache";
import { createGradingScheme, getGradingSchemeById, updateGradingScheme } from "./grading-scheme";
import {
  EXPAND_ALL,
  EXPAND_ASSESSMENTS,
  EXPAND_COURSES,
  EXPAND_MODULES,
  EXPAND_NONE,
} from "./constants";
import { findAssessmentInCourse, findQuestionInAssessment } from "./util";

/// enables debug logging - very verbose but helpful in debugging complex restore/expand
//  processes, so the debug logging is guarded instead of just being removed
const DEBUG = false;

/**
 * Get all courses.
 *
 * @function listCourses
 * @param {ExpandOptions} [expand=EXPAND_MODULES]
 * @returns {Promise<Course[]>}
 */
/* eslint-disable-next-line no-use-before-define */
export const listCourses = async (expand = EXPAND_MODULES) => searchCourses({}, expand);

/**
 * Get all assessments
 *
 * @function listAssessments
 * @returns {Promise<Assessment[]>}
 */
export const listAssessments = async () => {
  const courses = await listCourses(EXPAND_ASSESSMENTS);
  const assessments = [];
  courses.forEach((course) => course.modules.forEach(
    (module) => assessments.push(module.assessments),
  ));
  return assessments.flat();
};

/* EXPAND_FNS - fetches children of objects in course hierarchy */

/**
 * Lectures are cached with only their item's ids in a list, and the items themselves
 * are stored in separate metadata caches. This restores the item's metadata entries.
 *
 * Returns null if any of the entries were not found (this should not happen).
 *
 * @function expandLecture
 * @param {object} cachedLecture a partially restored lecture
 * @param {UUID} courseId
 * @param {UUID} moduleId
 * @param {ExpandOptions} [expand=EXPAND_ALL]
 * @return {?Promise<Lecture>} the fully restored lecture item
 */
export const expandLecture = async (
  cachedLecture,
  courseId,
  moduleId,
  expand = EXPAND_ALL,
) => {
  if (DEBUG) log.debug("COURSE_API :: expanding lecture", cachedLecture, expand);
  return responseToLecture({
    id: cachedLecture.id,
    title: cachedLecture.title,
    description: cachedLecture.description,
    videoItem: cachedLecture.videoItemId && expand.metadata
      ? (await getVideo(cachedLecture.videoItemId, false, false))
      : null,
    poster: cachedLecture.posterId && expand.metadata
      ? (await getDocument(cachedLecture.posterId))
      : null,
    slides: cachedLecture.slidesId && expand.metadata
      ? (await getDocument(cachedLecture.slidesId))
      : null,
    // FIXME this is probably broken by refactor and needs to be updated
    additionalMaterial: expand.metadata
      ? (await Promise.all(
        (cachedLecture.additionalMaterialIds?.map(
          (id) => getDocument(id).catch(() => null),
        ) || [])
          .filter((i) => i !== null),
      ))
      : [],
    instructorId: cachedLecture.instructorId,
    courseId,
    moduleId,
  });
};

/**
 * Populates evaluation children.
 *
 * @function expandEvaluation
 * @private
 * @param {string} courseId
 * @param {AbbreviatedEvaluation} aEval
 * @param {?object} [expand]
 * @returns {Promise<Evaluation>}
 */
const expandEvaluation = async (courseId, aEval, expand = EXPAND_ALL) => {
  if (DEBUG) log.debug("COURSE_API :: expanding evaluation", expand);
  // we definitely need assessments so tag that in
  const courseExpand = merge(expand, EXPAND_ASSESSMENTS);
  /* eslint-disable no-use-before-define */
  const [course, student] = await Promise.all([
    getCourseById(courseId, false, courseExpand),
    getAttendingUser(aEval.studentId),
  ]);
  const assessment = findAssessmentInCourse(course, aEval.assessmentId);
  return {
    id: aEval.id,
    assessment,
    student,
    solutions: aEval.solutions?.map((solution, index) => ({
      ...solution,
      id: solution.id,
      question: findQuestionInAssessment(assessment, solution, index),
      value: solution.value,
      score: solution.score,
    })) || [],
    publishTime: aEval.publishTime ? new Date(aEval.publishTime) : null,
    status: aEval.status,
    score: aEval.score,
    course,
  };
  /* eslint-enable no-use-before-define */
};

/**
 * Gets a user attending a course or evaluation, which may be the current user.
 *
 * This is necessary because normal users can't use getUserById, and are usually
 * referring to themselves.
 *
 * @param {UUID} userId
 * @return {?User}
 */
const getAttendingUser = async (userId) => {
  const me = cache.getProfile();
  if (me.id === userId) return me;
  try {
    return (await getUserById(userId));
  } catch (e) {
    return null;
  }
};

/**
 * Populates attendance item children.
 *
 * @function expandAttendance
 * @private
 * @param {string} courseId
 * @param {AbbreviatedCourseAttendance} abbreviatedAttendance
 * @param {object} [expand=EXPAND_ALL]
 * @returns Promise<CourseAttendance>
 */
const expandAttendance = async (courseId, abbreviatedAttendance, expand = EXPAND_ALL) => {
  const [course, student, ...evaluations] = await Promise.all([
    /* eslint-disable-next-line no-use-before-define */
    getCourseById(courseId, false, expand),
    expand.students
      ? getAttendingUser(abbreviatedAttendance.studentId)
      : abbreviatedAttendance.studentId,
    ...(expand.evaluations
      ? abbreviatedAttendance.evaluationIds
        /* eslint-disable-next-line no-use-before-define */
        .map((evaluationId) => getEvaluationById(courseId, evaluationId, expand)
          .catch(() => ({ id: evaluationId, title: "Unavailable" })))
      : abbreviatedAttendance.evaluationIds
    ),
  ]);
  return {
    id: abbreviatedAttendance.id,
    course,
    student,
    creationDate: abbreviatedAttendance.creationDateTime,
    expirationDate: abbreviatedAttendance.expirationDateTime,
    evaluations,
    grade: abbreviatedAttendance.grade,
  };
};

/**
 * Fetch the full bodies of all child DTOs for a module.
 *
 * @function expandModule
 * @private
 * @param {CourseModuleDTO} moduleData
 * @param {UUID} courseId
 * @param {boolean} [skipCache=false]
 * @param {ExpandOptions} [expand=EXPAND_ALL]
 * @returns {Promise<CourseModule>}
 */
const expandModule = async (moduleData, courseId, skipCache = false, expand = EXPAND_ALL) => {
  /* eslint-disable no-use-before-define */
  if (DEBUG) log.debug("COURSE_API :: expanding module", moduleData, expand);
  const lectures = moduleData.lectures?.length && expand.lectures
    ? await getLecturesByIds(
      courseId,
      moduleData.id,
      moduleData.lectures,
      skipCache,
      expand,
    )
    : moduleData.lectures;
  const assessments = moduleData.assessments?.length && expand.assessments
    ? await getAssessmentsByIds(
      courseId,
      moduleData.id,
      moduleData.assessments,
      skipCache,
    )
    : moduleData.assessments;
  const module = {
    ...moduleData,
    lectures,
    assessments,
  };
  return module;
  /* eslint-enable no-use-before-define */
};

/**
 * Fetch the full bodies of all child DTOs of a course DTO.
 *
 * @function expandCourse
 * @private
 * @param {CourseDTO} courseData
 * @param {boolean} [skipCache=false]
 * @param {ExpandOptions} [expand=EXPAND_ALL] which child objects to fully populate
 * @return {Promise<Course>}
 */
const expandCourse = async (courseData, skipCache = false, expand = EXPAND_ALL) => {
  /* eslint-disable no-use-before-define */
  if (DEBUG) log.debug("COURSE_API :: expandCourse : in", courseData, expand);
  const modules = courseData.moduleItemDtos && expand.modules
    ? await getModulesByIds(courseData.id, courseData.moduleItemDtos, skipCache, expand)
    : courseData.moduleItemDtos;
  const gradingScheme = courseData.gradingDto && expand.grading
    ? await getGradingSchemeById(courseData.gradingDto)
    : courseData.gradingDto;
  const course = {
    ...courseData,
    modules,
    gradingScheme,
  };
  if (expand.instructors) await populateInstructors([course]);
  return responseToCourse(course);
  /* eslint-enable no-use-before-define */
};

/**
 * Like expandCourse, but handles instructors across all courses instead of one at a time.
 *
 * This is done for performance reasons.
 *
 * @function expandCourses
 * @private
 * @param {CourseDTO[]}
 * @param {boolean} [skipCache=false]
 * @param {ExpandOptions} expand
 * @return {Promise<Course[]>}
 */
const expandCourses = async (coursesData, skipCache = false, expand = EXPAND_ALL) => {
  /* eslint-disable no-use-before-define */
  if (DEBUG) log.debug("COURSE_API :: expanding courses", coursesData, expand);
  const courses = await Promise.all(coursesData.map(async (courseData) => {
    const modules = courseData.moduleItemDtos && expand.modules
      ? await getModulesByIds(courseData.id, courseData.moduleItemDtos, skipCache, expand)
      : (courseData.moduleItemDtos || []);
    const gradingScheme = courseData.gradingDto && expand.grading
      ? await getGradingSchemeById(courseData.gradingDto)
      : courseData.gradingDto;
    const course = {
      ...courseData,
      modules,
      gradingScheme,
    };
    return course;
  }));
  if (expand.instructors) await populateInstructors(courses);
  return courses.map(responseToCourse);
  /* eslint-enable no-use-before-define */
};

/* GET_FNS - fetching items by id */

/**
 * Get course by id.
 *
 * @function getCourseById
 * @param {string} id
 * @param {boolean} skipCache
 * @param {ExpandOptions} expand which child objects to expand
 * @returns {Promise<Course>}
 */
export const getCourseById = async (id, skipCache = false, expand = EXPAND_ALL) => {
  if (DEBUG) log.debug("COURSE_API :: getCourseById", id, skipCache, expand);
  if (!skipCache) {
    return expandCourse(
      await cache.getOrRefreshExpiringMapEntry(
        CACHE_COURSE_KEY,
        id,
        async () => cache.storeExpiringMapEntry(
          CACHE_COURSE_KEY,
          id,
          (await get(endpoints.COURSE(id))).body,
        ),
      ),
      skipCache,
      expand,
    );
  }

  return expandCourse(
    cache.storeExpiringMapEntry(
      CACHE_COURSE_KEY,
      id,
      (await get(endpoints.COURSE(id))).body,
    ),
    skipCache,
    expand,
  );
};

/**
 * Create course by id
 *
 * @function create
 * @param {Object} partial
 * @returns {Promise<Course>}
 */
export const create = async (partial) => {
  let gradingScheme = null;
  if (partial.gradingScheme) {
    gradingScheme = await createGradingScheme(partial.gradingScheme);
  }
  const prepared = merge(partial, { gradingScheme });
  const response = (await post(
    endpoints.COURSES,
    null,
    makeCourseDTO(prepared),
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(CACHE_COURSE_KEY, response.id, response);
  const course = await expandCourse(response);
  if (DEBUG) log.debug("COURSE_API :: initialized course", course);

  // now create modules and lectures, then append them
  // eslint-disable-next-line no-restricted-syntax
  for (const module of partial.modules) {
    /* eslint-disable-next-line no-use-before-define, no-await-in-loop */
    course.modules.push(await createModule(course.id, module));
    /* eslint-enable no-use-before-define, no-await-in-loop */
  }
  if (DEBUG) log.debug("COURSE_API :: saved new course modules", course);

  return course;
};

/**
 * Handles CRUD for child objects of a course when saving a course.
 *
 * @function processCourseChildren
 * @private
 * @param {Course} course
 * @return {Promise<Course>} copy with updated child objects
 */
const processCourseChildren = async (course) => {
  const modules = [];
  if (DEBUG) log.debug("COURSE_API :: processCourseChildren : modules", course.modules.map((m) => ({ title: m.title, id: m.id })));
  /* eslint-disable-next-line no-use-before-define */
  const currentModules = new Map((await getModulesByIds(
    course.id,
    course.modules.map((m) => m.id),
    true,
    EXPAND_NONE,
  )).reduce(filterMap((m) => (!!m), (m) => ([m.id, m])), []));

  if (DEBUG) {
    log.debug(
      "COURSE_API :: processCourseChildren : currentModules",
      currentModules,
    );
  }
  // process each module in the new course, creating new modules where needed, updating
  // lectures in existing modules. The output should have the modules all created or
  // updated with the sort order intact.
  // eslint-disable-next-line no-restricted-syntax
  for (const module of course.modules) {
    /* eslint-disable no-use-before-define, no-await-in-loop */
    if (!module.id) {
      modules.push(await createModule(course.id, module));
    } else {
      modules.push(
        await updateModule(course.id, module, currentModules.get(module.id)),
      );
    }
    /* eslint-enable no-use-before-define, no-await-in-loop */
  }

  if (modules.length && DEBUG) log.debug("COURSE_API :: processCourseChildren : created/updated modules", modules);

  const gradingScheme = (course.gradingScheme?.id)
    ? await updateGradingScheme(course.gradingScheme)
    : await createGradingScheme(course.gradingScheme);

  return merge(course, { modules, gradingScheme });
};

/**
 * Update course by id
 *
 * @function update
 * @param {Course} partial
 * @returns {Promise<Course>}
 */
export const update = async (partial) => {
  const processed = await processCourseChildren(partial);
  if (DEBUG) log.debug("COURSE_API :: update : after processing", processed);
  const dto = makeCourseDTO(processed);
  if (DEBUG) log.debug("COURSE_API :: update : dto", dto);
  const response = (await put(
    endpoints.COURSE(partial.id),
    null,
    dto,
  )).body;
  expireAllSearches();
  deleteCachedCourse(partial.id);
  if (DEBUG) log.debug("COURSE_API :: update : response", response);
  return expandCourse(
    cache.storeExpiringMapEntry(CACHE_COURSE_KEY, response.id, response),
  );
};

/**
 * Delete course by id
 *
 * @function deleteById
 * @param {string} id
 * @return boolean indicating success
 */
export const deleteById = async (id) => {
  const response = await del(endpoints.COURSE(id));
  if (response.ok) {
    expireAllSearches();
    cache.storeExpiringMapEntry(CACHE_COURSE_KEY, id, null);
  }
  return response.ok;
};

/**
 * Get a time left for the evaluation
 *
 * @function getTimeLeftForEvaluation
 * @param {string} courseId
 * @param {string} evaluationId
 * @returns {Promise<EvaluationTime>}
 */
export const getTimeLeftForEvaluation = async (courseId, evaluationId) => {
  const response = (await get(`${endpoints.COURSE(courseId)}/eval/${evaluationId}/time`)).body;
  return responseToEvaluationTime(response);
};

/**
 * Get evaluations page
 *
 * @param params
 * @param {number} params.pageNo
 * @param {string} params.title
 * @param {string} params.fullName
 * @param {string} params.status
 * @param {Date} params.startDate
 * @param {Date} params.endDate
 * @param {string} params.search
 * @param {string} params.sortBy
 * @param {string} params.assessmentId
 * @param {string} params.userId
 * @param {ExpandOptions} [expand]
 * @returns {Promise<Pageable<Evaluation>>}
 */
export const getEvaluations = async (params, expand = EXPAND_ALL) => {
  const evaluationsResponse = (await get(endpoints.EVALUATION, params)).body;
  const evaluationsPage = responseToPageable(evaluationsResponse, "evals");
  return {
    ...evaluationsPage,
    items: await Promise.all(
      evaluationsPage.items.map((item) => expandEvaluation(
        item.courseId,
        responseToAbbreviatedEvaluation(item),
        expand,
      )),
    ),
  };
};

/**
 * Get evaluation by id
 *
 * @function getEvaluationById
 * @param {string} courseId
 * @param {string} evaluationId
 * @param {?object} [expand]
 * @returns {Promise<Evaluation>}
 */
export const getEvaluationById = async (courseId, evaluationId, expand = EXPAND_ALL) => {
  const assessmentResponse = (
    await get(`${endpoints.COURSE(courseId)}/eval/${evaluationId}`)
  ).body;
  return expandEvaluation(
    courseId,
    responseToAbbreviatedEvaluation(assessmentResponse),
    expand,
  );
};

/**
 * Looks up a user's evaluation related to the given assessment id.
 * @function getEvaluationByAssessmentId
 * @param {UUID} courseId
 * @param {UUID} userId
 * @param {UUID} assessmentId
 * @param {ExpandOptions} [expand=EXPAND_ALL]
 */
export const getEvaluationByAssessmentId = async (
  courseId,
  userId,
  assessmentId,
  expand = EXPAND_ALL,
) => {
  const cached = cache.getObject(makeEvaluationKey(assessmentId));
  if (cached) return cached;
  /* eslint-disable-next-line no-use-before-define */
  const attendance = await getCourseAttendanceByUserId(courseId, userId, expand);
  if (attendance) {
    const evaluation = await Promise.any(attendance.evaluations.map(
      (evaluationId) => new Promise((resolve, reject) => {
        getEvaluationById(courseId, evaluationId, expand)
          .then((ev) => {
            if (ev.assessmentId === assessmentId) resolve(ev);
            else reject();
          })
          .catch(() => reject());
      }),
    ));
    if (evaluation) {
      return expandEvaluation(courseId, evaluation, expand);
    }
  }
  return null;
};

/**
 * Create and initialize a student evaluation.
 *
 * @function createEvaluation
 * @param {UUID} courseId
 * @param {UUID} attendanceId
 * @param {UUID} assessmentId
 * @param {UUID} studentId
 * @param {Evaluation} partial
 * @return {Evaluation}
 */
export const createEvaluation = async (courseId, attendanceId, assessment, student) => {
  const response = (await post(
    `${endpoints.COURSE(courseId)}/attendance/${attendanceId}/assessment/${assessment.id}/eval`,
    null,
    makeEvaluationDTO({
      studentId: student.id,
      assessmentId: assessment.id,
    }),
  )).body;
  return expandEvaluation(
    courseId,
    responseToAbbreviatedEvaluation(response),
  );
};

/**
 * Update evaluation
 *
 * @function updateEvaluation
 * @param {string} courseId
 * @param {Partial<Evaluation>} partial
 * @param {boolean} omitSolutionValues
 * @returns {Promise<Evaluation>}
 */
export const updateEvaluation = async (courseId, partial, omitSolutionValues = false) => {
  const assessmentResponse = (await put(
    `${endpoints.COURSE(courseId)}/assessment/${partial.assessment.id}/eval/${partial.id}`,
    null,
    makeEvaluationDTO(partial, omitSolutionValues),
  )).body;
  return expandEvaluation(
    courseId,
    responseToAbbreviatedEvaluation(assessmentResponse),
  );
};

/**
 * Get user course attendance
 *
 * @function getCourseAttendanceByUserId
 * @param {string} courseId
 * @param {string} userId
 * @param {} [expand=EXPAND_ALL]
 * @returns {Promise<CourseAttendance>}
 */
export const getCourseAttendanceByUserId = async (courseId, userId, expand = EXPAND_ALL) => {
  const abbreviatedAttendanceResponse = (
    await get(`${endpoints.COURSE(courseId)}/attendance/${userId}`)
  ).body;
  return expandAttendance(
    courseId,
    responseToAbbreviatedCourseAttendance(abbreviatedAttendanceResponse),
    expand,
  );
};

/**
 * Enroll user to course
 *
 * @function enrollUserToCourse
 * @param {string} courseId
 * @param {string} userId
 * @returns {Promise<CourseAttendance>}
 */
export const enrollUserToCourse = async (courseId, userId) => {
  const abbreviatedAttendanceResponse = (
    await post(`${endpoints.COURSE(courseId)}/enroll/${userId}`)
  ).body;
  return expandAttendance(
    courseId,
    responseToAbbreviatedCourseAttendance(abbreviatedAttendanceResponse),
  );
};

/**
 * A collection of parent ids belonging to an assessment.
 *
 * @typedef AssessmentParents
 * @property {UUID} assessmentId
 * @property {UUID} courseId
 * @property {UUID} parentId
 */

/**
 * Finds an assessment's course and module id given only the assessment id.
 *
 * This can be pretty fast if the assessment has already been cached, or very slow
 * if there is no cache data for courses, modules, or assessments.
 *
 * Returns null if the given assessment ID no longer exists.
 *
 * @function findAssessmentParents
 * @param {UUID} assessmentId
 * @returns {Promise<AssessmentParents>}
 */
export const findAssessmentParents = async (assessmentId, skipCache = false) => {
  if (!skipCache) {
    const cached = getCachedAssessments([assessmentId]);
    if (cached?.[0]?.id && cached?.[0]?.courseId && cached?.[0]?.moduleId) {
      return ({
        assessmentId: cached[0].id,
        moduleId: cached[0].moduleId,
        courseId: cached[0].courseId,
      });
    }
  }

  const courses = await listCourses(EXPAND_MODULES);
  let moduleId, courseId;
  if (DEBUG) log.debug("COURSE_API :: finding assessment parents", assessmentId, courses);
  const found = courses.find((course) => course.modules.find((module) => {
    if (DEBUG) log.debug("COURSE_API :: checking course module", course.id, module);
    if (module.assessments.includes(assessmentId)) {
      moduleId = module.id;
      courseId = course.id;
      return true;
    }
    return false;
  }));
  if (DEBUG) log.debug("COURSE_API :: found assessment parents", courseId, moduleId);

  if (found && assessmentId && courseId && moduleId) {
    return ({
      assessmentId,
      courseId,
      moduleId,
    });
  }
  return null;
};

/**
 * Get assessment by id
 *
 * @function getAssessmentById
 * @param {UUID} assessmentId
 * @param {boolean} skipCache
 * @returns {Promise<Assessment>}
 */
export const getAssessmentById = async (assessmentId, skipCache = false) => {
  if (!skipCache) {
    const cached = getCachedAssessments([assessmentId]);
    if (cached?.[0]) return responseToAssessment(cached[0]);
  }
  try {
    // even if we want a fresh result we can let getAssessmentParents rely on cache
    // since assessments shouldn't change parents (?)
    const parents = await findAssessmentParents(assessmentId);

    if (parents?.courseId && parents?.moduleId) {
      const { courseId, moduleId } = parents;
      const result = (await get(
        `${endpoints.COURSE(courseId)}/module/${moduleId}/assessment/?aid=${assessmentId}`,
      )).body[0];

      if (result) {
        const assessment = {
          ...result,
          courseId,
          moduleId,
        };

        cacheAssessments([assessment]);
        return responseToAssessment(assessment);
      }
    }
  } catch (e) {
    // skip
  }

  // simulate a 404
  throw new APIResponseError({
    body: "Assessment not found",
    status: 404,
  });
};

/**
 * Get assessments by ids list
 *
 * @function getAssessmentsByIds
 * @param {UUID} courseId
 * @param {UUID} moduleId
 * @param {UUID[]} assessmentIds
 * @param {boolean} skipCache
 * @returns {Promise<Assessment[]>}
 */
export const getAssessmentsByIds = async (courseId, moduleId, assessmentIds, skipCache = false) => {
  const expand = (assessment) => responseToAssessment(assessment);

  if (!skipCache) {
    const cached = getCachedAssessments(assessmentIds);
    if (cached) {
      // verify that everything is in there first...
      const delta = difference(assessmentIds, cached.map((a) => a.id));
      if (delta?.length === 0) return cached.map(expand);
    }
  }

  try {
    const response = (await get(
      `${endpoints.COURSE(courseId)}/module/${moduleId}/assessment/?aid=${assessmentIds}`,
    )).body;

    // FIXME this fixes ordering of items because BE does not necessarily return them in
    //       in the same order they were requested. Can remove this if/when backend
    //       changes (BUT: keep the merged assessments)

    const assessMap = new Map(response.map(
      (assess) => [assess.id, merge(assess, { courseId, moduleId })],
    ));

    const assessments = assessmentIds.reduce(mapFilter((id) => assessMap.get(id), (a) => !!a), []);

    cacheAssessments(assessments);
    return assessments.map(expand);
  } catch (e) {
    log.error("failed to load assessments", e);
    return [];
  }
};

/**
 * Get all assessments in a single course.
 *
 * @function getAssessmentsByCourseId
 * @param {UUID} courseId
 * @returns {Promise<Assessment[]>}
 */
export const getAssessmentsByCourseId = async (courseId) => {
  const course = await getCourseById(courseId, true, EXPAND_MODULES);
  if (DEBUG) log.debug("COURSE_API :: getAssessmentsByCourseId: getByCourseId", courseId, course);
  const assessmentGroups = await Promise.all(
    course.modules.map((module) => (
      module?.assessments?.length
        ? getAssessmentsByIds(courseId, module.id, module.assessments)
        : []
    )),
  );
  if (DEBUG) log.debug("COURSE_API :: getAssessmentsByCourseId: found groups", assessmentGroups);
  return assessmentGroups.flat();
};

/**
 * Create a new assessment
 *
 * @function createAssessment
 * @param {string} courseId
 * @param {string} moduleId
 * @param {Assessment} partial
 * @returns {Promise<Assessment>}
 */
export const createAssessment = async (courseId, moduleId, partial) => {
  const assessment = (await post(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/assessment`,
    null,
    makeAssessmentDTO(partial),
  )).body;
  expireAllSearches();
  const withParents = {
    ...assessment,
    courseId,
    moduleId,
  };
  cacheAssessments([withParents]);
  deleteCachedModules([moduleId]);
  deleteCachedCourse(courseId);
  return responseToAssessment(withParents);
};

/**
 * Update an assessment
 *
 * @function updateAssessment
 * @param {string} courseId
 * @param {string} moduleId
 * @param {Assessment} partial
 * @returns {Promise<Assessment>}
 */
export const updateAssessment = async (courseId, moduleId, partial) => {
  const assessment = (await put(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/assessment/${partial.id}`,
    null,
    makeAssessmentDTO(partial),
  )).body;
  expireAllSearches();
  const withParents = {
    ...assessment,
    courseId,
    moduleId,
  };
  const oldAssessment = getCachedAssessments([partial.id]);
  if (oldAssessment.moduleId && oldAssessment.moduleId !== moduleId) {
    deleteCachedModules([oldAssessment.moduleId]);
  }
  deleteCachedAssessments([partial.id]);
  cacheAssessments([withParents]);
  return responseToAssessment(withParents);
};

/**
 * Delete an assessment
 *
 * @function deleteAssessment
 * @param {string} courseId
 * @param {string} moduleId
 * @param {string} assessmentId
 * @returns {Promise<Course>}
 */
export const deleteAssessment = async (courseId, moduleId, assessmentId) => {
  const response = await del(`${endpoints.COURSE(courseId)}/module/${moduleId}/assessment/${assessmentId}`);
  if (response.ok) {
    expireAllSearches();
  }
  deleteCachedAssessments([assessmentId]);
  deleteCachedModules([moduleId]);
  deleteCachedCourse(courseId);
  return response.ok;
};

/**
 * Create a new lecture
 *
 * @function createLecture
 * @private
 * @param {string} courseId
 * @param {string} moduleId
 * @param {Lecture} partial
 * @returns {Promise<Lecture>}
 */
const createLecture = async (courseId, moduleId, partial) => {
  const lecture = (await post(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/lecture`,
    null,
    makeLectureDTO(partial),
  )).body;
  expireAllSearches();
  cacheLectures([lecture]);
  if (DEBUG) log.debug("COURSE_API :: created lecture", lecture);
  return expandLecture(lecture, courseId, moduleId);
};

/**
 * Create a new lecture
 *
 * @function updateLecture
 * @private
 * @param {string} courseId
 * @param {string} moduleId
 * @param {Lecture} partial
 * @returns {Promise<Lecture>}
 */
const updateLecture = async (courseId, moduleId, partial) => {
  deleteCachedCourse(courseId);
  const lecture = (await put(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/lecture/${partial.id}`,
    null,
    makeLectureDTO(partial),
  )).body;
  expireAllSearches();
  cacheLectures([lecture]);
  return expandLecture(lecture, courseId, moduleId);
};

/**
 * Get lectures by list of ids
 *
 * @function getLecturesByIds
 * @private
 * @param {string} courseId
 * @param {string} moduleId
 * @param {string[]} lectureIds
 * @returns {Promise<Lecture[]>}
 */
const getLecturesByIds = async (
  courseId,
  moduleId,
  lectureIds,
  skipCache = false,
  expand = EXPAND_ALL,
) => {
  const url = `${endpoints.COURSE(courseId)}/module/${moduleId}/lecture/?lid=${lectureIds.join(',')}`;
  const expandFn = (lectures) => Promise.all(lectures.map(
    (lecture) => expandLecture(lecture, courseId, moduleId, expand),
  ));

  if (!skipCache) {
    const cached = getCachedLectures(lectureIds);
    if (cached) return expandFn(cached);
  }

  const response = (await get(url)).body;

  // FIXME this fixes ordering of items because BE does not necessarily return them in
  //       in the same order they were requested. Can remove this if/when backend
  //       changes
  const lectureMap = new Map(response.map((item) => [item.id, item]));
  const lectures = lectureIds.reduce(mapFilter((id) => lectureMap.get(id), (item) => !!item), []);

  cacheLectures(lectures);
  return expandFn(lectures);
};

/**
 * Delete a lecture
 *
 * NOTE currently unused (lectures are automatically deleted when omitted from module)
 *      saved in case we need it down the road
 *
 * @function deleteLecture
 * @private
 * @param {string} courseId
 * @param {string} moduleId
 * @param {Lecture} partial
 * @returns boolean indicating success
 */
/*
const deleteLecture = async (courseId, moduleId, lectureId) => {
  const response = await del(
    `${endpoints.COURSE(courseId)}/module/${moduleId}/lecture/${lectureId}`,
  );
  if (response.ok) {
    deleteCachedLectures([lectureId]);
    deleteCachedModules([moduleId]);
    deleteCachedCourse(courseId);
    expireAllSearches();
  }
  return response.ok;
};
*/

/**
 * Get modules by ids list
 *
 * @function getModulesByIds
 * @private
 * @param {UUID} courseId
 * @param {UUID} moduleIds
 * @param {boolean} [skipCache=false]
 * @param {ExpandOptions} [expand] which child objects to fully populate
 * @returns {Promise<CourseModule[]>}
 */
export const getModulesByIds = async (
  courseId, moduleIds, skipCache = false, expand = EXPAND_ALL,
) => {
  const expandFn = async (modules) => Promise.all(modules.map(
    async (m) => responseToCourseModule(
      await expandModule(m, courseId, skipCache, expand),
    ),
  ));

  if (moduleIds === null) return [];

  if (!skipCache) {
    const cached = getCachedModules(moduleIds);
    if (cached) return expandFn(cached);
  }

  const response = await (await get(
    `${endpoints.COURSE(courseId)}/module/?mid=${moduleIds.join(',')}`,
  )).body;

  // FIXME this fixes ordering of items because BE does not necessarily return them in
  //       in the same order they were requested. Can remove this if/when backend
  //       changes
  const moduleMap = new Map(response.map((item) => [item.id, item]));
  const modules = moduleIds.reduce(mapFilter((id) => moduleMap.get(id), (item) => !!item), []);

  cacheModules(modules);
  return expandFn(modules);
};

/**
 * Create a module
 *
 * @function createModule
 * @private
 * @param {UUID} courseId
 * @param {Course} partial
 * @returns {Promise<CourseModule>}
 */
const createModule = async (courseId, partial) => {
  // module has to be created first with no lectures
  const response = (await post(
    `${endpoints.COURSE(courseId)}/module`,
    null,
    makeModuleDTO({ ...partial, lectures: [] }),
  )).body;
  if (DEBUG) log.debug("COURSE_API :: initialized module", partial);

  const lectures = [];
  // then we create the lectures individually
  // eslint-disable-next-line no-restricted-syntax
  for (const lecture of partial.lectures) {
    /* eslint-disable-next-line no-await-in-loop */
    lectures.push(await createLecture(courseId, response.id, lecture));
  }
  // now append their ids (they'll just be fetched from cache in a second)
  response.lectures = lectures.map((l) => l.id);

  // now fix module cache
  expireAllSearches();
  cacheModules([response]);
  if (DEBUG) log.debug("COURSE_API :: created module", response);

  // ... and refetch
  // TODO it would be nice to skip the trouble of refetching here, if possible
  //      (but we'd have to jump through a hoop to update the module cache first)
  return responseToCourseModule(await expandModule(response, courseId));
};

/**
 * Deals with updating child objects before updating a module.
 *
 * For now this should only have to handle lectures, since assessments have their own
 * API.
 *
 * @function processModuleChildren
 * @private
 * @param {UUID} courseId
 * @param {CourseModule} module
 * @return {CourseModule} module with children updated
 */
const processModuleChildren = async (courseId, module) => {
  const lectures = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const lecture of module.lectures) {
    /* eslint-disable no-await-in-loop */
    if (!lecture.id) {
      lectures.push(await createLecture(courseId, module.id, lecture));
    } else {
      const cached = getCachedLectures([lecture.id]);
      if (cached && deepequal(makeLectureDTO(lecture), cached[0])) {
        // nothing to do, objects are identical
        lectures.push(lecture);
      } else {
        lectures.push(await updateLecture(courseId, module.id, lecture));
      }
    }
    /* eslint-enable no-await-in-loop */
  }
  if (lectures.length && DEBUG) log.debug("COURSE_API :: module created/updated lectures", lectures);

  return merge(module, { lectures });
};

/**
 * Update a module
 *
 * @function updateModule
 * @private
 * @param {UUID} courseId
 * @param {CourseModule} partial
 * @returns {Promise<CourseModule>}
 */
const updateModule = async (courseId, partial, oldModule) => {
  deleteCachedCourse(courseId);
  // fetch assessments first in case any have been created while the course
  // was being edited, because if we save the module without the assessment
  // the assessment will be deleted
  // const [oldModule] = await getModulesByIds(courseId, [partial.id], true, EXPAND_NONE);
  const processed = await processModuleChildren(
    courseId,
    merge(partial, { assessments: oldModule.assessments }),
  );
  if (DEBUG) log.debug("COURSE_API :: pre-processed module", processed);

  const response = (await put(
    `${endpoints.COURSE(courseId)}/module/${partial.id}`,
    null,
    makeModuleDTO(processed),
  )).body;

  expireAllSearches();
  cacheModules([response]);
  if (DEBUG) log.debug("COURSE_API :: updated module", response);

  return responseToCourseModule(await expandModule(response, courseId));
};

/**
 * Delete a module
 *
 * NOTE unused (course deletes modules automatically when omitted from dto)
 *      preserved for future use
 *
 * @function deleteModule
 * @private
 * @param {UUID} courseId
 * @param {UUID} moduleId
 * @returns {Promise<CourseModule>}
 *
const deleteModule = async (courseId, moduleId) => {
  const response = await del(
    `${endpoints.COURSE(courseId)}/module/${moduleId}`,
  );
  if (response.ok) {
    expireAllSearches();
    deleteCachedModules([moduleId]);
    deleteCachedCourse(courseId);
  }
  return response.ok;
};
*/

/**
 * Search courses.
 *
 * TODO implement params once spec is settled
 *
 * @function searchCourses
 * @param {object} searchParams
 * @param {ExpandOptions} expand
 * @return {Course[]}
 */
export const searchCourses = async (searchParams = {}, expand = EXPAND_ALL) => {
  if (DEBUG) log.debug("COURSE_API :: searching courses", searchParams, expand);
  let cached = await cache.getOrRefreshExpiringMapEntry(
    CACHE_COURSE_KEY_SEARCH,
    md5(searchParams),
    async () => {
      const courses = (await get(
        endpoints.COURSES,
        null, // when we have search by courses use that
        true,
      )).body;
      courses.forEach((course) => {
        cache.storeExpiringMapEntry(CACHE_COURSE_KEY, course.id, course);
      });
      return courses.map((course) => course.id);
    },
  );
  // FIXME hacky last resort get cached courses from v2 (workaround for BE issue)
  if (cached?.length === 0) {
    const cachedv2 = [...cache.getExpiredMap(CACHE_COURSE_KEY_V2)?.values?.() || []]
      ?.map((item) => item?.value?.id)
      ?.filter((item) => item !== null);
    if (cachedv2?.length) {
      cached = cachedv2;
    }
  }
  if (!expand.courses) return cached;
  // prefetch all metadata, we're going to need it anyway most of the time
  if (expand.metadata) await metadataList();
  // prefetch all users, we're going to need it anyway most of the time
  if (expand.instructors) await userList();
  const populated = await Promise.all(cached.map(
    async (id) => cache.getExpiredMapEntry(CACHE_COURSE_KEY, id)
      || getCourseById(id, false, EXPAND_COURSES),
  )).then((courses) => expandCourses(courses, false, expand));
  return populated;
};
