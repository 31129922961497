/**
 * Collapsible with a summary for the content profile
 *
 * @module ui/page/admin/content/review-collapsible
 * @category Pages
 * @subcategory Admin - Content Management
 */
import { merge } from "util/object";
import collapsible from "ui/component/collapsible";
import div from "ui/html/div";
import span from "ui/html/span";
import labelCollection from "ui/component/label-collection";
import scheduleBadge from "ui/component/schedule-availability-badge";
import { usFullDateFormat } from "util/date";

const defaultState = {
  title: "",
  description: "",
  categories: [],
  startDate: null,
  endDate: null,
  opened: false,
  disabled: false,
};

export default function reviewCollapsible(inState = {}) {
  const state = merge(defaultState, inState);

  return collapsible(
    { title: "Review", open: state.opened, disabled: state.disabled },
    [
      div(".key-value-container", [
        span("Title", ".label"),
        span(state.title, ".value"),
      ]),
      div(".key-value-container", [
        span("Description", ".label"),
        span(state.description, ".value"),
      ]),
      div(".key-value-container", [
        span("Creation Date", ".label"),
        span(state.startDate ? usFullDateFormat(state.startDate) : "N/A", ".value"),
      ]),
      div(".key-value-container.wrap", [
        span("Categories", ".label"),
        labelCollection(state.categories, null, false),
      ]),
      div(".key-value-container.wrap", [
        span("Schedule", ".label"),
        span(scheduleBadge(state), ".label"),
      ]),
    ],
  );
}
