/**
 * A help modal dialog for FTP page.
 *
 * @module ui/component/modal/help/ftp
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminFTPHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_FTP,
    skipCache,
    async,
    sel: ".help-modal-ftp",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-ftp.svg"),
            p("Mass File Upload", ".title"),
            div(".text-container", [
              p("Log into the FTP portal with Filebrowser username and password"),
              p("for your organization.", ".spaced-bottom"),
              p("This login information is organization-specific.", ".spaced-bottom"),
              p("Please contact your organization’s SmartEdge admin for more details.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-ftp.svg"),
            p("Mass File Upload", ".title"),
            div(".text-container", [
              p("To upload a file, click to upload icon.", ".spaced-bottom"),
              p("Select the files you want to upload and click Open.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-ftp.svg"),
            p("Mass File Upload", ".title"),
            div(".text-container", [
              p("Once you are done, head back to the Content Mass Creation page to complete your content upload.", ".spaced-bottom"),
              p([
                "If you have any more questions, please visit our ",
                a("Admin FAQ", "/admin/admin-faq"),
                " for more help",
              ]),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
