/**
 * A course card for displaying a metadata item, used in grids and carousels.
 *
 * @module ui/component/card/course
 * @category UI
 * @subcategory Components
 */
import { div, h3, span } from "ui/html";
import { cardFormatOptions } from "model/dynamic-page/constants";
import icon from "ui/component/icon";
import placeholder from "../placeholder";
import { desc, openModalButton, position } from "./common";

const titleBlock = (title, temp) => (title || !temp
  ? h3(title, ".title", { props: { title } })
  : h3(placeholder(), ".title")
);

const poster = () => {
  const post = div(
    ".poster",
    {
      hook: {
        /* eslint-disable-next-line no-param-reassign */
        insert: (node) => { node.elm.dataset.imageLoaded = "not-loaded"; },
      },
    },
  );
  post.children = [
    icon.regular("book"),
  ];
  return post;
};

export default function courseItemCard({ metadata, cardSize = cardFormatOptions.VERTICAL }) {
  const { title, description } = metadata;
  if (cardSize === cardFormatOptions.HORIZONTAL) {
    return div(".content-card.course-item-card.big", [
      div(".pane.poster-pane", [
        poster(),
        position(0),
      ]),
      div(".pane.info-pane", [
        div(".info-content", [
          titleBlock(title),
          desc(description, false, cardSize),
        ]),
        div('.info-footer', [
          span(openModalButton(metadata), ".mobile"),
          openModalButton(metadata),
        ]),
      ]),
    ]);
  }
  return div(".content-card.course-item-card", [
    poster(),
    position(0),
    titleBlock(title),
    desc(description),
    openModalButton(metadata),
  ]);
}
