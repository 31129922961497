/**
 * A cameo for members of groups.
 *
 * @module ui/component/cameo-group-member
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import userModal from "ui/component/modal/user";
import button from "ui/component/form-managed/button";
import { userNamesString } from "util/format";
import { getModal } from "ui/view/modal-view";

export default function cameoGroupMember({
  user,
  onRemove = null,
  controls = null,
}) {
  return cameo({
    label: userNamesString(user),
    controls: [
      button({
        label: "view",
        iconOnly: true,
        icon: "eye",
        onClick: (e) => {
          e.stopPropagation();
          getModal().async(userModal({ user }));
        },
      }),
      ...(controls || []),
      onRemove
        ? button.warning({ iconOnly: true, icon: "trash-alt", onClick: onRemove, label: "delete" })
        : "",
    ],
    sel: ".cameo-group-member",
  });
}
