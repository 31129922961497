/**
 *
 * ## Overview
 * The UI module collects all the components, utilities and factories related
 * to user interface display and behavior.
 *
 * ### Pages
 * Higher-level business logic and state management are performed by _pages_
 * ({@link module:ui/page}). A page is not a well-defined object - just a
 * module with a collection of components, views, and helper functions
 * necessary to drive a single page in the system.
 * Pages have a 1:1 relationship to routes, with the exception of dynamic pages
 * (which are all virtual routes rendered by the dynamic page module).
 *
 * Pages are responsible for managing state and retrieving data from the API,
 * as well as selecting and binding views.
 *
 * Each page module provides a single function as a default export, which is
 * loaded in a corresponding HTML file, served statically or via a route
 * determined by the frontend web server.
 *
 * ### Views
 * Component-level logic and internal state are managed by _views_,
 * which compose a tree of components with internal state:
 * ({@link module:ui/view}).
 *
 * Views are bound to DOM nodes, and a page can have several of these bindings.
 * At a minimum a page will create and bind a DOM node for use by the header
 * component, modal dialogs, the loading overlay, and the page's primary view.
 *
 * Once a view is bound it takes control of the bound element's properties,
 * attributes, children, and event listeners.
 *
 * Views themselves are persistent for the lifetime of a page visit. They
 * receive a _component factory_, an optional update callback, a
 * _state object_, and a dom selector during instantiation. Views _must not_
 * modify state (the root view factory makes state immutable), but can
 * receive partial state updates, which are merged with the previous state.
 *
 * Views are generally unconcerned with managing state or changing the
 * components they render - mostly they just provide methods to do this for
 * use by a page module.
 * Most of the time the [generic view]{@link module:ui/view/view} is sufficient,
 * and many pages use it for their views, supplying their own component factory,
 * update callback and state updates.
 *
 * Only create a new view if you need to provide some higher-level reusable
 * functionality outside the scope of a component but useful across numerous
 * pages (for example as done by the form and modal views).
 *
 * ### Components
 * Event bindings and composition of ui components is done by _components_:
 * ({@link module/ui:component}).
 *
 * Components are composed of one or more base HTML _elements_. Component
 * modules are factory functions that accept state and return a VDOM tree.
 * Their internal logic should not do any state retrieval or management;
 * it should only decide what VDOM to yield given the state they receive.
 *
 * Components may also bind event listeners and communicate up the chain
 * to views and page modules via callback functions or emitted events.
 *
 * When building components, avoid uneccessarily deep DOM trees or excessive
 * structural elements. These have a significant negative impact on payload
 * and performance, besides being difficult to work with.
 *
 * ### Elements
 * Base HTML elements are factory functions that take properties and attributes
 * and produce virtual DOM nodes ({@link module:ui/html}). They do not do any
 * state or data management internally.
 *
 * The element modules are not comprehensive - instead we create them on an
 * as-needed basis. They are designed with ergonomics rather than standardized
 * interfaces in mind, so their signatures vary significantly. See the html
 * module documentation for guidelines.
 *
 * ## UI Stylesheets
 * Stylesheets are in `src/styles`. These aren't exceptionally well-organized,
 * but universal styles for components are defined in a number of files grouped
 * by general category and styles specific to individual pages have their own
 * files. These are all compiled by the sass compiler into a single `app.css`,
 * which is loaded in the header of each of the HTML templates found in
 * `src/markup/`.
 *
 * Notably we do _not_ use BEM or any other strict naming convention, nor do we
 * use any form of css-in-js (styled-components, etc.). The SmartEdge DOM tree
 * is shallow and it is not a problem to use well-considered classes and
 * selectors to ensure proper separation of styles with a minimal footprint.
 *
 * General guidelines when applying styles to components:
 * - Components _must not_ use IDs for the purpose of styling, since the
 *   number of components of a given type is unpredictable
 * - Components _should not_ use excessive classes when selecting by DOM node
 *   would be sufficient. For example `.my-component > h1` is much better than
 *   `.my-component .my-component-header` when considering classes and CSS
 *   selectors.
 * - Name components for what they _are_ or what _state_ they are in, not for
 *   what they should _look like_. For example, `p.danger` is much better than
 *   `p.red`.
 * - Avoid changing styles via `Element.style`. Instead use state-based
 *   classes unless this would be extremely burdensome. For example,
 *   `myElement.classList.add("open")` is better than
 *   `myElement.style.display = "block"` to show/hide elements.
 * - Avoid any method of animation with JS unless it absolutely cannot be done
 *   with CSS transitions and animations, even if using CSS is somewhat more
 *   burdensome.
 *
 * If you must break these guidelines, leave a comment in the component
 * explaining why it was necessary.
 *
 * @module ui
 * @category UI
 */
import * as html from "./html";
import * as component from "./component";
import * as common from './common';
import page from './page';
import view from './view';

export default {
  initPage: common.initPage,
  common,
  component,
  html,
  page,
  view,
};
