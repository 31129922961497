/**
 * Constraints applied by form validation to various predefined
 * form components.
 *
 * These apply various attributes like `minlength` and `pattern`
 * as well as default placecholder and autocomplete properties.
 *
 * @module ui/common/validation-constraints
 * @category UI
 * @subcategory Common
 */
import { frozen, merge } from "../../util/object";

/**
 * Constraints applicable to text inputs.
 *
 * @constant textConstraints
 * @readonly
 */
export const textConstraints = frozen({
  attrs: {
    minlength: "1",
    maxlength: "255",
  },
});

/**
 * Constraints applicable to numeric inputs.
 *
 * @constant numericConstraints
 * @readonly
 */
export const numericConstraints = frozen({
  attrs: {
    minlength: "1",
    maxlength: "12",
  },
  props: {
    pattern: "^[0-9]+\\.?[0-9]*$",
  },
});

/**
 * A non-zero integer input.
 *
 * @constant nonZeroIntegerConstraints
 * @readonly
 */
export const nonZeroIntegerConstraints = frozen({
  attrs: {
    minlength: "1",
    maxlength: "12",
  },
  props: {
    pattern: "^[1-9][0-9]*$",
  },
});

/**
 * Constraints applicable to cell phone numbers.
 *
 * @constant cellPhoneConstraints
 * @readonly
 */
export const cellPhoneConstraints = {
  props: {
    pattern: "^\\+[\\d][\\d]{8,16}$",
    placeholder: "Phone Number",
    autocomplete: "tel",
  },
};

/**
 * Constraints applicable to email inputs.
 *
 * @constant emailConstraints
 * @readonly
 */
export const emailConstraints = frozen(merge(
  textConstraints,
  {
    props: {
      /* doesn't support the \S character class ?? */
      /* eslint-disable-next-line no-useless-escape */
      pattern: "^\\S+@\\S+(\\.?\\S+)+$",
      autocomplete: "email",
    },
    attrs: {
      placeholder: "Email",
    },
  },
));

/**
 * Constraints applicable to given and family names.
 *
 * @constant nameConstraints
 * @readonly
 */
export const nameConstraints = frozen({
  props: {
    pattern: "$([a-zA-Z]+)|([a-zA-Z]+( [a-zA-Z]+)*)",
    minlength: 0,
    maxlength: 255,
  },
});

/**
 * Constraints applicable to passwords inputs.
 *
 * NOTE: password complexity enforcement is handled in
 * {@module ui/component/form-managed/field-password-confirmed} because
 * pattern matching is insufficient to implement password complexity requirements.
 *
 * @constant passwordConstraints
 * @readonly
 */
export const passwordConstraints = frozen(merge(
  textConstraints,
  {
    props: {
      autocomplete: "current-password",
    },
    attrs: {
      minlength: 8,
      maxlength: 255,
      placeholder: "Password",
    },
  },
));

/**
 * Constraints for URL slugs (used by pages).
 *
 * @constant slugConstraints
 * @readonly
 */
export const slugConstraints = frozen({
  props: {
    pattern: `^[a-z0-9]([a-z0-9]+-?)+[a-z0-9]$`,
    autocomplete: "off",
  },
  attrs: {
    minlength: 3,
    maxlength: 128,
    placeholder: "URL Extension",
  },
});

/**
 * Constraints applicable to content titles.
 *
 * @constant titleConstraints
 * @readonly
 */
export const titleConstraints = frozen({
  props: {
    minlength: 0,
    maxlength: 255,
    pattern: "^(\\S)|([\\S ]*\\S)$",
  },
  attrs: {
    placeholder: "Title",
  },
});

/**
 * Constraints applicable to text-inputs that accept usernames.
 *
 * @constant usernameConstraints
 * @readonly
 */
export const usernameConstraints = frozen(merge(
  textConstraints,
  {
    props: {
      pattern: "[a-zA-Z0-9][a-zA-Z0-9._]+@?[a-zA-Z0-9._]+",
      autocomplete: "username",
    },
    attrs: {
      minlength: 3,
      maxlength: 255,
      placeholder: "Username",
    },
  },
));

/**
 * Constraints applicable to verification codes.
 *
 * @constant verificationCodeConstraints
 * @readonly
 */
export const verificationCodeConstraints = {
  props: {
    pattern: "[0-9]+",
  },
  attrs: {
    placeholder: "Verification Code",
  },
};

export const urlConstraints = {
  props: {
    /* eslint-disable-next-line no-useless-escape */
    pattern: "(https?:\\/\\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})",
    autocomplete: "url",
  },
  attrs: {
    placeholder: "Remote URL",
  },
};

export const urlRelativeConstraints = {
  props: {
    /* eslint-disable-next-line no-useless-escape */
    pattern: "((\\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])+)|(https?:\\/\\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})",
    autocomplete: "off",
  },
  attrs: {
    placeholder: "Remote or Local URL",
  },
};

export const defaultValidity = frozen({ valid: true, fields: {} });
