/**
 * API calls for metadata endpoints.
 *
 * General naming conventions:
 *
 * - create*: create a new object
 * - delete*: delete an object
 * - get*: returns a single object
 * - list*: returns collections of objects
 * - search*: collections of objects filtered by search parameters
 * - update*: update an existing object
 *
 * @module api/metadata
 * @deprecated prefer `api/metadata/:type` when available
 * @category Backend API
 * @subcategory Metadata
 */
/***/
import md5 from "md5";
import cache from "cache";
import { APIResponseError } from "model/error";
import {
  makeDocumentMetadataDTO,
  makeDocumentSearchParams,
  makePlaylistMetadataDTO,
  makePlaylistSearchParams,
  makeVideoMetadataDTO,
  makeVideoPlaybackDTO,
  makeVideoSearchParams,
  responseToCategory,
  responseToDocumentMetadata,
  responseToPlaylistMetadata,
  responseToVideoMetadata,
} from "model/metadata";
import { metadataTypes, videoSortCriteria } from "model/metadata/constants";
import { sortCriteria } from "model/constraints";
import { endpoints } from "api/constants";
import { del, get, post, put } from "api/request";
import {
  CACHE_DOCUMENT_KEY,
  CACHE_PLAYBACK_KEY,
  CACHE_PLAYBACK_TIME,
  CACHE_PLAYLIST_KEY,
  CACHE_SEARCH_DOCUMENT_KEY,
  CACHE_SEARCH_PLAYLIST_KEY,
  CACHE_SEARCH_PRESET_KEY,
  CACHE_SEARCH_VIDEO_KEY,
  CACHE_VIDEO_KEY,
  expireAllSearches,
  removeMetadataCacheEntries,
  restorePlaylistItemsFromCache,
  storePlaylistWithItems,
} from "./cache";

/** Documents */

/**
 * Search document metadata.
 *
 * @function searchDocuments
 * @param {object} partial matching {@link module:api/types/metadata.DocumentMetadataDTO}
 *
 * @return {module:api/types/metadata~DocumentMetadata[]}
 */
export const searchDocuments = async (partial) => {
  const cached = (await cache.getOrRefreshExpiringMapEntry(
    CACHE_SEARCH_DOCUMENT_KEY,
    md5(JSON.stringify(partial)),
    async () => {
      const documents = (await get(
        endpoints.META_DOCUMENT,
        makeDocumentSearchParams(partial),
      )).body;
      documents.forEach(
        (doc) => cache.storeExpiringMapEntry(CACHE_DOCUMENT_KEY, doc.id, doc),
      );
      return documents.map((doc) => doc.id);
    },
  ));
  const cachedDocuments = cache.getExpiringMap(CACHE_DOCUMENT_KEY);
  return Promise.all(
    cached.map((id) => responseToDocumentMetadata(cachedDocuments.get(id).value)),
  );
};

/**
 * Get document metadata by UUID.
 *
 * @function getDocument
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~DocumentMetadata}
 */
export const getDocument = async (uuid, skipCache = false) => {
  if (!skipCache) {
    const doc = await cache.getOrRefreshExpiringMapEntry(
      CACHE_DOCUMENT_KEY,
      uuid,
      async () => (await get(`${endpoints.META_DOCUMENT}/${uuid}`)).body,
    );
    // abstract indicates it was an abstract entry from a playlist
    if (doc && !doc.abstract) return responseToDocumentMetadata(doc);
  }
  // if we're forcing skip cache or the document was abstract, refresh it
  return responseToDocumentMetadata(cache.storeExpiringMapEntry(
    CACHE_DOCUMENT_KEY,
    uuid,
    (await get(`${endpoints.META_DOCUMENT}/${uuid}`)).body,
  ));
};

/**
 * Create a new document metadata entry.
 *
 * @function createDocument
 * @param {object} partial matching {@link module:api/types/metadata.documentMetadataDTO}
 *
 * @return {DocumentMetadata} the created item
 */
export const createDocument = async (partial) => {
  const response = (await post(
    endpoints.META_DOCUMENT,
    null,
    makeDocumentMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(CACHE_DOCUMENT_KEY, response.id, response);
  return responseToDocumentMetadata(response);
};

/**
 * Create a new document metadata entry batch.
 *
 * @function batchCreateDocument
 * @param {object[]} partial matching {@link module:api/types/metadata~documentMetadataDTO}
 *
 * @returns {Promise<{entries: DocumentMetadata[], errors: object[]}>} the created items
 */
export const batchCreateDocument = async (partial = []) => {
  const response = (await post(
    endpoints.META_DOCUMENT_BATCH,
    null,
    partial.map(makeDocumentMetadataDTO),
  )).body;
  expireAllSearches();
  const pairs = response.entries.map((e) => [e.id, e]);
  cache.storeExpiringMapEntries(CACHE_DOCUMENT_KEY, pairs);
  return {
    entries: response.entries.map((entry) => (entry ? responseToDocumentMetadata(entry) : null)),
    errors: response.errors,
  };
};

/**
 * Update a document metadata entry.
 *
 * @function updateDocument
 * @param {object} partial matching {@link module:api/types/metadata.documentMetadataDTO}
 *
 * @return {module:api/types/metadata~DOCUMENTMetadata} the created item
 */
export const updateDocument = async (uuid, partial) => {
  const response = (await put(
    `${endpoints.META_DOCUMENT}/${uuid}`,
    null,
    makeDocumentMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(CACHE_DOCUMENT_KEY, response.id, response);
  return responseToDocumentMetadata(response);
};

/**
 * Delete a document metadata entry.
 *
 * @function deleteDocument
 * @param {string} uuid
 *
 * @return boolean indicating success
 */
export const deleteDocument = async (uuid) => {
  const res = await del(`${endpoints.META_DOCUMENT}/${uuid}`);
  if (res.ok) {
    removeMetadataCacheEntries(uuid, metadataTypes.DOCUMENT);
  }
  return res.ok;
};

/** PLAYLISTS */

/**
 * Search playlist metadata.
 *
 * @function searchPlaylists
 * @param {object} partial matching {@link module:api/types/metadata.PlaylistSearchParams}
 *
 * @return {module:api/types/metadata~PlaylistMetadata[]}
 */
export const searchPlaylists = async (partial) => {
  const cached = (await cache.getOrRefreshExpiringMapEntry(
    CACHE_SEARCH_PLAYLIST_KEY,
    md5(JSON.stringify(partial)),
    async () => {
      const playlists = (await get(
        endpoints.META_LIST,
        makePlaylistSearchParams(partial),
      )).body;
      playlists.forEach((list) => storePlaylistWithItems(list));
      return playlists.map((list) => list.id);
    },
  ));
  return Promise.all(
    cached.map((id) => restorePlaylistItemsFromCache(
      // we don't care if the entry for a search result is expired
      cache.getExpiredMapEntry(CACHE_PLAYLIST_KEY, id),
    )),
  );
};

/**
 * Get playlist metadata by UUID.
 *
 * @function getPlaylist
 * @param {string} uuid
 * @param {boolean} skipCache
 * @return {?module:api/types/metadata~PlaylistMetadata}
 */
export const getPlaylist = async (uuid, skipCache = false) => {
  if (!skipCache) {
    return restorePlaylistItemsFromCache((await cache.getOrRefreshExpiringMapEntry(
      CACHE_PLAYLIST_KEY,
      uuid,
      async () => storePlaylistWithItems(
        (await get(`${endpoints.META_LIST}/${uuid}`)).body,
      ),
    )));
  }
  // by fetching and passing through store & restore we ensure consistency of
  // data in cache and leverage handling of BE DTOs already built into these
  // functions
  return restorePlaylistItemsFromCache(
    storePlaylistWithItems(
      (await get(`${endpoints.META_LIST}/${uuid}`)).body,
    ),
  );
};

/**
 * Get the dynamically generated homelist.
 *
 * @function getHomelist
 * @deprecated home page is part of the dynamic page system now
 * @return {(PlaylistMetadata|null)}
 */
export const getHomelist = async () => responseToPlaylistMetadata((await get(`${endpoints.META_HOME_LIST}`)).body);

/**
 * Create a new playlist metadata entry.
 *
 * @function createPlaylist
 * @param {object} partial matching {@link module:api/types/metadata.PlaylistMetadataDTO}
 *
 * @return {module:api/types/metadata~PlaylistMetadata} the created item
 */
export const createPlaylist = async (partial) => {
  const response = (await post(
    endpoints.META_LIST,
    null,
    makePlaylistMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(
    CACHE_PLAYLIST_KEY,
    response.id,
    storePlaylistWithItems(response),
  );
  return responseToPlaylistMetadata(response);
};

/**
 * Update a playlist metadata entry.
 *
 * @function updatePlaylist
 * @param {object} partial matching {@link module:api/types/metadata.PlaylistMetadataDTO}
 *
 * @return {module:api/types/metadata~PlaylistMetadata} the created item
 */
export const updatePlaylist = async (uuid, partial) => {
  const response = (await put(
    `${endpoints.META_LIST}/${uuid}`,
    null,
    makePlaylistMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(
    CACHE_PLAYLIST_KEY,
    uuid,
    storePlaylistWithItems(response),
  );
  return responseToPlaylistMetadata(response);
};

/**
 * Delete a playlist metadata entry.
 *
 * @function deletePlaylist
 * @param {string} uuid
 *
 * @return boolean indicating success
 */
export const deletePlaylist = async (uuid) => {
  const response = (await del(`${endpoints.META_LIST}/${uuid}`));
  if (response.ok) {
    removeMetadataCacheEntries(uuid, metadataTypes.LIST);
  }
  return response.ok;
};

/** VIDEOS */

/**
 * Search video metadata.
 *
 * @function searchVideos
 * @param {object} partial matching {@link module:api/types/metadata.VideoSearchParams}
 *
 * @return {module:api/types/metadata~VideoMetadata[]}
 */
export const searchVideos = async (partial) => {
  const cached = (await cache.getOrRefreshExpiringMapEntry(
    CACHE_SEARCH_VIDEO_KEY,
    md5(JSON.stringify(partial)),
    async () => {
      const videos = (await get(
        endpoints.META_VIDEO,
        makeVideoSearchParams(partial),
      )).body;
      videos.forEach(
        (video) => cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, video.id, video),
      );
      return videos.map((video) => video.id);
    },
  ));
  const cachedVideos = cache.getExpiringMap(CACHE_VIDEO_KEY);
  return Promise.all(
    cached.map(
      /* eslint-disable-next-line no-use-before-define */
      (id) => responseToVideoMetadata(cachedVideos.get(id)?.value) || getVideo(id, true),
    ),
  );
};

/**
 * Filter callback function for checking if an item is in a set.
 * @function inSet
 * @private
 */
const inSet = (set) => (item) => set.has(item);

/**
 * Search all metadata types.
 * @function searchAll
 * @param {object} partial matching any valid search params
 * @return {module:api/types/metadata~ListMetadata[]}
 */
export const searchAll = async (partial) => {
  const generalParams = { ...partial };
  const videoParams = { ...partial };
  // have to do some munging here because only video supports certain sort params
  if (partial.sortCriteriaList) {
    const videoCriteria = partial.sortCriteriaList.filter(inSet(videoSortCriteria));
    if (videoCriteria.length) videoParams.sortCriteriaList = videoCriteria;
    else delete videoParams.sortCriteriaList;

    const generalCriteria = partial.sortCriteriaList.filter(inSet(sortCriteria));
    if (generalCriteria.length) generalParams.sortCriteriaList = generalCriteria;
    else delete generalParams.sortCriteriaList;
  }

  return (await Promise.all([
    searchVideos(videoParams),
    searchDocuments(generalParams),
    searchPlaylists(generalParams),
  ])).flat();
};

/**
 * Get video metadata by UUID.
 *
 * @function getVideo
 * @param {string} uuid
 * @param {boolean} [skipCache=false]
 * @return {?module:api/types/metadata~VideoMetadata}
 */
export const getVideo = async (uuid, skipCache = false, getPlayback = true) => {
  if (!skipCache) {
    const [video, playback] = await Promise.all([
      cache.getOrRefreshExpiringMapEntry(
        CACHE_VIDEO_KEY,
        uuid,
        async () => (await get(`${endpoints.META_VIDEO}/${uuid}`)).body,
      ),
      /* eslint-disable-next-line no-use-before-define */
      getPlayback ? getVideoPlaybackPosition(uuid) : Promise.resolve(0),
    ]);
    // abstract indicates it was an abstract entry from a playlist
    if (video && !video.abstract) {
      video.positionInSeconds = playback;
      return responseToVideoMetadata(video);
    }
  }
  // if we're forcing skip cache or the video was abstract, refresh it
  return responseToVideoMetadata(cache.storeExpiringMapEntry(
    CACHE_VIDEO_KEY,
    uuid,
    (await get(`${endpoints.META_VIDEO}/${uuid}`)).body,
  ));
};

/**
 * Alias of getVideo.
 * @function getVideoById
 * @deprecated in favor of getVideo, for naming consistency.
 * @alias getVideo
 * @param {string} uuid
 */
export const getVideoById = getVideo;

/**
 * Create a new video metadata entry.
 *
 * @function createVideo
 * @param {object} partial matching {@link module:api/types/metadata~VideoMetadataDTO}
 *
 * @return {module:api/types/metadata~VideoMetadata} the created item
 */
export const createVideo = async (partial) => {
  const response = (await post(
    endpoints.META_VIDEO,
    null,
    makeVideoMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, response.id, response);
  return responseToVideoMetadata(response);
};

/**
 * Create a new video metadata entry batch.
 *
 * @function batchCreateVideo
 * @param {object[]} partial matching {@link module:api/types/metadata~VideoMetadataDTO}
 *
 * @returns {Promise<{entries: VideoMetadata[], errors: object[]}>} the created item
 */
export const batchCreateVideo = async (partial = []) => {
  const response = (await post(
    endpoints.META_VIDEO_BATCH,
    null,
    partial.map(makeVideoMetadataDTO),
  )).body;
  expireAllSearches();
  const pairs = response.entries.map((e) => [e.id, e]);
  cache.storeExpiringMapEntries(CACHE_VIDEO_KEY, pairs);
  return {
    entries: response.entries.map((entry) => (entry ? responseToVideoMetadata(entry) : null)),
    errors: response.errors,
  };
};

/**
 * Update a video metadata entry.
 *
 * @function updateVideo
 * @param {object} partial matching {@link module:api/types/metadata~VideoMetadataDTO}
 *
 * @return {module:api/types/metadata~VideoMetadata} the created item
 */
export const updateVideo = async (uuid, partial) => {
  const response = (await put(
    `${endpoints.META_VIDEO}/${uuid}`,
    null,
    makeVideoMetadataDTO(partial),
    true,
  )).body;
  expireAllSearches();
  cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, response.id, response);
  return responseToVideoMetadata(response);
};

/**
 * Get the user's last-saved playback position for a video.
 *
 * @function getVideoPlaybackPosition
 * @param {string} uuid
 *
 * @return {number} position in seconds
 */
export const getVideoPlaybackPosition = async (uuid) => parseInt(
  await cache.getOrRefreshExpiringMapEntry(
    CACHE_PLAYBACK_KEY,
    uuid,
    async () => (await get(
      `${endpoints.META_VIDEO}/${uuid}/playback-position`,
      null,
      false,
      true,
      { positionInSeconds: 0 },
    )).body.positionInSeconds,
    CACHE_PLAYBACK_TIME,
  ),
  10,
);

/**
 * Update the user's stored position for a video.
 *
 * @function updateVideoPlaybackPosition
 * @param {string} uuid
 * @param {number} position in seconds
 *
 * @return {boolean} indicating success
 */
export const updateVideoPlaybackPosition = async (uuid, position) => {
  const value = (await put(
    `${endpoints.META_VIDEO}/${uuid}/playback-position`,
    null,
    makeVideoPlaybackDTO(position),
  )).ok ? position : 0;
  const video = cache.getExpiringMapEntry(CACHE_VIDEO_KEY, uuid);
  if (video) {
    cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, video.uuid, {
      ...video,
      positionInSeconds: value,
    });
  }
  return cache.storeExpiringMapEntry(CACHE_PLAYBACK_KEY, uuid, value);
};

/**
 * Delete a video metadata entry.
 *
 * @function deleteVideo
 * @param {string} uuid
 *
 * @return {boolean} indicating success
 */
export const deleteVideo = async (uuid) => {
  const response = (await del(`${endpoints.META_VIDEO}/${uuid}`));
  if (response.ok) {
    removeMetadataCacheEntries(uuid, metadataTypes.VIDEO);
  }
  return response.ok;
};

/**
 * A list of currently existing category names.
 * @function listCategories
 * @return {Category[]}
 */
export const listCategories = async () => {
  const response = (await get(endpoints.CATEGORY)).body;
  return response.map(responseToCategory);
};

/**
 * get metadata of an unknown type by checking all metadata endpoints.
 *
 * @function getMetadata
 * @param {UUID} uuid
 * @param {boolean} [skipCache=false] bypass cache if true
 * @return {?module:api/types/metadata~ListMetadata}
 */
export const getMetadata = async (uuid, skipCache = false) => Promise.any([
  getVideo(uuid, skipCache),
  getDocument(uuid, skipCache),
  getPlaylist(uuid, skipCache),
]).catch((res) => Promise.reject(new APIResponseError({
  request: res.errors[0].request,
  body: res.errors[0].body,
  statusCode: res.errors[0].statusCode,
  statusText: res.errors[0].statusText,
})));
  /*
  if (!skipCache) {
    // check if it's in cache to skip pointless API calls if we already  have it
    const video = cache.getExpiringMapEntry(CACHE_VIDEO_KEY, uuid);
    if (video) {
      return responseToVideoMetadata(video);
    }
    const doc = cache.getExpiringMapEntry(CACHE_DOCUMENT_KEY, uuid);
    if (doc) {
      return responseToDocumentMetadata(doc);
    }
    const playlist = cache.getExpiringMapEntry(CACHE_PLAYLIST_KEY, uuid);
    if (playlist) {
      return restorePlaylistItemsFromCache(playlist);
    }
  }
  // no cache, or else we're skipping cache, so we have to do all three queries
  /*
  const [video, doc, list] = (await Promise.all([
    getVideo(uuid, skipCache).catch(() => null),
    getDocument(uuid, skipCache).catch(() => null),
    getPlaylist(uuid, skipCache).catch(() => null),
  ]));
  if (video) return video;
  if (doc) return doc;
  if (list) return list;
  */

/**
 * List all existing video metadata objects.
 * @function listVideos
 * @return {module:api/types/metadata~VideoMetadata[]}
 */
export const listVideos = async () => searchVideos({ maxItems: 1000000 });

/**
 * List all existing document metadata objects.
 * @function listVideos
 * @return {module:api/types/metadata~DocumentMetadata[]}
 */
export const listDocuments = async () => searchDocuments({ maxItems: 1000000 });

/**
 * List all existing playlist metadata objects.
 * @function listVideos
 * @return {module:api/types/metadata~PlaylistMetadata[]}
 */
export const listPlaylists = async () => searchPlaylists({ maxItems: 1000000 });

/**
 * List all metadata objects.
 * @function list
 * @return {module:api/types/metadata~ListMetadata[]}
 */
export const list = async () => (await Promise.all([
  (await listVideos()),
  (await listDocuments()),
  (await listPlaylists()),
])).flat();

/**
 * Universal function for preset searches.
 * @function presetSearch
 * @return {module:api/types/metadata~ListMetadata[]}
 */
const presetSearch = async (endpoint, limit = 5) => {
  const cached = (await cache.getOrRefreshExpiringMapEntry(
    CACHE_SEARCH_PRESET_KEY,
    md5(JSON.stringify({ endpoint, limit })),
    async () => {
      const results = (await get(
        `${endpoint}/?max-items=${limit}`,
      )).body;
      results.forEach((result) => {
        switch (result.type) {
          case metadataTypes.VIDEO:
            cache.storeExpiringMapEntry(CACHE_VIDEO_KEY, result.id, result);
            break;
          case metadataTypes.DOCUMENT:
            cache.storeExpiringMapEntry(CACHE_DOCUMENT_KEY, result.id, result);
            break;
          case metadataTypes.LIST:
            storePlaylistWithItems(result);
            break;
          default:
            break;
        }
      });
      return results.map(({ id, type }) => ({ id, type }));
    },
  ));
  return Promise.all(cached.map(({ id, type }) => {
    switch (type) {
      case metadataTypes.DOCUMENT:
        return getDocument(id);
      case metadataTypes.LIST:
        return getPlaylist(id);
      case metadataTypes.VIDEO:
        return getVideo(id);
      default:
        return null;
    }
  }));
};

/**
 * List recently added items.
 *
 * @function recentlyAdded
 * @async
 * @return {module:api/types/metadata~ListMetadata[]}
 */
export const recentlyAdded = async (limit = 5) => presetSearch(
  endpoints.META_RECENTLY_ADDED,
  limit,
);

/**
 * List recently watched videos.
 *
 * @function recentlyWatched
 * @async
 * @return {module:api/types/metadata~VideoMetadata[]}
 */
export const recentlyWatched = async (limit = 5) => presetSearch(
  endpoints.META_RECENTLY_WATCHED,
  limit,
);

/**
 * List most watched videos.
 *
 * @function mostWatched
 * @async
 * @return {module:api/types/metadata~VideoMetadata[]}
 */
export const mostWatched = async (limit = 5) => presetSearch(
  endpoints.META_MOST_WATCHED,
  limit,
);

/**
 * List trending videos.
 *
 * @function trending
 * @return {module:api/types/metadata~VideoMetadata[]}
 */
export const trending = async (limit = 5) => presetSearch(
  endpoints.META_TRENDING,
  limit,
);
