/**
 * A chart component with calendar range picker
 *
 * @module ui/component/chart-with-calendar
 * @category UI
 * @subcategory Components
 */
import { merge } from "util/object";
import chart from "ui/component/chart";
import button from "ui/component/form-managed/button";
import startEndDatePickerModal from "ui/component/modal/start-end-date-picker";
import scrubBar from "ui/component/scrub-bar";

const defaultState = {
  startDate: undefined,
  endDate: undefined,
  onDateSelected: () => {
  },
  withScrubBar: false,
  onScrubBarValueChange: () => {
  },
};

/**
 * @function chartWithCalendar
 * @param {object} inState
 * @param {object} inState
 * @param {ChartData} ...inState.chartData
 * @param {Date} inState.startDate
 * @param {Date} inState.endDate
 * @param {Function} inState.onDateSelected
 * @param {...any} args the rest arguments
 */
const chartWithCalendar = (modalView) => ({ state: inState = defaultState, ...args }) => {
  const state = merge(defaultState, inState);
  return chart({
    ...args,
    state: {
      canvasId: state.canvasId,
      data: state.data,
      type: state.type,
      sel: ".sm-chart-with-calendar",
      children: [
        state.withScrubBar
          ? scrubBar({
            onScrubBarValueChange: (value) => state.onScrubBarValueChange(value),
          })
          : "",
        button.icon({
          icon: "calendar",
          sel: (state.startDate || state.endDate) ? ".secondary" : "",
          onClick: async () => {
            const { startDate, endDate } = await modalView
              .async(startEndDatePickerModal({
                startDate: state.startDate,
                endDate: state.endDate,
              }, modalView));
            state.onDateSelected({
              startDate,
              endDate,
            });
          },
        }),
      ],
    },
  });
};

export default chartWithCalendar;
