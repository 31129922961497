/**
 * A modal for editing answer.
 *
 * @module ui/component/modal/course/answer-edit
 * @category UI
 * @subcategory Modal Dialogs
 */
import { merge } from "util/object";
import div from "ui/html/div";
import h3 from "ui/html/h3";
import form from "ui/component/form-managed";
import textarea from "ui/component/form-managed/field-textarea";
import button from "ui/component/form-managed/button";
import toggle from "ui/component/form-managed/field-toggle";
import { getModal } from "ui/view/modal-view";
import bindModalForm from "ui/component/modal/bound-form";
import dialog from "ui/component/modal/layout/dialog";

const defaultState = {
  validation: {
    fields: {},
  },
  answer: {
    value: "",
    correct: false,
  },
  existingAnswers: [],
  title: "Edit Answer",
  viewOnly: false,
};

const makeHeader = (state) => (state.viewOnly ? "Answer" : state.title);

const formContent = (formView) => form("#answer-edit-form", formView.bind([
  [textarea, {
    name: "value",
    label: "Answer Entry",
    required: true,
    value: formView.state.answer.value,
    disabled: formView.state.viewOnly,
    taken: formView.state.existingAnswers.map((answer) => answer.value),
  }],
  [toggle, {
    toggled: formView.state.answer.correct,
    disabled: formView.state.viewOnly,
    label: "CORRECT ANSWER",
    name: "correct",
  }],
], formView.state.answer));

const onSave = (formView) => {
  const answer = formView.values;
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    return;
  }
  getModal().resolve(answer);
};

/**
 * A modal for answer editing
 *
 * @param inState
 * @param {string} inState.title
 * @param {boolean} inState.viewOnly false by default
 * @param {Answer} inState.answer
 * @param {Answer[]} inState.existingAnswers
 * @returns {*|El}
 */
export default function answerEditModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  const formNode = bindModalForm("answer-edit-form", formContent, state);
  return dialog({
    sel: ".edit-answer",
    header: h3(makeHeader(state)),
    body: formNode,
    footerSpacer: true,
    footer: [
      div(".buttons", { attrs: { role: "group" } }, [
        button({ label: "Save", onClick: () => onSave(formNode.view) }),
      ]),
    ],
  }, getModal());
}
