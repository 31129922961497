/**
 * Utility functions used for searching and filtering users and metadata.
 *
 * Most of the exports here are to be used with Array.filter, and consist
 * of functions that accept some filtering parameters and return a filter
 * callback function.
 *
 * @module util/filter
 * @category Utilities
 */
import { userInGroupName } from "model/user";
import { isSingleLecture } from "model/course";
import { systemPrivilegeGroups, systemPrivilegeGroupsFriendly } from "model/acl/constants";
import { fileTypeMimeTypes } from "model/file-descriptor/constants";
import { normalizeToNull, undefinedOrNull } from "./internal";
import { intersect } from "./array";
import { getFileType } from "./file";
import { isNowWithin } from "./date";

/**
 * A case insensitive text search. This is used internally by other filters.
 *
 * @function textSearch
 * @private
 */
const textSearch = (obj, field, search) => !undefinedOrNull(obj[field])
  && typeof obj[field].toString === "function"
  && obj[field]
    .toString()
    .toLowerCase()
    .indexOf(search.toLowerCase()) !== -1;

export const textFieldFilterAny = (fields, search) => (obj) => {
  if (!search) return true; // slight optimization
  // eslint-disable-next-line no-restricted-syntax
  for (const field of fields) {
    if (textSearch(obj, field, search) === true) return true;
  }
  return false;
};

/**
 * Determine if a field in an advanced search should be checked.
 *
 * @function used
 * @private
 */
const used = (a) => !undefinedOrNull(normalizeToNull(a));

/**
 * Determine if a metadata item has the given category.
 * @function includesCategory
 * @private
 */
const includesCategory = (category) => (obj) => !undefinedOrNull(
  normalizeToNull(obj.categories),
)
  && obj.categories instanceof Array
  && obj.categories.some((ctg) => ctg.name.toLowerCase().includes(category.toLowerCase()));

/**
 * Determine whether course entries amount equals
 *
 * @function courseEntryAmountEquals
 * @param entriesAmount
 * @private
 */
const courseEntriesAmountEquals = (entriesAmount) => (obj) => (
  parseInt(entriesAmount, 10) === obj.moduleIds?.length
);

/**
 * A callback for use with [Array.filter]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter}
 *
 * @callback MetadataTextFilterCallback
 * @param {module:api/types/metadata~ListMetadata} metadataItem
 */

/**
 * Generates a callback function for `Array.filter` which matches the text values of
 * a metadata object against a given search string.
 *
 * Specifically it matches against:
 *
 * - id
 * - title
 * - description
 * - labels
 *
 * @example
 * const matchingMetadata = arrayOfMetadata.filter(metadataTextFilter("mySearchText"));
 *
 * @function metadataTextFilter
 * @param {string} search
 * @return {MetadataTextFilterCallback}
 */
export const metadataTextFilter = (search) => (obj) => textFieldFilterAny([
  "id",
  "title",
  "description",
  "categories",
], search)(obj)
  || includesCategory(search)(obj);

/**
 * Generates a callback function for `Array.filter` which matches the title of
 * a metadata object against a given search string.
 *
 * @example
 * const matchingMetadata = arrayOfMetadata.filter(metadataTitleFilter("mySearchText"));
 *
 * @function metadataTitleFilter
 * @param {string} search
 * @return {MetadataTextFilterCallback}
 */
export const metadataTitleFilter = (search) => (obj) => textFieldFilterAny([
  "title",
], search)(obj);

/**
 * A filter that matches an array of terms.
 * @callback MetadataTermsFilterCallback
 * @param {module:api/types/metadata~ListMetadata} metadataItem
 */

/**
 * Generates a callback function for `Array.filter` which matches the text values of
 * a metadata object against a given set of search terms.
 *
 * Specifically it matches against:
 *
 * - id
 * - title
 * - description
 * - labels
 *
 * @example
 * const matchingMetadata = arrayOfMetadata.filter(metadataTextFilter(["my", "search", "terms"]));
 *
 * @function metadataTermsFilter
 * @param {string[]} terms list of terms to match against
 * @return {MetadataTermsFilterCallback}
 */
export const metadataTermsFilter = (terms) => (obj) => terms.reduce(
  (acc, term) => acc || metadataTextFilter(term)(obj),
  false,
);

/**
 * @callback MetadataAdvancedFilterCallback
 * @param {module:api/types/metadata~ListMetadata} metadataItem
 */

/**
 * Check if all of a metadata object's fields match the search params.
 *
 * - Text fields match if the field includes the given string.
 * - Labels match if the object has any of the given labels.
 * - Type is an exact match
 * - null or undefined fields in params are ignored.
 *
 * @example
 * const matchingMetadata = metadataArray.filter(metadataAdvancedFilter(searchParams));
 *
 * @function metadataAdvancedFilter
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.title
 * @param {string} params.description
 * @param {string[]} params.labels
 * @param {string[]} params.terms search terms to test against any text field
 * @param {module:model/metadata/constants~metadataTypes} params.type
 * @return {MetadataAdvancedFilterCallback}
 */
export const metadataAdvancedFilter = (params) => (obj) => {
  if (used(params.type) && params.type !== obj.type) return false;
  if (used(params.id) && !textSearch(obj, "id", params.id)) return false;
  if (used(params.title) && !textSearch(obj, "title", params.title)) return false;
  if (used(params.description)
    && (!textSearch(obj, "description", params.description))) return false;
  if (used(params.labels) && (
    !used(params.labels)
    // note for legacy reasons 'label' field is 'categories' on metadata object
    // we'll fix this eventually by updating the DTO munger but it will take
    // refactoring throughout the system
    || !intersect(obj.categories?.map((category) => category.name) || [], params.labels).length
  )) return false;
  if (used(params.terms) && !metadataTermsFilter(params.terms)(obj)) return false;
  return true;
};

/**
 * A callback for use with [Array.filter]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter}
 *
 * @callback AssessmentTextFilterCallback
 * @param {Assessment} assessment
 */

/**
 * Generates a callback function for `Array.filter` which matches the text values of
 * a metadata object against a given search string.
 *
 * Specifically it matches against:
 *
 * - title
 *
 * @example
 * const matchingAssessment = assessmentArray.filter(assessmentTextFilter("mySearchText"));
 *
 * @function assessmentTextFilter
 * @param {string} search
 * @return {AssessmentTextFilterCallback}
 */
export const assessmentTextFilter = (search) => (obj) => textFieldFilterAny([
  "title",
], search)(obj);

/**
 * @callback AssessmentAdvancedFilterCallback
 * @param {Assessment} assessment
 */

/**
 * Check if all of a course object's fields match the search params.
 *
 * - Text fields match if the field includes the given string.
 * - null or undefined fields in params are ignored.
 *
 * @example
 * const matchingAssessment = assessmentArray.filter(assessmentAdvancedFilter(searchParams));
 *
 * @function assessmentAdvancedFilter
 * @param {object} params
 * @param {string} params.title
 * @param {Date} params.startDate
 * @param {Date} params.endDate
 * @param {string} params.gradingScheme
 * @return {AssessmentAdvancedFilterCallback}
 */
export const assessmentAdvancedFilter = (params) => (obj) => {
  if (used(params.title) && !textSearch(obj, "title", params.title)) return false;
  if (params.startDate && (obj.startDate > new Date(params.startDate))) return false;
  if (params.endDate && (obj.endDate < new Date(params.endDate))) return false;
  if (used(params.gradingScheme) && !textSearch(obj.gradingScheme, "description", params.gradingScheme)) return false;
  return true;
};

/**
 * A callback for use with [Array.filter]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter}
 *
 * @callback CourseTextFilterCallback
 * @param {module:model/course~Course} course
 */

/**
 * Generates a callback function for `Array.filter` which matches the text values of
 * a metadata object against a given search string.
 *
 * Specifically it matches against:
 *
 * - id
 * - title
 * - entriesAmount
 *
 * @example
 * const matchingCourse = courseArray.filter(courseTextFilter("mySearchText"));
 *
 * @function courseTextFilter
 * @param {string} search
 * @return {CourseTextFilterCallback}
 */
export const courseTextFilter = (search) => (obj) => textFieldFilterAny([
  "id",
  "title",
  "entriesAmount",
], search)(obj);

/**
 * @callback CourseAdvancedFilterCallback
 * @param {module:model/course~Course} course
 */

/**
 * Check if all of a course object's fields match the search params.
 *
 * - Text fields match if the field includes the given string.
 * - null or undefined fields in params are ignored.
 *
 * @example
 * const matchingCourse = courseArray.filter(courseAdvancedFilter(searchParams));
 *
 * @function courseAdvancedFilter
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.title
 * @param {number} params.entriesAmount
 * @return {CourseAdvancedFilterCallback}
 */
export const courseAdvancedFilter = (params) => (obj) => {
  if (used(params.id) && !textSearch(obj, "id", params.id)) return false;
  if (used(params.title) && !textSearch(obj, "title", params.title)) return false;
  if (
    used(params.entriesAmount)
    && !courseEntriesAmountEquals(params.entriesAmount)(obj)
  ) return false;
  return true;
};

/**
 * Callback function for `Array.filter`.
 *
 * @callback MetadataScheduleFilterCallback
 * @param {ListMetadata} obj
 * @return {boolean} true if currently available
 */

/**
 * Filters metadata entries that are not currently available due to scheduling.
 * @function metadataScheduleFilter
 * @return {MetadataScheduleFilterCallback}
 */
export const metadataScheduleFilter = () => (obj) => isNowWithin(obj.startDate, obj.endDate);

/**
 * Callback function for `Array.filter`.
 *
 * @callback PageFilterCallback
 * @param {module:api/types/page~DynamicPage} page
 */

/**
 * Check if any of a page object's text fields contain the search string.
 * For use with Array.filter()
 *
 * @example
 * const matchingPages = pageArray.filter(pageTextFilter("mySearch"));
 *
 *
 * @function pageTextFilter
 * @param {string} search
 * @return {PageFilterCallback}
 */
export const pageTextFilter = (search) => (obj) => textFieldFilterAny([
  "id",
  "title",
  "slug",
], search)(obj);

/**
 * STUB
 */
export const pageAdvancedFilter = () => (obj) => [...obj];

/**
 * Filter pages by type. For use with `array.filter` on a collection of pages.
 *
 * @function pageTypeFilter
 * @param {PageType} type
 */
export const pageTypeFilter = (type) => (page) => page.type === type;

/**
 * Filter metadata by type. FOr use with `array.filter` on a colection of metadata.
 *
 * @function metadataTypeFilter
 * @param {MetadataType} type
 */
export const metadataTypeFilter = (type) => (meta) => meta.type === type;

/**
 * Callback function for use with `Array.filter`.
 *
 * @callback UserAdvancedFilterCallback
 * @param {module:api/types/user~User} user
 */

/**
 * Check if all of a user object's fields match the search params.
 *
 * - String fields match if the field includes the given string.
 * - null or undefined fields in params are ignored.
 *
 * @example
 * const matchingUsers = userArray.filter(userAdvancedFilter(searchParams));
 *
 * @function userAdvancedFilter
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.username]
 * @param {string} [params.firstName]
 * @param {string} [params.lastName]
 * @param {string} [params.lastName]
 * @param {boolean} [params.managesUsers]
 * @param {boolean} [params.managesFiles]
 * @param {boolean} [params.managesMedia]
 * @param {boolean} [params.enabled]
 * @param {boolean} [params.external]
 * @return {UserAdvancedFilterCallback}
 */
export const userAdvancedFilter = (params) => (obj) => {
  // these booleans are cheapest so do them first
  if (used(params.enabled) && (params.enabled !== obj.enabled)) return false;
  if (used(params.external) && (params.external !== obj.external)) return false;
  // checking authorities is cheaper than string matching, so those next...
  if (used(params.managesUsers)) {
    if (
      params.managesUsers === true
      && !userInGroupName(obj, systemPrivilegeGroups.USER_MANAGEMENT)
    ) return false;
    if (
      params.managesUsers === false
      && userInGroupName(obj, systemPrivilegeGroups.USER_MANAGEMENT)
    ) return false;
  }
  if (used(params.managesFiles)) {
    if (
      params.managesFiles === true
      && !userInGroupName(obj, systemPrivilegeGroups.FILE_MANAGEMENT)
    ) return false;
    if (
      params.managesFiles === false
      && userInGroupName(obj, systemPrivilegeGroups.FILE_MANAGEMENT)
    ) return false;
  }
  if (used(params.managesMedia)) {
    if (
      params.managesMedia === true
      && !userInGroupName(obj, systemPrivilegeGroups.MEDIA_MANAGEMENT)
    ) return false;
    if (
      params.managesMedia === false
      && userInGroupName(obj, systemPrivilegeGroups.MEDIA_MANAGEMENT)
    ) return false;
  }
  // do string matches last, so we can skip them if the cheaper ones fail
  if (used(params.id) && !textSearch(obj, "id", params.id)) return false;
  if (used(params.username)
    && !textSearch(obj, "username", params.username)) return false;
  if (used(params.firstName)
    && !textSearch(obj, "firstName", params.firstName)) return false;
  if (used(params.lastName)
    && !textSearch(obj, "lastName", params.lastName)) return false;
  if (used(params.cellPhone)
    && !textSearch(obj, "cellPhone", params.cellPhone)) return false;
  if (used(params.email)
    && !textSearch(obj, "email", params.email)) return false;
  return true;
};

/**
 * Callback function for use with `Array.filter`.
 *
 * @callback GroupAdvancedFilterCallback
 * @param {AclUserEntry} user
 */

/**
 * Check if all of a group object's fields match the search params.
 *
 * - String fields match if the field includes the given string.
 * - null or undefined fields in params are ignored.
 *
 * @example
 * const matchingGroups = groupArray.filter(groupAdvancedFilter(searchParams));
 *
 * @function groupAdvancedFilter
 * @param {object} params
 * @param {string} [params.id]
 * @param {string} [params.name]
 * @param {string} [params.count]
 * @return {GroupAdvancedFilterCallback}
 */
export const groupAdvancedFilter = (params) => (obj) => {
  if (used(params.id) && !textSearch(obj, "id", params.id)) return false;
  if (used(params.name)
    && !textSearch(obj, "name", params.name)) return false;
  if (used(params.count)
    && obj.members.length !== parseInt(params.count, 10)) return false;
  if (used(params.system) && params.system !== obj.system) return false;
  return true;
};

/**
 * Callback for use with `Array.filter`.
 *
 * @callback UserTextFilterCallback
 * @param {module:api/types/user~User} user
 */

/**
 * Check if any of a user object's text fields contain the search string.
 *
 * Specifically, matches against:
 * - id
 * - username
 * - firstName
 * - lastName
 * - email
 * - cellPhone
 *
 * @example
 * const matchingUsers = userArray(userTextFilter("mySearch"));
 *
 * @function userTextFilter
 * @param {string} search
 * @return {UserTextFilterCallback}
 */
export const userTextFilter = (search) => (obj) => textFieldFilterAny([
  "id",
  "username",
  "firstName",
  "lastName",
  "email",
  "cellPhone",
], search)(obj);

/**
 * Check if any of a user's name fields contain the search string.
 *
 * @function userNamesFilter
 * @param {string} search
 * @return {UserTextFilterCallback}
 */
export const userNamesFilter = (search) => (obj) => textFieldFilterAny([
  "username",
  "firstName",
  "lastName",
], search)(obj);

/**
 * Callback for use with `Array.filter`.
 *
 * @callback EvaluationTextFilterCallback
 * @param {Evaluation} evaluation
 */

/**
 * Callback for use with `Array.filter`.
 *
 * @callback CourseFilterCallback
 * @param {Course} course
 */

/**
 * Filter course by title.
 *
 * @function courseTitleFilter
 * @param {string} search
 * @return {CourseFilterCallback}
 */
export const courseTitleFilter = (search) => (obj) => textFieldFilterAny([
  "title",
], search)(obj);

/**
 * Callback for use with `Array.filter`.
 *
 * @callback CourseModuleFilterCallback
 * @param {CourseModule} module
 */

/**
 * Filter course entry by title.
 *
 * @function courseModuleTitleFilter
 * @param {string} search
 * @return {CourseModuleFilterCallback}
 */
export const courseModuleTitleFilter = (search) => (module) => (isSingleLecture(module)
  ? textSearch(module.lectures?.[0], "title", search)
  : textSearch(module, "title", search)
);

/**
 * Callback for use with `Array.filter`.
 *
 * @callback UserGroupFilterCallback
 * @param {module:model/acl~ACLUserGroup} group
 */

/**
 * Filter ACL users and groups by first name, last name, username, or group name.
 *
 * @function userGroupNameFilter
 * @param {string} search
 * @return {UserGroupFilterCallback}
 */
export const userGroupNameFilter = (search) => (obj) => textFieldFilterAny([
  "name",
  "username",
  "firstName",
  "lastName",
  "fullName",
  "friendlyGroupName",
], search)({
  ...obj,
  fullName: `${obj.firstName} ${obj.lastName}`,
  friendlyGroupName: systemPrivilegeGroupsFriendly.get(obj.name),
});

/**
 * Check if any of a group object's text fields contain the search string.
 *
 * Specifically, matches against:
 * - userId
 * - username
 * - count
 *
 * @example
 * const matchingGroups = groupArray(userTextFilter("mySearch"));
 *
 * @function groupTextFilter
 * @param {string} search
 * @return {UserGroupFilterCallback}
 */
export const groupTextFilter = (search) => (obj) => textFieldFilterAny([
  "id",
  "name",
  "count",
], search)(obj);

/**
 * Callback function for using `Array.filter` with any object to match against any
 * of the object's `string` typed fields.
 *
 * @callback AnyTextFilterCallback
 * @param {object} obj

/**
 * Searches any string-type fields on an object for the search text.
 *
 * @example
 * const myObjs = [
 *  { bool: false, text: "foo", type: "bar" },
 *  { text: "foo", type: "baz" },
 *  { text: "foo", obj: { baz: "baz" } },
 * ];
 *
 * const filtered = myObjs.filter(anyTextFilter("baz")); // => [ { text: "foo", type: "baz" } ],
 *
 * @function anyTextFilter
 * @param {string} search search string to check against
 * @return {AnyTextFilterCallback}
 */
export const anyTextFilter = (search) => (obj) => textFieldFilterAny(
  Object.keys(obj).filter((key) => typeof obj[key] === "string"),
  search,
)(obj);

/**
 * Callback for use with `Array.filter`.
 *
 * @callback TypeFilterCallback
 * @param {string} item a file name string
 */

/**
 * Filters file names by [file type]{@link module:api/constants~fileTypes}.
 *
 * @example
 * const files = ["1.jpg", "2.pdf", "3.docx", "4.jpg", "5.stl"];
 * files.filter(typeFilterAll(FileTypes.IMAGE)); // ["1.jpg", 4.jpg"];
 *
 * @function typeFilterAll
 * @param {Array<module:api/constants~FileType>} type file types to include
 * @return {TypeFilterCallback} function accepting an array of file names
 */
export const typeFilterAll = (types) => (item) => types.includes(getFileType(item));

/**
 * Filters file descriptors by mime type.
 *
 * @example
 * const files = ["1.jpg", "2.pdf", "3.docx", "4.jpg", "5.stl"];
 * files.filter(typeFilterAll(FileTypes.IMAGE)); // ["1.jpg", 4.jpg"];
 *
 * @function typeFilterAll
 * @param {Array<module:api/constants~FileType>} type file types to include
 * @return {TypeFilterCallback} function accepting an array of file names
 */
export const descriptorTypeFilterAll = (types) => {
  const supported = types.map((type) => fileTypeMimeTypes.get(type)).flat();
  return (descriptor) => supported.includes(descriptor.type);
};

/**
 * Inverts the results of a filter function.
 *
 * @example
 * const entries = [{ foo: "bar", bar: "baz" }, { foo: "qux", bar: "quux" }];
 * entries.filter(not(textFieldFilterAny("bar"))); // => [{ foo: "bar", bar: "baz" }]
 *
 * @function not
 * @param {function} filterFn
 * @return {function} inverted filter function
 */
export const not = (filterFn) => (obj) => !filterFn(obj);
