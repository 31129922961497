/**
 * The main management page for assessments.
 *
 * Corresponds to `markup/admin/manage-assessments.html`
 *
 * @module ui/page/admin/assessment/manage
 * @category Pages
 * @subcategory Admin - Assessment
 */
import { featureTypes } from "model/config/constants";
import { assessmentSortCriteria } from "model/course/constants";
import courseStream from "data/course";
import actionBar from "ui/component/dashboard-action-bar";
import {
  a, div, section, span,
} from "ui/html";
import widget from "ui/component/dashboard-widget";
import placeholder from "ui/component/placeholder";
import spinner from "ui/component/spinner";
import view from "ui/view";
import table, { sortOrders } from "ui/component/dynamic-table";
import icon from "ui/component/icon";
import {
  assessmentAdvancedFilter,
  assessmentTextFilter,
} from "util/filter";
import { assessmentsSort } from "util/sort";
import actionBarSearch from "ui/component/action-bar-search";
import advancedAssessmentSearchModal from "ui/component/modal/advanced-assessment-search";
import dashboardLayout from "ui/page/layout/dashboard";
import { usFullDateFormat } from "util/date";
import { guard } from "util/feature-flag";
import { defaultManageState, defaultSearchParams, searchModes } from "./state";

let modal, header, loading, manage, search;

/**
 * Clears search results.
 * @function clearSearchResult
 * @private
 */
const clearSearchResult = () => {
  manage.update({
    searchMode: searchModes.NONE,
    searchParams: defaultSearchParams,
  });
};

/**
 * Updates the search params with the courses of the basic search filter.
 *
 * @function doFilter
 * @private
 */
const doFilter = (filter) => {
  manage.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  });
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function showAdvancedSearchModal
 * @private
 */
const showAdvancedSearchModal = async () => {
  clearSearchResult();
  const searchParams = await modal.async(
    advancedAssessmentSearchModal({
      params: manage.state.searchParams,
    }, modal),
  );
  if (searchParams) {
    manage.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
  }
};

export const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "startDate":
      return (sortOrder === sortOrders.ASC
        ? assessmentSortCriteria.START_DATE_ASC
        : assessmentSortCriteria.START_DATE_DESC);
    case "endDate":
      return (sortOrder === sortOrders.ASC
        ? assessmentSortCriteria.END_DATE_ASC
        : assessmentSortCriteria.END_DATE_DESC);
    case "gradingScheme":
      return (sortOrder === sortOrders.ASC
        ? assessmentSortCriteria.GRADING_SCHEME_ASC
        : assessmentSortCriteria.GRADING_SCHEME_DESC);
    case "title":
    default:
      return (sortOrder === sortOrders.ASC
        ? assessmentSortCriteria.TITLE_ASC
        : assessmentSortCriteria.TITLE_DESC);
  }
};

const onSortChange = (self) => (sortColumn) => {
  let sortOrder;
  if (sortColumn === self.state.sortColumn) {
    if (self.state.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  self.update({
    sortOrder,
    sortColumn,
  });
};

const tableRow = (state) => (assessment) => {
  const course = state.courses.get(assessment.courseId);
  return ({
    PLACEHOLDER: assessment.PLACEHOLDER,
    title: a([
      icon.solid("edit"),
      assessment.title,
    ], `/admin/assessment-profile?id=${assessment.id}`),
    startDate: span(assessment.startDate ? usFullDateFormat(assessment.startDate) : "-"),
    endDate: span(assessment.endDate ? usFullDateFormat(assessment.endDate) : "-"),
    course: a(course.title, `/admin/course-profile?id=${course.id}`),
  });
};

const showManageAssessments = (self) => section("#manage-assessments", table({
  columnLabels: ["Title", "Start Date", "End Date", "Course"],
  columns: ["title", "startDate", "endDate", "course"],
  placeholder: self.state.loading > 0 ? spinner() : "No assessments found.",
  sortColumn: self.state.sortColumn,
  sortOrder: self.state.sortOrder,
  rows: self.state.filteredAssessments.map(tableRow(self.state)),
  onSortChange: onSortChange(self),
  loading: self.state.loading > 0,
}));

const showSearch = (self) => actionBarSearch({
  resultCount: self.state.filteredAssessments.length,
  filter: self.state.searchParams.filter,
  showClear: self.state.searchMode !== searchModes.NONE,
  onSearch: (filterText) => doFilter(filterText),
  onAdvancedSearch: showAdvancedSearchModal,
  onClear: clearSearchResult,
  title: "All Assessments",
});

const hasModules = (state) => state.modules.size
  && [...state.modules.values()]
    .reduce((acc, cur) => acc && !cur.PLACEHOLDER, true);

const doUpdate = (self) => {
  const {
    searchMode,
    assessments,
    sortColumn,
    sortOrder,
    searchParams,
  } = self.state;
  let filteredAssessments;
  const items = [...assessments.values()];
  switch (searchMode) {
    case searchModes.BASIC:
      filteredAssessments = items.filter(assessmentTextFilter(searchParams.filter));
      break;
    case searchModes.ADVANCED:
      filteredAssessments = items.filter(assessmentAdvancedFilter(searchParams));
      break;
    case searchModes.NONE:
    default:
      filteredAssessments = [...items];
  }
  filteredAssessments.sort(assessmentsSort(getSortCriteria(sortColumn, sortOrder)));
  self.updateState({ filteredAssessments, hasModules: hasModules(self.state) });
  self.patch(showManageAssessments(self));
  if (search) search.update(self.state);
};

export default async function manageAssessments(selector) {
  guard(featureTypes.LMS);
  courseStream.search([defaultSearchParams, 0]);
  const title = "Manage Assessments";
  ({ modal, loading, header } = dashboardLayout(
    selector,
    [
      actionBar(div("#search")),
      widget(placeholder("Loading...", "#manage-assessments")),
    ],
    title,
    true,
  ));
  header.update({ title });
  loading.show();

  manage = view.create(showManageAssessments, doUpdate)(
    '#manage-assessments',
    defaultManageState,
  );
  manage.bindStreams(
    [
      ["courses", courseStream.all$],
      ["modules", courseStream.getModules()],
      ["assessments", courseStream.getAssessments()],
      ["gradingSchemes", courseStream.getGradingSchemes()],
      ["loading", courseStream.pending$],
    ],
    () => loading.hide(),
    new Set(["courses"]),
  );

  search = view.create(showSearch)("#search", defaultManageState);
}
