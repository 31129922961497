/**
 * Type definitions and sanitation functions for dynamic pages.
 *
 * @module model/dynamic-page
 * @category Model
 * @subcategory Dynamic Page
 */
import { endpoints } from "api/constants";
import log from "log";
import {
  orNull,
  required,
  validArray,
  validBoolean,
  validInt,
  validObject,
  validString,
  validUUID,
} from "model/constraints";
import { responseToFileDescriptor } from "model/file-descriptor";
import { validVideoSortCriteria } from "model/metadata/constraints";
import { searchDataModes } from "model/search/constants";
import { baseUrlSync } from "api/core-util";
import { PAGE_DATA_VERSION, pageTypes, pageTypesSet, slideTypes } from "./constants";
import {
  validSlug,
  validSearchDataMode,
  validSearchDataPreset,
  validPageType,
} from "./constraints";

const emptyFn = () => {};

export const getPageFileUrl = (pageId, subPageId, descriptorId) => () => {
  if (!pageId || !subPageId || !descriptorId) return "";
  return `${baseUrlSync()}${endpoints.PAGE_FILE(pageId, subPageId)}/${descriptorId}`;
};

export const makeSlide = (parentId, id) => (partial) => {
  const getUrl = partial.type === slideTypes.IMAGE
    ? getPageFileUrl(parentId, id, partial.id)
    : emptyFn;
  return ({
    ...partial,
    url: getUrl(),
    getUrl,
  });
};

/**
 * Populate search data.
 *
 * Needed because slides need file URLs mapped.
 *
 * @function makeSearchData
 * @param {Object} data search data
 * @param {UUID} parentId id of the parent page of the section
 * @param {UUID} id id of the section to which the data belongs
 */
export const makeSearchData = (data, parentId, id) => ({
  ...(data || {}),
  slides: data?.slides?.map(makeSlide(parentId, id)),
});

/**
 * A user created/modified page definition. Used by frontend to look up and display
 * a page.
 *
 * @typedef DynamicPage
 * @property {UUID} id uuid
 * @property {string} title page title
 * @property {string} slug URI component used to navigate to the page
 * @property {Array<UUID>} subPageIds
 * @property {PageType} type section type
 *           {@link module:model/dynamic-page/constants.pageTypes}
 * @property {string} [text] section text
 * @property {object} [searchData] section search data
 * @property {Array<ImageDescriptor>} [sliderImages] section image file descriptors
 * @property {UUID} parentId
 * @property {UUID} [itemId] section's associated metadata UUID
 */

/**
 * Make a page object.
 */
export const makePage = (partial) => ({
  id: partial.id,
  title: partial.title || "",
  slug: partial.slug || "",
  subPageIds: partial.subPageIds || [],
  sections: partial.sections || [],
  type: partial.type || pageTypes.UNKNOWN,
  text: partial.text || "",
  searchData: makeSearchData(partial.searchData, partial.parentId, partial.id),
  images: partial.images || [],
  parentId: partial.parentId || null,
  itemId: partial.itemId || null,
  fileUrls: new Map(
    partial.images
      ?.map((id) => [id, getPageFileUrl(partial.parentId, partial.id, id)])
    || [],
  ),
  getFileUrl: new Map(
    partial.images
      ?.map((id) => [id, getPageFileUrl(partial.parentId, partial.id, id) || emptyFn])
    || [],
  ),
});

/**
 * As DynamicPage, but with its entity properties expanded into the referenced objects.
 *
 * @typedef ExpandedDynamicPage
 * @property {UUID} id uuid
 * @property {string} title page title
 * @property {string} slug URI component used to navigate to the page
 * @property {Array<UUID>} subPages
 * @property {Array<DynamicPage|ExpandedDynamicPage>} sections
 * @property {Array<DynamicPage|ExpandedDynamicPage>} children non-section subpages
 * @property {PageType} type page/section type
 *           {@link module:model/dynamic-page/constants.pageTypes}
 * @property {?string} text section text
 * @property {?object} searchData section search data
 * @property {Array<ImageDescriptor>} [sliderImages] section image file descriptors
 * @property {Array<UUID>} parentId
 * @property {DynamicPage|ExpandedDynamicPage} parent
 * @property {?UUID} itemId section's associated metadata UUID
 * @property {?mixed} item
 */

/**
 * @typedef PageSectionSearchData
 * @property {"preset"|"advanced"} searchMode
 * @property {string} sortOrder sort criteria {@link module:api/constants~sortCriteria}
 * @property {string} [preset] search preset {@link module:api/constants~dynamicListPresets}
 * @property {boolean} [searchVideos] whether to search videos
 * @property {boolean} [searchDocuments] whether to search documents
 * @property {boolean} [searchPlaylists] whether to search Playlists
 */

/**
 * @typedef PageDTO
 * @property {string} title
 * @property {string} slug
 * @property {Array<PageSection>} sections
 */

/**
 * @typedef SlideDTO
 * @property {UUID} id file descriptor id
 * @property {string} name file name
 * @property {slideType} type slide type
 */

export const makeSlideDTO = (slide) => ({
  id: slide.id,
  name: slide.name,
  type: slide.type,
});

/**
 * Validates page section search data and makes a JSON text blob out of it.
 *
 * `partial.preset` is required if `partial.searchMode` is `preset`.
 *
 * `partial.searchVideos`, `partial.searchDocuments`, and `partial.searchPlaylists`
 * are required if `partial.searchMode` is `advanced`.
 *
 * @see {PageSectionSearchData}
 *
 * @function makeSearchDataDTO
 * @param {object} partial
 * @param {"preset"|"advanced"} partial.searchMode
 * @param {string} partial.sortOrder
 * @param {string} partial.terms
 * @param {boolean} partial.searchVideos
 * @param {boolean} partial.searchDocuments
 * @param {boolean} partial.searchPlaylists
 * @param {number} partial.maxItems
 * @param {object[]} partial.entries
 * @param {string} partial.leftSideText
 * @param {string} partial.rightSideText
 * @param {boolean} partial.asContentCard
 * @param {cardFormatOptions} partial.cardSize
 * @param {boolean} partial.short
 * @param {object[]} partial.slides
 * @param {string} [partial.preset]
 */
const makeSearchDataDTO = (partial) => {
  try {
    const searchData = {
      mode: required(
        partial.mode,
        "DynamicPage.searchData.mode",
        validSearchDataMode,
      ),
      sortOrder: required(
        partial.sortOrder,
        "DynamicPage.searchData.sortOrder",
        validVideoSortCriteria,
      ),
      maxItems: required(
        partial.maxItems,
        "DynamicPage.searchData.maxItems",
        validInt,
      ),
      searchVideos: required(
        partial.searchVideos,
        "DynamicPage.searchData.searchVideos",
        validBoolean,
      ),
      searchDocuments: required(
        partial.searchDocuments || partial.searchPDFs || false, // bootstrap old sections
        "DynamicPage.searchData.searchDocuments",
        validBoolean,
      ),
      searchPlaylists: required(
        partial.searchPlaylists,
        "DynamicPage.searchData.searchPlaylists",
        validBoolean,
      ),
      terms: orNull(
        partial.terms,
        "DynamicPage.searchData.terms",
        () => validArray(
          partial.terms,
          "DynamicPage.searchData.terms[entry]",
          validString,
        ),
      ),
      collapsible: orNull(partial.collapsible, "DynamicPage.searchData.collapsilbe", validBoolean) || undefined,
      entries: partial?.entries,
      leftSideText: partial?.leftSideText,
      rightSideText: partial?.rightSideText,
      asContentCard: partial?.asContentCard,
      cardSize: partial?.cardSize,
      short: partial?.short,
      slides: partial?.slides?.map(makeSlideDTO),
      version: PAGE_DATA_VERSION,
    };
    if (searchData.mode === searchDataModes.PRESET) {
      searchData.preset = required(
        partial.preset,
        "DynamicPage.searchData.mode",
        validSearchDataPreset,
      );
    }
    return JSON.stringify(searchData);
  } catch (e) {
    log.error("Error in search data blob", partial, e);
    throw e;
  }
};

/**
 * Compares the given value to the set of page types and returns
 * either the correct type or pageTypes.UNKNOWN if it doesn't exist
 * in the list.
 *
 * @param {?string} type
 * @return {PageType}
 */
export const getPageType = (type) => {
  if (pageTypesSet.has(type)) return type;
  return pageTypes.UNKNOWN;
};

const dataV1ToV2 = (response, data) => ({
  ...(data || {}),
  slides: [...response.sliderImages.map((image) => ({
    id: image.id,
    name: image.fileName,
    type: slideTypes.IMAGE,
  }))],
  version: PAGE_DATA_VERSION,
});

/**
 * Handles a page response DTO.
 *
 * Found in all `/api/page` endpoints.
 *
 * @function responseToPage
 * @param {Object} response a response DTO from a page endpoint
 * @param {string} response.id page UUID
 * @param {string} response.title the page's title
 * @param {string} response.slug the page's human-readable URI component
 * @param {Array<FileDescriptor>} response.sliderImages slider images
 * @param {Array<UUID>} response.subPages sub-page UUID
 * @param {UUID} response.parentPage parent page UUID
 * @return DynamicPage
 */
export const responseToPage = (response) => ({
  id: response.id,
  title: response.title || null,
  text: response.text || null,
  itemId: response.itemId,
  type: getPageType(response.type),
  searchData: (() => {
    try {
      let data = JSON.parse(response.searchText);
      if (!data?.version || data?.version < PAGE_DATA_VERSION) {
        data = dataV1ToV2(response, data);
      }
      return makeSearchData(data, response.parentPage, response.id);
    } catch (e) {
      return response.searchText;
    }
  })(),
  images: response.sliderImages instanceof Array
    ? response.sliderImages.map(responseToFileDescriptor)
    : [],
  slug: response.slug?.toLowerCase() || null, // lowercase to fix backend auto-slugs
  parentId: response.parentPage,
  subPageIds: response.subPages,
});

/**
 * Validates and sanitizes a dynamic page for use in an api call.
 *
 * @function makePageDTO
 * @param {object} partial
 * @param {Array<FileDescriptor>} [partial.images] section image file descriptor IDs
 * @param {string} [partial.itemId] section's associated metadata UUID
 * @param {object} [partial.searchData] section search data
 * @param {string} partial.slug
 * @param {string} [partial.text] section text
 * @param {string} partial.title
 * @param {PageType} partial.type section type
 *                      {@link module:api/constants~pageTypes}
 * @return PageDTO
 */
export const makePageDTO = (partial) => ({
  itemId: orNull(partial.itemId, "DynamicPage.UUID", validUUID),
  searchText: partial.searchData?.mode
    ? makeSearchDataDTO(validObject(partial.searchData))
    : null,
  sliderImages: orNull(
    partial.images?.map((image) => image.id),
    "DynamicPage.images",
    validArray,
  ),
  subPageIds: orNull(
    partial.subPageIds,
    'DynamicPage.subPageIds',
    () => validArray(
      partial.subPageIds,
      'DynamicPage.subPageIds',
      validUUID,
    ),
  ) || [],
  slug: orNull(partial.slug, 'DynamicPage.slug', validSlug),
  text: orNull(partial.text, "DynamicPage.text", validString),
  title: orNull(partial.title, 'DynamicPage.title', validString),
  type: validPageType(partial.type, "DynamicPage.type"),
});

/**
 * Validates and sanitizes a sub-page for use in api calls that manipulate
 * page heirarchies.
 *
 * @function makeSubPageDTO
 * @param {object} partial
 * @param {UUID} id
 */
export const makeSubPageDTO = (partial) => ({
  subPageId: validUUID(partial.id, "DynamicPageSubPage.id"),
});
