/**
 * Endpoints dealing with logged-in user's profile.
 *
 * @module api/me
 * @category API
 */
import cache from "cache";
import {
  del,
  get,
  put,
} from "api/request";
import { endpoints } from "api/constants";
import { responseToUser, makeUserUpdateDTO } from "model/user";

/**
 * Get the currently authenticated user's profile data.
 *
 * @function getMe
 * @return {User}
 */
export const getMe = async () => responseToUser(
  (await get(`${endpoints.USER}/me`)).body,
);

/**
 * Updates current user's account information.
 *
 * @function updateMe
 * @param {object} data
 * @param {string} data.username
 * @param {string} [data.firstName]
 * @param {string} [data.lastName]
 * @param {string} [data.cellPhone]
 * @param {string} [data.email]
 * @param {string} [data.password]
 * @return {User}
 */
export const updateMe = async (data) => responseToUser((await put(
  endpoints.USER_SELF,
  null,
  makeUserUpdateDTO(data),
)).body);

/**
 * Deletes the current user's account.
 *
 * @function delete
 * @return {boolean} indicating success
 */
export const deleteMe = async () => {
  const { ok } = (await del(endpoints.USER_SELF));
  if (ok) { cache.clear(); }
  return ok;
};
