/**
 * The user-facing forgot password FAQ page.
 *
 * @module ui/page/faq/forgot-password
 * @category Pages
 * @subcategory User Facing
 */
import { a, div, h1, h3, p } from "ui/html";
import cache from "cache";
import api from "api";
import { pageVisit } from "api/v2/analytics";
import layout from "ui/page/layout";
import view from "ui/view";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";

const autoCloseConfiguration = {
  id: "forgot-password-faq",
  level: 0,
  autoCloseSameLevel: true,
};

const showForgotPasswordFaq = () => div(".questions", [
  h3([a(icon.sharp("arrow-left"), "/faq"), "Forgot Password"]),
  collapsible({
    title: "I forgot my password. How can I reset it?",
    autoCloseConfiguration,
  }, p(`No worries! It happens to the best of us. To reset your password, you can head to the Forgot Password
    page on the main login page. From there, you will be issued an OTP via e-mail or text. You will need to enter this on
    the Forgot Password page. Once your account has been verified, you are free to change your password. Login with the
    new credentials after this process has been completed.`)),
  collapsible({
    title: "I didn't receive my one-time password (OTP).",
    autoCloseConfiguration,
  }, p(`Oh no! We’re sorry to hear that. Please get in touch with your organization’s SmartEdge admin.
    They should be able to assist you.`)),
  collapsible({
    title: "Can I change my password if I am an external user?",
    autoCloseConfiguration,
  }, p(`No- you will not be able to change your password from your account. If you would like to update your
    password, you will need to do this on the external credentials account management page. Please get in touch with
    your organization’s SmartEdge admin for any assistance.`)),
]);

export default async function forgotPasswordFaq(selector) {
  const {
    header,
    loading,
  } = layout(
    selector,
    [
      div("#end-user-faq", [
        h1("Forgot Password"),
      ]),
    ],
  );

  loading.show();
  pageVisit(window.location.pathname);

  const [user, uiConfig] = await Promise.all([
    cache.getProfile(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    user,
    horizontal: uiConfig.horizontalMenu,
  };

  header.update(state);
  view.create(showForgotPasswordFaq)("#end-user-faq", state);
  loading.hide();
}
