/**
 * A help modal dialog for add user bulk page.
 *
 * @module ui/component/modal/help/add-user-bulk
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminAddUserBulkHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_ADD_USER_BULK,
    skipCache,
    async,
    sel: ".help-modal-add-user-bulk",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-user-bulk.svg"),
            p("User Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("User Mass Creation allows organizations to add a large group of users to SmartEdge at the same time.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-user-bulk.svg"),
            p("User Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("To get started, download the CSV template and enter the user information"),
              p("into the spreadsheet.", ".spaced-bottom"),
              p("Save the file as CSV.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-user-bulk.svg"),
            p("User Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("After you have prepared the CSV, go to Import CSV to upload the completed CSV. Our verification system will scan for any issues and confirm the"),
              p("correct user entries."),
              p("Double check and verify the necessary changes.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-user-bulk.svg"),
            p("User Mass Creation", ".title.spaced-bottom"),
            div(".text-container", [
              p("Then click SAVE. You’re all set!", ".spaced-bottom"),
              p("Please confirm the newly created users on the Manage Users interface.", ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
