import span from "ui/html/span";
import formatDistance from "date-fns/formatDistance";
import { reportReasons } from "@smartedge/em-message/constants";
import { inboxTypes } from "model/message/constants";
import { frozen } from "util/object";
import cache from "cache";
import { usFullDateFormat } from "util/date";

/**
 * List of chat interface types.
 *
 * @enum {string} chatTypes
 * @readonly
 * @property {string} ALL_MESSAGES
 * @property {string} REGULAR_MESSAGING
 * @property {string} NONE
 */
export const chatTypes = frozen({
  ALL_MESSAGES: "ALL_MESSAGES",
  REGULAR_MESSAGING: "REGULAR_MESSAGING",
  NONE: "NONE",
});

/**
 * A map of report reasons to user-friendly strings.
 *
 * @constant reportReasonsFriendly
 * @type {Map.<string, string>}
 * @readonly
 */
export const reportReasonsFriendly = Object.freeze(new Map([
  [reportReasons.SPAM, "Spam"],
  [reportReasons.HARASSMENT, "Harassment"],
  [reportReasons.OFFENSIVE, "Offensive"],
  [reportReasons.OTHER, "Other"],
]));

/**
 * List of chat modes.
 *
 * @enum {string} chatMode
 * @readonly
 * @property {string} MESSAGE_SELECTION
 * @property {string} DEFAULT
 */
export const chatMode = frozen({
  MESSAGE_SELECTION: "MESSAGE_SELECTION",
  DEFAULT: "DEFAULT",
});

export const timeStamp = (time) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const messageDate = new Date(time);
  const utc1 = Date.UTC(messageDate.getFullYear(), messageDate.getMonth(), messageDate.getDate());
  const now = new Date();
  const utc2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());
  const daysDistance = Math.floor((utc2 - utc1) / MS_PER_DAY);
  if (daysDistance > 0) {
    return span(usFullDateFormat(messageDate), ".time");
  }
  return span(`${formatDistance(messageDate, now)} ago`, ".time");
};

const getFromKnownUsers = (members = [], state = { knownUsers: new Map() }) => members.map(
  (u) => state.knownUsers.get(u.id),
);

export const getChannelUsersExtended = (state) => {
  const { selectedChannel, channels, channelMembers } = state;
  const me = cache.getProfile();
  if (!selectedChannel) {
    return getFromKnownUsers(
      channels.filter((c) => c.type === inboxTypes.DM && c.id !== me.id),
      state,
    );
  }
  if (selectedChannel.type === inboxTypes.DM) {
    return getFromKnownUsers(
      selectedChannel.members,
      state,
    );
  }
  return getFromKnownUsers(
    [...(channelMembers.get(selectedChannel.id)?.values() || [])],
    state,
  );
};

/**
 * Returns true if scrolled to the top of an element
 *
 * @param {HTMLElement} target
 * @returns {boolean}
 */
export const isScrolledToTop = (target) => {
  const { offsetHeight, scrollHeight, scrollTop } = target;
  return (offsetHeight - scrollHeight === Math.floor(scrollTop));
};

/**
 * Returns a link to a thread profile depending on a target
 *
 * @param {Array | string} target
 * @returns {string}
 */
export const getThreadProfileLink = (target) => {
  if (Array.isArray(target)) {
    return `/admin/thread-profile?userOneId=${target[0]}&userTwoId=${target[1]}`;
  }
  return `/admin/thread-profile?groupId=${target}`;
};
