/**
 * @module ui/page/admin/stats/state
 * @private
 * @category Pages
 * @subcategory Admin
 */
/***/
import { frozen } from "util/object";
import hashmap from "util/hash-map";

export const emptySearchParams = frozen({});

export const defaultState = frozen({
  user: null,
  documentCount: null,
  groupCount: null,
  labelCount: null,
  pageCount: null,
  playlistCount: null,
  userCount: null,
  videoCount: null,
  clientInfo: null,
  serverInfo: null,
  messageInfo: null,
  analyticsInfo: null,
  users: hashmap(),
});
