/**
 * A group of buttons, meant to be displayed as a bar. Presentation
 * is handled by the stylesheets. This is just a convenience component.
 *
 * @module ui/component/button-group
 * @category UI
 * @subcategory Components
 */
import { div } from "ui/html";

/**
 * @function buttonGroup
 * @param {module:ui/html/button[]} buttons
 * @param {string} sel
 * @return {module:ui/html/div~Div} div.button-group
 */
export default function buttonGroup(buttons, sel = "") {
  return div(`.button-group${sel}`, buttons);
}
