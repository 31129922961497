/**
 *
 * Constriants used for validating and sanitizing models, especially when they are
 * sent or received from the backend API. They check that a property meets requirements
 * and do explicit type coercion for mixed types.
 *
 * First-pass validation should happen in UI code; these are just last-line sanity
 * checks before we send user input to the API. They could be used in UI validation
 * but would require user-friendly error messages.
 *
 * All constraint functions return the passed value if it complies with the
 * constraint, and throw an {@link ConstraintError} if something fails.
 *
 * The fieldName param is displayed in the error's message if an error is thrown,
 * to help with debugging.
 *
 * @module model/constraints
 * @category Model
 */

import { iso8601 } from "util/date";
import { ConstraintError } from "model/error";

/**
 * Ensures the value is a string. Will coerce numbers to strings, but not other
 * types.
 *
 * @function validString
 * @param {mixed} value a number or string only
 * @param {string} fieldName displayed in the error message
 *
 * @throws ConstraintError if the value is not a string or number
 *
 * @return {string}
 */
export const validString = (value, fieldName) => {
  if (typeof value !== "string") {
    if (typeof value === "number" && !Number.isNaN(value)) {
      // we can coerce a number to a string, that's ok
      return `${value}`;
    }
    throw new ConstraintError(fieldName, "expected string", value);
  }

  return value;
};

/**
 * Ensures the value is a boolean. Does not support or coerce to general
 * truthy/falsyness.
 *
 * @function validBoolean
 * @param {boolean} value
 * @param {string} fieldName displayed in the error message
 * @throws ConstraintError if the value is not a boolean type
 *
 * @return {boolean}
 */
export const validBoolean = (value, fieldName) => {
  if (typeof value !== "boolean") {
    throw new ConstraintError(fieldName, "expecting boolean", value);
  }
  return value;
};

/**
 * Ensures the value is an integer. Will safely coerce strings to integers, but
 * throws if the result is NaN.
 *
 * @function validInt
 * @param {mixed} value anything that can parse to an int
 * @param {string} fieldName displayed in the error message
 *
 * @throws ConstraintError if the value cannot be coerced to an integer
 *
 * @return {number} an integer
 */
export const validInt = (value, fieldName) => {
  if ((typeof value === "undefined") || Number.isNaN(parseInt(value, 10))) {
    throw new ConstraintError(fieldName, "expected integer", value);
  }
  return parseInt(value, 10);
};

/**
 * Ensures the value is floating-point. Will safely coerce strings to floats, but
 * throws if the result is NaN.
 *
 * @function validFloat
 * @param {mixed} value anything that can parse to an int
 * @param {string} fieldName displayed in the error message
 *
 * @throws ConstraintError if the value cannot be coerced to a float
 *
 * @return {number} a floating-point number
 */
export const validFloat = (value, fieldName) => {
  if ((typeof value === "undefined") || Number.isNaN(parseFloat(value, 10))) {
    throw new ConstraintError(fieldName, "expected floating point number", value);
  }
  return parseFloat(value, 10);
};

/**
 * Checks the value using the constraint function, and returns the valid value
 * or null if it doesn't pass the constraint.
 *
 * @function orNull
 * @param {mixed} value anything that can parse to an int
 * @param {string} fieldName displayed in the error message
 * @param {function} constraintFn one of the constraint functions
 *
 * @throws ConstraintError if the value is not null or callback throws an
 *                            error not of type ConstraintError
 *
 * @return {mixed} the return value of constraintFn or null if an ConstraintError was thrown
 */
export const orNull = (value, fieldName, constraintFn) => {
  if (value === null) return null;
  try {
    return constraintFn(value, fieldName);
  } catch (e) {
    if (e.name === "ConstraintError") {
      return null;
    }
    throw e;
  }
};

/**
 * Ensures the value exists, is valid and has a non-empty, non-null value.
 *
 * @function required
 * @param {mixed} value
 * @param {string} fieldName
 * @param {function} constraintFn one of the constraint functions
 *
 * @throws ConstraintError if the value is null, undefined or empty
 *
 * @return {mixed} a valid value
 */
export const required = (value, fieldName, constraintFn) => {
  if (typeof value === "undefined" || value === null || value === "") {
    throw new ConstraintError(fieldName, "field is required", value);
  }
  return constraintFn(value, fieldName);
};

/**
 * Ensures the value is an array, optionally with a per-item constraint.
 *
 * @function validArray
 * @param {mixed} value
 * @param {string} fieldName
 * @param {function} [constraintFn] optional, applied to each array member if defined
 *
 * @throws ConstraintError if the value is not an array or if its members do
 *                            not pass the constraintFn callback
 *
 * @returns {mixed} a valid value
 */
export const validArray = (value, fieldName, constraintFn) => {
  if (Array.isArray(value)) {
    if (typeof constraintFn === "function") {
      value.forEach((entry) => constraintFn(entry, `${fieldName} member`));
    }
    return value;
  }
  throw new ConstraintError(fieldName, "expected array", value);
};

/**
 * Ensures the value is an object.
 *
 * @function validObject
 * @param {mixed} value
 * @param {string} fieldName
 * @throws ConstraintError if the value is not an array or if its members do
 *                            not pass the constraintFn callback
 *
 * @returns {mixed} a valid value
 */
export const validObject = (value, fieldName) => {
  if (value instanceof Object) {
    return value;
  }
  throw new ConstraintError(fieldName, "expected object", value);
};

/**
 * Ensures the value is a member of the provided enumeration (as a Set).
 *
 * @function validMember
 * @param {mixed} value
 * @param {string} fieldName
 * @param {Set} set collection of allowed values
 * @throws ConstraintError if the value is not a member of the set
 * @return {mixed} the value
 */
export const validMember = (value, fieldName, set) => {
  if (set.has(value)) {
    return value;
  }
  throw new ConstraintError(fieldName, `expected value to be one of (${[...set].join("|")})`, value);
};

/**
 * Ensures the value is a properly formatted UUID string.
 *
 * @function validUUID
 * @param {string} value
 * @param {string} fieldName
 * @throws ConstriantError if the value is not a UUID
 * @return {string} the value
 */
export const validUUID = (value, fieldName) => {
  validString(value, fieldName);
  const match = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/.exec(value);
  if (!match || match[0] !== value) {
    throw new ConstraintError(fieldName, "expected UUID", value);
  }
  return value;
};

/**
 * Ensures the value is a properly formatted URL.
 *
 * @function validUrl
 * @param {string} value
 * @param {string} fieldName
 * @throws ConstriantError if the value is not a UUID
 * @return {string} the value
 */
export const validUrl = (value, fieldName) => {
  validString(value, fieldName);
  const match = /^(https?|ftp):\/\/[^\s/$.?#].\S*$|^www\.[^\s/$.?#].\S*$/i
    .exec(value);
  if (!match || match[0] !== value) {
    throw new ConstraintError(fieldName, "expected URL", value);
  }
  return value;
};

/**
 * Ensures the value is a properly formatted relative or fully-qualified URL.
 *
 * @function validUrlRelative
 * @param {string} value
 * @param {string} fieldName
 * @throws ConstriantError if the value is not a URL
 * @return {string} the value
 */
export const validUrlRelative = (value, fieldName) => {
  validString(value, fieldName);
  const match = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$|^[/?#]\S*$/i
    .exec(value);
  if (!match || match[0] !== value) {
    throw new ConstraintError(fieldName, "expected relative URL", value);
  }
  return value;
};

/**
 * Ensures the value is a properly formatted iso8601 date string.
 * @param {Date|string} value
 * @param {string} fieldName
 * @throws ConstraintError if the value is not processable
 * @return {string}
 */
export const validDate = (value, fieldName) => {
  try {
    if (value instanceof Date) {
      return iso8601(value);
    }
    if (typeof value === "string") {
      return iso8601(new Date(value));
    }
  } catch (e) {
    // whatever went wrong throw a constraint error below
  }
  throw new ConstraintError(fieldName, "expected date", value);
};

/**
 * Valid members of the criteria fields in metadata searches.
 *
 * @constant searchCriteria
 * @type {Set.<string>}
 * @readonly
 */
export const searchCriteria = Object.freeze(new Set(['ALL', 'ANY', 'NONE']));

/**
 * Set of valid sort criteria for metadata searches.
 *
 * @constant sortCriteria
 * @type {Set.<string>}
 * @readonly
 */
export const sortCriteria = Object.freeze(
  new Set([
    'BY_TITLE_ASC',
    'BY_TITLE_DESC',
    'BY_CREATION_DATE_ASC',
    'BY_CREATION_DATE_DESC',
  ]),
);

/**
 * Ensures the value is one of the enumerated criteria in
 * {@see module:api/constants~searchCritieria}
 *
 * @function validSearchCriteria
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not valid search criteria
 *
 * @return {string} the value
 */
export const validSearchCriteria = (value, fieldName) => validMember(
  value,
  fieldName,
  searchCriteria,
);

/**
 * Ensures the value is one of the enumerated criteria in {@link module:api/constants.sortCriteria}
 *
 * @function validSortCriteria
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not valid sort criteria
 *
 * @return {string} the value
 */
export const validSortCriteria = (value, fieldName) => validMember(
  value,
  fieldName,
  sortCriteria,
);
