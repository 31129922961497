/**
 * The main admin page for managing users.
 *
 * Corresponds to `markup/admin/manage-users.html`
 *
 * @module ui/page/admin/users/manage
 * @category Pages
 * @subcategory Admin - Users
 */
import api from "api";
import getConfig from "config";
import { featureTypes } from "model/config/constants";
import { userSortCriteria } from "model/user/constants";
import div from "ui/html/div";
import actionBar from "ui/component/dashboard-action-bar";
import actionBarSearch from "ui/component/action-bar-search";
import advancedUserSearch from "ui/component/modal/advanced-user-search";
import { sortOrders } from "ui/component/dynamic-table";
import widget from "ui/component/dashboard-widget";
import tabs from "ui/component/tabs";
import view from "ui/view";
import { guard } from "util/feature-flag";
import { userTextFilter, userAdvancedFilter } from "util/filter";
import { merge } from "util/object";
import { userSort } from "util/sort";
import dashboardLayout from "ui/page/layout/dashboard";
import {
  defaultSearchParams,
  defaultManageState as defaultState,
  searchModes,
  manageModes,
  manageModeTabIndex,
} from "./state";
import manageUsersTable from "./manage-users-table";
import approveUsersTable from "./approve-users-table";

let modalView;
let searchView;
let manageUsersView;

/**
 * Clears search results.
 * @function onClear
 * @private
 */
const onClear = () => {
  manageUsersView.update({
    searchMode: searchModes.NONE,
    searchParams: {
      ...defaultSearchParams,
      filter: "",
    },
  });
};

/**
 * Updates the search params with the contents of the basic search filter.
 *
 * @function onSearch
 * @private
 */
const onSearch = (filter) => {
  manageUsersView.update({
    searchMode: searchModes.BASIC,
    searchParams: {
      ...defaultSearchParams,
      filter,
    },
  });
};

/**
 * Raises the advanced search modal when "advanced" button is clicked, and updates
 * the search params if the modal gave a result.
 *
 * @function onAdvancedSearch
 * @private
 */
const onAdvancedSearch = async () => {
  onClear();
  const searchParams = await modalView.async(
    advancedUserSearch({
      params: manageUsersView.state.searchParams,
    }, modalView),
  );
  if (searchParams) {
    manageUsersView.update({
      searchMode: searchModes.ADVANCED,
      searchParams: { ...defaultSearchParams, ...searchParams },
    });
  }
};

/**
 * Figures out which userSortCriteria to used based on sortColumn and sortOrder
 * state properties.
 * @function getSortCriteria
 * @private
 */
const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "fullName":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.FULLNAME_ASC
        : userSortCriteria.FULLNAME_DESC);
    case "cellPhone":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.CELLPHONE_ASC
        : userSortCriteria.CELLPHONE_DESC);
    case "email":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.EMAIL_ASC
        : userSortCriteria.EMAIL_DESC);
    case "enabled":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.ENABLED_ASC
        : userSortCriteria.ENABLED_DESC);
    case "external":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.EXTERNAL_DESC
        : userSortCriteria.EXTERNAL_ASC);
    case "authorities":
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.AUTHORITIES_ASC
        : userSortCriteria.AUTHORITIES_DESC);
    case "username":
    default:
      return (sortOrder === sortOrders.ASC
        ? userSortCriteria.USERNAME_ASC
        : userSortCriteria.USERNAME_DESC);
  }
};

/**
 * Sets up the search action bar.
 * @function showSearch
 * @private
 */
const showSearch = (self) => actionBarSearch({
  resultCount: (self.state.mode === manageModes.MANAGE)
    ? self.state.filteredUsers.length
    : self.state.unapprovedUsers.length,
  filter: self.state.searchParams.filter,
  showClear: self.state.searchMode !== searchModes.NONE,
  title: (self.state.mode === manageModes.MANAGE)
    ? "All Users"
    : "Pending",
  onSearch,
  onAdvancedSearch,
  onClear,
});

const showManageUsers = (theView) => tabs(
  ["All Users", "Pending"],
  [
    manageUsersTable(theView),
    approveUsersTable(theView, modalView),
  ],
  manageModeTabIndex.get(theView.state.mode),
  "#manage-users",
  {
    on: {
      "em:tab-change": (ev) => {
        theView.update({
          mode: (
            [...manageModeTabIndex.entries()].find((entry) => entry[1] === ev.detail.id)
            || [manageModes.MANAGE]
          )[0],
        });
      },
    },
  },
);

const doUpdate = (self) => {
  const {
    searchMode,
    users,
    sortColumn,
    sortOrder,
    searchParams,
  } = self.state;
  let filteredUsers;
  switch (searchMode) {
    case searchModes.BASIC:
      filteredUsers = users.filter(userTextFilter(searchParams.filter));
      break;
    case searchModes.ADVANCED:
      filteredUsers = users.filter(userAdvancedFilter(searchParams));
      break;
    case searchModes.NONE:
    default:
      filteredUsers = [...users];
  }
  filteredUsers.sort(userSort(getSortCriteria(sortColumn, sortOrder)));
  const unapprovedUsers = filteredUsers.filter(
    (u) => u.approved || u.approving || u.deleting || u.deleted || !u.enabled,
  );
  self.updateState({ filteredUsers, unapprovedUsers });
  self.render();
  if (searchView) searchView.update(self.state);
};

const getFullName = (user) => (user.fullName
  ? user.fullName
  : [user.firstName, user.lastName].filter((n) => n !== null).join(" "));

/**
 * @function manageUsers
 * @param {module:ui/html~Selector} selector root element for the manage users view
 */
export default async function manageUsers(selector) {
  guard(featureTypes.USER_MANAGEMENT);
  const title = "Manage Users";
  const { modal, loading, header } = dashboardLayout(
    selector,
    [
      actionBar(div("#action-bar-search")),
      widget(div('#manage-users')),
    ],
    title,
    true,
  );
  modalView = modal;

  loading.show();

  const [user, config, users] = await Promise.all([
    api.user.getMe(),
    getConfig(),
    api.user.list().then((usrs) => usrs.map((usr) => ({
      ...usr,
      fullName: getFullName(usr),
    }))),
  ]);

  const state = merge(
    defaultState,
    {
      user,
      users,
      filteredUsers: [...users],
      unapprovedUsers: [...users.filter((u) => !u.enabled)],
      config,
      title,
    },
  );

  manageUsersView = view.create(showManageUsers, doUpdate)('#manage-users', state);
  searchView = view.create(showSearch)("#action-bar-search", state);
  header.update(state);
  loading.hide();
}
