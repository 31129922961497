/**
 * Maps v.1 menus to v.2 menus.
 *
 * @module model/site/menu
 * @category Model
 * @private
 * @subcategory Site
 */
/***/

/**
 * @function v1ToV2
 * @private
 * @return {MenuEntry}
 */
/* eslint-disable-next-line import/prefer-default-export */
export const v1ToV2 = (response) => ({
  ...response,
  accountRequired: false,
  entries: response.entries?.map(v1ToV2),
});
