/**
 * Deals with all requests to and responses from the SmartEdge API.
 *
 * Submodules contain individual API call functions.
 *
 * - ACL: {@link module:api/acl}
 * - Authentication: {@link module:api/authentication}
 * - Users: {@link module:api/user}
 * - Media (PDF, video, and image files) : {@link module:api/media}
 *   - this is deprecated in favor of file descriptors (WIP)
 * - Metadata (Documents, videos, playlists): {@link module:api/metadata}
 * - Dynamic Pages: {@link module:api/page}
 * - File Descriptors: {@link module:api/file}
 * - Generic Requests & Authentication: {@link module:api/requests}
 * - Site Customization/Configuration: {@link module:api/site}
 * - Courses: {@link module:api/course}
 *
 * The API submodule does quite a lot of orchestration, sanitation, and error checking
 * of API requests:
 *
 * - {@link module:api/core} handles constructing requests, attaching authentication,
 *   and actually making the remote request (almost always via fetch API)
 * - {@link module:api/queue} manages queueing requests and orchestrates queue order
 *   and blocking/synchronous requests, such as requests for server status,
 *   configuration, and refreshing authentication which sometimes have to pause other
 *   requests to avoid race conditions
 *
 * Most of the time you don't need to worry about how that all works. The high-level
 * functions are designed to be ergonomic.
 *
 * See also the [cache module]{@link module:cache}, which some API calls use to store
 * data and bypass additional requests.
 *
 * ```js
 * import api from "lib/api";
 *
 * // get the current user's profile information
 * let user = await api.user.getMe();
 * log.debug(user); // { username: "Foo", firstName: "Bar", ... }
 *
 * // change the user's username
 * user = await api.user.updateMe({ username: "Mr. Cool" });
 * log.debug(user); // { username: "Mr. Cool", firstName: "Bar", ... }
 *
 * // get a collection of all available video metadata
 * const videos = await api.metadata.listVideos();
 * log.debug(videos); // [ { title: "Video 1", ... }, { title: "Video 2", ... }, ... ]
 * ```
 * The fact that under the hood those requests are being managed in a queue and getting
 * munged in half a dozen ways is completely transparent.
 *
 * Adding New Requests
 * ===================
 * If you need to add a new API function because a new endpoint has been added to the
 * backend, inspect existing requests to get an idea of how they're implemented. In
 * particular, you may need to make a couple new DTO factories under
 * {@link module:model} and then create a new request function in an appropriate
 * place (such as {@link module:api/metadata}).
 *
 * API functions should ideally accept zero or one objects as a parameter and return
 * an object representing the requested data, a scalar value, an array of data objects,
 * or boolean for success/failure requests that have no response.
 *
 * If you're feeling ambitious, and it makes sense to do so, consider implementing
 * a cache for the request data. This is useful for reducing burden on the backend and
 * apparent UI latency. In general this is a good idea whenever data may be requested
 * several times on a page, but does not need to be updated during a session or else
 * is updated due to user interaction. See for example:
 * - {@link module:api/metadata~getVideoPlaybackPosition}
 * - {@link module:api/metadata~updateVideoPlaybackPosition}
 *
 * Cache can also be used higher-level in a page, view or component.
 *
 * You shouldn't have to touch `core` or `queue` unless there's a bug to be fixed.
 *
 * @module api
 * @category Backend API
 */
import * as acl from "./acl";
import * as authentication from "./authentication";
import * as configuration from "./configuration";
import * as constants from "./constants";
import * as course from "./course";
import * as media from "./media";
import * as message from "./message";
import * as metadata from "./metadata";
import * as page from "./page";
import * as server from "./server";
import * as user from "./user";
import * as file from "./file";
import * as util from "./core-util";
import * as notification from "./notification";
import * as site from "./site";
import * as feedback from "./feedback";

export default {
  acl,
  authentication,
  configuration,
  constants,
  course,
  file,
  media,
  message,
  metadata,
  page,
  server,
  user,
  util,
  notification,
  site,
  feedback,
};
