/**
 * A form field that supports both fully-qualified and relative URLs.
 *
 * Relative URLs require a leading slash.
 *
 * @module ui/component/form-managed/field-url-relative
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { urlRelativeConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  tooLong: "URLs must be less than 255 characters.",
  taken: "This URL is already in use.",
  invalid: "URL must be in the form of 'https://example.com' for external links, or '/page-name' for internal links.",
});

/**
 * @private
 */
const defaultState = frozen({
  name: "url",
  label: "URL",
  constraints: urlRelativeConstraints,
  helpTexts,
  sel: "",
});

/**
 * A form field for URLs.
 *
 *
 * @function title
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="title"]
 * @param {string} [label="Title"]
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function url(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.first-name`,
  });
}
