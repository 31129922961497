/**
 * Fields for configuring an embedded video/image section.
 *
 * @module ui/component/modal/section-edit/embedded-video-image
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import title from "ui/component/form-managed/field-title";
import textarea from "ui/component/form-managed/field-textarea";
import toggle from "ui/component/form-managed/field-toggle";
import button from "ui/component/form-managed/button";
import { metadataTypes } from "model/metadata/constants";
import { fileTypes } from "model/file-descriptor/constants";
import { typeFilterAll } from "util/filter";
import metadataSelectModal from "ui/component/modal/metadata-select";
import fileSelectModal from "ui/component/modal/file-select";
import cameoMetadata from "ui/component/cameo-metadata";
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import message, { messageTypes } from "ui/component/message";

export const staticMessages = Object.freeze({
  selectItem: { type: messageTypes.ERROR, text: "Please select video or image" },
});

const isVideoSelected = (self) => !!self.state.section.itemId;

const isImageSelected = (self) => !!self.state.section.images?.length;

const selectVideoOrImage = async (self, modalView) => {
  const isVideo = await modalView.confirm(
    "What would you like to add?",
    null,
    "Metadata",
    "Image",
    false,
    true,
    true,
  );
  if (isVideo) {
    const selected = await modalView.async(metadataSelectModal.async({
      entries: self.state.metadata?.filter(
        (item) => item.type === metadataTypes.VIDEO || item.type === metadataTypes.DOCUMENT,
      ),
    }, modalView), false);
    self.update({
      section: {
        itemId: selected.id,
        item: selected,
        text: selected.type,
      },
      messages: [],
    });
  } else {
    const image = await modalView.async(fileSelectModal({
      uploads: self.state.files.filter(typeFilterAll(fileTypes.IMAGE)),
    }, modalView));
    self.update({
      section: { images: [image.file] },
      messages: [],
    });
  }
};

const removeVideoItem = (self) => {
  self.update({
    section: {
      itemId: null,
      item: null,
    },
  });
};

const removeImage = (self) => {
  self.update({
    section: { images: [] },
  });
};

const showSelectedItemCameo = (self) => {
  if (isVideoSelected(self)) {
    const metadata = self.state.section.item
      || self.state.metadata.find((m) => m.id === self.state.section.itemId);
    return cameoMetadata({
      metadata,
      controls: [
        button.icon({
          icon: "trash-alt",
          onClick: () => removeVideoItem(self),
          sel: ".danger",
        }),
      ],
    });
  }
  if (isImageSelected(self)) {
    const image = self.state.section.images?.[0];
    return cameo({
      sel: ".image-entry",
      icon: icon("image"),
      label: typeof image === "string" ? image : image.name,
      controls: [
        button.icon({
          icon: "trash",
          onClick: () => removeImage(self),
          sel: ".danger",
        }),
      ],
    });
  }
  return null;
};

export default function embeddedItem(self, modalView) {
  const { section } = self.state;
  return self.bind([
    [title, { value: section.title }],
    [textarea, { name: "leftSideText", label: "Left Side Text", value: section.searchData.leftSideText }],
    [textarea, { name: "rightSideText", label: "Right Side Text", value: section.searchData.rightSideText }],
    showSelectedItemCameo(self) || button.standIn({
      icon: "plus-circle",
      label: "Select Item",
      onClick: () => selectVideoOrImage(self, modalView),
    }),
    section.itemId ? [toggle.boxed, {
      toggled: section.searchData?.asContentCard || false,
      name: "asContentCard",
      label: "Show As Content Card",
      sel: ".inverse",
    }] : "",
    [toggle.boxed, {
      toggled: section.searchData?.collapsible || false,
      name: "collapsible",
      label: "Small Embed View",
      sel: ".inverse",
    }],
    ...self.state.messages.map((m) => message(m)),
  ]);
}
