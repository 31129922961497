/**
 * Fields for configuring a collapsible faq section.
 *
 * @module ui/component/modal/section-edit/faq
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
import title from "ui/component/form-managed/field-title";
import { label } from "ui/html";
import button from "ui/component/form-managed/button";
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import faqAddEntryModal from "./faq-add-entry";

const deleteEntry = (self, entry) => {
  const entries = self.state.section?.searchData?.entries || [];
  self.update({
    section: {
      searchData: {
        entries: entries.filter((e) => e !== entry),
      },
    },
  });
};

const editEntry = async (self, entry, modalView) => {
  const values = await modalView.async(faqAddEntryModal({ entry }, modalView));
  if (!values) {
    return;
  }
  const entries = self.state.section?.searchData?.entries || [];
  self.update({
    section: {
      searchData: {
        entries: entries.map((e) => {
          if (e === entry) {
            return values;
          }
          return e;
        }),
      },
    },
  });
};

const entryList = (self, modalView) => {
  const entries = self.state.section.searchData?.entries || [];
  return entries.map((entry) => cameo({
    label: entry.label,
    icon: icon("question-circle"),
    controls: [
      button.icon({
        icon: "edit",
        onClick: () => editEntry(self, entry, modalView),
        sel: ".primary",
      }),
      button.icon({
        icon: "trash",
        onClick: () => deleteEntry(self, entry),
        sel: ".danger",
      }),
    ],
  }));
};

const addEntry = async (self, modalView) => {
  const values = await modalView.async(faqAddEntryModal({}, modalView));
  if (!values) {
    return;
  }
  const { label: faqLabel, text } = values;
  const entries = self.state.section.searchData.entries || [];
  self.update({
    section: {
      searchData: {
        entries: [
          ...entries,
          {
            label: faqLabel,
            text,
          },
        ],
      },
    },
  });
};

/**
 * @function faqFields
 * @private
 *
 * @param self
 * @param {modalView} modalView
 * @return ChildEl[]
 */
export default (self, modalView) => {
  const { section } = self.state;
  return self.bind([
    [title, { value: section.title }],
    label("Entries"),
    ...entryList(self, modalView),
    button.standIn({
      icon: "plus-circle",
      label: "Add Entry",
      onClick: () => addEntry(self, modalView),
    }),
  ]);
};
