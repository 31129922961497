/**
 * The site UI configuration, stored in the site-config table.
 *
 * @module model/site/configuration
 * @category Model
 * @subcategory Site
 */
import { validBoolean } from "model/constraints";
import { CONFIGURATION_KEY } from "./constants";

/**
 * A UI configuration.
 *
 * @typedef UIConfiguration
 * @property {boolean} horizontalMenu
 */

/**
 * Maps an API response to a UIConfiguration
 * @function responseToConfiguration
 * @param {object} response
 * @return {UIConfiguration}
 */
export const responseToConfiguration = (response) => ({
  horizontalMenu: !!response.horizontalMenu,
});

/**
 * Validates a UI configuration for storage in the site config database.
 */
export const makeUIConfigurationDTO = (partial) => ({
  [CONFIGURATION_KEY]: JSON.stringify({
    horizontalMenu: validBoolean(partial.horizontalMenu, "UIConfiguration.horizontalMenu"),
  }),
});
