import log from "log";
import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import { button } from "ui/html";

export default (modalView) => collapsible({
  title: "Alert Dialogs",
  open: false,
}, [
  button(["Basic Alert Dialog", icon("bell-exclamation")], async () => {
    await modalView.alert(
      "This is a drop-in replacement for window.alert().",
    );
    log.debug("Alert dialog closed.");
  }, ".warn.subtle.forward"),
  button(["Custom Alert Dialog", icon("exclamation-triangle")], () => {
    modalView.customAlert({
      title: "Alert Dialog",
      body: "This is a custom alert",
      onConfirm: () => log.debug("Alert dialog closed."),
    });
  }, ".danger.subtle.forward"),
  button(["Labeled Alert", icon("comment-alt")], async () => {
    await modalView.alert(
      "My label is customized!",
      null,
      "Great!",
    );
    log.debug("Alert dialog closed.");
  }, ".subtle.forward"),
  button(["Alert With Body", icon("comment-alt")], async () => {
    await modalView.alert(
      null,
      "This is a drop-in replacement for window.alert().",
    );
    log.debug("Alert dialog closed.");
  }, ".subtle.forward"),
  button(["Dangerous Alert", icon("engine-warning")], () => {
    modalView.customAlert({
      title: "Alert Dialog",
      body: "This is a custom alert",
      onConfirm: () => log.debug("Alert dialog closed."),
      dangerous: true,
    });
  }, ".danger.subtle.forward"),
]);
