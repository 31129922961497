/**
 * A modal dialog encapsulating the content schedule functionality.
 *
 * Used on content bulk upload.
 *
 * @module ui/component/modal/content-schedule
 * @category UI
 * @subcategory Modal Dialogs
 */
import addWeeks from "date-fns/addWeeks";
import startOfDay from "date-fns/startOfDay";
import div from "ui/html/div";
import button from "ui/html/button";
import contentSchedule from "ui/component/content-schedule";
import closeFab from "ui/component/modal/component/close-fab";

const onSave = (modalView) => () => {
  const start = modalView.qs("input[name=startDate]")?.value;
  const end = modalView.qs("input[name=endDate]")?.value;
  modalView.resolve({
    startDate: start ? new Date(start) : null,
    endDate: end ? new Date(end) : null,
  });
};

/* eslint-disable no-use-before-define */
const onEnable = (modalView) => () => {
  modalView.patch(contentScheduleModal({
    startDate: startOfDay(new Date()),
    endDate: startOfDay(addWeeks(new Date(), 1)),
  }, modalView));
};

const onDisable = (modalView) => () => {
  modalView.patch(contentScheduleModal({
    startDate: null,
    endDate: null,
  }, modalView));
};
/* eslint-enable no-use-before-define */

export default function contentScheduleModal({ startDate, endDate }, modalView) {
  return div(
    ".dialog.content-schedule-dialog",
    [
      closeFab(true, modalView),
      div(
        ".body",
        [
          contentSchedule({
            startDate,
            endDate,
            onEnable: onEnable(modalView),
            onDisable: onDisable(modalView),
          }),
          button("Save", onSave(modalView)),
        ],
      ),
    ],
  );
}
