/**
 * Renderer for [dynamic pages]{@link ui/page/admin/dynamic}.
 *
 * @module ui/page/dynamic
 * @category Pages
 * @subcategory User Facing
 */
import { differenceInDays } from "date-fns";
import { enrollUserToCourse } from "api/v2/course/attendance";
import { pageVisit } from "api/v2/analytics";
import fromEvent from "xstream/extra/fromEvent";
import cache from "cache";
import log from "log";
import { featureTypesFrontend } from "model/config/constants";
import pageMenuStream from "data/site/page-menu";
import courseStream from "data/course";
import attendanceStream from "data/course/attendance";
import pageStream from "data/dynamic-page";
import abstractStream from "data/metadata/abstract";
import documentStream from "data/metadata/document";
import playbackStream from "data/metadata/playback-position";
import playlistStream from "data/metadata/playlist";
import videoStream from "data/metadata/video";
import { tee } from "data/stream/compose";
import meStream from "data/user/me";
import { pageTypes } from "model/dynamic-page/constants";
import { metadataTypes } from "model/metadata/constants";
import {
  div,
  main,
} from "ui/html";
import view from "ui/view";
import contentModal from "ui/component/modal/content";
import layout from "ui/page/layout";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import pwaAndroidPrompt from "ui/component/modal/pwa-android-prompt";
import pwaIosPrompt from "ui/component/modal/pwa-ios-prompt";
import { emit } from "util/event";
import hashmap from "util/hash-map";
import { merge } from "util/object";
import {
  getSlug,
  handleLoadingPageFail,
  reopenModal,
  setTitle,
} from "util/navigation";
import { featureEnabled } from "util/feature-flag";
import { isIos, isPWA } from "util/pwa";
import menuV2 from "ui/component/header/menu-v2";
import courseWelcomeModal from "ui/component/modal/help/course-welcome";
import coursePreviewModal from "ui/component/modal/course/course-preview";
import sectionCourseCarousel from "./section-course-carousel";
import sectionCourseGrid from "./section-course-grid";
import sectionCourseOverview from "./section-course-overview";
import sectionDynamicCarousel from "./section-dynamic-carousel";
import sectionDynamicGrid from "./section-dynamic-grid";
import sectionPlaylistCarousel from "./section-playlist-carousel";
import sectionPlaylistGrid from "./section-playlist-grid";
import sectionRichText from "./section-rich-text";
import sectionSlider from "./section-slider";
import sectionText from "./section-text";
import { defaultState, readyState } from "./state";
import sectionFaq from "./section-faq";
import sectionEmbeddedItem from "./section-embedded-item";
import { playlistItemFilterMap, searchResultFilterMap, scheduleFilter } from "./common";
import sectionTextHeader from "./section-text-header";

const FORCE_READY_TIMEOUT = 3000;

let loading, modal, header;

let visited = false;
const recordVisitOnce = (pageKey, pageId) => {
  if (!visited) {
    pageVisit(pageKey, pageId);
    visited = true;
  }
};

const showHelpModalIfCourseSectionPresent = (sectionType) => {
  if (
    (sectionType === pageTypes.COURSE_OVERVIEW
    || sectionType === pageTypes.COURSE_MODULE_GRID
    || sectionType === pageTypes.COURSE_MODULE_CAROUSEL)
    && !isHelpModalSeen(modalTypes.COURSE_WELCOME)
  ) {
    modal.show(courseWelcomeModal(modal));
    markModalAsSeen(modalTypes.COURSE_WELCOME);
  }
};

const displaySection = (self) => (sectionId) => {
  const { attendance, sections } = self.state;
  const section = sections.get(sectionId);
  if (!section) return "";
  showHelpModalIfCourseSectionPresent(section.type);
  switch (section.type) {
    case pageTypes.COURSE_OVERVIEW:
      return sectionCourseOverview(self.state, sectionId, attendance.get(section.id));
    case pageTypes.COURSE_MODULE_CAROUSEL:
      return sectionCourseCarousel(
        self.state,
        sectionId,
      );
    case pageTypes.COURSE_MODULE_GRID:
      return sectionCourseGrid(
        self.state,
        sectionId,
      );
    case pageTypes.TEXT:
      return sectionText(section);
    case pageTypes.TEXT_HEADER:
      return sectionTextHeader(section);
    case pageTypes.RICH_TEXT:
      return sectionRichText(section);
    case pageTypes.PLAYLIST_CAROUSEL:
      return sectionPlaylistCarousel(self.state, sectionId);
    case pageTypes.PLAYLIST_GRID:
      return sectionPlaylistGrid(self.state, sectionId);
    case pageTypes.IMAGE_SLIDER:
      // FIXME sections should not be manipulating the view
      return sectionSlider(self, sectionId);
    case pageTypes.DYNAMIC_LIST_CAROUSEL:
      return sectionDynamicCarousel(self.state, sectionId);
    case pageTypes.DYNAMIC_LIST_GRID:
      return sectionDynamicGrid(self.state, sectionId);
    case pageTypes.FAQ:
      return sectionFaq(section);
    case pageTypes.EMBEDDED_ITEM:
      return sectionEmbeddedItem(self.state, sectionId);
    default:
      return "";
  }
};

const hasSubmenu = (state) => !!state.sideMenu?.get(state.page?.id)?.entries?.length;

const showPage = (self) => {
  if (!self.state.page || self.state.page?.PLACEHOLDER === true) {
    return main("#page");
  }
  const sideMenu = self.state.sideMenu.get(self.state.page?.id);
  const pageContent = self.state.page.subPageIds.length
    ? self.state.page.subPageIds.map(displaySection(self))
    : "";
  return main(
    `#page${hasSubmenu(self.state) ? ".with-submenu" : ""}`,
    { key: `page-${self.state.page.id}` },
    hasSubmenu(self.state)
      ? [
        sideMenu?.entries?.length
          ? menuV2.dynamic(sideMenu?.entries, self.state.user)
          : "",
        div(".content", pageContent),
      ]
      : pageContent,
  );
};

const scroll = (v) => ({ detail: { list, position } }) => {
  const positions = hashmap(v.state.positions);
  positions.set(list.id, position);
  v.update({ positions });
};

const sectionsNotReady = (sectionIds, itemReady) => sectionIds.filter((id) => !itemReady.has(id));

const doUpdate = (self) => {
  const { page, itemReady, playback } = self.state;
  // [null] here so it won't detect as ready before page section ids are loaded
  const idsToCheck = page?.subPageIds.slice(0, 4) || [null];
  if (
    self.state.ready !== readyState.READY
    // check that first four (above fold) sections are ready to show
    && sectionsNotReady(idsToCheck, itemReady).length <= 0
    && !playback.PLACEHOLDER) {
    /* eslint-disable-next-line no-use-before-define */
    setReadyState(self, readyState.READY);
    loading.hide();
  } else if (self.state.ready) {
    self.render();
  }
};

const getFullItem = (self, item) => {
  switch (item.type) {
    case metadataTypes.VIDEO:
      return self.state.videos.get(item.id) || item;
    case metadataTypes.DOCUMENT:
      return self.state.documents.get(item.id) || item;
    case metadataTypes.LIST:
      return self.state.playlists.get(item.id) || item;
    default:
      return item;
  }
};

const showDeferredModal = (self, type, id, metadata) => {
  const { videos, documents, playlists, playback } = self.state;
  let listItem, populated;
  switch (type) {
    case metadataTypes.VIDEO:
      modal.async(contentModal({ metadata: videos.get(id), playback: playback?.get(id) }, modal));
      break;
    case metadataTypes.DOCUMENT:
      modal.async(contentModal({ metadata: documents.get(id) }, modal));
      break;
    case metadataTypes.LIST:
      listItem = playlists.get(id);
      populated = merge(playlists.get(id) || {}, {
        items: listItem.items?.map?.((item) => getFullItem(self, item)),
      });
      modal.async(contentModal({ metadata: populated }, modal));
      break;
    case metadataTypes.COURSE:
      modal.async(coursePreviewModal({ metadata }));
      break;
    default:
      break;
  }
};

const open = (self) => ({ detail: { type, id, metadata } }) => {
  if (self.state.ready === readyState.READY) {
    showDeferredModal(self, type, id, metadata);
  } else {
    fromEvent(document.body, "em:dynamic-content-ready", true)
      .addListener({ next: () => showDeferredModal(self, type, id, metadata) });
  }
};

// shows PWA install prompt if a pwa install event is deferred and it has been
// more than 2 days since the last time we showed it
const showPwaPrompt = async () => {
  if (!featureEnabled(featureTypesFrontend.PWA)) {
    log.info("PWA :: disabled");
    return;
  }
  const lastPwaPrompt = cache.getValue("pwa-prompted");
  const deferredAppPrompt = window.smartEdgeDeferredAppPrompt;
  if (isPWA()) {
    log.info("PWA :: already in app, skipping prompt");
  }
  if (!lastPwaPrompt || differenceInDays(new Date(), new Date(lastPwaPrompt)) > 2) {
    if (isIos()) {
      log.info("PWA :: showing iOS install prompt");
      await modal.async(pwaIosPrompt(modal));
      cache.storeValue("pwa-prompted", new Date().toISOString());
    } else {
      if (!deferredAppPrompt) {
        log.info("PWA :: no deferred prompt, skipping");
        return;
      }
      log.info("PWA :: trying install");
      const res = await modal.async(pwaAndroidPrompt(modal));
      if (res) {
        deferredAppPrompt.prompt();
        const { outcome } = await deferredAppPrompt.userChoice;
        log.info("PWA :: user choice", outcome);
      }
      cache.storeValue("pwa-prompted", new Date().toISOString());
    }
  }
};

const setReadyState = (self, ready) => {
  if (ready <= self.state.ready) return;
  if (self.state.ready === readyState.BEGIN) self.update({ ready });
  if (ready === readyState.READY) {
    emit(document.body, "em:dynamic-content-ready");
    showPwaPrompt();
  }
  self.update({ ready });
};

const enrollUser = (self) => async (e) => {
  loading.show("Enrolling...");
  const { attendance, user } = self.state;
  const result = await enrollUserToCourse(e.detail.course.id, user.id);
  if (result) {
    attendance.set(result.id, result);
    self.update({ attendance });
  } else {
    await modal.async("Something went wrong. Please try again in a little while.");
  }
  loading.hide();
};

/**
 * Shows embedded video section if it successfully loaded callback
 *
 * @param self
 * @returns {(function({detail: *}): void)|*}
 */
const showEmbeddedVideoSection = (self) => ({ detail: videoId }) => {
  const { sections } = self.state;
  const foundSection = Array.from(sections.values()).filter((s) => s.itemId === videoId);
  foundSection.forEach((section) => {
    document.getElementById(section.id)?.classList?.remove("hidden");
  });
};

/**
 * Build a dynamic page.
 *
 * @function dynamic
 */
export default async (selector) => {
  ({ header, loading, modal } = layout(selector, [main("#page")]));
  loading.show();
  const slug = getSlug();
  if (slug === "home") setTitle("Homepage");

  const self = view.create(showPage, doUpdate)("#page", defaultState);

  const topPage$ = pageStream.getSlug(slug).compose(tee((page) => {
    if (slug !== "home" && !page.PLACEHOLDER) {
      setTitle(page.title);
      if (header.state.title !== page.title) header.update({ title: page.title });
      recordVisitOnce(window.location.pathname, page.id);
    } else {
      recordVisitOnce("/", page.id);
    }
  },
  (error) => {
    log.error(error);
    handleLoadingPageFail(error, cache?.getProfile());
  }));

  self.bindStreams(
    [
      ["page", topPage$],
      ["user", meStream.get()],
      ["sections", topPage$
        .map((page) => page.subPageIds)
        .map(pageStream.getMany)
        .flatten(),
      ],
      ["courses", pageStream.getCourses()],
      ["courseModules", courseStream.getModules()],
      ["courseLectures", courseStream.getLectures()],
      ["searchResults", pageStream.getSearches().map(searchResultFilterMap)],
      ["assessments", courseStream.getAssessments()],
      ["evaluations", courseStream.getEvaluations()],
      ["attendance", attendanceStream.all$],
      // only subscribe to these streams; we'll request these items as needed below
      ["videos", videoStream.all$.map(scheduleFilter)],
      ["documents", abstractStream.all$.map(scheduleFilter)],
      ["pdf", documentStream.all$.map(scheduleFilter)],
      ["playlists", pageStream.getPlaylists().map(playlistItemFilterMap)],
      ["playback", playbackStream.get(cache.getProfile()?.id)
        .map((result) => result.positions),
      ],
      ["sideMenu", topPage$.map((page) => pageMenuStream.getMany([page.id])).flatten()],
    ],
    () => {
      setReadyState(self, readyState.FIRST_DATA);
      loading.hide();
    },
    new Set(["user", "page", "sections", "playback"]),
  );
  setTimeout(() => {
    if (self.state.ready !== readyState.READY) {
      setReadyState(self, readyState.READY);
      loading.hide();
    }
  }, FORCE_READY_TIMEOUT);

  // this will put playlist, search and course lecture metadata
  // into the metadata request streams
  // the items will come out from metadata all$'s that we subscribe to
  courseStream.getLectureMetadata();
  playlistStream.getItems();
  abstractStream.getItems();
  pageStream.getMetadataForEmbeddedItems();

  // fetch attendance once we have a userId
  meStream.all$.compose(tee((user) => courseStream.getAttendance(user.id)));

  // hold off on displaying until we have at least some results for each section
  // we will recieve itemReady state as soon as a page has its top-level item
  // or search result (e.g. course or playlist object, or first page of search)
  //
  // this doesn't take very long and it prevents jitter and layout shifts
  pageStream.ready$.compose(tee((itemReady) => (self.update({ itemReady }))));

  // lecture carousel scroll events
  fromEvent(document.body, "em:carousel-scroll").addListener({ next: scroll(self) });
  // modal open requests from metadata sections
  fromEvent(document.body, "em:modal").addListener({ next: open(self) });
  // course enrollment request
  fromEvent(document.body, "em:course-enroll").addListener({ next: enrollUser(self) });

  // handle having navigated back from the video player
  fromEvent(document.body, "em:dynamic-content-ready")
    .addListener({ next: () => reopenModal() });

  fromEvent(document.body, "em:player-video-load-success")
    .addListener({ next: showEmbeddedVideoSection(self) });
};
