/**
 * A notification/message component for displaying error, success, and prompts
 * to the user at the bottom of a validated form.
 *
 * @module ui/component/message
 * @category UI
 * @subcategory Components
 */
import { p } from "ui/html";
import { frozen } from "util/object";
import icon from "ui/component/icon";

// we want each message to have a unique key
// this ensures that animations run every time
let counter = 0;

/**
 * @typedef Message
 * @property {string} text
 * @property {messageTypes} type
 * @property {boolean} temporary
 * @property {Selector} sel
 */

/**
 * Member of {@link messageTypes} enum
 * @typedef {string} MessageType
 */

/**
 * @enum messageTypes
 * @property INFO
 * @property WARNING
 * @property ERROR
 */
export const messageTypes = frozen({
  ERROR: "error",
  HINT: "hint",
  INFO: "info",
  OK: "ok",
  INVALID: "invalid",
  REQUIRED: "required",
  WARNING: "warning",
});

/**
 * @functiong getIcon
 * @private
 * @param {messageTypes} type
 * @return {module:ui/component/icon~icon}
 */
function getIcon(type) {
  switch (type) {
    case messageTypes.INFO:
    case messageTypes.HINT:
    case messageTypes.OK:
      return icon.solid("info-circle");
    case messageTypes.WARNING:
    case messageTypes.REQUIRED:
      return icon.solid("exclamation-triangle");
    case messageTypes.ERROR:
    case messageTypes.INVALID:
      return icon.solid("exclamation-circle");
    default:
      return icon.solid("question-circle");
  }
}

const temp = (style, temporary, sel) => `${sel}.message${temporary ? ".temp" : ""}.${style}`;

function getStyle(type, temporary, sel) {
  switch (type) {
    case messageTypes.INFO:
    case messageTypes.OK:
      return temp("info", temporary, sel);
    case messageTypes.HINT:
      return temp("info.hint", temporary, sel);
    case messageTypes.WARNING:
    case messageTypes.REQUIRED:
      return temp("warn.required", temporary, sel);
    case messageTypes.ERROR:
      return temp("error", temporary, sel);
    case messageTypes.INVALID:
      return temp("error.invalid", temporary, sel);
    default:
      return temp("alternate", temporary, sel);
  }
}

/**
 * An informational message with appropriate icon and styling.
 *
 * @function message
 * @param {Message} message
 * @return {module:ui/html/p}
 */
export default function message({
  text,
  type = "",
  temporary = false,
  sel = "",
  key = counter++,
}) {
  return p(
    [getIcon(type), text],
    getStyle(type, temporary, sel),
    { key, dataset: { key } },
  );
}
