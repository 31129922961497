/**
 * A collapsible section, accordion style.
 *
 * @module ui/component/collapsible
 * @category UI
 * @subcategory Components
 */
import {
  div,
  h2,
} from "ui/html";
import { emit } from "util/event";

let counter = 0;

const recurseCollapse = (target, recursing = false, instant = false) => {
  if (!target) return;
  /* eslint-disable no-param-reassign */
  if (target.classList.contains("collapsible")) {
    if (target.classList.contains("open")) {
      // note that CSS should be controlling transition and collapsed height here
      // a parent collapsible has to get 100% height so it'll expand before
      // it can know its new scrollHeight
      target.style.maxHeight = (recursing || instant)
        ? "100%"
        : `${target.scrollHeight}px`;
      // let's give a moment and then give it a fixed height again
      // this might jank slightly if the stylesheet specifies multiple
      // transition durations
      const endHandler = () => {
        if (target.classList.contains("open")) {
          if (
            parseInt(target.style.maxHeight, 10) !== target.scrollHeight
          ) {
            target.style.maxHeight = `${target.scrollHeight}px`;
          }
        } else {
          target.removeEventListener("transitionend", endHandler);
          target.style.maxHeight = `${target.querySelector(".collapse-title").clientHeight}px`;
        }
      };
      target.addEventListener("transitionend", endHandler);
    } else target.style.maxHeight = `${target.querySelector(".collapse-title").clientHeight}px`;
    if (!recursing) emit(target, "em:collapse-toggle");
    recurseCollapse(target.parentElement, true, instant);
  } else recurseCollapse(target.parentElement, recursing, instant);
  /* eslint-enable no-param-reassign */
};

const toggleOpen = (target, onChange, title, label, autoCloseConfiguration) => ({
  dataset: { target },
  props: { title: label || title },
  on: {
    click: (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { id, level, autoCloseSameLevel } = autoCloseConfiguration;
      if (autoCloseSameLevel) {
        const sameLevelElements = document
          .querySelectorAll(`[data-collapse-id="${id}"][data-collapse-level="${level}"]:not(${e.currentTarget.dataset.target})`);
        sameLevelElements.forEach((element) => {
          if (element.classList.contains("open")) {
            element.classList.remove("open");
            recurseCollapse(element);
          }
        });
      }
      const elm = document.querySelector(e.currentTarget.dataset.target);
      elm.classList.toggle("open");
      recurseCollapse(elm);
      if (onChange) onChange(elm.classList.contains("open"));
    },
    contextmenu: (e) => {
      e.preventDefault();
      e.target.click();
    },
  },
});

/**
 * Snabbdom does not do a good job of removing classes, only adding them,
 * so we need to manually remove `open` class if the element should not be
 * open, and likewise for disabled.
 */
const classState = (open, disabled) => (node) => {
  // if it's already open and we're resizing due to a dom change we want it to
  // start adjusting height instantly
  const instant = node.elm.classList.contains("open") && open;
  if (open) node.elm.classList.add("open");
  else node.elm.classList.remove("open");
  if (disabled) node.elm.classList.add("disabled");
  else node.elm.classList.remove("disabled");
  // eslint-disable-next-line no-param-reassign
  node.elm.style.maxHeight = `${node.elm.querySelector(".collapse-title").clientHeight}px`;
  recurseCollapse(node.elm, false, instant);
};

/**
 * A collapsible section.
 *
 * @callback CollapsibleOnChange
 * @param {boolean} isOpen whether the change resulted in the collapsible being open
 *
 * @function collapsible
 * @param {Object} config
 * @param {string} [config.title=""] title of the collapse header
 * @param {boolean} [config.open=false] whether the collapsible should be open
 * @param {boolean} [config.disabled=false] whether the collapsible should be toggleable
 * @param {CollapsibleOnChange} [config.onChange=null] callback function when open state changes
 * @param {module:lib/html/ChildEl} children contained nodes
 * @param {string} sel additional css selector
 * @return {module:ui/common/el~El
 */
const collapsible = (
  {
    title = "",
    open = false,
    disabled = false,
    onChange = null,
    label = null,
    autoCloseConfiguration = {
      id: null,
      autoCloseSameLevel: false,
      level: null,
    },
  },
  children,
  sel = "",
) => {
  counter++;
  const childrenAsArray = children ? [children] : [""];
  return div(
    `${sel}.collapsible`,
    {
      hook: { insert: classState(open, disabled), update: classState(open, disabled) },
      dataset: {
        collapseLevel: autoCloseConfiguration?.level?.toString(),
        collapseId: autoCloseConfiguration?.id?.toString(),
        collapseTarget: counter,
      },
    },
    [
      h2(
        [
          div(".collapse-label", title),
        ],
        ".collapse-title",
        disabled
          ? { props: { title: label || title } }
          : toggleOpen(`[data-collapse-target="${counter}"]`, onChange, title, label, autoCloseConfiguration),
      ),
      /* eslint-disable-next-line no-nested-ternary */
      ...(Array.isArray(children) ? children : childrenAsArray),
    ],
  );
};

export default collapsible;
