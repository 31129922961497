/**
 * Utilities for use in assessments and evaluations.
 *
 * @module util/assessments
 * @category Utilities
 */
import hash from "string-hash";
import MersenneTwister from "mersenne-twister";
import { getEvaluationByAssessmentId } from "api/course";
import { EXPAND_NONE } from "api/course/constants";
import { evaluationStatus } from "model/course/constants";
import { clone, merge } from "util/object";
import { shuffle } from "util/sort";

/**
 * Deterministically shuffles an assessment if randomized questions and answers are enabled.
 *
 * Note that if an assessment's settings are changed, or the number of questions change, or
 * the number of answers on any question change, the outcome of the shuffle will also change.
 *
 * So this is only deterministic for a given version of a given assessment.
 *
 * @function shuffleAssessment
 * @param {Assessment} assessment
 * @param {User} user
 * @return {Assessment} the assessment, shuffled
 */
/* eslint-disable-next-line import/prefer-default-export */
export const shuffleAssessment = (assessment, user) => {
  // this is a lossy, ugly way to produce the seed but it should be sufficiently unique
  // we're not shooting for zero collisions, just random enough that most students will
  // get different question sets
  // use an arraybuffer to stop JS from automatically coercing the seed to a float
  const buf = new Uint32Array(3);
  buf[0] = parseInt(hash(user.id.replace('-', "")), 10);
  buf[1] = parseInt(hash(assessment.id.replace('-', "")), 10);
  buf[2] = buf[0] * buf[1];
  const rng = new MersenneTwister(buf[2]);
  const result = clone(assessment);

  result.questions = result.questions.map((question) => {
    const qResult = merge({}, question);
    if (qResult.randomizeAnswers) {
      qResult.answers = shuffle([...qResult.answers], rng);
    }
    return qResult;
  });

  if (result.randomizeQuestions) {
    result.questions = shuffle([...result.questions], rng);
  }

  return result;
};

/**
 * Fetches completed evaluation entity and opens its profile in a new tab
 *
 * @param {AnalyticsEvaluation} evalStatus
 * @param {Object} loadingView
 * @return {Promise<void>}
 */
export const fetchAndOpenEvaluationByEvalStatus = async (evalStatus, loadingView) => {
  if (evalStatus.status !== evaluationStatus.COMPLETE
    || evalStatus !== evaluationStatus.SCORED) return;
  loadingView.show();
  const evaluation = await getEvaluationByAssessmentId(
    evalStatus.courseId,
    evalStatus.userId,
    evalStatus.assessmentId,
    EXPAND_NONE,
  );
  loadingView.hide();
  if (evaluation) {
    window.open(
      `/admin/evaluation-profile?courseId=${evalStatus.courseId}&evaluationId=${evaluation.id}`,
      "_blank",
    );
  }
};
