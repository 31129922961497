/**
 * API requests for users.
 *
 * @module api/v2/user
 * @category Backend API v2
 * @subcategory User
 */
import cache from "cache";
import { endpoints } from "api/constants";
import { get } from "api/request";
import log from "log";
import { canModerate, responseToUser, responseToAbbreviatedUser } from "model/user";

/**
 * Get a list of all users visible to the current user.
 *
 * @function list
 * @return {module:api/types/user~User[]}
 */
export const list = async (params) => {
  try {
    const users = (await get(endpoints.USER, params)).body;
    return users.map(responseToUser);
  } catch (e) {
    log.error(e, "unable to list users");
    return [];
  }
};

/**
 * Search users by their properties.
 *
 * @function search
 * @param {object} params
 * @param {string} [params.firstName]
 * @param {string} [params.lastName]
 * @param {string} [params.email]
 * @param {string} [params.cellPhone]
 * @param {string} [params.username]
 * @return {module:api/types/user~User[]}
 */
export const search = async (params) => {
  try {
    const users = (await get(endpoints.USER, params)).body;
    return users.map(responseToUser);
  } catch (e) {
    log.error(e, "unable to search users", params);
    return [];
  }
};

export const count = async (params) => {
  try {
    const users = (await get(endpoints.USER, params)).body;
    return users.length;
  } catch (e) {
    log.error(e, "unable to count users");
    return [];
  }
};

const mungeKnownUser = (user) => ({
  ...user,
  groupIds: user.userLists.map((entry) => entry.userGroupId),
});

export const getKnownUsers = async () => {
  try {
    const me = cache.getProfile();
    const users = canModerate(me)
      ? (await get(endpoints.USER)).body.map(mungeKnownUser)
      : (await get(`${endpoints.ACL_MESSAGE_USER}/${me.id}`)).body;
    return users.map(responseToAbbreviatedUser);
  } catch (e) {
    log.error(e, "could not fetch known users");
    return [];
  }
};
