/**
 * Config models.
 *
 * @module model/config
 * @category Model
 * @subcategory Config
 */

/**
 * @typedef ServerConfig
 * @property {boolean} cellPhoneRequired
 * @property {boolean} emailRequired
 * @property {boolean} firstNameRequired
 * @property {boolean} registrationEnabled
 * @property {boolean} ldapEnabled
 * @property {number} gridFsSizeInBytes
 * @property {string} host
 * @property {boolean} lastNameRequired
 * @property {boolean} openSiteAccessEnabled
 * @property {string} port
 * @property {string} protocol
 * @property {string} regexFileName
 * @property {verificationMethods} userVerification
 * @property {FeatureSetConfig} featureSetConfig
 */

/**
 * @typedef FeatureSetConfig
 * @property {boolean} allowAnonymousAccess
 * @property {number} gridFsSizeLimitInMB
 * @property {number} gridFsSizeInBytes
 * @property {boolean} ldapEnabled
 * @property {boolean} lmsEnabled
 * @property {number} maxConcurrentLogins
 * @property {boolean} messagingEnabled
 * @property {boolean} registrationEnabled
 * @property {boolean} zoomIntegrationEnabled
 */

/**
 * Convert configuration DTO to entity
 *
 * @param {object} response
 * @returns {ServerConfig}
 */
// eslint-disable-next-line import/prefer-default-export
export const responseToServerConfig = (response) => ({
  cellPhoneRequired: response.cellPhoneRequired,
  emailRequired: response.emailRequired,
  firstNameRequired: response.firstNameRequired,
  gridFsCurrentSizeInBytes: response.gridFsCurrentSizeInBytes,
  host: response.host,
  lastNameRequired: response.lastNameRequired,
  port: response.port,
  protocol: response.protocol,
  regexFileName: response.regexFileName,
  userVerification: response.userVerification,
  openSiteAccessEnabled: response.featureSetConfig?.allowAnonymousAccess
    || true, // backwards compatibility
  registrationEnabled: response.featureSetConfig?.registrationEnabled, // backwards compatibility
  ldapEnabled: response.featureSetConfig?.ldapEnabled, // backwards compatibility
  featureSetConfig: {
    gridFsSizeLimitInBytes: response.featureSetConfig?.gridFsSizeLimitInBytes,
    allowAnonymousAccess: response.featureSetConfig?.allowAnonymousAccess,
    ldapEnabled: response.featureSetConfig?.ldapEnabled,
    lmsEnabled: response.featureSetConfig?.lmsEnabled,
    maxConcurrentLogins: response.featureSetConfig?.maxConcurrentLogins,
    messagingEnabled: response.featureSetConfig?.messagingEnabled,
    registrationEnabled: response.featureSetConfig?.registrationEnabled,
    zoomIntegrationEnabled: response.featureSetConfig?.zoomIntegrationEnabled,
    userManagementEnabled: response.featureSetConfig?.userManagementEnabled,
  },
});
