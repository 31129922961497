/**
 * The account verification view for the login self.
 *
 * @module ui/self/login/verification
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import api from "api";
import log from "log";
import { verificationMethods } from "model/config/constants";
import { verificationResendTypes } from "model/user/constants";
import form from "ui/component/form-managed";
import username from "ui/component/form-managed/field-username";
import otp from "ui/component/form-managed/field-otp";
import button from "ui/component/form-managed/button";
import h1 from "ui/html/h1";
import { tidyBackendError } from "util/error";
import { merge } from "util/object";
import messages from "./messages";
import { initLogin } from "./common";

/**
 * Handles submission of the verification form.
 *
 * @function doVerify
 * @private
 */
export const doVerify = async (self, notification) => {
  self.setFullValidation(true);
  if (!self.validate().valid) {
    notification.post(messages.invalid);
    return;
  }

  try {
    self.disable();
    await api.user.verify(self.values);
    notification.post(messages.verificationSuccess);
    initLogin(self, self.values);
  } catch (error) {
    log.error(error);
    // handle error messages
    if (error.statusCode === 401 || error.statusCode === 400) {
      // username or code incorrect
      notification.post(messages.verificationFailure);
    } else {
      tidyBackendError(error).map((text) => notification.post(
        merge(messages.apiError, { text }),
      ));
    }
  }
  self.enable();
};

/**
 * Sends a verification code to the user's email and/or SMS depending on server
 * config.
 *
 * @param {View} self
 * @private
 * @param {module:model/user/constants~verificationResendType} resendType
 */
export const sendCode = async (self, resendType, notification) => {
  try {
    const data = {
      username: self.values.username,
      resendType,
    };
    const { userVerification } = self.state.config.server;
    let successMessage = {};
    switch (userVerification) {
      case verificationMethods.OTP:
        data.viaEmail = true;
        data.viaSms = true;
        successMessage = messages.resendVerifySuccessBoth;
        break;
      default:
        throw new Error("unknown verification method type");
    }
    self.disable();
    await api.user.reverify(data);
    notification.post(successMessage);
    self.enable();
    return true;
  } catch (error) {
    self.enable();
    if (error.statusCode === 400) {
      notification.post(messages.passwordResetContactAdmin);
      throw (error);
    } else if (error.statusCode === 403 || error.statusCode === 500) {
      notification.post(messages.verificationRateLimitFailure);
      throw (error);
    } else {
      tidyBackendError(error).map((text) => notification.post(
        merge(messages.apiError, { text }),
      ));
      throw (error);
    }
  }
};

/**
 * Resend a verification code.
 *
 * @function doResendVerification
 */
const doResendVerification = async (self, notification) => {
  if (!self.values.username) {
    notification.post(messages.resendVerifyInputUsername);
    return;
  }
  const { userVerification } = self.state.config.server;
  if (userVerification === verificationMethods.ADMIN) {
    notification.post(messages.registerSuccessAdminVerify);
    initLogin(self);
    return;
  }
  await sendCode(
    self,
    verificationResendTypes.REGISTRATION,
    notification,
  );
};

/**
 * Show the user account verification form.
 *
 * @function showVerification
 * @private
 * @param {View} self
 */
export default (self, notification) => form(
  "#password-reset",
  self.bind([
    h1("Verify Account"),
    [username, { required: true }],
    [otp, { required: true }],
    [button, {
      label: "Verify",
      onClick: () => doVerify(self, notification),
      disabled: !self.validate().valid,
    }],
    [button, {
      label: "Resend Verification Code",
      onClick: () => doResendVerification(self, notification),
      sel: ".subtle.accent",
    }],
    [button, {
      label: "Back to Login",
      onClick: () => initLogin(self),
      sel: ".subtle.ok",
    }],
  ], self.state.verification),
);
