/**
 * Messaging models.
 *
 * @module model/message
 * @category Model
 * @subcategory Message
 */
import log from "log";
import { orNull, validDate, validMember, validString } from "../constraints";
import {
  /* messageAttributeName, */
  messageFilterParams,
  messageFilterTypes,
  /* messageFilterType, */
} from "./constants";

/**
 * Inbox model
 *
 * @typedef Inbox
 * @property {string} id
 * @property {inboxTypes} type
 * @property {inboxModes} mode
 * @property {inboxStatuses} status
 * @property {Date} createdAt
 * @property {Date} updatedAt
 */

/**
 * Converts inbox response to inbox object
 *
 * @param response
 * @param {string} response.inboxId
 * @param {inboxTypes} response.inboxType
 * @param {inboxModes} response.inboxMode
 * @param {inboxStatuses} response.inboxStatus
 * @param {string} response.createdAt
 * @param {string} response.updatedAt
 * @returns Inbox
 */
export const responseToInbox = (response) => ({
  id: response.inboxId,
  type: response.inboxType,
  mode: response.inboxMode,
  status: response.inboxStatus,
  createdAt: response.createdAt ? new Date(response.createdAt) : new Date(),
  updatedAt: response.updatedAt ? new Date(response.updatedAt) : new Date(),
});

/**
 * Converts conversation id response object to conversation id string
 *
 * @param {string} response.conversId
 * @returns {UUID}
 */
export const responseToConversationId = (response) => response.conversId;

/**
 * @typedef FilterParamDTO
 * @property {messageAttributeName} name
 * @property {messageFilterType} filterType
 * @property {string} value
 */

const makeFilterParamDTO = (data) => ({
  name: validString(data.name, "filterParamDTO.name"),
  filterType: validMember(data.filterType, "filterParamDTO.filterType", messageFilterTypes),
  value: validString(data.value, "filterParamDTO.value"),
});

/**
 * Creates filter params array
 *
 * @param {object} params
 * @param {boolean} params.hasAttachments
 * @param {string} params.attachmentName
 * @param {reportTypes} params.reportType
 * @param {messageStatuses} params.messageStatus
 * @param {messageType} params.type filter by message type
 * @param {UUID} params.userId sender id / from
 * @param {UUID} params.toId receiver id / to
 * @param {FilterParamDTO[]} params.filters arbitrary filters
 * @returns {FilterParamDTO[]}
 */
const makeFilterParams = (params) => {
  const paramsArray = [];
  if (params.hasAttachments) {
    paramsArray.push(messageFilterParams.HAS_ATTACHMENT());
  }
  if (params.messageStatus) {
    paramsArray.push(messageFilterParams.BY_MESSAGE_STATUS(params.messageStatus));
  }
  if (params.type) {
    paramsArray.push(messageFilterParams.BY_TYPE(params.type));
  }
  if (params.attachmentName) {
    paramsArray.push(messageFilterParams.BY_ATTACHMENT_NAME(params.attachmentName));
  }
  if (params.userId) {
    paramsArray.push(messageFilterParams.BY_USER_ID(params.userId));
  }
  if (params.toId) {
    paramsArray.push(messageFilterParams.BY_RECEIVER_ID(params.toId));
  }
  if (params.reportType) {
    paramsArray.push(messageFilterParams.BY_REPORT_TYPE(params.reportType));
  }
  if (params.filters) {
    params.filters.forEach((filter) => {
      try {
        paramsArray.push(makeFilterParamDTO(filter));
      } catch (e) {
        log.error(filter, e);
        throw e;
      }
    });
  }
  return paramsArray;
};

/**
 * Search message model
 *
 * @typedef SearchMessage
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {string} text
 * @property {number} messageCount
 * @property {string} searchString
 * @property {sortOrders} sortOrder
 * @property {Object} params
 * @property {?boolean} params.hasAttachments
 * @property {?messageStatuses} params.messageStatus
 * @property {?string} params.attachmentName
 * @property {?string} params.userId
 */

/**
 * Search message DTO model
 *
 * @typedef SearchMessageDTO
 * @property {Date} startDateTime
 * @property {Date} endDateTime
 * @property {number} messageCount
 * @property {string} searchString
 * @property {FilterParamDTO[]} filterParam
 * @property {sortOrders} sortOrder
 */

/**
 * Make search DTO for searching messages
 *
 * @param {SearchMessage} partial
 * @returns SearchMessageDTO
 */
export const makeSearchMessageDTO = (partial) => ({
  startDateTime: orNull(partial.startDate, `SearchMessageDTO.startDate`, validDate)
      || undefined,
  endDateTime: orNull(partial.endDate, `SearchMessageDTO.endDate`, validDate) || undefined,
  messageCount: partial?.messageCount,
  searchString: partial?.searchString,
  filterParam: partial.params ? makeFilterParams(partial.params) : [],
  sortDirection: partial?.sortOrder,
});
