import { frozen } from "util/object";

export const CURRENT_MENU_VERSION = 2.0;

export const MAIN_MENU_KEY = "MAIN_MENU";

export const PAGE_MENU_KEY = (pageId) => `PAGE_MENU_${pageId}`;

export const menuEntryTypes = frozen({
  LINK_EXTERNAL: "LINK_EXTERNAL", // deprecated
  LINK_PAGE: "LINK_PAGE", // deprecated
  SUB_MENU: "SUB_MENU", // deprecated
  STATIC: "STATIC", // used in static menus
  FUNCTION: "FUNCTION", // used in static menus
  ENTRY: "ENTRY", // actual menu items
  // ??? - other things that may appear in a menu (separator, etc)
});

/**
 * Menu entries may be exclusive to only web or PWA.
 * @const menuEntryExclusiveTypes
 */
export const menuEntryExclusiveTypes = frozen({
  PWA: "pwa",
  WEB: "web",
});

export const menuEntryExclusiveTypesSet = frozen(new Set(Object.values(menuEntryExclusiveTypes)));

/**
 * A map of menu link types to user-friendly strings.
 * @constant menuEntryTypesFriendly
 */
export const menuEntryTypesLabeled = frozen(new Map([
  [menuEntryTypes.LINK_EXTERNAL, "External Link (Deprecated)"],
  [menuEntryTypes.LINK_PAGE, "Page Link (Deprecated)"],
  [menuEntryTypes.SUB_MENU, "Sub-Menu (Deprecated)"],
  [menuEntryTypes.ENTRY, "Menu Entry"],
]));

export const menuEntryTypesSet = frozen(new Set(Object.values(menuEntryTypes)));

export const menuLinkTypes = frozen({
  LINK_EXTERNAL: "LINK_EXTERNAL", // link to an external URL
  LINK_PAGE: "LINK_PAGE", // link to a page
  NONE: null, // no link
});

export const menuLinkTypesLabeled = frozen(new Map([
  [menuLinkTypes.LINK_EXTERNAL, "External Link"],
  [menuLinkTypes.LINK_PAGE, "Page Link"],
  [menuLinkTypes.NONE, "No Link"],
]));

export const menuLinkTypesSet = frozen(new Set(Object.values(menuLinkTypes)));
