/**
 * Constraints specific to ACL models.
 *
 * @module model/acl/constraints
 * @category Model
 * @subcategory ACL
 */
import { validArray, validMember } from "model/constraints";
import { aclRightsSet } from "./constants";

/**
 * Ensures that the value is one of the supported user access rights in
 * {@link module:model/acl/constants.accessRights}
 *
 * @function validACLAccessRight
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not one of the enumerated language codes
 *
 * @return {string} the value
 */
export const validACLAccessRight = (value, fieldName) => validMember(
  value,
  fieldName,
  aclRightsSet,
);

/**
 * Ensures a user access rights array contains all valid members.
 * {@see module:model/acl/constraints.validACLAccessRight}
 *
 * @function validACLAccessRights
 * @param {array} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not one of the access rights
 *
 * @return {array} the access rights

 */
export const validACLAccessRights = (value, fieldName) => validArray(
  value,
  fieldName,
  validACLAccessRight,
);
