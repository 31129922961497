/**
 * The main menu, stored in the site-config table.
 *
 * See also the [static menus]{@link module:ui/component/header/menu/static-menus}.
 *
 * @module model/site/menu
 * @category Model
 * @subcategory Site
 */
import log from "log";
import {
  orNull,
  required,
  validArray,
  validString,
  validMember,
  validUUID,
  validUrlRelative,
} from "model/constraints";
import { frozen } from "util/object";
import { v0ToV1 } from "./v0";
import { v1ToV2 } from "./v1";
import {
  menuEntryTypes,
  menuEntryExclusiveTypes,
  menuLinkTypes,
  CURRENT_MENU_VERSION,
  MAIN_MENU_KEY,
  PAGE_MENU_KEY,
} from "./constants";

/**
 * The default empty menu.
 */
export const defaultMenu = frozen({
  entries: [],
  version: CURRENT_MENU_VERSION,
});

export const defaultMenuEntry = () => ({
  accountRequired: false,
  label: "",
  icon: null, // only used by static menus for now (no UI for selecting icons)
  type: menuEntryTypes.ENTRY,
  linkType: menuLinkTypes.NONE,
  url: null,
  slug: null,
  pageId: null,
  entries: [],
  exclusive: null,
});

/**
 * A menu of links.
 *
 * @typedef Menu
 * @property {Array.<MenuEntry>} entries
 * @property {float} version version number (for potential future migration)
 */

/**
 *
 * @typedef MenuEntry
 * @property {boolean} accountRequired is it needed to be logged in to see the entry
 * @property {string} label label displayed to the user
 * @property {menuEntryTypes} [type=menuEntryTypes.ENTRY] menu item type designation
 * @property {?string} url external link url
 * @property {?string} slug last seen page slug for a page link
 * @property {?UUID} pageId id of page for a page link
 * @property {number} counter ???
 * @property {?Array.<MenuLink>} entries child menu entries
 * @property {?menuEntryExclusiveTypes} exclusive
 */

/**
 * @function responseToExternalLink
 * @private
 * @return {MenuEntry}
 */
const responseToEntry = (response) => ({
  accountRequired: response.accountRequired,
  label: response.label,
  type: menuEntryTypes.ENTRY,
  // external link stuff
  linkType: response.linkType || menuLinkTypes.NONE,
  // page link stuff
  url: response.url || null,
  slug: response.slug || null,
  // submenus
  /* eslint-disable-next-line no-use-before-define */
  pageId: response.pageId || null,
  entries: response.entries?.map(responseToEntry),
  exclusive: response.exclusive || null,
});

/**
 * Auto-upgrades older menu versions.
 * @function upgrade
 * @private
 * @param {object} partial
 */
const upgrade = (version) => (response) => {
  let entry = response;
  if (version === 0.0) {
    entry = v1ToV2(v0ToV1(response));
    log.debug("v0 menu entry upgraded to v1", entry);
  }
  if (version === 1.0) {
    entry = v1ToV2(response);
    log.debug("v1 menu entry upgraded to v2", entry);
  }
  return responseToEntry(entry);
};

/**
 * Maps an API response to a Menu
 * @function responseToMenu
 * @param {object} response
 * @return {Menu}
 */
export const responseToMenu = (response) => {
  const version = parseFloat(response.version, 10);
  if (version > CURRENT_MENU_VERSION) {
    log.debug("menu is an unsupported version and may be broken.");
  }
  return ({
    version,
    entries: response.entries.map(upgrade(version)),
  });
};

/**
 * @function makeEntryDTO
 * @private
 * @param {object} partial
 * @return {MenuSubMenu}
 */
const makeEntryDTO = (partial) => ({
  accountRequired: !!partial.accountRequired,
  label: required(partial.label, "MenuSubMenu.title", validString),
  type: menuEntryTypes.ENTRY,
  linkType: partial.linkType || menuLinkTypes.NONE,
  // external links
  url: orNull(partial.url, "MenuEntry.url", validUrlRelative),
  // page links
  slug: orNull(partial.slug, "MenuEntry.slug", validString),
  pageId: orNull(partial.pageId, "MenuEntry.pageId", validUUID),
  // sub-menu entries
  entries: validArray(partial.entries?.map(makeEntryDTO) || [], "MenuEntry.entries"),
  exclusive: orNull(partial.exclusive, "MenuEntry.exclusive", (value, fieldName) => validMember(value, fieldName, menuEntryExclusiveTypes)),
});

/**
 * Validates a menu for storage in the site config database.
 */
export const makeMenuDTO = (partial) => {
  const dto = {};
  dto[MAIN_MENU_KEY] = JSON.stringify({
    version: CURRENT_MENU_VERSION,
    entries: validArray(partial.entries.map(makeEntryDTO) || [], "Menu.entries"),
  });
  return dto;
};

/**
 * Validates a page menu for storage in the site config database.
 */
export const makePageMenuDTO = (pageId, partial) => {
  const dto = {};
  dto[PAGE_MENU_KEY(pageId)] = JSON.stringify({
    version: CURRENT_MENU_VERSION,
    entries: validArray(partial.entries.map(makeEntryDTO) || [], "Menu.entries"),
  });
  return dto;
};
