/**
 * A modal dialog for selecting a pre-existing user.
 *
 * @module ui/component/modal/acl/user-select
 * @category UI
 * @subcategory Modal Dialogs
 */
import listModal from "ui/component/modal/layout/list";
import cameoUser from "ui/component/cameo-user";
import { userGroupNameFilter } from "util/filter";
import { merge, frozen } from "util/object";
import { getModal } from "ui/view/modal-view";

const doSearch = (state) => (search) => {
  /* eslint-disable-next-line no-use-before-define */
  getModal().patch(userSelectModal({
    ...state,
    search,
  }));
};

const defaultState = frozen({
  users: [],
  search: "",
});

/**
 * A modal dialog for selecting an ACL user user.
 *
  * Resolves with:
 * ```js
 * {
 *   type: aclPrincipalTypes,
 *   user: UserGroupEntry,
 * }
 * ```
*
 * @function userSelectModal
 * @param {Object} [inState=defaultState]
 * @param {User[]} inState.users
 * @param {string} inState.search
 * @return {module:ui/common/el~El}
 */
export default function userSelectModal(inState = defaultState) {
  const state = merge(defaultState, inState);
  return listModal({
    entries: state.users
      .filter(userGroupNameFilter(state.search))
      .map((user) => cameoUser({
        user,
        config: {
          on: {
            click: () => getModal().resolve(user),
          },
        },
      })),
    sel: ".user-select",
    onSearch: doSearch(state),
    searchText: state.search,
  });
}
