/**
 * Constants related to user models.
 *
 * @module model/user/constants
 * @category Model
 * @subcategory User
 */
import { frozen } from "util/object";

/**
 * Anonymous username
 *
 * @type {string}
 */
export const ANONYMOUS_USERNAME = "anonymous";

/**
 * Sort criteria applicable to all users. Used in searching, filtering,  and
 * table column sorting.
 *
 * @enum {string} userSortCriteria
 * @readonly
 * @property {string} USERNAME_ASC
 * @property {string} USERNAME_DESC
 * @property {string} FULLNAME_ASC
 * @property {string} FULLNAME_DESC
 * @property {string} CELLPHONE_ASC
 * @property {string} CELLPHONE_DESC
 * @property {string} EMAIL_ASC
 * @property {string} EMAIL_DESC
 * @property {string} ENABLED_ASC
 * @property {string} ENABLED_DESC
 * @property {string} EXTERNAL_ASC
 * @property {string} EXTERNAL_DESC
 * @property {string} AUTHORITIES_ASC
 * @property {string} AUTHORITIES_DESC
 */
export const userSortCriteria = Object.freeze({
  USERNAME_ASC: 'BY_USERNAME_ASC',
  USERNAME_DESC: 'BY_USERNAME_DESC',
  FULLNAME_ASC: 'BY_FULLNAME_ASC',
  FULLNAME_DESC: 'BY_FULLNAME_DESC',
  CELLPHONE_ASC: 'BY_CELLPHONE_ASC',
  CELLPHONE_DESC: 'BY_CELLPHONE_DESC',
  EMAIL_ASC: 'BY_EMAIL_ASC',
  EMAIL_DESC: 'BY_EMAIL_DESC',
  ENABLED_ASC: 'BY_ENABLED_ASC',
  ENABLED_DESC: 'BY_ENABLED_DESC',
  EXTERNAL_ASC: 'BY_EXTERNAL_ASC',
  EXTERNAL_DESC: 'BY_EXTERNAL_DESC',
  AUTHORITIES_ASC: 'BY_AUTHORITIES_ASC',
  AUTHORITIES_DESC: 'BY_AUTHORITIES_DESC',
});

/**
 * Reasons for requesting a new verification code.
 *
 * @enum {string} verificationResendTypes
 * @readonly
 * @property {string} REGISTRATION the user needs a new registration code
 * @property {string} RESET_PASSWORD the user is resetting his password
 */
export const verificationResendTypes = Object.freeze({
  REGISTRATION: "REGISTRATION",
  RESET_PASSWORD: "RESET_PASSWORD",
});

/**
 * List of values of registrationResendTypes - used in API request validation.
 *
 * @constant verificationResendTypeValues
 * @type {Set.<string>}
 * @readonly
 */
export const verificationResendTypeValues = Object.freeze(new Set(
  [...Object.values(verificationResendTypes)],
));

/**
 * User types enum
 *
 * @enum
 * @readonly
 * @property {string} REGISTERED a registered user
 * @property {string} ANONYMOUS a non-registered user
 * @property {string} ROOT the root (super-admin) user
 */
export const userTypes = Object.freeze({
  REGISTERED: "AuthenticatedUser",
  ANONYMOUS: "AnonymousUser",
  ROOT: "RootUser",
});

/**
 * Set of User type strings accepted by the backend API,
 * as [userTypes]{@link userTypes}
 *
 * @constant userTypesSet
 * @type {Set.<string>}
 * @readonly
 */
export const userTypesSet = frozen(
  new Set(Object.values(userTypes)),
);

/**
 * User-friendly labels for user types.
 *
 * @constant userTypesFriendly
 * @type {Map.<userTypes, string>}
 */
export const userTypesFriendly = frozen(new Map([
  [userTypes.REGISTERED, "Registered"],
  [userTypes.ANONYMOUS, "Anonymous"],
  [userTypes.ROOT, "Root"],
]));
