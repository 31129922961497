import log from "log";
import { aclSubjectTypes } from "model/acl/constants";
import { videoMetadataList } from "api/mocks/metadata";
import { coursesMock } from "api/mocks/course";

import collapsible from "ui/component/collapsible";
import icon from "ui/component/icon";
import manageGrantsDialog from "ui/component/modal/acl/manage-grants";
import { button } from "ui/html";
import { principalList } from "../mocks";

export default (modalView, notificationView) => collapsible({
  title: "ACL Dialogs",
  open: false,
}, [
  button(["Grant Rights (Content)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      principals: principalList,
      subjects: videoMetadataList,
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
  button(["Grant Rights (Course)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      principals: principalList,
      subjects: coursesMock,
      subjectType: aclSubjectTypes.COURSE,
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
  button(["Grant Rights (Page)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      principals: principalList,
      subjects: videoMetadataList,
      subjectType: aclSubjectTypes.PAGE,
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
  button(["Grant (Preselected User)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      grant: {
        principal: principalList[0],
        type: aclSubjectTypes.MEDIA,
      },
      subjects: videoMetadataList,
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
  button(["Grant (Preselected Content)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      grant: {
        subject: videoMetadataList[0],
        type: aclSubjectTypes.MEDIA,
      },
      principals: principalList,
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
  button(["Grant (Preselected User + Content)", icon("lock")], async () => {
    const result = await modalView.async(manageGrantsDialog({
      grant: {
        principal: principalList[0],
        subject: videoMetadataList[0],
        type: aclSubjectTypes.MEDIA,
      },
    }, modalView, notificationView));
    log.debug("User granted privileges", result);
  }, ".subtle.forward"),
]);
