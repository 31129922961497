/**
 * @module ui/view/rich-text-edit-view
 * @category UI
 * @subcategory Views
 */
import view from "ui/view/view";
import richTextEditor, { getEditor } from "ui/component/rich-text-editor";

let count = 0;

/**
 * A view wrapping the rich text editor component, which automates refreshing state and
 * managing the editor id.
 *
 * @typedef {object} RichTextEditView
 * @borrows {module:ui/view/view~View}
 * @property {function} html see {@link module:ui/view/rich-text-edit-view~html}
 */

const active = (editor) => (...params) => editor?.isActive(...params) || false;

const enabled = (editor) => (cmd, ...params) => editor?.can()[cmd](...params) || false;

const updateFn = (theView) => {
  theView.updateState({
    id: theView.id,
    content: theView.html,
  });
  theView.patch(theView.factory(theView));
};

/**
 * Composes additional functionality for the rich text editor with a basic view.
 */
function bootstrapView(theView, onChange) {
  // force an initial patch, so the editor is initialized
  theView.patch(theView.factory(theView));

  const editorId = theView.state.id;

  const bindChangeListener = () => {
    theView.element.addEventListener("rte:change", () => {
      theView.update({ content: theView.html });
      if (typeof onChange === "function") onChange(theView);
    });
  };

  bindChangeListener();
  const origRebind = theView.rebind;

  /* eslint-disable-next-line no-param-reassign */
  theView.rebind = (sel) => {
    origRebind(sel);
    bindChangeListener();
  };

  Object.defineProperties(theView, {
    id: {
      get: () => editorId,
      configurable: false,
    },
    editor: {
      get: () => getEditor(editorId),
      configurable: false,
    },
    html: {
      get: () => getEditor(editorId)?.getHTML(),
      configurable: false,
    },
    json: {
      get: () => getEditor(editorId)?.getJSON(),
      configurable: false,
    },
    active: {
      get: (type) => active(getEditor(editorId))(type),
      configurable: false,
    },
    enabled: {
      get: (type, params) => enabled(getEditor(editorId))(type, params),
      configurable: false,
    },
  });
  /* eslint-enable no-param-reassign */
  return theView;
}

const factory = (modalView) => (theView) => richTextEditor(theView.state, modalView);

/**
 * @callback OnChangeCallback
 * @param {View} view the rich text edit view
 */

/**
 * Initialize a richTextEditView.
 *
 * FIXME the onchange callback might not be the best approach here, rethink later?
 *
 * @function richTextEditView
 * @param {Selector} selector
 * @param {ModalView} modalView
 * @param {object} [initState={}]
 * @param {Array.<DynamicPage>} pages list of existing pages (for linking)
 * @param {string} content html content for editor
 * @param {OnChangeCallback} onChange
 * @return {module:ui/view/richTextEditView~RichTextEditView}
 */
export default function richTextEditView(
  selector,
  modalView,
  initState = {},
  onChange = () => {},
) {
  const state = { id: initState.id || ++count, ...initState };
  const theView = bootstrapView(view(selector, state, factory(modalView), updateFn), onChange);
  return theView;
}
