/**
 * A page for editing existing content (metadata) entries.
 *
 * Corresponds to `markup/admin/content-profile.html`
 *
 * @module ui/page/admin/content-profile
 * @category Pages
 * @subcategory Admin - Content Management
 */
import addWeeks from "date-fns/addWeeks";
import startOfDay from "date-fns/startOfDay";
import parseISO from "date-fns/parseISO";
import api from "api";
import log from "log";
import cache from "cache";
import cacheStream from "data/cache";
import {
  aclRights,
  aclPrincipalTypes,
  aclSubjectTypes,
} from "model/acl/constants";
import { metadataTypes, metadataTypesFriendly, languageCodeMap } from "model/metadata/constants";
import { isRoot } from "model/user";
import view from "ui/view";
import actionBar from "ui/component/dashboard-action-bar";
import div from "ui/html/div";
import collapsible from "ui/component/collapsible";
import dashboardWidget from "ui/component/dashboard-widget";
import filePicker, { filePickerModes } from "ui/component/form-managed/file-picker";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import title from "ui/component/form-managed/field-title";
import button from "ui/component/form-managed/button";
import hidden from "ui/component/form-managed/field-hidden";
import description from "ui/component/form-managed/field-description";
import labelCollection from "ui/component/label-collection";
import metaList from "ui/component/meta-list";
import fakeInput from "ui/component/fake-input";
import contentSchedule from "ui/component/content-schedule";
import userContentAccess from "ui/component/user-content-access";
import metadataSelect from "ui/component/modal/metadata-select";
import placeholder from "ui/component/placeholder";
import { getQueryParams } from "util/navigation";
import { unique, difference } from "util/array";
import { tidyBackendError } from "util/error";
import { prettyBytes } from "util/file";
import { prettyTime } from "util/format";
import { merge } from "util/object";
import {
  makeGrantQueue,
  validateOwnRights,
  validateRights,
} from "util/acl";
import pageListWidget from "ui/component/page-list-widget";
import dashboardLayout from "ui/page/layout/dashboard";
import { notificationMessageTypes } from "model/notification/constants";
import getConfig from "config";
import { getContentDuration } from "api/v2/analytics";
import { analyticsContentDurationToDataset } from "model/chart";
import chartWithCalendar from "ui/component/chart/chart-with-calendar";
import { chartTypes } from "model/chart/constants";
import { sortNum } from "util/sort";
import {
  checkContentFiles,
  handleFilePickers,
  filePickerConfig,
  isFileSelected,
  onRemoveLabel,
  onSelectLabel,
  onOpenStep,
} from "./common";
import {
  staticMessages,
  videoMessages,
  documentMessages,
  posterMessages,
} from "./messages";
import {
  profileSteps as steps,
  profileMetadataOpenSteps as metadataOpenSteps,
  profileFileOpenSteps as fileOpenSteps,
  profileMetadataFileInfoTypes as metadataFileInfoTypes,
  defaultProfileState as defaultState,
} from "./state";
import reviewCollapsible from "./review-collapsible";

let modalView;
let loadingView;
let profileView;
let aclGrantQueue;
let notificationView;
let analyticsView;
let userAccessView;
let pageReferenceView;
let actionButtonsView;

const continueFromVideoFileStep = () => profileView.update({ step: steps.POSTER });

const continueFromPosterFileStep = () => profileView.update({ step: steps.COMPLETE });

const continueFromBasicsStep = () => profileView.update({ step: steps.SCHEDULE });

const continueFromScheduleStep = () => profileView.update({ step: steps.LABEL });

const continueFromPlaylistStep = () => profileView.update({ step: steps.POSTER });

const continueFromLabelStep = () => {
  if (profileView.values.type === metadataTypes.LIST) {
    profileView.update({ step: steps.POSTER });
  } else profileView.update({ step: steps.FILE });
};

/**
 * Handles a change callback from the document file picker.
 * @function onDocumentChange
 * @private
 */
const onDocumentChange = () => profileView.update({
  isFormChanged: true,
  messages: profileView.state.messages.filter((m) => !documentMessages.has(m)),
});

/**
 * Handles a change callback from the video file picker.
 * @function onVideoChange
 * @private
 */
const onVideoChange = () => profileView.update({
  content: {
    ...profileView.state.content,
    createdAt: new Date(),
    language: null,
    year: null,
    height: null,
    width: null,
    length: null,
    videoFile: {
      ...profileView.state.content.videoFile,
      size: 0,
    },
  },
  messages: profileView.state.messages.filter((m) => !videoMessages.has(m)),
  isFormChanged: true,
});

/**
 * Handles a change callback from the poster file picker.
 * @function onPosterChange
 * @private
 */
const onPosterChange = () => profileView.update({
  messages: profileView.state.messages.filter((m) => !posterMessages.has(m)),
  isFormChanged: true,
});

/**
 * Determine which part of the accordion to show next when there are validation
 * errors to correct.
 *
 * @function getInvalidSteps
 * @private
 * @param {object} state profileView state
 * @param {object} validation profileView validation state
 */
const getInvalidSteps = (state, validation) => {
  const { values } = profileView;
  const invalidSteps = new Set();
  if (
    !isFileSelected(values.posterFile)
    || !validation.fields.posterFile?.valid
  ) {
    invalidSteps.add(steps.POSTER);
  }
  if (
    state.content.type === metadataTypes.VIDEO
    && (!isFileSelected(values.videoFile) || !validation.fields.videoFile?.valid)
  ) {
    invalidSteps.add(steps.FILE);
  }
  if (
    state.content.type === metadataTypes.DOCUMENT
    && (
      !isFileSelected(values.documentFile)
      || !validation.fields.documentFile?.valid
    )
  ) {
    invalidSteps.add(steps.FILE);
  }
  if (!validation.fields.title.valid) invalidSteps.add(steps.BASIC);
  if (!validation.fields.description.valid) invalidSteps.add(steps.BASIC);
  return [...invalidSteps].sort(sortNum);
};

const onDelete = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { id, type, title: contentTitle } = profileView.state.content;
  if (await modalView.confirm(
    `Are you sure you want to delete this content? This action is irreversible.`,
  )) {
    try {
      loadingView.show(`Deleting ${contentTitle}...`);
      switch (type) {
        case metadataTypes.VIDEO:
          await api.metadata.deleteVideo(id);
          break;
        case metadataTypes.DOCUMENT:
          await api.metadata.deleteDocument(id);
          break;
        case metadataTypes.LIST:
          await api.metadata.deletePlaylist(id);
          break;
        default:
          throw new Error("Invalid metadata type.");
      }
      await modalView.alert(staticMessages.successDelete.text);
      window.location.replace("/admin/manage-content", 2000);
      loadingView.show(``);
    } catch (err) {
      log.error(err);
      switch (e.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDeniedEdit);
          break;
        case 404:
          notificationView.post(staticMessages.doesNotExist);
          break;
        case 500:
          notificationView.post(staticMessages.errorDeleting);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
      loadingView.hide();
    }
  }
};

/**
 * Handle errors that occured while saving content.
 * @param {Error} err
 */
const handleError = (err) => {
  log.error(err);
  if (err.statusCode) {
    switch (err.statusCode) {
      case 403:
        notificationView.post(staticMessages.accessDenied);
        break;
      case 400:
      default:
        notificationView.post(staticMessages.backendErrors);
        tidyBackendError(err.body).forEach((text) => notificationView.post({
          title: "Error",
          text,
          type: notificationMessageTypes.FAIL,
        }));
        break;
    }
  } else {
    notificationView.post(staticMessages.backendErrors);
  }
};

const onSave = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  profileView.setFullValidation(true);
  const validation = profileView.validate(true);
  const { values } = profileView;
  const { type } = profileView.state.content;
  const {
    selectedLabels,
    listItems,
    manageRightsEnabled,
    previouslyHadLabels,
  } = profileView.state;
  if (!checkContentFiles(profileView, steps.FILE, steps.POSTER)) return;
  if (!validation.valid) {
    const invalidSteps = getInvalidSteps(profileView.state, validation);
    profileView.update({
      validation,
      invalidSteps,
      step: (invalidSteps[0] !== undefined)
        ? invalidSteps[0]
        : profileView.state.step,
    });
    notificationView.post(staticMessages.invalid);
    return;
  }
  if (!selectedLabels.length && previouslyHadLabels) {
    if (!await modalView.confirm(
      "You have not selected any search labels. Are you sure you want to continue?",
    )) {
      profileView.update({
        step: steps.LABEL,
      });
      return;
    }
  }
  if (type === metadataTypes.LIST && !listItems.length) {
    if (!await modalView.confirm(
      "You have not added any items to the playlist. Are you sure you want to continue?",
    )) {
      profileView.update({
        step: steps.PLAYLIST,
      });
      return;
    }
  }
  const files = {};

  try {
    files.posterFile = (
      await handleFilePickers({ posterFile: values.posterFile }, loadingView)
    ).posterFile;
  } catch (err) {
    profileView.updateState({ invalidSteps: [steps.POSTER], step: steps.POSTER });
    handleError(err);
    loadingView.hide(); // just in case it crapped out while loading view was open
    return;
  }
  try {
    if (type === metadataTypes.VIDEO) {
      files.videoFile = (await handleFilePickers(
        { videoFile: values.videoFile },
        loadingView,
      )).videoFile;
    }
    if (type === metadataTypes.DOCUMENT) {
      files.documentFile = (await handleFilePickers(
        { documentFile: values.documentFile },
        loadingView,
      )).documentFile;
    }
  } catch (err) {
    profileView.updateState({ invalidSteps: [steps.FILE], step: steps.FILE });
    handleError(err);
    loadingView.hide(); // just in case it crapped out while loading view was open
    return;
  }

  const data = {
    id: values.id,
    title: values.title,
    description: values.description || "",
    startDate: values.startDate ? parseISO(values.startDate) : null,
    endDate: values.endDate ? parseISO(values.endDate) : null,
    language: "eng",
    categories: [...selectedLabels],
  };

  log.debug("got updated file descriptors", files);

  if (files.posterFile) data.posterFileId = files.posterFile.id;
  if (files.videoFile) data.videoFileId = files.videoFile.id;
  if (files.documentFile) data.documentFileId = files.documentFile.id;

  // handle playlist-specific stuff
  if (type === metadataTypes.LIST) {
    log.debug("appending items", listItems, listItems.map((i) => i.id));
    data.itemIds = listItems.map((i) => i.id);
  }

  loadingView.show(`Saving ${metadataTypesFriendly.get(type)}...`);
  log.debug("saving item", data);
  try {
    let res;
    switch (type) {
      case metadataTypes.VIDEO:
        res = await api.metadata.updateVideo(data.id, data);
        cacheStream.clearVideo(data.id);
        break;
      case metadataTypes.DOCUMENT:
        res = await api.metadata.updateDocument(data.id, data);
        cacheStream.clearDocument(data.id);
        break;
      case metadataTypes.LIST:
        res = await api.metadata.updatePlaylist(data.id, data);
        cacheStream.clearPlaylist(data.id);
        break;
      default:
        notificationView.post(staticMessages.unknown);
        break;
    }
    if (manageRightsEnabled) {
      await aclGrantQueue.process();
    }
    profileView.update({ isFormChanged: false });
    await modalView.alert(staticMessages.successSaved.text);
    loadingView.hide();
    loadingView.show("Reloading...");
    window.location.assign(
      `/admin/content-profile?type=${res.type}&id=${res.id}`,
    );
  } catch (err) {
    log.error(err);
    handleError(err);
    loadingView.hide(); // just in case it crapped out while loading was open
  }
};

const keepValue = (current) => ({
  mode: filePickerModes.KEEP,
  current,
});

const videoPicker = (self) => collapsible(
  {
    title: "Video File",
    open: self.state.step === steps.FILE,
    onChange: onOpenStep(self, steps.FILE),
  },
  self.bind([
    [hidden, { name: "type", value: metadataTypes.VIDEO }],
    [
      filePicker.video,
      filePickerConfig("videoFile", self, false, onVideoChange),
    ],
    [button, {
      label: "Continue",
      onClick: continueFromVideoFileStep,
      sel: "#continue-from-video-file-step",
    }],
  ], { videoFile: keepValue(self.state.content.videoFile) }),
);

const documentPicker = (self) => collapsible(
  {
    title: "Document File",
    open: self.state.step === steps.FILE,
    onChange: onOpenStep(self, steps.FILE),
  },
  self.bind([
    [hidden, { name: "type", value: metadataTypes.DOCUMENT }],
    [
      filePicker.document,
      filePickerConfig("documentFile", self, false, onDocumentChange),
    ],
    [button, {
      label: "Continue",
      onClick: continueFromVideoFileStep,
      sel: "#continue-from-document-file-step",
    }],
  ], { documentFile: keepValue(self.state.content.documentFile) }),
);

const posterPicker = (self) => collapsible({
  title: "Poster File",
  open: self.state.step === steps.POSTER,
  onChange: onOpenStep(self, steps.POSTER),
}, self.bind([
  [
    filePicker.image,
    filePickerConfig("posterFile", self, false, onPosterChange),

  ],
  [button, {
    label: "Continue",
    onClick: continueFromPosterFileStep,
    sel: "#continue-from-poster-file-step",
  }],
], { posterFile: keepValue(self.state.content.posterFile) }));

const fileStep = (self, entry) => {
  const fileCollapse = (inner) => collapsible(
    {
      title: "Files",
      open: fileOpenSteps.has(self.state.step),
      onChange: onOpenStep(self, steps.POSTER),
    }, [
      inner,
      posterPicker(self),
    ],
  );

  switch (entry.type) {
    case metadataTypes.VIDEO:
      return fileCollapse(videoPicker(self));
    case metadataTypes.DOCUMENT:
      return fileCollapse(documentPicker(self));
    case metadataTypes.LIST:
      return posterPicker(self);
    default:
      return div("", "Invalid content type.");
  }
};

const fileInfo = (entry) => {
  switch (entry.type) {
    case metadataTypes.VIDEO:
      return [
        fakeInput("Created", entry.createdAt?.toLocaleDateString() || "unknown"),
        fakeInput("language", languageCodeMap.get(entry.language) || "unknown"),
        fakeInput("year", entry.year || "unknown"),
        fakeInput("width", entry.width ? `${entry.width} px` : "unknown"),
        fakeInput("height", entry.height ? `${entry.height} px` : "unknown"),
        fakeInput("duration", entry.length ? prettyTime(entry.length) : "unknown"),
        fakeInput("size", entry.videoFile.size ? prettyBytes(entry.videoFile.size) : "unknown"),
      ];
    case metadataTypes.DOCUMENT:
      return [
        fakeInput("Created", entry.createdAt?.toLocaleString() || "unknown"),
        fakeInput("language", languageCodeMap.get(entry.language) || "unknown"),
      ];
    default:
      return [
        fakeInput("Created", entry.createdAt?.toLocaleString() || "unknown"),
      ];
  }
};

const metadataStep = (self, entry) => {
  const { step, editEnabled } = self.state;
  const {
    title: titleValue,
    description: descriptionValue,
    startDate,
    endDate,
  } = entry;

  return collapsible({
    title: "Metadata",
    open: metadataOpenSteps.has(step),
  }, [
    collapsible(
      {
        title: "Basic Information",
        open: step === steps.BASIC,
        onChange: onOpenStep(self, steps.BASIC),
      },
      self.bind([
        [hidden, { name: "type", value: metadataTypes.LIST }],
        [hidden, { name: "id", value: entry.id }],
        [title, { value: titleValue, required: true, disabled: !editEnabled }],
        [description, { value: descriptionValue, disabled: !editEnabled }],
        button.primary({
          label: "Continue",
          onClick: continueFromBasicsStep,
        }),
      ]),
    ),
    collapsible({
      title: "Schedule",
      open: step === steps.SCHEDULE,
      onChange: onOpenStep(self, steps.SCHEDULE),
    }, [
      contentSchedule({
        startDate,
        endDate,
        onEnable: () => self.update({
          content: {
            startDate: startOfDay(new Date()),
            endDate: startOfDay(addWeeks(new Date(), 1)),
          },
        }),
        onDisable: () => self.update({
          content: {
            startDate: null,
            endDate: null,
          },
        }),
      }),
      button.primary({
        label: "Continue",
        onClick: continueFromScheduleStep,
        sel: "#continue-from-schedule-step",
      }),
    ]),
    metadataFileInfoTypes.has(entry.type) ? collapsible({
      title: "Additional",
      open: step === steps.FILE_INFO,
      onChange: onOpenStep(self, steps.FILE_INFO),
    }, fileInfo(entry)) : "",
  ]);
};

const playlistStep = (self, entry) => {
  const { metadata, listItems, step } = self.state;
  if (entry.type === metadataTypes.LIST) {
    return collapsible({
      title: "Playlist Items",
      open: step === steps.PLAYLIST,
      onChange: onOpenStep(self, steps.PLAYLIST),
    }, [
      metaList(
        self.state.listItems,
        (item) => self.update({
          step: steps.PLAYLIST,
          listItems: self.state.listItems.filter((i) => i.id !== item.id),
        }),
      ),
      button.subtle({
        icon: "plus-circle",
        label: "Add Item",
        sel: ".back",
        onClick: () => modalView.show(metadataSelect(
          {
            entries: difference(
              metadata.filter((item) => item.type !== metadataTypes.LIST),
              listItems,
            ),
            onSelect: (item) => self.update({
              step: steps.PLAYLIST,
              listItems: self.state.listItems.concat([item]),
            }),
            search: "",
          },
          modalView,
        )),
      }),
      button.primary({
        label: "Continue",
        onClick: continueFromPlaylistStep,
      }),
    ]);
  }
  return "";
};

const labelsStep = (self) => {
  const { selectedLabels, step, editEnabled } = self.state;
  return collapsible({
    title: "Search Labels",
    open: step === steps.LABEL,
    onChange: onOpenStep(self, steps.LABEL),
  }, [
    labelCollection(selectedLabels, onRemoveLabel(steps, self, modalView)),
    button.subtle({
      icon: "plus-circle",
      label: "Add Label",
      onClick: onSelectLabel(steps, self, modalView),
      disabled: !editEnabled,
    }),
    button.primary({
      label: "Continue",
      onClick: continueFromLabelStep,
    }),
  ]);
};

const showForm = (self) => {
  // each time we access values the accessor has to rebuild the values object
  // so store it here and pass it into the functions separate from self itself
  const entry = {
    ...self.state.content,
    ...self.values,
  };
  // bootstrap time values back into dates
  if (self.state.content.startDate === null) {
    entry.startDate = null;
  } else if (typeof entry.startDate === "string") {
    entry.startDate = parseISO(entry.startDate);
  }
  if (self.state.content.endDate === null) {
    entry.endDate = null;
  } else if (typeof entry.endDate === "string") {
    entry.endDate = parseISO(entry.endDate);
  }

  return form(
    "#add-content",
    [
      metadataStep(self, entry),
      playlistStep(self, entry),
      labelsStep(self),
      fileStep(self, entry),
      reviewCollapsible({ ...entry, opened: true }),
    ],
  );
};

const showActionButtons = (actionsView) => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: (e) => onSave(e),
    disabled: !actionsView?.state?.editEnabled,
    sel: "#save-content",
  }),
  actionsView?.state?.deleteEnabled ? button.warning({
    icon: "trash",
    label: "Delete",
    onClick: (e) => onDelete(e),
    sel: "#delete-content",
  }) : "",
]);

const showPageReferenceView = (theView) => {
  const { state } = theView;
  return pageListWidget({ pages: state.pageReferences });
};

const showUserAccess = () => {
  if (!userAccessView) return div("#access-rights.placeholder");
  const { state } = userAccessView;
  const {
    user,
    grants,
    allUsers,
    groups,
    content: subject,
    anonymousUser,
  } = state;
  return div("#access-rights", userContentAccess({
    anonymousUser,
    grants,
    users: allUsers,
    groups,
    subject,
    subjectType: aclSubjectTypes.MEDIA,
    onAdd: (grant) => {
      userAccessView.update({
        grants: [
          ...userAccessView.state.grants,
          grant,
        ],
      });
      aclGrantQueue.update(grant);
    },
    onRemove: async (grant) => {
      const newGrants = grants.filter((g) => g.principal.id !== grant.principal.id);
      if (!(await validateOwnRights(user, newGrants, grants))) return;
      if (!(await validateRights(grant.principal, newGrants, grants))) return;
      userAccessView.update({ grants: newGrants });
      aclGrantQueue.remove(grant);
    },
    onUpdate: async (grant) => {
      const newGrants = grants.map((g) => {
        if (g.principal.id === grant.principal.id) {
          return grant;
        }
        return g;
      });
      if (!(await validateOwnRights(user, newGrants, grants))) return;
      if (!(await validateRights(grant.principal, newGrants, grants))) return;
      userAccessView.update({ grants: newGrants });
      aclGrantQueue.update(grant);
    },
  }, modalView, notificationView));
};

const preparePlaylistItems = (metadataIdsString, metadataList, existingMetadata) => {
  if (!metadataIdsString) {
    return existingMetadata;
  }
  const metadataIds = metadataIdsString.split(",");
  const existingMetadataIds = existingMetadata.map((item) => item.id);
  const uniqueIds = unique([
    ...existingMetadataIds,
    ...metadataIds,
  ]);
  return uniqueIds.map((id) => metadataList.find((item) => item.id === id));
};

const analyticsState = (contentDurationAnalytics, content) => ({
  type: chartTypes.BAR,
  data: analyticsContentDurationToDataset(contentDurationAnalytics, content.length),
  onDateSelected: async ({ startDate, endDate }) => {
    if (!startDate && !endDate) {
      return;
    }
    const contentDuration = await getContentDuration(content.id, {
      startDate,
      endDate,
    });
    analyticsView.update({
      contentDurationAnalytics: contentDuration,
    });
  },
});

const doUpdate = (profileViewView) => {
  profileViewView.render();
  userAccessView.update({ content: profileViewView.state.content });
  actionButtonsView.update(profileViewView.state);
  pageReferenceView.update(profileViewView.state);
};

/**
 * The page initializer for content profile.
 *
 * @function contentProfile
 * @param {module:ui/html~Selector} selector the root element for the content profile form
 */
export default async function contentProfile(selector) {
  const { type, id, metadataIds } = getQueryParams();

  let pageTitle = `${metadataTypesFriendly.get(type)} Profile`;

  const { modal, loading, header, notification } = dashboardLayout(
    selector,
    [
      actionBar(placeholder("Actions", "#actions")),
      dashboardWidget(
        form("#content-profile"),
        "Profile",
        ".profile",
      ),
      dashboardWidget(
        placeholder("Loading...", "#access-rights"),
        "Access Control",
        ".access-rights",
      ),
      dashboardWidget(
        placeholder("Coming Soon", "#analytics"),
        "Analytics",
        ".analytics",
      ),
      dashboardWidget(
        placeholder("Loading...", "#page-reference"),
        "Page Reference",
        ".page-reference",
      ),
    ],
    pageTitle,
  );
  modalView = modal;
  loadingView = loading;
  notificationView = notification;
  loading.show();

  const user = cache.getProfile();
  let content, metadata, labels, files, descriptors, allUsers, groups, config,
    contentDurationAnalytics, anonymousUser;

  const getData = () => {
    switch (type) {
      case metadataTypes.VIDEO:
        return api.metadata.getVideo(id, true);
      case metadataTypes.DOCUMENT:
        return api.metadata.getDocument(id, true);
      case metadataTypes.LIST:
        return api.metadata.getPlaylist(id, true);
      default:
        return null;
    }
  };

  let userRights;
  let grants;
  let pageReferences;

  try {
    [
      anonymousUser,
      files,
      descriptors,
      metadata,
      labels,
      content,
      allUsers,
      groups,
      userRights,
      grants,
      pageReferences,
      config,
      contentDurationAnalytics,
    ] = await Promise.all([
      api.user.getAnonymousUser(),
      api.media.files(),
      api.file.list(),
      api.metadata.list(),
      api.metadata.listCategories(),
      getData(),
      api.user.list().catch(() => []),
      api.acl.listGroups().catch(() => []),
      api.acl.listPrincipalAccessRightsForMetadataId(
        user.id,
        aclPrincipalTypes.INDIVIDUAL,
        id,
        type,
      ).catch(() => new Set()),
      api.acl.listMetadataGrants({ id, type }).catch(() => []),
      api.page.listPageReferences(id),
      getConfig(),
      getContentDuration(id),
    ]);

    if (grants) {
      // populate subject since we only gave the id and type earlier
      grants = grants.map((grant) => ({ ...grant, subject: content }));
    } else {
      grants = [];
    }

    aclGrantQueue = makeGrantQueue(content, aclSubjectTypes.MEDIA);

    const state = merge(
      defaultState,
      {
        anonymousUser,
        listItems: preparePlaylistItems(metadataIds, metadata, content.items || []),
        config,
        files,
        descriptors,
        user,
        metadata,
        labels,
        content,
        allUsers,
        userRights,
        groups,
        grants,
        pageReferences,
        selectedLabels: content?.categories?.length
          ? content.categories
          : [],
        previouslyHadLabels: !!content?.categories?.length,
        editEnabled: isRoot(user)
          || userRights.has(aclRights.WRITE) || userRights.has(aclRights.OWNER),
        deleteEnabled: isRoot(user)
          || userRights.has(aclRights.DELETE) || userRights.has(aclRights.OWNER),
        manageRightsEnabled: isRoot(user)
          || (grants?.length && allUsers?.length && groups?.length),
      },
    );

    pageTitle = content?.title || pageTitle;
    header.update({ user, title: pageTitle });

    userAccessView = view.create(showUserAccess)("#access-rights", state);
    pageReferenceView = view.create(showPageReferenceView)("#page-reference", state);
    actionButtonsView = view.create(showActionButtons)("#actions", state);
    if (content.type === metadataTypes.VIDEO) {
      analyticsView = view.chart(chartWithCalendar(modalView))("#analytics", analyticsState(contentDurationAnalytics, content));
    }

    profileView = formManagedView(showForm, doUpdate)("#content-profile", state);

    loading.hide();
  } catch (e) {
    if (!content) {
      log.error(e);
      await modalView.alert("The content entry you requested no longer exists, or you do not have access to it.");
    } else {
      log.error(e);
      await modalView.alert("Unable to retrieve neccessary metadata at this time. Please try again in a few moments.");
    }
    window.location.replace("/admin/manage-content");
  }
}
