/**
 * Editor fields for playlist sections.
 *
 * @module ui/component/modal/section-edit/course
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  hr,
  label,
} from "ui/html";
import cameoCourse from "ui/component/cameo-course";
import courseSelectModal from "ui/component/modal/course-select";
import title from "ui/component/form-managed/field-title";
import button from "ui/component/form-managed/button";
import input from "ui/component/form-managed/field-input";

const selectCourse = async (self, modalView) => {
  const { courses } = self.state;
  const selected = await modalView.async(courseSelectModal({
    courses,
  }, modalView), false);
  if (selected) {
    const sectionTitle = !self.state.section.item
      || (self.state.section.title === self.state.section.item.title)
      ? selected.title
      : self.state.section.title;
    /* eslint-disable-next-line no-param-reassign */
    self.element.querySelector("[name=title]").value = sectionTitle;

    self.update({
      section: {
        itemId: selected.id,
        item: selected,
        title: sectionTitle,
      },
      messages: [],
    });
  }
};

/**
 * @function courseFields
 * @private
 *
 * @param self
 * @param {module:ui/view/modal~modalView} modalView
 * @return {module:ui/html~ChildEl[]}
 */
export default function courseFields(self, modalView) {
  const { section, courses } = self.state;
  let currentCourse = section.item;
  if (!currentCourse && section.itemId) {
    currentCourse = courses.find((m) => m.id === section.itemId);
  }
  return currentCourse
    ? [
      [title, { value: section.title }],
      [input, { value: section.text, label: "No entries text", name: "text" }],
      hr(),
      label("Course", ".required"),
      cameoCourse({
        course: currentCourse,
        controls: [
          button.icon({
            icon: "edit",
            label: "Change Course",
            onClick: () => selectCourse(self, modalView),
            sel: ".primary",
          }),
        ],
      }),
      [input, { type: "hidden", name: "itemId", value: currentCourse.id, required: true }],
    ]
    : [
      [title, { value: section.title, disabled: true }],
      [input, { value: section.text, label: "No entries text", name: "text" }],
      hr(),
      label("Course", ".required"),
      button.standIn({
        label: "Please Select Course",
        onClick: () => selectCourse(self, modalView),
      }),
    ];
}
