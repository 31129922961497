/**
 * The admin page for creating or editing course.
 *
 * Corresponds to `markup/admin/course-profile.html`
 *
 * @module ui/page/admin/course/profile
 * @category Pages
 * @subcategory Admin - Groups
 */
import xs from "xstream";
import api from "api";
import { EXPAND_COURSE_PROFILE } from "api/course/constants";
import log from "log";
import cache from "cache";
import { CACHE_COURSE_KEYS } from "cache/constants";
import { metadataTypes } from "model/metadata/constants";
import { aclPrincipalTypes, aclRights, aclSubjectTypes } from "model/acl/constants";
import { featureTypes } from "model/config/constants";
import { isSingleLecture } from "model/course";
import { courseEntryType } from "model/course/constants";
import { defaultGradingSchemes } from "model/course/default-grading-scheme";
import { pageTemplateTypes } from "model/dynamic-page/templates";
import { getQueryParams } from "util/navigation";
import { a, div, p } from "ui/html";
import widget from "ui/component/dashboard-widget";
import view from "ui/view";
import actionBar from "ui/component/dashboard-action-bar";
import sortable from "ui/component/sortable";
import cameoModule from "ui/component/cameo-module";
import lectureModal from "ui/component/modal/course/lecture";
import metadataSelect from "ui/component/modal/metadata-select";
import moduleModal from "ui/component/modal/course/module";
import cameoLecture from "ui/component/cameo-lecture";
import placeholder from "ui/component/placeholder";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import description from "ui/component/form-managed/field-description";
import spinner from "ui/component/spinner";
import { tidyBackendError } from "util/error";
import { metadataTypeFilter } from "util/filter";
import { merge } from "util/object";
import { userContentAccess } from "ui/component";
import {
  bootstrapGroupMembers,
  makeGrantQueue,
  validateOwnRights,
  validateRights,
} from "util/acl";
import gradingSchemeSelectModal from "ui/component/modal/course/grading-scheme-select";
import cameoGradingScheme from "ui/component/cameo-grading-scheme";
import pageListWidget from "ui/component/page-list-widget";
import dashboardLayout from "ui/page/layout/dashboard";
import title from "ui/component/form-managed/field-title";
import { notificationMessageTypes } from "model/notification/constants";
import { getAssessmentStatusesByCourseId } from "api/v2/analytics";
import { fetchAndOpenEvaluationByEvalStatus } from "util/assessments";
import { getExtendedAnalyticsEvaluations } from "util/course";
import { guard } from "util/feature-flag";
import { defaultProfileState, SINGLE_LECTURE, staticMessages } from "./state";
import evaluationStatusTable from "./evaluation-status-table";

let loadingView;
let courseProfileView;
let modulesView;
let actionsView;
let headerView;
let notificationView;
let modalView;
let userAccessView;
let aclGrantQueue;
let evaluationsView;
let pageReferenceView;

const pageUpdateChannel = (typeof BroadcastChannel !== "undefined")
  ? new BroadcastChannel("page_updates")
  : null;

const selectGradingScheme = async (oldScheme = {}) => {
  const selectedGradingScheme = await modalView.async(
    gradingSchemeSelectModal({
      selectedScheme: oldScheme,
      gradingSchemes: defaultGradingSchemes,
    }, modalView),
  );
  if (selectedGradingScheme) {
    // wipe old scheme first to prevent a merge
    courseProfileView.updateState({ course: { gradingScheme: null } });
    courseProfileView.update({
      course: {
        gradingScheme: selectedGradingScheme,
      },
    });
  }
};

const handleError = (err) => {
  if (err.statusCode) {
    switch (err.statusCode) {
      case 403:
        notificationView.post(staticMessages.accessDenied);
        break;
      case 400:
      default:
        tidyBackendError(err).forEach((text) => notificationView.post({
          title: "API Error Message",
          text,
          type: notificationMessageTypes.FAIL,
        }));
        break;
    }
  } else {
    notificationView.post(staticMessages.backendErrors);
  }
};

const doSave = async () => {
  courseProfileView.setFullValidation(true);
  const validation = courseProfileView.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  const { values, state } = courseProfileView;

  if (!state.course.gradingScheme) {
    notificationView.post(staticMessages.gradingScheme);
    return;
  }
  try {
    const partial = {
      ...state.course,
      title: values.title,
      description: values.description,
      id: state.courseId,
    };
    loadingView.show(`Saving ${partial.title}`);
    let response;
    if (state.courseId) {
      response = await api.course.update(partial);
      if (state.manageRightsEnabled) await aclGrantQueue.process();
    } else {
      response = await api.course.create(partial);
    }
    // FIXME hacky thing to fix cache v2 invalidation
    [...CACHE_COURSE_KEYS].forEach((key) => cache.deleteValue(key));
    loadingView.hide();
    if (state.courseId) {
      const evaluationStatuses = await getAssessmentStatusesByCourseId(response.id);
      notificationView.post(staticMessages.success);
      courseProfileView.update({
        course: response,
        evaluationStatuses,
      });
      headerView.update({ title: response.title });
      loadingView.hide();
    } else {
      await modalView.alert(`${partial.title} created. Opening profile...`);
      window.location.assign(`/admin/course-profile?id=${response.id}`);
    }
  } catch (e) {
    log.error(e);
    handleError(e);
    loadingView.hide();
  }
};

const showCourseProfileForm = (self) => {
  const { course, editEnabled } = self.state;
  return form(
    "#course-profile-form",
    self.bind([
      [title, { required: true, value: course.title, disabled: !editEnabled }],
      [description, { value: course.description, disabled: !editEnabled }],
      course.gradingScheme
        ? div(
          ".grading-scheme-entry",
          {
            on: {
              click: editEnabled ? () => {
                selectGradingScheme(course.gradingScheme);
              } : undefined,
            },
          },
          cameoGradingScheme({
            gradingScheme: course.gradingScheme,
            onChangeMode: editEnabled
              ? (automatic) => self.update({
                course: { gradingScheme: { automatic } },
              })
              : null,
          }),
        )
        : button.standIn({
          label: "Select grading scheme",
          onClick: () => {
            selectGradingScheme();
          },
        }),
    ]),
  );
};

const onEntriesSort = (newSortOrder) => {
  const modules = [];
  const { course } = courseProfileView.state;
  const oldEntries = course.modules;
  newSortOrder.forEach((i) => modules.push(oldEntries[i]));
  courseProfileView.update({ course: { ...course, modules }, modulesReordered: true });
};

const createSingleLectureModule = (lecture) => ({
  id: null,
  title: SINGLE_LECTURE,
  description: null,
  startDate: lecture?.schedule?.startDate || undefined,
  endDate: lecture?.schedule?.endDate || undefined,
  lectures: [{
    ...lecture,
    schedule: undefined,
  }],
});

const addModule = async () => {
  const { state } = courseProfileView;
  const { users, course } = state;
  const { metadataList } = modulesView.state;
  let createItemType;
  const result = await modalView.confirm(
    "What would you like to create?",
    null,
    "Module",
    "Single lecture",
    false,
    true,
    true,
  );
  if (result) {
    createItemType = courseEntryType.MODULE;
  } else if (result !== null) {
    createItemType = courseEntryType.SINGLE_LECTURE;
  }
  let newModule;
  switch (createItemType) {
    case courseEntryType.MODULE: {
      newModule = await modalView.async(moduleModal({
        course,
        module: { title: "New Module" },
        metadataList,
        users,
      }, modalView, notificationView), false);
      break;
    }
    case courseEntryType.SINGLE_LECTURE: {
      const lecture = await modalView.async(lectureModal({
        course,
        module: { title: SINGLE_LECTURE },
        metadataList,
        users,
        showSchedule: true,
      }, modalView, notificationView), false);
      if (lecture) newModule = createSingleLectureModule(lecture);
      break;
    }
    default:
      return;
  }
  if (newModule) {
    courseProfileView.update({
      course: {
        ...state.course,
        modules: [
          ...state.course.modules,
          newModule,
        ],
      },
    });
  }
};

const importPlaylist = async () => {
  const { LIST, VIDEO, DOCUMENT } = metadataTypes;
  const {
    course,
    user,
    users,
  } = courseProfileView.state;
  const { metadataList } = modulesView.state;
  const selected = await modalView.async(metadataSelect.async({
    entries: metadataList.filter(metadataTypeFilter(LIST)),
  }, modalView));
  if (selected) {
    const lectures = selected.items.filter(metadataTypeFilter(VIDEO)).map((vid) => ({
      title: vid.title,
      description: vid.description,
      videoMetadata: vid,
      instructor: user,
    }));

    const attachments = selected.items.filter(metadataTypeFilter(DOCUMENT));

    if (lectures.length && attachments.length) {
      lectures[0].additionalMaterial = attachments;
    } else if (!lectures.length) {
      await modalView.alert("The selected playlist contained no videos.");
      return;
    }

    const module = await modalView.async(moduleModal({
      course,
      metadataList,
      module: {
        title: selected.title,
        description: selected.description,
        lectures,
        assessments: [],
      },
      users,
      headerTitle: selected.title,
    }, modalView, notificationView));

    if (module) {
      courseProfileView.update({
        course: {
          ...course,
          modules: [
            ...course.modules,
            module,
          ],
        },
      });
    }
  }
};

const showSingleLectureCameo = (module, state, course) => cameoLecture({
  course,
  module,
  lecture: module.lectures[0],
  metadataList: state.metadataList,
  schedule: {
    startDate: module.startDate,
    endDate: module.endDate,
  },
  users: state.users,
  onRemove: courseProfileView.state.editEnabled ? () => {
    courseProfileView.update({
      course: {
        ...course,
        modules: course.modules.filter((item) => item !== module),
      },
    });
  } : null,
  onUpdate: courseProfileView.state.editEnabled ? (lecture) => {
    courseProfileView.update({
      course: {
        ...course,
        modules: course.modules.map((item) => {
          if (item === module) {
            return ({
              ...item,
              startDate: lecture.schedule?.startDate,
              endDate: lecture.schedule?.endDate,
              lectures: [lecture],
            });
          }
          return item;
        }),
      },
    });
  } : null,
}, modalView, notificationView);

const showModuleCameo = (module, state, course) => {
  const editEnabled = state.editEnabled !== false && courseProfileView.state.editEnabled;
  return cameoModule({
    course,
    module,
    metadataList: state.metadataList,
    users: state.users,
    onRemove: editEnabled ? async (removeModule) => {
      if (module.assessments?.length && !(await modalView.confirm(
        "Attached Assessments",
        "Deleting this module will also delete its assessments and all associated student evaluations. This action is irreversible. Are you sure?",
        "Delete",
        "Cancel",
        true,
      ))) {
        return;
      }
      courseProfileView.update({
        course: {
          ...course,
          modules: course.modules.filter((item) => item.id !== removeModule.id),
        },
      });
    } : null,
    onUpdate: editEnabled ? (updatedModule) => {
      courseProfileView.update({
        course: {
          ...course,
          modules: course.modules.map((item, index) => {
            if (course.modules.indexOf(module) === index) {
              return updatedModule;
            }
            return item;
          }),
        },
      });
    } : null,
  }, modalView, notificationView);
};

let iteration = 0;

const showModules = ({ state }) => {
  const { course } = courseProfileView.state;
  const editEnabled = state.editEnabled !== false && courseProfileView.state.editEnabled;
  if (!state.ready) return placeholder(spinner(), "#course-modules");
  iteration++;
  return div(
    "#course-modules",
    [
      sortable(
        { childSelector: ".cameo", onSort: onEntriesSort },
        div(
          "#entries.entries",
          { key: `entries-${iteration}` },
          course.modules.map((module) => (
            (isSingleLecture(module) && module.lectures?.length)
              ? showSingleLectureCameo(module, state, course)
              : showModuleCameo(module, state, course)
          )),
        ),
      ),
      div(".controls", [
        editEnabled ? button.standIn({
          icon: "plus-circle",
          sel: "#add-entry",
          onClick: addModule,
          label: "Add Module / Lecture",
        }) : "",
        editEnabled ? button.standIn({
          icon: "album-collection",
          sel: "#import-playlist",
          onClick: importPlaylist,
          label: "Import Playlist",
        }) : "",
      ]),
    ],
  );
};

const showUserAccess = (self) => {
  const { state } = courseProfileView;
  if (!self.state.ready) return placeholder(spinner(), "#access-rights");
  const {
    user,
    grants,
    course: subject,
    users,
    groups,
    anonymousUser,
  } = state;
  return div("#access-rights", userContentAccess({
    anonymousUser,
    grants,
    users,
    groups,
    subject,
    subjectType: aclSubjectTypes.COURSE,
    onAdd: (grant) => {
      courseProfileView.update({
        grants: [
          ...courseProfileView.state.grants,
          grant,
        ],
      });
      aclGrantQueue.update(grant);
    },
    onRemove: async (grant) => {
      const newGrants = grants.filter((g) => g.principal.id !== grant.principal.id);
      if (!(await validateOwnRights(user, newGrants, grants, modalView))) return;
      if (!(await validateRights(grant.principal, newGrants, grants, modalView))) return;
      courseProfileView.update({ grants: newGrants });
      aclGrantQueue.remove(grant);
    },
    onUpdate: async (grant) => {
      const newGrants = grants.map((g) => {
        if (g.principal.id === grant.principal.id) {
          return grant;
        }
        return g;
      });
      if (!(await validateOwnRights(user, newGrants, grants, modalView))) return;
      if (!(await validateRights(grant.principal, newGrants, grants, modalView))) return;
      courseProfileView.update({ grants: newGrants });
      aclGrantQueue.update(grant);
    },
  }, modalView, notificationView));
};

const doDelete = async () => {
  const { state } = courseProfileView;
  const assessments = state.course.modules.reduce(
    (acc, m) => (m.assessments?.length
      ? [...acc, ...(m?.assessments || [])]
      : acc),
    [],
  );
  if (assessments.length > 0) {
    await modalView.alert(
      "This action is unavailable.",
      div("", [
        p("The following assessments are attached to this course:"),
        ...assessments.map((as) => p(a(as.title, `/admin/assessment-profile?id=${as.id}`, "", { attrs: { target: "_blank" } }))),
        p("You must remove all assessments from the course before you can delete it."),
      ]),
      "Ok",
      true,
    );
    return;
  }
  const { pageReferences } = pageReferenceView.state;
  if (pageReferences.length) {
    await modalView.alert(
      "This action is unavailable.",
      "This course is assigned to pages. Remove page references first.",
      "Ok",
      true,
    );
    return;
  }
  if (!(await modalView.confirm(
    `Are you sure you want to delete ${state.course.title}?`,
    "This action is irreversible.",
    undefined,
    undefined,
    true,
  ))) {
    return;
  }
  await api.course.deleteById(state.course.id);
  // FIXME remove this after merging update streams
  [...CACHE_COURSE_KEYS].forEach((key) => cache.deleteValue(key));
  await modalView.alert("Course deleted. Returning to course management page.");
  window.location.assign("/admin/manage-courses");
};

const showEvaluations = ({ state }) => (state.ready
  ? evaluationStatusTable({
    evaluationStatuses: state.evaluationStatuses
      .filter((status) => state.assessments
        .some((assessment) => assessment.id === status.assessmentId)),
    assessments: state.assessments,
    users: state.users,
    onEvaluationClick: async (evalStatus) => {
      await fetchAndOpenEvaluationByEvalStatus(evalStatus, loadingView);
    },
  })
  : placeholder(spinner(), "#evaluations"));

const doUpdate = (self) => {
  const { courseId, grants, groups, users, userRights } = self.state;
  self.updateState({
    editEnabled: !courseId
      || (userRights.has(aclRights.WRITE) || userRights.has(aclRights.OWNER)),
    deleteEnabled: userRights.has(aclRights.DELETE)
      || userRights.has(aclRights.OWNER),
    manageRightsEnabled: grants?.length && users?.length && groups?.length,
  });
  self.render();
  // the following read state from courseProfileView and don't need to update
  if (actionsView) actionsView.render();
  if (modulesView) modulesView.render();
  if (evaluationsView) evaluationsView.render();
  if (userAccessView) userAccessView.render();
  if (pageReferenceView) pageReferenceView.render();
};

const doCreatePage = () => () => {
  const { course } = courseProfileView.state;
  window.open(`/admin/add-page?template=${pageTemplateTypes.COURSE_CAROUSEL}&courseId=${course.id}`, "_blank");
};

const showPageReferenceView = (self) => (self.state.ready
  ? pageListWidget({
    pages: self.state.pageReferences,
    onCreatePage: doCreatePage(),
  }, modalView)
  : placeholder(spinner(), "#page-reference"));

const showActionButtons = () => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: doSave,
    disabled: !courseProfileView?.state?.editEnabled,
  }),
  courseProfileView?.state?.deleteEnabled
    ? button.warning({
      icon: "trash",
      label: "Delete",
      onClick: doDelete,
    })
    : "",
]);

const openModuleModal = async (moduleId) => {
  const { course, users } = courseProfileView.state;
  const { metadataList } = modulesView.state;
  const module = course.modules.find((m) => m.id === moduleId);
  if (module.title === SINGLE_LECTURE) {
    const updatedLecture = await modalView.async(lectureModal({
      lecture: module.lectures?.[0],
      metadataList,
      users,
      course,
      module,
    }, modalView, notificationView));
    courseProfileView.update({
      course: {
        ...course,
        modules: course.modules.map((item) => {
          if (item === module) {
            return ({
              ...item,
              startDate: updatedLecture.schedule?.startDate,
              endDate: updatedLecture.schedule?.endDate,
              lectures: [updatedLecture],
            });
          }
          return item;
        }),
      },
    });
  } else {
    const updatedModule = await modalView.async(moduleModal({
      module,
      users,
      course,
      metadataList,
    }, modalView, notificationView));
    courseProfileView.update({
      course: {
        ...course,
        modules: course.modules.map((item, index) => {
          if (course.modules.indexOf(module) === index) {
            return updatedModule;
          }
          return item;
        }),
      },
    });
  }
};

/**
 * @param {Course} course
 * @returns {Assessment[]}
 */
const getAllAssessmentsFromCourse = (course) => course.modules
  .flatMap((module) => module.assessments);

export default async function courseProfile(selector) {
  guard(featureTypes.LMS);
  const { id: courseId, moduleId } = getQueryParams();
  const browserTitle = courseId ? "Course Profile" : "Add Course";
  const views = dashboardLayout(
    selector,
    [
      actionBar(placeholder("", "#actions"), ""),
      widget(form("#course-profile-form"), browserTitle, ".course-profile"),
      widget(placeholder(spinner(), "#access-rights"), "Access Control", ".acl"),
      widget(placeholder(spinner(), "#course-modules"), "Modules", ".modules"),
      widget(
        placeholder("Loading...", "#page-reference"),
        "Page References",
        ".page-reference",
      ),
      widget(placeholder(spinner(), "#evaluations"), "Evaluations", ".evaluations"),
    ],
    browserTitle,
    false,
    courseId ? "" : ".new-course",
  );
  const { header, modal, loading, notification } = views;
  loadingView = loading;
  loading.show();
  headerView = header;
  modalView = modal;
  notificationView = notification;

  const [
    user,
    users,
    rawgroups,
    course,
    anonymousUser,
  ] = await Promise.all([
    api.user.getMe(),
    api.user.list(),
    api.acl.listGroups(),
    courseId
      ? api.course.getCourseById(courseId, true, EXPAND_COURSE_PROFILE)
      : Promise.resolve({ modules: [] }),
    api.user.getAnonymousUser(),
  ]);

  // FIXME maybe this should be done inside the components that need it instead of
  //       at the page level
  const groups = bootstrapGroupMembers(rawgroups, users);
  aclGrantQueue = makeGrantQueue(course, aclSubjectTypes.COURSE);

  const state = merge(defaultProfileState, {
    anonymousUser,
    user,
    users,
    courseId,
    course,
    groups,
  });

  const pageTitle = course?.title ? course.title : "Add Course";

  actionsView = view.create(showActionButtons)("#actions");

  courseProfileView = formManagedView(showCourseProfileForm, doUpdate)(
    "#course-profile-form",
    state,
  );

  if (courseId) {
    pageReferenceView = view.create(showPageReferenceView)("#page-reference");
    pageReferenceView.bindStreams([
      // TODO: use data stream, but listPageReferences is not yet supported
      ["pageReferences", xs.fromPromise(api.page.listPageReferences(courseId))],
    ], () => pageReferenceView.update({ ready: true }));

    evaluationsView = view.create(showEvaluations)("#evaluations");
    evaluationsView.bindStreams([
      // TODO: use data stream, but requires refactoring
      ["assessments", xs.of(getAllAssessmentsFromCourse(course))],
      ["users", xs.of(users)],
      ["evaluationStatuses", xs.fromPromise(getAssessmentStatusesByCourseId(courseId))],
      // TODO: a copy from courseProfile view. Needs refactoring to data stream
      [
        "grants",
        xs.fromPromise(api.acl.listCourseGrants(course).catch(() => new Set())),
      ],
    ], () => {
      const evaluationStatuses = getExtendedAnalyticsEvaluations({
        courseGrants: evaluationsView.state.grants,
        users: evaluationsView.state.users,
        analyticsEvaluations: evaluationsView.state.evaluationStatuses,
        assessments: evaluationsView.state.assessments,
      });
      return evaluationsView.update({ ready: true, evaluationStatuses });
    });
    userAccessView = view.create(showUserAccess)("#access-rights");

    courseProfileView.bindStreams([
      [
        "grants",
        xs.fromPromise(api.acl.listCourseGrants(course).catch(() => new Set())),
      ],
      [
        "userRights",
        xs.fromPromise(api.acl.listPrincipalAccessRightsForCourseId(
          user.id, aclPrincipalTypes.INDIVIDUAL, courseId,
        )),
      ],
    ], () => userAccessView.update({ ready: true }));
  }

  header.update({ user, title: pageTitle });

  // listen for page updates and reload the page list when it happens
  pageUpdateChannel?.addEventListener("message", ({ data }) => {
    // TODO formalize these messages if they end up getting used a lot
    const action = async () => {
      if (data.type === "page_created") {
        const newReferences = await api.page.listPageReferences(courseId);
        pageReferenceView.update({
          pageReferences: newReferences,
        });
      }
    };
    action().catch((e) => log.error("Failed to get new page references", e));
  });

  modulesView = view.create(showModules)("#course-modules");
  modulesView.bindStreams([
    // TODO: use data stream, but requires significant refactoring
    ["metadataList", xs.fromPromise(api.metadata.list().catch((e) => {
      log.error(e);
      notificationView.post(staticMessages.metadataPreloadError);
      modulesView.updateState({ editEnabled: false });
      return [];
    }))],
  ], () => modulesView.update({ ready: true }));

  if (moduleId) {
    openModuleModal(moduleId);
  }

  loadingView.hide();
}
