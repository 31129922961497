/**
 * v2 API endpoints for course attendance.
 *
 * @module api/v2/course/lecture
 * @category Backend API v2
 * @subcategory Course
 */
import { endpoints } from "api/constants";
import { get, post } from "api/request";
import { responseToAbbreviatedCourseAttendance } from "model/course";

/* eslint-disable-next-line import/prefer-default-export */
export const byCourseIdUserId = async (courseId, userId) => {
  try {
    return responseToAbbreviatedCourseAttendance(
      (await get(`${endpoints.COURSE(courseId)}/attendance/${userId}`)).body,
    );
  } catch (e) {
    // not a problem if error, but we need a response
    return null;
  }
};

/**
 * Enroll user to course
 *
 * @function enrollUserToCourse
 * @param {string} courseId
 * @param {string} userId
 * @returns {Promise<CourseAttendance>}
 */
export const enrollUserToCourse = async (courseId, userId) => {
  try {
    return responseToAbbreviatedCourseAttendance((
      await post(`${endpoints.COURSE(courseId)}/enroll/${userId}`)
    ).body);
  } catch (e) {
    return null;
  }
};
