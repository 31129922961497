/**
 * A paragraph element.
 *
 * @module ui/html/p
 * @category UI
 * @subcategory Elements
 */
import el from "ui/common/el";
/**
 *
 * @function p
 * @param {module:ui/html~ChildEl} children text, vnode, or vnode array
 * @param {module:ui/html~Selector} [sel=""] css selectors
 * @param {module:ui/html~Config} [config={}] snabbdom configuration object
 * @return {module:ui/common/el~El}
 */
export default (children, sel = "", config = {}) => el(`p${sel}`, config, children);
