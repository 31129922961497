import div from "ui/html/div";
import h3 from "ui/html/h3";
import progressBar from "ui/component/progress-bar";
import span from "ui/html/span";
import {
  getFileStorageUsed,
  getFileStorageAvailable,
  getFileStoragePercentUsed,
  prettyBytes,
} from "util/file";

const storageUtilization = () => {
  const used = getFileStorageUsed() || 0;
  const available = getFileStorageAvailable() || 0;
  const percentage = Math.round(getFileStoragePercentUsed() || 0);
  let sel = ".ok";
  if (percentage > 75) {
    sel = ".danger";
  } else if (percentage > 50) {
    sel = ".warning";
  }
  return [
    span([
      span(`Storage`),
      span(`${prettyBytes(used)} / ${prettyBytes(available)} (${percentage}%)`, `${sel}.detail`),
    ], ".label.storage"),
    span(progressBar({ value: used, min: 0, max: available, sel }), ".value"),
  ];
};

export default function serverList() {
  return div(
    "#resources.block",
    [
      h3("Resources"),
      ...storageUtilization(),
    ],
  );
}
