/**
 * A cameo for question.
 *
 * @module ui/component/cameo-question
 * @category UI
 * @subcategory Components
 */
import cameo from "ui/component/cameo";
import icon from "ui/component/icon";
import button from "ui/component/form-managed/button";
import { questionType, questionTypeFriendly } from "model/course/constants";
import { getModal } from "ui/view/modal-view";
import questionMultipleChoiceModal from "./modal/assessment/question-multiple-choice";
import questionTextEntryModal from "./modal/assessment/question-text-entry";
import questionFileDropboxModal from "./modal/assessment/question-file-dropbox";

const showQuestion = (
  question,
  onEdit,
  maxPoints,
  usedPoints,
) => async () => {
  let editedQuestion;
  switch (question.questionType) {
    case questionType.MULTIPLE_CHOICE_MULTI_ANSWER:
    case questionType.MULTIPLE_CHOICE_SINGLE_ANSWER:
      editedQuestion = await getModal().async(questionMultipleChoiceModal({
        question,
        maxPoints,
        usedPoints,
      }));
      break;
    case questionType.FILL_IN_LONG:
    case questionType.FILL_IN_SHORT:
      editedQuestion = await getModal().async(questionTextEntryModal({
        question,
        maxPoints,
        usedPoints,
      }));
      break;
    case questionType.UPLOAD:
      editedQuestion = await getModal().async(questionFileDropboxModal({
        question,
        maxPoints,
        usedPoints,
      }));
      break;
    default:
      editedQuestion = null;
  }
  if (editedQuestion) {
    onEdit(editedQuestion);
  }
};

const getIconName = (type) => {
  switch (type) {
    case questionType.MULTIPLE_CHOICE_SINGLE_ANSWER:
    case questionType.MULTIPLE_CHOICE_MULTI_ANSWER:
      return "ballot-check";
    case questionType.FILL_IN_SHORT:
    case questionType.FILL_IN_LONG:
      return "text";
    case questionType.UPLOAD:
      return "file-upload";
    default:
      return "list-alt";
  }
};

/**
 * Cameo element for question
 *
 * @param {Question} question
 * @param {Function} onEdit
 * @param {Function} onRemove
 * @param {number} maxPoints
 * @param {number} usedPoints
 * @param {ModalView} getModal()
 * @param {NotificationView} notificationView
 * @returns {*|El}
 */
export default function cameoQuestion({
  question,
  onEdit = null,
  onRemove = null,
  maxPoints = 0,
  usedPoints = 0,
}) {
  return cameo({
    label: question.text,
    icon: icon.solid(
      getIconName(question.questionType),
      ".question-type",
      { props: { title: questionTypeFriendly.get(question.questionType) } },
    ),
    controls: [
      onEdit
        ? button({
          label: "Edit",
          iconOnly: true,
          icon: "edit",
          onClick: showQuestion(
            question,
            onEdit,
            maxPoints,
            usedPoints,
          ),
        })
        : "",
      onRemove
        ? button.warning({
          label: "Delete",
          iconOnly: true,
          icon: "trash-alt",
          onClick: () => onRemove(question),
        })
        : "",
    ],
    sel: ".cameo-question",
  });
}
