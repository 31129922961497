/**
 * A page footer.
 *
 * @module ui/component/footer
 * @category UI
 * @subcategory Components
 */
import { a, footer, p } from "ui/html";

/**
 * @constant pageFooter
 * @type Object
 */
const pageFooter = {};

/**
 * @function login
 * @return {module:ui/html/footer}
 */
const login = () => footer(p([
  "By signing in or creating an account, you agree to our ",
  a("Terms & Conditions", "/tos"),
  " and ",
  a("Privacy Statement", "/privacy"),
  ".",
]));

const admin = () => footer(p([
  `Copyright ©${new Date().getFullYear()}, SmartEdge. All rights reserved.`,
  a("Terms & Conditions", "/tos"),
  a("Privacy Statement", "/privacy"),
]));

pageFooter.login = login;
pageFooter.admin = admin;

/**
 * @constant footer
 * @type Object
 */
export default pageFooter;
