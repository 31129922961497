/**
 * Static messages for the login page.
 *
 * @module ui/page/login/messages
 * @private
 * @category Pages
 * @subcategory User Accounts
 */
/** */
import { notificationMessageTypes } from "model/notification/constants";
import { frozen } from "util/object";

const { SUCCESS, FAIL, BASIC } = notificationMessageTypes;

/**
 * Collection of messages shown to the user in the notification area.
 *
 * @constant messages
 * @type object
 * @readonly
 * @private
 */
export default frozen({
  invalid: {
    title: "Invalid Form",
    text: "Please correct all errors to continue.",
    type: FAIL,
    duration: 3,
  },
  signInSuccess: {
    title: "Sign in Successful",
    text: "Now redirecting you to the home page.",
    type: SUCCESS,
  },
  signInInvalid: {
    title: "Invalid Sign In",
    text: "The username or password you entered is incorrect. Please try again.",
    type: FAIL,
    duration: 3,
  },
  signInUnverified: {
    title: "Unverified Account",
    text: "Please complete account verification to continue.",
    type: BASIC,
    duration: 3,
  },
  registerSuccessBothVerify: {
    title: "Registration Successful",
    text: `Please enter the OTP (5-digit number) sent to the email address and/or
           mobile number associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  registerSuccessEmailVerify: {
    title: "Registration Successful",
    text: `Please enter the OTP (5-digit number) sent to the email address
           associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  registerSuccessSMSVerify: {
    title: "Registration Successful",
    text: `Please enter the OTP (5-digit number) sent to the mobile number
           associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  registerSuccessAdminVerify: {
    title: "Registration Successful",
    text: `A site administrator will verify your account and notify you when it is available to use.`,
    type: BASIC,
    duration: 10,
  },
  passwordResetUsername: {
    title: `Please enter your registered username to continue.`,
    type: BASIC,
    duration: 10,
  },
  // this is displayed on page
  passwordResetBothVerify: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the email address and/or
           mobile number associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  passwordResetSMSVerify: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the email address
           associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  passwordResetEmailAdminVerify: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the mobile number
           associated with your account. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  passwordResetContactAdmin: {
    title: "Could Not Reset Password",
    text: `Please contact a site administrator for assistance.`,
    type: FAIL,
  },
  passwordResetSuccess: {
    title: "Your password was successfully reset!",
    type: SUCCESS,
  },
  verificationRequiredEmail: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the email address
           associated with your account to complete registration. Thanks!`,
    type: BASIC,
    duration: 10,
  },
  verificationRequiredSMS: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the mobile number
           associated with your account to complete registration.`,
    type: BASIC,
    duration: 10,
  },
  verificationRequiredBoth: {
    title: "Verification Required",
    text: `Please enter the OTP (5-digit number) sent to the mobile number and/or
           email address associated with your account to complete registration.`,
    type: BASIC,
    duration: 10,
  },
  verificationRequiredAdmin: {
    title: "Verification Unavailable",
    text: "Your account is awaiting verification from a site administrator.",
    type: FAIL,
    duration: 10,
  },
  verificationRequiredDisabled: {
    title: "Verification Unavailable",
    text: "Your account is disabled. Contact a site administrator.",
    type: FAIL,
    duration: 10,
  },
  verificationSuccess: {
    title: `Account Verified`,
    text: `Your account was successfully verified. Please log in to continue.`,
    type: SUCCESS,
  },
  verificationFailure: {
    title: "Verification Failed",
    text: `The username or verification code you entered was incorrect. Please check it and try again.`,
    type: FAIL,
    duration: 5,
  },
  verificationRateLimitFailure: {
    title: "Rate Limited",
    text: `Too many verification codes have been dispatched for this account.
           This limit will reset in a few hours. Please try again later.`,
    type: FAIL,
  },
  resendVerifyInputUsername: {
    text: `Please input the username you chose during registration to resend your verification code.`,
    type: FAIL,
    duration: 5,
  },
  resendVerifySuccessSMS: {
    title: "Success",
    text: `A new one-time password was sent to the mobile phone number associated with your
           account. Please enter it to verify your account.`,
    type: SUCCESS,
  },
  resendVerifySuccessEmail: {
    title: "Success",
    text: `A new one-time password was sent to the email address associated with your
           account. Please enter it to verify your account.`,
    type: SUCCESS,
  },
  resendVerifySuccessBoth: {
    title: "Success",
    text: `A new one-time password was sent to the email address and/or mobile number
           associated with your account. Please enter it to verify your acccount.`,
    type: SUCCESS,
  },
  apiError: {
    title: "API Error",
    text: "",
    type: FAIL,
  },
});
