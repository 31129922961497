/**
 * A help modal dialog explaining the "search terms" field for page sections.
 *
 * @module ui/component/modal/help/page-section-no-entries
 * @category UI
 * @subcategory Modal Dialogs
 */
import { p } from "ui/html";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function pageSectionSearchTermsHelpModal(
  skipCache = true,
  async = true,
) {
  return commonHelpModal({
    modalType: modalTypes.QUESTION_GRADING,
    skipCache,
    async,
  }, [
    p("These search keywords will be used to populate the grid or carousel."),
    p("This section performs a search just like the search page's search bar and filters and fills the carousel or grid with the search results."),
  ]);
}
