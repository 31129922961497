/**
 * A basic HTML label element.
 *
 * @module ui/html/label
 * @category UI
 * @subcategory Elements
 */
import el, { textNodesToSpans } from "ui/common/el";
import { merge } from "util/object";

/**
 * Creates a basic LABEL element with parameters for the text and the `for` property.
 *
 * @function label
 * @param {string} labelText the text shown to the user
 * @param {string} forName the name of the labeled element
 * @param {module:ui/common/el} [child] child element, if the label will wrap one
 *
 * @return {module:ui/common/el~El}
 */
const label = (labelText, forName, child, config = {}) => el(
  `label`,
  merge(
    {
      attrs: { for: forName },
    },
    config,
  ),
  textNodesToSpans(child ? [labelText, child] : labelText),
);

export default label;
