/**
 * Constants related to API models.
 *
 * @module model/api/constants
 * @category Model
 * @subcategory API
 */

/**
 * Possible values for the `contentType` field on {@link module:api/types.APIRequest}
 *
 * Used when validating API requests, and determines how the API request is generated.
 *
 * @enum {string} contentTypes
 * @readonly
 * @property {string} FORM_DATA sends `multipart/form-data` and a form data body
 * @property {string} JSON sends `application/json` and a json body
 */
export const contentTypes = Object.freeze({
  FORM_DATA: 'multipart/form-data',
  JSON: 'application/json',
});

/**
 * Set of values of [contentTypes]{@link contentTypes}.
 *
 * @constant contentTypeValues
 * @type {Set.<string>}
 * @default
 * @readonly
 */
export const contentTypeValues = Object.freeze(
  new Set(Object.values(contentTypes)),
);
