/**
 * V2 Cache constants - these are used by data streams with v2 api functions
 * where the cache structure is different from the original APIs.
 *
 * @module cache/constants
 * @category Backend API
 * @subcategory Core
 */

/* METADATA */

/**
 * Cache key for document metadata.
 * @const
 */
export const CACHE_DOCUMENT_KEY_V2 = "documentMetadata_v2";
/**
 * Cache key for playlist metadata.
 * @const
 */
export const CACHE_PLAYLIST_KEY_V2 = "playlistMetadata_v2";
/**
 * Cache key for video metadata.
 * @const
 */
export const CACHE_VIDEO_KEY_V2 = "videoMetadata_v2";
/**
 * Cache key for video metadata playback positions.
 * @const
 */
export const CACHE_PLAYBACK_KEY_V2 = "videoPlaybackPosition_v2";

/* METADATA_SEARCH */

/**
 * Cache key for document metadata search results.
 * @const
 */
export const CACHE_SEARCH_DOCUMENT_KEY_V2 = "searchDocumentMetadata_v2";

/**
 * Cache key for playlist metadata search results.
 * @const
 */
export const CACHE_SEARCH_PLAYLIST_KEY_V2 = "searchPlaylistMetadata_v2";

/**
 * Cache key for video metadata search results.
 * @const
 */
export const CACHE_SEARCH_VIDEO_KEY_V2 = "searchVideoMetadata_v2";

/* DYNAMIC_PAGE */

/**
 * Cache key for dynamic pages.
 * @const
 */
export const CACHE_PAGE_KEY_V2 = "dynamicPage_v2";

/**
 * Cache key for slug <-> id map.
 * @const
 */
export const CACHE_PAGE_SLUG_KEY_V2 = "pageSlug_v2";

/**
 * Cache for page menus.
 * @constant
 */
export const CACHE_PAGE_MENU_KEY_V2 = "pageMenu_v2";

/* COURSE */

/**
 * Cache key for courses.
 * @const
 */
export const CACHE_COURSE_KEY_V2 = "course_v2";

/**
 * Cache key for course searches.
 * @const
 */
export const CACHE_SEARCH_COURSE_KEY_V2 = "searchCourse_v2";

/**
 * Cache key for course lectures.
 * @const
 */
export const CACHE_COURSE_LECTURE_KEY_V2 = "courseLecture_v2";

/**
 * Cache key for course modules.
 * @const
 */
export const CACHE_COURSE_MODULE_KEY_V2 = "courseModule_v2";
/**
 * Cache key for course assessments.
 * @const
 */
export const CACHE_COURSE_ASSESSMENT_KEY_V2 = "courseAssessment_v2";
/**
 * Cache key for course attendance.
 * @const
 */
export const CACHE_COURSE_ATTENDANCE_KEY_V2 = "courseAttendance_v2";
/**
 * Cache key for course attendance.
 * @const
 */
export const CACHE_COURSE_EVALUATION_KEY_V2 = "courseEvaluation_v2";
/**
 * Cache key for course grading schemes.
 * @constant
 */
export const CACHE_COURSE_GRADING_KEY_V2 = "courseGrading_v2";

/**
 * List of all keys related to courses. Used for forcibly wiping cache.
 * @constant
 */
export const CACHE_COURSE_KEYS = new Set([
  CACHE_COURSE_KEY_V2,
  CACHE_SEARCH_COURSE_KEY_V2,
  CACHE_COURSE_LECTURE_KEY_V2,
  CACHE_COURSE_MODULE_KEY_V2,
  CACHE_COURSE_ASSESSMENT_KEY_V2,
  CACHE_COURSE_ATTENDANCE_KEY_V2,
  CACHE_COURSE_EVALUATION_KEY_V2,
  CACHE_COURSE_GRADING_KEY_V2,
]);

/** MISCELLANY */

/**
 * Cache that stores data for resuming a modal.
 * @constant
 */
export const CACHE_MODAL_KEY = "modalStash_v2";
/**
 * Cache for abstract items (base form of metadata / etc).
 * @constant
 */
export const CACHE_ABSTRACT_ITEM_KEY_V2 = "abstractItem_v2";
/**
 * Cache for abstract item searches (base form of metadata / etc).
 * @constant
 */
export const CACHE_SEARCH_ABSTRACT_ITEM_KEY_V2 = "searchAbstractItem_v2";

/** USER */

/**
 * Cache for users.
 * @constant
 */
export const CACHE_USER_KEY_V2 = "user_v2";
/**
 * Cache for user searches.
 * @constant
 */
export const CACHE_SEARCH_USER_KEY_V2 = "searchUser_v2";
/**
 * Cache for abbreviated user results.
 * @constant
 */
export const CACHE_USER_ABBREVIATED_V2 = "briefUser_v2";
