/**
 * A badge displaying the availability of scheduled content.
 *
 * @module ui/component/schedule-availability-badge
 * @category UI
 * @subcategory Components
 */
import span from "ui/html/span";
import strong from "ui/html/strong";
import { usFullDateFormat, isBeforeNow, isAfterNow, monthDayYear } from "util/date";
import { frozen, merge } from "util/object";

/**
 * @private
 */
const dateSpan = (date) => strong(
  monthDayYear(date),
  ".date",
  { attrs: { title: usFullDateFormat(date) } },
);

/**
 * @private
 */
const scheduleBadge = (content, available) => span(
  content,
  `.badge.schedule-availability.${available ? ".available" : ".disabled"}`,
);

/**
 * @private
 */
const defaultState = frozen({
  startDate: null,
  endDate: null,
});

/**
 * Displays a badge based on content's start and end dates.
 *
 * Style is disabled if scheduled duration hasn't started or has already ended.
 * Otherwise style is "available" and date range is displayed.
 *
 * @function scheduleAvailabilityBadge
 * @param {object} inState
 * @param {Date} inState.startDate
 * @param {Date} inState.endDate
 * @returns {El}
 */
export default function scheduleAvailabilityBadge(inState) {
  const state = merge(defaultState, inState);

  if (state.endDate !== null && isBeforeNow(state.endDate)) {
    return [
      scheduleBadge(["unavailable since ", dateSpan(state.endDate)], false),
    ];
  }
  if (state.startDate !== null && isAfterNow(state.startDate)) {
    return [
      scheduleBadge(["unavailable until ", dateSpan(state.startDate)], false),
    ];
  }
  if (state.startDate && state.endDate) {
    return [
      scheduleBadge([
        "available from ",
        dateSpan(state.startDate),
        " until ",
        dateSpan(state.endDate),
      ], true),
    ];
  }
  if (state.endDate) {
    return [
      scheduleBadge(["available until ", dateSpan(state.endDate)], true),
    ];
  }
  if (state.startDate) {
    return [
      scheduleBadge(["available since ", dateSpan(state.startDate)], true),
    ];
  }
  return scheduleBadge("always available", true);
}
