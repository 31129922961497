/**
 * A context-sensitive resume button.
 *
 * @module ui/component/button-video-resume
 * @category Components
 * @subcategory Metadata
 */
import button from "ui/component/form-managed/button";
import { isNowWithin, MIN_DATE, MAX_DATE } from "util/date";
import { videoUrl } from "util/metadata";
import { isPWA } from "util/pwa";

/**
 * A context-sensitive resume button.
 *
 * Returns an empty string if the given video should not have a resume button.
 *
 * @function videoResumeButton
 * @param {object} state
 * @param {Metadata} state.metadata
 * @param {Selector} state.sel
 * @param {function} onClick called before opening URL
 * @param {boolean} sameTab if false, will open target=_blank except in PWA
 */
export default function videoResumeButton({
  metadata,
  onClick = () => {},
  playback,
  sameTab = true,
  sel = "",
}) {
  if (!isNowWithin(metadata.startDate || MIN_DATE, metadata.endDate || MAX_DATE)) {
    return "";
  }
  if (playback?.position > 0) {
    return button.secondary({
      label: "resume",
      onClick: () => {
        onClick();
        const url = videoUrl(metadata, playback);
        if (sameTab || isPWA()) window.location.assign(url);
        else window.open(url, "_blank");
      },
      sel,
    });
  }
  return "";
}
