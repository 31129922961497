/**
 * A card for displaying course modules containing only one lecture.
 *
 * @module ui/component/card/single-lecture-module
 * @category UI
 * @subcategory Components
 */
import {
  a,
  div,
  h2,
  p,
} from "ui/html";
import { lectureUrl } from "util/course";
import { splitParagraphs } from "util/format";

const poster = (title, url) => div(
  ".poster",
  {
    style: url ? { backgroundImage: `url(${url})` } : {},
  },
  title,
);

export default function singleLectureModuleCard(state, lecture, attendance) {
  const video = state.videos.get(lecture.videoId);
  return div(
    `.course-lecture-summary${attendance ? "" : ".disabled"}`,
    [
      h2(lecture.title),
      div(".meta", [
        video
          ? poster(lecture.title, video.getPosterUrl())
          : "",
        lecture.description.length ? div(
          ".description",
          splitParagraphs(lecture?.description).map((para) => p(para)),
        ) : "",
        a(
          "view",
          lectureUrl(lecture.courseId, lecture.moduleId, lecture.id),
          ".button",
        ),
      ]),
    ],
  );
}
