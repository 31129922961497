/**
 * A page for adding content (metadata entries).
 *
 * Corresponds to `markup/admin/add-content.html`
 *
 * @module ui/page/admin/add-content
 * @category Pages
 * @subcategory Admin - Content Management
 */
import addWeeks from "date-fns/addWeeks";
import startOfDay from "date-fns/startOfDay";
import parseISO from "date-fns/parseISO";
import api from "api";
import cache from "cache";
import cacheStream from "data/cache";
import log from "log";
import { metadataTypes, metadataTypesFriendly } from "model/metadata/constants";
import div from "ui/html/div";
import collapsible from "ui/component/collapsible";
import actionBar from "ui/component/dashboard-action-bar";
import contentSchedule from "ui/component/content-schedule";
import widget from "ui/component/dashboard-widget";
import filePicker, { filePickerModes } from "ui/component/form-managed/file-picker";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import title from "ui/component/form-managed/field-title";
import hidden from "ui/component/form-managed/field-hidden";
import button from "ui/component/form-managed/button";
import description from "ui/component/form-managed/field-description";
import labelCollection from "ui/component/label-collection";
import metaList from "ui/component/meta-list";
import metadataSelect from "ui/component/modal/metadata-select";
import tabs from "ui/component/tabs";
import icon from "ui/component/icon";
import view from "ui/view";
import { difference } from "util/array";
import { getQueryParams } from "util/navigation";
import { emit } from "util/event";
import { tidyBackendError } from "util/error";
import { merge } from "util/object";
import adminAddContentHelpModal from "ui/component/modal/help/add-content";
import dashboardLayout from "ui/page/layout/dashboard";
import { notificationMessageTypes } from "model/notification/constants";
import getConfig from "config";
import { sortNum } from "util/sort";
import {
  checkContentFiles,
  checkFileCapacity,
  handleFilePickers,
  isFileSelected,
  filePickerConfig,
  onOpenStep,
  onRemoveLabel,
  onSelectLabel,
} from "./common";
import {
  addSteps as steps,
  defaultAddState as defaultState,
} from "./state";
import {
  staticMessages,
} from "./messages";
import reviewCollapsible from "./review-collapsible";

let modalView;
let loadingView;
let notificationView;
let addContentView;
let actionButtonsView;

/**
 * @function getInvalidSteps
 * @private
 * @param {object} values form values
 * @param {object} validation self validation state
 */
const getInvalidSteps = (values, validation) => {
  if (validation.valid) return [];
  const invalidSteps = new Set();
  if (!isFileSelected(values.posterFile) || !validation.fields.posterFile?.valid) {
    invalidSteps.add(steps.POSTER);
  }
  if (values.type === metadataTypes.VIDEO
    && (!isFileSelected(values.videoFile) || !validation.fields.videoFile?.valid)) {
    invalidSteps.add(steps.ADD);
  }
  if (values.type === metadataTypes.DOCUMENT
    && (!isFileSelected(values.documentFile)
      || !validation.fields.documentFile?.valid)
  ) {
    invalidSteps.add(steps.ADD);
  }
  if (!validation.fields.title.valid) invalidSteps.add(steps.BASIC);
  if (!validation.fields.description.valid) invalidSteps.add(steps.BASIC);
  return [...invalidSteps].sort(sortNum);
};

/**
 * Handle errors that occured while saving content.
 * @param {Error} err
 */
const handleError = (err) => {
  log.error(err);
  if (err.statusCode) {
    switch (err.statusCode) {
      case 403:
        notificationView.post(staticMessages.accessDenied);
        break;
      case 400:
      default:
        notificationView.post(staticMessages.backendErrors);
        tidyBackendError(err.body).forEach((text) => notificationView.post({
          title: "Error",
          text,
          type: notificationMessageTypes.FAIL,
        }));
        break;
    }
  } else {
    notificationView.post(staticMessages.backendErrors);
  }
};

/**
 * Do preflight checks for saving, and if everything looks good
 * make an API request and redirect the user if successful.
 *
 * @function onSave
 * @private
 */
const onSave = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { values } = addContentView;
  const {
    type,
    // title,
    // description,
  } = values;
  const { selectedLabels, listItems } = addContentView.state;
  addContentView.setFullValidation(true);
  const validation = addContentView.validate(true);
  if (!checkFileCapacity(addContentView)) {
    if (!(await modalView.confirm(
      "File Storage Low",
      "The files you have selected exceed your remaining file storage capacity. This upload may fail. Are you sure you want to proceed?",
      "Yes",
      "No",
      ".danger",
    ))) {
      return;
    }
  }
  if (!checkContentFiles(addContentView, steps.ADD, steps.POSTER)) return;
  if (!validation.valid) {
    addContentView.update({
      validation,
      invalidSteps: getInvalidSteps(values, validation),
    });
    log.debug("invalid form:", validation);
    notificationView.post(staticMessages.invalid);
    return;
  }
  if (!selectedLabels.length) {
    if (!await modalView.confirm(
      "You have not selected any search labels. Are you sure you want to continue?",
    )) {
      addContentView.update({
        step: steps.LABEL,
      });
      return;
    }
  }
  if (type === metadataTypes.LIST && !listItems.length) {
    if (!await modalView.confirm(
      "You have not added any items to the playlist. Are you sure you want to continue?",
    )) {
      addContentView.update({
        step: steps.PLAYLIST,
      });
      return;
    }
  }

  const todoPickers = { posterFile: values.posterFile };
  if (type === metadataTypes.VIDEO) todoPickers.videoFile = values.videoFile;
  if (type === metadataTypes.DOCUMENT) todoPickers.documentFile = values.documentFile;

  let files;
  try {
    files = await handleFilePickers(todoPickers, loadingView);
  } catch (err) {
    addContentView.updateState({ invalidSteps: [steps.ADD], step: steps.ADD });
    handleError(err);
    loadingView.hide(); // just in case it crapped out while loading view was open
    return;
  }

  const data = {
    title: values.title,
    description: values.description,
    language: "eng",
    categories: [...selectedLabels],
    startDate: values.startDate ? parseISO(values.startDate) : null,
    endDate: values.endDate ? parseISO(values.endDate) : null,
  };

  if (files.posterFile) data.posterFileId = files.posterFile.id;
  if (files.videoFile) data.videoFileId = files.videoFile.id;
  if (files.documentFile) data.documentFileId = files.documentFile.id;

  // handle playlist-specific stuff
  if (type === metadataTypes.LIST) {
    log.debug("appending items", listItems, listItems.map((i) => i.id));
    data.itemIds = listItems.map((i) => i.id);
  }

  loadingView.show(`Saving ${metadataTypesFriendly.get(type)}...`);
  log.debug("saving item", data);

  try {
    let res;
    switch (type) {
      case metadataTypes.VIDEO:
        res = await api.metadata.createVideo(data);
        // mainly triggers clearing searches so new items will appear
        await cacheStream.clearVideo(res.id);
        break;
      case metadataTypes.DOCUMENT:
        res = await api.metadata.createDocument(data);
        // mainly triggers clearing searches so new items will appear
        await cacheStream.clearDocument(res.id);
        break;
      case metadataTypes.LIST:
        res = await api.metadata.createPlaylist(data);
        // mainly triggers clearing searches so new items will appear
        await cacheStream.clearPlaylist(res.id);
        break;
      default:
        notificationView.post(staticMessages.unknown);
        break;
    }
    addContentView.update({ isFormChanged: false });
    await modalView.customConfirm({
      title: 'Content was successfully added',
      body: '',
      confirmLabel: "Add More",
      cancelLabel: `Go To ${metadataTypesFriendly.get(type)} Profile`,
      onConfirm: () => window.location.assign('/admin/add-content'),
      onCancel: () => window.location.assign(
        `/admin/content-profile?type=${res.type}&id=${res.id}`,
      ),
    });
    loadingView.hide();
    loadingView.show("Redirecting...");
  } catch (err) {
    handleError(err);
    loadingView.hide(); // just in case it crapped out while loading was open
  }
};

/**
 * @const typeTabsList
 * @type array
 * @private
 */
const typeTabsList = [metadataTypes.VIDEO, metadataTypes.DOCUMENT, metadataTypes.LIST];

/**
 * @function continueFromTypeStep
 * @private
 */
const continueFromTypeStep = (self) => () => {
  if (isFileSelected(self.values.posterFile)) {
    self.update({ step: steps.BASIC, messages: [] });
  } else {
    self.update({
      step: steps.POSTER,
      isFormChanged: true,
      messages: [],
    });
  }
};

/**
 * @function continueFromAddStep
 * @private
 */
const continueFromAddStep = (self) => () => {
  const { values } = self;
  const { type } = values;
  if (type === metadataTypes.LIST
    || (type === metadataTypes.VIDEO && isFileSelected(values.videoFile))
    || (type === metadataTypes.DOCUMENT && isFileSelected(values.documentFile))
  ) {
    continueFromTypeStep(self)();
  } else {
    self.update({
      step: steps.ADD,
      isFormChanged: true,
    });
    notificationView.post(staticMessages.noContentFileSelected);
  }
};

/**
 * @function continueFromPosterStep
 * @private
 */
const continueFromPosterStep = (self) => () => {
  if (isFileSelected(self.values.posterFile)) {
    self.update({ step: steps.BASIC, messages: [] });
  } else {
    self.update({
      step: steps.POSTER,
      isFormChanged: true,
    });
    notificationView.post(staticMessages.noPosterFileSelected);
  }
};

/**
 * @function continueFromBasicsStep
 * @private
 */
const continueFromBasicsStep = (self) => () => {
  self.update({ step: steps.SCHEDULE });
};

/**
 * @function continueFromScheduleStep
 * @private
 */
const continueFromScheduleStep = (self) => () => {
  if (self.values.type === metadataTypes.LIST) self.update({ step: steps.PLAYLIST });
  else self.update({ step: steps.LABEL });
};

/**
 * @function continueFromLabelStep
 * @private
 */
const continueFromPlaylistStep = (self) => () => {
  self.update({ step: steps.LABEL });
};

/**
 * @function continueFromLabelStep
 * @private
 */
const continueFromLabelStep = (self) => () => {
  self.update({ step: steps.COMPLETE });
};

/**
 * @function addContentStep
 * @private
 */
const addContentStep = (self, values) => {
  const { step } = self.state;
  const { metadataIds } = getQueryParams();
  const type = values.type || typeTabsList[metadataIds ? 2 : 0];
  return div(
    ".type-section",
    {
      on: {
        "em:tab-change": (e) => {
          if (!e.target.classList.contains("type-tabs")) return;
          const newType = typeTabsList[e.detail.id];
          document.querySelector("[name=type]").value = newType;
          self.update({ step: steps.ADD, type: newType });
          emit(self.node.elm, "em:form-managed-change");
        },
      },
    },
    [
      ...self.bind([[hidden, { name: "type", value: type }]]),
      collapsible(
        {
          title: "Select Type",
          open: step === steps.ADD,
          onChange: onOpenStep(self, steps.ADD),
        },
        [
          tabs(
            typeTabsList.map((t) => metadataTypesFriendly.get(t)),
            self.bind([
              [
                filePicker.newVideo,
                filePickerConfig("videoFile", self, type !== metadataTypes.VIDEO),
              ],
              [
                filePicker.newDocument,
                filePickerConfig("documentFile", self, type !== metadataTypes.DOCUMENT),
              ],
              div(".list-picker", button({
                icon: "arrow-right",
                label: "continue",
                sel: "#continue-from-list-picker",
                onClick: continueFromTypeStep(self),
              })),
            ], {
              videoFile: { mode: filePickerModes.UPLOAD },
              documentFile: { mode: filePickerModes.UPLOAD },
            }),
            typeTabsList.indexOf(type),
            ".type-tabs",
            { key: "type_tabs" },
          ),
          type === metadataTypes.LIST ? "" : button({
            label: "Continue",
            sel: "#continue-from-add-step",
            onClick: continueFromAddStep(self),
            disabled: !(type === metadataTypes.LIST
            || (
              type === metadataTypes.DOCUMENT
              && isFileSelected(values.documentFile)
            )
            || (type === metadataTypes.VIDEO && isFileSelected(values.videoFile))),
          }),
        ],
      ),
      icon("question-square", ".show-help", {
        on: {
          click: () => {
            modalView.show(adminAddContentHelpModal(true));
          },
        },
      }),
    ],
  );
};

/**
 * @function metadataStep
 * @private
 */
const metadataStep = (self, values) => {
  const { step } = self.state;
  const {
    title: titleValue,
    description: descriptionValue,
  } = values;

  return collapsible({
    title: "Edit Metadata",
    open: (step === steps.BASIC) || (step === steps.POSTER),
    disabled: step < steps.POSTER,
  }, [
    collapsible({
      title: "Poster File",
      open: step === steps.POSTER,
      disabled: step < steps.POSTER,
      onChange: onOpenStep(self, steps.POSTER),
    }, self.bind([
      [filePicker.newImage, filePickerConfig("posterFile", self)],
      (isFileSelected(values.posterFile))
        ? button({
          label: "Continue",
          sel: "#continue-from-poster-step",
          onClick: continueFromPosterStep(self),
        })
        : "",
    ], { posterFile: { mode: filePickerModes.UPLOAD } })),
    collapsible({
      title: "Basic Information",
      open: step === steps.BASIC,
      disabled: step < steps.POSTER,
      onChange: onOpenStep(self, steps.BASIC),
    }, self.bind([
      [title, { value: titleValue, required: true, disabled: step < steps.POSTER }],
      [description, { value: descriptionValue, disabled: step < steps.POSTER }],
      button.primary({
        label: "Continue",
        sel: "#continue-from-basics-step",
        onClick: continueFromBasicsStep(self),
      }),
    ])),
  ]);
};

/**
 * @function scheduleStep
 * @private
 */
const scheduleStep = (self, values) => {
  const { step } = self.state;
  const { startDate, endDate } = values;
  return collapsible({
    title: "Schedule",
    open: (step === steps.SCHEDULE),
    disabled: step < steps.SCHEDULE,
    onChange: onOpenStep(self, steps.SCHEDULE),
  }, [
    contentSchedule({
      startDate,
      endDate,
      onEnable: () => self.update({
        schedule: {
          startDate: startOfDay(new Date()),
          endDate: startOfDay(addWeeks(new Date(), 1)),
          step: steps.SCHEDULE,
        },
      }),
      onDisable: () => self.update({
        schedule: {
          startDate: null,
          endDate: null,
          step: steps.SCHEDULE,
        },
      }),
    }),
    button.primary({
      label: "Continue",
      sel: "#continue-from-schedule-step",
      onClick: continueFromScheduleStep(self),
    }),
  ]);
};

/**
 * @function playlistStep
 * @private
 */
const playlistStep = (self, values) => {
  const { step, metadata, listItems } = self.state;
  if (values.type === metadataTypes.LIST) {
    return collapsible({
      title: "Add Playlist Items",
      open: step === steps.PLAYLIST,
      disabled: step < steps.PLAYLIST,
      onChange: onOpenStep(self, steps.PLAYLIST),
    }, [
      metaList(
        self.state.listItems,
        (item) => self.update({
          step: steps.PLAYLIST,
          listItems: self.state.listItems.filter((entry) => entry.id !== item.id),
        }),
      ),
      button.subtle({
        icon: "plus-circle",
        label: "Add Item",
        sel: ".back",
        onClick: () => modalView.show(metadataSelect(
          {
            entries: difference(
              metadata.filter((item) => item.type !== metadataTypes.LIST),
              listItems,
            ),
            onSelect: (entry) => self.update({
              step: steps.PLAYLIST,
              listItems: self.state.listItems.concat([entry]),
            }),
            search: "",
          },
          modalView,
        )),
      }),
      button.primary({
        label: "Continue",
        sel: "#continue-from-playlist-step",
        onClick: continueFromPlaylistStep(self),
      }),
    ]);
  }
  return "";
};

/**
 * @function labelsStep
 * @private
 */
const labelsStep = (self) => {
  const { step, selectedLabels } = self.state;
  return collapsible({
    title: "Add Search Labels",
    open: step === steps.LABEL,
    disabled: step < steps.LABEL,
    onChange: onOpenStep(self, steps.LABEL),
  }, [
    labelCollection(selectedLabels, onRemoveLabel(steps, self, modalView)),
    button.subtle({
      icon: "plus-circle",
      label: "Add Label",
      sel: ".add-labels",
      onClick: onSelectLabel(steps, self, modalView),
    }),
    button.primary({
      label: "Continue",
      sel: "#continue-from-label-step",
      onClick: continueFromLabelStep(self),
    }),
  ]);
};

/**
 * Main factory for the add page form view.
 *
 * @function showForm
 * @private
 */
const showForm = (self) => {
  // each time we access values the accessor has to rebuild the values object
  // so store it here and pass it into the functions separate from self itself
  const values = merge({}, self.values);
  const { schedule } = self.state;
  // bootstrap time values back into dates
  if (schedule.startDate === null) {
    values.startDate = null;
  } else if (typeof values.startDate === "string") {
    values.startDate = parseISO(values.startDate);
  } else {
    values.startDate = schedule.startDate;
  }
  if (schedule.endDate === null) {
    values.endDate = null;
  } else if (typeof values.endDate === "string") {
    values.endDate = parseISO(values.endDate);
  } else {
    values.endDate = schedule.endDate;
  }
  const reviewData = {
    title: values.title,
    description: values.description,
    language: "eng",
    categories: [...self.state.selectedLabels],
    startDate: values.startDate,
    endDate: values.endDate,
    opened: self.state.step === steps.COMPLETE,
    disabled: self.state.step < steps.COMPLETE,
  };

  return form(
    "#add-content",
    [
      addContentStep(self, values),
      metadataStep(self, values),
      scheduleStep(self, values),
      playlistStep(self, values),
      labelsStep(self),
      reviewCollapsible(reviewData),
    ],
  );
};

const showActionButtons = (theView) => div("#actions", [
  button.primary({
    icon: "save",
    label: "Save",
    onClick: (e) => {
      if (theView.state.step === steps.COMPLETE) {
        onSave(e);
      }
    },
    disabled: theView.state.step !== steps.COMPLETE,
    sel: "#save-content",
  }),
]);

/**
 * Callback function for the onUpdate hook in the add content form view.
 *
 * @function updateForm
 * @private
 */
const updateForm = (self) => {
  if (self.state.invalidSteps.length) {
    self.updateState({
      step: self.state.invalidSteps[0],
      invalidSteps: self.state.invalidSteps.slice(1),
    });
  }

  self.render();
  actionButtonsView.update({ step: self.state.step });
};

/**
 * The add content page initializer.
 *
 * @function addContent
 * @param {module:ui/html~Selector} Selector the root element of the form view
 */
export default async function addContent(selector) {
  const { metadataIds } = getQueryParams();
  const pageTitle = "Add Content";

  const views = dashboardLayout(
    selector,
    [
      actionBar(div("#actions.placeholder", "Actions")),
      widget([
        form("#add-content"),
      ], "Profile", ".profile"),
    ],
    pageTitle,
    true,
  );
  const { header, notification } = views;
  loadingView = views.loading;
  modalView = views.modal;
  notificationView = notification;

  loadingView.show();

  const user = cache.getProfile();
  let state;
  try {
    const [
      files,
      descriptors,
      metadata,
      labels,
      config,
    ] = await Promise.all([
      api.media.files(),
      api.file.list(),
      api.metadata.list(),
      api.metadata.listCategories(),
      getConfig(),
    ]);

    state = merge(
      defaultState,
      {
        listItems: metadataIds
          ?.split(",")
          ?.map((id) => metadata.find((item) => item.id === id)) || [],
        files,
        descriptors,
        user,
        metadata,
        labels,
        title: pageTitle,
        config,
      },
    );
    header.update(state);
  } catch (e) {
    log.error(e);
    await modalView.alert("Unable to retrieve data necessary to create a content item at this time. Please try again in a few moments.");
    window.location.replace("/admin/manage-content");
  }

  actionButtonsView = view.create(showActionButtons)("#actions", state);
  addContentView = formManagedView(showForm, updateForm)("#add-content", state);

  loadingView.hide();
}
