/**
 * Helper functions for the chat page.
 *
 * @module ui/page/chat/common
 * @private
 * @category Pages
 * @subcategory User Facing
 */
/***/
import log from "log";
import api from "api";
import { normalizeFileName } from "util/file";
import { uuidv4 } from "util/generator";
import { notificationMessageTypes } from "model/notification/constants";
import { inboxTypes, sortOrders } from "model/message/constants";
import reportMessageModal from "ui/component/chat/report-message";
import { sortDate } from "util/sort";
import { chatMode, chatTypes } from "ui/component/chat/common";
import { MAX_DATE, MIN_DATE } from "util/date";
import hashmap from "util/hash-map";
import { messageStatus, messageTypes as types } from "@smartedge/em-message/constants";
import cache from "cache";
import { markMessagesRead } from "api/message";

/**
 * A function that return default search params. Not an object because we need the latest endDate
 * all the time we call it
 *
 * @returns {SearchMessage}
 */
export const defaultSearchParams = () => ({
  startDate: undefined,
  endDate: new Date(),
  sortOrder: sortOrders.DESC,
  messageCount: 20,
  searchString: undefined,
  text: undefined,
  params: {
    hasAttachments: undefined,
    userId: undefined,
    attachmentName: undefined,
    messageStatus: undefined,
    reportType: undefined,
  },
});

/**
 * Fetch a descriptors for the given file ids. Sets a descriptor map to the state
 *
 * @param {object} self
 * @param {string[]} ids
 * @returns {Promise<void>}
 */
export const fetchDescriptors = async (self, ids) => {
  const filteredIds = ids.filter((id) => id);
  const descriptors = await Promise.all(filteredIds.map((id) => api.file.getById(id)));
  const descriptorsMap = new Map(self.state.descriptors);
  filteredIds.forEach((id) => descriptorsMap.set(id, descriptors.find((d) => d.id === id)));
  self.update({ descriptors: descriptorsMap });
};

/**
 * Upload a file to the server
 *
 * @param {File} file
 * @returns {Promise<boolean>}
 */
export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("file", file, normalizeFileName(file.name));
  return api.file.uploadFile(file.name, { data: formData });
};

/**
 * Process & upload file attached to a message
 *
 * @param {object} self
 * @param {File} file
 * @param {NotificationView} notificationView
 * @returns {Promise<void>}
 */
export const processFileAttachment = async (self, file, notificationView) => {
  const fileMetadata = {
    name: file.name,
    size: file.size,
    type: file.type,
    uuid: uuidv4(),
    fileAttachInProgress: true,
    failed: false,
  };
  self.update({
    attachedFiles: [
      ...self.state.attachedFiles,
      fileMetadata,
    ],
  });
  let descriptor;
  try {
    descriptor = await uploadFile(file);
    self.update({
      attachedFilesDescriptors: [
        ...self.state.attachedFilesDescriptors,
        {
          ...descriptor,
          fileMetadata: {
            ...fileMetadata,
            fileAttachInProgress: false,
          },
        },
      ],
      attachedFiles: self.state.attachedFiles
        .map((f) => {
          if (f.uuid === fileMetadata.uuid) {
            return {
              ...f,
              fileAttachInProgress: false,
            };
          }
          return f;
        }),
    });
  } catch (e) {
    notificationView.post({
      title: "Error",
      text: "Failed to upload file.",
      type: notificationMessageTypes.FAIL,
      duration: 5,
    });
    self.update({
      attachedFiles: self.state.attachedFiles
        .map((f) => {
          if (f.uuid === fileMetadata.uuid) {
            return {
              ...f,
              fileAttachInProgress: false,
              failed: true,
            };
          }
          return f;
        }),
    });
  }
};

/**
 * Add a received message to a channel messages map
 *
 * @param {object} self
 * @param {string} channelId
 * @param {Message} message
 * @param {boolean} skipDescriptors
 */
const addMessageToChannel = async (self, channelId, message, skipDescriptors = false) => {
  if (message.type !== types.CHAT_TEXT) return;
  if (message?.attachments && !skipDescriptors) {
    await fetchDescriptors(self, message.attachments);
  }
  const channelMessages = new Map(self.state.channelMessages);
  if (channelMessages.get(channelId)?.some((m) => m.id === message.id)) {
    return;
  }
  const messages = channelMessages.get(channelId) || [];
  channelMessages.set(
    channelId,
    [...messages, message].sort((a, b) => sortDate(new Date(b.created), new Date(a.created))),
  );
  self.update({ channelMessages });
};

/**
 * Mark messages as READ. Map all to READ without waiting of a response
 *
 * @param {object[]} messages
 * @returns {object[]}
 */
export const updateMessagesWithReadStatus = (messages, me) => {
  if (!messages) {
    return [];
  }
  const toMark = messages
    .filter((m) => m.to === me.id && m.status === messageStatus.UNREAD)
    .map((m) => m.id);
  if (toMark.length) markMessagesRead(toMark);
  return messages.map((m) => ({ ...m, status: messageStatus.READ }));
};

/**
 * Add message to all messages Map
 *
 * @param {object} self
 * @param {object} message
 */
const addMessageToAllMessages = (self, message) => {
  const { latestDirectMessages, me } = self.state;
  if (message.to !== me.id) return;
  const hasChannel = latestDirectMessages.has(message.from);
  if (!hasChannel) {
    latestDirectMessages.set(message.from, [message]);
  } else {
    latestDirectMessages.set(message.from, [
      message,
      ...latestDirectMessages.get(message.from),
    ]);
  }
  self.update({
    latestDirectMessages,
  });
};

/**
 * Process received message list from BE or MBE response.
 *
 * @param {object} self
 * @param {Message[]} messages
 * @param {?boolean} skipDescriptors
 */
export const processReturnedMessages = (self, messages = [], skipDescriptors = false) => {
  const { me, knownUsers } = self.state;
  let tempUsers;
  messages.forEach((message) => {
    const fromChannel = self.state.channels.find((c) => c.id === message.from);
    if (fromChannel?.conversation?.blocked) return;
    const fromUser = fromChannel?.members.find((u) => u.id === message.from);
    if (fromUser?.id && !knownUsers.has(message.from) && !tempUsers?.has(message.from)) {
      if (!tempUsers) tempUsers = hashmap(knownUsers);
      tempUsers.set(fromUser.id, fromUser);
    }
    if (
      self.state.channels
        .filter((c) => c.type === inboxTypes.GROUP)
        .find((c) => c.id === message.to)
    ) {
      // a group chat
      addMessageToChannel(self, message.to, message, skipDescriptors)
        .catch((e) => log.error(e, "could not add message to channel"));
    } else if (message.from === me.id) {
      addMessageToChannel(self, message.to, message, skipDescriptors)
        .catch((e) => log.error(e, "could not add message to channel"));
    } else if (message.to === me.id) {
      // DM from someone else to me
      addMessageToChannel(self, message.from, message, skipDescriptors)
        .catch((e) => log.error(e, "could not add message to channel"));
      addMessageToAllMessages(self, message);
    }
  });
  if (tempUsers) self.update({ knownUsers: tempUsers });
  if (self.state.selectedChannel) {
    const updatedMessages = updateMessagesWithReadStatus(
      messages,
      me,
    );
    const currentChannelMessages = self.state.channelMessages.get(self.state.selectedChannel.id);
    const toUpdate = currentChannelMessages.map((m) => {
      const updatedMessage = updatedMessages?.find((updated) => updated.id === m.id);
      return updatedMessage || m;
    });
    const { channelMessages } = self.state;
    channelMessages.set(self.state.selectedChannel.id, toUpdate);
    self.update({
      channelMessages,
    });
  }
};

/**
 * Search messages by search params. Automatically checks whether all messages,
 * direct messages or group messages is opened
 *
 * @param {object} self
 * @param {SearchMessage} searchParams
 * @returns {Promise<object[]>}
 */
export const searchFn = async (self, searchParams) => {
  const { state } = self;
  const { selectedChannel } = state;
  if (selectedChannel.type === inboxTypes.DM) {
    const messages = await api.message
      .searchMessagesWithUser(self.state.me.id, selectedChannel.id, searchParams);
    processReturnedMessages(self, messages);
    return messages;
  }
  if (selectedChannel.type === inboxTypes.GROUP) {
    const messages = await api.message.searchGroupMessages(selectedChannel.id, searchParams);
    processReturnedMessages(self, messages);
    return messages;
  }
  return [];
};

/**
 * Performs basic search by a message text
 *
 * @param {object} self
 * @param {SearchMessage} searchParams
 * @returns {Promise<void>}
 */
export const onSearch = async (self, searchParams) => {
  const { state } = self;
  const { selectedChannel, channelMessages } = state;
  if (!selectedChannel) {
    return;
  }
  const updatedChannelMessages = channelMessages.set(selectedChannel.id, []);
  self.update({
    channelMessages: updatedChannelMessages,
    messagesLoading: true,
  });
  await searchFn(self, searchParams);
  self.update({
    messagesLoading: false,
  });
};

/**
 * Performs search for the latest messages
 *
 * @param {object} self
 * @param {SearchMessage} searchParams
 * @returns {Promise<void>}
 */
export const searchLatestMessages = async (self, searchParams) => {
  const { state } = self;
  const { me } = state;
  self.update({ messagesLoading: true });
  const messages = await api.message.getLatestDirectMessages(me.id, searchParams);
  const descriptorIds = Array.from(messages.values())
    .map((m) => m?.attachments)
    .flat();
  await fetchDescriptors(self, descriptorIds);
  self.update({ latestDirectMessages: messages, messagesLoading: false });
};

/**
 * Performs basic search by a message text for all latest messages
 *
 * @param {object} self
 * @param {SearchMessage} params
 * @returns {Promise<void>}
 */
export const onSearchAllLatestMessages = async (self, params) => {
  const { state } = self;
  const { selectedChatType } = state;
  if (selectedChatType !== chatTypes.ALL_MESSAGES) {
    return;
  }
  const searchParams = {
    ...params,
    startDate: new Date(MIN_DATE),
    endDate: new Date(MAX_DATE),
    messageCount: undefined,
    sortOrder: sortOrders.DESC,
    params: {
      type: types.CHAT_TEXT,
      ...params.params,
    },
  };
  await searchLatestMessages(self, searchParams);
};

/**
 * Report a message
 *
 * @param {Message} message
 * @param {NotificationView} notificationView
 * @param {ModalView} modalView
 * @returns {Promise<void>}
 */
export const reportMessage = async (message, notificationView, modalView) => {
  const result = await modalView.async(reportMessageModal({
    message,
  }, modalView, notificationView));
  if (result) {
    await api.message.reportMessage(result);
    notificationView.post({
      title: "Success",
      text: "Report is sent!",
      type: notificationMessageTypes.SUCCESS,
    });
  }
};

export const blockUser = async (message, page, notification, modal) => {
  const blockedUser = page.state.knownUsers.get(message.from);
  if (blockedUser.isModerator) {
    await modal.alert("You cannot block moderators.");
    return;
  }
  const ok = await modal.confirm("You will no longer be able to exchange direct messages with this user, or see the user's messages in public channels. Are you sure you want to do this?");
  if (ok) {
    await api.message.sendBlockUser(message.from);
    notification.post({
      title: "User Blocked",
      text: "User was successfully blocked.",
      type: notificationMessageTypes.SUCCESS,
    });
    page.update({
      blockedUserIds: new Set([...page.state.blockedUserIds, message.from]),
    });
    if (page.state.selectedChannel?.id === message.from) {
      /* eslint-disable-next-line no-use-before-define */
      await selectChatType(page, chatTypes.ALL_MESSAGES);
    }
  }
};

export const unblockUser = async (id, page, notification, modal) => {
  const ok = await modal.confirm("Are you sure you want to unblock this user?");
  if (ok) {
    await api.message.sendUnblockUser(id);
    notification.post({
      title: "User Unblocked",
      text: "User was unblocked.",
      type: notificationMessageTypes.SUCCESS,
    });
    page.update({
      blockedUserIds: new Set([...page.state.blockedUserIds].filter((blocked) => blocked !== id)),
    });
  }
  return ok;
};

/**
 * Load more messages for the selected channel
 *
 * @param {object} self
 * @returns {Promise<void>}
 */
export const loadMoreMessages = async (self) => {
  const { state } = self;
  const { channelMessages, selectedChannel, messagesLoading } = state;
  if (messagesLoading) {
    return;
  }
  const messages = channelMessages.get(selectedChannel.id);
  if (!messages) {
    return;
  }
  const lastMessage = messages[messages.length - 1];
  // eslint-disable-next-line consistent-return
  return searchFn(self, {
    ...state.searchParams,
    endDate: lastMessage?.created,
  });
};

/**
 * Remove attached file when it is not sent yet
 *
 * @param {Object} self
 * @param {File} file
 */
export const removeAttachedFile = (self, file) => {
  const { state } = self;
  self.update({
    attachedFiles: state.attachedFiles.filter((f) => f !== file),
    attachedFilesDescriptors: state.attachedFilesDescriptors
      .filter((f) => f?.fileMetadata?.uuid !== file?.uuid),
  });
};

/**
 * Update chat mode.
 * MESSAGE_SELECTION is for message selection mode (supported in All Messages)
 * In DEFAULT mode all the selected messages are cleared
 *
 * @param {Object} self
 * @param {chatMode} mode
 */
export const updateChatMode = (self, mode) => {
  if (mode === chatMode.DEFAULT) {
    self.updateState({ selectedMessages: [] });
  }
  self.update({ chatMode: mode });
};

/**
 * Select chat type. If chat type is ALL_MESSAGES then fetches the latest direct messages
 * and updates the state
 *
 * @param {Object} self
 * @param {chatTypes} chatType
 * @returns {Promise<void>}
 */
export const selectChatType = async (self, chatType) => {
  self.update({ selectedChatType: chatType });
  if (chatType === chatTypes.ALL_MESSAGES) {
    self.update({ messagesLoading: true });
    const retrieved = await api.message.getLatestDirectMessages(self.state.me.id, {
      startDate: new Date(MIN_DATE),
      endDate: new Date(MAX_DATE),
      sortOrder: sortOrders.DESC,
      params: {
        type: types.CHAT_TEXT,
      },
    });
    const latestDirectMessages = new Map([...retrieved.entries()].filter(
      ([uId]) => !self.state.blockedUserIds.has(uId),
    ));
    const descriptorIds = Array.from(latestDirectMessages.values())
      .flat()
      .map((m) => m?.attachments)
      .flat();
    await fetchDescriptors(self, descriptorIds);
    self.update({ selectedChannel: null, latestDirectMessages, messagesLoading: false });
  }
};

/**
 * Perform initial search messages when the channel is selected
 *
 * @param {Object} self
 * @param {Object} channel
 * @returns {Promise<void>}
 */
export const selectChannel = async (self, channel) => {
  const channelMessages = new Map(self.state.channelMessages);
  if (channelMessages.has(channel.id)) {
    self.update({
      selectedChannel: channel,
      messagesLoading: false,
    });
  } else {
    channelMessages.set(channel.id, []);
    self.update({ selectedChannel: channel, channelMessages, messagesLoading: true });
  }
  await searchFn(self, defaultSearchParams());
  self.update({ messagesLoading: false });
};

/**
 * Select message callback. Adds selected message to the state
 *
 * @param {Object} self
 * @param {Message} message
 */
export const selectMessage = (self, message) => {
  self.update({ selectedMessages: [message] });
};

/**
 * Send a message from current user
 *
 * @param {Object} self
 * @param {string} text
 * @param {Object[]} attachedFiles
 * @returns {Promise<void>}
 */
export const sendMessage = async (self, text, attachedFiles = []) => {
  if (!text && !attachedFiles?.filter((f) => !f.failed).length) {
    return;
  }
  await api.message.sendMessageFromMe(
    self.state.selectedChannel?.id,
    text,
    self.state.attachedFilesDescriptors.map((descriptor) => descriptor.id),
    self.state.attachedFilesDescriptors.map((descriptor) => descriptor.name),
  );
  self.update({
    attachedFiles: [],
    attachedFilesDescriptors: [],
  });
};

/**
 * Clear search params
 *
 * @param {object} self
 */
export const clearSearchParams = (self) => {
  self.update({
    searchParams: defaultSearchParams(),
  });
};

/**
 * Checks whether the channel has unread messages
 *
 * @param {object[]} messages
 * @returns {boolean}
 */
export const hasUnreadMessagesInChannel = (messages = []) => {
  const me = cache.getProfile();
  const isUnreadInGroup = (m) => m.from !== me.id
    && m.to !== me.id
    && m.status === messageStatus.UNREAD;
  const isUnreadInDM = (m) => m.to === me.id && m.status === messageStatus.UNREAD;
  return messages?.some((m) => isUnreadInDM(m) || isUnreadInGroup(m));
};

/**
 * Tries to search for a message in a thread. If found it scrolls to it.
 * If not then tries to load more messages in this thread and does the same
 * action until it finds it.
 *
 * @param {object} self
 * @param {object} message
 * @param {number} duration highlight message duration
 * @returns {Promise<void>}
 */
export const highlightMessage = async (self, message, duration = 3000) => {
  const selector = `[data-message-id="${message.id}"]`;
  try {
    const element = document.querySelector(selector);
    element.classList.toggle("highlighted");
    element.scrollIntoView();
    setTimeout(() => element.classList.toggle("highlighted"), duration);
  } catch (e) {
    const loadedMessages = await loadMoreMessages(self);
    if (loadedMessages?.length <= 1 || !loadedMessages) return;
    await highlightMessage(self, message, duration);
  }
};

export const makeDMChannelDescriptorFactory = (me) => (u) => ({
  name: u.username,
  id: u.id,
  type: inboxTypes.DM,
  status: u.status,
  firstName: u.firstName,
  lastName: u.lastName,
  members: [
    {
      name: me.username,
      id: me.id,
      type: inboxTypes.DM,
      firstName: me.firstName,
      lastName: me.lastName,
      userType: me.userType,
      isModerator: me.isModerator,
    },
    {
      name: u.username,
      id: u.id,
      type: inboxTypes.DM,
      firstName: u.firstName,
      lastName: u.lastName,
      userType: u.userType,
      isModerator: u.isModerator,
    },
  ],
});
