/**
 * A form field for selecting from one of a list of options, with compact presentation.
 *
 * Conceptually replaces a select dropdown.
 * @module ui/component/form-managed/field-select
 * @category UI
 * @subcategory Forms
 */
import deepeq from "deep-equal";
import listSelectCameo from "ui/component/cameo-list-select-item";
import genericField from "ui/component/form-managed/field-generic";
import listSelectModal from "ui/component/modal/list-select";
import { frozen, merge } from "util/object";
import { managedField } from "ui/view/form-managed/util";
import { getModal } from "ui/view/modal-view";
import { emit } from "util/event";

const helpTexts = frozen({
  required: "Please make a selection.",
});

/**
 * @private
 */
const defaultState = frozen({
  disabled: false,
  helpTexts,
  label: "Select",
  name: "select",
  onChange: null,
  options: [],
  placeholder: null,
  required: false,
  sel: "",
  value: null,
});

const requiredHook = (required, getValue) => (_node, validity) => {
  if (!required) return;
  if (!getValue()) {
    /* eslint-disable no-param-reassign */
    validity.valueMissing = true;
    validity.valid = false;
    /* eslint-enable no-param-reassign */
  }
};

/**
 *
 * A form field for selecting from one of a list of options, with compact presentation.
 *
 * Conceptually replaces a select dropdown.
 *
 * When clicked, options are displayed in a list-select modal dialog.
 *
 * @function fieldSelect
 * @param {string} label
 * @param {string} placeholder shown when no option is selected
 * @param {object[]} options at least one option must be provided
 * @param {string} options[].label label for the option
 * @param {mixed} options[].value value the option represents
 * @param {boolean} disabled
 *
 */
export default function fieldSelect(inState) {
  const state = merge(defaultState, inState);
  const value = state.values?.[state.name] || state.value || "";
  let selectedValue = value;
  const lastValue = selectedValue;
  if (!state?.options?.length) throw new Error("fieldSelect: must supply at least one option!");
  const options = state.options.map((opt) => {
    if (!opt.value) throw new Error("fieldSelect: each option must have a unique value");
    return {
      ...opt,
      label: opt.label || opt.value,
    };
  });
  const label = options
    .find((opt) => deepeq(opt.value, selectedValue))?.label || state.placeholder || state.label;

  let valid = true;
  if (
    state?.validity?.fields?.[state.name]
    && !state.validity.fields[state.name].valid
  ) {
    valid = false;
  }

  let sel = (valid) ? "" : ".invalid";

  if (!value) sel += ".empty";

  const inner = listSelectCameo({
    label: `${label}${state.required && !value ? "*" : ""}`,
    faded: state.disabled,
    onSelect: async () => {
      const selected = await getModal().async(listSelectModal({
        entries: options,
      }));
      if (selected) {
        selectedValue = selected.value;
        if (selectedValue !== lastValue) emit(inner.elm, "em:form-managed-change");
        if (state.onChange) state.onChange(selectedValue);
      }
    },
  });

  return genericField(
    state,
    managedField(
      inner,
      state.name,
      () => selectedValue,
      [requiredHook(state.required, () => selectedValue)],
    ),
    `${state.sel}.${sel}.field-select`,
  );
}
