/**
 * The UI customization page.
 *
 * @module ui/page/admin/ui-customization
 * @category Pages
 * @subcategory Admin
 */
import api from "api";
import log from "log";
import getConfig from "config";
import {
  CURRENT_BRANDING_VERSION,
  CURRENT_COLOR_PALETTE_VERSION,
  paletteModes,
} from "model/site/constants";
import {
  button, div, em, h1, h2, h3, input, p, span, strong, u,
} from "ui/html";
import actionBar from "ui/component/dashboard-action-bar";
import collapsible from "ui/component/collapsible";
import toggle from "ui/component/form-managed/field-toggle";
import widget from "ui/component/dashboard-widget";
import dynamicTable from "ui/component/dynamic-table";
import icon from "ui/component/icon";
import multiToggle from "ui/component/multi-toggle";
import placeholder from "ui/component/placeholder";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import view from "ui/view";
import { applyBranding, applyColorPalette, updateColorPalette } from "util/ui-customization";
import { tidyBackendError } from "util/error";
import { frozen } from "util/object";
import filePicker, { filePickerModes } from "ui/component/form-managed/file-picker";
import { saveBranding } from "api/site";
import dashboardLayout from "ui/page/layout/dashboard";
import colorPicker from "ui/component/form-managed/field-color-picker";
import { notificationMessageTypes } from "model/notification/constants";
import { filePickerConfig, handleFilePickers } from "./content/common";

let loadingView;
let uiCustomizationView;
let actionsView;
let brandingView;
let headerView;
let notificationView;

const staticMessages = frozen({
  invalid: {
    title: "Invalid Form",
    text: "Please correct the errors.",
    type: notificationMessageTypes.FAIL,
    duration: 5,
  },
  backendErrors: {
    title: "Error",
    text: "A server error occurred. Please report this to the operators.",
    type: notificationMessageTypes.FAIL,
    duration: 5,
  },
  success: {
    title: "Success",
    text: "Saved!",
    type: notificationMessageTypes.SUCCESS,
  },
  accessDenied: {
    title: "Access Denied",
    text: "You do not have permission to customize app.",
    type: notificationMessageTypes.FAIL,
    duration: 5,
  },
});

const { LIGHT, DARK } = paletteModes;

const MODES = frozen([LIGHT, DARK]);

const defaultColors = [
  /* eslint-disable object-curly-newline */
  { mode: LIGHT, color: "#12348e", label: "Primary", key: "fg-primary" },
  { mode: LIGHT, color: "#ed7450", label: "Accent", key: "fg-accent" },
  { mode: LIGHT, color: "#981826", label: "Warning", key: "fg-danger" },
  { mode: LIGHT, color: "#1e9d79", label: "Secondary", key: "fg-secondary" },
  { mode: LIGHT, color: "#752e7c", label: "Third", key: "fg-alternate" },
  { mode: LIGHT, color: "#000000", label: "Body Text", key: "fg-body" },
  { mode: LIGHT, color: "#ffffff", label: "Button Text", key: "fg-inv" },
  { mode: LIGHT, color: "#717dab", label: "Secondary Background", key: "fg-soft" },
  { mode: DARK, color: "#4a75e4", label: "Primary", key: "dark-fg-primary" },
  { mode: DARK, color: "#d08549", label: "Accent", key: "dark-fg-accent" },
  { mode: DARK, color: "#cb4855", label: "Warning", key: "dark-fg-danger" },
  { mode: DARK, color: "#17b688", label: "Secondary", key: "dark-fg-secondary" },
  { mode: DARK, color: "#9c4ca4", label: "Third", key: "dark-fg-alternate" },
  { mode: DARK, color: "#ffffff", label: "Body Text", key: "dark-fg-body" },
  { mode: DARK, color: "#ffffff", label: "Button Text", key: "dark-fg-inv" },
  { mode: DARK, color: "#717dab", label: "Secondary Background", key: "dark-fg-soft" },
  /* eslint-enable object-curly-newline */
];

const revertPalette = () => {
  uiCustomizationView.update({
    colors: [...uiCustomizationView.state.origColors],
    changed: false,
  });
};

const defaultPalette = () => {
  uiCustomizationView.update({ colors: defaultColors, changed: true });
};

const makePaletteObject = (colors) => {
  const palette = {};
  colors.forEach(({ key, color }) => { palette[key] = color; });
  return palette;
};

const remapColors = (page) => {
  const { state, values } = page;
  return state.colors.map((color) => ({
    ...color,
    color: values[color.key] || color.color,
  }));
};

const buildFields = (self) => {
  const { state } = self;
  const curColors = state.colors.filter(({ mode }) => mode === state.mode);
  const curDefaultColors = defaultColors.filter(({ mode }) => mode === state.mode);
  const defaultColorList = [...new Set([
    ...curColors.map(({ color }) => color),
    ...curDefaultColors.map(({ color }) => color),
  ])];
  return [
    multiToggle({
      label: "Palette Mode",
      options: [
        { label: "Light", name: "palette-mode", value: LIGHT },
        { label: "Dark", name: "palette-mode", value: DARK },
      ],
      selectedTab: MODES.indexOf(state.mode),
      onSelect: (choice) => self.update({ mode: choice.value, changed: true }),
    }),
    div(".colors", self.bind([
      ...curColors.map((color) => [
        colorPicker,
        {
          value: color.color,
          name: color.key,
          label: color.label,
          required: true,
          onChange: () => {
            self.render();
            updateColorPalette({ [color.key]: color.color });
            self.update({
              changed: true,
              colors: remapColors(self),
            });
          },
          predefinedColors: defaultColorList,
        },
      ]),
    ])),
  ];
};

const doSave = async () => {
  uiCustomizationView.setFullValidation(true);
  const validation = uiCustomizationView.validate(true);
  if (!validation.valid) {
    notificationView.post(staticMessages.invalid);
    return;
  }
  try {
    await api.site.saveColorPalette({
      colors: makePaletteObject(uiCustomizationView.state.colors),
      mode: uiCustomizationView.state.mode,
      version: CURRENT_COLOR_PALETTE_VERSION,
    });
    await applyColorPalette();
    const brandingFiles = await handleFilePickers(uiCustomizationView.state.pickers, loadingView);
    await saveBranding({
      version: CURRENT_BRANDING_VERSION,
      images: {
        logoFile: {
          id: brandingFiles?.logoFile?.id || null,
          url: brandingFiles?.logoFile?.fileUrlStatic || null,
        },
        iconsFile: {
          id: brandingFiles?.iconsFile?.id || null,
          url: brandingFiles?.iconsFile?.fileUrlStatic || null,
        },
      },
    });
    await applyBranding();
    headerView.update({});
    loadingView.hide();
    uiCustomizationView.update({
      origColors: [...uiCustomizationView.state.colors],
      changed: false,
    });
    notificationView.post(staticMessages.success);
  } catch (err) {
    log.error(err);
    if (err.statusCode) {
      switch (err.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDenied);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
    } else {
      notificationView.post(staticMessages.backendErrors);
    }
  }
  uiCustomizationView.updateState({ messages: [] });
};

const showUICustomizationForm = (page) => {
  const fields = buildFields(page);

  return form(
    "#ui-customization-form",
    fields,
  );
};

/**
 * Handles a change callback from the logo file picker.
 * @function onLogoChange
 * @private
 */
const onLogoChange = (choice) => {
  uiCustomizationView.update({
    pickers: {
      logoFile: choice,
    },
    changed: true,
  });
};

/**
 * Handles a change callback from the icons file picker.
 * @function onIconsChange
 * @private
 */
const onIconsChange = (choice) => {
  uiCustomizationView.update({
    pickers: {
      iconsFile: choice,
    },
    changed: true,
  });
};

const showBrandingView = () => div(".branding", [
  h3("Select Logo"),
  filePicker.imageAndSvg(
    filePickerConfig("logoFile", uiCustomizationView, false, onLogoChange),
  ),
  h3("Select Icon"),
  filePicker.imageAndSvg(
    filePickerConfig("iconsFile", uiCustomizationView, false, onIconsChange),
  ),
]);

const showActionButtons = ({ state }) => div("#actions", [
  button(
    [icon("sync-alt"), "defaults"],
    () => defaultPalette(),
    ".danger",
    "button",
    "Reset Colors to Defaults",
    false,
  ),
  button(
    [icon("undo"), "revert"],
    () => revertPalette(),
    ".secondary",
    "button",
    "Revert Current Changes",
    !state.changed,
  ),
  button(
    [icon("save"), "save"],
    () => doSave(),
    ".primary",
    "button",
    "Save Changes",
    !state.changed,
  ),
]);

const doUpdate = (self) => {
  const { state } = self;
  if (state.mode === LIGHT) document.body.classList.remove("dark-mode");
  if (state.mode === DARK) document.body.classList.add("dark-mode");
  updateColorPalette(makePaletteObject(state.colors));
  self.render();
  actionsView.update(self.state);
  brandingView?.update(self.state);
};

const elementPreview = () => div("", [
  span([
    button("primary", () => {}, ".primary"),
    button("secondary", () => {}, ".secondary"),
  ], ".buttons"),
  span([
    button("third", () => {}, ".alternate"),
    button("warning", () => {}, ".danger"),
    button("accent", () => {}, ".warn"),
  ], ".buttons"),
  span([
    button("inverse", () => {}, ".inverse"),
    button("stand-in", () => {}, ".stand-in"),
  ], ".buttons"),
  input.text("", false, "textinput", { props: { placeholder: "Text Input" } }),
  toggle(),
  multiToggle({
    label: "Tabs",
    options: [
      { label: "Choice A", name: "choice", value: "a" },
      { label: "Choice B", name: "choice", value: "b" },
      { label: "Choice C", name: "choice", value: "c" },
    ],
  }),
]);

const textPreview = () => div("", [
  collapsible(
    { title: "Headings", open: false },
    [
      h1("Heading 1"),
      h2("Heading 2"),
      h3("Heading 3"),
    ],
  ),
  collapsible(
    { title: "Underline Headings", open: false },
    [
      h1("Heading 1"),
      h2("Heading 2"),
      h3("Heading 3"),
    ],
    ".underlines",
  ),
  collapsible(
    { title: "Body Text", open: false },
    [
      h2("Paragraph Text"),
      p("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."),
      p([
        strong("bold"),
        span(" "),
        em("italic"),
        span(" "),
        strong(em("bold italic")),
        span(" "),
        u("underline"),
      ]),
    ],
  ),
  collapsible(
    { title: "Tables", open: false },
    dynamicTable({
      rows: [
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
        { a: "Lorem", b: "Ipsum", c: "Dolor" },
      ],
    }),
  ),
]);

export default async function uiCustomization(selector) {
  const title = "UI Customization";
  const views = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget([
        form("#ui-customization-form"),
      ], "Colors"),
      widget([
        h2("Branding"),
        placeholder("Coming Soon", "#branding"),
      ]),
      widget([
        h2("Text Preview"),
        textPreview(),
        h2("Element Preview"),
        elementPreview(),
      ]),
    ],
    title,
    false,
  );
  const { header, notification } = views;
  loadingView = views.loading;
  headerView = header;
  notificationView = notification;
  loadingView.show();

  const [
    config,
    user,
    colorPalette,
    files,
    descriptors,
    branding,
  ] = await Promise.all([
    getConfig(),
    api.user.getMe(),
    api.site.getColorPalette(),
    api.media.files(),
    api.file.list(),
    api.site.getBranding(),
  ]);

  let colors = [];
  if (colorPalette?.colors) {
    defaultColors.forEach(({
      key,
      color,
      label,
      mode,
    }) => {
      colors.push({
        key,
        mode,
        label,
        color: colorPalette.colors[key] || color,
      });
    });
  } else {
    colors = defaultColors;
  }

  let logo;
  if (branding?.images?.logoFile?.id) {
    const file = await api.file.getById(branding.images.logoFile.id);
    logo = {
      mode: filePickerModes.KEEP,
      current: file,
    };
  }
  let icons;
  if (branding?.images?.iconsFile?.id) {
    const file = await api.file.getById(branding.images.iconsFile.id);
    icons = {
      mode: filePickerModes.KEEP,
      current: file,
    };
  }

  const state = {
    changed: false,
    user,
    messages: [],
    mode: colorPalette.mode || LIGHT,
    colors,
    origColors: colors,
    title,
    pickers: {
      logoFile: logo || {},
      iconsFile: icons || {},
    },
    files,
    descriptors,
  };

  actionsView = view.create(showActionButtons)("#actions", state);

  uiCustomizationView = formManagedView(showUICustomizationForm, doUpdate)(
    "#ui-customization-form",
    state,
  );

  if (config.env === false) { // "development") {
    brandingView = view.create(showBrandingView)("#branding", state);
  }

  header.update(state);
  loadingView.hide();
}
