/**
 *
 * These are utility functions for core that don't belong anywhere else or
 * cause dependency cycles where they do belong.
 *
 * @module api/core-util
 * @category Backend API
 * @subcategory Core
 */

import getConfig, { getURLCacheByService } from "../config";
import { services } from "./constants";

/**
 * Generates a base URL for an API request from server configuration settings.
 *
 * @function baseUrl
 * @async
 * @param {services} service
 * @return {Promise<string>} base URL
 */
export const baseUrl = async (service = services.DEFAULT) => {
  // best/usual case, config & url cache are already populated
  let url = getURLCacheByService(service);
  if (url === null) {
    // this should result in url cache being built
    await getConfig();
    // now it should be present
    url = getURLCacheByService(service);
  }
  return url;
};

/**
 * Generates a base URL for an API request from server configuration settings.
 *
 * Sync version of {@link baseUrl} for non-async functions. Depends on the
 * config already having been cached.
 *
 * @function baseUrlSync
 * @throws error if config information has not yet been loaded
 * @param {services} service
 * @return {string} base URL
 */
export const baseUrlSync = (service = services.DEFAULT) => {
  const url = getURLCacheByService(service);
  if (url === null) throw new Error("baseUrlSync called before base URL was cached");
  return url;
};
