/**
 * Constraints specific to metadata models.
 *
 * @module model/metadata/constraints
 * @category Model
 * @subcategory Metadata
 */
import {
  validMember,
} from "../constraints";

import * as constants from "./constants";

/**
 * Ensures the value is one of the enumerated criteria in
 * {@link module:api/constants.videoSortCriteria}
 *
 * @function validVideoSortCriteria
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not valid video sort criteria
 *
 * @return {string} the value
 */
export const validVideoSortCriteria = (value, fieldName) => validMember(
  value,
  fieldName,
  constants.videoSortCriteria,
);

/**
 * Ensures the value is one of the supported language codes in
 * {@link module:model/metadata/constant~languageCodes}
 *
 * @function validLanguageCode
 * @param {mixed} value
 * @param {string} fieldName
 *
 * @throws ConstraintError if the value is not one of the enumerated language codes
 *
 * @return {string} the value
 */
export const validLanguageCode = (value, fieldName) => validMember(
  value,
  fieldName,
  constants.languageCodes,
);
