/**
 * Editor fields for text header section.
 *
 * @module ui/component/modal/section-edit/text-header
 * @private
 * @category UI
 * @subcategory Modal Dialogs
 */
/***/
import title from "ui/component/form-managed/field-title";

/**
 * @function sliderFields
 * @private
 *
 * @param self
 * @return {module:ui/html~ChildEl[]}
 */
export default (self) => {
  const { section } = self.state;
  return self.bind([
    [title, { value: section.title }],
  ]);
};
