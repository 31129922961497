/**
 * General purpose search component for use in an action bar widget.
 * @module ui/component/action-bar-search
 * @category UI
 * @subcategory Components - Dashboard
 */
import {
  a,
  button,
  div,
  input,
  p,
  span,
} from "ui/html";
import icon from "ui/component/icon";
import { qs } from "util/dom";

/**
 * Called with the value of the search input when a basic search is submitted.
 *
 * @callback SearchCallback
 * @param {string} searchText
 */

/**
 * Called when the `advanced` search button is clicked. Should raise a modal
 * dialog with advanced search options and perform its own search.
 * @callback AdvanccedSearchCallback
 */

const doSearch = (onSearch) => {
  if (onSearch) onSearch(qs("#action-bar-search input[name=filter]").value);
};

/**
 * Displays a search input, a search button, and an advanced search button.
 *
 * If `onSearch` isn't present, only the advanced search button will be
 * displayed.
 *
 * If `onAdvancedSearch` isn't present the advanced search button will not be
 * displayed.
 *
 * @function actionBarSearch
 * @param {object} config
 * @param {int} config.resultCount number of current search results
 * @param {?string} [config.filter=""] current filter text
 * @param {boolean} [config.showClear=false] whether to show the clear button
 * @param {?SearchCallback} config.onSearch
 * @param {?AdvanccedSearchCallback} config.onAdvancedSearch
 * @param {function} config.onClear
 */
export default function actionBarSearch({
  resultCount,
  filter = null,
  showClear = false,
  onSearch = null,
  onAdvancedSearch = null,
  onClear = null,
  title = "All Results",
  additionalControls = [],
}) {
  return div("#action-bar-search", [
    div(".pane-left", [
      p(
        showClear
          ? span([`Found - ${resultCount} Result${resultCount === 1 ? "" : "s"}`, a.fn(icon.light("times-hexagon"), onClear, ".clear-results")], ".result-count")
          : span(title, ".title"),
        ".results",
      ),
    ]),
    div(".pane-right", [
      div(".search-container", [
        icon.sharp("magnifying-glass", ".search-toggle"),
        div('.search-block', [
          onSearch
            ? input.text(filter || "", false, "filter", {
              props: { placeholder: 'Search…' },
              on: {
                keypress: (e) => {
                  if (e.keyCode === 13) {
                    doSearch(onSearch);
                  }
                },
              },
            })
            : "",
          onSearch ? button('Search', () => doSearch(onSearch)) : "",
          onAdvancedSearch ? button('Advanced', onAdvancedSearch, ".alternate") : "",
        ]),
      ]),
      div(".additional-controls", [...additionalControls]),
    ]),
  ]);
}
