/**
 * A help modal dialog for add content page.
 *
 * @module ui/component/modal/help/add-content
 * @category UI
 * @subcategory Modal Dialogs
 */
import { div, img, p } from "ui/html";
import { getModal } from "ui/view/modal-view";
import slider from "ui/component/slider";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function adminAddContentHelpModal(
  skipCache = false,
  async = false,
) {
  return commonHelpModal({
    modalType: modalTypes.ADMIN_ADD_CONTENT,
    skipCache,
    async,
    sel: ".help-modal-add-content",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content.svg"),
            p("Add Content", ".title.spaced-bottom"),
            div(".text-container", [
              p("To upload a video or document, select type video or document.", ".spaced-bottom"),
              p("Upload a file from your computer or from the media directory.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content.svg"),
            p("Add Content", ".title.spaced-bottom"),
            div(".text-container", [
              p("To create a list, select type List.", ".spaced-bottom"),
              p("Select the list items from existing content by clicking Add Item.", ".spaced-bottom"),
            ]),
          ]),
        },
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-add-content.svg"),
            p("Add Content", ".title.spaced-bottom"),
            div(".text-container", [
              p("Complete the Metadata (poster image & labels), click Save, and you’re all set!", ".spaced-bottom"),
              p("Please confirm the newly added content on the Manage Content interface.", ".spaced-bottom"),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
