/**
 * User stream.
 *
 * @module data/user
 * @category Data Streams
 * @subcategory User
 */
import {
  count as countUsers,
  search as searchUsers,
  getKnownUsers,
} from "api/v2/user";
import {
  CACHE_SEARCH_USER_KEY_V2 as SEARCH_KEY,
  CACHE_USER_KEY_V2 as CACHE_KEY,
  CACHE_USER_ABBREVIATED_V2 as KNOWN_KEY,
} from "cache/constants";
import countPipe from "data/pipeline/count";
import searchPipe from "data/pipeline/search";
import { mapSpread, collectAll, streamLog } from "data/stream/compose";
import hashmap from "util/hash-map";

const countMap = hashmap();
const itemMap = hashmap();
const knownMap = hashmap();
const knownGroupMembers = hashmap();

const {
  post: count,
  sink$: countSink$,
} = countPipe({
  apiFn: countUsers,
  defaultFn: () => 0,
  modelFn: (r) => r,
  accumulator: countMap,
});

const {
  post: search,
  sink$: searchSink$,
} = searchPipe({
  apiFn: searchUsers,
  cacheSearchKey: SEARCH_KEY,
  cacheItemsKey: CACHE_KEY,
  accumulator: itemMap,
});

const {
  post: known,
  sink$: knownSink$,
} = searchPipe({
  apiFn: getKnownUsers,
  cacheSearchKey: SEARCH_KEY,
  cacheItemsKey: KNOWN_KEY,
  accumulator: knownMap,
});

const extractor = (in$) => in$
  .compose(mapSpread)
  .map((searchResult) => hashmap(searchResult.items.map((u) => [u.id, u])))
  .compose(collectAll(itemMap));

const known$ = extractor(knownSink$);

const knownGroupMembers$ = known$
  .map((users) => {
    [...users.values()].forEach((user) => {
      user.groupIds?.forEach((id) => {
        if (!knownGroupMembers.has(id)) knownGroupMembers.set(id, new Map());
        knownGroupMembers.get(id).set(user.id, user);
      });
    });
    return knownGroupMembers;
  });

const all$ = searchSink$
  .compose(mapSpread)
  .map((searchResult) => hashmap(searchResult.items.map((u) => [u.id, u])))
  .compose(collectAll(itemMap))
  .compose(streamLog("USER_ALL$"));

export default {
  count,
  count$: countSink$.compose(streamLog("USER_COUNT$")),
  search,
  known: () => extractor(known([{ type: "known" }, 0])),
  known$,
  knownGroupMembers$,
  all$,
};
