/**
 * A form field for titles.
 *
 * @module ui/component/form-managed/field-title
 * @category UI
 * @subcategory Forms
 */
import input from "ui/component/form-managed/field-input";
import { titleConstraints } from "ui/common/validation-constraints";
import { frozen, merge } from "util/object";

const helpTexts = frozen({
  tooLong: "Titles must be less than 255 characters.",
  taken: "This title is already in use.",
  invalid: "Titles must be between 0-255 characters and may not start or end with whitespace.",
});

/**
 * @private
 */
const defaultState = frozen({
  name: "title",
  label: "Title",
  constraints: titleConstraints,
  helpTexts,
  sel: "",
  taken: null,
});

/**
 * A form field for titles.
 *
 * If the `taken` property is provided with a list of title strings,
 * validation will check if the current value is in the list.
 *
 * @function title
 * @param {object} state
 * @param {string} [state.value=""]
 * @param {boolean} [required=false]
 * @param {boolean} [disabled=false]
 * @param {FieldValidity} [validity=defaultValidity]
 * @param {string} [name="title"]
 * @param {string} [label="Title"]
 * @param {?string[]} taken list of already taken usernames
 * @param {Selector} [sel=""]
 * @return {module:ui/common/el~El}
 */
export default function title(inState) {
  const state = merge(defaultState, inState);
  return input({
    ...state,
    sel: `${state.sel}.title`,
  });
}
