/**
 * A modal component for editing sub menus.
 *
 * @module ui/component/modal/menu-entry-edit
 * @category UI
 * @subcategory Modal Dialogs
 */
import { menuLinkTypes } from "model/site/menu/constants";
import { div, span, h3 } from "ui/html";
import cameoPage from "ui/component/cameo-page";
import menuEntryCameo from "ui/component/cameo-menu-entry";
import iconButton from "ui/component/icon-button";
import message, { messageTypes } from "ui/component/message";
import multiToggle from "ui/component/multi-toggle";
import sortable from "ui/component/sortable";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import button from "ui/component/form-managed/button";
import dialog from "ui/component/modal/layout/dialog";
import titleField from "ui/component/form-managed/field-title";
import urlField from "ui/component/form-managed/field-url-relative";
import pageSelectModal from "ui/component/modal/page-select";
import view from "ui/view";
import { clone, merge, frozen } from "util/object";
import staticMessages from "ui/page/admin/menu/messages";
import { qs } from "util/dom";
import { defaultMenuEntryState } from "./state";
import { getLabel, onFormUpdate } from "./common";

let count = 0;
let notification, modal;
const storedViews = new Map();
const animationClasses = frozen([
  "from-child",
  "from-parent",
  "to-child",
  "to-parent",
]);

const getModalId = () => qs(".dialog.menu-entry-edit.menu-entry-edit")?.dataset?.entryEditId;

const getStoredViewsById = (id) => storedViews.get(id) || [];

const setStoredViewsById = (id, views) => {
  storedViews.set(id, views);
};

const deleteStoredViewsById = (id) => {
  storedViews.delete(id);
};

const getPage = (formView) => {
  if (!formView.state.entry.pageId) return null;
  return formView.state.pages.find(
    (p) => p.id === formView.state.entry.pageId,
  );
};

const getBreadcrumbs = (formView) => {
  let breadcrumbs = [...formView.state.breadcrumbs];
  if (breadcrumbs.length > 2) {
    breadcrumbs = [
      "…",
      ...breadcrumbs.slice(breadcrumbs.length - 2),
    ];
  }
  breadcrumbs.push(formView.values.label || "New Entry");
  return breadcrumbs;
};

const getDialog = () => modal?.element.querySelector(".dialog");

const clearAnimationClasses = () => {
  const elm = getDialog();
  animationClasses.forEach((cls) => elm.classList.remove(cls));
};

/**
 * Handles updating the sortable submenu view.
 * @function onSubMenuUpdate
 * @private
 */
const onSubMenuUpdate = (subMenuView) => {
  subMenuView.patch(subMenuView.factory(subMenuView));
};

const doSubViewUpdate = (state) => {
  const [storedFormView, storedSubEntriesView] = getStoredViewsById(getModalId());
  storedSubEntriesView.update(state);
  storedFormView.update(state);
  /* eslint-disable-next-line no-use-before-define */
  bindInnerViews(storedFormView, storedSubEntriesView);
};

const transition = (cls, cb) => {
  clearAnimationClasses();
  const elm = getDialog();
  setTimeout(cb, 300);
  elm.classList.add(cls);
};

const transitionToChild = (cb) => transition("to-child", cb);
const transitionToParent = (cb) => transition("to-parent", cb);

/**
 * Handles adding a new entry to a menu-entry.
 * @function onAddEntry
 * @private
 */
const onAddEntry = (state) => transitionToChild(async () => {
  const [storedFormView, storedSubEntriesView] = getStoredViewsById(getModalId());

  /* eslint-disable-next-line no-use-before-define */
  const entry = await modal.async(menuEntryEditModal({
    breadcrumbs: getBreadcrumbs(storedFormView),
    pages: state.pages,
  }, modal, notification));

  if (entry === null) return; // user closed dialog during entry edit
  const entries = [
    ...storedSubEntriesView.state.entry?.entries || [],
    entry,
  ];
  doSubViewUpdate({ entry: { entries } });
});

/**
 * Handles editing a submenu link.
 *
 * @function onEditEntry
 * @private
 */
const onEditEntry = (entry, index) => transitionToChild(async () => {
  const [storedFormView, storedSubEntriesView] = getStoredViewsById(getModalId());
  const { state } = storedSubEntriesView;
  /* eslint-disable-next-line no-use-before-define */
  const updated = await modal.async(menuEntryEditModal({
    breadcrumbs: getBreadcrumbs(storedFormView),
    entry,
    pages: state.pages,
  }, modal, notification));

  if (updated === null) return; // user closed dialog during entry edit
  const entries = [...storedSubEntriesView.state.entry.entries].map(clone);
  entries.splice(index, 1, updated);
  doSubViewUpdate({ entry: { entries } });
});

/**
 * Handles deleting a submenu link.
 * @function onDeleteEntry
 * @private
 */
const onDeleteEntry = async (entry, index) => {
  const [formView] = getStoredViewsById(getModalId());
  const entries = [...formView.state.entry.entries];
  if (
    !(await modal.confirm(`Are you sure you want to delete ${entry.label}?`))
  ) {
    return;
  }
  entries.splice(index, 1);
  doSubViewUpdate({ entry: { entries } });
};

/**
 * Handles selecting a page for a page link.
 * @function onSelectPage
 * @private
 */
const onSelectPage = async () => {
  const [storedFormView] = getStoredViewsById(getModalId());
  const { pages } = storedFormView.state;
  const selected = (await modal.async(pageSelectModal({
    pages,
  }, modal)));
  if (selected) {
    const toUpdate = {
      entry: {
        label: storedFormView.state.entry.label || selected.label,
        pageId: selected.id,
        slug: selected.slug,
      },
    };
    storedFormView.update(toUpdate);
  }
};

/**
 * Handles sorting a submenu.
 * @function onSortSubMenu
 */
const onSortSubMenu = (newOrder) => {
  const [, subEntriesView] = getStoredViewsById(getModalId());
  const oldEntries = [...subEntriesView.state.entry.entries];
  const newEntries = [];
  newOrder.forEach((index) => {
    newEntries.push(clone(oldEntries[index]));
  });
  doSubViewUpdate({ entry: { entries: newEntries } });
};

/**
 * @emits `em:add-entry` opens an entry creation dialog, listened for in index.js
 */
const buildSubEntries = ({ state }) => div("#menu-entry-links", {}, [
  h3("Sub-Menu"),
  state.entry.entries?.length
    ? sortable(
      {
        childSelector: "span.menu-entry-edit-bar.cameo",
        onSort: state.hooks.onSortSubMenu,
      },
      div(".entries", {}, state.entry.entries.map((item, index) => menuEntryCameo(
        item,
        index,
        state.hooks.onEditEntry,
        state.hooks.onDeleteEntry,
      ))),
    )
    : "",
  button.standIn({
    icon: "bars",
    label: "Add Sub-Menu Entry",
    onClick: () => onAddEntry(state),
  }),
]);

/**
 * Replaces inner modal elements with empty placeholders after initial DOM
 * construction.
 *
 * Whenever the modal is reloaded (due to opening it initially, or an async
 * modal resolving), the body has to be rebuilt and rebound to the form view.
 * Otherwise the inner views lose their binding and snabbdom tends to leave
 * some of the old contents from a different modal hanging around.
 *
 * @function createPlaceholderElements
 * @private
 */
const createPlaceholderElements = () => {
  const formEl = document.createElement("FORM");
  formEl.id = "menu-entry-edit";
  modal.element.querySelector(".body").replaceChildren(formEl);

  const subMenuEl = document.createElement("div");
  subMenuEl.id = "menu-entry-links";
  modal.element.querySelector(".body").append(subMenuEl);
};

/**
 * Binds the internal form view and sortable sub menu list view.
 *
 * This is necessary to enable form validation, and to stop the
 * outer modal and form views from messing up sortable event bindings.
 *
 * @function bindInnerViews
 * @private
 */
export const bindInnerViews = (formView, subMenuView) => {
  createPlaceholderElements();
  formView.rebind("#menu-entry-edit");
  if (subMenuView) subMenuView.rebind("#menu-entry-links");
  const labelInput = document.querySelector(`#menu-entry-edit input[name="label"]`);
  if (labelInput) labelInput.focus();
};

/**
 * @function buildLinkFields
 * @private
 */
const buildLinkFields = (self) => {
  let page;
  switch (self.state.entry.linkType) {
    case menuLinkTypes.LINK_PAGE:
      page = getPage(self);
      if (page) {
        return [
          cameoPage({
            page,
            controls: [
              iconButton("edit", "Change Page", onSelectPage),
            ],
          }),
        ];
      }
      return [
        button.standIn({
          icon: "file-plus",
          label: "Select Page",
          onClick: onSelectPage,
        }),
      ];
    case menuLinkTypes.LINK_EXTERNAL:
      return [
        [urlField, { required: true, name: "url", label: "URL" }],
        message({
          text: `URLs must include "http://" or "https://" if linking to an external site.`,
          type: messageTypes.HINT,
        }),
        message({
          text: `To link to a page or other resource, include a leading slash ("/").`,
          type: messageTypes.HINT,
        }),
      ];
    default:
      return [
        message({
          text: "This menu item will not be clickable.",
          type: messageTypes.WARNING,
        }),
      ];
  }
};

/**
 * @function buildEditForm
 * @private
 */
const buildEditForm = (self) => {
  const { entry } = self.state;
  self.updateState({ messages: [] });
  const tabs = [menuLinkTypes.NONE, menuLinkTypes.LINK_PAGE, menuLinkTypes.LINK_EXTERNAL];
  return form(
    "#menu-entry-edit",
    self.bind([
      h3("Menu Text"),
      [titleField, { required: true, name: "label", label: "Label" }],
      multiToggle({
        label: "Link",
        options: [
          { name: "none", label: "None", value: menuLinkTypes.NONE },
          { name: "page", label: "Page", value: menuLinkTypes.LINK_PAGE },
          { name: "external", label: "External", value: menuLinkTypes.LINK_EXTERNAL },
        ],
        selectedTab: tabs.indexOf(self.state.entry.linkType) || 0,
        onSelect: (option) => self.update({ entry: { linkType: option.value } }),
      }),
      ...buildLinkFields(self),
    ], entry),
  );
};

/**
 * Validates the form and, if everything checks out, resolves the modal
 * with the updated menu entry object.
 *
 * Called when the user clicks "finish" button.
 *
 * @function onFinish
 * @private
 */
const onFinish = (modalView) => async () => {
  const [formView] = getStoredViewsById(getModalId());
  formView.setFullValidation(true);
  const validation = formView.validate(true);
  if (!validation.valid) {
    notification.post(staticMessages.invalid);
    return;
  }

  deleteStoredViewsById(getModalId());

  transitionToParent(() => modalView.resolve({
    ...formView.state.entry,
    ...formView.state.values,
  }));
};

/**
 * Initializes the form view on an empty form element. See common/bindForm for
 * explanation.
 *
 * @function initInnerViews
 * @private
 */
const initInnerViews = (state) => {
  const [storedFormView, storedSubEntriesView] = getStoredViewsById(getModalId());
  if (storedFormView && storedSubEntriesView) {
    bindInnerViews(storedFormView, storedSubEntriesView);
    return;
  }
  createPlaceholderElements();

  const formView = formManagedView(buildEditForm, onFormUpdate)(
    "#menu-entry-edit",
    state,
  );

  const subEntriesView = view.create(buildSubEntries, onSubMenuUpdate)(
    "#menu-entry-links",
    merge(state, {
      hooks: {
        onAddEntry,
        onEditEntry,
        onDeleteEntry,
      },
    }),
  );
  setStoredViewsById(getModalId(), [formView, subEntriesView]);
};

/**
 * A modal view for editing menu-entry entries.
 *
 * Calls the page-link or external-link modals when the user chooses to add one to
 * the menu-entry's links list.
 *
 * @function menuEntryEditModal
 * @param {object} inState
 * @param {module:api/model/site/menu~Menu} inState.entry a menu entry to be edited
 * @param {Array.<module:model/dynamic-page~Page>} pages for adding a page-type link
 * @param {?module:ui/view/modal~modalView} modalView a modal view instance
 * @param {?NotificationView} notificationView
 */
export default function menuEntryEditModal(
  inState,
  modalView = null,
  notificationView = null,
) {
  notification = notificationView;
  modal = modalView;

  const state = merge.all([defaultMenuEntryState, inState, {
    hooks: {
      onSortSubMenu,
    },
  }]);

  return dialog({
    sel: ".dialog.menu-entry-edit",
    config: {
      dataset: { entryEditId: (++count) },
      hook: {
        insert: () => initInnerViews(state),
        postpatch: () => initInnerViews(state),
      },
      key: "menu-entry-menu-entry-edit-view",
      on: {
        animationend: clearAnimationClasses,
      },
    },
    onClose: () => deleteStoredViewsById(getModalId()),
    header: [
      h3(`Edit ${getLabel(state.entry)}`),
      span(["Location: ", state.breadcrumbs.join(" › ")], ".breadcrumbs"),
    ],
    body: span(),
    footer: button({
      label: "Finish Editing",
      onClick: onFinish(modalView),
    }),
  }, modal);
}
