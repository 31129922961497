/**
 * Manages state persistence for various cachable items.
 *
 * TODO consolidate detectStrategy calls
 *
 * @module cache
 * @category Backend API
 * @subcategory Core
 */
import { storageStrategies, storageStrategyList } from "api/constants";
import log from "log";
import { TOKEN_KEY } from "model/authentication/constants";
import { responseToNotification } from "model/notification";
import { responseToUser } from "model/user";
import { secondsFromNow } from "util/date";

// enables very verbose logging for cache debugging purposes
const DEBUG = false;

const CACHE_KEY_PREFIX = "SmartEdge_cache_v2";

/**
 * @constant
 */
const DEFAULT_EXPIRY_TIME_SECONDS = 300; // 5 minutes

/**
 * The key for the notifications cache entry
 *
 * @constant
 * @private
 */
const NOTIFICATIONS = `notifications`;

/**
 * The key for the user profile cache entry.
 *
 * @constant
 * @private
 */
export const USER_PROFILE = `userProfile`;

/**
 * Cache for reported message ids
 * @constant
 */
export const CACHE_REPORTED_MESSAGE_IDS = `reportedMessageIds`;

/**
 * Live cache keeps object values in memory so we can skip the JSON processing,
 * which could be slow for large values.
 *
 * @constant
 * @private
 */
const LIVE_CACHE = new Map();

/**
 * Which storage system to use. Currently expected to have the
 * shape of a browser Storage object.
 *
 * @private
 */
let strategy = null;

/**
 * Composes the {@link CACHE_KEY_PREFIX} with the unique part of a cache key.
 *
 * @function makeStorageKey
 * @private
 * @param {string} key
 * @return {string}
 */
const makeStorageKey = (key) => `${CACHE_KEY_PREFIX}_${key}`;

// FIXME remove after end of 1.10 release cycle
const clearV1Cache = () => {
  if (window.localStorage.getItem(`SmartEdge_cache_v1`)) window.localStorage.clear();
  if (window.sessionStorage.getItem(`SmartEdge_cache_v1`)) window.sessionStorage.clear();
};

/**
 * Detects the storage strategy by checking for existing, known keys in
 * all supported cache methods (@see storageStrategyList}.
 *
 * FIXME this doesn't do anything since we don't use local storage
 *       instead we need some strategy that clears the cache after the
 *       last window in a session is closed
 *
 * @function detectStrategy
 * @private
 * @return {string} storage strategy
 */
const detectStrategy = () => {
  if (strategy !== null) return true; // already detected
  // FIXME remove after end of 1.10 release cycle
  clearV1Cache();
  if (DEBUG) log.debug("CACHE :: detecting storage strategy");
  let success = false;
  storageStrategyList.forEach((key) => {
    if (storageStrategies[key].getItem(CACHE_KEY_PREFIX)) {
      if (DEBUG) log.debug("CACHE :: discovered storage strategy", key);
      success = true;
      strategy = storageStrategies[key];
    }
  });
  if (success) return true;
  if (DEBUG) log.debug("CACHE :: no storage strategy detected");
  return false;
};

/**
 * Get a cache entry using the storage strategy selected by {@link detectStrategy}.
 *
 * @function getUsingStrategy
 * @private
 * @param {string} key the cache key
 * @return {?object}
 */
const getUsingStrategy = (key) => {
  if (detectStrategy()) return strategy.getItem(key);
  return null;
};

/**
 * Set a cache entry using the storage strategy selected by {@link detectStrategy}.
 *
 * This does not process the value in any way, so you have to have already serialized it
 * if applicable.
 *
 * @function setUsingStrategy
 * @private
 * @param {string} key the cache key
 * @param {mixed} value what to put in the cache
 * @return {?object}
 */
const setUsingStrategy = (key, value) => {
  if (detectStrategy()) return strategy.setItem(key, value);
  return null;
};

/**
 * Store the current user profile.
 *
 * @function storeProfile
 * @param {model/user~User} user
 *
 * @return boolean indicating success
 */
const storeProfile = (user) => {
  if (DEBUG) log.debug("CACHE :: storing profile", user);
  const profile = {
    ...user,
  };
  try {
    /* eslint-disable-next-line no-use-before-define */
    storeExpiring(USER_PROFILE, profile);
    return true;
  } catch (e) {
    log.error(e);
    return false;
  }
};

/**
 * For arbitrary storage of objects. Key is namespaced with the CACHE_KEY_PREFIX.
 *
 * The value is JSON encoded.
 *
 * @function storeObject
 * @param {string} key
 * @param {object} value
 *
 * @return {boolean} indicating success
 */
const storeObject = (key, value) => {
  const skey = makeStorageKey(key);
  LIVE_CACHE.set(skey, value);
  setUsingStrategy(skey, JSON.stringify(value));
  return true;
};

/**
 * Get object entry from storage.
 *
 * Note that the returned object is mutable, but direct changes will not persist
 * between navigation changes unless you explicitly store it with {@link storeObject()}!
 *
 * @function getObject
 * @param {string} key storage key
 * @return {?object} the decoded cached object
 */
const getObject = (key) => {
  const skey = makeStorageKey(key);
  const live = LIVE_CACHE.get(skey);
  if (!live) {
    const value = JSON.parse(getUsingStrategy(makeStorageKey(key)) || "null");
    LIVE_CACHE.set(skey, value);
    return value;
  }
  return live;
};

/**
 * Initialize an object cache entry with a default if the cache does not already
 * exist, then return either the existing value or the default.
 *
 * @function initObject
 * @param {string} key storage key
 * @param {object} value default value
 * @return {object}
 */
const initObject = (key, value) => {
  let cached = getObject(key);
  if (cached === null) {
    storeObject(key, value);
    cached = getObject(key);
  }
  return cached;
};

/**
 * Retrieves a value from the expiring cache if it has not expired.
 *
 * @function getExpiring
 * @param {string} key
 * @return {?mixed} null if the value was expired
 */
const getExpiring = (key) => {
  const cache = getObject(key);
  return (cache?.expires > Date.now())
    ? cache.value
    : null;
};

/**
 * @type MapCacheEntry
 * @property {int} expires unix timestamp
 * @property {mixed} value
 */

/**
 * Retrieves a whole cache map.
 *
 * For when we want to manipulate entries in the map in bulk, without doing the
 * whole Map => cache entry => Map loop for each entry to be changed.
 *
 * For single entries, just use getExpiringMapEntry and storeExpiringMapEntry.
 *
 * @function getExpiringMap
 * @param {string} key cache key
 * @return { Map.<string, MapCacheEntry> }
 */
const getExpiringMap = (key) => {
  const cache = getObject(key);
  if (cache?.expires > Date.now()) {
    if (DEBUG) log.debug(`CACHE :: hit for map ${key}`);
    return new Map(cache.value);
  }
  if (DEBUG) log.debug(`CACHE :: miss for map ${key}`);
  return null;
};

/**
 * Retrieves a value from a map cache, if the entry is not expired.
 *
 * @param {string} cacheKey
 * @param {string} itemKey
 * @return {?mixed}
 */
const getExpiringMapEntry = (cacheKey, itemKey) => {
  const item = getExpiringMap(cacheKey)?.get(itemKey);
  if (item?.expires > Date.now()) {
    if (DEBUG) log.debug(`CACHE :: hit for map entry ${cacheKey} : ${itemKey} `);
    return item.value;
  }
  if (DEBUG) log.debug(`CACHE :: miss for map entry ${cacheKey} : ${itemKey} `);
  return null;
};

/**
 * Retrieves a value from the expiring cache even if it was expired.
 *
 * Useful if we want a temporary result while refreshing.
 *
 * @function getExpired
 * @param {string} key
 * @return {?mixed}
 */
const getExpired = (key) => getObject(key)?.value;

/**
 * Retrieves a map cache even if the outer cache is expired.
 *
 * Useful e.g. when populating a cached playlist's items, and we don't
 * care if the individual items are a little out of date, but we want to
 * avoid re-hydrating the map from cache for each individual item.
 *
 * @function getExpiredMap
 * @param {string} key
 * @return {?Map}
 */
const getExpiredMap = (key) => new Map(getExpired(key));

/**
 * Retrieves a single entry from a map cache, even if it's expired.
 *
 * Useful when restoring children or dependents of cached objects where we only
 * care if the parent object is expired.
 *
 * @param {string} cacheKey
 * @param {string} itemKey
 * @return {?mixed}
 */
const getExpiredMapEntry = (cacheKey, itemKey) => {
  // in this case the map should not be expired because its expiry should
  // be at least >= the item's expiry, so we can skip the check
  const entry = getExpiredMap(cacheKey)?.get(itemKey);
  return (entry?.expires > Date.now())
    ? entry.value
    : null;
};

const getExpiredMapEntries = (cacheKey, itemKeys) => {
  const map = getExpiredMap(cacheKey);
  const found = new Map();
  let hits = 0;
  let misses = 0;
  itemKeys.forEach((key) => {
    const entry = map.get(key);
    if (entry?.expires > Date.now()) {
      found.set(key, entry.value);
      hits += 1;
    } else {
      found.set(key, null);
      misses = 1;
    }
  });
  if (DEBUG) {
    log.debug(`CACHE :: getExpiredMapEntries: hits ${hits} / misses ${misses}`);
  }
  return found;
};

/**
 * Stores a value in cache that automatically expires after a duration.
 *
 * @function storeExpiring
 * @param {string} key
 * @param {mixed} value
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 * @return {?mixed} null if `duration` was before now, otherwise `value`
 */
const storeExpiring = (key, value, duration = DEFAULT_EXPIRY_TIME_SECONDS) => {
  storeObject(key, {
    expires: secondsFromNow(duration),
    value,
  });
  return getExpiring(key);
};

/**
 * Stores a Map in cache that automatically expires after a duration.
 *
 * The map is converted to an array before storing, and then re-instantiated
 * as a map when retrieving it or its entries.
 *
 * @function storeExpiringMap
 * @param {string} key
 * @param {Map} map
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 * @return {?mixed} null if `duration` was before now, otherwise `value`
 */
const storeExpiringMap = (key, map, duration = DEFAULT_EXPIRY_TIME_SECONDS) => {
  storeExpiring(key, [...map.entries()], duration);
  return getExpiringMap(key);
};

/**
 * Adds or updates an entry in a cached map.
 *
 * @function storeExpiringMapEntry
 * @param {string} cacheKey
 * @param {string} itemKey
 * @param {mixed} value
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 * @return {?mixed} null if `duration` was before now, otherwise `value`
 */
const storeExpiringMapEntry = (
  cacheKey, itemKey, value, duration = DEFAULT_EXPIRY_TIME_SECONDS,
) => {
  let map = getExpiringMap(cacheKey);
  if (map === null) map = new Map();
  map.set(itemKey, { expires: secondsFromNow(duration), value });
  storeExpiringMap(cacheKey, map, duration);
  return getExpiringMapEntry(cacheKey, itemKey);
};

/**
 * Adds or updates multiple entries in a cache map.
 *
 * @function storeExpiringMapEntries
 * @param {string} cacheKey
 * @param {Array.<Array.<string, mixed>>} pairs
 * @param {string} pairs[].0 itemKey
 * @param {string} pairs[].0 itemValue
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 */
const storeExpiringMapEntries = (
  cacheKey, pairs, duration = DEFAULT_EXPIRY_TIME_SECONDS,
) => {
  const map = getExpiringMap(cacheKey) || new Map();
  const expires = secondsFromNow(duration);
  pairs.forEach(([itemKey, value]) => {
    map.set(itemKey, { expires, value });
  });
  if (DEBUG) log.debug("CACHE :: STORING ENTRIES", map);
  storeExpiringMap(cacheKey, map, duration);
};

/**
 * Callback for the {@link fromExpiringCache} function.
 *
 * @callback ExpiringCacheCallback
 * @async
 * @return {object}
 */

/**
 * Retrieves a value from an expiring cache entry, or retrieves and caches a new value
 * using the provided callback function if the cache has expired.
 *
 * @function getOrRefreshExpiring
 * @param {string} key
 * @param {ExpiringCacheCallback} cb
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 * @return {?mixed} the stored object (should == the callback response)
 */
const getOrRefreshExpiring = async (key, cb, duration = DEFAULT_EXPIRY_TIME_SECONDS) => {
  const cached = getExpiring(key);
  if (cached !== null) {
    return cached;
  }
  const value = await cb();
  return storeExpiring(key, value, duration);
};

/**
 * Retrieves an entry from an expiring map cache, or retrieves and caches a new value
 * using the provided callback function if the item or map has expired.
 *
 * @function getOrRefreshExpiring
 * @param {string} cacheKey
 * @param {string} itemKey
 * @param {ExpiringCacheCallback} cb
 * @param {int} [duration=DEFAULT_EXPIRY_TIME_SECONDS]
 * @return {?mixed} the stored object (should == the callback response)
 */
const getOrRefreshExpiringMapEntry = async (
  cacheKey, itemKey, cb, duration = DEFAULT_EXPIRY_TIME_SECONDS,
) => {
  const cached = getExpiringMapEntry(cacheKey, itemKey);
  if (cached !== null) {
    if (DEBUG) log.debug("CACHE :: map refresh hit for", cacheKey, itemKey);
    return cached;
  }
  if (DEBUG) log.debug("CACHE :: map refresh miss for", cacheKey, itemKey);
  const value = await cb();
  return storeExpiringMapEntry(cacheKey, itemKey, value, duration);
};

/**
 * For arbitrary storage of objects. Key is namespaced with the CACHE_KEY_PREFIX.
 *
 * The value is stored as-is with no encoding (see also {@link storeObject}).
 *
 * @function storeValue
 * @param {string} key
 * @param {object} value
 * @return {mixed} @see setUsingStrategy
 */
const storeValue = (key, value) => setUsingStrategy(makeStorageKey(key), value);

/**
 * Get plaintext entry from storage.
 * @param {string} key storage key
 */
const getValue = (key) => getUsingStrategy(makeStorageKey(key));

/**
 * Delete a cache entry from storage.
 * @function deleteValue
 * @param {string} key
 */
const deleteValue = (key) => {
  if (DEBUG) log.debug(`CACHE :: removing value for ${key}`);
  storageStrategyList.forEach((strat) => {
    storageStrategies[strat].removeItem(makeStorageKey(key));
  });
};

/**
 * Remove a single item from a map cache.
 *
 * @param {string} cacheKey
 * @param {string} itemKey
 */
const deleteExpiringMapEntry = (cacheKey, itemKey) => {
  const cached = getObject(cacheKey);
  if (DEBUG) log.debug(`CACHE :: removing map entry ${cacheKey} : ${itemKey}`);
  let map;
  if (cached) {
    map = new Map(cached.value || []);
    map.delete(itemKey);

    if (map.size) {
      storeObject(cacheKey, {
        expires: cached.expires,
        value: [...map.entries()],
      });
    } else deleteValue(cacheKey);
  }
};

/**
 * Deletes multiple entries in a cache map.
 *
 * @function deleteExpiringMapEntries
 * @param {string} cacheKey
 * @param {Array.<Array.<string>>} itemKeys
 */
const deleteExpiringMapEntries = (cacheKey, itemKeys) => {
  if (DEBUG) log.debug("CACHE :: DELETING ENTRIES", itemKeys);
  const map = getExpiringMap(cacheKey) || new Map();
  itemKeys.forEach((key) => map.delete(key));
  storeExpiringMap(cacheKey, map);
};

/**
 * Finds the keys associated with items matched by filterFn.
 *
 * @function findExpiringMapEntryKeys
 * @param {string} cacheKey
 * @param {FilterCallback} filterFn
 * @return {string[]}
 */
const findExpiringMapEntryKeys = (cacheKey, filterFn) => {
  const cached = getObject(cacheKey);
  const map = new Map(cached?.value || []);
  if (DEBUG) log.debug("CACHE:findExpiringMapEntryKeys :: found for", cacheKey, map);
  const found = [];
  [...map.entries()].forEach(([key, value]) => {
    if (filterFn(value)) found.push(key);
  });
  if (DEBUG) log.debug("CACHE:findExpiringMapEntryKeys :: found keys", found);
  return found;
};

/**
 * Deletes entries in a cache map matched by filterFn.
 *
 * @function deleteMatchingExpiringMapEntries
 * @param {string} cacheKey
 * @param {FilterCallback} filterFn
 */
const deleteMatchingExpiringMapEntries = (cacheKey, filterFn) => {
  const itemKeys = findExpiringMapEntryKeys(
    cacheKey,
    filterFn,
  );
  deleteExpiringMapEntries(cacheKey, itemKeys);
};

/**
 * Checks whether an expiring map entry exists and is not expired.
 * @param {string} cacheKey
 * @param {string} itemKey
 * @return {boolean}
 */
const mapEntryIsValid = (cacheKey, itemKey) => !!getExpiringMapEntry(cacheKey, itemKey);

/**
 * Checks whether an expiring entry exists and is not expired.
 * @param {string} cacheKey
 * @return boolean
 */
const isValid = (cacheKey) => !!getExpiring(cacheKey);

/**
 * Get the currently stored user profile.
 *
 * @function getProfile
 * @return {?module:api/types/user~User}
 */
const getProfile = () => {
  const profile = getExpired(USER_PROFILE);
  if (profile) return responseToUser(profile);
  return null;
};

/**
 * Get reported message ids
 *
 * @returns {?Set.<string>}
 */
const getReportedMessageIds = () => new Set(getObject(CACHE_REPORTED_MESSAGE_IDS) || []);

/**
 * Store reported message id in a cache
 *
 * @param {string} id
 * @returns {boolean}
 */
const storeReportedMessageId = (id) => {
  if (DEBUG) log.debug("Storing message id: ", id);
  try {
    const existingMessages = getReportedMessageIds();
    existingMessages.add(id);
    storeObject(CACHE_REPORTED_MESSAGE_IDS, Array.from(existingMessages));
    return true;
  } catch (e) {
    log.error(e);
    return false;
  }
};

/**
 * Clear reported message ids cache
 *
 * @returns {boolean}
 */
const clearReportedMessageIds = () => {
  try {
    storeObject(CACHE_REPORTED_MESSAGE_IDS, []);
    return true;
  } catch (e) {
    log.error(e);
    return false;
  }
};

/**
 * Get the currently stored notifications
 *
 * @function getNotifications
 * @returns {?module:model/notification~Array<NotificationItem>}
 */
const getNotifications = () => {
  const notificationsObject = getObject(NOTIFICATIONS);
  return notificationsObject ? notificationsObject.map(responseToNotification) : [];
};

/**
 * Store the received notifications
 *
 * @function storeNotifications
 * @param {?module:model/notification~Array<NotificationItem>} notifications
 *
 * @returns boolean indicating success
 */
const storeNotifications = (notifications) => {
  if (DEBUG) log.debug("Storing notifications", notifications);
  try {
    const existingNotifications = getNotifications();
    storeObject(NOTIFICATIONS, [
      ...notifications,
      ...existingNotifications,
    ]);
    return true;
  } catch (e) {
    log.error(e);
    return false;
  }
};

/**
 * Clear notifications from cache
 *
 * @function clearNotifications
 * @returns {boolean} indicating success
 */
const clearNotifications = () => {
  try {
    storeObject(NOTIFICATIONS, []);
    return true;
  } catch (e) {
    log.error(e);
    return false;
  }
};

/**
 * Clears all stored entries controlled by the cache module. Call when logging
 * out a user.
 *
 * @function clear
 */
const clear = () => {
  storageStrategyList.forEach((strat) => Object.keys(storageStrategies[strat]).forEach((key) => {
    if (key.startsWith(CACHE_KEY_PREFIX)) {
      storageStrategies[strat].removeItem(key);
    }
  }));
};

const clearItem = (key) => {
  storageStrategyList.forEach((strat) => {
    storageStrategies[strat].removeItem(makeStorageKey(key));
  });
};

/**
 * Used when changing storage strategies. Dumps all entries in old storage with the
 * smartedge prefix, so they can be re-added to the new storage strategy.
 *
 * @function dumpStorage
 * @private
 */
const dumpStorage = () => {
  if (!strategy) return new Map();
  const dump = new Map();
  Object.keys(strategy.key).forEach((key) => {
    dump.set(key, strategy.getItem(key));
  });
  if (DEBUG) log.debug("storage dump", dump);
  return dump;
};

/**
 * Set the storage strategy.
 *
 * @function setStrategy
 * @param {module:api/constants.storageStrategies} chosen
 * @return {undefined}
 */
const setStrategy = (chosen) => {
  let ok = false;
  storageStrategyList.forEach((key) => {
    if (storageStrategies[key] === chosen) ok = true;
  });
  if (ok) {
    const dump = dumpStorage();
    clear();
    strategy = chosen;
    [...dump.entries()].forEach(strategy.setItem);
    strategy.setItem(CACHE_KEY_PREFIX, true);
    return;
  }
  log.error("invalid storage strategy", chosen);
};

/**
 * Shortcut to get the currently stored authentication token pair.
 * @function getTokens
 * @return {module:model/authentication~AuthenticationTokenPair}
 */
const getTokens = () => getObject(TOKEN_KEY);

/**
 * Shortcut to get the currently stored authentication token pair.
 * @function storeTokens
 * @param {module:model/authentication~AuthenticationTokenPair} tokenPair
 * @return {boolean}
 */
const storeTokens = (tokenPair) => storeObject(TOKEN_KEY, tokenPair);

window.addEventListener("load", detectStrategy);

export default {
  notificationsState: {
    storeNotifications,
    getNotifications,
    clearNotifications,
  },
  reportedMessages: {
    storeReportedMessageId,
    getReportedMessageIds,
    clearReportedMessageIds,
  },
  clear,
  clearItem,
  deleteValue,
  deleteExpiringMapEntry,
  deleteExpiringMapEntries,
  deleteMatchingExpiringMapEntries,
  findExpiringMapEntryKeys,
  getExpiring,
  getExpiringMap,
  getExpiringMapEntry,
  getExpired,
  getExpiredMap,
  getExpiredMapEntry,
  getExpiredMapEntries,
  getOrRefreshExpiring,
  getOrRefreshExpiringMapEntry,
  getObject,
  getProfile,
  getValue,
  getTokens,
  initObject,
  isValid,
  mapEntryIsValid,
  setStrategy,
  storeExpiring,
  storeExpiringMap,
  storeExpiringMapEntry,
  storeExpiringMapEntries,
  storeObject,
  storeProfile,
  storeValue,
  storeTokens,
  DEFAULT_EXPIRY_TIME_SECONDS,
};
