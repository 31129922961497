/**
 * Renders the full-width slider image section type for dynamic pages.
 *
 * @module ui/page/dynamic/section-slider
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import heroSlider from "ui/component/hero-slider";

const intervals = {};

const rotate = (parent, cur, count, rotateNav = true) => {
  const prev = parent.querySelector(".current");
  if (prev) prev.classList.remove("current");
  const next = parent.children[cur % count];
  if (next) next.classList.add("current");
  if (rotateNav) rotate(parent.querySelector("nav"), cur, count, false);
};

const getSliderElement = (page, sliderIndex) => page.element.querySelector(`#slides-${sliderIndex}`);

const getSliderPosition = (page, sliderIndex) => page.state
  .positions.get(sliderIndex) || 0;

const initRotation = (page, sliderIndex, sliderTime) => {
  const section = page.state.sections.get(sliderIndex);
  if (intervals[sliderIndex]) clearInterval(intervals[sliderIndex]);
  intervals[sliderIndex] = setInterval(() => {
    const { positions } = page.state;
    const current = getSliderPosition(page, sliderIndex);
    const el = getSliderElement(page, sliderIndex);
    rotate(el, current, section.searchData?.slides?.length);
    positions.set(sliderIndex, current + 1);
    page.updateState({ positions });
  }, sliderTime);
};

const makeOnNavigate = (page, sliderIndex, sliderTime) => (slideIndex) => {
  const { positions, sections } = page.state;
  positions.set(sliderIndex, slideIndex);

  const parent = getSliderElement(page, sliderIndex);
  const slideCount = sections.get(sliderIndex).searchData?.slides?.length;

  rotate(parent, slideIndex, slideCount);

  // we want to update the state, but not trigger a patch here
  page.updateState({ positions });
  initRotation(page, sliderIndex, sliderTime);
};

const makeSectionId = (id) => id;

/**
 * An image slider.
 *
 * @function sectionSlider
 * @param {module:model/dynamic-page~PageSection} section of type `TEXT`
 * @return {module:ui/common/el~El} section element
 */
export default function sectionSlider(page, sectionId, sliderTime = 5000) {
  const section = page.state.sections.get(sectionId);
  const sliderIndex = makeSectionId(sectionId);
  if (!section) return heroSlider.placeholder({ key: sectionId, sliderIndex });
  const current = page.state.positions.get(sliderIndex) || 0;
  const slider = heroSlider.generic({
    slides: section?.searchData?.slides || [],
    sliderIndex,
    current,
    onNavigate: makeOnNavigate(page, sliderIndex, sliderTime),
    key: sectionId,
    sel: section?.searchData?.short ? ".short" : "",
  });
  slider.merge({
    hook: {
      insert: () => initRotation(page, sliderIndex, sliderTime),
      postpatch: () => initRotation(page, sliderIndex, sliderTime),
    },
  });
  return slider;
}
