/**
 * Basic chart component
 *
 * @module ui/component/chart
 * @category UI
 * @subcategory Components
 */
import { div, p } from "ui/html";
import { merge } from "util/object";
import canvas from "ui/html/canvas";
import icon from "ui/component/icon";

const defaultState = {
  canvasId: null,
  data: {
    labels: [],
    datasets: [],
  },
  options: {},
  type: null,
  children: [],
  sel: "",
};

export default function chart({ state: inState = {} }) {
  const state = merge(defaultState, inState);
  return div(`.sm-chart.chart-container${state.sel}`, [
    state.data.labels.length
      ? canvas(`#${state.canvasId}`)
      : div(".no-data", [
        icon("chart-line"),
        p("No Data Available"),
      ]),
    div(".children-container", [
      ...state.children,
    ]),
  ]);
}
