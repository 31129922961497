/* eslint-disable-next-line import/prefer-default-export */
export const makeSearchModel = ({ params, items, pageNumber, count }) => ({
  params: params || {},
  items: items || [],
  pageNumber: pageNumber || 0,
  count: count || 0,
});

export const makeCountModel = ({ params, count }) => ({
  params: params || {},
  count: count || 0,
});
