/**
 * A help modal dialog for question grading.
 *
 * @module ui/component/modal/help/question-grading
 * @category UI
 * @subcategory Modal Dialogs
 */
import {
  a, div, img, li, ol, p,
} from "ui/html";
import slider from "ui/component/slider";
import { getModal } from "ui/view/modal-view";
import commonHelpModal from "./common-help";
import { modalTypes } from "./utils";

export default function questionGradingHelpModal(skipCache = true, async = true) {
  return commonHelpModal({
    modalType: modalTypes.QUESTION_GRADING,
    skipCache,
    async,
    sel: ".help-modal-question-grading",
  }, [
    slider({
      controlButtonsConfiguration: {
        withControlButtons: true,
        onLastSlideControlClick: () => getModal().back(),
      },
      dotsClickable: false,
      slideTime: null,
      slides: [
        {
          content: div(".modal-content-container.centered", [
            img(".spaced-bottom", "/media/modal-help-question-grading.svg"),
            p("Question Grading", ".title"),
            div(".text-container", [
              ol([
                li("Please enter a number that should be associated with the Weight/Grade Value for this question."),
                li("Weight/Grade Value is the number that associated with the question itself, not the whole assessment."),
                li("For instance, if an assessment is worth 20 points total and one question is worth 4 points, the 4 points is the Weight/Grade Value."),
                li([
                  "For more questions, please get in touch with your local site administrator or ",
                  a("support@smartedge.com", "mailto:support@smartedge.com"),
                  ". Thanks!",
                ]),
              ]),
            ]),
          ]),
        },
      ],
    }),
  ]);
}
