/**
 * The admin page for creating or editing assessment.
 *
 * Corresponds to `markup/admin/assessment-profile.html`
 *
 * @module ui/page/admin/assessment/profile
 * @category Pages
 * @subcategory Admin - Assessment
 */
import api from "api";
import { EXPAND_LECTURES, EXPAND_NONE } from "api/course/constants";
import { featureTypes } from "model/config/constants";
import cache from "cache";
import { CACHE_COURSE_KEYS } from "cache/constants";
import { getQueryParams } from "util/navigation";
import formManagedView from "ui/view/form-managed";
import form from "ui/component/form-managed";
import description from "ui/component/form-managed/field-description";
import widget from "ui/component/dashboard-widget";
import actionBar from "ui/component/dashboard-action-bar";
import placeholder from "ui/component/placeholder";
import view from "ui/view";
import { button, div, span } from "ui/html";
import formButton from "ui/component/form-managed/button";
import icon from "ui/component/icon";
import startOfDay from "date-fns/startOfDay";
import addWeeks from "date-fns/addWeeks";
import contentSchedule from "ui/component/content-schedule";
import questionTypeSelectModal from "ui/component/modal/assessment/question-type-select";
import cameoQuestion from "ui/component/cameo-question";
import courseSelectModal from "ui/component/modal/course-select";
import moduleSelectModal from "ui/component/modal/course/module-select";
import {
  assessmentUserSortCriteria,
  courseEntryType,
} from "model/course/constants";
import cameoLecture from "ui/component/cameo-lecture";
import cameoModule from "ui/component/cameo-module";
import { tidyBackendError } from "util/error";
import log from "log";
import { sortOrders } from "ui/component/dynamic-table";
import { assessmentUserSort } from "util/sort";
import dashboardLayout from "ui/page/layout/dashboard";
import title from "ui/component/form-managed/field-title";
import number from "ui/component/form-managed/field-number";
import nonZero from "ui/component/form-managed/field-non-zero-integer";
import toggle from "ui/component/form-managed/field-toggle";
import { notificationMessageTypes } from "model/notification/constants";
import { qs } from "util/dom";
import { getAssessmentStatusesByCourseIdAndAssessmentId } from "api/v2/analytics";
import { fetchAndOpenEvaluationByEvalStatus } from "util/assessments";
import { getExtendedAnalyticsEvaluations } from "util/course";
import { guard } from "util/feature-flag";
import userEvaluationStatus from "./user-evaluation-status";
import { defaultProfileState, displayModes, staticMessages } from "./state";
import helpSection from "./help";

let loadingView;
let actionsView;
let analyticsView;
let modalView;
let notificationView;
let assessmentProfileView;
let questionsView;

const selectCourseModule = async (courses) => {
  const updatedCoursesWithoutModules = courses.map((course) => {
    if (!course.modules.length) {
      return {
        ...course,
        title: `${course.title} (0 Modules)`,
        disabled: true,
      };
    }
    return course;
  });
  const course = await modalView.async(
    courseSelectModal(
      { courses: updatedCoursesWithoutModules },
      modalView,
    ),
  );
  if (course) {
    const module = await modalView.async(
      moduleSelectModal({ modules: course.modules }, modalView, notificationView),
    );
    if (module) {
      assessmentProfileView.update({
        selectedCourse: course,
        selectedCourseModule: module,
      });
    }
  }
};

const showAssessmentProfileForm = (self) => {
  const {
    assessment,
    courses,
    selectedCourseModule,
    selectedCourse,
  } = self.state;
  const required = true;
  const sel = assessment.id ? ".disabled" : "";
  const cameo = () => (selectedCourseModule.title === courseEntryType.SINGLE_LECTURE
    ? cameoLecture(
      {
        course: selectedCourse,
        module: selectedCourseModule,
        lecture: selectedCourseModule?.lectures?.[0],
        controls: [button(
          icon.solid("external-link"),
          () => window.open(
            `/admin/course-profile?id=${selectedCourse.id}`,
            "_blank",
          ),
        )],
      },
      assessment.id ? undefined : modalView,
      notificationView,
    )
    : cameoModule(
      {
        course: selectedCourse,
        module: selectedCourseModule,
        controls: [
          button(
            icon.solid("external-link"),
            () => window.open(
              `/admin/course-profile?id=${selectedCourse.id}`,
              "_blank",
            ),
          ),
          assessment.id
            ? ""
            : button(
              icon.solid("trash"),
              () => self.update({ selectedCourse: null, selectedCourseModule: null }),
              ".danger",

            ),
        ],
      },
      assessment.id ? undefined : modalView,
      notificationView,
    ));
  return form("#assessment-profile-form", self.bind([
    [title, { required }],
    [description],
    contentSchedule({
      startDate: assessment.startDate ? new Date(assessment.startDate) : null,
      endDate: assessment.endDate ? new Date(assessment.endDate) : null,
      onEnable: () => self.update({
        assessment: {
          startDate: startOfDay(new Date()),
          endDate: startOfDay(addWeeks(new Date(), 1)),
        },
      }),
      onDisable: () => self.update({
        assessment: {
          startDate: null,
          endDate: null,
        },
      }),
    }),
    [number, { name: "maxPoints", label: "Max points" }],
    [nonZero, { name: "durationInMin", label: "Time Limit (minutes)", required: true }],
    [toggle.boxed.inverse, { label: "Mandatory", name: "mandatory" }],
    span("Module", ".label-course-module"),
    selectedCourseModule
      ? div(
        `.module-entry.button-course-module${sel}`,
        {
          on: {
            click: assessment.id
              ? null
              : () => {
                selectCourseModule(courses);
              },
          },
        },
        cameo(),
      )
      : button(
        "Select course module",
        () => selectCourseModule(courses),
        ".stand-in.button-course-module",
      ),
  ], assessment));
};

const calculateUsedPoints = (state, values) => {
  const maxPoints = values?.maxPoints || state?.assessment?.maxPoints;
  const usedPoints = state.assessment.questions
    .map((item) => item.weight)
    .reduce((accumulator, currentValue) => accumulator + (+currentValue), 0);
  return {
    maxPoints: +maxPoints || 0,
    usedPoints: +usedPoints || 0,
  };
};

const addQuestion = async () => {
  const question = await modalView.async(
    questionTypeSelectModal(calculateUsedPoints(
      assessmentProfileView.state,
      assessmentProfileView.values,
    )),
  );
  if (question) {
    assessmentProfileView.update({
      assessment: {
        questions: [
          ...assessmentProfileView.state.assessment.questions,
          question,
        ],
      },
    });
  }
};

const makeQuestionRow = (question, state, calculatedUsedPoints) => {
  const { assessment } = state;
  return cameoQuestion({
    ...calculatedUsedPoints,
    question,
    onEdit: (editedQuestion) => {
      assessmentProfileView.update({
        assessment: {
          questions: assessment.questions.map((item, index) => {
            if (assessment.questions.indexOf(question) === index) {
              return editedQuestion;
            }
            return item;
          }),
        },
      });
    },
    onRemove: (removedQuestion) => {
      assessmentProfileView.update({
        assessment: {
          questions: assessment.questions.filter((item) => item !== removedQuestion),
        },
      });
    },
  }, modalView, notificationView);
};

const showQuestions = ({ state }) => {
  const { assessment } = state;
  const calculatedUsedPoints = calculateUsedPoints(state, assessmentProfileView?.values);
  return div(".container-questions", [
    toggle.boxed.inverse({
      toggled: state.assessment.randomizeQuestions,
      label: "Randomize Questions",
      name: "randomizeQuestions",
      onToggle: (toggled) => {
        assessmentProfileView.updateState({
          assessment: {
            randomizeQuestions: toggled,
          },
        });
      },
    }),
    ...assessment.questions
      ?.map((question) => makeQuestionRow(question, state, calculatedUsedPoints)) || "",
    button(
      [icon("plus-circle"), "Add Question"],
      () => addQuestion(),
      "#add-question.stand-in",
    ),
  ]);
};

const handleError = (err) => {
  if (err.statusCode) {
    switch (err.statusCode) {
      case 403:
        notificationView.post(staticMessages.accessDenied);
        break;
      case 400:
      default: {
        let errorDetermined = false;
        tidyBackendError(err.body).forEach((text) => {
          if (text.includes("maxPoints")) {
            notificationView.post(staticMessages.maxPoints);
            errorDetermined = true;
          }
          if (text.includes("startDate")) {
            notificationView.post(staticMessages.startDate);
            errorDetermined = true;
          }
        });
        if (!errorDetermined) {
          notificationView.post(staticMessages.backendErrors);
        }
        break;
      }
    }
  } else {
    notificationView.post({
      title: "Error",
      text: err.message,
      type: notificationMessageTypes.FAIL,
    });
  }
};

const doSave = async () => {
  assessmentProfileView.setFullValidation(true);
  const validation = assessmentProfileView.validate(true);
  const { values, state } = assessmentProfileView;
  const {
    selectedCourse, selectedCourseModule, assessment, assessmentId,
  } = state;
  let errors = 0;
  if (!selectedCourseModule) {
    errors++;
    notificationView.post(staticMessages.selectCourseModule);
  }
  if (!validation.valid) {
    errors++;
    notificationView.post(staticMessages.invalid);
  }
  if (!assessment.questions.length) {
    errors++;
    notificationView.post(staticMessages.questionsLength);
  }
  if (errors > 0) {
    return;
  }
  try {
    loadingView.show(`Saving ${assessment.title}`);
    const preparedAssessment = {
      ...assessment,
      maxPoints: values.maxPoints,
      durationInMin: values.durationInMin,
      mandatory: values.mandatory || false,
      title: values.title,
      description: values.description,
      startDate: values?.startDate || undefined,
      endDate: values?.endDate || undefined,
    };
    let response;
    if (assessmentId) {
      response = await api.course
        .updateAssessment(selectedCourse.id, selectedCourseModule.id, preparedAssessment);
    } else {
      response = await api.course
        .createAssessment(selectedCourse.id, selectedCourseModule.id, preparedAssessment);
    }
    // FIXME remove during stream refactor
    [...CACHE_COURSE_KEYS].forEach((key) => cache.deleteValue(key));
    loadingView.hide();
    if (assessmentId) {
      notificationView.post(staticMessages.success);
      loadingView.hide();
    } else {
      await modalView.alert(`${preparedAssessment.title} created. Opening profile...`);
      window.location.assign(`/admin/assessment-profile?id=${response.id}`);
    }
  } catch (e) {
    log.error(e);
    handleError(e);
    loadingView.hide();
  }
};

const doDelete = async () => {
  const { state } = assessmentProfileView;
  if (!(await modalView.confirm(
    `Are you sure you want to delete ${state.assessment.title}?`,
    "This action is irreversible.",
    undefined,
    undefined,
    true,
  ))) {
    return;
  }
  try {
    loadingView.show();
    await api.course.deleteAssessment(
      state.selectedCourse.id,
      state.selectedCourseModule.id,
      state.assessmentId,
    );
    await modalView.alert("Assessment deleted. Returning to assessment management page.");
    // FIXME remove during stream refactor
    [...CACHE_COURSE_KEYS].forEach((key) => cache.deleteValue(key));
    window.location.assign("/admin/manage-assessments");
  } catch (e) {
    loadingView.hide();
    handleError(e);
  }
};

const onAnalyticsSortChange = (sortColumn) => {
  const { state } = assessmentProfileView;
  let sortOrder;
  if (sortColumn === state.analytics.sortColumn) {
    if (state.analytics.sortOrder === sortOrders.ASC) sortOrder = sortOrders.DESC;
    else sortOrder = sortOrders.ASC;
  } else sortOrder = sortOrders.ASC;
  assessmentProfileView.update({
    analytics: {
      sortOrder,
      sortColumn,
    },
  });
};

const showAnalytics = ({ state }) => userEvaluationStatus({
  evaluationStatuses: state.evaluationStatuses,
  users: state.users,
  sortColumn: state.analytics.sortColumn,
  sortOrder: state.analytics.sortOrder,
  onSortChange: onAnalyticsSortChange,
  onRowClick: async (evalStatus) => {
    await fetchAndOpenEvaluationByEvalStatus(evalStatus, loadingView);
  },
});

const showActionButtons = ({ state }) => div("#actions", [
  state.displayMode === displayModes.HELP
    ? formButton.alternate({
      icon: "table",
      label: "Assessment Profile",
      onClick: () => {
        // need to double-update to get validation back
        qs("main.dashboard").classList.remove("show-help");
        assessmentProfileView.update({ displayMode: displayModes.ASSESSMENT_PROFILE });
        assessmentProfileView.update({});
      },
      ariaLabel: "Show Assessment Profile",
    })
    : formButton.alternate({
      icon: "question-circle",
      label: "Help",
      onClick: () => {
        qs("main.dashboard").classList.add("show-help");
        assessmentProfileView.update({ displayMode: displayModes.HELP });
      },
      ariaLabel: "Show Help",
    }),
  button(
    [icon("save"), "Save"],
    () => doSave(),
    ".primary",
    "button",
    "Save",
  ),
  assessmentProfileView?.state?.assessmentId
    ? button(
      [icon("trash"), "Delete"],
      doDelete,
      ".danger",
      "button",
      "Delete",
    )
    : "",
]);

const getSortCriteria = (sortColumn, sortOrder) => {
  switch (sortColumn) {
    case "evaluationStatus":
      return (sortOrder === sortOrders.ASC
        ? assessmentUserSortCriteria.EVAL_STATUS_ASC
        : assessmentUserSortCriteria.EVAL_STATUS_DESC);
    case "student":
    default:
      return (sortOrder === sortOrders.ASC
        ? assessmentUserSortCriteria.STUDENT_ASC
        : assessmentUserSortCriteria.STUDENT_DESC);
  }
};

const doUpdate = (self) => {
  actionsView.update(self.state);
  questionsView.update(self.state);
  self.render();
  let sortedEvaluationStatuses = [];
  if (self.state.evaluationStatuses?.length) {
    sortedEvaluationStatuses = [...self.state.evaluationStatuses].sort(
      assessmentUserSort(
        getSortCriteria(self.state.analytics.sortColumn, self.state.analytics.sortOrder),
        self.state.users,
      ),
    );
  }
  self.updateState({
    messages: [],
    evaluationStatuses: sortedEvaluationStatuses,
  });
  analyticsView.update(self.state);
};

export default async function assessmentProfile(selector) {
  guard(featureTypes.LMS);
  const { id: assessmentId } = getQueryParams();
  const browserTitle = assessmentId ? "Assessment Profile" : "Add Assessment";

  const views = dashboardLayout(
    selector,
    [
      actionBar(placeholder("", "#actions")),
      widget([
        form("#assessment-profile-form"),
      ], "Profile", ".assessment-profile"),
      // widget(placeholder("Coming Soon", "#access-rights"), "Access Control", ".acl"),
      widget(div("#analytics"), "Evaluations", ".analytics"),
      widget([
        div("#questions-container"),
      ], "Sections", ".questions"),
      widget(div("#help"), null, ".help"),
    ],
    browserTitle,
    false,
    assessmentId ? "" : ".new-assessment",
  );

  const { header, modal, loading, notification } = views;
  loadingView = loading;
  modalView = modal;
  notificationView = notification;
  loadingView.show();

  const [courses] = await Promise.all([
    api.course.listCourses(EXPAND_LECTURES),
  ]);

  try {
    let assessment;
    let selectedCourse;
    let selectedCourseModule;
    let evaluationStatuses = [];
    let users = [];
    let courseGrants = [];
    if (assessmentId) {
      assessment = await api.course.getAssessmentById(assessmentId, EXPAND_NONE);
      selectedCourse = courses.find((course) => course.id === assessment.courseId);
      selectedCourseModule = courses
        .find((course) => course.id === assessment.courseId)
        .modules
        .find((module) => module.id === assessment.moduleId);
      [users, evaluationStatuses, courseGrants] = await Promise.all([
        api.user.list(),
        getAssessmentStatusesByCourseIdAndAssessmentId(
          selectedCourse.id,
          assessmentId,
        ),
        api.acl.listCourseGrants(selectedCourse).catch(() => []),
      ]);
    }

    const state = {
      ...defaultProfileState,
      user: cache.getProfile(),
      users,
      assessmentId,
      assessment: assessment || defaultProfileState.assessment,
      courses,
      selectedCourse,
      selectedCourseModule,
      evaluationStatuses: getExtendedAnalyticsEvaluations({
        courseGrants,
        users,
        analyticsEvaluations: evaluationStatuses,
        assessments: [assessment],
      }),
    };

    const pageTitle = state.assessment.title ? state.assessment.title : "Add Assessment";

    actionsView = view.create(showActionButtons)("#actions", state);
    analyticsView = view.create(showAnalytics)("#analytics", state);
    questionsView = view.create(showQuestions)("#questions-container", state);
    view.create(helpSection)("#help", state);

    assessmentProfileView = formManagedView(showAssessmentProfileForm, doUpdate)(
      "#assessment-profile-form",
      state,
    );
    actionsView.update({});
    header.update({ ...state, title: pageTitle });
    loadingView.hide();
  } catch (e) {
    log.error(e);
    switch (e.statusCode) {
      case 404:
      case 403:
        await modalView.alert("The assessment you requested no longer exists, or you do not have access to it.");
        break;
      default:
        await modalView.alert("Unable to retrieve necessary metadata at this time. Please try again in a few moments.");
    }
    window.location.replace("/admin/manage-assessments");
  }
}
