/**
 * A menu editor. Displayed as a list of menu items cameos. You are
 * able to add/edit/delete those. A callback with updated menu
 * is called when any actions performed on the menu items
 *
 * @module ui/component/menu-editor
 * @category UI
 * @subcategory Components - Menu
 */
import form from "ui/component/form-managed";
import sortable from "ui/component/sortable";
import { clone, frozen, merge } from "util/object";
import { div } from "ui/html";
import menuEntryCameo from "ui/component/cameo-menu-entry";
import { CURRENT_MENU_VERSION, menuEntryTypes } from "model/site/menu/constants";
import button from "ui/component/form-managed/button";
import menuEntryEditModal from "ui/component/modal/menu-entry-edit";

const defaultMenu = frozen({
  version: CURRENT_MENU_VERSION,
  entries: [],
});

const defaultState = {
  menu: defaultMenu,
  firstBreadcrumbTitle: "Page",
  pages: [],
  onMenuUpdate: () => {},
};

const onSortEntries = (state) => (newSortOrder) => {
  const entries = [];
  const oldEntries = state.menu.entries;
  newSortOrder.forEach((i) => entries.push(oldEntries[i]));
  const updatedMenu = {
    ...state.menu,
    entries,
  };
  state.onMenuUpdate(updatedMenu);
};

const onAddEntry = async (state, modalView, notificationView) => {
  const entry = await modalView.async(
    menuEntryEditModal({
      breadcrumbs: [state.firstBreadcrumbTitle],
      pages: state.pages,
    }, modalView, notificationView),
    false,
  );

  if (!entry) return;
  const { menu } = state;
  const updatedMenu = {
    ...menu,
    entries: [...menu.entries, entry],
  };
  state.onMenuUpdate(updatedMenu);
};

const onEditEntry = (state, modalView, notificationView) => async (entry, index) => {
  if (!entry) return;

  const result = await modalView.async(menuEntryEditModal({
    breadcrumbs: [state.firstBreadcrumbTitle],
    entry,
    pages: state.pages,
  }, modalView, notificationView),
  false);

  if (!result) return;

  const entries = state.menu.entries.map(clone);
  entries[index] = result;

  const updateMenu = {
    ...state.menu,
    entries,
  };
  state.onMenuUpdate(updateMenu);
};

const onDeleteEntry = (state, modalView) => async (_entry, index) => {
  const entries = state.menu.entries.map(clone);
  const confirmText = `Are you sure you want to delete ${entries[index].label} menu item? It is irreversible after saving.
    ${entries[index].type === menuEntryTypes.SUB_MENU ? " All submenu items will also be deleted." : ""}`;
  if (
    !(await modalView.confirm(confirmText))
  ) {
    return;
  }
  entries.splice(index, 1);
  const updatedMenu = {
    ...state.menu,
    entries,
  };
  state.onMenuUpdate(updatedMenu);
};

export default function menuEditor(inState = {}, modalView = null, notificationView = null) {
  const state = merge(defaultState, inState);

  return form(
    "#menu-editor-form",
    [
      sortable(
        {
          childSelector: "span.menu-entry-edit-bar.cameo",
          onSort: onSortEntries(state),
        },
        div(".menu-entries", state.menu.entries.map(
          (entry, id) => menuEntryCameo(
            entry,
            id,
            onEditEntry(state, modalView, notificationView),
            onDeleteEntry(state, modalView),
          ),
        )),
      ),
      button.standIn({
        label: "Add Entry",
        onClick: () => onAddEntry(state, modalView, notificationView),
      }),
    ],
  );
}
