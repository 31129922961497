/**
 * Metadata models.
 *
 * @module model/metadata
 * @category Model
 * @subcategory Metadata
 */
import { responseToFileDescriptor } from "model/file-descriptor";
import {
  required,
  validArray,
  validDate,
  validInt,
  validSearchCriteria,
  validSortCriteria,
  validString,
} from "model/constraints";
import { maybeDate } from "util/date";
import { metadataTypes } from "./constants";
import * as url from "./url";
import { validLanguageCode, validVideoSortCriteria } from "./constraints";

const emptyFn = () => "";

/**
 * Category model
 *
 * @typedef Category
 * @property {string} name
 * @property {string} description
 */

/**
 * Converts category response to category object
 *
 * @param response
 * @param {string} response.name
 * @param {string} response.description
 * @returns Category
 */
export const responseToCategory = (response) => ({
  name: response.name,
  description: response.description,
});

/** URL BUILDERS */

/** SEARCH PARAMS */

/**
 * Params common to all metadata searches.
 *
 * @typedef SearchParams
 * @property {string[]} [terms]
 * @property {string} [termsCriteria] member of {@link searchCriteria}
 * @property {string[]} [categories]
 * @property {string} [categoriesCriteria] member of {@link searchCriteria}
 * @property {string[]} [sortCriteriaList] members of {@link sortCriteria}
 * @property {int} [maxItems] maximum number of results to display
 * @property {int} [firstItem] begin index for a search result (for pagination)
 */

/**
 * Handles validation & cleanup of search params common to all metadata endpoints.
 *
 * @function makeCommonSearchParams
 * @param {object} partial containing any or none of the properties of {@link SearchParams}
 * @return {SearchParams}
 */
export const makeCommonSearchParams = (partial) => {
  const out = { firstItem: 0 };
  if (partial.terms) out.terms = validArray(partial.terms, "SearchParams.terms", validString);
  if (partial.termsCriteria) out.termsCriteria = validSearchCriteria(partial.termsCriteria, "SearchParams.termsCriteria");
  if (partial.categoriesCriteria) out.categoriesCriteria = validSearchCriteria(partial.categoriesCriteria, "SearchParams.categoriesCriteria");
  if (partial.categories) out.categories = validArray(partial.categories, "SearchParams.categories", validString);
  else if (partial.labels) out.categories = validArray(partial.labels, "SearchParams.labels", validString);

  if (partial.sortCriteriaList) out.sortCriteriaList = validArray(partial.sortCriteriaList, "SearchParams.sortCriteriaList", validSortCriteria);
  if (partial.maxItems) out.maxItems = validInt(partial.maxItems, "SearchParams.maxItems");

  return out;
};

/**
 * Document search params include common params and language.
 *
 * @typedef DocumentSearchParams
 * @extends SearchParams
 * @property {string} [language] member of {@link languageCodes};
 */

/**
 * Produces a complete set of Document search params from a partial value.
 *
 * @function makeDocumentSearchParams
 * @param {object} partial containing any or no possible search params
 *
 * @return {DocumentSearchParams}
 */
export const makeDocumentSearchParams = (partial) => {
  const out = makeCommonSearchParams(partial);

  if (partial.language) out.language = validLanguageCode(partial.language, "DocumentSearchParams.language");

  return out;
};

/**
 * Playlist search params consist entirely of common params.
 *
 * This is just an alias for {@link makeCommonSearchParams}.  Implement this function if
 * additional parameters get added.
 *
 * @typedef PlaylistSearchParams
 * @extends SearchParams
 */
export const makePlaylistSearchParams = makeCommonSearchParams;

/**
 * Parameters relevant to video search.
 *
 * @typedef VideoSearchParams
 * @extends SearchParams
 * @property {string} [yearAfter] first publication year to include in results
 * @property {string} [yearBefore] last publication year to include in results
 * @property {string} [language] member of {@link languageCodes};
 * @property {string[]} [sortCriteriaList] members of {@link videoSortCritieria}
 */

/**
 * Videos support additional search parameters and sort criteria.
 *
 * This function extends {@link makeCommonSearchParams} to include the additional parameters.
 *
 * @function makeVideoSearchParams
 * @param {object} partial containing any or none of the properties of {@link VideoSearchParams}
 *
 * @return VideoSearchParams
 */
export const makeVideoSearchParams = (partial) => {
  // we'll need a copy of partial because we're going to mutate
  // `tmp` to prevent sortCriteriaList from being validated twice
  // shallow clone is safe here because we're only dropping the value
  const tmp = { ...partial };
  let out = { firstItem: 0 };
  if (tmp.sortCriteriaList) {
    out.sortCriteriaList = validArray(tmp.sortCriteriaList, "SearchParams.sortCriteriaList", validVideoSortCriteria);
    delete tmp.sortCriteriaList;
  }
  out = { ...out, ...makeCommonSearchParams(tmp) };

  if (tmp.yearAfter) out.yearAfter = validString(tmp.yearAfter, "VideoSearchParams.yearAfter");
  if (tmp.yearBefore) out.yearBefore = validString(tmp.yearBefore, "VideoSearchParams.yearBefore");
  if (tmp.language) out.language = validLanguageCode(tmp.language, "VideoSearchParams.language");

  return out;
};

/**
 * @typedef {(VideoMetadata|DocumentMetadata|PlaylistMetadata)} ListMetadata
 */

/**
 * A Document metadata entry.
 *
 * See also DocumentMetadataRespDto in backend API schema.
 *
 * @typedef DocumentMetadata
 * @property {string} id uuid
 * @property {FileDescriptor} posterFile poster file descriptor
 * @property {string} title
 * @property {string} description
 * @property {string[]} categories
 * @property {FileDescriptor} documentFile Document file descriptor
 * @property {string} language
 * @property {string} type one of {@link api/constants.metadataTypes}
 * @property {string} posterUrl url for poster image (static, token embedded)
 * @property {string} fileUrl url for Document file (static, token embedded)
 * @property {function} getPosterUrl url for poster image (dynamic, new token fetched on call)
 * @property {function} getFileUrl url for Document file (dynamic, new token fetched on call)
 * @property {Date} createdAt Document creation date
 * @property {?Date} startDate when scheduled content becomes available
 * @property {?Date} endDate when scheduled content becomes unavailable
 */

/**
 * Creates a document metadata item with default properties.
 *
 * Note that this will not pass validation in makeDocumentMetadataDTO due to
 * required fields being missing, unless it is supplied with those fields.
 *
 * @function makeDocumentMetadata
 * @param {?object} data partial data
 * @return {DocumentMetadata}
 */
export const makeDocumentMetadata = (data = {}) => ({
  id: data?.id || null,
  posterFile: data.posterFile || null,
  posterUrl: data.posterFile?.id ? url.getDocumentPosterURL(data.id) : null,
  getPosterUrl: data.posterFile?.id ? () => url.getDocumentPosterURL(data.id) : emptyFn,
  title: data.title || "",
  description: data.description || "",
  categories: data.categories?.map(responseToCategory) || [],
  fileUrl: data.id ? url.getDocumentFileURL(data.id) : null,
  getFileUrl: data.id ? () => url.getDocumentFileURL(data.id) : emptyFn,
  getFileUrlNoAuth: data.getFileUrlNoAuth || emptyFn,
  language: data.language || "eng",
  documentFile: data.documentFile || null,
  type: metadataTypes.DOCUMENT,
  createdAt: maybeDate(data.createdAt) || new Date(),
  startDate: maybeDate(data.startDateTime || data.startDate),
  endDate: maybeDate(data.endDateTime || data.endDate),
});

/**
 * Transforms a Document metadata response DTO to a standardized DocumentMetadata object.
 *
 * @function responseToDocumentMetadata
 * @param {object} data
 *
 * @return {(DocumentMetadata|null)}
 */
export const responseToDocumentMetadata = (data) => {
  const posterFile = responseToFileDescriptor(data.posterFile || {});
  const documentFile = responseToFileDescriptor(data.documentFile || {});
  const meta = {
    id: data.id,
    posterFile,
    posterUrl: url.getDocumentPosterURL(data.id),
    getPosterUrl: () => url.getDocumentPosterURL(data.id),
    title: data.title || "",
    description: data.description || "",
    categories: data.categories?.map(responseToCategory) || [],
    documentFile,
    fileUrl: url.getDocumentFileURL(data.id),
    getFileUrl: () => url.getDocumentFileURL(data.id),
    language: data.language,
    type: metadataTypes.DOCUMENT,
    createdAt: maybeDate(data.createdAt) || new Date(),
    startDate: maybeDate(data.startDateTime || data.startDate),
    endDate: maybeDate(data.endDateTime || data.endDate),
  };
  return meta;
};

/**
 * A Course metadata entry.
 *
 * @typedef CourseMetadata
 * @property {string} id uuid
 * @property {FileDescriptor} posterFile poster file descriptor
 * @property {string} posterUrl url for poster image
 * @property {string} title
 * @property {string} description
 * @property {string} type one of {@link metadataTypes}
 * @property {string} posterUrl url for poster image (static, token embedded)
 * @property {function} getPosterUrl url for poster image (dynamic, new token fetched on call)
 * @property {Date} createdAt Course creation date
 */

/**
 * Transforms a Course metadata response DTO to a standardized CourseMetadata object.
 *
 * @function responseToCourseMetadata
 * @param {object} data
 *
 * @return {(CourseMetadata|null)}
 */
export const responseToCourseMetadata = (data) => {
  const posterFile = responseToFileDescriptor(data.posterFile || {});
  return {
    id: data.id,
    posterFile,
    posterUrl: url.getImageFileURL(posterFile.id),
    getPosterUrl: () => url.getImageFileURL(posterFile.id),
    title: data.title || "",
    description: data.description || "",
    type: metadataTypes.COURSE,
    createdAt: maybeDate(data.createdAt) || new Date(),
  };
};

/**
 * A playlist metadata entry.
 *
 * See also MetadataListRespDto in backend API schema.
 *
 * @typedef PlaylistMetadata
 * @property {string} id uuid
 * @property {FileDescriptor} posterFile poster file descriptor
 * @property {string} posterUrl url for poster image (static, token embedded at load time)
 * @property {function} getPosterUrl url for poster image (dynamic, gets new token)
 * @property {string} title
 * @property {string} description
 * @property {string[]} categories
 * @property {ListMetadata[]} items
 * @property {string} type one of {@link api/constants.metadataTypes}
 * @property {Date} createdAt Document creation date
 * @property {?Date} startDate when scheduled content becomes available
 * @property {?Date} endDate when scheduled content becomes unavailable
 */

/**
 * Creates a playlist metadata item with default properties.
 *
 * Note that this will not pass validation in makePlaylistMetadataDTO due to
 * required fields being missing, unless it is supplied with those fields.
 *
 * @function makePlaylistMetadata
 * @param {?object} data partial data
 * @return {PlaylistMetadata}
 */
export const makePlaylistMetadata = (data = {}) => ({
  id: data?.id || null,
  posterFile: data.posterFile || null,
  posterUrl: data.posterFile?.id ? url.getListPosterURL(data.id) : null,
  getPosterUrl: data.posterFile?.id ? () => url.getListPosterURL(data.id) : emptyFn,
  title: data.title || "",
  description: data.description || "",
  categories: data.categories?.map(responseToCategory) || [],
  items: data.items instanceof Array ? data.items : [],
  type: metadataTypes.LIST,
  createdAt: maybeDate(data.createdAt) || new Date(),
  startDate: maybeDate(data.startDateTime || data.startDate),
  endDate: maybeDate(data.endDateTime || data.endDate),
});

/**
 * Transforms a playlist metadata response DTO to a standardized PlaylistMetadata object.
 *
 * Receives a playlist response and produces a PlaylistMetadata object.
 *
 * @function responseToPlaylistMetadata
 * @param {object} data
 *
 * @return {(PlaylistMetadata|null)}
 */
export const responseToPlaylistMetadata = (data) => {
  const posterFile = responseToFileDescriptor(data.posterFile || {});
  return {
    id: data.id,
    posterFile,
    posterUrl: url.getListPosterURL(data.id),
    getPosterUrl: () => url.getListPosterURL(data.id),
    title: data.title || "",
    description: data.description || "",
    categories: data.categories?.map(responseToCategory) || [],
    /* eslint-disable-next-line no-use-before-define */
    items: data?.items?.map(responseToListMetadata) || []
      // catch occasional backend bug where a content entry is null
      // or of unknown content type
      // (should not happen in production, but dev gets broken sometimes...)
      .filter((item) => item !== null),
    type: data.type,
    createdAt: maybeDate(data.createdAt) || new Date(),
    startDate: maybeDate(data.startDateTime || data.startDate),
    endDate: maybeDate(data.endDateTime || data.endDate),
  };
};

/**
 * A video metadata entry.
 *
 * See also VideoMetadataRespDto in backend API schema.
 *
 * @typedef VideoMetadata
 * @property {string} id uuid
 * @property {FileDescriptor} posterFile poster file descriptor
 * @property {string} posterUrl url for poster image
 * @property {string} title
 * @property {string} description
 * @property {string[]} categories
 * @property {FileDescriptor} videoFile video file descriptor
 * @property {string} fileUrl for video file
 * @property {string} language
 * @property {integer} year
 * @property {integer} width in pixels
 * @property {integer} height in pixels
 * @property {integer} length in seconds
 * @property {integer} viewCount
 * @property {integer} positionInSeconds
 * @property {string} type one of {@link api/constants.metadataTypes}
 * @property {function} getPosterUrl url for poster image (dynamic, gets new token)
 * @property {function} getFileUrl url for video file (dynamic, gets new token)
 * @property {Date} createdAt Document creation date
 * @property {?Date} startDate when scheduled content becomes available
 * @property {?Date} endDate when scheduled content becomes unavailable
 */

/**
 * Creates a video metadata item with default properties.
 *
 * Note that this will not pass validation in makeVideoMetadataDTO due to
 * required fields being missing, unless it is supplied with those fields.
 *
 * @function makeVideoMetadata
 * @param {?object} data partial data
 * @return {VideoMetadata}
 */
export const makeVideoMetadata = (data = {}) => ({
  id: data?.id || null,
  posterFile: data.posterFile || null,
  posterUrl: data.posterFile?.id ? url.getVideoPosterURL(data.id) : null,
  getPosterUrl: data.posterFile?.id ? () => url.getVideoPosterURL(data.id) : emptyFn,
  title: data.title || "",
  description: data.description || "",
  categories: data.categories?.map(responseToCategory) || [],
  fileUrl: data.fileUrl || null,
  getFileUrl: data.getFileUrl || emptyFn,
  getFileUrlNoAuth: data.getFileUrlNoAuth || emptyFn,
  language: data.language || "eng",
  year: data.year || 0,
  width: data.width || 0,
  height: data.height || 0,
  length: data.length || 0,
  videoFile: data.videoFile || null,
  viewCount: data.viewCount || 0,
  positionInSeconds: data.positionInSeconds || 0,
  type: metadataTypes.VIDEO,
  createdAt: maybeDate(data.createdAt) || new Date(),
  startDate: maybeDate(data.startDateTime || data.startDate),
  endDate: maybeDate(data.endDateTime || data.endDate),
});

/**
 * Translates a response from a playlist metadata endpoint to a {@link PlaylistMetadata} object.
 *
 * @function responseToVideoMetadata
 * @param {object} data
 *
 * @return {(PlaylistMetadata|null)}
 */
export const responseToVideoMetadata = (data) => {
  const posterFile = responseToFileDescriptor(data.posterFile || {});
  const videoFile = responseToFileDescriptor(data.videoFile || {});
  return {
    id: data.id,
    posterFile,
    posterUrl: url.getVideoPosterURL(data.id),
    getPosterUrl: () => url.getVideoPosterURL(data.id),
    title: data.title,
    description: data.description || "",
    categories: data.categories?.map(responseToCategory) || [],
    fileUrl: url.getVideoFileURL(data.id),
    getFileUrl: () => url.getVideoFileURL(data.id),
    getFileUrlNoAuth: () => url.getVideoFileURLNoAuth(data.id),
    language: data.language,
    year: data.year || 0,
    width: data.width || 0,
    height: data.height || 0,
    length: data.length || 0,
    videoFile,
    viewCount: data.viewCount || 0,
    positionInSeconds: data.positionInSeconds || 0,
    type: metadataTypes.VIDEO,
    createdAt: maybeDate(data.createdAt) || new Date(),
    startDate: maybeDate(data.startDateTime || data.startDate),
    endDate: maybeDate(data.endDateTime || data.endDate),
  };
};

/**
 * Converts a playlist item entry in a response to the appropriate type of ListMetadata.
 *
 * Expects `data` to be some kind of playlist entry response object.
 *
 * @function responseToListMetadata
 * @param {object} data
 *
 * @return {ListMetadata}
 */
export const responseToListMetadata = (data) => {
  switch (data.type) {
    case metadataTypes.DOCUMENT:
      return responseToDocumentMetadata(data);
    case metadataTypes.VIDEO:
      return responseToVideoMetadata(data);
    case metadataTypes.DYNAMIC_LIST:
    case metadataTypes.LIST:
      return responseToPlaylistMetadata(data);
    default:
      return null;
  }
};

/**
 * Video playback position.
 *
 * @typedef VideoPlaybackPosition
 * @property {UUID} id video id
 * @property {int} position position in seconds
 */
export const makePlaybackPosition = (partial) => ({
  id: partial.id || null,
  position: partial.position || 0,
});

export const responseToPlaybackPosition = (data) => ({
  id: data.videoMetadataId || null,
  position: data.positionInSeconds || 0,
});

/** REQUEST BODIES */

/**
 * Category DTO
 *
 * @typedef CategoryDTO
 * @property {string} category.name
 * @property {string} category.description
 */

/**
 * Make category DTO (temporary)
 *
 * @param {Category} category
 * @returns {string}
 */
export const makeCategoryDTO = (category) => category.name;

/**
 * Properties common to all metadata DTO types.
 *
 * Note that posterFileId and posterFile should not be used at the same time. If both
 * are present, posterFileId takes precedence.
 *
 * @typedef CommonMetadataDTO
 * @property {string} title
 * @property {string} [description]
 * @property {string[]} [categories]
 * @property {?Date} [startDate]
 * @property {?Date} [endDate]
 * @property {string} [posterFileId] the poster file descriptor id
 * @property {module:api/types/file~FileDescriptor} [posterFile]
 */

/**
 * Shared validation for common fields in metadata DTOs.
 *
 * Note that this does *not* check for or attach the properties unique to each metadata
 * type.
 *
 * @function makeCommonMetadataDTO
 * @param {object} partial containing at least a title, optionally other metadata fields
 */
export const makeCommonMetadataDTO = (partial, type = "CommonMetadataDTO") => {
  // only title is required
  const out = {
    title: required(partial.title, `${type}.title`, validString),
    categories: partial.categories?.map(makeCategoryDTO) || [],
    startDateTime: partial.startDate
      ? validDate(partial.startDate, `${type}.startDate`)
      : null,
    endDateTime: partial.endDate
      ? validDate(partial.endDate, `${type}.endDate`)
      : null,
  };

  // optional fields should be omitted if not present or valid in the partial
  if (partial.description) {
    out.description = validString(partial.description, `${type}.description`);
  }
  if (partial.posterFileId) {
    out.posterFileId = validString(partial.posterFileId, `${type}.posterFileId`);
  } else if (partial?.posterFile?.fileId) {
    out.posterFileId = validString(partial.posterFile.fileId, `${type}.posterFile.fileId`);
  }

  return out;
};

/**
 * A Document metadata create/update DTO. See also DocumentItemCreationReqDto and
 * DocumentItemUpdateReqDto in the backend API specs.
 *
 * Note that documentFileId and documentFile should not be mixed. If both are
 * present, documentFileId takes precedence over the FileDescriptor.
 *
 * @typedef DocumentMetadataDTO
 * @extends CommonMetadataDTO
 * @property {string} [language]
 * @property {string} [documentFileId] the document file descriptor id
 * @property {module:api/types/file~FileDescriptor} [documentFile]
 */

/**
 * Validates and sanitizes video metadata for use in an api call.
 *
 * @function makeDocumentMetadataDTO
 * @param {object} partial matching the DocumentMetadataDTO
 *
 * @return {DocumentMetadataDTO}
 */
export const makeDocumentMetadataDTO = (partial) => {
  const type = "DocumentMetadataDTO";
  const out = makeCommonMetadataDTO(partial, type);
  if (partial.language) {
    out.language = validLanguageCode(partial.language, `${type}.language`);
  }
  if (partial.documentFileId) {
    out.documentId = validString(partial.documentFileId, `${type}.documentFileId`);
  } else if (partial?.documentFile?.fileId) {
    out.documentId = validString(partial.documentFile.fileId, `${type}.documentFile.fileId`);
  }
  return out;
};

/**
 * A playlist metadata request DTO.
 *
 * Used both for creating & updating playlist items.
 *
 *
 * See also ItemListMetadataCreationReqDto and ItemListMetadataUpdateReqDto in backend
 * API schema.
 * @typedef PlaylistMetadataDTO
 * @extends CommonMetadataDTO
 * @property {string[]} [itemIds]
 */

/**
 * Validates and sanitizes playlist metadata for use in an api call.
 *
 * @function makePlaylistMetadataDTO
 * @param {object} partial matching VideoMetadataDTO
 * @return VideoMetadataDTO
 */
export const makePlaylistMetadataDTO = (partial) => {
  const type = "DocumentMetadataDTO";
  const out = makeCommonMetadataDTO(partial, type);
  if (partial.itemIds) out.itemIds = validArray(partial.itemIds, `${type}.itemIds`, validString);
  return out;
};

/**
 * A video metadata request DTO.
 *
 * Used both for creating & updating video items.
 *
 * Note that videoFileId and videoFile should not be mixed. If both are present,
 * videoFileId takes precedence over the FileDescriptor.
 *
 * See also VideoMetadataCreationReqDto and VideoMetadataUpdateReqDto in backend API schema.
 *
 * @typedef VideoMetadataDTO
 * @extends CommonMetadataDTO
 * @property {string} [videoFileId] the video file descriptor id
 * @property {module:api/types/file~FileDescriptor} [videoFile]
 * @property {string} language
 * @property {integer} [year]
 * @property {integer} [width]
 * @property {integer} [height]
 * @property {integer} [length]
 */

/**
 * Validates and sanitizes video metadata for use in an api call.
 *
 * @function makeVideoMetadataDTO
 * @param {object} partial matching VideoMetadataDTO
 * @return VideoMetadataDTO
 */
export const makeVideoMetadataDTO = (partial) => {
  const type = "VideoMetadataDTO";
  const out = Object.assign(
    makeCommonMetadataDTO(partial, type),
    // enforce required fields here
    {
      language: required(partial.language, `${type}.language`, validLanguageCode),
    },
  );

  // optional fields should be omitted if not present or valid in the partial
  if (partial.videoFileId) {
    out.videoFileId = validString(partial.videoFileId, `${type}.videoFileId`);
  } else if (partial?.videoFile?.fileId) {
    out.videoFileId = validString(partial.videoFile.fileId, `${type}.videoFile.fileId`);
  }
  if (partial.year) out.year = validInt(partial.year, `${type}.poster`);
  if (partial.width) out.width = validInt(partial.width, `${type}.width`);
  if (partial.height) out.height = validInt(partial.height, `${type}.height`);
  if (partial.length) out.length = validInt(partial.length, `${type}.length`);
  return out;
};

/**
 * Type for getting & setting video playback positions.
 *
 * @typedef VideoPlaybackDTO
 * @property {int} positionInSeconds
 */

/**
 * Create a video playback DTO from a playback position value.
 *
 * @function makeVideoPlaybackDTO
 * @param {number} position
 *
 * @return VideoPlaybackDTO
 */
export const makeVideoPlaybackDTO = (position) => ({
  positionInSeconds: validInt(position, "VideoPlaybackDTO.positionInSeconds"),
});

/**
 * @typedef {VideoMetadata|PlaylistMetadata|DocumentMetadata} Metadata
 */
