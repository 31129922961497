/**
 * Views encapsulate a top-level virtual node and an internal state object.
 *
 * See the explanation in the [UI module]{@link module:ui}.
 *
 * - [Generic View]{@link module:ui/view/view}
 * - [Modal Dialog]{@link module:ui/view/modal-view}
 * - [Managed Form]{@link module:ui/view/form-managed}
 * - [Loading Overlay]{@link module:ui/view/loading-view}
 * - [Header View]{@link module:ui/view/header-view}
 * - [Chart View]{@link module:ui/view/chart}
 *
 * @module ui/view
 * @category UI
 * @subcategory Views
 */
import view from "./view";
import modal from "./modal-view";
import header from "./header-view";
import heroSlider from "./hero-slider";
import loadingView from "./loading-view";
import notificationView from "./notification-view";
import chartView from "./chart";

view.modal = modal;
view.header = header;
view.heroSlider = heroSlider;
view.loading = loadingView;
view.notification = notificationView;
view.chart = chartView;

export default view;
