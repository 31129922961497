/**
 * A common functions for course modals
 *
 * @module ui/component/modal/course/common
 * @category UI
 * @subcategory Modal Dialogs
 */
import { a, div, p } from "ui/html";
import { shortDescription, splitParagraphs } from "util/format";
import icon from "ui/component/icon";

export const parseDescription = (text, onExpand) => [
  ...(
    (text && text.length >= 180)
      ? [
        div(".excerpt", [
          p(shortDescription(text || "", 180, false).trim()),
        ]),
        a.fn("More", () => {
          onExpand(true);
        }, ".more"),
        div(".full", [
          ...splitParagraphs(text).map((chunk) => p(chunk)),
        ]),
        a.fn("Less", () => {
          onExpand(false);
        }, ".less"),
      ]
      : splitParagraphs(text).map((chunk) => p(chunk))
  ),
];

export const poster = () => {
  const post = div(
    ".poster.course-preview",
    {
      hook: {
        /* eslint-disable-next-line no-param-reassign */
        insert: (node) => { node.elm.dataset.imageLoaded = "not-loaded"; },
      },
    },
  );
  post.children = [
    icon.regular("book"),
  ];
  return post;
};
