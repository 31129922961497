/**
 * Builds course module carousels for a dynamic page.
 *
 * @module ui/page/dynamic/section-course-carousel
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import {
  div,
  section,
} from "ui/html";
import { isEmptyModule, isSingleLecture } from "model/course";
import cardCarousel from "ui/component/card-carousel";
import { moduleItemCard } from "ui/component/card/module-item";
import singleLectureModuleCard from "ui/component/card/single-lecture-module";
import { getAttendance, showSingleLecture, shouldSkipModule } from "./common";

const makeSectionId = (pageSection, moduleId) => `course-carousel-${pageSection.id}:${moduleId}`;

const makeModuleCarouselSection = (
  pageSection,
  state,
) => (moduleId) => {
  const module = state.courseModules.get(moduleId);
  if (!module) {
    return section(
      `#course-carousel-${moduleId}.course-carousel`,
      { key: `course-carousel-${moduleId}` },
      cardCarousel.placeholder(`#course-carousel-${moduleId}.course-carousel`, makeSectionId(pageSection, moduleId)),
    );
  }
  if (shouldSkipModule(module)) return "";

  const attendance = getAttendance(state, module.courseId);

  const assessments = module.assessmentIds.map(
    (id) => state.assessments.get(id) || { PLACEHOLDER: true },
  );

  const lectures = module.lectureIds.map(
    (id) => state.courseLectures.get(id) || { PLACEHOLDER: true },
  );

  if (showSingleLecture(module, lectures)) {
    return singleLectureModuleCard(state, lectures[0], attendance);
  }

  const title = isSingleLecture(module) ? lectures[0].title : module.title;
  const items = isEmptyModule(lectures) ? [...assessments] : [...lectures, ...assessments];
  const position = state.positions.get(makeSectionId(pageSection, moduleId)) || 0;

  return section(
    `.course-carousel${attendance ? ".enabled" : ".disabled"}`,
    { key: module.id },
    [
      cardCarousel({
        list: {
          id: makeSectionId(pageSection, moduleId),
          type: pageSection.type,
          items,
          title,
          text: module.description,
          playback: state.playback,
        },
        position,
        cardFactory: moduleItemCard(state),
      }),
    ],
  );
};

const checkReady = (course, state) => (
  !course
  || course.PLACEHOLDER
  || course.moduleIds?.reduce((acc, id) => acc && state.courseModules.has(id), true)
);

const buildPlaceholder = (id) => div(".course-carousels", [
  div(`#course-carousels-${id}.course-carousel`, div(".content-card-carousel", [
    cardCarousel.placeholder(),
  ])),
]);

export default function sectionCourseCarousel(state, id) {
  const pageSection = state.sections.get(id);
  if (!pageSection || pageSection.PLACEHOLDER) return buildPlaceholder();
  const course = state.courses?.get(pageSection.itemId);
  if (!checkReady(course, state)) return buildPlaceholder(id);

  return div(
    `#course-carousels-${id}.course-carousels`,
    { key: `course-carousels-${id}` },
    course.moduleIds.map(
      makeModuleCarouselSection(pageSection, state),
    ),
  );
}
