/**
 * A footer element.
 *
 * @module ui/html/footer
 * @category UI
 * @subcategory Elements
 */
import el from 'ui/common/el';

/**
 * Create a footer element.
 *
 * @function footer
 * @param {module:lib/ui/html~ChildEl} child element(s)
 * @param {module:lib/ui/html~Selector} sel css selector
 * @return {module:lib/ui/common/el~El}
 */
export default (children, sel = "") => el(`footer${sel}`, children);
