/**
 * Lecture data stream.
 *
 * Provides `getBatch` interface for fetching assessments by
 * `[courseId, moduleId, lectureIds]`.
 *
 * @module data/course/lecture
 * @category Data Streams
 * @subcategory Course
 */
import xs from "xstream";
import { byIds } from "api/v2/course/lecture";
import { CACHE_COURSE_LECTURE_KEY_V2 as CACHE_KEY } from "cache/constants";
import { makeLecture } from "model/lecture";
import documentStream from "data/metadata/document";
import videoStream from "data/metadata/video";
import batch from "data/pipeline/batch";
import { streamLog, sumStream } from "data/stream/compose";
import hashmap from "util/hash-map";

const itemMap = hashmap();

const all$ = xs.create();

const byIdsFlat = (args) => byIds(...args);

const defaultFn = ([courseId, moduleId]) => (id) => makeLecture(
  { id, courseId, moduleId },
);

const {
  post: getBatch,
  sink$: getBatchSink$,
  pending$: batchPending$,
} = batch({
  apiFn: byIdsFlat,
  defaultFn,
  cacheKey: CACHE_KEY,
  accumulator: itemMap,
  itemSink$: all$,
});

const pending$ = sumStream(
  batchPending$,
  videoStream.pending$,
  documentStream.pending$,
);

const out$ = xs.merge(getBatchSink$);

all$.imitate(out$);
all$.compose(streamLog("LECTURE_ALL$"));

/**
 * Pushes all lecture metadata items into the respective metadata streams, returning
 * document and video `all$`.
 *
 * Useful for ensuring the availability of lecture metadata for any lectures fetched
 * by getLectures().
 *
 * @example
 * const courseA$ = courseStream.get(courseIdA);
 * const courseB$ = courseStream.get(courseIdB);
 * const module$ = courseStream.getModules();
 * // map of all modules in courseA and courseB
 * const lecture$ = courseStream.getLectures();
 * // map of all lectures in courseA and courseB
 * const { document$, video$ } = courseStream.getLectureMetadata();
 * // maps of all metadatas in both courses' lectures
 *
 * @function getMetadata
 * @returns {Stream} `{
 *   document$: Stream.<Map.<UUID, DocumentMetadata>>,
 *   video$: Stream.<Map.<UUID, VideoMetadata>>
 * }`
 */
const getMetadata = () => {
  let seen = new Set();
  all$.addListener({
    next: (lectures) => {
      const videoIds = new Set();
      const documentIds = new Set();
      [...lectures.values()].forEach((lecture) => {
        if (lecture.videoId && !seen.has(lecture.videoId)) {
          videoIds.add(lecture.videoId);
        }
        if (lecture.slidesId && !seen.has(lecture.slidesId)) {
          documentIds.add(lecture.slidesId);
        }
        if (lecture.additionalMaterialIds?.length) {
          lecture.additionalMaterialIds.forEach((matId) => {
            if (!seen.has(matId)) documentIds.add(matId);
          });
        }
      });
      if (documentIds.size) documentStream.getMany([...documentIds]);
      if (videoIds.size) videoStream.getMany([...videoIds]);
      seen = new Set([...seen, ...documentIds, ...videoIds]);
    },
  });

  return {
    document$: documentStream.all$,
    video$: videoStream.all$,
  };
};

export default {
  get: (...args) => getBatch([args[0], args[1], [args[2]]])
    .map((lectures) => lectures.get(args[2])),
  getBatch,
  getMetadata,
  all$,
  pending$,
};
