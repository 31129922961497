import { endpoints } from "api/constants";
import {
  responseToEvaluationTime,
} from "model/course";
import {
  responseToEvaluation,
  makeEvaluationDTO,
} from "model/course/evaluation";
import { get, put } from "api/request";

/**
 * Get a time left for the evaluation
 *
 * @function getTimeLeftForEvaluation
 * @param {string} courseId
 * @param {string} evaluationId
 * @returns {Promise<EvaluationTime>}
 */
export const getTimeLeftForEvaluation = async (courseId, evaluationId) => {
  const response = (await get(`${endpoints.COURSE(courseId)}/eval/${evaluationId}/time`)).body;
  return responseToEvaluationTime(response);
};

/**
 * Get evaluation by id
 *
 * @function getEvaluationById
 * @param {string} courseId
 * @param {string} evaluationId
 * @param {?object} [expand]
 * @returns {Promise<Evaluation>}
 */
export const byCourseIdEvaluationId = async (
  courseId,
  evaluationId,
) => {
  const res = responseToEvaluation((
    await get(`${endpoints.COURSE(courseId)}/eval/${evaluationId}`)
  ).body);
  return res;
};

/**
 * Update evaluation
 *
 * @function updateEvaluation
 * @param {string} courseId
 * @param {Partial<Evaluation>} partial
 * @returns {Promise<Evaluation>}
 */
export const updateEvaluation = async (partial) => {
  const assessmentResponse = (await put(
    `${endpoints.COURSE(partial.courseId)}/assessment/${partial.assessmentId}/eval/${partial.id}`,
    null,
    makeEvaluationDTO(partial),
  )).body;
  return responseToEvaluation(assessmentResponse);
};
