import { isEmptyModule, isSingleLecture } from "model/course";
import { metadataTypes } from "model/metadata/constants";
import { metadataScheduleFilter } from "util/filter";
import { merge } from "util/object";

export const bootstrapAssessment = (evaluations) => (assessment) => merge(
  assessment,
  { evaluation: evaluations.get(assessment.id) },
);

export const showSingleLecture = (module, lectures) => {
  if (isEmptyModule(lectures)) return false;
  return isSingleLecture(module) && module.assessmentIds?.length === 0;
};

export const shouldSkipModule = (module) => !(
  module?.lectureIds?.length || module?.assessmentIds?.length
);

export const getAttendance = (state, courseId) => {
  const userId = state.user?.id;
  if (!userId || !courseId) return null;
  return [...state.attendance.values()]
    .find((att) => (
      att
      && att.courseId === courseId
      && att.studentId === userId
      && !att.PLACEHOLDER
    ));
};

// upgrade a metadata item from playlist or search if we have the full item
// in state
export const upgradeMetadataItem = (state) => (item) => {
  switch (item?.type) {
    case metadataTypes.VIDEO:
      if (state?.videos?.has(item?.id)) return state.videos.get(item.id);
      return item;
    case metadataTypes.DOCUMENT:
      if (state?.documents?.has(item?.id)) return state.documents.get(item.id);
      return item;
    case metadataTypes.LIST:
      if (state?.playlists?.has(item?.id)) return state.playlists.get(item.id);
      return item;
    case metadataTypes.COURSE:
      if (state?.courses?.has(item?.id)) {
        const course = state.courses.get(item.id);
        return {
          ...item,
          ...course,
          modules: course.moduleIds
            .map((id) => state?.courseModules?.get(id))
            .map((module) => ({
              ...module,
              lectures: module?.lectureIds
                ?.map((lectureId) => state?.courseLectures?.get(lectureId)),
            })),
        };
      }
      return item;
    default:
      return item;
  }
};

// filter hashmap of metadata items, removing unavailable items
export const scheduleFilter = (entries) => entries.filterValues(metadataScheduleFilter());

// filter hashmap of playlist items, removing unavailable members
export const playlistItemFilterMap = (entries) => entries.filterMap(
  (pair) => metadataScheduleFilter()(pair[1]),
  ([k, v]) => ([
    k,
    { ...v, items: v.items?.filter(metadataScheduleFilter()) },
  ]),
);

// filter hashmap of search results, removing unavailable results
export const searchResultFilterMap = (results) => results.map(([k, v]) => ([
  k,
  { ...v, items: v.items.filter(metadataScheduleFilter()) },
]));
