/**
 * A grid of content-cards.
 *
 * @module ui/component/card-grid
 * @category UI
 * @subcategory Components
 */
import {
  div,
  section,
} from "ui/html";
import card from "ui/component/card/content";
import placeholderCard from "ui/component/card/placeholder";
import { cardFormatOptions } from "model/dynamic-page/constants";
import { metadataTypes } from "model/metadata/constants";
import courseItemCard from "ui/component/card/course";

/**
 * @function cardGrid
 * @param {object} state
 * @param {module:api/types~ListMetadata[]} state.list list of metadata items to show
 * @param {function} [state.cardFactory=ContentCard] which factory to use for cards
 * @param {cardFormatOptions} [state.cardSize] card size option
 * @return {module:ui/html/main}
 */
export default function cardGrid({
  items,
  playback,
  cardFactory = card,
  cardSize = cardFormatOptions.VERTICAL,
}) {
  return section(
    ".content-card-grid",
    div(".cards", items.map((metadata) => {
      if (metadata.type === metadataTypes.COURSE) {
        return courseItemCard({ metadata, cardSize });
      }
      return cardFactory({
        metadata,
        playbackPosition: playback?.get?.(metadata.id),
        cardSize,
      });
    })),
  );
}

cardGrid.placeholder = () => section(
  `.content-card-grid.temporary`,
  div(".cards", [
    placeholderCard(),
    placeholderCard(),
    placeholderCard(),
    placeholderCard(),
  ]),
);
