/**
 * A searchable list of messages.
 *
 * @module ui/component/modal/messaging/message-list
 * @component UI
 * @subcomponent Modal Dialogs
 */
import showMessage from "ui/component/messaging/message";
import listModal from "ui/component/modal/layout/list";
import { anyTextFilter } from "util/filter";
import { merge, frozen } from "util/object";

const defaultState = frozen({
  messages: [],
  users: [],
  groups: [],
  descriptors: new Map(),
  searchText: "",
});

const doSearch = (state) => (searchText, modalView) => {
  /* eslint-disable-next-line no-use-before-define */
  modalView.patch(messageListModal({
    ...state,
    searchText,
  }, modalView));
};

/**
 * A modal for displaying a list of messages.
 *
 * @function messageListModal
 * @param {Object} state
 * @param {string[]} uploads media dump file name list
 * @param {FileDescriptor[]} descriptors file descriptor object list
 * @param {string} search prepopulated search text
 */
export default function messageListModal(inState, modalView = null) {
  const state = merge(defaultState, inState);

  return listModal({
    entries: state.messages.filter(anyTextFilter(state.searchText))
      .map((message) => showMessage({
        ...state,
        message,
        messages: undefined,
        searchText: undefined,
        onClick: state.onClick ? () => state.onClick(message) : undefined,
      })),
    sel: ".message-list",
    searchText: state.searchText,
    onSearch: doSearch(state),
  }, modalView);
}
