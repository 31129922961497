/**
 * Manage the site's menu.
 *
 * @module ui/page/admin/menu
 * @category Pages
 * @subcategory Admin - Site Customization
 */
import api from "api";
import cacheStream from "data/cache";
import getConfig from "config";
import log from "log";
import { button, div } from "ui/html";
import form from "ui/component/form-managed";
import toggle from "ui/component/form-managed/field-toggle";
import formManagedView from "ui/view/form-managed";
import editor from "ui/component/menu-editor";
import icon from "ui/component/icon";
import widget from "ui/component/dashboard-widget";
import actionBar from "ui/component/dashboard-action-bar";
import { isHelpModalSeen, markModalAsSeen, modalTypes } from "ui/component/modal/help/utils";
import { CURRENT_MENU_VERSION } from "model/site/constants";
import view from "ui/view";
import { frozen } from "util/object";
import { tidyBackendError } from "util/error";
import adminEditMenuHelpModal from "ui/component/modal/help/edit-menu";
import dashboardLayout from "ui/page/layout/dashboard";
import { notificationMessageTypes } from "model/notification/constants";
import { siteGeneral } from "ui/component/header/static-menus";
import staticMessages from "./messages";

let modalView, loadingView, headerView, editMenuView, configurationView, notificationView;

const defaultMenu = frozen({
  version: CURRENT_MENU_VERSION,
  entries: [],
});

const defaultState = frozen({
  config: {},
  user: {},
  menu: defaultMenu,
  messages: [],
  pages: [],
});

const doSave = async (self) => {
  const { menu } = self.state;
  if (
    menu.entries.length === 0
    && !(await modalView.confirm("You haven't added any menu entries. Are you sure you want to save?"))
  ) return;
  try {
    loadingView.show();
    const response = await api.site.saveMenu(self.state.menu);

    await api.site.saveUIConfiguration({
      horizontalMenu: configurationView.state.uiConfiguration.horizontalMenu,
    });

    notificationView.post(staticMessages.ok);

    self.update({
      menu: response,
    });

    cacheStream.clearMenu();

    loadingView.hide();
  } catch (err) {
    log.error(err);
    if (err.statusCode) {
      switch (err.statusCode) {
        case 403:
          notificationView.post(staticMessages.accessDenied);
          break;
        case 400:
        default:
          notificationView.post(staticMessages.backendErrors);
          tidyBackendError(err.body).forEach((text) => notificationView.post({
            title: "Error",
            text,
            type: notificationMessageTypes.FAIL,
          }));
          break;
      }
    } else {
      notificationView.post({
        title: "Error",
        text: err.message,
        type: notificationMessageTypes.FAIL,
      });
    }
    loadingView.hide();
  }
};

const showMenuItemsAmountToFitInHorizontalMenu = (self) => {
  try {
    const menuItemsAmount = self.state.menu.entries.length
      + siteGeneral.entries.length;
    const MAX_CHARS_HD = 97;
    const MAX_CHARS_SMALL = 53;
    let hdScreenItemsShown = 0;
    let smallScreenItemsShown = 0;
    let charsProcessed = 0;
    self.state.menu.entries.forEach((entry) => {
      if (charsProcessed <= MAX_CHARS_HD) {
        hdScreenItemsShown++;
      }
      if (charsProcessed <= MAX_CHARS_SMALL) {
        smallScreenItemsShown++;
      }
      charsProcessed += entry.label.length;
    });
    const hiddenHdItemsAmount = menuItemsAmount - hdScreenItemsShown;
    const hiddenSmallItemsAmount = menuItemsAmount - smallScreenItemsShown;
    return div(".hidden-items-tip", [
      hiddenHdItemsAmount
        ? div("", `On the Full HD (> 1920px) ~${hiddenHdItemsAmount} will be in the dropdown`)
        : "",
      hiddenSmallItemsAmount
        ? div("", `On the small screen (> 768px) ~${hiddenSmallItemsAmount} will be in the dropdown`)
        : "",
    ]);
  } catch (e) {
    return "";
  }
};

const showMenuEditor = (self) => div("", [
  editor({
    menu: self.state.menu,
    firstBreadcrumbTitle: "Main Menu",
    pages: self.state.pages,
    onMenuUpdate: (menu) => {
      self.update({ menu });
    },
  }, modalView, notificationView),
  showMenuItemsAmountToFitInHorizontalMenu(self),
]);

const showActions = () => div(
  "#actions",
  button([icon("save"), "Save"], () => doSave(editMenuView), ".primary"),
);

const showConfigurationsView = (page) => {
  const { state } = page;
  return form(
    "#configurations-form",
    [
      toggle.boxed.inverse({
        toggled: state.uiConfiguration.horizontalMenu,
        name: "horizontalMenu",
        label: "Horizontal Menu Mode",
        onToggle: (toggled) => {
          configurationView.updateState({
            uiConfiguration: {
              horizontalMenu: toggled,
            },
          });
        },
      }),
    ],
  );
};

/**
 * The menu editor page allows a user to customize entries in the navigation
 * menu.
 *
 * @function menuEditor
 * @param {module:ui/common/html~Selector} selector
 */
export default async function menuEditor(selector) {
  const title = "Edit Menu";
  ({
    header: headerView,
    loading: loadingView,
    modal: modalView,
    notification: notificationView,
  } = dashboardLayout(
    selector,
    [
      actionBar(div("#actions")),
      widget([
        form("#configurations-form"),
      ], "Configuration", ".configuration"),
      widget([
        form("#menu-editor-form"),
      ], "Main Menu", "#edit-menu"),
    ],
    title,
  ));
  loadingView.show();

  const [config, user, menu, pages, uiConfiguration] = await Promise.all([
    getConfig(),
    api.user.getMe(),
    api.site.getMenu(true),
    api.page.list(),
    api.site.getUIConfiguration(),
  ]);

  const state = {
    ...defaultState,
    config,
    user,
    menu,
    pages,
    uiConfiguration,
    title,
    messages: [],
  };
  headerView.update(state);

  configurationView = formManagedView(showConfigurationsView)("#configurations-form", state);
  editMenuView = formManagedView(showMenuEditor)("#menu-editor-form", state);
  view.create(showActions)("#actions", state);

  if (!isHelpModalSeen(modalTypes.ADMIN_EDIT_MENU)) {
    modalView.show(adminEditMenuHelpModal());
    markModalAsSeen(modalTypes.ADMIN_EDIT_MENU);
  }
  loadingView.hide();
}
