/**
 * Contains the core {@link doAPICall} function and internal helpers.
 *
 * @module api/core
 * @category Backend API
 * @subcategory Core
 */
import cache from "cache";
import { contentTypes } from "model/api/constants";
import { makeAPIResponse } from "model/api";
import { ConstraintError } from "model/error";
import { TOKEN_KEY } from "model/authentication/constants";
import { baseUrl } from "./core-util";

/**
 * Helper function to take the params object from an APIRequest and turn
 * them into URL-safe parameters.
 *
 * If it encounters an array as a value it will create a comma-separated
 * string out of the values. If it encounters an object, it will JSON encode the object.
 * Anything else is cast to a string.
 *
 * This is not strongly validated, so garbage in will yield garbage out.
 *
 * @function makeUrlParams
 * @param {object} params key-value pairs
 * @return {URLSearchParams}
 */
const makeUrlParams = (params) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const originalValue = params[key];
    let value = "";
    if (originalValue instanceof Array) {
      value = originalValue.join(",");
    } else if (typeof originalValue === "object") {
      value = JSON.stringify(originalValue);
    } else {
      value = `${originalValue}`;
    }
    urlParams.append(key, value);
  });
  return urlParams;
};

/**
 * Makes the URL for a fetch request.
 *
 * @function makeUrl
 * @async
 * @param {APIRequest} request
 * @return {Promise<string>}
 */
const makeUrl = async (request) => {
  let params;

  if (request.params !== null) {
    params = `?${makeUrlParams(request.params).toString()}`;
  } else params = "";
  const base = await baseUrl(request.service);

  return new URL(`${base}${request.endpoint}${params}`);
};

const makeBodyAndHeaders = (request) => {
  const headers = new Headers();
  let body;

  if (request.contentType === contentTypes.FORM_DATA) {
    if (request.body) body = request.body;
    else throw new ConstraintError("APIRequest.body", "no form data supplied");
  } else if (request.contentType === contentTypes.JSON) {
    if (request.body) body = JSON.stringify(request.body);
    headers.set("Content-Type", request.contentType);
  } else {
    throw new ConstraintError("APIRequest.body", "invalid content type", request.contentType);
  }

  const accessToken = cache.getObject(TOKEN_KEY)?.accessToken;
  if (request.authenticate && accessToken) {
    headers.set("X-Access-Token", `Bearer ${accessToken}`);
  }
  return { headers, body };
};

/**
 * Performs an API call given an APIRequest object.
 *
 * This is called from the {@link module:api/queue} when a queue entry is processed.
 *
 * @function doAPICall
 * @async
 * @param {module:api/types/api~APIRequest} request
 * @return {module:api/types/api~APIResponse} a response object
 */
const doAPICall = async (request) => {
  const { headers, body } = makeBodyAndHeaders(request);

  try {
    const response = await fetch(
      (await makeUrl(request)).toString(),
      {
        method: request.method,
        headers,
        body,
        mode: request.authenticate ? "cors" : "no-cors",
      },
    );

    const result = { request, ok: response.ok, status: response.status };
    const contentType = response.headers.get('Content-Type');
    if (
      contentType === contentTypes.JSON // fast route
      || contentType?.startsWith(contentTypes.JSON) // for "<type>; <encoding>" format
      || contentType?.endsWith("json") // for spring actuator responses
    ) {
      const responseBody = await response.json();
      result.body = responseBody;
    } else {
      result.body = await response.text();
    }

    if (!result.ok) {
      if (result?.status === 404 && request.defaultResponse) {
        return makeAPIResponse({
          ...result,
          status: 404,
          ok: true,
          body: { ...request.defaultResponse },
        });
      }

      // by default just display the status text
      // we might want nicer handling of this in the future
      result.message = response.statusText;
    }

    return makeAPIResponse(result);
  } catch (e) {
    return makeAPIResponse({
      request,
      status: e.status,
      ok: false,
      message: e.message,
      body: e,
    });
  }
};

export default doAPICall;
