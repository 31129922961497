/**
 * Endpoints related to ACL.
 *
 * @module api/acl
 * @category Backend API
 * @subcategory ACL
 */
import log from "log";
import { getAnonymousUser, getById as getUserProfile } from "api/user";
import {
  makeAccessRightsDTO,
  makeCreateGroupDTO, makeGrantDTO,
  makeUserGroupDTO,
  responseToAccessRight,
  responseToACLGrantEntry,
  responseToACLPrincipalEntry,
  userToPrincipal,
} from "model/acl";
import {
  aclPrincipalTypes,
  aclRights,
  aclSubjectTypes,
  systemPrivilegeGroups,
} from "model/acl/constants";
import { metadataTypes } from "model/metadata/constants";
import { ANONYMOUS_USERNAME } from "model/user/constants";
import {
  del, get, post, put,
} from "./request";
import { endpoints } from "./constants";

// verbose debug logging for ACL
const DEBUG = false;

/**
 * Finds the appropriate ACL endpoint for a metadata type.
 * @param {MetadataType} type
 * @return {string} endpoint
 */
const metaAclEndpoint = (type) => {
  switch (type) {
    case metadataTypes.DOCUMENT:
      return endpoints.ACL_MEDIA_DOCUMENT;
    case metadataTypes.VIDEO:
      return endpoints.ACL_MEDIA_VIDEO;
    case metadataTypes.LIST:
      return endpoints.ACL_MEDIA_LIST;
    default:
      throw new Error("unrecognized metadata type", type);
  }
};

/**
 * List all acl user entries.
 *
 * @function listUsers
 * @return {Promise<module:model/acl~ACLPrincipalEntry[]>}
 */
export const listUsers = async () => (await get(
  `${endpoints.ACL_USER}/all`,
)).body.map(responseToACLPrincipalEntry);

/**
 * List all the users in a certain Group.
 * Path param id is a userGroupIdOnly users with USER_MANAGEMENT role can call this api.
 * If a calling user doesn't have USER_MANAGEMENT role,
 * api will give unauthorized error.userId of a calling user will be taken from
 * accessToken and matched with ACL in the backend.
 *
 * @function listUsersInGroup
 * @return {Promise<module:model/acl~ACLPrincipalEntry[]>}
 */
export const listUsersInGroup = async (id) => (await get(
  `${endpoints.ACL_USER_GROUP}/${id}`,
)).body.map(responseToACLPrincipalEntry);

/**
 * List all acl user groups.
 *
 * @function listGroups
 * @return {Promise<module:model/acl~ACLPrincipalEntry[]>}
 */
export const listGroups = async () => (await get(
  `${endpoints.ACL_GROUP}/all`,
)).body.map(responseToACLPrincipalEntry);

/**
 * Get a single acl user group by id.
 *
 * @function getGroup
 * @param {UUID} id
 * @return {module:model/acl~ACLPrincipalEntry}
 */
export const getGroup = async (id) => (await listGroups())
  .find((group) => group.id === id) || null;

/**
 * Create a user group.
 *
 * @function createGroup
 * @param {string} name
 * @return {module:model/acl~ACLPrincipalEntry}
 */
export const createGroup = async (name) => responseToACLPrincipalEntry((await post(
  `${endpoints.ACL_GROUP}`,
  null,
  makeCreateGroupDTO({ name }),
)).body);

/**
 * Delete a user group.
 *
 * @function deleteGroup
 * @param {UUID} id
 * @return {boolean}
 */
export const deleteGroup = async (id) => {
  (await del(`${endpoints.ACL_GROUP}/${id}`));
  return true;
};

/**
 * Update a user group name.
 *
 * @function changeGroupName
 * @param {string} id
 * @param {string} name
 * @return {Promise<module:model/acl~ACLPrincipalEntry>}
 */
export const changeGroupName = async (id, name) => responseToACLPrincipalEntry((await put(
  `${endpoints.ACL_GROUP}/${id}`,
  null,
  makeCreateGroupDTO({ name }),
)).body);

/**
 * Get a single acl user entry by id.
 *
 * Note this is probably not used anywhere, here for completeness.
 *
 * @function getUser
 * @param {UUID} id
 * @return {Promise<module:model/acl~ACLPrincipalEntry>}
 */
export const getUser = async (id) => (await listUsers())
  .find((user) => user.id === id) || null;

/**
 * List all the ACL user and Group entries. Only the user who has USER_MANAGEMENT role
 * will be able to call this api.
 *
 * @function listAll
 * @return {Promise<module:model/acl~ACLPrincipalEntry[]>}
 */
export const listAll = async () => (await Promise.all([
  listGroups(),
  listUsers(),
])).flat();

/**
 * List grants for a subject by type and id.
 * @function listGrants
 * @return {Promise<module:model/acl~ACLGrantEntry[]>}
 */
export const listGrants = async (type, id) => (
  await get(`${endpoints.ACL}/${type}/user/all/${id}`)
).map(responseToACLGrantEntry);

/**
 * Add a particular user to the user group.
 * Only users with USER_MANAGEMENT role can call this api.
 * If a calling user doesn't have USER_MANAGEMENT role,
 * api will give unauthorized error.userId of a calling user will be taken from
 * accessToken and matched with ACL in the backend.
 *
 * @function addUserToGroup
 * @param {object} partial
 */
export const addUserToGroup = async (partial) => (await post(
  `${endpoints.ACL_USER_GROUP}/list`,
  null,
  makeUserGroupDTO(partial),
)).ok;

/**
 * Remove a particular user to the user group.
 * Only users with USER_MANAGEMENT role can call this api.
 * If a calling user doesn't have USER_MANAGEMENT role,
 * api will give unauthorized error.userId of a calling user will be taken from
 * accessToken and matched with ACL in the backend.
 *
 * @function deleteUserFromGroup
 * @param {object} partial
 */
export const deleteUserFromGroup = async (partial) => (await del(
  `${endpoints.ACL_USER_GROUP}/list`,
  null,
  makeUserGroupDTO(partial),
)).ok;

/**
 * Generic grant endpoint. Used internally by the updateGrant calls by subject type.
 */
const updateGrant = async (
  type,
  subjectId,
  grants,
) => (await post(
  endpoints.UPDATE_GRANTS(type, subjectId),
  null,
  makeAccessRightsDTO(grants),
)).ok;

/**
 * Update access type for a given #userId on given entity #id. Only the users who have
 * OWNER access type on this entity will be able to call this endpoint.
 *
 * @function updateGrantForMetadata
 * @param {ListMetadata} subject
 * @param {Promise<module:model/acl~ACLGrantEntry[]>} grants
 * @return boolean
 */
export const updateGrantForMetadata = async (subject, grants) => (await post(
  `${metaAclEndpoint(subject.type)}/${subject.id}`,
  null,
  makeAccessRightsDTO(grants),
)).ok;

/**
 * Update access type for a given #userId on given entity #id. Only the users who have
 * OWNER access type on this entity will be able to call this endpoint.
 *
 * @function updateGrantForMetadataBatch
 * @param {metadataTypes} type
 * @param {Array.<{id:string, list:ACLGrantEntry[]}>} grants
 * @return boolean
 */
export const updateGrantForMetadataBatch = async (type, grants) => (await post(
  `${metaAclEndpoint(type)}/batch`,
  null,
  grants.map((grant) => ({
    ...grant,
    list: grant.list.map(makeGrantDTO),
  })),
)).ok;

/**
 * Update access type for a given #userId on given entity #id. Only the users who have
 * OWNER access type on this entity will be able to call this endpoint.
 *
 * @function updateGrantForFileDescriptor
 * @param {UUID} subjectId
 * @param {Promise<module:model/acl~ACLGrantEntry[]>} grants
 * @return boolean
 */
export const updateGrantForFileDescriptor = async (subject, grants) => updateGrant(
  aclSubjectTypes.FILE,
  subject,
  grants,
);

/**
 * Update access type for a given #userId on given entity #id. Only the users who have
 * OWNER access type on this entity will be able to call this endpoint.
 *
 * @function updateGrantForPage
 * @param {DynamicPage} subject
 * @param {Promise<module:model/acl~ACLGrantEntry[]>} grants
 * @return boolean
 */
export const updateGrantForPage = async (subject, grants) => updateGrant(
  aclSubjectTypes.PAGE,
  subject,
  grants,
);

/**
 * Update access type for a given #userId on given entity #id. Only the users who have
 * OWNER access type on this entity will be able to call this endpoint.
 *
 * @function updateGrantForCourse
 * @param {Course} subject
 * @param {Promise<module:model/acl~ACLGrantEntry[]>} grants
 * @return boolean
 */
export const updateGrantForCourse = async (subject, grants) => updateGrant(
  aclSubjectTypes.COURSE,
  subject.id,
  grants,
);

/**
 * List access type for a given #userId on given #courseId. Only
 * the users who have OWNER access type on the File Descriptor will be able to call this endpoint.
 *
 * @function listPrincipalAccessRightsForCourseId
 * @param {string} principalId
 * @param {string} principalType
 * @param {string} courseId
 * @returns {Promise<Set<string>>}
 */
export const listPrincipalAccessRightsForCourseId = async (
  principalId,
  principalType,
  courseId,
) => responseToAccessRight(
  (await get(
    `${endpoints.ACL_COURSE}/${courseId}/user/${principalId}/${principalType.toLowerCase()}`,
  )).body,
);

/**
 * List all the users and user groups who are linked to this course.
 * Only the users who have OWNER access type on this course
 * will be able to call this endpoint.
 *
 * @function listPrincipalsForCourseId
 * @param subjectId
 */
export const listPrincipalsForCourseId = async (subjectId) => (
  await get(`${endpoints.ACL_COURSE}/user/all/${subjectId}`)
).body.map(responseToACLPrincipalEntry);

/**
 * List access type for a given #userId on given #metadataId. Only
 * the users who have OWNER access type on the File Descriptor will be able to call this endpoint.
 *
 * @function listPrincipalAccessRightsForMetadataId
 * @param {string} principalId
 * @param {string} principalType
 * @param {string} metadataId
 * @param {string} metadataType
 * @returns {Promise<Set<string>>}
 */
export const listPrincipalAccessRightsForMetadataId = async (
  principalId,
  principalType,
  metadataId,
  metadataType,
) => responseToAccessRight(
  (await get(
    `${metaAclEndpoint(metadataType)}/${metadataId}/user/${principalId}/${principalType.toLowerCase()}`,
  )).body,
);

/**
 * List all the users and user groups who are linked to this media metadata.
 * Only the users who have OWNER access type on this media metadata
 * will be able to call this endpoint.
 *
 * @function listPrincipalsForMetadataId
 * @param subjectId
 */
export const listPrincipalsForMetadataId = async (subjectId, metadataType) => (
  await get(`${metaAclEndpoint(metadataType)}/user/all/${subjectId}`)
).body.map(responseToACLPrincipalEntry);

/**
 * List all the users and user groups who are linked to this media metadata.
 * Only the users who have OWNER access type on this media metadata
 * will be able to call this endpoint.
 *
 * @function listPrincipalsForMetadataId
 * @param {string[]} subjectIds
 * @param {metadataTypes} metadataType
 */
export const listPrincipalsForMetadataBatch = async (subjectIds, metadataType) => {
  if (!subjectIds?.length) return null;
  const ids = subjectIds.join(",");
  const response = (await get(`${metaAclEndpoint(metadataType)}/user/all/batch?ids=${ids}`)).body;
  return {
    entries: response.entries.map((entries) => entries.map(responseToACLPrincipalEntry)),
    errors: response.errors,
  };
};

/* chunked request - disabled for now pending ACL batch API fix
export const listPrincipalsForMetadataBatch = async (subjectIds, metadataType) => {
  console.log("checking acl batch", subjectIds);
  if (!subjectIds?.length) {
    console.log("batch is empty, quitting");
    return null;
  }
  const chunks = chunk(subjectIds, 10);
  let entries = [];
  let errors = [];
  try {
    // break up into chunks because endpoint dies with large requests
    for (let i = 0; i < chunks.length; i++) {
      console.log("metadata batch", i, "/", chunks.length, chunks[i]);
      const ids = chunks[i].join(",");
      /* eslint-disable-next-line no-await-in-loop *
      const response = (await get(`${metaAclEndpoint(metadataType)}/user/all/batch?ids=${ids}`))
        .body;
      console.log("response", response);
      entries = entries.concat(response.entries);
      errors = errors.concat(response.errors);
    }
  } catch (e) {
    log.error(e, "while batching metadata acl rights");
  }
  console.log("results", entries, errors);
  return {
    entries: entries.map((items) => items.map(responseToACLPrincipalEntry)),
    errors,
  };
};
*/

/**
 * List access type for a given #userId on given #metadataId. Only
 * the users who have OWNER access type on the File Descriptor will be able to call this endpoint.
 *
 * @function listPrincipalAccessRightsForPageId
 * @param {string} principalId
 * @param {string} pageId
 * @param {string} principalType
 * @returns {Promise<Set<string>>}
 */
export const listPrincipalAccessRightsForPageId = async (
  principalId,
  pageId,
  principalType,
) => responseToAccessRight(
  (await get(
    endpoints.USER_PAGE_ACCESS(principalId, pageId, principalType),
  )).body,
);

/**
 * List all the users and user groups who are linked to this media metadata.
 * Only the users who have OWNER access type on this media metadata
 * will be able to call this endpoint.
 *
 * @function listPrincipalsForPageId
 * @param subjectId
 */
export const listPrincipalsForPageId = async (subjectId) => (await get(
  endpoints.USER_LIST_PAGE(subjectId),
)).body.map(responseToACLPrincipalEntry);

/**
 * List all the users and user groups who has access to given conversId.
 * Only the users who have OWNER access type on this entity
 * will be able to call this endpoint.
 *
 * @function listPrincipalsForConversationId
 * @param conversationId
 * @returns {Promise<module:model/acl~ACLPrincipalEntry[]>}
 */
export const listPrincipalsForConversationId = async (conversationId) => {
  try {
    return (await get(
      endpoints.CONVERSATION_PRINCIPALS(conversationId),
    )).body.map(responseToACLPrincipalEntry);
  } catch (e) {
    log.error(e);
    return [];
  }
};

/**
 * List access type for a given #userId on given entity #id.
 * Only the users who have OWNER access type on this entity
 * will be able to call this endpoint.
 *
 * @param {string} conversationId
 * @param {string} principalId
 * @param {aclPrincipalTypes} principalType
 * @returns {Promise<*>}
 */
export const listPrincipalAccessRightsForConversationId = async (
  conversationId, principalId, principalType,
) => {
  try {
    return (await get(
      endpoints.CONVERSATION_GRANTS(principalId, conversationId, principalType),
    )).body;
  } catch (e) {
    log.error(e, "Unable to retrieve conversation access rights.");
    return [];
  }
};

/**
 * Get grants for a conversation entry.
 *
 * @param {ListMetadata} subject
 * @return {Promise<module:model/acl~ACLGrantEntry[]>}
 */
export const listConversationGrants = async (conversationId) => {
  try {
    let principals = await listPrincipalsForConversationId(conversationId);
    principals = await Promise.all(principals.map((principal) => {
      if (principal.type === aclPrincipalTypes.INDIVIDUAL) {
        return getUserProfile(principal.id).then(userToPrincipal);
      }
      return principal;
    }));
    const grants = Promise.all(principals.map(
      (principal) => listPrincipalAccessRightsForConversationId(
        conversationId,
        principal.id,
        principal.type,
      ).then((rights) => responseToACLGrantEntry({
        subject: { id: conversationId, type: aclSubjectTypes.CONVERSATION },
        principal,
        rights,
        type: aclSubjectTypes.CONVERSATION,
      })),
    ));

    return grants;
  } catch (e) {
    if (DEBUG) log.info("ACL :: unable to retrieve grants for metadata", conversationId);
    return null;
  }
};

/**
 * List access type for a given #userId on given entity #id.
 * Only the users who have OWNER access type on this entity
 * will be able to call this endpoint.
 *
 * @param {string} conversationId
 * @param {string} principalId
 * @param {aclPrincipalTypes} principalType
 * @returns {Promise<*>}
 */
export const listLimitedPrincipalAccessRightsForConversationId = async (
  conversationId, principalId, principalType,
) => {
  try {
    return (await get(
      `${endpoints.CONVERSATION_GRANTS(principalId, conversationId, principalType)}/limited`,
    )).body;
  } catch (e) {
    log.error(e, "Unable to retrieve conversation access rights.");
    return [];
  }
};

/**
 * Get grants for a metadata entry.
 *
 * @param {ListMetadata} subject
 * @return {Promise<module:model/acl~ACLGrantEntry[]>}
 */
export const listMetadataGrants = async (subject) => {
  try {
    let principals = await listPrincipalsForMetadataId(subject.id, subject.type);
    principals = await Promise.all(principals.map((principal) => {
      // getUserProfile returns 503 for the anon user (delete the IF when BE fix it)
      if (principal.name === ANONYMOUS_USERNAME) {
        return getAnonymousUser().then(userToPrincipal);
      }
      if (principal.type === aclPrincipalTypes.INDIVIDUAL) {
        return getUserProfile(principal.id).then(userToPrincipal);
      }
      return principal;
    }));
    const grants = Promise.all(principals.map(
      (principal) => listPrincipalAccessRightsForMetadataId(
        principal.id,
        principal.type,
        subject.id,
        subject.type,
      ).then((rights) => responseToACLGrantEntry({
        subject,
        principal,
        rights,
        type: aclSubjectTypes.MEDIA,
      })),
    ));

    return grants;
  } catch (e) {
    if (DEBUG) log.info("ACL :: unable to retrieve grants for metadata", subject.id);
    return null;
  }
};

/**
 * Get grants for a page entry.
 *
 * @param {DynamicPage} subject
 * @return {Promise<module:model/acl~ACLGrantEntry[]>}
 */
export const listPageGrants = async (subject) => {
  try {
    let principals = await listPrincipalsForPageId(subject.id);
    principals = await Promise.all(principals.map((principal) => {
      if (principal.type === aclPrincipalTypes.INDIVIDUAL) {
        return getUserProfile(principal.id).then(userToPrincipal);
      }
      return principal;
    }));
    const grants = Promise.all(principals.map(
      (principal) => listPrincipalAccessRightsForPageId(
        principal.id,
        subject.id,
        principal.type,
      ).then((rights) => responseToACLGrantEntry({
        subject,
        principal,
        rights,
        type: aclSubjectTypes.PAGE,
      })),
    ));

    return grants;
  } catch (e) {
    if (DEBUG) log.info("ACL :: unable to retrieve grants for metadata", subject.id);
    return null;
  }
};

/**
 * Get grants for a page entry.
 *
 * @param {Course} subject
 * @return {Promise<module:model/acl~ACLGrantEntry[]>}
 */
export const listCourseGrants = async (subject) => {
  try {
    let principals = await listPrincipalsForCourseId(subject.id);
    principals = await Promise.all(principals.map((principal) => {
      if (principal.type === aclPrincipalTypes.INDIVIDUAL) {
        return getUserProfile(principal.id).then(userToPrincipal);
      }
      return principal;
    }));
    return Promise.all(principals.map(
      (principal) => listPrincipalAccessRightsForCourseId(
        principal.id,
        principal.type,
        subject.id,
      ).then((rights) => responseToACLGrantEntry({
        subject,
        principal,
        rights,
        type: aclSubjectTypes.COURSE,
      })),
    ));
  } catch (e) {
    if (DEBUG) log.info("ACL :: unable to retrieve grants for course", subject.id);
    return null;
  }
};

/**
 * Check if user is in USER_MANAGEMENT group
 *
 * @param {string} userId
 * @returns {Promise<boolean>}
 */
export const isUserInUserManagementGroup = async (userId) => listGroups()
  .catch(() => false)
  .then((principals) => {
    const userManagementGroup = principals
      .find((principal) => principal.name === systemPrivilegeGroups.USER_MANAGEMENT);
    return userManagementGroup.members.some((user) => user.id === userId);
  });

const makeGivenAccessForMetadata = (principals, type) => ({
  publicAccess: principals?.some((principal) => principal?.name === ANONYMOUS_USERNAME),
  registeredUsers: principals
    ?.some((principal) => principal?.name === systemPrivilegeGroups.PUBLIC),
  type,
});

/**
 * Get whether public or registered users has access to given metadata list
 *
 * @param {object[]} metadata
 * @returns {
 *   Promise<Map<string, {publicAccess: boolean, registeredUsers: boolean, type: metadataTypes}>>
 * }
 */
export const getMetadataAccessMap = async (metadata = []) => {
  const typeMap = new Map([
    [metadataTypes.DOCUMENT, []],
    [metadataTypes.VIDEO, []],
    [metadataTypes.LIST, []],
  ]);
  metadata.forEach((data) => typeMap.get(data.type)?.push(data.id));
  const [documentPrincipals, videoPrincipals, listPrincipals] = await Promise.all([
    listPrincipalsForMetadataBatch(typeMap.get(metadataTypes.DOCUMENT), metadataTypes.DOCUMENT),
    listPrincipalsForMetadataBatch(typeMap.get(metadataTypes.VIDEO), metadataTypes.VIDEO),
    listPrincipalsForMetadataBatch(typeMap.get(metadataTypes.LIST), metadataTypes.LIST),
  ]);
  const contentPrincipalsMap = new Map();
  typeMap.get(metadataTypes.DOCUMENT).forEach((documentId, index) => {
    contentPrincipalsMap
      .set(
        documentId,
        makeGivenAccessForMetadata(documentPrincipals.entries?.[index], metadataTypes.DOCUMENT),
      );
  });
  typeMap.get(metadataTypes.VIDEO).forEach((videoId, index) => {
    contentPrincipalsMap
      .set(
        videoId,
        makeGivenAccessForMetadata(videoPrincipals.entries?.[index], metadataTypes.VIDEO),
      );
  });
  typeMap.get(metadataTypes.LIST).forEach((listId, index) => {
    contentPrincipalsMap
      .set(
        listId,
        makeGivenAccessForMetadata(listPrincipals.entries?.[index], metadataTypes.LIST),
      );
  });
  return contentPrincipalsMap;
};

export const hasChangedMediaGrants = (
  initialContentPrincipalsMap,
  toSaveContentPrincipalsMap,
) => {
  let changed = false;
  toSaveContentPrincipalsMap.forEach(({ publicAccess, registeredUsers }, id) => {
    const initialValues = initialContentPrincipalsMap.get(id);
    if (publicAccess !== initialValues.publicAccess) {
      changed = true;
    }
    if (registeredUsers !== initialValues.registeredUsers) {
      changed = true;
    }
  });
  return changed;
};

/**
 * Update grants using batch (used in manage tables)
 *
 * @param {
 *   Map<string, {publicAccess: boolean, registeredUsers: boolean, type: metadataTypes}>
 * } initialContentPrincipalsMap
 * @param {
 *   Map<string, {publicAccess: boolean, registeredUsers: boolean, type: metadataTypes}>
 * } toSaveContentPrincipalsMap
 * @returns {Promise<void>}
 */
export const updateMediaGrantsBatch = async (
  initialContentPrincipalsMap,
  toSaveContentPrincipalsMap,
) => {
  const [anonymousUser, groups] = await Promise.all([
    getAnonymousUser(),
    listGroups(),
  ]);
  const registeredUsersGroup = groups.find((group) => group.name === systemPrivilegeGroups.PUBLIC);
  const documentGrants = [];
  const videoGrants = [];
  const playlistGrants = [];
  toSaveContentPrincipalsMap.forEach(({ publicAccess, registeredUsers, type }, id) => {
    const initialValues = initialContentPrincipalsMap.get(id);
    const grantToAdd = {
      id,
      list: [],
    };
    if (publicAccess !== initialValues.publicAccess) {
      const publicAccessGrant = {
        id: anonymousUser.id,
        type: aclPrincipalTypes.INDIVIDUAL,
        rights: publicAccess ? [aclRights.READ] : [],
      };
      grantToAdd.list.push(publicAccessGrant);
    }
    if (registeredUsers !== initialValues.registeredUsers) {
      const registeredUsersGrant = {
        id: registeredUsersGroup.id,
        type: aclPrincipalTypes.GROUP,
        rights: registeredUsers ? [aclRights.READ] : [],
      };
      grantToAdd.list.push(registeredUsersGrant);
    }
    if (!grantToAdd.list.length) return;
    switch (type) {
      case metadataTypes.DOCUMENT: {
        documentGrants.push(grantToAdd);
        break;
      }
      case metadataTypes.VIDEO: {
        videoGrants.push(grantToAdd);
        break;
      }
      case metadataTypes.LIST: {
        playlistGrants.push(grantToAdd);
        break;
      }
      default: break;
    }
  });

  return Promise.all([
    ...(documentGrants.length
      ? [updateGrantForMetadataBatch(metadataTypes.DOCUMENT, documentGrants)]
      : []
    ),
    ...(videoGrants.length
      ? [updateGrantForMetadataBatch(metadataTypes.VIDEO, videoGrants)]
      : []
    ),
    ...(playlistGrants.length
      ? [updateGrantForMetadataBatch(metadataTypes.LIST, playlistGrants)]
      : []
    ),
  ]);
};
