/**
 * The floating close button used by modal dialogs.
 * @module ui/component/modal/component/close-fab
 * @private
 */
/***/
import icon from "ui/component/icon";
import span from "ui/html/span";

/**
 * @function closeFab
 * @param {boolean} isAsync
 * @param {module:ui/view/modal~modalView}
 */
export default (isAsync = false, modalView = null, onClose = async () => {}) => span(
  icon("times.ok.button"),
  ".fab",
  {
    on: {
      click: async () => {
        let result;
        if (onClose) {
          result = await onClose();
        }
        if (modalView && result === undefined && isAsync) {
          modalView.resolve(null);
        } else if (modalView && result === undefined) {
          modalView.back();
        }
      },
    },
  },
);
