/**
 * Constants used by messaging models.
 *
 * @module model/message/constants
 * @category Model
 * @subcategory Message
 */
import { threadStatuses as statuses } from "@smartedge/em-message/constants";
import { frozen } from "util/object";

export const threadStatuses = statuses;

/**
 * @enum {string} inboxTypes
 * @readonly
 * @property {string} DM
 * @property {string} GROUP
 */
export const inboxTypes = Object.freeze({
  DM: "DM",
  GROUP: "GROUP",
});

/**
 * @enum {string} inboxModes
 * @readonly
 * @property {string} OPEN
 * @property {string} RESTRICTED
 */
export const inboxModes = Object.freeze({
  OPEN: "OPEN",
  RESTRICTED: "RESTRICTED",
});

/**
 * @enum {string} inboxStatuses
 * @readonly
 * @property {string} ACTIVE
 * @property {string} BLOCKED
 */
export const inboxStatuses = Object.freeze({
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
});

/**
 * @enum {string} messageStatuses
 * @readonly
 * @property {string} READ
 * @property {string} UNREAD
 * @property {string} REPORTED
 */
export const messageStatuses = Object.freeze({
  READ: "READ",
  UNREAD: "UNREAD",
  REPORTED: "REPORTED",
});

/**
 * @enum sortOrders
 * @property ASC
 * @property DESC
 */
export const sortOrders = frozen({
  ASC: "ASC",
  DESC: "DESC",
});

/**
 * @enum {string} messageFilterType
 * @readonly
 * @property {string} EXACT_MATCH
 * @property {string} GREATER_EQUAL
 * @property {string} LESS_EQUAL
 * @property {string} BETWEEN
 * @property {string} REGEX_MATCH
 */
export const messageFilterType = Object.freeze({
  EXACT_MATCH: "EXACT_MATCH",
  GREATER_EQUAL: "GREATER_EQUAL",
  LESS_EQUAL: "LESS_EQUAL",
  BETWEEN: "BETWEEN",
  REGEX_MATCH: "REGEX_MATCH",
});

export const messageFilterTypes = frozen(new Set([
  ...Object.values(messageFilterType),
]));

/**
 * @enum {string} messageAttributeName
 * @readonly
 * @property {string} ATTACHMENT
 * @property {string} ATTACHMENT_FILE_NAME
 * @property {string} REPORT_TYPE
 * @property {string} MESSAGE_STATUS
 * @property {string} TYPE
 * @property {string} USER_ID
 * @property {string} RECEIVER_ID
 */
export const messageAttributeName = Object.freeze({
  ATTACHMENT: "attributes.attachments",
  ATTACHMENT_FILE_NAME: "attributes.attachmentFileNames",
  REPORT_TYPE: "attributes.reportType",
  MESSAGE_STATUS: "messageStatus",
  TYPE: "type",
  USER_ID: "senderInboxId",
  RECEIVER_ID: "receiverInboxId",
});

/**
 * User-friendly labels for thread statuses.
 *
 * @constant threadStatusesFriendly
 * @type {Map.<statuses, string>}
 */
export const threadStatusesFriendly = frozen(new Map([
  [statuses.OPEN, "Open"],
  [statuses.CLOSED, "Closed"],
  [statuses.REPORTED, "Reported"],
  [statuses.REMOVED, "Removed"],
]));

/**
 * Generates a filter param
 *
 * @property {Function} HAS_ATTACHMENT
 * @property {Function} BY_ATTACHMENT_NAME
 * @property {Function} BY_MESSAGE_STATUS
 * @property {Function} BY_USER_ID
 * @property {Function} BY_TYPE
 * @property {Function} BY_REPORT_TYPE
 */
export const messageFilterParams = Object.freeze({
  HAS_ATTACHMENT: () => ({
    name: messageAttributeName.ATTACHMENT,
    filterType: messageFilterType.REGEX_MATCH,
    value: ".+",
  }),
  BY_ATTACHMENT_NAME: (name) => ({
    name: messageAttributeName.ATTACHMENT_FILE_NAME,
    filterType: messageFilterType.REGEX_MATCH,
    value: name,
  }),
  BY_MESSAGE_STATUS: (status) => ({
    name: messageAttributeName.MESSAGE_STATUS,
    filterType: messageFilterType.EXACT_MATCH,
    value: status,
  }),
  BY_TYPE: (value) => ({
    name: messageAttributeName.TYPE,
    filterType: messageFilterType.EXACT_MATCH,
    value,
  }),
  BY_USER_ID: (userId) => ({
    name: messageAttributeName.USER_ID,
    filterType: messageFilterType.EXACT_MATCH,
    value: userId,
  }),
  BY_RECEIVER_ID: (userId) => ({
    name: messageAttributeName.RECEIVER_ID,
    filterType: messageFilterType.EXACT_MATCH,
    value: userId,
  }),
  BY_REPORT_TYPE: (type) => ({
    name: messageAttributeName.REPORT_TYPE,
    filterType: messageFilterType.EXACT_MATCH,
    value: type,
  }),
});
