/**
 * API requests for metadata labels.
 *
 * @module api/v2/metadata/label
 * @category Backend API v2
 * @subcategory Metadata
 */
import { endpoints } from "api/constants";
import { get } from "api/request";
import log from "log";
import { responseToCategory } from "model/metadata";

/**
 * A list of currently existing labels.
 * @function list
 * @return {Category[]}
 */
export const list = async () => {
  try {
    const response = (await get(endpoints.CATEGORY)).body;
    return response.map(responseToCategory);
  } catch (e) {
    log.error(e, "unable to list labels");
    return [];
  }
};

export const countLabels = async () => {
  try {
    const response = (await get(endpoints.CATEGORY)).body;
    return response.length;
  } catch (e) {
    log.error(e, "unable to retrieve label count");
    return [];
  }
};
