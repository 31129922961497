import { get } from "api/request";
import { endpoints, services } from "api/constants";
/**
 * Get theme service info.
 *
 * @function info
 * @return {Promise.<Object>}
 */
/* eslint-disable-next-line import/prefer-default-export */
export const info = async () => (
  await get.service(services.FE)(endpoints.FE_INFO)
).body;
