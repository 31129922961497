/**
 * Renders the rich-text section type for dynamic pages.
 *
 * @module ui/page/dynamic/section-rich-text
 * @private
 * @category Page
 * @subcategory User Facing
 */
/***/
import createDOMPurify from "dompurify";
import collapsible from "ui/component/collapsible";
import {
  section,
} from "ui/html";
import el from "ui/common/el";

let sanitizer;

/**
 * A rich text section.
 *
 * @function sectionText
 * @param {module:model/dynamic-page~PageSection} section of type `RICH_TEXT`
 * @return {module:ui/common/el~El} section element
 */
export default function sectionText(pageSection) {
  if (!pageSection.text) return ""; // don't display section if empty
  if (!sanitizer) sanitizer = createDOMPurify(window);
  const cleanText = sanitizer.sanitize(pageSection.text);
  const inner = el.fromHTML(`<div class="rich-content-wrapper">${cleanText}</div>`);
  if (pageSection.searchData?.collapsible && pageSection.title) {
    return section(
      ".rich-text.collapsing",
      collapsible({ title: pageSection.title }, inner),
    );
  }
  return section(".rich-text", inner);
}
