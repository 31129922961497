/**
 * Analytics models.
 *
 * @module model/analytics
 * @category Model
 * @subcategory Analytics
 */
import { metadataTypes } from "model/metadata/constants";
import { MAX_DATE, MIN_DATE } from "util/date";
import { orNull, validDate } from "../constraints";

/**
 * @typedef AnalyticsEvaluation
 * @property {string} assessmentId
 * @property {string} userId
 * @property {string} moduleId
 * @property {string} courseId
 * @property {evaluationStatus} status
 */

/**
 * @param response
 * @param {string} response.courseId
 * @param {string} response.moduleId
 * @param {string} response.status
 * @param {string} response.assessmentId
 * @param {string} response.userId
 * @return {AnalyticsEvaluation}
 */
export const responseToAnalyticsEvaluation = (response) => ({
  courseId: response.courseId,
  moduleId: response.moduleId,
  status: response.status,
  assessmentId: response.assessmentId,
  userId: response.userId,
});

/**
 * @typedef AnalyticsEntry
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {string} pageId
 * @property {string} pageKey
 * @property {string} visitedBy
 * @property {number} value
 */

/**
 * @param response
 * @param {string} response.pageKey
 * @param {string} response.pageId
 * @param {string} response.start
 * @param {string} response.stop
 * @param {number} response.value
 * @param {string} response.visitedBy
 * @returns AnalyticsEntry
 */
export const responseToAnalyticEntry = (response) => ({
  startDate: response.start ? new Date(response.start) : null,
  endDate: response.stop ? new Date(response.stop) : null,
  pageId: response.pageId,
  pageKey: response.pageKey,
  value: response.value,
  visitedBy: response.visitedBy,
});

/**
 * @typedef AnalyticsContentDurationEntry
 * @property {string} watchedBy
 * @property {number} elapsed
 * @property {number} videoTime
 * @property {string} contentId
 */

/**
 * @param response
 * @param {string} response.watchedBy
 * @param {number} response.elapsed
 * @param {number} response.videoTime
 * @param {string} response.contentKey
 * @returns {AnalyticsContentDurationEntry}
 */
export const responseToAnalyticContentDurationEntry = (response) => ({
  watchedBy: response.watchedBy,
  elapsed: response.elapsed || 0,
  videoTime: response.videoTime || 0,
  contentId: response.contentKey,
});

/**
 * @typedef AnalyticsPlaybackEntry
 * @property {UUID} id video id
 * @property {UUID} userId
 * @property {UUID} moduleId
 * @property {UUID} courseId
 * @property {UUID} sessionId
 * @property {float} position in seconds.milliseconds
 */

/**
 * @function responseToAnalyticsPlaybackEntry
 * @param {object} response
 * @param {UUID} response.contentKey id of the video metadata
 * @param {?UUID} response.moduleId module id if watched as a lecture
 * @param {?UUID} response.courseId course id if watched as a lecture
 * @param {UUID} response.watchedBy user id of the viewer
 * @param {UUID} response.sessionKey unique key of the viewing session
 * @param {int} response.videoTime in milliseconds
 * @returns {AnalyticsPlaybackEntry}
 */
export const responseToAnalyticsPlaybackEntry = (response) => ({
  id: response.contentKey,
  userId: response.watchedBy,
  moduleId: response.moduleId,
  courseId: response.courseId,
  sessionId: response.sessionKey,
  position: response.videoTime / 1000,
});

/**
 * Entries for trending, recently watched, recently added videos.
 *
 * @typedef AnalyticsVideoTrendsEntry
 * @property {UUID} id
 */

/**
 * @function responseToAnalyticsVideoTrendsEntry
 * @param {object} response
 * @param {UUID} response.contentKey
 * @param {mixed} response[...] unused/unimplemented data
 * @return AnalyticsVideoTrendsEntry
 */
export const responseToAnalyticsVideoTrendsEntry = (response) => ({
  id: response.contentKey,
  type: metadataTypes.VIDEO,
});

/**
 * @typedef AnalyticsRequest
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {string} window
 * @property {string} pageId
 * @property {string} pageKey
 * @property {string} visitedBy
 * @property {string} contentId
 */

/**
 * @typedef AnalyticsRequestDTO
 * @property {Date} start
 * @property {Date} end
 * @property {string} window
 * @property {string} pageKey
 * @property {string} pageId
 * @property {string} visitedByKey
 */

/**
 * Make analytics DTO acceptable by BE
 *
 * @param {AnalyticsRequest} partial
 * @returns AnalyticsRequestDTO
 */
export const makeAnalyticsRequestDTO = (partial) => ({
  start: orNull(partial.startDate, `AnalyticsRequestDTO.startDate`, validDate) || new Date(MIN_DATE),
  end: orNull(partial.endDate, `AnalyticsRequestDTO.startDate`, validDate) || new Date(MAX_DATE),
  window: partial.window || undefined,
  pageId: partial.pageId || undefined,
  pageKey: partial.pageKey || undefined,
  visitedByKey: partial.visitedBy || undefined,
  contentKey: partial.contentId || undefined,
});
