/**
 * Utility functions dealing with arrays.
 *
 * @module util/array
 * @category Utilities
 */

/**
 * Take the intersection of two arrays.
 *
 * @param {array} first
 * @param {array} second
 * @return {array} containing only items that appear in both arrays
 */
/* eslint-disable-next-line import/prefer-default-export */
export const intersect = (first, second) => {
  const intersection = [];
  first.forEach((entry) => {
    if (second.includes(entry)) intersection.push(entry);
  });
  return intersection;
};

/**
 * Find the difference of two arrays.
 * @param {array} first
 * @param {array} second
 * @return {array} containing only items that do not appear in both arrays
 */
export const difference = (first, second) => {
  const out = [];
  first.forEach((item) => {
    if (!second.includes(item)) out.push(item);
  });

  return out;
};

/**
 * Find only the unique values in an array.
 * @param {array} array
 * @return {array} new array containing only the unique values
 */
export const unique = (array) => ([...new Set(array)]);// let's just exploit Set

/**
 * Get the last entry in an array.
 * @param {array} array
 * @return {?mixed} last entry
 */
export const last = (array) => (array.length ? array[array.length - 1] : null);

/**
 * Make the list query param (e.g. id=123&id=321&id=111)
 *
 * @param {string} name
 * @param {string[]} items
 * @returns {string}
 */
export const makeQueryParamsList = (name, items) => items
  .reduce((_, currentItem, index) => `${name}=${currentItem}${index === items.length - 1 ? "" : "&"}`, "");

/**
 * A reducer combining the function of map and reduce. Filter is run first, then map.
 *
 * @example
 * const arr = ["a", "b", "c", "d"];
 * const filterFn = (item) => item !== "b";
 * const mapFn = (item) => ({ letter: item });
 *
 * const result = arr.reduce(filterMap(filterFn, mapFn));
 * // result: [{ letter: "a" }, { letter: "c" }, { letter: "d" }];
 *
 * @function filterMap
 * @param {function} filterFn filter to perform
 * @param {function} mapFn map function to perform if filter passes
 */
export const filterMap = (filterFn, mapFn) => (acc, cur, idx, arr) => {
  if (filterFn(cur, idx, arr)) {
    return [...acc, mapFn(cur, idx, arr)];
  }
  return acc;
};

/**
 * A reducer combining the function of map and reduce. MapFn is run first,
 * then filterFn on the mapped item.
 *
 * @example
 * const arr = ["a", "b", "c", "d"];
 * const filterFn = (item) => item !== "b";
 * const mapFn = (item) => ({ letter: item });
 *
 * const result = arr.reduce(filterMap(filterFn, mapFn));
 * // result: [{ letter: "a" }, { letter: "c" }, { letter: "d" }];
 *
 * @function filterMap
 * @param {function} filterFn filter to perform
 * @param {function} mapFn map function to perform if filter passes
 */
export const mapFilter = (mapFn, filterFn) => (acc, cur, idx, arr) => {
  const mapped = mapFn(cur, idx, arr);
  if (filterFn(mapped, idx, arr)) {
    return [...acc, mapFn(cur, idx, arr)];
  }
  return acc;
};

/**
 * Split an array into even-sized chunks.
 */
export const chunk = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};
