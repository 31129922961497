/**
 * Pre-configured states for the file-picker component.
 *
 * @module ui/component/form-managed/constants
 * @private
 * @category UI
 * @subcategory Validated Forms
 */

/**
 * Determines the mode the file picker is in, and thus which tab should
 * be displayed and how the result should be handled.
 *
 * Exported as FilePicker.modes from the main module.
 *
 * @constant modes
 * @type object
 * @property {string} KEEP the existing file should be kept
 * @property {string} UPLOAD a new file will be uploaded
 * @property {string} URL a url will be provided for a downloadable file
 * @property {string} SELECT a file will be selected from the media dump or a
 *                           pre-existing descriptor
 */
/* eslint-disable-next-line import/prefer-default-export */
export const filePickerModes = {
  KEEP: "KEEP",
  UPLOAD: "UPLOAD",
  DESCRIPTOR: "DESCRIPTOR",
  FILE: "FILE",
  URL: "URL",
};
